import { userConstants } from '../user.constants';

export const currentAccount = (state = {}, action) => {
  switch (action.type) {
    case userConstants.GET_CURRENT_ACCOUNT_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case userConstants.GET_CURRENT_ACCOUNT_SUCCESS:
      return action.result;
    case userConstants.GET_CURRENT_ACCOUNT_FAILURE:
      return {
        error: action.error.message,
      };
    case userConstants.GET_TASK_STATUS:
      return {
        ...state,
        task_in_progress: true
      };
    case userConstants.GET_TASK_DONE:
      return {
        ...state,
        task_in_progress: false
      };
    default:
      return state;
  }
};
