import styled from 'styled-components';

const Text = styled.span`
  color: ${props => props.theme.colors.darkGray};
  ${props => props.color && `color: ${props.color};`}
`;

Text.displayName = 'Text';

export default Text;
