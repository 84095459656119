import { combineReducers } from 'redux';

import {
  firmwareDeploymentReducer as deploymentsReducer,
  firmwareDeploymentFiltersReducer as filtersReducer,
  firmwareDeploymentMetadataReducer as metadataReducer,
  firmwareDeploymentDetailsReducer as detailsReducer
} from './reducers/firmwareDeployment.reducers';

export const firmwareDeploymentReducer = combineReducers({
  deployments: deploymentsReducer,
  filters: filtersReducer,
  metadata: metadataReducer,
  details: detailsReducer
});
