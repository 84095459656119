import {
  machineAddActionConstants,
  MACHINE_STAGES,
} from 'home/Machines/MachineAdd/actions/machineAdd.constants';

const initialState = {
  stage: MACHINE_STAGES.CHOOSE_ADD_METHOD,
  newlyAddedMachineIds: [],
};

export const machineAddStageReducer = (state = initialState, action) => {
  switch (action.type) {
    case machineAddActionConstants.RESET: {
      return initialState;
    }
    case machineAddActionConstants.SET_STAGE: {
      return {
        ...state,
        stage: action.stage,
      };
    }
    case machineAddActionConstants.BULK_CREATE_ASSETS: {
      return {
        ...state,
        newlyAddedMachineIds: action.machine_ids,
      };
    }
    default:
      return state;
  }
};
