import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const strokeWidthMap = {
  thin: 0.87499923,
  medium: 1.5,
  thick: 2.5
};

const Span = styled.span`
  ${props => props.margin && `margin: ${props.margin}`}
  &:hover {
    cursor: pointer;
  }
`;

const CrossSvg = ({ width, height, fill, strokeWidth, onClick, ...rest }) => (
  <Span onClick={onClick} {...rest}>
    <svg width={width} height={height} viewBox="0 0 15 15" id="svg2">
      <defs id="defs4" />
      <g id="layer1" transform="translate(0,-1037.3622)">
        <g
          id="cross-svg-stroke"
          transform="matrix(0.80812277,-0.80812277,0.80812277,0.80812277,-530.28093,731.39306)"
          style={{ strokeWidth: strokeWidthMap[strokeWidth], strokeMiterlimit: 4, strokeDasharray: 'none' }}
        >
          <path
            id="cross-svg-path"
            d="m 134.5,526.68363 8.57143,0"
            stroke={fill}
          />
          <path
            d="m 138.78571,522.39791 0,8.57143"
            id="cross-svg-path"
            stroke={fill}
          />
        </g>
      </g>
    </svg>
  </Span>
);

CrossSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  onClick: PropTypes.func,
  strokeWidth: PropTypes.string
};

CrossSvg.defaultProps = {
  width: 15,
  height: 15,
  fill: '#000000',
  onClick: () => {},
  strokeWidth: 'thin'
};


export default CrossSvg;
