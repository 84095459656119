import {
  machineAddActionConstants,
  MACHINE_ADD_METHODS,
} from 'home/Machines/MachineAdd/actions/machineAdd.constants';

const initialState = {
  addMethod: MACHINE_ADD_METHODS.TEMPLATE,
  mcType: 'rotating',
};

export const machineAddOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case machineAddActionConstants.RESET: {
      return initialState;
    }
    case machineAddActionConstants.CHOOSE_ADD_METHOD: {
      return {
        ...state,
        addMethod: action.method,
      };
    }
    case machineAddActionConstants.CHOOSE_MC_TYPE: {
      return {
        ...state,
        mcType: action.mcType,
      };
    }
    case machineAddActionConstants.CHOOSE_NON_ROTATING_SUBTYPE: {
      return {
        ...state,
        nonRotatingSubtype: action.subtype
      };
    }
    default:
      return state;
  }
};
