import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Button from 'common/components/atoms/Button';

import FooterFlex from '../atoms/FooterFlex';

const ButtonLink = styled(Button)`
  a {
    color: ${props => props.theme.primaryColor};
  }
`;

const NoteFooter = ({
  savedNote,
  error,
  onCancel,
  loading,
  note,
  onSave,
  machineId,
  width
}) => savedNote ? (
  <FooterFlex width={width}>
    <span hidden={!error}>{error}</span>
    <span hidden={error}>Your note was added to Activity successfully</span>
    <ButtonLink
      text
      hidden={error}
    >
      <Link to={`/machines/${machineId}/activity`}>
        View activity
      </Link>
    </ButtonLink>
  </FooterFlex>
) : (
<FooterFlex width={width}>
  <Button hidden={savedNote} cancel onClick={onCancel}>Cancel</Button>
  <Button
    disabled={loading || error || !note}
    onClick={onSave}
  >
    Save
  </Button>
</FooterFlex>
);

NoteFooter.propTypes = {
  width: PropTypes.string,
  savedNote: PropTypes.bool,
  error: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  note: PropTypes.string.isRequired,
  machineId: PropTypes.number.isRequired
};

NoteFooter.defaultProps = {
  width: '100%',
  savedNote: false,
  error: '',
  loading: false
};

export default styled(NoteFooter)``;
