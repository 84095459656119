import React from 'react';
import styled from 'styled-components';

import { history } from '../../../../common/helpers';
import ActivityItem from '../../../Activity/components/ActivityItem';
import NoActivity from '../../../../_images/dashboard/ps-no_activity.png';


const ImageConatiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
`;

const TextContainer = styled.span`
  margin-top: 2em;
`;

const ActivityItemDiv = styled.div`
  &:hover {
    cursor: pointer;
    zoom: 1;
    filter: alpha(opacity=100);
    opacity: 1 !important;
  }
`;

const ActivityListContainer = styled.div`
  &:hover ${ActivityItemDiv} {
    zoom: 1;
    filter: alpha(opacity=45);
    opacity: 0.45;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -ms-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
  }
`;

class ActivityList extends React.Component {
  redirectToActivity = (e, activity) => {
    if (activity.type === 'task' || activity.type === 'repair') {
      history.push({
        pathname: `/${activity.type}s/${activity.activity_id}`,
        state: { from_app: true }
      });
    } else {
      history.push('/activity', { activity });
    }
  };

  render() {
    const { activities } = this.props;

    return (
      <ActivityListContainer className="dashboard-activity-list">
        {activities && !activities.length && (
          <ImageConatiner>
            <img height="170px" width="216px" alt="No Activity" title="No Activity" src={NoActivity} />
            <TextContainer>No new activity this week</TextContainer>
          </ImageConatiner>
        )}
        {activities && activities.map((activity, index) => (
          <ActivityItemDiv key={index} onClick={e => this.redirectToActivity(e, activity)}>
            <ActivityItem
              key={index}
              activity={activity}
              compact
            />
          </ActivityItemDiv>
        ))}
      </ActivityListContainer>
    );
  }
}

export default ActivityList;
