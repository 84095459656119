import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'common/components/atoms/FlexContainer';
import InputField_T from 'common/components/atoms/InputField';
import Loading from 'common/components/atoms/Loading';
import colors from 'common/styles/colors';

import * as utils from 'common/utils';

import CreateAndDisplayTrendAlarm from './CreateAndDisplayTrendAlarm';

const TagDescription = styled(FlexContainer)`
  font-size: 14px;
  font-weight: 600
  margin-bottom: 10px;
`;

const InputField = styled(InputField_T)`
  margin-right: 1em;
  position: absolute;
  top: 210px;
  left: 10px;
  height: max-content;
`;

const ChartsContainer = styled.div`
  position: relative;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid ${props => props.selected ? props.theme.primaryColor : colors.greyXD};
  margin-bottom: 20px;
`;


const OverallAlarmPreviewItem = ({
  loading,
  selected,
  tag,
  toggleSelectedTagBin,
  unitSystem,
  alarmThresholds,
  selectedBin,
  data,
  ampType,
  feature,
  updateOverallAlarmThresholds,
  overallAlarmSettings,
  yUnit,
  speedUnit
}) => (
  <ChartsContainer selected={selected}>
    <TagDescription>{tag.description}</TagDescription>
    {loading && (
      <Loading postion="relative" center />
    )}
    {!loading && !data && (
      <FlexContainer>No data available for this tag</FlexContainer>
    )}
    {!loading && data && (
      <>
        <InputField
          onClick={() => toggleSelectedTagBin(tag.id)}
          type="checkbox"
          value={selected}
        />
        <FlexContainer style={{ margin: '0px 20px' }} direction="column" flexBasis="100%">
          <CreateAndDisplayTrendAlarm
            chartName={`overall-alarm-item-${tag.id}`}
            warningThreshold={alarmThresholds && alarmThresholds[selectedBin] && alarmThresholds[selectedBin].warning * utils.getMultiplyFactorForAlarm(feature)}
            criticalThreshold={alarmThresholds && alarmThresholds[selectedBin] && alarmThresholds[selectedBin].critical * utils.getMultiplyFactorForAlarm(feature)}
            ampType={ampType}
            feature={feature}
            unitSystem={unitSystem}
            data={data}
            tag={tag}
            updateOverallAlarmThresholds={updateOverallAlarmThresholds}
            overallAlarmSettings={overallAlarmSettings}
            yUnit={yUnit}
            speedUnit={speedUnit}
          />
        </FlexContainer>
      </>
    )}
  </ChartsContainer>
);

OverallAlarmPreviewItem.propTypes = {
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  tag: PropTypes.object.isRequired,
  toggleSelectedTagBin: PropTypes.func.isRequired,
  unitSystem: PropTypes.number.isRequired
};

export default OverallAlarmPreviewItem;
