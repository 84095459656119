import React from 'react';
import styled from 'styled-components';
import { HEADERS } from '../constants/CompareTags.constants';

const ModalHeaderLabel = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #3c3d3a;
  text-align: center;
`;

const SelectAssetsModalHeader = ({ machineName, isEditMode }) => (
  <ModalHeaderLabel>
    {machineName && (
      <>
        {HEADERS.MACHINE_SELECTED}
        <b>{machineName}</b>
      </>
    )}
    {!machineName && !isEditMode && HEADERS.MACHINE_NOT_SELECTED}
    {isEditMode && (
      <>
        {HEADERS.EDIT_MODE}
        <b>CONTINUE</b>
      </>
    )}
  </ModalHeaderLabel>
);

export default SelectAssetsModalHeader;
