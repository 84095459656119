import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ASSET_TYPE } from 'common/charts/constants';
import Button from 'common/components/atoms/Button';
import FlexContainer from 'common/components/atoms/FlexContainer';
import InputField_T from 'common/components/atoms/InputField';
import Loading from 'common/components/atoms/Loading';
import Modal from 'common/components/organisms/Modal';

import AlarmFlagSvg from 'common/images/AlarmFlagSvg';
import colors from 'common/styles/colors';
import { convertSpectrumDataToOrders } from '../../../utils/envelopeUtils';
import Error_T from '../../atoms/Error';
import WaterfallChart from '../../../../Machines/MachineDetails/MachineCharts/components/molecules/WaterfallChart';

import * as envelopeActions from '../../../actions/envelope.actions';

const InputField = styled(InputField_T)`
  width: max-content;
  min-width: 150px;
`;

const DateContainer = styled(FlexContainer)`
  height: 260px;
`;

const DateItemContainer = styled.span`
  display: flex;
  text-align: center;
`;

const Error = styled(Error_T)`
  padding: 0;
  padding-left: 40px;
  margin-top: 10px;
`;

class EnvelopAlarmWaterfallModal extends Component {
  constructor(props) {
    super(props);
    const bin = this.props.activeTabValue;
    const bin_spectrums = props.tag.envelope_spectrums[`bin_${bin}`];

    this.state = {
      hoveredIndex: null,
      excludedIndexes: props.tag.envelope_spectrums[`bin_${bin}`].data.items.reduce((arr, spe, idx) => {
        if (spe.exclude) arr.push(idx + 1);
        return arr;
      }, []),
      voilationIndexes: this.calculateVoilationIndexes(bin_spectrums),
    };
  }

  onHoverInput = (e) => {
    e.stopPropagation();
    this.setState({
      hoveredIndex: e.currentTarget.getAttribute('index')
    });
  };

  onExitInput = () => {
    this.setState({ hoveredIndex: null });
  };

  handleCheckboxClick = (idx) => {
    const { excludedIndexes } = this.state;
    if (excludedIndexes.includes(idx)) this.setState(prevState => ({ excludedIndexes: prevState.excludedIndexes.filter(i => i !== idx) }));
    else this.setState(prevState => ({ excludedIndexes: prevState.excludedIndexes.concat([idx]) }));
  };

  onSave = () => {
    const { excludedIndexes } = this.state;
    const { tag: { envelope_spectrums }, activeTabValue } = this.props;
    const items = envelope_spectrums[`bin_${activeTabValue}`].data.items;
    const excludedTimestamps = excludedIndexes.map(idx => items[idx - 1].timestamp);
    this.props.envelopeActions.excludeSpectrums(this.props.tag.id, activeTabValue, excludedTimestamps);
    this.props.close();
  }

  onCancel = () => {
    this.props.close();
  }

  getFooter = () => {
    const { tag: { envelope_spectrums }, activeTabValue } = this.props;
    const { excludedIndexes } = this.state;
    const bin_spectrums = envelope_spectrums[`bin_${activeTabValue}`];
    return (
      <>
        <Button cancel onClick={this.onCancel}>Cancel</Button>
        <Button
          disabled={
            excludedIndexes.length === bin_spectrums.data.items.length ||
            bin_spectrums.loading
          }
          onClick={this.onSave}
        >
          Save
        </Button>
      </>
    );
  }

  getYLabel = () => {
    const { tag: { envelope_spectrums, type }, activeTabValue } = this.props;
    const bin_spectrums = envelope_spectrums[`bin_${activeTabValue}`];
    if (type === 'vibration') return `Amplitude(${bin_spectrums.data.amp_units},0-Pk)`;
    return `Amplitude(${bin_spectrums.data.amp_units})`;
  }

  getSpectrums = (bin_spectrums) => {
    const { assetType } = this.props;
    let spectrums = bin_spectrums.data.items;
    if (assetType === ASSET_TYPE.VFD) {
      spectrums = convertSpectrumDataToOrders(_.cloneDeep(spectrums));
    }
    return spectrums;
  }

  getXUnit = (bin_spectrums) => {
    const { assetType } = this.props;
    let xUnit = bin_spectrums.data.freq_units;
    if (assetType === ASSET_TYPE.VFD) {
      xUnit = 'Orders';
    }
    return xUnit;
  }

  * getSpectralEnvelopeRange() {
    const { envelope_data } = this.props.spectralEnvelope;

    const startFreq = envelope_data.start_frequencies;
    const endFreq = envelope_data.end_frequencies;
    const amp = envelope_data.amplitudes;

    for (let i = 0; i < amp.length; i++) {
      yield {
        start: startFreq[i],
        end: endFreq[i],
        amp: amp[i]
      };
    }
  }

  calculateVoilationIndexes = (bin_spectrums) => {
    const voilationIndexes = new Set();

    bin_spectrums.data.items.forEach((item, index) => {
      const spectralRange = this.getSpectralEnvelopeRange();
      let current = spectralRange.next().value;

      let i = 0;
      while (i < item.spectrum_data.length && !voilationIndexes.has(index)) {
        const point = item.spectrum_data[i];
        if (point.x > current.end) {
          current = spectralRange.next().value;
        } else if (point.x >= current.start && point.x <= current.end) {
          if (point.y > current.amp) {
            voilationIndexes.add(index);
          }
          i++;
        } else {
          i++;
        }
      }
    });

    return Array.from(voilationIndexes);
  }

  render() {
    const { hierarchyViewPane, tag: { envelope_spectrums, description }, activeTabValue } = this.props;
    const { hoveredIndex, excludedIndexes, voilationIndexes } = this.state;

    const bin_spectrums = envelope_spectrums[`bin_${activeTabValue}`];

    return (
      <Modal
        close={this.props.close}
        footer={this.getFooter}
        title={`${description} > Base Graph > Review Data`}
      >
        { bin_spectrums.loading && (
          <Loading center />
        )}
        <WaterfallChart
          excludedSpectrums={excludedIndexes}
          hoveredIndex={hoveredIndex}
          spectrums={this.getSpectrums(bin_spectrums)}
          hierarchyViewPane={hierarchyViewPane}
          xUnit={this.getXUnit(bin_spectrums)}
          yUnit={bin_spectrums.data.amp_units}
          yLabel={this.getYLabel()}

        />
        {!bin_spectrums.loading &&
          excludedIndexes.length === bin_spectrums.data.items.length && (
          <Error> select at least one spectrum </Error>
        )}
        <DateContainer padding="10px 40px" direction="column" flexWrap="wrap">
          {bin_spectrums.data.items.map((item, idx) => (
            <DateItemContainer
              index={idx + 1}
              key={`date-${idx}`}
              onMouseEnter={this.onHoverInput}
              onMouseLeave={this.onExitInput}
            >
              <InputField
                onClick={() => this.handleCheckboxClick(idx + 1)}
                onMouseEnter={this.onHoverInput}
                onMouseLeave={this.onExitInput}
                title={moment(item.timestamp).format('MMM Do YY, h:mm:ss a')}
                type="checkbox"
                value={!excludedIndexes.includes(idx + 1)}
              />
              <div style={{ width: 10 }} />
              {voilationIndexes.includes(idx) && <AlarmFlagSvg width={12} fill={colors.alarmCritical} />}
            </DateItemContainer>
          ))}
        </DateContainer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  hierarchyViewPane: state.hierarchyViewPane
});

const mapDispatchToProps = dispatch => ({
  envelopeActions: bindActionCreators(envelopeActions, dispatch)
});

EnvelopAlarmWaterfallModal.propTypes = {
  close: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
  activeTabValue: PropTypes.number.isRequired,
  assetType: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(EnvelopAlarmWaterfallModal);
