import styled from 'styled-components';

const MsgContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  padding: 1em;
  height: max-content;
  margin: 1em;
  border: 2px dotted ${props => props.theme.colors.lightGray};
  border-radius: ${props => props.theme.utils.borderRadius};
  span {
    color: ${props => props.theme.colors.lightGray};
    font-weight: bold;
    height: max-content;
    width: max-content;
  }
`;

export default styled(MsgContainer)``;
