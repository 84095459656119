import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import styled from 'styled-components';

import List from 'common/components/molecules/List';
import Modal from 'common/components/organisms/Modal';
import FlexContainer from 'common/components/atoms/FlexContainer';
import InputField from 'common/components/atoms/InputField';
import Button from 'common/components/atoms/Button';
import { Search } from 'common/images/FaIcons';
import Checkbox from 'common/components/atoms/Checkbox';
import LoadingSvg from 'common/components/atoms/Loading';
import AssignSensorListItem from './AssignSensorListItem';
import * as sensorsActions from '../actions/sensors.actions';
import { sensorsConstants } from '../actions/sensors.constants';

const ButtonContainer = styled.div`
  margin-left: 10px;
  margin-top: 15px;
`;

const ListContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  position: relative;
  overflow: auto;
  border: 1px solid #ddd;
  margin-top: 0.5em;
  border-radius: 10px;
  height: 32.5em;
`;

const SensorContainer = styled(FlexContainer).attrs({
  flexWrap: 'wrap'
})`
  width: 100%;
  overflow: auto;
  padding: 20px;
  border: 1px solid #E5E8E1;
  border-radius: 8px;
  height: 30em;
  & > * {
    font-size: 14px;
    flex-basis: 50%;
    margin-bottom: 20px;
    font-weight: 400;
  }
`;

const InputFieldSearch = styled(InputField)`
  input {
    padding-left: 0;
  }
`;

const CheckboxContainer = styled(FlexContainer).attrs({ alignItems: 'center' })`
  &:hover {
    cursor: pointer;
  }
  span {
    font-family: 'Petasense Open Sans';
    color: ${props => props.selected ? 'black' : '#999B95'};
    font-size: 14px;
    font-weight: 600;
    margin-left: 0.5em;
  }
`;

const AssignSensorModal = (props) => {
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [sensorsToAssign, setSensorsToAssign] = useState([]);
  const [sensors, updateSensors] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [listViewOpen, setListViewOpen] = useState(false);
  const [channelOptions, setChannelOptions] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);

  const assignSensorsLoading = props.loadingReducer[sensorsConstants.ASSIGN_SENSORS_TO_TX];

  const onChannelSelect = (sensorId, channelId, orientation, prevChannelId, txSerialNumber) => {
    if (channelId === prevChannelId) return;
    const newSensorsToAssign = sensorsToAssign.map((sensor) => {
      if (sensor.id === sensorId && sensor.orientation === orientation) {
        sensor.channel_id = channelId;
        sensor.tx_serial_number = txSerialNumber;
      }
      return sensor;
    });
    let newSelectedChannels = [...selectedChannels];
    if (prevChannelId) newSelectedChannels = newSelectedChannels.filter(id => id !== prevChannelId);
    if (channelId) newSelectedChannels.push(channelId);
    setSelectedChannels(newSelectedChannels);
    setSensorsToAssign(newSensorsToAssign);
  };

  const listProps = {
    columnSizes: [40, 60],
    headers: [
      {
        name: 'sensor',
        label: 'Sensor',
        noSorter: true
      },
      {
        name: 'channel',
        label: 'Channel',
        noSorter: true
      },
    ],
    maxHeightAfterScroll: 'calc(100vh - 100px)',
    items: { object: sensorsToAssign },
    ItemComponent: AssignSensorListItem,
    emptyMsg: 'No Sensors Selected',
    channelOptions,
    selectedChannels,
    onChannelSelect
  };

  useEffect(() => {
    props.sensorsActions.getUnassignedSensors().then(
      (items) => {
        updateSensors(items);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    const newChannelOptions = props.txs.map((tx) => {
      const options = { text: tx.serial_number, children: [] };
      tx.ports.forEach((port) => {
        const unusedChannels = port.unused_channels || [];
        unusedChannels.forEach((channel) => {
          const option = {
            text: `Port-${port.port_position} Channel-${channel.position}`,
            value: channel.channel_id,
            channel_position: channel.position,
            port_position: port.port_position
          };
          options.children.push(option);
        });
      });
      return options;
    });
    setChannelOptions(newChannelOptions);
  }, [props.txs.length]);

  const selectSensor = (sensor) => {
    let newSelectedSensors = [];
    const checked = selectedSensors.some(currSensor => currSensor.id === sensor.id);
    if (checked) newSelectedSensors = selectedSensors.filter(currSensor => currSensor.id !== sensor.id);
    else newSelectedSensors = selectedSensors.concat([{
      id: sensor.id,
      serial_number: sensor.serial_number,
      no_of_channels: sensor.no_of_channels,
      sensor_type: sensor.sensor_type,
      sensors: sensor.sensors,
      is_vsx: sensor.is_vsx,
      orientation: null,
      channel_id: null,
      tx_serial_number: null
    }]);
    setSelectedSensors(newSelectedSensors);
  };

  const onClickContinue = () => {
    let expandedSensors = [];
    selectedSensors.forEach((sensor) => {
      if (sensor.is_vsx) {
        if (_.isArray(sensor.sensors)) expandedSensors = expandedSensors.concat(sensor.sensors.map(sensor => ({
          id: sensor.id,
          serial_number: sensor.serial_number,
          no_of_channels: sensor.no_of_channels,
          sensor_type: sensor.sensor_type,
          sensors: sensor.sensors,
          is_vsx: sensor.is_vsx,
          orientation: null,
          channel_id: null,
          tx_serial_number: null
        })));
      } else expandedSensors.push(_.clone(sensor));
    });
    const newSensorsToAssign = [];
    expandedSensors.forEach((sensor) => {
      if (sensor.no_of_channels > 1) {
        for (let i = 0; i < sensor.no_of_channels; i++) {
          const currSensor = _.clone(sensor);
          currSensor.orientation = i;
          newSensorsToAssign.push(currSensor);
        }
      } else newSensorsToAssign.push(sensor);
    });
    setSensorsToAssign(newSensorsToAssign);
    setListViewOpen(true);
  };

  const onClickBack = () => {
    setSensorsToAssign([]);
    setSelectedChannels([]);
    setListViewOpen(false);
  };

  const onClickDone = () => {
    const uniqueTxs = new Set();
    const formattedSensorsToAssign = sensorsToAssign
      .filter(sensor => !_.isNil(sensor.channel_id))
      .map((sensor) => {
        uniqueTxs.add(sensor.tx_serial_number);
        return {
          sensor_id: sensor.id,
          channel_id: sensor.channel_id,
          orientation: sensor.orientation
        };
      });
    props.sensorsActions.assignSensorsToTx(formattedSensorsToAssign).then(
      () => {
        uniqueTxs.forEach((txSerialNumber) => {
          if (txSerialNumber) props.sensorsActions.getSensorDetails(txSerialNumber);
        });
        props.close();
        props.onAssignSensor();
      }
    );
  };

  const onSearch = (e) => {
    setSearchKey(e.target.value);
    const filtered = props.unassignedSensors.list.filter(sensor => sensor.serial_number.toLowerCase().includes(e.target.value.toLowerCase()));
    updateSensors(filtered);
  };

  return (
    <Modal
      title="Associate Sensors"
      close={props.close}
      width="50%"
      padding="0"
    >
      {listViewOpen && (
        <>
          <ListContainer>
            {assignSensorsLoading && (
              <div style={{ margin: 'auto' }}>
                <LoadingSvg />
              </div>
            )}
            {!assignSensorsLoading && (
              <List
                {...listProps}
              />
            )}
          </ListContainer>
          <FlexContainer marginleft="auto">
            <ButtonContainer>
              <Button
                disabled={assignSensorsLoading}
                onClick={onClickBack}
                secondary
              >
                BACK
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button
                disabled={assignSensorsLoading || !_.some(sensorsToAssign, sensor => !_.isNil(sensor.channel_id))}
                onClick={onClickDone}
              >
                DONE
              </Button>
            </ButtonContainer>
          </FlexContainer>
        </>
      )}
      {!listViewOpen && (
        <>
          <InputFieldSearch
            type="text"
            prefix={<Search />}
            prefixSide="left"
            value={searchKey}
            onChange={onSearch}
          />

          <SensorContainer>
            {(props.unassignedSensors.loading) && (
              <div style={{ margin: 'auto' }}>
                <LoadingSvg />
              </div>
            )}
            {sensors.map((sensor, idx) => (
              <CheckboxContainer
                key={`checkbox-container-${idx}`}
                alignItems="center"
                onClick={() => selectSensor(sensor)}
                selected={selectedSensors.some(currSensor => currSensor.id === sensor.id)}
              >
                <Checkbox value={selectedSensors.some(currSensor => currSensor.id === sensor.id)} />
                <span>{sensor.serial_number}</span>
              </CheckboxContainer>
            ))}
          </SensorContainer>
          <FlexContainer marginleft="auto">
            <ButtonContainer>
              <Button
                disabled={_.isEmpty(selectedSensors)}
                onClick={onClickContinue}
              >
                CONTINUE
              </Button>
            </ButtonContainer>
          </FlexContainer>
        </>
      )}
    </Modal>
  );
};

AssignSensorModal.propTypes = {
  close: PropTypes.func.isRequired,
  onAssignSensor: PropTypes.func,
  txs: PropTypes.array
};

AssignSensorModal.defaultProps = {
  onAssignSensor: () => {},
  txs: []
};

const mapStateToProps = state => ({
  unassignedSensors: state.sensors.unassignedSensors,
  loadingReducer: state.loadingReducer
});

const mapDispatchToProps = dispatch => ({
  sensorsActions: bindActionCreators(sensorsActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignSensorModal);
