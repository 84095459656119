import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DonutChart from 'common/charts/GoogleCharts/DonutChart';
import { FlexContainer } from 'common/components/atoms';
import LoadingSvg from 'common/images/LoadingSvg';
import { accountAnalyticsMetadata } from '../constants/accountAnalytics.constants';

const ChartContainer = styled.div`
  background-color: white;
  height: 220px;
  border-radius: 4px;
`;

const AccountAnalyticsWidget = (props) => {
  const accountAnalyticsState = props.accountAnalytics;
  const data = accountAnalyticsState[props.analytics_type];
  const metadata = accountAnalyticsMetadata[props.analytics_type];

  if (!data || accountAnalyticsState.loading) {
    return (
      <>
        <ChartContainer>
          <FlexContainer alignItems="center" justifyContent="center" height="100%">
            <LoadingSvg />
          </FlexContainer>
        </ChartContainer>
      </>
    );
  }
  if (data.error) {
    return (
    <>
      <div>Error loading Account Analytics</div>
    </>
    );
  }
  return (
    <ChartContainer>
      <DonutChart
        title={metadata.title}
        data={data}
        resourceName="Devices"
        colors={metadata.colors}
        formatData
        {...props}
      />
    </ChartContainer>
  );
};

const mapStateToProps = state => ({
  adminDashboard: state.adminDashboard,
  accountAnalytics: state.accountAnalytics,
});

AccountAnalyticsWidget.propTypes = {
  first: PropTypes.bool,
  titlePaddingBottom: PropTypes.string,
  analytics_type: PropTypes.string,
  showTitle: PropTypes.bool,
  filters: PropTypes.array
};

AccountAnalyticsWidget.defaultProps = {
  first: false,
  titlePaddingBottom: null,
  analytics_type: null,
  showTitle: true,
  filters: []
};

export default connect(mapStateToProps, null)(AccountAnalyticsWidget);
