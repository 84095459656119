import _ from 'lodash';
import { toastr } from 'react-redux-toastr';

import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse } from 'common/helpers';
import { formatChartData, convertTimestringsToDateObjects } from 'common/utils';
import { alertActions } from 'alert';

import * as hierarchyUtils from '../../../../AssetHierarchy/utils/assetHierarchyUtils';
import { updateComponents, updateMachineInfo } from '../../MachineInfo/actions/machineInfo.actions';
import MachineMLTypes from './machineML.types';


export const getMachineTrainHealthCondition = (machine_id) => {
  const request = () => ({ type: MachineMLTypes.GET_MACHINE_HEALTH_CONDITION_REQUEST });
  const success = prediction => ({ type: MachineMLTypes.GET_MACHINE_HEALTH_CONDITION_SUCCESS, prediction });
  const failure = error => ({ type: MachineMLTypes.GET_MACHINE_HEALTH_CONDITION_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_TRAIN_HEALTH_CONDITION(machine_id)))
      .then(
        (res) => {
          dispatch(success(res));
        },
        (err) => {
          dispatch(failure(err));
        }
      );
  };
};

export const getMachineTrainHealthTrend = (machineId, params) => {
  const request = () => ({ type: MachineMLTypes.GET_MACHINE_TRAIN_HEALTH_TREND_REQUEST });
  const success = health => ({ type: MachineMLTypes.GET_MACHINE_TRAIN_HEALTH_TREND_SUCCESS, health, machineId });
  const failure = error => ({ type: MachineMLTypes.GET_MACHINE_TRAIN_HEALTH_TREND_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    dispatch(alertActions.clear());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_TRAIN_HEALTH_TREND(machineId), { params }))
      .then(
        (res) => {
          const machine =
            formatChartData(
              res.machine_health_data.health_score,
              convertTimestringsToDateObjects(res.machine_health_data.timestamp)
            );
          const components = res.components_health_data.map(component => ({
            ...component,
            health_trend:
              formatChartData(
                component.health_trend.health_score,
                convertTimestringsToDateObjects(component.health_trend.timestamp)
              )
          }));
          const health = {
            machine,
            components
          };
          dispatch(success(health));
          return health;
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      );
  };
};

export const getMachineHealthTrainCondition = (machine_id) => {
  const request = () => ({ type: MachineMLTypes.GET_MACHINE_TRAIN_HEALTH_REQUEST });
  const success = result => ({ type: MachineMLTypes.GET_MACHINE_TRAIN_HEALTH_SUCCESS, result, machine_id });
  const failure = error => ({ type: MachineMLTypes.GET_MACHINE_TRAIN_HEALTH_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_TRAIN_HEALTH_CONDITION(machine_id))).then(
      (res) => {
        const mds = res.machine_defect_options;
        res.machine_defect_options = _.groupBy(mds, 'type');
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error.message));
      },
    );
  };
};

export const getMachineHealthTrend = (machineId, params) => {
  const request = () => ({ type: MachineMLTypes.GET_MACHINE_HEALTH_TREND_REQUEST });
  const success = machine_health => ({ type: MachineMLTypes.GET_MACHINE_HEALTH_TREND_SUCCESS, machine_health });
  const failure = error => ({ type: MachineMLTypes.GET_MACHINE_HEALTH_TREND_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_HEALTH_TREND(machineId), { params }))
      .then(
        (res) => {
          const machine_health =
            formatChartData(
              res.health_trend.health_score,
              convertTimestringsToDateObjects(res.health_trend.timestamp)
            );
          dispatch(success(machine_health));
          return machine_health;
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      );
  };
};

export const getComponentHealthTrend = (componentId, params) => {
  const request = () => ({ type: MachineMLTypes.GET_COMPONENT_HEALTH_TREND_REQUEST, componentId });
  const success = health => ({ type: MachineMLTypes.GET_COMPONENT_HEALTH_TREND_SUCCESS, health, componentId });
  const failure = error => ({ type: MachineMLTypes.GET_COMPONENT_HEALTH_TREND_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_COMPONENT_HEALTH_TREND(componentId), { params }))
      .then(
        (res) => {
          const health =
            formatChartData(
              res.health_trend.health_score,
              convertTimestringsToDateObjects(res.health_trend.timestamp),
              null,
              { anomaly_result: res.health_trend.anomaly_result, anomaly_score: res.health_trend.anomaly_score }
            );
          dispatch(success(health));
        },
        (error) => {
          dispatch(failure(error.message));
        }
      );
  };
};

export const getMachineTrend = (machineId) => {
  const request = () => ({ type: MachineMLTypes.GET_MACHINE_TREND_REQUEST });
  const success = components => ({ type: MachineMLTypes.GET_MACHINE_TREND_SUCCESS, components, machineId });
  const failure = error => ({ type: MachineMLTypes.GET_MACHINE_TREND_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    dispatch(alertActions.clear());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_TREND(machineId)))
      .then(
        (res) => {
          const components = res.machine_trend.map(component => ({
            ...component,
            tags_data: component.tags_data.map(tag => ({
              ...tag,
              trend: formatChartData(
                tag.trend.data,
                convertTimestringsToDateObjects(tag.trend.time),
                null,
                { measurement_id: tag.trend.measurement_id }
              )
            }))
          }));
          dispatch(success(components));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      );
  };
};

export const getComponentTrendsForMachineId = machineId => (dispatch, getState) => {
  const hierarchy = getState().assetHierarchyReducer.assetInfo.hierarchy;
  const componentIds = hierarchyUtils.getComponentsFromMachineId(hierarchy, parseInt(machineId, 10)).map(c => c.id);

  dispatch({ type: MachineMLTypes.SET_COMPONENTS_IN_BASELINES, componentIds });
  componentIds.map(id => dispatch(getComponentTrend(id)));
};

export const getComponentTrend = (componentId) => {
  const request = () => ({ type: MachineMLTypes.GET_COMPONENT_TREND_REQUEST, componentId });
  const success = component => ({ type: MachineMLTypes.GET_COMPONENT_TREND_SUCCESS, component, componentId });
  const failure = error => ({ type: MachineMLTypes.GET_COMPONENT_TREND_FAILURE, componentId, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_COMPONENT_TREND(componentId)))
      .then(
        (res) => {
          const component = {
            ...res,
            tags_data: res.tags_data.map(tag => ({
              ...tag,
              trend: formatChartData(
                tag.trend.data,
                convertTimestringsToDateObjects(tag.trend.time),
                null,
                { measurement_id: tag.trend.measurement_id }
              )
            }))
          };
          dispatch(success(component));
        },
        (error) => {
          const errorMessage = error.message || error;
          dispatch(failure(errorMessage));
          toastr.error(errorMessage);
        }
      );
  };
};

export const getFeatureTrend = (
  componentId,
  ampType = null,
  feature = null,
  tagType = null,
  speedBin = null,
  baselineInfo = null
) => {
  const request = () => ({ type: MachineMLTypes.GET_FEATURE_TREND_REQUEST });
  const success = () => ({ type: MachineMLTypes.GET_FEATURE_TREND_SUCCESS });
  const failure = () => ({ type: MachineMLTypes.GET_FEATURE_TREND_FAILURE });

  const params = {};
  if (ampType) params.amp_type = ampType;
  if (feature) params.feature = feature;
  if (tagType) params.tag_type = tagType;
  if (speedBin) params.bin_nos = [speedBin];
  if (baselineInfo && baselineInfo.start_time && baselineInfo.end_time) {
    params.start_time = baselineInfo.start_time;
    params.end_time = baselineInfo.end_time;
  }

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_COMPONENT_TREND(componentId), { params }))
      .then(
        (res) => {
          dispatch(success());
          const tagsData = res.tags_data.map(tag => ({
            ...tag,
            trend: formatChartData(
              tag.trend.data,
              convertTimestringsToDateObjects(tag.trend.time),
              null,
              { measurement_id: tag.trend.measurement_id }
            )
          }));
          return tagsData;
        },
        (error) => {
          const errorMessage = error.message || error;
          dispatch(failure(errorMessage));
          toastr.error(errorMessage);
        }
      );
  };
};

export const postComponentBaseline = (componentId, componentName, tagIds, baseline_info, re_extract_features_with_rms) => {
  const request = () => ({ type: MachineMLTypes.POST_COMPONENT_BASELINE_REQUEST, tagIds });
  const success = (message, error_tag_ids) => ({ type: MachineMLTypes.POST_COMPONENT_BASELINE_SUCCESS, message, tagIds, baseline_info, error_tag_ids });
  const failure = error => ({ type: MachineMLTypes.POST_COMPONENT_BASELINE_FAILURE, tagIds, error });

  return (dispatch) => {
    dispatch(request());
    dispatch(alertActions.clear());
    return handleResponse(axiosInstance.post(ENDPOINT.COMPONENT_BASELINE(componentId), { ...baseline_info, tag_ids: tagIds, re_extract_features_with_rms }))
      .then(
        (res) => {
          dispatch(success(res.message, res.error_tag_ids));
          toastr.success(`${componentName}:`, res.message);
          return res;
        },
        (error) => {
          dispatch(failure(error));
          toastr.error(`${componentName}:`, error.message);
        }
      );
  };
};

export const validateComponentBaseline = (componentId, tagIds, baseline_info, ampType = null, feature = null, tagType = null, speedBin = null) => {
  const request = () => ({ type: MachineMLTypes.VALIDATE_COMPONENT_BASELINE_REQUEST });
  const success = (result, key) => ({ type: MachineMLTypes.VALIDATE_COMPONENT_BASELINE_SUCCESS, result, key });
  const failure = () => ({ type: MachineMLTypes.VALIDATE_COMPONENT_BASELINE_FAILURE });

  const params = { ...baseline_info, tag_ids: tagIds };
  if (ampType) params.amp_type = ampType;
  if (feature) params.feature = feature;
  if (speedBin) params.bin_nos = [speedBin];

  let key = null;
  if (tagType) {
    key = `${tagType}_${ampType}_${feature}`;
  }

  if (speedBin) {
    key = `${speedBin}_${feature}`;
  }

  return (dispatch) => {
    dispatch(request());
    dispatch(alertActions.clear());

    return handleResponse(axiosInstance.post(ENDPOINT.VALIDATE_COMPONENT_BASELINE(componentId), params))
      .then(
        (res) => {
          dispatch(success(res.validation_result, key));
        },
        (error) => {
          dispatch(failure());
        }
      );
  };
};

export const clearBaselineValidation = (tagIds) => {
  const clearValidation = () => ({ type: MachineMLTypes.CLEAR_BASELINE_VALIDATION, tagIds });
  return dispatch => dispatch(clearValidation());
};

export const deleteComponentBaseline = (componentId, tagIds = []) => {
  const request = () => ({ type: MachineMLTypes.DELETE_COMPONENT_BASELINE_REQUEST });
  const success = () => ({ type: MachineMLTypes.DELETE_COMPONENT_BASELINE_SUCCESS, tagIds });
  const failure = error => ({ type: MachineMLTypes.DELETE_COMPONENT_BASELINE_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.delete(ENDPOINT.COMPONENT_BASELINE(componentId), { data: { tag_ids: tagIds } }))
      .then(
        (res) => {
          dispatch(success(res));
          toastr.success(res.message);
        },
        (error) => {
          dispatch(failure(error));
          toastr.error(error.message);
        }
      );
  };
};

export const recomputeMachineHealthScore = (machineId, params = {}) => dispatch => handleResponse(axiosInstance.post(ENDPOINT.MACHINE_HEALTH_SCORE_RECOMPUTE(machineId), params))
  .then(
    (res) => {
      dispatch(getHealthScoreRecomputeTaskStatus(res.task_id, machineId));
    },
    (error) => {
      toastr.error(error.message);
      throw error;
    }
  );

export const getHealthScoreRecomputeTaskStatus = (taskId, machineId) => {
  const success = (status, completed, total) => ({ type: MachineMLTypes.MACHINE_HEALTH_SCORE_RECOMPUTE_TASK, status, completed, total });
  const done = () => ({ type: MachineMLTypes.MACHINE_HEALTH_SCORE_RECOMPUTE_TASK_DONE });
  const clear = () => ({ type: MachineMLTypes.MACHINE_HEALTH_SCORE_RECOMPUTE_TASK_CLEAR });
  return (dispatch, getState) => handleResponse(axiosInstance.get(ENDPOINT.MACHINE_HEALTH_SCORE_RECOMPUTE_TASK_STATUS(taskId)))
    .then(
      (result) => {
        const stateMachineId = getState().machineDetails.details.object.id;
        if (result.state === 'FAILURE') {
          dispatch(done());
          toastr.error(result.status);
        } else if (result.state !== 'SUCCESS') {
          // temeporary solution to make sure we don't show status of one machine on another
          if (parseInt(machineId, 10) !== stateMachineId) {
            dispatch(clear());
          } else {
            dispatch(success(result.status, result.completed_measurements, result.total_measurements));
          }
          setTimeout(() => dispatch(getHealthScoreRecomputeTaskStatus(taskId, machineId)), 1500);
        } else {
          dispatch(done());
          dispatch(getMachineTrainHealthTrend(machineId));
          dispatch(getMachineHealthTrainCondition(machineId));
        }
      }
    );
};

export const getAllTagChartDataForAComponent = (tagIds, componentId, params = {}) =>
  (dispatch) => {
    const apis = tagIds.map(tagId =>
      dispatch(getChartsTrendData(tagId, componentId, params)));
    Promise.all(apis).then(
      () => dispatch({ type: MachineMLTypes.GET_CHARTS_TAG_TREND_ALL_SUCCESS, componentId })
    );
  };


export const getChartsTrendData = (tagId, componentId, params = {}) => {
  const request = () => ({ type: MachineMLTypes.GET_CHARTS_TAG_TREND_REQUEST, tagId, componentId });
  const success = trend => ({ type: MachineMLTypes.GET_CHARTS_TAG_TREND_SUCCESS, tagId, componentId, trend });
  const failure = error => ({ type: MachineMLTypes.GET_CHARTS_TAG_TREND_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_TREND(tagId), { params }))
      .then(
        (res) => {
          const location_data = formatChartData(
            res.trend_data.data,
            convertTimestringsToDateObjects(res.trend_data.time),
            null,
            { measurement_id: res.trend_data.measurement_id }
          );
          dispatch(success(location_data));
          return res;
        },
        (error) => {
          dispatch(failure(error.message));
          // throw error;
        });
  };
};

export const getComponentHealthScoreDetails = (componentId, timestamp) => {
  const request = () => ({ type: MachineMLTypes.GET_COMPONENT_HEALTH_SCORE_DETAILS_REQUEST });
  const success = details => ({ type: MachineMLTypes.GET_COMPONENT_HEALTH_SCORE_DETAILS_SUCCESS, details });
  const failure = error => ({ type: MachineMLTypes.GET_COMPONENT_HEALTH_SCORE_DETAILS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_COMPONENT_HEALTH_SCORE_DETAILS(componentId), {params: {timestamp } }))
      .then(
        (res) => {
          dispatch(success(res));
          return res;
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };
};

export const updateHealthScoreParameters = (machineId, components) => {
  const success = components => ({ type: MachineMLTypes.UPDATE_COMPONENTS_HEALTH_SCORE_PARAMETERS_SUCCESS, components });
  const failure = error => ({ type: MachineMLTypes.UPDATE_COMPONENTS_HEALTH_SCORE_PARAMETERS_FAILURE, error });

  return (dispatch) => {
    dispatch(updateComponents(machineId, components)).then(
      () => {
        toastr.success('AHS Settings updated sucessfully');
        dispatch(success(components));
      },
      (error) => {
        toastr.error(error);
        dispatch(failure(error));
      }
    );
  };
};


export const updateAHSFeedbackState = (feedbackId) => {
  const success = () => ({ type: MachineMLTypes.UPDATE_AHS_FEEDBACK_STATE_SUCCESS });
  const failure = error => ({ type: MachineMLTypes.UPDATE_AHS_FEEDBACK_STATE_FAILURE, error });

  return dispatch => handleResponse(axiosInstance.patch(ENDPOINT.AHS_FEEDBACK(feedbackId), { state: null })).then(
    () => {
      dispatch(success());
    },
    (error) => {
      dispatch(failure(error.message));
    }
  );
};

export const updateAHSEnabled = (machineId, ahs_enabled) => {
  const success = ahs_enabled => ({ type: MachineMLTypes.UPDATE_AHS_ENABLED_SUCCESS, ahs_enabled });
  const failure = error => ({ type: MachineMLTypes.UPDATE_AHS_ENABLED_FAILURE, error });

  return (dispatch) => {
    dispatch(updateMachineInfo(machineId, { ahs_enabled }, true)).then(
      () => {
        toastr.success(`AHS ${ahs_enabled ? 'enabled' : 'disabled'} for the asset sucessfully`);
        dispatch(success(ahs_enabled));
      },
      (error) => {
        toastr.error(error);
        dispatch(failure(error));
      }
    );
  };
};

export const eraseAHSScore = (machineId) => {
  const request = () => ({ type: MachineMLTypes.ERASE_AHS_SCORES_REQUEST });
  const success = () => ({ type: MachineMLTypes.ERASE_AHS_SCORES_SUCCESS });
  const failure = () => ({ type: MachineMLTypes.ERASE_AHS_SCORES_FAILURE });

  return (dispatch) => {
    dispatch(request);
    return handleResponse(axiosInstance.delete(ENDPOINT.ERASE_AHS_SCORES(machineId)))
      .then(
        () => {
          toastr.success('AHS erase request initiated successfully');
          dispatch(success);
        },
        () => {
          toastr.error('Failed to initiate the AHS erase request');
          dispatch(failure);
        }
      );
  };
};

export const getAHSFeaturesInfo = (machine_id) => {
  const request = () => ({ type: MachineMLTypes.GET_AHS_FEATURES_INFO_REQUEST });
  const success = features_info => ({ type: MachineMLTypes.GET_AHS_FEATURES_INFO_SUCCESS, features_info });
  const failure = error => ({ type: MachineMLTypes.GET_AHS_FEATURES_INFO_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_AHS_SELECTED_FEATURES_INFO(machine_id)))
      .then(
        (res) => {
          dispatch(success(res));
          return res;
        },
        (err) => {
          dispatch(failure(err));
        }
      );
  };
};

export const updateAHSFeaturesInfo = (params, machine_id) => {
  const request = () => ({ type: MachineMLTypes.UPDATE_AHS_FEATURES_INFO_REQUEST });
  const success = () => ({ type: MachineMLTypes.UPDATE_AHS_FEATURES_INFO_SUCCESS });
  const failure = () => ({ type: MachineMLTypes.UPDATE_AHS_FEATURES_INFO_FAILURE });
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.post(ENDPOINT.UPDATE_AHS_SELECTED_FEATURES_INFO(machine_id), params))
      .then(
        (res) => {
          dispatch(success());
          dispatch(getAHSFeaturesInfo(machine_id));
          toastr.success(res.message);
        },
        (err) => {
          dispatch(failure(err));
          toastr.error(err.message);
        }
      );
  };
};
