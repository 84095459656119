import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse } from 'common/helpers';
import * as _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { formatError } from '../../../common/utils';
import TagTemplateConstants from '../constants/tagTemplates.constants';


export const getTagTemplateMetadata = () => {
  const request = () => ({ type: TagTemplateConstants.GET_TAG_TEMPLATE_METADATA_REQUEST });
  const success = data => ({ type: TagTemplateConstants.GET_TAG_TEMPLATE_METADATA_SUCCESS, data });
  const failure = error => ({ type: TagTemplateConstants.GET_TAG_TEMPLATE_METADATA_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.TAG_TEMPLATE_METADATA)).then(
      (res) => {
        dispatch(success(res));
        return res;
      },
      (error) => {
        dispatch(failure(error.message));
        throw error.message;
      }
    );
  };
};

export const updateTagTemplate = (params) => {
  const id = params.id;
  const request = () => ({ type: TagTemplateConstants.UPDATE_TAG_TEMPLATE_DETAILS_REQUEST });
  const success = data => ({ type: TagTemplateConstants.UPDATE_TAG_TEMPLATE_DETAILS_SUCCESS, data, id });
  const failure = error => ({ type: TagTemplateConstants.UPDATE_TAG_TEMPLATE_DETAILS_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    const { details } = getState().tagTemplates.templates;
    const changedDetails = {};
    Object.entries(params).forEach(([key, value]) => {
      if (!_.isEqual(details[key], value)) changedDetails[key] = value;
    });
    return handleResponse(axiosInstance.patch(ENDPOINT.TAG_TEMPLATE(id), { ...changedDetails })).then(
      (res) => {
        dispatch(success(changedDetails));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        throw error;
      }
    );
  };
};

export const createTagTemplate = (params) => {
  const request = () => ({ type: TagTemplateConstants.SAVE_TAG_TEMPLATE_DETAILS_REQUEST });
  const success = data => ({ type: TagTemplateConstants.SAVE_TAG_TEMPLATE_DETAILS_SUCCESS, data });
  const failure = error => ({ type: TagTemplateConstants.SAVE_TAG_TEMPLATE_DETAILS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.post(ENDPOINT.TAG_TEMPLATE(), { ...params })).then(
      (res) => {
        dispatch(success({ ...params, id: res.id }));
        return res;
      },
      (error) => {
        toastr.error(formatError(error.message));
        dispatch(failure(error));
        throw error;
      }
    );
  };
};


export const getTagTemplateDetails = (tag_template_id) => {
  const request = () => ({ type: TagTemplateConstants.GET_TAG_TEMPLATE_DETAILS_REQUEST });
  const success = data => ({ type: TagTemplateConstants.GET_TAG_TEMPLATE_DETAILS_SUCCESS, data });
  const failure = error => ({ type: TagTemplateConstants.GET_TAG_TEMPLATE_DETAILS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.TAG_TEMPLATE(tag_template_id))).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error.message));
      }
    );
  };
};

export const deleteTagTemplate = (id) => {
  const request = () => ({ type: TagTemplateConstants.DELETE_TAG_TEMPLATE_REQUEST });
  const success = data => ({ type: TagTemplateConstants.DELETE_TAG_TEMPLATE_SUCCESS, data, id });
  const failure = error => ({ type: TagTemplateConstants.DELETE_TAG_TEMPLATE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.delete(ENDPOINT.TAG_TEMPLATE(id))).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        toastr.error(formatError(error.message));
        dispatch(failure(error.message));
      }
    );
  };
};

export const selectTagTemplate = id => (dispatch) => {
  dispatch({ type: TagTemplateConstants.SELECT_TAG_TEMPLATE, id });
};

export const addNewTagTemplateToList = () => (dispatch) => {
  dispatch({ type: TagTemplateConstants.ADD_NEW_TAG_TEMPLATE_TO_LIST });
  dispatch({ type: TagTemplateConstants.SELECT_TAG_TEMPLATE, id: -1 });
};

export const addTagTemplateToList = data => (dispatch) => {
  dispatch({ type: TagTemplateConstants.ADD_TAG_TEMPLATE_TO_LIST, data });
};

export const removeTagTemplateFromList = id => dispatch =>
  dispatch({ type: TagTemplateConstants.REMOVE_TAG_TEMPLATE_FROM_LIST, id });

export const initTagTemplatePaginater = () => (dispatch) => {
  dispatch({ type: TagTemplateConstants.CLEAR_TAG_TEMPLATES });
  dispatch({ type: TagTemplateConstants.CLEAR_TAG_TEMPLATE_SEARCH_PARAMS });
  dispatch(setTagTemplatePaginater(1));
};

export const setTagTemplatePaginater = page => (dispatch) => {
  dispatch({ type: TagTemplateConstants.SET_TAG_TEMPLATE_PAGINATER, page });
  dispatch(getTagTemplates());
};

export const increaseTagTemplatePaginater = () => (dispatch, getState) => {
  const tagTemplates = getState().tagTemplates;
  const { paginater } = tagTemplates;
  dispatch(setTagTemplatePaginater(paginater.page + 1));
};

export const setTagTemplateSearchParams = searchParams => (dispatch) => {
  dispatch({
    type: TagTemplateConstants.SET_TAG_TEMPLATE_SEARCH_PARAMS,
    searchParams
  });
  dispatch({
    type: TagTemplateConstants.SET_TAG_TEMPLATE_PAGINATER,
    page: 1
  });
  dispatch({
    type: TagTemplateConstants.CLEAR_TAG_TEMPLATES
  });
  dispatch(getTagTemplates());
};

export const setTagTemplateSorter = sorter => (dispatch) => {
  dispatch({ type: TagTemplateConstants.SET_TAG_TEMPLATE_SORTER, sorter });
  dispatch({ type: TagTemplateConstants.CLEAR_TAG_TEMPLATES });
  dispatch({ type: TagTemplateConstants.SET_TAG_TEMPLATE_PAGINATER, page: 1 });

  dispatch(getTagTemplates());
};

const getTagTemplates = () => {
  const request = () => ({
    type: TagTemplateConstants.GET_TAG_TEMPLATE_LIST_REQUEST
  });
  const success = (data, page, total_count) => ({
    type: TagTemplateConstants.GET_TAG_TEMPLATE_LIST_SUCCESS,
    data,
    page,
    total_count
  });
  const failure = error => ({
    type: TagTemplateConstants.GET_TAG_TEMPLATE_LIST_FAILURE,
    error
  });

  return (dispatch, getState) => {
    dispatch(request());
    const tagTemplates = getState().tagTemplates;
    const { sorter, paginater, searchParams } = tagTemplates;

    const order_by = { field: 'name', direction: 'asc' };
    if (sorter && sorter.sorter && sorter.sorter.order) {
      order_by.field = sorter.sorter.name;
      order_by.direction = sorter.sorter.order;
    }

    const params = {
      page: paginater.page,
      per_page: TagTemplateConstants.NUMBER_OF_TAG_TEMPLATES_PER_PAGE,
      order_by,
      search_key: searchParams.searchKey,
      resource_version: 3
    };

    if (searchParams) {
      params.filters = [];
      Object.keys(searchParams).forEach((filterKey) => {
        if (filterKey === 'searchKey') return;
        if (searchParams[filterKey].length > 0) {
          const filter = {
            name: filterKey,
            op: 'in',
            value: searchParams[filterKey]
          };
          params.filters.push(filter);
        }
      });
    }
    return handleResponse(axiosInstance.get(ENDPOINT.TAG_TEMPLATE(), { params })).then(
      (res) => {
        dispatch(success(res.items, paginater.page, res.total_count));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};
