import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexContainer } from 'common/components/atoms';
import colors from 'common/styles/colors';

const TabsListContainer = styled(FlexContainer)`
  & div {
    background: #F7F8F6;
    font-weight: bold;
    color: ${colors.greyXXD};
    padding: 1.25rem;
    border-right: 1px solid #D8D8D6;
    border-bottom: 1px solid #D8D8D6;
    cursor: pointer;
  }

  & div:last-of-type {
    border-right: 0px;
    flex: auto;
    cursor: default;
  }

  & div.error {
    border: 1px solid ${colors.error}
  }

  & div.error:first-of-type {
    border-radius: 8px 0 0 0;
  }

  & div.active {
    background: white;
    border-bottom: 0px;
    cursor: default;
  }
`;

const TabsContainer = styled.div`
  border: 1px solid #D8D8D6;
  overflow: hidden;
  border-radius: 8px;
  margin-top: 1.875rem;
`;

const TabsList = props => (
  <TabsListContainer>
    {props.items.map(item => (
      <div
        className={`${
          item.value === props.activeTabValue && 'active'
        } ${
          props.binsWithError.includes(item.value) && 'error'
        }`}
        onClick={() => {
          if (item.value !== props.activeTabValue) props.onTabClick(item.value);
        }}
        key={item.value}
      >
        {item.text}
      </div>
    ))}
    <div />
  </TabsListContainer>
);

const SpeedRangeTabs = props => (
  <TabsContainer>
    <TabsList
      items={props.tabItems}
      onTabClick={props.onTabClick}
      activeTabValue={props.activeTabValue}
      binsWithError={props.binsWithError}
    />
    {props.children}
  </TabsContainer>
);

SpeedRangeTabs.propTypes = {
  tabItems: PropTypes.array.isRequired,
  onTabClick: PropTypes.func.isRequired,
  activeTabValue: PropTypes.number.isRequired,
  binsWithError: PropTypes.array.isRequired
};

export default SpeedRangeTabs;
