import { Component } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

import { LineChart } from '../../../../../common/components/Chart/hoc/LineChart';

import * as graphUtils from '../../../../../common/components/Chart/utils/graphUtils';

class FeatureChart extends Component {
  constructor(props) {
    super(props);
    this.drawFeatures = this.drawFeatures.bind(this);
    this.drawFeature = this.drawFeature.bind(this);
    this.draggableFeature = this.draggableFeature.bind(this);
    this.dragged = this.dragged.bind(this);
    this.dragended = this.dragended.bind(this);
    this.highlightSelectedFeature = this.highlightSelectedFeature.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.features, this.props.features)) {
      this.drawFeatures();
    }

    if (!_.isEqual(prevProps.selectedFeature, this.props.selectedFeature)) {
      this.highlightSelectedFeature();
    }
  }

  drawGraph(ctx) {
    this.ctx = ctx;

    this.featureContainer = d3.select(ctx.chart)
      .append('g')
      .attr('width', ctx.chart.getBoundingClientRect().width)
      .attr('height', ctx.chart.getBoundingClientRect().height)
      .attr('class', `${this.props.chartName} featureGroupContainer`);

    this.drawFeatures();
    if (this.props.selectedFeature) {
      this.highlightSelectedFeature();
    }
  }


  drawFeature(ctx, feature) {
    // dont draw feature that doesnt have a marker (the placeholder for a new one)
    if (feature.marker === null) return;

    // dont draw individual features that are off the selected width of chart
    if (feature.type === 'individual' && (this.ctx.x(feature.frequency) > this.ctx.chart.getBoundingClientRect().width || this.ctx.x(feature.frequency) < 0)) return;

    // TODO: support for area like features (should be done similarly as here just using a rect instead of a line)
    if (!(feature.frequency || (feature.frequency_start && feature.frequency_end))) return;

    d3.select(`#feature-${this.props.chartName}-${feature.id}`).remove();

    // draw single feature line into the container
    const freq_range = feature.type === 'range' ? (feature.frequency_end - feature.frequency_start) : 0;
    const featureG = this.featureContainer
      .append('g')
      .attr('class', `${this.props.chartName} featureGroup`)
      .attr('id', `feature-${this.props.chartName}-${feature.id}`)
      .attr('transform', `translate(${ctx.x(feature.type === 'range' ? feature.frequency_end - (freq_range / 2) : feature.frequency)}, 0)`);

    if (feature.type === 'range') {
      const options = {
        extent: [
          ctx.x(feature.frequency_start),
          ctx.x(feature.frequency_end)
        ]
      };
      d3.select(`.feature-${this.props.chartName}-${feature.id}.brush`).remove();
      this.ctx.brush = null;
      this.ctx.brush = graphUtils.addHorizontalBrush(this.ctx.chart, `feature-${this.props.chartName}-${feature.id} featureBrush`, options);

      const brushEl = d3.select(`.feature-${this.props.chartName}-${feature.id}.brush`);

      brushEl.select('.overlay')
        .attr('pointer-events', 'none');

      brushEl.select('.selection')
        .attr('pointer-events', 'none');

      brushEl.selectAll('.handle')
        .attr('pointer-events', 'none');
    } else {
      graphUtils.drawLineOnPos(featureG.node(), 0, 0, 0, ctx.chart.getBoundingClientRect().height, `${this.props.chartName} featurePosition`)
        .attr('stroke-width', 3)
        .attr('fill', '#383838');
    }

    // TODO: draw rect on pos and draw text on pos util funcs to be added (also overwrite from places where this would be used otherwise)
    const featureRect = featureG.append('rect')
      .attr('height', 16)
      .attr('width', 0)
      .attr('fill', '#d3d3d3')
      .attr('id', `feature-${this.props.chartName}-${feature.id}_tooltip_rect`)
      .attr('cursor', 'pointer')
      .attr('pointer-events', 'all');

    if (this.props.featureLabelClick) {
      graphUtils.addMouseClickEvent(featureRect.node(), (e) => {
        e = e || window.event;
        this.props.featureLabelClick(feature);
        if (e) e.stopPropagation();
      });
    }

    const featureText = featureG.append('text')
      .attr('dx', 0)
      .attr('dy', 12)
      .attr('fill', '#fff')
      .attr('font-size', '10px')
      .text(feature.marker)
      .attr('pointer-events', 'none');

    const widthOfFeatureLabel = featureText.node().getBBox().width;
    d3.select(`#feature-${this.props.chartName}-${feature.id}_tooltip_rect`)
      .attr('width', widthOfFeatureLabel + 10)
      .attr('transform', `translate(-${(widthOfFeatureLabel + 10) / 2}, -16)`);

    d3.select(`#feature-${this.props.chartName}-${feature.id} text`)
      .attr('transform', `translate(-${(widthOfFeatureLabel) / 2}, -16)`);

    // dont draw range type features label rect if middle of range (where label rect is) is out of bounds of chart
    if (feature.type === 'range') {
      const featureMiddle = feature.frequency_end - (feature.frequency_end - feature.frequency_start) / 2;
      if (this.ctx.x(featureMiddle) > this.ctx.chart.getBoundingClientRect().width || this.ctx.x(featureMiddle) < 0) {
        d3.select(`#feature-${this.props.chartName}-${feature.id}_tooltip_rect`).remove();
        d3.select(`#feature-${this.props.chartName}-${feature.id} text`).remove();
      }
    }

    if (_.isEqual(this.props.selectedFeature, feature)) {
      // drag handler for selected feature
      this.draggableFeature(feature, featureG, this.ctx.brush);
    }
  }

  drawFeatures() {
    if (!this.ctx || !this.props.features) return;

    // remove all previous feature elements
    d3.selectAll(`.${this.props.chartName}.featureGroup`).remove();
    d3.selectAll('.featureBrush').remove();

    this.props.features.forEach((feature) => {
      this.drawFeature(this.ctx, feature);
    });
    this.highlightSelectedFeature();
  }

  draggableFeature(feature, featureG, brush) {
    const drag = d3.drag()
      .on('drag', () => this.dragged(feature, featureG, brush))
      .on('end', () => this.dragended(feature));

    const height = this.ctx.chart.getBoundingClientRect().height;

    d3.selectAll(`.${this.props.chartName} .handle`).remove();
    if (feature.type === 'individual') {
      graphUtils.drawLineOnPos(featureG.node(), 0, height * 0.45, 0, height - height * 0.45, `${this.props.chartName} handle`);
      featureG
        .attr('cursor', 'pointer')
        .call(drag);
    }
    if (feature.type === 'range') {
      featureG
        .attr('cursor', 'pointer')
        .call(drag);
    }
  }

  dragged(feature, featureG, brush) {
    let newpos = d3.event.x;
    const range = this.ctx.x.range();
    if (feature.type === 'individual') {
      if (newpos < 0) newpos = 0;
      if (newpos > range[1]) newpos = range[1];
      featureG.attr('transform', `translate(${newpos}, 0)`);

      this.props.draggedCallback({ ...feature, frequency: Math.round(this.ctx.x.invert(newpos)) });
    } else if (feature.type === 'range') {
      const node = d3.select(`.feature-${this.props.chartName}-${feature.id}.brush .selection`).node();
      const width = node.getBoundingClientRect().width;
      if (newpos - width / 2 < 0) newpos = width / 2;
      if (newpos + width / 2 > range[1]) newpos = range[1] - width / 2;

      featureG.attr('transform', `translate(${newpos}, 0)`);
      d3.select(`.feature-${this.props.chartName}-${feature.id}.brush`)
        .call(brush.move, [newpos - width / 2, newpos + width / 2]);

      const frequency_start = Math.round(this.ctx.x.invert(newpos - width / 2));
      const frequency_end = Math.round(this.ctx.x.invert(newpos + width / 2));
      this.props.draggedCallback({ ...feature, frequency_start, frequency_end });
    }
  }

  dragended(feature) {
    if (feature.type === 'range') {
      const brushEl = d3.select(`.feature-${this.props.chartName}-${feature.id}.brush`);
      const brushSelection = d3.brushSelection(brushEl.node());

      const frequency_start = Math.round(this.ctx.x.invert(brushSelection[0]));
      const frequency_end = Math.round(this.ctx.x.invert(brushSelection[1]));

      this.props.dragCallback({ ...feature, frequency_start, frequency_end });
    } else if (feature.type === 'individual') {
      let newpos = d3.event.x;
      newpos = this.ctx.x.invert(newpos);
      newpos = Math.round(newpos);

      const range = this.ctx.x.range();
      if (newpos < 0) newpos = 1;
      if (newpos > this.ctx.x.invert(range[1])) newpos = this.ctx.x.invert(range[1]);

      this.props.dragCallback({ ...feature, frequency: newpos });
    }
  }

  highlightSelectedFeature() {
    // reset previous selection
    d3.selectAll(`.${this.props.chartName}.featureGroup line`)
      .classed('selected', false);
    d3.selectAll(`.${this.props.chartName}.featureGroup rect`)
      .attr('fill', '#d3d3d3');
    d3.selectAll('.featureBrush.brush .selection')
      .attr('fill', '#777');

    if (this.props.selectedFeature) {
      const selectedFeature = this.props.selectedFeature;
      this.drawFeature(this.ctx, selectedFeature);
      d3.select(`#feature-${this.props.chartName}-${selectedFeature.id} line`)
        .classed('selected', true);
      d3.select(`#feature-${this.props.chartName}-${selectedFeature.id} rect`)
        .attr('fill', '#91b846');
      d3.select(`.feature-${this.props.chartName}-${selectedFeature.id}.brush .selection`)
        .attr('fill', '#91b846');
    }
  }

  redraw(ctx) {
    this.ctx = ctx; // set ctx again (features has wrong context otherwise)

    if (this.cursors) {
      d3.selectAll(`.${this.props.chartName}.cursorGroup`).remove();

      this.cursors.forEach((cursor) => {
        if (ctx.x(cursor.x) > ctx.chart.getBoundingClientRect().width || ctx.x(cursor.x) < 0) return;
        this.drawCursor(ctx, cursor);
      });
    }
    this.drawFeatures();
    this.highlightSelectedFeature();
  }

  render() {
    return null;
  }
}

FeatureChart.displayName = 'FeatureChart';

export default LineChart(FeatureChart);
