import moment from 'moment';

export const APIAuditConstants = {
  GET_API_AUDIT_METADATA_REQUEST: 'GET_API_AUDIT_METADATA_REQUEST',
  GET_API_AUDIT_METADATA_SUCCESS: 'GET_API_AUDIT_METADATA_SUCCESS',
  GET_API_AUDIT_METADATA_FAILURE: 'GET_API_AUDIT_METADATA_FAILURE',
  GET_API_AUDIT_LOGS: 'GET_API_AUDIT_LOGS',
  GET_API_AUDIT_LOGS_REQUEST: 'GET_API_AUDIT_LOGS_REQUEST',
  GET_API_AUDIT_LOGS_SUCCESS: 'GET_API_AUDIT_LOGS_SUCCESS',
  GET_API_AUDIT_LOGS_FAILURE: 'GET_API_AUDIT_LOGS_FAILURE'
};

export const APIAuditListColumnHeaders = [
  {
    name: 'resource',
    label: 'Resource'
  },
  {
    name: 'action',
    label: 'Action'
  },
  {
    user: 'user',
    label: 'User'
  },
  {
    name: 'timestamp',
    label: 'Timestamp'
  },
  {
    name: 'details',
    label: 'Details'
  }
];

export const APIAuditListColumnSizes = [20, 30, 25, 15, 10];

export const MESSAGE = {
  noItems: 'No API Logs Found'
};

export const calendarOptions = [
  {
    text: '1D',
    value: 1
  },
  {
    text: '7D',
    value: 7
  },
  {
    text: '30D',
    value: 30
  },
  { text: '3M',
    value: 90
  },
  { text: '1Y',
    value: 365 },
  {
    text: 'All',
    value: undefined
  },
  {
    text: 'Custom',
    value: 'custom',
  }
];

export const initialSearchParams = {
  resource: [],
  action: [],
  endTime: moment().toDate(),
  startTime: moment().subtract(7, 'days').toDate()
};
