import React from 'react';
import { FilterIcon, CrossCircle } from 'common/images/FaIcons';
import styled from 'styled-components';
import colors from 'common/styles/colors';

const IconsContainer = styled.span`
  position: relative;
  width: 1rem;
  color: ${props => props.theme.primaryColor};
`;

const IconContainer = styled.span`
  position: absolute;
  font-size: 8px;
  right: -5px;
  bottom: 0;
  color: ${colors.critical};
  color: red;
`;

function ClearFilterIcon() {
  return (
    <IconsContainer>
      <FilterIcon />
      <IconContainer><CrossCircle /></IconContainer>
    </IconsContainer>
  );
}

export default ClearFilterIcon;
