import { combineReducers } from 'redux';
import types from '../actions/machineOverview.types';

const overviewReducer = combineReducers({
  config,
  charts
});

export default overviewReducer;

export function config(state = {}, action) {
  switch (action.type) {
    case types.GET_CONFIG_REQUEST: {
      return {
        loading: true,
      };
    }

    case types.GET_CONFIG_SUCCESS: {
      return {
        loading: false,
        data: action.config,
        machine_id: action.machine_id
      };
    }
    case types.GET_CONFIG_FAILURE: {
      return {
        loading: false,
        machine_id: action.machine_id
      };
    }
    case types.RESET_MACHINE_OVERVIEW_CONFIG:
      return {};
    default:
      return state;
  }
}

export function charts(state = {}, action) {
  switch (action.type) {
    case types.GET_MACHINE_SUMMARY_REQUEST: {
      return {
        loading: true,
      };
    }
    case types.GET_MACHINE_SUMMARY_SUCCESS: {
      return {
        loading: false,
        summary: action.summary
      };
    }
    case types.GET_MACHINE_SUMMARY_FAILURE: {
      return {
        error: action.error.message,
      };
    }
    default:
      return state;
  }
}
