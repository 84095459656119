export default {
  GET_MACHINE_INFO_REQUEST: 'GET_MACHINE_INFO_REQUEST',
  GET_MACHINE_INFO_SUCCESS: 'GET_MACHINE_INFO_SUCCESS',
  GET_MACHINE_INFO_FAILURE: 'GET_MACHINE_INFO_FAILURE',

  UPDATE_MACHINE_INFO_REQUEST: 'UPDATE_MACHINE_INFO_REQUEST',
  UPDATE_MACHINE_INFO_SUCCESS: 'UPDATE_MACHINE_INFO_SUCCESS',
  UPDATE_MACHINE_INFO_FAILURE: 'UPDATE_MACHINE_INFO_FAILURE',

  UPDATE_COMPONENT_INFO_REQUEST: 'UPDATE_COMPONENT_INFO_REQUEST',
  UPDATE_COMPONENT_INFO_SUCCESS: 'UPDATE_COMPONENT_INFO_SUCCESS',
  UPDATE_COMPONENT_INFO_FAILURE: 'UPDATE_COMPONENT_INFO_FAILURE',

  CREATE_COMPONENT_REQUEST: 'CREATE_COMPONENT_REQUEST',
  CREATE_COMPONENT_SUCCESS: 'CREATE_COMPONENT_SUCCESS',
  CREATE_COMPONENT_FAILURE: 'CREATE_COMPONENT_FAILURE',

  DELETE_COMPONENT_REQUEST: 'DELETE_COMPONENT_REQUEST',
  DELETE_COMPONENT_SUCCESS: 'DELETE_COMPONENT_SUCCESS',
  DELETE_COMPONENT_FAILURE: 'DELETE_COMPONENT_FAILURE',

  UPDATE_COMPONENTS_REQUEST: 'UPDATE_COMPONENTS_REQUEST',
  UPDATE_COMPONENTS_SUCCESS: 'UPDATE_COMPONENTS_SUCCESS',
  UPDATE_COMPONENTS_FAILURE: 'UPDATE_COMPONENTS_FAILURE',

  REORDER_COMPONENTS_REQUEST: 'REORDER_COMPONENTS_REQUEST',
  REORDER_COMPONENTS_SUCCESS: 'REORDER_COMPONENTS_SUCCESS',
  REORDER_COMPONENTS_FAILURE: 'REORDER_COMPONENTS_FAILURE',

  CREATE_LOCATION_REQUEST: 'CREATE_LOCATION_REQUEST',
  CREATE_LOCATION_SUCCESS: 'CREATE_LOCATION_SUCCESS',
  CREATE_LOCATION_FAILURE: 'CREATE_LOCATION_FAILURE',

  DELETE_LOCATION_REQUEST: 'DELETE_LOCATION_REQUEST',
  DELETE_LOCATION_SUCCESS: 'DELETE_LOCATION_SUCCESS',
  DELETE_LOCATION_FAILURE: 'DELETE_LOCATION_FAILURE',

  UPDATE_LOCATION_REQUEST: 'UPDATE_LOCATION_REQUEST',
  UPDATE_LOCATION_SUCCESS: 'UPDATE_LOCATION_SUCCESS',
  UPDATE_LOCATION_FAILURE: 'UPDATE_LOCATION_FAILURE',

  ASSOCIATE_BEARING_TO_COMPONENT_REQUEST: 'ASSOCIATE_BEARING_TO_COMPONENT_REQUEST',
  ASSOCIATE_BEARING_TO_COMPONENT_SUCCESS: 'ASSOCIATE_BEARING_TO_COMPONENT_SUCCESS',
  ASSOCIATE_BEARING_TO_COMPONENT_FAILURE: 'ASSOCIATE_BEARING_TO_COMPONENT_FAILURE',

  UNASSIGN_SENSOR_REQUEST: 'UNASSIGN_SENSOR_REQUEST',
  UNASSIGN_SENSOR_SUCCESS: 'UNASSIGN_SENSOR_SUCCESS',
  UNASSIGN_SENSOR_FAILURE: 'UNASSIGN_SENSOR_FAILURE',

  ASSIGN_SENSOR_REQUEST: 'ASSIGN_SENSOR_REQUEST',
  ASSIGN_SENSOR_SUCCESS: 'ASSIGN_SENSOR_SUCCESS',
  ASSIGN_SENSOR_FAILURE: 'ASSIGN_SENSOR_FAILURE',

  RESET_MACHINE_OVERVIEW_CONFIG: 'RESET_MACHINE_OVERVIEW_CONFIG'
};
