import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import {
  SortableElement,
  SortableContainer,
} from 'react-sortable-hoc';

import ConditionalFragment from 'common/components/atoms/ConditionalFragment';
import HierarchyNodeItem from '../molecules/HierarchyNodeItem';

const findNodeDepth = (currNode) => {
  const findTotalDepth = (nodes, currHeight = 0) => {
    nodes.forEach((node) => {
      currHeight += 1;
      if (!node || !node.expanded) return;
      const addedHeight = findTotalDepth(node.children);
      currHeight += addedHeight;
    });
    return currHeight;
  };
  const totalDepth = findTotalDepth([currNode]);
  if (!currNode.children.length) return totalDepth - 1;
  const removeDepth = findTotalDepth([currNode.children[currNode.children.length - 1]]);
  return totalDepth - removeDepth;
};

const renderNodes = (nodes, switchNode, selectedNodeId, selectedNodeType, editMode, getNodeMenuOptions, assetHierarchyIcons) => (
  <>
    {Object.values(nodes)
      .map((node, idx) => (
        <ConditionalFragment
          showFrag={(node.children === undefined || node.children.length === 0)}
          height="100%"
          key={node.id}
          style={{ position: 'relative' }}
          depth={node.depth}
        >
          <HierarchyNodeItem
            node={node}
            depth={node.depth}
            family={{ childs: node.children.length, index: idx }}
            key={node.node_type + node.id}
            activeDepth={findNodeDepth(node)}
            name={node.node_name}
            switchNode={onlyToggleExpand => switchNode(node.id, node.node_type, false, onlyToggleExpand)}
            selected={selectedNodeId === node.id && selectedNodeType === node.node_type}
            expanded={node.expanded}
            editMode={editMode}
            getNodeMenuOptions={getNodeMenuOptions}
            assetHierarchyIcons={assetHierarchyIcons}
          />
          {!_.isEmpty(node.children) && node.expanded
            && renderNodes(node.children, switchNode, selectedNodeId, selectedNodeType, editMode, getNodeMenuOptions, assetHierarchyIcons)
          }
        </ConditionalFragment>
      ))
    }
  </>
);

const UniversalSortableContainer = SortableContainer(
  ({
    nodes,
    switchNode,
    selectedNodeId,
    selectedNodeType,
    machine_id,
    sortStart,
    sortEnd,
    editMode,
    getNodeMenuOptions,
    assetHierarchyIcons
  }) => (
    <div>
      {nodes.map((node, idx) => (
        <UniversalSortableItem
          key={`area-${node.id}`}
          disabled={node.id < 0}
          node={node}
          index={idx}
          switchNode={switchNode}
          selectedNodeId={selectedNodeId}
          selectedNodeType={selectedNodeType}
          machine_id={machine_id}
          siblingsLength={nodes.length}
          sortStart={sortStart}
          sortEnd={sortEnd}
          editMode={editMode}
          getNodeMenuOptions={getNodeMenuOptions}
          assetHierarchyIcons={assetHierarchyIcons}
        />
      ))}
    </div>
  )
);

const UniversalSortableItem = SortableElement(
  ({
    node,
    switchNode,
    selectedNodeId,
    selectedNodeType,
    machine_id,
    index,
    siblingsLength,
    sortStart,
    sortEnd,
    editMode,
    getNodeMenuOptions,
    assetHierarchyIcons
  }) => (
    <ConditionalFragment
      showFrag={node.children === undefined || node.children.length === 0}
      height="100%"
      key={node.id}
      style={{ position: 'relative' }}
      depth={node.depth}
    >
    <HierarchyNodeItem
      node={node}
      depth={node.depth}
      family={{ childs: node.children.length, index }}
      key={node.node_type + node.id}
      activeDepth={findNodeDepth(node)}
      name={node.node_name}
      switchNode={onlyToggleExpand => switchNode(node.id, node.node_type, false, onlyToggleExpand)}
      selected={selectedNodeId === node.id && selectedNodeType === node.node_type}
      expanded={node.expanded}
      machine_id={machine_id}
      editMode={editMode}
      getNodeMenuOptions={getNodeMenuOptions}
      assetHierarchyIcons={assetHierarchyIcons}
    />
    {!_.isEmpty(node.children) && node.expanded && (
      <UniversalSortableContainer
        nodes={node.children}
        siblingsLength={siblingsLength}
        lockAxis="y"
        onSortStart={sortStart}
        sortStart={sortStart}
        sortEnd={sortEnd}
        useDragHandle
        helperClass="hierarchy-drag-helper"
        onSortEnd={sortEnd}
        switchNode={switchNode}
        selectedNodeId={selectedNodeId}
        selectedNodeType={selectedNodeType}
        getContainer={() => document.getElementById('hierarchy')}
        editMode={editMode}
        getNodeMenuOptions={getNodeMenuOptions}
        assetHierarchyIcons={assetHierarchyIcons}
      />
    )}
    </ConditionalFragment>
  )
);


const NodeList = ({
  editMode,
  switchNode,
  selectedNodeId,
  selectedNodeType,
  treeNodes,
  machine_id,
  reorderTree,
  getNodeMenuOptions
}) => {
  const [draggedText, setDraggedText] = useState(null);
  const [assetHierarchyIcons, setAssetHierarchyIcons] = useState(null);
  const sortStart = ({ node }) => {
    const selectedSpan = node.querySelector('.hierarchy-node');
    setDraggedText(selectedSpan.getAttribute('name'));
  };
  const sortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const [node_type, node_id] = draggedText.split('_');
      try {
        reorderTree(oldIndex, newIndex, node_type, Number(node_id));
        setDraggedText(null);
      } catch (e) { console.log(e); }
    }
  };

  useEffect(() => {
    import('../../constants/assetHierarchyIcons').then(assetHierarchyIcons =>
      setAssetHierarchyIcons(assetHierarchyIcons.default)
    );
  }, []);

  return (
  <div>
    {Object.values(treeNodes)
      .map((node, idx) => (
        <ConditionalFragment
          showFrag={(node.children === undefined || node.children.length === 0)}
          height="100%"
          key={node.node_type + node.id}
          style={{ position: 'relative' }}
          depth={node.depth}
        >
          {/* site node */}
          <HierarchyNodeItem
            node={node}
            depth={node.depth}
            family={{ childs: node.children.length, index: idx }}
            key={node.node_type + node.id}
            activeDepth={findNodeDepth(node)}
            name={node.node_name}
            switchNode={onlyToggleExpand => switchNode(node.id, node.node_type, false, onlyToggleExpand)}
            selected={selectedNodeId === node.id && selectedNodeType === node.node_type}
            expanded={node.expanded}
            machine_id={machine_id}
            editMode={editMode}
            getNodeMenuOptions={getNodeMenuOptions}
            assetHierarchyIcons={assetHierarchyIcons}
          />
          {!_.isEmpty(node.children) && node.expanded && (
          <UniversalSortableContainer
            nodes={node.children}
            lockAxis="y"
            onSortStart={sortStart}
            sortStart={sortStart}
            sortEnd={sortEnd}
            useDragHandle
            helperClass="hierarchy-drag-helper"
            onSortEnd={sortEnd}
            switchNode={switchNode}
            selectedNodeId={selectedNodeId}
            selectedNodeType={selectedNodeType}
            getContainer={() => document.getElementById('hierarchy')}
            editMode={editMode}
            getNodeMenuOptions={getNodeMenuOptions}
            assetHierarchyIcons={assetHierarchyIcons}
          />
          )}
        </ConditionalFragment>
      ))
    }
  </div>
  );
};

NodeList.propTypes = {
  switchNode: PropTypes.func.isRequired,
  treeNodes: PropTypes.array.isRequired,
  selectedNodeType: PropTypes.string.isRequired
};

export default NodeList;
