
import { combineReducers } from 'redux';

import TagTemplateConstants from '../constants/tagTemplates.constants';

const tagTemplateInitialState = {
  list: [],
  hasMore: false,
  metadata: {},
  selectedTagTemplate: null,
  details: {},
  loading: true
};

const template = {
  id: -1,
  name: 'New Tag Template'
};

const templates = (state = tagTemplateInitialState, action) => {
  switch (action.type) {
    case TagTemplateConstants.GET_TAG_TEMPLATE_METADATA_SUCCESS:
      return {
        ...state,
        metadata: action.data,
        loading: false
      };
    case TagTemplateConstants.GET_TAG_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case TagTemplateConstants.GET_TAG_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };

    case TagTemplateConstants.GET_TAG_TEMPLATE_LIST_SUCCESS: {
      let templates = action.data;
      if (action.page !== 1) {
        templates = [...state.list, ...action.data];
      }
      return {
        ...state,
        list: templates,
        selectedTagTemplate: null,
        loading: false,
        hasMore: action.data.length >= TagTemplateConstants.NUMBER_OF_TAG_TEMPLATES_PER_PAGE
      };
    }

    case TagTemplateConstants.GET_TAG_TEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.data
      };
    case TagTemplateConstants.UPDATE_TAG_TEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        list: state.list.map(r => r.id === action.id ? { ...r, ...action.data } : r),
        details: {
          ...state.details,
          ...action.data,
        }
      };
    case TagTemplateConstants.SAVE_TAG_TEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.data
      };
    case TagTemplateConstants.SELECT_TAG_TEMPLATE:
      return {
        ...state,
        selectedTagTemplate: action.id === state.selectedTagTemplate ? null : action.id
      };
    case TagTemplateConstants.ADD_NEW_TAG_TEMPLATE_TO_LIST:
      return {
        ...state,
        list: [{ ...template }, ...state.list]
      };
    case TagTemplateConstants.REMOVE_TAG_TEMPLATE_FROM_LIST:
      return {
        ...state,
        list: state.list.filter(template => template.id !== action.id)
      };
    case TagTemplateConstants.ADD_TAG_TEMPLATE_TO_LIST:
      return {
        ...state,
        list: state.list.map(template => template.id === -1 ? action.data : template)
      };
    case TagTemplateConstants.CLEAR_TAG_TEMPLATES:
      return {
        ...state,
        list: []
      };
    default:
      return state;
  }
};

const searchParams = (state = {}, action) => {
  switch (action.type) {
    case TagTemplateConstants.SET_TAG_TEMPLATE_SEARCH_PARAMS:
      return action.searchParams;
    case TagTemplateConstants.CLEAR_TAG_TEMPLATE_SEARCH_PARAMS:
      return {};
    default:
      return state;
  }
};

const sorter = (state = {}, action) => {
  switch (action.type) {
    case TagTemplateConstants.SET_TAG_TEMPLATE_SORTER:
      return {
        sorter: action.sorter
      };
    default:
      return state;
  }
};

const paginater = (state = {}, action) => {
  switch (action.type) {
    case TagTemplateConstants.SET_TAG_TEMPLATE_PAGINATER:
      return {
        page: action.page
      };
    default:
      return state;
  }
};

export const tagTemplates = combineReducers({
  templates,
  paginater,
  searchParams,
  sorter
});
