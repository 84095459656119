import React from 'react';
import PropTypes from 'prop-types';

const RefreshSvg = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 16 14">
    <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3-5-2-Machines-Expanded-Dropdown" transform="translate(-1009.000000, -246.000000)">
        <g id="dropdown/active" transform="translate(993.000000, 229.000000)">
          <g id="refresh-btn" transform="translate(16.000000, 16.000000)">
            <g id="refresh/active-icon">
              <rect id="bounds" x="0" y="0" width={width} height={width} />
              <g id="icon" transform="translate(1.000000, 2.000000)" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                <polyline id="Shape" points="14 0.654545455 14 4.58181818 10.1818182 4.58181818" />
                <polyline id="Shape" points="0 11.1272727 0 7.2 3.81818182 7.2" />
                <path d="M1.59727273,3.92727273 C2.25328831,2.02045854 3.81453365,0.593161033 5.73254609,0.146787228 C7.65055854,-0.299586577 9.65709872,0.297389934 11.0472727,1.728 L14,4.58181818 M0,7.2 L2.95272727,10.0538182 C4.34290128,11.4844282 6.34944146,12.0814048 8.26745391,11.635031 C10.1854663,11.1886571 11.7467117,9.76135964 12.4027273,7.85454545" id="Shape" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

RefreshSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

RefreshSvg.defaultProps = {
  width: 16,
  height: 14,
  fill: '#000000'
};


export default RefreshSvg;
