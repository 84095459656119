
export const eventAnalyticsConstants = {
  GET_EVENT_ANALYTICS_REQUEST: 'GET_EVENT_ANALYTICS_REQUEST',
  GET_EVENT_ANALYTICS_SUCCESS: 'GET_EVENT_ANALYTICS_SUCCESS',
  GET_EVENT_ANALYTICS_FAILURE: 'GET_EVENT_ANALYTICS_FAILURE',
};

export const eventAnalyticsMetadata = theme => ({
  breakdown_by_state: {
    sortOrder: ['open', 'closed'],
    colors: [theme.colors.red, theme.primaryColor],
    route: '/devices',
    title: 'Breakdown by State',
    id: 'event-analytics-breakdown-by-state',
    widgetType: 'DONUT_CHART'
  },
  avg_duration: {
    sortOrder: [],
    colors: [theme.primaryColor],
    route: '/devices',
    title: 'Average Duration of Events in Days',
    id: 'event-analytics-avg-duration',
    widgetType: 'BAR_CHART'
  },
  num_of_events: {
    sortOrder: [],
    colors: [theme.primaryColor],
    route: '/devices',
    title: 'Number of Events',
    id: 'event-analytics-num-of-events',
    widgetType: 'BAR_CHART'
  },
  duration_of_events: {
    title: 'Duration of Events, in Days',
    legend: { position: 'none' },
    chartArea: { width: '80%' },
    height: 200,
    hAxis: {
      title: 'Duration',
    },
    vAxis: {
      title: 'Events'
    },
    colors: [theme.primaryColor],
    widgetType: 'HISTOGRAM'
  }
});

export const widgetTypes = {
  DONUT_CHART: 'DONUT_CHART',
  BAR_CHART: 'BAR_CHART',
  HISTOGRAM: 'HISTOGRAM'
};
