export const AGGRESSIVE_INTERVAL_THRESHOLD = 600;
export const StandardSensors = [
  'Vibration Mote',
  'Vibration Mote (VM1)',
  'Vibration Mote (VM2)',
  'Vibration Mote (VM3)',
  'Vibration Mote (VM4P)',
  'Vibration Mote (GSP)',
  'Vibration Mote (VM4)',
  'Vibration Mote (GS)',
  'Transmitter'
];

export const VectorSensors = [
  'Magnetometer Sensor',
  'Current Sensor',
  'Vibration Sensor',
  'VSX',
  'Ultrasound Vector Sensor'
];

export const GainSettingOptions = [
  { text: 1, value: 1 },
  { text: 2, value: 2 },
  { text: 4, value: 4 },
  { text: 8, value: 8 },
];

export const BiasOptions = [
  { text: '0%', value: 0 },
  { text: '10%', value: 10 },
  { text: '20%', value: 20 },
  { text: '30%', value: 30 },
  { text: '40%', value: 40 },
  { text: '50%', value: 50 },
  { text: '60%', value: 60 },
  { text: '70%', value: 70 },
  { text: '80%', value: 80 },
  { text: '90%', value: 90 },
  { text: '100%', value: 100 },
];

export const OperatingVoltageOptions = [
  { text: 'OFF', value: 0 },
  { text: '5V', value: 5 },
  { text: '12V', value: 12 },
  { text: '15V', value: 15 },
  { text: '24V', value: 24 },
  { text: '30V', value: 30 },
];

export const OutputRangeOptions = [
  {
    text: 'Voltage',
    value: 'voltage',
    children: [
      { text: '0-5 V', value: 0 },
      { text: '0-30 V', value: 3 },
    ],
  },
  {
    text: 'Current',
    value: 'current',
    children: [
      { text: '4-20 mA', value: 4 },
    ],
  },
];

export const SensorSubTypeOptions = [
  { text: 'Voltage', value: 'voltage' },
  { text: 'Current', value: 'current' },
];

export const TemperatureSensorSubTypeOptions = [
  { text: 'RTD', value: 'rtd' },
  { text: 'Voltage', value: 'voltage' },
  // { text: 'Thermocouple', value: 'thermocouple' },
];

export const UltrasoundSensorSubTypeOptions = [
  // { text: 'Voltage', value: 'voltage' },
  { text: 'Current', value: 'current' },
];

export const ConditionerTypeOptions = [
  { text: 'IEPE or ICP', value: 'iepe' },
  { text: '4 - 20 mA', value: '4-20mA' },
  { text: 'Cable Resistance', value: 'cable_resistance' },
  { text: 'N/A', value: '' },
];

export const AxesOptions = [
  { text: 'Triaxial', value: 3 },
  { text: 'Single axis', value: 1 },
];

export const SensorRequiredFields = [
  'name',
  'model_number',
  'units',
  'sensor_type',
  'output_signal_type'
];

export const SensorConfigRequiredFields = {
  default: ['operating_voltage', 'range', 'output_type', 'input_range', 'settling_time'],
  accelerometer: ['default_fmax', 'default_lor'],
  current: ['rl', 'te'],
  temperature: ['sub_type', 'r0'],
  pressure: ['sub_type']
};

export const ErrorMessages = {
  wifi_ssid_required: 'Wi-Fi SSID is required',
  wifi_password_required: 'Wi-Fi Password is required',
  invalid_bssid: 'Entered BSSID is invalid ( should be a 12 digit hexadecimal mac address )',
  bssid_required: 'BSSID is required',
  invalid_channel: 'Entered Channel is invalid ( should be a number between 1 and 13 )',
  channel_required: 'Channel is required'
};

export const reservedPrefixes = ['VM', 'TX', 'VSX'];

export const MESSAGE = {
  serialNumberError: `Serial Number cannot start with a Petasense reserved keyword - ${reservedPrefixes.join(', ')}`
};


export const vibrationMeasurementTypeEnum = {
  overall: '0',
  fullBandwidth: '1',
  highRes: '2',
};

export const sensorChannelOrientations = {
  accelerometer: {
    0: 'x',
    1: 'y',
    2: 'z'
  },
  current: {
    0: 'r',
    1: 'y',
    2: 'b'
  }
};

export const oldTxMeasurementVersion = [
  'v1'
];

export const defaultSensorsPageColumns = [
  'serial_number',
  'machine',
  'location',
  'battery_level',
  'wifi_signal',
  'last_reported_at',
];

export const allSensorsPageColumns = [
  'serial_number',
  'machine',
  'location',
  'battery_level',
  'wifi_signal',
  'last_reported_at',
  'last_measurement_time',
  'firmware'
];

export const CHART_TYPES_FOR_VM3 = [
  { value: 'Battery Voltage', text: 'Battery Voltage' },
  { value: 'Coulomb Count', text: 'Coulomb Count' },
  { value: 'Wifi RSSI Level', text: 'Wifi RSSI Level' }
];

export const CHART_TYPES = [
  { value: 'Battery Voltage', text: 'Battery Voltage' },
  { value: 'Wifi RSSI Level', text: 'Wifi RSSI Level' },
  { value: 'Measurement Cycle Time', text: 'Measurement Cycle Time' },
  { value: 'Wifi Connect Time', text: 'Wifi Connect Time' },
  { value: 'Data Exchange Time', text: 'Data Exchange Time' }
];

export const RI_FIRMWARE_VERSION_ULIMIT = {
  MAJOR_VERSION: 'R41',
  MINOR_VERSION: '19'
};
