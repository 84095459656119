export const teamsActionConstants = {
  GET_TEAMS_REQUEST: 'GET_TEAMS_REQUEST',
  GET_TEAMS_SUCCESS: 'GET_TEAMS_SUCCESS',
  GET_TEAMS_FAILURE: 'GET_TEAMS_FAILURE',

  UPDATE_TEAM_REQUEST: 'UPDATE_TEAM_REQUEST',
  UPDATE_TEAM_SUCCESS: 'UPDATE_TEAM_SUCCESS',
  UPDATE_TEAM_FAILURE: 'UPDATE_TEAM_FAILURE',

  ADD_USER_REQUEST: 'ADD_USER_REQUEST',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE: 'ADD_USER_FAILURE',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

  ADD_AREA_REQUEST: 'ADD_AREA_REQUEST',
  ADD_AREA_SUCCESS: 'ADD_AREA_SUCCESS',
  ADD_AREA_FAILURE: 'ADD_AREA_FAILURE',

  DELETE_AREA_REQUEST: 'DELETE_AREA_REQUEST',
  DELETE_AREA_SUCCESS: 'DELETE_AREA_SUCCESS',
  DELETE_AREA_FAILURE: 'DELETE_AREA_FAILURE',

  CREATE_TEAM_REQUEST: 'CREATE_TEAM_REQUEST',
  CREATE_TEAM_SUCCESS: 'CREATE_TEAM_SUCCESS',
  CREATE_TEAM_FAILURE: 'CREATE_TEAM_FAILURE',

  DELETE_TEAM_REQUEST: 'DELETE_TEAM_REQUEST',
  DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
  DELETE_TEAM_FAILURE: 'DELETE_TEAM_FAILURE'
};
