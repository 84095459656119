import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import InputField from '../../../../../common/components/atoms/InputField';
import Label from '../../../../../common/typography/Label/Label';
import Text from '../../../../../common/typography/Text/Text';
import Button from '../../../../../common/components/atoms/Button';
import FlexContainer from '../../../../../common/components/atoms/FlexContainer';
import colors from '../../../../../common/styles/colors';

const StyledForm = styled(FlexContainer).attrs({ direction: 'column' })`
  padding-top: 2em;
  padding-left: 50px;
  ${InputField} {
    width: 300px;
    margin-right: 2em;
    &.small {
      font-size: 100%;
      width: 150px;
      text-transform: uppercase;
    }
    &.large {
      font-size: 100%;
      width: calc(600px + 2em);
    }
  }
`;

const SaveButton = styled(Button)`
  margin-left: 10px;
`;

const FrequencyRange = styled(FlexContainer).attrs({ direction: 'column' })`
  ${InputField}:first-child {
    margin-right: 1em;
    margin-bottom: 0;
  }
  ${InputField}:last-child {
    margin-left: 1em;
    margin-bottom: 0;
  }
`;

// const CheckboxContainer = styled(FlexContainer)`
//   height: 100%;
//   width: auto;
//   ${Label} {
//     color: ${props => props.theme.colors.black};
//     font-weight: 300;
//   }
// `;

const DeleteButtonContainer = styled.div`
  margin-top: auto;
  margin-left: auto;
  align-self: flex-end;
`;

const DeleteConfirmButtons = styled(FlexContainer).attrs({ alignItems: 'center' })`
  ${Button} {
    width: 50px;
    margin-left: 0.5em;
  }
`;

const DeleteButton = styled(Button)`
  ${props => props.disabled && `
    background-color: ${props.theme.colors.backgroundGray};
    &:hover {
      border-color: ${props.theme.colors.grey};
    }
  `}
  width: 100px;
`;

const frequencyTypeOptions = [
  { key: 1, text: 'Individual', value: 'individual' },
  { key: 2, text: 'Range', value: 'range' },
];

class FeatureForm extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_.isEqual(nextProps.feature, prevState.feature)) {
      return {
        feature: _.cloneDeep(nextProps.feature),
        originalFeature: _.cloneDeep(nextProps.feature)
      };
    }
    // do not update state otherwise
    return null;
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.submitFeature = this.submitFeature.bind(this);
    this.deleteFeature = this.deleteFeature.bind(this);
    this.state = {
      feature: _.cloneDeep(props.feature),
      originalFeature: _.cloneDeep(props.feature),
      deleteConfirm: false,
      saveDisabled: true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEqual(this.state.originalFeature, this.state.feature)) {
      if (!this.state.saveDisabled) this.setState({ saveDisabled: true });
    } else if (this.state.saveDisabled) this.setState({ saveDisabled: false });
  }

  onChange(val, field, submitBool = false) {
    if (val < 0) return;

    if (field === 'marker') {
      const regex = new RegExp('^[a-zA-Z0-9]+$');
      if (!regex.test(val)) return;
      val = val.toUpperCase();
    }

    const feature = {
      ...this.state.feature,
      [field]: field === 'frequency' || field === 'frequency_start' || field === 'frequency_end' ? Number(val) : val
    };

    if (field === 'type' && val === 'range' && this.state.feature.type !== 'range') {
      feature.frequency = null;
      feature.frequency_start = this.state.feature.frequency;
      feature.frequency_end = this.state.feature.frequency;
    }

    if (field === 'type' && val === 'individual' && this.state.feature.type !== 'individual') {
      feature.frequency = this.state.feature.frequency_start;
      feature.frequency_start = null;
      feature.frequency_end = null;
    }

    this.setState({
      feature
    });
    this.props.onChangeFeature(feature);
    if (submitBool) this.submitFeature(feature);
  }

  submitFeature(feature) {
    feature = feature || this.state.feature;
    if (!(feature.frequency_start > feature.frequency_end)) {
      this.props.onSubmitFeature(feature);
    }
  }

  deleteFeature() {
    this.props.onDeleteFeature(this.state.feature);
    this.setState({
      deleteConfirm: false
    });
  }

  saveDisabled = () => {
    const { feature } = this.state;
    if (!feature.marker || !feature.type) return true;
    if (feature.type === 'individual' && isNaN(feature.frequency)) return true;
    if (feature.type === 'range' && (isNaN(feature.frequency_start) || isNaN(feature.frequency_end))) return true;
    return false;
  };

  render() {
    const { feature } = this.state;
    const saveDisabled = this.saveDisabled();
    return (
      <StyledForm>
        <FlexContainer direction="column">
          <FlexContainer>
            <InputField
              className="small"
              label="Marker"
              type="text"
              value={feature.marker || ''}
              maxLength={4}
              onChange={el => this.onChange(el.target.value, 'marker')}
              // onBlur={() => this.submitFeature()}
            />
            <InputField
              label="Description"
              type="text"
              value={feature.description || ''}
              onChange={el => this.onChange(el.target.value, 'description')}
              // onBlur={() => this.submitFeature()}
              disabled={!feature.marker}
            />
          </FlexContainer>
          <FlexContainer>
            <InputField
              label="Type"
              type="select"
              options={frequencyTypeOptions}
              selection
              value={feature.type}
              onChange={(event, data) => this.onChange(data.value, 'type')}
              // onBlur={() => this.submitFeature()}
              disabled={!feature.marker}
            />
            {feature.type === 'individual' && (
              <InputField
                className="small"
                label={feature.yLabel}
                type="number"
                prefix={feature.yUnit}
                value={feature.frequency || ''}
                onChange={el => this.onChange(el.target.value, 'frequency')}
                // onBlur={() => this.submitFeature()}
                disabled={!feature.marker}
              />)
            }
            {feature.type === 'range' && (
              <FrequencyRange>
                <Label>{feature.yLabel.toUpperCase()}</Label>
                <FlexContainer alignItems="center">
                  <InputField
                    className="small"
                    type="number"
                    prefix={feature.yUnit}
                    value={feature.frequency_start || ''}
                    onChange={el => this.onChange(el.target.value, 'frequency_start')}
                    // onBlur={() => this.submitFeature()}
                    disabled={!feature.marker}
                  />
                  <Text>-</Text>
                  <InputField
                    className="small"
                    type="number"
                    prefix={feature.yUnit}
                    value={feature.frequency_end || ''}
                    onChange={el => this.onChange(el.target.value, 'frequency_end')}
                    // onBlur={() => this.submitFeature()}
                    disabled={!feature.marker}
                  />
                </FlexContainer>
              </FrequencyRange>)
            }
          </FlexContainer>
          <DeleteButtonContainer>
            <Fragment>
              {this.state.deleteConfirm ? (
                <DeleteConfirmButtons>
                  <span>Delete feature?</span>
                  <Button secondary={colors.critical} onClick={this.deleteFeature}>Yes</Button>
                  <Button secondary={colors.lightGray} onClick={() => this.setState({ deleteConfirm: false })}>No</Button>
                </DeleteConfirmButtons>
              ) : (
                <DeleteButton secondary={colors.critical} disabled={feature.new} onClick={() => this.setState({ deleteConfirm: true })}>Delete</DeleteButton>
              )}
              <SaveButton disabled={saveDisabled || this.state.saveDisabled} onClick={() => this.submitFeature()}>Save</SaveButton>
            </Fragment>
          </DeleteButtonContainer>
        </FlexContainer>
      </StyledForm>
    );
  }
}

FeatureForm.propTypes = {
  feature: PropTypes.object.isRequired,
  onChangeFeature: PropTypes.func.isRequired,
  onSubmitFeature: PropTypes.func.isRequired,
  onDeleteFeature: PropTypes.func.isRequired,
};


export default FeatureForm;
