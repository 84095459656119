import * as _ from 'lodash';
import { toastr } from 'react-redux-toastr';

import { getReqControllerSignal } from 'common/utils';
import { sensorsConstants } from './sensors.constants';
import { humanize } from '../../../common/helpers/humanize';
import { axiosInstance, ENDPOINT } from '../../../common/constants';
import { handleResponse } from '../../../common/helpers';
import { getHierarchy } from '../../AssetHierarchy/actions/assetDetails.actions';
import {
  StandardSensors,
  VectorSensors,
  vibrationMeasurementTypeEnum
} from '../constants/sensors.constants';

// new APIs
export const getSensorsList = (filters, search_key, page, storeInRedux = true) => (dispatch, getState) => {
  const request = page => ({ type: sensorsConstants.GET_SENSORS_LIST_REQUEST, page });
  const success = (sensors, page, total_pages, total_count) =>
    ({ type: sensorsConstants.GET_SENSORS_LIST_SUCCESS, sensors, page, total_pages, total_count });
  const failure = error => ({ type: sensorsConstants.GET_SENSORS_LIST_FAILURE, error });

  const sensorsState = getState().sensors;
  const adminDashboardState = getState().adminDashboard;
  const params = {
    per_page: sensorsConstants.NUMBER_OF_SENSORS_PER_PAGE,
    page: page || sensorsState.items.page,
    filters: filters || sensorsState.filters.filters,
    search_key: search_key || sensorsState.filters.search_key,
    all: adminDashboardState.adminOpen
  };
  if (storeInRedux) dispatch(request(params.page));
  if (!_.isEmpty(sensorsState.sorter)) {
    params.order_by = { field: sensorsState.sorter.sorter.name, direction: sensorsState.sorter.sorter.order };
  }
  const signal = getReqControllerSignal(
    getState(),
    sensorsConstants.GET_SENSORS_LIST_REQUEST
  );
  return handleResponse(axiosInstance.get(ENDPOINT.GET_SENSORS_LIST, { params, signal })).then(
    (res) => {
      // O(n2) complexity
      let items = [];
      if (res.page === 1) items = res.items;
      else items = res.items.filter(sensor => !sensorsState.items.object.find(i => i.serial_number === sensor.serial_number));
      if (storeInRedux) dispatch(success(items, res.page, res.total_pages, res.total_count));
      return res;
    },
    (err) => {
      if (signal && signal.aborted) return;
      if (storeInRedux) dispatch(failure(err));
      throw err;
    }
  );
};

// For machine builder sensor assignment, not to mess with the actual sensor lsit
export const getUnassociatedSensorsList = () => (dispatch) => {
  const request = () => ({ type: sensorsConstants.GET_UNASSOCIATED_SENSORS_LIST_REQUEST });
  const success = sensors =>
    ({ type: sensorsConstants.GET_UNASSOCIATED_SENSORS_LIST_SUCCESS, sensors });
  const failure = error => ({ type: sensorsConstants.GET_UNASSOCIATED_SENSORS_LIST_FAILURE, error });

  const params = {
    per_page: 500,
    page: 1,
    filters: [
      { name: 'machine_id', op: 'eq', value: null },
      { name: 'type', op: 'ne', value: 'Transmitter' }
    ]
  };
  dispatch(request());
  return handleResponse(axiosInstance.get(ENDPOINT.GET_SENSORS_LIST, { params })).then(
    (res) => {
      dispatch(success(res.items));
    },
    (error) => {
      dispatch(failure(error.message));
    }
  );
};

export const setSensorsSorter = sorter => (dispatch) => {
  dispatch({ type: sensorsConstants.SET_SENSORS_SORTER, sorter });
  dispatch({ type: sensorsConstants.CLEAR_SENSORS_LIST });
  dispatch(getSensorsList(null, null, 1));
};

export const setSensorsFilters = (filters, search_key) => (dispatch) => {
  dispatch({ type: sensorsConstants.SET_SENSORS_FILTERS, filters, search_key });
  dispatch({ type: sensorsConstants.CLEAR_SENSORS_LIST });
  dispatch({ type: sensorsConstants.SET_SENSOR_DETAILS_TO_INITIAL_STATE });
  return dispatch(getSensorsList(filters, search_key, 1));
};

// Removes the serial_number from details state.
export const clearDetails = (model, serial_number) => dispatch =>
  dispatch({ type: sensorsConstants.CLEAR_SENSOR_DETAILS, model, serial_number });

export const loadMoreSensors = () => (dispatch, getState) => {
  const sensorsStateItems = getState().sensors.items;
  const page = sensorsStateItems.page;
  if (sensorsStateItems.listLoading) return;
  dispatch({ type: sensorsConstants.INCREASE_PAGE, page: page + 1 });
  dispatch(getSensorsList(null, null, page + 1));
};

export const selectSensor = id =>
  dispatch => dispatch({ type: sensorsConstants.SELECT_SENSOR, id });

// FIXME: Improve the structure in future
export const selectGivenSensor = id =>
  dispatch => dispatch({ type: sensorsConstants.SELECT_GIVEN_SENSOR, id });

export const getSensorDetails = (serialNumber, select = false) => (dispatch, getState) => {
  const request = () => ({ type: sensorsConstants.GET_SENSORS_DETAILS_REQUEST });
  const success = details => ({ type: sensorsConstants.GET_SENSORS_DETAILS_SUCCESS, details });
  const failure = (error, serial_number) => ({ type: sensorsConstants.GET_SENSORS_DETAILS_FAILURE, error, serial_number });

  const addSensorToList = details => ({ type: sensorsConstants.ADD_SENSOR_TO_LIST, details });

  dispatch(request());
  return handleResponse(axiosInstance.get(ENDPOINT.GET_SENSOR_DETAILS(serialNumber))).then(
    (res) => {
      const { items } = getState().sensors;
      if (res.type.includes('Vibration Mote')) {
        const { metadata } = getState().sensors.metadata;
        if (!metadata || !metadata[res.model]) {
          dispatch(failure('Metadata not available.'));
          return null;
        }
        delete res.measurement_settings['0'].reporting;
        delete res.measurement_settings['0'].sampling;
        delete res.measurement_settings['1'].reporting;
        delete res.measurement_settings['2'].reporting;
        const details = {
          serial_number: res.serial_number,
          type: res.type,
          agent_url: res.agent_url,
          device_id: res.device_id,
          mac_address: res.mac_address,
          fs_range: res.fs_range,
          last_battery_change_time: res.last_battery_change_time,
          id: res.id,
          last_measurement: res.last_measurement,
          last_report: res.last_report,
          machine: res.machine_id,
          machine_name: res.machine,
          component_name: res.component,
          location_name: res.location,
          component: res.component_id,
          model: res.model,
          location: res.location_id,
          orientation: res.orientation,
          phase: res.phase,
          battery_level: res.battery_and_wireless_info.battery_level,
          wifi_signal: res.battery_and_wireless_info.wifi_signal,
          wifi_rssi: res.battery_and_wireless_info.wifi_rssi,
          wifi_ssid: res.battery_and_wireless_info.wifi_ssid,
          speed_detection: res.speed_detection,
          utilization_detection: res.utilization_detection,
          reporting_interval: res.reporting.interval,
          // 0 overall
          overall: res.measurement_settings['0'],
          // 1 Full bandwidth
          full_bandwidth: res.measurement_settings['1'],
          // 2 High res
          high_res: res.measurement_settings['2'],
          aggressive_measurement: res.aggressive_measurement,
          firmware_versions: res.firmware_versions,
          bluetooth_p2p_enabled: res.bluetooth_p2p_enabled,
          master_device: res.master_device,
          config_wifi_ssid: res.wifi_config && res.wifi_config.ssid,
          config_wifi_passphrase: res.wifi_config && res.wifi_config.passphrase,
          ap_bssid: res.wifi_ap_config && res.wifi_ap_config.bssid,
          ap_channel: res.wifi_ap_config && res.wifi_ap_config.channel
        };

        if (!items.object.find(i => i.serial_number === res.serial_number)) {
          const details = {
            id: 'someid',
            area: res.area,
            last_reported_at: res.last_measurement,
            battery_level: res.battery_and_wireless_info.battery_level,
            serial_number: res.serial_number,
            component: res.component,
            wifi_signal: res.battery_and_wireless_info.wifi_signal,
            type: res.type,
            model: res.model,
            location: res.location,
            machine: res.machine,
            aggressive_measurement: res.aggressive_measurement
          };
          dispatch(addSensorToList(details));
        }
        dispatch(success(details));
      } else if (VectorSensors.includes(res.type)) {
        const details = {
          serial_number: res.serial_number,
          type: res.type,
          source: res.source,
          last_measurement: res.last_measurement,
          tx: res.tx_id,
          tx_serial_number: res.transmitter,
          machine: res.machine_id,
          machine_name: res.machine,
          component_name: res.component,
          location_name: res.location,
          component: res.component_id,
          model: res.model,
          location: res.location_id,
          orientation: res.orientation,
          phase: res.phase,
          // 1 Full bandwidth
          full_bandwidth: res.spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth],
          // 2 High res
          high_res: res.spectrum_measurements[vibrationMeasurementTypeEnum.highRes],
          measurement_type: res.spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth].measurement.type,
          measurement_interval: res.spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth].measurement_interval,
          transmitter_version: res.transmitter_version,
          aggressive_measurement: res.aggressive_measurement,
          tx_reporting_interval: res.tx_reporting_interval,
          tx_poe: res.tx_poe,
          utilization_detection: res.utilization_detection,
          state_based_p2p: res.state_based_p2p
          // overall_measurement_type: res.overall_measurements.measurement.type,
          // overall_reporting_interval: res.overall_measurements.reporting.interval,
        };
        if (!items.object.find(i => i.serial_number === res.serial_number)) {
          const details = {
            id: 'someid',
            area: res.area,
            last_reported_at: res.last_measurement,
            battery_level: null,
            serial_number: res.serial_number,
            component: res.component,
            wifi_signal: null,
            type: res.type,
            model: res.model,
            location: res.location,
            machine: res.machine,
            aggressive_measurement: res.aggressive_measurement
          };
          dispatch(addSensorToList(details));
        }
        dispatch(success(details));
      } else if (res.type === 'Transmitter') {
        const details = {
          serial_number: res.serial_number,
          type: res.type,
          id: res.id,
          last_report: res.last_report,
          model: res.model,
          orientation: res.orientation,
          phase: res.phase,
          battery_level: res.battery_and_wireless_info.battery_level,
          wifi_signal: res.battery_and_wireless_info.wifi_signal,
          wifi_rssi: res.battery_and_wireless_info.wifi_rssi,
          wifi_ssid: res.battery_and_wireless_info.wifi_ssid,
          poe: res.battery_and_wireless_info.poe,
          reporting_interval: res.reporting.interval,
          ports: res.ports,
          aggressive_measurement: res.aggressive_measurement,
          firmware_versions: res.firmware_versions,
          agent_url: res.agent_url,
          device_id: res.device_id,
          mac_address: res.mac_address,
          config_wifi_ssid: res.wifi_config && res.wifi_config.ssid,
          config_wifi_passphrase: res.wifi_config && res.wifi_config.passphrase,
          ap_bssid: res.wifi_ap_config && res.wifi_ap_config.bssid,
          ap_channel: res.wifi_ap_config && res.wifi_ap_config.channel
        };
        if (!items.object.find(i => i.serial_number === res.serial_number)) {
          const details = {
            id: 'someid',
            area: res.area,
            last_reported_at: res.last_measurement,
            battery_level: res.battery_and_wireless_info.battery_level,
            serial_number: res.serial_number,
            component: res.component,
            wifi_signal: res.battery_and_wireless_info.wifi_signal,
            type: res.type,
            model: res.model,
            location: res.location,
            machine: res.machine,
            aggressive_measurement: res.aggressive_measurement
          };
          dispatch(addSensorToList(details));
        }
        dispatch(success(details));
      } else if (!StandardSensors.includes(res.type)) {
        const details = {
          serial_number: res.serial_number,
          type: res.type,
          source: res.source,
          tx: res.tx_id,
          tx_serial_number: res.transmitter,
          last_measurement: res.last_measurement,
          machine: res.machine_id,
          machine_name: res.machine,
          component_name: res.component,
          location_name: res.location,
          component: res.component_id,
          model: res.model,
          location: res.location_id,
          orientation: res.orientation,
          phase: res.phase,
          overall_measurement_type: res.overall_measurements.measurement.type,
          overall_measurement_schedule: res.overall_measurements.measurement.schedule,
          overall_measurement_stops: res.overall_measurements.measurement.stops,
          overall_measurement_interval: res.overall_measurements.measurement_interval,
          transmitter_version: res.transmitter_version,
          aggressive_measurement: res.aggressive_measurement,
          tx_reporting_interval: res.tx_reporting_interval
        };
        if (!items.object.find(i => i.serial_number === res.serial_number)) {
          const details = {
            id: 'someid',
            area: res.area,
            last_reported_at: res.last_measurement,
            battery_level: null,
            serial_number: res.serial_number,
            component: res.component,
            wifi_signal: null,
            type: res.type,
            model: res.model,
            location: res.location,
            machine: res.machine,
            aggressive_measurement: res.aggressive_measurement
          };
          dispatch(addSensorToList(details));
        }
        dispatch(success(details));
      }
      if (select) dispatch(selectGivenSensor(serialNumber));
      return res;
    },
    (err) => {
      dispatch(failure(err, serialNumber));
    }
  );
};

export const deleteSensor = res => (dispatch) => {
  dispatch({ type: sensorsConstants.DELETE_SENSOR_REQUEST });
  return handleResponse(axiosInstance.delete(ENDPOINT.SENSORS, { data: res })).then(
    (data) => {
      dispatch(getHierarchy(false));
      dispatch(getSensorsList());
      return data;
    },
    (error) => {
      dispatch({ type: sensorsConstants.DELETE_SENSOR_FAILURE, error });
      throw error.message;
    }
  );
};

export const updateSensorSerialNumber = (serial_number, model, params) => (dispatch) => {
  const request = () => ({ type: sensorsConstants.UPDATE_SERIAL_NUMBER_REQUEST });
  const success = details => ({ type: sensorsConstants.UPDATE_SERIAL_NUMBER_SUCCESS, details });
  const failure = error => ({ type: sensorsConstants.UPDATE_SERIAL_NUMBER_FAILURE, error });

  dispatch(request());
  return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_SENSOR(serial_number), params)).then(
    (res) => {
      dispatch(success({ ...params, serial_number, model }));
      dispatch(selectSensor(params.new_serial_number));
      toastr.success(res.message);
    },
    (error) => {
      toastr.error(error.message);
      dispatch(failure(error));
    }
  );
};

export const updateSensorDetails = res => (dispatch, getState) => {
  const request = () => ({ type: sensorsConstants.UPDATE_DETAILS_REQUEST, res });
  const success = details => ({ type: sensorsConstants.UPDATE_DETAILS_SUCCESS, details });
  const failure = error => ({ type: sensorsConstants.UPDATE_DETAILS_FAILURE, error });
  dispatch(request());
  return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_SENSOR_INFO, res)).then(
    (data) => {
      const { details } = _.cloneDeep(getState().sensors.details);
      Object.entries(res).forEach((entry) => {
        if (!details[entry[0]]) return;
        if (!data[entry[0]].success) return;
        if (entry[1].type.includes('Vibration Mote')) {
          const list = details[entry[0]].map((detail) => {
            if (!entry[1].serial_number.includes(detail.serial_number)) return detail;
            if (!entry[1].measurement_settings['0'].schedule) detail.overall.sampling = entry[1].measurement_settings['0'].sampling;
            else detail.overall = entry[1].measurement_settings['0'];
            if (!entry[1].measurement_settings['1'].schedule) detail.full_bandwidth.sampling = entry[1].measurement_settings['1'].sampling;
            else detail.full_bandwidth = entry[1].measurement_settings['1'];
            if (!entry[1].measurement_settings['2'].schedule) detail.high_res.sampling = entry[1].measurement_settings['2'].sampling;
            else detail.high_res = entry[1].measurement_settings['2'];
            if (entry[1].reporting) detail.reporting_interval = entry[1].reporting.interval;
            if (entry[1].orientation) detail.orientation = entry[1].orientation;
            if (entry[1].phase) detail.phase = entry[1].phase;
            if (entry[1].machine) detail.machine = entry[1].machine;
            if (entry[1].machine_name) detail.machine_name = entry[1].machine_name;
            if (entry[1].component) detail.component = entry[1].component;
            if (entry[1].component_name) detail.component_name = entry[1].component_name;
            if (entry[1].location_id) detail.location = entry[1].location_id;
            if (entry[1].location_name) detail.location_name = entry[1].location_name;
            if (entry[1].wifi_config && entry[1].wifi_config.ssid) detail.wifi_ssid = entry[1].wifi_config.ssid;
            if (entry[1].wifi_config && entry[1].wifi_config.passphrase) detail.wifi_passphrase = entry[1].wifi_config.passphrase;

            if (entry[1].wifi_ap_config && entry[1].wifi_ap_config.bssid) detail.ap_bssid = entry[1].wifi_ap_config.bssid;
            if (entry[1].wifi_ap_config && entry[1].wifi_ap_config.channel) detail.ap_channel = entry[1].wifi_ap_config.channel;

            if ('aggressive_measurement' in entry[1]) detail.aggressive_measurement = entry[1].aggressive_measurement;
            if ('fs_range' in entry[1]) detail.fs_range = entry[1].fs_range;
            const sensorList = getState().sensors.items.object;
            entry[1].serial_number.forEach((serial_number) => {
              if (entry[1].machine) {
                const item = sensorList.find(s => s.serial_number === serial_number);
                item.machine = entry[1].machine_name;
                item.machine_id = entry[1].machine;
                const filterResourceList = getState().sensors.filterResourceList;
                const machine = filterResourceList.machine.find(r => r.id === entry[1].machine);
                if (machine.area_id) {
                  item.area_id = machine.area_id;
                  const area = filterResourceList.area.find(r => r.id === machine.area_id);
                  item.area = area.name;
                }
              } else if (entry[1].machine === null) {
                const item = sensorList.find(s => s.serial_number === serial_number);
                item.machine = '';
                item.machine_id = null;
                item.area = '';
                item.area_id = null;
              }
              if ('aggressive_measurement' in entry[1]) {
                const item = sensorList.find(s => s.serial_number === serial_number);
                item.aggressive_measurement = entry[1].aggressive_measurement;
              }
            });
            return detail;
          });
          details[entry[0]] = list;
        }
        if (VectorSensors.includes(entry[1].type)) {
          const list = details[entry[0]].map((detail) => {
            if (!entry[1].serial_number.includes(detail.serial_number)) return detail;
            if (entry[1].spectrum_measurements) {
              if (entry[1].spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth]) {
                detail.full_bandwidth = entry[1].spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth];
                if (entry[1].spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth].measurement_interval) {
                  detail.measurement_interval = entry[1].spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth].measurement_interval;
                }
                if (entry[1].spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth].measurement.type) {
                  detail.measurement_type = entry[1].spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth].measurement.type;
                }
              }
              if (entry[1].spectrum_measurements[vibrationMeasurementTypeEnum.highRes]) {
                detail.high_res = entry[1].spectrum_measurements[vibrationMeasurementTypeEnum.highRes];
              }
            }
            if (entry[1].orientation) detail.orientation = entry[1].orientation;
            if (entry[1].phase) detail.phase = entry[1].phase;
            if (entry[1].machine) detail.machine = entry[1].machine;
            if (entry[1].machine_name) detail.machine_name = entry[1].machine_name;
            if (entry[1].component) detail.component = entry[1].component;
            if (entry[1].component_name) detail.component_name = entry[1].component_name;
            if (entry[1].location_id) detail.location = entry[1].location_id;
            if (entry[1].location_name) detail.location_name = entry[1].location_name;
            if ('aggressive_measurement' in entry[1]) detail.aggressive_measurement = entry[1].aggressive_measurement;
            const sensorList = getState().sensors.items.object;
            entry[1].serial_number.forEach((serial_number) => {
              if (entry[1].machine) {
                const item = sensorList.find(s => s.serial_number === serial_number);
                item.machine = entry[1].machine_name;
                item.machine_id = entry[1].machine;
                const filterResourceList = getState().sensors.filterResourceList;
                const machine = filterResourceList.machine.find(r => r.id === entry[1].machine);
                if (machine.area_id) {
                  item.area_id = machine.area_id;
                  const area = filterResourceList.area.find(r => r.id === machine.area_id);
                  item.area = area.name;
                }
              } else if (entry[1].machine === null) {
                const item = sensorList.find(s => s.serial_number === serial_number);
                item.machine = '';
                item.machine_id = null;
                item.area = '';
                item.area_id = null;
              }
              if ('aggressive_measurement' in entry[1]) {
                const item = sensorList.find(s => s.serial_number === serial_number);
                item.aggressive_measurement = entry[1].aggressive_measurement;
              }
            });
            return detail;
          });
          details[entry[0]] = list;
        }
        if (entry[1].type === 'Transmitter') {
          const list = details[entry[0]].map((detail) => {
            if (!entry[1].serial_number.includes(detail.serial_number)) return detail;
            if (entry[1].reporting) detail.reporting_interval = entry[1].reporting.interval;
            if (entry[1].wifi_config && entry[1].wifi_config.ssid) detail.wifi_ssid = entry[1].wifi_config.ssid;
            if (entry[1].wifi_config && entry[1].wifi_config.passphrase) detail.wifi_passphrase = entry[1].wifi_config.passphrase;
            if (entry[1].wifi_ap_config && entry[1].wifi_ap_config.bssid) detail.ap_bssid = entry[1].wifi_ap_config.bssid;
            if (entry[1].wifi_ap_config && entry[1].wifi_ap_config.channel) detail.ap_channel = entry[1].wifi_ap_config.channel;
            if ('aggressive_measurement' in entry[1]) {
              detail.aggressive_measurement = entry[1].aggressive_measurement;
            }
            const sensorList = getState().sensors.items.object;
            entry[1].serial_number.forEach((serial_number) => {
              if ('aggressive_measurement' in entry[1]) {
                const item = sensorList.find(s => s.serial_number === serial_number);
                item.aggressive_measurement = entry[1].aggressive_measurement;
              }
            });
            return detail;
          });
          details[entry[0]] = list;
        }
        if (![...StandardSensors, ...VectorSensors].includes(entry[1].type)) {
          const list = details[entry[0]].map((detail) => {
            if (!entry[1].serial_number.includes(detail.serial_number)) return detail;
            if (entry[1].overall_measurements.measurement) {
              detail.overall_measurement_type = entry[1].overall_measurements.measurement.type;
              detail.overall_measurement_schedule = entry[1].overall_measurements.measurement.schedule;
              detail.overall_measurement_stops = entry[1].overall_measurements.measurement.stops;
            }
            if (entry[1].overall_measurements.measurement_interval) {
              detail.overall_measurement_interval = entry[1].overall_measurements.measurement_interval;
            }

            if (entry[1].machine) detail.machine = entry[1].machine;
            if (entry[1].machine_name) detail.machine_name = entry[1].machine_name;
            if (entry[1].component) detail.component = entry[1].component;
            if (entry[1].component_name) detail.component_name = entry[1].component_name;
            if (entry[1].location_id) detail.location = entry[1].location_id;
            if (entry[1].location_name) detail.location_name = entry[1].location_name;
            const sensorList = getState().sensors.items.object;

            entry[1].serial_number.forEach((serial_number) => {
              if (entry[1].machine) {
                const item = sensorList.find(s => s.serial_number === serial_number);
                item.machine = entry[1].machine_name;
                item.machine_id = entry[1].machine;
                const filterResourceList = getState().sensors.filterResourceList;
                const machine = filterResourceList.machine.find(r => r.id === entry[1].machine);
                if (machine.area_id) {
                  item.area_id = machine.area_id;
                  const area = filterResourceList.area.find(r => r.id === machine.area_id);
                  item.area = area.name;
                }
              } else if (entry[1].machine === null) {
                const item = sensorList.find(s => s.serial_number === serial_number);
                item.machine = '';
                item.machine_id = null;
                item.area = '';
                item.area_id = null;
              }
            });
            return detail;
          });
          details[entry[0]] = list;
        }
      });
      dispatch(success(details));
      dispatch(getHierarchy(false));
    },
    (err) => {
      dispatch(failure(err));
      if (err.mote_exists) throw err;
      else throw err.message;
    }
  );
};

export const assignDevicesToAccount = params =>
  () => handleResponse(axiosInstance.put(ENDPOINT.UPDATE_SENSOR_INFO, params)).then(
    (res) => {
      toastr.success(res.message);
      return res;
    },
    (error) => {
      toastr.error(error.message);
      throw error.message;
    }
  );

export const getComponentOptions = machine_id => (dispatch, getState) => {
  const componentList = getState().sensors.componentList;
  if (componentList[machine_id] !== undefined) {
    const list = componentList[machine_id].map(res => ({ text: res.name, value: res.id }));
    return Promise.resolve(list);
  }
  return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_COMPONENTS(machine_id)))
    .then((res) => {
      dispatch({ type: sensorsConstants.GET_COMPONENT_LIST_SUCCESS, key: machine_id, value: res.items });
      const optionsList = res.items.map(res => ({ text: res.name, value: res.id }));
      return optionsList;
    }, (error) => {
      throw error.message;
    });
};

export const getLocationOptions = (machine_id, component_id, filter_bearing = false) =>
  (dispatch, getState) => {
    const locationList = getState().sensors.locationList;
    if (locationList[`${machine_id},${component_id}`] !== undefined) {
      const list = locationList[`${machine_id},${component_id}`]
        .filter(r => filter_bearing ? r.bearing : true)
        .map(r => ({ text: r.bearing ? humanize(r.description || r.name) : humanize(r.name), value: r.id, component_id: r.component_id }));
      return Promise.resolve(list);
    }
    return handleResponse(axiosInstance.get(
      ENDPOINT.GET_MACHINE_COMPONENTS_LOCATIONS(machine_id), { params: { component_id } })).then(
      (res) => {
        dispatch({ type: sensorsConstants.GET_LOCATION_LIST_SUCCESS, key: `${machine_id},${component_id}`, value: res });
        return res
          .filter(r => filter_bearing ? r.bearing : true)
          .map(r => ({ text: r.bearing ? humanize(r.description || r.name) : humanize(r.name), value: r.id, component_id: r.component_id }));
      },
      (err) => {
        throw err.message;
      }
    );
  };

export const getSensorsMetadata = () => dispatch =>
  handleResponse(axiosInstance.get(ENDPOINT.GET_SENSORS_METADATA)).then(
    (res) => {
      dispatch({ type: sensorsConstants.GET_SENSORS_METADATA_SUCCESS, data: res });
      return res;
    },
    (error) => {
      dispatch({ type: sensorsConstants.GET_SENSORS_METADATA_FAILURE, error });
      throw error;
    }
  );

export const clearSelectedSensor = () =>
  dispatch => dispatch({ type: sensorsConstants.CLEAR_SELECTED_SENSOR });

export const getResourcesBasedOnType = resource_type =>
  (dispatch) => {
    const params = {
      resource_type
    };
    return handleResponse(axiosInstance(ENDPOINT.GET_FILTER_RESOURCES, { params })).then(
      (res) => {
        dispatch({ type: sensorsConstants.GET_FILTER_RESOURCE_SUCCESS, resource_type, result: res.result });
        if (resource_type === 'component') return res.result.map(r => ({ value: r.id, text: r.name }));
        if (resource_type === 'mcu_firmware') return res.result.map(r => ({ value: r.version, text: r.version }));
        if (resource_type === 'area') return res.result.map(r => ({ value: r.id, text: r.path }));
        return res.result.map(r => ({ value: r.id, text: r.name }));
      },
      (error) => {
        toastr.error(error.message);
      }
    );
  };

export const getTxModels = () =>
  () => handleResponse(axiosInstance.get(ENDPOINT.GET_TX_MODELS)).then(
    res =>
      res,
    (error) => {
      throw error.message;
    }
  );

export const getMoteModels = () =>
  () => handleResponse(axiosInstance.get(ENDPOINT.GET_MOTE_MODELS)).then(
    res =>
      res,
    (error) => {
      throw error.message;
    }
  );

export const createMote = params =>
  () => handleResponse(axiosInstance.post(ENDPOINT.CREATE_MOTE, params)).then(
    res =>
      res,
    (error) => {
      throw error.message;
    }
  );

export const createTx = params =>
  () => handleResponse(axiosInstance.post(ENDPOINT.CREATE_TX, params)).then(
    res =>
      res,
    (error) => {
      throw error.message;
    }
  );

export const createSensor = sensor => (dispatch) => {
  dispatch({ type: sensorsConstants.CREATE_SENSOR_REQUEST });
  return handleResponse(axiosInstance.post(ENDPOINT.SENSORS, sensor)).then(
    (res) => {
      dispatch({ type: sensorsConstants.CREATE_SENSOR_SUCCESS });
      dispatch(getHierarchy(false));
      dispatch(getSensorsList());
      toastr.success(res.message);
      return res;
    },
    (error) => {
      dispatch({ type: sensorsConstants.CREATE_SENSOR_FAILURE, error });
      throw error.message;
    }
  );
};

export const getUnassignedSensors = () => {
  const request = () => ({ type: sensorsConstants.GET_UNASSIGNED_SENSORS_REQUEST });
  const success = sensors => ({ type: sensorsConstants.GET_UNASSIGNED_SENSORS_SUCCESS, sensors });
  const failure = error => ({ type: sensorsConstants.GET_UNASSIGNED_SENSORS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    // remove account id
    return handleResponse(axiosInstance.get(ENDPOINT.UNASSIGNED_SENSORS, { params: { unassociated: 1, per_page: 99999999999 } })).then(
      (res) => {
        dispatch(success(res.items));
        return res.items;
      },
      (error) => {
        dispatch(failure(error.message));
        throw error.message;
      }
    );
  };
};

export const assignSensorToTx = (port_id, data, model, serial_number) => dispatch =>
  handleResponse(axiosInstance.post(ENDPOINT.ASSIGN_SENSOR(port_id), data)).then(
    (res) => {
      dispatch(getSensorDetails(serial_number));
      return res;
    },
    (error) => {
      toastr.error(error.message);
      throw error.message;
    }
  );

export const unassignSensorFromTx = data => dispatch =>
  handleResponse(axiosInstance.put(ENDPOINT.SENSORS, data)).then(
    (res) => {
      dispatch(getSensorDetails(data.tx_serial_no));
      return res;
    },
    (error) => {
      toastr.error(error.message);
      throw error.message;
    }
  );

export const assignSensorsToTx = sensors => (dispatch) => {
  const request = () => ({ type: sensorsConstants.ASSIGN_SENSORS_TO_TX_REQUEST });
  const success = () => ({ type: sensorsConstants.ASSIGN_SENSORS_TO_TX_SUCCESS });
  const failure = () => ({ type: sensorsConstants.ASSIGN_SENSORS_TO_TX_FAILURE });

  dispatch(request());
  return handleResponse(axiosInstance.post(ENDPOINT.ASSIGN_SENSORS_TO_TX, { sensors })).then(
    (res) => {
      dispatch(success());
      toastr.success(res.message);
    },
    (error) => {
      dispatch(failure());
      toastr.error(error.message);
      throw error.message;
    }
  );
};

export const getDeviceLogsData = (mote_id, days, startDate, endDate) => (dispatch) => {
  const request = () => ({ type: sensorsConstants.GET_DEVICE_LOGS_DATA_REQUEST });
  const success = data => ({ type: sensorsConstants.GET_DEVICE_LOGS_DATA_SUCCESS, data });
  const failure = () => ({ type: sensorsConstants.GET_DEVICE_LOGS_DATA_FAILURE });

  const start_date = startDate;
  const end_date = endDate;
  const queryParams = { days, end_date, start_date };

  dispatch(request());
  return handleResponse(
    axiosInstance.get(ENDPOINT.GET_DEVICE_LOGS_DATA(mote_id), { params: queryParams })
  ).then(
    (res) => {
      if (_.isArray(res) && res.length === 0) {
        dispatch(success(res));
      } else {
        dispatch(success(res));
      }
    },
    (error) => {
      dispatch(failure());
      toastr.error(error.message);
    }
  );
};

export const getDeviceLastReportedInfo = serialNumber => (dispatch) => {
  const request = () => ({ type: sensorsConstants.GET_DEVICE_LAST_REPORTED_INFO_REQUEST });
  const success = data => ({ type: sensorsConstants.GET_DEVICE_LAST_REPORTED_INFO_SUCCESS, data });
  const failure = () => ({ type: sensorsConstants.GET_DEVICE_LAST_REPORTED_INFO_FAILURE });

  dispatch(request());
  return handleResponse(
    axiosInstance.get(ENDPOINT.GET_DEVICE_LAST_REPORTED_INFO(serialNumber))
  ).then(
    (res) => {
      dispatch(success(res));
    },
    (error) => {
      dispatch(failure());
      toastr.error(error.message);
    }
  );
};
