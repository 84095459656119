import moment from 'moment';
import _ from 'lodash';
import { RI_FIRMWARE_VERSION_ULIMIT } from '../constants/sensors.constants';

export const REFERENCE_DATE = '2020-11-01';
export const DAY_IN_SECONDS = 86400;

export const DayTimings = ['12 AM', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12 PM',
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'
];

export const dayTimeArray = [null, '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];

export const DayMap = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
};

export const filterStateBasedOption = (options, filter = true) => {
  if (filter) {
    options = options.filter(t => t.value !== 'state_based');
  }
  return options;
};

export const filterMeasurementOption = (
  options, scheduleBasedOnly = true, stateBasedOnly = false
) => {
  if (scheduleBasedOnly) {
    options = options.filter(t => t.value !== 'state_based');
  }

  if (stateBasedOnly) {
    options = options.filter(t => t.value !== 'schedule_based');
  }

  return options;
};

export const convertTimestampArrayFormatToMomentObject = (arrayTimestamp) => {
  if (!_.isArray(arrayTimestamp)) return null;
  const [day, time] = arrayTimestamp;
  const obj = moment(`${REFERENCE_DATE} ${time}`);
  obj.add(Number(day), 'days');
  return obj;
};

export const calculateMaxReportingInterval = (schedule, maxMeasurementCount) => {
  const timestamps = [];

  // Looping through the different types of measurement schedule, for example - Full Bandwidth and High Res
  schedule.forEach((typeSchedule) => {

    // Looping through each (start, stop, interval) in the measurement schedule
    typeSchedule.forEach((schedule) => {
      const startTime = convertTimestampArrayFormatToMomentObject(schedule.start);
      const endTime = convertTimestampArrayFormatToMomentObject(schedule.end);
      const interval = schedule.interval;

      // Handling case where start is before the stop in the weekday format, for example - (start: 0,08:00, end: 3,10:00)
      if (startTime < endTime) {
        while (startTime <= endTime) {
          timestamps.push(startTime.clone());
          startTime.add(interval, 'seconds');
        }

      // Handling case where start comes after the stop in the weekday format, for example - (start: 6,08:00, end: 5,10:00)
      } else {
        while (startTime < (moment(`${REFERENCE_DATE} 00:00`).add(7, 'days'))) {
          timestamps.push(startTime.clone());
          startTime.add(interval, 'seconds');
        }
        startTime.subtract(7, 'days');
        while (startTime <= endTime) {
          timestamps.push(startTime.clone());
          startTime.add(interval, 'seconds');
        }
      }
    });
  });

  // Sorting the resultant timestamp array and using sliding window technique to find max reporting interval
  timestamps.sort((a, b) => a.diff(b));
  const window = maxMeasurementCount - 1;
  let maxInterval = DAY_IN_SECONDS;
  for (let i = 0; i + window < timestamps.length; i++) {
    const timeDiff = timestamps[i + window].diff(timestamps[i], 'seconds');
    maxInterval = _.min([maxInterval, timeDiff]);
  }

  return maxInterval;
};


export const convertScheduleFormat = (schedule) => {
  Object.entries(schedule).forEach(([key, value]) => {
    value.sort((a, b) => {
      const [aDay, aTime] = a.start.split(',');
      const row1 = Number(aDay);
      const col1 = dayTimeArray.findIndex(t => t === aTime);

      const [bDay, bTime] = b.start.split(',');
      const row2 = Number(bDay);
      const col2 = dayTimeArray.findIndex(t => t === bTime);
      if (row1 < row2 || (row1 === row2 && col1 < col2)) {
        return -1;
      }
      if (row1 > row2 || (row1 === row2 && col1 > col2)) {
        return 1;
      }
      return 0;
    });
    value.forEach((s) => {
      s.start = s.start.split(',');
      s.end = s.end.split(',');
      s.interval *= 3600;
    });
  });
  return schedule;
};

/**
 * @param {string} date YYYY-MM-DD
 * @param {string} time HH:mm
 * @returns {number}
 */
export const getEpochFromDateTime = (date, time) => {
  const start_time = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm');
  return start_time.unix();
};

export const getVersionThreshold = (fw_version) => {
  const firmware_version = fw_version;
  let version_threshold = 1e9;
  if (firmware_version) {
    const major_fw = firmware_version.split('-')[1];
    const minor_fw = firmware_version.split('-')[2] || '0';
    if (major_fw < RI_FIRMWARE_VERSION_ULIMIT.MAJOR_VERSION || minor_fw < RI_FIRMWARE_VERSION_ULIMIT.MINOR_VERSION) {
      version_threshold = 86400; // 1 day
    }
  }
  return version_threshold;
};
