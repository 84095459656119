import { adminDashboardConstants } from './constants/adminDashboard.constants';

const adminDashboardInitialState = {
  adminOpen: false
};

export const adminDashboard = (state = adminDashboardInitialState, action) => {
  switch (action.type) {
    case adminDashboardConstants.TOGGLE_ADMIN_DASHBOARD:
      return {
        ...state,
        adminOpen: !state.adminOpen
      };
    default:
      return state;
  }
};
