import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Button_T from 'common/components/atoms/Button';
import FlexContainer from 'common/components/atoms/FlexContainer';

import colors from 'common/styles/colors';
import { ASSET_TYPE } from 'common/charts/constants';
import SpeedRangeTabs from 'common/components/molecules/SpeedRangeTabs';
import BaseGraphSettings from './BaseGraphSettings';
import BaselineModal from '../../../../Machines/MachineDetails/Health/components/BaselineModal';
import EnvelopeAlarmWaterfallModal from './EnvelopeAlarmWaterfallModal';
import BaselineOption from './BaselineOption';
import EnvelopeAlarmVFDSettings from './EnvelopeAlarmVFDSettings';
import EnvelopeThresholdSettings from './EnvelopeThresholdSettings';
import Error_T from '../../atoms/Error';
import SpectralWindowSettings from './SpectralWindowSettings';
import { ENVELOPE_STATES, MESSAGE } from '../../../constants/envelope.constants';

import * as assetDetailActions from '../../../actions/assetDetails.actions';
import * as envelopeActions from '../../../actions/envelope.actions';

const Button = styled(Button_T)`
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.alignSelf && `align-self: ${props.alignSelf};`}
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
`;

const Error = styled(Error_T)`
  margin-top: 10px;
  padding: 0;
`;

const NoDataOverlay = styled(FlexContainer)`
  background: white;
  height: 8rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 5px 0;
  }
`;

const NoSettingsHeader = styled.span`
  display: block;
  color: ${colors.greyXXD};
  font-size: '16px';
  font-weight: '400';
`;

class EnvelopeAlarmSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_waterfall_modal: false,
      showBaselineModal: false,
      baseline_set: props.tag.baseline_set
    };
  }

  componentDidMount() {
    this.getTagBaseGraphSpectrums();
    if (this.props.assetType === ASSET_TYPE.VFD) {
      this.props.filterTrendChart({ bin_nos: [this.props.activeTabValue] });
    }
  }

  getTagBaseGraphSpectrums = () => {
    const { tag, envelopeSettings, measurement_type, amp_type } = this.props;
    if (!envelopeSettings) return;
    const bins = Object.keys(envelopeSettings);
    bins.forEach((bin_no) => {
      if (envelopeSettings[bin_no].base_graph.timestamps) {
        const params = {
          timestamps: envelopeSettings[bin_no].base_graph.timestamps,
          measurement_type,
          amp_type
        };
        const bin = parseInt(bin_no.slice(-1), 10);
        if (bin) params.bin_no = bin;
        this.props.fetchBaseGraphSpectrums(tag.id, params);
      }
    });
  }

  openWaterfallModal = () => {
    this.setState({ show_waterfall_modal: true });
  }

  closeWaterfallModal = () => {
    this.setState({ show_waterfall_modal: false });
  }

  closeBaselineModal = () => {
    const { breadcrumb: { site, machine } } = this.props;
    this.props.assetDetailActions.getTags(machine.id, site.id, false).then(
      (tags) => {
        const tag = _.find(tags, (tag => tag.id === this.props.tag.id));
        this.setState({ baseline_set: tag.baseline_set });
      }
    );
    this.setState({ showBaselineModal: false });
  }

  openBaselineModal = () => {
    this.setState({ showBaselineModal: true });
  }

  areSpectrumsEmpty = (envelope_spectrums) => {
    const { activeTabValue: bin } = this.props;
    const bin_no = `bin_${bin}`;

    return !envelope_spectrums ||
      !envelope_spectrums[bin_no] ||
      envelope_spectrums[bin_no].loading ||
      _.isEmpty(envelope_spectrums[bin_no].data.items);
  }

  renderEnvelopeSettings = () => {
    const {
      config,
      tag,
      currentUser,
      envelopeSettings,
      envelopeState,
      envelopeSettingsError,
      updateEnvelopeSettings,
      onBinChange,
      binFilterOptions,
      assetType,
      settingsShown,
      activeTabValue,
      resetBaseGraph,
      binSelectorError,
      setBinSelectorError,
      clearEnvelopeSettingAndEnvelope,
      spectrumXScale,
      spectrumXUnit
    } = this.props;

    const { baseline_set } = this.state;

    const frequencyUnits = assetType === ASSET_TYPE.VFD ? 'Orders' : currentUser.frequency_units;
    const ampUnits = config && config.spectrum && config.spectrum.amp_units;

    return (
      <FlexContainer direction="column" marginleft="2rem">
        {envelopeSettingsError.base_graph && (
          <Error>{envelopeSettingsError.base_graph}</Error>
        )}
        <FlexContainer direction="row" alignItems="center" margintop="18px">
          {(envelopeState === ENVELOPE_STATES.CREATE || envelopeState === ENVELOPE_STATES.RESET) && (
            <BaselineOption
              baselineSet={baseline_set}
              openBaselineModal={this.openBaselineModal}
              settings={settingsShown.base_graph}
              updateSettings={updateEnvelopeSettings}
            />
          )}
          {envelopeSettings && envelopeState === ENVELOPE_STATES.EDIT && (
            <Button text onClick={resetBaseGraph}>Reset Data</Button>
          )}
          {tag.envelope_spectrums && !this.areSpectrumsEmpty(tag.envelope_spectrums) && (
            <Button marginLeft="1em" text onClick={this.openWaterfallModal}>Review Data</Button>
          )}
        </FlexContainer>
        <FlexContainer direction="row">
          {(envelopeState === ENVELOPE_STATES.CREATE || envelopeState === ENVELOPE_STATES.RESET) && (
            <BaseGraphSettings
              settings={settingsShown.base_graph}
              updateEnvelopeSettings={updateEnvelopeSettings}
            />
          )}
          <EnvelopeThresholdSettings
            baseGraphType={settingsShown.base_graph.type}
            envelopeSettingsError={envelopeSettingsError}
            frequencyUnits={frequencyUnits}
            settings={settingsShown.threshold}
            updateEnvelopeSettings={updateEnvelopeSettings}
            spectrumXScale={spectrumXScale}
            spectrumXUnit={spectrumXUnit}
          />
          <SpectralWindowSettings
            envelopeSettingsError={envelopeSettingsError}
            frequencyUnits={frequencyUnits}
            settings={settingsShown.spectral_window}
            unitSystem={currentUser.unit_system}
            ampUnits={ampUnits}
            updateEnvelopeSettings={updateEnvelopeSettings}
            spectrumXScale={spectrumXScale}
            spectrumXUnit={spectrumXUnit}
          />
          {assetType === ASSET_TYPE.VFD && (
            <EnvelopeAlarmVFDSettings
              base_graph_type={settingsShown.base_graph.type}
              activeTabValue={activeTabValue}
              onBinChange={onBinChange}
              binFilterOptions={binFilterOptions}
              binSelectorError={binSelectorError}
              setBinSelectorError={setBinSelectorError}
            />
          )}
        </FlexContainer>
        {assetType === ASSET_TYPE.VFD && (
          <Button
            danger
            alignSelf="flex-start"
            marginBottom="18px"
            onClick={() => clearEnvelopeSettingAndEnvelope(activeTabValue)}
          >
            {`Clear Settings for Speed Range ${activeTabValue}`}
          </Button>
        )}
      </FlexContainer>
    );
  }

  renderNoDataContent = () => {
    const { config, onSetSettingsClick } = this.props;
    if (config.trends_data && config.trends_data[0] && !_.isEmpty(config.trends_data[0].trend_data)) {
      return (
        <>
          <NoSettingsHeader>{MESSAGE.NO_SETTINGS_SET}</NoSettingsHeader>
          <Button text onClick={onSetSettingsClick}>Set Settings</Button>
        </>
      );
    }
    return (
      <NoSettingsHeader>{MESSAGE.NO_DATA_AVAILABLE}</NoSettingsHeader>
    );
  }

  render() {
    const {
      breadcrumb,
      tag,
      binFilterOptions,
      assetType,
      settingsShown,
      onTabClick,
      activeTabValue,
      binsWithError,
      spectralEnvelope,
    } = this.props;

    const {
      showBaselineModal,
      show_waterfall_modal
    } = this.state;

    const { machine } = breadcrumb;

    return (
      <>
        {showBaselineModal && (
          <BaselineModal
            close={this.closeBaselineModal}
            machineId={machine.id}
          />
        )}
        {show_waterfall_modal && (
          <EnvelopeAlarmWaterfallModal
            close={this.closeWaterfallModal}
            activeTabValue={activeTabValue}
            assetType={assetType}
            spectralEnvelope={spectralEnvelope}
            tag={tag}
          />
        )}
        {assetType === ASSET_TYPE.VFD && (
          <SpeedRangeTabs
            tabItems={binFilterOptions}
            onTabClick={onTabClick}
            activeTabValue={activeTabValue}
            binsWithError={binsWithError}
          >
            {!settingsShown && (
              <NoDataOverlay>
                {this.renderNoDataContent()}
              </NoDataOverlay>
            )}
            {settingsShown && (
              this.renderEnvelopeSettings()
            )}
          </SpeedRangeTabs>
        )}
        {assetType === ASSET_TYPE.FIXED_SPEED && (
          this.renderEnvelopeSettings()
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  breadcrumb: state.breadcrumb,
  currentUser: state.user.user
});

const mapDispatchToProps = dispatch => ({
  assetDetailActions: bindActionCreators(assetDetailActions, dispatch),
  envelopeActions: bindActionCreators(envelopeActions, dispatch)
});

EnvelopeAlarmSettings.propTypes = {
  envelopeSettings: PropTypes.object,
  envelopeSettingsError: PropTypes.object.isRequired,
  envelopeState: PropTypes.string.isRequired,
  measurement_type: PropTypes.number,
  amp_type: PropTypes.string.isRequired,
  resetBaseGraph: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
  updateEnvelopeSettings: PropTypes.func.isRequired,
  onBinChange: PropTypes.func.isRequired,
  binFilterOptions: PropTypes.object.isRequired,
  assetType: PropTypes.string.isRequired,
  settingsShown: PropTypes.object,
  spectralEnvelope: PropTypes.object,
  fetchBaseGraphSpectrums: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  activeTabValue: PropTypes.number.isRequired,
  onTabClick: PropTypes.func.isRequired,
  onSetSettingsClick: PropTypes.func.isRequired,
  filterTrendChart: PropTypes.func.isRequired,
  binSelectorError: PropTypes.string.isRequired,
  setBinSelectorError: PropTypes.func.isRequired,
  clearEnvelopeSettingAndEnvelope: PropTypes.func.isRequired,
  binsWithError: PropTypes.array.isRequired
};

EnvelopeAlarmSettings.defaultProps = {
  measurement_type: 1,
  envelopeSettings: null,
  settingsShown: null,
  spectralEnvelope: null
};

export default connect(mapStateToProps, mapDispatchToProps)(EnvelopeAlarmSettings);
