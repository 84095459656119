import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import ScrollContainer_T from '../../../common/components/atoms/ScrollContainer';
import LoadingSvg from '../../../common/images/LoadingSvg';
import VibrationMote from './types/VibrationMote';
import VectorSensor from './types/VectorSensor';
import Transmitter from './types/Transmitter';
import OtherSensor from './types/OtherSensor';
import GroupedSensors from './types/GroupedSensors';
import {
  StandardSensors,
  VectorSensors
} from '../constants/sensors.constants';


const ScrollContainer = styled(ScrollContainer_T)`
  height: 100%;
`;

const CenterDiv = styled.div`
  margin: auto;
`;
class SensorsDetails extends Component {
  constructor() {
    super();
    this.childRef = React.createRef();
  }

  saveSensorDetails = () => {
    if (this.childRef && this.childRef.current) {
      return this.childRef.current.wrappedInstance.onSave();
    }
  }

  componentWillUnmount() {
    if (this.props.updateFooter) this.props.updateFooter(false);
  }

  render() {
    const {
      closeDetails,
      editMode,
      machines,
      sensorsList,
      machineSensors,
      version,
      utcOffset,
      machine_id,
      appliedFilters,
      removeAppliedFilters
    } = this.props;
    if (_.isEmpty(sensorsList)) return <CenterDiv><LoadingSvg /></CenterDiv>;

    const { type } = sensorsList[0];
    return (
      <ScrollContainer width="70%" height={machineSensors ? 'calc(100vh - 201px)' : 'calc(100vh - 138px)'}>
      {sensorsList.length === 1 && (
        <Fragment>
          {type.includes('Vibration Mote') && (
            <VibrationMote
              ref={this.childRef}
              sensor={sensorsList[0]}
              close={closeDetails}
              editMode={editMode}
              machines={machines}
              version={version}
              utcOffset={utcOffset}
              createdMachineId={this.props.createdMachineId}
              updateFooter={this.props.updateFooter}
            />)
          }
          {type === 'Transmitter' && (
            <Transmitter
              sensor={sensorsList[0]}
              close={closeDetails}
              editMode={editMode}
              machine_id={machine_id}
              appliedFilters={appliedFilters}
              removeAppliedFilters={removeAppliedFilters}
              assignSensorVersionV2
            />)
          }
          {VectorSensors.includes(type) && (
            <VectorSensor
              ref={this.childRef}
              sensor={sensorsList[0]}
              close={closeDetails}
              editMode={editMode}
              machines={machines}
              utcOffset={utcOffset}
              appliedFilters={appliedFilters}
              removeAppliedFilters={removeAppliedFilters}
              createdMachineId={this.props.createdMachineId}
              updateFooter={this.props.updateFooter}
              showOrientation={['Vibration Sensor', 'VSX'].includes(type)}
              showPhase={['Current Sensor'].includes(type)}
              showSamplingOptions={['Vibration Sensor', 'VSX', 'Current Sensor'].includes(type)}
              showHighPassFilterCutoff={['Vibration Sensor', 'VSX', 'Current Sensor'].includes(type)}
            />)
          }
          {![...StandardSensors, ...VectorSensors].includes(type) && (
            <OtherSensor
              sensor={sensorsList[0]}
              ref={this.childRef}
              close={closeDetails}
              machines={machines}
              editMode={editMode}
              appliedFilters={appliedFilters}
              removeAppliedFilters={removeAppliedFilters}
              utcOffset={utcOffset}
              createdMachineId={this.props.createdMachineId}
              updateFooter={this.props.updateFooter}
            />)
          }
        </Fragment>)
      }
      {editMode && sensorsList.length > 1 && (
        <GroupedSensors
          ref={this.childRef}
          sensorList={sensorsList}
          close={closeDetails}
          editMode={editMode}
          version={version}
          utcOffset={utcOffset}
          updateFooter={this.props.updateFooter}
          assignSensorVersionV2
        />)
      }
      </ScrollContainer>
    );
  }
}

SensorsDetails.propTypes = {
  sensorsList: PropTypes.array.isRequired,
  closeDetails: PropTypes.func.isRequired,
  createdMachineId: PropTypes.number,
  machines: PropTypes.array,
  editMode: PropTypes.bool.isRequired,
  utcOffset: PropTypes.number.isRequired
};

SensorsDetails.defaultProps = {
  machines: [],
  createdMachineId: null
};

export default SensorsDetails;
