import _ from 'lodash';
import modelActionTypes from '../actions/modelAction.types';

const initialState = {
  loading: false,
  models: [],
  hasMore: false,
  page: 1,
  account_id: null,
  selectedItems: []
};

export const sensorsModelListReducer = (state = initialState, action) => {
  switch (action.type) {
    case modelActionTypes.GET_SENSOR_MODEL_LIST_REQUEST:
      return {
        ...state,
        account_id: action.account_id,
        loading: true
      };

    case modelActionTypes.GET_SENSOR_MODEL_LIST_SUCCESS: {
      let modelsArray = [];
      if (action.page === 1) modelsArray = action.models;
      else modelsArray = state.models.concat(action.models);
      return {
        ...state,
        loading: false,
        models: modelsArray,
        hasMore: action.page < action.total_pages,
        page: action.page,
        total_count: action.total_count,
        error: false
      };
    }

    case modelActionTypes.INCREASE_PAGE:
      return {
        ...state,
        page: action.page
      };

    case modelActionTypes.GET_SENSOR_MODEL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        models: [],
        hasMore: false,
        error: action.error.message
      };

    case modelActionTypes.CLEAR_MODELS_LIST: {
      return {
        ...state,
        models: []
      };
    }
    case modelActionTypes.SELECT_MODELS_FOR_CREATION: {
      return {
        ...state,
        selectedItems: _.union(state.selectedItems, action.models),
      };
    }
    case modelActionTypes.REMOVE_MODEL_FOR_CREATION: {
      return {
        ...state,
        selectedItems: _.differenceBy(state.selectedItems, action.models, 'id')
      };
    }
    case modelActionTypes.CLEAR_SELECTED_MODELS: {
      return {
        ...state,
        selectedItems: [],
      };
    }
    case modelActionTypes.UPDATE_MODEL_COPIES: {
      const updatedItems = state.selectedItems.map((item) => {
        if (item.id !== action.id) return item;
        return { ...item, copies: action.copies };
      });
      return {
        ...state,
        selectedItems: [
          ...updatedItems,
        ],
      };
    }
    default:
      return state;
  }
};

export const sensorsModelsSorter = (state = {}, action) => {
  switch (action.type) {
    case modelActionTypes.SET_MODELS_SORTER: {
      return {
        sorter: action.sorter
      };
    }
    default:
      return state;
  }
};

const initialDetails = {
  details: {},
  loading: false,
  updating: false,
  creating: false,
  createError: '',
  error: ''
};

export const sensorModelDetailsReducer = (state = initialDetails, action) => {
  switch (action.type) {
    case modelActionTypes.GET_SENSOR_MODEL_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case modelActionTypes.GET_SENSOR_MODEL_SUCCESS: {
      const { model } = action;
      return {
        ...state,
        details: model,
        loading: false
      };
    }

    case modelActionTypes.GET_SENSOR_MODEL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      };
    }

    case modelActionTypes.CREATE_SENSOR_MODEL_REQUEST: {
      return {
        ...state,
        modelCreating: true
      };
    }

    case modelActionTypes.CREATE_SENSOR_MODEL_SUCCESS: {
      return {
        ...state,
        modelCreating: false
      };
    }

    case modelActionTypes.CREATE_SENSOR_MODEL_FAILURE: {
      return {
        ...state,
        error: action.error.message,
        modelCreating: false
      };
    }

    case modelActionTypes.GET_SENSOR_MODEL_METADATA_REQUEST: {
      return {
        ...state,
        metadataFetching: true
      };
    }

    case modelActionTypes.GET_SENSOR_MODEL_METADATA_SUCCESS: {
      return {
        ...state,
        modelMetadata: action.data,
        metadataFetching: false
      };
    }

    case modelActionTypes.GET_SENSOR_MODEL_METADATA_FAILURE: {
      return {
        ...state,
        error: action.error.message,
        metadataFetching: false
      };
    }

    case modelActionTypes.UPDATE_SENSOR_MODEL_SUCCESS: {
      return {
        ...state,
        modelUpdating: false
      };
    }

    case modelActionTypes.UPDATE_SENSOR_MODEL_FAILURE: {
      return {
        ...state,
        error: action.error.message,
        modelUpdating: false
      };
    }

    case modelActionTypes.GET_SENSOR_INSTANCES_REQUEST: {
      return {
        ...state,
        sensorInstances: []
      };
    }

    case modelActionTypes.GET_SENSOR_INSTANCES_SUCCESS: {
      return {
        ...state,
        sensorInstances: action.sensors
      };
    }

    case modelActionTypes.GET_SENSOR_INSTANCES_FAILURE: {
      return {
        ...state,
        sensorInstances: [],
        error: action.error.message
      };
    }

    case modelActionTypes.CLEAR_SENSOR_INSTANCES: {
      return {
        ...state,
        sensorInstances: []
      };
    }

    case modelActionTypes.CLEAR_SELECTED_MODEL: {
      return {};
    }

    default:
      return state;
  }
};

export const sensorSearchParams = (state = {}, action) => {
  switch (action.type) {
    case modelActionTypes.SET_SENSOR_SEARCH_PARAMS:
      return action.searchParams;
    case modelActionTypes.CLEAR_SENSOR_SEARCH_PARAMS:
      return {};
    default:
      return state;
  }
};
