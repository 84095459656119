import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import _ from 'lodash';


import DonutChartContainer from './DonutChartContainer';

export const DonutChart = (WrappedComponent) => {
  class DonutChartWrapper extends WrappedComponent {
    constructor(props) {
      super(props);
      this.setRef = this.setRef.bind(this);
      this.drawGraph = this.drawGraph.bind(this);
      this.drawArc = this.drawArc.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (!_.isEqual(prevProps.data, this.props.data)) {
        this.drawArc();
      }
    }

    componentDidMount() {
      this.drawGraph();
    }

    drawGraph() {
      if (this.refNode.initializeGraphOverride) this.refNode.initializeGraphOverride(this);
      else if (this.refNode.initializeGraphExtend) {
        this.initializeGraph();
        this.refNode.initializeGraphExtend(this);
      } else this.initializeGraph();

      if (this.refNode.drawArcOverride) this.refNode.drawArcOverride(this);
      else if (this.refNode.drawArcExtend) {
        this.drawArc();
        this.refNode.drawArcExtend(this);
      } else this.drawArc();
    }

    initializeGraph() {
      const minDimension = Math.min(this.container.getBoundingClientRect().height, this.container.getBoundingClientRect().width);
      // calculate radius
      this.outerRadius = minDimension / 2 * 0.95 - 20;
      // graph container and a g to place the arcs on,
      // transform the g to a position alinged to the left of the available container
      this.svg = d3.select(this.chart)
        .attr('width', this.container.getBoundingClientRect().width)
        .attr('height', this.container.getBoundingClientRect().height);
      this.donutG = this.svg.append('g')
        .attr('transform', `translate(${this.container.getBoundingClientRect().width / 2}, ${this.container.getBoundingClientRect().height / 2})`);
    }

    drawArc() {
      this.donutG.selectAll('.arc').remove();

      this.donutG.selectAll('.labelDonut').remove();

      this.donutG.selectAll('polyline').remove();

      const pie = d3.pie()
        .padAngle(0.02)
        .value(d => d)
        .sort(null);

      const arc = d3.arc()
        .innerRadius(this.outerRadius - 20)
        .outerRadius(this.outerRadius);

      // arcs
      this.arcs = this.donutG.selectAll('.arc')
        .data(pie(this.props.data))
        .enter()
        .append('g')
        .attr('class', 'arc')
        .append('path')
        .attr('d', arc)
        .attr('fill', (d, i) => this.props.colors[i]);

      this.arcs.exit()
        .remove();

      let total = 0;
      this.props.data.forEach((d) => {
        total += d;
      });
      // TODO: POS IF LABELS BOTH SINGLE DIGITS
      // labels
      this.text = this.donutG.selectAll('.labelDonut')
        .data(pie(this.props.data))
        .enter()
        .append('text')
        .attr('class', 'labelDonut')
        .attr('opacity', d => (d.value === 0 ? 0 : 1))
        .attr('font-weight', 600)
        .text((d) => {
          let percentage = Math.round(d.value / total * 100);
          if (percentage < 1) {
            percentage = '< 1';
          }
          return `${percentage} %`;
        });

      const midAngle = d => d.startAngle + (d.endAngle - d.startAngle) / 2;

      this.text.transition().duration(0)
        .attrTween('transform', (d) => {
          this.current = this.current || d;
          const interpolate = d3.interpolate(this.current, d);
          this.current = interpolate(0);
          return (t) => {
            const d2 = interpolate(t);
            const pos = arc.centroid(d2);
            pos[0] = this.outerRadius * (midAngle(d2) < Math.PI ? 1.2 : -1.2);
            return `translate(${pos})`;
          };
        })
        .styleTween('text-anchor', (d) => {
          this.current = this.current || d;
          const interpolate = d3.interpolate(this.current, d);
          this.current = interpolate(0);
          return (t) => {
            const d2 = interpolate(t);
            return midAngle(d2) < Math.PI ? 'start' : 'end';
          };
        });

      this.text.exit()
        .remove();

      // polylines
      this.polyline = this.donutG.selectAll('polyline')
        .data(pie(this.props.data))
        .enter()
        .append('polyline')
        .attr('stroke', (d, i) => this.props.colors[i])
        .attr('stroke-width', '1px')
        .attr('fill', 'none')
        .attr('opacity', d => (d.value === 0 ? 0 : 1));

      this.polyline.transition().duration(0)
        .attrTween('points', (d) => {
          this.current = this.current || d;
          const interpolate = d3.interpolate(this.current, d);
          this.current = interpolate(0);
          return (t) => {
            const d2 = interpolate(t);
            const pos = arc.centroid(d2);
            pos[0] = this.outerRadius * (midAngle(d2) < Math.PI ? 1.1 : -1.1);
            return [arc.centroid(d2), arc.centroid(d2), pos];
          };
        });

      this.polyline.exit()
        .remove();
      // legend commented out, add legend if design changes
      // const legend = this.donutG.selectAll('.legend')
      //   .data(valAr)
      //   .enter()
      //   .append('g')
      //   .attr('class', 'legend')
      //   .attr('transform', (d, i) => `translate(${-100 + 75 * i}, ${this.outerRadius + 20})`);

      // legend.append('rect')
      //   .attr('width', 10)
      //   .attr('height', 10)
      //   .attr('fill', (d, i) => this.props.colors[i])
      //   .attr('stroke', (d, i) => this.props.colors[i]);

      // legend.append('text')
      //   .attr('x', 15)
      //   .attr('y', 10)
      //   .text(d => d);
    }

    // resize() {
    //   // TODO
    // }

    setRef(node) {
      // if ref callback gets called with null it is unmounting or in preliminary stages so remove eventlistener
      // when it has props the node exists and we can set the ref and eventlistener
      if (!node) {
        window.removeEventListener('resize', this.redraw, false);
      } else if (node.props) {
        window.addEventListener('resize', this.redraw, false);
        this.refNode = node;
      }
    }

    render() {
      return (
          <DonutChartContainer
            innerRef={(node) => { this.container = node; }}
            chartName={this.props.chartName}
            height={this.props.height}
            width={this.props.width}
          >
            <svg ref={(node) => { this.chart = node; }} />
            <WrappedComponent
              {...this.props}
              ref={this.setRef}
            />
          </DonutChartContainer>
      );
    }
  }

  DonutChartWrapper.propTypes = {
    data: PropTypes.array.isRequired
  };

  DonutChartWrapper.defaultProps = {
  };

  DonutChartWrapper.displayName = 'DonutChartWrapper';

  return DonutChartWrapper;
};
