import styled from 'styled-components';

const H4 = styled.h4`
  font-size: 14px;
  font-weight: 600;
  font-family: 'Petasense Open Sans';
  color: ${props => props.theme.colors.black};
  line-height: 16px;
  margin: 0;
  padding: 0;
`;

export default H4;
