import React, { PureComponent, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import Modal from 'common/components/organisms/Modal';
import LoadingSvg from 'common/components/atoms/Loading';
import FooterFlex_T from 'common/components/atoms/FooterFlex';
import * as machineBuilderActions from './actions/machineBuilder.actions';

import SensorList from './components/SensorList';
import MachineSelector from './components/MachineSelector';
import MachineBuilder from './components/MachineBuilder';
import AssociateSensors from './components/AssociateSensors';
import SelectSensorModal from './components/organisms/SelectSensorModal/SelectSensorModal';

const MachineBuilderContainer = styled.div`
  background-color: ${props => props.theme.colors.white}
  height: ${props => props.buildType === 'template' ? 'calc(100vh - 55px)' : 'calc(100vh - 112px)'};
  height: calc(100vh - 112px);
`;

const FooterFlex = styled(FooterFlex_T)`
  align-self: flex-end;
  position: relative;
  width: 100%;
`;

class MachineBuilderModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      footer: null,
      assetType: null,
      showSplashScreen: false,
      selectSensors: false,
      associatedSensors: [],
      createdMachineDetails: { id: null, name: '' },
    };
  }

  componentDidMount() {
    if (_.isEmpty(this.props.machineBuilder.metadata)) {
      this.props.machineBuilderActions.getMachineBuilderMetadata();
    }
  }

  componentDidUpdate() {
    if (_.isEmpty(this.props.machineBuilder.metadata)) {
      this.props.machineBuilderActions.getMachineBuilderMetadata();
    }
  }

  setTitle = (title) => {
    this.setState({ title });
  };

  setFooter = (footer) => {
    this.setState({ footer });
  };

  setAssetType = (assetType) => {
    const assetTypes = ['Rotating Machine', 'Valve', 'Steam Trap', 'Electric Panel'];
    if (assetTypes.includes(assetType)) {
      this.setState({ assetType });
    }
  };

  setSelectSensors = (bool) => {
    this.setState({ selectSensors: bool });
  };

  associateSensors = (sensors) => {
    this.setState({ showSplashScreen: false });
    this.setSelectSensors(false);
    this.setState({ associatedSensors: sensors });
  };

  onClickContinue = (machine_id, machine_name) => {
    this.setState({ createdMachineDetails: { id: machine_id, name: machine_name } });
    this.setState({ showSplashScreen: true });
  };

  openAssociateSensorsModal = () => {
    this.setSelectSensors(true);
  };

  closeModal = () => {
    this.props.close(this.state.createdMachineDetails.id);
  };

  render() {
    const {
      footer,
      assetType,
      showSplashScreen,
      selectSensors,
      associatedSensors,
      createdMachineDetails,
    } = this.state;

    const {
      buildType,
    } = this.props;

    const assetTypes = ['Rotating Machine', 'Valve', 'Steam Trap', 'Electric Panel'];

    return (
      <MachineBuilderContainer
        close={this.closeModal}
        footer={footer}
        buildType={buildType}
      >
        {/* 3 stages, one after the other: in MachineSelector choose machine type, build it in MachineBuilder, then AssociateSensors for connecting sensors */}
        {(_.isEmpty(this.props.machineBuilder.metadata) || this.props.machineBuilder.metadata.loading) && (
          <div style={{ height: '100%', marginTop: '25%' }}>
            <LoadingSvg center />
          </div>
        )}
        {!_.isEmpty(this.props.machineBuilder.metadata) && !this.props.machineBuilder.metadata.loading ? (
          <>
            {!assetType && !showSplashScreen && _.isEmpty(associatedSensors) && false && (
              <MachineSelector
                setAssetType={this.setAssetType}
                assetTypes={assetTypes}
                setTitle={this.setTitle}
              />)
            }
            {!showSplashScreen && _.isEmpty(associatedSensors) && (
              <MachineBuilder
                assetType={assetType}
                setTitle={this.setTitle}
                // setFooter={null}
                setFooter={this.setFooter}
                associateSensors={this.onClickContinue}
                cancel={this.closeModal}
                buildType={buildType}
              />)
            }
            {showSplashScreen && (
              <AssociateSensors
                setTitle={this.setTitle}
                setFooter={this.setFooter}
                associateSensors={this.associateSensors}
                setShowSensorModal={bool => this.setSelectSensors(bool)}
                machineName={createdMachineDetails.name}
                onShowClick={() => { }}
              />
            )}
            {selectSensors && (
              <SelectSensorModal
                close={() => this.setSelectSensors(false)}
                sensors={_.cloneDeep(this.props.sensors.unassociatedItems.object)}
                submitSensors={this.associateSensors}
              />
            )}
            {!_.isEmpty(associatedSensors) && !showSplashScreen && (
              <SensorList
                setFooter={this.setFooter}
                associatedSensors={associatedSensors}
                associateSensors={this.openAssociateSensorsModal}
                createdMachineId={createdMachineDetails.id}
                createdMachineName={createdMachineDetails.name}
                cancel={this.closeModal}
              />
            )}
          </>
        ) : (
            this.props.machineBuilder.metadata.error && (
              <div>{this.props.machineBuilder.metadata.error}</div>
            )
          )}
        {footer && (
          <FooterFlex left="0">
            {typeof footer === 'function' ? footer()
              : footer.items.map(Item =>
                <Item.Tag {...Item.props}>{Item.children}</Item.Tag>)}
          </FooterFlex>)
        }
      </MachineBuilderContainer>
    );
  }
}

MachineBuilderModal.propTypes = {
  buildType: PropTypes.string,
};

MachineBuilderModal.defaultProps = {
  buildType: 'machine',
};

const mapStateToProps = state => ({
  machineBuilder: state.machines.machineBuilder,
  sensors: state.sensors
});

const mapDispatchToProps = dispatch => ({
  machineBuilderActions: bindActionCreators(machineBuilderActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MachineBuilderModal);
