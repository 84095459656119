import React from 'react';
import PropTypes from 'prop-types';

const ComponentActiveIcon = ({ width, height }) => (
<svg width={width} height={height} viewBox="0 0 16 11" version="1.1">
    <defs>
        <linearGradient x1="49.9979904%" y1="16.8577661%" x2="49.9979904%" y2="99.3915416%" id="linearGradient-active-1">
            <stop stopColor="#B7D381" offset="0%" />
            <stop stopColor="#91B846" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="linearGradient-active-2">
            <stop stopColor="#91B846" offset="0%" />
            <stop stopColor="#B7D381" offset="100%" />
        </linearGradient>
        <linearGradient x1="49.9983702%" y1="-19.1685147%" x2="49.9983702%" y2="99.9976748%" id="linearGradient-active-3">
            <stop stopColor="#91B846" offset="0%" />
            <stop stopColor="#6D8C30" offset="100%" />
        </linearGradient>
        <linearGradient x1="49.9968335%" y1="-23.707854%" x2="49.9968335%" y2="96.869469%" id="linearGradient-active-4">
            <stop stopColor="#B7D381" offset="0%" />
            <stop stopColor="#91B846" offset="100%" />
        </linearGradient>
        <linearGradient x1="49.9997764%" y1="0%" x2="49.9997764%" y2="96.8602635%" id="linearGradient-active-5">
            <stop stopColor="#6D8C30" offset="0%" />
            <stop stopColor="#B7D381" offset="100%" />
        </linearGradient>
        <linearGradient x1="49.9979904%" y1="16.8577661%" x2="49.9979904%" y2="99.3915416%" id="linearGradient-active-6">
            <stop stopColor="#B7D381" offset="0%" />
            <stop stopColor="#91B846" offset="100%" />
        </linearGradient>
        <linearGradient x1="50.0014902%" y1="0%" x2="50.0014902%" y2="96.8616337%" id="linearGradient-active-7">
            <stop stopColor="#B7D381" offset="0%" />
            <stop stopColor="#91B846" offset="100%" />
        </linearGradient>
        <linearGradient x1="49.9979904%" y1="16.8577661%" x2="49.9979904%" y2="99.3915416%" id="linearGradient-active-8">
            <stop stopColor="#B7D381" offset="0%" />
            <stop stopColor="#91B846" offset="100%" />
        </linearGradient>
        <linearGradient x1="49.9979904%" y1="16.8577661%" x2="49.9979904%" y2="99.3915416%" id="linearGradient-active-9">
            <stop stopColor="#B7D381" offset="0%" />
            <stop stopColor="#91B846" offset="100%" />
        </linearGradient>
    </defs>
    <g id="PS---Web-app-UI-Kit-3-0-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3-0-1/p05/icons" transform="translate(-586.000000, -1274.000000)">
            <g id="activity" transform="translate(80.000000, 972.000000)">
                <g id="icons" transform="translate(350.000000, 0.000000)">
                    <g id="component_active-icon" transform="translate(156.000000, 299.000000)">
                        <g id="bound">
                            <rect id="Rectangle" x="0" y="0" width="16" height="16" />
                        </g>
                        <g id="ac_induction_motor_group" transform="translate(0.000000, 3.000000)">
                            <path d="M14.122449,2.44897959 L14.7860879,2.44897959 C15.0250704,2.44897959 15.0757606,2.52805847 15.0757606,2.76399938 C15.0757606,3.23224539 15.1020408,4.89794951 15.1020408,4.89794951 C15.1020408,4.89794951 15.0757409,6.56980358 15.0757409,7.03191899 C15.0757409,7.26874951 15.0340273,7.34693878 14.7929975,7.34693878 L14.122449,7.34693878" id="drive_end_bearing_path" fill="url(#linearGradient-active-1)" />
                            <polyline id="_-screw_compressor_drive_end_shaft_zone1" fill="url(#linearGradient-active-2)" transform="translate(15.551020, 4.897959) scale(-1, 1) translate(-15.551020, -4.897959) " points="16 5.30612245 15.723446 5.30612245 15.723446 5.19046962 15.1020408 5.19046962 15.1020408 4.89795918 15.1020408 4.60544875 15.723446 4.60544875 15.723446 4.48979592 16 4.48979592" />
                            <g id="base" transform="translate(0.244898, 0.000000)">
                                <path d="M2.98337554,8.27844593 L2.78577858,8.27844593 L2.77791751,1.49134726 L2.98337554,1.49134726 L2.98337554,8.27844593 Z M12.3290018,8.27844593 L12.1233492,8.27844593 L12.1233492,1.49134726 L12.3290018,1.49134726 L12.3290018,8.27844593 Z" id="Combined-Shape" fill="url(#linearGradient-active-3)" />
                                <polyline id="ac_induction_motor_gearbox" fill="url(#linearGradient-active-4)" points="8.67078245 0.946085562 8.66636546 0.000946874624 11.7388767 0.000946874624 11.7344597 0.946085562" />
                                <polyline id="path4032" fill="url(#linearGradient-active-5)" points="3.50037716 8.84874063 3.21099635 9.49713275 11.9131628 9.49713275 11.6238015 8.84874063" />
                                <polygon id="ac_induction_motor_base_path" fill="url(#linearGradient-active-6)" points="2.64995563 9.46938776 2.64995563 10.0408163 12.4467613 10.0408163 12.4467613 9.46938776" />
                                <polygon id="path4034" fill="url(#linearGradient-active-7)" points="2.9818189 0.951806263 12.1217925 0.951806263 12.1217925 8.85219277 2.9818189 8.85219277" />
                                <path d="M2.81055901,8.81632653 L1.05023292,8.81632653 C0.470053048,8.81632653 0.0253741387,8.26647636 0.0182938737,7.8821487 L0,6.39011754 L0.00914693686,3.40558559 L0.00914693686,1.91376967 C0.00914693686,1.42135047 0.487638896,0.979591837 1.05023292,0.979591837 L2.80523924,0.979591837 L2.81055901,8.81632653 L2.81055901,8.81632653 Z" id="path_free_end_bearing" fill="url(#linearGradient-active-8)" />
                                <path d="M12.3289045,0.951806263 L13.4104439,0.951806263 C13.7903632,0.951806263 13.8824584,1.07651754 13.8824584,1.45994258 L13.8824584,8.34405645 C13.8824584,8.7274815 13.776042,8.85219277 13.4216712,8.85219277 L12.3289045,8.85219277 L12.3289045,0.951806263 L12.3289045,0.951806263 Z" id="path4040" fill="url(#linearGradient-active-9)" />
                            </g>
                            <g id="lines" transform="translate(3.600000, 1.300000)" fill="#6D8C30">
                                <polygon id="path4044" points="0.0186095941 6.95952849 7.92557412 6.95952849 7.92722185 7.18018973 0.0108943665 7.18018973" />
                                <polygon id="path4046" points="0.0186095941 6.42178261 7.92557412 6.42178261 7.92722185 6.64244385 0.0108943665 6.64244385" />
                                <polygon id="path4048" points="0.0186095941 5.90139609 7.92557412 5.90139609 7.92722185 6.12203761 0.0108943665 6.12203761" />
                                <polygon id="path4050" points="0.0186095941 5.35280061 7.92557412 5.35280061 7.92722185 5.57346185 0.0108943665 5.57346185" />
                                <polygon id="path4052" points="0.0186095941 4.83478128 7.92557412 4.83478128 7.92722185 5.05544252 0.0108943665 5.05544252" />
                                <polygon id="path4054" points="0.0186095941 4.2970354 7.92557412 4.2970354 7.92722185 4.51769664 0.0108943665 4.51769664" />
                                <polygon id="path4056" points="0.0186095941 3.77664889 7.92557412 3.77664889 7.92722185 3.99731013 0.0108943665 3.99731013" />
                                <polygon id="path4058" points="0.0186095941 3.22805341 7.92557412 3.22805341 7.92722185 3.44871465 0.0108943665 3.44871465" />
                                <polygon id="path4060" points="0.0186095941 2.67531534 7.92557412 2.67531534 7.92722185 2.89597658 0.0108943665 2.89597658" />
                                <polygon id="path4062" points="0.0186095941 2.13756946 7.92557412 2.13756946 7.92722185 2.3582307 0.0108943665 2.3582307" />
                                <polygon id="path4064" points="0.0186095941 1.61718295 7.92557412 1.61718295 7.92722185 1.83784419 0.0108943665 1.83784419" />
                                <polygon id="path4066" points="0.0186095941 1.06858747 7.92557412 1.06858747 7.92722185 1.28922898 0.0108943665 1.28922898" />
                                <polygon id="path4068" points="0.0186095941 0.552540796 7.92557412 0.552540796 7.92722185 0.773202037 0.0108943665 0.773202037" />
                                <polygon id="path4070" points="0.0186095941 0.00414257648 7.92557412 0.00414257648 7.92722185 0.22478409 0.0108943665 0.22478409" />
                            </g>
                            <g id="locations" transform="translate(0.000000, 0.734694)" fill="#6D8C30">
                                <rect id="loc_-ac_induction_motor_free_end_pad_horz" x="0" y="1.46938776" width="1" height="1" />
                                <polygon id="loc_-ac_induction_motor_free_end_pad_vert" points="3.1844376 0 3.99978168 0 4 0.244897959 3.18367347 0.244897959" />
                                <polygon id="loc_-ac_induction_motor_drive_end_pad_vert" points="14.1232131 1.46938776 14.9385572 1.46938776 14.9387755 1.71428571 14.122449 1.71428571" />
                                <rect id="loc_-ac_induction_motor_drive_end_pad_horz" x="15.1020408" y="2.28571429" width="1" height="1" />
                            </g>
                            <polyline id="path4078" fill="#6D8C30" points="10.7755102 9.46938776 10.7755102 9.30612245 10.2857143 9.30612245 10.2857143 9.4679309" />
                            <polyline id="path4080" fill="#6D8C30" points="5.2244898 9.46938776 5.2244898 9.30612245 4.73469388 9.30612245 4.73469388 9.4679309" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

ComponentActiveIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

ComponentActiveIcon.defaultProps = {
  width: 16,
  height: 11,
};

export default ComponentActiveIcon;
