import React from 'react';
import MsgContainer from '../atoms/MsgContainer';


const Message = ({ message, ...props }) => (
  <MsgContainer {...props}>
    <span>{message}</span>
  </MsgContainer>
);

export default Message;
