import { Component } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

import { yAxisGridlines, xAxisGridlines, ticksBottom } from '../utils/gridUtils';
import { drawDataline, addHorizontalBrush } from '../utils/graphUtils';


class LineContext extends Component {
  // use new static method to derive state from props
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_.isEqual(nextProps.selectedRange, prevState.selectedRange)) {
      return {
        selectedRange: nextProps.selectedRange
      };
    }
    if (!_.isEqual(nextProps.activeData, prevState.activeData)) {
      return {
        activeData: nextProps.activeData
      };
    }
    // do not update state otherwise
    return null;
  }

  constructor(props) {
    super(props);
    this.brushed = this.brushed.bind(this);
    this.redraw = this.redraw.bind(this);
    this.adjustSelectedRange = this.adjustSelectedRange.bind(this);
    this.zoomHandler = this.zoomHandler.bind(this);
    this.setBrushToSelection = this.setBrushToSelection.bind(this);
    this.brushended = this.brushended.bind(this);

    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.state.selectedRange, prevState.selectedRange)) {
      this.adjustSelectedRange();
    }
    if (!this.brushSelection && this.props.brushSelected) {
      d3.select(`g.${this.props.chartName}.brush`).call(this.brush.move, [this.props.brushSelected[0], this.props.brushSelected[1]]);
    }
    if (!_.isEqual(this.state.activeData, prevState.activeData)) {
      this.state.activeData.forEach((active, idx) => {
        const opacity = active ? 1 : 0;
        d3.select(`.${this.props.chartName}.noClipDataline.idx-${idx}`)
          .attr('opacity', opacity);
      });
    }
  }

  // setting range 1D, 7D, 30D and 'All'
  adjustSelectedRange() {
    // guard first load will
    if (!this.brush) return;

    d3.select(`g.${this.props.chartName}.brush`)
      .call(this.brush.move, null);

    if (this.state.selectedRange === 'All') {
      return;
    }

    // find dataline that has some data
    let d;
    let idx = 0;
    while (d === undefined) {
      const data = this.props.data[idx];
      if (data.length > 0) {
        d = data[data.length - 1];
      }
      idx++;
      if (idx > this.props.data.length - 1) d = false;
    }

    // guard against no data found
    if (!d) return;

    let newEnd;
    switch (this.state.selectedRange) {
      case '1D':
        newEnd = d.x - 24 * 60 * 60 * 1000;
        break;
      case '7D':
        newEnd = d.x - 24 * 60 * 60 * 1000 * 7;
        break;
      case '30D':
        newEnd = d.x - 24 * 60 * 60 * 1000 * 30;
        break;
      default:
        break;
    }

    // guard for selection over the total chart length
    let newBrushStartPos = this.props.parent.x(newEnd);
    if (newBrushStartPos < 0) newBrushStartPos = 0;

    d3.select(`g.${this.props.chartName}.brush`)
      .call(this.brush.move, [newBrushStartPos, this.props.parent.x(d.x)]);
  }

  drawGraph(ctx) {
    this.ctx = ctx;
    const options = {
      brushed: this.brushed,
      brushended: this.brushended,
      onBrushOverlayClick: this.onBrushOverlayClick
    };
    const {
      chartType,
      xUnit,
      ampType,
      disableInitialBrush,
      tag_type
    } = this.props;
    if (
      (chartType === 'spectrum' || chartType === 'demod_spectrum') &&
      (ampType === 'velocity' || ampType === 'normal') && !_.isEmpty(this.props.data) &&
      (tag_type === 'vibration' || tag_type === 'current') &&
      !disableInitialBrush
    ) {
      let maxExtent = tag_type === 'current' ? 180 : 400;
      if (xUnit === 'CPM') maxExtent *= 60;
      else if (xUnit === 'Orders') maxExtent = 10;
      options.extent = [0, this.ctx.x(maxExtent)];
    }
    d3.selectAll(`.${ctx.props.chartName}.brush`).remove();
    this.brush = addHorizontalBrush(this.ctx.chart, this.props.chartName, options);
    d3.select(`g.${this.props.chartName}.brush`).call(this.brush.move, null);
  }

  onBrushOverlayClick = () => {
    d3.event.stopImmediatePropagation();
    if (!this.props.xAxisLocked && this.props.resetChartZoom) {
      this.props.resetChartZoom('x');
    }
  };

  setBrushToSelection(selection) {
    d3.select(`#${this.props.chartName}-clip > rect`)
      .attr('x', selection[0])
      .attr('width', selection[1] - selection[0]);
    d3.select(`g.${this.props.chartName}.brush`).call(this.brush.move, selection);
  }

  resetBrushSelection = () => {
    d3.select(`g.${this.ctx.props.chartName}.brush`).call(this.brush.move, null);
  };

  brushended() {
    if (d3.event.sourceEvent && (d3.event.sourceEvent.type === 'zoom')) return;
    const selection = d3.event.selection;
    if (this.props.xAxisLocked) d3.selectAll(`.${this.props.chartName}.brush>.handle`).style('display', 'none');
    if (selection) this.props.parent.setNewTransform(selection);
  }

  zoomHandler(ctx) {
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'brush') return; // ignore zoom-by-brush
    const transform = d3.event.transform;

    if (!transform) return;
    const xRange = ctx.x.range().map(transform.invertX, transform);

    d3.select(`#${this.props.chartName}-clip > rect`)
      .attr('x', xRange[0])
      .attr('width', xRange[1] - xRange[0]);

    let brushRange = [];
    if (xRange[0] < ctx.x.range()[0] || xRange[1] > ctx.x.range()[1]) {
      if (xRange[0] < ctx.x.range()[0] && xRange[1] > ctx.x.range()[1]) {
        brushRange = [0, ctx.x.range()[1]];
        d3.select(`g.${this.props.chartName}.brush`).call(this.brush.move, brushRange);
      } else if (xRange[0] < ctx.x.range()[0] && xRange[1] <= ctx.x.range()[1]) {
        brushRange = [ctx.x.range()[0], xRange[1]];
        d3.select(`g.${this.props.chartName}.brush`).call(this.brush.move, brushRange);
      } else if (xRange[0] >= ctx.x.range()[0] || xRange[1] > ctx.x.range()[1]) {
        brushRange = [xRange[0], ctx.x.range()[1]];
        d3.select(`g.${this.props.chartName}.brush`).call(this.brush.move, brushRange);
      }
    } else {
      brushRange = xRange;
      d3.select(`g.${this.props.chartName}.brush`).call(this.brush.move, xRange);
    }
    if (this.props.parent.refNode.storeBrushSelection) this.props.parent.refNode.storeBrushSelection(brushRange);

    const s = d3.brushSelection(d3.select(`g.${this.ctx.props.chartName}.brush`).node());
    if (s !== null) {
      this.brushSelection = [this.ctx.x2.invert(s[0]), this.ctx.x2.invert(s[1])];
    } else this.brushSelection = this.props.parent.x.domain();
    if (this.props.brushCb) {
      if (this.props.multiBrush) this.props.brushCb(s);
      else this.props.brushCb(this.brushSelection);
    }
  }

  brushed() {
    if (d3.event.sourceEvent && (d3.event.sourceEvent.type === 'zoom')) return;
    const selection = d3.event.selection;
    if (!selection) return;
    d3.select(`#${this.props.chartName}-clip > rect`)
      .attr('x', selection[0])
      .attr('width', selection[1] - selection[0]);
    if (!this.props.parent.x) return;
    // this.props.parent is a reference to the graph that is attached to this context chart
    this.props.parent.x.domain(selection.map(this.props.parent.x2.invert, this.props.parent.x2));
    this.props.parent.redraw();
    // TODO/FIX/IMPROVE: baseline brush rescale should not be here (maybe)
    // if (this.props.parent.props.baselineStart) {
    //   d3.select(`g.${this.props.parent.props.chartName}.brush`)
    //     .call(this.props.parent.brush.move, [this.props.parent.x(this.props.parent.props.baselineStart), this.props.parent.x(this.props.parent.props.baselineEnd)]);

    //   d3.selectAll(`.${this.props.parent.props.chartName}.circle`).remove();
    //   this.props.parent.props.measurementsToDiscard.forEach((dataPoint, idx) => {
    //     if (!this.props.activeData[idx]) return;
    //     dataPoint.forEach((d) => {
    //       const tmp = drawCircleOnPos(this.props.parent.chart, this.props.parent.x(d.x), this.props.parent.y(d.y), 4, `${this.props.parent.props.chartName}`);
    //       tmp
    //         .attr('stroke', '#333')
    //         .attr('fill', '#fff');
    //     });
    //   });
    // }
    if (this.props.parent.refNode.storeBrushSelection) this.props.parent.refNode.storeBrushSelection(selection);
    // brush values after bursh TODO: why this and not line 117 enough?
    const s = d3.brushSelection(d3.select(`g.${this.ctx.props.chartName}.brush`).node());
    if (s !== null) {
      this.brushSelection = [this.ctx.x2.invert(s[0]), this.ctx.x2.invert(s[1])];
    } else this.brushSelection = this.props.parent.x.domain();
    if (this.props.brushCb) this.props.brushCb(this.brushSelection);
  }

  redraw(ctx) {
    this.xAxisGridlines
      .call(d3.axisTop(ctx.x)
        .ticks(6)
        .tickFormat(''));

    this.ticksBottom
      .call(d3.axisBottom(ctx.x)
        .ticks(6));

    if (!ctx.props.utilCtx) {
      ctx.props.data.forEach((dataSet, idx) => {
        if (this.props.parent.props.activeData[idx]) {
          this.noClipDataline[idx]
            .attr('d', d3.line()
              .curve(d3.curveLinear)
              .x(d => ctx.x(d.x))
              .y(d => ctx.y(d.y)));
        }
      });
    } else if (ctx.props.utilCtx) {
      ctx.props.data.forEach((dataSet, idx) => {
        const orderValue = (idx + 1) % 3;
        if (this.props.parent.props.activeData[idx]) {
          this.noClipDataline[idx]
            .attr('d', d3.line()
              .defined((d) => {
                if (d.y === idx) {
                  return true;
                } return false;
              })
              .x(d => ctx.x(d.x))
              .y(() => ctx.y(orderValue)));
        }
      });
    }

    // rescale brush extent and apply that to brush element
    this.brush.extent([[0, 0], [ctx.chart.getBoundingClientRect().width, ctx.chart.getBoundingClientRect().height]]);
    d3.select(`g.${ctx.props.chartName}.brush`)
      .attr('width', ctx.chart.getBoundingClientRect().width)
      .call(this.brush);

    // rescale current brush selection if it exists
    if (!this.brushSelection) {
      d3.select(`g.${ctx.props.chartName}.brush`).call(this.brush.move, ctx.x.range());
    }
    if (this.brushSelection) {
      d3.select(`g.${ctx.props.chartName}.brush`).call(this.brush.move, [ctx.x(this.brushSelection[0]), ctx.x(this.brushSelection[1])]);
    }
  }

  drawAxis(ctx) {
    const { chartName } = this.props;
    d3.selectAll(`.${chartName}.axis`).remove();
    // draw grid lines and ticks (values along axises)
    this.yAxisGridlines = yAxisGridlines(ctx.chart, ctx.y, 0, ` ${chartName}`);
    this.xAxisGridlines = xAxisGridlines(ctx.chart, ctx.x, 6, `${chartName}`);
    this.ticksBottom = ticksBottom(ctx.chart, ctx.x, 6, `${chartName}`, 0);
  }

  drawDataLines(ctx) {
    // TODO: utils chart context lines override regular once is clunky here
    if (ctx.props.utilCtx) {
      const { chartName } = this.props;

      d3.selectAll(`.${chartName}.dataline`).remove();
      d3.selectAll(`.${chartName}.noClipDataline`).remove();

      ctx.chartDatalines = [];
      this.noClipDataline = [];

      ctx.props.data.forEach((dataSet, idx) => {
        const orderValue = (idx + 1) % 3;
        const dataline = drawDataline(
          ctx.chart,
          dataSet,
          ctx.x,
          ctx.y,
          `${chartName} dataline idx-${idx}`,
          {
            lineFunc: d3.line()
              .curve(d3.curveLinear)
              .defined((d) => {
                if (d.y === idx) {
                  return true;
                } return false;
              })
              .x(d => ctx.x(d.x))
              .y(() => ctx.y(orderValue))
          }
        );

        if (!ctx.props.activeData[idx]) dataline.attr('opacity', 0);
        ctx.chartDatalines.push(dataline);

        const noClipDataline = drawDataline(
          ctx.chart,
          dataSet,
          ctx.x,
          ctx.y,
          `${chartName} noClipDataline idx-${idx}`,
          {
            lineFunc: d3.line()
              .curve(d3.curveLinear)
              .defined((d) => {
                if (d.y === idx) {
                  return true;
                } return false;
              })
              .x(d => ctx.x(d.x))
              .y(() => ctx.y(orderValue))
          }
        );

        if (!ctx.props.activeData[idx]) noClipDataline.attr('opacity', 0);
        this.noClipDataline.push(noClipDataline);
      });
      return;
    }

    const { chartName } = this.props;
    d3.selectAll(`.${chartName}.noClipDataline`).remove();
    // add a dataline that wont get clipped (shows a 'not selected line')
    this.noClipDataline = [];

    ctx.props.data.forEach((dataSet, idx) => {
      if (this.props.parent.props.activeData[idx]) {
        this.noClipDataline.push(drawDataline(
          ctx.chart,
          dataSet,
          ctx.x,
          ctx.y,
          `${chartName} noClipDataline idx-${idx}`,
          {
            lineFunc: d3.line()
              .curve(d3.curveLinear)
              .x(d => ctx.x(d.x))
              .y(d => ctx.y(d.y))
          }
        ));
      }
    });
  }

  render() {
    return null;
  }
}

export default LineContext;
