import styled from 'styled-components';
import InputField from './InputField';

const FormContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${InputField} {
    flex-grow: 0;
    flex-shrink: 1;
    margin-left: 10px;
    margin-right: 10px;
    flex-basis: calc(33% - 20px);
    ${props => props.theme.media.desktop`flex-basis: calc(50% - 20px);`}
    ${props => props.theme.media.tablet`flex-basis: calc(100% - 20px);`}
  }
`;

FormContainer.displayName = 'FormContainer';

export default FormContainer;
