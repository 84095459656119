import styled from 'styled-components';
import PropTypes from 'prop-types';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: ${props => props.left};
  width: ${props => props.width};
  opacity: 0.8;
  height: 100%;
  background-color: black;
  z-index: 999;
`;

Overlay.propTypes = {
  width: PropTypes.string.isRequired,
  left: PropTypes.string,
};

Overlay.defaultProps = {
  left: '',
};

export default styled(Overlay)``;
