import React, { useState } from 'react';
import { Checkbox, FlexContainer } from 'common/components/atoms';
import Modal from 'common/components/organisms/Modal';
import { humanize } from 'common/helpers';
import { ColumnIcon } from 'common/images/FaIcons';


function EditDropdown({ allColumns, selectedColumns, toggleColumn }) {
  const [showColumnEditor, setShowColumnEditor] = useState(false);

  return (
    <div>
      <span style={{ cursor: 'pointer' }} onClick={() => setShowColumnEditor(true)}>
        <ColumnIcon />
      </span>
      {showColumnEditor && (
        <Modal
          title="Select devices columns"
          padding="0"
          outerPadding="0"
          headerPadding="1rem"
          headerRadius="5px"
          width="30rem"
          close={() => setShowColumnEditor(false)}
          showHeaderBorder
        >
          <FlexContainer direction="column" margin="1rem">
            {allColumns.map(column => (
              <FlexContainer alignItems="center" padding="10px" onClick={() => toggleColumn(column)}>
                <Checkbox value={selectedColumns.includes(column)} />
                <span style={{ marginLeft: '10px' }}>{humanize(column)}</span>
              </FlexContainer>
            ))}
          </FlexContainer>
        </Modal>
      )}
    </div>
  );
}

export default EditDropdown;
