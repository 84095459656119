import { generateQueryString } from 'common/utils';
import { history } from 'common/helpers';

export const navToAssetsPage = (widget_name, data, filterOptions = null, filterName = null, swithAccount = () => {}) => {
  const route = '/assets';
  let filter;
  if (widget_name === 'incomplete_asset_setup_breakdown' || widget_name === 'open_events_breakdown') {
    filter = [filterOptions.filter(
      item => !item.groupName
    )[data]];
    filter = generateQueryString({ [filterName]: filter });
  } else if (widget_name === 'asset_health_scores_breakdown' || widget_name === 'vfd_breakdown') {
    filter = [filterOptions[data]];
    filter = generateQueryString({ [filterName]: filter });
  } else if (widget_name === 'plant_asset_health') {
    filter = generateQueryString({ condition: [data.text] });
  } else if (widget_name === 'current_plant_utilization') {
    filter = generateQueryString({ state: [data.text] });
  } else if (widget_name === 'alarm_review_and_action_report') {
    let account_id = null;
    filter = generateQueryString({ alarm_metrics: [data] });
    filterOptions.forEach((filter) => {
      if (filter.name === 'account_id') {
        account_id = filter.value;
      }
    });
    if (account_id) {
      swithAccount(account_id, () => {
        history.push({
          pathname: route,
          search: filter
        });
      });
    }
  }

  if (widget_name !== 'alarm_review_and_action_report') {
    history.push({
      pathname: route,
      search: filter
    });
  }
};

export const navToDevicesPage = (widget_name, data, filterOptions, switchAccount = () => {}) => {
  const route = '/devices';
  let filter;
  if (widget_name === 'firmware') {
    filter = generateQueryString({ [filterOptions[data].name]: [data.text] });
  } else if (widget_name === 'device_unreachable_above_fourteen_days_count'
    || widget_name === 'devices_with_continuous_outliers_count'
    || widget_name === 'devices_with_inconsistent_vibration_levels') {
    if (widget_name === 'devices_with_continuous_outliers_count') {
      filterOptions.push({ name: 'other_filters', op: 'in', value: ['devices_with_continuous_outliers'] });
      filter = generateQueryString({ other_filters: ['devices_with_continuous_outliers'] });
    } else if (widget_name === 'device_unreachable_above_fourteen_days_count') {
      filterOptions.push({ name: 'last_measurement', op: 'in', value: ['unreachable_extended'] });
      filter = generateQueryString({ last_measurement: ['unreachable_extended'] });
    } else if (widget_name === 'devices_with_inconsistent_vibration_levels') {
      filterOptions.push({ name: 'other_filters', op: 'in', value: ['devices_with_inconsistent_vibrations'] });
      filter = generateQueryString({ other_filters: ['devices_with_inconsistent_vibrations'] });
    }
    let account_id = null;
    filterOptions.forEach((filter) => {
      if (filter.name === 'account_id') {
        account_id = filter.value[0];
      }
    });
    if (account_id) {
      switchAccount(account_id, () => {
        history.push({
          pathname: route,
          search: filter
        });
      });
    }
  } else {
    filter = generateQueryString({ [filterOptions[data].name]: filterOptions[data].value });
  }
  history.push({
    pathname: route,
    search: filter
  });
};

export const navToActiviyPage = (widget_name, data, filterOptions, swithAccount = () => {}) => {
  if (data.tooltip === 'Others') return;

  const route = '/activity';
  let activityListFilters = JSON.parse(localStorage.getItem('activityListFilters'));

  if (widget_name === 'not_analysed_open_alarms') {
    let account_id = null;
    filterOptions.forEach((filter) => {
      if (filter.name === 'account_id') {
        account_id = filter.value;
      }
    });

    if (account_id) {
      activityListFilters = {
        ...activityListFilters,
        [account_id]: {
          type: ['event'],
          analysis: [data]
        }
      };
      localStorage.setItem('activityListFilters', JSON.stringify(activityListFilters));
      swithAccount(account_id, () => {
        history.push({
          pathname: route,
        });
      });
    }
  } else {
    const defect = filterOptions.rawData.find(d => d.name === data.tooltip.split(' (')[0]);
    const defect_id = defect && defect.id;
    if (!defect_id) return;

    activityListFilters = {
      ...activityListFilters,
      [filterOptions.account_id]: {
        type: ['task', 'analyst_summary'],
        defect: [defect_id]
      }
    };
    localStorage.setItem('activityListFilters', JSON.stringify(activityListFilters));
    history.push({
      pathname: route,
    });
  }
};
