import React from 'react';
import PropTypes from 'prop-types';

const MultilineIconSvg = ({ width, height, fill }) => (
<svg height={height} viewBox="0 0 16 16" width={width} xmlns="http://www.w3.org/2000/svg">
    <g fill={fill} fillRule="evenodd">
        <path d="m0 0h16v16h-16z" fill="#fff" fillRule="nonzero" />
        <g stroke="#737473" strokeWidth="1.5" transform="translate(1.6 1)">
            <path d="m0 13.1180718 2.79140271-1.4515282 2.29497738 1.4515282 2.13582013-1.4515282 5.05514708-5.98962052" />
            <path d="m0 8.11807176 2.79140271-1.45152814 2.29497738 1.45152814 2.13582013-1.45152814 5.05514708-5.98962054" />
        </g>
    </g>
</svg>

);
MultilineIconSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

MultilineIconSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: 'none'
};

export default MultilineIconSvg;
