import * as _ from 'lodash';
import { DEFECT_OPTIONS_ARRAY } from 'common/constants';
import { accountMenuItems } from './constants/routes.constants';

export const isUnsavedAnalystSummaryExists = (localSummary, reduxSummary) => {
  if (localSummary.analyst_id) {
    let suspectedDefect = DEFECT_OPTIONS_ARRAY.filter(option => option.id === reduxSummary.suspected_defect);
    if (suspectedDefect.length > 0) suspectedDefect = suspectedDefect[0].name;
    return !(
      _.isEqual(localSummary.suspected_defect, suspectedDefect) &&
      _.isEqual(localSummary.description, reduxSummary.description)
    );
  }
  return !!(localSummary.condition || localSummary.suspected_defect || localSummary.description);
};

export const getFlattenedAccountMenuRoutes = (excludeRouteKeys = []) => {
  return _.flatMap(accountMenuItems, ({ items }) =>
    items.filter(item => !excludeRouteKeys.includes(item.key)));
};

export const getAccountMenuItems = (excludeRouteKeys = [], excludeGroupKeys = []) => {
  const menuItems = accountMenuItems.filter(item => !excludeGroupKeys.includes(item.key));
  return _.flatMap(menuItems, menuItem => [
    {
      key: menuItem.key,
      groupName: menuItem.displayName
    },
    ...menuItem.items.filter(item => !excludeRouteKeys.includes(item.key))
  ]);
};

export const getChartSearchParams = (activity) => {
  let params = `?tag_id=${activity.tag_id}&timestamp=${activity.timestamp}`;
  if (activity.threshold_type) params = `${params}&threshold_type=${activity.threshold_type}`;
  if (activity.measurement_type) params = `${params}&measurement_type=${activity.measurement_type}`;
  if (activity.event_type && (activity.event_type === 'vibration_acceleration_warning' || activity.event_type === 'vibration_acceleration_critical')) params = `${params}&amp_type=acceleration`;
  else if (activity.event_type && (activity.event_type === 'vibration_demod_warning' || activity.event_type === 'vibration_demod_critical')) params = `${params}&amp_type=demod`;
  else if (activity.event_type && (activity.event_type === 'vibration_warning' || activity.event_type === 'vibration_critical')) params = `${params}&amp_type=velocity`;
  return params;
};
