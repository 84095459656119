import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FilterIconSvg = ({ width, height, fill }) => (
<svg width={width} height={height} viewBox="0 0 16 16" version="1.1" style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g id="VFD" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="3-0-3-VFD" transform="translate(-1160.000000, -25.000000)">
      <g id="top_bar-spectrum">
        <g id="filter-pressed-icon" transform="translate(1160.000000, 25.000000)">
          <rect id="bound" fill="#FFFFFF" x={0} y={0} width={16} height={16} />
          <g id="icon" transform="translate(2.000000, 1.000000)" fill={fill} fillRule="nonzero">
            <path d="M12.3640833,2.0048 C12.6351582,1.62168681 12.6750808,1.11362282 12.4673741,0.690298178 C12.2596675,0.266973533 11.8395509,0.000168546197 11.3804167,-8.73112779e-08 L1.62066667,-8.73112779e-08 C1.16343197,-0.000175505364 0.744568548,0.264249932 0.535850555,0.684836537 C0.327132562,1.10542314 0.363678102,1.61140459 0.6305,1.99528 L4.47633333,7.69104 L4.47633333,12.17944 C4.47629394,12.5337644 4.61998941,12.8718652 4.87245914,13.1114836 C5.12492886,13.3511021 5.46302866,13.4702721 5.8045,13.44 C6.10377168,13.4493463 6.39939362,13.3700735 6.65654167,13.21152 C8.13691667,12.25952 7.98091667,12.35808 7.995,12.34744 C8.30115264,12.1088495 8.48156795,11.7358061 8.4825,11.33944 L8.4825,7.69272 L12.3640833,2.0048 Z M7.252375,7.0672 C7.17168242,7.18531062 7.12835025,7.32635836 7.12833333,7.47096 L7.12833333,11.10032 C7.12843021,11.1990652 7.07821055,11.2905625 6.99616667,11.34112 C6.8185,11.44976 6.49620833,11.64744 6.235125,11.80424 C6.1513805,11.8544935 6.04805633,11.8547019 5.96412286,11.8047864 C5.88018939,11.754871 5.82841732,11.6624266 5.82833333,11.56232 L5.82833333,7.47096 C5.82856355,7.32770209 5.78599961,7.18786535 5.70645833,7.07056 L2.17641667,1.84072 C2.11870875,1.75516539 2.11178149,1.64354043 2.15844106,1.5510631 C2.20510063,1.45858576 2.29761203,1.40055996 2.3985,1.40055996 L10.596625,1.40055996 C10.6977898,1.40055996 10.7905523,1.45874024 10.8371568,1.5515699 C10.8837613,1.64439956 10.8764376,1.75634384 10.8181667,1.84184 L7.252375,7.0672 Z" id="Shape" />
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
);


FilterIconSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

FilterIconSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: '#3C3D3A'
};


export default styled(FilterIconSvg)``;
