import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse } from 'common/helpers';

import { erroneousReadingsConstants } from './constants/erroneousReadings.constants';

export const getErroneousReadings = (filters, start_date, end_date, page) => (dispatch, getState) => {
  const request = page => ({ type: erroneousReadingsConstants.GET_SUMMARIZED_ERRONEOUS_READINGS_REQUEST, page });
  const success = (erroneousReadings, page, total_pages, total_count) => ({ type: erroneousReadingsConstants.GET_SUMMARIZED_ERRONEOUS_READINGS_SUCCESS, erroneousReadings, page, total_pages, total_count });
  const failure = error => ({ type: erroneousReadingsConstants.GET_SUMMARIZED_ERRONEOUS_READINGS_FAILURE, error });

  const summarizedErroneousReadingsState = getState().erroneousReadings.summarizedErroneousReadings;
  const params = {
    days: erroneousReadingsConstants.NUMBER_OF_DAYS_ERRONEOUS,
    page: page || summarizedErroneousReadingsState.items.page,
    filters: filters || summarizedErroneousReadingsState.filters.filters,
    start_date: start_date || summarizedErroneousReadingsState.filters.start_date,
    end_date: end_date || summarizedErroneousReadingsState.filters.end_date,
    per_page: erroneousReadingsConstants.NUMBER_OF_ERRONEOUS_READINGS_PER_PAGE
  };
  dispatch(request(params.page));
  return handleResponse(axiosInstance.get(ENDPOINT.GET_SUMMARIZED_ERRONEOUS_READINGS, { params })).then(
    (res) => {
      let items = [];
      if (res.page === 1) {
        items = res.items;
      } else {
        items = res.items.filter(reading => !summarizedErroneousReadingsState.items.object.find(i => i.id === reading.id));
      }
      dispatch(success(items, res.page, res.total_pages, res.total));
      return res;
    },
    (err) => {
      dispatch(failure(err));
      throw err;
    }
  );
};

export const getSensorErroneousReadings = (filters, start_date, end_date, page, sensor_id) => (dispatch, getState) => {
  const request = page => ({ type: erroneousReadingsConstants.GET_ERRONEOUS_READINGS_REQUEST, page });
  const success = (erroneousReadings, page, total_pages, total_count, sensor_id) => ({ type: erroneousReadingsConstants.GET_ERRONEOUS_READINGS_SUCCESS, erroneousReadings, page, total_pages, total_count, sensor_id });
  const failure = error => ({ type: erroneousReadingsConstants.GET_ERRONEOUS_READINGS_FAILURE, error });

  const erroneousReadingsState = getState().erroneousReadings.sensorErroneousReadings;
  const params = {
    days: erroneousReadingsConstants.NUMBER_OF_DAYS_SENSOR_ERRONEOUS,
    page: page || erroneousReadingsState.items.page,
    filters: filters || erroneousReadingsState.filters.filters,
    start_date: start_date || erroneousReadingsState.filters.start_date,
    end_date: end_date || erroneousReadingsState.filters.end_date,
    per_page: erroneousReadingsConstants.NUMBER_OF_SENSOR_ERRONEOUS_READINGS_PER_PAGE,
    sensor_id: sensor_id || erroneousReadingsState.items.sensor_id
  };
  dispatch(request(params.page));
  return handleResponse(axiosInstance.get(ENDPOINT.ERRONEOUS_READINGS(null), { params })).then(
    (res) => {
      let items = [];
      if (res.page === 1) {
        items = res.items;
      } else {
        items = res.items.filter(reading => !erroneousReadingsState.items.object.find(i => i.id === reading.id));
      }
      dispatch(success(items, res.page, res.total_pages, res.total, params.sensor_id));
      return res;
    },
    (err) => {
      dispatch(failure(err));
      throw err;
    }
  );
};

export const loadMoreSummarizedErroneousReadings = () => (dispatch, getState) => {
  const summarizedErroneousReadingsStateItems = getState().erroneousReadings.summarizedErroneousReadings.items;
  const page = summarizedErroneousReadingsStateItems.page;
  if (summarizedErroneousReadingsStateItems.listLoading) return;
  dispatch({ type: erroneousReadingsConstants.INCREASE_ERRONEOUS_PAGE, page: page + 1 });
  dispatch(getErroneousReadings(null, null, null, page + 1));
};

export const loadMoreErroneousReadings = () => (dispatch, getState) => {
  const erroneousReadingsStateItems = getState().erroneousReadings.sensorErroneousReadings.items;
  const page = erroneousReadingsStateItems.page;
  if (erroneousReadingsStateItems.listLoading) return;
  dispatch({ type: erroneousReadingsConstants.INCREASE_SENSOR_ERRONEOUS_PAGE, page: page + 1 });
  dispatch(getSensorErroneousReadings(null, null, null, page + 1, null));
};

export const getWaveform = (tag_id, timestamp) => () =>
  handleResponse(axiosInstance.get(ENDPOINT.GET_ERRONEOUS_READINGS_WAVEFORM(tag_id), { params: { timestamp } })).then(
    res => res,
    (err) => {
      throw err;
    }
  );


export const getSpectrum = (tag_id, timestamp) => () =>
  handleResponse(axiosInstance.get(ENDPOINT.GET_ERRONEOUS_READINGS_SPECTRUM(tag_id), { params: { timestamp } })).then(
    res => res,
    (err) => {
      throw err;
    }
  );

export const removeFromOutlierList = id => () =>
  handleResponse(axiosInstance.delete(ENDPOINT.ERRONEOUS_READINGS(id))).then(
    res => res,
    (err) => {
      throw err;
    }
  );

export const validate = id => () =>
  handleResponse(axiosInstance.put(ENDPOINT.ERRONEOUS_READINGS(id))).then(
    res => res,
    (err) => {
      throw err;
    }
  );

export const setSummarizedErroneousReadingsFilters = (filters, start_date, end_date) => (dispatch) => {
  dispatch({ type: erroneousReadingsConstants.SET_SUMMARIZED_ERRONEOUS_READINGS_FILTERS, filters, start_date, end_date });
  dispatch({ type: erroneousReadingsConstants.CLEAR_SUMMARIZED_ERRONEOUS_READINGS_LIST });
  return dispatch(getErroneousReadings(filters, start_date, end_date, 1));
};

export const setSensorErroneousReadingsFilters = (filters, start_date, end_date, sensor_id) => (dispatch) => {
  dispatch({ type: erroneousReadingsConstants.SET_ERRONEOUS_READINGS_FILTERS, filters, start_date, end_date });
  dispatch({ type: erroneousReadingsConstants.CLEAR_ERRONEOUS_READINGS_LIST });
  return dispatch(getSensorErroneousReadings(filters, start_date, end_date, 1, sensor_id));
};
