import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MoreIconSvg = ({ width, height, fill, className, ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 16 4" className={className} style={{ cursor: 'pointer' }} {...rest}>
    <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3-3-0-Machines-Charts" transform="translate(-1194.000000, -209.000000)">
        <g id="charts" transform="translate(80.000000, 147.000000)">
          <g id="1" transform="translate(0.000000, 36.000000)">
            <g id="top" transform="translate(20.000000, 20.000000)">
              <g id="options" transform="translate(1022.000000, 0.000000)">
                <g id="more-icon" transform="translate(72.000000, 4.000000)">
                  <rect id="bounds" x="0" y="0" width={width} height={height} />
                  <g id="icon" transform="translate(0.000000, 2.000000)" fill={fill}>
                    <path d="M14,0 C12.8949275,0 12,0.894927536 12,2 C12,3.10507246 12.8949275,4 14,4 C15.1050725,4 16,3.10507246 16,2 C16,0.894927536 15.1050725,0 14,0 Z" id="Path" />
                    <path d="M6,2 C6,0.894927536 6.89492754,0 8,0 C9.10507246,0 10,0.894927536 10,2 C10,3.10507246 9.10507246,4 8,4 C6.89492754,4 6,3.10507246 6,2 Z" id="Path" />
                    <path d="M2,0 C0.894927536,0 0,0.894927536 0,2 C0,3.10507246 0.894927536,4 2,4 C3.10507246,4 4,3.10507246 4,2 C4,0.894927536 3.10507246,0 2,0 Z" id="Path" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);


MoreIconSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  className: PropTypes.string.isRequired
};

MoreIconSvg.defaultProps = {
  width: 16,
  height: 4,
  fill: '#000000'
};


export default styled(MoreIconSvg)``;
