import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import LoadingSvg from 'common/components/atoms/Loading';
import { Switch, Route, Redirect } from 'react-router-dom';
import lazyRetry from 'common/helpers/componentLoader';

import {
  LoginPage,
  LoginWithSSO,
  TwoFactorAuthPage,
} from '../../onboarding';
import { FlexContainer } from '../components/atoms';

const RegisterPage = lazy(() => lazyRetry(() => import('onboarding/components/RegisterPage')));
const ForgotPasswordPage = lazy(() => lazyRetry(() => import('onboarding/components/ForgotPasswordPage')));
const ResetPasswordPage = lazy(() => lazyRetry(() => import('onboarding/components/ResetPasswordPage')));
const AssociateWithSite = lazy(() => lazyRetry(() => import('onboarding/components/AssociateWithSite')));
const TermsPage = lazy(() => lazyRetry(() => import('onboarding/components/TermsPage')));

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: auto;
  margin: 0;
  ${props => props.theme.scrollbar}
`;

class PublicLayout extends React.Component {
  render() {
    return (
      <Container className="container">
        <FlexContainer className="col-sm-4 col-sm-offset-4 col-xs-8 col-xs-offset-2" direction="column" alignItems="center">
        <Suspense fallback={(
            <LoadingSvg height="100%" />
          )}
        >
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/signup" component={RegisterPage} />
            <Route path="/forgot_password" component={ForgotPasswordPage} />
            <Route path="/reset_password" component={ResetPasswordPage} />
            <Route path="/associate-with-site" component={AssociateWithSite} />
            <Route path="/sso" component={LoginWithSSO} />
            <Route
              path="/two_factor_auth"
              render={({ location }) => (
                location.state && location.state.flow_type ? (
                  <TwoFactorAuthPage {...location} />
                ) : (
                  <Redirect to="/login" />
                )
              )}
            />
          </Switch>
        </Suspense>
        </FlexContainer>
        <Switch>
          <Suspense fallback={(
              <LoadingSvg height="100%" />
            )}
          >
            <Route path="/terms" component={TermsPage} />
          </Suspense>
        </Switch>
      </Container>
    );
  }
}

export default PublicLayout;
