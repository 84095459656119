import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import NoDataChart from '../../../../Machines/MachineDetails/MachineCharts/components/NoDataChart';
import CreateAndDisplayEnvelope from './CreateAndDisplayEnvelope';
import Trend from '../Trend';
import { ENVELOPE_STATES } from '../../../constants/envelope.constants';

class EnvelopeWithTrend extends Component {
  render() {
    const {
      ampType,
      config,
      envelopeSettings,
      feature,
      frequencyUnits,
      isError,
      measurement_type,
      spectralEnvelope,
      tag,
      unitSystem,
      updateEnvelopeData,
      envelopeState,
      measurementTimestamp,
      componentNode,
      activeTabValue,
      assetType,
      spectrumXScale,
      setSpectrumXScale,
      spectrumXUnit,
      setSpectrumXUnit,
      getShaftSpeed
    } = this.props;
    const commonProps = {
      ampType,
      measurement_type,
      tag,
      unitSystem,
    };

    return (
      <>
        {config.trends_data && config.trends_data[0] && !_.isEmpty(config.trends_data[0].trend_data) ? (
          <>
            <Trend
              config={config}
              feature={feature}
              {...commonProps}
            />
            {config.spectrum && (
              <CreateAndDisplayEnvelope
                config={config}
                envelopeSettings={envelopeSettings}
                computeEnvelope={envelopeState !== ENVELOPE_STATES.VIEW}
                frequencyUnits={frequencyUnits}
                isError={isError}
                spectralEnvelope={spectralEnvelope}
                spectrum={config.spectrum}
                updateEnvelopeData={updateEnvelopeData}
                measurementTimestamp={measurementTimestamp}
                componentNode={componentNode}
                activeTabValue={activeTabValue}
                assetType={assetType}
                spectrumXScale={spectrumXScale}
                setSpectrumXScale={setSpectrumXScale}
                spectrumXUnit={spectrumXUnit}
                setSpectrumXUnit={setSpectrumXUnit}
                getShaftSpeed={getShaftSpeed}
                {...commonProps}
              />
            )}
          </>
        ) : (
          <NoDataChart
            tagType={tag.type}
            height="200px"
            title="No data available"
          />
        )}
      </>
    );
  }
}

EnvelopeWithTrend.propTypes = {
  ampType: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  envelopeSettings: PropTypes.object,
  feature: PropTypes.string.isRequired,
  frequencyUnits: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  spectralEnvelope: PropTypes.object,
  spectrum: PropTypes.object.isRequired,
  tag: PropTypes.object.isRequired,
  unitSystem: PropTypes.number.isRequired,
  updateEnvelopeData: PropTypes.func.isRequired,
  assetType: PropTypes.string.isRequired,
  envelopeState: PropTypes.string.isRequired,
  activeTabValue: PropTypes.number.isRequired
};

EnvelopeWithTrend.defaultProps = {
  envelopeSettings: null,
  isError: false,
  spectralEnvelope: null
};

export default EnvelopeWithTrend;
