import styled from 'styled-components';
import ReactCalendar from 'react-calendar';

const Calendar = styled(ReactCalendar)`
  border: 1px solid ${props => props.theme.primaryColor} !important;
  border-radius: 4px;
  .react-calendar__navigation__prev2-button {
      display: none;
    }
    .react-calendar__navigation__next2-button {
      display: none;
    }
    .react-calendar__tile {
      padding: 0.5em;
      margin-top: 0.1em;
      margin-bottom: 0.1em;
      border-radius: 50%;
      &:hover(:not(:disabled)) {
        background-color: grey;
      }
      &:disabled {
        border-radius: 0;
      }
      &.react-calendar__tile--active {
        abbr {
          color: white;
        }
        background: ${props => props.theme.primaryColor} !important;
        &:hover {
          background-color: ${props => props.theme.primaryColor};
        }
      }
    }
    .react-calendar__navigation__label {
      color: #383838;
      font-family: "Petasense Open Sans";
      font-size: 12px;
      font-weight: bold;
      line-height: 10px;
      text-align: center;
    }
    abbr {
      color: #3C3D3A;
      font-family: "Petasense Open Sans";
      font-size: 10px;
      font-weight: 800;
      line-height: 12px;
      text-decoration: none;
    }
`;

export default Calendar;
