import styled from 'styled-components';
import NodeItem_T from 'common/components/atoms/NodeItem';

const SearchNodeItem = styled(NodeItem_T)`
  height: unset;
  min-height: 20px;
  padding: 0 0 0 15px;
`;

export default styled(SearchNodeItem)``;
