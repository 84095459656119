const colors = {
  // ui kit colors
  white: '#FFFFFF',
  black: '#383838',
  greyXXD: '#3C3D3A',
  greyXXDA: a => `rgba(60, 61, 58, ${a})`,
  greyXD: '#999B95',
  greyD: '#BCBFB8',
  grey: '#D8DCD3',
  greyL: '#E5E8E1',
  greyXL: '#EFF0EE',
  greyXXL: '#EFEFEE',
  greyXXXL: '#F7F7F7',
  greenXD: '#53682C',
  greenD: '#6D8C30',
  green: '#91B846',
  greenA: a => `rgba(145, 184, 70, ${a})`,
  greenL: '#B7D381',
  greenXL: '#CED7A9',
  greenXXL: '#EDF3E1',
  diffGreenXL: '#F1FEF5',
  diffGreen: '#73E298',
  red: '#CC005E',
  redL: '#FF5C5C',
  diffRedXL: '#FEFAFA',
  diffRed: '#EC9D9D',
  orange: '#FF8C00',
  yellow: '#F0C039',
  yellowL: '#EFC919',
  aqua: '#CC005E',
  blue: '#4E89F4',
  blueA: a => `rgb(216, 239, 243, ${a})`,
  pink: 'FFB1D4',
  // end ui kit colors
  default: '#91B846',
  backgroundGray: '#eff0ee',
  divBackgroundGray: '#E5E8E1',
  disabled: '#EFF0EE',
  darkGray: '#888',
  lightGray: '#bbb',
  borderGray: '#ddd',
  borderGrayHover: '#bdbdbd',
  borderActive: '#91b846',
  error: '#e23333',
  normal: '#91B846',
  good: '#91B846',
  slight: '#4E89F4',
  moderate: '#E4BE0C',
  warning: '#F38F0A',
  critical: '#E61111',
  alarmWarning: '#EFC919',
  alarmCritical: '#FF5C5C',
  baselineLine: '#17A589',
  criticalA: a => `rgba(255, 92, 92, ${a})`,
  selectedItem: 'rgba(145,184,70,0.08)',
  unknown: '#E5E8E1',
  opacityBlack: opacity => `#000000${opacity}`,
  cyan: '#37B6B8',
  battery_level_warning: '#F0C000',
  battery_level_critical: '#FF4F39',
};

export default colors;
