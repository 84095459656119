import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import styled from 'styled-components';

import colors from 'common/styles/colors';
import SplitIconSvg from 'common/images/SplitIconSvg';
import { Info, ToggleOff as FaToggleOff, ToggleOn as FaToggleOn } from 'common/images/FaIcons';

import InputField from 'common/components/atoms/InputField';
import FlexContainer from 'common/components/atoms/FlexContainer';
import CalendarFilter from 'common/components/Chart/components/CalendarFilter';
import Label from 'common/typography/Label/Label';

import TagsBaselineItem from './TagsBaselineItem';
import MultiTagsBaselineItem from './MultiTagsBaselineItem';
import * as machineMLActions from '../actions/machineML.actions';

const ToggleContainer = styled(FlexContainer)`
  padding: 0 5px 0 5px;
  margin-bottom: 16px;
  cursor: pointer;
`;

const ToggleOn = styled(FaToggleOn)`
  color: ${props => props.theme.primaryColor};
  font-size: 1.5em;
  cursor: pointer;
  ${props => props.disabled && `
    cursor: not-allowed;
    & * {
      cursor: not-allowed;
    }
  `}
`;

const ToggleOff = styled(FaToggleOff)`
  color: ${props => props.theme.colors.greyD};
  font-size: 1.5em;
  cursor: pointer;
  ${props => props.disabled && `
    cursor: not-allowed;
    & * {
      cursor: not-allowed;
    }
  `}
`;

const BtnContainer = styled.div`
  display: flex;
  outline: none;
  border: none;
  padding: 0.75em 0.3em;
  margin: 0 0.3em;
  z-index: 1;
  ${props => props.noCursor || 'cursor: pointer'};
`;

const BaselineItemHeader = styled(FlexContainer).attrs({
  direction: 'row'
})`
  height: 50px;
`;

const SeedInputField = styled(InputField)`
  flex-basis: 145px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1em;
  /* margin-left: auto; */
  label {
    font-size: 13px;
    font-weight: 300;
  }
  input {
    font-size: 14px;
    padding: 0.4em 0.6em;
    width: 50px;
  }
`;

const RightSideContainer = styled(FlexContainer).attrs({ direction: 'row' })`
  margin-left: auto;
`;

const CalendarContainer = styled(FlexContainer)`
  > div {
    border: none;
    z-index: unset;
  }
  padding-top: 8px;
`;

class ComponentBaselineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: null,
      customDayTo: '',
      customDayFrom: '',
      calendarExpanded: false,
      split: false,
      groupedTagInfo: [],
      seedHealthScore: null,
      isMultiSetItem: false,
      isDetailsViewOpen: false,
      tagTypes: []
    };
  }

  componentDidMount() {
    this.setInitialState();
  }

  componentDidUpdate(prevProps, prevState) {
    const { component, isMultiSetActive, multiSetProperties } = this.props;
    const { isMultiSetItem } = this.state;
    const { calendarInfo } = multiSetProperties;

    if (!_.isEqual(prevProps.component, component)) {
      this.setInitialState();
    }
    if (!_.isEqual(prevProps.isMultiSetActive, isMultiSetActive)) {
      this.setState({ isMultiSetItem: false });
    }
    if (!_.isEqual(prevProps.multiSetProperties.components, multiSetProperties.components)) {
      this.setState(() => {
        if (multiSetProperties.components[component.id]) {
          return { isMultiSetItem: true };
        }
        return { isMultiSetItem: false };
      });
    }
    if (isMultiSetItem && !_.isEqual(prevState.isMultiSetItem, isMultiSetItem)) {
      this.updateSeedScore(multiSetProperties.seedHealthScore);
      if (calendarInfo.days) {
        this.calendarSelect(calendarInfo.days, calendarInfo.customDayFrom, calendarInfo.customDayTo);
      }
    }
    if (isMultiSetItem && !_.isEqual(prevProps.multiSetProperties.calendarInfo, multiSetProperties.calendarInfo)) {
      if (calendarInfo.days) this.calendarSelect(calendarInfo.days, calendarInfo.customDayFrom, calendarInfo.customDayTo);
    }
    if (isMultiSetItem && !_.isEqual(prevProps.multiSetProperties.seedHealthScore, multiSetProperties.seedHealthScore)) {
      this.updateSeedScore(multiSetProperties.seedHealthScore);
    }
  }

  setInitialState = () => {
    const tagTypes = _.uniq(this.props.component.tags_data.map(item => item.tag_type));
    this.setState({
      groupedTagInfo: this.groupedTagInfoFromProps(),
      seedHealthScore: this.seedHealthScoreFromProps(),
      tagTypes
    });
  }

  groupedTagInfoFromProps = () => {
    const { component } = this.props;
    const groupedTagsByType = _.groupBy(component.tags_data, 'tag_type');
    return _.entries(groupedTagsByType).map(([tag_type, tags]) => ({
      tag_ids: tags.map(t => t.tag_id),
      tag_type,
      baseline_info: this.defaultBaselineInfo(tags.map(t => t.baseline_info)),
      baseline_missing_tag_ids: _.flatten(_.map(tags, t => t.baseline_missing_tag_ids))
    }));
  }

  seedHealthScoreFromProps = () => {
    const { component } = this.props;
    const tag_data = component.tags_data.find(t => t.baseline_info);
    return tag_data && tag_data.baseline_info.seed_health_score ? tag_data.baseline_info.seed_health_score : 10;
  }

  defaultBaselineInfo = (baselines) => {
    const baseline = baselines.find(b => b);
    return {
      start_time: baseline ? new Date(baseline.start_time) : null,
      end_time: baseline ? new Date(baseline.end_time) : null,
      outlier_lower_limit: baseline && baseline.outlier_lower_limit ? String(baseline.outlier_lower_limit) : '',
      outlier_upper_limit: baseline && baseline.outlier_upper_limit ? String(baseline.outlier_upper_limit) : '',
    };
  }

  baselineInfoForMulti = () => {
    const { component } = this.props;
    const allEqual = arr => _.every(arr, v => v === arr[0]);
    const activeTagsData = component.tags_data.filter(t => !_.isEmpty(t.trend));
    const start_times = activeTagsData.map(t => t.baseline_info ? t.baseline_info.start_time : null);
    const end_times = activeTagsData.map(t => t.baseline_info ? t.baseline_info.end_time : null);

    return {
      start_time: allEqual(start_times) && allEqual(end_times) ? start_times[0] : null,
      end_time: allEqual(start_times) && allEqual(end_times) ? end_times[0] : null,
    };
  }

  updateSeedScore = (score) => {
    this.setState({
      seedHealthScore: score
    }, () => {
      if (this.state.isMultiSetItem) this.props.setMultiSetSeedScore(score);
    });
  }

  toggleSplit = () => {
    this.setState(prevState => ({ split: !prevState.split }));
  }

  save = (baseline_info, tagIds, action = 'save') => {
    const { component, machineMLActions } = this.props;
    const { seedHealthScore } = this.state;
    const finalBaselineInfo = {
      start_time: new Date(baseline_info.start_time),
      end_time: new Date(baseline_info.end_time),
      ...('outlier_lower_limit' in baseline_info && { outlier_lower_limit: parseFloat(baseline_info.outlier_lower_limit) }),
      ...('outlier_upper_limit' in baseline_info && { outlier_upper_limit: parseFloat(baseline_info.outlier_upper_limit) }),
      seed_health_score: seedHealthScore
    };
    if (action === 'save') {
      machineMLActions.postComponentBaseline(component.id, component.name, tagIds, finalBaselineInfo).then(() => this.setInitialState());
    } else {
      machineMLActions.validateComponentBaseline(component.id, tagIds, finalBaselineInfo);
    }
  }

  clearValidationResult = (tagIds) => {
    this.props.machineMLActions.clearBaselineValidation(tagIds);
  }

  deleteBaselines = (tagIds = []) => {
    const { component, machineMLActions } = this.props;
    machineMLActions.deleteComponentBaseline(component.id, tagIds).then(
      () => {
        this.setInitialState();
      }
    );
  }

  toggleCalendarExpand = () => {
    this.setState(prevState => ({
      calendarExpanded: !prevState.calendarExpanded
    }));
  }

  calendarSelect = (selectedRange, newCustomDayFrom = null, newCustomDayTo = null) => {
    const newState = {
      selectedRange,
      customDayFrom: null,
      customDayTo: null,
      days: undefined
    };
    const days = parseInt(selectedRange, 10);
    const params = {};

    if (selectedRange === 'custom' && newCustomDayFrom && newCustomDayTo) {
      params.from_timestamp = newCustomDayFrom;
      params.to_timestamp = newCustomDayTo;
      newState.customDayFrom = newCustomDayFrom;
      newState.customDayTo = newCustomDayTo;
      newState.days = 'custom';
    }
    if (!Number.isNaN(days)) {
      params.days = days;
      newState.days = params.days;
    }

    const { component, getAllTagChartDataForAComponent } = this.props;
    const tagIds = component.tags_data.map(t => t.tag_id);

    this.setState({
      ...newState,
      calendarExpanded: false
    }, () => getAllTagChartDataForAComponent(tagIds, component.id, params));
  }

  renderCalendarFilter = () => {
    const { calendarExpanded, days, customDayFrom, customDayTo, isMultiSetItem } = this.state;
    const { setMultiSetCalendar } = this.props;
    return (
      <CalendarContainer>
        <CalendarFilter
          calendarSelect={isMultiSetItem ? setMultiSetCalendar : this.calendarSelect}
          calendarExpand={this.toggleCalendarExpand}
          calendarExpanded={calendarExpanded}
          days={days}
          customDayFrom={customDayFrom}
          customDayTo={customDayTo}
          alignHorizontal="right"
        />
      </CalendarContainer>

    );
  }

  toggleDetailsViewOpen = () => {
    this.setState(prevState => ({ isDetailsViewOpen: !prevState.isDetailsViewOpen }));
  }

  render() {
    const { component, currentUser, isMultiSetActive, multiSetProperties, setBaselineValue, multiSetComponentHandler, primaryColor, ahsFeaturesInfo } = this.props;
    const { isMultiSetItem, isDetailsViewOpen, tagTypes } = this.state;
    const { components, baselineInfo, outlierInfo } = multiSetProperties;
    const seedHealthScoreChanged = !_.isEqual(parseFloat(this.state.seedHealthScore), parseFloat(this.seedHealthScoreFromProps()));
    const baselineMissingTagIds = _.uniq(_.flatten(_.map(this.state.groupedTagInfo, t => t.baseline_info ? t.baseline_missing_tag_ids : [])));
    return (
      <>
        <BaselineItemHeader>
          {!_.isEmpty(component.tags_data) && (
            <RightSideContainer>
              {!_.isEmpty(baselineMissingTagIds) && (
                <BtnContainer
                  noCursor
                  title={`Baseline not set for the following tags: ${baselineMissingTagIds.join(
                    ', '
                  )}`}
                >
                  <Info size="sm" color={colors.redL} />
                </BtnContainer>
              )}
              <BtnContainer onClick={this.toggleSplit} title="Split Chart">
                <SplitIconSvg
                  active={this.state.split}
                  primaryColor={primaryColor}
                />
              </BtnContainer>
              {this.renderCalendarFilter()}
              <SeedInputField
                label="Seed score"
                labelSide="left"
                type="number"
                color="red"
                min="1"
                max="10"
                onChange={e => this.updateSeedScore(e.target.value)}
                value={this.state.seedHealthScore}
              />
            </RightSideContainer>
          )}
        </BaselineItemHeader>
        <ToggleContainer
          title="Toggle Details View"
          onClick={this.toggleDetailsViewOpen}
        >
          <Label margin="0 10px 0 0" padding="0 0.5em 0 0">
            {' '}
            Detailed View{' '}
          </Label>
          {isDetailsViewOpen ? <ToggleOn /> : <ToggleOff />}
        </ToggleContainer>
        {this.state.split ? (
          <>
            {this.state.groupedTagInfo.map(tagInfo => (
              <>
                <TagsBaselineItem
                  tagInfo={tagInfo}
                  seedHealthScoreChanged={seedHealthScoreChanged}
                  component={component}
                  save={this.save}
                  deleteBaselines={this.deleteBaselines}
                  unit_system={currentUser.unit_system}
                  isMultiSetActive={isMultiSetActive}
                  isMultiSetItem={
                    components &&
                    components[component.id] &&
                    components[component.id].includes(tagInfo.tag_ids[0])
                  }
                  setBaselineValue={setBaselineValue}
                  multiSetBaselineInfo={baselineInfo}
                  multiSetOutlierInfo={
                    outlierInfo ? outlierInfo[tagInfo.tag_type] : null
                  }
                  multiSetComponentHandler={multiSetComponentHandler}
                  clearValidationResult={this.clearValidationResult}
                  isDetailsViewOpen={isDetailsViewOpen}
                  ahsFeaturesInfo={ahsFeaturesInfo}
                />
              </>
            ))}
          </>
        ) : (
          <>
            <MultiTagsBaselineItem
              component={component}
              seedHealthScoreChanged={seedHealthScoreChanged}
              baseline_info={this.baselineInfoForMulti()}
              save={this.save}
              deleteBaselines={this.deleteBaselines}
              unit_system={currentUser.unit_system}
              isMultiSetActive={isMultiSetActive}
              isMultiSetItem={isMultiSetItem}
              setBaselineValue={setBaselineValue}
              multiSetBaselineInfo={baselineInfo}
              multiSetComponentHandler={multiSetComponentHandler}
              clearValidationResult={this.clearValidationResult}
              ahsFeaturesInfo={ahsFeaturesInfo}
              tagTypes={tagTypes}
              isDetailsViewOpen={isDetailsViewOpen}
            />
          </>
        )}
      </>
    );
  }
}

ComponentBaselineItem.propTypes = {
  component: PropTypes.object.isRequired,
  multiSetProperties: PropTypes.object.isRequired,
  isMultiSetActive: PropTypes.bool.isRequired,
  multiSetComponentHandler: PropTypes.func.isRequired,
  setBaselineValue: PropTypes.func.isRequired,
  setMultiSetCalendar: PropTypes.func.isRequired,
  setMultiSetSeedScore: PropTypes.func.isRequired
};


const mapDispatchToProps = dispatch => ({
  machineMLActions: bindActionCreators(machineMLActions, dispatch)
});

const mapStateToProps = state => ({
  currentUser: state.user.user,
  primaryColor: state.companyReducer.partner.theme.primaryColor
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentBaselineItem);
