import React from 'react';
import PropTypes from 'prop-types';

import ListItem from 'common/components/atoms/ListItem';
import Button from 'common/components/atoms/Button';

class MissedMeasurementsListItem extends React.Component {
  render() {
    const {
      item,
      columnSizes,
      onSensorSelect,
      adminOpen
    } = this.props;

    return (
      <ListItem>
          <td width={`${columnSizes[0]}%`}>
            {item.serial_number}
          </td>
          <td width={`${columnSizes[1]}%`} style={{ textAlign: 'center' }}>
            {item.count}
          </td>
          {adminOpen && (
            <td width={`${columnSizes[2]}%`} style={{ textAlign: 'center' }}>
            {item.account_name}
            </td>
          )}
          {adminOpen ? (
            <td width={`${columnSizes[3]}%`}>
              <Button text onClick={() => onSensorSelect(item.serial_number)} fontSize="12px" margin="0 20px">
                View Details
              </Button>
            </td>
          ) : (
            <td width={`${columnSizes[2]}%`}>
              <Button text onClick={() => onSensorSelect(item.serial_number)} fontSize="12px" margin="0 20px">
                View Details
              </Button>
            </td>
          )}
      </ListItem>
    );
  }
}

MissedMeasurementsListItem.propTypes = {
  item: PropTypes.shape({
    count: PropTypes.number,
    serial_number: PropTypes.string
  }).isRequired,
  columnSizes: PropTypes.array.isRequired,
  onSensorSelect: PropTypes.func.isRequired,
  adminOpen: PropTypes.bool.isRequired
};

export default MissedMeasurementsListItem;
