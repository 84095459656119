import React from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import * as bearingActions from '../bearing.actions';
import PlusSvg from 'common/images/PlusSvg';
import InfoIconSvg from '../../../../../../../common/images/BearingModal/InfoIconSvg';
import { Search } from '../../../../../../../common/images/FaIcons';
import Button_T from '../../../../../../../common/components/atoms/Button';
import List from '../../../../../../../common/components/molecules/List';
import Modal from '../../../../../../../common/components/organisms/Modal';
import FlexContainer from '../../../../../../../common/components/atoms/FlexContainer';
import InputField_T from '../../../../../../../common/components/atoms/InputField';
import BearingItem from './BearingItem';

const InputField = styled(InputField_T)`
  margin: 0;
  width: 450px;
  svg > path {
    fill: ${props => props.theme.colors.greyXD}
  }
  input {
    padding-left: 0;
  }
`;

const Button = styled(Button_T)`
  height: 42px;
  ${props => !props.borderRequired && 'border: none !important;'}
`;

const TableContainer = styled.div`
  width: 100%;
  margin: 1.5em 0 0;
  height: 75vh;
  width: auto;
  border: 1px solid ${props => props.theme.colors.greyL};
  border-radius: 4px;
  table {
    thead th:first-of-type label {
      margin-left: 3em;
    }
    tr {
      font-size: 14px;
    }
    thead {
      svg {
        height: 0.85em;
      }
    }
  }
  `;
class BearingModal extends React.Component {
  constructor() {
    super();
    this.state = {
      search: ''
    };
  }

  componentDidMount() {
    this.props.bearingActions.newBearingEntry(false);
    if (this.props.bearingModelId) {
      this.props.bearingActions.getBearings(this.props.bearingModelId).then(
        () => {
          const { selectedBearingId, bearingModelId } = this.props;
          if (!selectedBearingId || selectedBearingId !== bearingModelId) {
            this.props.bearingActions.selectRow(this.props.bearingModelId);
          }
        }
      );
    } else {
      this.props.bearingActions.setSelectedFalse();
      this.props.bearingActions.initBearingPaginater();
    }
  }

  onSearch = (e) => {
    this.setState({
      search: e.target.value
    }, this.debouncedSearch);
  }

  debouncedSearch = _.debounce(() => {
    this.props.bearingActions.setBearingSearchFilter(this.state.search);
  }, 500);

  getNextBearings = () => {
    this.props.bearingActions.increaseBearingPaginater();
  };

  toggleNewBearingEntry = () => {
    const newEntryBool = this.props.newBearingEntry;
    this.props.bearingActions.newBearingEntry(!newEntryBool);
  };

  associateBearing = () => {
    const { selectedBearingId, componentId, bearingLocationId } = this.props;
    if (selectedBearingId) {
      if (this.props.apply) {
        const item = this.props.items.object.find(i => i.id === selectedBearingId);
        if (!item) return;
        this.props.apply(item.id, _.cloneDeep(item));
        this.props.close();
        return;
      }
      const data = {
        bearing_location_id: bearingLocationId,
        bearing_model_id: selectedBearingId
      };
      this.props.bearingActions.associateBearing(data, componentId).then(
        () => {
          this.setState({ saved: true });
          setTimeout(() => this.props.close(), 500);
        }
      );
    }
  };

  render() {
    const {
      sorterState,
      selectedBearingId,
      newBearingEntry,
    } = this.props;
    const items = _.cloneDeep(this.props.items);
    const { search } = this.state;
    const { setBearingSorter } = this.props.bearingActions;
    if (newBearingEntry) {
      if (items.object) items.object.unshift({ newitem: true });
      else this.props.bearingActions.newBearingEntry(false);
    }

    const footer = {
      items: [
        {
          Tag: Button,
          props: {
            key: 'footer-btn12',
            opaque: !this.state.saved,
            text: 'true'
          },
          children: 'SAVED',
        },
        {
          Tag: Button,
          props: {
            key: 'footer-btn1',
            disabled: !selectedBearingId || this.state.saved,
            onClick: this.associateBearing,
          },
          children: 'APPLY',
        }
      ]
    };
    const header = {
      items: [{
        Tag: InputField,
        props: {
          key: 'hdr-ip1',
          type: 'text',
          prefixSide: 'left',
          placeholder: 'Search by manufacturer or bearing number',
          onChange: this.onSearch,
          value: search,
          prefix: <Search />
        }
      }]
    };

    return (
      <Modal
        title="Choose from Bearing Database"
        close={this.props.close}
        footer={footer}
        header={header}
        padding="1em 2em"
        width={this.props.width}
      >
        <FlexContainer justifyContent="space-between" alignItems="center">
          <Button
            secondary
            borderRequired={newBearingEntry}
            onClick={this.toggleNewBearingEntry}
          >
            {newBearingEntry ? 'CANCEL' : <PlusSvg width="16" height="16" />}
          </Button>
        </FlexContainer>
        <TableContainer>
          {!_.isEmpty(items) ? (
            <List
              columnSizes={[35, 14, 9, 9, 9, 9, 11]}
              headers={[{
                name: 'manufacturer',
                label: 'Manufacturer'
              }, {
                name: 'bearing_no',
                label: 'Bearing Number'
              }, {
                name: 'bpfi',
                label: 'BPFI',
                noSorter: true,
                component: <InfoIconSvg />,
                message: 'Ball Pass Frequency, Inner Race'
              }, {
                name: 'bpfo',
                label: 'BPFO',
                noSorter: true,
                component: <InfoIconSvg />,
                message: 'Ball Pass Frequency, Outer Race'
              }, {
                name: 'bsf',
                label: 'BSF',
                noSorter: true,
                component: <InfoIconSvg />,
                message: 'Ball Spin Frequency'
              }, {
                name: 'ftf',
                label: 'FTF',
                noSorter: true,
                component: <InfoIconSvg />,
                message: 'Fundamental Train Frequency'
              }, {
                name: 'source',
                label: 'Custom'
              }]}
              sorterState={sorterState}
              setSorter={setBearingSorter}
              items={items}
              hasMore={items.hasMore}
              loading={items.loading}
              newEntry={newBearingEntry}
              loadMoreItems={this.getNextBearings}
              ItemComponent={BearingItem}
              selectedId={selectedBearingId}
              emptyMsg="No bearings are available."
              errorMsg={items.error}
              maxHeightAfterScroll="calc(75vh - 56px)"
              componentId={this.props.componentId}
              bearingLocationId={this.props.bearingLocationId}
            />
          ) : (
            null
          )}
        </TableContainer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  query: state.bearings.query,
  items: state.bearings.items,
  sorterState: state.bearings.sorter,
  selectedBearingId: state.bearings.selectedBearingId,
  newBearingEntry: state.bearings.newBearingEntry
});

const mapDispatchToProps = dispatch => ({
  bearingActions: bindActionCreators(bearingActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(BearingModal));
