import React from 'react';
import PropTypes from 'prop-types';

const UtilizationIconSvg = ({ width, height, fill }) => (
<svg height={height} viewBox="0 0 16 16" width={width} xmlns="http://www.w3.org/2000/svg">
    <g fill={fill} fillRule="evenodd">
        <path d="m0 0h16v16h-16z" fill="#fff" />
        <g stroke="#999b95" strokeLinecap="square" strokeWidth="1.5">
            <path d="m1 3h3" />
            <path d="m12 3h3" />
            <path d="m5 8h3" />
            <path d="m9 13h3" />
        </g>
    </g>
</svg>

);
UtilizationIconSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

UtilizationIconSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: 'none'
};

export default UtilizationIconSvg;
