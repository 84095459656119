import * as _ from 'lodash';

import { handleResponse } from '../../../../common/helpers';

import modelActionTypes from './modelAction.types';
import { axiosInstance, ENDPOINT } from '../../../../common/constants';


export const setSensorSearchParams = (searchParams, per_page = 50) => (dispatch) => {
  dispatch({ type: modelActionTypes.SET_SENSOR_SEARCH_PARAMS, searchParams });
  return dispatch(getModelsList(1, per_page));
};

export const getModelsList = (page, per_page = 50, load = true) => {
  return (dispatch, getState) => {
    const modelsState = getState().settings.sensorModels;
    const { searchParams } = modelsState;
    const params = {
      per_page,
      page: page || modelsState.list.page,
      search_key: searchParams.searchKey,
    };
    if (searchParams) {
      params.filters = [];
      Object.keys(searchParams).forEach((filterKey) => {
        if (filterKey === 'show_all_sensors') {
          const filter = {
            name: filterKey,
            op: 'is',
            value: searchParams[filterKey]
          };
          params.filters.push(filter);
          return;
        }
        if (filterKey === 'searchKey') {
          return;
        }
        if (searchParams[filterKey].length > 0) {
          const filter = {
            name: filterKey,
            op: 'in',
            value: searchParams[filterKey]
          };
          params.filters.push(filter);
        }
      });
    }

    if (!_.isEmpty(modelsState.sorter)) {
      params.order_by = { field: modelsState.sorter.sorter.name, direction: modelsState.sorter.sorter.order };
    }
    if (load) dispatch(request(params.account_id));
    return handleResponse(axiosInstance.get(ENDPOINT.SENSOR_MODELS(), { params })).then(
      (res) => {
        dispatch(success(res.items, res.page, res.total_pages, res.total_count));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(account_id) {
    return { type: modelActionTypes.GET_SENSOR_MODEL_LIST_REQUEST, account_id };
  }
  function success(models, page, total_pages, total_count) {
    return { type: modelActionTypes.GET_SENSOR_MODEL_LIST_SUCCESS, models, page, total_pages, total_count };
  }
  function failure(error) {
    return { type: modelActionTypes.GET_SENSOR_MODEL_LIST_FAILURE, error };
  }
};

export const loadMoreModels = () =>
  (dispatch, getState) => {
    const sensorsStateItems = getState().settings.sensorModels.list;
    const page = sensorsStateItems.page;
    if (sensorsStateItems.loading) return;
    dispatch({ type: modelActionTypes.INCREASE_PAGE, page: page + 1 });
    dispatch(getModelsList(page + 1));
  };

export const setModelsSorter = (sorter, per_page = 50) =>
  (dispatch) => {
    dispatch({ type: modelActionTypes.SET_MODELS_SORTER, sorter });
    dispatch({ type: modelActionTypes.CLEAR_MODELS_LIST });
    dispatch(getModelsList(1, per_page));
  };


export const getModel = (model_id, load = true) => {
  return (dispatch) => {
    if (load) dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.SENSOR_MODELS(model_id))).then(
      (model) => {
        dispatch(success(model));
        dispatch(getSensorInstances(model_id));
        return model;
      },
      (error) => {
        dispatch(failure(error));
        throw error.message;
      }
    );
  };

  function request() {
    return { type: modelActionTypes.GET_SENSOR_MODEL_REQUEST };
  }
  function success(model) {
    return { type: modelActionTypes.GET_SENSOR_MODEL_SUCCESS, model };
  }
  function failure(error) {
    return { type: modelActionTypes.GET_SENSOR_MODEL_FAILURE, error };
  }
};

export const createSensorModel = (modelParams) => {
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.post(ENDPOINT.SENSOR_MODELS(), modelParams)).then(
      res => res,
      (error) => {
        dispatch(failure(error));
        throw error.message;
      }
    );
  };

  function request() {
    return { type: modelActionTypes.CREATE_SENSOR_MODEL_REQUEST };
  }
  function failure(error) {
    return { type: modelActionTypes.CREATE_SENSOR_MODEL_FAILURE, error };
  }
};

export const getSensorModelsMetadata = () => {
  const request = () => ({ type: modelActionTypes.GET_SENSOR_MODEL_METADATA_REQUEST });
  const success = data => ({ type: modelActionTypes.GET_SENSOR_MODEL_METADATA_SUCCESS, data });
  const failure = error => ({ type: modelActionTypes.GET_SENSOR_MODEL_METADATA_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_SENSOR_MODEL_METADATA)).then(
      (res) => {
        dispatch(success(res));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const updateSensorModel = (modelId, modelParams) => {
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.put(ENDPOINT.SENSOR_MODELS(modelId), modelParams)).then(
      () => {
        dispatch(getModelsList(1));
      },
      (error) => {
        dispatch(failure(error));
        throw error.message;
      }
    );
  };

  function request() {
    return { type: modelActionTypes.UPDATE_SENSOR_MODEL_REQUEST };
  }
  function failure(error) {
    return { type: modelActionTypes.UPDATE_SENSOR_MODEL_FAILURE, error };
  }
};

export const clearSelectedSensor = () => (dispatch) => {
  dispatch({ type: modelActionTypes.CLEAR_SELECTED_MODEL });
};

export const clearSensorInstances = () => (dispatch) => {
  dispatch({ type: modelActionTypes.CLEAR_SENSOR_INSTANCES });
};

export const clearModelList = () =>
  (dispatch) => {
    dispatch({ type: modelActionTypes.CLEAR_MODELS_LIST });
  };


export const deleteSensorModel = (modelId) => {
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.delete(ENDPOINT.SENSOR_MODELS(modelId))).then(
      () => {
        dispatch(getModelsList(1));
      },
      (error) => {
        dispatch(failure(error));
        throw error.message;
      }
    );
  };

  function request() {
    return { type: modelActionTypes.DELETE_SENSOR_MODEL_REQUEST };
  }
  function failure(error) {
    return { type: modelActionTypes.DELETE_SENSOR_MODEL_FAILURE, error };
  }
};

export const getSensorInstances = (model_id) => {
  return (dispatch) => {
    dispatch(request);
    handleResponse(axiosInstance.get(ENDPOINT.GET_SENSOR_INSTANCES(model_id))).then(
      (res) => {
        dispatch(success(res.items));
      },
      (error) => {
        dispatch(failure(error));
        throw error.message;
      }
    );
  };

  function request() {
    return { type: modelActionTypes.GET_SENSOR_INSTANCES_REQUEST };
  }
  function success(sensors) {
    return { type: modelActionTypes.GET_SENSOR_INSTANCES_SUCCESS, sensors };
  }
  function failure(error) {
    return { type: modelActionTypes.GET_SENSOR_INSTANCES_FAILURE, error };
  }
};

export const selectModelsForCreation = models => (dispatch) => {
  dispatch({ type: modelActionTypes.SELECT_MODELS_FOR_CREATION, models });
};

export const removeModelsForCreation = models => (dispatch) => {
  dispatch({ type: modelActionTypes.REMOVE_MODEL_FOR_CREATION, models });
};

export const clearSelectedModels = () => (dispatch) => {
  dispatch({ type: modelActionTypes.CLEAR_SELECTED_MODELS });
};

export const updateModelCopies = (id, copies) => (dispatch) => {
  dispatch({ type: modelActionTypes.UPDATE_MODEL_COPIES, id, copies });
};
