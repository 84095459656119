import React, { Component } from 'react';
import * as d3 from 'd3';

import { LineChart as LineChartHoc } from '../../../../../common/components/Chart/hoc/LineChart';
import * as helpers from '../../../../../common/components/Chart/utils/helpers';
import * as graphUtils from '../../../../../common/components/Chart/utils/graphUtils';


class MultilineChart extends Component {
  addMouseoverEventListener(ctx) {
    if (ctx.props.mouseover) {
      if (this.mouseover) return;
      this.mouseover = graphUtils.addMouseoverEvent(ctx.chart, () => {
        const dataPoints = helpers.getAllDataPointsArray(ctx);
        ctx.props.mouseover(dataPoints);
      });
    }
  }

  /* eslint-disable */
  drawSelection(ctx, selection) {
    d3.selectAll(`.${ctx.props.chartName}.selection`).remove();
    const height = ctx.chart.getBoundingClientRect().height
    selection.forEach((dataPoint) => {
      graphUtils.drawLineOnPos(ctx.chart, ctx.x(dataPoint.x), 0, ctx.x(dataPoint.x), height, `${ctx.props.chartName} selection`)
        .attr('pointer-events', 'none');
    });

    selection.forEach((dataPoint) => {
      graphUtils.drawCircleOnPos(ctx.chart, ctx.x(dataPoint.x), ctx.y(dataPoint.y), 3, `${ctx.props.chartName} selection`)
        .attr('pointer-events', 'none')
        .attr('fill', ctx.props.colors[dataPoint.dataIdx]);
    });
  }

  drawPoints(ctx, points) {
    if (_.isEmpty(points)) return
    d3.selectAll(`.${ctx.props.chartName}.pointSelection`).remove();
    const height = ctx.chart.getBoundingClientRect().height
    points.forEach((dataPoint) => {
      graphUtils.drawLineOnPos(ctx.chart, ctx.x(dataPoint.x), 0, ctx.x(dataPoint.x), height, `${ctx.props.chartName} pointSelection`);
      graphUtils.drawCircleOnPos(ctx.chart, ctx.x(dataPoint.x), ctx.y(dataPoint.y), 4, `${ctx.props.chartName} pointSelection`)
    });

  }

  drawOutliers(ctx) {
    const { outliersArray, chartName } = ctx.props;
    const height = ctx.chart.getBoundingClientRect().height
    d3.selectAll(`.${chartName}.outliers`).remove();
    if (ctx && outliersArray) {
      outliersArray.forEach((d) => {
        console.log(d)
        if (ctx.x(d.x) > 0 && ctx.x(d.x) < ctx.chart.getBoundingClientRect().width
              && ctx.y(d.y) > 0 && ctx.y(d.y) < parseInt(height, 10)) {
          graphUtils.drawCircleOnPos(ctx.chart, ctx.x(d.x), ctx.y(d.y), 2.5, `${chartName} outliers`).attr('fill', 'gray');
        }
      });
    }
  }

  drawAxis(ctx) {
    // d3.selectAll(`.${ctx.props.chartName}.ticks-left`).remove();
  }

  drawGraph(ctx) {
    this.drawOutliers(ctx);
  }

  redraw(ctx) {
    // move selection line
    if (ctx.props.selection) {
      this.drawSelection(ctx, ctx.props.selection);
    }
    if (ctx.props.points) {
      this.drawPoints(ctx, ctx.props.points);
    }
    if (ctx.props.outliersArray) {
      this.drawOutliers(ctx);
    }

  }

  render() {
    return null;
  }
}

MultilineChart.displayName = 'MultilineChart';

export default LineChartHoc(MultilineChart);
