import _ from 'lodash';
import * as d3 from 'd3';

import { getFreqConversionFactor, round } from 'common/utils';
import {
  SPECTRAL_WINDOW_TYPE,
  THRESHOLD_OPTIONS,
  PEAK_MIN_DISTANCE,
  X_SCALE
} from '../constants/envelope.constants';


export const calculateStandardDeviation = (array) => {
  if (_.isEmpty(array)) return 0;
  const array_size = array.length;
  const mean = _.sum(array) / array_size;
  return Math.sqrt(_.sum(_.map(array, d => (d - mean) ** 2)) / array_size);
};

export const create2DArray = (rows, cols, initialVal = -1) => {
  const arr = [];
  for (let i = 0; i < rows; i++) {
    arr.push([]);
    for (let j = 0; j < cols; j++) {
      arr[i].push(initialVal);
    }
  }
  return arr;
};

export const calculatePeaks = (array, min_val = 0) => {
  const peaks = [];
  array.forEach((d, idx) => {
    const amp = array[idx].y;
    if (amp >= min_val) peaks.push(array[idx]);
  });
  return peaks;
};

const createFixedBins = (fmax, width) => {
  if (!width || !fmax) return [];
  const bins = [];
  let start = 0;
  while (start < fmax) {
    const end = round(Math.min(start + width, fmax));
    bins.push([start, end]);
    start = end;
  }
  return bins;
};

const getAllSpectrumsPeaks = (spectrums, min_val = 0) => {
  const peaks = [];
  spectrums.forEach((spectrum) => {
    peaks.push(...calculatePeaks(spectrum.spectrum_data, min_val));
  });
  return peaks;
};

const filterCloserPeaks = (peaks, min_distance = 0.5) => {
  if (_.isEmpty(peaks)) return [];
  const filtered_peaks = _.sortBy(peaks, peak => peak.x);
  for (let i = 0; i < filtered_peaks.length - 1; i++) {
    let exclude = false;
    if (filtered_peaks[i + 1].x - filtered_peaks[i].x < min_distance) exclude = true;
    filtered_peaks[i] = {
      ...filtered_peaks[i],
      exclude
    };
  }
  return _.filter(filtered_peaks, peak => !peak.exclude);
};

const mergeBins = (bins) => {
  let prev_bin_idx = 0;
  for (let i = 1; i < bins.length; i++) {
    if (bins[prev_bin_idx][1] >= bins[i][0]) {
      bins[prev_bin_idx][1] = Math.max(bins[prev_bin_idx][1], bins[i][1]);
      bins[prev_bin_idx][0] = Math.min(bins[prev_bin_idx][0], bins[i][0]);
    } else {
      prev_bin_idx++;
      bins[prev_bin_idx] = bins[i];
    }
  }
  const desired_bins = bins.slice(0, prev_bin_idx + 1);
  return desired_bins;
};

const fillVoidBtwBins = (bins, fmax) => {
  if (_.isEmpty(bins)) return [[0, fmax]];
  const left_bound = bins[0][0];
  const new_bins = [];
  let bin_idx = 0;
  let start = 0;
  if (left_bound === 0) {
    new_bins.push(bins[0]);
    start = bins[0][1];
    bin_idx = 1;
  }
  for (let i = bin_idx; i < bins.length; i++) {
    const left_bound = bins[i][0];
    const right_bound = bins[i][1];
    if (start <= left_bound) new_bins.push([start, left_bound]);
    new_bins.push(bins[i]);
    start = right_bound;
  }
  if (start < fmax) new_bins.push([start, fmax]);
  return new_bins;
};

const createCenterFreqBins = (type, spectrums, fmax, width, resolution, min_caution_level = 0, x_scale) => {
  // width is in percent;
  if (_.isEmpty(spectrums) || !width || width < 0) return [];
  const min_distance = x_scale === X_SCALE.ORDERS ? PEAK_MIN_DISTANCE.X_SCALE_ORDERS : PEAK_MIN_DISTANCE.X_SCALE_FREQUENCY;
  const peaks = filterCloserPeaks(getAllSpectrumsPeaks(spectrums, min_caution_level), min_distance);
  let bins = [];

  peaks.forEach((p) => {
    let approx_no_of_points;
    if (type === SPECTRAL_WINDOW_TYPE.FIXED_CENTER_FREQUENCY) {
      approx_no_of_points = Math.ceil(width / (2 * resolution));
    } else {
      approx_no_of_points = Math.ceil((p.x * width * (1 / 200)) / resolution);
    }
    const dist_from_mid = approx_no_of_points * resolution;
    const left_bound = Math.max(0, round(p.x - dist_from_mid));
    const right_bound = Math.min(round(p.x + dist_from_mid), fmax);
    bins.push([left_bound, right_bound]);
  });
  bins = mergeBins(bins);
  bins = fillVoidBtwBins(bins, fmax);
  bins = bins.filter(bin => (bin[1] - bin[0]) >= resolution);
  return bins;
};

export const createBins = (spectrums, type, width, min_caution_level = 0, x_scale) => {
  if (_.isEmpty(spectrums) || !width || width <= 0 || _.isUndefined(min_caution_level)) return [];
  const spectrum = _.maxBy(spectrums, s => s.fmax);
  const resolution = spectrum.spectrum_data[1].x;
  if (type === SPECTRAL_WINDOW_TYPE.FIXED) return createFixedBins(spectrum.fmax, width);
  if ([
    SPECTRAL_WINDOW_TYPE.FIXED_CENTER_FREQUENCY,
    SPECTRAL_WINDOW_TYPE.PERCENT_CENTER_FREQUENCY
  ].includes(type)) {
    return createCenterFreqBins(
      type,
      spectrums,
      spectrum.fmax,
      width,
      resolution,
      min_caution_level,
      x_scale
    );
  }
  return [];
};

export const getMaxAmplitudesInBins = (bins, spectrums) => {
  if (_.isEmpty(bins) || _.isEmpty(spectrums)) return [];
  const amplitudes = create2DArray(bins.length, spectrums.length, -100);
  const bisectorRight = d3.bisector(d => d[1]).right;
  const bisectorLeft = d3.bisector(d => d[1]).left;

  spectrums.forEach((spectrum, spec_idx) => {
    spectrum.spectrum_data.forEach((d) => {
      let left_idx = bisectorLeft(bins, d.x);
      let right_idx = bisectorRight(bins, d.x);

      left_idx = left_idx === bins.length ? left_idx - 1 : left_idx;
      right_idx = right_idx === bins.length ? right_idx - 1 : right_idx;
      amplitudes[left_idx][spec_idx] = Math.max(amplitudes[left_idx][spec_idx], d.y);
      amplitudes[right_idx][spec_idx] = Math.max(amplitudes[right_idx][spec_idx], d.y);
    });
  });
  return amplitudes;
};

export const combineBinsAndAmplitudes = (amplitudes, bins, algorithm) => {
  if (!amplitudes || !bins || amplitudes.length !== bins.length) return [];
  switch (algorithm) {
    case 'average':
      return _.map(bins, (bin, idx) => {
        const amps = amplitudes[idx];
        let amp = 0;
        if (amps.length) amp = _.sum(amps) / amps.length;
        const deviation = calculateStandardDeviation(amps);
        // mean + 1 * standard deviation
        return [...bin, amp + deviation];
      });
    case 'max':
      return _.map(bins, (bin, idx) => {
        const amp = _.max(amplitudes[idx]);
        return [...bin, amp];
      });
    case 'standard_deviation':
      return _.map(bins, (bin, idx) => {
        const amps = amplitudes[idx];
        let avg = 0;
        if (amps.length) avg = _.sum(amps) / amps.length;
        const deviation = calculateStandardDeviation(amps);
        return [...bin, [avg, deviation]];
      });
    default:
      return [];
  }
};

export const createBaseGraph = (spectrums, spectral_window_settings, algorithm, x_scale) => {
  const { type, width } = spectral_window_settings;
  const included_spectrums = spectrums.filter(sp => !sp.exclude);
  const min_caution_level = spectral_window_settings.min_caution_level;
  const bins = createBins(included_spectrums, type, width, min_caution_level, x_scale);

  const amplitudes = getMaxAmplitudesInBins(bins, included_spectrums);
  const base_graph = combineBinsAndAmplitudes(amplitudes, bins, algorithm);
  return base_graph;
};

export const prepareEnvelopeResponse = (envelope, x_scale, x_units) => {
  const start_frequencies = [];
  const end_frequencies = [];
  const amplitudes = [];
  envelope.forEach((e) => {
    start_frequencies.push(e[0]);
    end_frequencies.push(e[1]);
    amplitudes.push(e[2]);
  });

  return {
    start_frequencies,
    end_frequencies,
    amplitudes,
    x_scale,
    x_units
  };
};

export const convertSpectrumDataToOrders = spectrums =>
  spectrums.map((sp) => {
    const shaft_speed = sp.shaft_speed;
    sp.fmax = round(sp.fmax / shaft_speed);
    sp.spectrum_data = sp.spectrum_data.map((data) => {
      data.x = round(data.x / shaft_speed);
      return data;
    });
    return sp;
  });

export const createEnvelope = (spectrums, envelope_settings, x_scale = X_SCALE.FREQUENCY, x_units = 'Hz') => {
  if (_.isEmpty(spectrums) || _.isEmpty(envelope_settings)) {
    return {
      start_frequencies: [],
      end_frequencies: [],
      amplitudes: [],
      x_scale
    };
  }

  const { spectral_window, threshold } = envelope_settings;
  let algorithm = 'average';
  if (threshold.type === THRESHOLD_OPTIONS.STD_ABOVE_BASE_GRAPH) algorithm = 'standard_deviation';
  const base_graph = createBaseGraph(spectrums, spectral_window, algorithm, x_scale);
  const envelope = applyThreshold(base_graph, spectral_window.min_caution_level, threshold);
  return prepareEnvelopeResponse(envelope, x_scale, x_units);
};

export const applyThreshold = (base_graph, min_caution_level, threshold) => {
  let envelope = [];
  const { starting_frequency, type, value } = threshold;
  if (type === THRESHOLD_OPTIONS.STD_ABOVE_BASE_GRAPH) {
    envelope = _.map(base_graph, d => [d[0], d[1], round(Math.max(min_caution_level, d[2][0] + d[2][1] * value))]);
  } else {
    envelope = _.map(base_graph, d => [d[0], d[1], round(Math.max(min_caution_level, d[2] * (1 + (value / 100))))]);
  }
  const bisector = d3.bisector(d => d[1]).right;
  if (starting_frequency) {
    const starting_freq_idx = bisector(envelope, starting_frequency);
    if (starting_freq_idx === envelope.length) return [];
    const ele = envelope[starting_freq_idx];
    // if element lies in the bucket
    if (ele[0] <= starting_frequency && starting_frequency < ele[1]) {
      envelope[starting_freq_idx][0] = starting_frequency;
    }
    envelope.splice(0, starting_freq_idx);
  }

  return envelope;
};

export const getEnvelopeSettingsInPrefUnits = (envelopeSettings, oldUnit, newUnit, shaftSpeed, shaftSpeedUnits) => {
  if (!envelopeSettings) return envelopeSettings;
  const freq_fact = getFreqConversionFactor(oldUnit, newUnit, shaftSpeed, shaftSpeedUnits);
  const settings = _.cloneDeep(envelopeSettings);
  Object.keys(settings).forEach((bin) => {
    const binSetting = settings[bin];
    if (binSetting.spectral_window.type === 'fixed_center_frequency' || binSetting.spectral_window.type === 'fixed') {
      binSetting.spectral_window.width = round(binSetting.spectral_window.width * freq_fact, 2);
    }
    binSetting.threshold.starting_frequency = round(binSetting.threshold.starting_frequency * freq_fact, 2);
    if (newUnit === 'Orders') {
      binSetting.x_scale = X_SCALE.ORDERS;
    } else {
      binSetting.x_scale = X_SCALE.FREQUENCY;
    }
  });
  return settings;
};

export const transformFromEnvelopeKeys = (data, amp_type, keys) => {
  if (!data) return data;
  const obj = {};
  const bins = Object.keys(data);

  const prefix = amp_type === 'velocity' ? '' : `${amp_type}_`;
  const transformed_keys = keys.map(key => prefix + key);

  bins.forEach((bin) => {
    if (data[bin][transformed_keys[0]]) {
      transformed_keys.forEach((key, idx) => {
        _.set(obj, `${bin}.${keys[idx]}`, data[bin][key]);
      });
    }
    if (_.isEmpty(obj[bin])) delete obj[bin];
  });
  if (_.isEmpty(obj)) return null;
  return obj;
};
