import React from 'react';
import PropTypes from 'prop-types';

const SplitIconSvg = ({ width, height, active, primaryColor }) => (
<svg width={width} height={height} viewBox="0 0 16 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g id="Machine/Health" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="1-0-3-asset-health-diagnostics-misalignment" transform="translate(-1200.000000, -25.000000)">
      <g id="top_bar-spectrum">
        <g id="stacked-icon" transform="translate(1200.000000, 25.000000)">
          <rect id="bounds" x={0} y={0} width={16} height={16} />
          <g id="icon" transform="translate(1.000000, 1.000000)" stroke={active ? primaryColor : '#999B95'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
            <line x1="2.1875" y1="13.01888" x2="2.1875" y2="0.41888" id="Shape" />
            <polyline id="Shape" points="0.4375 2.09888 2.1875 0.41888 3.9375 2.09888" />
            <polyline id="Shape" points="0.4375 11.33888 2.1875 13.01888 3.9375 11.33888" />
            <line x1="13.5625" y1="0.41888" x2="9.1875" y2="0.41888" id="Shape" />
            <line x1="13.5625" y1="2.93888" x2="6.5625" y2="2.93888" id="Shape" />
            <line x1="13.5625" y1="5.45888" x2="6.5625" y2="5.45888" id="Shape" />
            <line x1="13.5625" y1="7.97888" x2="9.1875" y2="7.97888" id="Shape" />
            <line x1="13.5625" y1="10.49888" x2="6.5625" y2="10.49888" id="Shape" />
            <line x1="13.5625" y1="13.01888" x2="6.5625" y2="13.01888" id="Shape" />
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

);

SplitIconSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  active: PropTypes.bool
};

SplitIconSvg.defaultProps = {
  width: 16,
  height: 15,
  active: false
};

export default SplitIconSvg;
