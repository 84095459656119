import styled from 'styled-components';

import FlexContainer from 'common/components/atoms/FlexContainer';

const SelectionContainer = styled.div`
${props => props.position && `position: ${props.position}`}
top: 1em;
text-align: center;
flex-direction: row;
display: flex;
align-items: center;
justify-content: center;
margin: auto;
left: 0;
right: 0;
pointer-events: none;
> ${FlexContainer} {
  align-items: center;
  margin: 0 0.5em;
  > span {
    font-size: 10px;
  }
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
`;

export default SelectionContainer;
