import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { getTaskDetails } from 'home/HomePage/actions/tasks.actions';
import { noteConstants, taskConstants } from 'home/HomePage/constants/constants';
import { alertActions } from '../../../alert';
import { axiosInstance, ENDPOINT } from '../../../common/constants';
import { handleResponse } from '../../../common/helpers';
import ActivityTypes from './activity.types';

import * as utils from '../../../common/utils';

import * as definitionService from '../../Settings/EventDefinitions/definition.service';
import definitionTypes from '../../Settings/EventDefinitions/definition.types';

export const getActivity = (
  searchParams,
  machine_id,
  paginate = false,
  newCtx = false,
  storeInRedux = true,
  eventCallbacks = {
    onSuccess: () => {},
    onFailure: () => {}
  }
) => {
  const request = newContext => ({ type: ActivityTypes.GET_EVENTS_REQUEST, newContext });
  const success = events => ({ type: ActivityTypes.GET_EVENTS_SUCCESS, events });
  const failure = error => ({ type: ActivityTypes.GET_EVENTS_FAILURE, error });

  return (dispatch, getState) => {
    const prevState = getState().events;
    const account_id = getState().user.user.account_id;
    let { nextPage } = prevState;
    // newContext will reset current state stored list of events as well as reset pageNr to 1
    let newContext = !_.isEqual(searchParams, prevState.searchParams) || (machine_id !== prevState.machine_id) || (account_id !== prevState.account_id) || newCtx;
    if (paginate) newContext = false;
    if (newContext) {
      nextPage = 1;
    }
    const params = {
      machine_id,
      resource_version: 3,
      page: nextPage,
      per_page: searchParams.per_page || ActivityTypes.NUMBER_OF_EVENTS_PER_PAGE,
      order_by: searchParams.order_by,
      search_key: searchParams.search_key,
      type: searchParams.type.map(type => type.toLowerCase()),
      event_type: searchParams.event_type
    };
    if (searchParams.days === 'Custom') {
      params.start_date = searchParams.startDate;
      params.end_date = searchParams.endDate;
    } else {
      params.days = searchParams.days;
    }
    params.filters = [];
    if (searchParams.state.length) {
      params.filters.push({
        name: 'state',
        op: 'in',
        value: searchParams.state
      });
    }
    if (searchParams.machines.length) {
      params.filters.push({
        name: 'machine_id',
        op: 'in',
        value: searchParams.machines
      });
    }
    if (searchParams.read_status.length) {
      params.filters.push({
        name: 'read_status',
        op: 'in',
        value: searchParams.read_status.map(r => r === 'read')
      });
    }

    if (searchParams.analysis.length) {
      params.filters.push({
        name: 'analysis',
        op: 'in',
        value: searchParams.analysis.map(r => r === 'analysed')
      });
    }

    if (searchParams.suspension_status.length) {
      params.filters.push({
        name: 'suspension',
        op: 'in',
        value: searchParams.suspension_status.map(r => r === 'on')
      });
    }

    if (searchParams.defect.length) {
      params.filters.push({
        name: 'defect',
        op: 'in',
        value: searchParams.defect
      });
    }

    if (storeInRedux) {
      dispatch(request(newContext));
      dispatch(alertActions.clear());
    }
    return handleResponse(axiosInstance.get(ENDPOINT.GET_ACTIVITY, {
      params
    }))
      .then(
        (res) => {
          const hasMore = res.total_pages > nextPage;
          const total_count = res.total_count;
          const newList = res.items.map(activity => ({
            ...activity,
            timestamp: activity.timestamp
          }));
          let list = [];
          let observation_list = [];
          if (nextPage === 1) {
            newList.forEach((activity) => {
              if (!activity.observation) list.push(activity);
              else observation_list.push(activity);
            });
          } else {
            list = [...prevState.list, ...newList];
            observation_list = [...prevState.observation_list];
          }
          const events = {
            account_id,
            machine_id,
            list,
            observation_list,
            hasMore,
            searchParams,
            nextPage: hasMore ? (nextPage + 1) : nextPage,
            total_count
          };
          if (eventCallbacks && eventCallbacks.onSuccess) eventCallbacks.onSuccess(events);
          if (storeInRedux) dispatch(success(events));
          return res.items;
        },
        (error) => {
          if (eventCallbacks && eventCallbacks.onFailure) eventCallbacks.onFailure(error);
          if (storeInRedux) {
            dispatch(failure(error));
            dispatch(alertActions.error(error.message));
          }
        }
      );
  };
};

export const getGroupedActivity = (searchParams, machine_id, storeInRedux = true, page = 1) => {
  const request = newContext => ({ type: ActivityTypes.GET_EVENTS_REQUEST, newContext });
  const success = events => ({ type: ActivityTypes.GET_EVENTS_SUCCESS, events });
  const poolEventsForGrouping = events => ({ type: ActivityTypes.POOL_EVENTS_FOR_GROUPING, events });
  const failure = error => ({ type: ActivityTypes.GET_EVENTS_FAILURE, error });

  return (dispatch, getState) => {
    const prevState = getState().events;
    const account_id = getState().user.user.account_id;
    let nextPage = page;

    const newContext = !_.isEqual(searchParams, prevState.searchParams) || (machine_id !== prevState.machine_id) || (account_id !== prevState.account_id);
    const params = {
      machine_id,
      resource_version: 3,
      page: nextPage,
      per_page: 500,
      order_by: searchParams.order_by,
      search_key: searchParams.search_key,
      type: searchParams.type.map(type => type.toLowerCase()),
      event_type: searchParams.event_type
    };

    if (searchParams.days === 'Custom') {
      params.start_date = searchParams.startDate;
      params.end_date = searchParams.endDate;
    } else {
      params.days = searchParams.days;
    }
    params.filters = [];
    if (searchParams.state.length) {
      params.filters.push({
        name: 'state',
        op: 'in',
        value: searchParams.state
      });
    }
    if (searchParams.machines.length) {
      params.filters.push({
        name: 'machine_id',
        op: 'in',
        value: searchParams.machines
      });
    }
    if (searchParams.read_status.length) {
      params.filters.push({
        name: 'read_status',
        op: 'in',
        value: searchParams.read_status.map(r => r === 'read')
      });
    }
    if (searchParams.analysis.length) {
      params.filters.push({
        name: 'analysis',
        op: 'in',
        value: searchParams.analysis.map(r => r === 'analysed')
      });
    }
    if (searchParams.suspension_status.length) {
      params.filters.push({
        name: 'suspension',
        op: 'in',
        value: searchParams.suspension_status.map(r => r === 'on')
      });
    }

    if (searchParams.defect.length) {
      params.filters.push({
        name: 'defect',
        op: 'in',
        value: searchParams.defect
      });
    }
    if (storeInRedux) {
      dispatch(request(newContext));
      dispatch(alertActions.clear());
    }
    return handleResponse(axiosInstance.get(ENDPOINT.GET_ACTIVITY, { params }))
      .then(
        async (res) => {
          const hasMore = res.total_pages > nextPage;
          const newList = res.items.map(activity => ({
            ...activity,
            timestamp: activity.timestamp
          }));

          let list = [];
          let observation_list = [];

          if (nextPage === 1) {
            newList.forEach((activity) => {
              if (!activity.observation) list.push(activity);
              else observation_list.push(activity);
            });
          } else {
            list = [...prevState.list, ...newList];
            observation_list = [...prevState.observation_list];
          }

          const events = {
            account_id,
            machine_id,
            list,
            observation_list,
            hasMore,
            searchParams,
            nextPage: hasMore ? (nextPage + 1) : nextPage,
            total_count: res.total_count
          };

          if (hasMore) {
            nextPage += 1;
            const nextPageSearchParams = { ...searchParams, page: nextPage };
            dispatch(poolEventsForGrouping(events));
            await dispatch(getGroupedActivity(nextPageSearchParams, machine_id, false, nextPage));
          } else {
            dispatch(success(events));
            return list;
          }
        },
        (error) => {
          if (storeInRedux) {
            dispatch(failure(error));
            dispatch(alertActions.error(error.message));
          }
        }
      );
  };
};


export const getEventDetails = (id, activity_type) => {
  const request = () => ({ type: ActivityTypes.GET_EVENT_DETAILS_REQUEST, id, activity_type });
  const success = event => ({ type: ActivityTypes.GET_EVENT_DETAILS_SUCCESS, event });
  const failure = error => ({ type: ActivityTypes.GET_EVENT_DETAILS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    // todo add view_all option
    return handleResponse(axiosInstance.get(ENDPOINT.GET_EVENT_DETAILS(id), { params: { view_all: false } }))
      .then(
        (res) => {
          const details = {
            ...res,
            description_metadata: _.mapValues(res.description_metadata, (metadata) => {
              if (utils.isValidDatetime(metadata)) return utils.formatDate(metadata);
              return metadata;
            })
          };
          dispatch(success({ details, id, activity_type }));
        },
        (error) => {
          dispatch(failure({ error, id, activity_type }));
        }
      );
  };
};

export const getComments = (activity_id, activity_type) => {
  const request = () => ({ type: ActivityTypes.GET_ACTIVITY_COMMENTS_REQUEST, activity_type, activity_id });
  const success = item => ({ type: ActivityTypes.GET_ACTIVITY_COMMENTS_SUCCESS, item });
  const failure = error => ({ type: ActivityTypes.GET_ACTIVITY_COMMENTS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_ACTIVITY_COMMENTS(activity_type, activity_id)))
      .then(
        (details) => {
          dispatch(success({ details, activity_id, activity_type }));
        },
        (error) => {
          dispatch(failure({ error, activity_id, activity_type }));
        }
      );
  };
};

export const getNoteDetails = (id, activity_type, isTaskPage = false, isInternal = false, note = null) => {
  const setOpenNoteDetails = noteDetails => ({ type: noteConstants.SET_NOTE_DETAILS, noteDetails });
  const updateTaskNote = note => ({ type: taskConstants.UPDATE_TASK_NOTE, note });
  const request = () => ({ type: ActivityTypes.GET_NOTE_DETAILS_REQUEST, id, activity_type });
  const success = note => ({ type: ActivityTypes.GET_NOTE_DETAILS_SUCCESS, note });
  const failure = error => ({ type: ActivityTypes.GET_NOTE_DETAILS_FAILURE, error });

  return (dispatch, getState) => {
    if (isInternal) {
      const noteDetails = {
        ...note,
        id: note.activity_id,
        activity_type: note.type,
        details: {
          note: note.note_text
        }
      };
      dispatch(success(noteDetails));
      return noteDetails;
    }

    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_NOTE_DETAILS(id)))
      .then(
        (res) => {
          const details = {
            ...res
          };
          switch (details.chart_snapshot_data && details.chart_snapshot_data.chart_type) {
            case 'trend':
              details.chart_snapshot_data = {
                ...details.chart_snapshot_data,
                data: {
                  ...res.chart_snapshot_data.data,
                  chart_data: utils.formatChartData(
                    details.chart_snapshot_data.data.data,
                    utils.convertTimestringsToDateObjects(details.chart_snapshot_data.data.time),
                    null,
                    { measurement_id: details.chart_snapshot_data.data.measurement_id }
                  )
                }
              };
              break;
            case 'spectrum':
              details.chart_snapshot_data = {
                ...details.chart_snapshot_data,
                data: {
                  ...details.chart_snapshot_data.data,
                  chart_data: utils.formatChartData(
                    details.chart_snapshot_data.data.mag,
                    details.chart_snapshot_data.data.freq
                  )
                }
              };
              break;
            case 'waveform':
              details.chart_snapshot_data = {
                ...details.chart_snapshot_data,
                data: {
                  ...details.chart_snapshot_data.data,
                  chart_data: utils.formatChartData(
                    details.chart_snapshot_data.data.data,
                    details.chart_snapshot_data.data.time
                  )
                }
              };
              break;
            default:
              break;
          }
          dispatch(success({ details, id, activity_type }));

          const noteModal = getState().noteModal;
          if (noteModal.open && noteModal.noteId === id) {
            dispatch(setOpenNoteDetails({ ...res, note_text: res.note }));
          }
          if (isTaskPage) {
            dispatch(updateTaskNote({ ...res, note_text: res.note }));
          }

          return res;
        },
        (error) => {
          dispatch(failure({ error, id, activity_type }));
          toastr.error(error.message);
          throw error;
        }
      );
  };
};

export const postComment = (id, comment, type) => {
  const request = () => ({ type: ActivityTypes.POST_ACTIVITY_COMMENT_REQUEST });
  const success = item => ({ type: ActivityTypes.POST_ACTIVITY_COMMENT_SUCCESS, item });
  const failure = error => ({ type: ActivityTypes.POST_ACTIVITY_COMMENT_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    dispatch(alertActions.clear());
    return handleResponse(axiosInstance.post(ENDPOINT.POST_COMMENT(type, id), { comment }))
      .then(
        (res) => {
          dispatch(success({ id, type, res }));
        },
        (error) => {
          dispatch(failure({ error, id, type }));
          dispatch(alertActions.error(error.message));
        }
      );
  };
};

export const putComment = (id, comment, type, comment_id) => {
  const request = () => ({ type: ActivityTypes.PUT_ACTIVITY_COMMENT_REQUEST });
  const success = item => ({ type: ActivityTypes.PUT_ACTIVITY_COMMENT_SUCCESS, item });
  const failure = error => ({ type: ActivityTypes.PUT_ACTIVITY_COMMENT_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_COMMENT(type, id, comment_id), { comment }))
      .then(
        (res) => {
          dispatch(success({ id, type, res }));
        },
        (error) => {
          dispatch(failure({ error, id, type }));
          dispatch(alertActions.error(error.message));
        }
      );
  };
};

export const deleteComment = (id, type, comment_id) => {
  const request = () => ({ type: ActivityTypes.DELETE_ACTIVITY_COMMENT_REQUEST });
  const success = item => ({ type: ActivityTypes.DELETE_ACTIVITY_COMMENT_SUCCESS, item });
  const failure = error => ({ type: ActivityTypes.DELETE_ACTIVITY_COMMENT_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.delete(ENDPOINT.DELETE_COMMENT(type, id, comment_id)))
      .then(
        (res) => {
          dispatch(success({ id, type, res }));
        },
        (error) => {
          dispatch(failure({ error, id, type }));
        }
      );
  };
};

export const deleteNote = (note_id, task_id = null) => {
  const request = () => ({ type: ActivityTypes.DELETE_NOTE_REQUEST });
  const success = () => ({ type: ActivityTypes.DELETE_NOTE_SUCCESS, note_id });
  const failure = error => ({ type: ActivityTypes.DELETE_NOTE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.delete(ENDPOINT.NOTE(note_id)))
      .then(
        () => {
          dispatch(success());
          if (task_id) dispatch(getTaskDetails(task_id));
          toastr.success('Note deleted successfully');
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };
};
/*
todo close event
export const closeEvent = (event_id) => {
  const request = () => ({ type: ActivityTypes.CLOSE_EVENT_REQUEST });
  const success = eventId => ({ type: ActivityTypes.CLOSE_EVENT_SUCCESS, eventId });
  // const failure = error => ({ type: ActivityTypes.CLOSE_EVENT_FAILURE, error });

  const mockCloseParams = {
    status: 'inactive',
    close_reason: 'this is why i close this'
  };

  return (dispatch) => {
    dispatch(request());
    dispatch(alertActions.clear());
    dispatch(success(event_id));
    // return handleResponse(axiosInstance.put(ENDPOINT.CLOSE_EVENT(event_id), {
    //   mockCloseParams
    // }))
    //   .then(
    //     (res) => {
    //       dispatch(success(res));
    //     },
    //     (error) => {
    //       dispatch(failure(error));
    //       dispatch(alertActions.error(error));
    //     }
    //   );
  };
};
*/
export const getResourcesBasedOnType = (resource_type) => {
  return (dispatch) => {
    const params = {
      resource_type
    };

    dispatch(request());
    return definitionService.getResources(params).then(
      (res) => {
        const resources = res.result.map(r => ({ value: r.id, text: r.name }));
        dispatch(success(resources));
        return resources;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: definitionTypes.GET_RESOURCES_REQUEST };
  }
  function success(resources) {
    return { type: definitionTypes.GET_RESOURCES_SUCCESS, resources };
  }
  function failure(error) {
    return { type: definitionTypes.GET_RESOURCES_FAILURE, error };
  }
};

export const updateActivity = (payload, action, time_period = null, comment = null) => {
  const request = () => ({ type: ActivityTypes.UPDATE_ACTIVITY_REQUEST });
  const success = (message, payload, action) => ({ type: ActivityTypes.UPDATE_ACTIVITY_SUCCESS, message, payload, action });
  const failure = error => ({ type: ActivityTypes.UPDATE_ACTIVITY_FAILURE, error });

  return (dispatch) => {
    const params = {
      payload,
      action,
      ...(time_period && { time_period }),
      ...(comment && { comment })
    };

    dispatch(request());
    return handleResponse(axiosInstance.patch(ENDPOINT.UPDATE_ACTIVITY, params))
      .then(
        (res) => {
          dispatch(success(res.message, payload, action));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };
};

export const markEventsAsAnalyzed = (event_ids, notes, machine_id) => {
  const request = () => ({ type: ActivityTypes.MARK_EVENTS_ANALYZED_REQUEST });
  const success = () => ({ type: ActivityTypes.MARK_EVENTS_ANALYZED_SUCCESS });
  const failure = () => ({ type: ActivityTypes.MARK_EVENTS_ANALYZED_SUCCESS });

  return (dispatch) => {
    const params = {
      event_ids,
      notes,
      machine_id
    };

    dispatch(request());
    return handleResponse(axiosInstance.post(ENDPOINT.MARK_EVENTS_AS_ANALYZED, params))
      .then(
        (res) => {
          dispatch(success());
          toastr.success(res.message);
        },
        (error) => {
          dispatch(failure());
          toastr.error(error.message);
        }
      );
  };
};

export const updateActivityRead = (payload, action) => {
  const request = () => ({ type: ActivityTypes.UPDATE_ACTIVITY_READ_REQUEST });
  const success = (message, payload, action) => ({ type: ActivityTypes.UPDATE_ACTIVITY_READ_SUCCESS, message, payload, action });
  const failure = error => ({ type: ActivityTypes.UPDATE_ACTIVITY_READ_FAILURE, error });

  return (dispatch) => {
    const params = {
      payload,
      action
    };

    dispatch(request());
    return handleResponse(axiosInstance.patch(ENDPOINT.UPDATE_ACTIVITY_READ, params))
      .then(
        (res) => {
          dispatch(success(res.message, payload, action));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };
};
