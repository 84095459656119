import React from 'react';
import _ from 'lodash';

import { ENDPOINT } from 'common/constants';
import { history } from 'common/helpers';
import { getBaseUrl } from 'home/DomainName/DomainConstants';
import LoginWithSSOForm from './LoginWithSSOForm';
import { OnboardingHeader } from '../../common/OnboardingComponents';

export class LoginWithSSO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: '',
      email: null,
      slug: null,
      useEmail: false
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const errorMsg = urlParams.get('error');
    const email = urlParams.get('email');
    const slug = urlParams.get('slug');

    if (errorMsg) {
      this.setState({ errorMsg, email, slug, useEmail: Boolean(email) });
      history.replace('/sso');
    }
  }

  toggleUseEmail = () => {
    this.setState(prevState => ({ useEmail: !prevState.useEmail }));
  }

  handleSubmit = (values) => {
    const { slug, email } = values;
    const { useEmail } = this.state;
    const { location } = this.props;
    
    // Get destination from state or URL and make it absolute
    let relayState = window.location.origin;
    if (location.state) {
      relayState = `${window.location.origin}${location.state.pathname}${location.state.search || ''}`;
    } else if (location.pathname !== '/login' && location.pathname !== '/sso') {
      relayState = `${window.location.origin}${location.pathname}${location.search || ''}`;
    }
    
    let redirectUrl = `${getBaseUrl()}/${ENDPOINT.SSO_LOGIN}`;
    if (useEmail) {
      redirectUrl = `${redirectUrl}?email=${email}&relay_state=${encodeURIComponent(relayState)}`;
    } else {
      redirectUrl = `${redirectUrl}?slug=${slug}&relay_state=${encodeURIComponent(relayState)}`;
    }

    window.location.assign(redirectUrl);
  };

  render() {
    const { useEmail, email, slug, errorMsg } = this.state;
    return (
      <div className="auth-page">
        <OnboardingHeader />
        <LoginWithSSOForm
          useEmail={useEmail}
          toggleUseEmail={this.toggleUseEmail}
          errorMsg={errorMsg}
          onSubmit={this.handleSubmit}
          initialValues={{ email, slug }}
        />
      </div>
    );
  }
}

export default LoginWithSSO;
