
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';

import subscriptionMetadataInfo from 'home/HomePage/SubscriptionBanner.constants';
import { getCurrentAccount } from 'onboarding/user.actions';
import { dayDifference } from 'common/utils';
import Banner from './atoms/Banner';

class SubscriptionBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      show_banner: false,
    };
  }

    getSusbscriptionRemainingDays = () => {
      const expiry_date = new Date(this.props.currentAccount.subscription_end_date);
      return dayDifference(expiry_date, new Date());
    }

    closeBanner = () => {
      this.setState({ show_banner: false });
      localStorage.setItem('bannerClosedDate', new Date());
    }

    componentDidMount() {
      this.setState({ show_banner: this.isTimeToShowBanner() });
    }

    componentDidUpdate(prevProps) {
      if (!_.isEqual(prevProps.currentAccount.subscription_end_date, this.props.currentAccount.subscription_end_date)) {
        this.setState({ show_banner: this.isTimeToShowBanner() });
      }
    }

    getBannerText = () => {
      const subscription_type = this.props.currentAccount.subscription_type;
      const remaining_days = this.getSusbscriptionRemainingDays();
      if (remaining_days > 0) {
        return `Your ${subscription_type} subscription is going to expire in ${remaining_days} days. `;
      }
      if (remaining_days === 0) {
        return `Your ${subscription_type} subscription is going to expire today. `;
      }
      return `Your ${subscription_type} subscription has expired. `;
    }

    isTimeToShowBanner = () => {
      const { subscription_end_date } = this.props.currentAccount;
      if (!subscription_end_date) return false;
      if (this.getSusbscriptionRemainingDays() > 30) return false;

      let bannerClosedDate = localStorage.getItem('bannerClosedDate');
      if (!bannerClosedDate) return true;

      const expiry_date = new Date(subscription_end_date);
      bannerClosedDate = new Date(bannerClosedDate);
      const daysRemainingFromToday = dayDifference(expiry_date, new Date());
      const daysRemainingWhenBannerClosed = dayDifference(expiry_date, bannerClosedDate);

      for (let i = 0; i < subscriptionMetadataInfo.length - 1; i++) {
        if ((daysRemainingFromToday > subscriptionMetadataInfo[i].remainingDays &&
        daysRemainingWhenBannerClosed > subscriptionMetadataInfo[i].remainingDays &&
        daysRemainingFromToday <= subscriptionMetadataInfo[i + 1].remainingDays &&
        daysRemainingWhenBannerClosed <= subscriptionMetadataInfo[i + 1].remainingDays)) return false;
      }

      return true;
    }

    getSubscriptionBannerInfo = () => subscriptionMetadataInfo.find(
      info => this.getSusbscriptionRemainingDays() <= info.remainingDays
    );

    render() {
      const subscriptionBannerInfo = this.getSubscriptionBannerInfo();
      if (!this.state.show_banner || !subscriptionBannerInfo) return null;
      return (
        <Banner
          primaryText={this.getBannerText()}
          secondaryText="Renew it now."
          secondaryColor={subscriptionBannerInfo.secondaryColor}
          backgroundColor={subscriptionBannerInfo.backgroundColor}
          close={this.getSusbscriptionRemainingDays() >= 0 ? this.closeBanner : null}
        />
      );
    }
}

const mapStateToProps = (state) => {
  const { currentAccount } = state;
  return {
    currentAccount
  };
};

const mapDispatchToProps = dispatch => ({
  getCurrentAccount: bindActionCreators(getCurrentAccount, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionBanner);
