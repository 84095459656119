import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import FlexContainer from '../atoms/FlexContainer';
import { ChevronDown } from '../../images/FaIcons';
import InlineModal from './InlineModal';
import OutsideAlerter from '../../OutsideAlerter';

const FilterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
`;

const FilterLabel = styled.span`
  white-space: pre;
  margin-right: 0.5em;
  color: gray;
  font-size: 12px;
  font-weight: 600;
`;

const ValueSelector = styled(FlexContainer).attrs({ alignItems: 'center' })`
  cursor: pointer;
  ${props => props.disabled && 'cursor: not-allowed;'}
`;

const Value = styled.span.attrs({ className: 'filter-value' })`
  margin-right: 0.5em;
  font-weight: 600;
  color: ${props => props.theme.colors.black};
  font-size: 12px;
`;

const Filter = ({
  event,
  title,
  value,
  open,
  toggleOpen,
  alignVertical,
  alignHorizontal,
  onChangeSearch,
  searchValue,
  children,
  viewAllItems,
  selectedItems,
  selectGivenItems,
  itemName,
  width,
  minWidth,
  Icon,
  zIndex,
  modalClassName,
  displayItemClassName,
  disabled,
  hoverTitle,
  customDisplayValue,
  autoFocusSearch,
  ...rest
}) => (
  <FilterContainer {...rest}>
    <ValueSelector
      className={displayItemClassName}
      onClick={toggleOpen}
      disabled={disabled}
    >
      {!customDisplayValue && (
        <>
          <FilterLabel>
            {title}
          </FilterLabel>
          {!_.isEmpty(value) && (
            <Value title={hoverTitle}>
              {value}
            </Value>
          )}
          {Icon ? <Icon /> : <ChevronDown size="xs" />}
        </>
      )}
      {customDisplayValue && (
        <>{customDisplayValue()}</>
      )}
    </ValueSelector>
    {open && (
      <OutsideAlerter
        open={open}
        handleClick={toggleOpen}
        event={event}
      >
        <InlineModal
          alignVertical={alignVertical}
          alignHorizontal={alignHorizontal}
          close={toggleOpen}
          searchValue={searchValue}
          onChangeSearch={onChangeSearch}
          viewAllItems={viewAllItems}
          selectedItems={selectedItems}
          selectGivenItems={selectGivenItems}
          itemName={itemName}
          width={width}
          minWidth={minWidth}
          zIndex={zIndex}
          noScroll={rest.noScroll}
          className={modalClassName}
          noOverlay={rest.noOverlay}
          minWidthInline={rest.minWidthInline}
          marginTop={rest.marginTop}
          autoFocusSearch={autoFocusSearch}
        >
          {children}
        </InlineModal>
      </OutsideAlerter>
    )
    }
  </FilterContainer>
);

Filter.propTypes = {
  event: PropTypes.oneOf(['mousedown', 'click']),
  title: PropTypes.string,
  value: PropTypes.string,
  hoverTitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  alignVertical: PropTypes.oneOf(['top', 'bottom']),
  alignHorizontal: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  Icon: PropTypes.node,
  modalClassName: PropTypes.string,
  displayItemClassName: PropTypes.string,
  autoFocusSearch: PropTypes.bool
};

Filter.defaultProps = {
  event: 'mousedown',
  title: undefined,
  value: undefined,
  alignVertical: 'bottom',
  modalClassName: '',
  alignHorizontal: 'left',
  children: undefined,
  Icon: undefined,
  hoverTitle: '',
  displayItemClassName: 'filter-item',
  autoFocusSearch: false
};

export default styled(Filter)``;
