import styled from 'styled-components';

import FlexContainer from './FlexContainer';

const Background = styled(FlexContainer)`
  position: fixed;
  top: ${props => props.version === 'v2' ? '55px' : '0'};
  left: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: ${props => (props.fullScreen ? 'white' : 'rgba(56,56,56,0.96)')};
`;

Background.displayName = 'Background';

export default styled(Background)``;
