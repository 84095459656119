import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SortContainer from './components/SortContainer';
import Label from '../../typography/Label/Label';
import { SortUp, SortDown } from '../../images/FaIcons';
import Checkbox from '../atoms/Checkbox';

const HeadersRow = styled.tr`
  border-bottom: 2px solid ${props => props.theme.colors.borderGray};
  height: ${props => props.headerHeight || 'auto'};
  ${props => (props.interactive !== null ? `
    th {
      label {
        cursor: pointer;
        color: ${props.theme.colors.darkGray};
      }
      svg {
        color: ${props.theme.colors.darkGray};
      }
      &:hover label {
        color: ${props.theme.colors.black};
      }
      &:hover svg {
        color: ${props.theme.colors.black};
      }
    }
  ` : '')}
  th {
    padding: 1rem 1rem 1rem 0;
  }
  th:first-of-type {
    padding-left: 1.5rem;
  }
  ${Label} {
    margin-bottom: -4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Container = styled.span`
  margin-left: .5em;
`;

const Th = styled.th`
  ${props => props.alignCenter && 'text-align: center;'}
`;

const CheckBoxContainer = styled.div`
  display: inline-flex;
  margin-right: 0.5rem;
  vertical-align: middle;
  font-size: 16px;
`;

class ListHeaders extends Component {
  updateSort = (el, header) => {
    const {
      sorterState, setSorter
    } = this.props;
    if (!sorterState) return;

    let newSorter = '';
    // FIXME: name -> field, order -> direction
    if (!sorterState.sorter || sorterState.sorter.name !== header.name) {
      newSorter = { name: header.name, order: 'asc' };
    } else {
      newSorter = { name: header.name, order: sorterState.sorter.order === 'asc' ? 'desc' : 'asc' };
    }
    setSorter(newSorter);
  }

  getSortClassName(name) {
    const {
      sorterState
    } = this.props;
    if (sorterState.sorter && sorterState.sorter.name === name) return sorterState.sorter.order;
    return '';
  }

  render() {
    const {
      sorterState,
      headerHeight,
      headers,
      columnSizes,
      editMode,
      allSelected,
      selectAll,
    } = this.props;
    return (
      <HeadersRow interactive={sorterState} headerHeight={headerHeight}>
        {headers.map((header, index) => (
          <Th alignCenter={header.alignCenter} key={index.toString()} width={`${columnSizes[index]}%`}>
            {editMode !== undefined && index === 0 && editMode && selectAll && (
              <CheckBoxContainer>
                <Checkbox value={allSelected} onClick={selectAll} />
              </CheckBoxContainer>
            )}
            <Label className="th-label" onClick={header.noSorter ? _ => _ : el => this.updateSort(el, header)}>
              {header.label}
              {sorterState && !header.noSorter && (
                <SortContainer className={`${this.getSortClassName(header.name)}`}>
                  <SortUp />
                  <SortDown />
                </SortContainer>
              )}
            </Label>
            {header.component && (
              <span className="tool-tip" data-tooltip={header.message} trigger="click" data-position="bottom center" data-variation="basic">
                <Container>{header.component}</Container>
              </span>
            )}
          </Th>
        ))}
      </HeadersRow>
    );
  }
}

ListHeaders.propTypes = {
  columnSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    alignCenter: PropTypes.bool
  })).isRequired,
  sorterState: PropTypes.object,
  setSorter: PropTypes.func.isRequired,
  allSelected: PropTypes.bool,
  selectAll: PropTypes.func,
};

ListHeaders.defaultProps = {
  sorterState: null,
  allSelected: false,
  selectAll: null,
};

export default ListHeaders;
