import React from 'react';
import colors from 'common/styles/colors';
import HealthIconSvg from 'common/images/HealthIconSvg';
import NoteSvgNew from 'common/images/NoteSvgNew';
import AlarmFlagSvg from 'common/images/AlarmFlagSvg';
import OverallAlarmWarningNew from 'common/images/OverallAlarmWarningNew';
import OverallAlarmCriticalNew from 'common/images/OverallAlarmCriticalNew';
import MoteUnreachableNew from 'common/images/MoteUnreachableNew';
import MachineSvg from 'common/images/MachineSvg';
import NewBatteryIcon from 'common/images/NewBatteryIcon';
import { Task, Wrench } from 'common/images/FaIcons';

export const sortOptions = [
  {
    value: {
      field: 'timestamp',
      direction: 'desc'
    },
    text: 'Date - newest'
  }, {
    value: {
      field: 'timestamp',
      direction: 'asc'
    },
    text: 'Date - oldest'
  }
];

export const taskSortOptions = [
  {
    value: {
      field: 'condition'
    },
    text: 'Task Priority'
  }
];

export const dayOptions = [
  {
    value: 1,
    text: '1 Day'
  }, {
    value: 7,
    text: '7 Days'
  }, {
    value: 30,
    text: '30 Days'
  }, {
    value: null,
    text: 'All'
  }
];

export const initialSearchParams = {
  order_by: sortOptions[0],
  search_key: '',
  type: [],
  days: dayOptions[3],
  state: ['open', 'in_progress', 'scheduled', 'review_pending'],
  event_type: [],
  machines: [],
  read_status: [],
  analysis: [],
  suspension_status: ['off'],
  defect: [],
  view_by: 'Individual',
};

export const siteLevelSortOptions = [
  ...sortOptions,
  {
    value: {
      field: 'machine_criticality',
      direction: 'desc'
    },
    text: 'Machine Criticality'
  },
  {
    value: {
      field: 'machines',
      direction: 'asc'
    },
    text: 'Machines'
  }
];

export const typeOptions = [
  { text: 'Event', value: 'event' },
  { text: 'Note', value: 'note' },
  { text: 'Diagnosis', value: 'diagnosis' },
  { text: 'AHS Feedback', value: 'ahs_feedback' },
  { text: 'Task', value: 'task' },
  { text: 'Repair', value: 'repair' },
];

export const analysisOptions = [
  { text: 'Analysed', value: 'analysed' },
  { text: 'Not Analysed', value: 'not_analysed' },
];

export const stateOptions = [
  { text: 'Open', value: 'open' },
  { text: 'In Progress', value: 'in_progress' },
  { text: 'Scheduled', value: 'scheduled' },
  { text: 'Review Pending', value: 'review_pending' },
  { text: 'Closed', value: 'closed' },
];

export const readStatusOptions = [
  { text: 'Read', value: 'read' },
  { text: 'Unread', value: 'unread' }
];

export const suspensionStatusOptions = [
  { text: 'On', value: 'on' },
  { text: 'Off', value: 'off' }
];

export const dateFormat = 'MMM Do, YYYY';

export const viewByOptions = [
  {
    text: 'Group by Asset'
  }, {
    text: 'Individual'
  }
];

export const activitiesInOrder = [
  'task', 'repair',
  'machine_condition_critical',
  'vibration_critical', 'temperature_critical',
  'vibration_acceleration_critical',
  'vibration_demod_critical',
  'health_score_critical',
  'machine_condition_warning',
  'vibration_warning',
  'temperature_warning',
  'vibration_demod_warning',
  'vibration_acceleration_warning',
  'vibration_envelope_alarm',
  'current_envelope_alarm',
  'health_score_warning',
  'battery_level_critical',
  'battery_level_warning',
  'tx_battery_level_critical',
  'tx_battery_level_warning',
  'mote_unreachable',
  'tx_unreachable',
  'note',
  'diagnosis'
];

export const getActivityIcon = (activityArrayWithCount) => {
  const highestCriticalEvent = activityArrayWithCount.reduce((highestEvent, currentEvent) => {
    if (currentEvent.count > highestEvent.count && currentEvent.event_type.includes('critical')) {
      return currentEvent;
    }
    return highestEvent;
  }, { count: 0 });
  const highestCountEvent = activityArrayWithCount.reduce((highestEvent, currentEvent) => {
    if (currentEvent.count > highestEvent.count) {
      return currentEvent;
    }
    return highestEvent;
  }, { count: 0 });

  const event_type = (highestCriticalEvent.count !== 0 && !highestCriticalEvent.event_type.includes('battery_level')) ? highestCriticalEvent.event_type : highestCountEvent.event_type;
  switch (event_type) {
    case 'vibration_warning':
    case 'vibration_demod_warning':
    case 'vibration_acceleration_warning':
    {
      return <OverallAlarmWarningNew />;
    }
    case 'vibration_critical':
    case 'vibration_demod_critical':
    case 'vibration_acceleration_critical':
    case 'vibration_envelope_alarm':
    case 'current_envelope_alarm': {
      return <OverallAlarmCriticalNew />;
    }
    case 'machine_condition_warning': {
      return <MachineSvg fill={colors.warning} />;
    }
    case 'machine_condition_critical': {
      return <MachineSvg fill={colors.critical} />;
    }
    case 'tx_unreachable': {
      return <AlarmFlagSvg fill={colors.alarmCritical} />;
    }
    case 'battery_level_warning':
    case 'battery_level_critical':
    case 'tx_battery_level_warning':
    case 'tx_battery_level_critical': {
      return <NewBatteryIcon fill={event_type.includes('critical') ? '#FF4F39' : '#F0C000'} />;
    }
    case 'mote_unreachable': {
      return <MoteUnreachableNew fill={colors.critical} />;
    }
    case 'diagnosis': {
      return <HealthIconSvg />;
    }
    case 'task': {
      return <Task />;
    }
    case 'repair': {
      return <Wrench />;
    }
    case 'note': {
      return <NoteSvgNew />;
    }
    default: {
      if (event_type) {
        return <AlarmFlagSvg fill={event_type.includes('critical') ? colors.alarmCritical : colors.alarmWarning} />;
      }
      return '';
    }
  }
};

export const getActivityTextColor = (text) => {
  switch (text) {
    case 'mote_unreachable':
    case 'tx_unreachable': {
      return colors.darkGray;
    }
    case 'diagnosis':
    case 'task':
    case 'repair':
    case 'note': {
      return colors.black;
    }
    default: {
      if (text.includes('critical')) return colors.critical;
      if (text.includes('warning') || text === 'vibration_envelope_alarm' || text === 'current_envelope_alarm') return colors.warning;
      if (text) return colors.alarmWarning;
      return colors.black;
    }
  }
};
