import rulActionTypes from '../actions/rul.types';

const initialState = {
  machineId: null,
  components: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case rulActionTypes.GET_RUL_TREND_REQUEST:
      return {
        ...state,
        machineId: action.machineId,
        components: {
          ...(action.machineId === state.machineId ? state.components : {}),
        }
      };
    case rulActionTypes.GET_RUL_TREND_SUCCESS:
      return {
        ...state,
        machineId: action.machineId,
        components: {
          ...state.components,
          [action.componentId]: {
            ...action.info
          }
        }
      };
    case rulActionTypes.GET_RUL_TREND_FAILURE:
      return {
        ...state,
        machineId: action.machineId,
        components: {
          ...state.components,
          [action.componentId]: {
            ...state.components[action.componentId],
            error: action.error
          }
        }
      };
    case rulActionTypes.GET_RUL_SETTINGS_REQUEST:
      return {
        ...state,
        machineId: action.machineId,
        components: {
          ...(action.machineId === state.machineId ? state.components : {}),
        }
      };
    case rulActionTypes.GET_RUL_SETTINGS_SUCCESS:
      return {
        ...state,
        machineId: action.machineId,
        components: {
          ...state.components,
          [action.componentId]: {
            ...state.components[action.componentId],
            settings: {
              ...action.settings
            }
          }
        }
      };
    case rulActionTypes.GET_RUL_SETTINGS_FAILURE:
      return {
        ...state,
        machineId: action.machineId,
        components: {
          ...state.components,
          [action.componentId]: {
            ...state.components[action.componentId],
            settings: {
              error: action.error
            }
          }
        }
      };
    default:
      return state;
  }
};
