import styled from 'styled-components';

import Container from '../../atoms/Container';

const ChartPopupContainer = styled(Container)`
  position: absolute;
  padding: 24px;
  min-height: ${props => props.height}px;
  width: ${props => props.width}px;
  display: flex;
  align-items: flex-start;
  ${props => `
    left: calc(${props.x}px + 70px);
    top: calc(${props.y}px - 100px);
  `}
  ${props => (props.alignLeft ? `
    left: calc(${props.x}px - ${props.width}px + 40px);
  ` : '')}
  box-shadow: 0 0 8px 0 rgba(60,61,58,0.24);
  z-index: 10;
  &:after {
    position: absolute;
    top: 90px;
    content: '';
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    ${props => (props.alignLeft ? `
      border-left: 10px solid white;
      right: -8px;
    ` : `
      border-right: 10px solid white;
      left: -8px;
    `)}
  }
`;

export default styled(ChartPopupContainer)``;
