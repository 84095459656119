import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import moment from 'moment';
import DonutChart from 'common/charts/GoogleCharts/DonutChart';
import BarChart from 'common/charts/GoogleCharts/BarChart';
import LineChart from 'common/charts/GoogleCharts/LineChart';
import LoadingSvg from 'common/images/LoadingSvg';
import BigNumber from 'common/charts/BigNumber';
import CountBar from 'common/charts/CountBar';
import Metrics from 'common/charts/Metrics';
import NoDataWidget from 'common/charts/NoDataWidget';
import { getHighestPrivilegeRole } from 'common/rbac/util';
import { bindActionCreators } from 'redux';
import { widgetActions } from './actions/widget.actions';

const Loader = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const getErrorMessage = (errorType) => {
  if (errorType === '404') {
    return 'No data available for selected filters';
  }
  return 'Error while fetching widget data';
};

function Widget({
  height,
  width,
  metadata,
  showBorder,
  widgetActions,
  gridColumn,
  filters,
  filterRoles,
  area_id,
  widgetInterval,
  user,
  widgetClassName,
  setWidgetLoading, // for parent component to keep track of loading
  setFilterOptions,
  selectedUserTeams,
  ...props
}) {
  const [dataLoading, setDataLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [widgetProps, setWidgetProps] = useState({
    data: null
  });
  const { id, chart_type: type } = metadata;
  const Component = metadata.component && metadata.component();

  useEffect(() => {
    const interval = setInterval(fetchChartData, metadata.refresh_rate * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchChartData();
  }, [JSON.stringify(filters), widgetInterval, selectedUserTeams]);

  const renderWidget = () => {
    switch (type) {
      case 'list':
        return <Component data={widgetProps.data} />;
      case 'donut':
        return <DonutChart {...metadata} {...widgetProps} />;
      case 'bar':
        return <BarChart {...metadata} {...widgetProps} />;
      case 'trend':
        return <LineChart {...metadata} {...widgetProps} />;
      case 'count':
        return <BigNumber {...metadata} {...widgetProps} />;
      case 'count/bar':
        return <CountBar {...metadata} {...widgetProps} />;
      case 'metrics':
        return <Metrics {...metadata} {...widgetProps} />;
      case 'table':
        return <Component data={widgetProps.data} />;
      default:
        return <></>;
    }
  };

  const fetchChartData = async () => {
    setDataLoading(true);
    setWidgetLoading(true);
    setErrorType(null);
    const params = {
      widget_id: id
    };
    if (!_.isEmpty(filters)) {
      if (
        _.isEmpty(filterRoles) ||
        (user &&
          user.roles &&
          filterRoles &&
          filterRoles.includes(getHighestPrivilegeRole(user.roles)))
      ) {
        params.filters = [...filters];
      }
    }
    if (area_id !== undefined) {
      if (!params.filters) params.filters = [];
      params.filters.push({ name: 'area_id', op: 'eq', value: area_id });
    }
    if (!_.isEmpty(selectedUserTeams)) {
      if (!params.filters) params.filters = [];
      params.filters.push({ name: 'team', op: 'in', value: selectedUserTeams });
    }
    if (metadata.widget_metadata.allow_interval_change && widgetInterval && widgetInterval !== 'all') {
      params.end_time = moment().toDate();
      params.start_time = moment().subtract(widgetInterval, 'days').startOf('day').toDate();
    }
    try {
      const [data, filterOptions] = await widgetActions.getWidgetData(params, metadata, type);
      if (!_.isEmpty(filterOptions) && metadata.filterName) {
        setFilterOptions(metadata.filterName, filterOptions);
      }
      setWidgetProps(prevProps => ({
        ...prevProps,
        data,
        handleClick: metadata.handleClick ?
          metadata.handleClick(metadata.name, filterOptions) :
          () => {}
      }));
      setDataLoading(false);
      setWidgetLoading(false);
    } catch (error) {
      if (typeof error !== 'string' && error.code === 404) {
        setErrorType('404');
      } else {
        setErrorType('Other');
      }
      setDataLoading(false);
      setWidgetLoading(false);
    }
  };

  if (!dataLoading && !errorType && type === 'table' && (!widgetProps.data || !Array.isArray(widgetProps.data.items) || widgetProps.data.items.length === 0)) {
    return null;
  }
  return (
    <div
      className={widgetClassName}
      style={{
        height,
        width,
        backgroundColor: 'white',
        borderRadius: '4px',
        overflow: 'hidden',
        border: showBorder ? `1px solid ${props.theme.colors.borderGray}` : '',
        gridColumn: gridColumn || ''
      }}
    >
      {dataLoading && (
        <Loader>
          <LoadingSvg />
        </Loader>
      )}
      {!dataLoading && errorType && <NoDataWidget title={metadata && metadata.title} message={getErrorMessage(errorType)} />}
      {!dataLoading && !errorType &&
        widgetProps.data && renderWidget()}
    </div>
  );
}

Widget.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  showBorder: PropTypes.bool,
  filters: PropTypes.object,
  filterRoles: PropTypes.array,
  widgetClassName: PropTypes.string,
  setWidgetLoading: PropTypes.func,
  setFilterOptions: PropTypes.func,
  selectedUserTeams: PropTypes.array
};

Widget.defaultProps = {
  height: '100%',
  width: '100%',
  showBorder: false,
  filters: {},
  filterRoles: [],
  widgetClassName: '',
  setWidgetLoading: () => {},
  setFilterOptions: () => {},
  selectedUserTeams: []
};

const mapDispatchToProps = dispatch => ({
  widgetActions: bindActionCreators(widgetActions, dispatch),
});

const mapStateToProps = state => ({
  user: state.user.user
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Widget));
