import axios from 'axios';
import { getBaseUrl } from 'home/DomainName/DomainConstants';
import theme from './styles/theme';

export const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' }
});
export const ENDPOINT = {
  LOGIN: 'login',
  SSO_LOGIN: 'saml/login',
  SIGNUP: 'signup',
  ASSOCIATE_TOKEN_DETAILS: 'associate-with-site',
  SIGNUP_TOKEN_DETAILS: 'signup-token-details',
  GENERATE_SIGNUP_TOKEN: 'generate-signup-token',
  GET_ALL_TIMEZONES: 'i/v1/timezones',
  CURRENT_USER: 'i/v1/current-user',
  DELETE_USER: user_id => `i/v1/users/${user_id}`,
  UPDATE_USER: user_id => `i/v1/users/${user_id}`,
  CREATE_USER: 'i/v1/users',
  SWITCH_ACCOUNT: 'i/v1/switch-account',
  LOGOUT: 'logout',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  VERIFY_USER_UPDATE_OTP: 'user-update-authorization',
  VERIFY_LOGIN_OTP: 'verify-login-otp',
  RESEND_LOGIN_OTP: 'send-login-otp',
  VERIFY_SIGNUP_OTP: 'verify-signup-otp',
  RESEND_SIGNUP_OTP: 'send-signup-otp',
  CHANGE_PASSWORD: 'change-password',
  UPDATE_ACCOUNT: 'i/v1/current-account',
  ACCOUNT_LOGO: 'i/v1/account-logo',
  SASS_AGREEMENT_ACTION: 'i/v1/accounts/online-agreement-action',
  GET_CURRENT_ACCOUNT: 'i/v1/current-account',
  GET_CURRENT_ACCOUNT_USERS: account_id => `i/v1/accounts/${account_id}/users`,
  UPDATE_PREFERENCES: 'i/v1/account-preferences',
  UPDATE_EVENT_PREFERENCES: account_id => `i/v1/events/account-prefs/${account_id}`,
  GET_TASK_STATUS: task_id => `i/v1/async-task-status/${task_id}`,
  GET_DASHBOARD_CHART_DATA: 'i/v1/analytics/plant',
  GET_DASHBOARD_ACTIVITY: 'events/service/account-events',
  GET_BEARING_LIST: 'i/v1/bearing-models',
  ADD_BEARING: 'i/v1/bearing-models',
  UPDATE_BEARING: bearingId => `i/v1/bearing-models/${bearingId}`,
  GET_ALL_MANUFACTURERS: 'i/v1/bearing-models/manufacturers',
  DELETE_BEARING: bearingId => `i/v1/bearing-models/${bearingId}`,
  ASSOCIATE_BEARING: componentId => `i/v1/components/${componentId}/set-bearing`,
  UNASSIGN_BEARING: componentId => `i/v1/components/${componentId}/unassign-bearing`,
  GET_MACHINES_BUILDER_METADATA: 'i/v2/machines/metadata',
  GET_MACHINE_LIST: 'i/v1/machines',
  GET_MACHINE_DETAILS: machineId => `i/v1/machines/${machineId}`,
  GET_MACHINE_SUMMARY: machineId => `i/v1/analytics/machines/${machineId}`,
  GET_MACHINE_ANALYTICS: 'i/v1/analytics/machines/metadata',
  GET_MACHINE_COMPLETION_STATUS: machineId => `i/v1/analytics/machines/${machineId}/completion-status`,
  GET_MACHINE_SENSORS: machineId => `i/v1/machines/${machineId}/sensors`,
  GET_MACHINE_COMPONENTS: machineId => `i/v1/machines/${machineId}/components`,
  GET_MACHINE_COMPONENTS_LOCATIONS: machineId => `i/v1/machines/${machineId}/locations`,
  GET_MACHINE_DATA_OVERVIEW: machineId => `i/v1/machines/${machineId}/data-overview`,
  CREATE_MACHINE_COMPONENTS_LOCATIONS: machineId => `i/v1/machines/${machineId}/locations`,
  DELETE_MACHINE_COMPONENTS_LOCATIONS: (machineId, locationId) => `i/v1/machines/${machineId}/locations/${locationId}`,
  UPDATE_MACHINE_COMPONENTS_LOCATIONS: (machineId, locationId) => `i/v1/machines/${machineId}/locations/${locationId}`,
  GET_MOTE: moteId => `i/v1/motes/${moteId}`,
  GET_TX: txId => `i/v1/tx/${txId}`,
  UPDATE_MACHINE_INFO: machineId => `i/v1/machines/${machineId}`,
  UPDATE_COMPONENT_INFO: componentId => `i/v1/components/${componentId}`,
  DELETE_COMPONENT: componentId => `i/v1/components/${componentId}`,
  UPDATE_COMPONENTS: machineId => `i/v2/machines/${machineId}/components`,
  REORDER_COMPONENTS: machineId => `i/v2/machines/${machineId}/reorder-components`,
  CREATE_COMPONENT: machineId => `i/v2/machines/${machineId}/components`,
  GET_COMPONENT_SVG: componentType => `i/v1/component-types/${componentType}/graphics`,
  GET_MACHINE_TAGS: machineId => `/i/v1/machines/${machineId}/tags`,
  GET_MACHINE_TAGS: machineId => `i/v1/machines/${machineId}/tags`,
  LOCATION: locationId => `i/v1/locations/${locationId}`,
  UPDATE_TAG: tagId => `/i/v1/tags/${tagId}`,
  UPDATE_TAG: tagId => `i/v1/tags/${tagId}`,
  UPDATE_TAGS: machineId => `i/v1/machines/${machineId}/tags`,
  GET_TAG_TYPES: '/i/v1/tag-types',
  GET_TPI_TAG_TYPES: '/i/v1/all-tag-types',
  GET_TAG_TYPES: 'i/v1/tag-types',
  GET_TPI_TAG_TYPES: 'i/v1/all-tag-types',
  UPDATE_THIRD_PARTY_TAG: tagId => `i/v1/third-party-tags/${tagId}`,
  GET_TAG_TREND: tagId => `/i/v1/tags/${tagId}/trend`,
  GET_TAG_WAVEFORM: tagId => `/i/v1/tags/${tagId}/waveform`,
  GET_TAG_SPECTRUM: tagId => `/i/v1/tags/${tagId}/spectrum`,
  GET_TAG_SPECTRUMS: tagId => `/i/v1/tags/${tagId}/spectrums`,
  GET_TAG_CEPSTRUM: tagId => `/i/v1/tags/${tagId}/cepstrum`,
  CREATE_ENVELOPE_THRESHOLDS: '/i/v1/tags/envelope-thresholds',
  GET_COMPUTED_ENVELOPE_THRESHOLDS: taskId => `/i/v1/tags/envelope-thresholds/${taskId}`,
  CREATE_THIRD_PARTY_INTEGRATIONS: '/i/v1/third-party-integrations',
  UPDATE_THIRD_PARTY_INTEGRATIONS: integrationId => `/i/v1/third-party-integrations/${integrationId}`,
  DELETE_THIRD_PARTY_INTEGRATIONS: integrationId => `/i/v1/third-party-integrations/${integrationId}`,
  GET_THIRD_PARTY_INTEGRATION_DETAILS: integrationId => `/i/v1/third-party-integrations/${integrationId}`,
  GET_THIRD_PARTY_INTEGRATION_LIST: '/i/v1/third-party-integrations',
  GET_TAG_TREND: tagId => `i/v1/tags/${tagId}/trend`,
  GET_TAG_WAVEFORM: tagId => `i/v1/tags/${tagId}/waveform`,
  GET_TAG_SPECTRUM: tagId => `i/v1/tags/${tagId}/spectrum`,
  GET_TAG_SPECTRUMS: tagId => `i/v1/tags/${tagId}/spectrums`,
  GET_TAG_CEPSTRUM: tagId => `i/v1/tags/${tagId}/cepstrum`,
  CREATE_ENVELOPE_THRESHOLDS: 'i/v1/tags/envelope-thresholds',
  GET_COMPUTED_ENVELOPE_THRESHOLDS: taskId => `i/v1/tags/envelope-thresholds/${taskId}`,
  CREATE_THIRD_PARTY_INTEGRATIONS: 'i/v1/third-party-integrations',
  UPDATE_THIRD_PARTY_INTEGRATIONS: integrationId => `i/v1/third-party-integrations/${integrationId}`,
  DELETE_THIRD_PARTY_INTEGRATIONS: integrationId => `i/v1/third-party-integrations/${integrationId}`,
  GET_THIRD_PARTY_INTEGRATION_DETAILS: integrationId => `i/v1/third-party-integrations/${integrationId}`,
  GET_THIRD_PARTY_INTEGRATION_LIST: 'i/v1/third-party-integrations',
  UPDATE_ATTENTION_MACHINE_READ: machineId => `i/v1/machines/${machineId}/read`,
  ADD_NOTE: 'i/v1/machine-notes',
  NOTE: noteId => `i/v1/machine-notes/${noteId}`,
  ADD_TASK: 'i/v1/tasks',
  TASK: taskId => `i/v1/tasks/${taskId}`,
  ADD_REPAIR: 'i/v1/repairs',
  REPAIR: repairID => `i/v1/repairs/${repairID}`,
  CREATE_TEMPLATE: '/i/v1/task-templates',
  TEMPLATE_LIST: '/i/v1/task-templates',
  CREATE_TEMPLATE: 'i/v1/task-templates',
  TEMPLATE_LIST: 'i/v1/task-templates',
  DELETE_TASK_TEMPLATE: templateID => `i/v1/task-templates/${templateID}`,
  UPDATE_TASK_TEMPLATE: templateID => `i/v1/task-templates/${templateID}`,
  DASHBOARDS: 'i/v1/dashboards',
  ACCOUNT_DASHBOARDS: 'i/v1/account-dashboards',
  DELETE_DASHBOARD: dashboardId => `i/v1/dashboards/${dashboardId}`,
  DASHBOARD_SETTINGS: 'i/v1/dashboard-settings',
  WIDGETS_METADATA: 'i/v1/widgets/metadata',
  WIDGET_DATA: 'i/v1/widget-data',
  MACHINES: machineId => machineId ? `i/v2/machines/${machineId}` : 'i/v2/machines',
  GET_VIBRATION_MOTES: machineId => `webapp/machines/${machineId}/motes-info`,
  GET_VIBRATION_TXES: machineId => `webapp/machines/${machineId}/tx`,
  GET_MOTE_LIST: 'i/v1/motes',
  GET_TX_LIST: 'i/v1/tx',
  CREATE_MOTE: 'i/v1/motes',
  CREATE_TX: 'i/v1/tx',
  GET_FORCING_FREQUENCIES: tagId => `i/v1/tags/${tagId}/forcing-frequencies`,
  GET_MACHINE_PICTURES: machineId => `i/v1/machines/${machineId}/pictures`,
  DELETE_MACHINE_PICTURE: (machineId, pictureId) => `i/v1/machines/${machineId}/pictures/${pictureId}`,
  UPLOAD_MACHINE_PICTURE: machineId => `i/v1/machines/${machineId}/pictures`,
  UPDATE_MACHINE_PICTURE_LABEL: (machineId, pictureId) => `i/v1/machines/${machineId}/pictures/${pictureId}`,
  UPDATE_TAG_SPECTRUM_FEATURES: featureId => `/i/v1/tag-spectrum-features/${featureId}`,
  GET_TAG_SPECTRUM_FEATURES: tagId => `/i/v1/tag-spectrum-features/${tagId}`,
  CREATE_TAG_SPECTRUM_FEATURES: '/i/v1/tag-spectrum-features',
  GET_TAG_SPECTRUM_FEATURE_TREND: featureId => `/i/v1/tag-spectrum-features/${featureId}/trend`,
  GET_TAG_SPECTRUM_FEATURE_TREND_TASK: taskId => `/i/v1/tag-spectrum-features/trend-task/${taskId}`,
  GET_CUSTOM_FEATURE_TREND: tagId => `/i/v1/tags/${tagId}/forcing-frequency-trend`,
  UPDATE_TAG_SPECTRUM_FEATURES: featureId => `i/v1/tag-spectrum-features/${featureId}`,
  GET_TAG_SPECTRUM_FEATURES: tagId => `i/v1/tag-spectrum-features/${tagId}`,
  CREATE_TAG_SPECTRUM_FEATURES: 'i/v1/tag-spectrum-features',
  GET_TAG_SPECTRUM_FEATURE_TREND: featureId => `i/v1/tag-spectrum-features/${featureId}/trend`,
  GET_TAG_SPECTRUM_FEATURE_TREND_TASK: taskId => `i/v1/tag-spectrum-features/trend-task/${taskId}`,
  GET_CUSTOM_FEATURE_TREND: tagId => `i/v1/tags/${tagId}/forcing-frequency-trend`,
  UPLOAD_ATTACHMENT: (origin_type, origin_id) => `i/v1/attachments/${origin_type}/${origin_id}`,
  DOWNLOAD_ATTACHMENT: (attachment_id, thumbnail) => `i/v1/attachments/${attachment_id}?thumbnail=${thumbnail}`,
  DELETE_ATTACHMENT: attachment_id => `i/v1/attachments/${attachment_id}`,
  GET_MACHINE_ATTACHMENTS: attachment_id => `i/v1/machines/${attachment_id}/attachments`,
  SENSOR_MODELS: model_id => model_id ? `i/v2/sensor-models/${model_id}` : 'i/v2/sensor-models',
  GET_SENSOR_MODEL_METADATA: 'i/v2/sensor-models/metadata',
  GET_SENSOR_INSTANCES: model_id => `i/v2/sensor-models/${model_id}/instances`,
  GET_TX_MODELS: '/i/v1/tx-models',
  GET_MOTE_MODELS: '/i/v1/mote-models',
  GET_TX_MODELS: 'i/v1/tx-models',
  GET_MOTE_MODELS: 'i/v1/mote-models',
  GET_DEFINITIONS: 'i/v1/event-templates',
  CREATE_DEFINITION: 'i/v1/event-templates',
  GET_EVENT_DEFINITION: template_id => `i/v1/event-templates/${template_id}`,
  DELETE_EVENT_DEFINITION: template_id => `i/v1/event-templates/${template_id}`,
  UPDATE_DEFINITION_TRIGGER: template_id => `i/v1/event-templates/${template_id}/trigger`,
  UPDATE_ACTION: template_id => `i/v1/event-templates/${template_id}`,
  UPDATE_SUBSCRIPTION: template_id => `i/v1/event-templates/${template_id}/subscriptions`,
  ADD_DEFINTION_RESOURCE: template_id => `i/v1/event-templates/${template_id}/resources`,
  SYNC_DEFINTION_RESOURCE: template_id => `i/v1/event-templates/${template_id}/sync-resources`,
  DELETE_DEFINTION_RESOURCE: template_id => `i/v1/event-templates/${template_id}/resources`,
  GET_FILTER_RESOURCES: '/i/v1/filter-resources',
  GET_FILTER_RESOURCES: 'i/v1/filter-resources',
  GET_EVENT_DEFINITION_METADATA: 'i/v1/event-templates-metadata',
  GET_ACTIVITY: 'i/v1/activities',
  UPDATE_ACTIVITY: 'i/v1/activities',
  UPDATE_ACTIVITY_READ: 'i/v1/activities-read',
  GET_EVENT_DETAILS: eventId => `/i/v1/events/${eventId}`,
  POST_EVENT_COMMENT: eventId => `/i/v1/events/${eventId}/comments`,
  MARK_EVENTS_AS_ANALYZED: '/i/v1/events/mark-analyzed',
  EVENT_COMMENT: (eventId, commentId) => `/i/v1/events/${eventId}/comments/${commentId}`,
  CLOSE_EVENT: eventId => `/i/v1/events/${eventId}`,
  GET_EVENT_DETAILS: eventId => `i/v1/events/${eventId}`,
  POST_EVENT_COMMENT: eventId => `i/v1/events/${eventId}/comments`,
  EVENT_COMMENT: (eventId, commentId) => `i/v1/events/${eventId}/comments/${commentId}`,
  CLOSE_EVENT: eventId => `i/v1/events/${eventId}`,
  GET_NOTE_DETAILS: noteId => `i/v1/machine-notes/${noteId}`,
  GET_ACTIVITY_COMMENTS: (activity_type, activity_id) => `i/v1/activities/${activity_type}/${activity_id}/comments`,
  POST_COMMENT: (activity_type, activity_id) => `i/v1/activities/${activity_type}/${activity_id}/comments`,
  UPDATE_COMMENT: (activity_type, activity_id, commentId) => `i/v1/activities/${activity_type}/${activity_id}/comments/${commentId}`,
  DELETE_COMMENT: (activity_type, activity_id, commentId) => `i/v1/activities/${activity_type}/${activity_id}/comments/${commentId}`,
  NOTE_COMMENT: (noteId, commentId) => `i/v1/machine-notes/${noteId}/comments/${commentId}`,
  MARK_AS_ANALYSED: machineId => `/i/v1/machines/${machineId}/mark-analyzed`,
  // Instrumentation Alerts tab
  GET_INSTRUMENTATION_ALERTS: 'i/v1/instrumentation-alerts',
  UPDATE_INSTRUMENTATION_ALERTS: 'i/v1/instrumentation-alerts-status',
  // Overview tab
  GET_MACHINE_CONFIG: machineId => `/i/v2/machines/${machineId}/graphics-config`,
  GET_MACHINE_CONFIG: machineId => `i/v2/machines/${machineId}/graphics-config`,
  // Feed tab
  GET_FEEDS: 'i/v1/audit/feeds',
  // Analyst Tab
  SUBMIT_ANALYST_ASSESSMENT: machine_id => `/i/v1/machines/${machine_id}`,
  SUBMIT_ANALYST_ASSESSMENT: machine_id => `i/v1/machines/${machine_id}`,
  // Datascience related
  GET_MACHINE_TRAIN_HEALTH_CONDITION: machine_id =>
    `i/v1/machine-train-health-condition/${machine_id}`,
  GET_MACHINE_TRAIN_HEALTH_TREND: machineId => `i/v1/ahs/${machineId}/detailed`,
  MACHINE_HEALTH_SCORE_RECOMPUTE: machineId => `i/v1/ahs/${machineId}/recompute`,
  MACHINE_HEALTH_SCORE_RECOMPUTE_TASK_STATUS: taskId => `i/v1/ahs/recompute/${taskId}`,
  GET_MACHINE_HEALTH_TREND: machineId => `i/v1/ahs/${machineId}/trend`,
  GET_MACHINE_VIBRATION_TREND: machineId =>
    `i/v1/vibration-baseline/machines/${machineId}/vibration-trend`,
  GET_COMPONENT_HEALTH_TREND: componentId => `i/v1/component-health-score/${componentId}/trend`,
  GET_COMPONENT_HEALTH_SCORE_DETAILS: componentId => `i/v1/component-health-score/${componentId}`,
  LOCATION_BASELINE: locationId => `i/v1/vibration-baseline/${locationId}`,
  ERASE_AHS_SCORES: machineId => `/i/v1/ahs/${machineId}`,
  ERASE_AHS_SCORES: machineId => `i/v1/ahs/${machineId}`,
  GET_MACHINE_TREND: machineId => `i/v1/baseline/machines/${machineId}/trend`,
  GET_COMPONENT_TREND: componentId => `i/v1/baseline/components/${componentId}/trend`,
  COMPONENT_BASELINE: componentId => `i/v1/baseline/${componentId}`,
  VALIDATE_COMPONENT_BASELINE: componentId => `i/v1/baseline/${componentId}/validate`,
  GET_MACHINE_BHS_TREND: 'i/v1/bhs/trend',
  CREATE_BHS_MODEL: 'i/v1/bhs/models',
  GET_BHS_MODELS: 'i/v1/bhs/models',
  UPDATE_BHS_MODEL: 'i/v1/bhs/models',
  RESET_BHS_MODEL: 'i/v1/bhs/models/reset',
  GET_BHS_SCORE_DETAILS: 'i/v1/bhs/score-details',
  GET_BHS_LATEST_SCORE: '/i/v1/bhs/latest-score',
  GET_BHS_LATEST_SCORE: 'i/v1/bhs/latest-score',
  BHS_RECOMPUTE: 'i/v1/bhs/recompute',
  BHS_RECOMPUTE_TASK_STATUS: taskId => `i/v1/bhs/recompute/${taskId}`,
  // Charts
  CHARTS_CONFIG_LIST: '/i/v1/charts-config',
  CHARTS_CONFIG: configId => `/i/v1/charts-config/${configId}`,
  CHARTS_CONFIG_METADATA: '/i/v1/charts-config/metadata',
  GET_CHARTS_DATA: '/i/v1/charts-data',
  REORDER_CHARTS: '/i/v1/charts-config/sort',
  CREATE_COMPARE_TAGS_CHARTS: 'i/v1/asset-charts-data-request',
  GET_COMPARE_TAGS_CHARTS_RESULTS: taskId => `i/v1/asset-charts-data/${taskId}`,
  POST_OUTLIER_READING: (tag_id, timestamp) => `/i/v1/tag-outlier-readings/${tag_id}/${timestamp}`,
  DELETE_OUTLIER_READING: (tag_id, timestamp) => `/i/v1/tag-outlier-readings/${tag_id}/${timestamp}`,
  POST_DERIVED_TAG: '/i/v1/derived-tags',
  CHARTS_CONFIG_LIST: 'i/v1/charts-config',
  CHARTS_CONFIG: configId => `i/v1/charts-config/${configId}`,
  CHARTS_CONFIG_METADATA: 'i/v1/charts-config/metadata',
  GET_CHARTS_DATA: 'i/v1/charts-data',
  REORDER_CHARTS: 'i/v1/charts-config/sort',
  POST_OUTLIER_READING: (tag_id, timestamp) => `i/v1/tag-outlier-readings/${tag_id}/${timestamp}`,
  DELETE_OUTLIER_READING: (tag_id, timestamp) => `i/v1/tag-outlier-readings/${tag_id}/${timestamp}`,
  POST_DERIVED_TAG: 'i/v1/derived-tags',
  // Sensors
  GET_SENSORS_LIST: 'i/v2/sensors',
  SENSORS: 'i/v2/sensors',
  UPDATE_SENSOR_INFO: 'i/v2/sensors',
  UPDATE_SENSOR: serialNumber => `i/v2/sensors/${serialNumber}`,
  GET_SENSOR_DETAILS: serialNumber => `i/v2/sensors/${serialNumber}`,
  GET_SENSORS_METADATA: 'i/v2/sensors/metadata',
  GET_DEVICE_LOGS_DATA: mote_id => `i/v1/motes/${mote_id}/logs/features`,
  DOWNLOAD_DEVICES_LOGS: mote_id => `/i/v1/motes/${mote_id}/download-logs?output=csv`,
  DOWNLOAD_DEVICES_LOGS: mote_id => `i/v1/motes/${mote_id}/download-logs?output=csv`,
  GET_DEVICE_LAST_REPORTED_INFO: serial_number => `i/v2/sensors/${serial_number}/last-reported-info`,
  DEVICE_CONFIG: serial_number => `i/v2/sensors/${serial_number}/config`,
  DEVICE_CONFIG_HISTORY: serial_number => `i/v2/sensors/${serial_number}/config-history`,
  // Erroneous Readings
  GET_SUMMARIZED_ERRONEOUS_READINGS: 'i/v1/summarized-vibration-erroneous-readings',
  GET_ERRONEOUS_READINGS_WAVEFORM: tag_id => `i/v1/tags/${tag_id}/waveform`,
  GET_ERRONEOUS_READINGS_SPECTRUM: tag_id => `i/v1/tags/${tag_id}/spectrum`,
  ERRONEOUS_READINGS: id => id ? `i/v1/vibration-erroneous-readings/${id}` : 'i/v1/vibration-erroneous-readings',
  // BHS
  GET_TAGS_SPECTRUM_DATA: tag_id => `/i/v1/tags-data/${tag_id}/spectrum`,
  GET_ANALYST_SUMMARY: machine_id => `/i/v1/machines/${machine_id}/analyst-summaries/latest`,
  POST_ANALYST_SUMMARY: machine_id => `/i/v1/machines/${machine_id}/analyst-summaries`,
  PUT_ANALYST_SUMMARY: (machine_id, summary_id) => `/i/v1/machines/${machine_id}/analyst-summaries/${summary_id}`,
  GET_TAGS_SPECTRUM_DATA: tag_id => `i/v1/tags-data/${tag_id}/spectrum`,
  GET_ANALYST_SUMMARY: machine_id => `i/v1/machines/${machine_id}/analyst-summaries/latest`,
  POST_ANALYST_SUMMARY: machine_id => `i/v1/machines/${machine_id}/analyst-summaries`,
  PUT_ANALYST_SUMMARY: (machine_id, summary_id) => `i/v1/machines/${machine_id}/analyst-summaries/${summary_id}`,
  // Asset Reports
  GET_ASSET_REPORTS: 'i/v1/asset-reports',
  UPLOAD_ASSET_REPORT: 'i/v1/asset-reports',
  DOWNLOAD_ASSET_REPORT: report_id => `i/v1/asset-reports/${report_id}`,
  // Asset Templates
  GET_ASSET_TEMPLATES: 'i/v1/asset-templates',
  CREATE_ASSET_TEMPLATE: 'i/v1/asset-templates',
  GET_ASSET_TEMPLATE: template_id => `i/v1/asset-templates/${template_id}`,
  UPDATE_ASSET_TEMPLATE: template_id => `i/v1/asset-templates/${template_id}`,
  DELETE_ASSET_TEMPLATE: template_id => `i/v1/asset-templates/${template_id}`,
  CREATE_ASSETS_FROM_TEMPLATES: 'i/v1/asset-templates/assets',
  UNASSIGNED_SENSORS: 'i/v1/tx-sensors',
  ASSIGN_SENSOR: portId => `i/v1/tx-port/${portId}/assign-sensor`,
  UNASSIGN_SENSOR: portId => `i/v1/tx-port/${portId}/unassign-sensor`,
  ASSIGN_SENSORS_TO_TX: 'i/v2/tx/assign-sensor',
  // RBAC
  GET_RBAC_PERMISSIONS: 'i/v1/rbac-rules',
  // hierarchy
  GET_ACCOUNT_HIERARCHY: 'i/v1/asset-hierarchies',
  UPDATE_AREAS: 'i/v1/areas',
  UPDATE_MACHINES: 'i/v1/machines',
  // machine diagnostics
  RULES_METADATA: 'i/v1/diagnostic-rules/metadata',
  RULES_RESULT: resultId => resultId ? `i/v1/diagnostic-results/${resultId}` : 'i/v1/diagnostic-results',
  RULES: ruleId => ruleId ? `i/v1/diagnostic-rules/${ruleId}` : 'i/v1/diagnostic-rules',
  FAILURE_MODE: failureModeId => failureModeId ? `i/v1/failure-mode/${failureModeId}` : 'i/v1/failure-modes',
  PARAMETERS_TREND: resultId => `i/v1/diagnostic-results/${resultId}/parameters-trend`,
  RULE_TEST_RUN: taskId => taskId ? `i/v1/diagnostic-rules/test-run/${taskId}` : 'i/v1/diagnostic-rules/test-run',
  // AHS feedback
  AHS_FEEDBACK: feedbackId => `i/v1/ahs-feedback/${feedbackId}`,
  DELETE_AREA: area_id => `i/v1/areas/${area_id}`,
  UPDATE_AREA: area_id => `i/v1/areas/${area_id}`,
  CREATE_AREA: 'i/v1/areas',
  // Devices Health
  GET_DEVICES_HEALTH_DATA: 'i/v1/analytics/devices',
  // Tags List
  GET_TAGS_LIST: 'i/v1/tags',
  TAGS: 'i/v1/tags',
  // Automatic alarm settings
  MACHINES_ALARM_SETTINGS: 'i/v1/machines/alarm-settings',
  // Tag template
  TAG_TEMPLATE: tagTemplateId => tagTemplateId ? `i/v1/tag-template/${tagTemplateId}` : 'i/v1/tag-template',
  TAG_TEMPLATE_METADATA: 'i/v1/tag-template/metadata',
  // AdminDashboard
  ACCOUNT_ANALYTICS: id => `i/v1/analytics/accounts/${id}`,
  ACCOUNT_ANALYTICS_SUMMARY: 'i/v1/analytics/accounts/summary',
  EVENT_ANALYTICS: 'i/v1/analytics/events',
  GET_MACHINE_RESOURCES: 'i/v1/machine-trains',
  GET_PAST_WEEK_ANALYTICS: 'i/v1/analytics/events/weekly',
  MEASUREMENT_DEVICE_MONITOR: 'i/v1/analytics/devices',
  MEASUREMENT_DEVICE_MONITOR_SUMMARY: `i/v1/analytics/devices`,
  // FirmwareDeployments
  GET_FIRMWARE_DEPLOYMENTS: 'i/v1/firmware-deployments',
  CREATE_FIRMWARE_DEPLOYMENT: 'i/v1/firmware-deployments',
  VERIFY_DEVICE_DEPLOYMENTS: 'i/v1/verify-device-deployments',
  GET_FIRMWARE_DEPLOYMENT_DETAILS: deployment_id => `i/v1/firmware-deployments/${deployment_id}`,
  GET_FIRMWARE_DEPLOYMENT_DEVICES: deployment_id => `i/v1/firmware-deployments/${deployment_id}/devices`,
  UPDATE_FIRMWARE_DEPLOYMENT: deployment_id => `i/v1/firmware-deployments/${deployment_id}`,
  DELETE_FIRMWARE_DEPLOYMENT: deployment_id => `i/v1/firmware-deployments/${deployment_id}`,
  RETRY_FIRMWARE_DEPLOYMENT: deployment_id => `i/v1/firmware-deployments/${deployment_id}/retry`,
  // Firmwares
  GET_FIRMWARES: 'i/v1/firmwares',
  // Sensors OPS-APP
  GET_ALL_OPS_TX: (all = 1, pagination = 0) => `i/v1/ops/tx?all=${all}&pagination=${pagination}`, // Always fixed not need to changes or add dynamic params
  GET_ALL_OPS_MOTES: (all = 1, pagination = 0) => `i/v1/ops/motes?all=${all}&pagination=${pagination}`, // Always fixed not need to changes or add dynamic params
  // Accounts
  GET_ACCOUNTSLIST: '/i/v1/accounts',
  ADD_ACCOUNT: '/i/v1/accounts',
  GET_ACCOUNTSLIST: 'i/v1/accounts',
  ADD_ACCOUNT: 'i/v1/accounts',
  // API Audit
  GET_API_AUDIT_METADATA: 'i/v1/api-audit/metadata',
  GET_API_AUDIT_LOGS: 'i/v1/api-audit/logs',
  // RUL
  GET_RUL_TREND: component_id => `i/v1/components/${component_id}/rul-trend`,
  UPDATE_RUL_SETTINGS: component_id => `i/v1/components/${component_id}/rul-settings`,
  GET_RUL_SETTINGS: component_id => `i/v1/components/${component_id}/rul-settings`,
  // Teams
  GET_TEAMS_LIST: 'i/v1/teams',
  UPDATE_TEAM: team_id => `i/v1/teams/${team_id}`,
  ADD_USER_TO_TEAM: team_id => `i/v1/teams/${team_id}/users`,
  DELETE_USER_FROM_TEAM: team_id => `i/v1/teams/${team_id}/users`,
  ADD_AREA_TO_TEAM: team_id => `i/v1/teams/${team_id}/areas`,
  DELETE_AREA_FROM_TEAM: team_id => `i/v1/teams/${team_id}/areas`,
  CREATE_TEAM: 'i/v1/teams',
  DELETE_TEAM: team_id => `i/v1/teams/${team_id}`,
  // Feature Selection
  GET_AHS_SELECTED_FEATURES_INFO: machine_id => `i/v1/ahs/${machine_id}/features`,
  UPDATE_AHS_SELECTED_FEATURES_INFO: machine_id => `i/v1/ahs/${machine_id}/features`,

  GET_ACTIVITY_UNREAD_COUNT: '/i/v1/activities/unread-count'
};
export const getFullEndPointUrl = (endPointUrl) => {
  return `${getBaseUrl()}/${endPointUrl}`;
};
export const getImpUrl = (device_id) => {
  return `https://impcentral.electricimp.com/ide/devices/${device_id}`;
};
export const getDeviceGCPLogsUrl = (device_id) => {
  return `https://console.cloud.google.com/logs/query?query=resource.type%3D%22generic_node%22%0Aresource.labels.node_id%3D%22${device_id}%22%0A-jsonPayload.module%3D%22ImpAgent%22&project=petasense`;
}
export const markersToSkip = new Set(['2xLF']);
export const TYPES = {
  GET_RESOURCES_BASED_ON_TYPE: 'GET_RESOURCES_BASED_ON_TYPE',
  GET_RESOURCES_BASED_ON_TYPE_REQUEST: 'GET_RESOURCES_BASED_ON_TYPE_REQUEST',
  GET_RESOURCES_BASED_ON_TYPE_SUCCESS: 'GET_RESOURCES_BASED_ON_TYPE_SUCCESS',
  GET_RESOURCES_BASED_ON_TYPE_FAILURE: 'GET_RESOURCES_BASED_ON_TYPE_FAILURE'
};
// js Date().getDay() returned values.
export const mapDayToString = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  0: 'Sunday'
};
export const OVERALL_ALARMS_LIST = [
  'pressure_critical',
  'pressure_warning',
  'temperature_critical',
  'temperature_warning',
  'vibration_critical',
  'vibration_warning'
];
export const SEVERITY_CSS_CLASS_MAP = {
  good: 'ps-good-health',
  slight: 'ps-slight-health',
  moderate: 'ps-moderate-health',
  warning: 'ps-warning-health',
  critical: 'ps-critical-health',
  null: 'ps-unknown-health',
  undefined: 'ps-unknown-health'
};
export const MACHINE_CONDITION = [
  { text: 'Good', value: 'good', color: theme.colors.normal, type: 'bullet' },
  { text: 'Slight', value: 'slight', color: theme.colors.slight, type: 'bullet' },
  { text: 'Moderate', value: 'moderate', color: theme.colors.moderate, type: 'bullet' },
  { text: 'Warning', value: 'warning', color: theme.colors.warning, type: 'bullet' },
  { text: 'Critical', value: 'critical', color: theme.colors.critical, type: 'bullet' }
];
export const getMachineConditionOptions = (assetConditionLevels) => {
  const options = MACHINE_CONDITION.slice();
  if (assetConditionLevels === 3) options.splice(1, 2);
  return options;
};
export const KEYBOARD_CODES = {
  ESC: 27,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
  ENTER: 13,
  H: 72,
  S: 83
};
export const EVENT_TYPES = {
  MOTE_UNREACHABLE: 'mote_unreachable',
  TX_UNREACHABLE: 'tx_unreachable'
};
export const CONDITION_MAP = {
  good: 'GOOD',
  slight: 'SLIGHT',
  moderate: 'MODERATE',
  warning: 'WARNING',
  critical: 'CRITICAL'
};
export const getMachineConditionColors = (assetConditionLevels) => {
  const options = getMachineConditionOptions(assetConditionLevels);
  const colorsArray = options.map(c => c.color).concat([theme.colors.unknown]);
  return colorsArray;
};
export const REVERSE_CONDITION_MAP = {
  Good: 'good',
  Slight: 'slight',
  Moderate: 'moderate',
  Warning: 'warning',
  Critical: 'critical',
  Unknown: null
};
export const getMachineConditionLabels = (assetConditionLevels) => {
  const options = getMachineConditionOptions(assetConditionLevels);
  const labelsArray = options.map(c => c.text).concat(['Unknown']);
  return labelsArray;
};
export const METRIC_UNIT_SYSTEM = 1;
export const IMPERIAL_UNIT_SYSTEM = 2;
export const SCROLL_REF_THRESHOLD = 50;
export const unitSystemOptions = [
  { text: 'Imperial', value: IMPERIAL_UNIT_SYSTEM },
  { text: 'Metric', value: METRIC_UNIT_SYSTEM }
];
export const unitSystemMap = {
  1: { name: 'Metric', units: 'mm/s' },
  2: { name: 'Imperial', units: 'in/s' }
};
export const temperatureUnitSystemMap = {
  1: { name: 'Metric', units: '°C' },
  2: { name: 'Imperial', units: '°F' }
};
export const CRITICALITY_MAP = {
  1: 'Very High',
  2: 'High',
  3: 'Medium',
  4: 'Low',
  5: 'Very Low'
};
export const REVERSE_CRITICALITY_MAP = {
  'Very High': 1,
  High: 2,
  Medium: 3,
  Low: 4,
  'Very Low': 5
};
export const MACHINE_STATE = {
  0: 'OFF',
  1: 'ON',
  2: 'NA'
};
export const REVERSE_MACHINE_STATE_MAP = {
  OFF: 0,
  ON: 1,
  NA: 2
};
export const chartColors = [
  '#91B846',
  '#000000',
  '#37B6B8',
  '#EF5ECD',
  '#FF5C5C',
  '#FF9764',
  '#FFE25F',
  '#845317',
  '#05FBA1',
  '#FB056A',
  '#A6FF36',
  '#4E89F4'
];
export const bearingFrequenciesDescription = [
  'Ball Pass Frequency, Inner Race',
  'Ball Pass Frequency, Outer Race',
  'Fundamental Train Frequency',
  'Ball Spin Frequency'
];
export const ColorAccToCondition = (condition, type = 'circle') => {
  switch (condition) {
    case 'good':
      return theme.colors.normal;
    case 'slight':
      return theme.colors.slight;
    case 'moderate':
      return theme.colors.moderate;
    case 'warning':
      return theme.colors.warning;
    case 'critical':
      return theme.colors.critical;
    default:
      return type === 'text' ? theme.colors.black : theme.colors.grey;
  }
};
export const DEFECT_OPTIONS = {
  1: { id: 1, name: 'Unbalance', type: 'Shaft' },
  2: { id: 2, name: 'Angular Misalignment', type: 'Shaft' },
  3: { id: 3, name: 'Parallel Misalignment', type: 'Shaft' },
  4: { id: 4, name: 'Bent Shaft', type: 'Shaft' },
  5: { id: 5, name: 'Rotating Looseness', type: 'Looseness' },
  6: { id: 6, name: 'Structural Looseness', type: 'Looseness' },
  7: { id: 7, name: 'Resonance', type: 'other' },
  8: { id: 8, name: 'Flow Turbulence', type: 'Flow' },
  9: { id: 9, name: 'Cavitation', type: 'Flow' },
  10: { id: 10, name: 'Blade/Vane Defect', type: 'Flow' },
  11: { id: 11, name: 'Stage1 Defect', type: 'Bearings' },
  12: { id: 12, name: 'Stage2 Defect', type: 'Bearings' },
  13: { id: 13, name: 'Stage3 Defect', type: 'Bearings' },
  14: { id: 14, name: 'Stage4 Defect', type: 'Bearings' },
  15: { id: 15, name: 'Tooth Wear', type: 'Gears' },
  16: { id: 16, name: 'Tooth Load', type: 'Gears' },
  17: { id: 17, name: 'Eccentricity', type: 'Gears' },
  18: { id: 18, name: 'Misalignment', type: 'Gears' },
  19: { id: 19, name: 'Broken Tooth', type: 'Gears' },
  20: { id: 20, name: 'Hunting Tooth', type: 'Gears' },
  21: { id: 21, name: 'Worn/Loose', type: 'Belt' },
  22: { id: 22, name: 'Sheave Misalignment', type: 'Belt' },
  23: { id: 23, name: 'Resonance', type: 'Belt' }
};
export const DEFECT_OPTIONS_ARRAY = [
  { id: 1, name: 'Unbalance', header: 'Shaft' },
  { id: 2, name: 'Angular Misalignment', header: 'Shaft' },
  { id: 3, name: 'Parallel Misalignment', header: 'Shaft' },
  { id: 4, name: 'Bent Shaft', header: 'Shaft' },
  { id: 5, name: 'Rotating Looseness', header: 'Looseness' },
  { id: 6, name: 'Structural Looseness', header: 'Looseness' },
  { id: 7, name: 'Resonance', header: 'other' },
  { id: 8, name: 'Flow Turbulence', header: 'Flow' },
  { id: 9, name: 'Cavitation', header: 'Flow' },
  { id: 10, name: 'Blade/Vane Defect', header: 'Flow' },
  { id: 11, name: 'Stage1 Defect', header: 'Bearings' },
  { id: 12, name: 'Stage2 Defect', header: 'Bearings' },
  { id: 13, name: 'Stage3 Defect', header: 'Bearings' },
  { id: 14, name: 'Stage4 Defect', header: 'Bearings' },
  { id: 15, name: 'Tooth Wear', header: 'Gears' },
  { id: 16, name: 'Tooth Load', header: 'Gears' },
  { id: 17, name: 'Eccentricity', header: 'Gears' },
  { id: 18, name: 'Misalignment', header: 'Gears' },
  { id: 19, name: 'Broken Tooth', header: 'Gears' },
  { id: 20, name: 'Hunting Tooth', header: 'Gears' },
  { id: 21, name: 'Worn/Loose', header: 'Belt' },
  { id: 22, name: 'Sheave Misalignment', header: 'Belt' },
  { id: 23, name: 'Resonance', header: 'Belt' }
];