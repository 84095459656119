import { userConstants } from '../user.constants';

export function accountSelectionState(state = {}, action) {
  switch (action.type) {
    case userConstants.SELECT_ACCOUNT_REQUEST:
      return {
        loadingId: action.id,
      };
    case userConstants.SELECT_ACCOUNT_SUCCESS:
      return {
        result: true,
      };
    case userConstants.SELECT_ACCOUNT_FAILURE:
      return {
        result: false,
      };
    default:
      return state;
  }
}
