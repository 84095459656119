import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';

import Selection from 'common/components/Chart/components/Selection';
import ChartItemContainer from 'common/components/Chart/components/ChartItemContainer';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Loading from 'common/components/atoms/Loading';
import * as utils from 'common/utils';
import { bearingFrequenciesDescription } from 'common/constants';
import { ASSET_TYPE, mapNames } from 'common/charts/constants';
import UtilizationChart from '../../../Machines/MachineDetails/MachineCharts/components/UtilizationChart';
import TagChart from '../../../Machines/MachineDetails/MachineCharts/components/TagChart';
import WaterfallChart from '../../../Machines/MachineDetails/MachineCharts/components/molecules/WaterfallChart';
import NoDataChart from '../../../Machines/MachineDetails/MachineCharts/components/NoDataChart';
import ChartContainer from '../atoms/ChartContainer';
import assetHierarchyConstants from '../../constants/assetHierarchy.constants';
import { transformFromEnvelopeKeys } from '../../utils/envelopeUtils';
import { SPECTRAL_ENVELOPE_KEYS } from '../../constants/envelope.constants';


const InfoText = styled.span`
  display: block;
  font-size: 12px;
  color: #999B95;
  padding-bottom: 0.4em;
  b {
    font-weight: 600;
    color: black;
  }
  margin-right: 2em;
`;

const TagInfoContainer = styled(FlexContainer)`
  margin-left: 25px;
  min-height: 24px;
  flex-wrap: wrap;
`;

const GreenSpan = styled.span`
  color: #91b846;
  font-family: 'Petasense Open Sans';
  &:hover {
    cursor: pointer;
  }
`;

class ChartItem extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.assetType = this.getAssetType();
    this.state = {
      spectrum_data: props.config.spectrum ? _.cloneDeep(props.config.spectrum.spectrum_data) : null
    };
  }

  componentDidUpdate(prevProps) {
    const { config } = this.props;

    if (
      config &&
      config.spectrum &&
      config.spectrum.spectrum_data &&
      prevProps.config.spectrum &&
      !_.isEqual(prevProps.config.spectrum.spectrum_data, this.props.config.spectrum.spectrum_data)
    ) {
      this.setState({
        spectrum_data: _.cloneDeep(config.spectrum.spectrum_data),
        spectrumXUnit: config.spectrum.freq_units,
        spectrumYUnit: config.spectrum.amp_units,
        originalData: _.cloneDeep(config.spectrum.spectrum_data),
        shaftSpeed: config.spectrum.shaft_speed
      });
    }

    if (config && config.spectrum && _.isNil(config.spectrum.spectrum_data) && this.state.spectrum_data) {
      this.setState({
        spectrum_data: null
      });
    }

    if (prevProps.chartType !== this.props.chartType) {
      this.setState({ errorBandpass: '' });
    }
  }

  toggleSpecFrequencyUnits = (units, spectrumData) => {
    this.setState({
      spectrumXUnit: units,
      spectrum_data: spectrumData[0]
    });
  }

  toggleSpectrumAmplitudeUnits = (units, spectrumData) => {
    this.setState({
      spectrumYUnit: units,
      spectrum_data: spectrumData[0]
    });
  }

  setShaftSpeedState = (shaftSpeed) => {
    this.setState({ shaftSpeed });
  }

  getAssetType = () => {
    const { tag } = this.props;
    if (tag && tag.no_of_bins) return ASSET_TYPE.VFD;
    return ASSET_TYPE.FIXED_SPEED;
  }

  getEnvelopeData = () => {
    const { measurement_type, amp_type,
      tag: { spectral_envelope },
      config: { spectrum } } = this.props;

    let speed_bin = 0;
    if (spectrum) {
      speed_bin = spectrum.speed_bin;
    }

    const bin_no = `bin_${speed_bin || 0}`;
    if (spectral_envelope && spectral_envelope[measurement_type]) {
      const data = transformFromEnvelopeKeys(
        spectral_envelope[measurement_type],
        amp_type,
        SPECTRAL_ENVELOPE_KEYS
      );
      if (data) return data[bin_no];
    }
    return null;
  }

  isLoading = () => {
    const { config } = this.props;
    return (
      config.trends_data && config.trends_data.loading ||
      config.waterfall_spectrum && config.waterfall_spectrum.loading
    );
  }

  getNoDataTitle = () => {
    const { errorBandpass } = this.state;
    const { customDayFrom, customDayTo, chartType, amp_type } = this.props;
    let title = this.props.days === 'custom' && customDayFrom ?
      `No data available from ${customDayFrom.toString().slice(0, 15)} to ${customDayTo.toString().slice(0, 15)}`
      : 'No data available';
    if (chartType === 'waterfall_spectrum') {
      title = 'Either machine is off or no data available in this interval';
    }
    if (errorBandpass && amp_type === 'demod') {
      title = <>Bandpass cutoff is not set, click on <GreenSpan onClick={() => this.props.openEditDemod()}>edit</GreenSpan> to set</>;
    }
    return title;
  }

  getTrendChartHeight = (chartType) => {
    switch (chartType) {
      case 'TSW':
        return '100px';
      case 'trend':
        return '300px';
      default:
        return '70px';
    }
  }

  getFilterSettings = (type) => {
    const { config } = this.props;
    if (!config.spectrum || this.props.amp_type !== 'demod') return null;
    if (!config.spectrum.fmax) return null;
    const settings = {};
    settings.units = this.props.currentUser.frequency_units;
    switch (type) {
      case 'demod_spectrum':
        settings.filterTitle = 'Spectrum Filter:';
        settings.lowerFilter = this.props.tag.bandpass_lower_cutoff || assetHierarchyConstants.DEMOD_TREND_LOWER_FILTER;
        settings.upperFilter = this.props.tag.bandpass_upper_cutoff;
        return settings;

      case 'demod_waveform':
        settings.filterTitle = 'Waveform Filter:';
        settings.lowerFilter = this.props.tag.bandpass_lower_cutoff || assetHierarchyConstants.DEMOD_TREND_LOWER_FILTER;
        settings.upperFilter = this.props.tag.bandpass_upper_cutoff;
        return settings;

      case 'demod_trend':
        settings.filterTitle = 'Trend Filter:';
        settings.lowerFilter = assetHierarchyConstants.DEMOD_TREND_LOWER_FILTER * utils.getFreqConversionFactor('Hz', settings.units);
        settings.upperFilter = config.spectrum.fmax;
        return settings;

      default:
        return null;
    }
  }

  getAlarmThreshold = (type) => {
    const { tag, bin_nos, feature, amp_type } = this.props;
    const thresholds = tag.alarm_thresholds;
    if (tag.type === 'vibration') {
      if (bin_nos.length > 1 || (amp_type !== 'velocity' && amp_type !== 'demod' && amp_type !== 'acceleration')) return null;
      type = amp_type === 'velocity' ? type : `${amp_type}_${type}`;
      const bin = `bin_${bin_nos.length === 0 ? 0 : bin_nos[0]}`;
      return thresholds && thresholds[bin] &&
      thresholds[bin][type] * utils.getMultiplyFactorForAlarm(feature);
    }
    return thresholds && thresholds[type];
  }

  getEnvelopeXUnit = envelope => (
    envelope && envelope.envelope_data && envelope.envelope_data.x_units
  )

  getOnOffReadingIndicator = () => {
    const { config } = this.props;
    const data = config.trends_data[0].trend_data[0];
    const isEnabled = typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'on_off_reading');
    return isEnabled;
  }

  render() {
    const {
      amp_type,
      config,
      feature,
      tag,
      chartType,
      componentNode,
      currentUser
    } = this.props;

    if (!config) {
      return null;
    }

    let spectrumFeatures;
    if (config.spectrumFeatures && tag && tag.forcingFrequencies) {
      spectrumFeatures = {
        items: config.spectrumFeatures.items.concat(tag.forcingFrequencies.items)
      };
    } else if (config.spectrumFeatures) {
      spectrumFeatures = config.spectrumFeatures;
    } else if (tag && tag.forcingFrequencies) {
      spectrumFeatures = tag.forcingFrequencies;
    }

    let hasData = false;
    if (
      config.trends_data &&
      config.trends_data[0] &&
      config.trends_data[0].trend_data &&
      config.trends_data[0].trend_data.length > 0
    ) {
      hasData = true;
    }
    const noData = (config.trends_data && !config.trends_data.loading && !hasData && chartType !== 'waterfall_spectrum') || (
      chartType === 'waterfall_spectrum' && config.waterfall_spectrum &&
      !config.waterfall_spectrum.loading && !config.waterfall_spectrum.items
    );
    const commonProps = {
      unit_system: this.props.currentUser.unit_system,
      addingNote: this.props.addingNote,
      addNote: this.props.addNote,
      config_id: config.config_id,
      tag_type: tag.type
    };

    const noDataChart = (
      <NoDataChart
        tagType={tag.type}
        configId={config.config_id}
        height="300px"
        days={this.props.days}
        title={this.getNoDataTitle()}
        domain={config.time_range}
      />
    );

    const spectralEnvelope = (chartType === 'spectrum') ? this.getEnvelopeData() : null;
    const envelopeXUnit = this.getEnvelopeXUnit(spectralEnvelope);

    return (
      <ChartItemContainer innerRef={this.chartRef}>
        {!noData && tag.structure_type === 'vector' && this.props.chartType !== 'waterfall_spectrum' && (
          <TagInfoContainer>
            {config.spectrum && config.spectrum.fmax && (
              <>
                {this.props.waveAndSpecSelection && (
                  <InfoText>
                    <b>{utils.formatDate(this.props.waveAndSpecSelection.x)}</b>
                  </InfoText>
                )}
                <InfoText>
                  Fmax:&nbsp;
                  <b>
                    {config.spectrum.fmax} {config.spectrum.freq_units}
                  </b>
                </InfoText>
                <InfoText>
                  Lines of resolution: <b>{config.spectrum.lines_of_resolution}</b>
                </InfoText>
              </>
            )}
            {config.waveform && config.waveform.broadband_features && (
              <>
                <InfoText>
                  RMS:&nbsp;
                  <b>
                    {config.waveform.broadband_features.rms.toFixed(2)}&nbsp;
                    {config.waveform.amp_units}
                  </b>
                </InfoText>
                {config.spectrum && config.spectrum.shaft_speed && (
                  <InfoText>
                    Shaft Speed:&nbsp; <b>{config.spectrum.shaft_speed} {config.spectrum.freq_units}</b>
                  </InfoText>
                )}
                <InfoText>
                  Peak:&nbsp;
                  <b>
                    {config.waveform.broadband_features.peak.toFixed(2)}&nbsp;
                    {config.waveform.amp_units}
                  </b>
                </InfoText>
                <InfoText>
                  Peak to Peak:&nbsp;
                  <b>
                    {config.waveform.broadband_features.p2p.toFixed(2)}&nbsp;
                    {config.waveform.amp_units}
                  </b>
                </InfoText>
                <InfoText>
                  Crest Factor:&nbsp;
                  <b>{config.waveform.broadband_features.crest_factor.toFixed(2)}</b>
                </InfoText>
              </>
            )}
            {tag && tag.forcingFrequencies && tag.forcingFrequencies.items.map((item) => {
              if (!bearingFrequenciesDescription.includes(item.description)) return null;
              return (
                <InfoText>
                  {item.marker.toUpperCase()}:&nbsp;
                  <b>{item.frequency.toFixed(1)} {item.freq_units}</b>
                </InfoText>
              );
            })}
          </TagInfoContainer>
        )}
        <ChartContainer id="Chart-Container" minimizeHeight={this.props.minimizeHeight && false}>
          {this.isLoading() && <Loading />}
          {hasData && (
            <Fragment>
              {tag.type === 'utilization' && (
                <>
                  {this.props.selection && <Selection data={this.props.selection} />}
                  <UtilizationChart
                    trend={config.trends_data[0]}
                    type={tag.type}
                    expanded
                    calendarExpanded={this.props.calendarExpanded}
                    tagId={config.trends_data[0].tag_id}
                    selectedRange={this.props.selectedRange}
                    selection={this.props.selection}
                    mouseoverChart={this.props.mouseoverChart}
                    domain={config.time_range}
                    {...commonProps}
                  />
                </>
              )}
              {config.trends_data[0].tag_type !== 'utilization' && this.props.chartType !== 'waterfall_spectrum' && (
                <>
                  {this.props.selection && <Selection toFixedDecimalPoints={amp_type === 'demod' ? null : 2} data={this.props.selection} filterSettings={this.getFilterSettings('demod_trend')} />}

                  <TagChart
                    type={amp_type === 'demod' ? 'demod_trend' : 'trend'}
                    title="trend"
                    chartName={`configId-${config.config_id}-trend-analyst`}
                    data={config.trends_data[0].trend_data}
                    color={config.trends_config[0].color}
                    loading={config.trends_data.loading}
                    tagId={config.trends_data[0].tag_id}
                    expanded
                    yTitle={tag.structure_type === 'vector' ? (mapNames[feature] || utils.CapitalizeOnlyFirstLetter(feature)) : utils.CapitalizeEachWord(config.trends_data[0].tag_type)}
                    yUnit={config.trends_data[0].units}
                    xIsDate
                    mouseoverChart={this.props.mouseoverChart}
                    selectedRange={this.props.selectedRange}
                    mouseClick={this.props.getWaveAndSpecForId}
                    points={this.props.waveAndSpecSelection}
                    refreshChartAfterOutlier={this.props.refreshChartAfterOutlier}
                    storeBrushSelection={this.props.storeBrushSelection}
                    brushSelected={this.props.trendBrushSelection}
                    warningThreshold={this.getAlarmThreshold('warning')}
                    criticalThreshold={this.getAlarmThreshold('critical')}
                    baselineValue={config.trends_data[0].baseline_value}
                    ampType={this.props.amp_type}
                    feature={this.props.feature}
                    waveAndSpecSelection={this.props.waveAndSpecSelection}
                    setLastHoveredChart={this.props.setLastHoveredChart}
                    lastHoveredChart={this.props.lastHoveredChart}
                    height={this.getTrendChartHeight(this.props.chartType)}
                    contextHeight="25px"
                    miniMode={this.props.chartType !== 'trend' && this.props.chartType !== 'TSW'}
                    chart_size={this.props.chartType === 'trend' ? 'Large' : 'Normal'}
                    enableOnOffIndicator={this.getOnOffReadingIndicator()}
                    filterMinVal={this.props.filterMinVal}
                    filterMaxVal={this.props.filterMaxVal}
                    filterKey="speed"
                    paginationLoading={this.props.paginationLoading}
                    showBaseline={this.props.showBaseline}
                    editRULSettings={this.props.editRULSettings}
                    closeRULSettings={this.props.closeRULSettings}
                    rulFdp={tag.rul_settings && tag.rul_settings.fdp}
                    rulStartTime={tag.rul_settings && tag.rul_settings.start_time}
                    domain={config.time_range}
                    {...commonProps}
                  />
                </>
              )}
                {config.spectrum &&
                (this.props.chartType === 'spectrum' || this.props.chartType === 'TSW') && (
                  <Fragment>
                    <TagChart
                      fmax={config.spectrum.fmax}
                      lines_of_resolution={config.spectrum.lines_of_resolution}
                      type={amp_type === 'demod' ? 'demod_spectrum' : 'spectrum'}
                      title="spectrum"
                      chartName={`configId-${config.config_id}-spectrum-analyst`}
                      data={this.state.spectrum_data}
                      color={config.trends_config[0].color}
                      loading={config.spectrum.loading}
                      originalData={this.state.originalData || config.spectrum.spectrum_data}
                      tagId={tag.id}
                      expanded
                      yTitle="Amplitude"
                      initialYunit={config.spectrum.amp_units}
                      yUnit={this.state.spectrumYUnit || config.spectrum.amp_units}
                      xTitle="Frequency"
                      xUnit={this.state.spectrumXUnit || config.spectrum.freq_units}
                      initialXunit={config.spectrum.freq_units}
                      toggleSpectrumFrequencyUnits={this.toggleSpecFrequencyUnits}
                      toggleSpectrumAmplitudeUnits={this.toggleSpectrumAmplitudeUnits}
                      xIsDate={false}
                      shaftSpeed={this.state.shaftSpeed || config.spectrum.shaft_speed}
                      setShaftSpeedState={this.setShaftSpeedState}
                      xAxisType="linear"
                      ampType={this.props.amp_type}
                      onFeatureDetailsClick={this.props.onFeatureDetailsClick}
                      onFeatureEditClick={this.props.onFeatureEditClick}
                      features={spectrumFeatures}
                      filterSettings={this.getFilterSettings('demod_spectrum')}
                      setLastHoveredChart={this.props.setLastHoveredChart}
                      lastHoveredChart={this.props.lastHoveredChart}
                      storeBrushSelection={this.props.storeBrushSelection}
                      measurementTimestamp={this.props.waveAndSpecSelection && this.props.waveAndSpecSelection.x}
                      componentNode={componentNode}
                      {...commonProps}
                      height={this.props.chartType === 'TSW' ? '100px' : '300px'}
                      contextHeight="25px"
                      chart_size={this.props.chartType === 'spectrum' ? 'Large' : 'Normal'}
                      spectralEnvelope={spectralEnvelope}
                      envelopeXUnit={envelopeXUnit}
                      shaftSpeedUnit={currentUser.frequency_units}
                      assetType={this.assetType}
                    />
                  </Fragment>
                )}
              {config.waveform &&
                (this.props.chartType === 'waveform' || this.props.chartType === 'TSW') && (
                  <Fragment>
                    <TagChart
                      type="waveform"
                      title="waveform"
                      chartName={`configId-${config.config_id}-waveform-analyst`}
                      data={config.waveform.waveform_data}
                      color={config.trends_config[0].color}
                      loading={config.waveform.loading}
                      tagId={tag.id}
                      expanded
                      yTitle="Amplitude"
                      yUnit={config.waveform.amp_units}
                      xTitle="Time"
                      xUnit="ms"
                      ampType={this.props.amp_type}
                      xIsDate={false}
                      xAxisType="linear"
                      setLastHoveredChart={this.props.setLastHoveredChart}
                      lastHoveredChart={this.props.lastHoveredChart}
                      storeBrushSelection={this.props.storeBrushSelection}
                      {...commonProps}
                      height={this.props.chartType === 'TSW' ? '100px' : '300px'}
                      contextHeight="25px"
                      chart_size={this.props.chartType === 'waveform' ? 'Large' : 'Normal'}
                      filterSettings={this.getFilterSettings('demod_waveform')}
                    />
                  </Fragment>
              )}
              {config.cepstrum && this.props.chartType === 'cepstrum' && (
                  <Fragment>
                    <TagChart
                      type="waveform"
                      title="Cepstrum"
                      chartName={`configId-${config.config_id}-cepstrum-analyst`}
                      data={config.cepstrum.cepstrum_data}
                      color={config.trends_config[0].color}
                      loading={config.cepstrum.loading}
                      tagId={tag.id}
                      expanded
                      yTitle="Amplitude"
                      yUnit={config.cepstrum.amp_units}
                      xTitle="Quefrency"
                      xUnit="ms"
                      ampType={this.props.amp_type}
                      xIsDate={false}
                      xAxisType="linear"
                      setLastHoveredChart={this.props.setLastHoveredChart}
                      lastHoveredChart={this.props.lastHoveredChart}
                      storeBrushSelection={this.props.storeBrushSelection}
                      {...commonProps}
                      height="300px"
                      contextHeight="25px"
                      chart_size="Large"
                      filterSettings={this.getFilterSettings('demod_waveform')}
                    />
                  </Fragment>
              )}
              {this.props.chartType === 'waterfall_spectrum' && tag && config.waterfall_spectrum && config.waterfall_spectrum.items && (
                <WaterfallChart
                  spectrums={config.waterfall_spectrum.items}
                  xUnit={config.waterfall_spectrum.freq_units}
                  yUnit={config.waterfall_spectrum.amp_units}
                  yLabel={commonProps.tag_type === 'vibration' ? `Amplitude(${config.waterfall_spectrum.amp_units},0-Pk)` : `Amplitude(${config.waterfall_spectrum.amp_units})`}
                  hierarchyViewPane={this.props.hierarchyViewPane}
                  showPerspectiveChanger
                  showAmplitudeTagChart
                />
              )}
            </Fragment>
          )}
          {(noData || this.state.errorBandpass) && noDataChart}
        </ChartContainer>
      </ChartItemContainer>
    );
  }
}

ChartItem.propTypes = {
  config: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentUser: state.user.user,
  rbacPermissions: state.rbacPermissions,
  hierarchyViewPane: state.hierarchyViewPane,
  componentId: state.breadcrumb.component.id
});

export default connect(mapStateToProps, {})(withRouter(ChartItem));
