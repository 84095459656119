import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell as TableCellT,
  TableBody,
  Table as TableT
} from 'semantic-ui-react';
import Checkbox from 'common/components/atoms/Checkbox';
import { CapitalizeEachWord, oneToOneMapping, toFixedXIfNumber } from 'common/utils';
import { mapNames as mapFeatureNames } from 'common/charts/constants';
import { getTagGroupName, getTagMatchKey } from 'common/components/Chart/utils/helpers';
import { scrollbar } from 'common/styles/scrollbar';
import * as assetConstants from '../../constants/assetHierarchy.constants';

const Container = styled.div`
  margin: 1em;
  border: 1px solid #ddd;
  border-radius: 0.28rem;
  overflow: auto;

  ${scrollbar}
`;

const ColoredCircle = styled.div`
  background: ${props => props.color};
  width: 10px;
  height: 10px;
  border-radius: 1em;
`;

const Table = styled(TableT)`
  th, td {
    border: 1px solid #ddd;
    background: #fff;
    min-width: 120px;
  }

  /* Make first column of table sticky */
  th:first-child, td:first-child {
    position: sticky;
    font-weight: bold;
    left: 0;
    z-index: 1; /* Ensure the first column is above other columns */
  }

  /* Adjust z-index for the header row */
  th:first-child {
    z-index: 4; /* Ensure the header cell is above the body cells */
  }
`;

const TableCell = ({ children }) => (
  <TableCellT>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      {children}
    </div>
  </TableCellT>
);

const CustomCheckbox = ({ label, value, onChange }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Checkbox title={label} value={value} onChange={onChange} />
  </div>
);

const constructTagTitle = (tag, trendData) => {
  const tagName = _.chain([tag.location_description, tag.type, tag.direction])
    .filter(_.isString)
    .map(CapitalizeEachWord)
    .join(' ')
    .value();
  let title = oneToOneMapping(assetConstants.SHORTER_CHART_CONFIG_NAME_MAPPINGS, tagName);
  if (tag.amp_type) {
    title += mapFeatureNames[tag.amp_type] ? ` (${mapFeatureNames[tag.amp_type]})` : ` (${tag.amp_type})`;
  }
  if (tag.feature) {
    title += mapFeatureNames[tag.feature] ? ` - ${mapFeatureNames[tag.feature]}` : ` - ${tag.feature}`;
  }
  title += ` (${trendData.units})`;
  return title;
};

function ChartLegendContainer({ config, tags, selections, exclusions, onUpdateExclusions }) {
  const trendsConfig = useMemo(() => {
    if (_.isEmpty(tags)) return [];
    const localConfigs = config.trends_config
      .map((item) => {
        let tag = tags.find(i => i.id === item.tag_id);
        tag = _.cloneDeep(tag);
        if (item.amp_type) tag.amp_type = item.amp_type;
        if (item.feature) tag.feature = item.feature;
        const match_key = getTagMatchKey(item);
        const trendData = config.trends_data.find(i => getTagMatchKey(i) === match_key);
        return {
          ...item,
          tag,
          trendData,
        };
      })
      .filter(item => item.tag)
      .filter(item => item.trendData);
    _.forEach(selections, (selection) => {
      const config = localConfigs[selection.dataIdx];
      if (config) config.selection = selection;
    });
    return localConfigs;
  }, [config, tags, selections]);

  const onHeaderCheckChange = (item) => {
    let { tagNames, machineIds } = exclusions;
    if (item.data.type === 'tag') {
      if (tagNames.includes(item.data.id)) {
        tagNames = tagNames.filter(i => i !== item.data.id);
      } else {
        tagNames = [...tagNames, item.data.id];
      }
    } else if (machineIds.includes(item.data.id)) {
      machineIds = machineIds.filter(i => i !== item.data.id);
    } else {
      machineIds = [...machineIds, item.data.id];
    }

    onUpdateExclusions({
      tagNames, machineIds
    });
  };

  const tableData = useMemo(() => {
    const constructTagName = item => getTagGroupName(item.tag);
    const tagNames = [...new Set(trendsConfig.map(item => constructTagName(item)))];
    const assetNames = [
      ...new Set(trendsConfig.map(item => item.machine_name))
    ];

    const table = Array.from({ length: assetNames.length + 1 }, () =>
      Array(tagNames.length + 1)
    );

    // Fill the column headers with tag names
    for (let i = 0; i < tagNames.length; i++) {
      const tagName = tagNames[i];
      const trendItem = trendsConfig.find(i => constructTagName(i) === tagName);

      const title = constructTagTitle(trendItem.tag, trendItem.trendData);

      table[0][i + 1] = {
        type: 'header',
        data: {
          id: tagNames[i],
          type: 'tag',
          title,
          checked: !exclusions.tagNames.includes(tagNames[i]),
        }
      };
    }

    // Fill first column with asset names
    table[0][0] = {
      type: 'empty'
    };
    for (let i = 0; i < assetNames.length; i++) {
      const assetName = assetNames[i];
      const machine_id = trendsConfig.find(i => i.machine_name === assetName).machine_id;

      table[i + 1][0] = {
        type: 'header',
        data: {
          id: machine_id,
          type: 'machine',
          title: assetName,
          checked: !exclusions.machineIds.includes(machine_id)
        }
      };
    }

    const rowEmpty = Array(table.length).fill(tagNames.length);
    const colEmpty = Array(table.length).fill(assetNames.length);

    // Fill remaining data
    trendsConfig.forEach((item) => {
      const row = assetNames.indexOf(item.machine_name) + 1;
      const col = tagNames.indexOf(constructTagName(item)) + 1;

      let value = '';
      if (item.selection) {
        value = toFixedXIfNumber(item.selection.realY || item.selection.y);
        value += ` ${item.trendData.units}`;
      } else {
        rowEmpty[row] -= 1;
        colEmpty[col] -= 1;
      }

      table[row][col] = {
        type: 'cell',
        data: {
          id: item.tag_id,
          color: item.color,
          value,
          disable: !table[row][0].data.checked || !table[0][col].data.checked
        }
      };
    });

    // In case if we missed any data, fill it with empty data
    for (let i = 1; i < table.length; i++) {
      for (let j = 1; j < table[i].length; j++) {
        if (!table[i][j]) {
          table[i][j] = {
            type: 'cell',
            data: {}
          };
        }
      }
    }

    // Check if a row or column is empty for the asset and remove it
    let finalTable = table;
    rowEmpty.forEach((row, idx) => {
      if (row <= 0) {
        finalTable[idx] = null;
      }
    });
    finalTable = finalTable.filter(item => item);
    colEmpty.forEach((col, idx) => {
      if (col <= 0) {
        finalTable = finalTable.map((row) => {
          row.splice(idx, 1);
          return row;
        });
      }
    });

    return finalTable;
  }, [trendsConfig]);

  const constructComponent = (item) => {
    if (!item) return <></>;
    if (item.type === 'header') {
      return (
        <CustomCheckbox
          label={item.data.title}
          value={item.data.checked}
          onChange={() => onHeaderCheckChange(item)}
        />
      );
    }

    if (item.type === 'cell' && item.data.value && !item.data.disable) {
      return (
        <>
          <ColoredCircle color={item.data.color} /> {item.data.value}
        </>
      );
    }

    return <></>;
  };

  if (_.isEmpty(tableData)) {
    return <></>;
  }

  return (
    <Container>
      <Table compact celled small style={{ margin: '0em' }}>
        <TableHeader>
          <TableRow>
            {tableData[0].map((item, idx) => (
              <TableHeaderCell key={idx}>{constructComponent(item)}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>

        <TableBody>
          {tableData.map(
            (items, index) =>
              index !== 0 && (
                <TableRow>
                  {Array(tableData[index].length).fill(1).map((_, idx) => (
                    <TableCell key={items[idx].data.id}>{constructComponent(items[idx])}</TableCell>
                  ))}
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </Container>
  );
}

ChartLegendContainer.propTypes = {
  config: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  exclusions: PropTypes.object.isRequired,
  onUpdateExclusions: PropTypes.func.isRequired,
  selections: PropTypes.array,
};

ChartLegendContainer.defaultProps = {
  selections: undefined,
};

ChartLegendContainer.displayName = 'ChartLegendContainer';

export default ChartLegendContainer;
