import ThirdPartyIntegrationsTypes from '../actions/thirdPartyIntegrations.types';

const initialState = {
  items: [],
  page: 1,
  hasMore: false,
  service_account_details: {},
  tagTypes: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ThirdPartyIntegrationsTypes.GET_THIRD_PARTY_INTEGRATION_LIST_SUCCESS: {
      let tpiList = [];
      const payload = action.payload;
      if (payload.page === 1) tpiList = payload.TpiList;
      else tpiList = state.items.concat(payload.TpiList);
      return {
        items: tpiList,
        hasMore: payload.page < payload.total_pages,
        page: payload.page,
        total_count: payload.total_count
      };
    }

    case ThirdPartyIntegrationsTypes.GET_THIRD_PARTY_INTEGRATION_DETAILS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        service_account_details: payload.data
      };
    }

    case ThirdPartyIntegrationsTypes.GET_TPI_TAG_TYPES: {
      return {
        ...state,
        tagTypes: action.res.tag_types
      };
    }

    default:
      return state;
  }
};
