import { prop } from 'styled-tools';
import styled from 'styled-components';

const HealthHeader = styled.span`
  cursor: pointer;
  display: block;
  color: ${prop('theme.color.greyXXD')};
  font-size: ${props => props.sub ? '16px' : '20px'};
  font-weight: ${props => props.light ? '400' : '600'};;
`;

export default styled(HealthHeader)``;
