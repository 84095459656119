import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import SvgBtnT from './SvgBtn';
import CrossSvg from '../../images/CrossSvg';

const SvgBtn = styled(SvgBtnT)`
  opacity: 0.4;
  grid-area: 2 / 3 / span 1 / span 1; 
`;

const AlertGrid = styled.div.attrs({})`
  position: absolute;
  top: ${prop('top')};
  right: ${prop('right')};
  display: grid;
  width: fit-content;
  grid-template: 11px repeat(auto-fill, minmax(20px, 1fr)) 11px / 16px 310px 45px;
  border-radius: 4px;
  background-color: #3C3D3A;
  overflow-wrap: break-word;
  z-index: 4;
`;

const AlertTitle = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: ${prop('color', '#ffffff')};
  grid-area: 2 / 2 / span 1 / span 1;
`;

const AlertMessage = (props) => {
  const {
    message,
    top,
    right,
    close
  } = props;
  return (
    <AlertGrid top={top} right={right} onClick={close}>
      <AlertTitle>{message}</AlertTitle>
      <SvgBtn><CrossSvg fill="#ffffff" height={20} width={20} /></SvgBtn>
    </AlertGrid>
  );
};


AlertMessage.propTypes = {
  message: PropTypes.string.isRequired,
  top: PropTypes.string,
  right: PropTypes.string,
  close: PropTypes.func.isRequired
};

AlertMessage.defaultProps = {
  top: '40px',
  right: '20px'
};

export default styled(AlertMessage)``;
