import styled from 'styled-components';

const InfoboxContainer = styled.div`
  padding: 0px 25px;
  border-bottom: 2px solid ${props => props.theme.colors.greyL};
  background-color: #EDF3E1;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
`;

export default styled(InfoboxContainer)``;
