import React, { Component } from 'react';
import styled from 'styled-components';

import Button from 'common/components/atoms/Button';
import AlertPrompt from 'common/components/organisms/AlertPrompt';

const InlineButton = styled(Button)`
  vertical-align: text-bottom;
  margin-left: 12px;
`;

class ButtonConfirm extends Component {
  constructor() {
    super();
    this.state = {
      confirmDeleteOpen: false,
    };
  }

  setConfirmDeleteOpen = (value) => {
    this.setState({
      confirmDeleteOpen: value,
    });
  };

  render() {
    const {
      onProceed,
      tooltip,
      buttonContent,
      promptMessage,
      promptSecondaryMessage,
    } = this.props;

    const {
      confirmDeleteOpen,
    } = this.state;

    return (
            <>
                <InlineButton
                  title={tooltip}
                  marginBottom="0px"
                  text
                  onClick={() => this.setConfirmDeleteOpen(true)}
                >
                    {buttonContent}
                </InlineButton>
                {confirmDeleteOpen && (
                    <AlertPrompt
                      message={promptMessage}
                      secondaryMessage={promptSecondaryMessage}
                      onProceed={() => {
                        this.setConfirmDeleteOpen(false);
                        onProceed();
                      }}
                      onCancel={() => this.setConfirmDeleteOpen(false)}
                    />
                )}
            </>
    );
  }
}

ButtonConfirm.propTypes = {
};

ButtonConfirm.defaultProps = {
};

export default ButtonConfirm;
