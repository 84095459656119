import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import BearingNewItem from './BearingNewItem';
import BearingRowItem from './BearingRowItem';

class BearingItem extends Component {
  constructor(props) {
    super(props);

    this.startEdit = this.startEdit.bind(this);
    this.itemSaved = this.itemSaved.bind(this);

    this.state = {
      editing: false
    };
  }

  startEdit() {
    this.setState({
      editing: true
    });
  }

  itemSaved() {
    this.setState({
      editing: false
    });
  }

  render() {
    const {
      item,
      columnSizes,
      selected
    } = this.props;

    const editable = (item.newitem || this.state.editing);
    return (
      <Fragment>
        {!editable ? (
          <BearingRowItem
            selected={selected}
            columnSizes={columnSizes}
            item={item}
            edit={this.startEdit}
          />
        ) : (
          <BearingNewItem
            item={item}
            columnSizes={columnSizes}
            itemSaved={this.itemSaved}
            componentId={this.props.componentId}
            bearingLocationId={this.props.bearingLocationId}
          />
        )}
      </Fragment>
    );
  }
}

BearingItem.propTypes = {
  columnSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  item: PropTypes.object.isRequired
};

export default BearingItem;
