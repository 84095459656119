import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import FlexContainer from 'common/components/atoms/FlexContainer';
import H2 from 'common/typography/H2/H2';
import InputField from 'common/components/atoms/InputField';
import { unitSystemMap } from 'common/constants';

import { SPECTRAL_WINDOW_TYPE_OPTIONS } from '../../../constants/envelope.constants';

const getSpectralWidthPrefix = (settingsType, xScale, spectrumXUnit) => {
  if (settingsType === SPECTRAL_WINDOW_TYPE_OPTIONS[2].value) {
    return '%';
  }
  return spectrumXUnit;
};

const SpectralWindowSettings = ({
  ampUnits,
  envelopeSettingsError,
  spectrumXUnit,
  settings,
  unitSystem,
  updateEnvelopeSettings,
  spectrumXScale
}) => (
  <FlexContainer marginright="2em" direction="column">
    <H2>&nbsp;</H2>
    <InputField
      label="Spectral window width type"
      options={SPECTRAL_WINDOW_TYPE_OPTIONS}
      onChange={(e, data) => {
        updateEnvelopeSettings('spectral_window', {
          ...settings,
          type: data.value
        });
      }}
      type="select"
      value={settings.type}
    />
    <InputField
      error={envelopeSettingsError.spectral_window_width}
      label="Spectral window width"
      onChange={(e) => {
        updateEnvelopeSettings('spectral_window', {
          ...settings,
          width: _.isEmpty(e.target.value) ? '' : parseFloat(e.target.value, 0)
        });
      }}
      prefix={getSpectralWidthPrefix(settings.type, spectrumXScale, spectrumXUnit)}
      type="number"
      value={settings.width}
    />
    <InputField
      error={envelopeSettingsError.min_caution_level}
      label="Min. caution level"
      onChange={(e) => {
        updateEnvelopeSettings('spectral_window', {
          ...settings,
          min_caution_level: _.isEmpty(e.target.value) ? '' : parseFloat(e.target.value, 0)
        });
      }}
      prefix={ampUnits || unitSystemMap[unitSystem].units}
      type="number"
      value={settings.min_caution_level}
    />
  </FlexContainer>
);

SpectralWindowSettings.propTypes = {
  envelopeSettingsError: PropTypes.object.isRequired,
  frequencyUnits: PropTypes.string.isRequired,
  ampUnits: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  unitSystem: PropTypes.number.isRequired,
  updateEnvelopeSettings: PropTypes.func.isRequired
};

export default SpectralWindowSettings;
