import styled from 'styled-components';

import Loading from 'common/components/atoms/Loading';

const AbsoluteLoading = styled(Loading)`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e447;
`;
export default styled(AbsoluteLoading)``;
