import { Component } from 'react';
import * as d3 from 'd3';
import * as _ from 'lodash';

import { LineChart } from '../hoc/LineChart';
import {
  yAxisGridlines,
  xAxisGridlines,
  ticksBottom
} from '../utils/gridUtils';
import {
  drawDataline,
  addHorizontalBrush
} from '../utils/graphUtils';

class SimpleLineContext extends Component {
  constructor(props) {
    super(props);
    this.brushed = this.brushed.bind(this);
    this.brushended = this.brushended.bind(this);

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.brushRange &&
      !_.isEqual(prevProps.brushRange, this.props.brushRange)
    ) {
      const selection = this.props.brushRange;
      d3.select(`#${this.props.chartName}-clip > rect`)
        .attr('x', selection[0])
        .attr('width', selection[1] - selection[0]);
      d3.select(`g.${this.props.chartName}.brush`).call(
        this.brush.move,
        selection
      );
    }
    if (
      this.props.axisLocked &&
      !_.isEqual(prevProps.axisLocked, this.props.axisLocked)
    ) {
      const brushSelection = d3.brushSelection(
        d3.select(`g.${this.props.chartName}.brush`).node()
      );
      if (!this.props.axisLocked.x) {
        d3.selectAll(`.${this.props.chartName}.brush>.overlay`)
          .attr('pointer-events', 'all')
          .attr('cursor', 'crosshair');
        if (brushSelection) {
          d3.selectAll(`.${this.props.chartName}.brush>.handle`).style(
            'display',
            'block'
          );
        }
      } else {
        d3.selectAll(`.${this.props.chartName}.brush>.overlay`)
          .attr('pointer-events', 'none')
          .attr('cursor', 'not-allowed');
        if (brushSelection) {
          d3.selectAll(`.${this.props.chartName}.brush>.handle`).style(
            'display',
            'none'
          );
        }
      }
    }
  }

  brushed() {
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') return;
    const selection = d3.event.selection;
    if (!selection) return;
    if (_.isNaN(selection[0]) || _.isNaN(selection[1])) return;
    d3.select(`#${this.props.chartName}-clip > rect`)
      .attr('x', selection[0])
      .attr('width', selection[1] - selection[0]);
    if (this.ctx.container) {
      const kx =
        this.ctx.container.getBoundingClientRect().width /
        (selection[1] - selection[0]);
      const transform = { transformX: -selection[0] * kx, kx };
      if (this.props.storeTransform) this.props.storeTransform(transform);
    }
    const s = d3.brushSelection(
      d3.select(`g.${this.props.chartName}.brush`).node()
    );
    if (s !== null) {
      this.brushSelection = [
        this.ctx.x2.invert(s[0]),
        this.ctx.x2.invert(s[1])
      ];
    } else this.brushSelection = this.ctx.x.domain();
    if (this.props.brushCb) this.props.brushCb(s || this.ctx.x.domain());
  }

  brushended() {
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') return;
    if (this.props.axisLocked && this.props.axisLocked.x) {
      d3.selectAll(`.${this.props.chartName}.brush>.handle`).style(
        'display',
        'none'
      );
    }
  }

  drawGraph(ctx) {
    this.ctx = ctx;
    const options = {
      brushed: this.brushed,
      brushended: this.brushended,
      onBrushOverlayClick: this.onBrushOverlayClick
    };
    const {
      chartType,
      ampType,
      tag_type,
      xUnit
    } = this.props;
    if (
      (chartType === 'spectrum' || chartType === 'demod_spectrum') &&
      ampType === 'velocity' && !_.isEmpty(this.props.data) &&
      tag_type === 'vibration'
    ) {
      let maxExtent = 400;
      if (xUnit === 'CPM') maxExtent = 400 * 60;
      options.extent = [0, this.ctx.x(maxExtent)];
    }
    d3.selectAll(`.${ctx.props.chartName}.brush`).remove();
    this.brush = addHorizontalBrush(
      this.ctx.chart,
      this.props.chartName,
      options
    );
    d3.select(`g.${this.props.chartName}.brush`).call(this.brush.move, null);
  }

  drawAxis(ctx) {
    const { chartName } = this.props;
    d3.selectAll(`.${chartName}.axis`).remove();
    // draw grid lines and ticks (values along axises)
    this.yAxisGridlines = yAxisGridlines(ctx.chart, ctx.y, 0, ` ${chartName}`);
    this.xAxisGridlines = xAxisGridlines(ctx.chart, ctx.x, 6, `${chartName}`);
    this.ticksBottom = ticksBottom(ctx.chart, ctx.x, 6, `${chartName}`, 0);
  }

  drawDataLines(ctx) {
    // TODO: utils chart context lines override regular once is clunky here
    if (ctx.props.utilCtx) {
      const { chartName } = this.props;

      d3.selectAll(`.${chartName}.dataline`).remove();
      d3.selectAll(`.${chartName}.noClipDataline`).remove();

      ctx.chartDatalines = [];
      this.noClipDataline = [];

      ctx.props.data.forEach((dataSet, idx) => {
        const orderValue = (idx + 1) % 3;
        const dataline = drawDataline(
          ctx.chart,
          dataSet,
          ctx.x,
          ctx.y,
          `${chartName} dataline idx-${idx}`,
          {
            lineFunc: d3
              .line()
              .curve(d3.curveLinear)
              .defined((d) => {
                if (d.y === idx) {
                  return true;
                }
                return false;
              })
              .x(d => ctx.x(d.x))
              .y(() => ctx.y(orderValue))
          }
        );

        if (!ctx.props.activeData[idx]) dataline.attr('opacity', 0);
        ctx.chartDatalines.push(dataline);

        const noClipDataline = drawDataline(
          ctx.chart,
          dataSet,
          ctx.x,
          ctx.y,
          `${chartName} noClipDataline idx-${idx}`,
          {
            lineFunc: d3
              .line()
              .curve(d3.curveLinear)
              .defined((d) => {
                if (d.y === idx) {
                  return true;
                }
                return false;
              })
              .x(d => ctx.x(d.x))
              .y(() => ctx.y(orderValue))
          }
        );

        if (!ctx.props.activeData[idx]) noClipDataline.attr('opacity', 0);
        this.noClipDataline.push(noClipDataline);
      });
      return;
    }

    const { chartName } = this.props;
    d3.selectAll(`.${chartName}.noClipDataline`).remove();
    // add a dataline that wont get clipped (shows a 'not selected line')
    this.noClipDataline = [];

    ctx.props.data.forEach((dataSet, idx) => {
      this.noClipDataline.push(
        drawDataline(
          ctx.chart,
          dataSet,
          ctx.x,
          ctx.y,
          `${chartName} noClipDataline idx-${idx}`,
          {
            lineFunc: d3
              .line()
              .curve(d3.curveLinear)
              .x(d => ctx.x(d.x))
              .y(d => ctx.y(d.y))
          }
        )
      );
    });
  }

  render() {
    return null;
  }
}

export default LineChart(SimpleLineContext);
