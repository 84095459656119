import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ListItem from 'common/components/atoms/ListItem';

const MissedMeasurementListItem = styled(ListItem)`
  cursor: pointer;
  ${props => props.selected && 'background-color: rgba(145,184,70,0.08);'}
  td {
    height: 70px;
  }
`;

class MissedMeasurementsItem extends React.Component {
  render() {
    const {
      item,
      columnSizes,
      onSensorSelect,
    } = this.props;
    return (
      <>
      <MissedMeasurementListItem onClick={() => onSensorSelect(item.serial_number)}>
          <td width={`${columnSizes[0]}%`}>
            {item.serial_number || '--'}
          </td>
          <td width={`${columnSizes[1]}%`} style={{ textAlign: 'center' }}>
            {item.count || '--'}
          </td>
          <td width={`${columnSizes[2]}%`} style={{ textAlign: 'center' }}>
            {item.account_name || '--'}
          </td>
      </MissedMeasurementListItem>
      </>
    );
  }
}

MissedMeasurementsItem.propTypes = {
  item: PropTypes.object.isRequired,
  columnSizes: PropTypes.array.isRequired
};

export default MissedMeasurementsItem;
