import React from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import colors from 'common/styles/colors';
import Label from 'common/typography/Label/Label';
import Text_T from 'common/typography/Text/Text';
import LoadingSvg from 'common/images/LoadingSvg';
import { humanize } from 'common/helpers';
import DeleteIconSvg from 'common/images/BearingModal/DeleteIconSvg';
import { FlexContainer } from 'common/components/atoms';
import { deleteNote } from 'home/Activity/actions/activity.actions';
import activityFormat from 'home/Activity/components/ActivityFormat';
import MetadataContainer from 'common/components/atoms/MetadataContainer';
import SecondaryText_T from 'common/typography/SecondaryText/SecondaryText';
import { formatDate } from 'common/utils';
import { Paperclip } from 'common/images/FaIcons';
import { deleteRepair } from '../actions/repairs.actions';
import Attachments from '../organisms/Attachments';
import DiffViewer from './atoms/DiffViewer';

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.greyD};
  margin-bottom: 1rem;
  ${Label} {
    margin: 0;
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  color: ${colors.greyD};
`;

const TaskItemContainer = styled.div`
  display: flex;
  padding: 1rem;
  position: relative;
  margin-bottom: 1rem;
  background-color: ${colors.greyXXXL};
  border-radius: 5px;
  border: 1px solid ${colors.white};
  ${props => props.clickable && `
    cursor: pointer;
    &:hover {
      background-color: ${colors.greyXXL};
      border: 1px solid ${colors.greyD};
    }
  `};
`;

const Text = styled(Text_T)`
  font-size: 14px;
  word-break: break-word;
  ${props => props.color && `color: ${props.color};`}
`;

const SecondaryText = styled(SecondaryText_T)`
  font-size: 12px;
  line-height: 160%;
  ${props => props.marginTop && `margin-top: ${props.marginTop};`}
`;

const IconContainer = styled(FlexContainer).attrs({
  justifyContent: 'center',
  alignItems: 'center'
})`
  ${props => props.paddingRight && `padding-right: ${props.paddingRight};`}
`;

const formHistorySentence = (item, getItemField) => {
  if (item.resource_type === 'task') {
    if (item.op === 'ADD') {
      return <><b>{item.user_name}</b> created this task in <b>{humanize(item.state)}</b></>;
    }
    switch (item.column) {
      case 'name':
        return <><b>{item.user_name}</b> renamed this task to <b>{item.new_value}</b></>;
      case 'state':
        return <><b>{item.user_name}</b> moved this task from <b>{humanize(item.old_value)}</b> to <b>{humanize(item.new_value)}</b></>;
      case 'description':
        return <><b>{item.user_name}</b> updated the description</>;
      case 'recommended_action':
        return <><b>{item.user_name}</b> updated the recommended action</>;
      case 'task_type':
        return <><b>{item.user_name}</b> updated the task type to <b>{humanize(item.new_value)}</b></>;
      case 'condition':
        return <><b>{item.user_name}</b> changed the asset condition from <b>{humanize(item.old_value)}</b> to <b>{humanize(item.new_value)}</b></>;
      case 'defect':
        return (_.isEmpty(item.new_value) ?
          <><b>{item.user_name}</b> removed the defect <b>{humanize(item.old_value)}</b></> :
          <><b>{item.user_name}</b> updated the suspected defect to <b>{humanize(item.new_value)}</b></>
        );
      case 'devices_list':
        return (_.isEmpty(item.new_value) ?
          <><b>{item.user_name}</b> removed all selected devices</> :
          <><b>{item.user_name}</b> selected the devices <b>{item.new_value.map(it => it.serial_number).join(', ')}</b></>
        );
      case 'user_role_assigned':
        return <><b>{item.user_name}</b> assigned the task to <b>{humanize(item.new_value)}</b></>;
      default:
        return '';
    }
  } else if (item.resource_type === 'attachment') {
    return <><b>{item.user_name}</b> added <b>{item.attachments.length}</b> attachment(s)</>;
  } else {
    if (item.op === 'ADD') {
      switch (item.resource_type) {
        case 'note':
          return <><b>{item.user_name}</b> added a note</>;
        case 'repair':
          return <><b>{item.user_name}</b> added a repair <b>{getItemField(item, 'name')}</b></>;
        default:
          return '';
      }
    }
    if (item.op === 'UPDATE') {
      switch (item.resource_type) {
        case 'note':
          return <><b>{item.user_name}</b> updated the note</>;
        case 'repair':
          return <><b>{item.user_name}</b> updated the repair <b>{item.name}</b></>;
        default:
          return '';
      }
    }
    switch (item.resource_type) {
      case 'note':
        return <><b>{item.user_name}</b> deleted a <b>{humanize(item.resource_type)}</b></>;
      case 'repair':
        return <><b>{item.user_name}</b> deleted the repair <b>{item.name}</b></>;
      default:
        return '';
    }
  }
};

function TaskHistory({
  items,
  loading,
  setRepairModalOpen,
  setNoteModalOpen,
  deleteNote,
  deleteRepair,
  taskId,
  repairs,
  notes
}) {
  const onItemClickHandler = (item) => {
    if (item.resource_type === 'repair') {
      setRepairModalOpen(item.resource_id);
    } else if (item.resource_type === 'note') {
      setNoteModalOpen(item.resource_id);
    }
  };

  const onClickDelete = (resource_type, resource_id) => {
    if (resource_type === 'note') {
      deleteNote(resource_id, taskId);
    } else if (resource_type === 'repair') {
      deleteRepair(resource_id, taskId);
    }
  };

  const isItemClickable = item => (
    (item.resource_type === 'repair' || item.resource_type === 'note') &&
    item.op !== 'DELETE' && !item.deleted
  );

  const getItemField = (item, fieldName = 'description') => {
    let fieldVal = null;
    if (item.resource_type === 'repair') {
      const repair = repairs.find(r => r.id === item.resource_id);
      if (repair) {
        fieldVal = repair[fieldName];
      }
    } else if (item.resource_type === 'note') {
      const note = notes.find(n => n.id === item.resource_id);
      const field = fieldName === 'description' ? 'note_text' : fieldName;
      if (note) {
        fieldVal = note[field];
      }
    }
    return fieldVal;
  };

  const getIcon = (resource_type) => {
    if (resource_type === 'attachment') return <Paperclip />;
    return activityFormat[resource_type] && activityFormat[resource_type].icon();
  };

  const diffToBeShown = item => (
    item.resource_type === 'task' &&
    (item.column === 'description' || item.column === 'recommended_action')
  );

  return (
    <div style={{ marginTop: '1rem' }}>
      <LabelContainer>
        <Label>Task Activity</Label>
      </LabelContainer>
      {loading && (
        <NoDataContainer><LoadingSvg /></NoDataContainer>
      )}
      {!loading && (
        _.isEmpty(items) ? (
          <NoDataContainer>No activities to show</NoDataContainer>
        ) : (
          items.map((item, idx) => (
            <TaskItemContainer
              key={idx}
              clickable={isItemClickable(item)}
            >
              <IconContainer paddingRight="1rem">
                {getIcon(item.resource_type)}
              </IconContainer>
              <FlexContainer
                direction="column"
                flexGrow={1}
                onClick={() => { if (isItemClickable(item)) onItemClickHandler(item); }}
              >
                <Text color={item.deleted ? colors.greyD : colors.black}>
                  {formHistorySentence(item, getItemField)}
                  {diffToBeShown(item) && (
                    <DiffViewer marginLeft="5px" oldValue={item.old_value} newValue={item.new_value} />
                  )}
                </Text>
                <MetadataContainer fontSize="12px">
                  <SecondaryText>{formatDate(item.timestamp)}</SecondaryText>
                  {item.deleted && (
                    <SecondaryText color={colors.redL}>{`${humanize(item.resource_type)} Deleted`}</SecondaryText>
                  )}
                </MetadataContainer>
                {getItemField(item, item.column) && (
                  <SecondaryText color={colors.black} marginTop="6px">
                    {getItemField(item, item.column)}
                  </SecondaryText>
                )}
                {item.resource_type === 'attachment' && (
                  <Attachments
                    originType="task"
                    originId={taskId}
                    attachments={item.attachments}
                    showExistingAtts
                    showBrowseButton={false}
                    allowUpload={false}
                    disableCarousel
                    showSelectedFiles
                  />
                )}
              </FlexContainer>
              {isItemClickable(item) && item.op === 'ADD' && (
                <IconContainer>
                  <span
                    onClick={() => onClickDelete(item.resource_type, item.resource_id)}
                    title={`Delete ${humanize(item.resource_type)}`}
                  >
                    <DeleteIconSvg />
                  </span>
                </IconContainer>
              )}
            </TaskItemContainer>
          ))
        )
      )}
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  deleteNote: bindActionCreators(deleteNote, dispatch),
  deleteRepair: bindActionCreators(deleteRepair, dispatch)
});

TaskHistory.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  taskId: PropTypes.number.isRequired,
  setRepairModalOpen: PropTypes.func,
  setNoteModalOpen: PropTypes.func,
  notes: PropTypes.array,
  repairs: PropTypes.array
};

TaskHistory.defaultProps = {
  setRepairModalOpen: () => {},
  setNoteModalOpen: () => {},
  notes: [],
  repairs: []
};

export default connect(null, mapDispatchToProps)(TaskHistory);
