import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NavButton from '../atoms/NavButton';

const SplashContainer = styled.div`
  max-width: 750px;
  text-align: center;
  margin: 0 auto;
  img {
    width: 360px;
    height: auto;
    margin-bottom: 60px;
  }
  span:first-of-type {
    margin-bottom: 16px;
  }
  span:last-of-type {
    margin-bottom: 48px;
  }
`;

const SplashHeader = styled.span`
  color: ${props => props.theme.colors.greyXXD};
  font-weight: 600;
  line-height: 33px;
  font-size: 24px;
  display: block;
  & > span {
    color: ${props => props.theme.primaryColor};
  }
`;

const SplashSubheader = styled.span`
  color: ${props => props.theme.colors.greyXXD};
  font-family: "Petasense Open Sans";
  font-weight: 600;
  line-height: 19px;
  font-size: 14px;
  display: block;
  & > span {
    color: ${props => props.theme.primaryColor};
  }
`;

const SplashScreen = ({
  click,
  linkText,
  image,
  heading,
  children,
  showLinkButton
}) => (
  <SplashContainer>
    <img src={image} />
    <SplashHeader>
      {typeof heading === 'function' ? heading() : heading}
    </SplashHeader>
    <SplashSubheader>
      {children}
    </SplashSubheader>
    <div>
      {!showLinkButton && <NavButton onClick={click}>{linkText}</NavButton>}
    </div>

  </SplashContainer>
);

SplashScreen.propTypes = {
  heading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Element),
  ]).isRequired,
  click: PropTypes.func.isRequired,
  linkText: PropTypes.string,
  showLinkButton: PropTypes.bool
};

SplashScreen.defaultProps = {
  linkText: 'Submit',
  showLinkButton: false
};

export default styled(SplashScreen)``;
