import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typeahead as ReactTypeahead } from 'react-typeahead';
import { InputStyles } from './Input';


const StyledTypeahead = styled(ReactTypeahead)`
  width: 100%;
  position: relative;
  color: ${props => props.theme.colors.black};
  input {
    ${InputStyles}
  }
  ul {
    position: absolute;
    top: 30px;
    padding: 0;
    padding-top: 1em;
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    list-style-type: none;
    cursor: pointer;
    border: 1px solid ${props => props.theme.colors.borderActive};
    border-radius: ${props => props.theme.utils.borderRadius};
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 3;
    li {
      line-height: ${props => props.theme.utils.inputLineHeight};
      padding: ${props => props.theme.utils.inputPadding};
      ${props => props.theme.fonts.input}
      &:hover {
        background-color: ${props => props.theme.colors.backgroundGray};
      }
      a {
        color: ${props => props.theme.colors.black};
      }
    }
  }
`;

class Typeahead extends Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.typeahead.setState({ entryValue: nextProps.value });
    }
  }

  render() {
    const {
      value,
      disabled,
      options,
      onChange,
      onBlur,
      placeholder,
      error
    } = this.props;
    return (
      <StyledTypeahead
        innerRef={(inputField) => { this.typeahead = inputField; }}
        value={value}
        disabled={disabled}
        options={options}
        maxVisible={5}
        onChange={onChange}
        error={error}
        onBlur={(e) => {
          this.typeahead.setState({ showResults: false });
          this.typeahead.refs.entry.blur();
          onBlur(e);
        }}
        onOptionSelected={() => {
          this.typeahead.refs.entry.blur();
        }}
        placeholder={placeholder}
      />
    );
  }
}

Typeahead.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string
};

Typeahead.defaultProps = {
  value: '',
  error: '',
  disabled: false,
  options: [],
  onChange: () => {},
  onBlur: () => {},
};

Typeahead.displayName = 'Typeahead';

export default Typeahead;
