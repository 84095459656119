import { combineReducers } from 'redux';

import { APIAuditConstants } from './constants';

export const apiAuditMetadata = (state = {}, action) => {
  switch (action.type) {
    case APIAuditConstants.GET_API_AUDIT_METADATA_REQUEST:
      return {
        loading: true
      };

    case APIAuditConstants.GET_API_AUDIT_METADATA_SUCCESS:
      return {
        metadata: action.payload
      };

    case APIAuditConstants.GET_API_AUDIT_METADATA_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
};

export const apiAuditLogs = (state = {}, action) => {
  switch (action.type) {
    case APIAuditConstants.GET_API_AUDIT_LOGS_REQUEST: {
      const { page } = action.params;
      return {
        ...state,
        items: page === 1 ? [] : state.items,
        loading: true
      };
    }

    case APIAuditConstants.GET_API_AUDIT_LOGS_SUCCESS: {
      const { items, page } = action.payload;
      return {
        ...action.payload,
        items: page === 1 ? items : state.items.concat(items),
      };
    }

    case APIAuditConstants.GET_API_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state;
  }
};

export const apiAuditReducer = combineReducers({
  apiAuditMetadata,
  apiAuditLogs
});
