import colors from 'common/styles/colors';

export const machineDetailsConstants = {
  GET_MACHINE_DETAILS_REQUEST: 'GET_MACHINE_DETAILS_REQUEST',
  GET_MACHINE_DETAILS_SUCCESS: 'GET_MACHINE_DETAILS_SUCCESS',
  GET_MACHINE_DETAILS_FAILURE: 'GET_MACHINE_DETAILS_FAILURE',

  UPDATE_MACHINE_NAME: 'UPDATE_MACHINE_NAME',

  UPDATE_MACHINE_DETAILS_REQUEST: 'UPDATE_MACHINE_DETAILS_REQUEST',
  UPDATE_MACHINE_DETAILS_SUCCESS: 'UPDATE_MACHINE_DETAILS_SUCCESS',
  UPDATE_MACHINE_DETAILS_FAILURE: 'UPDATE_MACHINE_DETAILS_FAILURE',

  GET_MACHINE_COMPLETION_STATUS_REQUEST: 'GET_MACHINE_COMPLETION_STATUS_REQUEST',
  GET_MACHINE_COMPLETION_STATUS_SUCCESS: 'GET_MACHINE_COMPLETION_STATUS_SUCCESS',
  GET_MACHINE_COMPLETION_STATUS_FAILURE: 'GET_MACHINE_COMPLETION_STATUS_FAILURE',

  GET_MACHINE_DETAILS_CHARTS_TAGS_REQUEST: 'GET_MACHINE_DETAILS_CHARTS_TAGS_REQUEST',
  GET_MACHINE_DETAILS_CHARTS_TAGS_SUCCESS: 'GET_MACHINE_DETAILS_CHARTS_TAGS_SUCCESS',
  GET_MACHINE_DETAILS_CHARTS_TAGS_FAILURE: 'GET_MACHINE_DETAILS_CHARTS_TAGS_FAILURE',

  SWAP_MACHINE_DETAILS_CHARTS_TAGS: 'SWAP_MACHINE_DETAILS_CHARTS_TAGS',

  ADD_NOTE_REQUEST: 'ADD_NOTE_REQUEST',
  ADD_NOTE_SUCCESS: 'ADD_NOTE_SUCCESS',
  ADD_NOTE_FAILURE: 'ADD_NOTE_FAILURE',

  GET_MACHINE_ATTACHMENTS: 'GET_MACHINE_ATTACHMENTS',
  GET_MACHINE_ATTACHMENTS_SUCCESS: 'GET_MACHINE_ATTACHMENTS_SUCCESS',
  GET_MACHINE_ATTACHMENTS_FAILURE: 'GET_MACHINE_ATTACHMENTS_FAILURE',

  MARK_AS_ANALYSED_REQUEST: 'MARK_AS_ANALYSED_REQUEST',
  MARK_AS_ANALYSED_SUCCESS: 'MARK_AS_ANALYSED_SUCCESS',
  MARK_AS_ANALYSED_FAILURE: 'MARK_AS_ANALYSED_FAILURE',

  tagChart: {
    TREND_REQUEST: 'MACHINE_DETAILS_TAG_CHART/TREND_REQUEST',
    TREND_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/TREND_SUCCESS',
    TREND_FAILURE: 'MACHINE_DETAILS_TAG_CHART/TREND_FAILURE',

    WAVEFORM_REQUEST: 'MACHINE_DETAILS_TAG_CHART/WAVEFORM_REQUEST',
    WAVEFORM_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/WAVEFORM_SUCCESS',
    WAVEFORM_FAILURE: 'MACHINE_DETAILS_TAG_CHART/WAVEFORM_FAILURE',

    SPECTRUM_REQUEST: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_REQUEST',
    SPECTRUM_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_SUCCESS',
    SPECTRUM_FAILURE: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FAILURE',

    spectrumFeature: {
      GET_FEATURES_REQUEST: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/GET_FEATURES_REQUEST',
      GET_FEATURES_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/GET_FEATURES_SUCCESS',
      GET_FEATURES_FAILURE: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/GET_FEATURES_FAILURE',

      CREATE_FEATURE_REQUEST: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/CREATE_FEATURE_REQUEST',
      CREATE_FEATURE_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/CREATE_FEATURE_SUCCESS',
      CREATE_FEATURE_FAILURE: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/CREATE_FEATURE_FAILURE',

      UPDATE_FEATURE_REQUEST: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/UPDATE_FEATURE_REQUEST',
      UPDATE_FEATURE_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/UPDATE_FEATURE_SUCCESS',
      UPDATE_FEATURE_FAILURE: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/UPDATE_FEATURE_FAILURE',

      DELETE_FEATURE_REQUEST: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/DELETE_FEATURE_REQUEST',
      DELETE_FEATURE_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/DELETE_FEATURE_SUCCESS',
      DELETE_FEATURE_FAILURE: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/DELETE_FEATURE_FAILURE',
    },

    SPECTRUM_SHOW_FEATURE_MODAL: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_SHOW_FEATURE_MODAL',
    SPECTRUM_HIDE_FEATURE_MODAL: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_HIDE_FEATURE_MODAL',
  },

  CLEAR_MACHINE_DETAILS_TAGS: 'CLEAR_MACHINE_DETAILS_TAGS',
};

export const ASSET_SETUP_CHART_COLORS = [colors.green, colors.greyXXL];
