import React from 'react';
import PropTypes from 'prop-types';

const NoteSvg = ({ width, height, fill }) => (
  <svg width="18px" height="18px" viewBox="0 0 18 18">
    <g id="AH_v1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2-4-0-ah1-trend" transform="translate(-392.000000, -75.000000)">
            <g id="options" transform="translate(320.000000, 64.000000)">
                <g id="add_note-pressed-icon" transform="translate(73.000000, 12.000000)">
                    <rect id="bound" fill="#FFFFFF" x="0" y="0" width={width} height={height} />
                    <g id="notes-add" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                        <circle id="Oval" cx="11.5" cy="11.5" r="4" />
                        <path d="M11.5,9.5 L11.5,13.5" id="Path" />
                        <path d="M9.5,11.5 L13.5,11.5" id="Path" />
                        <path d="M5.5,13.5 L1.5,13.5 C1.23279876,13.5066554 0.97455957,13.4034372 0.785561164,13.2144388 C0.596562758,13.0254404 0.493344566,12.7672012 0.5,12.5 L0.5,2.57133333 C0.481261367,1.99971016 0.928441983,1.52063066 1.5,1.5 L11.5,1.5 C12.071558,1.52063066 12.5187386,1.99971016 12.5,2.57133333 L12.5,5.5" id="Path" />
                        <path d="M3.5,0.5 L3.5,3.5" id="Path" />
                        <path d="M6.5,0.5 L6.5,3.5" id="Path" />
                        <path d="M9.5,0.5 L9.5,3.5" id="Path" />
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
);

NoteSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

NoteSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: '#000000'
};


export default NoteSvg;
