import _ from 'lodash';
import moment from 'moment-timezone';
import { toastr } from 'react-redux-toastr';

import { axiosInstance, ENDPOINT } from '../../../common/constants';
import { handleResponse } from '../../../common/helpers';
import assetDetailsConstants from '../constants/assetHierarchy.constants';
import * as breadcrumbActions from '../../Menu/actions/breadcrumb.actions';
import { formatChartData, convertTimestringsToDateObjects } from '../../../common/utils';
import { getSelectedPathFromBreadcrumb, getConfigList } from '../utils/assetHierarchyUtils';
import * as hierarchyActions from './hierarchy.actions';
import { selectAccount } from '../../../onboarding/user.actions';

export const selectParent = (siteId, machineId, componentId, areaId, configId) => (dispatch) => {
  dispatch({
    type: assetDetailsConstants.UPDATE_SELECTED_MACHINE_AND_COMPONENT,
    machine_id: machineId,
    component_id: componentId,
    site_id: siteId,
    area_id: areaId,
    config_id: configId
  });
};

export const getHierarchy = (reload = true) => {
  const request = () => ({ type: assetDetailsConstants.GET_ASSET_HIERARCHY_REQUEST });
  const success = (data, prevPath) => ({ type: assetDetailsConstants.GET_ASSET_HIERARCHY_SUCCESS, data, reload, prevPath });
  const failure = error => ({ type: assetDetailsConstants.GET_ASSET_HIERARCHY_FAILURE, error });

  return (dispatch, getState) => {
    const path = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    if (reload) dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_ACCOUNT_HIERARCHY)).then(
      (res) => {
        if (!path.site_id || path.site_id !== res.id) {
          const params = {
            site: {
              name: res.name,
              id: res.id
            },
            selected: {
              name: res.name,
              id: res.id,
              type: res.type
            }
          };
          dispatch(breadcrumbActions.updateBreadcrumb(params));
        }
        dispatch(success(res, path));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        throw error;
      }
    );
  };
};

export const updateAreas = (params, last) => dispatch =>
  handleResponse(axiosInstance.patch(ENDPOINT.UPDATE_AREAS, params)).then(
    (res) => {
      if (last) {
        toastr.success(res.message);
        dispatch(getHierarchy(false));
      }
    },
    (error) => {
      toastr.error(error.message);
      throw error;
    }
  );

export const updateMachines = (params, last) => dispatch =>
  handleResponse(axiosInstance.patch(ENDPOINT.UPDATE_MACHINES, params)).then(
    (res) => {
      if (last) {
        toastr.success(res.message);
        dispatch(getHierarchy(false));
      }
    },
    (error) => {
      toastr.error(error.message);
      throw error;
    }
  );


export const getConfigs = (machine_id, site_id) => {
  const request = () => ({ type: assetDetailsConstants.GET_CHARTS_CONFIG_REQUEST, machine_id, site_id });
  const success = configs => ({ type: assetDetailsConstants.GET_CHARTS_CONFIG_SUCCESS, machine_id, site_id, configs });
  const failure = error => ({ type: assetDetailsConstants.GET_CHARTS_CONFIG_FAILURE, machine_id, site_id, error });

  return (dispatch) => {
    const params = { machine_id };
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.CHARTS_CONFIG_LIST, { params })).then(
      (res) => {
        const configs = res.configurations;
        dispatch(success(configs));
        return configs;
      },
      (error) => {
        dispatch(failure(error));
      },
    );
  };
};

export const validateAndSwitchAccountOnConfig = config_id => (dispatch, getState) =>
  handleResponse(axiosInstance.get(ENDPOINT.CHARTS_CONFIG(config_id), {})).then(
    (res) => {
      const user = getState().user.user;
      if (user.account_id !== res.account_id) {
        dispatch(selectAccount(res.account_id, false)).then(
          dispatch(getHierarchy(true)).then(() => {
            dispatch(hierarchyActions.selectNode(config_id, 'config'));
          })
        );
      }
    },
    (err) => {
      toastr.error(err.message);
    }
  );

export const validateAndSwitchAccountWithCallback = (account_id, callBackFn) => (dispatch, getState) => {
  const user = getState().user.user;
  if (user.account_id !== account_id) {
    dispatch(selectAccount(account_id, false)).then(
      dispatch(getHierarchy(true)).then(() => {
        callBackFn();
      })
    );
  } else {
    callBackFn();
  }
};

export const validateAndSwitchAccountOnTagId = (account_id, callBackFn) => (dispatch, getState) => {
  const user = getState().user.user;
  if (user.account_id !== account_id) {
    dispatch(selectAccount(account_id, false)).then(() => {
      dispatch(getHierarchy(true)).then(() => {
        callBackFn();
      });
    });
  }
};

export const validateAndSwitchAccountOnLocation = location_id => (dispatch, getState) =>
  handleResponse(axiosInstance.get(ENDPOINT.LOCATION(location_id), {})).then(
    (res) => {
      const user = getState().user.user;
      if (user.account_id !== res.account_id) {
        dispatch(selectAccount(res.account_id, false)).then(
          dispatch(getHierarchy(true)).then(() => {
            dispatch(hierarchyActions.selectNode(location_id, 'location'));
          })
        );
      }
    },
    (err) => {
      toastr.error(err.message);
    }
  );

export const getTags = (machine_id, site_id, store_in_redux = true) => {
  const request = () => ({ type: assetDetailsConstants.GET_MACHINE_CHARTS_TAGS_REQUEST, machine_id, site_id });
  const success = tags => ({ type: assetDetailsConstants.GET_MACHINE_CHARTS_TAGS_SUCCESS, tags, machine_id, site_id });
  const failure = error => ({ type: assetDetailsConstants.GET_MACHINE_CHARTS_TAGS_FAILURE, error, machine_id, site_id });

  return (dispatch) => {
    if (store_in_redux) dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_TAGS(machine_id))).then(
      (res) => {
        if (store_in_redux) dispatch(success(res));
        return res;
      },
      (error) => {
        if (store_in_redux)dispatch(failure(error));
        if (error && error.data) {
          toastr.error(error.data.message);
        }
        throw error;
      }
    );
  };
};

export const getChartsData = (params, pagination = false, noOfRequests = null, uniqueKey = null) => {
  const config_id = params.config_id;
  const request = (site_id, machine_id) => ({ type: assetDetailsConstants.GET_CHARTS_DATA_REQUEST, config_id, site_id, machine_id, pagination, uniqueKey });
  const success = (data, site_id, machine_id, time_range) => ({ type: assetDetailsConstants.GET_CHARTS_DATA_SUCCESS, config_id, data, site_id, machine_id, pagination, noOfRequests, uniqueKey, time_range });
  const failure = (error, site_id, machine_id) => ({ type: assetDetailsConstants.GET_CHARTS_DATA_FAILURE, config_id, error, site_id, machine_id });

  return (dispatch, getState) => {
    const assetDetails = getState().assetHierarchyReducer;
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    const { sites } = assetDetails.assetInfo;
    if (!params.trends_config) dispatch(request(site_id, machine_id));
    params.discard_outliers = !getChartSettingsFromSession().showOutliers;
    params.version = 2;

    return handleResponse(axiosInstance.get(ENDPOINT.GET_CHARTS_DATA, { params })).then(
      (res) => {
        const data = res.response.map(d => ({
          ...d,
          trend_data: formatChartData(
            d.trend_data.data,
            convertTimestringsToDateObjects(d.trend_data.time),
            d.trend_data.outliers ? convertTimestringsToDateObjects(d.trend_data.outliers) : null,
            { measurement_id: d.trend_data.measurement_id, speed: d.trend_data.speed, on_off_reading: d.trend_data.on_off_reading }
          ),
          days: params.days
        }));
        // order data to match the order of trends_config
        // TODO: this is not suitable as a unique identifier, there can be duplicates

        let time_range;
        if (res.time_range) {
          time_range = [
            moment(res.time_range.start_timestamp),
            moment(res.time_range.end_timestamp)
          ];
        }

        const configItems = getConfigList(machine_id, site_id, sites);

        if (!params.config_id && ((configItems && configItems.length > 0) || params.trends_config)) {
          let configItem = _.find(configItems, item => item.config_id === config_id);
          if (!configItem && params.trends_config) configItem = { trends_config: params.trends_config };
          if (configItem !== undefined) {
            const orderedData = configItem.trends_config.map(config =>
              _.find(data, (d) => {
                let sameObject = true;
                if (config.tag_id) {
                  sameObject = d.tag_id === config.tag_id;
                }
                if (config.tag_type) {
                  sameObject = sameObject && d.tag_type === config.tag_type;
                }
                if (config.feature) {
                  sameObject = sameObject && d.feature === config.feature;
                }
                if (config.amp_type) {
                  sameObject = sameObject && d.amp_type === config.amp_type;
                }
                if (config.color) {
                  sameObject = sameObject && d.color === config.color;
                }
                return sameObject;
              })
            );
            // FIXME: undefined is found if two tags with same tag_id is present.
            const tempFix = orderedData.filter(d => d !== undefined);
            if (!params.trends_config) dispatch(success(tempFix, site_id, machine_id, time_range));
            return tempFix;
          }
        } else {
          const tempFix = data.filter(d => d !== undefined);
          dispatch(success(tempFix, site_id, machine_id, time_range));
        }
        return data;
      },
      (error) => {
        dispatch(failure(error, site_id, machine_id));
        toastr.error(error.message);
      }
    );
  };
};

export const chartsFilters = filters => ({ type: assetDetailsConstants.CHARTS_FILTERS, filters });

export const getTagWaveform = (tagId, params, configId) => {
  const request = (machine_id, site_id) => ({ type: assetDetailsConstants.GET_TAG_WAVEFORM_REQUEST, configId, machine_id, site_id });
  const success = (waveform, machine_id, site_id) => ({
    type: assetDetailsConstants.GET_TAG_WAVEFORM_SUCCESS,
    configId,
    waveform,
    machine_id,
    site_id
  });
  const failure = (error, machine_id, site_id) => ({
    type: assetDetailsConstants.GET_TAG_WAVEFORM_FAILURE,
    configId,
    error,
    machine_id,
    site_id
  });

  params.timestamp = moment(params.timestamp).toISOString();

  return (dispatch, getState) => {
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    dispatch(request(machine_id, site_id));

    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_WAVEFORM(tagId), { params })).then(
      (res) => {
        const waveform = {
          ...res,
          waveform_data: formatChartData(
            res.waveform_data,
            _.map(res.waveform_data, (d, i) => (1000 / res.sampling_rate) * i)
          )
        };
        dispatch(success(waveform, machine_id, site_id));
        return waveform;
      },
      (error) => {
        dispatch(failure(error, machine_id, site_id));
        return error;
      }
    );
  };
};

export const getTagSpectrum = (tagId, params, configId = null, isMultiline = null) => {
  const { feature, amp_type } = params;
  const request = (machine_id, site_id) => ({ type: assetDetailsConstants.GET_TAG_SPECTRUM_REQUEST, configId, machine_id, site_id, isMultiline, tagId, feature });
  const success = (spectrum, machine_id, site_id) => ({
    type: assetDetailsConstants.GET_TAG_SPECTRUM_SUCCESS,
    configId,
    spectrum,
    machine_id,
    site_id,
    tagId,
    isMultiline
  });
  const failure = (error, machine_id, site_id) => ({
    type: assetDetailsConstants.GET_TAG_SPECTRUM_FAILURE,
    configId,
    error,
    machine_id,
    site_id
  });

  params.timestamp = moment(params.timestamp).toISOString();

  return (dispatch, getState) => {
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    if (configId) dispatch(request(machine_id, site_id));
    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUM(tagId), { params })).then(
      (res) => {
        const spectrum = {
          ...res,
          spectrum_data: formatChartData(res.spectrum_data.mag, res.spectrum_data.freq),
          tag_id: tagId,
          feature,
          amp_type
        };
        if (configId) dispatch(success(spectrum, machine_id, site_id));
        return spectrum;
      },
      (error) => {
        dispatch(failure(error, machine_id, site_id));
        return error;
      }
    );
  };
};

export const getTagCepstrum = (tagId, params, configId = null) => {
  const request = (machine_id, site_id) => ({ type: assetDetailsConstants.GET_TAG_CEPSTRUM_REQUEST, configId, machine_id, site_id });
  const success = (cepstrum, machine_id, site_id) => ({
    type: assetDetailsConstants.GET_TAG_CEPSTRUM_SUCCESS,
    configId,
    cepstrum,
    machine_id,
    site_id
  });
  const failure = (error, machine_id, site_id) => ({
    type: assetDetailsConstants.GET_TAG_CEPSTRUM_FAILURE,
    configId,
    error,
    machine_id,
    site_id
  });

  params.timestamp = moment(params.timestamp).toISOString();

  return (dispatch, getState) => {
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);

    if (configId) dispatch(request(machine_id, site_id));

    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_CEPSTRUM(tagId), { params })).then(
      (res) => {
        const cepstrum = {
          ...res,
          cepstrum_data: formatChartData(
            res.ceps,
            res.quefrency
          )
        };
        if (configId) dispatch(success(cepstrum, machine_id, site_id));
        return cepstrum;
      },
      (error) => {
        dispatch(failure(error, machine_id, site_id));
      }
    );
  };
};

export const getChartsConfigMetadata = () => {
  const request = () => ({ type: assetDetailsConstants.GET_HIERARCHY_CHARTS_CONFIG_METADATA_REQUEST });
  const success = metadata => ({
    type: assetDetailsConstants.GET_HIERARCHY_CHARTS_CONFIG_METADATA_SUCCESS,
    metadata
  });
  const failure = error => ({ type: assetDetailsConstants.GET_HIERARCHY_CHARTS_CONFIG_METADATA_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.CHARTS_CONFIG_METADATA)).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

export const addNote = (machineId, noteParams) => {
  const params = noteParams;
  params.machine_id = machineId;
  params.date = moment(params.date).format('YYYY-MM-DD');
  return () => handleResponse(axiosInstance.post(ENDPOINT.ADD_NOTE, { ...params })).then(
    () => {},
    (error) => {
      throw error;
    },
  );
};

export const getTagWaterfallSpectrum = (tagId, params, configId) => {
  const request = (machine_id, site_id) => ({
    type: assetDetailsConstants.GET_TAG_WATERFALL_SPECTRUM_REQUEST,
    configId,
    machine_id,
    site_id
  });
  const success = (data, machine_id, site_id) => ({
    type: assetDetailsConstants.GET_TAG_WATERFALL_SPECTRUM_SUCCESS,
    configId,
    data,
    machine_id,
    site_id
  });
  const failure = (error, machine_id, site_id) => ({
    type: assetDetailsConstants.GET_TAG_WATERFALL_SPECTRUM_FAILURE,
    configId,
    error,
    machine_id,
    site_id
  });

  return (dispatch, getState) => {
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);

    dispatch(request(machine_id, site_id));
    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUMS(tagId), { params })).then(
      (res) => {
        const data = {
          ...res,
          items: res.items.map((item) => {
            const spectrum_data = formatChartData(item.spectrum_data.mag, item.spectrum_data.freq);
            return {
              ...item,
              spectrum_data
            };
          })
        };
        dispatch(success(data, machine_id, site_id));
        return (data);
      },
      (error) => {
        dispatch(failure(error, machine_id, site_id));
        return (error);
      }
    );
  };
};

export const getTagDemodSpectrum = (tagId, params, configId) => {
  const request = (machine_id, site_id) => ({
    type: assetDetailsConstants.GET_TAG_DEMOD_SPECTRUM_REQUEST,
    configId,
    machine_id,
    site_id
  });
  const success = (data, machine_id, site_id) => ({
    type: assetDetailsConstants.GET_TAG_DEMOD_SPECTRUM_SUCCESS,
    configId,
    data,
    machine_id,
    site_id
  });
  const failure = (error, machine_id, site_id) => ({
    type: assetDetailsConstants.GET_TAG_DEMOD_SPECTRUM_FAILURE,
    configId,
    error,
    machine_id,
    site_id
  });

  params.timestamp = moment(params.timestamp).toISOString();
  return (dispatch, getState) => {
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);

    dispatch(request(machine_id, site_id));
    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUM(tagId), { params })).then(
      (res) => {
        const data = {
          ...res,
          spectrum_data: formatChartData(res.spectrum_data.mag, res.spectrum_data.freq)
        };
        dispatch(success(data, machine_id, site_id));
      },
      (error) => {
        dispatch(failure(error, machine_id, site_id));
      }
    );
  };
};

export const getDataOverview = (machineId) => {
  const request = () => ({ type: assetDetailsConstants.GET_ASSET_DATA_OVERVIEW_REQUEST });
  const success = res => ({
    type: assetDetailsConstants.GET_ASSET_DATA_OVERVIEW_SUCCESS, res });
  const failure = error => ({ type: assetDetailsConstants.GET_ASSET_DATA_OVERVIEW_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_DATA_OVERVIEW(machineId))).then(
      (res) => {
        dispatch(success(res));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        toastr.error(error.message);
        throw error;
      }
    );
  };
};

export const getChartSettingsFromSession = () => {
  const chartSettings = sessionStorage.getItem('chartSettings');
  return chartSettings ? JSON.parse(chartSettings) : {};
};

export const toggleShowSettingInSession = (setting) => {
  let chartSettings = getChartSettingsFromSession();
  chartSettings = {
    ...chartSettings,
    [setting]: !chartSettings[setting]
  };
  sessionStorage.setItem('chartSettings', JSON.stringify(chartSettings));
};
