import styled from 'styled-components';
import PropTypes from 'prop-types';

const ListItem = styled.tr`
  ${props => props.showBorderBottom &&
    `border-bottom: 1px solid ${props.theme.colors.borderGray};`}
  ${props => props.theme.fonts.tableRow};
  td {
    padding: 1rem 1rem 1rem 0;
    &:first-of-type {
      padding-left: 1.5rem;
    }
    ${props => props.tdPadding && `padding: ${props.tdPadding}`}
  }
  a {
    color: ${props => props.theme.colors.black};
  }
  ${(props) => {
    if (props.selectedItem) {
      return `
        background-color: ${props.theme.backgroundA(0.08)};
      `;
    }
    if (props.editItem) {
      return `
        background-color: #F7F8F6;
        td {
          &:first-of-type input {
            margin-left: 3rem;
          }
        }
      `;
    }
    return '';
  }}
  `;

ListItem.propTypes = {
  selectedItem: PropTypes.bool,
  editItem: PropTypes.bool,
  showBorderBottom: PropTypes.bool
};

ListItem.defaultProps = {
  selectedItem: false,
  editItem: false,
  showBorderBottom: true
};

ListItem.displayName = 'ListItem';

export default ListItem;
