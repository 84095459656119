import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import { getFreqConversionFactor, round } from 'common/utils';
import { connect } from 'react-redux';
import { unitSystemMap } from 'common/constants';
import { ASSET_TYPE } from 'common/charts/constants';
import { X_SCALE } from '../../../constants/envelope.constants';
import * as envelopeUtils from '../../../utils/envelopeUtils';
import TagChart from '../../../../Machines/MachineDetails/MachineCharts/components/TagChart';

class CreateAndDisplaySpectrum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spectrumData: props.spectrum ? _.cloneDeep(props.spectrum.spectrum_data) : null,
      initialXUnit: props.frequencyUnits
    };
  }

  componentDidMount() {
    if (this.props.computeEnvelope) this.createOrUpdateEnvelope();
  }

  componentDidUpdate(prevProps) {
    const { spectrum, isError, tag, envelopeSettings } = this.props;

    if (
      this.props.computeEnvelope &&
      (!_.isEqual(prevProps.tag.envelope_spectrums, tag.envelope_spectrums) ||
      !_.isEqual(prevProps.envelopeSettings, envelopeSettings) ||
      (!_.isEqual(prevProps.isError, isError) && !isError))
    ) {
      if (!isError) this.createOrUpdateEnvelope();
    }

    if (
      spectrum &&
      spectrum.spectrum_data &&
      !_.isEqual(prevProps.spectrum, spectrum)
    ) {
      this.setSpectrumState();
    }

    if (
      spectrum &&
      spectrum.spectrum_data &&
      prevProps.spectrum_data &&
      !_.isEqual(prevProps.spectrum.spectrum_data, spectrum.spectrum_data)
    ) {
      this.setState({
        originalData: spectrum.spectrum_data,
        shaftSpeed: spectrum.shaft_speed
      });
    }
  }

  setSpectrumState = () => {
    const { spectrum, frequencyUnits, spectrumXUnit } = this.props;
    const converionFactor = getFreqConversionFactor(frequencyUnits, spectrumXUnit, spectrum.shaft_speed, frequencyUnits);
    const spectrumData = _.map(spectrum.spectrum_data, s => ({ x: s.x * converionFactor, y: s.y }));
    this.setState({ spectrumData });
  }

  setShaftSpeedState = (shaftSpeed) => {
    this.setState({ shaftSpeed });
  }

  isTagSpectrumLoading = () => {
    const { tag, activeTabValue } = this.props;
    const bin_no = `bin_${activeTabValue}`;
    return tag.envelope_spectrums &&
      tag.envelope_spectrums[bin_no] &&
      tag.envelope_spectrums[bin_no].loading;
  }

  convertSpectrumsXUnit = (spectrums, shaftSpeed) => (
    _.cloneDeep(spectrums).map((sp) => {
      const converionFactor = getFreqConversionFactor('Hz', this.props.spectrumXUnit, shaftSpeed, sp.freq_units);
      if (this.props.spectrumXUnit === 'Orders') sp.fmax = round(sp.fmax / sp.shaft_speed);
      sp.spectrum_data = sp.spectrum_data.map((data) => {
        data.x *= converionFactor;
        return data;
      });
      return sp;
    })
  )

  createOrUpdateEnvelope = () => {
    const { tag, envelopeSettings, user } = this.props;
    if (!envelopeSettings) return;
    const bins = Object.keys(envelopeSettings);
    const allEnvelopeData = {};
    const shaftSpeed = this.props.getShaftSpeed();
    const settings = envelopeUtils.getEnvelopeSettingsInPrefUnits(
      envelopeSettings,
      this.props.spectrumXUnit,
      user.frequency_units,
      shaftSpeed,
      user.frequency_units
    );
    bins.forEach((bin_no) => {
      if (tag.envelope_spectrums &&
          tag.envelope_spectrums[bin_no] &&
          !tag.envelope_spectrums[bin_no].loading
      ) {
        const envelopeData = envelopeUtils.createEnvelope(
          tag.envelope_spectrums[bin_no].data.items,
          settings[bin_no],
          X_SCALE.FREQUENCY,
          user.frequency_units
        );
        allEnvelopeData[bin_no] = envelopeData;
      }
    });
    this.props.updateEnvelopeData(allEnvelopeData, bins);
  }

  toggleSpectrumFrequencyUnits = (unit, spectrumData) => {
    this.setState({
      spectrumData: spectrumData[0]
    });
    this.props.setSpectrumXUnit(unit);
  }

  getEnvelopeXUnit = () => {
    const { spectralEnvelope } = this.props;
    return spectralEnvelope.envelope_data && spectralEnvelope.envelope_data.x_units;
  }

  render() {
    const {
      config,
      ampType,
      chartName,
      disableInitialBrush,
      spectrum,
      tag,
      unitSystem,
      updateEnvelopeData,
      spectralEnvelope,
      measurementTimestamp,
      componentNode,
      assetType,
      spectrumXScale,
      setSpectrumXScale,
      spectrumXUnit,
      disableXaxisToggle,
      user
    } = this.props;

    const { spectrumData, initialXUnit } = this.state;

    let spectrumFeatures;
    if (config.spectrumFeatures && tag && tag.forcingFrequencies) {
      spectrumFeatures = {
        items: config.spectrumFeatures.items.concat(tag.forcingFrequencies.items)
      };
    } else if (config.spectrumFeatures) {
      spectrumFeatures = config.spectrumFeatures;
    } else if (tag && tag.forcingFrequencies) {
      spectrumFeatures = tag.forcingFrequencies;
    }

    const envelopeXUnit = this.getEnvelopeXUnit();

    return (
      <>
        {spectrum && (
          <TagChart
            ampType={ampType}
            analystMode
            chartName={chartName || `tagId-${tag.id}-envelope-spectrum-analyst`}
            contextHeight="25px"
            data={spectrumData}
            color={config.trends_config[0].color}
            componentNode={componentNode}
            measurementTimestamp={measurementTimestamp}
            originalData={this.state.originalData || spectrum.spectrum_data}
            disableInitialBrush={disableInitialBrush}
            expanded
            height="300px"
            initialXunit={initialXUnit}
            loading={spectrum.loading || this.isTagSpectrumLoading()}
            shaftSpeed={this.state.shaftSpeed || spectrum.shaft_speed}
            spectralEnvelope={spectralEnvelope}
            features={spectrumFeatures}
            tagId={tag.id}
            tag_type={tag.type}
            title="spectrum"
            toggleSpectrumFrequencyUnits={this.toggleSpectrumFrequencyUnits}
            setShaftSpeedState={this.setShaftSpeedState}
            type="spectrum"
            unit_system={unitSystem}
            updateEnvelopeData={updateEnvelopeData}
            xAxisType="linear"
            xIsDate={false}
            xTitle="Frequency"
            xUnit={spectrumXUnit}
            yTitle="Amplitude"
            yUnit={spectrum.amp_units || unitSystemMap[unitSystem].units}
            envelopeXUnit={envelopeXUnit}
            spectrum_x_scale={spectrumXScale}
            disableXaxisToggle={assetType === ASSET_TYPE.VFD || disableXaxisToggle}
            showSetHarmonicButtons={false}
            useSpectrumShaftSpeed={assetType === ASSET_TYPE.VFD}
            setSpectrumXScale={setSpectrumXScale}
            shaftSpeedUnit={user.frequency_units}
            assetType={assetType}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user
});

CreateAndDisplaySpectrum.propTypes = {
  ampType: PropTypes.string,
  chartName: PropTypes.string,
  disableInitialBrush: PropTypes.bool,
  envelopeSettings: PropTypes.object,
  frequencyUnits: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  spectralEnvelope: PropTypes.object,
  spectrum: PropTypes.object,
  tag: PropTypes.object.isRequired,
  unitSystem: PropTypes.number.isRequired,
  updateEnvelopeData: PropTypes.func,
  computeEnvelope: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired,
  assetType: PropTypes.string.isRequired
};

CreateAndDisplaySpectrum.defaultProps = {
  ampType: 'velocity',
  chartName: '',
  disableInitialBrush: false,
  envelopeSettings: null,
  isError: false,
  spectrum: null,
  spectralEnvelope: null,
  updateEnvelopeData: () => {}
};

export default connect(mapStateToProps, null)(CreateAndDisplaySpectrum);
