export const attachmentConstants = {
  CHANGE_UPLOAD_PROGRESS: 'CHANGE_UPLOAD_PROGRESS',

  UPLOAD_ATTACHMENT_REQUEST: 'UPLOAD_ATTACHMENT_REQUEST',
  UPLOAD_ATTACHMENT_SUCCESS: 'UPLOAD_ATTACHMENT_SUCCESS',
  UPLOAD_ATTACHMENT_FAILURE: 'UPLOAD_ATTACHMENT_FAILURE',

  DELETE_ATTACHMENT_REQUEST: 'DELETE_ATTACHMENT_REQUEST',
  DELETE_ATTACHMENT_SUCCESS: 'DELETE_ATTACHMENT_SUCCESS',
  DELETE_ATTACHMENT_FAILURE: 'DELETE_ATTACHMENT_FAILURE',

  SET_ATTACHMENT_FILES: 'SET_ATTACHMENT_FILES',
  CLEAR_ATTACHMENT_FILES: 'CLEAR_ATTACHMENT_FILES',
  REMOVE_ATTACHMENT_FILE: 'REMOVE_ATTACHMENT_FILE',

  RESET_UPLOAD: 'RESET_UPLOAD',

  GET_MACHINE_ATTACHMENT_REQUEST: 'GET_MACHINE_ATTACHMENT_REQUEST',
  GET_MACHINE_ATTACHMENT_SUCCESS: 'GET_MACHINE_ATTACHMENT_SUCCESS',
  GET_MACHINE_ATTACHMENT_FAILURE: 'GET_MACHINE_ATTACHMENT_FAILURE',
  GET_MACHINE_ATTACHMENT_FILES: 'GET_MACHINE_ATTACHMENT_FILES' 
};

export const MAX_FILE_SIZE = 5; // in MB
