import React from 'react';
import PropTypes from 'prop-types';

const aggressiveReadingIcon = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Component" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1-0-0-component-aggresive_reading" transform="translate(-634.000000, -238.000000)">
        <g id="sensors_list" transform="translate(320.000000, 224.000000)">
          <g id="1">
            <g id="agg_reading-icon" transform="translate(314.000000, 14.000000)">
              <rect id="icon-bg" x="0" y="0" width="20" height="20" />
              <path d="M13.6206061,3.54700855 C14.4158961,3.54700855 15.0606061,4.19171851 15.0606061,4.98700855 L15.0602727,6.99900855 L14.7432589,7 C14.5556619,7 14.3805316,7.09048608 14.2666103,7.24175392 L14.214976,7.32231547 L10.078521,14.8823442 C9.97238415,15.0789087 9.97394157,15.3195223 10.0826132,15.5145693 C10.1912849,15.7096163 10.3907825,15.829862 10.6068039,15.8305213 L10.6068039,15.8305213 L12.8498931,15.8305213 C12.9370009,15.8309956 13.0183314,15.8761304 13.067475,15.9512695 C13.1166187,16.0264085 13.1273084,16.1219696 13.0960729,16.2069222 L13.0960729,16.2069222 L12.5112727,17.7690085 L6.26727273,17.7692308 C5.96903896,17.7692308 5.72727273,17.5274645 5.72727273,17.2292308 L5.72727273,13.8647863 C5.72727273,13.5665526 5.96903896,13.3247863 6.26727273,13.3247863 L6.57527273,13.3240085 L6.57575758,4.98700855 C6.57575758,4.19171851 7.22046754,3.54700855 8.01575758,3.54700855 L13.6206061,3.54700855 Z" id="Combined-Shape" fill={fill} fillRule="nonzero" />
              <path d="M18.8735732,13.3562633 L12.952696,19.92617 C12.8750644,20.0081254 12.7509075,20.0237354 12.6556625,19.9635153 C12.5604174,19.9032952 12.5203849,19.7838741 12.5598884,19.6778116 L14.4080567,14.9051916 C14.4323509,14.8414772 14.4240368,14.7698064 14.3858139,14.7134521 C14.3475911,14.6570978 14.284334,14.6232467 14.2165835,14.622891 L12.4719586,14.622891 C12.303942,14.6223965 12.1487772,14.5322122 12.0642547,14.385927 C11.9797323,14.2396417 11.978521,14.0591816 12.0610719,13.9117582 L15.2783147,8.24173661 C15.3619453,8.0923712 15.5189745,8 15.6892014,8 L18.0608393,8 C18.2316298,7.99962774 18.3893278,8.09212984 18.4733106,8.24194748 C18.5572933,8.39176512 18.5544882,8.57557792 18.4659736,8.72272398 L16.4937175,12.2444457 C16.457275,12.3083735 16.4574942,12.3870403 16.4942924,12.4507611 C16.5310906,12.5144818 16.5988655,12.5535556 16.6720423,12.5532379 L18.5267848,12.5532379 C18.7154442,12.5532929 18.8860572,12.6662044 18.9610195,12.8406142 C19.0359818,13.015024 19.0009779,13.217625 18.8719296,13.3562633 L18.8735732,13.3562633 Z" id="Path" fill={fill} fillRule="nonzero" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

aggressiveReadingIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

aggressiveReadingIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: '#3C3D3A'
};

export default aggressiveReadingIcon;
