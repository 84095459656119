export const reportActionConstants = {
  UPDATE_REPORTS_LIST: 'UPDATE_REPORTS_LIST',
  SET_REPORT_OPTIONS: 'SET_REPORT_OPTIONS',
  ADD_ASSET_TO_REPORT: 'ADD_ASSET_TO_REPORT',
  SET_ASSET_OVERVIEW_AVAILABLE: 'SET_ASSET_OVERVIEW_AVAILABLE',
  CLEAR_ASSET_OVERVIEW_AVAILABLE: 'CLEAR_ASSET_OVERVIEW_AVAILABLE',
  REMOVE_ASSET_FROM_REPORT: 'REMOVE_ASSET_FROM_REPORT',
  SELECT_ASSET_FOR_CHARTS: 'SELECT_ASSET_FOR_CHARTS',
  ADD_CHART_TO_REPORT: 'ADD_CHART_TO_REPORT',
  ADD_CHART_IMAGE_TO_REPORT: 'ADD_CHART_IMAGE_TO_REPORT',
  REMOVE_CHART_FROM_REPORT: 'REMOVE_CHART_FROM_REPORT',
  ADD_PLANT_SUMMARY_IMAGE_TO_REPORT: 'ADD_PLANT_SUMMARY_IMAGE_TO_REPORT',
  ADD_ASSET_DETAILS_IMAGE_TO_REPORT: 'ADD_ASSET_DETAILS_IMAGE_TO_REPORT',
  ADD_ALL_ASSETS: 'ADD_ALL_ASSETS',
  REMOVE_ALL_ASSETS: 'REMOVE_ALL_ASSETS',
  ADD_ALL_SENSORS: 'ADD_ALL_SENSORS',
  REMOVE_ALL_SENSORS: 'REMOVE_ALL_SENSORS',
  INIT_REPORTS: 'INIT_REPORTS',
  SET_SUBMITTING_REPORT: 'SET_SUBMITTING_REPORT',
  CLEAR_SUBMITTING_REPORT: 'CLEAR_SUBMITTING_REPORT',
  SET_CAPTURING_CHARTS: 'SET_CAPTURING_CHARTS',
  CLEAR_CAPTURING_CHARTS: 'CLEAR_CAPTURING_CHARTS',
  SET_CHARTS_ASSETS_SORTER: 'SET_CHARTS_ASSETS_SORTER',
  SET_REPORTS_SORTER: 'SET_REPORTS_SORTER',
  ADD_TASKS_TO_REPORT: 'ADD_TASKS_TO_REPORT'
};

export const reportOptionConstants = {
  PLANT_HEALTH_HEADING: 'Plant Health Summary',
  PLANT_HEALTH_SUBHEADING: 'Charts and graphs of overall plant health',
  ASSET_HEALTH_HEADING: 'Asset Health Summary',
  ASSET_HEALTH_SUBHEADING: 'A tabular summary of all selected assets',
  INDEPTH_ANALYSIS_HEADING: 'In-depth Asset Analysis',
  INDEPTH_ANALYSIS_SUBHEADING: 'Detailed pages per asset with machine condition, health score and analyst comments',
  CHARTS_HEADING: 'Charts',
  ALL_DEVICES: 'Include All Devices',
  ALL_DEVICES_SUBHEADING: 'Include All Devices in the site, both reachable and unreachable',
  CHARTS_SUBHEADING: 'Selected charts such as trend, spectrum or waveform for each asset',
  DEVICE_HEALTH_HEADING: 'Device Health Summary',
  DEVICE_HEALTH_SUBHEADING: 'Summarized views of each device health such as unreachable or low battery devices',
};

export const reportDocxConstants = {
  REPORT_TITLE: 'Condition Monitoring Report',
  PLANT_HEALTH_HEADING: 'Plant Health Summary',
  ASSET_HEALTH_HEADING: 'Asset Health Summary',
  INDEPTH_ANALYSIS_HEADING: 'Asset Details',
  DEVICE_HEALTH_HEADING: 'Device Health Summary',
};
