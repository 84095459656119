import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  ${props => props.backgroundColor};
  color: ${props => props.active ? props.theme.primaryColor : props.color || props.theme.colors.darkGrey};
  ${props => props.fontWeight ? `font-weight: ${props.fontWeight};` : ''}
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  text-transform: uppercase;
  border-radius: 50%;
`;

const Avatar = ({
  initials,
  size,
  className,
  active,
  backgroundColor,
  fontWeight,
  color
}) => (
  <AvatarContainer
    backgroundColor={backgroundColor}
    active={active}
    className={className}
    size={size}
    fontWeight={fontWeight}
    color={color}
  >
    {initials}
  </AvatarContainer>);


Avatar.propTypes = {
  initials: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  size: PropTypes.number,
  backgroundColor: PropTypes.string
};

Avatar.defaultProps = {
  size: 35,
  backgroundColor: '#eff0ee'
};

export default styled(Avatar)``;
