import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { checkPermissions } from './util';

/*
RBAC (Role based access control): A component used to show different UI
depending upon the user role e.g. Admin, Analyst, Manager, Technician, Petasense Admin etc.
props:
resource: type of resource that user / component is using
  e.g. users, charts etc.
operation: type of operation based on the UI responsible for it
  e.g. create, read, update, delete
yes: returns UI when user has access
no: returns UI when user does not has access

Example 1 -  To show / hide a button whose operation is used to delete a user based on user role
<RBAC
  resource={mapComponentToResource.AccountUsers}
  operation={operations.Delete}
  yes={(
     <Button onClick={this.delete}>Delete</Button>
    )}
/>

In the above example we haven't passed 'no' prop so by default null will be returned and hide the button
Below example is with the use of yes and no prop together

Example 2 -  To show / disable a button whose operation is used to delete a user based on user role
<RBAC
  resource={mapComponentToResource.AccountUsers}
  operation={operations.Delete}
  yes={(
     <Button onClick={this.delete}>Delete</Button>
    )}
  no={(
     <Button disabled>Delete</Button>
    )}
/>

*/
const RBAC = (props) => {
  const { resource, operation } = props;
  const hasAccess = checkPermissions(
    resource,
    props.rbacPermissions.result,
    operation,
    props.user.user
  );
  return hasAccess ? props.yes : props.no;
};

const mapStateToProps = state => ({
  user: state.user,
  rbacPermissions: state.rbacPermissions
});

RBAC.propTypes = {
  resource: PropTypes.string,
  operation: PropTypes.string
};

RBAC.defaultProps = {
  yes: null,
  no: null,
  resource: '',
  operation: ''
};

export default connect(mapStateToProps)(RBAC);
