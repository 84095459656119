export default {
  GET_EVENTS_REQUEST: 'GET_EVENTS_REQUEST',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  POOL_EVENTS_FOR_GROUPING: 'POOL_EVENTS_FOR_GROUPING',
  GET_EVENTS_FAILURE: 'GET_EVENTS_FAILURE',

  GET_EVENT_DETAILS_REQUEST: 'GET_EVENT_DETAILS_REQUEST',
  GET_EVENT_DETAILS_SUCCESS: 'GET_EVENT_DETAILS_SUCCESS',
  GET_EVENT_DETAILS_FAILURE: 'GET_EVENT_DETAILS_FAILURE',

  POST_ACTIVITY_COMMENT_REQUEST: 'POST_ACTIVITY_COMMENT_REQUEST',
  POST_ACTIVITY_COMMENT_SUCCESS: 'POST_ACTIVITY_COMMENT_SUCCESS',
  POST_ACTIVITY_COMMENT_FAILURE: 'POST_ACTIVITY_COMMENT_FAILURE',

  CLOSE_EVENT_REQUEST: 'CLOSE_EVENT_REQUEST',
  CLOSE_EVENT_SUCCESS: 'CLOSE_EVENT_SUCCESS',
  CLOSE_EVENT_FAILURE: 'CLOSE_EVENT_FAILURE',

  GET_NOTE_DETAILS_REQUEST: 'GET_NOTE_DETAILS_REQUEST',
  GET_NOTE_DETAILS_SUCCESS: 'GET_NOTE_DETAILS_SUCCESS',
  GET_NOTE_DETAILS_FAILURE: 'GET_NOTE_DETAILS_FAILURE',

  DELETE_TASK_REQUEST: 'DELETE_TASK_REQUEST',
  DELETE_TASK_SUCCESS: 'DELETE_TASK_SUCCESS',
  DELETE_TASK_FAILURE: 'DELETE_TASK_FAILURE',

  DELETE_REPAIR_REQUEST: 'DELETE_REPAIR_REQUEST',
  DELETE_REPAIR_SUCCESS: 'DELETE_REPAIR_SUCCESS',
  DELETE_REPAIR_FAILURE: 'DELETE_REPAIR_FAILURE',

  GET_ACTIVITY_COMMENTS_REQUEST: 'GET_ACTIVITY_COMMENTS_REQUEST',
  GET_ACTIVITY_COMMENTS_SUCCESS: 'GET_ACTIVITY_COMMENTS_SUCCESS',
  GET_ACTIVITY_COMMENTS_FAILURE: 'GET_ACTIVITY_COMMENTS_FAILURE',

  PUT_ACTIVITY_COMMENT_REQUEST: 'PUT_ACTIVITY_COMMENT_REQUEST',
  PUT_ACTIVITY_COMMENT_SUCCESS: 'PUT_ACTIVITY_COMMENT_SUCCESS',
  PUT_ACTIVITY_COMMENT_FAILURE: 'PUT_ACTIVITY_COMMENT_FAILURE',

  DELETE_ACTIVITY_COMMENT_REQUEST: 'DELETE_ACTIVITY_COMMENT_REQUEST',
  DELETE_ACTIVITY_COMMENT_SUCCESS: 'DELETE_ACTIVITY_COMMENT_SUCCESS',
  DELETE_ACTIVITY_COMMENT_FAILURE: 'DELETE_ACTIVITY_COMMENT_FAILURE',

  DELETE_NOTE_REQUEST: 'DELETE_NOTE_REQUEST',
  DELETE_NOTE_SUCCESS: 'DELETE_NOTE_SUCCESS',
  DELETE_NOTE_FAILURE: 'DELETE_NOTE_FAILURE',

  UPDATE_ACTIVITY_REQUEST: 'UPDATE_ACTIVITY_REQUEST',
  UPDATE_ACTIVITY_SUCCESS: 'UPDATE_ACTIVITY_SUCCESS',
  UPDATE_ACTIVITY_FAILURE: 'UPDATE_ACTIVITY_FAILURE',

  UPDATE_ACTIVITY_READ_REQUEST: 'UPDATE_ACTIVITY_READ_REQUEST',
  UPDATE_ACTIVITY_READ_SUCCESS: 'UPDATE_ACTIVITY_SUCCESS',
  UPDATE_ACTIVITY_READ_FAILURE: 'UPDATE_ACTIVITY_FAILURE',

  MARK_EVENTS_ANALYZED_REQUEST: 'MARK_EVENTS_ANALYZED_REQUEST',
  MARK_EVENTS_ANALYZED_SUCCESS: 'MARK_EVENTS_ANALYZED_SUCCESS',
  MARK_EVENTS_ANALYZED_FAILURE: 'MARK_EVENTS_ANALYZED_FAILURE',

  NUMBER_OF_EVENTS_PER_PAGE: 50,
};
