import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import RibbonItem from 'common/components/atoms/RibbonItem';
import OutsideAlerter from 'common/OutsideAlerter';
import HeaderContainer from 'home/AssetHierarchy/components/atoms/HeaderContainer';
import FlexContainer from '../../atoms/FlexContainer';

const RibbonsContainer = styled(FlexContainer)`
  height: 52px;
  align-items: center;
  width: max-content;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 10px;
`;

const Dropdown = styled.div`
  display: block;
  position: absolute;
  margin-left: -40px;
  background-color: #f1f1f1;
  border: 0.5px lightgrey;
  border-radius: 4px;
  z-index: 5;
  box-shadow: rgba(60, 61, 58, 0.24) 0px 0px 8px 0px;
  border-radius: 10px;
`;

const ChartTab = ({
  tabItems,
  selectedTabItem,
  toggleShowTabOptions
}) => (
  <>
  {<HeaderContainer height="35px" border direction="column">
    <RibbonsContainer>
      {tabItems.map((item, idx) => {
        if (item.hidden) return null;
        return (
        <FlexContainer onClickCapture={() => { item.onClick(); }} direction="column" justifyContent="space-between" alignItems="center">
          <RibbonItem flexDirection="column" fontWeight="400" key={idx}>{item.text}</RibbonItem>
            {selectedTabItem === item.text && item.dropdown && (
              <OutsideAlerter open handleClick={toggleShowTabOptions} event="click">
                <Dropdown key={item.id}>
                  {item.dropdown.map((i, index) => {
                    if (i.hidden) return null;
                    return (
                    <RibbonItem selected={i.selected} disabled={i.disabled} padding="0.8em 1em" onClick={i.onClick} key={`sidedropdown-${item.id}-idx-${index}`}>
                      <span title={i.title}>{i.text}</span>
                    </RibbonItem>
                    );
                  }
                  )}
                </Dropdown>
              </OutsideAlerter>
            )}
        </FlexContainer>
        );
      })}
    </RibbonsContainer>
    </HeaderContainer>
  }
  </>
);

ChartTab.propTypes = {
  tabItems: PropTypes.array,
  selectedTabItem: PropTypes.string,
  toggleShowTabOptions: PropTypes.func
};

ChartTab.defaultProps = {
  tabItems: [],
  selectedTabItem: '',
  toggleShowTabOptions: () => {}
};

export default ChartTab;
