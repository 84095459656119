import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Dropdown } from 'semantic-ui-react';

import { userActions } from '../../onboarding/user.actions';

import Avatar from '../../common/components/atoms/Avatar';
import * as utils from '../../common/utils';
import SideMenuDropdown, { MenuLink, MenuFakeLink } from './SideMenuDropdown';


const ProfileDropdown = ({ user, userActions, onClick, active }) => (
  <SideMenuDropdown
    title={`${user.user.first_name} ${user.user.last_name.charAt(0)}.`}
    onClick={onClick}
    id="profile-dropdown"
    width="110px"
    trigger={(
    <Avatar
      initials={utils.getInitials(`${user.user.first_name} ${user.user.last_name}`)}
      size={35}
      active={active}
      backgroundColor="white"
      fontWeight={600}
      color="#999B95"
    />)}
    active={active}
  >
    <Dropdown.Item as={MenuLink} to="/profile" name="profile" onClick={onClick}>
      Profile
    </Dropdown.Item>
    <Dropdown.Item as={MenuFakeLink} onClick={() => userActions.logout()}>
      Logout
    </Dropdown.Item>
  </SideMenuDropdown>
);

ProfileDropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);
