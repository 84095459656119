import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FlexContainer } from 'common/components/atoms';
import ListItem from 'common/components/atoms/ListItem';
import Checkbox from 'common/components/atoms/Checkbox';
import { Check } from 'common/images/FaIcons';
import { chartColors } from 'common/constants';

const TagListItem = styled(ListItem)`
   ${props => props.selected && `background-color: ${props.theme.secondaryColor};`}
  td {
    padding: 0.75rem 1rem 0.75rem 0;
    &:first-of-type {
      padding-left: 1rem;
    }
  }
`;

const CheckBoxContainer = styled.div`
  display: inline-flex;
  margin-right: 0.5em;
  vertical-align: middle;
`;

const ColoredCircleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ColoredCircle = styled.div`
  cursor: pointer;
  background-color: ${props => props.color ? props.color : props.theme.colors.black};
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  ${props => props.disabled && `
    border: 5px solid ${props.theme.colors.greyD};
    cursor: not-allowed;
  `}
`;

const CheckContainer = styled.div`
  position: absolute;
  pointer-events: none;
`;

class TagSelectItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemRef = React.createRef();
  }

  getAvailableColor = () => {
    const availableColors = chartColors.filter(color => !this.props.takenColors.includes(color));
    return availableColors[0];
  };

  render() {
    const {
      item,
      columnSizes,
      takenColors,
      changeColor,
      toggleOption,
      defaultChart
    } = this.props;
    return (
      <TagListItem innerRef={this.itemRef} selected={item.selected}>
        <td
          onClick={() => toggleOption(item.value, this.getAvailableColor())}
          width={`${columnSizes[0]}%`}
          style={{ cursor: defaultChart ? 'default' : 'pointer' }}
        >
          <FlexContainer>
            {!defaultChart && (
              <CheckBoxContainer>
                <Checkbox value={item.selected} />
              </CheckBoxContainer>
            )}
            {item.text || '--'}
          </FlexContainer>
        </td>
        <td width={`${columnSizes[1]}%`}>
          <FlexContainer>
            {chartColors.map((color, idx) => (
              <ColoredCircleContainer key={idx}>
                <ColoredCircle
                  disabled={item.color !== color && takenColors.includes(color)}
                  color={color}
                  onClick={() => {
                    if (item.selected) changeColor(item.value, color);
                  }}
                />
                {item.color === color && (
                  <CheckContainer>
                    <Check color="white" />
                  </CheckContainer>)
                }
              </ColoredCircleContainer>
            ))}
          </FlexContainer>
        </td>
      </TagListItem>
    );
  }
}

TagSelectItem.propTypes = {
  defaultChart: PropTypes.bool,
  item: PropTypes.object.isRequired,
  columnSizes: PropTypes.array.isRequired,
  takenColors: PropTypes.array.isRequired,
  changeColor: PropTypes.func.isRequired,
  toggleOption: PropTypes.func.isRequired
};

TagSelectItem.defaultProps = {
  defaultChart: false
};

export default TagSelectItem;
