import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: block !important;
  visibility: visible;
  opacity: 0;
`;

const ButtonElement = styled.label`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 1px solid ${props => props.theme.colors.borderGray};
  ${props => (props.error ? `
    border-color: ${props.theme.colors.error};
  ` : '')}
  border-radius: ${props => props.theme.utils.borderRadius};
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  margin: 0;
  height: 43px;
  width: 6rem;
  z-index: 2;
  &:before, &:after {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    pointer-events: none;
    z-index: 10;
    width: 3rem;
    height: 43px;
    cursor: pointer;
    transition: color 0.5s ease-in-out;
  }
  &:before {
    right: 0;
    content: 'YES';
    color: ${props => (props.checked ? props.theme.colors.lightGray : props.theme.colors.white)}
  }
  &:after {
    content: 'NO';
    color: ${props => (props.checked ? props.theme.colors.white : props.theme.colors.lightGray)}
  }
  ${HiddenCheckbox}:focus {
    + ${ButtonElement} {
      border-color: ${props => props.theme.colors.borderActive};
    }
  }
  ${ButtonElement}:hover {
    border-color: ${props => props.theme.colors.borderGrayHover};
  }
  ${HiddenCheckbox} {
    + ${ButtonElement}:before {
      position: absolute;
      content: '';
      border-radius: ${props => props.theme.utils.borderRadius};
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
    + ${ButtonElement}:after {
      position: absolute;
      content: '';
      border-radius: ${props => props.theme.utils.borderRadius};
      height: 90%;
      width: 2.5rem;
      left: 3.15rem;
      background-color: ${props => props.theme.colors.lightGray};
      transition: left 0.5s ease-in-out;
    }
  }
  ${HiddenCheckbox}:checked {
    + ${ButtonElement}:after {
      left: 0.15rem;
    }
  }
`;

const ToggleButton = ({
  disabled,
  value,
  onChange,
  error
}) => {
  let checkbox;
  return (
    <Container
      checked={value}
      onClick={() => {
        onChange(!checkbox.checked);
      }}
    >
      <HiddenCheckbox disabled={disabled} checked={value} innerRef={(ref) => { checkbox = ref; }} />
      <ButtonElement error={error} />
    </Container>
  );
};

ToggleButton.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string
};

ToggleButton.defaultProps = {
  disabled: false,
  value: false,
  onChange: () => {},
  error: undefined
};

ToggleButton.displayName = 'ToggleButton';

export default ToggleButton;
