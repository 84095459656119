import colors from 'common/styles/colors';

const subscriptionMetadataInfo = [
  {
    remainingDays: 0,
    secondaryColor: colors.white,
    backgroundColor: colors.redL,
  },
  {
    remainingDays: 7,
    secondaryColor: colors.white,
    backgroundColor: colors.redL,
  },
  {
    remainingDays: 14,
    secondaryColor: colors.white,
    backgroundColor: colors.yellowL,
  },
  {
    remainingDays: 30,
    secondaryColor: colors.white,
    backgroundColor: colors.greyXXD,
  }
];

export default subscriptionMetadataInfo;
