import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse } from 'common/helpers';
import * as _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { formatError } from '../../../common/utils';
import FailureModeConstants from '../constants/FailureMode.constants';

export const updateFailureMode = (params) => {
  const id = params.id;
  const request = () => ({ type: FailureModeConstants.UPDATE_FAILURE_MODE_DETAILS_REQUEST });
  const success = data => ({ type: FailureModeConstants.UPDATE_FAILURE_MODE_DETAILS_SUCCESS, data, id });
  const failure = error => ({ type: FailureModeConstants.UPDATE_FAILURE_MODE_DETAILS_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    const { details } = getState().assetFailureMode.failureModes;
    const changedDetails = {};
    Object.entries(params).forEach(([key, value]) => {
      if (!_.isEqual(details[key], value)) changedDetails[key] = value;
    });
    return handleResponse(axiosInstance.patch(ENDPOINT.FAILURE_MODE(id), { ...changedDetails })).then(
      (res) => {
        dispatch(success(changedDetails));
      },
      (error) => {
        toastr.error(formatError(error.message));
        dispatch(failure(error));
      }
    );
  };
};

export const createFaliureMode = (params) => {
  const request = () => ({ type: FailureModeConstants.SAVE_FAILURE_MODE_DETAILS_REQUEST });
  const success = data => ({ type: FailureModeConstants.SAVE_FAILURE_MODE_DETAILS_SUCCESS, data });
  const failure = error => ({ type: FailureModeConstants.SAVE_FAILURE_MODE_DETAILS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.post(ENDPOINT.FAILURE_MODE(), { ...params })).then(
      (res) => {
        dispatch(success({ ...params, id: res.id }));
        return res;
      },
      (error) => {
        toastr.error(formatError(error.message));
        dispatch(failure(error));
        throw error;
      }
    );
  };
};


export const getFailureModeDetails = (failure_mode_id) => {
  const request = () => ({ type: FailureModeConstants.GET_FAILURE_MODE_DETAILS_REQUEST });
  const success = data => ({ type: FailureModeConstants.GET_FAILURE_MODE_DETAILS_SUCCESS, data });
  const failure = error => ({ type: FailureModeConstants.GET_FAILURE_MODE_DETAILS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.FAILURE_MODE(failure_mode_id))).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error.message));
      }
    );
  };
};

export const deleteFailureMode = (id) => {
  const request = () => ({ type: FailureModeConstants.DELETE_FAILURE_MODE_REQUEST });
  const success = data => ({ type: FailureModeConstants.DELETE_FAILURE_MODE_SUCCESS, data, id });
  const failure = error => ({ type: FailureModeConstants.DELETE_FAILURE_MODE_SUCCESS, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.delete(ENDPOINT.FAILURE_MODE(id))).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        toastr.error(formatError(error.message));
        dispatch(failure(error.message));
      }
    );
  };
};

export const getFailureModeList = () => {
  const request = () => ({ type: FailureModeConstants.GET_FAILURE_MODE_LIST_REQUEST });
  const success = data => ({ type: FailureModeConstants.GET_FAILURE_MODE_LIST_SUCCESS, data });
  const failure = error => ({ type: FailureModeConstants.GET_FAILURE_MODE_LIST_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.FAILURE_MODE())).then(
      (res) => {
        dispatch(success(res.items));
        return res.items;
      },
      (error) => {
        dispatch(failure(error.message));
        throw error.message;
      }
    );
  };
};
