import { Component } from 'react';

import { DonutChart as DonutChartHoc } from '../hoc/DonutChart';

class DonutChart extends Component {
  render() {
    return null;
  }
}

DonutChart.displayName = 'DonutChart';

export default DonutChartHoc(DonutChart);
