import React from 'react';
import styled, { withTheme } from 'styled-components';
import { FlexContainer } from '../components/atoms';
import BigNumber from './BigNumber';
import BarChart from './GoogleCharts/BarChart';

const ChartContainer = styled(FlexContainer).attrs({
  alignItems: 'center',
  direction: 'column'
})`
  height: 100%;
`;

const TITLE_HEIGHT = 40;
const Title = styled.div`
  color: ${props => props.theme.colors.darkGray};
  font-weight: 600;
  width: 100%;
  padding: 1em 0 0 1em;
  height: ${TITLE_HEIGHT}px;
`;

function CountBar({ title, data, theme, ...props }) {
  return (
    <ChartContainer>
      <Title className="title">{title}</Title>
      <FlexContainer width="100%" height="100%">
        <div style={{ width: '20%' }}><BigNumber data={data.count} numberPadding={5} numberSize={40} labelSize={14} {...props} /></div>
        <div style={{ width: '80%' }}><BarChart data={data.bar} {...props} /></div>
      </FlexContainer>
    </ChartContainer>
  );
}

export default withTheme(CountBar);
