import * as d3 from 'd3';
import * as _ from 'lodash';
import colors from 'common/styles/colors';

// applies a path element to given node, line drawn based on data and with
// a linear curve (straight lines from point to point)
export const drawDataline = (
  node,
  data,
  xAxis,
  yAxis,
  chartName,
  {
    lineFunc = d3
      .line()
      .curve(d3.curveLinear)
      .x(d => xAxis(d.x))
      .y(d => yAxis(d.y))
  } = {}
) =>
  d3
    .select(node)
    .append('path')
    .datum(data)
    .attr('class', `${chartName}`)
    .attr('d', lineFunc);

export const drawLineOnPos = (node, x1, y1, x2, y2, chartName) =>
  d3
    .select(node)
    .append('line')
    .attr('class', `${chartName} line`)
    .attr('x1', x1)
    .attr('y1', y1)
    .attr('x2', x2)
    .attr('y2', y2);

export const drawCircleOnPos = (node, x, y, r, chartName) =>
  d3
    .select(node)
    .append('circle')
    .attr('class', `${chartName} circle`)
    .attr('cx', x)
    .attr('cy', y)
    .attr('r', r);

export const drawRectOnPos = (node, chartName, height, width, offsetX = 0, offsetY = 0) =>
  d3
    .select(node)
    .append('rect')
    .attr('class', `${chartName} rect`)
    .attr('height', height)
    .attr('width', width)
    .attr('transform', `translate(${offsetX}, ${offsetY})`);

export const addMouseoverEvent = (node, onMouseover) => {
  // mouse event overlay
  d3.select(node)
    .attr('pointer-events', 'all')
    .on('mousemove', onMouseover);
};

export const addMouseClickEvent = (node, onMouseClick) =>
  // mouse event overlay
  d3
    .select(node)
    .attr('pointer-events', 'all')
    .on('click', onMouseClick);

export const addDoubleClickEvent = (node, onDoubleClick) =>
  // mouse event overlay
  d3
    .select(node)
    .attr('pointer-events', 'all')
    .on('dblclick', onDoubleClick);

export const addRightMouseClickEvent = (node, onRightMouseClick) =>
  // mouse event
  d3
    .select(node)
    .attr('pointer-events', 'all')
    .on('contextmenu', () => { d3.event.preventDefault(); onRightMouseClick(); });

// brush element is a selection tool for example in a context graph
// apply bursh event selection to clippath selection and call brushed as a callback
export const addHorizontalBrush = (
  node,
  chartName,
  { brushed = () => {}, brushended = () => {}, brushstarted = () => {}, extent = undefined, onBrushOverlayClick = () => {} } = {}
) => {
  const brush = d3
    .brushX()
    .extent([[0, 0], [node.getBoundingClientRect().width, node.getBoundingClientRect().height]])
    .on('brush', () => brushed())
    .on('end', () => brushended())
    .on('start', () => brushstarted());

  const firstClass = chartName.split(' ')[0];
  d3.select(node)
    .append('g')
    .attr('class', `${chartName} brush`)
    .call(brush)
    .selectAll('.overlay')
    .on(
      'click',
      onBrushOverlayClick,
      true
    );

  if (extent) d3.select(`g.${firstClass}.brush`).call(brush.move, extent);
  return brush;
};

// drag listener on a node
export const addDragHandler = (node, { dragged = () => {}, dragended = () => {} } = {}) => {
  const drag = d3
    .drag()
    .on('drag', dragged)
    .on('end', dragended);

  node.attr('cursor', 'pointer').call(drag);
};

// element to be placed on the top-left corner of the chart as its title
export const drawTitle = (node, title, chartName) =>
  d3
    .select(node)
    .append('text')
    .attr('class', `${chartName} title`)
    .attr('pointer-events', 'none')
    .attr('dx', 10)
    .attr('dy', 35) // 60 - fontsize = 30 when .title fontsize is 30, so the offset from x and y is the same
    .text(title);

// element to be placed vertically on the left side of the chart to show its label
export const drawYAxisLabel = (node, label, chartName, originallabel) => {
  const labelSvg = d3.select(node)
    .append('svg')
    .attr('class', `${chartName} ylabel svg`);

  labelSvg
    .append('title')
    .text(originallabel);

  return labelSvg
    .append('text')
    .attr('class', `${chartName} ylabel`)
    .attr('dx', `-${node.getBoundingClientRect().height / 2}`)
    .attr('dy', -45)
    .attr('text-anchor', 'middle')
    .attr('transform', 'rotate(-90)')
    .attr('pointer-events', 'all')
    .attr('font-weight', '600')
    .text(label);
};

export const drawXAxisLabel = (node, label, chartName) =>
  d3.select(node)
    .append('text')
    .attr('class', `${chartName} xlabel`)
    .attr('dx', `${node.getBoundingClientRect().width / 2}`)
    .attr('dy', `${node.getBoundingClientRect().height + 35}`)
    .attr('text-anchor', 'middle')
    .text(label);


// small tooltip container on position x, y
export const drawTooltip = (node, x, y, text, chartName) => {
  const tooltip = d3
    .select(node)
    .append('g')
    .attr('transform', `translate(${y - 15}, ${x + 10})`)
    .attr('class', `${chartName}-tooltip`);

  // currently only being used in BaseLine, temporary hide

  // tooltip
  //   .append('rect')
  //   .attr('rx', 4)
  //   .attr('ry', 4)
  //   .attr('height', 30)
  //   .attr('width', 10)
  //   .attr('fill', '#333')
  //   .attr('fill-opacity', '0.5')
  //   .attr('pointer-events', 'none');

  tooltip
    .append('text')
    .attr('dx', 10)
    .attr('dy', 20)
    .attr('fill', '#888')
    .text(text);

  const tipRect = d3.select(`.${chartName}-tooltip rect`);
  if (tipRect.parentNode) {
    tipRect.attr('width', () => this.parentNode.getBBox().width + 10);
  }

  return tooltip;
};

export const drawStepGraph = (startX, endX, amps, chartName, node, x, y) => {
  if (!startX || !endX || !amps || _.isEmpty(startX) || _.isEmpty(endX) || _.isEmpty(amps)) return;
  let line = 'M';
  for (let i = 0; i < startX.length; i++) {
    const y0 = y(amps[i]);
    const x0 = x(endX[i]);
    if (i === 0) {
      line += `${x(startX[0])},${y0}H${x0}`;
    } else {
      line += `H${x0}`;
    }
    if (amps[i + 1]) {
      line += `V${y(amps[i + 1])}`;
    }
  }

  d3.select(node)
    .append('path')
    .attr('class', `${chartName} step-graph dataline`)
    .attr('d', line)
    .attr('stroke', colors.alarmCritical)
    .style('stroke-width', '1px');
};
