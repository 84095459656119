import React, {
  Component
} from 'react';
import MachineImg from 'common/images/machineImg.png';
import SelectionTitle from './atoms/SelectionTitle/SelectionTitle';
import MachineContainer from './atoms/MachineContainer/MachineContainer';
import AssetContainer from './atoms/AssetContainer/AssetContainer';

class MachineSelector extends Component {
  constructor(props) {
    super(props);
    this.props.setTitle('Create Asset > Choose Asset Type');
  }

  render() {
    return (
        <MachineContainer>
          {this.props.assetTypes.map((type, idx) => (
            <AssetContainer key={idx.toString()} onClick={() => this.props.setAssetType(type)}>
              <img height="182px" src={MachineImg} />
              <SelectionTitle>{type}</SelectionTitle>
            </AssetContainer>
          ))}
        </MachineContainer>
    );
  }
}

export default MachineSelector;
