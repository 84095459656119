import React from 'react';
import PropTypes from 'prop-types';

const CalendarSvg = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 16 16">
    <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3-3-0-Machines-Charts" transform="translate(-1122.000000, -203.000000)">
        <g id="charts" transform="translate(80.000000, 147.000000)">
          <g id="1" transform="translate(0.000000, 36.000000)">
            <g id="top" transform="translate(20.000000, 20.000000)">
              <g id="options" transform="translate(1022.000000, 0.000000)">
                <g id="calendar-icon">
                  <rect id="bounds" fill="none" x="0" y="0" width={width} height={height} />
                  <g id="icon" transform="translate(1.000000, 1.000000)" stroke={fill} strokeWidth="1.5">
                      <rect id="calendar" strokeLinecap="round" strokeLinejoin="round" x="0" y="0" width={width - 2} height={height - 2} rx="2" />
                      <path d="M0,4.66666667 L14,4.66666667" id="line" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

CalendarSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

CalendarSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: '#000000'
};


export default CalendarSvg;
