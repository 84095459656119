import ScrollContainer_T from 'common/components/atoms/ScrollContainer';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ScrollContainer = styled(ScrollContainer_T)`
  max-height: ${props => props.edit ? 'calc(100vh - 162px)' : 'calc(100vh - 110px)'};
  ${props => !props.open && (
    'max-width: 0px;'
  )}
  overflow-x: hidden;
  background: white;
  ::-webkit-scrollbar {
    width: 2.5px;
  }
  &:hover {
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #E5E8E1;
    }
  }
`;

ScrollContainer.propTypes = {
  open: PropTypes.bool,
  edit: PropTypes.bool
};

ScrollContainer.defaultProps = {
  open: false,
  edit: false
};
export default ScrollContainer;
