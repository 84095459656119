import React from 'react';
import styled from 'styled-components';

import Modal from '../../common/components/organisms/Modal';
import Text_T from '../../common/typography/Text/Text';
import Label from '../../common/typography/Label/Label';

import mlHorizontalHAV from '../../common/images/mlHorizontalHAV.png';
import mlHorizontalVAH from '../../common/images/mlHorizontalVAH.png';
import mlHorizontalHVA from '../../common/images/mlHorizontalHVA.png';
import mlVerticalHAV from '../../common/images/mlVerticalHAV.png';
import mlVerticalHVA from '../../common/images/mlVerticalHVA.png';

const ContainerWrapper = styled.div`
  margin-left: 5%;
`;

const Text = styled(Text_T)`
  display: block;
  margin: 0.5em 0;
`;

const MoteOrientationGuide = ({
  close
}) => (
  <Modal
    title=""
    close={close}
  >
    <ContainerWrapper>
      <h3 className="modal-title">Mote Orientation Guide</h3>
      <div className="modal-body">
          <div className="container-fluid">
            <Text>Accurate Mote orientation is critical for proper analysis. </Text>
            <Text>Machines are typically configured either in horizontal or vertical orientation.  There are two options for Mote orientation in each machine configuration. </Text>
            <ol>
              <li>
                <Label style={{ fontSize: '16px' }}>Horizontal Machine Orientation</Label>
                <Text>For machines in Horizontal Orientation, there are three locations to install the Mote:</Text>
                <ol type="a">
                  <li>
                    <Label style={{ fontSize: '14px' }}>Around the bearings, Vertical Orientation (TAR - HAV)</Label>
                    <div className="col-sm-12">
                      <img src={mlHorizontalHAV} height="350" />
                    </div>
                  </li>
                  <li>
                    <Label style={{ fontSize: '14px' }}>Around the bearings, Horizontal Orientation (TAR - VAH)</Label>
                    <div className="col-sm-12">
                      <img src={mlHorizontalVAH} height="350" />
                    </div>
                  </li>
                  <li>
                    <Label style={{ fontSize: '14px' }}>On the side plate (TRA - HVA)</Label>
                    <div className="col-sm-12">
                      <img src={mlHorizontalHVA} height="350" />
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                <Label style={{ fontSize: '16px' }}>Vertical Machine Orientation</Label>
                <Text>Discharge Pipe direction is Horizontal and 90 deg to it is Vertical</Text>
                <Text>For machines in Vertical Orientation, there are two locations to install the Mote:</Text>
                <ol type="a">
                  <li>
                    <Label style={{ fontSize: '14px' }}>Around the bearings (TAR - HAV)</Label>
                    <div className="col-sm-12">
                      <img src={mlVerticalHAV} height="350" />
                    </div>
                  </li>
                  <li>
                    <Label style={{ fontSize: '14px' }}>On the side plate (TRA - HVA)</Label>
                    <div className="col-sm-12">
                      <img src={mlVerticalHVA} height="350" />
                    </div>
                  </li>
                </ol>
              </li>
            </ol>
            <Text>Mote orientation is critical to ensure repeatable data and consistent diagnoses over time. The Mote measures the vibration data from three axes or directions:</Text>
            <ul>
              <li>Axial (A)</li>
              <li>Radial (R)</li>
              <li>Tangential (T)</li>
            </ul>
            <Label style={{ fontSize: '16px' }}>Definitions</Label>
            <ul>
              <li><p><Label style={{ fontSize: '14px' }}>Axial</Label> direction runs along the length of the shaft.</p></li>
              <li><p><Label style={{ fontSize: '14px' }}>Radial</Label>  is the direction from the center of the bearing/shaft along the radius.</p></li>
              <li><p><Label style={{ fontSize: '14px' }}>Tangential</Label> is perpendicular to the radial direction.</p></li>
            </ul>
          </div>
      </div>
    </ContainerWrapper>
  </Modal>
);

export default MoteOrientationGuide;
