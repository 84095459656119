import { combineReducers } from 'redux';

import { picturesConstants } from './pictures.constants';

export const picturesReducer = combineReducers({
  thumbnails,
  deletePicture,
  upload
});

export function thumbnails(state = {}, action) {
  switch (action.type) {
    case picturesConstants.GET_MACHINE_PICTURES_REQUEST:
      return {
        loading: true,
      };

    case picturesConstants.GET_MACHINE_PICTURES_SUCCESS: {
      return {
        pictures: action.pictures.map(picture =>
          ({ url: picture.url, id: picture.id, label: picture.label })),
        machine_id: action.machine_id
      };
    }

    case picturesConstants.GET_MACHINE_PICTURES_FAILURE:
      return {
        error: action.error,
      };

    case picturesConstants.CLEAR_MACHINE_PICTURES:
      return {};

    default:
      return state;
  }
}

export function deletePicture(state = {}, action) {
  switch (action.type) {
    case picturesConstants.DELETE_PICTURES_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}

export function upload(state = {}, action) {
  switch (action.type) {
    case picturesConstants.APPEND_TO_FILE_STACK:
      return {
        ...state,
        index: action.index,
        progress: action.value,
      };

    case picturesConstants.TOGGLE_PROGRESS:
      return {
        ...state,
        index: action.index,
        progress: action.value,
      };

    case picturesConstants.CHANGE_UPLOAD_PROGRESS:
      return {
        ...state,
        index: action.index,
        progress: action.value,
      };

    default:
      return state;
  }
}
