import styled from 'styled-components';

const SvgBtn = styled.button`
  ${props => props.right && 'margin-left: auto;'}
  background: transparent;
  border: none;
  outline: none;
  padding: ${props => props.padding || 0};
  display: table-cell;
  svg {
    display: table-cell;
    margin: auto;
  }
  & > span {
    margin-bottom: 2px;
  }
  ${props => props.width && `width: ${props.width}`};
  ${props => props.height && `height: ${props.width}`};
  ${props => props.greyBorder && `
    border: 1px solid #E5E8E1;
    border-radius: 2px;
  `}
  ${props => props.opaque && `
    background: white;
  `}
`;

export default styled(SvgBtn)``;
