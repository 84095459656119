import { axiosInstance, ENDPOINT } from '../common/constants';
import { handleResponse } from '../common/helpers';

export const login = loginData =>
  handleResponse(axiosInstance.post(ENDPOINT.LOGIN, loginData));

export const register = (user) => {
  const registerDetails = {
    username: user.username,
    first_name: user.firstName,
    last_name: user.lastName,
    password: user.password,
    confirm_password: user.password, // to be removed after api gets fixed
    company_name: user.company,
    phone: user.phone,
    timezone_id: user.timezone,
    token: user.token,
  };

  return handleResponse(axiosInstance.post(ENDPOINT.SIGNUP, registerDetails));
};

export const getTokenDetails = token =>
  handleResponse(axiosInstance.post(ENDPOINT.SIGNUP_TOKEN_DETAILS, { token }));

export const getAssociateTokenDetails = token =>
  handleResponse(axiosInstance.post(ENDPOINT.ASSOCIATE_TOKEN_DETAILS, { token }));

export const getTimezones = () =>
  handleResponse(axiosInstance.get(ENDPOINT.GET_ALL_TIMEZONES));

export const getUser = () =>
  handleResponse(axiosInstance.get(ENDPOINT.CURRENT_USER));

export const verifyOtp = (otp, flow_type, trust_device) => {
  const otpDetails = {
    otp: otp.toString(),
    trust_device
  };
  if (flow_type === 'login') {
    return handleResponse(axiosInstance.post(ENDPOINT.VERIFY_LOGIN_OTP, otpDetails));
  }
  if (flow_type === 'signup') {
    return handleResponse(axiosInstance.post(ENDPOINT.VERIFY_SIGNUP_OTP, otpDetails));
  }
  return null;
};

export const resendOtp = (flow_type) => {
  if (flow_type === 'login') {
    return handleResponse(axiosInstance.get(ENDPOINT.RESEND_LOGIN_OTP));
  }
  if (flow_type === 'signup') {
    return handleResponse(axiosInstance.get(ENDPOINT.RESEND_SIGNUP_OTP));
  } return null;
};

export const selectAccount = id =>
  handleResponse(axiosInstance.post(ENDPOINT.SWITCH_ACCOUNT, JSON.stringify({ account_id: id })));

export const logout = () =>
  handleResponse(axiosInstance.get(ENDPOINT.LOGOUT));

export const forgotPassword = username =>
  handleResponse(axiosInstance.post(ENDPOINT.FORGOT_PASSWORD, username));

export const resetPassword = userData =>
  handleResponse(axiosInstance.post(ENDPOINT.RESET_PASSWORD, JSON.stringify({ ...userData })));

export const changePassword = data =>
  handleResponse(axiosInstance.post(ENDPOINT.CHANGE_PASSWORD, JSON.stringify({ ...data })));

export const saasAgreementAction = data =>
  handleResponse(axiosInstance.post(ENDPOINT.SASS_AGREEMENT_ACTION, JSON.stringify({ ...data })));

export const getCurrentAccount = () =>
  handleResponse(axiosInstance.get(ENDPOINT.GET_CURRENT_ACCOUNT));

export const updateUser = data =>
  handleResponse(axiosInstance.put(ENDPOINT.CURRENT_USER, JSON.stringify({ ...data })));

export const updatePreferences = data =>
  handleResponse(axiosInstance.put(ENDPOINT.UPDATE_PREFERENCES, JSON.stringify({ ...data })));

export const updateAccount = data =>
  handleResponse(axiosInstance.put(ENDPOINT.UPDATE_ACCOUNT, JSON.stringify({ ...data })));

export const updateAccountLogo = (data, config) =>
  handleResponse(axiosInstance.post(ENDPOINT.ACCOUNT_LOGO, data, config));

export const deleteAccountLogo = () =>
  handleResponse(axiosInstance.delete(ENDPOINT.ACCOUNT_LOGO));
