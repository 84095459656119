import _ from 'lodash';
import MachineBhsTypes from '../actions/machineBhs.types';

const initialState = {
  health: {
    locations: [],
    loading: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MachineBhsTypes.CREATE_BHS_MODEL_REQUEST:
      return {
        ...state,
        health: {
          ...state.health,
          creatingModel: true,
        }
      };

    case MachineBhsTypes.CREATE_BHS_MODEL_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          creatingModel: false
        }
      };

    case MachineBhsTypes.GET_BHS_MODELS_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          models: action.models
        }
      };

    case MachineBhsTypes.GET_BHS_LATEST_SCORE_REQUEST:
      return state;

    case MachineBhsTypes.GET_BHS_LATEST_SCORE_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          ...action.health
        }
      };

    case MachineBhsTypes.SET_SELECTED_LOCATION_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          selected_location_id: action.selected_location_id
        }
      }

    case MachineBhsTypes.GET_SPECTRUM_DATA_REQUEST:
      return {
        ...state,
        health: {
          ...state.health,
          loadingSpectrum: true,
          spectrums: [],
          isSpectrumEmpty: false
        }
      };

    case MachineBhsTypes.GET_SPECTRUM_DATA_SUCCESS:
      const idx = state.health.spectrums.findIndex(spectrum => spectrum.tag_id === action.tag_id);
      if (idx > -1) {
        state.health.spectrums.splice(idx, 1, action.spectrum);
      } else {
        state.health.spectrums.push(action.spectrum);
      }
      return {
        ...state,
        health: {
          ...state.health,
          loadingSpectrum: false,
          locations: state.health.locations.map((loc) => {
            if (loc.location_id === action.location_id) {
              if (!('spectrums' in loc)) {
                loc.spectrums = {};
              }
              loc.spectrums[action.tag_id] = action.spectrum;
            }
            return loc;
          }),
          spectrums: [
            ...state.health.spectrums,
          ],
          isSpectrumEmpty: false
        }
      };

    case MachineBhsTypes.GET_SPECTRUM_DATA_FAILURE:
      return {
        ...state,
        health: {
          ...state.health,
          loadingSpectrum: false,
          isSpectrumEmpty: true
        }
      };

    case MachineBhsTypes.GET_MACHINE_BHS_TREND_REQUEST:
      return {
        ...state,
        health: {
          ...state.health,
        }
      };

    case MachineBhsTypes.RECOMPUTE_BHS_INITIATED:
      if (action.params.machine_id) {
        return {
          ...state,
          health: {
            ...state.health,
            locations: action.tasks.map((task, idx) => ({
              ...task,
              ...state.health.locations[idx],
              recompute: true
            }))
          }
        };
      }
      return {
        ...state,
        health: {
          ...state.health,
          locations: _.map(state.health.locations, (location) => {
            if (!(action.params.location_ids.includes(location.location_id))) return location;
            const task = _.find(
              action.tasks,
              ['location_id', location.location_id]
            );
            return {
              ...task,
              ...location,
              recompute: true
            };
          })
        }
      };

    case MachineBhsTypes.RECOMPUTE_BHS_TASK_STATUS:
      return {
        ...state,
        health: {
          ...state.health,
          locations: _.map(state.health.locations, (location) => {
            if (location.task_id !== action.taskId) return location;
            const ts = action.status;
            const status = ts.status;
            let progress = 1;
            if (Number.isInteger(ts.completed_iterations)) {
              progress = (ts.completed_iterations / (ts.total_iterations + 1)) * 100;
            } else if (Number.isInteger(ts.completed_measurements)) {
              progress = (ts.completed_measurements / (ts.total_measurements + 1)) * 100;
            }
            return {
              ...location,
              task_status: {
                status,
                progress,
              },
            };
          }),
        },
      };

    case MachineBhsTypes.RECOMPUTE_BHS_TASK_DONE:
      return {
        ...state,
        health: {
          ...state.health,
          locations: _.map(state.health.locations, (location) => {
            if (location.task_id !== action.taskId) return location;
            const ts = action.status;
            const status = ts.status;
            const progress = 100;
            return {
              ...location,
              task_status: {
                status,
                progress
              },
              recompute: false
            };
          })
        }
      };

    case MachineBhsTypes.GET_MACHINE_BHS_TREND_SUCCESS: {
      if (action.params.machine_id) {
        return {
          ...state,
          health: {
            ...state.health,
            locations: state.health.locations.map((loc) => {
              const filteredLoc = _.omit(loc, ['task_id', 'task_status']);
              const filtered = action.health.locations.filter(loc2 => loc.location_id === loc2.location_id)[0];
              if (!filtered) return loc;
              const model_set = filtered.model_set;
              const health_trend = filtered.health_trend;
              return {
                ...filteredLoc,
                model_set,
                health_trend,
                loading: false,
                days: action.health.days
              };
            })
          }
        };
      }
      const health = {
        ...state.health
      };
      action.health.locations.forEach((location) => {
        const idx = _.findIndex(
          health.locations,
          ['location_id', location.location_id]
        );
        health.locations[idx] = {
          ...health.locations[idx],
          ...location
        };
      });
      return {
        ...state,
        health: {
          ...health,
          loading: false
        }
      };
    }


    case MachineBhsTypes.GET_MACHINE_BHS_TREND_FAILURE:
      return {
        ...state,
        health: {
          ...state.health,
          loading: false,
          error: action.error.message
        }
      };

    case MachineBhsTypes.GET_BHS_SCORE_DETAILS_REQUEST:
      return state;

    case MachineBhsTypes.GET_BHS_SCORE_DETAILS_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          locations: state.health.locations.map((loc) => {
            if (loc.location_id === action.params.location_id) loc.contributingFeatures = action.contributingFeatures;
            return loc;
          }),
          contributing_features: action.contributingFeatures,
          freq_units: action.freq_units,
          shaft_speed: action.shaft_speed
        }
      };

    case MachineBhsTypes.GET_BHS_SCORE_DETAILS_FAILURE:
      return {
        ...state,
        health: {
          ...state.health,
          loading: false,
          error: action.error.message
        }
      };
    default:
      return state;
  }
};
