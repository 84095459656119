import styled from 'styled-components';

const DonutChartContainer = styled.div`
  position: relative;
  width: ${props => props.width || '100%'};
  ${props => props.height === '400px' && `height: ${props.height}`};
`;

DonutChartContainer.displayName = 'DonutChartContainer';

export default DonutChartContainer;
