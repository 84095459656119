export const prepareForcingFrequenciesForMultiStageGearbox = (forcingFrequenciesMetadata, numberOfShafts) => {
  const forcingFrequenciesList = [];
  for (let shaftNumber = 1; shaftNumber <= numberOfShafts; shaftNumber++) {
    if (shaftNumber === 1) {
      const inputShaftFF = forcingFrequenciesMetadata.input_shaft;
      inputShaftFF.forEach((ff) => {
        forcingFrequenciesList.push(ff);
      }
      );
    } else if (shaftNumber === numberOfShafts) {
      const outputShaftFF = forcingFrequenciesMetadata.output_shaft;
      outputShaftFF.forEach((ff) => {
        ff.shaft = numberOfShafts;
        forcingFrequenciesList.push(ff);
      });
    } else {
      const intermediateShaftFF = forcingFrequenciesMetadata.intermediate_shaft;
      intermediateShaftFF.forEach((ff) => {
        const forcingFreq = { ...ff };
        forcingFreq.shaft = shaftNumber;
        let markerName = ff.marker;
        markerName += (shaftNumber - 1);
        forcingFreq.marker = markerName;
        forcingFreq.name = `${forcingFreq.multiplier} X Intermediate Shaft ${shaftNumber - 1} Speed`;
        forcingFrequenciesList.push(forcingFreq);
      });
    }
  }

  const numberOfStages = numberOfShafts - 1;
  for (let stageNumber = 1; stageNumber <= numberOfStages; stageNumber++) {
    const gmf = { ...forcingFrequenciesMetadata.GMF };
    gmf.marker += stageNumber;
    gmf.stage = stageNumber;
    gmf.shaft = stageNumber + 1;
    const shaftOptions = JSON.parse(JSON.stringify(gmf.shaft_options));
    if (stageNumber === 1 && numberOfStages !== 1) {
      shaftOptions[1].text = 'Intermediate Shaft 1';
    } else if (stageNumber === numberOfStages && numberOfStages !== 1) {
      shaftOptions[0].text = `Intermediate Shaft ${stageNumber - 1}`;
      shaftOptions[1].text = 'Output Shaft';
      shaftOptions[0].value = stageNumber;
      shaftOptions[1].value = stageNumber + 1;
    } else if (stageNumber > 1) {
      shaftOptions[0].text = `Intermediate Shaft ${stageNumber - 1}`;
      shaftOptions[1].text = `Intermediate Shaft ${stageNumber}`;
      shaftOptions[0].value = stageNumber;
      shaftOptions[1].value = stageNumber + 1;
    }
    gmf.shaft_options = shaftOptions;
    forcingFrequenciesList.push(gmf);
  }
  return forcingFrequenciesList;
};
