/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Message, Grid, Segment } from 'semantic-ui-react';

import AccountMenuItems from 'common/components/organisms/AccountMenuItems';
import AlertPrompt from 'common/components/organisms/AlertPrompt';
import MoreOptions from 'common/components/organisms/MoreOptions';
import RibbonItem from 'common/components/atoms/RibbonItem';
import TickSvg from 'common/images/BearingModal/TickSvg';
import { Info } from 'common/images/FaIcons';
import Label_T from 'common/typography/Label/Label';
import EditIcon from 'common/images/BearingModal/EditIcon';
import OutsideAlerter from 'common/OutsideAlerter';
import Button_T from 'common/components/atoms/Button';
import InputField from 'common/components/atoms/InputField';
import { CapitalizeEachWord, splitRangeIntoEqualParts } from 'common/utils';
import { isPetasenseAdmin, isPetasenseViewer } from 'common/rbac/util';
import FlexContainer from 'common/components/atoms/FlexContainer';
import DropdownSelect from 'common/components/molecules/DropdownSelect';
import H3_T from 'common/typography/H3/H3';
import LoadingSvg_T from 'common/components/atoms/Loading';
import RBAC from 'common/rbac/RBAC';
import Modal from 'common/components/organisms/Modal';
import {
  mapComponentToResource,
  operations
} from 'common/rbac/constants';
import { humanize } from 'common/helpers/humanize';
import { prepareForcingFrequenciesForMultiStageGearbox } from 'home/Machines/MachineBuilder/utils/utils';
import { getShaftName } from 'home/Machines/utils';

import ForcingFreqModal from 'home/Machines/MachineBuilder/components/organisms/ForcingFreqModal';
import ComponentTypeModal from 'home/Machines/MachineBuilder/components/organisms/ComponentTypeModal';
import Dimensions from 'home/Machines/MachineBuilder/components/organisms/Dimensions';
import MultiSelectDropdown from 'common/components/molecules/MultiSelectDropdown';
import MeasurementScheduleModal from 'home/Sensors/components/MeasurementScheduleModal';
import machineAddActions from 'home/Machines/MachineAdd/actions/machineAdd.actions';
import templatesActions from 'home/Templates/actions/templates.actions';
import ComponentGraphics from 'home/Machines/MachineBuilder/components/organisms/ComponentGraphics';
import {
  ResourceInputField, ResourceItem, ResourceItemLabel, ResourceSection, ResourceSubSection
} from 'common/components/atoms/ResourceDetails';
import RefreshSvg from 'common/images/RefreshSvg';
import { Accordion } from 'common/components/molecules';
import * as bearingActions from '../../../MachineDetails/MachineInfo/components/BearingModal/bearing.actions';
import * as machineDiagnosticActions from '../../../../MachineDiagnostics/actions/machineDiagnostics.actions';

import SpanLabel from '../atoms/SpanLabel/SpanLabel';
import RowContainer from '../atoms/RowContainer/RowContainer';
import InputFieldBold from '../molecules/InputFieldBold/InputFieldBold';
import ComponentTitle from '../atoms/ComponentTitle/ComponentTitle';

import BearingDetails from '../../../MachineDetails/MachineInfo/components/BearingDetails';
import OtherLocationDetails from '../../../MachineDetails/MachineInfo/components/OtherLocationDetails';
import BearingModal from '../../../MachineDetails/MachineInfo/components/BearingModal/components/BearingModal';
import * as machineInfoActions from '../../../MachineDetails/MachineInfo/actions/machineInfo.actions';
import * as sensorActions from '../../../../Sensors/actions/sensors.actions';
import {
  EXCLUDE_FROM_MACHINE_PROPERTIES,
  EXCLUDE_FROM_TEMPLATE_PROPERTIES,
  MEASUREMENT_TYPE,
  NUM_AGGRESSIVE_READINGS,
  AGGRESSIVE_INTERVALS,
  ADDITIONAL_AGGRESSIVE_MEASUREMENT_INTERVALS
} from '../../constants/NodeDetails.constants';
import {
  ToggleOff as FaToggleOff,
  ToggleOn as FaToggleOn
} from '../../../../../common/images/FaIcons';

const ERROR_MESSAGES = {
  SEED_SPEED_VALUES_EMPTY: 'Seed speed values cannot be empty',
  BIN_RANGE_ERROR: 'Speed range error',
  GENERATE_SPEED_BINS: 'Please generate speed ranges',
  MIN_MAX_ERROR: 'Min value cannot be greater than Max value',
  BIN_COUNT_ERROR: 'Enter Speed Range Count in between 1 and 10',
  BIN_RANGE_FORMAT_ERROR: 'Enter speed range in proper format',
  WARNING_LIMIT_ERROR: 'Warning limit is required',
  CRITICAL_LIMIT_ERROR: 'Critical limit is required',
  ISO_LIMIT_ERROR: 'ISO limits are required',
  WARNING_GREATER_THAN_CRITICAL: 'Warning limit cannot be greater than Critical limit',
  LOWER_BANDPASS_CUTOFF_ERROR: 'Lower bandpass cutoff is required',
  UPPER_BANDPASS_CUTOFF_ERROR: 'Upper bandpass cutoff is required',
  LOWER_BANDPASS_GREATER_THAN_UPPER: 'Lower bandpass cutoff cannot be greater than Upper bandpass cutoff',
  POLE_COUNT_ERROR: 'Pole Count should be an even number less than or equal to 30'
};

const ToggleOn = styled(FaToggleOn)`
  color: ${props => props.theme.colors.green};
  margin-right: 10px;
  font-size: 1.75em;
  cursor: pointer;
`;

const ToggleOff = styled(FaToggleOff)`
  color: ${props => props.theme.colors.greyD};
  font-size: 1.75em;
  cursor: pointer;
`;

const LoadingSvg = styled(LoadingSvg_T)`
  padding-top: 5px;
  position: relative;
  top: 50%;
`;

const Column = styled(Grid.Column)`
  min-width: 220px;
`;

const SvgContainer = styled.span`
  padding: ${props => props.padding ? props.padding : '0 0 0 0.7em'};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '1.5rem'};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  &:hover {
    cursor: ${props => (props.valid ? 'pointer' : 'default')};
    visibility: visible;
    #edit-icon-stroke {
      stroke: ${props => props.theme.colors.black};
    }
  }
`;

const Label = styled(Label_T)`
  ${props => props.header && `
    color: #3C3D3A;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
  `}
  ${props => props.error && `
    color: red;
  `}
`;
const SettingsLabel = styled(Label_T)`
  color: #3C3D3A;
  font-size: 20px;
  font-weight: 300;
  margin-top: 0.8em;
  margin-bottom: 1.2em;
`;

const DescriptionLabel = styled(Label_T)`
  font-weight: 300;
  margin-bottom: 0;
  margin-left: 8px;
  margin-top: -2px;
`;

const FormMessage = styled(Message)`
  position: absolute !important;
  right: 2rem;
  width: 300px;
`;

const Button = styled(Button_T)`
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  font-size: ${props => (props.fontSize ? props.fontSize : '13px')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0px')};
`;

const AbsoluteLoadingContainer = styled.div`
  position: absolute;
  width: 70%;
  height: 100%;
  background-color: #e4e4e447;
  z-index: 3;
`;

const ComponentSvgContainer = styled.div`
  width: 45em;
  margin: 2em 0;
`;

const LinksContainer = styled(FlexContainer)`
  align-items: center;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '2.5rem'};
`;

const RadioButtonContainer = styled(FlexContainer).attrs({
  direction: 'column'
})`
  order: ${props => props.order};
`;

const VfdContainer = styled(FlexContainer)`
  ${InputField}:first-of-type {
    margin-right: 1.5em;
  }
  ${InputField} {
    cursor: pointer;
  }
`;

const GenerateButton = styled(Button).attrs({ fontSize: '12px' })`
  margin-bottom: 1.5em;
`;

const ShaftNumberContainer = styled(H3_T)`
  width: max-content;
  margin-bottom: 0.5em;
  margin-top: 1em;
  color: #3C3D3A;
  font-size: 17px;
  font-weight: 300;
`;

const CheckList = styled.ul`
  padding-left: 3px;
`;

const WarningDescription = styled(FlexContainer)`
  font-size: 12px;
  color: #999B95;
  margin-bottom: 0px;
  font-weight: 800;
`;

const Description = styled(FlexContainer)`
  font-size: 12px;
  color: #999B95;
  margin-top: 25px;
  font-weight: 300;
`;

const ShaftDescription = styled(FlexContainer)`
  font-size: 11px;
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: 1px;
`;

const BorderContainer = styled.div`
  border: 2px solid #f0f0f0;
  padding: 20px;
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled.div`
  visibility: hidden;
  width: 210px;
  background-color: #f9f9f9;
  color: #000;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -210px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;

  ${TooltipContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;


const toLowerCaseUnderscore = (arr = []) => {
  const retArray = [];
  arr.forEach((item) => {
    retArray.push(item.toLowerCase().replace(/ /g, '_'));
  });
  return retArray;
};

const NodeDetails = (props) => {
  const getForcingFrequenciesFromComponents = () => {
    const components = props.componentsList();
    const forcingFreqs = [];
    components.forEach((component) => {
      let ffs = _.cloneDeep(component.forcing_frequencies);
      if ((!ffs || ffs.length === 0)) {
        ffs = props.forcingFrequencyConstants[component.component_type];
        if (component.component_type === 'multi_stage_gearbox') {
          ffs = component.no_of_shafts ? prepareForcingFrequenciesForMultiStageGearbox(ffs, component.no_of_shafts) : [];
        }
        if (ffs) {
          ffs = ffs.map((ff, index) => ({
            ...ff,
            is_default: true,
            id: index
          }));
        }
      }
      forcingFreqs.push({
        component_type: component.component_type,
        component_name: component.name,
        component_id: component.id,
        forcing_frequencies: ffs || []
      });
    });
    return forcingFreqs;
  };

  let initialForcingFrequencies = getForcingFrequenciesFromComponents();

  const rotatingComponentTypes = props.metadata.component_function_types
    .filter(ft => ft.text !== 'Other')
    .map(ft => ({
      text: ft.text,
      children: props.metadata.components[ft.value]
    }));
  let nonRotatingComponentTypes = props.metadata.components.other;
  if (props.nonRotatingSubtype) nonRotatingComponentTypes = nonRotatingComponentTypes
    .filter(nrType => nrType.component_type === props.nonRotatingSubtype);
  const [nodeName, setNodeName] = useState(props.nodeName);
  const [nodeType, setNodeType] = useState(props.nodeType);
  const [editName, setEditName] = useState(!props.nodeName);
  const [details, updateDetails] = useState(_.cloneDeep(props.details));
  const [saveEnabled, setSaveEnabled] = useState(true);
  const [areas, setAreas] = useState(_.cloneDeep(props.areas));
  const [subtypes, setSubtypes] = useState(_.cloneDeep(props.MACHINE_SUBTYPES));
  const [deleting, setDeleting] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmSyncSchedule, setConfirmSyncSchedule] = useState(false);
  const [bearingModalOpen, setBearingModalOpen] = useState({ open: false, key: '' });
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [formFillMessage, setFormFillMessage] = useState([]);

  const [compTypeText, setCompTypeText] = useState('');
  const [filterError, setFilterError] = useState('');
  const [speedBinError, setSpeedBinError] = useState('');
  const [alarmsType, setAlarmsType] = useState({ iso: props.details.alarms_type === 'iso', user_defined: props.details.alarms_type === 'user_defined' });
  const [rulesSelected, setSelectedRules] = useState([]);
  const [ruleDetails, setRuleDetails] = useState([]);
  const [errors, setErrors] = useState({});

  const [forcingFreqModalOpen, setForcingFreqModalOpen] = useState(false);
  const [componentTypeModalOpen, setComponentTypeModalOpen] = useState(false);
  const [showVoltmeterUtilizationOptions, setShowVoltmeterUtilizationOptions] = useState(false);
  const [showSpeedDetectionSettingOptions, setShowSpeedDetectionSettingOptions] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showGenerateButton, setShowGenerateButton] = useState(false);

  const descriptionOptions = {
    VM1: 'using accelerometer every 5 minutes.',
    VM2: 'using accelerometer every 5 minutes.',
    VM3: 'using magnetometer every 5 minutes.',
    VM4: 'using magnetometer every 5 minutes.'
  };

  useEffect(() => {
    // setForcingFrequencies(initialForcingFrequencies);
  }, [JSON.stringify(initialForcingFrequencies)]);

  useEffect(() => {
    const errorExists = (filterError || speedBinError || !_.every(errors, _.isEmpty));
    if (((nodeName !== props.nodeName) || (nodeType !== props.nodeType) || !_.isEqual(details, props.details))
      && nodeName && nodeName.trim() && !errorExists &&
      nodeType && (nodeType === 'component' ? details.component_type : true)
    ) {
      props.setEnableSave_info(true, { nodeName, nodeType, details });
    } else if (props.enableSave_info) {
      props.setEnableSave_info(false);
    }
  }, [nodeName, nodeType, details]);

  useEffect(() => onCancelClick(), [props.id, props.nodeName, props.nodeType, props.details]);

  useEffect(() => {
    const errors = props.reviewMachineStructure();
    if (errors.length === 0) {
      setFormFillMessage([]);
      if (!filterError && !speedBinError) {
        setSaveEnabled(true);
        props.setEnableSave(true);
      }
    } else {
      setFormFillMessage([...errors]);
      setSaveEnabled(false);
      props.setEnableSave(false);
    }
  }, [nodeName]);

  useEffect(() => {
    const component_types = _.uniq(props.getComponentTypes());
    const searchParams = { component_type: component_types };

    if (nodeType === 'machine') {
      if (props.isInfo && _.isEmpty(component_types)) return;
      props.machineDiagnosticActions.setRulesSearchParams(searchParams);
    }
  }, [nodeType]);

  useEffect(() => {
    if (props.isInfo && props.info && props.info.details && props.info.details.diagnostic_rules) {
      setSelectedRules([...props.info.details.diagnostic_rules.map(x => x.id)]);
    }
  }, []);

  useEffect(() => {
    if (props.isInfo && props.info && props.info.details && props.info.details.diagnostic_rules) {
      setRuleDetails([...props.info.details.diagnostic_rules]);
    }
  }, []);

  useEffect(() => {
    props.sensorActions.getUnassociatedSensorsList();
  }, []);

  useEffect(() => {
    setSubtypes([...props.MACHINE_SUBTYPES]);
  }, [props.MACHINE_SUBTYPES]);

  useEffect(() => {
    updatedBearingDetails();
  }, [details.no_of_shafts]);

  useEffect(() => {
    const components = props.isInfo && props.info && props.info.details && props.info.details.components || [];
    const bearings = components.map(c => c.bearings).flat();
    const locations = components.map(c => c.locations).flat();
  }, [props.info.details]);

  useEffect(() => {
    const components = props.isInfo && props.info && props.info.details && props.info.details.components || [];
    const bearings = components.map(c => c.bearings).flat();
    const locations = components.map(c => c.locations).flat();

    if (_.some([...bearings, ...locations], tx => !_.isEmpty(tx.tx_sensors) &&
      _.some(tx.tx_sensors, sensor => sensor.type === 'Voltmeter Sensor'))) {
      setShowVoltmeterUtilizationOptions(true);
    }
    if (isPetasenseAdmin(props.currentUser)) {
      setShowSpeedDetectionSettingOptions(true);
    }
  }, [props.info.details]);

  useEffect(() => {
    if (!props.isInfo || props.newNode) {
      if (((nodeType !== props.nodeType) || !_.isEqual(details, props.details)) && !filterError && !speedBinError && nodeType) {
        props.saveNodeDetails(nodeName, nodeType, details, props.id, false, props.newNode);
      }
    }
    const errors = props.reviewMachineStructure();
    if (errors.length === 0) {
      setFormFillMessage([]);
      if (!filterError && !speedBinError) {
        setSaveEnabled(true);
        props.setEnableSave(true);
      }
    } else {
      setFormFillMessage([...errors]);
      setSaveEnabled(false);
      props.setEnableSave(false);
    }
  }, [nodeType, details]);

  useEffect(() => {
    if (props.depth > 1 && !nodeType) {
      setNodeType('component');
    }
  }, [nodeType, props.depth]);

  const onNameSubmit = (e) => {
    if (e && e.key && e.key !== 'Enter') return;
    const name = nodeName.trim();
    if (name) {
      setEditName(false);
      props.saveNodeDetails(name, nodeType, details, props.id);
    }
  };

  const componentType = props.depth === 1 ? 'New Asset' : 'New Component';

  const onBearingLocationNameSubmit = (name, description, shaftNumber) => {
    if (name) {
      const req = {
        component_id: props.id,
        description,
        is_bearing: true,
        name,
        shaft_number: shaftNumber
      };
      if (props.isInfo && !props.newNode && props.buildType === 'machine') {
        props.machineInfoActions.createLocation(props.info.details.id, props.id, req).then(
          (res) => {
            toastr.success(res.message);
            const newDetails = _.cloneDeep(details);
            if (!newDetails.bearings) newDetails.bearings = {};
            newDetails.bearings[res.location.id] = {
              bearing_location_id: res.location.id,
              id: undefined,
              description,
              motes: [],
              tx_sensors: [],
              properties: _.cloneDeep(props.metadata.bearing_properties),
              shaft_number: shaftNumber,
              name: res.location.name
            };
            updateDetails(newDetails);
          },
          (error) => {
            toastr.error(error.message);
          }
        );
      } else {
        const newDetails = _.cloneDeep(details);
        if (!newDetails.bearings) newDetails.bearings = {};
        newDetails.bearings[name] = {
          bearing_location_id: undefined,
          id: undefined,
          description,
          motes: [],
          tx_sensors: [],
          properties: _.cloneDeep(props.metadata.bearing_properties),
          shaft_number: shaftNumber
        };
        updateDetails(newDetails);
      }
    }
  };

  const onLocationNameSubmit = (name, description) => {
    if (name) {
      const req = {
        component_id: props.id,
        description,
        is_bearing: false,
        name,
      };
      if (props.isInfo && !props.newNode && props.buildType === 'machine') {
        props.machineInfoActions.createLocation(props.info.details.id, props.id, req).then(
          (res) => {
            toastr.success(res.message);
            const newDetails = _.cloneDeep(details);
            if (!newDetails.locations) newDetails.locations = [];
            newDetails.locations.push({
              description,
              id: res.location.id,
              name,
              tx_sensors: []
            });
            updateDetails(newDetails);
          },
          (error) => {
            toastr.error(error.message);
          }
        );
      } else {
        const newDetails = _.cloneDeep(details);
        if (!newDetails.locations) newDetails.locations = [];
        newDetails.locations.push({
          description,
          is_bearing: false,
          name,
          tx_sensors: []
        });
        updateDetails(newDetails);
      }
    }
  };

  const onCancelClick = () => {
    setNodeName(props.nodeName);
    setNodeType(props.nodeType);
    setEditName(!props.nodeName);
    updateDetails(_.cloneDeep(props.details));
    if (props.nodeType === 'component') {
      let compName = '';
      Object.entries(props.metadata.components).some(([, list]) => {
        const el = list.find(e => e.value === props.details.component_type);
        if (!el) return false;
        compName = el.text;
        return true;
      });
      setCompTypeText(compName);
    }
  };

  const handleSubtypeAddition = (e, { value }) => {
    setSubtypes([{ text: value, value, key: value }, ...subtypes]);
  };

  const resetSettingsError = (inputErrors) => {
    inputErrors.overall_interval = '';
    inputErrors.high_resolution_interval = '';
    inputErrors.full_bandwidth_interval = '';
    inputErrors.aggressive_reading_interval = '';
    inputErrors.num_aggressive_readings = '';
    inputErrors.is_aggressive_measurement = '';
  };

  const getMinInterval = (key, value) => {
    let {
      overall_interval,
      high_resolution_interval,
      full_bandwidth_interval,
    } = details;

    overall_interval = key === 'overall_interval' ? value : overall_interval;
    high_resolution_interval = key === 'high_resolution_interval' ? value : high_resolution_interval;
    full_bandwidth_interval = key === 'full_bandwidth_interval' ? value : full_bandwidth_interval;

    return Math.min(overall_interval, full_bandwidth_interval, high_resolution_interval);
  };

  const isValidSettingValues = (key, value) => {
    let {
      num_aggressive_readings,
      aggressive_reading_interval,
      is_aggressive_measurement
    } = details;

    aggressive_reading_interval = key === 'aggressive_reading_interval' ? value : aggressive_reading_interval;
    num_aggressive_readings = key === 'num_aggressive_readings' ? value : num_aggressive_readings;
    is_aggressive_measurement = key === 'is_aggressive_measurement' ? value : is_aggressive_measurement;
    const min_interval = getMinInterval(key, value);

    if (is_aggressive_measurement && (num_aggressive_readings * aggressive_reading_interval > min_interval)) return false;
    return true;
  };

  const validateInput = (key, value) => {
    const inputErrors = {};
    const {
      warning_limit,
      critical_limit,
      lower_bandpass_cutoff,
      upper_bandpass_cutoff,
      num_aggressive_readings,
      aggressive_reading_interval,
      is_aggressive_measurement
    } = details;
    let min_interval;
    let max_agg_interval;
    let max_readings;

    switch (key) {
      case 'alarms_type':
        if (value === 'user_defined') {
          inputErrors.warning_limit = '';
          inputErrors.critical_limit = '';
          if (!warning_limit) inputErrors.warning_limit = ERROR_MESSAGES.WARNING_LIMIT_ERROR;
          if (!critical_limit) inputErrors.critical_limit = ERROR_MESSAGES.CRITICAL_LIMIT_ERROR;
          if (warning_limit && critical_limit && warning_limit > critical_limit) inputErrors.warning_limit = ERROR_MESSAGES.WARNING_GREATER_THAN_CRITICAL;
        } else if (value === 'iso') {
          const { alarms_limits } = details;
          inputErrors.alarms_limits = '';
          if (!alarms_limits) inputErrors.alarms_limits = ERROR_MESSAGES.ISO_LIMIT_ERROR;
        } else {
          inputErrors.warning_limit = '';
          inputErrors.critical_limit = '';
          inputErrors.alarms_limits = '';
        }
        break;
      case 'alarms_limits':
        if (value) inputErrors.alarms_limits = '';
        break;
      case 'warning_limit':
        inputErrors.warning_limit = '';
        if (!value) inputErrors.warning_limit = ERROR_MESSAGES.WARNING_LIMIT_ERROR;
        if (critical_limit && value && value > critical_limit) inputErrors.warning_limit = ERROR_MESSAGES.WARNING_GREATER_THAN_CRITICAL;
        break;
      case 'critical_limit':
        inputErrors.critical_limit = '';
        if (warning_limit && value && value >= warning_limit) inputErrors.warning_limit = '';
        if (!value) inputErrors.critical_limit = ERROR_MESSAGES.CRITICAL_LIMIT_ERROR;
        if (warning_limit && value && value < warning_limit) inputErrors.warning_limit = ERROR_MESSAGES.WARNING_GREATER_THAN_CRITICAL;
        break;
      case 'lower_bandpass_cutoff':
        if (!value && upper_bandpass_cutoff) inputErrors.lower_bandpass_cutoff = ERROR_MESSAGES.LOWER_BANDPASS_CUTOFF_ERROR;
        if (value && upper_bandpass_cutoff && value >= upper_bandpass_cutoff) inputErrors.lower_bandpass_cutoff = ERROR_MESSAGES.LOWER_BANDPASS_GREATER_THAN_UPPER;
        if (value && !upper_bandpass_cutoff) {
          inputErrors.upper_bandpass_cutoff = ERROR_MESSAGES.UPPER_BANDPASS_CUTOFF_ERROR;
        }
        if ((!value && !upper_bandpass_cutoff) || (value && upper_bandpass_cutoff && value < upper_bandpass_cutoff)) {
          inputErrors.lower_bandpass_cutoff = '';
          inputErrors.upper_bandpass_cutoff = '';
        }
        break;
      case 'upper_bandpass_cutoff':
        if (!value && lower_bandpass_cutoff) inputErrors.upper_bandpass_cutoff = ERROR_MESSAGES.UPPER_BANDPASS_CUTOFF_ERROR;
        if (value && lower_bandpass_cutoff && value <= upper_bandpass_cutoff) inputErrors.upper_bandpass_cutoff = ERROR_MESSAGES.LOWER_BANDPASS_GREATER_THAN_UPPER;
        if (value && !lower_bandpass_cutoff) inputErrors.lower_bandpass_cutoff = ERROR_MESSAGES.LOWER_BANDPASS_CUTOFF_ERROR;
        if ((!value && !lower_bandpass_cutoff) || (value && lower_bandpass_cutoff && value > lower_bandpass_cutoff)) {
          inputErrors.lower_bandpass_cutoff = '';
          inputErrors.upper_bandpass_cutoff = '';
        }
        break;
      case 'overall_interval':
        inputErrors.overall_interval = '';
        if (is_aggressive_measurement && value && value < aggressive_reading_interval * num_aggressive_readings) {
          inputErrors.overall_interval = `Should be more than ${aggressive_reading_interval * num_aggressive_readings} seconds. Please adjust parameters!`;
        }
        if (isValidSettingValues(key, value)) {
          inputErrors.aggressive_reading_interval = '';
          inputErrors.num_aggressive_readings = '';
          inputErrors.is_aggressive_measurement = '';
        }

        break;
      case 'high_resolution_interval':
        inputErrors.high_resolution_interval = '';
        if (is_aggressive_measurement && value && value < aggressive_reading_interval * num_aggressive_readings) {
          inputErrors.high_resolution_interval = `Should be more than ${aggressive_reading_interval * num_aggressive_readings} seconds. Please adjust parameters!`;
        }
        if (isValidSettingValues(key, value)) {
          inputErrors.aggressive_reading_interval = '';
          inputErrors.num_aggressive_readings = '';
          inputErrors.is_aggressive_measurement = '';
        }

        break;
      case 'full_bandwidth_interval':
        inputErrors.full_bandwidth_interval = '';
        if (is_aggressive_measurement && value && value < aggressive_reading_interval * num_aggressive_readings) {
          inputErrors.full_bandwidth_interval = `Should be more than ${aggressive_reading_interval * num_aggressive_readings} seconds. Please adjust parameters!`;
        }
        if (isValidSettingValues(key, value)) {
          inputErrors.aggressive_reading_interval = '';
          inputErrors.num_aggressive_readings = '';
          inputErrors.is_aggressive_measurement = '';
        }

        break;
      case 'aggressive_reading_interval':
        resetSettingsError(inputErrors);
        min_interval = getMinInterval(key, value);

        max_agg_interval = Math.floor(min_interval / num_aggressive_readings);
        if (!isValidSettingValues(key, value)) {
          inputErrors.aggressive_reading_interval = `Should not be more than ${max_agg_interval} seconds. Please adjust parameters!`;
        }
        break;
      case 'num_aggressive_readings':
        resetSettingsError(inputErrors);
        min_interval = getMinInterval(key, value);

        max_readings = Math.floor(min_interval / aggressive_reading_interval);
        if (!isValidSettingValues(key, value)) {
          inputErrors.num_aggressive_readings = `No. of readings should not be more than ${max_readings}. Please adjust parameters!`;
        }
        break;
      case 'is_aggressive_measurement':
        resetSettingsError(inputErrors);

        min_interval = getMinInterval(key, value);

        max_readings = Math.floor(min_interval / aggressive_reading_interval);
        if (!isValidSettingValues(key, value)) {
          inputErrors.is_aggressive_measurement = `No. of readings should not be more than ${max_readings}. Please adjust parameters!`;
        }
        break;
      case 'pole_count':
        inputErrors.pole_count = '';
        if (!_.isNil(value) && (value > 30 || (value % 2 !== 0))) {
          inputErrors.pole_count = ERROR_MESSAGES.POLE_COUNT_ERROR;
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, ...inputErrors });
  };

  const onChangeDetailsField = (e, data, cb = () => { }) => {
    let value;
    let key;
    if (e.target && e.target.name) {
      value = e.target.value;
      key = e.target.name;
    } else if (data) {
      value = data.value;
      key = data.name;
    }

    validateInput(key, value);
    if (key === 'no_of_shafts') {
      const shaftInfoArray = [];
      for (let shaftNum = 1; shaftNum <= details.no_of_shafts; shaftNum++) {
        shaftInfoArray.push({ shaft_number: shaftNum });
      }
      updateDetails({
        ...details,
        shaft_info: shaftInfoArray,
      });
    }
    if (!(key in details)) {
      if (e.target && e.target.type === 'number') props.details[key] = null;
      else props.details[key] = '';
    }
    if (data && data.name === 'vfd') {
      updateDetails({
        ...details,
        [key]: value,
        seed_speed: data.value ? (props.details.seed_speed ? _.cloneDeep(props.details.seed_speed) : [10, 40]) : null,
        speed_bins_config: data.value ? (props.details.speed_bins_config ? _.cloneDeep(props.details.speed_bins_config) : { no_of_bins: 2 }) : null,
      });

      if (!data.value) {
        setFilterError('');
        setSpeedBinError('');
      } else if (data.value && !props.details.speed_bins_config) {
        setSpeedBinError(ERROR_MESSAGES.GENERATE_SPEED_BINS);
        setSaveEnabled(false);
        props.setEnableSave(false);
      }
    } else {
      let val = value;
      if (e.target && e.target.type === 'number') val = value === '' ? null : Number(val);
      if (key === 'component_type') updateDetails({
        ...details,
        [key]: val,
        locations: null
      });
      else updateDetails({
        ...details,
        [key]: val
      });
    }

    if (key === 'state_detection_enabled' && value === false) {
      updateDetails({
        ...details,
        state_detection_enabled: false,
        bluetooth_p2p_enabled: false
      });
    }
    cb(e, { value });
  };

  const onChangeShaftDetails = (e, data, cb = () => { }, shaftNumber) => {
    let value;
    let key;
    if (e.target && e.target.name) {
      value = e.target.value;
      key = e.target.name;
    } else if (data) {
      value = data.value;
      key = data.name;
    }
    if (!(key in details)) {
      if (e.target && e.target.type === 'number') props.details[key] = null;
      else props.details[key] = '';
    }

    if (!details.shaft_info) {
      const shaftInfoArray = [];
      for (let shaftNum = 1; shaftNum <= details.no_of_shafts; shaftNum++) {
        shaftInfoArray.push({ shaft_number: shaftNum });
      }
      updateDetails({
        ...details,
        shaft_info: shaftInfoArray
      });
    } else {
      details.shaft_info.forEach((prop) => {
        if (prop.shaft_number === shaftNumber) {
          prop[key] = value;
        }
      });

      updateDetails({
        ...details,
        shaft_info: details.shaft_info
      });
    }

    cb(e, { value });
  };

  const onChangeVfdMin = (e) => {
    const value = e.target.value;
    if (!value || !details.seed_speed[1]) {
      setFilterError(ERROR_MESSAGES.SEED_SPEED_VALUES_EMPTY);
      setSpeedBinError('');
      setSaveEnabled(false);
      props.setEnableSave(false);
    } else if (details.seed_speed[1] && details.seed_speed[1] <= value) {
      setFilterError(ERROR_MESSAGES.MIN_MAX_ERROR);
      setSpeedBinError('');
      setSaveEnabled(false);
      props.setEnableSave(false);
    } else {
      setFilterError('');
      setSpeedBinError(ERROR_MESSAGES.GENERATE_SPEED_BINS);
      setShowGenerateButton(true);
    }

    updateDetails({
      ...details,
      seed_speed: [parseFloat(value), details.seed_speed[1]],
      speed_bins_config: { ...details.speed_bins_config, speed_bins: null }
    });
  };

  const onChangeVfdMax = (e) => {
    const value = e.target.value;
    if (!value || !details.seed_speed[0]) {
      setFilterError(ERROR_MESSAGES.SEED_SPEED_VALUES_EMPTY);
      setSpeedBinError('');
      setSaveEnabled(false);
      props.setEnableSave(false);
    } else if (details.seed_speed[0] && details.seed_speed[0] >= value) {
      setFilterError(ERROR_MESSAGES.MIN_MAX_ERROR);
      setSpeedBinError('');
      setSaveEnabled(false);
      props.setEnableSave(false);
    } else {
      setFilterError('');
      setSpeedBinError(ERROR_MESSAGES.GENERATE_SPEED_BINS);
      setShowGenerateButton(true);
    }

    updateDetails({
      ...details,
      seed_speed: [details.seed_speed[0], parseFloat(value)],
      speed_bins_config: { ...details.speed_bins_config, speed_bins: null }
    });
  };

  const onChangeBinCount = (e) => {
    const no_of_bins = parseInt(e.target.value, 10);
    if (no_of_bins < 1 || no_of_bins > 10) {
      setFilterError(ERROR_MESSAGES.BIN_COUNT_ERROR);
      setSpeedBinError('');
    } else if (!no_of_bins) {
      setFilterError('');
      setSpeedBinError('');
    } else {
      setFilterError('');
      setSpeedBinError(ERROR_MESSAGES.GENERATE_SPEED_BINS);
    }
    updateDetails({
      ...details,
      speed_bins_config: { no_of_bins }
    });
    setShowGenerateButton(true);
  };

  const onClickGenerate = () => {
    const speed_bins = generateDefaultSpeedBins();
    setSpeedBinError('');
    props.setEnableSave(true);
    updateDetails({
      ...details,
      speed_bins_config: { ...details.speed_bins_config, speed_bins }
    });
    setShowGenerateButton(false);
  };

  const onChangeSpeedBin = (value, bin_no) => {
    const speed_bins = _.cloneDeep(details.speed_bins_config.speed_bins);
    const bin_range = _.map(value.split('-'), r => parseFloat(r));
    const updateRange = bin_range.length === 2 && _.every(bin_range);
    const newbins = speed_bins.map((bin) => {
      if (bin.bin_no !== bin_no) return bin;
      return {
        ...bin,
        range_text: value,
        bin_range: updateRange ? bin_range : null
      };
    });
    if (!updateRange || _.some(newbins, b => !b.bin_range)) {
      setSpeedBinError(ERROR_MESSAGES.BIN_RANGE_FORMAT_ERROR);
      setSaveEnabled(false);
      props.setEnableSave(false);
    } else if (updateRange && (bin_range[0] > bin_range[1] ||
      bin_range[0] < details.seed_speed[0] ||
      bin_range[1] > details.seed_speed[1] ||
      (bin_range[0] < getBinRangeFromBinNo(bin_no - 1)[1])) ||
      (bin_range[1] > getBinRangeFromBinNo(bin_no + 1)[0])
    ) {
      setSpeedBinError(ERROR_MESSAGES.BIN_RANGE_ERROR);
      setSaveEnabled(false);
      props.setEnableSave(false);
    } else {
      setSpeedBinError('');
      setSaveEnabled(true);
      props.setEnableSave(true);
    }

    updateDetails({
      ...details,
      speed_bins_config: { ...details.speed_bins_config, speed_bins: newbins }
    });
  };

  const getBinRangeFromBinNo = (bin_no) => {
    const bin = details.speed_bins_config && details.speed_bins_config.speed_bins.find(b => b.bin_no === bin_no);
    return bin && bin.bin_range ? bin.bin_range : [];
  };

  const generateDefaultSpeedBins = () => {
    const seed_speed = _.cloneDeep(details.seed_speed);
    let speed_bins = splitRangeIntoEqualParts(seed_speed[1], seed_speed[0], details.speed_bins_config.no_of_bins);
    speed_bins = speed_bins.map(bin => ({ ...bin, range_text: `${bin.bin_range[0]}-${bin.bin_range[1]}` }));
    return speed_bins;
  };

  const openBearingModal = (loc_id, model_id, key) => {
    setBearingModalOpen({ open: true, key });
  };

  const setBearingDetails = (id, item, key) => {
    const newDetails = _.cloneDeep(details);
    if (!newDetails.bearings) newDetails.bearings = {};
    const bearingItem = _.cloneDeep(props.metadata.bearing_properties);
    Object.entries(item).forEach(([key, value]) => {
      if (!bearingItem[key]) return;
      bearingItem[key].value = value;
    });
    if (newDetails.bearings[key]) {
      newDetails.bearings[key] = {
        ...newDetails.bearings[key],
        id,
        properties: bearingItem
      };
    } else {
      newDetails.bearings[key] = {
        id,
        properties: bearingItem
      };
    }
    updateDetails(newDetails);
  };

  const unassignBearingDetails = (bearing_location_id, id, key) => {
    const data = { bearing_location_id };
    props.bearingActions.unassignBearing(data, props.id).then(
      (res) => {
        const newDetails = _.cloneDeep(details);
        delete newDetails.bearings[key].id;
        toastr.success(res.message);
        updateDetails(newDetails);
      },
      (error) => {
        toastr.error(error.message);
      }
    );
  };

  const getMachineMenuItems = () => (
    <FlexContainer direction="column">
      <RibbonItem disabled disabledColor="#999B95">
        Machine
      </RibbonItem>
      <RibbonItem
        fontSize="14px"
        onClick={() => setConfirmDeleteOpen(true)}
      >
        Delete
      </RibbonItem>
      <RibbonItem
        fontSize="14px"
        width="max-content"
        onClick={() => {
          props.saveNodeDetails(nodeName, nodeType, details, props.id, true);
          props.verifyTemplate();
        }}
      >
        Save as Template
      </RibbonItem>
    </FlexContainer>
  );

  const deleteLocation = (locationId, locationName, machineId = props.info.details.id) => {
    if (props.isInfo && !props.newNode && props.buildType === 'machine') {
      props.machineInfoActions.deleteLocation(machineId, props.id, locationId).then(
        (res) => {
          toastr.success(res.message);
          const newDetails = _.cloneDeep(details);
          if (newDetails.locations) {
            const newLocations = newDetails.locations.filter(location => location.id !== locationId);
            newDetails.locations = newLocations;
          }
          if (newDetails.bearings) {
            Object.entries(newDetails.bearings).forEach(([name, bearing]) => {
              if (bearing.bearing_location_id === locationId) {
                delete newDetails.bearings[name];
              }
            });
          }
          updateDetails(newDetails);
        },
        (error) => {
          toastr.error(error.message);
        }
      );
    } else {
      const newDetails = _.cloneDeep(details);
      if (newDetails.locations) {
        const newLocations = newDetails.locations.filter(location => location.name !== locationName);
        newDetails.locations = newLocations;
      }
      if (newDetails.bearings) {
        delete newDetails.bearings[locationName];
      }
      updateDetails(newDetails);
    }
  };

  const updateLocation = (locationId, attributes, machineId = props.info.details.id) => {
    if (props.isInfo && !props.newNode && props.buildType === 'machine') {
      props.machineInfoActions.updateLocation(machineId, props.id, locationId, attributes).then(
        (res) => {
          toastr.success(res.message);
          const newDetails = _.cloneDeep(details);
          // update other locations
          if (newDetails.locations) {
            const newLocations = newDetails.locations.map((location) => {
              if (location.id === locationId) {
                return { ...location, ...attributes };
              }
              return location;
            });
            newDetails.locations = newLocations;
          }
          // update bearing locations
          if (newDetails.bearings) {
            const oldName = _.findKey(newDetails.bearings, bearing => bearing.bearing_location_id === locationId);
            let newBearings = {};
            Object.keys(newDetails.bearings).forEach((name) => {
              if (name === oldName) {
                const newBearing = { [name]: { ...newDetails.bearings[name], name: attributes.name, description: attributes.description } };
                newBearings = { ...newBearings, ...newBearing };
              } else {
                newBearings = { ...newBearings, [name]: newDetails.bearings[name] };
              }
            });
            newDetails.bearings = newBearings;
          }
          updateDetails(newDetails);
        },
      );
    } else {
      const newDetails = _.cloneDeep(details);
      if (newDetails.locations) {
        const newLocations = newDetails.locations.map((location) => {
          if (location.id === locationId) {
            return { ...location, details };
          }
          return location;
        });
        newDetails.locations = newLocations;
      }
      if (newDetails.bearings) {
        const oldName = _.findKey(newDetails.bearings, bearing => bearing.bearing_location_id === locationId);
        let newBearings = {};
        Object.keys(newDetails.bearings).forEach((name) => {
          if (name === oldName) {
            const newBearing = { [attributes.name]: { ...newDetails.oldName, description: attributes.description } };
            newBearings = { ...newBearings, ...newBearing };
          } else {
            newBearings = { ...newBearings, [name]: newDetails.bearings.name };
          }
        });
        newDetails.bearings = newBearings;
      }
      updateDetails(newDetails);
    }
  };

  const unassignSensor = (locationId, params, serialNumber, model) => {
    props.machineInfoActions.unassignSensor(props.id, locationId, params, serialNumber).then(
      (res) => {
        toastr.success(`Successfully Dissociated ${serialNumber}`);
        const newDetails = _.cloneDeep(details);
        if (newDetails.locations) {
          newDetails.locations = newDetails.locations.map((location) => {
            if (location.id === locationId) {
              location.tx_sensors = location.tx_sensors.filter(tx_sensor => tx_sensor.serial_number !== serialNumber);
              return location;
            }
            return location;
          });
        }
        if (newDetails.bearings) {
          Object.entries(newDetails.bearings).forEach(([name, bearing]) => {
            if (bearing.bearing_location_id === locationId) {
              newDetails.bearings[name].motes = bearing.motes.filter(mote => mote.serial_number !== serialNumber);
              newDetails.bearings[name].tx_sensors = bearing.tx_sensors.filter(tx_sensor => tx_sensor.serial_number !== serialNumber);
            }
          });
        }
        updateDetails(newDetails);
      }
    );
  };

  const assignSensor = (locationId, params, serialNumber, model, orientation, type) => {
    props.machineInfoActions.assignSensor(props.id, locationId, params, serialNumber, orientation, model, type).then(
      (res) => {
        toastr.success(`Successfully Associated ${serialNumber}`);
        const newDetails = _.cloneDeep(details);
        const selectedSensor = {
          component_id: props.id,
          location_id: locationId,
          orientation,
          serial_number: serialNumber,
          model,
          type
        };
        if (newDetails.locations) {
          newDetails.locations = newDetails.locations.map((location) => {
            if (location.id === locationId) {
              location.tx_sensors.push(selectedSensor);
              return location;
            }
            return location;
          });
        }
        if (newDetails.bearings) {
          Object.entries(newDetails.bearings).forEach(([name, bearing]) => {
            if (bearing.bearing_location_id === locationId) {
              if (serialNumber.substr(0, 2) === 'VM') newDetails.bearings[name].motes = bearing.motes.concat([selectedSensor]);
              else newDetails.bearings[name].tx_sensors = bearing.tx_sensors.concat([selectedSensor]);
            }
          });
        }
        updateDetails(newDetails);
      }
    );
  };

  const componentPropertiesSort = (a, b) => {
    if (a[0] === 'graphics') return -1;
    if (b[0] === 'graphics') return 1;
    if (a[0] === 'bearings') return -1;
    if (b[0] === 'bearings') return 1;
    const a_properties = a[1];
    const b_properties = b[1];
    if (_.isArray(a_properties)) return -1;
    if (_.isArray(b_properties)) return 1;
    return a_properties.order > b_properties.order ? 1 : -1;
  };

  const machineTypeOptions = [];
  props.MACHINE_TYPES.forEach((type) => {
    machineTypeOptions.push({
      key: type,
      text: CapitalizeEachWord(type),
      value: type,
    });
  });

  const iso_alarm_limits = [];
  props.metadata.vibration_alarm_limits.iso.forEach((limit) => {
    const warning = Math.round(limit.warning * 100) / 100;
    const critical = Math.round(limit.critical * 100) / 100;
    const units = props.metadata.vibration_alarm_limits.units;
    iso_alarm_limits.push({
      text: String(`${warning} - ${critical} ${units}`),
      value: [limit.warning, limit.critical],
      key: [limit.warning, limit.critical],
    });
  });

  const high_resolution_interval_options = [...props.metadata.measurement_settings &&
    props.metadata.measurement_settings[MEASUREMENT_TYPE.high_resolution], ...ADDITIONAL_AGGRESSIVE_MEASUREMENT_INTERVALS];
  const full_bandwidth_interval_options = [...props.metadata.measurement_settings &&
    props.metadata.measurement_settings[MEASUREMENT_TYPE.full_bandwidth], ...ADDITIONAL_AGGRESSIVE_MEASUREMENT_INTERVALS];

  const machine_properties = {
    unique_id: {
      display_name: 'Unique ID',
      editable: true,
      input_type: 'text',
      placeholder: 'Enter Unique ID',
      order: 0,
      type: 'string',
    },
    sub_type: {
      display_name: 'Asset Subtype',
      editable: false,
      input_type: 'typeahead',
      order: 0,
      type: 'string',
      placeholder: 'Add or Select Asset Subtype',
      options: subtypes
    },
    area_name: props.metadata.machine_properties.area_name,
    description: {
      display_name: 'Description',
      editable: true,
      input_type: 'textarea',
      order: 10,
      type: 'string',
      placeholder: 'Machine description',
    },
    criticality: _.cloneDeep(props.metadata.machine_properties.criticality),
    foundation: _.cloneDeep(props.metadata.machine_properties.foundation),
    orientation: _.cloneDeep(props.metadata.machine_properties.orientation),
    voltmeter_utilization_enabled: {
      display_name: 'Use logic input for utilisation detection',
      editable: true,
      input_type: 'checkbox',
      value: details.voltmeter_utilization_enabled,
      order: 0,
      type: 'string',
      hide: !showVoltmeterUtilizationOptions,
      onChange: () => {
        updateDetails({
          ...details,
          voltmeter_utilization_enabled: !details.voltmeter_utilization_enabled
        });
        onChangeDetailsField({}, { value: !details.voltmeter_utilization_enabled, name: 'voltmeter_utilization_enabled' });
      }
    },
    speed_detection_from_flux_disabled: {
      display_name: 'Disable speed detection from Magnetic Flux',
      editable: true,
      input_type: 'checkbox',
      value: details.speed_detection_from_flux_disabled,
      order: 0,
      type: 'string',
      hide: !showSpeedDetectionSettingOptions,
      onChange: () => {
        updateDetails({
          ...details,
          speed_detection_from_flux_disabled: !details.speed_detection_from_flux_disabled
        });
        onChangeDetailsField({}, { value: !details.speed_detection_from_flux_disabled, name: 'speed_detection_from_flux_disabled' });
      }
    }
  };

  const machine_measurement_methods = {
    state_detection_enabled: _.cloneDeep(
      props.metadata.machine_properties.state_detection_enabled
    ),
    state_detection_interval: {
      ...(
        details.voltmeter_utilization_enabled ? _.cloneDeep(props.metadata.machine_properties.voltmeter_detection_interval) :
          _.cloneDeep(props.metadata.machine_properties.state_detection_interval)
      ),
      hide: !details.state_detection_enabled
    },
    bluetooth_p2p_enabled: {
      ...props.metadata.machine_properties.bluetooth_p2p_enabled
    }
  };

  const machine_measurement_properties = {
    overall_interval: {
      display_name: 'Overalls',
      editable: true,
      input_type: 'select',
      options: high_resolution_interval_options,
      value: 'schedule',
      order: 7,
      type: 'number',
      hide: !details.bluetooth_p2p_enabled
    },
    high_resolution_interval: {
      display_name: 'High Res',
      editable: true,
      input_type: 'select',
      options: high_resolution_interval_options,
      value: 'schedule',
      order: 7,
      type: 'number',
      hide: !details.bluetooth_p2p_enabled
    },
    full_bandwidth_interval: {
      display_name: 'Full Bandwidth',
      editable: true,
      input_type: 'select',
      options: full_bandwidth_interval_options,
      value: 'schedule',
      order: 7,
      type: 'number',
      hide: !details.bluetooth_p2p_enabled
    },
    state_detection_threshold_in_gauss: {
      display_name: 'ON/OFF Threshold',
      editable: true,
      input_type: 'number',
      order: 14,
      type: 'number',
      placeholder: '',
      hide: details.voltmeter_utilization_enabled,
      units: 'Gauss'
    },
    set_schedule: {
      display_name: 'Set Schedule',
      editable: false,
      order: 8
    },
    sync_schedule: {
      display_name: 'Sync Schedule',
      editable: false,
      order: 14
    },
    is_aggressive_measurement: {
      display_name: 'Set Aggressive measurement',
      editable: true,
      input_type: 'checkbox',
      order: 16,
      type: 'boolean',
      value: details.is_aggressive_measurement,
      hide: !details.bluetooth_p2p_enabled,
      onChange: () => {
        updateDetails({
          ...details,
          is_aggressive_measurement: !details.is_aggressive_measurement
        });
        onChangeDetailsField({}, { value: !details.is_aggressive_measurement, name: 'is_aggressive_measurement' });
      }
    },
    num_aggressive_readings: {
      display_name: 'No. of aggressive readings',
      editable: true,
      input_type: 'select',
      options: NUM_AGGRESSIVE_READINGS,
      value: 'schedule',
      order: 17,
      type: 'number',
      hide: !details.is_aggressive_measurement || !details.bluetooth_p2p_enabled
    },
    aggressive_reading_interval: {
      display_name: 'Aggressive reading interval',
      editable: true,
      input_type: 'select',
      options: AGGRESSIVE_INTERVALS,
      value: 'schedule',
      order: 18,
      type: 'number',
      hide: !details.is_aggressive_measurement || !details.bluetooth_p2p_enabled
    }
  };

  const machine_alarms_properties = {
    alarms_type: {
      display_name: 'Vibration Alarms Type',
      editable: true,
      input_type: 'select',
      clearable: true,
      options: [
        {
          text: 'ISO Alarms',
          value: 'iso',
          key: 'iso',
        },
        {
          text: 'User Defined',
          value: 'user_defined',
          key: 'user_defined',
        },
      ],
      order: 9,
      type: 'string',
      onChange: (ev, { value }) => {
        const setVal = { iso: false, user_defined: false };
        setVal[value] = true;
        setAlarmsType(setVal);
      }
    },
    alarms_limits: {
      display_name: 'Limits (Warning - Critical)',
      editable: true,
      input_type: 'select',
      options: iso_alarm_limits,
      order: 10,
      type: 'string',
      hide: !alarmsType.iso,
    },
    warning_limit: {
      display_name: 'Warning - Limit',
      editable: true,
      input_type: 'number',
      order: 10,
      type: 'number',
      placeholder: '',
      units: props.metadata.vibration_alarm_limits.units,
      hide: !alarmsType.user_defined,
    },
    critical_limit: {
      display_name: 'Critical - Limit',
      editable: true,
      input_type: 'number',
      order: 10,
      type: 'number',
      placeholder: '',
      units: props.metadata.vibration_alarm_limits.units,
      hide: !alarmsType.user_defined
    }
  };

  const machine_analysis_properties = {
    health_score_method_preference: _.cloneDeep(
      props.metadata.machine_properties.health_score_method_preference
    ),
    asset_off_vibration_threshold: {
      display_name: 'Minimum Vibration Threshold',
      editable: true,
      input_type: 'number',
      order: 15,
      type: 'number',
      placeholder: '',
      units: 'g'
    },
    forcing_frequencies: {
      display_name: 'View',
      editable: false,
      order: 11
    }
  };

  if (props.mcType) {
    Object.entries(EXCLUDE_FROM_MACHINE_PROPERTIES[props.mcType]).forEach(([idx, property]) => {
      delete machine_properties[property];
    });
  }

  // TOTO - add this to machine_builder metadata coming from backend.
  const template_properties = {
    asset_type: {
      display_name: 'Type',
      editable: false,
      input_type: 'select',
      order: 0,
      type: 'string',
      placeholder: 'Select',
      options: machineTypeOptions,
      onChange: (ev, { value }) => {
        props.chooseMcType(value);
      }
    },
    sub_type: {
      display_name: 'Subtype',
      editable: props.mcType === 'rotating',
      input_type: 'typeahead',
      order: 0,
      type: 'string',
      placeholder: 'Add or Select Machine Subtype',
      options: subtypes,
      onAddItem: handleSubtypeAddition,
      require: true
    },
    description: {
      display_name: 'Description',
      editable: true,
      input_type: 'textarea',
      order: 10,
      type: 'string',
      placeholder: 'Template Description',
      require: true
    },
    criticality: _.cloneDeep(props.metadata.machine_properties.criticality),
    foundation: _.cloneDeep(props.metadata.machine_properties.foundation),
    orientation: _.cloneDeep(props.metadata.machine_properties.orientation),
  };

  const template_measurement_methods = {
    state_detection_enabled: _.cloneDeep(props.metadata.machine_properties.state_detection_enabled),
    state_detection_interval: {
      ...(_.cloneDeep(props.metadata.machine_properties.state_detection_interval)),
      hide: !details.state_detection_enabled
    },
    bluetooth_p2p_enabled: {
      ...props.metadata.machine_properties.bluetooth_p2p_enabled,
      hide: !details.state_detection_enabled || !props.currentAccount.preferences.state_based_measurements
    },
  };

  const template_measurement_properties = {
    overall_interval: {
      display_name: 'Overalls',
      editable: true,
      input_type: 'select',
      options: high_resolution_interval_options,
      value: 'schedule',
      order: 7,
      type: 'number',
      hide: !details.bluetooth_p2p_enabled
    },
    high_resolution_interval: {
      display_name: 'High Res',
      editable: true,
      input_type: 'select',
      options: high_resolution_interval_options,
      value: 'schedule',
      order: 7,
      type: 'number',
      hide: !details.bluetooth_p2p_enabled
    },
    full_bandwidth_interval: {
      display_name: ' Full Bandwidth',
      editable: true,
      input_type: 'select',
      options: full_bandwidth_interval_options,
      value: 'schedule',
      order: 7,
      type: 'number',
      hide: !details.bluetooth_p2p_enabled
    },
    is_aggressive_measurement: {
      display_name: 'Set Aggressive measurement',
      editable: true,
      input_type: 'checkbox',
      order: 15,
      type: 'boolean',
      value: details.is_aggressive_measurement,
      hide: !details.bluetooth_p2p_enabled,
      onChange: () => {
        updateDetails({
          ...details,
          is_aggressive_measurement: !details.is_aggressive_measurement
        });
        onChangeDetailsField({}, { value: !details.is_aggressive_measurement, name: 'is_aggressive_measurement' });
      }
    },
    num_aggressive_readings: {
      display_name: 'No. of aggressive readings',
      editable: true,
      input_type: 'select',
      options: NUM_AGGRESSIVE_READINGS,
      value: 'schedule',
      order: 16,
      type: 'number',
      hide: !details.is_aggressive_measurement || !details.bluetooth_p2p_enabled
    },
    aggressive_reading_interval: {
      display_name: 'Aggressive reading interval',
      editable: true,
      input_type: 'select',
      options: AGGRESSIVE_INTERVALS,
      value: 'schedule',
      order: 17,
      type: 'number',
      hide: !details.is_aggressive_measurement || !details.bluetooth_p2p_enabled
    },
    set_schedule: {
      display_name: 'Set Schedule',
      editable: false,
      order: 8
    },
    sync_schedule: {
      display_name: 'Sync Schedule',
      editable: false,
      order: 14
    },
  };

  const template_alarms_properties = {
    alarms_type: {
      display_name: 'Type',
      editable: true,
      input_type: 'select',
      clearable: true,
      options: [
        {
          text: 'ISO Alarms',
          value: 'iso',
          key: 'iso',
        },
        {
          text: 'User Defined',
          value: 'user_defined',
          key: 'user_defined',
        }
      ],
      order: 9,
      type: 'string',
      onChange: (ev, { value }) => {
        const setVal = { iso: false, user_defined: false };
        setVal[value] = true;
        setAlarmsType(setVal);
      }
    },
    alarms_limits: {
      display_name: 'Limits (Warning - Critical)',
      editable: true,
      input_type: 'select',
      options: iso_alarm_limits,
      order: 10,
      type: 'array',
      hide: !alarmsType.iso,
    },
    warning_limit: {
      display_name: 'Warning - Limit',
      editable: true,
      input_type: 'number',
      order: 10,
      type: 'number',
      placeholder: '',
      units: props.metadata.vibration_alarm_limits.units,
      hide: !alarmsType.user_defined,
    },
    critical_limit: {
      display_name: 'Critical - Limit',
      editable: true,
      input_type: 'number',
      order: 10,
      type: 'number',
      placeholder: '',
      units: props.metadata.vibration_alarm_limits.units,
      hide: !alarmsType.user_defined,
    },
  };

  const template_analysis_properties = {
    forcing_frequencies: {
      display_name: 'View Forcing Frequencies',
      editable: false,
      order: 11,
    },
    is_global: {
      display_name: 'Save as Global Template',
      editable: true,
      input_type: 'checkbox',
      order: 10,
      type: 'boolean',
      value: props.saveAsGlobal,
      onChange: (value) => {
        props.setSaveAsGlobal(value);
      }
    },
  };

  const template_analysis_vibration_properties = {
    lower_bandpass_cutoff: {
      display_name: 'Lower Cut Off',
      editable: true,
      input_type: 'number',
      order: 12,
      type: 'number',
      placeholder: '',
      units: props.currentUser.frequency_units,
    },
    upper_bandpass_cutoff: {
      display_name: 'Upper Cut Off',
      editable: true,
      input_type: 'number',
      order: 13,
      type: 'number',
      placeholder: '',
      units: props.currentUser.frequency_units,
    }
  };

  if (props.mcType) {
    Object.entries(EXCLUDE_FROM_TEMPLATE_PROPERTIES[props.mcType]).forEach(([idx, property]) => {
      delete template_properties[property];
    });
  }

  const component_properties_common = {
    locations: {
      display_name: 'Add Location',
      editable: false,
      order: 0
    }
  };

  if (props.nonRotatingSubtype === 'tank') component_properties_common.graphics = {
    order: -1
  };

  if (!isPetasenseAdmin(props.currentUser) || props.isInfo) {
    delete template_properties.is_global;
  }

  const toggleDiagnosticRules = (id) => {
    let rules = rulesSelected;
    if (rulesSelected.includes(id)) rules = rulesSelected.filter(item => item !== id);
    else rules.push(id);
    setSelectedRules(rules);
    onChangeDetailsField({}, { value: rules, name: 'diagnostic_rules' });
  };

  const selectAllRules = (ids) => {
    setSelectedRules(ids);
    onChangeDetailsField({}, { value: ids, name: 'diagnostic_rules' });
  };

  const getRuleOptions = () => {
    const assetOptions = [
      ...(props.rules || []), ...(ruleDetails || [])
    ].map(rule => ({ value: rule.id, text: rule.name }));

    return _.sortBy(_.uniqBy(assetOptions, 'value'), 'text');
  };

  const renderDiagnosticRules = disable => (
    <>
      <ResourceSection>Diagnostic Rules</ResourceSection>
      <MultiSelectDropdown
        className="ed-multi-select-dropdown"
        optionsAvailable={getRuleOptions()}
        selectedOptions={rulesSelected}
        toggleOption={id => toggleDiagnosticRules(id)}
        placeholder="Search rules select from the list"
        typeOfOptions="Diagnostic Rules"
        addAllResources={ids => selectAllRules(ids)}
        disabled={disable}
        fontSize="12px"
      />
    </>
  );

  const isFieldIncomplete = (key, missingFields) => missingFields.indexOf(key) !== -1;

  const renderBearingDetails = (items, idx, shaftNumber) => (
    <BearingDetails
      columnSizes={[32, 18, 12, 12, 12, 12]}
      key={`bearing-${idx}`}
      order={idx}
      bearings={items}
      openBearingModal={openBearingModal}
      unassignBearingDetails={unassignBearingDetails}
      deleteLocation={deleteLocation}
      updateLocation={updateLocation}
      onLocationNameSubmit={onBearingLocationNameSubmit}
      unassignSensor={unassignSensor}
      assignSensor={assignSensor}
      unassociatedSensors={props.sensors.unassociatedItems}
      sensorsMetadata={props.sensors.metadata.metadata}
      newSensorDisabled={props.newNode || props.buildType !== 'machine' || !props.isInfo}
      editBearingDisabled={props.newNode || !props.isInfo}
      shaftNumber={shaftNumber}
    />
  );

  const updatedBearingDetails = () => {
    const newDetails = _.cloneDeep(details);
    const noOfShafts = details.no_of_shafts;
    if (!noOfShafts) return;
    if (!details.bearings) {
      newDetails.bearings = {};
      for (let shaftNum = 1; shaftNum <= 2 * details.no_of_shafts; shaftNum++) {
        newDetails.bearings[`L${shaftNum}`] = {
          bearing_location_id: null,
          location_type_id: true,
          id: null,
          motes: [],
          tx_sensors: [],
          properties: _.cloneDeep(props.metadata.bearing_properties),
          shaft_number: Math.floor((shaftNum + 1) / 2)
        };
      }
    } else {
      let detailArray = Object.entries(newDetails.bearings).map(([name, bearing]) => ({
        id: (bearing && bearing.id) || null,
        location_type_id: (bearing && bearing.location_type_id) || null,
        bearing_location_id: (bearing && bearing.bearing_location_id) || null,
        key: name,
        description: (bearing && bearing.description) || null,
        name,
        motes: (bearing && bearing.motes) || [],
        tx_sensors: (bearing && bearing.tx_sensors) || [],
        properties: (bearing && bearing.properties) || _.cloneDeep(props.metadata.bearing_properties),
        shaft_number: bearing.shaft_number
      }));

      const prevNoOfShafts = _.max(detailArray.map(b => b.shaft_number));
      if (prevNoOfShafts === noOfShafts) return;
      if (prevNoOfShafts > noOfShafts) {
        detailArray = detailArray.filter(bearing => bearing && bearing.shaft_number <= noOfShafts);
      } else {
        for (let shaftNum = 2 * prevNoOfShafts + 1; shaftNum <= 2 * details.no_of_shafts; shaftNum++) {
          detailArray.push({
            id: null,
            location_type_id: true,
            key: shaftNum,
            name: `L${shaftNum}`,
            motes: [],
            tx_sensors: [],
            properties: _.cloneDeep(props.metadata.bearing_properties),
            shaft_number: Math.floor((shaftNum + 1) / 2),
          });
        }
      }
      newDetails.bearings = {};
      for (let shaftNum = 0; shaftNum < detailArray.length; shaftNum++) {
        const bearing = detailArray[shaftNum].name;
        newDetails.bearings[bearing] = detailArray[shaftNum];
      }
    }
    setTimeout(() => updateDetails(newDetails), 200);
  };

  const prepareShaftBearingDetails = () => {
    let items = [];
    if (!details.bearings) return [];
    items = Object.entries(details.bearings).map(([name, bearing]) => ({
      id: (bearing && bearing.id) || null,
      location_type_id: (bearing && bearing.location_type_id) || null,
      bearing_location_id: (bearing && bearing.bearing_location_id) || null,
      key: name,
      description: (bearing && bearing.description) || null,
      name: bearing.name || name,
      motes: (bearing && bearing.motes) || [],
      tx_sensors: (bearing && bearing.tx_sensors) || [],
      properties: (bearing && bearing.properties) || _.cloneDeep(props.metadata.bearing_properties),
      shaft_number: bearing.shaft_number
    }));
    return items;
  };

  const renderShaftAndStageLabels = (shaftNumber, shaftName, stageNumber) => (
    <>
      <ShaftNumberContainer>{shaftName}</ShaftNumberContainer>
      {shaftNumber >= 3 && (
        <FlexContainer padding="0px 0px 10px 0px">
          <Info />
          <ShaftDescription>
            Stage {stageNumber} Input Shaft is Stage {stageNumber - 1}{' '}
            Intermediate Shaft
          </ShaftDescription>
        </FlexContainer>
      )}
    </>
  );

  const renderStageInputField = (shaftProperties, idx) => {
    const shaftCount = details.no_of_shafts;
    const bearings = prepareShaftBearingDetails();
    const stageSections = {};

    for (let shaftNumber = 1; shaftNumber <= shaftCount; shaftNumber++) {
      const stageNumber = shaftNumber === 1 ? 1 : shaftNumber - 1;
      const shaftName = getShaftName(shaftNumber, shaftCount);
      const bearingItem = bearings.filter(
        bearing => bearing && bearing.shaft_number === shaftNumber
      );

      const stageContent = [];
      Object.entries(shaftProperties).forEach(([key, value]) => {
        const inputType = value.input_type || 'text';
        const valueType = value.type;
        if (key === 'shaft_number') {
          return;
        }
        const shaftInfo = details && details.shaft_info ? details.shaft_info[shaftNumber - 1] : null;
        const missingFields = props.getMissingFields(props.id, props.nodeType);
        const isIncomplete = isFieldIncomplete(key, missingFields);
        const inputProps = {
          id: `${key}-${idx}-${shaftNumber}`,
          name: key,
          value: shaftInfo ? shaftInfo[key] : null,
          type: inputType,
          label: value.display_name || CapitalizeEachWord(key.replace(/[_]/g, ' ')),
          selectOnBlur: false,
          prefix: value.units,
          placeholder: value.placeholder ? value.placeholder : 'Enter value',
          disabled: false,
          order: value.order,
          isIncomplete,
          onChange: (e, data) => {
            onChangeShaftDetails(e, data, value.onChange, shaftNumber);
          },
          isMissing: missingFields => missingFields.indexOf(inputProps.id) !== -1
        };

        switch (inputType) {
          case 'text':
            if (valueType === 'string') {
              inputProps.type = 'text';
              inputProps.maxLength = 40;
            }
            if (valueType === 'integer') {
              inputProps.type = 'number';
              inputProps.step = 1;
              inputProps.maxLength = 10;
            }
            if (valueType === 'number') {
              inputProps.type = 'number';
              inputProps.maxLength = 10;
            }
            break;
          case 'select':
            inputProps.options = value.options;
            inputProps.placeholder = 'Select value';
            break;
          default:
            break;
        }
        stageContent.push(
          <>
            {shaftNumber !== 1 && (
              <RBAC
                key={`componetDetails-${idx}`}
                resource={mapComponentToResource.ComponentDetails}
                operation={operations.Update}
                yes={(
                  <FlexContainer direction="column">
                    <Grid columns={3} doubling stackable>
                      <Column>
                        <ResourceInputField {...inputProps} />
                      </Column>
                    </Grid>
                    {isIncomplete && (
                      <WarningDescription>
                        Recommended to set {humanize(inputProps.id)} for better
                        Analysis
                      </WarningDescription>
                    )}
                  </FlexContainer>
                )}
                no={(
                  <RowContainer key={`${key}-${idx}-${props.id}`}>
                    <InputFieldBold {...inputProps} disabled />
                  </RowContainer>
                )}
              />
            )}
          </>
        );
      });

      if (!stageSections[stageNumber]) {
        stageSections[stageNumber] = [];
      }
      stageSections[stageNumber].push(
        <div
          key={`stage-${shaftNumber}`}
          style={{ padding: '10px', marginBottom: '20px' }}
        >
          {renderShaftAndStageLabels(shaftNumber, shaftName, stageNumber)}
          <div style={{ marginTop: '20px' }}>
            <Grid columns={1}>{stageContent}</Grid>
          </div>
          <div style={{ marginTop: '20px' }}>
            {renderBearingDetails(bearingItem, idx, shaftNumber)}
          </div>
        </div>
      );
    }

    return (
      <div>
        {Object.entries(stageSections).map(
          ([stageNumber, stageGroup], index) => (
            <Accordion
              title={`Stage ${index + 1}`}
              color="grey"
              fontsize="16px"
              open
              fontweight="600"
              width="100%"
            >
              {stageGroup}
            </Accordion>
          )
        )}
      </div>
    );
  };

  const handleInputLabels = inputProps => (
    <>
      {inputProps.id === 'set_schedule' && (
        <SettingsLabel>Backup Schedule</SettingsLabel>
      )}
      {inputProps.label === 'Speed Detection' && (
        <SettingsLabel>Settings</SettingsLabel>
      )}
      {inputProps.id === 'overall' && (
        <SettingsLabel>Measurement</SettingsLabel>
      )}
    </>
  );

  const isEmptySchedule = () => {
    if (!_.isEmpty(details && details.schedule && details.schedule[MEASUREMENT_TYPE.full_bandwidth] && details.schedule[MEASUREMENT_TYPE.full_bandwidth].schedule)) return false;
    if (!_.isEmpty(details && details.schedule && details.schedule[MEASUREMENT_TYPE.overall] && details.schedule[MEASUREMENT_TYPE.overall].schedule)) return false;
    if (!_.isEmpty(details && details.schedule && details.schedule[MEASUREMENT_TYPE.high_resolution] && details.schedule[MEASUREMENT_TYPE.high_resolution].schedule)) return false;
    return true;
  };

  const handleToggleClick = () => {
    updateDetails({
      ...details,
      is_aggressive_measurement: !details.is_aggressive_measurement
    });
    onChangeDetailsField(
      {},
      {
        value: !details.is_aggressive_measurement,
        name: 'is_aggressive_measurement'
      }
    );
  };

  const renderInputField = (key, property, idx) => {
    const inputType = property.input_type || 'text';
    const valueType = property.type;
    const hide = property.hide || false;
    let value = details[key];
    const missingFields = props.getMissingFields(props.id, props.nodeType);
    const isIncomplete = isFieldIncomplete(key, missingFields);
    const drivenComponents = props.metadata.components.driven.map(component => component.value);
    const require = property.require || false;

    if (value === undefined || value === null) value = property.value ? property.value : '';
    if (key === 'shaft_info') {
      return renderStageInputField(property.properties, idx);
    }
    if (key === 'is_speed_derived' || key === 'on_vfd_asset') return '';
    if (key === 'bearings') {
      let items = [];
      if (property.length === 0 || details.component_type === 'multi_stage_gearbox') return '';

      if (!details.bearings) {
        const newDetails = _.cloneDeep(details);
        if (!newDetails.bearings) newDetails.bearings = {};
        property.forEach((name) => {
          newDetails.bearings[name] = {
            bearing_location_id: null,
            location_type_id: true,
            id: null,
            motes: [],
            tx_sensors: [],
            properties: _.cloneDeep(props.metadata.bearing_properties),
          };
          items.push({
            id: null,
            location_type_id: true,
            key: name,
            name,
            motes: [],
            tx_sensors: [],
            properties: _.cloneDeep(props.metadata.bearing_properties)
          });
        });

        updateDetails(newDetails);
      } else if (
        props.newNode &&
        !_.isEqual(
          details.bearings,
          props.metadata.component_properties[details.component_type].bearings
        )
      ) {
        property.forEach(([name], idx) => {
          details.bearings[name] = {
            bearing_location_id: null,
            location_type_id: true,
            id: null,
            motes: [],
            tx_sensors: [],
            properties: _.cloneDeep(props.metadata.bearing_properties)
          };
          items.push({
            id: null,
            location_type_id: true,
            bearing_location_id: null,
            key: name,
            description: null,
            motes: [],
            tx_sensors: [],
            name:
              props.metadata.component_properties[details.component_type]
                .bearings[idx],
            properties: _.cloneDeep(props.metadata.bearing_properties)
          });
        });
      } else {
        items = Object.entries(details.bearings).map(([name, bearing]) => ({
          id: bearing && bearing.id ? bearing.id : null,
          location_type_id:
            bearing && bearing.location_type_id
              ? bearing.location_type_id
              : null,
          bearing_location_id:
            bearing && bearing.bearing_location_id
              ? bearing.bearing_location_id
              : null,
          key: name,
          description:
            bearing && bearing.description ? bearing.description : null,
          name: bearing && bearing.name ? bearing.name : name,
          motes: bearing && bearing.motes ? bearing.motes : [],
          tx_sensors: bearing && bearing.tx_sensors ? bearing.tx_sensors : [],
          properties:
            bearing && bearing.properties
              ? bearing.properties
              : _.cloneDeep(props.metadata.bearing_properties)
        }));
      }


      return renderBearingDetails(items, idx);
    }

    if (key === 'locations') {
      let items = [];
      if (!details.locations) {
        const newDetails = _.cloneDeep(details);
        if (!newDetails.locations) newDetails.locations = [];
        if (_.isArray(property)) property.forEach((name) => {
          const locationDetails = {
            id: null,
            description: '',
            location_type_id: true,
            name,
            tx_sensors: []
          };
          newDetails.locations.push(locationDetails);
          items.push(locationDetails);
        });
        updateDetails(newDetails);
      } else {
        items = details.locations || [];
        items = items.map(item => ({
          id: item.id || null,
          description: item.description || '',
          location_type_id: item.location_type_id || false,
          name: item.name || '',
          tx_sensors: item.tx_sensors || []
        }));
      }
      return (
        <>
          <OtherLocationDetails
            key={`locations-${idx}`}
            order={idx}
            items={items}
            deleteLocation={deleteLocation}
            updateLocation={updateLocation}
            onLocationNameSubmit={onLocationNameSubmit}
            unassignSensor={unassignSensor}
            assignSensor={assignSensor}
            unassociatedSensors={props.sensors.unassociatedItems}
            newSensorDisabled={
              props.newNode || props.buildType !== 'machine' || !props.isInfo
            }
            editLocationDisabled={props.newNode || !props.isInfo}
          />
        </>
      );
    }
    if (key === 'forcing_frequencies') {
      return (
        <>
          <ResourceItemLabel>Forcing Frequencies</ResourceItemLabel>
          <Fragment>
            <LinksContainer>
              <Button
                fontSize="11px"
                onClick={() => {
                  setForcingFreqModalOpen(true);
                }}
              >
                {property.display_name}
              </Button>
            </LinksContainer>
          </Fragment>
        </>
      );
    }
    if (key === 'set_schedule') {
      const displayName = details.bluetooth_p2p_enabled
        ? 'Backup Schedule'
        : 'Measurement Schedule';
      if (!hide) {
        return (
          <Column>
            <Segment>
              <ResourceItemLabel>{displayName}</ResourceItemLabel>
              <ResourceItem>
                <FlexContainer
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    fontSize="11px"
                    onClick={() => {
                      setScheduleModalOpen(true);
                    }}
                    marginRight="15px"
                  >
                    Set
                  </Button>
                  <Button
                    onClick={() => {
                      setConfirmSyncSchedule(true);
                    }}
                    disabled={isEmptySchedule()}
                    text
                    title="Sync"
                  >
                    <RefreshSvg height="12px" />
                  </Button>
                </FlexContainer>
              </ResourceItem>
            </Segment>
          </Column>
        );
      }
      return undefined;
    }
    if (key === 'sync_schedule') {
      if (!hide) {
        return (
          <Fragment>
            <LinksContainer />
          </Fragment>
        );
      }
      return undefined;
    }
    if (key === 'is_aggressive_measurement') {
      return (
        <FlexContainer direction="column">
          <ResourceItemLabel>Set Aggressive Measurement</ResourceItemLabel>
          <RowContainer key={`${key}-${idx}-${props.id}`}>
            {details.is_aggressive_measurement ? (
              <ToggleOn onClick={() => handleToggleClick()} />
            ) : (
              <ToggleOff onClick={() => handleToggleClick()} />
            )}
          </RowContainer>
        </FlexContainer>
      );
    }

    if (key === 'dimensions') {
      if (hide) return null;
      const dimensions = Object.entries(property)
        .filter(([dimensionKey]) => !_.includes(['editable', 'order', 'type'], dimensionKey));

      return (
        <Dimensions
          dimensions={dimensions}
          value={value}
          isIncomplete={isIncomplete}
          onChangeField={onChangeDetailsField}
        />
      );
    }

    if (key === 'graphics') {
      return renderComponentGraphics();
    }
    if (key === 'speed_bins_config') return null;
    const inputProps = {
      id: key,
      error: errors[key],
      name: key,
      value,
      type: inputType,
      label: property.display_name || CapitalizeEachWord(key.replace(/[_]/g, ' ')),
      selectOnBlur: false,
      prefix: property.units,
      placeholder: property.placeholder ? property.placeholder : 'Enter value',
      disabled: !property.editable,
      order: property.order,
      require,
      isIncomplete,
      onChange: (e, data) => {
        onChangeDetailsField(e, data, property.onChange);
      },
      isMissing: missingFields => missingFields.indexOf(inputProps.id) !== -1
    };

    if (key === 'line_frequency') {
      inputProps.label = ' Line Frequency (Hz)';
      if (
        !details.line_frequency &&
        props.currentAccount.preferences &&
        props.currentAccount.preferences.line_frequency
      ) updateDetails({
        ...details,
        line_frequency: props.currentAccount.preferences.line_frequency
      });
    }
    if (key === 'no_of_shafts') {
      inputProps.label = 'No of Stages';
      inputProps.disabled = !props.newNode && props.isInfo;
    }
    if (key === 'bluetooth_p2p_enabled') {
      inputProps.disabled =
        !details.state_detection_enabled ||
        !props.currentAccount.preferences.state_based_measurements;
    }
    if (key === 'gear_ratio' && details.component_type === 'multi_stage_gearbox') {
      inputProps.label = 'Overall Gear Ratio';
    }
    if (key === 'speed') {
      if (details.on_vfd_asset
        || (props.newNode && props.isInfo && _.includes(drivenComponents, details.component_type))
        || (!props.newNode && !props.isInfo && _.includes(drivenComponents, details.component_type))) return '';
      if (details.is_speed_derived) inputProps.disabled = true;
    }

    switch (inputType) {
      case 'button':
        inputProps.options = property.options;
        inputProps.placeholder = 'Select value';
        break;
      case 'text':
        if (valueType === 'string') {
          inputProps.type = 'text';
          inputProps.maxLength = 40;
        }
        if (valueType === 'integer') {
          inputProps.type = 'number';
          inputProps.step = 1;
          inputProps.maxLength = 10;
        }
        if (valueType === 'number') {
          inputProps.type = 'number';
          inputProps.maxLength = 10;
        }
        break;
      case 'select':
        inputProps.options = property.options;
        inputProps.clearable = property.clearable || false;
        inputProps.placeholder = 'Select value';
        break;
      case 'typeahead':
        inputProps.type = 'select';
        inputProps.maxLength = 40;
        inputProps.placeholder = property.placeholder ? property.placeholder : 'Add or Select Value';
        inputProps.search = true;
        inputProps.options = property.options ? property.options : areas;
        break;
      case 'radiobutton':
        inputProps.type = 'radio';
        return (
          <RadioButtonContainer key={`radio-${idx}`} order={inputProps.order}>
            <SpanLabel>{inputProps.label}</SpanLabel>
            <VfdContainer>
              <RBAC
                resource={mapComponentToResource.ComponentDetails}
                operation={operations.Update}
                yes={(
                  <InputField
                    {...inputProps}
                    id="vfd-yes"
                    value={inputProps.value === '' ? false : inputProps.value}
                    label="Yes"
                    labelSide="right"
                    onChange={() =>
                      onChangeDetailsField(
                        {},
                        { value: true, name: inputProps.name }
                      )
                    }
                  />
                )}
                no={props.value && (
                  <InputField
                    {...inputProps}
                    value={inputProps.value === '' ? false : inputProps.value}
                    label="Yes"
                    labelSide="right"
                    disabled
                  />
                )
                }
              />
              <RBAC
                resource={mapComponentToResource.ComponentDetails}
                operation={operations.Update}
                yes={(
                  <InputField
                    {...inputProps}
                    id="vfd-no"
                    value={inputProps.value === '' ? true : !inputProps.value}
                    label="No"
                    labelSide="right"
                    onChange={() =>
                      onChangeDetailsField(
                        {},
                        { value: false, name: inputProps.name }
                      )
                    }
                  />
                )}
                no={props.value && (
                  <InputField
                    {...inputProps}
                    value={inputProps.value === '' ? true : !inputProps.value}
                    label="No"
                    labelSide="right"
                    disabled
                  />
                )
                }
              />
            </VfdContainer>
            {value && key === 'vfd' && (
              <RBAC
                resource={mapComponentToResource.ComponentDetails}
                operation={operations.Update}
                yes={renderVfdFields(false)}
                no={renderVfdFields(true)}
              />
            )}
            {filterError && (
              <Label id="vfd-error" error> {filterError} </Label>
            )}
            {speedBinError && (
              <Label id="vfd-speed-bin-error" error>{speedBinError}</Label>
            )}
          </RadioButtonContainer>
        );
      case 'list':
        return null;
      default:
        break;
    }
    return (
      <>
        {handleInputLabels(inputProps)}
        <RBAC
          key={`componetDetails-${idx}`}
          resource={mapComponentToResource.ComponentDetails}
          operation={operations.Update}
          yes={
            !hide && (
              <>
                <FlexContainer direction="column">
                  <FlexContainer direction="row">
                    <ResourceInputField {...inputProps} />
                    {key === 'asset_off_vibration_threshold' && (
                      <TooltipContainer>
                        <Info
                          onMouseEnter={() => setShowTooltip(true)}
                          onMouseLeave={() => setShowTooltip(false)}
                        />
                        {showTooltip && (
                          <TooltipText>
                            This &apos;g&apos; level serves as the minimum threshold for evaluating alarms and computing the asset health score. Setting this parameter allows for effective management of false alarms and asset health score trend distortions caused by sympathetic vibrations transmitted from neighboring machines.
                          </TooltipText>
                        )}
                      </TooltipContainer>
                    )}
                  </FlexContainer>
                  {isIncomplete &&
                    !(key === 'speed' && details.is_speed_derived) && (
                      <WarningDescription>
                        Recommended to set {humanize(inputProps.id)} for better
                        Analysis
                      </WarningDescription>
                  )}
                </FlexContainer>
              </>
            )
          }
          no={
            !hide && (
              <RowContainer>
                <InputFieldBold {...inputProps} disabled />
              </RowContainer>
            )
          }
        />
        {props.isInfo &&
          details.device &&
          inputProps.label === 'Speed Detection' && (
            <FlexContainer marginbottom="0.5em" width="388px">
              <Info aria-hidden="true" size="1x" />
              <DescriptionLabel>
                Detected by {details.device}{' '}
                {descriptionOptions[details.device.slice(0, 3)]}
              </DescriptionLabel>
            </FlexContainer>
        )}
      </>
    );
  };

  const renderComponentGraphics = () => (
    <ComponentSvgContainer>
      <ComponentGraphics
        components={[details]}
        svgWidth="30em"
        svgHeight="30em"
      />
    </ComponentSvgContainer>
  );

  const renderVfdFields = disabled => (
    <Grid columns={3} doubling stackable>
      <Column>
        <ResourceInputField
          id="min-vfd"
          label="Min Speed"
          placeholder="Enter in Speed units"
          type="number"
          prefix={props.metadata.component_properties[details.component_type].seed_speed &&
            props.metadata.component_properties[details.component_type].seed_speed.units}
          value={details.seed_speed && details.seed_speed[0]}
          onChange={onChangeVfdMin}
          disabled={disabled}
        />
      </Column>
      <Column>
        <ResourceInputField
          id="max-vfd"
          label="Max Speed"
          placeholder="Enter in Speed units"
          type="number"
          prefix={props.metadata.component_properties[details.component_type].seed_speed &&
            props.metadata.component_properties[details.component_type].seed_speed.units}
          value={details.seed_speed && details.seed_speed[1]}
          onChange={onChangeVfdMax}
          disabled={disabled}
        />
      </Column>
      <Column>
        <ResourceInputField
          id="bin-count"
          label="No. of Speed Ranges"
          type="number"
          value={details.speed_bins_config && details.speed_bins_config.no_of_bins}
          onChange={onChangeBinCount}
          disabled={disabled}
        />
      </Column>
      <Grid.Row>
        {showGenerateButton && (
          <Column>
            <GenerateButton
              id="generate-button"
              text
              disabled={disabled || filterError}
              onClick={onClickGenerate}
            >
              Generate
            </GenerateButton>
          </Column>
        )}
      </Grid.Row>
      {!_.isEmpty(details.speed_bins_config) &&
        details.speed_bins_config.speed_bins &&
        details.speed_bins_config.speed_bins.map(bin => (
          <Column>
            <ResourceInputField
              id={`speed-bin-${bin.bin_no}`}
              label={`Speed Range ${bin.bin_no}`}
              type="text"
              value={bin.range_text !== undefined ? bin.range_text : `${bin.bin_range[0]}-${bin.bin_range[1]}`}
              prefix={props.metadata.component_properties[details.component_type].seed_speed && props.metadata.component_properties[details.component_type].seed_speed.units}
              onChange={e => onChangeSpeedBin(e.target.value, bin.bin_no)}
            />
          </Column>
        ))}
    </Grid>
  );

  const saveForcingFrequencies = (forcingFrequencies) => {
    initialForcingFrequencies = forcingFrequencies;
    props.saveForcingFrequencies(forcingFrequencies);
  };

  const renderComponentProperties = () => {
    const properties = Object.entries({
      bearings: [],
      ...component_properties_common,
      ...props.metadata.component_properties[details.component_type],
    });

    if (props.nonRotatingSubtype === 'tank') {
      properties.sort(componentPropertiesSort);
    }

    const [locationDetails, nonLocationDetails] = [properties.slice(0, 2), properties.slice(2)];

    nonLocationDetails.sort((a, b) => {
      const orderA = a[1].order || Infinity;
      const orderB = b[1].order || Infinity;

      return orderA - orderB;
    });

    return (
      <>
        {locationDetails.map(([key, details], idx) => {
          if (
            details.type !== 'list' &&
            !(
              details.type === 'boolean' && details.input_type !== 'radiobutton'
            )
          ) {
            return <div key={key}>{renderInputField(key, details, idx)}</div>;
          }
          return null;
        })}
        {nonLocationDetails && nonLocationDetails.length>0 && (
          <>
            <ResourceSection>Properties</ResourceSection>
            <BorderContainer>
              <Grid columns={3} doubling stackable>
                {nonLocationDetails
                  .filter(([key]) => key !== 'vfd')
                  .map(([key, details], idx) => {
                    if (details.type !== 'list' &&
                      !(
                        details.type === 'boolean' &&
                        details.input_type !== 'radiobutton'
                      )) {
                      return (
                        <Column key={key}>
                          {renderInputField(key, details, idx)}
                        </Column>
                      );
                    }
                    return null;
                  })}
              </Grid>
              <Grid>
                {nonLocationDetails
                  .filter(([key]) => key === 'vfd')
                  .map(([key, details], idx) => (
                    <Column key={key}>
                      {renderInputField(key, details, idx)}
                    </Column>
                  ))}
              </Grid>
            </BorderContainer>
          </>
        )}
      </>
    );
  };

  const showComponentTypeEditIcon = () => {
    if (props.mcType !== 'rotating') return false;

    const compType = details.component_type;
    const foundType = rotatingComponentTypes.find(type =>
      !!type.children.find(
        child => child.value === compType
      )
    );
    const functionType = foundType && foundType.text;
    return functionType === 'Drive' || functionType === 'Driven';
  };

  const sortProperties = properties => Object.entries(properties)
    .filter(([key, details]) => !details.hide)
    .sort(([, propA], [, propB]) => propA.order - propB.order);

  const primaryColor = props.primaryColor;

  return (
    <>
      {props.isInfo && props.buildType === 'machine' && nodeType === 'machine' && (
        <RBAC
          resource={mapComponentToResource.Machines}
          operation={operations.Delete}
          yes={(
            <MoreOptions>
              {getMachineMenuItems()}
              <AccountMenuItems />
            </MoreOptions>
          )}
          no={(
            <MoreOptions>
              <AccountMenuItems />
            </MoreOptions>
          )}
        />
      )}
      {scheduleModalOpen && (
        <MeasurementScheduleModal
          close={() => setScheduleModalOpen(false)}
          editMode
          schedule={{
            0: details && details.schedule && details.schedule[MEASUREMENT_TYPE.overall] && details.schedule[MEASUREMENT_TYPE.overall].schedule,
            1: details && details.schedule && details.schedule[MEASUREMENT_TYPE.full_bandwidth] && details.schedule[MEASUREMENT_TYPE.full_bandwidth].schedule,
            2: details && details.schedule && details.schedule[MEASUREMENT_TYPE.high_resolution] && details.schedule[MEASUREMENT_TYPE.high_resolution].schedule,
          }}
          stops={{
            0: details && details.schedule && details.schedule[MEASUREMENT_TYPE.overall] && details.schedule[MEASUREMENT_TYPE.overall].stops || [],
            1: details && details.schedule && details.schedule[MEASUREMENT_TYPE.full_bandwidth] && details.schedule[MEASUREMENT_TYPE.full_bandwidth].stops || [],
            2: details && details.schedule && details.schedule[MEASUREMENT_TYPE.high_resolution] && details.schedule[MEASUREMENT_TYPE.high_resolution].stops || [],
          }}
          disabledRows={{
            0: false,
            1: false,
            2: false
          }}
          intervals={high_resolution_interval_options}
          saveSchedule={(schedule, stops) => {
            updateDetails({
              ...details,
              schedule: {
                [MEASUREMENT_TYPE.overall]: {
                  schedule: schedule[MEASUREMENT_TYPE.overall],
                  stops: stops[MEASUREMENT_TYPE.overall],
                  type: 'schedule_based'
                },
                [MEASUREMENT_TYPE.full_bandwidth]: {
                  schedule: schedule[MEASUREMENT_TYPE.full_bandwidth],
                  stops: stops[MEASUREMENT_TYPE.full_bandwidth],
                  type: 'schedule_based'
                },
                [MEASUREMENT_TYPE.high_resolution]: {
                  schedule: schedule[MEASUREMENT_TYPE.high_resolution],
                  stops: stops[MEASUREMENT_TYPE.high_resolution],
                  type: 'schedule_based'
                }
              }
            });
            setScheduleModalOpen(false);
          }}
          utcOffset={0}
          restrictHighRes
        />
      )}
      {forcingFreqModalOpen && (
        <ForcingFreqModal
          close={() => { setForcingFreqModalOpen(false); }}
          initialForcingFrequencies={initialForcingFrequencies}
          saveForcingFrequencies={saveForcingFrequencies}
        />
      )}
      {componentTypeModalOpen && (
        <ComponentTypeModal
          closeModal={() => setComponentTypeModalOpen(false)}
          rotatingComponentTypes={rotatingComponentTypes}
          componentTypeText={compTypeText}
          componentId={props.id}
        />
      )}
      {bearingModalOpen.open && (
        <BearingModal
          componentId={null}
          bearingLocationId={null}
          close={() => setBearingModalOpen({ open: false, key: '' })}
          bearingModelId={(details.bearings && details.bearings[bearingModalOpen.key] && details.bearings[bearingModalOpen.key].id) || null}
          apply={(id, item) => setBearingDetails(id, item, bearingModalOpen.key)}
        />
      )}
      {(props.updating) && (
        <AbsoluteLoadingContainer>
          <LoadingSvg />
        </AbsoluteLoadingContainer>
      )}
      {confirmDeleteOpen && (
        <Modal width="40%" close={() => setConfirmDeleteOpen(false)}>
          <FlexContainer direction="column" alignItems="center">
            <SpanLabel fontSize="14px" fontWeight="600" textAlign="center">
              All the data related to this machine will be deleted. This action cannot be undone.
            </SpanLabel>
            <FlexContainer>
              <Button onClick={() => { setDeleting(true); props.deleteMachine(); }}>Confirm</Button>
              <Button marginLeft="1em" cancel onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
            </FlexContainer>
            {deleting && (
              <LoadingSvg />
            )}
          </FlexContainer>
        </Modal>
      )}
      {confirmSyncSchedule && (
        <AlertPrompt
          message="All the devices will be updated with asset level measurement schedule."
          secondaryMessage="This action cannot be undone."
          onProceed={() => { props.updateSchedule(); setConfirmSyncSchedule(false); }}
          onCancel={() => setConfirmSyncSchedule(false)}
        />
      )}
      <RowContainer>
        {!editName && (
          <RBAC
            resource={mapComponentToResource.ComponentDetails}
            operation={operations.Update}
            yes={(
              <ComponentTitle id="edit-name" onClick={() => setEditName(true)}>
                {nodeName}
                <SvgContainer valid onClick={() => setEditName(true)}>
                  <EditIcon />
                </SvgContainer>
              </ComponentTitle>
            )}
            no={(
              <ComponentTitle>
                {nodeName}
              </ComponentTitle>
            )}
          />
        )}
        {editName && (
          <>
            <OutsideAlerter
              open
              styles="align-items: center; flex-basis: 100%"
              handleClick={() => {
                if (saveEnabled) {
                  setEditName(false);
                  props.saveNodeDetails(nodeName, nodeType, details, props.id);
                }
              }}
            >
              <FlexContainer>
                <ResourceInputField
                  style={{ marginBottom: '15px' }}
                  id="node-name"
                  type="text"
                  renderComponentProperties
                  value={nodeName}
                  placeholder={componentType}
                  maxLength="100"
                  onKeyPress={onNameSubmit}
                  onChange={e => setNodeName(e.target.value)}
                />
                <SvgContainer
                  id="name-edit-icon"
                  visible
                  valid={nodeName && nodeName.trim()}
                  onClick={onNameSubmit}
                  style={{ marginTop: '12px' }}
                >
                  <TickSvg inactive={!nodeName || !nodeName.trim()} style={{ marginTop: '15px' }} fill={primaryColor} />
                </SvgContainer>
              </FlexContainer>
            </OutsideAlerter>
          </>
        )}
      </RowContainer>
      {nodeType === 'machine' && props.buildType === 'machine' && (
        <>
          <ResourceSection>Metadata</ResourceSection>
          <BorderContainer>
            <Grid columns={3} doubling stackable>
              {Object.entries(machine_properties)
                .filter(([key, details]) => !details.hide)
                .map(([key, details], idx) => (
                  <Column key={key} style={{ minWidth: '220px' }}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
          </BorderContainer>
        </>
      )}
      {nodeType === 'machine' && props.buildType === 'machine' && props.mcType === 'rotating' && (
        <>
          <ResourceSection>Measurement Settings</ResourceSection>
          <BorderContainer>
            <Grid columns={3} doubling stackable>
              {Object.entries(machine_measurement_methods)
                .filter(([key, details]) => !details.hide)
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
            {details.bluetooth_p2p_enabled && (
              <ResourceSubSection style={{ margin: '20px 0' }}>
                {' '}
                Measurement Intervals
              </ResourceSubSection>
            )}
            <Grid columns={3} doubling stackable>
              {Object.entries(machine_measurement_properties)
                .filter(([key, details]) => !details.hide)
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
            {details.bluetooth_p2p_enabled && (
              <Description>
                {`State-based measurement relies on an eligible mote (e.g., ${props.partner === 'NTN' ? 'GSP' : 'VM3 or VM4P'} on Motor) for utilization detection; if unavailable, the measurement will fall back to scheduled-based`}.
              </Description>
            )}
          </BorderContainer>
        </>
      )}
      {nodeType === 'machine' && props.buildType === 'machine' && props.mcType === 'non_rotating' && (
        <>
          <ResourceSection>Measurement Settings</ResourceSection>
          <BorderContainer>
            <Grid columns={3} doubling stackable>
              {Object.entries(machine_measurement_properties)
                .filter(([key, details]) => key === 'set_schedule' && !details.hide)
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
          </BorderContainer>
        </>
      )}
      {nodeType === 'machine' && props.buildType === 'machine' && props.mcType === 'rotating' && (
        <>
          <ResourceSection>Analysis Settings</ResourceSection>
          <BorderContainer>
            <Grid columns={3} doubling stackable>
              {Object.entries(machine_alarms_properties)
                .filter(([key, details]) => !details.hide)
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
            <Grid columns={3} doubling stackable>
              {sortProperties(machine_analysis_properties).map(([key, details], idx) => (
                <Column key={key}>
                  {renderInputField(key, details, idx)}
                </Column>
              ))}
            </Grid>

          </BorderContainer>
        </>
      )}
      {nodeType === 'machine' && props.buildType === 'template' && (
        <>
          <ResourceSection>Metadata</ResourceSection>
          <BorderContainer>
            <Grid columns={3} doubling stackable>
              {Object.entries(template_properties)
                .filter(([key, details]) => !details.hide)
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
          </BorderContainer>
        </>
      )}
      {nodeType === 'machine' && props.buildType === 'template' && (
        <>
          <ResourceSection>Measurement Settings</ResourceSection>
          <BorderContainer>
            <Grid columns={3} doubling stackable>
              {Object.entries(template_measurement_methods)
                .filter(([key, details]) => !details.hide)
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
            {details.bluetooth_p2p_enabled && (
              <ResourceSubSection style={{ margin: '20px 0' }}>
                Measurement Intervals
              </ResourceSubSection>
            )}
            <Grid columns={3} doubling stackable>
              {Object.entries(template_measurement_properties)
                .filter(([key, details]) => !details.hide)
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
            {details.bluetooth_p2p_enabled && (
              <Description>
                {`State-based measurement relies on an eligible mote (e.g., ${props.partner === 'NTN' ? 'GSP' : 'VM3 or VM4P'} on Motor) for utilization detection; if unavailable, the measurement will fall back to scheduled-based.`}
              </Description>
            )}
          </BorderContainer>
        </>
      )}
      {nodeType === 'machine' && props.buildType === 'template' && (
        <>
          <ResourceSection>Analysis Settings</ResourceSection>
          <BorderContainer>
            <Grid columns={3} doubling stackable>
              {Object.entries(template_alarms_properties)
                .filter(([key, details]) => !details.hide)
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
            <Grid columns={3} doubling stackable>
              {Object.entries(template_analysis_properties)
                .filter(([key, details]) => !details.hide)
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
            <ResourceSubSection style={{ margin: '15px 0' }}>
              Vibration Demod Settings
            </ResourceSubSection>
            <Grid columns={3} doubling stackable>
              {Object.entries(template_analysis_vibration_properties)
                .filter(([key, details]) => !details.hide)
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
          </BorderContainer>
        </>
      )}
      {nodeType === 'component' &&
        (!props.isInfo || props.newNode ? (
          <>
            <ResourceItemLabel style={{ paddingTop: '15px' }}>
              Component Type
            </ResourceItemLabel>
            {props.mcType === 'rotating' && (
              <DropdownSelect
                placeholder="Select"
                value={compTypeText}
                data={rotatingComponentTypes}
                onClickItem={(text, value) => {
                  onChangeDetailsField({}, { name: 'component_type', value });
                  setCompTypeText(text);
                }}
                padding="0"
                width="30%"
                searchIcon
              />
            )}
            {props.mcType === 'non_rotating' && (
              <ResourceInputField
                label="Component Type"
                placeholder="Select"
                type="select"
                options={nonRotatingComponentTypes}
                onChange={(e, data) => {
                  onChangeDetailsField(
                    {},
                    { name: 'component_type', value: data.value }
                  );
                }}
                selectOnBlur={false}
              />
            )}
          </>
        ) : (
          <>
            <FlexContainer style={{ marginTop: '15px' }}>
              <Grid>
                <Column>
                  <ResourceItemLabel>Component Type</ResourceItemLabel>
                  <ResourceItem style={{ paddingBottom: '15px' }}>
                    {compTypeText}
                  </ResourceItem>
                </Column>
              </Grid>
              {showComponentTypeEditIcon() && (
                <SvgContainer
                  padding="30px 10px"
                  valid
                  visible
                  marginBottom="0"
                  onClick={() => setComponentTypeModalOpen(true)}
                >
                  <EditIcon />
                </SvgContainer>
              )}
            </FlexContainer>
          </>
        ))}
      {nodeType === 'component' && details.component_type && details.component_type !== 'multi_stage_gearbox' && (
        renderComponentProperties()
      )}
      {nodeType === 'component' &&
        details.component_type &&
        details.component_type === 'multi_stage_gearbox' && (
          <BorderContainer>
            <Grid columns={3} doubling stackable>
              {Object.entries({
                bearings: [],
                ..._.omit(
                  props.metadata.component_properties[details.component_type],
                  'locations'
                ),
                ...component_properties_common
              })
                .filter(
                  ([key, details]) =>
                    !details.hide && key !== 'shaft_info' && key !== 'locations'
                )
                .slice(1)
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
            <Grid columns={1}>
              {Object.entries({
                bearings: [],
                ..._.omit(
                  props.metadata.component_properties[details.component_type],
                  'locations'
                ),
                ...component_properties_common
              })
                .filter(
                  ([key, details]) => !details.hide && key === 'shaft_info'
                )
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
            <Grid>
              {Object.entries({
                bearings: [],
                ..._.omit(
                  props.metadata.component_properties[details.component_type],
                  'locations'
                ),
                ...component_properties_common
              })
                .filter(
                  ([key, details]) => !details.hide && key === 'locations'
                )
                .map(([key, details], idx) => (
                  <Column key={key}>
                    {renderInputField(key, details, idx)}
                  </Column>
                ))}
            </Grid>
          </BorderContainer>
      )}
      {nodeType === 'machine' && (
        <RBAC
          yes={renderDiagnosticRules(_.isEmpty(props.getComponentTypes()))}
          no={renderDiagnosticRules(true)}
          resource={mapComponentToResource.ComponentDetails}
          operation={operations.Update}
        />
      )}
    </>
  );
};

NodeDetails.propTypes = {
  id: PropTypes.number.isRequired,
  nodeName: PropTypes.string.isRequired,
  nodeType: PropTypes.string.isRequired,
  details: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  saveNodeDetails: PropTypes.func.isRequired,
  setEnableSave: PropTypes.func.isRequired,
  verifyTemplate: PropTypes.func,
  createMachine: PropTypes.func,
  verifyMachineStructure: PropTypes.func,
  reviewMachineStructure: PropTypes.func,
  numberOfChildren: PropTypes.number.isRequired,
  buildType: PropTypes.string,
};

NodeDetails.defaultProps = {
  buildType: 'machine',
  verifyTemplate: () => { },
  createMachine: () => { },
  verifyMachineStructure: () => { },
  reviewMachineStructure: () => { },
};

const mapStateToProps = (state) => {
  const mcType = state.machines.machineAdd.machineAddOptions.mcType;
  const asset_types = state.machines.machineBuilder.metadata.asset_types;
  const asset_subtypes = state.machines.machineBuilder.metadata.asset_sub_types;
  const available_subtypes = state.machines.machineBuilder.metadata.available_sub_types;
  const mappedSubtypes = [];
  const ffConstants = state.machines.machineBuilder.metadata.component_forcing_frequencies;
  asset_types.forEach((type) => {
    const arr = _.uniq(toLowerCaseUnderscore(_.union(available_subtypes[type], asset_subtypes[type])));
    arr.forEach((subtype) => {
      mappedSubtypes.push({
        key: subtype,
        text: CapitalizeEachWord(subtype),
        value: subtype,
        type,
      });
    });
  });
  const MACHINE_SUBTYPES = mappedSubtypes.filter(subtype => subtype.type === mcType);
  return {
    forcingFrequencyConstants: ffConstants,
    hierarchyViewPane: state.hierarchyViewPane,
    metadata: state.machines.machineBuilder.metadata,
    updating: state.machineDetails.info.updatingDetails,
    mcType,
    MACHINE_TYPES: state.machines.machineBuilder.metadata.asset_types,
    MACHINE_SUBTYPES,
    saveAsGlobal: state.templates.saveAsGlobal,
    currentUser: state.user.user,
    rules: state.machineDiagnostics.rules.list,
    currentAccount: state.currentAccount,
    sensors: state.sensors,
    nonRotatingSubtype: state.machines.machineAdd.machineAddOptions.nonRotatingSubtype,
    primaryColor: state.companyReducer.partner.theme.primaryColor,
    partner: state.companyReducer.partner.name
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveAsGlobal: bindActionCreators(templatesActions.setSaveAsGlobal, dispatch),
  chooseMcType: bindActionCreators(machineAddActions.chooseMcType, dispatch),
  machineInfoActions: bindActionCreators(machineInfoActions, dispatch),
  machineDiagnosticActions: bindActionCreators(machineDiagnosticActions, dispatch),
  sensorActions: bindActionCreators(sensorActions, dispatch),
  bearingActions: bindActionCreators(bearingActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NodeDetails));
