import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as utils from 'common/utils';
import Selection from 'common/components/Chart/components/Selection';
import { mapNames } from 'common/charts/constants';

import TagChart from '../../../Machines/MachineDetails/MachineCharts/components/TagChart';
import TagInfo from '../molecules/TagInfo';

import * as assetDetailsActions from '../../actions/assetDetails.actions';

class Trend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      points: {},
      selection: {}
    };
  }

  componentDidMount() {
    this.setInitialSelection();
  }

  getLabelAndUnits() {
    const { config } = this.props;
    return {
      yTitle:
        mapNames[config.trends_data[0].feature] ||
        utils.CapitalizeEachWord(config.trends_data[0].tag_type),
      yUnit: config.trends_data[0].units,
      speedUnit: config.trends_data[0].speed_units
    };
  }

  mouseoverChart = (selection) => {
    this.setState(prevState => ({
      selection: {
        ...prevState.selection,
        ...selection,
      }
    }));
  }

  setInitialSelection = () => {
    const { config } = this.props;
    if (
      config.trends_data &&
      !config.trends_data.loading &&
      config.trends_data[0] &&
      config.trends_data[0].trend_data &&
      config.trends_data[0].trend_data.length
    ) {
      const data = config.trends_data[0].trend_data[config.trends_data[0].trend_data.length - 1];
      this.setState({
        points: {
          ...data,
          isDate: true,
          ...this.getLabelAndUnits(data)
        },
        selection: {
          ...data,
          isDate: true,
          ...this.getLabelAndUnits(data)
        }
      });
    }
  }

  getSpectrum = (tag_id, config_id, params) => {
    this.props.assetDetailsActions.getTagSpectrum(tag_id, params, config_id);
  }

  getSpectrumDebounced = _.debounce((tag_id, config_id, params) => {
    this.getSpectrum(tag_id, config_id, params);
  }, 500);

  onMouseClick = (selection) => {
    const { amp_type, feature, tag, config, measurement_type } = this.props;
    const params = {
      amp_type,
      feature,
      timestamp: selection.x,
      measurement_type
    };
    this.getSpectrumDebounced(tag.id, config.config_id, params);
    this.setState(prevState => ({
      points: { ...prevState.selection, ...selection },
    }));
  }

  render() {
    const {
      ampType,
      config,
      feature,
      tag,
      unitSystem
    } = this.props;
    const { points } = this.state;
    return (
      <>
        <TagInfo tag={tag} config={config} waveAndSpecSelection={points} chartType="spectrum" />
        {this.state.selection && <Selection toFixedDecimalPoints={2} data={this.state.selection} />}
        <TagChart
          ampType={ampType}
          chartName={`configId-${config.config_id}-trend-analyst`}
          color={config.trends_config[0].color}
          contextHeight="25px"
          data={config.trends_data[0].trend_data}
          displayOutliers={false}
          expanded
          feature={feature}
          height="70px"
          loading={config.trends_data.loading}
          miniMode
          mouseClick={this.onMouseClick}
          mouseoverChart={this.mouseoverChart}
          points={this.state.points}
          tagId={config.trends_data[0].tag_id}
          title="trend"
          type="trend"
          unit_system={unitSystem}
          xIsDate
          yTitle={tag.structure_type === 'vector' ? mapNames[feature] : utils.CapitalizeEachWord(config.trends_data[0].tag_type)}
          yUnit={config.trends_data[0].units}
        />
      </>
    );
  }
}

Trend.propTypes = {
  ampType: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  feature: PropTypes.string.isRequired,
  measurement_type: PropTypes.number,
  tag: PropTypes.object.isRequired,
  unitSystem: PropTypes.number.isRequired,
};

Trend.defaultProps = {
  measurement_type: 1
};

const mapDispatchToProps = dispatch => ({
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(Trend);
