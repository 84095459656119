import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'common/components/atoms';
import { Paperclip } from 'common/images/FaIcons';
import colors from 'common/styles/colors';

const ButtonIconContainer = styled.span`
  ${props => props.showMargin && `
    margin-right: 8px;
  `}
`;

function BrowseButton({ setPickedFiles, inputRef, buttonText, multiple, buttonImage }) {
  const onClickHandler = () => {
    inputRef.current.click();
  };

  return (
    <span>
      <input ref={inputRef} type="file" id="files" className="hidden" multiple={multiple} onChange={setPickedFiles} />
      <Button onClick={onClickHandler} margin="0 0 1.5em 0" secondary={colors.black} fontSize="12px">
        <ButtonIconContainer showMargin={!!buttonText}>{buttonImage}</ButtonIconContainer>
        {buttonText}
      </Button>
    </span>
  );
}

BrowseButton.propTypes = {
  setPickedFiles: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  multiple: PropTypes.bool,
  buttonImage: PropTypes.any
};

BrowseButton.defaultProps = {
  buttonText: 'Attach files...',
  multiple: true,
  buttonImage: <Paperclip />
};

export default BrowseButton;
