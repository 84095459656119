import { dashboardConstants } from '../constants/dashboard.constants';

export function dashboardChartDetails(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.GET_DASHBOARD_CHARTS_DATA_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.GET_DASHBOARD_CHARTS_DATA_SUCCESS: {
      return {
        object: action.chartsData,
      };
    }
    case dashboardConstants.GET_DASHBOARD_CHARTS_DATA_FAILURE: {
      return {
        error: action.error.message,
      };
    }
    default:
      return state;
  }
}
