import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import allReducers from './reducers';

let middlewareArray = [thunkMiddleware];

let middleware = applyMiddleware(...middlewareArray);

if (process.env.NODE_ENV !== 'production') {
  const reduxLogger = require('redux-logger');
  const devTools = require('redux-devtools-extension');
  const logger = reduxLogger.createLogger();
  middlewareArray = [...middlewareArray, logger];
  middleware = devTools.composeWithDevTools(applyMiddleware(...middlewareArray));
}

export const store = createStore(allReducers, middleware);
