import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as _ from 'lodash';

import * as utils from 'common/utils';
import FlexContainer from 'common/components/atoms/FlexContainer';
import H2 from 'common/typography/H2/H2';
import H3 from 'common/typography/H3/H3';
import InputField_T from 'common/components/atoms/InputField';

const BodyContainer = styled(FlexContainer)`
padding: 0 24px 24px 24px;
`;

const InputField = styled(InputField_T)`
  width: ${props => props.width}
  margin-right: 2%;
`;

const SubTitle = styled(H3)`
  font-weight: 600;
  font-size: 10px;
  color: inherit;
  margin-bottom: 5px;
`;

const AddDerivedTagContainer = (props) => {
  let tagTypeOptions = _.chain(props.tags)
    .filter(tag => tag.type !== 'utilization' && tag.is_derived === false && tag.structure_type === 'scalar')
    .map('type')
    .uniq()
    .map(type => ({ text: utils.CapitalizeEachWord(type), value: type }))
    .value();

  let initialConfig = {
    tagType: null,
    tagIds: [null, null],
    operation: null
  };

  if (props.config) {
    const derivedTag = _.find(props.tags, tag => tag.id === props.config.tag_id);
    initialConfig = {
      tagType: derivedTag.type,
      tagIds: derivedTag.derived_tag_config.tags.map(tag => tag.id),
      operation: derivedTag.derived_tag_config.operation
    };
    tagTypeOptions = [...tagTypeOptions, { text: 'Differential Temperature', value: 'differential_temperature' }];
  }

  const [config, setConfig] = useState(initialConfig);
  const [tagOptions, setTagOptions] = useState(
    props.tags.map(tag => ({
      text: <FlexContainer direction="column">
              {tag.description}
              <SubTitle>
                {/* eslint-disable-next-line no-nested-ternary */}
                {tag.device_label ? `${tag.device_label}` : tag.type === 'shaft_speed' ? 'Auto-Detected' : ''}
              </SubTitle>
            </FlexContainer>,
      value: tag.id
    }))
  );

  const operationOptions = [
    {
      text: '+',
      value: 'addition'
    },
    {
      text: '-',
      value: 'difference'
    }
  ];

  useEffect(() => {
    if (props.config) return;
    const newTagOptions = _.filter(props.tags, tag => tag.type === config.tagType)
      .map(tag => ({
        text: <FlexContainer direction="column">
                {tag.description}
                <SubTitle>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {tag.device_label ? `${tag.device_label}` : tag.type === 'shaft_speed' ? 'Auto-Detected' : ''}
                </SubTitle>
              </FlexContainer>,
        value: tag.id
      }));
    setTagOptions(newTagOptions);
  }, [config.tagType]);

  useEffect(() => {
    if (config.tagType && config.operation &&
      config.tagIds.length === _.filter(config.tagIds, id => !!id).length &&
      config.tagIds.length === _.uniq(config.tagIds).length) props.updateConfig(config);
    else props.updateConfig(null);
  }, [config]);

  const changeTagType = (tagType) => {
    if (tagType === config.tagType) return;
    setConfig({
      tagType,
      tagIds: [null, null],
      operation: null
    });
  };

  return (
    <BodyContainer direction="column">
      <H2>{props.title}</H2>
      <InputField
        label="Tag Type"
        type="select"
        name="tag_type"
        options={tagTypeOptions}
        placeholder="Click to select a tag type"
        value={config.tagType}
        width="30%"
        onChange={(e, data) => changeTagType(data.value)}
        disabled={!!props.config}
      />
      {config.tagType && (
        <FlexContainer>
          <InputField
            label="Tag 1"
            type="select"
            name="tag_id_1"
            options={tagOptions}
            placeholder="Click to select Tag 1"
            value={config.tagIds[0]}
            width="40%"
            onChange={(e, data) => {
              const tagIds = config.tagIds;
              tagIds[0] = data.value;
              setConfig(prevConfig => ({
                ...prevConfig,
                tagIds
              }));
            }}
            disabled={!!props.config}
          />
          <InputField
            label="Operation"
            type="select"
            name="operation"
            options={operationOptions}
            value={config.operation}
            width="5%"
            onChange={(e, data) => {
              setConfig(prevConfig => ({
                ...prevConfig,
                operation: data.value
              }));
            }}
            disabled={!!props.config}
          />
          <InputField
            label="Tag 2"
            type="select"
            name="tag_id_2"
            options={tagOptions}
            placeholder="Click to select Tag 2"
            value={config.tagIds[1]}
            width="40%"
            onChange={(e, data) => {
              const tagIds = config.tagIds;
              tagIds[1] = data.value;
              setConfig(prevConfig => ({
                ...prevConfig,
                tagIds
              }));
            }}
            disabled={!!props.config}
          />
        </FlexContainer>
      )}
    </BodyContainer>
  );
};

AddDerivedTagContainer.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.array,
  updateConfig: PropTypes.func,
  config: PropTypes.object
};

AddDerivedTagContainer.defaultProps = {
  title: '',
  tags: [],
  updateConfig: () => {},
  config: null
};

export default AddDerivedTagContainer;
