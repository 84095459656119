import React from 'react';
import PropTypes from 'prop-types';

const AllChartsIcon = ({ width, height, fill }) => (
    <svg height={height} viewBox="0 0 17 16" width={width} xmlns="http://www.w3.org/2000/svg">
    <g fill={fill} fillRule="evenodd">
        <path d="m1 0h16v16h-16z" />
        <g stroke="#999b95" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(1 1)">
            <rect height="5" rx="1" width="15" />
            <path d="m2 3 2.14772727-1.55555556 1.94318182 1.55555556 2.04545455-.88888889 2.04545456.88888889 3.0681818-2" />
            <rect height="5" rx="1" width="15" y="9" />
            <path d="m2 12 2.14772727-1.5555556 1.94318182 1.5555556 2.04545455-.8888889 2.04545456.8888889 3.0681818-2" />
        </g>
    </g>
    </svg>
);
AllChartsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

AllChartsIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: 'none'
};

export default AllChartsIcon;
