import React from 'react';
import PropTypes from 'prop-types';
import MoteInstrumentationTaskSvg from 'common/images/MoteInstrumentationTaskSvg';

const InstrumentationTaskIcon = ({ marginLeft, marginRight }) => (
  <span
    style={{ marginLeft, marginRight }}
    title="Asset has instrumentation task"
  >
      <MoteInstrumentationTaskSvg />
  </span>
);
InstrumentationTaskIcon.propTypes = {
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string
};
InstrumentationTaskIcon.defaultProps = {
  marginRight: '0px',
  marginLeft: '0px'
};
export default InstrumentationTaskIcon;
