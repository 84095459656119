import { sensorsConstants } from '../actions/sensors.constants';
import { StandardSensors, VectorSensors } from '../constants/sensors.constants';

const initialState = {
  loading: false,
  object: [],
  hasMore: false,
  page: 1
};

export const sensorsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case sensorsConstants.GET_SENSORS_LIST_REQUEST: {
      if (action.page === 1) {
        return {
          ...state,
          loading: true,
          object: []
        };
      }
      return {
        ...state,
        loading: false,
        // hack state to avoid calling duplicate API request during pagination and not showing list loading SVG for better UX
        listLoading: true
      };
    }
    case sensorsConstants.GET_SENSORS_LIST_SUCCESS: {
      let sensorsArray = [];
      if (action.page === 1) sensorsArray = action.sensors;
      else sensorsArray = state.object.concat(action.sensors);
      return {
        hasMore: action.page < action.total_pages,
        object: sensorsArray,
        page: action.page,
        total_count: action.total_count,
        loading: false,
        listLoading: false
      };
    }
    case sensorsConstants.GET_SENSORS_LIST_FAILURE:
      return {
        ...state,
        hasMore: false,
        loading: false,
        listLoading: false,
        error: action.error.message
      };
    case sensorsConstants.CLEAR_SENSORS_LIST:
      return {
        ...state,
        object: []
      };
    case sensorsConstants.INCREASE_PAGE:
      return {
        ...state,
        page: action.page
      };
    case sensorsConstants.ADD_SENSOR_TO_LIST:
      return {
        ...state,
        object: state.object.concat([action.details])
      };
    case sensorsConstants.UPDATE_SERIAL_NUMBER_SUCCESS: {
      const { object } = state;
      const sensor = object.find(
        sensor => sensor.serial_number === action.details.serial_number
      );
      sensor.serial_number = action.details.new_serial_number;
      return state;
    }
    default:
      return state;
  }
};

export const unAssociatedSensorList = (
  state = { object: [], loading: false },
  action
) => {
  switch (action.type) {
    case sensorsConstants.GET_UNASSOCIATED_SENSORS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        object: []
      };
    case sensorsConstants.GET_UNASSOCIATED_SENSORS_LIST_SUCCESS:
      return {
        object: action.sensors,
        loading: false
      };
    case sensorsConstants.GET_UNASSOCIATED_SENSORS_LIST_FAILURE:
      return {
        loading: false,
        object: [],
        error: action.error
      };
    default:
      return state;
  }
};

export const sensorsSorter = (state = {}, action) => {
  switch (action.type) {
    case sensorsConstants.SET_SENSORS_SORTER:
      return {
        sorter: action.sorter
      };
    default:
      return state;
  }
};

const initialStateFilters = {
  filters: [],
  search_key: ''
};

export const sensorsFilters = (state = initialStateFilters, action) => {
  switch (action.type) {
    case sensorsConstants.SET_SENSORS_FILTERS:
      return {
        filters: action.filters,
        search_key: action.search_key
      };
    // case sensorsConstants.CLEAR_SELECTED_SENSOR:
    //   return {
    //     filters: [],
    //     search_key: ''
    //   };
    default:
      return state;
  }
};

// FIXME: remove below reducer if not required.
export const selectSensor = (state = { id: null }, action) => {
  switch (action.type) {
    case sensorsConstants.SELECT_SENSOR: {
      if (state.id === action.id) return { id: null };
      return { id: action.id };
    }
    case sensorsConstants.CLEAR_SELECTED_SENSOR:
      return {
        id: null
      };
    default:
      return state;
  }
};

export const selectedSensorList = (state = [], action) => {
  switch (action.type) {
    case sensorsConstants.CLEAR_SENSOR_DETAILS: {
      if (state.includes(action.serial_number)) {
        state.splice(state.indexOf(action.serial_number), 1);
        return [...state];
      }
      return state;
    }
    case sensorsConstants.SELECT_SENSOR: {
      if (state.includes(action.id)) {
        // state.splice(state.indexOf(action.id), 1);
        return [...state];
      }
      state.push(action.id);
      return [...state];
    }
    // non edit mode, select only one.
    case sensorsConstants.SELECT_GIVEN_SENSOR: {
      if (state[0] === action.id) return [];
      return [action.id];
    }
    case sensorsConstants.CLEAR_SELECTED_SENSOR:
      return [];
    case sensorsConstants.CLEAR_SENSORS_LIST:
      return [];
    default:
      return state;
  }
};

const initialSensorDetails = {
  details: {},
  loading: false,
  updating: false,
  deleting: false,
  creating: false,
  createError: '',
  error: '',
  errorList: []
};

export const sensorDetailsReducer = (state = initialSensorDetails, action) => {
  switch (action.type) {
    case sensorsConstants.GET_SENSORS_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sensorsConstants.SELECT_GIVEN_SENSOR: {
      if (state.errorList.includes(action.id)) return { ...state };
      return {
        ...state,
        error: '',
        errorList: []
      };
    }
    case sensorsConstants.GET_SENSORS_DETAILS_SUCCESS: {
      let { model } = action.details;
      if (![...StandardSensors, ...VectorSensors].includes(action.details.type)) model = 'other';
      if (state.details[model] !== undefined) {
        // To handle race condition when selecting and unselecting quickly
        const idxIfAlreadyExists = state.details[model].findIndex(
          d => d.serial_number === action.details.serial_number
        );
        if (idxIfAlreadyExists !== -1) state.details[model].splice(idxIfAlreadyExists, 1);
        return {
          ...state,
          details: {
            ...state.details,
            [model]: state.details[model].concat([action.details])
          },
          loading: false,
          error: ''
        };
      }
      return {
        ...state,
        details: {
          ...state.details,
          [model]: [action.details]
        },
        loading: false,
        error: ''
      };
    }
    case sensorsConstants.CLEAR_SELECTED_SENSOR:
      return {
        ...state,

        details: {},
        loading: false,
        error: '',
        errorList: []
      };
    case sensorsConstants.CLEAR_SENSOR_DETAILS: {
      if (state.errorList.includes(action.serial_number)) {
        const index = state.errorList.indexOf(action.serial_number);
        state.errorList.splice(index, 1);
        if (state.errorList.length === 0) {
          return {
            ...state,
            error: '',
            errorList: []
          };
        }
        return {
          ...state,
          errorList: state.errorList
        };
      }
      if (!state.details[action.model]) return state;
      const index = state.details[action.model].findIndex(
        d => d.serial_number === action.serial_number
      );
      if (index === -1) return state;
      state.details[action.model].splice(index, 1);
      if (state.details[action.model].length === 0) delete state.details[action.model];
      return {
        ...state,
        details: {
          ...state.details
        }
      };
    }
    case sensorsConstants.SET_SENSOR_DETAILS_TO_INITIAL_STATE:
      return {
        ...initialSensorDetails
      };
    case sensorsConstants.UPDATE_DETAILS_REQUEST:
      return {
        ...state,
        updating: true
      };
    case sensorsConstants.UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.details,
        updating: false
      };
    case sensorsConstants.UPDATE_DETAILS_FAILURE:
      if (action.error.mote_exists) {
        return {
          ...state,
          updating: false
        };
      }
      return {
        ...state,
        updating: false,
        error: action.error.message
      };
    case sensorsConstants.DELETE_SENSOR_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case sensorsConstants.DELETE_SENSOR_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.error.message
      };
    case sensorsConstants.GET_SENSORS_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error.message,
        errorList: state.errorList.concat([action.serial_number]),
        updating: false,
        loading: false
      };
    case sensorsConstants.CREATE_SENSOR_REQUEST:
      return {
        ...state,
        creating: true,
        createError: ''
      };
    case sensorsConstants.CREATE_SENSOR_SUCCESS:
      return {
        ...state,
        creating: false,
        createError: ''
      };
    case sensorsConstants.CREATE_SENSOR_FAILURE:
      return {
        ...state,
        creating: false,
        createError: action.error.message
      };
    case sensorsConstants.UPDATE_SERIAL_NUMBER_REQUEST: {
      return {
        ...state,
        updating: true
      };
    }
    case sensorsConstants.UPDATE_SERIAL_NUMBER_SUCCESS: {
      const { details } = state;
      const sensor = details[action.details.model].find(
        sensor => sensor.serial_number === action.details.serial_number
      );
      sensor.serial_number = action.details.new_serial_number;
      state.updating = false;
      return state;
    }
    case sensorsConstants.UPDATE_SERIAL_NUMBER_FAILURE: {
      return {
        ...state,
        updating: false
      };
    }
    default:
      return state;
  }
};

export const sensorsMetadataReducer = (state = { metadata: {} }, action) => {
  switch (action.type) {
    case sensorsConstants.GET_SENSORS_METADATA_SUCCESS:
      return {
        metadata: action.data
      };
    case sensorsConstants.GET_SENSORS_METADATA_FAILURE:
      return {
        error: action.error.message
      };
    default:
      return state;
  }
};

export const unassignedSensors = (
  state = { loading: false, list: [] },
  action
) => {
  switch (action.type) {
    case sensorsConstants.GET_UNASSIGNED_SENSORS_REQUEST:
      return {
        loading: true,
        list: []
      };
    case sensorsConstants.GET_UNASSIGNED_SENSORS_SUCCESS:
      return {
        loading: false,
        list: action.sensors
      };
    case sensorsConstants.GET_UNASSIGNED_SENSORS_FAILURE:
      return {
        loading: false,
        list: []
      };
    default:
      return state;
  }
};

export const filterResourceList = (state = {}, action) => {
  switch (action.type) {
    case sensorsConstants.GET_FILTER_RESOURCE_SUCCESS:
      return {
        ...state,
        [action.resource_type]: action.result
      };
    default:
      return state;
  }
};

export const componentList = (state = {}, action) => {
  switch (action.type) {
    case sensorsConstants.GET_COMPONENT_LIST_SUCCESS:
      return {
        ...state,
        [action.key]: action.value
      };
    default:
      return state;
  }
};

export const locationList = (state = {}, action) => {
  switch (action.type) {
    case sensorsConstants.GET_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        [action.key]: action.value
      };
    default:
      return state;
  }
};

const initialAnalysisState = {
  loading: false,
  data: null,
  error: null
};

export const deviceLogsData = (state = initialAnalysisState, action) => {
  switch (action.type) {
    case sensorsConstants.GET_DEVICE_LOGS_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sensorsConstants.GET_DEVICE_LOGS_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false
      };
    case sensorsConstants.GET_DEVICE_LOGS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};

const lastReportedInitialState = {
  loading: false,
  data: null,
  error: null
};

export const lastReportedInfo = (state = lastReportedInitialState, action) => {
  switch (action.type) {
    case sensorsConstants.GET_DEVICE_LAST_REPORTED_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sensorsConstants.GET_DEVICE_LAST_REPORTED_INFO_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false
      };
    case sensorsConstants.GET_DEVICE_LAST_REPORTED_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
};
