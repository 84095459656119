import styled from 'styled-components';

const H3 = styled.h3`
  font-size: 16px;
  font-weight: 300;
  font-family: 'Petasense Open Sans';
  color: ${props => props.theme.colors.black};
  margin: 0;
  padding: 0;
`;

export default H3;
