
import React from 'react';
import PropTypes from 'prop-types';

const DefaultConfigIconSvg = ({ width, height, fill, inactive }) => (
<svg height={height} viewBox="0 0 16 16" inactive={inactive} width={width} xmlns="http://www.w3.org/2000/svg">
  <g fill={fill} fillRule="evenodd">
    <path d="m0 0h16v16h-16z" fill="#fff" fillRule="nonzero" />
    <path d="m0 8.11807176 2.79140271-1.45152814 2.29497738 1.45152814 2.13582013-1.45152814 5.05514708-5.98962054" stroke={inactive ? '#b8b8b8' : '#737473'} strokeWidth="1.5" transform="translate(1.6 4)" />
  </g>
</svg>
);
DefaultConfigIconSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  inactive: PropTypes.bool,
};

DefaultConfigIconSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: 'none',
  inactive: false
};

export default DefaultConfigIconSvg;
