import _ from 'lodash';

export const chartOptions = {
  BarChart: {
    backgroundColor: { fill: 'transparent' },
    legend: {
      position: 'bottom',
    },
    tooltip: { isHtml: true }
  },
  ColumnChart: {
    backgroundColor: { fill: 'transparent' },
    vAxis: {
      textPosition: 'none',
      viewWindowMode: 'maximized',
      minValue: 0,
      ticks: []
    },
    hAxis: {
      textStyle: {
        fontName: 'Petasense Open Sans',
        fontSize: 10
      },
    },
    bar: { groupWidth: '75%' },
    legend: { position: 'none' },
    tooltip: { isHtml: true }
  },
  PieChart: {
    pieHole: 0.5,
    pieSliceTextStyle: {
      fontName: 'Petasense Open Sans',
      fontSize: 12,
      bold: true
    },
    backgroundColor: { fill: 'transparent' },
    pieSliceText: 'value',
    legend: {
      alignment: 'center',
      textStyle: {
        fontName: 'Petasense Open Sans',
        fontSize: 10
      }
    },
    tooltip: { isHtml: true },
    chartArea: { width: '80%' },
    sliceVisibilityThreshold: 0
  },
  LineChart: {
    chartArea: { width: '80%' },
    backgroundColor: { fill: 'transparent' },
    tooltip: { isHtml: true },
    legend: { position: 'none' },
    hAxis: {
      textStyle: {
        fontName: 'Petasense Open Sans',
        fontSize: 10
      },
    },
    vAxis: {},
    explorer: {
      axis: 'horizontal', // Enable zooming on the x-axis
      // keepInBounds: true, // Prevent panning outside the data range
      maxZoomIn: 0.01, // Maximum zoom level (1% of the original range)
      maxZoomOut: 1, // Prevent zooming out of chart range
      zoomDelta: 0.5 // lower value gives smoother zoom, value between 0 to 1 zooms in "correct" direction i.e. scroll up to zoom
    },
  }
};

export const stackedChartEvents = (handleClick, showSelectedLegends, allListeners, isStacked, hasCustomTooltip = false) => ([
  {
    eventName: 'select',
    callback: ({ chartWrapper }) => {
      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();
      const dataTable = chartWrapper.getDataTable();
      if (selection.length === 1) {
        const [selectedItem] = selection;
        const value = dataTable && dataTable.getValue(selectedItem.row, 1);
        const label = dataTable && dataTable.getValue(selectedItem.row, 0);
        let tooltip = '';
        if (dataTable && hasCustomTooltip) {
          tooltip = dataTable.getValue(selectedItem.row, 3);
        }
        if (isStacked) handleClick({ value, label, tooltip }, selectedItem.column - 1);
        else handleClick({ value, label, tooltip }, selectedItem.row);
      }
    },
  },
  {
    eventName: 'ready',
    callback: ({ chartWrapper, google }) => {
      if (!isStacked) return;
      const chart = chartWrapper.getChart();
      while (!_.isEmpty(allListeners)) {
        google.visualization.events.removeListener(allListeners.pop());
      }
      const listener = google.visualization.events.addListener(chart, 'click', (e) => {
        const match = e.targetID.match(/vAxis#(\d+)#label#(\d+)/);
        if ((match !== null) && (match.length > 0)) {
          const idx = parseInt(e.targetID.split('#').slice(-1)[0], 10);
          showSelectedLegends(idx);
        }
      });
      allListeners.push(listener);
    }
  }
]);

export const donutChartEvents = handleClick => ([
  {
    eventName: 'select',
    callback: ({ chartWrapper }) => {
      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();
      if (selection.length === 1) {
        const [selectedItem] = selection;
        const dataTable = chartWrapper.getDataTable();
        if (selectedItem) {
          const { row } = selectedItem;
          const value = dataTable && dataTable.getValue(row, 1);
          const label = dataTable && dataTable.getValue(row, 0);
          const text = label && label.split(' (')[0];
          handleClick({ label, value, text }, row);
        }
      }
    },
  },
]);

export const HEIGHT_PER_ROW = 30;
export const HEIGHT_MULTIPLIER = 1.4; // space for x-axis labels
