import Link_T from 'react-router-dom/Link';
import styled from 'styled-components';

const Link = styled(Link_T)`
 color: ${props => props.theme.primaryColor};
 display: block;
 font-size: 16px;
 margin: 3px 0;
 border-width: 0;
 width: 100%; 
 background-color: "transparent"; 
 text-align: center;

 &:hover {
    color: ${props => props.theme.primaryColor}; 
  }
`;

export default Link;
