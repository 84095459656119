import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Checkbox from './Checkbox';
import RadioButton from '../molecules/Radiobutton';

export const Prefix = styled.div`
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.greyXD};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props =>
    props.borderColor || props.theme.colors.borderGrayHover};
  ${props =>
    props.prefixSide === 'left'
      ? `
    border-bottom-left-radius: ${props.theme.utils.borderRadius};
    border-top-left-radius: ${props.theme.utils.borderRadius};
    border-right: none;
    order: 1;
  `
      : `
    border-bottom-right-radius: ${props.theme.utils.borderRadius};
    border-top-right-radius: ${props.theme.utils.borderRadius};
    border-left: none;
    order: 2;
  `}
  ${props =>
    props.error
      ? `
    border-color: ${props.theme.colors.error};
  `
      : ''}
  ${props =>
    props.searchBox &&
    !props.value &&
    `
    border-color: transparent;
  `}
  ${props =>
    props.disabled &&
    `
    background-color: ${props.theme.colors.disabled};
    border: 0px;
  `}
`;

export const InputStyles = css`
  ${props =>
    props.fontSettings ? props.fontSettings : props.theme.fonts.input}
  margin: 0;
  outline: 0;
  width: 100%;
  flex-grow: 1;
  font-weight: 300;
  height: auto !important;
  line-height: ${props => props.theme.utils.inputLineHeight};
  padding: ${props => props.theme.utils.inputPadding};
  border: 1px solid ${props =>
    props.borderColor || props.theme.colors.borderGrayHover};
  color: ${props => props.theme.colors.black};
  background-color:${props => props.backgroundColor || props.theme.colors.white}
  border-radius: ${props => props.theme.utils.borderRadius};
  /* transition: box-shadow .1s ease,border-color .1s ease; */
  ${props =>
    !props.searchBox &&
    `
    &:hover:not(:disabled) {
      border-color: ${props => props.theme.primaryColor};
      + ${Prefix} {
        border-color: ${props => props.theme.primaryColor};
      }
    }
  `}
  &:active:not(:disabled), &:focus:not(:disabled) {
    border: 1px solid ${props => props.theme.primaryColor};
    + ${Prefix} {
      border-color: ${props => props.theme.primaryColor};
    }
  }
  :invalid {
    box-shadow: none;
  }
  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    color: ${props => props.theme.colors.greyXD};
    opacity: 1;
    border-color: ${props => props.theme.colors.disabled};
  }
  &::placeholder {
    opacity: .7;
  }
  ${props =>
    props.prefix
      ? `
    ${props.prefixSide === 'left' ? `
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-left: none!important;
      order: 2;
    `
    : `
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: none;
      order: 1;
    `
}
  `
      : ''}
  ${props =>
    props.error
      ? `
    border-color: ${props.theme.colors.error};
    color: ${props.theme.colors.error};
    &:hover:not(:disabled) {
      color: ${props.theme.colors.black};
    }
  `
      : ''};
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  ${props =>
    props.searchBox &&
    `
    -webkit-transition: max-width .4s ease;
    -moz-transition: max-width .4s ease;
    -ms-transition: max-width .4s ease;
    -o-transition: max-width .4s ease;
    transition: max-width .4s ease;
    padding-left: 0;
    padding-right: 0;
    width: ${props.value ? '250px' : '0'};
    border-color: ${props.value ? props.theme.colors.borderGrayHover : 'transparent'};
    padding-right: ${props.theme.utils.inputLineHeight};
    &:focus:not(:disabled) {
      -webkit-transition: max-width .4s ease;
      -moz-transition: max-width .4s ease;
      -ms-transition: max-width .4s ease;
      -o-transition: max-width .4s ease;
      transition: max-width .4s ease;
      width: 250px;
    }
  `}
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  outline: 0;
  padding: 0;
  cursor: default;
  &:after {
    content: "X";
    width: 16px;
    height: 16px;
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #999b95;
    font-size: 14px;
    cursor: pointer;
  }
  ${props => !props.value && 'display: none;'}
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`;

const InputElement = styled.input`
  ${InputStyles}
`;

const Input = ({
  prefix,
  prefixSide,
  value,
  error,
  clearable,
  clearInput,
  selectable,
  onClickSelectable,
  selectableValue,
  width,
  fontWeight,
  ...rest
}) => {
  const inputRef = React.createRef();
  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  return (
    <Container
      className={rest.classNameProp}
      onClick={rest.searchBox ? focusInput : () => { }}
    >
      {selectable === 'checkbox' && (
        <Checkbox
          inputField
          onClick={onClickSelectable}
          value={selectableValue}
        />
      )}
      {selectable === 'radio' && (
        <RadioButton
          inputField
          onClick={onClickSelectable}
          value={selectableValue}
        />
      )}
      <InputElement
        innerRef={inputRef}
        autoFocus={rest.autoFocus}
        prefix={prefix}
        prefixSide={prefixSide}
        value={value}
        error={error}
        {...rest}
      />
      {prefix && (
        <Prefix
          disabled={rest.disabled}
          value={value}
          searchBox={rest.searchBox}
          prefixSide={prefixSide}
          error={error}
          borderColor={rest.borderColor}
          width={width}
        >
          {prefix}
        </Prefix>
      )}
      {clearable && <Button onClick={clearInput} value={value} />}
    </Container>
  );
};

Input.propTypes = {
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  prefixSide: PropTypes.oneOf(['left', 'right']),
  onClickSelectable: PropTypes.func,
  selectableValue: PropTypes.bool,
  selectable: PropTypes.oneOf(['radio', 'checkbox']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  clearable: PropTypes.bool,
  disabled: PropTypes.bool
};

Input.defaultProps = {
  prefix: undefined,
  prefixSide: 'right',
  selectable: undefined,
  onClickSelectable: () => { },
  selectableValue: false,
  value: undefined,
  error: undefined,
  clearable: false,
  disabled: false
};

Input.displayName = 'Input';

export default styled(Input)``;
