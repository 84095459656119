import styled from 'styled-components';

const SearchItemTitle = styled.div`
  font-size: 12px;
  ${props => props.selected && 'font-weight: bold'};
  font-family: Petasense Open Sans;
  color: ${props => props.selected ? props.theme.primaryColor : '#3C3D3A'}
  margin-left: 5px;
  margin: 5px;
`;

export default styled(SearchItemTitle)``;
