import React from 'react';
import PropTypes from 'prop-types';

const PerChannelSvg = props => (
    <div style={{ marginRight: '1.5em' }}>
      <svg height="40" width="40">
        <circle cx="20" cy="20" r="20" fill="white" stroke="#D8DCD3" />
        <circle cx="20" cy="20" r="4" fill="#D8DCD3" stroke="#D8DCD3" />
        {/* Channel: 1 */}
        <circle cx="10" cy="26" r="4" fill={props.channels.includes(1) ? '#91B846' : 'white'} stroke="#D8DCD3" />
        {/* Channel: 2 */}
        <circle cx="30" cy="14" r="4" fill={props.channels.includes(2) ? '#91B846' : 'white'} stroke="#D8DCD3" />
        {/* Channel: 3 */}
        <circle cx="10" cy="14" r="4" fill={props.channels.includes(3) ? '#91B846' : 'white'} stroke="#D8DCD3" />
        <circle cx="30" cy="26" r="4" fill="#D8DCD3" stroke="#D8DCD3" />
        <circle cx="20" cy="32" r="4" fill="#D8DCD3" stroke="#D8DCD3" />
        <rect width="14" height="8" x="13" y="0" fill="white" stroke="#D8DCD3" rx="4" ry="4" />
      </svg>
    </div>
);

PerChannelSvg.propTypes = {
  channels: PropTypes.array,
};

PerChannelSvg.defaultProps = {
  channels: [],
};

export default PerChannelSvg;
