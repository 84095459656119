import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as _ from 'lodash';
import moment from 'moment';
import AggressiveReadingIcon from 'common/images/AggressiveReadingIcon';
import { toastr } from 'react-redux-toastr';
import Accordion from 'common/components/molecules/Accordion';
import { ENDPOINT, getDeviceGCPLogsUrl, getFullEndPointUrl, getImpUrl } from 'common/constants';
import RBAC from 'common/rbac/RBAC';
import PerChannelSvg from 'common/images/PerChannelSvg';
import { isPetasenseAdmin } from 'common/rbac/util';
import { localTimeString } from 'common/helpers';
import { Grid, Segment } from 'semantic-ui-react';
import {
  ResourceItemLabel,
  ResourceInputField,
  ResourceSection,
  ResourceItem
} from 'common/components/atoms/ResourceDetails';
import Modal from '../../../../common/components/organisms/Modal';
import { history } from '../../../../common/helpers/history';
import * as sensorsActions from '../../actions/sensors.actions';
import LoadingSvg from '../../../../common/images/LoadingSvg';
import FlexContainer from '../../../../common/components/atoms/FlexContainer';
import CrossSvg from '../../../../common/images/CrossSvg';
import H1_T from '../../../../common/typography/H1/H1';
import H4_T from '../../../../common/typography/H4/H4';
import Button_T from '../../../../common/components/atoms/Button';
import { BatteryIcon } from '../../../../common/images/BatteryIcon';
import { PoeIcon } from '../../../../common/images/PoeIcon';
import WiFiSvg from '../../../../common/images/WiFiSvg';
import TxPortSvg from '../../../../common/images/TXPortSvg';
import AssignSensorModal from '../AssignSensorModal';
import {
  AGGRESSIVE_INTERVAL_THRESHOLD,
  ErrorMessages
} from '../../constants/sensors.constants';
import { getEpochFromDateTime } from '../../utils/sensorUtils';
import WifiSetting from '../WifiSettings';
import TxPorts from '../TxPorts';
import LastReportedModal from '../DeviceReportedInfoModal';
import PreferredAPSettings from '../PreferredAPSettings';

const Container = styled(FlexContainer).attrs({ direction: 'column' })`
  padding: 2em;
  min-height: Calc(100vh - 138px);
`;
const H1 = styled(H1_T)`
  padding: ${props => (props.padding ? `${props.padding};` : '0;')};
  margin-bottom: 3px;
  min-width: max-content;
  width: 100%;
  cursor: pointer;
`;
const H4 = styled(H4_T)`
  width: max-content;
  ${props =>
    props.hover &&
    `
    &:hover {
      color: ${props.theme.primaryColor};
      font-weight: 600;
      cursor: pointer;
    }
  `}
`;
const SvgContainer = styled.span`
  cursor: pointer;
  margin-left: auto;
`;
const BorderContainer = styled.div`
  border: 2px solid #f0f0f0;
  padding: 20px;
`;
const Button = styled(Button_T)`
  width: max-content;
  z-index: 2;
  padding-left: ${props => props.paddingLeft ? props.paddingLeft : '52px'};
  ${props => props.paddingBottom && `padding-bottom: ${props.paddingBottom};`}
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;
const SaveButton = styled(Button_T)`
  max-width: 200px;
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;
const AnalysisButton = styled(Button_T)`
 width: max-content;
 ${props => props.fontsize && `font-size: ${props.fontsize};`}
 ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
 ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;
const LoadingContainer = styled.div`
  align-self: center;
  margin: auto;
`;
const ButtonsContainer = styled(FlexContainer)`
  padding-top: 2em;
  button:first-child {
    margin-right: 15px;
  }
`;
const AbsoluteLoading = styled.div`
  background: white;
  position: absolute;
  align-self: center;
  margin: auto;
  opacity: 0.2;
  width: 100%;
  height: calc(100vh - 138px);
  overflow: none;
  svg {
    transform: translateX(35vw) translateY(50vh) translateY(-69px);
  }
`;
const WifiLabel = styled(H4_T)`
  padding-left: 0.5em;
  padding-top: 0.1em;
`;
const HeaderContainer = styled(FlexContainer).attrs({
  justifyContent: 'space-between',
  alignItems: 'center'
})``;
const PortHeader = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center'
})`
  margin-top: 0.8em;
  &:after {
    display: inline-block;
    content: " ";
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${props => props.theme.colors.borderGray};
  }
`;
const PerChannelContainer = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center'
})`
  border: 1px solid #d8dcd3;
  border-radius: 4px;
  padding: 0.5em 1em;
  margin-top: 0.8em;
`;
const SubTitle = styled(H4)`
  font-weight: 600;
  font-size: 10px;
  color: #333;
  padding-bottom: 0;
`;
const CenterText = styled.div`
  font-family: "Petasense Open Sans";
  font-weight: 600;
  text-align: center;
`;
class Transmitter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: {
        serial_number: props.sensor.serial_number,
        type: props.sensor.type,
        model: props.sensor.model,
        agent_url: props.sensor.agent_url,
        device_id: props.sensor.device_id,
        mac_address: props.sensor.mac_address,
        id: props.sensor.id,
        user: this.props.user
      },
      error: false,
      editMode: props.editMode,
      saveEnabled: false,
      openAssignSensorModal: false,
      selectedPortId: null,
      localLoading: false,
      ssid: '',
      passphrase: '',
      bssid: '',
      channel: null,
      wifi_schedule_time: '00:00',
      wifi_schedule_date: moment()
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      errorMessages: {},
      showLastReportedModal: false
    };
  }

  componentDidMount() {
    const {
      details,
      sensor: { model, serial_number }
    } = this.props;
    let sensor = {};
    if (details[model]) {
      sensor = details[model].find(d => d.serial_number === serial_number);
    }
    if (sensor) {
      this.setState({
        details: _.cloneDeep(sensor)
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      details,
      sensor: { model, serial_number }
    } = this.props;
    const {
      details: prevDetails,
      sensor: { model: prevModel, serial_number: prevSerialNumber }
    } = prevProps;
    let sensor = {};
    let prevSensor = {};
    if (details[model]) {
      sensor = details[model].find(d => d.serial_number === serial_number);
    }
    if (prevDetails[prevModel]) {
      prevSensor = prevDetails[prevModel].find(
        d => d.serial_number === prevSerialNumber
      );
    }
    if (
      sensor &&
      (!_.isEqual(prevSensor, sensor) ||
        prevState.details.serial_number !== sensor.serial_number)
    ) {
      this.setState({
        details: _.cloneDeep(sensor)
      });
    }
    if (prevState.editMode !== this.props.editMode) {
      this.setState({
        editMode: this.props.editMode
      });
    }
    if (_.get(details, [model, '0', 'config_wifi_ssid']) !== _.get(prevDetails, [model, '0', 'config_wifi_ssid'])) {
      this.setState({ ssid: details[model][0].config_wifi_ssid });
    }
    if (_.get(details, [model, '0', 'config_wifi_passphrase']) !== _.get(prevDetails, [model, '0', 'config_wifi_passphrase'])) {
      this.setState({ passphrase: details[model][0].config_wifi_passphrase });
    }
    if (_.get(details, [model, '0', 'ap_bssid']) !== _.get(prevDetails, [model, '0', 'ap_bssid'])) {
      this.setState({ bssid: details[model][0].ap_bssid });
    }
    if (_.get(details, [model, '0', 'ap_channel']) !== _.get(prevDetails, [model, '0', 'ap_channel'])) {
      this.setState({ channel: details[model][0].ap_channel });
    }
    this.enableSave(sensor);
  }

  enableSave = (sensorDetails) => {
    const { details, errorMessages, saveEnabled, ssid, passphrase, bssid, channel } = this.state;
    if (!_.isEqual(details, sensorDetails) && _.isEmpty(errorMessages)) {
      if (saveEnabled !== true) this.setState({ saveEnabled: true });
    } else if (!_.isEmpty(ssid) && ssid !== _.get(
      this.props.details, [sensorDetails.model, '0', 'config_wifi_ssid']
    ) && _.isEmpty(errorMessages)) {
      if (saveEnabled !== true) this.setState({ saveEnabled: true });
    } else if (!_.isEmpty(passphrase) && passphrase !== _.get(
      this.props.details, [sensorDetails.model, '0', 'config_wifi_passphrase']
    ) && _.isEmpty(errorMessages)) {
      if (saveEnabled !== true) this.setState({ saveEnabled: true });
    } else if (!_.isEmpty(bssid) && bssid !== _.get(
      this.props.details, [sensorDetails.model, '0', 'ap_bssid']
    ) && _.isEmpty(errorMessages)
    ) {
      if (saveEnabled !== true) this.setState({ saveEnabled: true });
    } else if (!_.isEmpty(channel) && channel !== _.get(
      this.props.details, [sensorDetails.model, '0', 'ap_channel']
    ) && _.isEmpty(errorMessages)
    ) {
      if (saveEnabled !== true) this.setState({ saveEnabled: true });
    } else if (saveEnabled !== false) {
      this.setState({ saveEnabled: false });
    }
  };

  validateInput = () => {
    const { ssid, passphrase, bssid, channel } = this.state;
    const errorMessages = {};
    if (ssid || passphrase) {
      if (!passphrase) errorMessages.passphrase_error = ErrorMessages.wifi_password_required;
      if (!ssid) errorMessages.ssid_error = ErrorMessages.wifi_ssid_required;
    }
    if (!_.isEmpty(bssid)) {
      if (!bssid.match(/^([A-Fa-f0-9]{12})$/)) {
        errorMessages.bssid_error = ErrorMessages.invalid_bssid;
      }
    }
    const channelInt = parseInt(channel, 10);
    if (_.isNumber(channelInt) && (channelInt < 1 || channelInt > 13)) {
      errorMessages.channel_error = ErrorMessages.invalid_channel;
    }
    this.setState({ errorMessages });
  };

  clearAPSettings = () => {
    this.setState({ bssid: '', channel: '' }, this.onSave);
  }

  onSave = () => {
    const { updateSensorDetails } = this.props.sensorsActions;
    const {
      details: { serial_number, model, reporting_interval },
      ssid,
      passphrase,
      wifi_schedule_date,
      wifi_schedule_time,
      bssid,
      channel
    } = this.state;
    const start_time = getEpochFromDateTime(
      wifi_schedule_date,
      wifi_schedule_time
    );
    const data = {
      [model]: {
        serial_number: [serial_number],
        reporting: {
          interval: reporting_interval
        },
        aggressive_measurement:
          reporting_interval <= AGGRESSIVE_INTERVAL_THRESHOLD,
        type: 'Transmitter'
      }
    };
    if (ssid && passphrase) {
      data[model].wifi_config = {
        ssid,
        passphrase,
        start_time
      };
    }

    // Initialize wifi_ap_config
    data[model].wifi_ap_config = {};

    // Add bssid if it exists
    if (bssid && bssid.trim() !== '') {
      data[model].wifi_ap_config.bssid = bssid;
    }

    // Add channel if it exists and is valid
    if (channel !== null && channel !== undefined && channel !== '') {
      const channelInt = parseInt(channel, 10);
      if (!Number.isNaN(channelInt)) {
        data[model].wifi_ap_config.channel = channelInt;
      }
    }

    updateSensorDetails(data).then(
      res => res,
      error => toastr.error(error)
    );
  };

  onCancel = () => {
    const {
      details,
      sensor: { model, serial_number }
    } = this.props;
    let sensor = {};
    if (details[model]) {
      sensor = details[model].find(d => d.serial_number === serial_number);
    }
    if (sensor && !_.isEqual(this.state.details, sensor)) {
      this.setState({
        details: _.cloneDeep(sensor)
      });
    }
  };

  handleInputClick = (e, data) => {
    const targetValue = e.target.value || data.value || '';
    const targetName = e.target.name || data.name;
    this.setState({ [targetName]: targetValue }, this.validateInput);
  };

  onChangeField = (e, { value }, name) => {
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        [name]: value
      }
    }));
  };

  assignSensorToTx = (port_id, sensor_id) => {
    const { details } = this.state;
    this.setState({ localLoading: true });
    this.props.sensorsActions
      .assignSensorToTx(
        port_id,
        { sensor_id },
        details.model,
        details.serial_number
      )
      .then(
        () => {
          this.setState({ localLoading: false });
        },
        () => {
          this.setState({ localLoading: false });
        }
      );
  };

  unassignSensorFromTx = (sesnor_serial_number) => {
    const { details } = this.state;
    this.setState({ localLoading: true });
    const data = {
      serial_numbers: [sesnor_serial_number],
      tx_serial_no: details.serial_number
    };
    this.props.sensorsActions.unassignSensorFromTx(data).then(
      () => {
        this.setState({ localLoading: false });
      },
      () => {
        this.setState({ localLoading: false });
      }
    );
  };

  viewSensor = (serial_number, machine_id) => {
    const { getSensorDetails } = this.props.sensorsActions;
    const {
      details: { serial_number: txSerialNumber }
    } = this.state;
    const { appliedFilters } = this.props;
    delete appliedFilters.area_search_key;
    delete appliedFilters.machine_search_key;
    if (this.props.machine_id) {
      if (this.props.machine_id === machine_id) {
        delete appliedFilters.machines;
        const filters = _.every(appliedFilters, value => _.isEmpty(value));
        const present = this.sensorPresentInTheList(serial_number);
        if (!filters && !present) {
          this.setState({
            changeFilterModal: true,
            changeFilterSerialNumber: serial_number
          });
          return;
        }
        getSensorDetails(serial_number, true);
      } else if (machine_id) {
        history.push(`/machines/${this.props.machine_id}/devices`, {
          serial_number: txSerialNumber
        });
        history.push(`/machines/${machine_id}/devices`, { serial_number });
      } else {
        history.push('/devices', { serial_number });
      }
    } else {
      const filters = _.every(appliedFilters, value => _.isEmpty(value));
      const present = this.sensorPresentInTheList(serial_number);
      if (!filters && !present) {
        this.setState({
          changeFilterModal: true,
          changeFilterSerialNumber: serial_number
        });
        return;
      }
      getSensorDetails(serial_number, true);
    }
  };

  clearAppliedFilters = () => {
    const { changeFilterSerialNumber } = this.state;
    if (!changeFilterSerialNumber) return;
    this.props.removeAppliedFilters(changeFilterSerialNumber);
  };

  sensorPresentInTheList = (serial_number) => {
    const { items } = this.props;
    return items.find(el => el.serial_number === serial_number);
  };

  openAssignSensorModal = (port_id) => {
    this.setState({
      openAssignSensorModal: true,
      selectedPortId: port_id
    });
  };

  handleLastReportedClick = () => {
    this.setState(prevState => ({
      lastReportedkey: prevState.lastReportedKey + 1
    }));
    this.setState({ showLastReportedModal: true });
  };

  allChannelsInUse = port =>
    port.sensors.reduce((acc, cur) => acc.concat(cur.channels), []).length === 3;

  filterReportingIntervals = () => {
    const { details } = this.state;
    const { metadata } = this.props;
    const anyTxSensorsInAggressiveMeasurement = _.some(details.ports, p =>
      _.some(p.sensors, 'aggressive_measurement')
    );
    if (anyTxSensorsInAggressiveMeasurement) {
      return _.filter(
        metadata[details.model].reporting.interval,
        o => o.value <= AGGRESSIVE_INTERVAL_THRESHOLD
      );
    }
    return _.filter(
      metadata[details.model].reporting.interval,
      o => o.value > AGGRESSIVE_INTERVAL_THRESHOLD
    );
  };

  refreshTxDetails = () => {
    const { details } = this.state;
    if (details && details.serial_number) {
      this.props.sensorsActions.getSensorDetails(details.serial_number);
    }
  };

  renderField = (type, label, options, value, disabled, name) => {
    const { editMode } = this.state;
    const { updating } = this.props;
    if (editMode) {
      return (
        <ResourceInputField
          type={type}
          label={label}
          name={name}
          key={name}
          options={options}
          value={value}
          disabled={disabled || updating}
          onChange={(e, value) => this.onChangeField(e, value, name)}
          selectOnBlur={false}
        />
      );
    }
    let textValue = '';
    if (options) {
      const element = options.filter(option =>
        _.isEqual(option.value, value)
      )[0];
      if (element) textValue = element.text;
      else textValue = '--';
    }
    return (
      <Fragment>
        <ResourceItemLabel>{label}</ResourceItemLabel>
        <ResourceItem>{textValue}</ResourceItem>
      </Fragment>
    );
  };

  render() {
    const {
      details: {
        serial_number,
        type,
        model,
        battery_level,
        wifi_signal,
        wifi_rssi,
        wifi_ssid,
        poe,
        reporting_interval,
        ports,
        firmware_versions,
        agent_url,
        mac_address,
        device_id,
        last_report
      },
      saveEnabled,
      error,
      localLoading,
      ssid,
      passphrase,
      bssid,
      channel,
      errorMessages,
      wifi_schedule_date,
      wifi_schedule_time,
      showLastReportedModal
    } = this.state;
    const {
      loading,
      detailsError,
      errorList,
      metadata,
      updating,
      user,
      primaryColor
    } = this.props;
    let aggressiveIconHoverText =
      'Device in aggressive measurement mode, this will impact battery life.';
    if (poe) {
      aggressiveIconHoverText = 'Device in aggressive measurement mode.';
    }
    if (!loading && model && (!metadata || !metadata[model])) return `Metadata for the model ${model} doesn't exist`;
    return (
      <Fragment>
        {(updating || localLoading) && (
          <AbsoluteLoading>
            <LoadingSvg />
          </AbsoluteLoading>
        )}
        {this.state.openAssignSensorModal && (
          <AssignSensorModal
            port_id={this.state.selectedPortId}
            assignSensorToTx={this.assignSensorToTx}
            close={() =>
              this.setState({
                openAssignSensorModal: false,
                selectedPortId: null
              })
            }
          />
        )}
        <Container>
          {loading && !error && !detailsError && (
            <LoadingContainer>
              <LoadingSvg />
            </LoadingContainer>
          )}
          {!loading && (error || detailsError) && (
            <div>
              {error || detailsError}{' '}
              <b>(serial numbers: {errorList.join(', ')})</b>
            </div>
          )}
          {!loading && !error && serial_number && model && (
            <Fragment>
              <HeaderContainer>
                <H1>{serial_number}</H1>
                <SvgContainer onClick={this.props.close}>
                  <CrossSvg width={25} height={25} />
                </SvgContainer>
              </HeaderContainer>
              <ResourceSection>Transmitter Info</ResourceSection>
              <BorderContainer>
                <Grid columns={3} doubling stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Type</ResourceItemLabel>
                        <ResourceItem>{type}</ResourceItem>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Model</ResourceItemLabel>
                        <ResourceItem>{model}</ResourceItem>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Firmware</ResourceItemLabel>
                        <ResourceItem>
                          {firmware_versions
                            ? firmware_versions.mcu || '--'
                            : '--'}
                        </ResourceItem>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Last Reported At</ResourceItemLabel>
                        <ResourceItem>{localTimeString(last_report)}</ResourceItem>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </BorderContainer>
              <ResourceSection>Battery &amp; Wireless</ResourceSection>
              <BorderContainer>
                <Grid columns={3} doubling stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Battery Level</ResourceItemLabel>
                        <ResourceItem>
                          <FlexContainer>
                            {poe ? (
                              <PoeIcon />
                            ) : (
                              <BatteryIcon batteryLevel={battery_level} />
                            )}
                          </FlexContainer>
                        </ResourceItem>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Wi-Fi Signal</ResourceItemLabel>
                        <ResourceItem>
                          <FlexContainer direction="row">
                            {_.isNull(wifi_signal) && (
                                <div
                                  title={
                                    wifi_rssi &&
                                    `Last WiFi Signal Strength: ${wifi_rssi} dB`
                                  }
                                >
                                  <ResourceItem>
                                  N/A
                                  </ResourceItem>
                                </div>
                            )}
                            {!_.isNull(wifi_signal) && (
                              <WiFiSvg wifiLevel={wifi_signal} />
                            )}
                            {!_.isNull(wifi_signal) && wifi_rssi && (
                              <WifiLabel>
                                <ResourceItem>{wifi_rssi} dB</ResourceItem>
                              </WifiLabel>
                            )}
                          </FlexContainer>
                        </ResourceItem>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Wi-Fi SSID</ResourceItemLabel>
                        <FlexContainer direction="row">
                          {_.isNull(wifi_ssid) && (
                            <div>
                              <ResourceItem>N/A</ResourceItem>
                            </div>
                          )}
                          {wifi_ssid && (
                            <WifiLabel>
                              <ResourceItem>{wifi_ssid}</ResourceItem>
                            </WifiLabel>
                          )}
                        </FlexContainer>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {this.props.editMode && (
                <Grid columns={4} doubling stackable>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Segment>
                        <RBAC
                          yes={(
                              <Accordion
                                title="Wi-Fi Settings"
                                color="grey"
                                fontsize="12px"
                                marginbottom="18px"
                              >
                              <WifiSetting
                                ssid={ssid}
                                passphrase={passphrase}
                                errorMessages={errorMessages}
                                wifi_schedule_date={wifi_schedule_date}
                                wifi_schedule_time={wifi_schedule_time}
                                preferredTimeOptions={this.preferredTimeOptions}
                                handleInputClick={this.handleInputClick}
                              />
                              </Accordion>
                         )}
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Segment>
                        <RBAC
                          yes={(
                              <Accordion
                                title="Preferred Access Point Settings"
                                color="grey"
                                fontsize="12px"
                                marginbottom="18px"
                              >
                                <Button
                                  text
                                  fontsize="12px"
                                  onClick={this.clearAPSettings}
                                  paddingLeft="0"
                                  paddingBottom="10px"
                                >
                                  Clear AP Settings
                                </Button>
                                <PreferredAPSettings
                                  bssid={bssid}
                                  channel={channel}
                                  errorMessages={errorMessages}
                                  handleInputClick={this.handleInputClick}
                                />
                              </Accordion>
                         )}
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                )}
              </BorderContainer>
              <ResourceSection>Reporting</ResourceSection>
              <BorderContainer>
                <Grid columns={3} doubling stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        {this.renderField(
                          'select',
                          'Interval',
                          this.filterReportingIntervals(),
                          reporting_interval,
                          false,
                          'reporting_interval',
                          true
                        )}
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </BorderContainer>
              {isPetasenseAdmin(user) && (
                <>
                  <ResourceSection>Advanced Device Details</ResourceSection>
                  <BorderContainer>
                    <Grid columns={3} doubling stackable>
                      <Grid.Row>
                        <Grid.Column>
                          <Segment>
                            <ResourceItemLabel>Device Id</ResourceItemLabel>
                            <ResourceItem>{device_id}</ResourceItem>
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                          <Segment>
                            <ResourceItemLabel>Mac Address</ResourceItemLabel>
                            <ResourceItem>{mac_address}</ResourceItem>
                          </Segment>
                        </Grid.Column>
                        {agent_url && (
                          <Grid.Column>
                            <Segment>
                              <ResourceItemLabel>Imp Page </ResourceItemLabel>
                              <ResourceItem>
                                <a
                                  href={getImpUrl(device_id)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: primaryColor,
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    letterSpacing: '1.2px'
                                  }}
                                >
                                  URL
                                </a>
                              </ResourceItem>
                            </Segment>
                          </Grid.Column>
                        )}
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Segment>
                            <ResourceItemLabel> Logs</ResourceItemLabel>
                            <ResourceItem>
                              {' '}
                              <a
                                href={getDeviceGCPLogsUrl(serial_number)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: primaryColor,
                                  fontSize: '12px',
                                  fontWeight: 600,
                                  letterSpacing: '1.2px'
                                }}
                              >
                                View
                              </a>
                            </ResourceItem>
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                          <Segment>
                            <ResourceItemLabel>
                              Last Reported Info
                            </ResourceItemLabel>
                            <ResourceItem>
                              <AnalysisButton
                                text
                                onClick={this.handleLastReportedClick}
                                fontsize="12px"
                                paddingBottom=""
                              >
                                view
                              </AnalysisButton>
                            </ResourceItem>
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                         <Segment>
                          <ResourceItemLabel>Config</ResourceItemLabel>
                          <ResourceItem style={{ color: 'red' }}>
                                    <a
                                      href={getFullEndPointUrl(ENDPOINT.DEVICE_CONFIG(serial_number))}
                                      style={{
                                        color: primaryColor,
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        letterSpacing: '1.2px'
                                      }}
                                      target="blank"
                                    >
                                      View
                                    </a>
                          </ResourceItem>
                         </Segment>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                         <Segment>
                          <ResourceItemLabel>Config Histories</ResourceItemLabel>
                          <ResourceItem style={{ color: 'red' }}>
                                    <a
                                      href={getFullEndPointUrl(ENDPOINT.DEVICE_CONFIG_HISTORY(serial_number))}
                                      style={{
                                        color: primaryColor,
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        letterSpacing: '1.2px'
                                      }}
                                      target="blank"
                                    >
                                      View
                                    </a>
                          </ResourceItem>
                         </Segment>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </BorderContainer>
                </>
              )}
              {showLastReportedModal && (
                <LastReportedModal
                  serialNumber={serial_number}
                  onCancel={() =>
                    this.setState({ showLastReportedModal: false })
                  }
                />
              )}
              {/* {ports && _.isEmpty(ports[0].sensors) && <H3>No sensors associated to this port</H3>} */}
              {this.props.assignSensorVersionV2 && (
                <TxPorts
                  txs={[this.state.details]}
                  editMode={this.props.editMode}
                  viewSensor={this.viewSensor}
                  unassignSensor={this.unassignSensorFromTx}
                  metadata={this.props.metadata}
                  onUpdateSensorSettings={this.props.sensorsActions.updateSensorDetails}
                  onRefreshTxDetails={this.refreshTxDetails}
                  user={this.props.user}
                />
              )}
              {!this.props.assignSensorVersionV2 &&
                ports &&
                ports.map((port, index) => (
                  <>
                    <BorderContainer>
                      <ResourceSection>
                        <PortHeader key={`header-${index}`}>
                          <TxPortSvg
                            width={60}
                            height={30}
                            portNumber={port.port_position}
                          />
                          <ResourceItemLabel
                            grey
                            marginbottom="0"
                            paddingtop="0"
                          >
                            Port {port.port_position}
                          </ResourceItemLabel>
                        </PortHeader>
                      </ResourceSection>
                      {port.sensors.map((sensor, idx) => (
                        <Grid columns={3} doubling stackable>
                          <PerChannelContainer key={`port1-${idx}`}>
                            <PerChannelSvg channels={sensor.channels} />
                            <Grid.Row columns={3}>
                              <Grid.Column>
                                <ResourceItem noPadding marginRight="4em">
                                  Channel {sensor.channels.join()}
                                </ResourceItem>
                              </Grid.Column>
                              <Grid.Column>
                                <ResourceItem
                                  noPadding
                                  marginRight="4em"
                                  hover={
                                    sensor.sensor_type && !this.props.editMode
                                  }
                                  onClick={
                                    sensor.sensor_type && !this.props.editMode
                                      ? () =>
                                        this.viewSensor(
                                          sensor.serial_number,
                                          sensor.machine_id
                                        )
                                      : () => {}
                                  }
                                >
                                  {sensor.sensor_type
                                    ? `${sensor.sensor_type} (${sensor.serial_number})`
                                    : '--'}
                                  {sensor.aggressive_measurement && (
                                    <span
                                      style={{
                                        float: 'right',
                                        paddingLeft: '5px'
                                      }}
                                      title={aggressiveIconHoverText}
                                    >
                                      <AggressiveReadingIcon />
                                    </span>
                                  )}
                                </ResourceItem>
                                <SubTitle>
                                  {sensor.machine ? 'on' : ''}
                                  {sensor.machine
                                    ? sensor.machine
                                    : '(not associated to any machine)'}
                                </SubTitle>
                              </Grid.Column>
                              <Grid.Column>
                                {this.props.editMode && (
                                  <Button
                                    text
                                    color="red"
                                    marginLeft="auto"
                                    fontsize="12px"
                                    onClick={() =>
                                      this.unassignSensorFromTx(
                                        sensor.serial_number
                                      )
                                    }
                                  >
                                    Unassign
                                  </Button>
                                )}
                              </Grid.Column>
                            </Grid.Row>
                          </PerChannelContainer>
                        </Grid>
                      ))}
                      {this.props.editMode && !this.allChannelsInUse(port) && (
                        <Button
                          text
                          marginTop="10px"
                          fontsize="12px"
                          onClick={() =>
                            this.openAssignSensorModal(port.port_id)
                          }
                        >
                          Assign Sensor
                        </Button>
                      )}
                    </BorderContainer>
                  </>
                ))}
              {this.state.editMode && (
                <ButtonsContainer>
                  <SaveButton disabled={!saveEnabled} onClick={this.onSave}>
                    Save
                  </SaveButton>
                  <SaveButton
                    secondary="#3C3D3A"
                    disabled={!saveEnabled}
                    onClick={this.onCancel}
                  >
                    Cancel
                  </SaveButton>
                </ButtonsContainer>
              )}
            </Fragment>
          )}
        </Container>
       {this.state.changeFilterModal && (
          <Modal
            width="40%"
            close={() => this.setState({ changeFilterModal: false, changeFilterSerialNumber: null })}
          >
            <CenterText>
              The sensor details cannot be displayed because there is a filter applied on this list. Would you like to remove the filter and proceed?
            </CenterText>
            <FlexContainer justifyContent="center">
              <ButtonsContainer>
                <Button width="70px" onClick={this.clearAppliedFilters}>Ok</Button>
                <Button secondary="#3C3D3A" onClick={() => this.setState({ changeFilterModal: false, changeFilterSerialNumber: null })}>Cancel</Button>
              </ButtonsContainer>
            </FlexContainer>
          </Modal>
       )}
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  items: state.sensors.items.object,
  metadata: state.sensors.metadata.metadata,
  details: state.sensors.details.details,
  loading: state.sensors.details.loading,
  detailsError: state.sensors.details.error,
  updating: state.sensors.details.updating,
  errorList: state.sensors.details.errorList,
  user: state.user.user,
  primaryColor: state.companyReducer.partner.theme.primaryColor
});
const mapDispatchToProps = dispatch => ({
  sensorsActions: bindActionCreators(sensorsActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Transmitter);