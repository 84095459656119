import React from 'react';
import PropTypes from 'prop-types';

import MoreIconContainer from 'common/components/atoms/MoreIconContainer';
import MoreIcon from 'common/images/MoreIconSvg';
import InlineModal from 'common/components/molecules/InlineModal';
import OutsideAlerter from 'common/OutsideAlerter';
import { connect } from 'react-redux';

class MoreOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemMenuOpen: false
    };
  }

  render() {
    const partner = this.props.partner;
    const primaryColor = partner.theme.primaryColor;
    const greyColor = partner.theme.grey;
    return (
      <MoreIconContainer {...this.props} onClick={(e) => { this.setState({ itemMenuOpen: true }); e.stopPropagation(); }}>
        <MoreIcon fill={this.state.itemMenuOpen ? primaryColor : greyColor} width={this.props.iconWidth} />
        {this.state.itemMenuOpen && (
          <InlineModal width={this.props.inlineWidth} noOverlay minWidthInline={this.props.minWidthInline} marginTop="5px" noScroll close={() => this.setState({ itemMenuOpen: false })}>
            <OutsideAlerter event="click" open handleClick={() => this.setState({ itemMenuOpen: false })}>
              <div onClick={(e) => { this.setState({ itemMenuOpen: false }); e.stopPropagation(); }}>
                {this.props.children}
              </div>
            </OutsideAlerter>
          </InlineModal>
        )}
      </MoreIconContainer>
    );
  }
}

MoreOptions.propTypes = {
  iconWidth: PropTypes.number,
  minWidthInline: PropTypes.string
};

MoreOptions.defaultProps = {
  iconWidth: 16,
  minWidthInline: '120px'
};

const mapStateToProps = state => ({
  partner: state.companyReducer.partner
});
export default connect(mapStateToProps)(MoreOptions);
