import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Label from 'common/typography/Label/Label';
import Button from 'common/components/atoms/Button';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Modal from 'common/components/organisms/ModalV2';
import InputField from 'common/components/atoms/InputField';
import * as sensorActions from '../actions/sensors.actions';

const CancelButton = styled(Button)`
  margin-right: 20px
`;

const TFlexContainer = styled(FlexContainer)`
  line-height: 0.2em;
`;

class AddDeviceModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account_id: '',
      allAccounts: [],
      device_type: 'Transmitter',
      deviceTypes: [{ value: 'Transmitter', text: 'Transmitter' }, { value: 'Mote', text: 'Mote' }],
      serial_number: '',
      device_id: '',
      mac_address: '',
      reporting_interval: 10800,
      transmitter_models: [],
      tx_model_id: '',
      mote_models: [],
      mote_model_id: ''
    };
  }

  componentDidMount() {
    const {
      getResourcesBasedOnType,
      getTxModels,
      getMoteModels
    } = this.props.sensorActions;
    getResourcesBasedOnType('account').then((res) => {
      this.setState({
        allAccounts: res
      });
    });
    getTxModels().then((res) => {
      const formatted_data = res.map(({ id, version }) => ({ value: id, text: version }));
      this.setState({
        transmitter_models: formatted_data
      });
    });
    getMoteModels().then((res) => {
      this.setState({
        mote_models: res.mote_models
      });
    });
  }

  render() {
    const { close, add } = this.props;
    const { account_id, device_type, serial_number, device_id, mac_address, reporting_interval, tx_model_id, mote_model_id } = this.state;
    return (
    <Modal
      width="600px"
      padding="0 20px"
      close={close}
    >
      <TFlexContainer direction="column" marginbottom="40px" margintop="40px">
        <FlexContainer direction="column" alignItems="left" marginbottom="40px">
          <Label fontWeight="300" fontSize="20px" marginBottom="8px">Add Device</Label>
        </FlexContainer>
          <InputField
            label="Device Type"
            maxLength={50}
            onChange={(e, d) => this.setState({ device_type: d.value })}
            placeholder="Click to select device type"
            type="select"
            search
            value={this.state.device_type}
            options={this.state.deviceTypes}
          />
          <InputField
            label="Account"
            maxLength={50}
            onChange={(e, d) => this.setState({ account_id: d.value })}
            placeholder="Click to select an account"
            type="select"
            search
            value={this.state.account_id}
            options={this.state.allAccounts}
          />
          <InputField
            label="Serial Number"
            maxLength={50}
            onChange={e => this.setState({ serial_number: e.target.value })}
            placeholder="Enter serial number"
            type="text"
            search
            value={this.state.serial_number}
          />
          <InputField
            label="Device Id"
            maxLength={16}
            onChange={e => this.setState({ device_id: e.target.value })}
            placeholder="16 Digits"
            type="text"
            value={this.state.device_id}
          />
          <InputField
            label="Mac Address"
            maxLength={12}
            onChange={e => this.setState({ mac_address: e.target.value })}
            placeholder="12 Digits"
            type="text"
            value={this.state.mac_address}
          />
          { this.state.device_type === 'Transmitter' && (
              <InputField
                label="Reporting Interval (Seconds)"
                onChange={e => this.setState({ reporting_interval: e.target.value })}
                type="number"
                value={this.state.reporting_interval}
              />
          )}
          { this.state.device_type === 'Transmitter' && (
              <InputField
                label="Transmitter Model"
                onChange={(e, d) => this.setState({ tx_model_id: d.value })}
                placeholder="Transmitter Models"
                type="select"
                search
                value={this.state.tx_model_id}
                options={this.state.transmitter_models}
              />
          )}
          { this.state.device_type === 'Mote' && (
              <InputField
                label="Mote Models"
                onChange={(e, d) => this.setState({ mote_model_id: d.value })}
                placeholder="Mote Models"
                type="select"
                search
                value={this.state.mote_model_id}
                options={this.state.mote_models}
              />
          )}
        <FlexContainer justifyContent="right">
          <CancelButton onClick={close} secondary="#000">CANCEL</CancelButton>
          <Button
            onClick={() => add(device_type, account_id, serial_number, device_id, mac_address, reporting_interval, tx_model_id, mote_model_id)}
            disabled={
              !(device_id.length === 16 && mac_address.length === 12
                && device_type && account_id && serial_number
                && reporting_interval && (tx_model_id || mote_model_id))}
          >
            ADD
          </Button>
        </FlexContainer>
      </TFlexContainer>
    </Modal>
    );
  }
}


AddDeviceModel.propTypes = {
  add: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  sensorActions: bindActionCreators(sensorActions, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(AddDeviceModel);
