import definitionTypes from './definition.types';

export default function list(state = {}, action) {
  switch (action.type) {
    case definitionTypes.GET_DEFINITION_LIST_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case definitionTypes.GET_DEFINITION_LIST_SUCCESS: {
      return {
        ...state,
        definitions: action.definitions,
        loading: false,
        error: false
      };
    }

    case definitionTypes.GET_DEFINITION_LIST_FAILURE: {
      return {
        ...state,
        definitions: [],
        loading: false,
        error: action.error.message
      };
    }

    case definitionTypes.CLEAR_DEFINITION_LIST:
      return {
        ...state,
        definitions: []
      };

    case definitionTypes.SEARCH_PARAMS:
      return {
        ...state,
        params: action.params
      };

    case definitionTypes.UPDATE_DEFINITION_NAME: {
      const { definitions } = state;
      const index = definitions.findIndex(d => d.event_template_id === action.id);
      if (index === -1) return { ...state };
      const row = definitions.splice(index, 1)[0];
      row.name = action.name;
      definitions.splice(index, 0, row);
      return {
        ...state,
        definitions
      };
    }
    default:
      return state;
  }
}
