import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import FlexContainer from '../../atoms/FlexContainer';
import ChartPopupContainer from '../atoms/ChartPopupContainer';
import OutsideAlerter from '../../../OutsideAlerter';
import H3 from '../../../typography/H3/H3';
import H5_T from '../../../typography/H5/H5';
import Button from '../../atoms/Button';
import { round } from '../../../utils';

const H5 = styled(H5_T)`
    font-weight: bold;
`;

const NewPeakModal = ({
  close,
  dataPoint,
  setNewPeak,
  setHarmonicNumber,
  harmonicNumber,
  updateHarmonicFrequencies,
  showSetHarmonicButtons,
  selectedFrequencyContribution,
  title,
  theme,
  ...rest
}) => (
    <OutsideAlerter handleClick={close} open>
      <ChartPopupContainer {...rest}>
        <FlexContainer direction="column" marginbottom="24px">
          <H5>Frequency</H5>
          <H3>{round(dataPoint.x, 2)} {dataPoint.xUnit}</H3>
        </FlexContainer>
        <FlexContainer direction="column">
          <H5>Amplitude</H5>
          <H3>{round(dataPoint.y, 2)} {dataPoint.yUnit}</H3>
        </FlexContainer>
        {showSetHarmonicButtons && (
          <FlexContainer direction="column" alignItems="flex-start" margintop="24px">
            <Button text onClick={setNewPeak}>Mark Point as 1x</Button>
            <FlexContainer margintop="16px">
              <Button text onClick={updateHarmonicFrequencies}>Mark Point as</Button>
              <input
                type="number"
                min="1"
                title="Value should be from 1 to 500"
                max="500"
                name="harmonicNumber"
                value={harmonicNumber}
                style={{ width: '3.25em', color: theme.primaryColor, marginLeft: '3px' }}
                onChange={setHarmonicNumber}
              />
              <Button text onClick={updateHarmonicFrequencies}>x Harmonic</Button>
            </FlexContainer>
            {title !== 'multiline spectrum' && (
              <>
                <H5>Contribution of Frequency and its harmonics in spectrum</H5>
                <H3>{selectedFrequencyContribution}%</H3>
              </>
            )}
          </FlexContainer>
        )}
      </ChartPopupContainer>
    </OutsideAlerter>
);

NewPeakModal.propTypes = {
  dataPoint: PropTypes.object.isRequired,
  harmonicNumber: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
  setNewPeak: PropTypes.func.isRequired,
  setHarmonicNumber: PropTypes.func.isRequired,
  updateHarmonicFrequencies: PropTypes.func.isRequired,
  showSetHarmonicButtons: PropTypes.bool,
  selectedFrequencyContribution: PropTypes.number
};

NewPeakModal.defaultProps = {
  showSetHarmonicButtons: true,
  selectedFrequencyContribution: 0
};

export default withTheme(NewPeakModal);
