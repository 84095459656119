import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as _ from 'lodash';

import FlexContainer from 'common/components/atoms/FlexContainer';
import List from 'common/components/molecules/List';
import NavButton from 'common/components/atoms/NavButton';

import * as sensorActions from '../../../Sensors/actions/sensors.actions';
import { getCurrentAccount } from '../../../../onboarding/user.actions';
import * as machineDetailsActions from '../../MachineDetails/machineDetails.actions';
import InfoboxTitle from './molecules/InfoboxTitle/InfoboxTitle';
import SensorsItem from '../../../Sensors/components/SensorsItem';
import SensorsDetails from '../../../Sensors/components/SensorsDetails';

const Container = styled.div`
  height: inherit;
`;

const ListContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  overflow: auto;
  width: ${props => props.width};
  height: 100%;
  ${props =>
    props.width === '30%' &&
    `border-right: 2px solid ${props.theme.colors.greyL};`}
`;

const SensorsContainer = styled(FlexContainer).attrs({
  direction: 'row',
  flexWrap: 'wrap'
})`
  background-color: ${props => props.theme.colors.white};
  height: calc(100% - 56px);
`;

const AssociateSensorsButton = styled(NavButton)`
  margin-right: auto;
`;

const SensorList = (props) => {
  const detailsRef = useRef(null);
  useEffect(() => {
    props.setFooter({
      items: [
        {
          Tag: AssociateSensorsButton,
          props: {
            key: 'footer-btn0',
            text: true,
            onClick: props.associateSensors
          },
          children: 'Associate Sensors'
        },
        {
          Tag: NavButton,
          props: {
            key: 'footer-btn1',
            cancel: true,
            onClick: props.cancel
          },
          children: 'Cancel',
        },
        {
          Tag: NavButton,
          props: {
            key: 'footer-btn2',
            onClick: onClickDone,
            disabled: true
          },
          children: 'Done',
        }
      ]
    });
  }, []);

  const listProps = {
    columnSizes: [22, 16, 14, 14, 14, 20],
    headers: [
      {
        name: 'serial_number',
        label: 'Serial Number'
      },
      {
        name: 'machine',
        label: 'Machine'
      },
      {
        name: 'location',
        label: 'Location'
      },
      {
        name: 'battery_level',
        label: 'Battery Level'
      },
      {
        name: 'wifi_signal',
        label: 'Wi-Fi Signal'
      },
      {
        name: 'last_reported_at',
        label: 'Last Measurement'
      }
    ],
    maxHeightAfterScroll: 'calc(100vh - 111px)',
    triggerLoadMore: 'calc(100vh - 111px)',
    hasMore: false,
    loading: false,
    sorterState: null,
    setSorter: () => {},
    items: {
      object: props.associatedSensors,
      loading: false,
      hasMore: false
    },
    loadMoreItems: () => {},
    ItemComponent: SensorsItem,
    emptyMsg: 'No results found',
    editMode: true
  };
  if (props.sensors.selectedSensorList.length > 0) {
    listProps.columnSizes = [100];
    listProps.headers = [
      {
        name: 'serial_number',
        label: 'Serial Number'
      }
    ];
  }

  const closeDetails = () => {
    const { clearSelectedSensor } = props.sensorActions;
    clearSelectedSensor();
  };

  const updateFooter = (enabled) => {
    props.setFooter({
      items: [
        {
          Tag: AssociateSensorsButton,
          props: {
            key: 'footer-btn0',
            text: true,
            onClick: props.associateSensors
          },
          children: 'Associate Sensors'
        },
        {
          Tag: NavButton,
          props: {
            key: 'footer-btn1',
            cancel: true,
            onClick: props.cancel
          },
          children: 'Cancel',
        },
        {
          Tag: NavButton,
          props: {
            key: 'footer-btn2',
            onClick: onClickDone,
            disabled: !enabled
          },
          children: 'Done',
        }
      ]
    });
  };

  const onClickDone = () => {
    if (detailsRef && detailsRef.current) {
      detailsRef.current.saveSensorDetails().then(() => props.cancel());
    }
  };

  return (
    <Container>
      <InfoboxTitle>Associate Motes and other sensors to {props.createdMachineName}. If necessary, these details can be updated later in the Sensors tab.</InfoboxTitle>
      <SensorsContainer>
        <ListContainer width={props.sensors.selectedSensorList.length > 0 ? '30%' : '100%'}>
          <List {...listProps} />
        </ListContainer>
        {props.sensors.selectedSensorList.length > 0 && (
          <SensorsDetails
            ref={detailsRef}
            sensorsList={props.associatedSensors.filter(item =>
              props.sensors.selectedSensorList.includes(item.serial_number)
            )}
            closeDetails={closeDetails}
            editMode
            machineSensors
            version={
              props.currentAccount.preferences &&
              props.currentAccount.preferences.measurement_version
            }
            utcOffset={
              props.currentAccount.preferences &&
              props.currentAccount.preferences.timezone.utc_offset
            }
            createdMachineId={props.createdMachineId}
            updateFooter={updateFooter}
          />
        )}
      </SensorsContainer>
    </Container>
  );
};

const mapStateToProps = state => ({
  sensors: state.sensors,
  currentAccount: state.currentAccount,
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  sensorActions: bindActionCreators(sensorActions, dispatch),
  machineDetailsActions: bindActionCreators(machineDetailsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorList);
