import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingSvg from '../../images/LoadingSvg';

const LoadingContainer = styled.div`
  ${props => props.position && `position: ${props.position}`};
  ${props => props.height && `height: ${props.height}`};
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor}`};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
  z-index: 3;
  ${props => props.center && 'margin: auto;'}
`;

const Loading = ({ className, ...rest }) => (
  <LoadingContainer className={className} {...rest}>
    <LoadingSvg />
  </LoadingContainer>
);

Loading.propTypes = {
  position: PropTypes.string,
  center: PropTypes.bool,
  className: PropTypes.string.isRequired
};

Loading.defaultProps = {
  position: 'initial',
  center: false
};

export default styled(Loading)``;
