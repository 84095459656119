import React, { useState, useEffect, useCallback } from 'react';
import { Popup } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import MultiSelectDropdown_T from 'common/components/molecules/MultiSelectDropdown';
import { Button, FlexContainer, Loading } from 'common/components/atoms';
import Modal from 'common/components/organisms/Modal';
import { connect } from 'react-redux';

import { getMachineBuilderMetadata } from 'home/Machines/MachineBuilder/actions/machineBuilder.actions';
import { getUsers } from 'home/Settings/EventDefinitions/definition.actions';
import DropdownSelect_T from 'common/components/molecules/DropdownSelect';
import { getMachineConditionOptions } from 'common/constants';
import { getDropdownOptions } from 'common/utils';
import { roleOptions } from 'home/Settings/AccountUsers/accountUsers.constants';
import { humanize } from 'common/helpers';
import InputEditOnDemand from 'common/components/molecules/InputEditOnDemand';
import PlusSvg from 'common/images/PlusSvg';
import * as machineDetailsActions from 'home/Machines/MachineDetails/machineDetails.actions';
import * as failureModesActions from 'home/MachineDiagnostics/actions/failureModes.actions';
import { getSensorsList } from 'home/Sensors/actions/sensors.actions';
import InputField from 'common/components/atoms/InputField';
import fonts from 'common/styles/fonts';
import { getComponentsFromMachineId } from 'home/AssetHierarchy/utils/assetHierarchyUtils';
import { getComponentOptions } from 'home/Machines/MachineDetails/MachineCharts/actions/machineCharts.actions';
import Label_T from 'common/typography/Label/Label';
import H5 from 'common/typography/H5/H5';
import RBAC from 'common/rbac/RBAC';
import { mapComponentToResource, operations } from 'common/rbac/constants';
import { Task, ArrowDown, ArrowUp } from 'common/images/FaIcons';
import { toastr } from 'react-redux-toastr';
import { isPetasenseAdmin } from 'common/rbac/util';
import colors from 'common/styles/colors';
import * as templateActions from 'home/TaskTemplates/actions/template.actions';
import TaskTitleWithId from './TaskTitleWithId';
import ListContainer from './atoms/ListContainer';
import RepairDetails from './RepairDetails';
import CollapsibleBox from './atoms/CollapsibleBox';
import NoteDetails from './NoteDetails';
import Footer from './atoms/Footer';
import * as taskActions from '../actions/tasks.actions';
import * as repairActions from '../actions/repairs.actions';
import * as noteActions from '../actions/notes.actions';
import TaskHistory from './TaskHistory';
import Attachments from '../organisms/Attachments';
import TaskTriggers from './TaskTriggers';
import { getChartSearchParams } from '../utils';

const DropdownSelect = styled(DropdownSelect_T).attrs({
  fontSize: '14px',
  minHeight: '71px'
})`
  .span-label {
    font-weight: 600;
  }
`;

const H3 = styled.span`
  font-size: 12px;
  font-family: Petasense Open Sans;
  ${props => props.color && `color: ${props.color};`}
  cursor: pointer;
`;

const Title = styled(H5)`
  font-weight: bold;
  margin: 0 0 1em 0;
  line-height: 0;
`;

const Description = styled(H5)`
  font-weight: 300;
  margin: 0 0 1.5em 0;
`;

const RowContainer = styled(FlexContainer).attrs({ justifyContent: 'space-between' })`
  ${InputField} {
    flex-basis: 49%;
    ${Label} {
      color: ${props => props.theme.colors.greyXXD};
      ${props => props.theme.fonts.boldLabel};
      margin-bottom: 6px
    }
  }
`;
const Label = styled(Label_T)`
color: ${props => props.theme.colors.greyXXD};
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 12px;
  display: block;
`;
const MultiSelectDropdown = styled(MultiSelectDropdown_T)`
  background-color: white;
`;
const fontSettings = `
  font-family: "Petasense Open Sans";
  font-size: 16px;
  font-weight: 400;
`;
const FollowersSelectionView = ({ text, showFollowIcon, onFollowClick }) => (
  <FlexContainer justifyContent="space-between" width="100%">
    <span>{text}</span>
    {showFollowIcon && (
      <span title="Follow this task" onClick={onFollowClick}><PlusSvg /></span>
    )}
  </FlexContainer>
);

function TaskDetailsModal(props) {
  const getDefectOptions = () => {
    let defectOptions = [{ value: 0, text: 'N/A' }];
    if (props.machineDetails.object && props.machineDetails.object.asset_type === 'non_rotating') {
      if (props.failureModes.list && props.machineDetails.object.sub_type) {
        const failureModes = props.failureModes.list.filter(fm => fm.sub_type === props.machineDetails.object.sub_type)
          .map(fm => ({ value: fm.id, text: fm.name }));
        defectOptions = [...defectOptions, ...failureModes];
      }
    } else if (props.machineDetails.object && props.failureModes.list) {
      const components = getComponentsFromMachineId(props.hierarchy, props.machineDetails.object.id);
      const machineComponentTypes = components.map(c => c.node_sub_type);
      const rotatingFailureModes = props.failureModes.list.filter((fm) => {
        if (fm.asset_type !== 'rotating') return false;
        const failureModeComponentTypes = fm.component_types;
        if (_.isNil(failureModeComponentTypes) || _.isEmpty(failureModeComponentTypes)) return true;
        return _.some(machineComponentTypes, ct => failureModeComponentTypes.includes(ct));
      }).map(fm => ({ value: fm.id, text: fm.name }));
      defectOptions = [...defectOptions, ...rotatingFailureModes];
    }

    return defectOptions;
  };

  const getComponentList = (components) => {
    const items = [];
    components.forEach((element) => {
      items.push({ text: humanize(element.component_subtype), value: element.component_subtype });
    });
    return items;
  };

  const onTaskSave = () => {
    const params = {
      name,
      state,
      description,
      task_type,
      user_role_assigned,
      machine_id: props.machineId || props.machine_id,
      devices_list,
      triggers_details: _.cloneDeep(selectedTriggers),
      component_type
    };
    if (params.triggers_details) {
      Object.keys(params.triggers_details).forEach((key) => {
        delete params.triggers_details[key].selected;
      });
    }
    if (!_.isEmpty(recommended_action)) {
      params.recommended_action = recommended_action;
    }
    if (task_type === 'asset_maintenance') {
      params.condition = condition;
      if (defect) params.defect_id = defect;
    }
    if (props.taskDetails.ara_id) {
      params.ara_id = props.taskDetails.ara_id;
    }
    setShowSaveLoader(true);
    const isAssetsPage = props.location.pathname === '/assets';
    props.taskActions.addTask(params, isAssetsPage).then(
      () => {
        if (_.isEmpty(props.files)) {
          setShowSaveLoader(false);
          props.closeModal();
        }
      },
      () => {
        setShowSaveLoader(false);
      }
    );
  };

  const onTaskTemplateCreate = () => {
    if (!checkMandatoryFeildsTemplateCreation()) {
      return;
    }
    const params = {
      name,
      description,
      task_type,
      component_type,
      is_global: isPetasenseAdmin(props.user)
    };
    if (!_.isEmpty(recommended_action)) {
      params.recommended_action = recommended_action;
    }
    if (task_type === 'asset_maintenance') {
      params.condition = condition;
      if (defect) params.defect_id = defect;
    }
    setShowSaveLoader(true);
    props.templateActions.createTemplate(params).then(
      () => {
        setShowSaveLoader(false);
        props.closeModal();
      },
      () => {
        setShowSaveLoader(false);
      }
    );
  };

  const checkMandatoryFeildsTemplateCreation = () => {
    if (_.isEmpty(name)) {
      toastr.error('Please enter task name.');
      return false;
    }
    if (_.isEmpty(recommended_action)) {
      toastr.error('Please enter recommended action.');
      return false;
    }
    if (_.isEmpty(description)) {
      toastr.error('Please enter descrption.');
      return false;
    }
    if (_.isEmpty(task_type)) {
      toastr.error('Please select task type.');
      return false;
    }
    if (task_type === 'asset_maintenance' && _.isEmpty(condition)) {
      toastr.error('Please select a condition.');
      return false;
    }
    if (task_type === 'asset_maintenance') {
      const conditionOptions = getMachineConditionOptions(3);
      if (_.isEmpty(conditionOptions.find(opt => opt.value === condition))) {
        toastr.error('Condition must be "Good", "Warning" or "Critical" for template creation.');
        return false;
      }
    }
    if (_.isEmpty(component_type)) {
      toastr.error('Please select component type.');
      return false;
    }
    return true;
  };

  const onTaskUpdate = (field, value) => {
    const params = {};
    params[field] = value;
    props.taskActions.updateTask(props.taskDetails.id, params);
  };

  const onTemplateSelection = (value) => {
    const selected_template = template_Data.find(element => (element.id === value));
    if (selected_template) {
      setName(selected_template.name);
      setTemplateName(selected_template.name);
      setRecommendedAction(selected_template.recommended_action);
      setDescription(selected_template.description);
      setTaskType(selected_template.task_type);
      setComponentType(selected_template.component_type);
      if (selected_template.task_type === 'asset_maintenance') {
        const defectID = defectOptions.find(element => (element.text === selected_template.suspected_defect));
        setDefect(defectID.value);
        setCondition(selected_template.condition);
      }
    }
  };


  const onDropdownChange = (value, field) => {
    switch (field) {
      case 'state':
        setTaskState(value);
        break;
      case 'user_role_assigned':
        setAssignedTo(value);
        break;
      case 'component_type':
        setComponentType(value);
        break;
      case 'task_type':
        setTaskType(value);
        break;
      case 'condition':
        setCondition(value);
        break;
      case 'defect_id':
        setDefect(value);
        break;
      default:
        break;
    }
    if (props.isEditMode && props.taskDetails[field] !== value) {
      onTaskUpdate(field, value);
    }
  };

  const getSelectedDefect = () => {
    if (!props.isEditMode) return {};
    if (!props.taskDetails.defect_id) return 0;
    const foundDefect = defectOptions.find(d => d.value === props.taskDetails.defect_id);
    return foundDefect ? foundDefect.value : null;
  };

  const getSelectedCondition = (condition) => {
    const conditionOptions = getMachineConditionOptions(props.assetConditionLevels);
    if (conditionOptions.find(opt => opt.value === condition)) return condition;
    return '';
  };

  const getSensorsList = (search_key = '') => {
    const filters = [
      { name: 'type',
        op: 'in',
        value: [
          'Vibration Mote (VM1)',
          'Vibration Mote (VM2)',
          'Vibration Mote (VM3)',
          'Vibration Mote (VM4P)',
          'Vibration Mote (GSP)',
          'Vibration Mote (VM4)',
          'Vibration Mote (GS)',
          'Transmitter'
        ]
      },
      { name: 'machine_id',
        op: 'in',
        value: [props.machineId || props.machine_id || props.taskDetails.machine_id]
      },
    ];
    props.getSensorsList(filters, search_key, 1, false).then(
      (res) => {
        if (!res) return;
        setSensorOptions(res.items.map(item => ({
          id: item.id,
          serial_number: item.serial_number,
          model: item.model
        })));
      }
    );
  };

  const getSensorsDebounced = useCallback(_.debounce(key => getSensorsList(key), 300), []);

  const toggleDeviceOption = (sr_no) => {
    const clickedOption = sensorOptions.find(opt => opt.serial_number === sr_no);
    setSelectedDevices((prevList) => {
      let newList = [];
      if (prevList.find(opt => _.isEqual(clickedOption, opt))) {
        newList = prevList.filter(opt => !_.isEqual(clickedOption, opt));
      } else {
        newList = [...prevList, clickedOption];
      }
      if (props.isEditMode && !_.isEqual(newList, props.taskDetails.devices_list)) {
        onTaskUpdate('devices_list', newList);
      }
      return newList;
    });
  };

  const toggleFollower = (user_id, e = null) => {
    if (e && e.stopPropagation) e.stopPropagation();
    setFollowers((prevList) => {
      let newList = [];
      if (prevList.includes(user_id)) {
        newList = prevList.filter(id => id !== user_id);
      } else {
        newList = [...prevList, user_id];
      }
      if (props.isEditMode && !_.isEqual(newList, props.taskDetails.followers)) {
        onTaskUpdate('followers', newList);
      }
      return newList;
    });
  };

  const toggleTrigger = (trigger) => {
    setSelectedTriggers((prevTriggers) => {
      const newTriggers = {
        ...prevTriggers,
        [trigger]: {
          ...prevTriggers[trigger],
          selected: !(prevTriggers[trigger] && prevTriggers[trigger].selected)
        }
      };
      if (props.isEditMode && !_.isEqual(newTriggers, props.taskDetails.triggers)) {
        const triggersToUpdate = _.cloneDeep(newTriggers);
        if (triggersToUpdate) {
          Object.keys(triggersToUpdate).forEach((key) => {
            delete triggersToUpdate[key].selected;
          });
        }
        onTaskUpdate('triggers_details', triggersToUpdate);
      }
      return newTriggers;
    });
  };

  const toggleEvent = (trigger, eventDetail) => {
    setSelectedTriggers((prevTriggers) => {
      const prevEvents = prevTriggers[trigger].resources_info || [];
      let newEvents = [];
      if (prevEvents.find(info => info.activity_id === eventDetail.activity_id)) {
        newEvents = prevEvents.filter(resource => resource.activity_id !== eventDetail.activity_id);
      } else {
        const resource = {
          activity_id: eventDetail.activity_id,
          description: eventDetail.description
        };
        if (eventDetail.tag_id && eventDetail.timestamp) {
          resource.urlInfo = {
            pathname: '/configs',
            searchParams: getChartSearchParams(eventDetail)
          };
        }
        newEvents = [...prevEvents, resource];
      }
      const newTriggers = {
        ...prevTriggers,
        [trigger]: {
          ...prevTriggers[trigger],
          resources_info: newEvents
        }
      };
      if (props.isEditMode && !_.isEqual(newTriggers, props.taskDetails.triggers)) {
        const triggersToUpdate = _.cloneDeep(newTriggers);
        if (triggersToUpdate) {
          Object.keys(triggersToUpdate).forEach((key) => {
            delete triggersToUpdate[key].selected;
          });
        }
        onTaskUpdate('triggers_details', triggersToUpdate);
      }
      return newTriggers;
    });
  };

  const getFollowersDisplayText = () => {
    let text = 0;
    if (followers.length) {
      text = followers.length;
      if (followers.includes(props.user.id)) {
        text = `You${followers.length - 1 ? ` +${followers.length - 1}` : ''}`;
      }
    }
    return text;
  };

  const checkIfToRenderTriggers = () => {
    if (!props.isEditMode) return !_.isEmpty(props.taskDetails.allowed_triggers) || !_.isEmpty(props.allowedTriggers);
    return !_.isEmpty(selectedTriggers);
  };

  const nameChangeHandler = (e) => {
    const value = e.target.value;
    setName(value);
    if (value && value.length > 150) {
      setNameError("You can't use more than 50 characters");
    } else {
      setNameError('');
    }
  };

  const renderTrendIndicatorsInfo = () => {
    const info = props.taskDetails.trend_indicators_info;
    if (!info || _.isNaN(info.max_value)) return null;

    const maxValue = info.max_value;
    let color = colors.good;
    if (maxValue >= 50) color = colors.alarmCritical;
    else if (maxValue >= 25) color = colors.alarmWarning;
    return (
      <FlexContainer direction="column">
        <H3 color={color}>
          {`${_.round(Math.abs(maxValue), 2)}% `}
          {maxValue > 0 ? <ArrowUp /> : <ArrowDown />}
        </H3>
      </FlexContainer>
    );
  };

  const setInitialTriggers = () => {
    let allowed_triggers = [];
    if (!props.isEditMode) {
      allowed_triggers = _.isEmpty(props.taskDetails.allowed_triggers) ? props.allowedTriggers :
        props.taskDetails.allowed_triggers;
    } else {
      allowed_triggers = _.cloneDeep(props.taskDetails.triggers_details || []);
      Object.keys(allowed_triggers).forEach((key) => {
        allowed_triggers[key].selected = true;
      });
    }
    setAllowedTriggers(allowed_triggers);
    setSelectedTriggers(allowed_triggers);
  };

  const menuItems = [];
  menuItems.push({
    text: 'Save as Template',
    onClick: onTaskTemplateCreate
  });

  const setRepairModalOpen = id => props.repairActions.openRepairModal(id);

  const setNoteModalOpen = id => props.noteActions.openNoteModal(id, props.taskDetails.id);

  const toggleRepairExpand = () => setRepairExpanded(prevState => !prevState);

  const toggleNoteExpand = () => setNoteExpanded(prevState => !prevState);

  const [name, setName] = useState('');
  const [template_name, setTemplateName] = useState('');
  const [state, setTaskState] = useState('open');
  const [description, setDescription] = useState('');
  const [recommended_action, setRecommendedAction] = useState('');
  const [task_type, setTaskType] = useState('asset_maintenance');
  const [component_type, setComponentType] = useState('');
  const [user_role_assigned, setAssignedTo] = useState('');
  const [condition, setCondition] = useState('');
  const [devices_list, setSelectedDevices] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [selectedTriggers, setSelectedTriggers] = useState([]);

  const [defectOptions, setDefectOptions] = useState(getDefectOptions());
  const [defect, setDefect] = useState(getSelectedDefect());

  const [createDisabled, setCreateDisabled] = useState(true);
  const [sensorOptions, setSensorOptions] = useState([]);
  const [repairExpanded, setRepairExpanded] = useState(false);
  const [noteExpanded, setNoteExpanded] = useState(false);
  const [deviceSearchKey, setSearchKey] = useState('');
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [attachmentsError, setAttachmentsError] = useState(false);
  const [nameError, setNameError] = useState('');
  const [template_List, setTemplateList] = useState([]);
  const [template_Data, setTemplateData] = useState([]);
  const [templateSelectionEnabled, setTemplateSelection] = useState(false);
  const [componentsList, setComponentsList] = useState([]);
  const [allowedTriggers, setAllowedTriggers] = useState([]);

  const toggleTemplateSelection = () => {
    setTemplateSelection(prevState => !prevState);
  };

  useEffect(() => {
    if (!props.taskMetadata) {
      props.getMachineBuilderMetadata();
    }

    props.getComponentOptions(Number(props.machineId || props.taskDetails.machine_id)).then(
      (res) => {
        const componentsList = getComponentList(res);
        setComponentsList(componentsList);
      }
    );

    if (!props.isEditMode) {
      const {
        getTemplatesList } = props.templateActions;
      getTemplatesList().then(
        (res) => {
          const template_List = [];
          res.items.forEach((element) => {
            template_List.push({ text: element.name, value: element.id });
          });
          setTemplateList(template_List);
          setTemplateData(res.items);
        }
      );
      setInitialTriggers();
    }

    if (props.match && props.match.params.task_id) {
      const task_id = parseInt(props.match.params.task_id, 10);
      props.taskActions.getTaskDetails(task_id, false);
    }

    if (_.isEmpty(props.users)) {
      props.getUsers();
    }
  }, []);

  useEffect(() => {
    if (props.machineId || props.taskDetails.machine_id || props.machine_id) {
      getSensorsDebounced(deviceSearchKey);
    }
  }, [deviceSearchKey]);

  useEffect(() => {
    const { machineDetails, machineId, machineDetailsActions, failureModesActions } = props;
    if (
      (!machineDetails.object && (machineId || props.taskDetails.machine_id)) ||
      (machineDetails.object && (machineId !== props.taskDetails.machine_id))
    ) {
      machineDetailsActions.getMachineDetails(props.taskDetails.machine_id || machineId);
    }
    failureModesActions.getFailureModeList();

    if (machineId || props.taskDetails.machine_id) {
      getSensorsList();
    }
  }, [props.taskDetails.machine_id]);

  useEffect(() => {
    setDefectOptions(getDefectOptions());
  }, [props.failureModes, props.machineDetails.object]);

  useEffect(() => {
    if (
      (_.isEmpty(name) || _.isEmpty(task_type) || _.isEmpty(description)) ||
      (task_type === 'asset_maintenance' && _.isEmpty(condition))
    ) {
      setCreateDisabled(true);
    } else {
      setCreateDisabled(false);
    }
  }, [name, task_type, description, condition, component_type, template_name]);

  useEffect(() => {
    if (props.isEditMode) {
      setTemplateName(props.taskDetails.template_name);
      setName(props.taskDetails.name);
      setTaskState(props.taskDetails.state);
      setDescription(props.taskDetails.description);
      setRecommendedAction(props.taskDetails.recommended_action);
      setTaskType(props.taskDetails.task_type);
      setAssignedTo(props.taskDetails.user_role_assigned);
      setSelectedDevices(props.taskDetails.devices_list);
      setFollowers(props.taskDetails.followers || []);
      setCondition(getSelectedCondition(props.taskDetails.condition));
      setDefectOptions(getDefectOptions());
      setComponentType(props.taskDetails.component_type);
      setInitialTriggers();
    }
  }, [props.taskDetails]);

  useEffect(() => {
    setDefect(getSelectedDefect());
  }, [defectOptions]);

  useEffect(() => {
    // checks if upload has been completed and closes the modal
    const completed = props.upload && props.upload.index === props.files.length - 1 && props.upload.progress >= 100;
    if (!props.isEditMode && completed && !_.isEmpty(props.files)) {
      setShowSaveLoader(false);
      props.closeModal();
    }
  }, [props.upload]);

  const trendIndicatorsInfo = props.taskDetails && props.taskDetails.trend_indicators_info;
  const machineName = props.isEditMode ? props.taskDetails.machine_name || props.machine_name : props.machineName;
  const eventsDetails = _.isEmpty(props.taskDetails.eventsDetails) ? props.eventsDetails :
    props.taskDetails.eventsDetails;

  const handleClose = () => {
    const state = window.history.state;
    if (state && state.prevLocation) {
      window.history.replaceState(null, '', state.prevLocation.pathname + (state.prevLocation.search || ''));
    }
    props.closeModal();
  };

  return (
   <Modal
     title={() => <TaskTitleWithId isEdit={props.isEditMode} taskId={props.taskDetails.id} machineName={machineName} />}
     padding="0"
     outerPadding="0"
     headerPadding="1rem"
     headerRadius="5px"
     width="72rem"
     maxWidth
     menuItems={menuItems}
     close={handleClose}
     showHeaderBorder
   >
      <FlexContainer
        height="50rem"
        style={{ maxHeight: 'calc(100vh - 150px)' }}
        position="relative"
      >
        <ListContainer
          width="70%"
        >
          { !props.isEditMode && (
                <div style={{ position: 'absolute', right: '20px' }}>
                  <RBAC
                    resource={mapComponentToResource.TaskTemplates}
                    operation={operations.Read}
                    yes={(
                <Button
                  title="Show task templates"
                  secondary={colors.black}
                  className="toolbar-item icon"
                  onClick={toggleTemplateSelection}
                  style={{ border: 'none' }}
                  color={templateSelectionEnabled ? colors.good : colors.grey}
                >
                  <Task width="16" height="16" />
                </Button>
              )}
                  />
                </div>
          )}
          { templateSelectionEnabled && (
              <RowContainer>
                <InputField
                  type="select"
                  name="Task template"
                  options={template_List}
                  placeholder="Click to select template options"
                  value={template_name}
                  onChange={(e, { value }) => onTemplateSelection(value)}
                  disabled={props.taskDetails.loading}
                  fontSettings={fontSettings}
                  optionFontSettings={fontSettings}
                  search
                  heightLimit
                />
              </RowContainer>
          )}

          <FlexContainer>
            <InputEditOnDemand
              inputType="title"
              type="text"
              placeholder="Task name"
              id="task-name"
              value={name}
              nameError={nameError}
              onChange={nameChangeHandler}
              onSave={value => onTaskUpdate('name', value)}
              isSaveDisabled={name === props.taskDetails.name || !_.isEmpty(nameError)}
              creationMode={!props.isEditMode}
              width="100%"
            />
            {props.isEditMode && trendIndicatorsInfo && trendIndicatorsInfo.max_value && (
              <FlexContainer direction="column" padding="0 0 1.3em 0.5em" justifyContent="flex-end">
                <Popup trigger={renderTrendIndicatorsInfo()} flowing hoverable position="right top">
                  <Title>Component</Title>
                  <Description>{trendIndicatorsInfo.component}</Description>
                  <Title>Location</Title>
                  <Description>{trendIndicatorsInfo.location}</Description>
                  <Title>Direction</Title>
                  <Description>{trendIndicatorsInfo.direction}</Description>
                  <Title>Feature</Title>
                  <Description>{trendIndicatorsInfo.feature_name}</Description>
                </Popup>
              </FlexContainer>
            )}
          </FlexContainer>
          <InputEditOnDemand
            inputType="text"
            type="textarea"
            placeholder="Description"
            height="100px"
            label="Description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            onCancel={() => props.isEditMode && setDescription(props.taskDetails.description || '')}
            onSave={value => onTaskUpdate('description', value)}
            isSaveDisabled={description === props.taskDetails.description}
            creationMode={!props.isEditMode}
          />
          <InputEditOnDemand
            inputType="text"
            type="textarea"
            placeholder="Recommended Action"
            height="100px"
            label="Recommended Action"
            value={recommended_action}
            onChange={e => setRecommendedAction(e.target.value)}
            onCancel={() => props.isEditMode && setRecommendedAction(props.taskDetails.recommended_action || '')}
            onSave={value => onTaskUpdate('recommended_action', value)}
            isSaveDisabled={recommended_action === props.taskDetails.recommended_action}
            creationMode={!props.isEditMode}
          />
          <Attachments
            originType="task"
            originId={props.taskDetails.id}
            attachments={props.taskDetails.attachments}
            allowUpload={props.isEditMode}
            showExistingAtts={props.isEditMode}
            showSelectedFiles={!props.isEditMode}
            setAttachmentsError={setAttachmentsError}
          />
          {checkIfToRenderTriggers() && (
            <TaskTriggers
              allowedTriggers={allowedTriggers}
              selectedTriggers={selectedTriggers}
              toggleTrigger={toggleTrigger}
              toggleEvent={toggleEvent}
              isEditMode={props.isEditMode}
              eventsDetails={eventsDetails}
            />
          )}
          {props.isEditMode && (
            <>
              <CollapsibleBox label="New Repair" expanded={repairExpanded} toggleExpand={toggleRepairExpand}>
                <RepairDetails
                  showCancel={false}
                  taskId={props.taskDetails.id}
                  machine_id={props.machineId || props.machine_id || props.taskDetails.machine_id}
                  toggleExpand={toggleRepairExpand}
                />
              </CollapsibleBox>
              <CollapsibleBox label="New Note" expanded={noteExpanded} toggleExpand={toggleNoteExpand}>
                <NoteDetails
                  showCancel={false}
                  taskId={props.taskDetails.id}
                  machine_id={props.machineId || props.machine_id || props.taskDetails.machine_id}
                  toggleExpand={toggleNoteExpand}
                />
              </CollapsibleBox>
              <TaskHistory
                loading={props.taskDetails.historyLoading}
                items={props.taskDetails.history}
                setRepairModalOpen={setRepairModalOpen}
                setNoteModalOpen={setNoteModalOpen}
                taskId={props.taskDetails.id}
                repairs={props.taskDetails.repairs}
                notes={props.taskDetails.notes}
              />
            </>
          )}
        </ListContainer>
        <ListContainer
          width="30%"
          background
          borderLeft
          borderRadius="0 0 5px 0"
        >
          <DropdownSelect
            onClickItem={(_text, value, _parText) => onDropdownChange(value, 'state')}
            placeholder="Select State"
            label="State"
            disabled={!props.isEditMode}
            data={props.taskMetadata ? getDropdownOptions(props.taskMetadata.states) : []}
            value={humanize(state)}
            heightLimit
          />
          <DropdownSelect
            onClickItem={(_text, value, _parText) => onDropdownChange(value, 'user_role_assigned')}
            placeholder="Assign task to.."
            label="Assigned to"
            data={roleOptions}
            value={humanize(user_role_assigned)}
            heightLimit
          />
          <DropdownSelect
            label="Component Type"
            placeholder="Select"
            value={humanize(component_type || '')}
            data={componentsList}
            onClickItem={(_text, value, _parText) => onDropdownChange(value, 'component_type')}
            heightLimit
            clearable
          />

          <DropdownSelect
            onClickItem={(_text, value, _parText) => onDropdownChange(value, 'task_type')}
            placeholder="Task Type"
            label="Task Type"
            data={props.taskMetadata ? getDropdownOptions(props.taskMetadata.types) : []}
            value={humanize(task_type)}
            heightLimit
          />
          {task_type === 'asset_maintenance' && (
            <>
              <DropdownSelect
                onClickItem={(_text, value, _parText) => onDropdownChange(value, 'condition')}
                type="bullet"
                placeholder="Select Condition"
                label="Condition"
                data={getMachineConditionOptions(props.assetConditionLevels)}
                value={humanize(condition)}
                heightLimit
              />
              <InputField
                label="Defect"
                type="select"
                options={defectOptions}
                placeholder="Select Defect"
                value={defect}
                onChange={(_e, { value }) => onDropdownChange(value, 'defect_id')}
                fontSettings={fonts.tableRow}
                optionFontSettings={fonts.selectOption}
                search
              />
            </>
          )}
          {task_type === 'instrumentation' && (
            <MultiSelectDropdown
              className="ed-multi-select-dropdown"
              key="instrumentation-devices"
              fontSize="14px"
              optionsAvailable={sensorOptions.map(
                op => ({ text: op.serial_number, value: op.serial_number })
              )}
              selectedOptions={devices_list.map(opt => opt.serial_number)}
              disableSelectAll
              toggleOption={toggleDeviceOption}
              id="devices-select"
              label="Devices"
              placeholder="Select Devices"
              setSearchField={setSearchKey}
              backendSearch
              showEmptyMsg
            />
          )}
          {props.isEditMode && (
            <MultiSelectDropdown
              className="followers-select-dropdown"
              key="task-followers"
              fontSize="14px"
              optionsAvailable={props.users.map(
                user => ({ text: `${user.first_name} ${user.last_name}`, value: user.id })
              )}
              selectedOptions={followers}
              disableSelectAll
              toggleOption={toggleFollower}
              id="followers-list"
              label="Followers"
              customSelectionView={() => (
                <FollowersSelectionView
                  text={getFollowersDisplayText()}
                  showFollowIcon={!followers.includes(props.user.id)}
                  onFollowClick={e => toggleFollower(props.user.id, e)}
                />
              )}
              showEmptyMsg
            />
          )}
        </ListContainer>
        {(props.taskDetails.loading || showSaveLoader) && <Loading position="absolute" height="100%" backgroundColor="#e4e4e447" />}
      </FlexContainer>
      {!props.isEditMode && (
        <Footer
          showBorderTop
          onCancel={props.closeModal}
          onCreateTaskTemplate={onTaskTemplateCreate}
          showCreateTaskTemplate={false}
          onSave={onTaskSave}
          saveDisabled={createDisabled || showSaveLoader || attachmentsError || !_.isEmpty(nameError)}
        />
      )}
   </Modal>
  );
}

const mapStateToProps = state => ({
  taskMetadata: state.machines.machineBuilder.metadata.task_metadata,
  assetConditionLevels: state.currentAccount.preferences.machine_condition_levels,
  machineDetails: state.machineDetails.details,
  failureModes: state.assetFailureMode.failureModes,
  machineName: state.machineDetails.details.object && state.machineDetails.details.object.name,
  machineId: state.machineDetails.details.object && state.machineDetails.details.object.id,
  taskDetails: state.taskModal.openTaskDetails,
  taskTemplates: state.taskTemplates,
  users: state.settings.eventDefinitions.static_data.users,
  user: state.user.user,
  files: state.attachments.files && state.attachments.files.task,
  upload: state.attachments.upload && state.attachments.upload.task,
  hierarchy: state.assetHierarchyReducer.assetInfo.hierarchy
});

const mapDispatchToProps = dispatch => ({
  getMachineBuilderMetadata: bindActionCreators(getMachineBuilderMetadata, dispatch),
  getSensorsList: bindActionCreators(getSensorsList, dispatch),
  getComponentOptions: bindActionCreators(getComponentOptions, dispatch),
  getUsers: bindActionCreators(getUsers, dispatch),
  taskActions: bindActionCreators(taskActions, dispatch),
  templateActions: bindActionCreators(templateActions, dispatch),
  repairActions: bindActionCreators(repairActions, dispatch),
  noteActions: bindActionCreators(noteActions, dispatch),
  machineDetailsActions: bindActionCreators(machineDetailsActions, dispatch),
  failureModesActions: bindActionCreators(failureModesActions, dispatch),
});

TaskDetailsModal.propTypes = {
  isEditMode: PropTypes.bool,
  closeModal: PropTypes.func,
  eventsDetails: PropTypes.object,
  allowedTriggers: PropTypes.object
};

TaskDetailsModal.defaultProps = {
  isEditMode: false,
  closeModal: () => {},
  eventsDetails: [],
  allowedTriggers: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TaskDetailsModal));
