import { combineReducers } from 'redux';
import { machineAttachmentsReducer } from 'home/HomePage/reducers/machineAttachments.reducer';
import { completionStatus, machineDetails } from './reducers/machineDetails.reducer';
import { machineDetailsTags } from './reducers/machineDetailsTags.reducer';
import { picturesReducer } from './Pictures/pictures.reducer';
import { analystReducer } from './Analyst/reducer';
import MachineInfoReducer from './MachineInfo/reducers/machineInfo.reducers';
import MachineMLReducer from './Health/reducers/machineML.reducers';
import MachineDiagnosticTestRunReducer from './Health/reducers/DiagnosticTestRun.reducers';
import MachineBhsReducer from './Health/reducers/machineBhs.reducers';
import MachineChartsReducer from './MachineCharts/reducers/machineCharts.reducers';
import MachineOverviewReducer from './MachineOverview/reducers/machineOverview.reducers';
import graphicsReducer from './MachineOverview/reducers/graphics.reducers';
import analystSummaryReducer from './Health/reducers/analystSummary.reducers';

export const reducer = combineReducers({
  details: machineDetails,
  tags: machineDetailsTags,
  pictures: picturesReducer,
  info: MachineInfoReducer,
  ML: MachineMLReducer,
  DiagnosticTestRun: MachineDiagnosticTestRunReducer,
  BHS: MachineBhsReducer,
  AS: analystSummaryReducer,
  charts: MachineChartsReducer,
  overview: MachineOverviewReducer,
  analyst: analystReducer,
  graphics: graphicsReducer,
  completion_status: completionStatus,
  attachments: machineAttachmentsReducer,
});

// machineDetails reducer to reset back to initial state when requested for a new machine details.
export const machineDetailsReducer = (state, action) => reducer(state, action);
