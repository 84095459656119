import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import NodeItem_T from 'common/components/atoms/NodeItem';
import { CapitalizeEachWord } from 'common/utils';
import SvgBtn from 'home/Machines/MachineBuilder/components/atoms/SvgBtn/SvgBtn';
import PlusSvg from 'common/images/PlusSvg';
import MinusSvg from 'common/images/MinusSvg';
import { FlexContainer } from 'common/components/atoms';

const NodeItem = styled(NodeItem_T)`
  font-size: 16px;
  border-bottom: 1px solid #eff0ee;
  ${props => props.selected && `
    background-color: #EDF3E1;
    &:hover {
      background-color: #EDF3E1;
    }
  `}
`;

const ItemContainer = styled(FlexContainer)`
  display: flex;
  align-items: center;
  height: 100%;
  ${SvgBtn} {
    position: relative;
    margin-right: 7.5px;
  }
  ${SvgBtn}, ${SvgBtn} span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CompareTagsHierarchy = ({
  hierarchy,
  onHierarchyGroupClick,
  onGroupItemClick,
  selectedItemKey
}) => (
  <>
    {Object.keys(hierarchy).map(firstLayerKey => (
      <Fragment key={firstLayerKey}>
        <NodeItem
          onClick={() => onHierarchyGroupClick(firstLayerKey)}
          height="25px"
          padding="22px"
          paddingLeft="0.75rem"
        >
          <ItemContainer>
            <SvgBtn
              opaque
              greyBorder
              height="16px"
              width="16px"
            >
              {hierarchy[firstLayerKey].expanded ?
                <MinusSvg height={8} width={8} /> :
                <PlusSvg height={8} width={8} />
              }
            </SvgBtn>
            {CapitalizeEachWord(firstLayerKey)}
          </ItemContainer>
        </NodeItem>
        {hierarchy[firstLayerKey].expanded && (
          Object.keys(hierarchy[firstLayerKey].items).map(secondLayerKey => (
            <NodeItem
              key={secondLayerKey}
              onClick={() => onGroupItemClick(firstLayerKey, secondLayerKey)}
              height="25px"
              padding="22px"
              selected={selectedItemKey === secondLayerKey}
              nohover={selectedItemKey === secondLayerKey}
            >
              {secondLayerKey}
            </NodeItem>
          ))
        )}
      </Fragment>
    ))}
  </>
);

CompareTagsHierarchy.propTypes = {
  hierarchy: PropTypes.object.isRequired,
  onHierarchyGroupClick: PropTypes.func.isRequired,
  onGroupItemClick: PropTypes.func.isRequired,
  selectedItemKey: PropTypes.string
};

CompareTagsHierarchy.defaultProps = {
  selectedItemKey: null
};

export default CompareTagsHierarchy;
