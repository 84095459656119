import styled from 'styled-components';

import Container from '../atoms/Container';


const ModalContainer = styled(Container)`
  height: ${props => props.fullScreen ? '100%' : props.height};
  border-radius: 0.5em;
  width: ${props => props.width};
  margin: auto;
  max-height: ${props => props.maxHeight};
  min-height: ${props => props.minHeight};
  ${props => props.width === '100%' ? 'min-height: 100%;' : 'padding: 0px 20px 25px'};
  ${props => props.width === '100%' && props.version !== 'v2' && 'padding: 56px 0px'};
  ${props => props.outerPadding ? `padding: ${props.outerPadding}` : ''};
  box-sizing: border-box;
`;

ModalContainer.displayName = 'ModalContainer';

export default styled(ModalContainer)``;
