import React from 'react';
import { Popup } from 'semantic-ui-react';
import styled, { withTheme } from 'styled-components';

const DiffButtonContainer = styled.span`
  padding: 3px;
  ${props => props.theme.fonts.boldLabel}
  border: 1px solid ${props => props.theme.colors.darkGray};
  border-radius: 4px;
  background-color: white;
  width: auto;
`;

const ValueContainer = styled.div`
  padding: 20px;
  margin: 1px;
  background-color: ${props => props.bgColor};
  border-left: 5px solid ${props => props.borderColor};
  max-width: 50rem;
  max-height: 10rem;
  overflow: auto;
  ${props => props.theme.fonts.lightLabel}
  ${props => props.theme.scrollbar}
`;

const DiffButton = () => <DiffButtonContainer>Diff</DiffButtonContainer>;

function DiffViewer({ oldValue, newValue, theme, marginLeft }) {
  return (
    <span style={{ marginLeft }}>
      <Popup trigger={DiffButton()} flowing hoverable>
        <ValueContainer
          bgColor={theme.colors.diffRedXL}
          borderColor={theme.colors.diffRed}
        >
          {oldValue}
        </ValueContainer>
        <ValueContainer
          bgColor={theme.colors.diffGreenXL}
          borderColor={theme.colors.diffGreen}
        >
          {newValue}
        </ValueContainer>
      </Popup>
    </span>
  );
}

export default withTheme(DiffViewer);
