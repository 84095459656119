const sizes = {
  large: '20px',
  big: '18px',
  medium: '16px',
  small: '14px',
  tiny: '12px'
};

const fonts = {
  input: `
    font-family: "Petasense Open Sans";
    font-weight: 600;
    font-size: ${sizes.medium};
  `,
  tableRow: `
    font-family: "Petasense Open Sans";
    font-weight: 400;
    font-size: ${sizes.small};
  `,
  lightHeading: `
    font-family: "Petasense Open Sans";
    font-size: ${sizes.large};
    font-weight: 400;
  `,
  lightTitle: `
    font-family: "Petasense Open Sans";
    font-size: ${sizes.medium};
    font-weight: 400;
  `,
  heavyTitle: `
    font-family: "Petasense Open Sans";
    font-size: ${sizes.medium};
    font-weight: 600;
  `,
  selectOption: `
    font-family: "Petasense Open Sans";
    font-weight: 600;
    font-size: ${sizes.small};
  `,
  boldLabel: `
    font-family: "Petasense Open Sans";
    font-size: ${sizes.tiny};
    font-weight: 800;
  `,
  lightLabel: `
    font-family: "Petasense Open Sans";
    font-size: ${sizes.tiny};
  `
};

export default fonts;
