import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse, humanize } from 'common/helpers';

import { accountAnalyticsConstants } from './constants/accountAnalytics.constants';

export const getAccountAnalytics = (filters, account_id) => (dispatch) => {
  dispatch(request());
  const params = {
    start_date: filters ? filters.start_date : null,
    end_date: filters ? filters.end_date : null
  };
  return handleResponse(axiosInstance.get(ENDPOINT.ACCOUNT_ANALYTICS(account_id), { params }).then(
    (res) => {
      const measurements_data = [];
      Object.entries(res.data.num_tag_measurements).forEach(([tag_type, count]) => {
        if (count) measurements_data.push({
          value: count,
          label: humanize(tag_type)
        });
      });

      const channels_data = [
        { value: res.data.num_mote_channels_in_use, label: 'Active Mote' },
        { value: res.data.num_tx_channels_in_use, label: 'Active Tx' },
        { value: res.data.num_available_mote_channels, label: 'Available Mote' },
        { value: res.data.num_available_tx_channels, label: 'Available Tx' }
      ];

      const tags_data = [
        { value: res.data.num_active_tags, label: 'Active' },
        { value: res.data.num_inactive_tags, label: 'Inactive' }
      ];

      dispatch(success({
        measurements: measurements_data,
        channels: channels_data,
        tags: tags_data,
        num_users: res.data.num_users,
        num_machines: res.data.num_machines,
        num_motes: res.data.num_motes,
        num_tx: res.data.num_tx,
        num_components: res.data.num_components,
        num_channels: res.data.num_channels,
        num_tags: res.data.num_tags,
        start_date: res.data.start_date ? res.data.start_date : null,
        num_measurements: res.data.num_measurements ? res.data.num_measurements : null,
        size_of_timeseries_data_in_GB: res.data.size_of_timeseries_data_in_GB ? res.data.size_of_timeseries_data_in_GB : null
      }));
    },
    (error) => {
      dispatch(failure(error));
    }
  ));
  function request() {
    return { type: accountAnalyticsConstants.GET_ACCOUNT_ANALYTICS_REQUEST };
  }

  function success(data) {
    return { type: accountAnalyticsConstants.GET_ACCOUNT_ANALYTICS_SUCCESS, data };
  }

  function failure(error) {
    return { type: accountAnalyticsConstants.GET_ACCOUNT_ANALYTICS_FAILURE, error };
  }
};

export const getAccountAnalyticsSummary = filters => (dispatch) => {
  dispatch(request());
  const params = {
    start_date: filters ? filters.start_date : null,
    end_date: filters ? filters.end_date : null
  };
  return handleResponse(axiosInstance.get(ENDPOINT.ACCOUNT_ANALYTICS_SUMMARY, { params }).then(
    (res) => {
      const channels_data = [
        { value: res.data.num_mote_channels_in_use, label: 'Active Mote' },
        { value: res.data.num_tx_channels_in_use, label: 'Active Tx' },
        { value: res.data.num_available_mote_channels, label: 'Available Mote' },
        { value: res.data.num_available_tx_channels, label: 'Available Tx' }
      ];

      const tags_data = [
        { value: res.data.num_active_tags, label: 'Active' },
        { value: res.data.num_inactive_tags, label: 'Inactive' }
      ];

      dispatch(success({
        channels: channels_data,
        tags: tags_data,
        num_users: res.data.num_users,
        num_machines: res.data.num_machines,
        num_motes: res.data.num_motes,
        num_tx: res.data.num_tx,
        num_components: res.data.num_components,
        num_channels: res.data.num_channels,
        num_tags: res.data.num_tags
      }));
    },
    (error) => {
      dispatch(failure(error));
    }
  ));
  function request() {
    return { type: accountAnalyticsConstants.GET_ACCOUNT_ANALYTICS_SUMMARY_REQUEST };
  }

  function success(data) {
    return { type: accountAnalyticsConstants.GET_ACCOUNT_ANALYTICS_SUMMARY_SUCCESS, data };
  }

  function failure(error) {
    return { type: accountAnalyticsConstants.GET_ACCOUNT_ANALYTICS_SUMMARY_FAILURE, error };
  }
};
