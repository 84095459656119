export default {
  DELETE_DIAGNOSTIC_RULE_TEST_RUN_SUCCESS: 'DELETE_DIAGNOSTIC_RULE_TEST_RUN_SUCCESS',
  DELETE_DIAGNOSTIC_RULE_TEST_RUN_REQUEST: 'DELETE_DIAGNOSTIC_RULE_TEST_RUN_REQUEST',

  DIAGNOSTIC_RULES_TEST_RUN_SUCCESS: 'DIAGNOSTIC_RULES_TEST_RUN_SUCCESS',
  DIAGNOSTIC_RULES_TEST_RUN_FAILURE: 'DIAGNOSTIC_RULES_TEST_RUN_FAILURE',
  UPDATE_RULES_TEST_RUN_TIME_SUCCESS: 'UPDATE_RULES_TEST_RUN_TIME_SUCCESS',

  DIAGNOSTIC_RULES_TEST_RUN_STATUS_DONE: 'DIAGNOSTIC_RULES_TEST_RUN_STATUS_DONE',
  DIAGNOSTIC_RULES_TEST_RUN_TASK_STATUS: 'DIAGNOSTIC_RULES_TEST_RUN_TASK_STATUS',
  DIAGNOSTIC_RULES_GET_TEST_RUN_TASK_STATUS_FAILURE: 'DIAGNOSTIC_RULES_GET_TEST_RUN_TASK_STATUS_FAILURE',

  DIAGNOSTIC_RULES_TEST_RUN_RESULT_SUCCESS: 'DIAGNOSTIC_RULES_TEST_RUN_RESULT_SUCCESS',
  DIAGNOSTIC_RULES_TEST_RUN_RESULT_REQUEST: 'DIAGNOSTIC_RULES_TEST_RUN_RESULT_REQUEST',
  DIAGNOSTIC_RULES_TEST_RUN_RESULT_FAILURE: 'DIAGNOSTIC_RULES_TEST_RUN_RESULT_FAILURE',
};
