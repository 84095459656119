import React from 'react';
import styled from 'styled-components';

import FlexContainer from '../../atoms/FlexContainer';
import ChartPopupContainer from '../atoms/ChartPopupContainer';
import OutsideAlerter from '../../../OutsideAlerter';
import H3 from '../../../typography/H3/H3';
import H5_T from '../../../typography/H5/H5';
import Button from '../../atoms/Button';
import * as utils from '../../../utils';

const H5 = styled(H5_T)`
    font-weight: bold;
`;

const ChartRULSettingsModal = ({
  close,
  dataPoint,
  setAsFDP,
  setAsStartTime,
  deleteFDP,
  deleteStartTime,
  rulFdpDatapoint,
  rulStartTimeDatapoint,
  ...rest
}) => {
  const isFdp = rulFdpDatapoint.x && dataPoint.x.toString() === rulFdpDatapoint.x.toString();
  const isStartTime = rulStartTimeDatapoint.x && dataPoint.x.toString() === rulStartTimeDatapoint.x.toString();
  return (
    <OutsideAlerter handleClick={close} open>
      <ChartPopupContainer {...rest}>
        <FlexContainer direction="column" marginbottom="24px">
          <H5>Amplitude</H5>
          <H3>{dataPoint.y} {dataPoint.yUnit}</H3>
        </FlexContainer>
        <FlexContainer direction="column" marginbottom="24px">
          <H5>Time</H5>
          <H3>{utils.formatDate(dataPoint.x)}</H3>
        </FlexContainer>
        {isFdp && (
          <Button text onClick={deleteFDP}>Delete FDP</Button>
        )}
        {isStartTime && (
          <Button text onClick={deleteStartTime}>Delete Start Time</Button>
        )}
        {!isFdp && !isStartTime && (
          <>
            <FlexContainer marginbottom="1em">
              <Button text onClick={setAsFDP}>Set as FDP</Button>
            </FlexContainer>
            <Button text onClick={setAsStartTime}>Set as Start Time</Button>
          </>
        )}
      </ChartPopupContainer>
    </OutsideAlerter>
  );
};

export default ChartRULSettingsModal;
