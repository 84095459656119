import _ from 'lodash';
import { toastr } from 'react-redux-toastr';

import { history } from 'common/helpers/history';

import { axiosInstance, ENDPOINT } from '../../../../../common/constants';
import { handleResponse } from '../../../../../common/helpers';
import MachineInfoTypes from './machineInfo.types';
import * as machineInfoUtils from './machineInfoUtils';

import { getHierarchy, getTags } from '../../../../AssetHierarchy/actions/assetDetails.actions';
import * as hierarchyActions from '../../../../AssetHierarchy/actions/hierarchy.actions';
import * as hierarchyUtils from '../../../../AssetHierarchy/utils/assetHierarchyUtils';
import * as breadcrumbActions from '../../../../Menu/actions/breadcrumb.actions';
import { getConfig } from '../../MachineOverview/actions/machineOverview.actions';
import { getUnassociatedSensorsList } from '../../../../Sensors/actions/sensors.actions';


export const getMachineInfo = (machineId, localOnly = false) => {
  const request = () => ({ type: MachineInfoTypes.GET_MACHINE_INFO_REQUEST });
  const success = details => ({ type: MachineInfoTypes.GET_MACHINE_INFO_SUCCESS, details });
  const failure = error => ({ type: MachineInfoTypes.GET_MACHINE_INFO_FAILURE, error });

  const params = {
    resource_version: 3
  };

  return (dispatch) => {
    if (!localOnly) dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.MACHINES(machineId), { params })).then(
      (res) => {
        if (!localOnly) dispatch(success(res));
        return res;
      },
      (error) => {
        if (!localOnly) dispatch(failure(error.message));
        return error.message;
      }
    );
  };
};

export const updateMachineInfo = (machineId, details, localOnly = false) => {
  const request = () => ({ type: MachineInfoTypes.UPDATE_MACHINE_INFO_REQUEST });
  const success = newDetails => ({
    type: MachineInfoTypes.UPDATE_MACHINE_INFO_SUCCESS,
    details: newDetails
  });
  const failure = error => ({ type: MachineInfoTypes.UPDATE_MACHINE_INFO_FAILURE, error });
  const resetMachineOverviewState = () => ({ type: MachineInfoTypes.RESET_MACHINE_OVERVIEW_CONFIG });

  return (dispatch, getState) => {
    if (!localOnly) dispatch(request());
    return handleResponse(
      axiosInstance.put(ENDPOINT.UPDATE_MACHINE_INFO(machineId), details)).then(
      () => {
        if (!localOnly) {
          const storeDetails = getState().machineDetails.info.details;
          let name = null;
          Object.entries(details).forEach(([key, value]) => {
            if (key === 'name') {
              name = value;
              return;
            }
            storeDetails[key] = value;
          });
          storeDetails.name = details.name;
          if (details.orientation) dispatch(resetMachineOverviewState());
          dispatch(success(storeDetails));
          dispatch(getHierarchy(false)).then(() => {
            if (name) {
              const path = [];
              const hierarchy = getState().assetHierarchyReducer.assetInfo.hierarchy;
              hierarchyUtils.haspath(hierarchy, machineId, 'machine', path);
              const params = hierarchyUtils.getHierarchyParams(path, machineId, 'machine');
              dispatch(breadcrumbActions.updateBreadcrumb(params));
            }
          });
        }
      },
      (error) => {
        if (!localOnly) {
          toastr.error(error.message);
          dispatch(failure(error));
        }
        throw error.message;
      }
    );
  };
};

export const updateComponentInfo = (componentId, details) => {
  const request = () => ({ type: MachineInfoTypes.UPDATE_COMPONENT_INFO_REQUEST });
  const success = newDetails => ({
    type: MachineInfoTypes.UPDATE_COMPONENT_INFO_SUCCESS,
    details: newDetails
  });
  const failure = error => ({ type: MachineInfoTypes.UPDATE_COMPONENT_INFO_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    return handleResponse(
      axiosInstance.put(ENDPOINT.UPDATE_COMPONENT_INFO(componentId), details)).then(
      () => {
        const storeDetails = getState().machineDetails.info.details;
        const { site_id, machine_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(getState().breadcrumb);
        const innerStoreNode = machineInfoUtils.findNode(parseInt(componentId, 10), storeDetails.components);
        const oldNodeName = innerStoreNode.name;
        Object.entries(details).forEach(([key, value]) => {
          if (key === 'name') return;
          if (key === 'rul_enabled' || key === 'rul_method_preference' || key === 'rul_feature') {
            innerStoreNode[key] = value;
          }
          innerStoreNode.properties[key] = value;
        });
        innerStoreNode.name = details.name || oldNodeName;
        dispatch(success(storeDetails));
        dispatch(getHierarchy(false));
        dispatch(getTags(machine_id, site_id));
      },
      (error) => {
        toastr.error(error.message);
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const createComponent = (machineId, component, indexOnParent) => {
  const request = () => ({ type: MachineInfoTypes.CREATE_COMPONENT_REQUEST });
  const success = message => ({
    type: MachineInfoTypes.CREATE_COMPONENT_SUCCESS,
    message
  });
  const failure = error => ({ type: MachineInfoTypes.CREATE_COMPONENT_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    return handleResponse(
      axiosInstance.post(ENDPOINT.CREATE_COMPONENT(machineId), component)
    ).then(
      (res) => {
        const storeDetails = getState().machineDetails.info.details;
        if (!component.parent_id) {
          storeDetails.components.splice(indexOnParent, 0, res.component);
        } else {
          const innerStoreNode = machineInfoUtils.findNode(component.parent_id, storeDetails.components);
          innerStoreNode.components.splice(indexOnParent, 0, res.component);
        }
        dispatch(success(res.message));
        dispatch(getConfig(storeDetails.id));
        dispatch(getHierarchy(false));
        return res;
      },
      (error) => {
        toastr.error(error.message);
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const reorderComponents = (machineId, components, componentNodes) => {
  const request = () => ({ type: MachineInfoTypes.REORDER_COMPONENTS_REQUEST });
  const success = message => ({
    type: MachineInfoTypes.REORDER_COMPONENTS_SUCCESS,
    message
  });
  const failure = error => ({ type: MachineInfoTypes.REORDER_COMPONENTS_FAILURE, error });

  const reorderStoreComponents = (nodes, storeComponents) => {
    const output = [];
    nodes.forEach((node) => {
      const children = reorderStoreComponents(node.children, storeComponents);
      const component = machineInfoUtils.findNode(node.id, storeComponents);
      if (!component) return;
      component.components = children;
      output.push(component);
    });
    return output;
  };

  return (dispatch, getState) => {
    dispatch(request());
    return handleResponse(
      axiosInstance.patch(ENDPOINT.REORDER_COMPONENTS(machineId), { components })
    ).then(
      (res) => {
        const storeDetails = getState().machineDetails.info.details;
        storeDetails.components = reorderStoreComponents(componentNodes, _.cloneDeep(storeDetails.components));
        dispatch(success(res.message));
        dispatch(getConfig(storeDetails.id));
        dispatch(getHierarchy(false));
      },
      (error) => {
        toastr.error(error.message);
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const updateComponents = (machineId, components) => {
  const request = () => ({ type: MachineInfoTypes.UPDATE_COMPONENTS_REQUEST });
  const success = message => ({
    type: MachineInfoTypes.UPDATE_COMPONENTS_SUCCESS,
    message
  });
  const failure = error => ({ type: MachineInfoTypes.UPDATE_COMPONENTS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(
      axiosInstance.patch(ENDPOINT.UPDATE_COMPONENTS(machineId), { components })
    ).then(
      (res) => {
        dispatch(success(res.message));
      },
      (error) => {
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const deleteComponent = (componentId) => {
  const request = () => ({ type: MachineInfoTypes.DELETE_COMPONENT_REQUEST });
  const success = message => ({
    type: MachineInfoTypes.DELETE_COMPONENT_SUCCESS,
    message
  });
  const failure = error => ({ type: MachineInfoTypes.DELETE_COMPONENT_FAILURE, error });

  const deleteNode = (nodeId, components) => {
    const innerNodes = (components) => {
      components.forEach((component, idx) => {
        if (component.id === nodeId) {
          components.splice(idx, 1);
        } else innerNodes(component.components);
      });
    };
    innerNodes(components);
  };

  return (dispatch, getState) => {
    dispatch(request());
    return handleResponse(
      axiosInstance.delete(ENDPOINT.DELETE_COMPONENT(componentId))
    ).then(
      (res) => {
        const storeDetails = getState().machineDetails.info.details;
        deleteNode(componentId, storeDetails.components);
        dispatch(getConfig(storeDetails.id));
        dispatch(getHierarchy(false));
        dispatch(success(res.message));
      },
      (error) => {
        toastr.error(error.message);
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};


export const associateBearing = (componentId, data, properties) => {
  const request = () => ({ type: MachineInfoTypes.ASSOCIATE_BEARING_TO_COMPONENT_REQUEST });
  const success = newDetails => ({
    type: MachineInfoTypes.ASSOCIATE_BEARING_TO_COMPONENT_SUCCESS,
    details: newDetails
  });
  const failure = error => ({ type: MachineInfoTypes.ASSOCIATE_BEARING_TO_COMPONENT_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    return handleResponse(
      axiosInstance.put(ENDPOINT.ASSOCIATE_BEARING(componentId), data)).then(
      () => {
        const storeDetails = getState().machineDetails.info.details;
        const innerStoreNode = machineInfoUtils.findNode(componentId, storeDetails.components);
        innerStoreNode.bearings.forEach((bearing) => {
          if (bearing.id === data.bearing_location_id) {
            Object.entries(properties).forEach(([key, value]) => {
              bearing.properties[key] = value.value;
            });
            bearing.properties.model_id = data.bearing_model_id;
          }
        });
        dispatch(success(storeDetails));
      },
      (error) => {
        toastr.error(error.message);
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const deleteMachine = machineId => (dispatch, getState) => handleResponse(axiosInstance.delete(ENDPOINT.MACHINES(machineId))).then(
  (res) => {
    history.push('/assets');
    dispatch(getHierarchy(false)).then(() => {
      const { site_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(getState().breadcrumb);
      dispatch(hierarchyActions.selectNode(site_id, 'site'));
    });
    return res;
  },
  (error) => {
    toastr.error(error.message);
  }
);

export const createLocation = (machineId, componentId, details) => {
  const request = () => ({ type: MachineInfoTypes.CREATE_COMPONENT_REQUEST });
  const success = location => ({
    type: MachineInfoTypes.CREATE_LOCATION_SUCCESS,
    location
  });
  const failure = error => ({ type: MachineInfoTypes.CREATE_LOCATION_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    return handleResponse(axiosInstance.post(ENDPOINT.CREATE_MACHINE_COMPONENTS_LOCATIONS(machineId), details)).then(
      (res) => {
        const storeDetails = getState().machineDetails.info.details;
        const innerStoreNode = machineInfoUtils.findNode(componentId, storeDetails.components);
        if (details.is_bearing) {
          innerStoreNode.bearings.push({ ...res.location, motes: [], tx_sensors: [], properties: {} });
        } else {
          innerStoreNode.locations.push({ ...res.location, tx_sensors: [] });
        }
        dispatch(success(res.location));
        return res;
      },
      (error) => {
        toastr.error(error.message);
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const deleteLocation = (machineId, componentId, locationId) => {
  const request = () => ({ type: MachineInfoTypes.DELETE_COMPONENT_REQUEST });
  const success = () => ({
    type: MachineInfoTypes.DELETE_COMPONENT_SUCCESS,
  });
  const failure = error => ({ type: MachineInfoTypes.DELETE_LOCATION_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    return handleResponse(axiosInstance.delete(ENDPOINT.DELETE_MACHINE_COMPONENTS_LOCATIONS(machineId, locationId))).then(
      (res) => {
        const storeDetails = getState().machineDetails.info.details;
        const innerStoreNode = machineInfoUtils.findNode(componentId, storeDetails.components);
        innerStoreNode.bearings = innerStoreNode.bearings.filter(location => location.id !== locationId);
        innerStoreNode.locations = innerStoreNode.locations.filter(location => location.id !== locationId);
        dispatch(success());
        return res;
      },
      (error) => {
        toastr.error(error.message);
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const updateLocation = (machineId, componentId, locationId, details) => {
  const request = () => ({ type: MachineInfoTypes.UPDATE_LOCATION_REQUEST });
  const success = () => ({
    type: MachineInfoTypes.UPDATE_LOCATION_SUCCESS,
  });
  const failure = error => ({ type: MachineInfoTypes.DELETE_LOCATION_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    return handleResponse(axiosInstance.patch(ENDPOINT.UPDATE_MACHINE_COMPONENTS_LOCATIONS(machineId, locationId), details)).then(
      (res) => {
        const storeDetails = getState().machineDetails.info.details;
        const innerStoreNode = machineInfoUtils.findNode(componentId, storeDetails.components);
        innerStoreNode.bearings = innerStoreNode.bearings.map((location) => {
          if (location.id === locationId) {
            return {
              ...location,
              name: details.name,
              description: details.description
            };
          }
          return location;
        });
        innerStoreNode.locations = innerStoreNode.locations.map((location) => {
          if (location.id === locationId) {
            return {
              ...location,
              name: details.name,
              description: details.description
            };
          }
          return location;
        });
        dispatch(success());
        return res;
      },
      (error) => {
        toastr.error(error.message);
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const unassignSensor = (componentId, locationId, params, serialNumber) => {
  const request = () => ({ type: MachineInfoTypes.UNASSIGN_SENSOR_REQUEST });
  const success = () => ({ type: MachineInfoTypes.UNASSIGN_SENSOR_SUCCESS });
  const failure = error => ({ type: MachineInfoTypes.UNASSIGN_SENSOR_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_SENSOR_INFO, params)).then(
      (res) => {
        const storeDetails = getState().machineDetails.info.details;
        const innerStoreNode = machineInfoUtils.findNode(componentId, storeDetails.components);
        innerStoreNode.bearings = innerStoreNode.bearings.map((location) => {
          if (location.id === locationId) {
            location.motes = location.motes.filter(mote => mote.serial_number !== serialNumber);
            location.tx_sensors = location.tx_sensors.filter(tx_sensor => tx_sensor.serial_number !== serialNumber);
            return location;
          }
          return location;
        });
        innerStoreNode.locations = innerStoreNode.locations.map((location) => {
          if (location.id === locationId) {
            location.tx_sensors = location.tx_sensors.filter(tx_sensor => tx_sensor.serial_number !== serialNumber);
            return location;
          }
          return location;
        });
        dispatch(success());
        dispatch(getUnassociatedSensorsList());
        return res;
      },
      (error) => {
        toastr.error(error.message);
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const assignSensor = (componentId, locationId, params, serialNumber, orientation, model, type) => {
  const request = () => ({ type: MachineInfoTypes.ASSIGN_SENSOR_REQUEST });
  const success = () => ({ type: MachineInfoTypes.ASSIGN_SENSOR_SUCCESS });
  const failure = () => ({ type: MachineInfoTypes.ASSIGN_SENSOR_FAILURE });

  return (dispatch, getState) => {
    dispatch(request());
    return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_SENSOR_INFO, params)).then(
      (res) => {
        const storeDetails = getState().machineDetails.info.details;
        const innerStoreNode = machineInfoUtils.findNode(componentId, storeDetails.components);
        const selectedSensor = {
          component_id: componentId,
          location_id: locationId,
          orientation,
          serial_number: serialNumber,
          model,
          type,
          phase: params.phase
        };
        innerStoreNode.bearings = innerStoreNode.bearings.map((location) => {
          if (location.id === locationId) {
            if (serialNumber.substr(0, 2) === 'VM') location.motes.push(selectedSensor);
            else location.tx_sensors.push(selectedSensor);
            return location;
          }
          return location;
        });
        innerStoreNode.locations = innerStoreNode.locations.map((location) => {
          if (location.id === locationId) {
            location.tx_sensors.push(selectedSensor);
            return location;
          }
          return location;
        });
        dispatch(success());
        dispatch(getUnassociatedSensorsList());
        return res;
      },
      (error) => {
        toastr.error(error.message);
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};
