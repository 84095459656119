import React, { Component } from 'react';
import styled from 'styled-components';

import NewBatteryIcon from './NewBatteryIcon';

const BatteryContainer = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 0.8em;
  }
`;

export class BatteryIcon extends Component {
  render() {
    const { batteryLevel } = this.props;
    let icon = null;
    let batteryPercentage = null;
    // batterylevel 1, 2, 3 :
    // GOOD (> 20%)
    // WARNING (< 20%).
    // LOW ( < 10%).

    switch (batteryLevel) {
      case 1: {
        icon = <NewBatteryIcon fill="#91b846" />;
        batteryPercentage = '> 20%';
        break;
      }
      case 2: {
        icon = <NewBatteryIcon fill="#F0C000" />;
        batteryPercentage = '< 20%';
        break;
      }
      case 3: {
        icon = <NewBatteryIcon fill="#FF4F39" />;
        batteryPercentage = '< 10%';
        break;
      }
      case -1: {
        batteryPercentage = 'Unknown';
        break;
      }
      default:
        return '--';
    }
    return (
      <BatteryContainer>
        <span>{batteryPercentage}</span>
        {icon}
      </BatteryContainer>
    );
  }
}
