import styled from 'styled-components';
import PropTypes from 'prop-types';

import MoreIcon from 'common/images/MoreIconSvg';

const MoreIconContainer = styled.div`
  position: ${props => props.position};
  top: ${props => props.top};
  right: ${props => props.right};
  &:hover {
    cursor: pointer;
  }
  ${MoreIcon} {
    transform: translateY(-5px) rotate(90deg);
  }
  ${props => props.marginLeft ? `margin-left: ${props.marginLeft};` : ''}
  ${props => props.zIndex && `z-index: ${props.zIndex};`}
`;

MoreIconContainer.propTypes = {
  position: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  marginLeft: PropTypes.string
};

MoreIconContainer.defaultProps = {
  position: 'absolute',
  top: '20px',
  right: '25px',
  marginLeft: ''
};

export default MoreIconContainer;
