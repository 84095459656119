import { devicesHealthConstants } from './devicesHealth.constants';

export const devicesHealthReducer = (state = {}, action) => {
  switch (action.type) {
    case devicesHealthConstants.GET_DEVICES_HEALTH_DATA_REQUEST:
      return {
        ...state,
        [action.analytics_type]: {
          loading: true
        }
      };
    case devicesHealthConstants.GET_DEVICES_HEALTH_DATA_SUCCESS: {
      return {
        ...state,
        ...action.devicesHealthData
      };
    }
    case devicesHealthConstants.GET_DEVICES_HEALTH_DATA_FAILURE: {
      return {
        ...state,
        [action.analytics_type]: {
          error: action.error.message,
          loading: false
        }
      };
    }
    default:
      return state;
  }
};

const initialMissedMeasurementsList = {
  loading: false,
  object: [],
  hasMore: false,
  page: 1,
};

export const missedMeasurementsList = (state = initialMissedMeasurementsList, action) => {
  switch (action.type) {
    case devicesHealthConstants.GET_MISSED_MEASUREMENTS_LIST_REQUEST: {
      if (action.page === 1) {
        return {
          ...state,
          loading: true,
          object: []
        };
      }
      return {
        ...state,
        loading: true
      };
    }
    case devicesHealthConstants.GET_MISSED_MEASUREMENTS_LIST_SUCCESS: {
      let result = [];
      const payload = action.payload;
      if (payload.page === 1) result = payload.data;
      else result = state.object.concat(payload.data);
      return {
        hasMore: payload.page < payload.total_pages,
        object: result,
        page: payload.page,
        total_count: payload.total_count,
        loading: false
      };
    }
    case devicesHealthConstants.GET_MISSED_MEASUREMENTS_LIST_FAILURE: {
      return {
        hasMore: false,
        loading: false,
        error: action.error.message
      };
    }
    case devicesHealthConstants.CLEAR_MISSED_MEASUREMENTS_LIST: {
      return {
        ...state,
        object: []
      };
    }
    case devicesHealthConstants.INCREASE_MISSED_MEASUREMENTS_PAGE:
      return {
        ...state,
        page: action.page
      };
    default:
      return state;
  }
};

const initialMissedMeasurementsFilters = {
  filters: [],
  searchKey: ''
};

export const missedMeasurementsFilters = (state = initialMissedMeasurementsFilters, action) => {
  switch (action.type) {
    case devicesHealthConstants.SET_MISSED_MEASUREMENTS_LIST_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

export const initialMissedMeasurementsSorter = {
  sorter: {
    name: 'count',
    order: 'desc'
  }
};

export const missedMeasurementsSorter = (state = initialMissedMeasurementsSorter, action) => {
  switch (action.type) {
    case devicesHealthConstants.SET_MISSED_MEASUREMENTS_SORTER:
      return {
        sorter: action.sorter
      };
    default:
      return state;
  }
};

export const selectedSensor = (state = { serial_number: null }, action) => {
  switch (action.type) {
    case devicesHealthConstants.SELECT_SENSOR:
      return {
        serial_number: action.serial_number
      };
    case devicesHealthConstants.DESELECT_SENSOR:
      return {
        serial_number: null
      };
    default:
      return state;
  }
};

const initialSensorMissedTimestampsList = {
  loading: false,
  object: [],
  hasMore: false,
  page: 1,
};

export const sensorMissedTimestampsList = (state = initialSensorMissedTimestampsList, action) => {
  switch (action.type) {
    case devicesHealthConstants.GET_SENSOR_MISSED_MEASUREMENTS_LIST_REQUEST: {
      if (action.page === 1) {
        return {
          ...state,
          loading: true,
          object: []
        };
      }
      return {
        ...state,
        loading: true
      };
    }
    case devicesHealthConstants.GET_SENSOR_MISSED_MEASUREMENTS_LIST_SUCCESS: {
      let result = [];
      const payload = action.payload;
      if (payload.page === 1) result = payload.data;
      else result = state.object.concat(payload.data);
      return {
        hasMore: payload.page < payload.total_pages,
        object: result,
        page: payload.page,
        total_count: payload.total_count,
        loading: false
      };
    }
    case devicesHealthConstants.GET_SENSOR_MISSED_MEASUREMENTS_LIST_FAILURE: {
      return {
        hasMore: false,
        loading: false,
        error: action.error.message
      };
    }
    case devicesHealthConstants.CLEAR_SENSOR_MISSED_MEASUREMENTS_LIST: {
      return {
        ...state,
        object: []
      };
    }
    case devicesHealthConstants.INCREASE_SENSOR_MISSED_MEASUREMENTS_PAGE:
      return {
        ...state,
        page: action.page
      };
    default:
      return state;
  }
};

export const initialSensorMissedTimestampsSorter = {
  sorter: {
    name: 'missed_timestamps',
    order: 'desc'
  }
};

export const sensorMissedTimestampsSorter = (state = initialSensorMissedTimestampsSorter, action) => {
  switch (action.type) {
    case devicesHealthConstants.SET_SENSOR_MISSED_MEASUREMENTS_SORTER:
      return {
        sorter: action.sorter
      };
    default:
      return state;
  }
};
