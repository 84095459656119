import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import Checkbox from 'common/components/atoms/Checkbox';
import RadioButton from '../molecules/Radiobutton';
import FlexContainer from './FlexContainer';

const Span = styled.span`
  margin-left: 10px;
`;

const DropdownCont = styled(SemanticDropdown)`
  display: flex !important;
  ${props => props.fontSettings ? props.fontSettings : props.theme.fonts.lightTitle}
  &.ui.fluid.dropdown {
    width: auto;
    flex: 1;
  }
  &.ui.dropdown.error {
    color: ${props => props.theme.colors.error};
    outline-color: ${props => props.theme.colors.error};
    border-color: ${props => props.theme.colors.error}!important;
  }
  &.ui.disabled.dropdown {
    background-color: ${props => props.theme.colors.disabled};
    color: ${props => props.theme.colors.greyXD};
    opacity: 1;
    border-color: ${props => props.theme.colors.disabled};
  }
  &.ui.active.selection.dropdown {
    border-color: ${props => props.theme.primaryColor};
    box-shadow: 0 0 8px 0 rgba(60,61,58,0.24);
  }
  &.ui.selection.active.dropdown .menu {
    border-color: ${props => props.theme.primaryColor};
    box-shadow: 0px 6px 8px 0px rgba(60,61,58,0.24);
  }
  &.ui.selection.active.dropdown .menu>.item {
    ${props => props.optionFontSettings ? props.optionFontSettings : props.theme.fonts.lightTitle}
    color: ${props => props.theme.colors.greyXXD};
  }
  &.ui.search {
    ${props => props.fontSettings}
  }
  a {
    font-size: 14px !important;
    font-family: 'Petasense Open Sans' !important;
    text-align: center !important;
    line-height: 1.4 !important;
  }
  ${Checkbox} {
    font-size: 14px;
  }
  ${props => props.preventContentWrap && `
    &.ui.selection.dropdown .menu>.item {
      white-space: nowrap;
    }
    .menu.transition.visible {
      width: auto !important;
    }
  `}
`;

const Dropdown = ({
  error,
  onClickSelectable,
  selectable,
  selectableValue,
  selectableProps,
  multiCheckbox,
  options,
  toggleSelection,
  isItemSelected,
  ...rest
}) => (
  <>
    {selectable === 'radio' && (
      <RadioButton {...selectableProps} inputField onClick={onClickSelectable} value={selectableValue} />
    )}
    {multiCheckbox ? (
      <DropdownCont
        fluid
        selection
        error={error}
        {...rest}
      >
        <SemanticDropdown.Menu>
          {options.map(({ value, text }) => {
            const checked = isItemSelected(value);
            return (
              <SemanticDropdown.Item
                key={value}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSelection(value, checked);
                }}
              >
                <FlexContainer alignItems="center" width="100%">
                  <Checkbox value={checked} />
                  <Span>{text}</Span>
                </FlexContainer>
              </SemanticDropdown.Item>
            );
          })}
        </SemanticDropdown.Menu>
      </DropdownCont>
    ) : (
      <DropdownCont
        fluid
        selection
        options={options}
        error={error}
        selectOnBlur={false}
        {...rest}
      />
    )}
  </>
);


/*
  "multiCheckbox": This is used to render multi-select dropdown, where dropdown items are rendered with checkboxes beside them
    Note: "multiCheckbox" is an alternative way to "multiple" prop provided by Semantic UI React dropdown.
    Refer to the documentation for "multiple" usage

  "isItemSelected": Helper function to decide whether item is checked, used with "multiCheckbox"
  "toggleSelection": Helper function to select/de-select an item, used with "multiCheckbox"

  "preventContentWrap": Sets width of dropdown items and selection button independently
    For example, if items in dropdown are ["a", "bigword"] and "a" is currently selected,
    then dropdown component itself can have width of "a",
    but the 'items menu' ( which is floating ) can expand to accomodate "bigword"
*/
Dropdown.propTypes = {
  multiCheckbox: PropTypes.bool,
  isItemSelected: PropTypes.func,
  toggleSelection: PropTypes.func
};

Dropdown.defaultProps = {
  multiCheckbox: false,
  isItemSelected: () => {},
  toggleSelection: () => {}
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
