import definitionTypes from './definition.types';

export default function currentDefinition(state = {}, action) {
  switch (action.type) {
    case definitionTypes.CLEAR_CURRENT_DEFINITION: {
      return {};
    }

    case definitionTypes.CREATE_DEFINITION_REQUEST: {
      return {
        ...state,
        definitionCreating: true
      };
    }

    case definitionTypes.CREATE_DEFINITION_SUCCESS: {
      return {
        ...state,
        definitionCreating: false
      };
    }

    case definitionTypes.CREATE_DEFINITION_FAILURE: {
      return {
        ...state,
        error: action.error.message,
        definitionCreating: false
      };
    }

    case definitionTypes.GET_RESOURCES_REQUEST: {
      return {
        ...state,
        resourcesLoading: true,
        resources: false
      };
    }

    case definitionTypes.GET_RESOURCES_SUCCESS: {
      return {
        ...state,
        resourcesLoading: false,
        resources: action.resources
      };
    }

    case definitionTypes.GET_RESOURCES_FAILURE: {
      return {
        ...state,
        resources: false,
        resourcesLoading: false,
        error: action.error.message
      };
    }

    case definitionTypes.GET_PARAMETERS_REQUEST: {
      return {
        ...state,
        parametersLoading: true,
        parameters: false
      };
    }

    case definitionTypes.GET_PARAMETERS_SUCCESS: {
      return {
        ...state,
        parameters: action.parameters,
        parametersLoading: false,
      };
    }

    case definitionTypes.GET_PARAMETERS_FAILURE: {
      return {
        ...state,
        parametersLoading: false,
        parameters: false,
        error: action.error.message
      };
    }

    case definitionTypes.GET_DEFINITION_REQUEST: {
      return {
        ...state,
        definitionLoading: true
      };
    }

    case definitionTypes.GET_DEFINITION_SUCCESS: {
      const { definition } = action;
      return {
        ...state,
        ...definition,
        definitionLoading: false,
      };
    }

    case definitionTypes.GET_DEFINITION_FAILURE: {
      return {
        ...state,
        definitionLoading: false,
        parameters: false,
        error: action.error.message
      };
    }

    case definitionTypes.DELETE_DEFINITION_REQUEST: {
      return {
        ...state,
        deleting: true
      };
    }

    case definitionTypes.DELETE_DEFINITION_SUCCESS: {
      return {
        ...state,
        deleting: false
      };
    }

    case definitionTypes.DELETE_DEFINITION_FAILURE: {
      return {
        ...state,
        deleting: false,
        error: action.error.message
      };
    }

    default:
      return state;
  }
}
