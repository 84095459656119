import { userConstants } from '../user.constants';

export function resetPasswordState(state = {}, action) {
  switch (action.type) {
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        loading: true
      };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        result: true,
      };
    case userConstants.RESET_PASSWORD_FAILED:
      return {
        result: false,
        error: action.error.message
      };
    default:
      return state;
  }
}
