import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Chart } from 'react-google-charts';
import { toastr } from 'react-redux-toastr';

import LoadingSvg from 'common/images/LoadingSvg';
import Button from 'common/components/atoms/Button';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Modal from 'common/components/organisms/ModalV2';
import * as erroneousReadingsActions from '../erroneousReadings.actions';

const CancelButton = styled(Button)`
  margin-right: 20px
`;

const Loader = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class ErroneousReadingsChartsModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waveform_data: [],
      waveformOptions: {
        title: 'Waveform',
        curveType: 'function',
        legend: { position: 'bottom' },
        vAxis: {
          format: '# in/s'
        },
        hAxis: {
          format: '# ms'
        }
      },
      spectrum_data: [],
      spectrumOptions: {
        title: 'Spectrum',
        curveType: 'function',
        legend: { position: 'bottom' },
        vAxis: {
          format: '# in/s'
        },
        hAxis: {
          format: '# Hz'
        }
      },
      waveformLoaded: false,
      spectrumLoaded: false
    };
  }

  getCharts() {
    const { close, item } = this.props;
    const { getWaveform, getSpectrum } = this.props.erroneousReadingsActions;

    getWaveform(item.tag_id, item.timestamp).then((res) => {
      const data = [];
      for (let i = 0; i < res.waveform_data.length; i++) {
        const x = 1000 / res.sampling_rate * i;
        const y = res.waveform_data[i];
        data.push([parseFloat(x), parseFloat(y)]);
      }
      this.setState({
        waveform_data: [['Frequency', 'Amplitude']].concat(data),
        waveformLoaded: true
      });
    }).catch((e) => {
      toastr.error(e.toString());
    });

    getSpectrum(item.tag_id, item.timestamp).then((res) => {
      const data = [];
      for (let i = 0; i < res.spectrum_data.freq.length; i++) {
        const x = res.spectrum_data.freq[i];
        const y = res.spectrum_data.mag[i];
        data.push([parseFloat(x), parseFloat(y)]);
      }
      this.setState({
        spectrum_data: [['Frequency', 'Amplitude']].concat(data),
        spectrumLoaded: true
      });
    }).catch((e) => {
      toastr.error(e.toString());
      close();
    });
  }

  remove = () => {
    const { close, item, search } = this.props;
    const { removeFromOutlierList } = this.props.erroneousReadingsActions;
    removeFromOutlierList(item.id).then((res) => {
      toastr.success(res.message);
      close();
      search();
    }).catch((e) => {
      toastr.error(e.message);
    });
  }

  valid = () => {
    const { close, item, search } = this.props;
    const { validate } = this.props.erroneousReadingsActions;
    validate(item.id).then((res) => {
      toastr.success(res.message);
      close();
      search();
    }).catch((e) => {
      toastr.error(e.message);
      close();
    });
  }

  componentDidMount() {
    this.getCharts();
  }

  render() {
    const { close, item } = this.props;
    return (
    <Modal
      padding="0 20px"
      width="55%"
      close={close}
    >
      {this.state.waveformLoaded && this.state.spectrumLoaded ? (
        <FlexContainer direction="column" marginbottom="40px" margintop="40px">
          <FlexContainer justifyContent="center" marginbottom="20px" margintop="20px">
              <Chart
                chartType="LineChart"
                data={this.state.waveform_data}
                width="100%"
                height="100%"
                options={this.state.waveformOptions}
                loader={(
                  <Loader><LoadingSvg /></Loader>
                )}
              />
          </FlexContainer>
          <FlexContainer justifyContent="center" marginbottom="30px" margintop="20px">
              <Chart
                chartType="LineChart"
                data={this.state.spectrum_data}
                width="100%"
                height="100%"
                options={this.state.spectrumOptions}
                loader={(
                    <Loader><LoadingSvg /></Loader>
                  )}
              />
          </FlexContainer>
            <FlexContainer justifyContent="center" flexGap="20px">
              <Button onClick={this.valid}>Valid</Button>
              <Button onClick={this.remove}>Remove from outlier list</Button>
              <CancelButton onClick={close} secondary="#000">Close</CancelButton>
            </FlexContainer>
            <FlexContainer justifyContent="center" flexGap="20px" margintop="20px">
                <a href={item.chart_url}>Link to chart</a>
            </FlexContainer>
        </FlexContainer>
      ) : (
        <FlexContainer direction="column" marginbottom="40px" margintop="40px">
          <FlexContainer justifyContent="center" marginbottom="10px" margintop="40px">
                <Loader>
                  <LoadingSvg />
                </Loader>
          </FlexContainer>
          <FlexContainer justifyContent="center" marginbottom="40px" margintop="10px">
              <h3>Fetching Data...</h3>
          </FlexContainer>
        </FlexContainer>
      )}
    </Modal>
    );
  }
}


ErroneousReadingsChartsModel.propTypes = {
  close: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  search: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  erroneousReadingsActions: bindActionCreators(erroneousReadingsActions, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(ErroneousReadingsChartsModel);
