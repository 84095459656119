import { machinesConstants } from './machines.constants';
import { axiosInstance, ENDPOINT } from '../../common/constants';
import { handleResponse } from '../../common/helpers';
import { getSearchParamFilters } from './utils';


export const machinesActions = {
  setMachineSorter,
  setMachineSearchParams,
  initMachinePaginater,
  setMachinePaginater,
  increaseMachinePaginater,
  getMachines,
  getAreas,
  toggleMachinesRequiringAttention,
  setAttentionMachineViewed
};

function setMachineSorter(sorter) {
  return (dispatch) => {
    dispatch({ type: machinesConstants.SET_MACHINE_SORTER, sorter });
    dispatch({ type: machinesConstants.CLEAR_MACHINES });
    dispatch({ type: machinesConstants.SET_MACHINE_PAGINATER, page: 1 });

    dispatch(getMachines());
  };
}

function toggleMachinesRequiringAttention() {
  return (dispatch) => {
    dispatch({ type: machinesConstants.SET_MACHINES_REQUIRING_ATTENTION_VIEW });
    dispatch({ type: machinesConstants.CLEAR_MACHINES });
    dispatch({ type: machinesConstants.SET_MACHINE_PAGINATER, page: 1 });

    dispatch(getMachines());
  };
}

function initMachinePaginater() {
  return (dispatch) => {
    dispatch({ type: machinesConstants.CLEAR_MACHINES });
    dispatch({ type: machinesConstants.CLEAR_MACHINE_SEARCH_PARAMS });
    dispatch(setMachinePaginater(1));
  };
}

function setMachinePaginater(page) {
  return (dispatch) => {
    dispatch({ type: machinesConstants.SET_MACHINE_PAGINATER, page });
    dispatch(getMachines());
  };
}

function setMachineSearchParams(searchParams) {
  return (dispatch) => {
    dispatch({ type: machinesConstants.SET_MACHINE_SEARCH_PARAMS, searchParams });
    dispatch({ type: machinesConstants.CLEAR_MACHINES });
    dispatch({ type: machinesConstants.SET_MACHINE_PAGINATER, page: 1 });
    return dispatch(getMachines());
  };
}

function increaseMachinePaginater() {
  return (dispatch, getState) => {
    const machinesState = getState().machines;
    const { paginater } = machinesState;

    dispatch(setMachinePaginater(paginater.page + 1));
  };
}

function getAreas() {
  const success = areas => ({ type: machinesConstants.GET_AREAS_SUCCESS, areas });
  const failure = error => ({ type: machinesConstants.GET_AREAS_FAILURE, error });

  return (dispatch) => {
    const params = {
      resource_type: 'area'
    };

    return handleResponse(axiosInstance.get(ENDPOINT.GET_FILTER_RESOURCES, { params }))
      .then(
        (res) => {
          dispatch(success(res.result));
          return res;
        },
        (error) => {
          dispatch(failure(error));
          throw error;
        }
      );
  };
}

export function getMachines() {
  const request = () => ({ type: machinesConstants.GET_MACHINES_REQUEST });
  const success = (machines, page, total_count) => ({ type: machinesConstants.GET_MACHINES_SUCCESS, machines, page, total_count });
  const failure = error => ({ type: machinesConstants.GET_MACHINES_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    const machinesState = getState().machines;
    const prevSorter = machinesState.sorter;
    const { paginater } = machinesState;
    const { searchParams } = machinesState;
    const machinesRequiringAttention = machinesState.machinesRequiringAttention.show;

    const order_by = { field: 'name', direction: 'asc' };
    if (prevSorter && prevSorter.sorter && prevSorter.sorter.order) {
      order_by.field = prevSorter.sorter.name;
      order_by.direction = prevSorter.sorter.order;
    }

    const params = {
      page: paginater.page,
      per_page: machinesConstants.NUMBER_OF_MACHINES_PER_PAGE,
      search_key: searchParams.searchKey,
      machines_requiring_attention: machinesRequiringAttention,
      resource_version: 3
    };

    if (searchParams) {
      params.filters = getSearchParamFilters(searchParams);
    }

    if (!machinesRequiringAttention || (
      machinesRequiringAttention && prevSorter && prevSorter.sorter && prevSorter.sorter.order
    )) {
      params.order_by = order_by;
    }

    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_LIST, { params }))
      .then(
        (res) => {
          dispatch(success(res.items, paginater.page, res.total_count));
        },
        (error) => {
          dispatch(failure(error));
        },
      );
  };
}

function setAttentionMachineViewed(machine_id) {
  const request = () => ({ type: machinesConstants.SET_ATTETNION_MACHINE_REQUEST });
  const success = () => ({ type: machinesConstants.SET_ATTENTION_MACHINE_SUCCESS });
  const failure = () => ({ type: machinesConstants.SET_ATTENTION_MACHINE_FAILURE });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.patch(ENDPOINT.UPDATE_ATTENTION_MACHINE_READ(machine_id)))
      .then(
        () => {
          dispatch(success());
        },
        () => {
          dispatch(failure());
        },
      );
  };
}
