import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as _ from 'lodash';
import Checkbox from 'common/components/atoms/Checkbox';
import { SelectedOption } from 'common/components/atoms/SelectedOption';
import FlexContainer from 'common/components/atoms/FlexContainer';
import OutsideAlerter from 'common/OutsideAlerter';
import LoadingSvg from 'common/images/LoadingSvg';
import { humanize as humanizeText } from 'common/helpers/humanize';
import Modal from 'common/components/organisms/Modal';
import Button_T from 'common/components/atoms/Button';
import colors from 'common/styles/colors';
import FilterItem_T from '../atoms/FilterItem';
import Label from '../../typography/Label/Label';
import { ResourceItem } from '../atoms/ResourceDetails';

const Button = styled(Button_T)`
  &:first-of-type {
    margin-right: 2em;
  }
`;
const MultiDropdownContainer = styled.div`
  padding: .78571429em 1em .78571429em 1em;
  font-size: ${props => props.fontSize ? props.fontSize : '16px'};
  width: 100%;
  ${props => props.disabled &&
    `background-color: #EFF0EE;
    input {
      background-color: #EFF0EE;
      cursor: default;
      height: 0;
      width: 0;
    }`
}
`;

const LoadingContainer = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
`;

const FilterItem = styled(FilterItem_T)`
  width: auto;
  span {
    margin-left: 0.5em;
  }
`;

const NoDataContainer = styled.span`
  font-size: 12px;
  color: ${colors.greyXD};
`;


export default class DropdownContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchField: '',
      matchedOptions: props.optionsAvailable,
      ctrFocused: false,
      selected: _.cloneDeep(this.props.selectedOptions),
      saveEnabled: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.optionsAvailable, prevProps.optionsAvailable)) {
      this.setState({
        matchedOptions: this.props.optionsAvailable,
      });
    }
  }

  toggleContainerFocus = (e) => {
    e.preventDefault();
    if (this.state.ctrFocused) {
      this.removeContainerFocus();
    } else {
      this.focusContainer();
    }
  }

  focusContainer = () => {
    this.setState({ ctrFocused: true });
    this.inputSearchField.focus();
  }

  removeContainerFocus = () => {
    const { optionsAvailable } = this.props;
    this.setState({ ctrFocused: false, searchField: '', matchedOptions: optionsAvailable });
    this.inputSearchField.blur();
  }

  closeChildComponent = () => {
    this.setState({ ctrFocused: false, saveEnabled: false });
    this.inputSearchField.blur();

    this.props.wrapUpMultiSelect();
  }

  toggleModelResource = (selected) => {
    this.setState({
      selected
    }, this.updateSaveEnabled);
  }

  updateSaveEnabled = () => {
    const { selected } = this.state;
    const selectedSorted = _.sortBy(selected);
    const optionSorted = _.sortBy(this.props.selectedOptions);

    if (_.isEqual(selectedSorted, optionSorted)) {
      if (this.state.saveEnabled) this.setState({ saveEnabled: false });
    } else if (!this.state.saveEnabled) {
      this.setState({ saveEnabled: true });
    }
  }

  applyChanges = () => {
    this.props.addAllResources(this.state.selected);
    this.setState({ ctrFocused: false, saveEnabled: false });
  }

  onSearchTextChanged = (event) => {
    const { optionsAvailable, backendSearch, setSearchField } = this.props;
    this.setState({ searchField: event.target.value });
    const needle = event.target.value;

    if (backendSearch) {
      setSearchField(needle);
      return;
    }

    const matches = [];
    if (!needle) {
      this.setState({ matchedOptions: optionsAvailable });
      return;
    }

    for (let i = 0; i < optionsAvailable.length; i++) {
      const haystack = optionsAvailable[i].text;

      if (haystack && needle && haystack.toUpperCase().indexOf(needle.toUpperCase()) >= 0) {
        matches.push(optionsAvailable[i]);
      }
    }
    this.setState({ matchedOptions: matches });
  }

  selectAllOptions = () => {
    const ids = [];
    this.props.optionsAvailable.forEach((option) => {
      ids.push(option.value);
    });
    this.props.addAllResources(ids);
  }

  toggleResource = (id) => {
    this.props.toggleOption(id);
  };

  getFooter = () => (
    <FlexContainer alignItems="center" justifyContent="space-between">
      <Label marginBottom="0px">{this.state.selected.length} {this.state.selected.length > 1 ? `${this.props.label}` : this.props.label} selected</Label>
      <Button onClick={this.applyChanges} disabled={!this.state.saveEnabled}>APPLY</Button>
    </FlexContainer>
  )

  render() {
    const {
      className, optionsAvailable, typeOfOptions, fontSize,
      placeholder, selectedOptions, loading, disabled, label,
      showEmptyMsg, humanize, customSelectionView, component: Component
    } = this.props;

    const { searchField, matchedOptions, ctrFocused } = this.state;
    const hideInputStyle = ctrFocused ? { height: '20px' } : { height: 0 };
    return (
      <>
        {!!label && (
          <Label>
            {label}
          </Label>
        )}

        <OutsideAlerter className={className} open={ctrFocused} handleClick={this.removeContainerFocus}>
          <MultiDropdownContainer disabled={disabled} fontSize={fontSize}>
            <FlexContainer
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              className={disabled || 'ps-hover-pointer'}
              onClick={disabled ? _ => _ : this.focusContainer}
            >
              {(ctrFocused || !_.isEmpty(selectedOptions)) || <div className="ed-multi-select-placeholder">{placeholder}</div>}

              <FlexContainer direction="row" flexWrap="wrap" className="ed-dropdown-top-selected-container" width="100%">
                {!customSelectionView && optionsAvailable && optionsAvailable.map((option, index) => (
                  selectedOptions.includes(option.value) && (
                    <SelectedOption
                      key={index}
                      option={option}
                      index={index}
                      humanize={humanize}
                      onClick={() => this.toggleResource(option.value)}
                      disabled={disabled}
                    />
                  )))
                }
                {customSelectionView && !ctrFocused && (
                  <>{customSelectionView()}</>
                )}
              </FlexContainer>
              <div style={{ ...hideInputStyle }}>
                <input
                  className="ed-dropdown-internal-input fill-remaining-space"
                  ref={(comp) => { this.inputSearchField = comp; }}
                  style={{ ...hideInputStyle }}
                  type="text"
                  value={searchField}
                  onChange={this.onSearchTextChanged}
                />
              </div>
            </FlexContainer>

            {ctrFocused && Component && (
              <Modal
                title={label}
                width="55%"
                padding="15px"
                headerPadding="10px"
                close={this.closeChildComponent}
              >
                <Component close={this.removeContainerFocus} toggleModelResource={this.toggleModelResource} />
                {this.getFooter()}
              </Modal>)
            }

            {
              ctrFocused && !Component &&
              (!loading ? (
                <Fragment>
                  <FlexContainer direction="row" flexWrap="wrap" className="ed-dropdown-bottom-options-container">
                    {!this.props.disableSelectAll && (
                      <FilterItem
                        selected={optionsAvailable.length === (selectedOptions).length}
                        onClick={this.selectAllOptions}
                      >
                        <Checkbox value={optionsAvailable.length === (selectedOptions).length} />
                        <ResourceItem style={{ marginLeft: '7px' }}>{`All ${typeOfOptions}`}</ResourceItem>
                      </FilterItem>
                    )}
                    {matchedOptions && matchedOptions.map((option, index) => {
                      const selectedState = selectedOptions.includes(option.value);
                      return (
                        <FilterItem
                          key={index.toString()}
                          selected={selectedState}
                          onClick={() => this.toggleResource(option.value)}
                        >
                          <Checkbox value={selectedState} />
                          <ResourceItem style={{ marginLeft: '7px' }}>{humanize ? humanizeText(option.text) : option.text}</ResourceItem>
                        </FilterItem>
                      );
                    })}
                    {showEmptyMsg && _.isEmpty(matchedOptions) && (
                      <NoDataContainer>No items available</NoDataContainer>
                    )}
                  </FlexContainer>
                  {this.props.viewAllButton &&
                    this.props.viewAllButton
                  }
                </Fragment>) : (
                <LoadingContainer>
                  <LoadingSvg />
                </LoadingContainer>
              ))}
          </MultiDropdownContainer>
        </OutsideAlerter>
      </>
    );
  }
}

DropdownContainer.propTypes = {
  optionsAvailable: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired
  })).isRequired,
  placeholder: PropTypes.string.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  typeOfOptions: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  component: PropTypes.func,
  disableSelectAll: PropTypes.bool,
  backendSearch: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  showEmptyMsg: PropTypes.bool
};

DropdownContainer.defaultProps = {
  component: null,
  disableSelectAll: false,
  backendSearch: false,
  showEmptyMsg: false
};
