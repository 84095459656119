import { machineDetailsConstants } from 'home/Machines/MachineDetails/machineDetails.constants';

export function machineAttachmentsReducer(state = [], action) {
  switch (action.type) {
    case machineDetailsConstants.GET_MACHINE_ATTACHMENTS_SUCCESS:
      return [...action.attachments];
    default:
      return state;
  }
}
