import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { CapitalizeEachWord } from 'common/utils';
import { localTimeString } from 'common/helpers';
import ListItem from 'common/components/atoms/ListItem';
import AllSensorReadingsModel from './AllSensorReadingsModel';

const ErroneousReadingsListItem = styled(ListItem)`
  cursor: pointer;
  ${props => props.selected && 'background-color: rgba(145,184,70,0.08);'}
  td {
    height: 54px;
  }
`;

class ErroneousReadingsItem extends React.Component {
  constructor() {
    super();
    this.state = {
      allSensorReadingsModelOpen: false
    };
  }

  toggleAllSensorReadingsModel = () => {
    this.setState(prevState => ({
      allSensorReadingsModelOpen: !prevState.allSensorReadingsModelOpen
    }));
  };

  render() {
    const {
      item,
      columnSizes,
    } = this.props;
    return (
      <>
      <ErroneousReadingsListItem onClick={this.toggleAllSensorReadingsModel}>
          <td width={`${columnSizes[0]}%`}>
            {item.sensor || '--'}
          </td>
          <td width={`${columnSizes[1]}%`}>
            {item.machine || '--'}
          </td>
          <td width={`${columnSizes[2]}%`}>
            {item.account || '--'}
          </td>
          <td width={`${columnSizes[3]}%`}>
            {item.component || '--'}
          </td>
          <td width={`${columnSizes[4]}%`}>
            {localTimeString(item.timestamp) || '--'}
          </td>
          <td width={`${columnSizes[5]}%`}>
            {CapitalizeEachWord(item.direction) || '--'}
          </td>
      </ErroneousReadingsListItem>

       {this.state.allSensorReadingsModelOpen && (
        <AllSensorReadingsModel
          sensor_id={item.sensor_id}
          sensor_name={item.sensor}
          close={this.toggleAllSensorReadingsModel}
        />
       )}
      </>
    );
  }
}

ErroneousReadingsItem.propTypes = {
  item: PropTypes.object.isRequired,
  columnSizes: PropTypes.array.isRequired
};

export default ErroneousReadingsItem;
