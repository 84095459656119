export default {
  GET_CONFIG_REQUEST: 'GET_CONFIG_REQUEST',
  GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS',
  GET_CONFIG_FAILURE: 'GET_CONFIG_FAILURE',
  GET_MACHINE_SUMMARY_REQUEST: 'GET_MACHINE_SUMMARY_REQUEST',
  GET_MACHINE_SUMMARY_SUCCESS: 'GET_MACHINE_SUMMARY_SUCCESS',
  GET_MACHINE_SUMMARY_FAILURE: 'GET_MACHINE_SUMMARY_FAILURE',

  GET_COMPONENT_SVG_REQUEST: 'GET_COMPONENT_SVG_REQUEST',
  GET_COMPONENT_SVG_SUCCESS: 'GET_COMPONENT_SVG_SUCCESS',
  GET_COMPONENT_SVG_FAILURE: 'GET_COMPONENT_SVG_FAILURE',

  RESET_MACHINE_OVERVIEW_CONFIG: 'RESET_MACHINE_OVERVIEW_CONFIG'
};
