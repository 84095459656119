export default {
  GET_MACHINE_TRAIN_HEALTH_TREND_REQUEST: 'GET_MACHINE_TRAIN_HEALTH_TREND_REQUEST',
  GET_MACHINE_TRAIN_HEALTH_TREND_SUCCESS: 'GET_MACHINE_TRAIN_HEALTH_TREND_SUCCESS',
  GET_MACHINE_TRAIN_HEALTH_TREND_FAILURE: 'GET_MACHINE_TRAIN_HEALTH_TREND_FAILURE',
  GET_MACHINE_TRAIN_HEALTH_TREND: 'GET_MACHINE_TRAIN_HEALTH_TREND',

  GET_MACHINE_HEALTH_TREND_REQUEST: 'GET_MACHINE_HEALTH_TREND_REQUEST',
  GET_MACHINE_HEALTH_TREND_SUCCESS: 'GET_MACHINE_HEALTH_TREND_SUCCESS',
  GET_MACHINE_HEALTH_TREND_FAILURE: 'GET_MACHINE_HEALTH_TREND_FAILURE',

  GET_MACHINE_HEALTH_CONDITION_REQUEST: 'GET_MACHINE_HEALTH_CONDITION_REQUEST',
  GET_MACHINE_HEALTH_CONDITION_SUCCESS: 'GET_MACHINE_HEALTH_CONDITION_SUCCESS',
  GET_MACHINE_HEALTH_CONDITION_FAILURE: 'GET_MACHINE_HEALTH_CONDITION_FAILURE',
  GET_MACHINE_HEALTH_CONDITION: 'GET_MACHINE_HEALTH_CONDITION',

  GET_COMPONENT_HEALTH_TREND_REQUEST: 'GET_COMPONENT_HEALTH_TREND_REQUEST',
  GET_COMPONENT_HEALTH_TREND_SUCCESS: 'GET_COMPONENT_HEALTH_TREND_SUCCESS',
  GET_COMPONENT_HEALTH_TREND_FAILURE: 'GET_COMPONENT_HEALTH_TREND_FAILURE',

  GET_CHARTS_TAG_TREND_REQUEST: 'GET_CHARTS_TAG_TREND_REQUEST',
  GET_CHARTS_TAG_TREND_SUCCESS: 'GET_CHARTS_TAG_TREND_SUCCESS',
  GET_CHARTS_TAG_TREND_FAILURE: 'GET_CHARTS_TAG_TREND_FAILURE',

  GET_CHARTS_TAG_TREND_ALL_SUCCESS: 'GET_CHARTS_TAG_TREND_ALL_SUCCESS',

  MACHINE_HEALTH_SCORE_RECOMPUTE_TASK: 'MACHINE_HEALTH_SCORE_RECOMPUTE_TASK',
  MACHINE_HEALTH_SCORE_RECOMPUTE_TASK_DONE: 'MACHINE_HEALTH_SCORE_RECOMPUTE_TASK_DONE',
  MACHINE_HEALTH_SCORE_RECOMPUTE_TASK_CLEAR: 'MACHINE_HEALTH_SCORE_RECOMPUTE_TASK_CLEAR',

  GET_MACHINE_TREND_REQUEST: 'GET_MACHINE_TREND_REQUEST',
  GET_MACHINE_TREND_SUCCESS: 'GET_MACHINE_TREND_SUCCESS',
  GET_MACHINE_TREND_FAILURE: 'GET_MACHINE_TREND_FAILURE',

  SET_COMPONENTS_IN_BASELINES: 'SET_COMPONENTS_IN_BASELINES',

  GET_COMPONENT_TREND_REQUEST: 'GET_COMPONENT_TREND_REQUEST',
  GET_COMPONENT_TREND_SUCCESS: 'GET_COMPONENT_TREND_SUCCESS',
  GET_COMPONENT_TREND_FAILURE: 'GET_COMPONENT_TREND_FAILURE',

  GET_FEATURE_TREND_REQUEST: 'GET_FEATURE_TREND_REQUEST',
  GET_FEATURE_TREND_SUCCESS: 'GET_FEATURE_TREND_SUCCESS',
  GET_FEATURE_TREND_FAILURE: 'GET_FEATURE_TREND_FAILURE',

  POST_COMPONENT_BASELINE_REQUEST: 'POST_COMPONENT_BASELINE_REQUEST',
  POST_COMPONENT_BASELINE_SUCCESS: 'POST_COMPONENT_BASELINE_SUCCESS',
  POST_COMPONENT_BASELINE_FAILURE: 'POST_COMPONENT_BASELINE_FAILURE',

  VALIDATE_COMPONENT_BASELINE: 'VALIDATE_COMPONENT_BASELINE',
  VALIDATE_COMPONENT_BASELINE_REQUEST: 'VALIDATE_COMPONENT_BASELINE_REQUEST',
  VALIDATE_COMPONENT_BASELINE_SUCCESS: 'VALIDATE_COMPONENT_BASELINE_SUCCESS',
  VALIDATE_COMPONENT_BASELINE_FAILURE: 'VALIDATE_COMPONENT_BASELINE_FAILURE',
  CLEAR_BASELINE_VALIDATION: 'CLEAR_BASELINE_VALIDATION',

  DELETE_COMPONENT_BASELINE_REQUEST: 'DELETE_COMPONENT_BASELINE_REQUEST',
  DELETE_COMPONENT_BASELINE_SUCCESS: 'DELETE_COMPONENT_BASELINE_SUCCESS',
  DELETE_COMPONENT_BASELINE_FAILURE: 'DELETE_COMPONENT_BASELINE_FAILURE',

  GET_MACHINE_TRAIN_HEALTH_REQUEST: 'GET_MACHINE_TRAIN_HEALTH_REQUEST',
  GET_MACHINE_TRAIN_HEALTH_SUCCESS: 'GET_MACHINE_TRAIN_HEALTH_SUCCESS',
  GET_MACHINE_TRAIN_HEALTH_FAILURE: 'GET_MACHINE_TRAIN_HEALTH_FAILURE',

  GET_COMPONENT_HEALTH_SCORE_DETAILS_REQUEST: 'GET_COMPONENT_HEALTH_SCORE_DETAILS_REQUEST',
  GET_COMPONENT_HEALTH_SCORE_DETAILS_SUCCESS: 'GET_COMPONENT_HEALTH_SCORE_DETAILS_SUCCESS',
  GET_COMPONENT_HEALTH_SCORE_DETAILS_FAILURE: 'GET_COMPONENT_HEALTH_SCORE_DETAILS_FAILURE',

  UPDATE_COMPONENTS_HEALTH_SCORE_PARAMETERS_SUCCESS: 'UPDATE_COMPONENTS_HEALTH_SCORE_PARAMETERS_SUCCESS',
  UPDATE_COMPONENTS_HEALTH_SCORE_PARAMETERS_FAILURE: 'UPDATE_COMPONENTS_HEALTH_SCORE_PARAMETERS_FAILURE',

  UPDATE_AHS_FEEDBACK_STATE_SUCCESS: 'UPDATE_AHS_FEEDBACK_STATE_SUCCESS',
  UPDATE_AHS_FEEDBACK_STATE_FAILURE: 'UPDATE_AHS_FEEDBACK_STATE_FAILURE',

  UPDATE_AHS_ENABLED_SUCCESS: 'UPDATE_AHS_ENABLED_SUCCESS',
  UPDATE_AHS_ENABLED_FAILURE: 'UPDATE_AHS_ENABLED_FAILURE',

  ERASE_AHS_SCORES_REQUEST: 'ERASE_AHS_SCORES_REQUEST',
  ERASE_AHS_SCORES_SUCCESS: 'ERASE_AHS_SCORES_SUCCESS',
  ERASE_AHS_SCORES_FAILURE: 'ERASE_AHS_SCORES_FAILURE',

  GET_AHS_FEATURES_INFO_REQUEST: 'GET_AHS_FEATURES_INFO_REQUEST',
  GET_AHS_FEATURES_INFO_SUCCESS: 'GET_AHS_FEATURES_INFO_SUCCESS',
  GET_AHS_FEATURES_INFO_FAILURE: 'GET_AHS_FEATURES_INFO_FAILURE',

  UPDATE_AHS_FEATURES_INFO_REQUEST: 'UPDATE_AHS_FEATURES_INFO_REQUEST',
  UPDATE_AHS_FEATURES_INFO_SUCCESS: 'UPDATE_AHS_FEATURES_INFO_SUCCESS',
  UPDATE_AHS_FEATURES_INFO_FAILURE: 'UPDATE_AHS_FEATURES_INFO_FAILURE'
};
