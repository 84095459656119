import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'common/styles/colors';
import RBAC from 'common/rbac/RBAC';
import { mapComponentToResource, operations } from 'common/rbac/constants';
import DeleteIconSvg from '../../../../../../common/images/BearingModal/DeleteIconSvg';
import EditIcon from '../../../../../../common/images/BearingModal/EditIcon';
import TickSvg from '../../../../../../common/images/BearingModal/TickSvg';
import FlexContainer from '../../../../../../common/components/atoms/FlexContainer';
// import Checkbox from '../../../../../../common/components/atoms/Checkbox';
import NavButton from '../../../../../../common/components/atoms/NavButton';
import SvgBtn from '../../../../../../common/components/atoms/SvgBtn';
// import RotateIcon from '../../../../../../common/images/RotateIcon.png';

const ThumbContainer = styled(FlexContainer).attrs({
  direction: 'column',
  justifyContent: 'space-between',
})`
  transform: rotate(${props => props.rotate}deg);
  position: relative;
  border-radius: 4px;
  border: 1px solid #ddd;
  height: ${props => props.height};
  width: ${props => props.width};
  ${props => props.image ?
    `background: url(${props.image}) no-repeat` :
    'background: grey'}
  background-size: 100%;
  background-position: center;
  background-size: cover;
  padding: 8px 8px 16px;
`;

const WarningContainer = styled(FlexContainer).attrs({
  direction: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 32px 16px 30px;
`;

const ButtonContainer = styled(FlexContainer).attrs({
  direction: 'row',
  justifyContent: 'space-between',
})`
  width: 100%;
`;

const TopRow = styled(FlexContainer).attrs({
  direction: 'row-reverse',
  justifyContent: 'flex-start',
})`
  position: relative;
  z-index: 3;
  * > { cursor: pointer; }
  img { margin-left: 12px; position: relative; }
`;

const BottomRow = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  max-width: 180px;
  z-index: 3;
  margin: 0 auto;
  span {
    margin-right: 10px;
  }
  ${SvgBtn} {
    display: none;
  }
  &:hover {
    ${SvgBtn} {
      display: inline-block;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 2;
  ${props => props.showOverlayGradient && `
    background: linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0.64) 200%);
  `}
  cursor: ${props => props.warning ? 'default' : 'pointer'};
  ${props => props.deleted && `
    background: rgba(0,0,0,0.1);
    cursor: not-allowed;
  `}
  ${props => props.disableCarousel && `
    cursor: not-allowed;
  `}
  transform: rotate(${props => props.rotate}deg);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${TopRow}, ${SvgBtn} {
    opacity: 0;
  }
  display: inherit;
  padding: inherit;
  flex-direction: inherit;
  justify-content: space-between;
  border-radius: inherit;
  &:hover {
    ${props => !props.deleted && !props.warning && 'background: linear-gradient(180deg,rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0) 55%, rgba(0,0,0,0.40) 100%);'}
    ${TopRow}, ${SvgBtn} {
      opacity: 1;
    }
  }
  ${props => props.warning ? 'background: rgba(60,61,58,0.98);' : ''}
`;

const Label = styled.span.attrs({
  contentEditable: props => props.isEditable ? 'true' : 'false',
})`
  outline: none;
  color: #D8DCD3;
  font-size: 14px;
  font-weight: 600;
  background: transparent;
  border: none;
  width: 130px;
  cursor: ${props => props.isEditable ? 'text' : 'default'};
`;

const WarningText = styled.span`
  color: #FFFF;
  font-size: ${props => props.size || '14px'};
  line-height: ${props => props.size || '14px'};

  font-weight: 600;
`;

const Thumbnail = ({
  updateLabel,
  click,
  image,
  title,
  id,
  showWarning,
  setWarning,
  performDelete,
  deleting,
  height,
  width,
  showLabel,
  showOverlayGradient,
  externalDeleteHandler,
  itemDeleted,
  disableCarousel
}) => {
  const [rotation, setRotation] = useState(0);
  const [isEditable, toggleEditable] = useState(false);
  const [label, setLabel] = useState(title);
  let spanRef = useRef();
  useEffect(() => {
    if (isEditable) {
      spanRef.focus();
    }
  });
  if (showWarning) return (
    <ThumbContainer image={image} rotate={rotation} height={height} width={width}>
      <Overlay rotate={`-${rotation}`} warning>
        <WarningContainer>
          <WarningText>Delete this picture?</WarningText>
          <ButtonContainer>
            <NavButton
              small
              critical
              onClick={() => performDelete()}
            >
              {deleting === id ? 'deleting' : 'Delete'}
            </NavButton>
            <NavButton
              small
              text="#ffffff"
              onClick={() => setWarning()}
            >
              Cancel
            </NavButton>
          </ButtonContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            <WarningText size="10px">Caution:</WarningText>
            <WarningText size="10px">This cannot be undone</WarningText>
          </div>
        </WarningContainer>
      </Overlay>
    </ThumbContainer>
  );
  return (
  <ThumbContainer image={image} rotate={rotation} height={height} width={width}>
    <Overlay
      title={!showLabel ? title : ''}
      rotate={`-${rotation}`}
      disableCarousel={disableCarousel}
      deleted={itemDeleted}
      showOverlayGradient={showOverlayGradient}
    >
      {itemDeleted && (
        <FlexContainer color={colors.error} alignItems="center" justifyContent="center">DELETED</FlexContainer>
      )}
      <TopRow>
        {/* <Checkbox /> */}
        {/* <img
          style={{ position: 'relative' }}
          src={RotateIcon}
          onClick={() => rotation >= 270 ? setRotation(0) : setRotation(rotation + 90)}
        /> */}
        {!itemDeleted && (
          <RBAC
            resource={mapComponentToResource.Attachments}
            operation={operations.Delete}
            yes={(
              <SvgBtn onClick={() => { setWarning(id); externalDeleteHandler(id); }}>
                <DeleteIconSvg
                  fill="#ffffff"
                  stroke="#ffffff"
                />
              </SvgBtn>
            )}
          />
        )}
      </TopRow>
      <div
        onClick={click}
        style={{
          height: '100%',
          width: '100%'
        }}
      />
      {showLabel && (
        <BottomRow>
          <Label
            contentEditable={isEditable}
            onInput={e => setLabel(e.target.innerText)}
            placeholder={title}
            innerRef={(node) => { spanRef = node; }}
          >
            {title}
          </Label>
          <SvgBtn
            onClick={handleEdit}
          >
          {isEditable ? (
            <TickSvg
              height={14}
              fill="#edffcc"
            />) : (
            <EditIcon
              fill="#ffffff"
            />)
          }
          </SvgBtn>
        </BottomRow>
      )}
    </Overlay>
  </ThumbContainer>
  );

  function handleEdit() {
    spanRef.focus();
    if (isEditable) {
      if (!!label && label !== title) updateLabel(label, id);
      toggleEditable(false);
    } else {
      toggleEditable(true);
    }
  }
};

Thumbnail.propTypes = {
  id: PropTypes.number.isRequired,
  showWarning: PropTypes.bool,
  setWarning: PropTypes.func,
  performDelete: PropTypes.func,
  externalDeleteHandler: PropTypes.func,
  height: PropTypes.string,
  width: PropTypes.string,
  showLabel: PropTypes.bool,
  showOverlayGradient: PropTypes.bool,
  itemDeleted: PropTypes.bool
};

Thumbnail.defaultProps = {
  showWarning: false,
  setWarning: () => {},
  performDelete: () => {},
  externalDeleteHandler: () => {},
  height: '200px',
  width: '200px',
  showLabel: true,
  showOverlayGradient: true,
  itemDeleted: false
};

export default styled(Thumbnail)``;
