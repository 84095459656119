import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import styled from 'styled-components';

import Button from 'common/components/atoms/Button';
import Modal from 'common/components/organisms/Modal';
import FlexContainer from 'common/components/atoms/FlexContainer';
import InputField_T from 'common/components/atoms/InputField';

import OverallAlarmPreviewItem from './OverallAlarmPreviewItem';
import Error from '../../atoms/Error';
import { OVERALL_ALARM_STATES, commonDefaultOverallAlarmSettings } from '../../../constants/overallAlarm.constants';

import * as assetDetailActions from '../../../actions/assetDetails.actions';
import * as overallAlarmActions from '../../../actions/overallAlarm.actions';
import * as envelopeActions from '../../../actions/envelope.actions';

const fontSettings = `
  font-family: "Petasense Open Sans";
  font-size: 14px;
  font-weight: 300;
`;

const InputField = styled(InputField_T)`
  min-width: 150px
`;
class OverallAlarmPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      selectedBin: 0,
      overallAlarmThresholdsList: this.initialOverallAlarmThresholdList()
    };
  }

  componentDidMount() {
    this.getTrendsDataForAllTags();
  }

  initialOverallAlarmThresholdList = () => {
    const { manualAlarmThresholds, tags } = this.props;
    const overallAlarmThresholdsList = _.map(tags, (tag) => {
      const alarmThresholds = _.mapValues(manualAlarmThresholds, t => ({ ...t, selected: true }));
      return { alarmThresholds, tag_id: tag.id };
    });
    return overallAlarmThresholdsList;
  }

  getFooter = () => {
    const { error } = this.state;
    return (
      <>
        {error && (
          <Error>{error}</Error>
        )}
        <Button secondary="black" onClick={this.onCancel}>Cancel</Button>
        <Button disabled={this.isSaveDisabled()} onClick={this.onSave}>Save</Button>
      </>
    );
  }

  getData = () => {
    const { overallAlarmThresholdsList } = this.state;
    const { overallAlarmSettings, tags } = this.props;
    const selectedAlarmThresholds = [];
    _.forEach(overallAlarmThresholdsList, (tagThresholds) => {
      if (tagThresholds.alarmThresholds) {
        let alarmThresholds = _.pickBy(tagThresholds.alarmThresholds, t => t.selected);
        alarmThresholds = _.mapValues(alarmThresholds, t => _.omit(t, 'selected'));
        if (!_.isEmpty(alarmThresholds)) {
          selectedAlarmThresholds.push({ ...tagThresholds, alarmThresholds });
        }
      }
    });
    const tag_ids = _.map(selectedAlarmThresholds, e => e.tag_id);
    const final_tags = _.filter(tags, tag => tag_ids.includes(tag.id));
    const data = [];
    final_tags.forEach((tag) => {
      const params = this.props.getParamsForUpdateTag(overallAlarmSettings, selectedAlarmThresholds.find(t => t.tag_id === tag.id).alarmThresholds, tag.overall_alarm_settings, tag.alarm_thresholds);
      params.id = tag.id;
      data.push(params);
    });
    return { tags: data };
  }

  onCancel = () => {
    this.props.close();
  }

  getParamsList = () => {
    const { overallAlarmSettings, getParamsToFetchTrendData, manualAlarmThresholds } = this.props;
    const uniq_settings = _.uniqWith(_.values(overallAlarmSettings), _.isEqual);
    const paramsList = _.map(uniq_settings, (settings) => {
      const bins = _.keys(_.pickBy(overallAlarmSettings, value => _.isEqual(value, settings)));
      return {
        bins: _.map(bins, bin => parseInt(bin, 10)),
        params: getParamsToFetchTrendData(settings)
      };
    });
    if (!_.isEmpty(manualAlarmThresholds)) {
      const bins = _.keys(manualAlarmThresholds);
      paramsList.push({
        bins: _.map(bins, bin => parseInt(bin, 10)),
        params: getParamsToFetchTrendData(commonDefaultOverallAlarmSettings)
      });
    }
    return paramsList;
  }

  onSave = () => {
    const data = this.getData();
    const { breadcrumb: { machine, site } } = this.props;
    this.props.envelopeActions.bulkUpdateTags(machine.id, data).then(
      (res) => {
        toastr.success(res.message);
        this.props.close();
        this.props.setAlarmState(OVERALL_ALARM_STATES.VIEW);
        this.props.assetDetailActions.getTags(machine.id, site.id);
      },
      (error) => {
        toastr.error(error.message);
      }
    );
  };

  isSaveDisabled = () => {
    const { error } = this.state;
    const { tags } = this.props;
    const areTagsLoading = _.some(tags, tag => tag.trends && tag.trends.loading);
    if (error || areTagsLoading) return true;
    return false;
  }

  getTrendsDataForAllTags = () => {
    const { tags, fetchTrendData } = this.props;
    const paramsList = this.getParamsList();
    tags.forEach((tag) => {
      paramsList.forEach(({ bins, params }) => fetchTrendData(tag.id, params, bins, true));
    });
  }

  onBinChange = (selectedBin) => {
    this.setState({ selectedBin });
  }

  updateOverallAlarmThresholds = (bins, thresholdsList, tag_id) => {
    let { overallAlarmThresholdsList } = this.state;
    overallAlarmThresholdsList = _.map(this.state.overallAlarmThresholdsList, (t) => {
      if (t.tag_id !== tag_id) return t;
      const alarmThresholds = _.cloneDeep(t.alarmThresholds) || {};
      bins.forEach((bin, idx) => { alarmThresholds[bin] = { ...thresholdsList[idx], selected: true }; });
      return {
        ...t,
        alarmThresholds
      };
    });
    this.setState({ overallAlarmThresholdsList });
  }

  toggleSelectedTagBin = (tag_id) => {
    let { overallAlarmThresholdsList } = this.state;
    const bin = _.cloneDeep(this.state.selectedBin).toString();
    overallAlarmThresholdsList = _.map(this.state.overallAlarmThresholdsList, (t) => {
      if (t.tag_id !== tag_id) return t;
      return {
        ...t,
        alarmThresholds: {
          ...t.alarmThresholds,
          [bin]: { ...t.alarmThresholds[bin], selected: !t.alarmThresholds[bin].selected }
        }
      };
    });
    let error = '';
    if (!_.some(overallAlarmThresholdsList, t => t.alarmThresholds && _.some(t.alarmThresholds, t => t.selected))) error = 'Select at least one tag';
    this.setState({ overallAlarmThresholdsList, error });
  }

  getTrendDataFromTag = (tag) => {
    const { selectedBin } = this.state;
    if (tag.trends && tag.trends.data[selectedBin] && tag.trends.data[selectedBin].length > 0) return tag.trends.data[selectedBin];
    return null;
  }

  binFilterOptionsToBeDisplayed = () => {
    const { binFilterOptions, overallAlarmSettings, manualAlarmThresholds } = this.props;
    return binFilterOptions.filter(bin => [..._.keys(overallAlarmSettings), ..._.keys(manualAlarmThresholds)]
      .includes(bin.value.toString())
    );
  }

  getHeader = () => {
    const { binFilterOptions } = this.props;
    const { selectedBin } = this.state;
    return (
      <>
      {binFilterOptions.length > 1 ? (
        <FlexContainer justifyContent="flex-end">
          <InputField
            type="select"
            options={this.binFilterOptionsToBeDisplayed()}
            value={selectedBin}
            onChange={(e, d) => this.onBinChange(d.value)}
            fontSettings={fontSettings}
            optionFontSettings={fontSettings}
            marginBottom="0"
          />
        </FlexContainer>
      ) : ''}
      </>
    );
  }

  render() {
    const {
      breadcrumb,
      currentUser,
      overallAlarmSettings,
      tags,
      ampType,
      feature,
      yUnit,
      speedUnit
    } = this.props;
    const { overallAlarmThresholdsList, selectedBin } = this.state;
    return (
      <Modal
        close={this.props.close}
        footer={this.getFooter}
        header={this.getHeader}
        title={`${breadcrumb.machine.name} > Trend Overall Alarm > Preview`}
      >
        {tags.map((tag, idx) => {
          const tagAlarmThresholds = _.find(overallAlarmThresholdsList, t => t.tag_id === tag.id);
          const data = this.getTrendDataFromTag(tag);
          if (!tagAlarmThresholds) return '';
          const { alarmThresholds } = tagAlarmThresholds;
          return (
            <OverallAlarmPreviewItem
              overallAlarmSettings={overallAlarmSettings}
              frequencyUnits={currentUser.frequency_units}
              key={`preview-${idx}`}
              loading={!tag.trends || tag.trends.loading}
              selected={alarmThresholds && alarmThresholds[selectedBin] && alarmThresholds[selectedBin].selected && data}
              alarmThresholds={alarmThresholds}
              selectedBin={selectedBin}
              data={data}
              tag={tag}
              toggleSelectedTagBin={this.toggleSelectedTagBin}
              unitSystem={currentUser.unit_system}
              updateOverallAlarmThresholds={this.updateOverallAlarmThresholds}
              ampType={ampType}
              feature={feature}
              yUnit={yUnit}
              speedUnit={speedUnit}
            />
          );
        })}
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const site = _.find(state.assetHierarchyReducer.assetInfo.sites, s => s.site_id === state.breadcrumb.site.id);
  const machine = site && _.find(site.machines, m => m.machine_id === state.breadcrumb.machine.id);
  const tags = machine && _.filter(machine.tags, t => t.type === ownProps.tagType);
  return {
    breadcrumb: state.breadcrumb,
    currentUser: state.user.user,
    tags
  };
};

const mapDispatchToProps = dispatch => ({
  assetDetailActions: bindActionCreators(assetDetailActions, dispatch),
  overallAlarmActions: bindActionCreators(overallAlarmActions, dispatch),
  envelopeActions: bindActionCreators(envelopeActions, dispatch)
});

OverallAlarmPreview.propTypes = {
  close: PropTypes.func.isRequired,
  overallAlarmSettings: PropTypes.object,
  manualAlarmThresholds: PropTypes.object,
  fetchTrendData: PropTypes.func,
  setAlarmState: PropTypes.func,
  getParamsToFetchTrendData: PropTypes.func,
  getParamsForUpdateTag: PropTypes.func,
  binFilterOptions: PropTypes.array,
};

OverallAlarmPreview.defaultProps = {
  overallAlarmSettings: {},
  manualAlarmThresholds: {},
  fetchTrendData: () => {},
  setAlarmState: () => {},
  getParamsToFetchTrendData: () => {},
  getParamsForUpdateTag: () => {},
  binFilterOptions: []
};


export default connect(mapStateToProps, mapDispatchToProps)(OverallAlarmPreview);
