/* eslint-disable no-nested-ternary */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';

import { humanize } from 'common/helpers';
import ChartRULSettingsModal from 'common/components/Chart/components/ChartRULSettingsModal';
import * as rulActions from 'home/Machines/MachineDetails/Health/actions/rul.actions';
import { convertTimestringsToDateObjects } from 'common/utils';
import Button from 'common/components/atoms/Button';
import ColoredCircle from 'common/components/Chart/atoms/ColoredCircle';
import Text from 'common/typography/Text/Text';
import FooterFlex from 'home/AssetHierarchy/components/atoms/FooterFlex';
import Loading from '../../../../../common/components/atoms/Loading';
import { Chart, Selection } from '../../../../../common/components/Chart';
import FlexContainer from '../../../../../common/components/atoms/FlexContainer';
import OutlierReadingModal from '../../../../../common/components/Chart/components/OutlierReadingModal';
import * as utils from '../../../../../common/utils';
import RBAC from '../../../../../common/rbac/RBAC';
import {
  mapComponentToResource,
  operations
} from '../../../../../common/rbac/constants';

import * as machineChartsActions from '../actions/machineCharts.actions';

import NoDataChart from './NoDataChart';
import AddNoteChartOverlay from './AddNoteChartOverlay';

const TrendChartContainer = styled.div`
  position: relative;
  ${props => (props.optionsVisible ? 'width: calc(100% - 220px);' : 'width: 100%;')};
`;

const Hidden = styled.div`
  opacity: 0;
`;

const AbsoluteLoading = styled(Loading)`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e447;
`;

const LegendContainer = styled(FlexContainer)`
  border: 1px solid grey;
  border-spacing: 15px;
  border-color:${props => props.theme.colors.lightGray};
  border-radius: 4px;
  width: 20%;
  margin-left: auto;
`;

class TagChart extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.points && !_.isEqual(nextProps.points, prevState.points)) {
      return {
        points: nextProps.points
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.mouseoverChart = this.mouseoverChart.bind(this);
    this.mouseClick = this.mouseClick.bind(this);
    this.brushCb = this.brushCb.bind(this);
    this.addNote = this.addNote.bind(this);
    this.state = {
      selection: {},
      points: [],
      dataPos: {
        y: false,
        x: false
      },
      dataPoint: null,
      showSettingsModal: false,
      rulFdpDatapoint: {},
      rulStartTimeDatapoint: {}
    };
  }

  componentDidMount() {
    const { data, loading } = this.props;
    if (!loading && data) this.setInitialSelection();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.props.loading &&
      (!_.isEqual(this.props.data, prevProps.data) ||
        ((`${this.props.yUnit}` !== this.state.selection.yUnit) && this.state.selection.yUnit !== 'dB') ||
        (this.props.warningThreshold && (this.props.warningThreshold !== prevProps.warningThreshold) ||
        this.props.criticalThreshold && (this.props.criticalThreshold !== prevProps.criticalThreshold)) ||
        (this.props.rulFdp !== prevProps.rulFdp) || (this.props.rulStartTime !== prevProps.rulStartTime)
      )
    ) {
      this.setInitialSelection(prevState.selectedIdx);
    }
    if (this.props.title === 'spectrum' ? !_.isEqual(this.props.originalData, prevProps.originalData) : !_.isEqual(this.props.data, prevProps.data)) {
      this.setState({
        points: []
      });
    }
    if (this.props.skipOverlay) {
      this.addNote();
    }
  }

  storeBrushDebounce = _.debounce(this.props.storeBrushSelection ?
    () => this.props.storeBrushSelection(this.state.brushSelection) :
    () => { },
  500
  )

  setPointsToCorrectUnits = (points) => {
    this.setState({ points });
  }

  setSelectionToBrushEnd = () => {
    if (this.props.noHover) return;
    const { data, yTitle, yUnit, xTitle, xUnit, xIsDate } = this.props;
    const { brushSelection } = this.state;
    if (data.length) {
      if (brushSelection && brushSelection.length) {
        this.setState({
          selection: {
            ...data[data.length - 1],
            x: brushSelection[1],
            xTitle: `${xTitle}`,
            xUnit: `${xUnit}`,
            isDate: xIsDate,
            yTitle: `${yTitle}`,
            yUnit: `${yUnit}`
          }
        });
      } else {
        this.setState({
          selection: {
            ...data[data.length - 1],
            xTitle: `${xTitle}`,
            xUnit: `${xUnit}`,
            isDate: xIsDate,
            yTitle: `${yTitle}`,
            yUnit: `${yUnit}`
          }
        });
      }
    }
  };

  brushCb(selection) {
    this.setState({
      brushSelection: selection
    }, () => {
      this.storeBrushDebounce();
      this.setSelectionToBrushEnd();
    });
  }

  setInitialSelection(oldSlectedIdx = undefined) {
    if (this.props.noHover || !this.props.data) return;
    const { data, yTitle, yUnit, xTitle, xUnit, xIsDate, rulFdp, rulStartTime } = this.props;
    let selection = data[data.length - 1];
    if (oldSlectedIdx !== undefined) {
      selection = data[oldSlectedIdx];
      // if (this.props.setInitialSelection) this.props.setInitialSelection(oldSlectedIdx);
    }
    let rulFdpDatapoint = {};
    let rulStartTimeDatapoint = {};
    if (rulFdp) {
      const rulFdpObject = convertTimestringsToDateObjects([rulFdp])[0];
      rulFdpDatapoint = data.find(d => d.x.toString() === rulFdpObject.toString()) || {};
    }
    if (rulStartTime) {
      const rulStartTimeObject = convertTimestringsToDateObjects([rulStartTime])[0];
      rulStartTimeDatapoint = data.find(d => d.x.toString() === rulStartTimeObject.toString()) || {};
    }
    if (data.length) {
      this.setState({
        selection: {
          ...selection,
          xTitle: `${xTitle}`,
          xUnit: `${xUnit}`,
          isDate: xIsDate,
          yTitle: `${yTitle}`,
          yUnit: `${yUnit}`
        },
        rulFdpDatapoint,
        rulStartTimeDatapoint
      });
    }
  }

  mouseoverChart(selection) {
    if (this.props.mouseoverChart) {
      this.props.mouseoverChart(selection);
    }
    const { yTitle, yUnit, xTitle, xUnit, xIsDate } = this.props;
    this.setState({
      selection: {
        ...selection,
        xTitle: `${xTitle}`,
        xUnit: `${xUnit}`,
        isDate: xIsDate,
        yTitle: `${yTitle}`,
        yUnit: `${yUnit}`
      },
    });
    if (this.props.setLastHoveredChart) {
      this.props.setLastHoveredChart(this.props.type);
    }
  }

  mouseClick(selection = undefined) {
    const idx = this.props.data.findIndex(d => _.isEqual(d.x, this.state.selection.x));
    if (this.props.mouseClick) {
      if (!selection && (this.state.selection.x === this.state.points.x)) {
        this.setState({
          showSettingsModal: true
        });
      } else {
        // TrendChart handlekeyStrokes is using selection.
        this.props.mouseClick(selection || this.state.selection);
        if (idx !== undefined) {
          this.setState({
            selectedIdx: idx,
            showSettingsModal: false
          });
          if (this.props.setSelectedIdx) this.props.setSelectedIdx(idx);
        }
      }
    } else if (this.state.points === undefined || this.state.points.length === 0) {
      // determine if first or second click on the graph
      // if (this.state.points === undefined || this.state.points.length === 0) {
      this.setState({
        points: [_.cloneDeep(this.state.selection)]
      });
    } else if (this.state.points.length === 1) {
      this.setState({
        points: [...this.state.points, _.cloneDeep(this.state.selection)]
      });
    } else if (this.state.points.length === 2) {
      this.setState({
        points: []
      });
    }
    // }
  }

  setDataPosAndDataPoint = (dataPos, dataPoint) => {
    this.setState({
      dataPos,
      dataPoint
    });
  }

  markReadingAsOutlier = () => {
    const { tagId } = this.props;
    const timestamp = moment.utc(this.state.dataPoint.x).format('YYYY-MM-DD HH:mm:ss');
    this.props.machineChartsActions.postOutlierReading(tagId, timestamp).then(
      () => {
        this.setState({
          showSettingsModal: false,
        });
        this.props.refreshChartAfterOutlier();
      });
  }

  setAsFDP = () => {
    const { tagId, componentId, machineId, siteId } = this.props;
    const timestamp = moment.utc(this.state.dataPoint.x).format('YYYY-MM-DD HH:mm:ss');
    const args = {
      tag_settings: {}
    };
    args.tag_settings[tagId] = {
      fdp: timestamp
    };

    this.props.rulActions.updateRULSettings(componentId, args, machineId, siteId).then(
      () => {
        this.setState({
          showSettingsModal: false,
        });
      }
    );
  }

  deleteFDP = () => {
    const { tagId, componentId, machineId, siteId } = this.props;
    const args = {
      tag_settings: {}
    };
    args.tag_settings[tagId] = {
      fdp: null
    };

    this.props.rulActions.updateRULSettings(componentId, args, machineId, siteId).then(
      () => {
        this.setState({
          showSettingsModal: false,
        });
      }
    );
  }

  setAsStartTime = () => {
    const { componentId, machineId, siteId } = this.props;
    const timestamp = moment.utc(this.state.dataPoint.x).format('YYYY-MM-DD HH:mm:ss');
    const args = {
      start_time: timestamp
    };
    this.props.rulActions.updateRULSettings(componentId, args, machineId, siteId).then(
      () => {
        this.setState({
          showSettingsModal: false,
        });
      }
    );
  }

  deleteStartTime = () => {
    const { componentId, machineId, siteId } = this.props;
    const args = {
      start_time: null
    };
    this.props.rulActions.updateRULSettings(componentId, args, machineId, siteId).then(
      () => {
        this.setState({
          showSettingsModal: false,
        });
      }
    );
  }

  deleteOutlierReading = () => {
    const { tagId } = this.props;
    const timestamp = moment.utc(this.state.dataPoint.x).format('YYYY-MM-DD HH:mm:ss');
    this.props.machineChartsActions.deleteOutlierReading(tagId, timestamp).then(
      () => {
        this.setState({
          showSettingsModal: false,
        });
        this.props.refreshChartAfterOutlier();
      });
  }

  addNote() {
    this.props.addNote({
      title: this.props.title,
      start: this.state.brushSelection ? this.state.brushSelection[0] : undefined,
      end: this.state.brushSelection ? this.state.brushSelection[1] : undefined,
      data: this.props.data,
      xAxisType: this.props.xAxisType,
      tag_id: this.props.tagId
    });
  }

  getChartRange = () => {
    const { data, spectralEnvelope, unit_system, tag_type, type, title, yUnit } = this.props;
    const range = utils.chartRange(data, unit_system, yUnit, title, tag_type, type);
    if (spectralEnvelope && spectralEnvelope.envelope_data && !_.isEmpty(spectralEnvelope.envelope_data.start_frequencies)) {
      const envelope_range = utils.getStepGraphRange(spectralEnvelope.envelope_data);
      range[0] = Math.min(range[0], envelope_range[0]);
      range[1] = Math.max(range[1], envelope_range[1]);
    }
    return range;
  }

  getYLabel = () => {
    const { tag_type, yTitle, yUnit, type } = this.props;
    if (tag_type === 'vibration' && (type === 'spectrum' || type === 'demod_spectrum')) return `${yTitle} ${yUnit === '' ? '' : `(${yUnit},0-Pk)`}`;
    return `${yTitle} ${yUnit === '' ? '' : `(${yUnit})`}`;
  }

  render() {
    const {
      data,
      tagId,
      loading,
      title,
      yUnit,
      expanded,
      xAxisType,
      xUnit,
      initialXunit,
      addingNote,
      config_id,
      displayOutliers,
      color,
      skipOverlay,
      type,
      filterSettings,
      shaftSpeed,
      originalData,
      warningThreshold,
      criticalThreshold
    } = this.props;

    if (!data && loading) {
      return (
        <Loading center />
      );
    }

    if (data) {
      const range = this.getChartRange();
      const toFixedDecimalPoints = type === 'demod_spectrum' ? 4 : 2;
      return (
        <Fragment>
          {this.state.selection &&
            title === 'waveform' && (
              <Selection
                data={{
                  ...this.state.selection,
                  x: this.state.selection.x / 1000,
                  xUnit: 's'
                }}
                filterSettings={filterSettings}
              />
          )}
          {this.state.selection &&
            title !== 'trend' &&
            title !== 'feature' &&
            title !== 'waveform' && <Selection title={title} toFixedDecimalPoints={toFixedDecimalPoints} shaftSpeed={shaftSpeed} initialXunit={initialXunit} data={this.state.selection} filterSettings={filterSettings} />}
          {this.state.selection &&
            title === 'feature' && (
              <Hidden>
                <Selection data={this.state.selection} />
              </Hidden>
          )}
          <FlexContainer>
            {(data && data.length) && (
                <TrendChartContainer optionsVisible={!!this.props.children}>
                  {loading && <AbsoluteLoading />}
                  <Chart
                    {...this.props}
                    chartName={this.props.chartName || `tag-${tagId}-${title}-${config_id}`}
                    data={[data]}
                    originalData={[originalData]}
                    outliersArray={data.filter(d => d.is_outlier)}
                    range={range}
                    colors={color ? [color] : undefined}
                    contextChart={expanded}
                    mouseover={this.mouseoverChart}
                    selection={this.state.selection}
                    selectedRange={this.props.selectedRange}
                    height={this.props.height ? this.props.height : '100px'}
                    title={title}
                    ylabel={this.getYLabel()}
                    xAxis={xAxisType}
                    xUnit={xUnit}
                    yUnit={yUnit}
                    setPointsToCorrectUnits={this.setPointsToCorrectUnits}
                    mouseClick={this.mouseClick}
                    points={this.state.points}
                    features={this.props.features}
                    warningThreshold={warningThreshold}
                    criticalThreshold={criticalThreshold}
                    enableAlarmAndBaselineLines={this.props.enableAlarmAndBaselineLines}
                    brushCb={this.brushCb}
                    brushSelected={this.props.brushSelected}
                    setDataPosAndDataPoint={this.setDataPosAndDataPoint}
                    rulFdpDatapoint={this.state.rulFdpDatapoint}
                    rulStartTimeDatapoint={this.state.rulStartTimeDatapoint}
                  />
                {addingNote && !skipOverlay && <AddNoteChartOverlay onClick={this.addNote} text={title} />}
                {displayOutliers && !this.props.editRULSettings && this.state.showSettingsModal && this.state.dataPos.x !== false && (
                  <RBAC
                    resource={mapComponentToResource.MachineCharts}
                    operation={operations.Update}
                    yes={(
                      <OutlierReadingModal
                        {...this.state.dataPos}
                        height="192"
                        width="250"
                        dataPoint={this.state.dataPoint}
                        markReadingAsOutlier={this.markReadingAsOutlier}
                        deleteOutlierReading={this.deleteOutlierReading}
                        close={() => this.setState({ showSettingsModal: false })}
                      />
                    )}
                  />
                )}
                {this.props.editRULSettings && this.state.showSettingsModal && this.state.dataPos.x !== false && (
                  <RBAC
                    resource={mapComponentToResource.RUL}
                    operation={operations.Update}
                    yes={(
                      <ChartRULSettingsModal
                        {...this.state.dataPos}
                        height="192"
                        width="250"
                        dataPoint={this.state.dataPoint}
                        setAsFDP={this.setAsFDP}
                        setAsStartTime={this.setAsStartTime}
                        deleteFDP={this.deleteFDP}
                        deleteStartTime={this.deleteStartTime}
                        close={() => this.setState({ showSettingsModal: false })}
                        rulFdpDatapoint={this.state.rulFdpDatapoint}
                        rulStartTimeDatapoint={this.state.rulStartTimeDatapoint}
                      />
                    )}
                  />
                )}
                {this.props.editRULSettings && (
                  <>
                    <LegendContainer>
                      <FlexContainer direction="column" margin="0.5em">
                        <FlexContainer alignItems="center" margin="0.33em">
                          <ColoredCircle color="green" size="1em" />
                          <Text>Start Time</Text>
                        </FlexContainer>
                        <FlexContainer alignItems="center" margin="0.33em">
                          <ColoredCircle color="blue" size="1em" />
                          <Text>First Detection Point (FDP)</Text>
                        </FlexContainer>
                      </FlexContainer>
                    </LegendContainer>
                    <FooterFlex>
                      <Button
                        cancel
                        onClick={this.props.closeRULSettings}
                      >
                        Cancel
                      </Button>
                    </FooterFlex>
                  </>
                )}
                </TrendChartContainer>
            )}
            {this.props.children}
          </FlexContainer>
        </Fragment>
      );
    }
    return (
        <NoDataChart
          configId={`config_id-${type}`}
          height="200px"
          title={`No ${title === 'Cepstrum' ? 'Cepstrum' : humanize(type)} data available`}
        />
    );
  }
}

TagChart.propTypes = {
  data: PropTypes.array,
  tagId: PropTypes.number.isRequired,
  machineChartsActions: PropTypes.object.isRequired,
  displayOutliers: PropTypes.bool,
  editRULSettings: PropTypes.bool,
  rulFdp: PropTypes.string,
  rulStartTime: PropTypes.string
};

TagChart.defaultProps = {
  data: undefined,
  displayOutliers: true,
  editRULSettings: false,
  rulFdp: null,
  rulStartTime: null
};

const mapDispatchToProps = dispatch => ({
  machineChartsActions: bindActionCreators(machineChartsActions, dispatch),
  rulActions: bindActionCreators(rulActions, dispatch)
});

const mapStateToProps = state => ({
  componentId: state.breadcrumb.component.id,
  machineId: state.breadcrumb.machine.id,
  siteId: state.breadcrumb.site.id
});

export default connect(mapStateToProps, mapDispatchToProps)(TagChart);
