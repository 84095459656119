import React, { Component } from 'react';
import styled from 'styled-components';

const PoeIconContainer = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 0.8em;
  }
`;

export class PoeIcon extends Component {
  render() {
    const icon = (
      <svg width="21px" height="8px" viewBox="0 0 21 8" version="1.1">
        <g id="Sensors" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="1-0-1-Edit-List" transform="translate(-813.000000, -291.000000)">
            <g id="sensors_list" transform="translate(60.000000, 175.000000)">
              <g id="3" transform="translate(0.000000, 96.000000)">
                <g id="battery" transform="translate(704.000000, 13.000000)">
                  <g id="poe-icon" transform="translate(59.500000, 10.500000) rotate(-270.000000) translate(-59.500000, -10.500000) translate(49.500000, 0.500000)">
                    <rect id="icon-bg" x="0" y="0" width="20" height="20" />
                    <g id="icon" transform="translate(7.000000, 0.000000)" fill="#3C3D3A" fillRule="nonzero">
                      <path d="M6.40657917,3.53286742 L5.7801308,3.53286742 L5.7801308,0.5859375 C5.7801308,0.262298594 5.5159103,0 5.19024316,0 L1.80637729,0 C1.48055655,0 1.21648965,0.262298594 1.21648965,0.5859375 L1.21648965,3.53286742 L0.58988764,3.53286742 C0.264066899,3.53286742 -1.81588078e-12,3.79531859 -1.81588078e-12,4.11880492 L-1.81588078e-12,11.3787842 C-1.81588078e-12,12.4455261 0.731214888,13.3454895 1.72173455,13.611145 L2.00899756,16.8058777 C2.03603408,17.1081543 2.29103755,17.3397827 2.59642734,17.3397827 L2.90842257,17.3397827 L2.90842257,19.4140625 C2.90842257,19.7377014 3.17248947,20 3.49831021,20 C3.82397734,20 4.08819785,19.7377014 4.08819785,19.4140625 L4.08819785,17.3397827 L4.40003951,17.3397827 C4.70542926,17.3397827 4.96043277,17.1081543 4.98746929,16.8058777 L5.27473226,13.611145 C6.26525192,13.3454895 6.99646681,12.4455261 6.99646681,11.3787842 L6.99646681,4.11880492 C6.99646681,3.79531859 6.73239991,3.53286742 6.40657917,3.53286742 Z M5.81669153,11.3787842 C5.81669153,12.0075989 5.30161517,12.5193787 4.66840767,12.5193787 L2.32805917,12.5193787 C1.69500525,12.5193787 1.17977528,12.0075989 1.17977528,11.3787842 L1.17977528,4.70474242 L5.81669153,4.70474242 L5.81669153,11.3787842 Z M3.13608235,16.1679077 L2.91349194,13.6912537 L4.0829749,13.6912537 L3.86038446,16.1679077 L3.13608235,16.1679077 Z M2.90842257,1.171875 L2.90842257,1.88949586 C2.90842257,2.21313477 3.17248947,2.47543336 3.49831021,2.47543336 C3.82397734,2.47543336 4.08819785,2.21313477 4.08819785,1.88949586 L4.08819785,1.171875 L4.60035552,1.171875 L4.60035552,3.53286742 L2.39626493,3.53286742 L2.39626493,1.171875 L2.90842257,1.171875 Z" id="Shape" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );

    return (
      <PoeIconContainer>
        <span>PoE</span>
        {icon}
      </PoeIconContainer>
    );
  }
}
