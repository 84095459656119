import styled from 'styled-components';
import FlexContainer from 'common/components/atoms/FlexContainer';

const ListContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  position: relative;
  overflow: auto;
  width: ${props => props.width};
  height: ${props => props.height};
  padding: 1rem;
  ${props => props.minWidth && `min-width: ${props.minWidth}`};
  ${props => props.paddingBottom && `padding-bottom: ${props.paddingBottom}`};
  ${props => props.borderLeft && `border-left: 2px solid ${props.theme.colors.greyL};`}
  ${props => props.borderRadius && `border-radius: ${props.borderRadius};`}
  ${props => props.background && `background: ${props.theme.colors.greyXL}`}
  ${props => props.padding && `padding: ${props.padding}`}
`;

export default ListContainer;
