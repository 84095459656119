import { noteConstants } from '../constants/constants';

const initialState = {
  open: false,
  noteId: null,
  openNoteDetails: {}
};

export const noteModal = (state = initialState, action) => {
  switch (action.type) {
    case noteConstants.SET_NOTE_MODAL_OPEN:
      return {
        ...state,
        open: true,
        noteId: action.noteId
      };

    case noteConstants.SET_NOTE_MODAL_CLOSE:
      return {
        ...initialState
      };

    case noteConstants.SET_NOTE_DETAILS:
      return {
        ...state,
        openNoteDetails: {
          ...action.noteDetails,
          ...action.noteDetails.details
        }
      };

    default:
      return state;
  }
};
