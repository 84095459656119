import styled, { css } from 'styled-components';
import H2_T from 'common/typography/H2/H2';
import H3_T from 'common/typography/H3/H3';
import H4_T from 'common/typography/H4/H4';
import Label_T from 'common/typography/Label/Label';
import colors from 'common/styles/colors';
import InputField_T from '../Chart/atoms/InputField';
import FlexContainer from './FlexContainer';

export const ResourceSection = styled(H2_T)`
  width: ${props => (props.width ? props.width : '100%')};
  font-size: 18px;
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom : '8px'};
`;

export const ResourceSubSection = styled(H3_T)`
  width: 100%;
  font-size: 16px;
  padding-top: 10px;
`;

export const ResourceItem = styled(H4_T)`
  width: max-content;
  font-size: 13px;
  font-weight: 300;
  ${props =>
    props.hover &&
    `
    &:hover {
      color: ${props.theme.primaryColor};
      font-weight: 300;
      cursor: pointer;
    }
  `}
`;

export const ResourceItemLabel = styled(Label_T)`
 font-weight: 600;
 font-size: 13px;
  ${props => props.grey && `color: ${props.theme.colors.greyD};`}
  ${props => props.marginbottom && `margin-bottom: ${props.marginbottom};`}
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.paddingtop && `padding-top: ${props.paddingtop};`}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.margintop && `margin-top: ${props.margintop};`}
  span {
    &:hover {
        cursor: pointer;
    }
  }
`;

const defaultFontSettings = props => css`
  font-family: ${props.theme.fonts.lightLabel};
`;
export const ResourceInputField = styled(InputField_T)`
  width: ${props => (props.width ? props.width : '100%')};
  min-width: ${props => (props.minwidth ? props.minwidth : '200px')};
  label {
    font-weight: 600;
    :after {
      content: ${props => (props.isIncomplete && !props.disabled ? '*' : '')};
    }
    ${props => props.require ? `
      &:before {
        content: '*';
        color: ${colors.red};
        margin-right: 0px;
        position: relative;
        left: -2px;
      }
      
      &:after {
        content: '(required)';
        font-style: italic;
        color: ${colors.red};
        margin-left: 5px;
        font-size: 0.8em;
      }
    ` : ''}

    ${props => (props.isIncomplete && !props.disabled) ? `
      &:after {
        content: '*';
        color: ${colors.cyan};
        margin-left: 5px;
      }
    ` : ''}
  }
  padding-bottom: 0px;
  margin-bottom: 0px;
  ${FlexContainer} {
    border: ${props =>
    props.isIncomplete && !props.disabled ? `1px solid ${colors.cyan}` : ''};
    border-radius: ${props =>
    props.isIncomplete && !props.disabled ? '6px' : ''};
  }
  input {
    ${props => (props.fontSettings ? props.fontSettings : defaultFontSettings)}
  }
  .ui.fluid.dropdown {
    ${props => (props.fontSettings ? props.fontSettings : defaultFontSettings)}
  }
  .ui.selection.active.dropdown .menu > .item {
    ${props =>
    props.optionFontSettings ? props.optionFontSettings : defaultFontSettings}
  }
  .ui.search {
    ${props => (props.fontSettings ? props.fontSettings : defaultFontSettings)}
  }
  textarea {
    ${props => (props.fontSettings ? props.fontSettings : defaultFontSettings)}
  }
`;
