import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withCookies } from 'react-cookie';
import * as qs from 'query-string';
import { history } from '../../common/helpers';
import { userActions } from '../user.actions';
import Loading from '../../common/components/atoms/Loading';

class AssociateWithSite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: false,
    };
  }

  componentDidMount() {
    const params = qs.parse(this.props.locationName.search);
    const associateToken = params.token;
    if (associateToken) {
      this.setState({ loading: true, error: false });
      this.props.userActions.associateUserToAccount(associateToken).then(
        (res) => {
          this.setState({
            loading: false,
            error: false
          });
          if (this.props.currentUser) {
            this.props.userActions.getUser();
            this.props.userActions.selectAccount(res.account_id);
            history.push('/');
          } else {
            history.push('/login');
          }
        },
        (error) => {
          this.setState({
            loading: false,
            error
          });
          history.push('/login');
          throw error;
        }
      );
    } else {
      this.setState({
        error: 'No Token found'
      });
    }
  }

  render() {
    const { loading, error } = this.state;
    return (
      <div className="full-screen-center">
        {loading && !error && (
          <Loading />
        )}
        {!loading && error && (
          error
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActions, dispatch)
});

export default withCookies(
  connect(
    null,
    mapDispatchToProps
  )(AssociateWithSite)
);
