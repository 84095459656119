export const AutomaticAlarmConstants = {
  GET_MACHINES_ALARM: 'GET_MACHINES_ALARM',
  GET_MACHINES_ALARM_REQUEST: 'GET_MACHINES_ALARM_REQUEST',
  GET_MACHINES_ALARM_SUCCESS: 'GET_MACHINES_ALARM_SUCCESS',
  GET_MACHINES_ALARM_FAILURE: 'GET_MACHINES_ALARM_FAILURE',
  RESET_STATE: 'RESET_STATE'
};

export const getListHeaders = editMode => ([
  {
    name: 'name',
    label: 'Asset',
  },
  {
    name: 'area_name',
    label: 'Area',
  },
  {
    name: 'base_graph',
    label: 'Base Graph',
    noSorter: true
  },
  {
    name: 'warning_factor',
    label: 'WarningX',
    noSorter: true,
    alignCenter: !editMode
  },
  {
    name: 'critical_factor',
    label: 'CriticalX',
    noSorter: true,
    alignCenter: !editMode
  },
  {
    name: 'threshold_type',
    label: 'Threshold Type',
    noSorter: true
  },
  {
    name: 'parameter',
    label: 'Parameter',
    noSorter: true
  },
  {
    name: 'alarm_completion_percent',
    label: 'Status',
    alignCenter: true,
  },
]);


export const AlarmListcolumnSizes = [20, 12.5, 12.5, 8, 8, 13, 17.5, 8.5];

export const MAX_MACHINES_PER_REQUEST = 25;

export const ParameterTypeEnum = {
  VIBRATION_RMS_VELOCITY: 'rms_velocity',
  VIBRATION_DEMOD_RMS: 'vibration_demod_rms_acceleration',
  VIBRATION_ACCELERATION_RMS: 'rms_acceleration',
  CURRENT_RMS: 'rms_current',
  TEMPERATURE: 'temperature',
  PRESSURE: 'pressure',
  ULTRASOUND: 'ultrasound'
};

export const ParameterOptions = [
  {
    text: 'RMS Velocity',
    value: ParameterTypeEnum.VIBRATION_RMS_VELOCITY,
    shortHand: 'RMS Velocity'
  },
  {
    text: 'Demod RMS Acceleration',
    value: ParameterTypeEnum.VIBRATION_DEMOD_RMS,
    shortHand: 'Demod RMS Acc'
  },
  {
    text: 'RMS Acceleration',
    value: ParameterTypeEnum.VIBRATION_ACCELERATION_RMS,
    shortHand: 'RMS Acc'
  },
  {
    text: 'RMS Current',
    value: ParameterTypeEnum.CURRENT_RMS,
    shortHand: 'RMS Current'
  },
  {
    text: 'Temperature',
    value: ParameterTypeEnum.TEMPERATURE,
    shortHand: 'Temperature'
  },
  {
    text: 'Pressure',
    value: ParameterTypeEnum.PRESSURE,
    shortHand: 'Pressure'
  },
  {
    text: 'Ultrasound',
    value: ParameterTypeEnum.ULTRASOUND,
    shortHand: 'Ultrasound'
  }
];

export const BaseGraphOptions = [
  {
    text: '30 Days',
    value: '30_last_days'
  },
  {
    text: '60 Days',
    value: '60_last_days'
  },
  {
    text: '90 Days',
    value: '90_last_days'
  },
  {
    text: 'Baseline',
    value: 'from_baseline'
  }
];

export const ThresholdOptions = [
  {
    text: 'Multiplier',
    value: 'multiplier'
  },
  {
    text: 'Std Dev',
    value: 'standard_deviation'
  },
  {
    text: 'Offset',
    value: 'offset'
  },
];

export const StatusOptions = [
  {
    text: '0 - 25 %',
    value: 1,
    range: [0, 25]
  },
  {
    text: '25 - 50 %',
    value: 2,
    range: [25, 50]
  },
  {
    text: '50 - 75 %',
    value: 3,
    range: [50, 75]
  },
  {
    text: '75 - 100 %',
    value: 4,
    range: [75, 100]
  }
];

export const MESSAGE = {
  applySettings: 'Applying Settings...',
  incompleteParameters: 'Incomplete Parameters',
  incompleteSettingsError: 'Some of the settings are not configured correctly.',
  multiSetText: 'Same settings will be applied to all the selected assets.',
  noAssets: 'No Assets found.',
  noParameter: 'No parameter found.',
  noParameterError: 'Uncheck this asset',
  progressMessage: 'Alarm thresholds are being computed in the background. You can safely navigate away. The status will be updated once thresholds are computed.',
  progressTitle: 'Thresholds computation in progress...',
  required: 'Required',
  warningGreaterThanCriticalError: 'WarningX < CriticalX',
};
