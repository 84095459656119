import * as d3 from 'd3';
import colors from '../../../styles/colors';

// container for special rules regarding an svg
export const createDefs = (node, chartName) =>
  d3.select(node)
    .append('defs')
    .attr('class', chartName);

// determines an area on which an svg can be drawn, is set on from css rules
export const addClipPath = (node, chartName) =>
  d3.select(`defs.${chartName}`)
    .append('clipPath')
    .attr('id', `${chartName}-clip`)
    .attr('class', `${chartName}`)
    .append('rect')
    .attr('width', node.getBoundingClientRect().width)
    .attr('height', node.getBoundingClientRect().height);

// a linear gradient for different y values on a dataset, applies a color filter
// TODO: remove 0, 10 hardcoded values from healthscore range, maybe get max and min range from yAxis
export const addLinearGradient = (node, yAxis, chartName, assetConditionLevels = 3) => {
  d3.select(`defs.${chartName}`)
    .append('linearGradient')
    .attr('id', 'line-gradient')
    .attr('class', `${chartName}`)
    .attr('gradientUnits', 'userSpaceOnUse')
    .attr('x1', 0)
    .attr('y1', yAxis(0))
    .attr('x2', 0)
    .attr('y2', yAxis(10))
    .selectAll('stop')
    .data(assetConditionLevels === 5 ? [
      { offset: '0%', color: colors.critical },
      { offset: '36.5%', color: colors.warning },
      { offset: '48.5%', color: colors.moderate },
      { offset: '62.5%', color: colors.slight },
      { offset: '100%', color: colors.normal }
    ] : [
      { offset: '0%', color: colors.critical },
      { offset: '50%', color: colors.warning },
      { offset: '100%', color: colors.normal }
    ])
    .enter()
    .append('stop')
    .attr('offset', d => d.offset)
    .attr('stop-color', d => d.color);
};
