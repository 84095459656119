import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from 'common/components/atoms/Button';
import { PAGE_STATE } from '../constants/CompareTags.constants';

const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between'};
  align-items: center;
  width: 100%;
  margin-top: ${props => props.marginTop ? props.marginTop : ''};
`;

const FooterStatusContainer = styled.div`
  font-weight: bold;
`;

const ModalFooter = ({
  statusContent,
  onCancel,
  onContinue,
  onBack,
  onDelete,
  proceed,
  pageState,
  marginTop,
  saveDisabled,
  isEditMode,
  justifyContent
}) => (
  <ModalFooterContainer marginTop={marginTop} justifyContent={justifyContent}>
    {isEditMode && (
      <Button
        danger
        onClick={onDelete}
        style={{ marginLeft: '0' }}
      >
        DELETE
      </Button>
    )}
    {statusContent && (
      <FooterStatusContainer id="footer-status">
        {statusContent}
      </FooterStatusContainer>
    )}
    {pageState === PAGE_STATE.SELECT_ASSETS && (
      <div>
        <Button id="footer-cancel" cancel onClick={onCancel}>
          CANCEL
        </Button>
        <Button id="footer-generate-continue" margin="0 0 0 1rem" onClick={onContinue} disabled={!proceed}>
          CONTINUE
        </Button>
      </div>
    )}
    {pageState === PAGE_STATE.SELECT_TAGS && (
      <div>
        <Button id="footer-back" cancel onClick={onBack}>
          BACK
        </Button>
        <Button id="footer-generate-continue" margin="0 0 0 1rem" onClick={onContinue} disabled={saveDisabled}>
          CONTINUE
        </Button>
      </div>
    )}
    {pageState === PAGE_STATE.CHART_PREVIEW && (
      <div>
        <Button id="footer-cancel" cancel onClick={onCancel}>
          CANCEL
        </Button>
        <Button id="footer-back" cancel onClick={onBack}>
          BACK
        </Button>
        <Button id="footer-generate-continue" margin="0 0 0 1rem" onClick={onContinue} disabled={saveDisabled} >
          SAVE
        </Button>
      </div>
    )}
  </ModalFooterContainer>
);

ModalFooter.propTypes = {
  statusContent: PropTypes.string,
  marginTop: PropTypes.string,
  pageState: PropTypes.string.isRequired,
  saveDisabled: PropTypes.bool,
  isEditMode: PropTypes.bool,
  proceed: PropTypes.bool,
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  onBack: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
};

ModalFooter.defaultProps = {
  statusContent: '',
  marginTop: '',
  isEditMode: false,
  proceed: false,
  saveDisabled: false,
  onCancel: () => {},
  onContinue: () => {},
  onBack: () => {},
};

export default ModalFooter;
