import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputField_T from 'common/components/atoms/InputField';
import Filter_T from 'common/components/molecules/Filter';

export const FILTER_LEFT_MARGIN = 28;

const ListSearchFilters = styled.div.attrs({
  className: 'search-filters'
})`
  background-color: ${props => props.theme.colors.white};
  height: ${props => props.height || 'auto'};
  ${props => props.showBorderBottom &&
    `border-bottom: 1px solid ${props.theme.colors.borderGray};`}
  padding: 10px 20px;
  flex-basis: 100%;
  display: flex;
  max-width: 100%;
  > ${InputField_T} {
    margin-bottom: 0px;
    svg {
      color: ${props => props.theme.colors.lightGray};
    }
  }
  ${Filter_T} {
    margin-left: ${FILTER_LEFT_MARGIN}px;
  }
`;

ListSearchFilters.propTypes = {
  showBorderBottom: PropTypes.bool
};

ListSearchFilters.defaultProps = {
  showBorderBottom: true
};

export default ListSearchFilters;
