import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as _ from 'lodash';

import FlexContainer from 'common/components/atoms/FlexContainer';
import Modal from 'common/components/organisms/Modal';
import List from 'common/components/molecules/List';
import Button from 'common/components/atoms/Button';
import Filter from 'common/components/molecules/Filter';
import SearchFilters from 'common/components/atoms/SearchFilters';
import FilterCountContainer from 'common/components/atoms/FilterCountContainer';
import Radiobutton from 'common/components/molecules/Radiobutton';
import MsgContainer from 'common/components/atoms/MsgContainer';
import { isFilterApplied } from 'common/utils';
import InputField from 'common/components/atoms/InputField';
import OutsideAlerter from 'common/OutsideAlerter';
import Calendar from 'common/components/molecules/ReactCalendar';
import { mapDayToString } from 'common/constants';
import H2 from 'common/typography/H2/H2';
import CrossSvg from 'common/images/CrossSvg';
import * as erroneousReadingsActions from '../erroneousReadings.actions';
import SensorErroneousReadingsItem from './SensorErroneousReadingsItem';

const CancelButton = styled(Button)`
  margin-right: 20px
`;
const SensorsContainer = styled(FlexContainer).attrs({ direction: 'row' })` 
  background-color: ${props => props.theme.colors.white};
  overflow: auto;
`;

const ListContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  position: relative;
  overflow: auto;
  width: ${props => props.width};
  height: 60vh;
  margin-left: 20px;
  ${props =>
    props.width === '30%' &&
    `border-right: 2px solid ${props.theme.colors.greyL};`}
  ${props =>
    props.minWidth && `min-width: ${props.minWidth};`}
  `;

const FilterItem = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  text-align: left;
  width: max-content;
  min-width: 150px;
  border: none;
  outline: none;
  background-color: white;
  font-family: 'Petasense Open Sans';
  font-weight: 600;
  color: ${props => props.theme.colors.lightGray};
  ${props => props.selected && `color: ${props.theme.colors.black};`}
  &:hover {
    color: ${props => props.theme.primaryColor};
    background-color: #f7f7f7;
  }
  &:first-of-type {
    padding-top: 1em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    padding-bottom: 1em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  span {
    margin-left: 0.5em;
  }
`;

const DateSelector = styled.div`
  height: 100%;
  display: inline-block;
  width: 250px;
  vertical-align: top;
  padding: 10px 15px;
  box-sizing: border-box;
  ${InputField} {
    margin-bottom: 13.5px;
  }
  input {
    font-size: 1em;
    padding: .57857143em 1em;
  }
  label {
    font-weight: 600;
    margin-bottom: 3px;
  }
`;

const CalenderContainer = styled.div`
  position: absolute;
  z-index: 3;
  width: 220px;
  top: ${props => props.to ? '103px' : '33px'};
`;

const SvgContainer = styled(FlexContainer)`
  cursor: pointer;
  padding: 0.5em;
  padding-left: 2em;
`;

class AllSensorReadingsModel extends Component {
  constructor() {
    super();
    this.initialSearchParams = {
      reviewed: [false],
      date: []
    };
    this.state = {
      searchParams: {
        ...this.initialSearchParams,
      },
      dateExpanded: false,
      customDayFrom: false,
      customDayTo: false,
      filtersOpen: {
        reviewed: false,
        date: false
      },
      reviewedOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ]
    };
    this.dateOptions = [
      {
        text: 'Last 7 Days',
        value: '7',
        name: 'Last 7 Days'
      },
      {
        text: 'Last 30 Days',
        value: '30',
        name: 'Last 30 Days'
      },
      {
        text: 'This Month',
        value: 'thisMonth',
        name: 'This Month'
      },
      {
        text: 'Last Month',
        value: 'lastMonth',
        name: 'Last Month'
      },
      {
        text: 'Custom',
        value: 'custom',
        name: 'Custom'
      }
    ];
  }

  componentDidMount() {
    const { sensor_id } = this.props;
    const { setSensorErroneousReadingsFilters } = this.props.erroneousReadingsActions;

    const filters = [
      { name: 'reviewed', op: 'in', value: false },
    ];
    setSensorErroneousReadingsFilters(filters, null, null, sensor_id);
  }

  toggleFilterOpen = (filter) => {
    this.setState(prevState => ({
      filtersOpen: {
        ...prevState.filtersOpen,
        [filter]: !prevState.filtersOpen[filter]
      }
    }));
  };

  changeFilter = (filter, value) => {
    const searchParams = {
      ...this.state.searchParams,
      [filter]: value
    };
    this.setState({
      searchParams
    });
    return this.search(searchParams);
  };

  changeDateFilter = (filter, value) => {
    const searchParams = {
      ...this.state.searchParams,
      [filter]: value,
      date_from: new Date(new Date().setDate(new Date().getDate() - 7)),
      date_to: new Date()
    };
    this.setState({
      searchParams,
      customDayFrom: false,
      customDayTo: false
    });
    return this.search(searchParams);
  };

  clearAllFilters = () => {
    const searchParams = {
      ...this.initialSearchParams
    };
    if (this.state.machineSensors) {
      searchParams.machines = this.state.searchParams.machines;
    }
    this.setState({ searchParams });
    if (this.state.dateExpanded) this.toggleDateExpanded();
    this.search(searchParams);
  }

  toggleDateExpanded = () => {
    this.setState(prevState => ({
      dateExpanded: !prevState.dateExpanded
    }));
  };

  openCustomCalender = (str) => {
    this.setState({
      calenderOpen: str
    });
  };

  closeCalender = () => {
    this.setState({ calenderOpen: null });
  };

  onClickDay = (value, label) => {
    let searchParams = {};
    if (label === 'from') {
      searchParams = {
        ...this.state.searchParams,
        date_from: value
      };
      this.setState({
        searchParams,
        customDayFrom: value,
        calenderOpen: null
      });
    } else if (label === 'to') {
      searchParams = {
        ...this.state.searchParams,
        date_to: value
      };
      this.setState({
        searchParams,
        customDayTo: value,
        calenderOpen: null
      });
    }
    this.search(searchParams);
  };

  clearDate = () => {
    const searchParams = {
      ...this.state.searchParams,
      date_from: new Date(new Date().setDate(new Date().getDate() - 7)),
      date_to: new Date()
    };
    this.setState({
      searchParams,
      customDayFrom: false,
      customDayTo: false
    });
    this.search(searchParams);
  };

  renderFilterOptions = (options, name) => {
    if (_.isEmpty(options)) {
      return (
        <MsgContainer>
          <span>No {name} are available</span>
        </MsgContainer>
      );
    }

    return options.map((option, index) => {
      const selected = _.includes(this.state.searchParams[name], option.value);
      return (
          <FilterItem
            key={index.toString()}
            className="dropdown-item"
            selected={selected}
            onClick={() => {
              let types = [];
              if (!selected) {
                types = [option.value];
              }
              if (option.value === 'custom' || this.state.dateExpanded) {
                this.toggleDateExpanded('dateExpanded');
              }
              this.changeDateFilter(name, types, selected);
            }}
          >
            <Radiobutton value={selected} />
            <span>{option.text}</span>
          </FilterItem>
      );
    });
  };

  search = (params = null) => {
    const { searchParams: stateParams } = this.state;
    const searchParams = params || stateParams;
    const { setSensorErroneousReadingsFilters } = this.props.erroneousReadingsActions;
    let start_date;
    let end_date;

    if (searchParams.date.includes('custom')) {
      start_date = searchParams.date_from;
      end_date = searchParams.date_to;
    } else if (searchParams.date.includes('7')) {
      start_date = new Date(new Date().setDate(new Date().getDate() - 7));
      end_date = new Date();
    } else if (searchParams.date.includes('30')) {
      start_date = new Date(new Date().setDate(new Date().getDate() - 30));
      end_date = new Date();
    } else if (searchParams.date.includes('thisMonth')) {
      start_date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      end_date = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    } else if (searchParams.date.includes('lastMonth')) {
      start_date = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      end_date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    }

    const filters = [
      { name: 'reviewed', op: 'in', value: searchParams.reviewed[0] === undefined ? null : searchParams.reviewed[0] },
    ];
    return setSensorErroneousReadingsFilters(filters, start_date, end_date);
  };

  render() {
    const {
      items
    } = this.props.erroneousReadings;
    const { close } = this.props;
    const { loadMoreErroneousReadings } = this.props.erroneousReadingsActions;
    const props = {
      columnSizes: [50, 50],
      headers: [
        {
          name: 'timestamp',
          label: 'Timestamp'
        },
        {
          name: 'reviewed_by',
          label: 'Reviewed By'
        }
      ],
      maxHeightAfterScroll: 'calc(100vh - 160px)',
      triggerLoadMore: 'calc(100vh - 195px)',
      hasMore: items.hasMore,
      loading: items.loading,
      emptyMsg: 'No results found',
      ItemComponent: SensorErroneousReadingsItem,
      items,
      search: this.search,
      loadMoreItems: loadMoreErroneousReadings
    };

    const reviewedTextValue = this.state.searchParams.reviewed.reduce(
      (acc, id, idx) => {
        const name = this.state.reviewedOptions.filter(r => r.value === id);
        if (idx === 0) return `${name[0].text}`;
        if (idx === 1) return `${acc}, ${name[0].text}...`;
        return acc;
      },
      ''
    );

    const dateTextValue = this.state.searchParams.date.reduce(
      (acc, id, idx) => {
        const name = this.dateOptions.filter(s => s.value === id);
        if (idx === 0) return `${name[0].name}`;
        if (idx === 1) return `${acc}, ${name[0].name}...`;
        return acc;
      },
      ''
    );

    const formatDate = (locale, date) => {
      const day = date.getDay();
      return mapDayToString[day][0];
      // if (day !== 6 && day !== 0) return mapDayToString[day][0];
      // return mapDayToString[day].slice(0, 2);
    };

    return (
    <Modal
      padding="0 20px"
      width="55%"
      height="100%"
      close={close}
      title={`Erroneous Readings for ${this.props.sensor_name}`}
      titleSize="22px"
      headerPadding="3em 1em"
    >
      <div>
        <SearchFilters height="60px">
          {items && (
              <FilterCountContainer>{items.total_count}</FilterCountContainer>
          )}
          <Filter
            title="Reviewed:"
            value={reviewedTextValue}
            open={this.state.filtersOpen.reviewed}
            toggleOpen={() => this.toggleFilterOpen('reviewed')}
          >
              {this.renderFilterOptions(this.state.reviewedOptions, 'reviewed')}
          </Filter>
          <Filter
            title="Date Range:"
            value={dateTextValue}
            open={this.state.filtersOpen.date}
            noScroll
            toggleOpen={() => this.toggleFilterOpen('date')}
          >
            <FlexContainer
              direction="row"
            >
              <FlexContainer
                direction="column"
              >
                {this.renderFilterOptions(this.dateOptions, 'date')}
              </FlexContainer>
              {this.state.dateExpanded && (
              <DateSelector>
                {this.state.calenderOpen === 'from' && (
                  <OutsideAlerter open={this.state.calenderOpen === 'from'} handleClick={this.closeCalender}>
                    <CalenderContainer>
                      <Calendar
                        formatShortWeekday={(locale, date) => formatDate(locale, date)}
                        maxDetail="month"
                        maxDate={this.state.customDayTo || new Date()}
                        showNeighboringMonth={false}
                        onClickDay={value => this.onClickDay(value, 'from')}
                        value={this.state.customDayFrom}
                      />
                    </CalenderContainer>
                  </OutsideAlerter>
                )}
                {this.state.calenderOpen === 'to' && (
                  <OutsideAlerter open={this.state.calenderOpen === 'to'} handleClick={this.closeCalender}>
                    <CalenderContainer to="true">
                      <Calendar
                        formatShortWeekday={(locale, date) => formatDate(locale, date)}
                        maxDetail="month"
                        maxDate={new Date()}
                        minDate={this.state.customDayFrom}
                        value={this.state.customDayTo}
                        showNeighboringMonth={false}
                        onClickDay={value => this.onClickDay(value, 'to')}
                      />
                    </CalenderContainer>
                  </OutsideAlerter>
                )}
                  <FlexContainer direction="row" justifyContent="space-between">
                    <H2>Custom</H2>
                    <SvgContainer
                      onClick={() => this.toggleFilterOpen('date')}
                    >
                      <CrossSvg width={30} height={30} fill="#999B95" />
                    </SvgContainer>
                  </FlexContainer>
                  <InputField label="From" placeholder="Select" readOnly value={this.state.customDayFrom ? this.state.customDayFrom.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalender('from')} />
                  <InputField label="To" placeholder="Select " readOnly value={this.state.customDayTo ? this.state.customDayTo.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalender('to')} />
                  <Button
                    text
                    onClick={this.clearDate}
                    disabled={!this.state.customDayFrom && !this.state.customDayTo}
                  >
                    CLEAR
                  </Button>
              </DateSelector>)
              }
            </FlexContainer>
          </Filter>
          {isFilterApplied(this.state.searchParams) && (
            <Button text onClick={this.clearAllFilters} fontSize="11px" margin="0 20px">
              Clear Filters
            </Button>
          )}
        </SearchFilters>
        <SensorsContainer>
            <ListContainer
              width="100%"
              padding="0px 20px"
              minWidth="45rem"
            >
                <List {...props} />
            </ListContainer>
        </SensorsContainer>
        <FlexContainer justifyContent="right" margintop="15px">
          <CancelButton onClick={close} secondary="#000">Close</CancelButton>
        </FlexContainer>
      </div>
    </Modal>
    );
  }
}

AllSensorReadingsModel.propTypes = {
  close: PropTypes.func.isRequired,
  sensor_id: PropTypes.number.isRequired,
  sensor_name: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  erroneousReadings: state.erroneousReadings.sensorErroneousReadings
});

const mapDispatchToProps = dispatch => ({
  erroneousReadingsActions: bindActionCreators(erroneousReadingsActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllSensorReadingsModel);
