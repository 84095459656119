import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputStyles } from './Input';

export const TextAreaStyled = styled.textarea`
  ${InputStyles}
  resize: none;
  height: ${props => props.height ? `${props.height} !important` : '150px'};
  width: ${props => props.width ? `${props.width}` : 'auto'};
  ${props => props.noFocusBorder && `
    &:focus:not(:disabled) {
      border: 0;
    }
  `}
`;

const TextArea = ({
  error,
  ...rest
}) => (
  <TextAreaStyled error={error} {...rest} />
);

TextArea.propTypes = {
  error: PropTypes.string,
  noFocusBorder: PropTypes.bool
};

TextArea.defaultProps = {
  error: undefined,
  noFocusBorder: false
};

TextArea.displayName = 'TextArea';

export default TextArea;
