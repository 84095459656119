import React from 'react';
import styled from 'styled-components';
import Filter from 'common/components/molecules/Filter';
import FilterItem_T from 'common/components/atoms/FilterItem';
import MsgContainer from 'common/components/atoms/MsgContainer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import * as _ from 'lodash';

import { userActions } from '../../onboarding/user.actions';
import { history } from '../../common/helpers';

const FilterItem = styled(FilterItem_T)`
  min-width: 200px;
`;

const dropdownOptions = accounts =>
  accounts.map((account, index) => ({ key: index, text: account.name, value: account.id }));

class SitesDropdown extends React.Component {
  constructor() {
    super();
    this.state = {
      dropdownOpen: false,
      accounts: [],
      accountSearchKey: ''
    };
  }

  componentDidMount() {
    const { user } = this.props.user;
    const accounts = user ? user.accounts : [];
    this.setState({ accounts: dropdownOptions(accounts) });
  }

  componentWillReceiveProps(nextProps) {
    const { accountSelectionState } = nextProps;
    const prevAccountSelectionState = this.props.accountSelectionState;

    if (accountSelectionState.result && prevAccountSelectionState.loadingId) {
      // go to dashboard...
      history.push('/');
    } else if (accountSelectionState.result === false) {
      toastr.error(accountSelectionState.error);
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.discardAnalystSummary, this.props.discardAnalystSummary) && this.props.discardAnalystSummary) {
      const { selectAccount } = this.props;

      if (this.lastAccount && this.lastAccount !== this.props.user.account_id) {
        selectAccount(this.lastAccount);
        this.props.setDiscardAnalystSummary(false);
      }
    }
  }

  getSelectedAccount = () => {
    const { user } = this.props.user;
    const accounts = user ? user.accounts : null;
    const selectedAccount = accounts.filter(
      account => account.id === (user ? user.account_id : null)
    )[0];
    return selectedAccount;
  }

  handleDropdownChange = (value) => {
    this.lastAccount = null;
    const selectedAccount = this.getSelectedAccount();

    if (selectedAccount.id !== value) {
      const { selectAccount } = this.props;
      if (this.props.checkNewSummaryExists()) {
        this.props.setAnalystSummaryWarning(true);
        this.lastAccount = value;
        return;
      }
      selectAccount(value);
    }
  };

  searchAccountFilter = (value) => {
    const { user } = this.props.user;
    const accounts = user ? user.accounts : [];
    const accountOptions = dropdownOptions(accounts);
    this.setState({
      accounts: accountOptions.filter(u =>
        u.text.toLowerCase().includes(value.toLowerCase())
      ),
      accountSearchKey: value
    });
  };

  renderFilterOptions = (options, name) => {
    if (_.isEmpty(options)) {
      return (
        <MsgContainer>
          <span>No {name} are available</span>
        </MsgContainer>
      );
    }

    return options.map((option, index) => {
      const selectedAccount = this.getSelectedAccount();
      return (
        <FilterItem
          key={index.toString()}
          className="dropdown-item"
          selected={selectedAccount.id === option.value}
          onClick={() => this.handleDropdownChange(option.value)}
        >
          <span className="text">{option.text}</span>
        </FilterItem>
      );
    });
  };

  toggleOpen = () => {
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
  };

  render() {
    const selectedAccount = this.getSelectedAccount();
    return (
      <Filter
        id="SitesDropdown"
        value={selectedAccount && selectedAccount.name}
        open={this.state.dropdownOpen}
        toggleOpen={this.toggleOpen}
        onChangeSearch={this.searchAccountFilter}
        searchValue={this.state.accountSearchKey}
        selectedItems={this.state.accounts.filter(acc => acc.value === selectedAccount.id)}
        customDisplayValue={() => (<span id="account-name">{selectedAccount && selectedAccount.name}</span>)}
        zIndex={1000}
        itemName="account"
        marginTop="7px"
        modalClassName="accounts-list"
        displayItemClassName="display-item"
        autoFocusSearch
      >
        {this.renderFilterOptions(this.state.accounts, 'accounts')}
      </Filter>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    user,
    accountSelectionState: state.accountSelectionState
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      selectAccount: userActions.selectAccount
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SitesDropdown);
