import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';

import * as bearingActions from '../bearing.actions';
import ListItem_T from '../../../../../../../common/components/atoms/ListItem';
import Radiobutton from '../../../../../../../common/components/molecules/Radiobutton';
import DeleteIconSvg from '../../../../../../../common/images/BearingModal/DeleteIconSvg';
import EditIcon from '../../../../../../../common/images/BearingModal/EditIcon';

const ClickContainter = styled.div`
  width: max-content;
  display: inline-flex;
  cursor: pointer;
  span {
    margin-left: 1.5em;
  }
`;

const ListItem = styled(ListItem_T)`
  svg:hover {
    cursor: pointer;
    #delete-icon-stroke {
      stroke: ${props => props.theme.colors.error};
    }
    #edit-icon-stroke {
      stroke: ${props => props.theme.colors.black}
    }
  }
  td {
    font-weight: 600;
  }
  td svg {
    float: right;
  }
  td:first-of-type {
    .editIcon {
      visibility: hidden;
    }
    &:hover {
      .editIcon {
        visibility: visible;
      }
    }
  }
`;

class BearingRowItem extends Component {
  onClick = () => {
    this.props.bearingActions.selectRow(this.props.item.id);
  }

  deleteBearing = (id) => {
    this.props.bearingActions.deleteBearing(id).then((res) => {
      this.props.bearingActions.removeRowFromTable(id);
    }, error => toastr.error(error));
  };

  onEditClick = () => {
    const { edit, item } = this.props;
    this.props.bearingActions.updateBearing(item, item.id).then((res) => {
      edit();
    }, error => toastr.error(error.message));
  };

  render() {
    const {
      item,
      columnSizes,
      selected,
      edit
    } = this.props;
    return (
      <ListItem selectedItem={selected} >
        <td width={`${columnSizes[0]}%`}>
          <ClickContainter onClick={this.onClick}>
            <Radiobutton value={selected} />
            <span>{item.manufacturer}</span>
          </ClickContainter>
          {item.source === 'Custom' &&
            <span className="editIcon" onClick={this.onEditClick}>
              <EditIcon />
            </span>
          }
        </td>
        <td width={`${columnSizes[1]}%`}>
          {item.bearing_no}
        </td>
        <td width={`${columnSizes[2]}%`}>
          {Number.parseFloat(item.bpfi).toFixed(3)}
        </td>
        <td width={`${columnSizes[3]}%`}>
          {Number.parseFloat(item.bpfo).toFixed(3)}
        </td>
        <td width={`${columnSizes[4]}%`}>
          {Number.parseFloat(item.bsf).toFixed(3)}
        </td>
        <td width={`${columnSizes[5]}%`}>
          {Number.parseFloat(item.ftf).toFixed(3)}
        </td>
        <td width={`${columnSizes[6]}%`}>
          {item.source}
          {item.source === 'Custom' &&
            <span onClick={() => this.deleteBearing(item.id)}>
              <DeleteIconSvg />
            </span>
          }
        </td>
      </ListItem>
    );
  }
}

BearingRowItem.propTypes = {
  columnSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  item: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired
};

const mapDispatchToProps = dispatch => ({
  bearingActions: bindActionCreators(bearingActions, dispatch)
});

export default connect(null, mapDispatchToProps)(BearingRowItem);
