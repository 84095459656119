import React, { Component } from 'react';
import ListItem_T from 'common/components/atoms/ListItem';
import Checkbox from 'common/components/atoms/Checkbox';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ListItem = styled(ListItem_T)`
  background-color: #F7F8F6;
`;

class CompareTagsAssetItem extends Component {
  render() {
    const {
      item,
      columnSizes,
      onAssetClick,
      selectedAssets
    } = this.props;

    const selectedItem = selectedAssets.includes(item.id);

    return (
      <ListItem id={`compare-tags-asset-item-${item.id}`} selectedItem={selectedItem}>
        <td width={`${columnSizes[0]}%`}>
          <Checkbox
            id={`compare-tags-asset-checkbox-${item.id}`}
            value={selectedItem}
            onClick={() => onAssetClick(item, selectedItem)}
            disabled={!selectedItem && selectedAssets.length >= 10}
          />
        </td>
        <td id={`compare-tags-asset-name-${item.id}`} className="compare-tags-asset-name" width={`${columnSizes[1]}%`}>
          {item.name}
        </td>
        <td width={`${columnSizes[2]}%`}>
          {item.area_name ? item.area_name : '--'}
        </td>
      </ListItem>
    );
  }
}

CompareTagsAssetItem.propTypes = {
  columnSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedAssets: PropTypes.arrayOf(PropTypes.number).isRequired,
  item: PropTypes.object.isRequired,
  onAssetClick: PropTypes.func.isRequired,
};

export default CompareTagsAssetItem;
