
import { combineReducers } from 'redux';
import { templateConstants } from 'home/HomePage/constants/constants';


const taskTemplateInitialState = {
  list: [],
  hasMore: false,
  metadata: {},
  selectedTaskTemplate: null,
  details: {},
  loading: true
};

const template = {
  id: -1,
  name: 'New Task Template'
};

const templates = (state = taskTemplateInitialState, action) => {
  switch (action.type) {
    case templateConstants.GET_TASK_TEMPLATE_METADATA_SUCCESS:
      return {
        ...state,
        metadata: action.data,
        loading: false
      };
    case templateConstants.GET_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case templateConstants.GET_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };

    case templateConstants.GET_TEMPLATE_LIST_SUCCESS: {
      const templates = action.data;
      return {
        ...state,
        list: templates,
        selectedTaskTemplate: null,
        loading: false,
      };
    }

    case templateConstants.GET_TEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.data
      };
    case templateConstants.UPDATE_TASK_TEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        list: state.list.map(r => r.id === action.id ? { ...r, ...action.data } : r),
        details: {
          ...state.details,
          ...action.data,
        }
      };
    case templateConstants.SAVE_TASK_TEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.data
      };
    case templateConstants.SELECT_TASK_TEMPLATE:
      return {
        ...state,
        selectedTaskTemplate: action.id === state.selectedTaskTemplate ? null : action.id
      };
    case templateConstants.ADD_NEW_TASK_TEMPLATE_TO_LIST:
      return {
        ...state,
        list: [{ ...template }, ...state.list]
      };
    case templateConstants.REMOVE_TASK_TEMPLATE_FROM_LIST:
      return {
        ...state,
        list: state.list.filter(template => template.id !== action.id)
      };
    case templateConstants.ADD_TASK_TEMPLATE_TO_LIST:
      return {
        ...state,
        list: state.list.map(template => template.id === -1 ? action.data : template)
      };
    case templateConstants.CLEAR_TASK_TEMPLATES:
      return {
        ...state,
        list: []
      };
    default:
      return state;
  }
};

const searchParams = (state = {}, action) => {
  switch (action.type) {
    case templateConstants.SET_TASK_TEMPLATE_SEARCH_PARAMS:
      return action.searchParams;
    case templateConstants.CLEAR_TASK_TEMPLATE_SEARCH_PARAMS:
      return {};
    default:
      return state;
  }
};

const sorter = (state = {}, action) => {
  switch (action.type) {
    case templateConstants.SET_TASK_TEMPLATE_SORTER:
      return {
        sorter: action.sorter
      };
    default:
      return state;
  }
};

const paginater = (state = {}, action) => {
  switch (action.type) {
    case templateConstants.SET_TASK_TEMPLATE_PAGINATER:
      return {
        page: action.page
      };
    default:
      return state;
  }
};

export const taskTemplates = combineReducers({
  templates,
  paginater,
  searchParams,
  sorter
});
