import styled from 'styled-components';

const SpanLabel = styled.span`
  font-weight: ${props => props.fontWeight || 800};
  width: ${props => props.width || '100%'};
  margin-bottom: 9px;
  font-size: ${props => props.fontSize || '12px'};
  ${props => props.textAlign && `text-align: ${props.textAlign};`}
`;

export default styled(SpanLabel)``;
