import React from 'react';
import styled from 'styled-components';

import FooterFlex from 'common/components/atoms/FooterFlex';

const Footer = ({ footer, showWidth }) => (
  <FooterFlex width={showWidth}>
    {typeof footer === 'function' ? footer()
      : footer.items.map(Item =>
        <Item.Tag {...Item.props}>{Item.children}</Item.Tag>)}
  </FooterFlex>
);

export default styled(Footer)``;
