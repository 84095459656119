import { reportActionConstants } from 'home/Reports/actions/reports.constants';
import _ from 'lodash';

export const reportLoadersReducer = (state = {}, action) => {
  switch (action.type) {
    case reportActionConstants.INIT_REPORTS: {
      return {};
    }
    case reportActionConstants.SET_SUBMITTING_REPORT: {
      return {
        ...state,
        submittingReport: action.value,
      };
    }
    case reportActionConstants.CLEAR_SUBMITTING_REPORT: {
      const nextState = _.cloneDeep(state);
      delete nextState.submittingReport;
      return {
        ...nextState,
      };
    }
    case reportActionConstants.SET_CAPTURING_CHARTS: {
      return {
        ...state,
        capturingCharts: action.value,
      };
    }
    case reportActionConstants.CLEAR_CAPTURING_CHARTS: {
      const nextState = _.cloneDeep(state);
      delete nextState.capturingCharts;
      return {
        ...nextState,
      };
    }
    default:
      return state;
  }
};
