import React from 'react';
import PropTypes from 'prop-types';

const DragHandlerIcon = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 8 13">
    <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3-6-3-Machines-Expanded-New_note-Empty" transform="translate(-1231.000000, -1139.000000)">
            <g id="charts" transform="translate(80.000000, 147.000000)">
                <g id="3" transform="translate(0.000000, 970.000000)">
                    <g id="top" transform="translate(20.000000, 20.000000)">
                        <g id="options" transform="translate(1022.000000, 0.000000)">
                            <g id="move-icon" transform="translate(108.000000, 0.000000)">
                                <rect id="bounds" x="0" y="0" width="10" height="16" />
                                <g id="icon" transform="translate(1.000000, 2.000000)" fill={fill}>
                                    <path d="M0,6.5 C0,5.67119565 0.671195652,5 1.5,5 C2.32880435,5 3,5.67119565 3,6.5 C3,7.32880435 2.32880435,8 1.5,8 C0.671195652,8 0,7.32880435 0,6.5 Z" id="Path" />
                                    <path d="M0,11.5 C0,10.6711957 0.671195652,10 1.5,10 C2.32880435,10 3,10.6711957 3,11.5 C3,12.3288043 2.32880435,13 1.5,13 C0.671195652,13 0,12.3288043 0,11.5 Z" id="Path" />
                                    <path d="M1.5,0 C0.671195652,0 0,0.671195652 0,1.5 C0,2.32880435 0.671195652,3 1.5,3 C2.32880435,3 3,2.32880435 3,1.5 C3,0.671195652 2.32880435,0 1.5,0 Z" id="Path" />
                                    <path d="M6.5,0 C5.67119565,0 5,0.671195652 5,1.5 C5,2.32880435 5.67119565,3 6.5,3 C7.32880435,3 8,2.32880435 8,1.5 C8,0.671195652 7.32880435,0 6.5,0 Z" id="Path" />
                                    <path d="M5,6.5 C5,5.67119565 5.67119565,5 6.5,5 C7.32880435,5 8,5.67119565 8,6.5 C8,7.32880435 7.32880435,8 6.5,8 C5.67119565,8 5,7.32880435 5,6.5 Z" id="Path" />
                                    <path d="M5,11.5 C5,10.6711957 5.67119565,10 6.5,10 C7.32880435,10 8,10.6711957 8,11.5 C8,12.3288043 7.32880435,13 6.5,13 C5.67119565,13 5,12.3288043 5,11.5 Z" id="Path" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
);

DragHandlerIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

DragHandlerIcon.defaultProps = {
  width: 8,
  height: 13,
  fill: '#999B95'
};

export default DragHandlerIcon;
