import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { validateAndSwitchAccountWithCallback } from 'home/AssetHierarchy/actions/assetDetails.actions';
import { FlexContainer } from '../components/atoms';
import { humanize } from '../helpers';

const ChartContainer = styled(FlexContainer).attrs({
  alignItems: 'center',
  direction: 'column'
})`
  height: 100%;
`;

const TITLE_HEIGHT = 40;
const Title = styled.div`
  color: ${props => props.theme.colors.darkGray};
  font-weight: 600;
  width: 100%;
  padding: 1em 0 0 1em;
  height: ${TITLE_HEIGHT}px;
`;

const NumberContainer = styled(FlexContainer).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})`
  color: ${props => props.color};
  height: 100%;

  ${props => props.isInteractive && `
    cursor: pointer;
  `}

  .big-number {
    ${props => props.theme.fonts.boldLabel}
    height: 60px;
    font-size: ${props => props.numberSize}px;
    padding: ${props => props.numberPadding}px;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .number-label {
    ${props => props.theme.fonts.boldLabel}
    font-size: ${props => props.labelSize}px;
    text-align: center;
  }
`;

function BigNumber({ title, data, theme, numberSize, labelSize, numberPadding, handleClick, switchAccount, isInteractive }) {
  return (
    <ChartContainer>
      {title && <Title className="title">{title}</Title>}
      <FlexContainer height="100%" width="100%" justifyContent="center">
        {Object.keys(data).map((key, idx) => (
          <NumberContainer
            key={key}
            color={idx % 2 === 0 ? theme.colors.greyXXD : theme.colors.greyXD}
            numberSize={numberSize}
            labelSize={labelSize}
            numberPadding={numberPadding}
            isInteractive={isInteractive}
            onClick={handleClick ? () => handleClick(key, switchAccount) : () => {}}
          >
            <span className="big-number">{data[key]}</span>
            <span className="number-label">{humanize(key)}</span>
          </NumberContainer>
        ))}
      </FlexContainer>
    </ChartContainer>
  );
}

BigNumber.propTypes = {
  numberSize: PropTypes.number,
  labelSize: PropTypes.number,
  numberPadding: PropTypes.number,
};

BigNumber.defaultProps = {
  numberSize: 60,
  labelSize: 16,
  numberPadding: 20,
};

const mapDispatchToProps = dispatch => ({
  switchAccount: bindActionCreators(validateAndSwitchAccountWithCallback, dispatch)
});

export default connect(null, mapDispatchToProps)(withTheme(BigNumber));
