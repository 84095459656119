/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import InputField from 'common/components/atoms/InputField';
import Label from 'common/typography/Label/Label';
import FlexContainer from 'common/components/atoms/FlexContainer';
import colors from 'common/styles/colors';

const InputFieldBold = styled(InputField).attrs({ fontWeight: 800 })`
  margin-bottom: 0;
  ${FlexContainer} {
    border: ${props => (props.isIncomplete && !props.disabled) ? `1px solid ${colors.cyan}` : ''};
    border-radius: ${props => (props.isIncomplete && !props.disabled) ? '6px' : ''};

  }
  ${Label} {
    :after {
      content: ${props => (props.isIncomplete && !props.disabled) ? '\'*\'' : ''};
    }
  }
`;

export default styled(InputFieldBold)``;
