import moment from 'moment';
import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse, humanize } from 'common/helpers';
import { isPartnerAdmin, isPetasenseAdmin, isPetasenseViewer } from 'common/rbac/util';
import { convertTimestringsToDateObjects, round } from 'common/utils';
import { widgetConstants, keyMetadataMap, customLabels } from '../constants/widgets.constants';

const chartTypeFormatterMap = {
  donut: formatDataForDonut,
  trend: formatDataForTrend,
  bar: formatDataForTrend,
  list: formatDataForList,
  count: formatDataForCount,
  metrics: formatDataForMetrics,
  table: formatDataForTable
};

function formatDataForList(res) {
  return [res.items, null];
}

function formatDataForTable(res) {
  return [res, null];
}

function formatDataForMetrics(res, _metadata, filters) {
  return [res.metrics, filters];
}

function formatDataForCount(res, metadata, filters) {
  const data = {};
  ((metadata && metadata.sortOrder) || Object.keys(res)).forEach((key) => {
    if (res[key] >= 1000) {
      data[key] = `${round(res[key] / 1000, 1)}k`;
    } else {
      data[key] = `${res[key]}`;
    }
  });

  if (filters === undefined) {
    filters = metadata.filters;
  }
  return [data, filters];
}

function formatDataForDonut(res, metadata) {
  let dataList = [];
  if (metadata.sortOrder) {
    dataList = metadata.sortOrder.map(item => ({ value: res[item], label: customLabels[item] || humanize(item) }));
  } else {
    Object.keys(res).forEach((key) => {
      if (key !== 'other') dataList.push({ label: key, value: res[key] });
    });
    if (res.other) dataList.push({ label: 'Other', value: res.other });
  }
  return [dataList, metadata.filters];
}

function formatDataForTrend(res) {
  const data = [['Date', 'Count']];
  const yData = res.counts;
  const xData = convertTimestringsToDateObjects(res.timestamps);
  if (!yData || !xData) return data;
  for (let i = 0; i < yData.length; i++) {
    data[i + 1] = [moment(xData[i]).format('MMM DD'), yData[i]];
  }
  return [data, null];
}

export const widgetActions = {
  getWidgetsMetadata,
  getWidgetData
};

function getWidgetsMetadata() {
  function request() {
    return { type: widgetConstants.GET_WIDGET_METADATA_REQUEST };
  }
  function success(object) {
    return { type: widgetConstants.GET_WIDGET_METADATA_SUCCESS, object };
  }
  function failure(error) {
    return { type: widgetConstants.GET_WIDGET_METADATA_FAILURE, error };
  }

  return (dispatch, getState) => {
    dispatch(request());

    return handleResponse(axiosInstance.get(ENDPOINT.WIDGETS_METADATA)).then(
      (res) => {
        const metadata = {};
        Object.keys(res).forEach((key) => {
          let feMetadata = keyMetadataMap[key];
          if (keyMetadataMap[key] && keyMetadataMap[key].hasSublevels) {
            const sublevelPath = keyMetadataMap[key].sublevelPath.split('.');
            const sublevelValue = sublevelPath.reduce((acc, curr) => acc[curr], getState());
            feMetadata = keyMetadataMap[key][sublevelValue];
          }
          if (feMetadata && feMetadata.colors && feMetadata.colors.length === 1 && getState().companyReducer.partner) {
            feMetadata.colors = [getState().companyReducer.partner.theme.primaryColor];
          }
          metadata[key] = {
            ...res[key],
            ...(feMetadata || {}),
            title: res[key].widget_metadata.display_name,
            account_id: getState().user.user.account_id !== null ? getState().user.user.account_id : undefined
          };
          const user = getState().user.user;
          if (user && !(isPetasenseAdmin(user) || isPetasenseViewer(user) || isPartnerAdmin(user))) {
            Object.keys(res).forEach((key) => {
              if (res[key].widget_metadata.petasense_admin_only) {
                delete metadata[key];
              }
            });
          }
        });
        dispatch(success(metadata));
      },
      (error) => {
        dispatch(failure(error.message));
      }
    );
  };
}

function getWidgetData(params, metadata, chartType) {
  function request() {
    return { type: widgetConstants.GET_WIDGET_DATA_REQUEST };
  }
  function success() {
    return { type: widgetConstants.GET_WIDGET_DATA_SUCCESS };
  }
  function failure() {
    return { type: widgetConstants.GET_WIDGET_DATA_FAILURE };
  }

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.WIDGET_DATA, { params })).then(
      (res) => {
        dispatch(success());
        let data; let filterOptions;
        if (metadata.dataFormatter) {
          [data, filterOptions] = metadata.dataFormatter(res, metadata);
        } else {
          [data, filterOptions] = chartTypeFormatterMap[chartType](res, metadata, params.filters);
        }
        return [data, filterOptions];
      },
      (error) => {
        dispatch(failure(error.message));
        throw error;
      }
    );
  };
}
