import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import FlexContainer from 'common/components/atoms/FlexContainer';
import ReasonItem from 'home/Machines/components/ReasonItem';
import SeverityMark from 'common/OnboardingComponents/SeverityMark';
import ListItem_T from 'common/components/atoms/ListItem';

const ListItem = styled(ListItem_T)`
  td {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    & p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    & p:hover {
      overflow: visible; 
      white-space: normal;
    }
  }
  td:first-of-type {
    a:hover {
      font-weight: 600;
      color: ${props => props.theme.primaryColor} 
    }
  }
`;

class AttentionMachinesItem extends Component {
  render() {
    const {
      item,
      columnSizes,
      onMachineSelect
    } = this.props;
    return (
      <ListItem tdPadding="7px">
        <td width={`${columnSizes[0]}%`}>
          <Link className="ps-hover-pointer" onClick={() => onMachineSelect(item.id)} to={`/machines/${item.id}`}>
            <FlexContainer marginbottom="8px">
              <SeverityMark machine={item} showTimeInfo={false} />
              <p>{item.name}</p>
            </FlexContainer>
          </Link>
        </td>
        <td width={`${columnSizes[1]}%`}>
          <ReasonItem reason={item.reason_for_attention} metadata={item.ara_metadata} machineId={item.id} />
        </td>
      </ListItem>
    );
  }
}

AttentionMachinesItem.propTypes = {
  onMachineSelect: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default AttentionMachinesItem;
