import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import LoadingSvg from 'common/components/atoms/Loading';

import * as machineOverviewActions from '../../../MachineDetails/MachineOverview/actions/machineOverview.actions';

const GraphicsContainer = styled.div`
  border: 1px solid #D8DCD3;
  border-radius: 4px;
  margin-bottom: 1.5em;
  padding: 2em;
  min-width: max-content;
`;

const AssetDiv = styled.div`
  display: flex;
  align-items: flex-end;
  width: max-content;
  margin: 0 auto;
`;

const INCREASE_SVG_SIZE_PERCENT = 0.3;

const ComponentGraphics = (props) => {
  const svgDivRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initializeSvg();
  }, [props]);

  const initializeSvg = () => {
    const { getComponentSVG } = props.machineOverviewActions;
    let componentsLoaded = 0;
    setLoading(true);
    svgDivRef.current.innerHTML = '';
    props.components.forEach((component) => {
      if (props.graphics.loading.includes(`${component.component_type}_horizontal`)) return;
      if (props.graphics.error[component.component_type]) {
        setLoading(false);
        return;
      }
      if (props.graphics.data[`${component.component_type}_horizontal`]) {
        const oParser = new DOMParser();
        const oDOM = oParser.parseFromString(props.graphics.data[`${component.component_type}_horizontal`], 'image/svg+xml');
        attachComponentSVGToDOM(oDOM, component);
        componentsLoaded++;
        if (componentsLoaded === props.components.length) {
          setLoading(false);
        }
      } else getComponentSVG(component.component_type, 'horizontal');
    });
  };

  const attachComponentSVGToDOM = (componentXml, component) => {
    if (!svgDivRef.current) return;
    const componentXMLNode = document.importNode(componentXml.documentElement, true);
    const compNodeSelection = d3.select(componentXMLNode);
    const viewBox = compNodeSelection.attr('viewBox');
    const width = viewBox.split(' ')[2];
    const height = viewBox.split(' ')[3];
    const newWidth = Number(width) * (1 + INCREASE_SVG_SIZE_PERCENT);
    const newHeight = Number(height) * (1 + INCREASE_SVG_SIZE_PERCENT);
    const newWidthString = props.svgWidth || `${newWidth}px`;
    const newHeightString = props.svgHeight || `${newHeight}px`;
    compNodeSelection.attr('width', newWidthString);
    compNodeSelection.attr('height', newHeightString);
    compNodeSelection.style('order', component.position);
    const moteIds = getMotesIds(component.component_type);
    moteIds.forEach(id => compNodeSelection.select(`#${id}`).style('visibility', 'hidden'));
    svgDivRef.current.appendChild(componentXMLNode);
  };

  const locArray = ['drive', 'free', 'input', 'output'];
  const orientations = ['tar', 'tra'];
  const getMotesIds = (comp_type) => {
    const moteIds = [];
    locArray.forEach((loc) => {
      orientations.forEach((o) => {
        moteIds.push(`${comp_type}_${loc}_end_${o}_mote`);
      });
    });
    return moteIds;
  };

  return (
    <GraphicsContainer>
      {loading && (
        <LoadingSvg />
      )}
      <AssetDiv innerRef={svgDivRef} />
    </GraphicsContainer>
  );
};

const mapStateToProps = state => ({
  graphics: state.machineDetails.graphics
});

const mapDispatchToProps = dispatch => ({
  machineOverviewActions: bindActionCreators(machineOverviewActions, dispatch)
});

ComponentGraphics.propTypes = {
  components: PropTypes.array.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentGraphics);
