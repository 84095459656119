
import { combineReducers } from 'redux';
import { AccountsTypes } from '../constants/account.constants';


const initialState = {
  hasMore: true,
  object: [],
  page: 1,
  total_count: 0,
  loading: false,
  listLoading: false,
  searchkey: '',
  error: null,
};

export const accountsList = (state = initialState, action) => {
  switch (action.type) {
    case AccountsTypes.GET_ACCOUNTSLIST_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case AccountsTypes.GET_ACCOUNTSLIST_DATA_SUCCESS: {
      let accountsListArray = [];
      if (action.all === true || action.page === 1) {
        accountsListArray = action.data;
      } else {
        accountsListArray = [...state.object, ...action.data];
      }
      return {
        ...state,
        hasMore: action.page < action.total_pages,
        object: accountsListArray,
        page: action.page,
        total_count: action.total_count,
        loading: false,
        listLoading: false,
        error: null,
      };
    }
    case AccountsTypes.GET_ACCOUNTSLIST_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        listLoading: false,
        error: action.error,
      };
    case AccountsTypes.INCREASE_ACCOUNTSLIST_PAGE:
      return {
        ...state,
        listLoading: true,
        page: state.page + 1,
      };

    default:
      return state;
  }
};

export const accountsSorter = (state = { sorter: null }, action) => {
  switch (action.type) {
    case AccountsTypes.SET_ACCOUNTS_SORTER:
      return {
        sorter: action.sorter
      };
    default:
      return state;
  }
};


export const accounts = combineReducers({
  accountsList,
  accountsSorter
});
