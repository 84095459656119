import { combineReducers } from 'redux';
import { reducer as toastReducer } from 'react-redux-toastr';
import { reducer as formReducer } from 'redux-form';

import { AutomaticAlarmReducer } from 'home/AutomaticAlarms/reducers';
import { machinesAnalyticsReducer } from 'home/MachinesAnalytics/machinesAnalytics.reducer';
import { taskModal } from 'home/HomePage/reducers/tasks.reducers';
import { repairModal } from 'home/HomePage/reducers/repairs.reducers';
import { noteModal } from 'home/HomePage/reducers/notes.reducers';
import { attachmentsReducer } from 'home/HomePage/reducers/attachments.reducer';
import { dashboards } from 'home/Dashboard/reducers/dashboardV2.reducer';
import { widgets } from 'home/Widget/reducers/widgets.reducer';
import rul from 'home/Machines/MachineDetails/Health/reducers/rul.reducers';
import { accounts } from 'home/Accounts/reducers/account.reducer';
import companyReducer from 'home/DomainName/currentDomain.reducer';
import mobileHierarchyViewPane from 'home/AssetHierarchy/reducers/mobileHierarchyViewPane.reducer';
import { activityUnreadCount } from 'home/HomePage/reducers/activity.reducer';
import { authentication } from '../onboarding/reducers/authentication.reducer';
import { user } from '../onboarding/reducers/user.reducer';
import { currentAccount } from '../onboarding/reducers/currentAccount.reducer';
import { timezones } from '../onboarding/reducers/timezones.reducer';
import { forgotPasswordState } from '../onboarding/reducers/forgotPassword.reducer';
import { resetPasswordState } from '../onboarding/reducers/resetPassword.reducer';
import { rbacPermissions } from '../onboarding/reducers/rbacPermissions.reducer';
import { accountSelectionState } from '../onboarding/reducers/accountSelection.reducer';
import { twoFactorAuthState } from '../onboarding/reducers/twoFactorAuth.reducer';
import { machineDetailsReducer } from '../home/Machines/MachineDetails';
import { dashboardReducer } from '../home/Dashboard/reducers';
import { alert } from '../alert';
import { sensors } from '../home/Sensors';
import { settings } from '../home/Settings';
import { machinesReducer } from '../home/Machines/reducers';
import { bearingReducer } from '../home/Machines/MachineDetails/MachineInfo/components/BearingModal/bearing.reducers';
import { machineDiagnostics } from '../home/MachineDiagnostics/reducers/machineDiagnostics.reducer';
import { assetHierarchyReducer } from '../home/AssetHierarchy';
import { common } from '../common/reducers';
import { breadcrumb } from '../home/Menu/reducers/breadcrumb.reducer';
import { reportsReducer } from '../home/Reports/reducers/reports.reducers';
import { templatesReducer } from '../home/Templates/reducers/templates.reducers';
import { resources } from '../home/Resources/reducers/resource.reducer';
import { apiAuditReducer } from '../home/APIAuditLogs/reducer';
import { devicesHealth } from '../home/DevicesHealth';
import hierarchyViewPane from '../home/AssetHierarchy/reducers/hierarchyViewPane.reducer';
import { assetFailureMode } from '../home/MachineDiagnostics/reducers/failureMode.reducer';
import { tagTemplates } from '../home/TagTemplates/reducers/tagTemplates.reducer';
import { taskTemplates } from '../home/TaskTemplates/reducers/taskTemplates.reducer';
import { adminDashboard } from '../home/AdminDashboard/adminDashboard.reducer';
import { erroneousReadings } from '../home/AdminDashboard/ErroneousReadings';
import { firmwareDeploymentReducer } from '../home/FirmwareDeployment';
import { accountAnalyticsReducer } from '../home/AdminDashboard/AccountAnalytics/accountAnalytics.reducer';
import { eventAnalyticsReducer } from '../home/AdminDashboard/EventAnalytics/eventAnalytics.reducer';
import events from '../home/Activity/reducers/activity.reducers';
import instrumentationAlerts from '../home/InstrumentationAlerts/reducers/instrumentation.reducers';
import feedsReducer from '../home/Feed/reducers/feed.reducers';

const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  // e.g type => GET_MACHINE_METADATA_REQUEST
  // requestName => GET_MACHINE_METADATA
  // requestState => REQUEST
  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  };
};

const abortControllers = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  if (requestState === 'REQUEST') {
    // if new request comes before old request is completed, abort old one
    if (state[requestName]) {
      state[requestName].abort();
    }
    // set request controller to new abort controller
    const abortController = new AbortController();
    return {
      ...state,
      [requestName]: abortController,
    };
  }
  // reset controller to null for success and failure
  return {
    ...state,
    [requestName]: null,
  };
};

export const errorReducer = (state = {}, action) => {
  const { type, error, other } = action;
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
    //      else clear errorMessage when receiving GET_TODOS_REQUEST
    [requestName]: { message: requestState === 'FAILURE' ? error : '', other },
  };
};

export const appReducer = combineReducers({
  authentication,
  breadcrumb,
  common,
  user,
  alert,
  timezones,
  forgotPasswordState,
  resetPasswordState,
  twoFactorAuthState,
  toastr: toastReducer,
  form: formReducer,
  dashboard: dashboardReducer,
  machines: machinesReducer,
  templates: templatesReducer,
  bearings: bearingReducer,
  reports: reportsReducer,
  devicesHealth,
  rbacPermissions,
  resources,
  sensors,
  settings,
  machineDetails: machineDetailsReducer,
  accountSelectionState,
  events,
  feedsReducer,
  currentAccount,
  assetHierarchyReducer,
  loadingReducer,
  errorReducer,
  machineDiagnostics,
  hierarchyViewPane,
  assetFailureMode,
  automaticAlarmReducer: AutomaticAlarmReducer,
  tagTemplates,
  taskTemplates,
  adminDashboard,
  machinesAnalytics: machinesAnalyticsReducer,
  erroneousReadings,
  firmwareDeployments: firmwareDeploymentReducer,
  accountAnalytics: accountAnalyticsReducer,
  eventAnalytics: eventAnalyticsReducer,
  taskModal,
  repairModal,
  noteModal,
  attachments: attachmentsReducer,
  dashboards,
  widgets,
  apiAuditReducer,
  rul,
  accounts,
  abortControllers,
  companyReducer,
  mobileHierarchyViewPane,
  activityUnreadCount,
  instrumentationAlerts
});

// whole redux store must reset to initial state if either user signs out or changes account
const rootReducer = (state, action) => {
  if (
    action.type === 'USERS_LOGOUT' ||
    action.type === 'SELECT_ACCOUNT_REQUEST'
  ) {
    state = {
      companyReducer: state.companyReducer,
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
