import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import HealthIconSvg from 'common/images/HealthIconSvg';
import { Task, Wrench } from 'common/images/FaIcons';
import NoteSvgNew from 'common/images/NoteSvgNew';
import Text_T from '../../../common/typography/Text/Text';
import AlarmFlagSvg from '../../../common/images/AlarmFlagSvg';
import OverallAlarmWarningNew from '../../../common/images/OverallAlarmWarningNew';
import OverallAlarmCriticalNew from '../../../common/images/OverallAlarmCriticalNew';
import MoteUnreachableNew from '../../../common/images/MoteUnreachableNew';
import MachineSvg from '../../../common/images/MachineSvg';
import NewBatteryIcon from '../../../common/images/NewBatteryIcon';
import { humanize } from '../../../common/helpers/humanize';

import colors from '../../../common/styles/colors';

export const Text = styled(Text_T)`
  font-size: 13px;
  ${props => props.hover && `
    &:hover {
      color: ${props.theme.primaryColor};
      cursor: pointer;
    }
  `}
`;

const DescriptionContainer = styled.div`
${props => props.marginTop && 'margin-top: 0.5em;'}
  > ${Text} {
    margin-bottom: 0.5em;
  }
`;

const getSpeedRangeText = (threshold_value) => {
  if (!threshold_value) return '';
  return `for speed range - ${threshold_value}`;
};


const groupedActivityFormat = {
  vibration_warning: {
    title: activity => `Vibration warning alarm on the ${humanize(activity.location)} at ${activity.component_name} in ${activity.direction} direction`,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibrations have exceeded warning alarm on the {humanize(activity.location)} at {activity.component_name} in {activity.direction} direction. </Text>
          <Text>The latest RMS of {activity.rms} {activity.units} exceeds the threshold of {activity.threshold_value} {activity.units} by {activity.percent_increase}% {getSpeedRangeText(activity.threshold_type)} . </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmWarningNew />
  },
  vibration_critical: {
    title: activity => `Vibration critical alarm on the ${humanize(activity.location)} at ${activity.component_name} in ${activity.direction} direction`,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibrations have exceeded critical alarm on the {humanize(activity.location)} at {activity.component_name} in {activity.direction} direction. </Text>
          <Text>The latest RMS of {activity.rms} {activity.units} exceeds the threshold of {activity.threshold_value} {activity.units} by {activity.percent_increase}% {getSpeedRangeText(activity.threshold_type)} . </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmCriticalNew />
  },
  vibration_demod_warning: {
    title: activity => `Demod vibration warning alarm on the ${humanize(activity.location)} at ${activity.component_name} in ${activity.direction} direction`,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibrations have exceeded {activity.alarm_level_prefix} {activity.alarm_level} alarm on the {humanize(activity.location)} at {activity.component_name} in {activity.direction} direction.</Text>
          <Text>The latest {activity.alarm_level_prefix} RMS of {activity.rms} {activity.units} exceeds the threshold of {activity.threshold_value} {activity.units} by {activity.percent_increase}% {getSpeedRangeText(activity.threshold_type)} . </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmWarningNew />
  },
  vibration_demod_critical: {
    title: activity => `Demod vibration critical alarm on the ${humanize(activity.location)} at ${activity.component_name} in ${activity.direction} direction`,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibrations have exceeded {activity.alarm_level_prefix} {activity.alarm_level} alarm on the {humanize(activity.location)} at {activity.component_name} in {activity.direction} direction.</Text>
          <Text>The latest {activity.alarm_level_prefix} RMS of {activity.rms} {activity.units} exceeds the threshold of {activity.threshold_value} {activity.units} by {activity.percent_increase}% {getSpeedRangeText(activity.threshold_type)} . </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmCriticalNew />
  },
  vibration_acceleration_warning: {
    title: activity => `Vibration acceleration warning alarm on the ${humanize(activity.location)} at ${activity.component_name} in ${activity.direction} direction`,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibrations have exceeded {activity.alarm_level_prefix} {activity.alarm_level} alarm on the {humanize(activity.location)} at {activity.component_name} in {activity.direction} direction.</Text>
          <Text>The latest {activity.alarm_level_prefix} RMS of {activity.rms} {activity.units} exceeds the threshold of {activity.threshold_value} {activity.units} by {activity.percent_increase}% {getSpeedRangeText(activity.threshold_type)} . </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmWarningNew />
  },
  vibration_acceleration_critical: {
    title: activity => `Vibration acceleration critical alarm on the ${humanize(activity.location)} at ${activity.component_name} in ${activity.direction} direction`,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibrations have exceeded {activity.alarm_level_prefix} {activity.alarm_level} alarm on the {humanize(activity.location)} at {activity.component_name} in {activity.direction} direction.</Text>
          <Text>The latest {activity.alarm_level_prefix} RMS of {activity.rms} {activity.units} exceeds the threshold of {activity.threshold_value} {activity.units} by {activity.percent_increase}% {getSpeedRangeText(activity.threshold_type)} . </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmCriticalNew />
  },
  vibration_envelope_alarm: {
    title: activity => `Vibration envelope alarm on the ${humanize(activity.location)} at ${activity.component_name} in ${activity.direction} direction`,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibration spectrum exceeded envelope limit {getSpeedRangeText(activity.threshold_type)}. </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmCriticalNew />
  },
  current_envelope_alarm: {
    title: activity => `Current envelope alarm on the ${humanize(activity.location)} at ${activity.component_name}`,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Current spectrum exceeded envelope limit. </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmCriticalNew />
  },
  machine_condition_warning: {
    title: activity => `${humanize(activity.event_type)} alarm`,
    description: activity => (
      <DescriptionContainer>
        <Text>This machine condition has changed to warning at {activity.created_at}. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <MachineSvg fill={colors.warning} />
  },
  machine_condition_critical: {
    title: activity => `${humanize(activity.event_type)} alarm`,
    description: activity => (
      <DescriptionContainer>
        <Text>This machine condition has changed to critical at {activity.created_at}. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <MachineSvg fill={colors.critical} />
  },
  health_score_warning: {
    title: () => 'Health score warning alarm',
    description: activity => (
      <DescriptionContainer>
        <Text>The health score {activity.change_direction} to </Text>
        <Text>{activity.parameter_value} which is {activity.comparision_string}. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <AlarmFlagSvg fill={colors.alarmWarning} />
  },
  health_score_critical: {
    title: () => 'Health score critical alarm',
    description: activity => (
      <DescriptionContainer>
        <Text>The health score {activity.change_direction} to </Text>
        <Text>{activity.parameter_value} which is {activity.comparision_string}. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <AlarmFlagSvg fill={colors.alarmCritical} />
  },
  battery_level_warning: {
    title: () => 'Battery level warning alarm',
    description: activity => (
      <DescriptionContainer>
        <Text>The mote on {humanize(activity.location)} has less than {activity.battery_level} battery remaining. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <NewBatteryIcon fill={colors.battery_level_warning} />
  },
  battery_level_critical: {
    title: activity => `${activity.sensor_serial_number} has a battery level critical alarm`,
    description: activity => (
      <DescriptionContainer>
        <Text>The mote on {humanize(activity.location)} has less than {activity.battery_level} battery remaining. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <NewBatteryIcon fill={colors.battery_level_critical} />
  },
  tx_battery_level_warning: {
    title: activity => `${activity.sensor_serial_number} has a tx battery level warning alarm`,
    description: activity => (
      <DescriptionContainer>
        <Text>This transmitter has less than {activity.battery_level} battery remaining. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <NewBatteryIcon fill={colors.battery_level_warning} />
  },
  tx_battery_level_critical: {
    title: activity => `${activity.sensor_serial_number} has a battery level critical alarm`,
    description: activity => (
      <DescriptionContainer>
        <Text>This transmitter has less than {activity.battery_level} battery remaining. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <NewBatteryIcon fill={colors.battery_level_critical} />
  },
  mote_unreachable: {
    title: activity => `${activity.sensor_serial_number} is unreachable`,
    description: activity => (
      <DescriptionContainer>
        {!activity.closed_at && (
          <>
            <Text>This mote on {humanize(activity.location)} is unreachable since {activity.duration}.</Text>
            <Text>It sent last reading at {activity.last_reporting_time}. </Text>
          </>
        )}

        {activity.closed_at && <Text>This mote on {humanize(activity.location)} was unreachable for {activity.duration} between {activity.last_reporting_time} and {activity.closed_at}.</Text>}
      </DescriptionContainer>
    ),
    icon: () => <MoteUnreachableNew fill={colors.critical} />
  },
  tx_unreachable: {
    title: activity => `${activity.sensor_serial_number} is unreachable`,
    description: activity => (
      <DescriptionContainer>
        {!activity.closed_at && (
          <>
            <Text>This transmitter is unreachable since {activity.duration}. </Text>
            <Text>It last reported at {activity.last_reporting_time}. </Text>
          </>
        )}
        {activity.closed_at && <Text>This transmitter was unreachable for {activity.duration} between {activity.last_reporting_time} and {activity.closed_at}.</Text>}
      </DescriptionContainer>
    ),
    icon: () => <AlarmFlagSvg fill={colors.alarmCritical} />
  },
  custom: {
    title: activity => `${humanize(activity.event_type)} alarm${activity.location ? ` on the ${humanize(activity.location)}` : ''}${activity.direction ? ` in ${activity.direction} direction` : ''}`,
    description: activity => (
      <>
      {activity.parameter_type !== 'health_score' ? (
        <DescriptionContainer>
          {activity.location && <Text>The {activity.parameter_type} on {humanize(activity.location)} {activity.change_direction} to </Text>}
          {activity.location && <Text>{activity.parameter_value} which is {activity.comparision_string}. </Text>}
          {!activity.location && <Text>The {activity.parameter_type} {activity.change_direction} to {activity.parameter_value}</Text>}
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
      ) : (
        <DescriptionContainer>
          <Text>The health score {activity.change_direction} to </Text>
          <Text>{activity.parameter_value} which is {activity.comparision_string}. </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
      )}
      </>
    ),
    icon: activity => <AlarmFlagSvg fill={activity.event_type && activity.event_type.includes('critical') ? colors.alarmCritical : colors.alarmWarning} />
  },
  diagnosis: {
    title: activity => `${activity.rule_name} triggered${activity.location ? ` at ${humanize(activity.location)}` : ''}`,
    description: activity => (
      <DescriptionContainer>
        <Text>{activity.recommendation}</Text>
      </DescriptionContainer>
    ),
    icon: () => <HealthIconSvg />
  },
  new_operation_modes: {
    title: () => 'New operating mode detected',
    description: () => (
      <DescriptionContainer>
        <Text>Re-configure VFD speed ranges.</Text>
      </DescriptionContainer>
    ),
    icon: () => ''
  },
  baseline_missing_for_operation_modes: {
    title: activity => `Has baselines missing for few operating modes at ${activity.component_name}`,
    description: activity => (
      <DescriptionContainer>
        <Text>Re-baseline to include these operation modes.</Text>
        {!_.isEmpty(activity.speed_info) && <Text> Feedback raised for speed ranges - {activity.speed_info.filter(info => info.speed_bin).map(info => info.speed_bin).join(', ')}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => ''
  },
  baseline_higher_than_measurement_value: {
    title: activity => `Vibrations changed from earlier indicated normal behavior at ${activity.component_name}`,
    description: activity => (
      <DescriptionContainer>
        <Text>Re-baseline asset with recent data.</Text>
        {!_.isEmpty(activity.speed_info) && <Text> Feedback raised for speed ranges - {activity.speed_info.filter(info => info.speed_bin).map(info => info.speed_bin).join(', ')}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => ''
  },
  task: {
    title: activity => `${activity.name} by ${activity.user_name}`,
    icon: () => <Task />
  },
  repair: {
    title: activity => `${activity.name} by ${activity.user_name}`,
    icon: () => <Wrench />
  },
  note: {
    icon: () => <NoteSvgNew />
  }
};

export default groupedActivityFormat;
