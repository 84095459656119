import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LineChart from 'common/charts/GoogleCharts/LineChart';
import colors from 'common/styles/colors';
import { FlexContainer } from 'common/components/atoms';
import * as machineMLActions from '../actions/machineML.actions';
import NoDataChart from '../../MachineCharts/components/NoDataChart';
import BaselineValidator from './organisms/BaselineValidator';
import machineMLTypes from '../actions/machineML.types';

const ChartContainer = styled(FlexContainer).attrs({
  width: '100%',
  height: '180px',
  alignItems: 'center',
  justifyContent: 'center',
  direction: 'column'
})`
  background-color: white;
  border: 1px solid #D8DCD3;
  border-radius: 8px;
  position: relative;
  :not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

const ValidatorContainer = styled.span`
  position: absolute;
  top: 14px;
  right: 14px;
`;

function formatDataForTrend(trend) {
  const data = [];
  for (let i = 0; i < trend.length; i++) {
    data[i] = [new Date(trend[i].x), trend[i].y];
  }
  return data;
}

function FeatureChart({
  feature,
  ampType,
  tagType,
  hierarchy,
  machineMLActions,
  componentId,
  featureDisplayName,
  user,
  component,
  startTime,
  endTime,
  tagIds,
  loadingReducer,
  speedBin
}) {
  const [featureData, setFeatureData] = useState([]);

  useEffect(() => {
    const finalBaselineInfo = {
      start_time: new Date(startTime),
      end_time: new Date(endTime)
    };

    if (!hierarchy.loading) {
      machineMLActions.getFeatureTrend(componentId, ampType, feature, tagType, speedBin, finalBaselineInfo).then((res) => {
        if (!res) return;
        const data = formatDataForTrend(res[0].trend);
        setFeatureData([['Date', res[0].units], ...data]);
      });
    }

    machineMLActions.validateComponentBaseline(componentId, tagIds, finalBaselineInfo, ampType, feature, tagType, speedBin);
  }, [feature, ampType, tagType, startTime, endTime]);

  const validationLoading = machineMLTypes.VALIDATE_COMPONENT_BASELINE in loadingReducer ?
    loadingReducer[machineMLTypes.VALIDATE_COMPONENT_BASELINE] : false;

  const validationKey = speedBin ? `${speedBin}_${feature}` : `${tagType}_${ampType || null}_${feature || null}`;

  return (
    <ChartContainer>
      {featureData.length > 1 && (
        <ValidatorContainer>
          <BaselineValidator
            validationLoading={validationLoading}
            component={component}
            tagIds={tagIds}
            toggleOutliers={() => {}}
            outliersShown={false}
            isMultiSetActive={false}
            user={user}
            showSaveOptions={false}
            validationKey={validationKey}
            showPopupformat={false}
          />
        </ValidatorContainer>
      )}
      {featureData.length > 1 && (
        <LineChart
          title={featureDisplayName || ' '}
          data={featureData}
          colors={[colors.black]}
          disableGrid
        />
      )}
      {featureData.length <= 1 && (
        <NoDataChart title={`No data is available for the feature ${featureDisplayName}`} height="150px" width="100%" />
      )}
    </ChartContainer>
  );
}

const mapStateToProps = state => ({
  hierarchy: state.assetHierarchyReducer.assetInfo.hierarchy,
  user: state.user.user,
  loadingReducer: state.loadingReducer
});

const mapDispatchToProps = dispatch => ({
  machineMLActions: bindActionCreators(machineMLActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureChart);
