import styled from 'styled-components';

const FooterFlex = styled.footer`
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  ${props => props.left ? `left: ${props.left}` : ''};
  display: flex;
  justify-content: ${props => props.justifyContent || 'flex-end'};
  border-top: 2px solid #EFF0EE;
  height: 57px;
  ${props => props.width ? `width: ${props.width}` : ''};
  padding: 12px 24px;
  z-index: 3;
  border-style: border-box;
  overflow: hidden;
  background: white;
  align-items: center;
  button {
    margin-left: 10px;
  }
`;

export default FooterFlex;
