import { reportActionConstants } from 'home/Reports/actions/reports.constants';
import _ from 'lodash';

const initialState = {
  items: {
    hasMore: false,
    object: [],
    loading: false,
  },
  reportsSorterState: {
    sorter: {
      order: 'desc',
      name: 'updated_at'
    }
  },
};

export const reportsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportActionConstants.UPDATE_REPORTS_LIST: {
      action.items.sort((a, b) => (b.created_at.localeCompare(a.created_at)));
      return {
        ...state,
        items: {
          ...state.items,
          object: [
            ...action.items
          ],
        }
      };
    }
    case reportActionConstants.SET_REPORTS_SORTER: {
      const nextState = _.cloneDeep(state);
      nextState.items.object.sort((a, b) => (action.sorterState.order === 'asc' ? a[action.sorterState.name].localeCompare(b[action.sorterState.name]) : b[action.sorterState.name].localeCompare(a[action.sorterState.name])));
      nextState.reportsSorterState.sorter = action.sorterState;
      return {
        ...nextState,
      };
    }
    default:
      return state;
  }
};
