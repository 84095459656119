import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as _ from 'lodash';

import DeleteSvg from 'common/images/BearingModal/DeleteIconSvg';
import {
  FlexContainer,
  ListItem,
} from 'common/components/atoms';

import InputField_T from 'common/components/atoms/InputField';
import Checkbox_T from 'common/components/atoms/Checkbox';

const Checkbox = styled(Checkbox_T)`
  display: flex;
  font-size: 16px;
  margin-right: 10px;
  z-index: 0;
  margin-left: ${props => props.marginLeft};
`;

const InputField = styled(InputField_T)`
  font-size: 14px;
  width: ${props => props.width}
`;

const ForcingFreqState = styled.div`
  white-space: nowrap;
  text-transform: capitalize;
  width: 40%;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${props => props.item.enabled ? props.theme.primaryColor : props.theme.colors.grey};
`;

class ForcingFreqListItem extends Component {
  render() {
    const {
      item,
      componentId,
      updateItem,
      deleteItem,
      columnSizes,
      hasAccess,
      componentType,
      shaftOptions,
      secondaryColumn
    } = this.props;

    const gmf = _.startsWith(item.marker, 'GMF') && item.is_default;
    const dropdownFontSettings = `
      font-size: 13px
    `;

    return (
      <ListItem style={{ fontSize: '14px' }}>
        <td width={`${columnSizes[0]}%`}>
          <InputField
            type="text"
            placeholder="Marker"
            marginBottom="0px"
            value={item.marker}
            disabled={item.is_default || !hasAccess}
            style={{ fontSize: '14px', padding: '0.5em' }}
            onChange={e => updateItem(componentId, item.id, 'marker', e.target.value)}
            maxLength={15}
          />
        </td>
        <td width={`${columnSizes[1]}%`}>
          <InputField
            type="text"
            placeholder="Type Name"
            marginBottom="0px"
            value={item.name}
            disabled={item.is_default || !hasAccess}
            style={{ fontSize: '14px', padding: '0.5em' }}
            onChange={e => updateItem(componentId, item.id, 'name', e.target.value)}
            maxLength={25}
          />
        </td>
        <td width={`${columnSizes[2]}%`} style={{ textAlign: 'center' }}>
          <InputField
            type="number"
            placeholder="Multiplier"
            marginBottom="0px"
            value={item.multiplier || ''}
            disabled={(item.is_default && !gmf) || !hasAccess}
            style={{ fontSize: '14px', padding: '0.5em' }}
            onChange={e => updateItem(componentId, item.id, 'multiplier', e.target.value)}
          />
        </td>
        <td width={`${columnSizes[3]}%`}>
          <InputField
            type="number"
            placeholder="Frequency"
            marginBottom="0px"
            value={item.frequency || ''}
            disabled={item.is_default || !hasAccess}
            style={{ fontSize: '14px', padding: '0.5em' }}
            onChange={e => updateItem(componentId, item.id, 'frequency', e.target.value)}
          />
        </td>
        {secondaryColumn && (
          <td width={`${columnSizes[4]}%`}>
            <Checkbox
              value={item.secondary}
              onClick={() => updateItem(componentId, item.id, 'secondary', !item.secondary)}
              marginLeft="22px"
            />
          </td>
        )}
        <td width={secondaryColumn ? `${columnSizes[5]}%` : `${columnSizes[4]}%`}>
          <FlexContainer>
            <ForcingFreqState item={item}>
              {hasAccess && (
                <Checkbox
                  value={item.enabled}
                  onClick={() => updateItem(componentId, item.id, 'enabled', !item.enabled)}
                />
              )}
              {item.enabled ? ' Enabled' : ' Disabled'}
            </ForcingFreqState>
            {gmf && (
                <InputField
                  type="select"
                  options={item.shaft_options}
                  value={item.shaft}
                  name="shaft"
                  key="shaft"
                  onChange={(e, data) => updateItem(componentId, item.id, 'shaft', data.value)}
                  placeholder="Shaft"
                  marginBottom="0px"
                  width="50%"
                  fontSettings={dropdownFontSettings}
                  optionFontSettings={dropdownFontSettings}
                  disabled={!hasAccess}
                />
            )}
            {componentType === 'multi_stage_gearbox' && !item.is_default && (
                <InputField
                  type="select"
                  options={shaftOptions(componentId)}
                  value={item.shaft}
                  name="shaft number"
                  key="shaft number"
                  onChange={(e, data) => updateItem(componentId, item.id, 'shaft', data.value)}
                  placeholder="Shaft No."
                  marginBottom="0px"
                  width="50%"
                  fontSettings={dropdownFontSettings}
                  optionFontSettings={dropdownFontSettings}
                  disabled={item.is_default || !hasAccess}
                />
            )}
          </FlexContainer>
        </td>
        <td width={secondaryColumn ? `${columnSizes[6]}%` : `${columnSizes[5]}%`}>
          {!item.is_default && hasAccess && (
            <DeleteSvg
              onClick={() => deleteItem(componentId, item.id)}
            />
          )}
        </td>
      </ListItem>
    );
  }
}

ForcingFreqListItem.propTypes = {
  columnSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  item: PropTypes.object.isRequired,
};

export default ForcingFreqListItem;
