import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';
import { FlexContainer, InputField } from 'common/components/atoms';
import { mapNames } from 'common/charts/constants';
import { humanize } from 'common/helpers';
import { splitOnLastDelimiter } from 'common/utils';
import Error from 'home/AssetHierarchy/components/atoms/Error';
import FeatureCharts from './FeatureCharts';
import SpeedBinCharts from './SpeedBinCharts';


const DetailsView = styled(FlexContainer)`
  background-color: white;
  padding: 15px;
  border: 1px solid #D8DCD3;
  border-radius: 8px;
  position: relative;
  flex-direction: column;

  :not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

const DropdownContainer = styled(FlexContainer)`
  ${InputField} {
    margin-right: 16px;
  }
  margin-bottom: 16px;
`;

function BaselineDetails({ theme, ahsFeaturesInfo, tagTypes, componentId, component, startTime, endTime }) {
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedTagType, setSelectedTagType] = useState('');
  const [selectedSpeedBinFeature, setSelectedSpeedBinFeature] = useState('');

  useEffect(() => {
    if (!_.isEmpty(tagTypeOptions)) setSelectedTagType(tagTypeOptions[0].value);
  }, [tagTypeOptions]);

  const toggleSelectedFeature = (value, checked) => {
    if (checked) {
      setSelectedFeatures(prevState => prevState.filter(f => f !== value));
    } else {
      setSelectedFeatures(prevState => ([...prevState, value]));
    }
  };

  const isFeatureSelected = value => selectedFeatures.includes(value);

  const featureOptions = useMemo(() => {
    const options = [];

    if (!_.isEmpty(ahsFeaturesInfo)) {
      ahsFeaturesInfo.features.forEach((info) => {
        let [feature, ampType] = splitOnLastDelimiter(info.name, '_');
        feature = mapNames[feature] || humanize(feature);
        ampType = mapNames[ampType] || humanize(ampType);
        const text = `${feature} ${ampType}`;
        options.push({ text, value: info.name });
      });
    }

    if (!_.isEmpty(options) && selectedTagType === 'vibration') {
      if (
        _.isEmpty(selectedFeatures) &&
        options.map(opt => opt.value).includes('rms_velocity')
      ) {
        setSelectedFeatures(['rms_velocity']);
      } else {
        setSelectedFeatures([options[0].value]);
      }
    } else {
      setSelectedFeatures([]);
    }

    if (!_.isEmpty(options)) {
      setSelectedSpeedBinFeature(options[0].value);
    }

    return options;
  }, [ahsFeaturesInfo, selectedTagType]);

  const tagTypeOptions = useMemo(() => (
    tagTypes.map(type => ({ text: humanize(type), value: type }))
  ), [tagTypes]);

  if (!startTime || !endTime) {
    return (
      <DetailsView>
        <Error>Please select a baseline range to see the feature charts.</Error>
      </DetailsView>
    );
  }

  return (
    <>
      <DetailsView>
        <DropdownContainer>
          <InputField
            className="tag-type-select"
            type="select"
            options={tagTypeOptions}
            fontSettings={theme.fonts.lightLabel}
            optionFontSettings={theme.fonts.lightLabel}
            marginBottom="0"
            onChange={(e, { value }) => setSelectedTagType(value)}
            preventContentWrap
            placeholder="Select Tag Type"
            value={selectedTagType}
            floating
          />
          {selectedTagType === 'vibration' && (
            <InputField
              className="features-select"
              type="select"
              options={featureOptions}
              fontSettings={theme.fonts.lightLabel}
              optionFontSettings={theme.fonts.lightLabel}
              marginBottom="0"
              multiCheckbox
              toggleSelection={toggleSelectedFeature}
              preventContentWrap
              placeholder="Select Features"
              isItemSelected={isFeatureSelected}
              floating
            />
          )}
        </DropdownContainer>
        {selectedTagType && (
          <FeatureCharts
            features={selectedFeatures}
            tagType={selectedTagType}
            componentId={componentId}
            component={component}
            startTime={startTime}
            endTime={endTime}
            tagIds={component.tags_data.filter(item => item.tag_type === selectedTagType).map(item => item.tag_id)}
          />
        )}
      </DetailsView>
      {component.tags_data[0].no_of_bins && tagTypes.includes('vibration') && (
        <DetailsView>
          <DropdownContainer>
            <InputField
              className="speed-bin-feature-select"
              type="select"
              options={featureOptions}
              fontSettings={theme.fonts.lightLabel}
              optionFontSettings={theme.fonts.lightLabel}
              marginBottom="0"
              onChange={(e, { value }) => setSelectedSpeedBinFeature(value)}
              preventContentWrap
              placeholder="Select Feature"
              value={selectedSpeedBinFeature}
              floating
            />
          </DropdownContainer>
          {selectedSpeedBinFeature && (
            <SpeedBinCharts
              tagType="vibration"
              attribute={selectedSpeedBinFeature}
              componentId={componentId}
              component={component}
              startTime={startTime}
              endTime={endTime}
              speedBins={component.tags_data[0].no_of_bins}
              tagIds={component.tags_data.filter(item => item.tag_type === 'vibration').map(item => item.tag_id)}
            />
          )}
        </DetailsView>
      )}
    </>
  );
}

export default withTheme(BaselineDetails);
