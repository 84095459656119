import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import _ from 'lodash';

import Loading from 'common/components/atoms/Loading';
import FlexContainer from 'common/components/atoms/FlexContainer';
import { Chart } from 'common/components/Chart';
import { mapNames, calendarOptions } from 'common/charts/constants';
import Text from 'common/typography/Text/Text';
import SelectionContainer from 'common/components/Chart/atoms/SelectionContainer';
import ColoredCircle from 'common/components/Chart/atoms/ColoredCircle';
import { formatDate, toFixedXIfNumber, chartRange } from 'common/utils';
import ChartItemContainer from 'common/components/Chart/components/ChartItemContainer';
import { getTagGroupName, getTagMatchKey } from 'common/components/Chart/utils/helpers';
import * as hierarchyUtils from '../../utils/assetHierarchyUtils';
import NoDataChart from '../../../Machines/MachineDetails/MachineCharts/components/NoDataChart';
import * as assetDetailsActions from '../../actions/assetDetails.actions';
import AbsoluteLoading from '../atoms/AbsoluteLoading';
import ChartContainer from '../atoms/ChartContainer';
import ChartLegendContainer from '../molecules/ChartLegendContainer';

const MultilineChartContainer = styled.div`
  position: relative;
  ${props => (props.optionsVisible ? 'width: calc(100% - 220px);' : 'width: 100%;')};
`;

const UpperText = styled(Text)`
  display: block;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 3px;
`;

const ValueText = styled(Text)`
  font-size: ${props => props.fontSize ? props.fontSize : '10px'};
  color: ${props => props.theme.colors.darkGray};
  margin-right: 5px;
  cursor: default;
`;

class ChartMultilineItem extends Component {
  constructor(props) {
    super(props);
    this.setSelections = this.setSelections.bind(this);
    this.calculateChartDataModifications = this.calculateChartDataModifications.bind(this);
    const idx = calendarOptions.findIndex(el => el.value === props.days);
    const selectedRange = idx < 0 ? 'custom' : calendarOptions[idx].text;
    this.state = {
      selectedRange,
      days: props.days,
      exclusions: { tagNames: [], machineIds: [] },
      activeData: []
    };
  }

  componentDidMount() {
    if (this.props.config.trends_data) {
      this.calculateChartDataModifications();
    }
    if (this.props.config) {
      const { site_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
      this.props.config.machines.forEach((machine_id) => {
        const tags = hierarchyUtils.getTagsList(machine_id, site_id, this.props.sites);
        if (!tags || !tags.length) this.props.assetDetailsActions.getTags(machine_id, site_id);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      config,
      days,
      customDayFrom,
      customDayTo,
    } = this.props;
    if (
      config.trends_data &&
      !config.trends_data.loading &&
      !_.isEqual(config.trends_data, prevProps.config.trends_data) ||
      !_.isEqual(this.state.exclusions, prevState.exclusions)
    ) {
      this.calculateChartDataModifications();
    }
    if (days !== prevProps.days || (days === 'custom' && (customDayFrom !== prevProps.customDayFrom || customDayTo !== prevProps.customDayTo))) {
      const params = {
        config_id: this.props.config.config_id,
      };
      const newState = {};
      if (days === 'custom') {
        params.from_timestamp = customDayFrom;
        params.to_timestamp = customDayTo;
      } else {
        params.days = days;
      }
      this.props.assetDetailsActions.getChartsData(params);
      const idx = calendarOptions.findIndex(el => el.value === params.days);
      const selectedRange = idx < 0 ? 'Custom' : calendarOptions[idx];
      this.setState({
        days: this.props.days,
        selectedRange,
        selections: undefined,
        ...newState
      });
    }
  }

  setInitialSelection() {
    if (this.props.noHover) return;

    const selections = this.state.data.map((data, idx) => {
      if (_.isEmpty(data)) return null;
      return { ...data[data.length - 1], dataIdx: idx };
    }).filter(item => item);

    if (selections) {
      this.setState({
        selections
      });
    }
  }

  setSelections(selections) {
    this.setState({
      selections
    });
  }

  calculateChartDataModifications() {
    const { tagNames: excludedTagNames, machineIds: excludedMachineIds } = this.state.exclusions;
    const { trends_config, trends_data } = this.props.config;

    const tags = this.getTagsList(); // TODO: A better way to get tag list
    const activeData = trends_data.map((item, index) => {
      const trend_config = trends_config[index];
      const tag = tags.find(i => i.id === item.tag_id);
      if (!tag) return true;
      const composedTag = {
        ...tag,
        ...trend_config
      };
      return !excludedMachineIds.includes(trend_config.machine_id) && !excludedTagNames.includes(getTagGroupName(composedTag));
    });

    // base range for which the chart will be rendered and data scaled to
    const endRange = [0, 10];
    // object for holding ranges of individual unit types,
    // e.g. { in/s: [min, max] } will hold min and max values from all datasets that are in/s
    const unitRanges = {};
    // boolean to determine existance of data
    let hasData = false;
    if (trends_data && trends_data.length > 0) {
      const unit_system = this.props.currentUser.unit_system;
      trends_data.forEach((item) => {
        if (item.trend_data.length > 0) {
          hasData = true;
          const yUnit = item.units;
          const tag_type = item.tag_type;
          const itemRange = chartRange(item.trend_data, unit_system, yUnit, '', tag_type);
          if (unitRanges[yUnit] === undefined) {
            unitRanges[yUnit] = itemRange;
          } else {
            if (itemRange[1] > unitRanges[yUnit][1]) unitRanges[yUnit][1] = itemRange[1];
            if (itemRange[0] < unitRanges[yUnit][0]) unitRanges[yUnit][0] = itemRange[0];
          }
        }
      });
    }

    // math function to scale data items to the given endRange,
    // scaled based on the calucated unitRange for the given unit
    const scaleDataEntry = (entry, unitRange, endRange) =>
      endRange[1] * ((entry - unitRange[0]) / (unitRange[1] - unitRange[0]));

    // map over all datapoints and give a scaled value for y
    // insert new key realY which will give the initial value of this datapoint (used on hover selection)
    let mapDataToScale = !_.every(trends_data.map(d => d.units), item => item === trends_data[0].units);
    if (!mapDataToScale && trends_data.length > 1 && trends_data[0].tag_type === 'vibration') mapDataToScale = true;

    let data = [];
    if (hasData) {
      if (mapDataToScale) {
        data = _.map(trends_data, (d) => {
          if (_.isEqual(d.trend_data, [])) return [];
          const dataWithScale = _.map(d.trend_data, dataEntry => ({
            ...dataEntry,
            y: scaleDataEntry(dataEntry.y, unitRanges[d.units], endRange),
            realY: dataEntry.y
          }));
          return dataWithScale;
        });
      } else {
        data = _.map(trends_data, d => d.trend_data);
      }
    }

    this.setState(
      {
        data,
        hasData,
        activeData,
        range: mapDataToScale || _.isEmpty(trends_data) ? endRange : unitRanges[trends_data[0].units]
      },
      () => this.setInitialSelection()
    );
  }

  getTagsList = () => {
    let tags = [];
    const { site_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    if (this.props.config) {
      this.props.config.machines.forEach((machine_id) => {
        const machine_tags = hierarchyUtils.getTagsList(machine_id, site_id, this.props.sites);
        if (machine_tags && !machine_tags.loading) tags = tags.concat(machine_tags);
      });
    }
    return tags;
  }

  getLegendDesc = (tags, selection) => {
    const { config } = this.props;
    const tag = tags.find(tag => (tag.id === config.trends_config[selection.dataIdx].tag_id));
    let machine_name = '';
    if (config.machines.length > 1) {
      machine_name = config.trends_config[selection.dataIdx].machine_name || '';
    }
    const tagDesc = tag ? tag.description : '';
    return (
      <ValueText fontSize="13px">
        <b>{machine_name}</b>
        {machine_name !== '' && ' - '}
        {tagDesc}
      </ValueText>
    );
  }

  render() {
    const { config, customDayFrom, customDayTo } = this.props;
    const { data, hasData, range } = this.state;
    const tags = this.getTagsList();
    return (
      <ChartItemContainer marginBottom="1em" position="relative">
        <ChartContainer>
          {config.trends_data && config.trends_data.loading && !hasData && <Loading />}
          {config.trends_data &&
            config.trends_data.error && <span>{config.trends_data.error}</span>}
          {config.trends_data &&
            !config.trends_data.loading &&
            !hasData && (
              <NoDataChart
                configId={config.config_id}
                height="400px"
                days={this.state.days}
                title={this.state.days === 'custom' ? `No data available from ${customDayFrom.toString().slice(0, 15)} to ${customDayTo.toString().slice(0, 15)}` : null}
              />
          )}
          {hasData && (
            <Fragment>
              {!_.isEmpty(this.state.selections) && (
                <SelectionContainer>
                  <FlexContainer direction="column">
                    <UpperText>{formatDate(this.state.selections[0].x)}</UpperText>
                  </FlexContainer>
                </SelectionContainer>
              )}
              <MultilineChartContainer>
                {config.trends_data.loading && <AbsoluteLoading><Loading /></AbsoluteLoading>}
                <Chart
                  type="multiline"
                  chartName={`chart-multi-${config.config_id}`}
                  data={data}
                  range={range}
                  activeData={this.state.activeData}
                  colors={_.map(config.trends_config, c => c.color)}
                  contextChart
                  ylabel="Multiline"
                  height="400px"
                  mouseover={this.setSelections}
                  selection={this.state.selections}
                  selectedRange={this.state.selectedRange}
                  charts_size="Large"
                  paginationLoading={this.props.paginationLoading}
                />
              </MultilineChartContainer>
              <FlexContainer direction="row" flexWrap="wrap" padding="0 4em" justifyContent="center">
                {!_.isEmpty(this.state.selections) && this.state.selections.length <= config.trends_config.length &&
                  this.state.selections.map((selection) => {
                    if (!config.trends_config[selection.dataIdx]) return null;
                    const description = this.getLegendDesc(tags, selection);
                    return (
                      <FlexContainer direction="row" alignItems="center">
                        <ColoredCircle color={config.trends_config[selection.dataIdx].color} />
                        {description}
                      </FlexContainer>
                    );
                  })}
              </FlexContainer>
              <ChartLegendContainer
                config={config}
                selections={this.state.selections}
                tags={tags}
                exclusions={this.state.exclusions}
                onUpdateExclusions={(exclusions) => {
                  this.setState({ exclusions });
                }}
              />
            </Fragment>
          )}
        </ChartContainer>
      </ChartItemContainer>
    );
  }
}

ChartMultilineItem.propTypes = {
  config: PropTypes.object.isRequired,
  assetDetailsActions: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentUser: state.user.user,
  rbacPermissions: state.rbacPermissions,
  assetHierarchy: state.assetHierarchyReducer.assetInfo,
  sites: state.assetHierarchyReducer.assetInfo.sites,
  breadcrumb: state.breadcrumb
});

const mapDispatchToProps = dispatch => ({
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartMultilineItem);
