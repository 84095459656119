import React from 'react';
import PropTypes from 'prop-types';

const CloseSvg = ({ width, height }) => (
    <svg width={width} height={height} viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
        <title>03FF287B-ABB8-40AD-ACE3-2ED635A53BA7</title>
        <desc>Created with sketchtool.</desc>
        <g id="Sensors" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Generic/3rd-Single-Options" transform="translate(-1241.000000, -154.000000)">
                <g id="details" transform="translate(416.000000, 129.000000)">
                    <g id="top" transform="translate(24.000000, 15.000000)">
                        <g id="close-icon" transform="translate(800.000000, 9.000000)">
                            <rect id="bounds" x="0" y="0" width="16" height="16" />
                            <g id="icon" transform="translate(2.000000, 2.000000)" stroke="#999B95" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                                <path d="M12,0 L0,12" id="Shape" />
                                <path d="M0,0 L12,12" id="Shape" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

CloseSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

CloseSvg.defaultProps = {
  width: 16,
  height: 16
};


export default CloseSvg;
