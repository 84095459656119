import React from 'react';
import PropTypes from 'prop-types';

const TxPortSvg = ({
  width,
  height,
  fill,
  portNumber
}) => (
  <svg width={width} height={height} viewBox="0 0 35 15" id="svg2">
    <defs id="defs4" />
    <g id="layer1" transform="translate(0,-1037.3622)">
      <g transform="translate(-159.00371,519.2185)" id="g4336">
        <path
          d="m 163.08701,520.88092 26.83339,0 c 0.62668,0 1.1312,0.45339 1.1312,1.01657 l 0,7.49243 c 0,0.56318 -0.50452,1.01657 -1.1312,1.01657 l -26.83339,0 c -0.62668,0 -1.13119,-0.45339 -1.13119,-1.01657 l 0,-7.49243 c 0,-0.56318 0.50451,-1.01657 1.13119,-1.01657 z"
          id="path4249"
          stroke={fill.rest}
          fill={fill.rest}
        />
        <path
          id="path4251"
          d="m 165.2933,523.53306 3.32074,4.5e-4 c 0.27773,3e-5 0.50131,0.20092 0.50131,0.4505 l 0,3.32034 c 0,0.24958 -0.22358,0.45054 -0.50131,0.4505 l -3.32074,-4.5e-4 c -0.27772,-3e-5 -0.5013,-0.20092 -0.5013,-0.4505 l 0,-3.32034 c 0,-0.24958 0.22358,-0.45054 0.5013,-0.4505 z"
          fill="#fff"
        />
        <circle
          r="2.1651785"
          cy="525.64404"
          cx="174.05418"
          id="path4253"
          fill={portNumber === 1 ? fill.selection : '#fff'}
        />
        <circle
          id="circle4255"
          cx="180.05418"
          cy="525.64404"
          r="2.1651785"
          fill={portNumber === 2 ? fill.selection : '#fff'}
        />
        <circle
          r="2.1651785"
          cy="525.64404"
          cx="186.05418"
          id="circle4257"
          fill={portNumber === 3 ? fill.selection : '#fff'}
        />
      </g>
    </g>
  </svg>
);

TxPortSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.shape({
    selection: PropTypes.string,
    rest: PropTypes.string
  }),
  portNumber: PropTypes.number
};

TxPortSvg.defaultProps = {
  width: 40,
  height: 20,
  fill: {
    selection: '#333',
    rest: '#d5d5d5'
  },
  portNumber: 1
};

export default TxPortSvg;
