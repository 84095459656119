import { widgetConstants } from '../constants/widgets.constants';

const initialState = {
  metadata: {
    object: {},
    loading: false
  },
  data: []
};

export function widgets(state = initialState, action) {
  switch (action.type) {
    case widgetConstants.GET_WIDGET_METADATA_REQUEST:
      return {
        ...state,
        metadata: {
          object: {},
          loading: true
        }
      };
    case widgetConstants.GET_WIDGET_METADATA_SUCCESS: {
      return {
        ...state,
        metadata: {
          object: action.object,
          loading: false
        }
      };
    }
    case widgetConstants.GET_WIDGET_METADATA_FAILURE:
      return {
        ...state,
        metadata: {
          error: action.error,
          loading: false
        }
      };
    default:
      return state;
  }
}
