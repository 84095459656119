import { alertActions } from '../../../alert';
import { axiosInstance, ENDPOINT } from '../../../common/constants';
import { handleResponse } from '../../../common/helpers';
import assetDetailsConstants from '../constants/assetHierarchy.constants';
import { formatChartData, convertTimestringsToDateObjects } from '../../../common/utils';
import { getSelectedPathFromBreadcrumb } from '../utils/assetHierarchyUtils';

// spectrum features actions
export const getSpectrumFeatures = (tagId, configId) => {
  const request = () => ({ type: assetDetailsConstants.GET_SPECTRUM_FEATURES_REQUEST });
  const success = (machine_id, site_id, features) => ({ type: assetDetailsConstants.GET_SPECTRUM_FEATURES_SUCCESS, configId, features, machine_id, site_id });
  const failure = (machine_id, site_id) => ({ type: assetDetailsConstants.GET_SPECTRUM_FEATURES_FAILURE, configId, machine_id, site_id });

  return (dispatch, getState) => {
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    dispatch(request(machine_id, site_id));
    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUM_FEATURES(tagId)))
      .then(
        (res) => {
          const features = res.features.map(feature => ({ ...feature, freq_units: res.freq_units }));
          dispatch(success(machine_id, site_id, features));
          return features;
        },
        () => {
          dispatch(failure(machine_id, site_id));
        },
      );
  };
};

export const initiateCustomFeatureTrend = (tagId, featureId, params) => {
  const failure = (machine_id, site_id, error) => ({ type: assetDetailsConstants.GET_SPECTRUM_FEATURES_FAILURE, error, machine_id, site_id });

  return (dispatch, getState) => {
    dispatch(alertActions.clear());
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    return handleResponse(axiosInstance.get(ENDPOINT.GET_CUSTOM_FEATURE_TREND(tagId), { params })).then(
      (res) => {
        dispatch(getSpectrumFeatureTrendFromTask(res.task_id, featureId));
      },
      (error) => {
        dispatch(failure(machine_id, site_id, error));
        dispatch(alertActions.error(error.message));
      }
    );
  };
};

export const initiateSpectrumFeatureTrend = (featureId, params) => {
  const failure = (machine_id, site_id, error) => ({ type: assetDetailsConstants.GET_SPECTRUM_FEATURES_FAILURE, error, machine_id, site_id });

  return (dispatch, getState) => {
    dispatch(alertActions.clear());
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    // return service.getSpectrumFeatureTrend(featureId, params)
    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUM_FEATURE_TREND(featureId), { params }))
      .then(
        (res) => {
          dispatch(getSpectrumFeatureTrendFromTask(res.task_id, featureId));
        },
        (error) => {
          dispatch(failure(machine_id, site_id, error));
          dispatch(alertActions.error(error.message));
        },
      );
  };
};

export const getSpectrumFeatureTrendFromTask = (taskId, featureId) => {
  const success = (machine_id, site_id, result, amp_units) => ({
    type: assetDetailsConstants.GET_SPECTRUM_FEATURE_TREND_SUCCESS,
    machine_id,
    site_id,
    featureId,
    result,
    amp_units
  });
  const failure = (machine_id, site_id, error) => ({ type: assetDetailsConstants.GET_SPECTRUM_FEATURE_TREND_FAILURE, error, machine_id, site_id });

  return (dispatch, getState) => {
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUM_FEATURE_TREND_TASK(taskId)))
    // service.getSpectrumFeatureTrendFromTask(taskId)
      .then(
        (res) => {
          if (res.state && res.state === 'SUCCESS') {
            if (res.result) {
              const trendFeatureData = formatChartData(
                res.result.amplitude,
                convertTimestringsToDateObjects(res.result.timestamp)
              );
              dispatch(success(machine_id, site_id, trendFeatureData, res.result.amp_units));
            } else dispatch(success(machine_id, site_id, [], ''));
          } else {
            setTimeout(() => dispatch(getSpectrumFeatureTrendFromTask(taskId, featureId)), 1200);
          }
        },
        (error) => {
          dispatch(failure(machine_id, site_id, error));
          dispatch(alertActions.error(error.message));
        },
      );
  };
};

export const createSpectrumFeature = (tagId, params, configId) => {
  const request = (machine_id, site_id) => ({ type: assetDetailsConstants.CREATE_SPECTRUM_FEATURE_REQUEST, machine_id, site_id });
  const success = (machine_id, site_id, feature) => ({ type: assetDetailsConstants.CREATE_SPECTRUM_FEATURE_SUCCESS, feature, configId, machine_id, site_id });
  const failure = (machine_id, site_id, error) => ({ type: assetDetailsConstants.CREATE_SPECTRUM_FEATURE_FAILURE, error, machine_id, site_id });

  return (dispatch, getState) => {
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    dispatch(request(machine_id, site_id));
    return handleResponse(axiosInstance.post(ENDPOINT.CREATE_TAG_SPECTRUM_FEATURES, { ...params, tag_id: tagId }))
      .then(
        (res) => {
          dispatch(success(machine_id, site_id, res));
        },
        (error) => {
          dispatch(failure(machine_id, site_id, error));
        },
      );
  };
};

export const updateSpectrumFeature = (featureId, params, configId) => {
  const request = (machine_id, site_id) => ({ type: assetDetailsConstants.UPDATE_SPECTRUM_FEATURE_REQUEST, machine_id, site_id });
  const success = (machine_id, site_id, res) => ({ type: assetDetailsConstants.UPDATE_SPECTRUM_FEATURE_SUCCESS, feature: res, configId, machine_id, site_id });
  const failure = (machine_id, site_id, error) => ({ type: assetDetailsConstants.UPDATE_SPECTRUM_FEATURE_FAILURE, error, machine_id, site_id });

  return (dispatch, getState) => {
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    dispatch(request(machine_id, site_id));

    return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_TAG_SPECTRUM_FEATURES(featureId), params))
      .then(
        (res) => {
          dispatch(success(machine_id, site_id, res));
        },
        (error) => {
          dispatch(failure(machine_id, site_id, error));
        },
      );
  };
};

export const deleteSpectrumFeature = (featureId, configId) => {
  const request = (machine_id, site_id) => ({ type: assetDetailsConstants.DELETE_SPECTRUM_FEATURE_REQUEST, machine_id, site_id });
  const success = (machine_id, site_id, featureId) => ({ type: assetDetailsConstants.DELETE_SPECTRUM_FEATURE_SUCCESS, featureId, configId, machine_id, site_id });
  const failure = (machine_id, site_id, error) => ({ type: assetDetailsConstants.DELETE_SPECTRUM_FEATURE_FAILURE, error, machine_id, site_id });

  return (dispatch, getState) => {
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    dispatch(request(machine_id, site_id, featureId));

    return handleResponse(axiosInstance.delete(ENDPOINT.UPDATE_TAG_SPECTRUM_FEATURES(featureId)))
    // return service.deleteSpectrumFeature(featureId)
      .then(
        () => {
          dispatch(success(machine_id, site_id, featureId));
        },
        (error) => {
          dispatch(failure(machine_id, site_id, error));
          dispatch(alertActions.error(error.message));
        },
      );
  };
};

export const getForcingFrequencies = (tagId, timestamp) => {
  const request = (machine_id, site_id) => ({ type: assetDetailsConstants.GET_FORCING_FREQUENCY_REQUEST, machine_id, site_id });
  const success = (machine_id, site_id, features, tagId) => ({ type: assetDetailsConstants.GET_FORCING_FREQUENCY_SUCCESS, features, tagId, machine_id, site_id });
  const failure = (machine_id, site_id, error) => ({ type: assetDetailsConstants.GET_FORCING_FREQUENCY_FAILURE, tagId, error, machine_id, site_id });
  return (dispatch, getState) => {
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    dispatch(request(machine_id, site_id));

    return handleResponse(axiosInstance.get(ENDPOINT.GET_FORCING_FREQUENCIES(tagId), { params: { timestamp } })).then(
      (res) => {
        dispatch(success(machine_id, site_id, res.features, tagId));
        return res.features;
      },
      (error) => {
        dispatch(failure(machine_id, site_id, error));
        // throw Error(error);
      }
    );
  };
};
