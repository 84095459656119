import React from 'react';
import PropTypes from 'prop-types';

const AssetIcon = ({ width, height, fill }) => (
<svg width={width} height={height} viewBox="0 0 16 16" version="1.1">
    <g id="AH_v3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ah_v3-4-0-0" transform="translate(-36.000000, -225.000000)">
            <g id="more_dd" transform="translate(16.000000, 98.000000)">
                <g id="4" transform="translate(20.000000, 127.000000)">
                    <g id="asset_class-icon">
                        <g id="bound" fill="#FFFFFF">
                            <rect id="icon-bg" x="0" y="0" width="16" height="16" />
                        </g>
                        <g id="icon">
                            <circle id="Oval" stroke={fill} strokeWidth="1.5" cx="8" cy="8" r="7.25" />
                            <text id="AC" fontFamily="OpenSans-Extrabold, Open Sans" fontSize="6" fontWeight="600" fill={fill}>
                                <tspan x="3.875" y="10">AC</tspan>
                            </text>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

AssetIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

AssetIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: '#999B95'
};

export default AssetIcon;
