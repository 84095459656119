import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import ClearFilterIcon from 'common/images/ClearFilterIcon';
import Button_T from 'common/components/atoms/Button';
import fonts from 'common/styles/fonts';
import colors from 'common/styles/colors';
import { FlexContainer } from '../atoms';
import { FILTER_LEFT_MARGIN } from '../atoms/SearchFilters';
import MoreFilters from './MoreFilters';

const Button = styled(Button_T)`
  ${fonts.lightLabel}
  background-color: white; 
  border-color: black;
  stroke: none;
  &:hover {
    border-color: ${props => props.theme.primaryColor}; 
  }
`;

const DynamicFilters = ({
  bufferSpace,
  panes,
  showClearFilter,
  clearAllFilters
}) => {
  const [filterSplitIndex, setFiltersSplitIndex] = useState(panes.length);
  const filtersContainerRef = useRef();
  const filterItemLengthsRef = useRef([]);
  const [renderAllFilters, setRenderAll] = useState(true); // render all initially to calculate widths
  const { current: resizeObserver } = useRef(new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      let miscItemsWidth = 0;
      const miscItems = entry.target.querySelectorAll('.filter-misc-items');
      miscItems.forEach((item) => {
        miscItemsWidth += item.offsetWidth;
      });
      findSplitIndex(entry.target.offsetWidth - miscItemsWidth - bufferSpace);
    });
  }));

  useEffect(() => {
    calcFilterItemsLengths();
    resizeObserver.observe(filtersContainerRef.current.parentNode);
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    // recalculate lengths if filters applied
    setRenderAll(true);
    // this will trigger length calculation
  }, [showClearFilter]);

  useEffect(() => {
    if (renderAllFilters) {
      calcFilterItemsLengths();
    }
  }, [renderAllFilters]);

  useEffect(() => {
    const searchInput = document.querySelector('input[name*="-search-box"]');
    const handleFocusChange = () => {
      setFiltersSplitIndex(panes.length);
      calcFilterItemsLengths();
    };

    if (searchInput) {
      searchInput.addEventListener('focus', handleFocusChange);
      searchInput.addEventListener('blur', handleFocusChange);
    }

    return () => {
      if (searchInput) {
        searchInput.removeEventListener('focus', handleFocusChange);
        searchInput.removeEventListener('blur', handleFocusChange);
      }
    };
  }, [panes]);

  const calcFilterItemsLengths = () => {
    filterItemLengthsRef.current = [];
    if (filtersContainerRef.current) {
      filtersContainerRef.current.querySelectorAll('.filter-container').forEach(
        (item) => {
          const countBadge = item.querySelector('.count-badge');
          const countBadgeWidth = countBadge ? countBadge.offsetWidth : 0;
          filterItemLengthsRef.current.push(item.offsetWidth + countBadgeWidth);
        }
      );
    }
    setRenderAll(false);

    const parent = filtersContainerRef.current.parentNode;
    let miscItemsWidth = 0;
    const miscItems = parent.querySelectorAll('.filter-misc-items');
    miscItems.forEach((item) => {
      miscItemsWidth += item.offsetWidth;
    });
    const searchInput = document.querySelector('input[name*="-search-box"]');
    const searchInputWidth = searchInput && document.activeElement === searchInput ? searchInput.offsetWidth : 0;
    const clearFilterWidth = showClearFilter ? filterItemLengthsRef.current[filterItemLengthsRef.current.length - 1] : 0;

    const availableWidth = parent.offsetWidth - miscItemsWidth - bufferSpace - searchInputWidth - clearFilterWidth;
    findSplitIndex(availableWidth);
  };

  const findSplitIndex = (availableWidth) => {
    const [moreTabWidth, iconWidth] = filterItemLengthsRef.current.slice(-2);
    let sum = 2 * FILTER_LEFT_MARGIN + moreTabWidth + iconWidth;
    let splitIndex = filterItemLengthsRef.current.length;

    for (let i = 0; i < filterItemLengthsRef.current.length - 1; i++) {
      if (i === filterItemLengthsRef.current.length - 2) sum -= moreTabWidth + iconWidth + 2 * FILTER_LEFT_MARGIN;
      if (sum + filterItemLengthsRef.current[i] + FILTER_LEFT_MARGIN > availableWidth) {
        splitIndex = i;
        break;
      } else {
        sum += filterItemLengthsRef.current[i] + FILTER_LEFT_MARGIN;
      }
    }

    setFiltersSplitIndex(splitIndex);
  };

  const splitAndGetFilterItems = () => {
    const splitIndex = renderAllFilters ? panes.length : filterSplitIndex;

    const filterItems = panes.slice(0, splitIndex);
    if (renderAllFilters || !_.isEmpty(panes.slice(splitIndex))) {
      filterItems.push(<MoreFilters filters={panes.slice(splitIndex)}>More Filters</MoreFilters>);
    }
    if (renderAllFilters || showClearFilter) {
      filterItems.push(
        <Button title="Clear Filters" onClick={clearAllFilters} className="filter-container" secondary={colors.black} fontSize="11px" margin="0 0 0 28px">
          <ClearFilterIcon />
        </Button>
      );
    }
    return filterItems;
  };

  return (
    <FlexContainer innerRef={filtersContainerRef} className="dynamic-filters" alignItems="center" width="100%" marginleft="7px">
      {splitAndGetFilterItems()}
    </FlexContainer>
  );
};

DynamicFilters.propTypes = {
  bufferSpace: PropTypes.number,
  panes: PropTypes.array.isRequired,
};

DynamicFilters.defaultProps = {
  bufferSpace: 0
};

export default DynamicFilters;
