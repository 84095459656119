import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import LoadingSvg from '../../images/LoadingSvg';
import TickingSvg from '../../images/TickingSvg';

const Button = styled.button`
  font-family: "Petasense Open Sans";
  letter-spacing: 1.2px;
  position: relative;
  background-color: ${props => props.theme.primaryColor};
  font-weight: 600;
  line-height: ${props => props.lineHeight ? props.lineHeight : '12px'};
  ${props => props.height && `height: ${props.height}`}
  ${props => props.inref && `ref: ${props.inref};`}
  font-size: ${props => props.fontSize};
  margin: ${props => props.margin};
  border: 1px solid ${props => props.theme.primaryColor};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${props => props.theme.colors.white};
  padding: 0.8em 1em;
  ${props => (props.text ? `
    border: none;
    color: ${props.theme.primaryColor};
    background-color: transparent;
    padding: 0;
  ` : '')}
  &:focus {
    outline: none;
  }
  ${props => props.opaque ? 'opacity: 0.1;' : 'opacity: 1;'}
  ${props => (props.secondary ? `
    background-color: transparent;
    border: 1px solid ${props.theme.colors.grey};
    color: ${props.theme.colors.greyXXD};
    &:hover {
      ${props.disabled || `border: 1px solid ${props.secondary};`}
    }
    ` : '')}
    ${props => (props.cancel ? `
    background-color: transparent;
    border: 1px solid ${props.theme.colors.grey};
    color: ${props.theme.colors.greyXXD};
    &:hover {
      border: 1px solid ${props.theme.colors.greyXXD};
    }
   ` : '')}
   ${props => (props.danger ? `
    background-color: transparent;
    color: ${props.theme.colors.red};
    ${!props.text && `border: 1px solid ${props.theme.colors.grey}`};
    ${!props.disabled ? `&:hover {
      border: 1px solid ${props.theme.colors.red};
    }` : ''}
   ` : '')}
  ${props => props.bordercolor && `border: 1px solid ${props.bordercolor};`}
  ${props => props.color && `color: ${props.color}`}
  ${props => (!props.disabled && !props.text ? `
   &:hover {
     border: 1px solid ${props.theme.colors.greyXXD};
   }
  ` : '')}
  ${props => (props.disabled ? `
    border: none;
    color: ${props.text ? props.theme.colors.disabled : props.theme.colors.white};
    background-color: ${!props.text && props.theme.colors.disabled};
    cursor: not-allowed;
  ` : '')}
  ${props => (props.disabled && props.loading ? `
    color: ${props.theme.colors.white};
    background-color: ${props.theme.primaryColor};
  ` : '')}
  ${props => (props.left ? `
    margin-right: auto;
  ` : '')}
  ${props => (props.right ? `
    margin-left: auto;
  ` : '')}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
`;

Button.propTypes = {
  text: PropTypes.bool,
  secondary: PropTypes.string,
  cancel: PropTypes.bool,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  fontSize: PropTypes.string
};

Button.defaultProps = {
  fontSize: '14px',
  text: false,
  secondary: undefined,
  disabled: false,
  color: undefined,
  cancel: false,
  danger: false,
};

const ButtonTransparent = styled(Button)`
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  font-size: ${props => props.fontSize ? props.fontSize : '20px'};
  padding: 12px 9px;
  border: none !important;
  width: ${props => props.width ? props.width : 'auto'};
`;

const AnimatedButton_T = ({
  loading,
  success,
  failure,
  children,
  error,
  fill,
  ...rest
}) => (
  <Button loading={loading} {...rest}>
    {(!loading && !success && !failure) ? children : '' }
    {(loading && !success && !error) ? <LoadingSvg fill={fill} /> : ''}
    {(success && !error) ? (
      <>
        {'Saved'}
        <TickingSvg
          style={{ position: 'relative', left: '3px' }}
          stroke={fill}
        />
      </>) : ''}
  </Button>
);

AnimatedButton_T.propTypes = {
  fill: PropTypes.string,
  text: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failure: PropTypes.bool,
  secondary: PropTypes.string,
  cancel: PropTypes.bool,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  fontSize: PropTypes.string
};

AnimatedButton_T.defaultProps = {
  fontSize: '14px',
  fill: '#ffff',
  text: false,
  loading: false,
  success: false,
  failure: false,
  secondary: undefined,
  disabled: false,
  color: undefined,
  cancel: false,
  danger: false,
};

Button.displayName = 'Button';

const AnimatedButton = styled(AnimatedButton_T)``;

export default styled(Button)``;
export { AnimatedButton, ButtonTransparent };
