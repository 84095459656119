import { userConstants } from '../user.constants';

const initialState = {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    // Login
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return { loggedIn: true };
    case userConstants.LOGIN_FAILURE:
      return {};

    // Logout
    case userConstants.LOGOUT:
      return {};

    // Signup
    case userConstants.REGISTER_REQUEST:
      return {
        registering: true,
        tokenDetails: state.tokenDetails
      };
    case userConstants.REGISTER_SUCCESS:
      return { loggedIn: true };
    case userConstants.REGISTER_FAILURE:
      return { tokenDetails: state.tokenDetails };

    // Token details
    case userConstants.TOKEN_DETAILS_REQUEST:
      return { validatingToken: true };
    case userConstants.TOKEN_DETAILS_SUCCESS:
      return { tokenDetails: action.details };
    case userConstants.TOKEN_DETAILS_FAILURE:
      return { error: action.error.message };
    default:
      return state;
  }
}
