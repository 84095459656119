import React, { Component } from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FlexContainer from 'common/components/atoms/FlexContainer';
import InputField from 'common/components/atoms/InputField';
import NoteFooter from './NoteFooter';
import * as assetDetailsActions from '../../actions/assetDetails.actions';

const NoteContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  margin-left: 4.5em;
  margin-right: 1.5em;
  margin-bottom: 1.7em;
  textarea {
    margin-bottom: 1em;
  }
`;

class NoteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: '',
      savedNote: false,
      loading: false,
      error: ''
    };
  }

  onNoteChange = (e) => {
    if (e.target.value.length > 500) return;
    this.setState({ note: e.target.value });
  }

  getChartRange = (brushSelection, chartType, config) => {
    if (!brushSelection) {
      switch (chartType) {
        case 'trend':
          return [
            new Date(config.trends_data[0].trend_data[0].x),
            new Date(config.trends_data[0].trend_data[config.trends_data[0].trend_data.length - 1].x)
          ];
        case 'spectrum':
          return [
            config.spectrum.spectrum_data[0].x,
            config.spectrum.spectrum_data[config.spectrum.spectrum_data.length - 1].x
          ];
        case 'waveform':
          return [
            config.waveform.waveform_data[0].x,
            config.waveform.waveform_data[config.waveform.waveform_data.length - 1].x
          ];
        default:
          return [];
      }
    }
    if (chartType === 'trend') return [new Date(brushSelection[0]), new Date(brushSelection[1])];
    return brushSelection;
  }

  saveNote = () => {
    const { feature, chart_type, amp_type, data_source, tag_id, config, brushSelected, machine_id } = this.props;
    const latestMeasurementId =
    config.trends_data[0].trend_data[config.trends_data[0].trend_data.length - 1].measurement_id;
    const measurement_id = this.props.measurement_id || latestMeasurementId;
    const mesurementDatapointIdx = _.findIndex(config.trends_data[0].trend_data, d => d.measurement_id === measurement_id);
    const date = new Date(config.trends_data[0].trend_data[mesurementDatapointIdx].x);
    const x_axis_range = this.getChartRange(brushSelected, chart_type, config);
    const params = {
      chart_type,
      note_text: this.state.note,
      chart_context: {
        amp_type,
        feature,
        data_source,
        tag_id,
        date,
        measurement_id,
        x_axis_range
      }
    };
    this.setState({ loading: true });
    this.props.assetDetailsActions.addNote(machine_id, params).then(
      () => {
        this.setState({ savedNote: true, loading: false });
      },
      (error) => {
        this.setState({ error: error.message, loading: false });
      }
    );
  }

  render() {
    const { savedNote, note, error, loading } = this.state;
    const { machine_id, onCancel, showWidth } = this.props;
    return (
    <>
      <NoteContainer>
        <InputField
          maxlength="10"
          type="textarea"
          placeholder="Add your note..."
          value={note}
          onChange={this.onNoteChange}
        />
      </NoteContainer>
      <NoteFooter
        savedNote={savedNote}
        error={error}
        onCancel={onCancel}
        loading={loading}
        note={note}
        onSave={this.saveNote}
        machineId={machine_id}
        width={showWidth}
      />
    </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch)
});

export default connect(null, mapDispatchToProps)(NoteForm);
