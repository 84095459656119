import styled from 'styled-components';

import { ColorAccToCondition } from 'common/constants';


const ColoredCircle = styled.div`
  background-color: ${props => ColorAccToCondition(props.type)};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.4em;
  width: ${props => props.size || '0.8em'};
  height: ${props => props.size || '0.8em'};
`;
export default ColoredCircle;
