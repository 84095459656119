/* eslint-disable react/jsx-no-undef */
/* eslint-disable space-infix-ops */
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { getMachineBuilderMetadata } from 'home/Machines/MachineBuilder/actions/machineBuilder.actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ENDPOINT } from 'common/constants';
import { FlexContainer } from 'common/components/atoms';
import BrowseButton from 'home/HomePage/components/atoms/BrowseButton';
import { FileUploadModal } from 'common/components/molecules';
import Thumbnail from 'home/Machines/MachineDetails/Pictures/components/molecules/Thumbnail';
import Carousel from 'home/Machines/MachineDetails/Pictures/components/organisms/Carousel';
import AlertPrompt from 'common/components/organisms/AlertPrompt';
import { SelectedOption } from 'common/components/atoms/SelectedOption';
import colors from 'common/styles/colors';
import Error from 'home/AssetHierarchy/components/atoms/Error';
import RBAC from 'common/rbac/RBAC';
import SplashScreen from 'common/components/molecules/SplashScreen';
import PSPhotos from 'common//images/PS_photos.png';
import { mapComponentToResource, operations } from 'common/rbac/constants';
import HealthHeader from 'home/Machines/MachineDetails/Health/components/atoms/HealthHeader';
import PlusSvg from 'common/images/PlusSvg';
import { getActivePartnerBasedOnDomain, getBaseUrl } from 'home/DomainName/DomainConstants';
import * as attachmentActions from '../actions/attachments.actions';
import DocumentView from '../components/atoms/DocumentView';
import { MAX_FILE_SIZE } from '../constants/attachments.constants';

const FilesView = styled(FlexContainer).attrs({
  direction: 'row',
  flexGap: '23px',
  flexWrap: 'wrap',
})`
  & > *, .ed-multiselect-selected-option {
    margin: 9.5px 11.5px 9.5px 0;
  }
  padding: 0;
  margin-bottom: 1.5em;
  &:first-of-type:not(:only-of-type) {
    margin-bottom: 0;
  }
`;

const AttachmentsSection = styled.section`
  ${props => props.originType === 'machine' && `
    height: calc(100vh - 110px);
    background: white;
    overflow-y: auto;
    padding-left: 20px;
    padding-top: 20px;
    position: relative;
  `}
`;

function Attachments({
  files,
  originType,
  originId,
  attachmentActions,
  upload,
  attachments,
  showBrowseButton,
  allowed_extensions,
  setAttachmentsError,
  getMachineBuilderMetadata,
  showSelectedFiles,
  showExistingAtts,
  allowUpload,
  disableCarousel,
  uploadButtonText,
  thumbnailHeightAndWidth,
}) {
  const setPickedFiles = (e) => {
    const eventFiles = e.target.files;
    if (eventFiles.length >= 1) {
      const fileErrors = [...eventFiles].map(file => getFileError(file)).filter(err => err !== '');
      if (allowUpload && !_.isEmpty(fileErrors)) {
        fileErrors.forEach(err => toastr.error(err));
        return;
      }
      attachmentActions.setAttachmentsFiles(eventFiles, originType);
    }
    if (showExistingAtts) {
      setShowUploadModal(eventFiles.length >= 1);
    }
  };

  const getAttachmentUrl = (picture_id, thumbnail = false) => (
    `${getBaseUrl()}/${ENDPOINT.DOWNLOAD_ATTACHMENT(picture_id, thumbnail)}`
  );

  const getFileExtension = filename => (
    filename.split('.').pop().toLowerCase()
  );

  const getFileError = (file) => {
    if (!_.isEmpty(allowed_extensions) && !allowed_extensions.includes(getFileExtension(file.name))) {
      return `'.${getFileExtension(file.name)}' files are not supported`;
    }
    if (file.size / 1024 / 1024 > MAX_FILE_SIZE) {
      return `'${file.name}' is too large (> ${MAX_FILE_SIZE}MB)`;
    }
    return '';
  };

  const deleteAttachment = (attachment_id) => {
    attachmentActions.deleteAttachment(originType, originId, attachment_id, () => {
      setDeletingId(null);
      setCarouselIdx(null);
      setShowDeleteModal(false);
    }
    );
  };

  const onCarouselDeleteHandler = (attachment_id) => {
    setDeletingId(attachment_id);
    deleteAttachment(attachment_id);
  };

  const onDeleteHandler = (attachment_id) => {
    setDeletingId(attachment_id);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    const partner = getActivePartnerBasedOnDomain();
    const title = partner.title;
    document.title = `${title} | Attachments `;
    if (_.isEmpty(allowed_extensions)) {
      getMachineBuilderMetadata();
    }
    if (originType === 'machine') {
      attachmentActions.getMachineAttachments(originId);
    }
    return () => {
      attachmentActions.clearAttachmentsFiles(originType);
      attachmentActions.resetUpload(originType);
    };
  }, []);

  useEffect(() => {
    const index = upload && _.isNumber(upload.index) ? upload.index + 1 : 0;
    if (allowUpload && files[index]) {
      attachmentActions.uploadAttachment(originType, originId, files, index);
    }

    const errors = files.map(file => getFileError(file)).filter(err => err !== '');
    setErrors(errors);
    if (!_.isEmpty(errors)) {
      setAttachmentsError(true);
    } else {
      setAttachmentsError(false);
    }
  }, [files]);

  const browseButtonRef = useRef();
  const [showFileUploadModal, setShowUploadModal] = useState(false);
  const [pictureCarouselIdx, setCarouselIdx] = useState(null);
  const [deletingAttId, setDeletingId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errors, setErrors] = useState([]);

  const pictures = attachments && attachments.filter(att => att.type === 'image').map(att => ({
    ...att,
    url: getAttachmentUrl(att.id),
    label: att.filename
  }));
  const documents = attachments && attachments.filter(att => att.type === 'document').map(att => ({
    ...att,
    url: getAttachmentUrl(att.id)
  }));

  return (
    <AttachmentsSection id="attachments" originType={originType}>
      {showExistingAtts && (
        <>
        {pictureCarouselIdx !== null && (
          <Carousel
            index={pictureCarouselIdx}
            images={pictures}
            close={() => setCarouselIdx(null)}
            deleting={deletingAttId}
            performDelete={picture_id => onCarouselDeleteHandler(picture_id)}
          />
        )}
        {originType === 'machine' && !_.isEmpty(attachments) && (
          <div style={{ position: 'absolute', right: '20px' }}>
            <RBAC
              resource={mapComponentToResource.Attachments}
              operation={operations.Create}
              yes={(
              <BrowseButton buttonText="" inputRef={browseButtonRef} setPickedFiles={setPickedFiles} buttonImage={<PlusSvg width="16px" height="16" />} />
            )}
            />
          </div>
        )}
        {!_.isEmpty(pictures) && (
          <div>
            <div> { originType === 'machine' ? <HealthHeader>Pictures</HealthHeader> : <HealthHeader />} </div>
            <FilesView>
              {pictures.map((picture, idx) => (
                <Thumbnail
                  click={() => !picture.deleted && !disableCarousel && setCarouselIdx(idx)}
                  image={getAttachmentUrl(picture.id, true)}
                  externalDeleteHandler={id => onDeleteHandler(id)}
                  key={picture.id}
                  id={picture.id}
                  title={picture.filename}
                  height={thumbnailHeightAndWidth}
                  width={thumbnailHeightAndWidth}
                  showLabel={false}
                  showOverlayGradient={false}
                  deleting={picture.id}
                  itemDeleted={picture.deleted}
                  disableCarousel={disableCarousel}
                />
              ))}
            </FilesView>
          </div>
        )}
        {!_.isEmpty(documents) && (
          <div>
            <div> { originType === 'machine' ? <HealthHeader>Documents</HealthHeader> : <HealthHeader />}</div>
            <FilesView>
              {documents.map(document => (
                <DocumentView
                  id={document.id}
                  key={document.id}
                  filename={document.filename}
                  url={document.url}
                  onDeleteHandler={id => onDeleteHandler(id)}
                  itemDeleted={document.deleted}
                  showFileName={!document.deleted}
                />
              ))}
            </FilesView>
          </div>
        )}
        {showFileUploadModal && (
          <FileUploadModal
            title="Add attachments"
            close={() => setShowUploadModal(false)}
            files={files.filter(file => file.uploaded !== false)}
            rejectedFiles={files.filter(file => file.uploaded === false)}
            addClick={() => browseButtonRef.current.click()}
            doneClick={() => setShowUploadModal(false)}
            upload={upload}
          />
        )}
        {showDeleteModal && (
          <AlertPrompt
            message="Are you sure you want to delete this attachment?"
            proceedColor={colors.red}
            onProceed={() => deleteAttachment(deletingAttId)}
            onCancel={() => setShowDeleteModal(false)}
          />
        )}
        </>
      )}
      {showSelectedFiles && (
        <>
          <FilesView>
            {files.map((file, idx) => (
              <SelectedOption
                key={idx}
                option={{ text: file.name }}
                index={idx}
                onClick={() => attachmentActions.removeAttachmentFile(file.name, originType)}
                backgroundColor={getFileError(file) === '' ? colors.greyL : colors.error}
                color={getFileError(file) === '' ? colors.black : colors.white}
              />
            ))}
          </FilesView>
          {errors.map(error => <Error padding="0 0 8px 0">{error}</Error>)}
        </>
      )}
      {_.isEmpty(attachments) && originType === 'machine' && (
        <div style={{ position: 'relative', top: '100px' }}>
          <SplashScreen
            heading="Upload asset pictures and documents from your desktop or mobile app"
            image={PSPhotos}
            showLinkButton
          >
            {'Asset pictures and documnets help users easily recognize machines during troubleshooting. It also helps analysts do better analysis.'}
            <div style={{ position: 'relative', top: '30px' }}>
              <RBAC
                resource={mapComponentToResource.Attachments}
                operation={operations.Create}
                yes={(
                  <BrowseButton buttonText={uploadButtonText} inputRef={browseButtonRef} setPickedFiles={setPickedFiles} />
                )}
              />
            </div>
          </SplashScreen>
        </div>
      )}
      {showBrowseButton && originType !== 'machine' && (
        <RBAC
          resource={mapComponentToResource.Attachments}
          operation={operations.Create}
          yes={(
            <BrowseButton buttonText={uploadButtonText} inputRef={browseButtonRef} setPickedFiles={setPickedFiles} />
          )}
        />
      )}
    </AttachmentsSection>
  );
}

const mapDispatchToProps = dispatch => ({
  attachmentActions: bindActionCreators(attachmentActions, dispatch),
  getMachineBuilderMetadata: bindActionCreators(getMachineBuilderMetadata, dispatch),
});

const mapStateToProps = (state, ownProps) => ({
  allowed_extensions: state.machines.machineBuilder.metadata.attachments_metadata &&
    state.machines.machineBuilder.metadata.attachments_metadata.allowed_extensions,
  upload: state.attachments.upload && state.attachments.upload[ownProps.originType],
  files: state.attachments.files && state.attachments.files[ownProps.originType],
  attachments: (ownProps.originType === 'machine') ? state.machineDetails.attachments : ownProps.attachments,
  thumbnailHeightAndWidth: (ownProps.originType === 'machine') ? '200px' : '100px',
});

Attachments.propTypes = {
  files: PropTypes.array,
  allowed_extensions: PropTypes.array,
  showBrowseButton: PropTypes.bool,
  setAttachmentsError: PropTypes.func,
  disableCarousel: PropTypes.bool,
  uploadButtonText: PropTypes.string,
  thumbnailHeightAndWidth: PropTypes.string
};

Attachments.defaultProps = {
  files: [],
  allowed_extensions: [],
  showBrowseButton: true,
  setAttachmentsError: () => {},
  disableCarousel: false,
  uploadButtonText: 'Attach files...',
  thumbnailHeightAndWidth: '100px',
};

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
