import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'common/components/atoms/FlexContainer';
import H2 from 'common/typography/H2/H2';
import InputField_T from 'common/components/atoms/InputField';

import {
  DAYS_OPTIONS,
  READINGS_OPTIONS,
  BASE_GRAPH_OPTIONS,
} from '../../../constants/overallAlarm.constants';

const InputField = styled(InputField_T)`
  min-width: 200px;
`;

const BaseGraphSettings = ({
  disabled,
  baseGraphSettings,
  updateOverallAlarmSettings
}) => (
  <FlexContainer marginright="2em" direction="column">
    <H2>Base graph</H2>
    <InputField
      disabled={baseGraphSettings.type !== BASE_GRAPH_OPTIONS.LAST_READINGS || disabled}
      label="Count of last"
      options={READINGS_OPTIONS}
      onClickSelectable={() => {
        if (baseGraphSettings.type === BASE_GRAPH_OPTIONS.LAST_READINGS) return;
        updateOverallAlarmSettings('base_graph', {
          type: BASE_GRAPH_OPTIONS.LAST_READINGS,
          value: READINGS_OPTIONS[0].value
        });
      }}
      onChange={(e, data) => {
        updateOverallAlarmSettings('base_graph', {
          ...baseGraphSettings,
          value: data.value
        });
      }}
      selectable="radio"
      selectableValue={baseGraphSettings.type === BASE_GRAPH_OPTIONS.LAST_READINGS}
      type="select"
      value={baseGraphSettings.type === BASE_GRAPH_OPTIONS.LAST_READINGS ? baseGraphSettings.value : ''}
    />
    <InputField
      disabled={baseGraphSettings.type !== BASE_GRAPH_OPTIONS.LAST_DAYS || disabled}
      label="Count from the last"
      options={DAYS_OPTIONS}
      onClickSelectable={() => {
        if (baseGraphSettings.type === BASE_GRAPH_OPTIONS.LAST_DAYS) return;
        updateOverallAlarmSettings('base_graph', {
          type: BASE_GRAPH_OPTIONS.LAST_DAYS,
          value: DAYS_OPTIONS[2].value
        });
      }}
      onChange={(e, data) => {
        updateOverallAlarmSettings('base_graph', {
          ...baseGraphSettings,
          value: data.value
        });
      }}
      prefix="days"
      selectable="radio"
      selectableValue={baseGraphSettings.type === BASE_GRAPH_OPTIONS.LAST_DAYS}
      type="select"
      value={baseGraphSettings.type === BASE_GRAPH_OPTIONS.LAST_DAYS ? baseGraphSettings.value : ''}
    />
  </FlexContainer>
);

BaseGraphSettings.propTypes = {
  updateOverallAlarmSettings: PropTypes.func.isRequired,
  baseGraphSettings: PropTypes.object
};

BaseGraphSettings.defaultProps = {
  baseGraphSettings: {}
};


export default BaseGraphSettings;
