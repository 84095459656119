import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import styled from 'styled-components';

import Modal from '../../../common/components/organisms/Modal';
import FlexContainer from '../../../common/components/atoms/FlexContainer';
import InputField from '../../../common/components/atoms/InputField';
import Button from '../../../common/components/atoms/Button';
import { Search } from '../../../common/images/FaIcons';
import Checkbox from '../../../common/components/atoms/Checkbox';
import LoadingSvg from '../../../common/components/atoms/Loading';

import * as sensorsActions from '../actions/sensors.actions';

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: 15px;
`;

const SensorContainer = styled(FlexContainer).attrs({
  flexWrap: 'wrap'
})`
  width: 100%;
  overflow: auto;
  padding: 20px;
  border: 1px solid #E5E8E1;
  border-radius: 8px;
  max-height: 400px;
  & > * {
    font-size: 14px;
    flex-basis: 50%;
    margin-bottom: 20px;
    font-weight: 400;
  }
`;

const InputFieldSearch = styled(InputField)`
  input {
    padding-left: 0;
  }
`;

const CheckboxContainer = styled(FlexContainer).attrs({ alignItems: 'center' })`
  &:hover {
    cursor: pointer;
  }
  span {
    font-family: 'Petasense Open Sans';
    color: ${props => props.selected ? 'black' : '#999B95'};
    font-size: 14px;
    font-weight: 600;
    margin-left: 0.5em;
  }
`;

const AssignSensorModal = (props) => {
  const [selectedSensor, setSelectedSensor] = useState({});
  const [sensors, updateSensors] = useState([]);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    props.sensorsActions.getUnassignedSensors().then(
      (items) => {
        updateSensors(items);
      },
      () => {}
    );
  }, []);

  const selectSensor = (sensor) => {
    setSelectedSensor(sensor);
  };

  const assignSensor = () => {
    if (_.isEmpty(selectedSensor)) return;
    props.assignSensorToTx(props.port_id, selectedSensor.id);
    props.close();
  };

  const onSearch = (e) => {
    setSearchKey(e.target.value);
    const filtered = props.unassignedSensors.list.filter(sensor => sensor.serial_number.toLowerCase().includes(e.target.value.toLowerCase()));
    updateSensors(filtered);
  };

  return (
    <Modal
      title="Associate Sensors"
      close={props.close}
      width="600px"
      padding="0"
      maxHeight="600px"
    >
      <InputFieldSearch
        type="text"
        prefix={<Search />}
        prefixSide="left"
        value={searchKey}
        onChange={onSearch}
      />

      <SensorContainer>
        {(props.unassignedSensors.loading) && (
          <div style={{ margin: 'auto' }}>
            <LoadingSvg />
          </div>
        )}
        {sensors.map((sensor, idx) => (
          <CheckboxContainer
            key={`checkbox-container-${idx}`}
            alignItems="center"
            onClick={() => selectSensor(sensor)}
            selected={sensor.serial_number === selectedSensor.serial_number}
          >
            <Checkbox value={sensor.serial_number === selectedSensor.serial_number} />
            <span>{sensor.serial_number}</span>
          </CheckboxContainer>
        ))}
      </SensorContainer>
      <ButtonContainer>
        <Button
          disabled={_.isEmpty(selectedSensor)}
          onClick={assignSensor}
        >
          Done
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

AssignSensorModal.propTypes = {
  port_id: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
  assignSensorToTx: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  unassignedSensors: state.sensors.unassignedSensors
});

const mapDispatchToProps = dispatch => ({
  sensorsActions: bindActionCreators(sensorsActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignSensorModal);
