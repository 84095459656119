import { userConstants } from '../user.constants';

export function user(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_SUCCESS:
      return {
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        user: action.user,
      };
    case userConstants.GET_USER_FAILURE:
      return {
        error: action.error.message
      };
    case userConstants.CLEAR_USER_STATUS:
      return {};
    case userConstants.SELECT_ACCOUNT_SUCCESS:
      return {
        user: action.user,
      };
    case userConstants.UPDATE_USER_REQUEST:
      return {
        loading: true,
        user: { ...state.user }
      };
    case userConstants.UPDATE_USER_SUCCESS:
      return {
        user: {
          ...state.user,
          ...action.result
        }
      };
    case userConstants.UPDATE_USER_FAILURE:
      return {
        user: {
          ...state.user
        }
      };
    default:
      return state;
  }
}
