import styled from 'styled-components';
import PropTypes from 'prop-types';

const ChevronContainer = styled.div`
  position: absolute;  
  ${props => props.left && 'width: 25px; height: 25px; left: -12px; top: 8px'};
  ${props => props.right && 'width: 20px; height: 40px; left: -20px; top: 3px'};
  cursor: pointer;
  box-shadow: 0 0 8px 4px rgba(0,0,0,0.08);
  background-color: #FFFFFF;
  ${props => props.left && 'border-radius: 50%'};
  ${props => props.right && 'border-top-left-radius: 20px; border-bottom-left-radius: 20px'};
  z-index: 1;
  svg {
    color: #999b95;
  }
  &:hover {
    cursor: pointer;
    ${props => props.left && 'width: 40px; height: 40px; left: -20px; top: 3px'};
    ${props => props.right && 'width: 30px; height: 40px; left: -25px; top: 3px'};
    ${props => props.left && 'border-radius: 50%'};
    ${props => props.right && 'border-top-left-radius: 40px; border-bottom-left-radius: 40px'};
    .fa-sm {
      font-size: 1.2em;
    }
    svg {
      color: black;
    }
  }
  transition: all 0.2s;
`;

ChevronContainer.propTypes = {
  right: PropTypes.boolean,
  left: PropTypes.boolean,
};

ChevronContainer.defaultProps = {
  left: false,
  right: false,
};
export default ChevronContainer;
