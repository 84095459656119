import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Maybe = ({ condition, consequence, tag, Component, children, ...rest }) => {
  if (condition && typeof consequence === 'function') return consequence(rest, children);
  if (condition && typeof consequence === 'string') return React.createElement(consequence, rest, children);
  if (Component) return (<Component {...rest}>{children}</Component>);
  return React.createElement(tag, rest, children);
};

Maybe.propTypes = {
  condition: PropTypes.bool,
  tag: PropTypes.string,
  consequence: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Maybe.defaultProps = {
  condition: false,
  consequence: (props, children) => (<Fragment key={props.key}>{children}</Fragment>),
  tag: 'div',
};

export default Maybe;
