import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import * as d3_xyzoom from 'd3-xyzoom';
import _ from 'lodash';
import styled from 'styled-components';

import LoadingSvg from 'common/images/LoadingSvg';
import { IMPERIAL_UNIT_SYSTEM, unitSystemMap } from 'common/constants';
import * as gridUtils from '../utils/gridUtils';
import * as graphUtils from '../utils/graphUtils';
import * as defsUtils from '../utils/defsUtils';
import * as helpers from '../utils/helpers';
import { Plus, Minus, ExclamationTriangle, Info } from '../../../images/FaIcons';
import FlexContainer from '../../atoms/FlexContainer';
import InputField_T from '../../atoms/InputField';
import ButtonT from '../../atoms/Button';
import CrossSvg from '../../../images/CrossSvg';
import LineContext from '../components/LineContext';
import LineChartContainer from './LineChartContainer';
import UnlockedZoomSVG from '../../../images/UnlockedZoomSvg';
import LockedZoomSVG from '../../../images/LockedZoomSvg';
import EditIcon from '../../../images/BearingModal/EditIcon';
import ResetSvg from '../../../images/ResetSvg';

const ButtonContainer = styled.span`
  width: max-content;
  position: relative;
  left: -50px;
  top: 35px;
  display: flex;
  flex-direction: column;
`;

const InputField = styled(InputField_T)`
  margin-bottom: 5px;
`;

const MultiSetItem = styled(InputField_T)`
  position: absolute;
  background: transparent;
  ${props => props.left ? `left: ${props.left};` : 'left: -57px;'}
  border: none;
  cursor: pointer;
  padding: 0;
  box-shadow: none;
  z-index: 2;
  ${props => props.top ? `top: ${props.top};` : 'top: 0;'}
`;

const YAxisModalInfo = styled(FlexContainer)`
  padding: 0.5em 0;
`;

const Loader = styled.div`
  top: 0;
  left: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(239,240,238,0.5);
`;

const SvgContainer = styled.span`
  width: max-content;
  &:hover {
    cursor: ${props => props.nohover ? 'default' : 'pointer'};
  }
  ${props => props.marginright && `margin-right: ${props.marginright};`}
  ${props => props.absolute && 'position: absolute;'}
  ${props => props.right && `right: ${props.right};`}
`;

const Label = styled.label`
  font-size: 12px;
  font-family: 'Petasense Open Sans';
  color: #999B95;
  font-weight: 600;
  margin-bottom: 0;
  ${props => props.header && `
    font-size: 18px;
    font-weight: 400;
  `}
  ${props => props.color && `color: ${props.color};`}
`;

const YAxisDomainModal = styled.div`
  position: absolute;
  width: 300px;
  padding: 1em;
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.12);
  background-color: white;
  border-radius: 4px;
  top: -5px;
  left: -20px;
  z-index: 3;
  &:after {
    right: 100%;
    top: 18%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    /* box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5); */
    border-right-color: #fff;
    border-width: 10px;
    margin-top: -10px;
  }
`;

const Button = styled.span`
  width: max-content;
  cursor: pointer;
  &:hover {
    ${props => props.disabled ? 'cursor: not-allowed;' : 'svg{color: black;}'}
  }
`;

const ButtonX = styled.span`
  position: absolute;
  background: transparent;
  bottom: -42px;
  cursor: pointer;
  right: 0px;
  border: none;
  padding: 0;
  box-shadow: none;
`;

const ButtonY = styled.span`
  position: absolute;
  background: transparent;
  ${props => props.left ? `left: ${props.left};` : 'left: -57px;'}
  border: none;
  cursor: pointer;
  padding: 0;
  box-shadow: none;
  z-index: 2;
  ${props => props.top ? `top: ${props.top};` : 'top: 0;'}
`;

// Chart type which d3 zoom should not be applied
const NoChartZoomTypes = [
  'overallAlarm',
  'utilization',
  'context'
];

const NoChartDblClickZoom = [
  'spectrum',
  'demod_spectrum'
];

export const LineChart = (WrappedComponent) => {
  class LineChartWrapper extends WrappedComponent {
    constructor(props) {
      super(props);
      this.setRef = this.setRef.bind(this);
      this.drawGraph = this.drawGraph.bind(this);
      this.redraw = this.redraw.bind(this);
      this.drawAxis = this.drawAxis.bind(this);
      this.drawTitle = this.drawTitle.bind(this);
      this.drawYlabel = this.drawYlabel.bind(this);
      this.drawSelection = this.drawSelection.bind(this);
      this.drawSelectionLines = this.drawSelectionLines.bind(this);
      this.drawDataLines = this.drawDataLines.bind(this);
      this.drawPoints = this.drawPoints.bind(this);
      this.zoomHandler = this.zoomHandler.bind(this);
      this.addMouseoverEventListener = this.addMouseoverEventListener.bind(this);
      this.addClickEventListener = this.addClickEventListener.bind(this);
      this.addDoubleClickEventListener = this.addDoubleClickEventListener.bind(this);
      this.changeYAxisDomain = this.changeYAxisDomain.bind(this);
      this.drawXlabel = this.drawXlabel.bind(this);

      this.state = {
        decreaseDisabled: true,
        axisLocked: props.axisLocked || { x: false, y: true },
        yOriginalDomain: [0, 1],
        yAxisUpperLimit: '',
        transformY: 0,
        transformX: 0,
        kx: 1,
        ky: 1,
        resetActive: false,
        zoom: {
          transformX: 0,
          transformY: 0,
          kx: 1,
          ky: 1,
        },
        contextHeight: this.props.height === '400px' ? '50px' : '25px'
      };
    }

    componentDidUpdate(prevProps) {
      if (prevProps.height !== this.props.height ||
          prevProps.width !== this.props.width ||
        !_.isEqual(prevProps.hierarchyViewPane, this.props.hierarchyViewPane)
      ) {
        this.drawGraph();
      }
      if (prevProps.data && (!_.isEqual(prevProps.data, this.props.data))) {
        this.drawGraph();
      }
      if (prevProps.range && !_.isEqual(prevProps.range, this.props.range)) {
        this.zoomApplied = false;
        this.drawGraph();
      }
      // For SpectrumChart.js temp x axis unit change
      if (this.props.xUnit && !_.isEqual(prevProps.xUnit, this.props.xUnit)) {
        this.drawGraph();
        // if (this.refNode.drawGraph) this.refNode.drawGraph(this);
        if (this.lineContext && this.lineContext.refNode.setBrushToSelection) {
          if (this.refNode.state.storeBrushSelection.length > 0) {
            this.lineContext.refNode.setBrushToSelection(this.refNode.state.storeBrushSelection);
          }
        }
        if (this.state.yScaleDomain) this.y.domain(this.state.yScaleDomain);
        this.redraw();
        // hack to restore brush to null on units change
        if (this.lineContext && this.x && this.refNode.state.storeBrushSelection && this.refNode.state.storeBrushSelection.length === 0 || _.isEqual(this.refNode.state.storeBrushSelection, this.x.range())) {
          setTimeout(() => {
            this.lineContext.refNode.resetBrushSelection();
          }, 0);
        }
      }
      if (prevProps.contextChart !== this.props.contextChart) {
        if (this.props.domain === undefined) {
          const domain = [];
          this.props.data.forEach((data) => {
            const dataDomain = d3.extent(data, d => d.x);
            if ((dataDomain[0] !== undefined && dataDomain[0] < domain[0]) || !domain[0]) domain[0] = dataDomain[0];
            if ((dataDomain[1] !== undefined && dataDomain[1] > domain[1]) || !domain[1]) domain[1] = dataDomain[1];
          });
          this.x.domain(domain);
        } else {
          this.x.domain(this.props.domain);
        }
        if (this.props.multiBrush && this.state.resetActive) this.props.redrawAllSpectrums();
        else this.redraw();
        if (this.props.type === 'multiline') {
          this.drawGraph();
        }
      } else if (!_.isEqual(prevProps.activeData, this.props.activeData)) {
        // redraw lines if active selection is changed
        this.props.activeData.forEach((active, idx) => {
          const opacity = active ? 1 : 0;
          d3.select(`.${this.props.chartName}.dataline.idx-${idx}`)
            .attr('opacity', opacity);
        });
      }
      if (!_.isEqual(this.props.selection, prevProps.selection)) {
        this.drawSelection();
      }

      if (this.props.axisLocked && !_.isEqual(prevProps.axisLocked, this.props.axisLocked)) {
        this.setState({
          axisLocked: this.props.axisLocked
        }, this.zoomAxis);
      }

      if (!_.isEqual(this.props.points, prevProps.points)) {
        this.drawPoints();
      }

      if (!_.isEqual(this.props.ylabel, prevProps.ylabel)) {
        this.drawYlabel();
      }

      if (!_.isEqual(this.props.colors, prevProps.colors)) {
        this.redrawDataLines();
      }

      if (this.props.multiBrush && this.props.brushSelectionFromParent && !_.isEqual(this.props.brushSelectionFromParent, prevProps.brushSelectionFromParent)) {
        if (this.props.redrawAllSpectrums) {
          this.props.redrawSpectrum(this.props.index, this);
        }
      }
      if (this.props.transformFromParent && !_.isEqual(this.props.transformFromParent, prevProps.transformFromParent)) {
        const { transformX, transformY, kx, ky } = this.props.transformFromParent;
        const t = d3_xyzoom.xyzoomIdentity
          .translate(transformX, transformY)
          .scale(kx, ky);
        this.setState({
          transformX,
          transformY,
          kx,
          ky
        }, () => {
          const new_xScale = t.rescaleX(this.x2);
          const new_yScale = t.rescaleY(this.y2);
          this.y.domain(new_yScale.domain());
          this.x.domain(new_xScale.domain());
          this.redraw();
          if (this.zoom) d3.select(this.chart).call(this.zoom.transform, t);
        });
      }
      // TODO: in willreceive props ? and elsewhere ? draw a selection line
      // if (this.refNode.selection && this.props.selection) this.refNode.selection(this, this.props.selection);
      if (!NoChartZoomTypes.includes(this.props.type) && this.chart) {
        const transform = d3_xyzoom.xyzoomTransform(this.chart);
        if (!_.isEqual(transform, d3_xyzoom.xyzoomIdentity) && this.state.resetActive !== true) this.setState({ resetActive: true });
        else if (_.isEqual(transform, d3_xyzoom.xyzoomIdentity) && this.state.resetActive !== false) this.setState({ resetActive: false });
      }
    }

    componentDidMount() {
      this.drawGraph();
      if (this.props.multiBrush && this.props.index) {
        this.props.storeSpectrumRefs(this.props.index, this);
      }
    }

    drawGraph() {
      // init graph
      this.initializeGraph();

      // add defs section
      this.drawDefs();

      // draw axis and ticks and datalines
      this.drawAxis();

      // draw inline title
      this.drawTitle();

      // draw left side label
      this.drawYlabel();

      this.drawXlabel();

      // draw datalines
      this.drawDataLines();

      if (this.refNode.drawGraph) this.refNode.drawGraph(this);

      // draw a selection line
      this.drawSelection();

      this.drawPoints();

      this.addMouseoverEventListener();

      this.addClickEventListener();

      this.addDoubleClickEventListener();

      this.redrawDataLines(this.x, this.y, this); // TODO: utils context chart requires redrawdatalines for some reason
    }

    setNewTransform = (selection) => {
      const oldTransform = d3_xyzoom.xyzoomTransform(this.chart);
      const kx = this.container.getBoundingClientRect().width / (selection[1] - selection[0]);
      const t = d3_xyzoom.xyzoomIdentity
        .translate(-selection[0] * kx, oldTransform.y)
        .scale(kx, oldTransform.ky);

      this.setState({ transformX: -selection[0] * kx, kx }, () => {
        if (this.zoom) d3.select(this.chart).call(this.zoom.transform, t);
      });
    };

    zoomHandler() {
      if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'brush') return;
      const { x, y } = this.state.axisLocked;
      if (!this.chart) return;
      const transform = d3_xyzoom.xyzoomTransform(this.chart);
      const new_xScale = transform.rescaleX(this.x2);
      const new_yScale = transform.rescaleY(this.y2);

      const eventTransform = d3.event.transform;
      const xRange = this.x.range().map(transform.invertX, eventTransform);
      let brushRange = [];
      if (xRange[0] < this.x.range()[0] || xRange[1] > this.x.range()[1]) {
        if (xRange[0] < this.x.range()[0] && xRange[1] > this.x.range()[1]) {
          brushRange = [0, this.x.range()[1]];
        } else if (xRange[0] < this.x.range()[0] && xRange[1] <= this.x.range()[1]) {
          brushRange = [this.x.range()[0], xRange[1]];
        } else if (xRange[0] >= this.x.range()[0] || xRange[1] > this.x.range()[1]) {
          brushRange = [xRange[0], this.x.range()[1]];
        }
      } else {
        brushRange = xRange;
      }

      if (!y) {
        // const maxYdomain = ((this.y2.domain()[1] - this.y2.domain()[0]) / 0.1) + new_yScale.domain()[0];
        // const minYdomain = ((this.y2.domain()[1] - this.y2.domain()[0]) / 10) + new_yScale.domain()[0];
        this.setState({
          yScaleDomain: new_yScale.domain(),
          // maxYdomain: maxYdomain.toFixed(2),
          // minYdomain: minYdomain.toFixed(2),
          yAxisUpperLimit: new_yScale.domain()[1].toFixed(2),
          transformY: transform.y,
          ky: transform.ky
        });
        if (this.props.storeTransform) {
          this.props.storeTransform({ transformY: transform.y, ky: transform.ky });
        }
      }

      if (!x) {
        this.setState({ transformX: transform.x, kx: transform.kx });
        if (this.props.storeTransform) {
          this.props.storeTransform({ transformX: transform.x, kx: transform.kx });
          this.props.brushCb(brushRange);
        }
      }
      // hack to fix x axis zoom when ky < 0 and y axis is locked.
      if (transform.kx === 1 && transform.ky < 1) {
        if (d3.event.sourceEvent && d3.event.sourceEvent.wheelDelta > 0) {
          const t = d3_xyzoom.xyzoomIdentity
            .translate(transform.x, transform.y)
            .scale(1.000001, transform.ky);
          if (this.zoom) d3.select(this.chart).call(this.zoom.transform, t);
        }
      }

      if (!y) this.y.domain(new_yScale.domain());

      if (!x) this.x.domain(new_xScale.domain());
      if (this.props.multiBrush && this.props.redrawAllSpectrums) this.props.redrawAllSpectrums();
      else this.redraw();
      if (!x && this.lineContext && this.lineContext.refNode.zoomHandler) {
        this.lineContext.refNode.zoomHandler(this);
      }
      if (!x && this.props.type === 'baseline') {
        if (this.refNode.zoomHandlerBaseline) this.refNode.zoomHandlerBaseline(this);
      }
    }

    // hack to restore transform x, y to correct values after panning when one of the axis is locked
    onZoomEnd = () => {
      if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'brush') return;
      const { transformX, transformY, kx, ky } = this.state;

      if (!this.chart) return;
      const oldTransform = d3_xyzoom.xyzoomTransform(this.chart);
      const t = d3_xyzoom.xyzoomIdentity
        .translate(transformX, transformY)
        .scale(kx, ky);
      if (this.zoom && (oldTransform.x !== transformX || oldTransform.y !== transformY || kx !== oldTransform.kx || ky !== oldTransform.ky)) d3.select(this.chart).call(this.zoom.transform, t);
    }

    changeZoomableAxis = (type = 'BOTH') => {
      if (type === 'X') this.zoom.scaleRatio([1, 0]);
      if (type === 'Y') this.zoom.scaleRatio([0, 1]);
      if (type === 'BOTH') this.zoom.scaleRatio([1, 1]);
      if (type === 'NONE') this.zoom.scaleRatio([0, 0]);

      d3.select(this.chart).call(this.zoom);
      if (NoChartDblClickZoom.includes(this.props.type)) d3.select(this.chart).on('dblclick.zoom', null);
    };

    initializeGraph() {
      this.y = d3.scaleLinear().range([this.container.getBoundingClientRect().height, 0]);
      this.y2 = d3.scaleLinear().range([this.container.getBoundingClientRect().height, 0]);
      this.y.domain(this.props.range);
      this.y2.domain(this.props.range);
      if (this.props.xAxis === 'linear') {
        this.x = d3.scaleLinear();
        this.x2 = d3.scaleLinear();
      } else {
        this.x = d3.scaleTime();
        this.x2 = d3.scaleTime();
      }
      if (this.props.domain === undefined) {
        // select largest timescale from datasets that we got
        const domain = [];

        this.props.data.forEach((data) => {
          const dataDomain = d3.extent(data, d => d.x);
          if ((dataDomain[0] !== undefined && dataDomain[0] < domain[0]) || !domain[0]) domain[
            0] = dataDomain[0];
          if ((dataDomain[1] !== undefined && dataDomain[1] > domain[1]) || !domain[1]) domain[
            1] = dataDomain[1];
        });
        this.x.range([0, this.container.getBoundingClientRect().width]).domain(domain);
        this.x2.range([0, this.container.getBoundingClientRect().width]).domain(domain);
      } else {
        this.x.range([0, this.container.getBoundingClientRect().width]).domain(this.props.domain);
        this.x2.range([0, this.container.getBoundingClientRect().width]).domain(this.props.domain);
      }

      this.xAxis = d3.axisBottom(this.x);
      this.yAxis = d3.axisLeft(this.y);

      this.initilizeZoom();

      d3.select(this.chart)
        .attr('width', this.container.getBoundingClientRect().width)
        .attr('height', this.container.getBoundingClientRect().height);

      if (!NoChartZoomTypes.includes(this.props.type)) {
        if ((this.props.contextChart || this.props.enableZoom) && !this.zoomApplied) {
          this.enableZoom();
        } else if (!(this.props.contextChart || this.props.enableZoom) && this.zoomApplied) {
          this.disableZoom();
        }
      }
    }

    drawDefs() {
      const { chartName } = this.props;
      d3.select(`defs.${chartName}`).remove();
      this.defs = defsUtils.createDefs(this.chart, chartName);
      this.clipPath = defsUtils.addClipPath(this.chart, chartName);

      if (this.props.applyGradient) defsUtils.addLinearGradient(this.chart, this.y, chartName, this.props.assetConditionLevels);

      if (this.refNode.drawDefs) this.refNode.drawDefs();
    }

    drawAxis() {
      const { chartName, xAxisTicks } = this.props;
      d3.selectAll(`.${chartName}.axis`).remove();
      this.yAxisGridlines = gridUtils.yAxisGridlines(this.chart, this.y, 2, `${chartName}`);
      this.xAxisGridlines = gridUtils.xAxisGridlines(this.chart, this.x, xAxisTicks, `${chartName}`);
      this.ticksBottom = gridUtils.ticksBottom(this.chart, this.x, xAxisTicks, `${chartName}`);
      this.ticksLeft = gridUtils.ticksLeft(this.chart, this.y, 2, `${chartName}`);
      this.ticksLeft.on('click', null);
      if (this.refNode.drawAxis) this.refNode.drawAxis(this);
    }

    changeYAxisDomain() {
      this.setState({
        yAxisModalOpen: true
      });
    }

    redraw(x = this.x, y = this.y) {
      if (!this.container) return;
      const { xAxisTicks } = this.props;
      this.y.range([this.container.getBoundingClientRect().height, 0]);
      this.x.range([0, this.container.getBoundingClientRect().width]);

      d3.select(this.chart)
        .attr('width', this.container.getBoundingClientRect().width)
        .attr('height', this.container.getBoundingClientRect().height);

      // move clippath
      d3.select(`#${this.props.chartName}-clip > rect`)
        .attr('width', this.container.getBoundingClientRect().width);

      // move axises
      this.yAxisGridlines
        .call(d3.axisRight(y)
          .ticks(2)
          .tickSize(this.chart.getBoundingClientRect().width)
          .tickFormat(''));

      this.xAxisGridlines
        .call(d3.axisTop(x)
          .ticks(xAxisTicks)
          .tickSize(this.chart.getBoundingClientRect().height)
          .tickFormat(''));

      this.ticksLeft
        .call(d3.axisLeft(y)
          .ticks(2)
          .tickSize(10));

      this.ticksBottom
        .call(d3.axisBottom(x)
          .ticks(xAxisTicks)
          .tickSize(10));

      this.drawSelection(x, y);
      this.drawPoints(x, y);
      this.redrawDataLines(x, y, this);

      // hack to make zoom work on ML tab as drawGraph is not being called when expanded unlike in charts tab.
      if (!NoChartZoomTypes.includes(this.props.type)) {
        if ((this.props.contextChart || this.props.enableZoom) && !this.zoomApplied) {
          this.enableZoom();
        } else if (!(this.props.contextChart || this.props.enableZoom) && this.zoomApplied) {
          this.disableZoom();
        }
      }
      if (this.refNode.redraw) this.refNode.redraw(this);
    }

    initilizeZoom = () => {
      this.zoom = d3_xyzoom.xyzoom()
        .extent([
          [0, 0],
          [this.container.getBoundingClientRect().width,
            this.container.getBoundingClientRect().height]])
        .scaleExtent([[1, 500], [0.1, 10]])
        .translateExtent([
          [0, -(this.container.getBoundingClientRect().height * 9)],
          [this.container.getBoundingClientRect().width,
            this.container.getBoundingClientRect().height]])
        .on('end', this.onZoomEnd)
        .on('zoom', this.zoomHandler);

      // waveform should zoom around center point
      if (this.props.type === 'waveform') {
        this.zoom.translateExtent([
          [0, -(this.container.getBoundingClientRect().height * 5)],
          [this.container.getBoundingClientRect().width,
            this.container.getBoundingClientRect().height * 5]]);
      }

      this.zoom.scaleRatio([1, 1]);
    };

    enableZoom = () => {
      this.zoomApplied = true;
      d3.select(this.chart)
        .call(this.zoom);
      if (NoChartDblClickZoom.includes(this.props.type)) d3.select(this.chart).on('dblclick.zoom', null);
      const maxYdomain = ((this.y2.domain()[1] - this.y2.domain()[0]) / 0.1) + this.y2.domain()[0];
      const yAxisUpperLimit = this.y2.domain()[1].toFixed(2);
      let minYdomain = 0.05;
      if (this.props.ampType === 'velocity' && unitSystemMap[IMPERIAL_UNIT_SYSTEM].units === this.props.yUnit) {
        minYdomain = 0.002;
      }
      this.setState({
        maxYdomain: maxYdomain.toFixed(2),
        minYdomain,
        yAxisUpperLimit
      });
    };

    disableZoom = () => {
      if (this.chart && this.zoom) {
        d3.select(this.chart).transition()
          .duration(500)
          .call(this.zoom.transform, d3_xyzoom.xyzoomIdentity)
          .on('end', () => {
            if (!this.state.axisLocked.x && this.lineContext && this.lineContext.refNode) {
              this.lineContext.refNode.resetBrushSelection();
            }
          });
      }
      this.zoomApplied = false;
      d3.select(this.chart).on('.zoom', null);
      this.setState({
        resetActive: false
      });
    };

    drawSelectionLines(x = this.x, y = this.y) {
      if (this.props.selection) {
        const dataPoint = this.props.selection;
        d3.select(`.${this.props.chartName}.selection.line`)
          .attr('x1', x(dataPoint.x))
          .attr('x2', x(dataPoint.x));

        d3.select(`.${this.props.chartName}.selection.circle`)
          .attr('cx', x(dataPoint.x))
          .attr('cy', y(dataPoint.y));
      }

      // move points circle and line
      if (!_.isEmpty(this.props.points)) {
        const dataPoint = this.props.points;
        d3.select(`.${this.props.chartName}.pointSelection.line`)
          .attr('x1', x(dataPoint.x))
          .attr('x2', x(dataPoint.x));

        d3.select(`.${this.props.chartName}.pointSelection.circle`)
          .attr('cx', x(dataPoint.x))
          .attr('cy', y(dataPoint.y));
      }
      if (this.refNode.redraw) this.refNode.redraw(this);
    }

    drawTitle() {
      d3.selectAll(`.${this.props.chartName}.title`).remove();
      this.chartTitle = graphUtils.drawTitle(this.chart, this.props.title, this.props.chartName);
    }

    drawXlabel() {
      d3.selectAll(`.${this.props.chartName}.xlabel`).remove();
      if (this.props.noXlabel) return;
      let label = '';
      if (this.props.type === 'waveform') {
        label = 'Time (ms)';
        if (this.props.xTitle === 'Quefrency') label = 'Quefrency (ms)';
      } else if ((this.props.type === 'trend' || this.props.type === 'multiline') && this.props.expanded) label = 'Date';
      if (label && !this.props.miniMode) graphUtils.drawXAxisLabel(this.chart, label, this.props.chartName);
    }

    drawYlabel() {
      d3.selectAll(`.${this.props.chartName}.ylabel`).remove();
      let label = this.props.ylabel;
      if (this.props.chart_size !== 'Large' && label && label.length >= 8 && ((this.props.contextChart && !this.props.miniMode) || this.props.showZoomLocks === 'y')) {
        label = label.slice(0, 9);
        label = `${label}..`;
        const noOfSpaces = this.props.ylabel.length - 4;
        for (let i = 0; i < noOfSpaces; i++) {
          label += '\xa0';
        }
      }
      if (this.props.chart_size !== 'Large' && this.props.ylabel && this.props.ylabel.length <= 10 && this.props.ylabel.length >= 7 && ((this.props.contextChart && !this.props.miniMode) || this.props.showZoomLocks === 'y')) {
        for (let i = 0; i < 6; i++) {
          label += '\xa0';
        }
      }
      this.chartLabel = graphUtils.drawYAxisLabel(this.chart, label, this.props.chartName, this.props.ylabel);
      if (this.refNode.toggleYaxisUnits && this.refNode.state.allowYUnitToggle) {
        this.chartLabel = this.chartLabel
          .attr('cursor', 'pointer')
          .attr('font-weight', '300')
          .attr('stroke', this.props.theme.primaryColor)
          .on('click', () => {
            d3.event.stopPropagation();
            this.refNode.toggleYaxisUnits();
          });
      }
    }

    drawSelection(x = this.x, y = this.y) {
      // draw a selection line
      let dataPoint = this.props.selection;
      if (dataPoint && _.isArray(dataPoint)) {
        dataPoint = dataPoint.filter(item => this.props.activeData[item.dataIdx]);
      }
      if (dataPoint && !_.isEmpty(dataPoint)) {
        if (this.refNode.drawSelection) this.refNode.drawSelection(this, dataPoint);

        else {
          d3.selectAll(`.${this.props.chartName}.selection`).remove();
          if (!_.isEmpty(dataPoint)) {
            graphUtils.drawLineOnPos(this.chart, x(dataPoint.x), 0, x(dataPoint.x), this.chart.getBoundingClientRect().height, `${this.props.chartName} selection`)
              .attr('pointer-events', 'none');
            graphUtils.drawCircleOnPos(this.chart, x(dataPoint.x), y(dataPoint.y), 3, `${this.props.chartName} selection`)
              .attr('pointer-events', 'none');
          }
        }
      }
    }

    drawDataLines(x = this.x, y = this.y) {
      const { colors } = this.props;
      if (this.refNode.drawDataLines) {
        this.refNode.drawDataLines(this);
      }
      if (this.refNode.drawDataLinesOverride) {
        this.refNode.drawDataLinesOverride(this);
        return;
      }
      const { chartName } = this.props;
      d3.selectAll(`.${chartName}.dataline`).remove();
      this.chartDatalines = [];
      this.props.data.forEach((dataSet, idx) => {
        const dataline = graphUtils.drawDataline(
          this.chart,
          dataSet,
          x,
          y,
          `${chartName} dataline idx-${idx}`
        );
        dataline.attr('stroke', colors ? colors[idx] : '#91B846');
        if (!this.props.activeData[idx]) dataline.attr('opacity', 0);
        this.chartDatalines.push(dataline);
      });
    }

    drawPoints(x = this.x, y = this.y) {
      d3.selectAll(`.${this.props.chartName}.pointSelection`).remove();
      if (this.props.points) {
        if (this.refNode.drawPoints) this.refNode.drawPoints(this, this.props.points);
        else {
          // default functionality
          const dataPoint = this.props.waveAndSpecSelection || this.props.points;
          if (_.isEmpty(dataPoint)) return;
          d3.selectAll(`.${this.props.chartName}.pointSelection`).remove();
          graphUtils.drawLineOnPos(this.chart, x(dataPoint.x), 0, x(dataPoint.x), this.chart.getBoundingClientRect().height, `${this.props.chartName} pointSelection`);
          graphUtils.drawCircleOnPos(this.chart, x(dataPoint.x), y(dataPoint.y), 4, `${this.props.chartName} pointSelection`);
          if (this.props.setDataPosAndDataPoint) {
            let dataPos = {
              x: false,
              y: false
            };
            if (x(dataPoint.x) >= 0 && x(dataPoint.x) <= this.chart.getBoundingClientRect().width
                  && y(dataPoint.y) >= 0 && y(dataPoint.y) <= parseInt(this.props.height, 10)) {
              dataPos = {
                x: x(dataPoint.x),
                y: y(dataPoint.y)
              };
              if (dataPos.x > this.chart.getBoundingClientRect().width - 250) dataPos.alignLeft = true;
            }
            this.props.setDataPosAndDataPoint(dataPos, dataPoint);
          }
        }
      }
    }

    addMouseoverEventListener() {
      if (this.props.mouseover) {
        // TODO: event listener is not removed anywhere
        if (this.refNode.addMouseoverEventListener) this.refNode.addMouseoverEventListener(this);
        else {
          if (this.mouseover) return;
          // this is really slow on linechart
          this.mouseover = graphUtils.addMouseoverEvent(this.chart, () => {
            const dataPoint = helpers.closestExactDataPoint(this);
            this.props.mouseover(dataPoint, this.props.chartName);
          });
        }
      }
    }

    addClickEventListener() {
      this.mouseClick = null;
      if (!this.mouseClick && this.props.mouseClick) {
        this.mouseClick = graphUtils.addMouseClickEvent(this.chart, () => {
          this.props.mouseClick();
        });
      }
    }

    addDoubleClickEventListener() {
      this.doubleClick = null;
      if (!this.doubleClick && this.props.doubleClick) {
        this.doubleClick = graphUtils.addDoubleClickEvent(this.chart, () => {
          this.props.doubleClick();
        });
      }
    }

    redrawDataLines(x = this.x, y = this.y) {
      if (this.refNode.redrawDataLines) this.refNode.redrawDataLines(x, y, this);
      else if (this.props.parent && this.props.parent.refNode.redrawDataLines) this.props.parent.refNode.redrawDataLines(x, y, this);
      else {
        this.props.data.forEach((dataSet, idx) => {
          if (!_.isEmpty(this.chartDatalines[idx])) {
            const dataline = this.chartDatalines[idx]
              .attr('d', d3.line()
                .curve(d3.curveLinear)
                .x(d => x(d.x))
                .y(d => y(d.y)));
            dataline.attr('stroke', this.props.colors ? this.props.colors[idx] : '#91B846');
            if (!this.props.activeData[idx]) dataline.attr('opacity', 0);
          }
        });
      }
    }

    setRef(node) {
      // if ref callback gets called with null it is unmounting or in preliminary stages so remove eventlistener
      // when it has props the node exists and we can set the ref and eventlistener
      if (!node) {
        window.removeEventListener('resize', () => this.redraw(), false);
      } else if (node.props) {
        window.addEventListener('resize', () => this.redraw(), false);
        this.refNode = node;
      }
    }

    increaseYAxis = () => {
      this.refNode.increaseYAxis();
    };

    decreaseYAxis = () => {
      this.refNode.decreaseYAxis();
    };

    toggleBools = (axis) => {
      const { axisLocked } = this.state;
      axisLocked[axis] = !axisLocked[axis];
      this.setState({ axisLocked }, this.zoomAxis);
      if (this.props.toggleZoomLocks) this.props.toggleZoomLocks({ [axis]: axisLocked[axis] });
    }

    resetChartZoom = (type) => {
      if (type === 'x') {
        const { transformY, ky } = this.state;
        const t = d3_xyzoom.xyzoomIdentity
          .translate(0, transformY)
          .scale(1, ky);
        d3.select(this.chart).transition()
          .duration(500)
          .call(this.zoom.transform, t)
          .on('end', () => {
            if (!this.state.axisLocked.x && this.lineContext.refNode) {
              this.lineContext.refNode.resetBrushSelection();
            }
          });
        if (this.props.storeTransform) {
          this.props.storeTransform({ kx: 1, transformX: 0 });
        }
      } else {
        d3.select(this.chart).transition()
          .duration(500)
          .call(this.zoom.transform, d3_xyzoom.xyzoomIdentity)
          .on('end', () => {
            if (!this.state.axisLocked.x && this.lineContext && this.lineContext.refNode) {
              this.lineContext.refNode.resetBrushSelection();
            }
          });
        if (this.props.storeTransform) {
          this.props.storeTransform({ transformY: 0, ky: 1, kx: 1, transformX: 0 });
        }
      }
    };

    zoomAxis = () => {
      const { axisLocked } = this.state;
      const brush = d3.select(`.${this.props.chartName}-ctx.brush`);
      const brushSelection = d3.brushSelection(brush) && d3.brushSelection(brush).node();
      if (!brushSelection) {
        if (!axisLocked.x && !axisLocked.y) this.changeZoomableAxis('BOTH');
        else if (!axisLocked.x) this.changeZoomableAxis('X');
        else if (!axisLocked.y) this.changeZoomableAxis('Y');
        else this.changeZoomableAxis('NONE');
        return;
      }

      if (!axisLocked.x) {
        d3.selectAll(`.${this.props.chartName}-ctx.brush>.overlay`)
          .attr('pointer-events', 'all')
          .attr('cursor', 'crosshair');
        if (brushSelection) {
          d3.selectAll(`.${this.props.chartName}-ctx.brush>.handle`).style('display', 'block');
        }
        if (!axisLocked.y) this.changeZoomableAxis('BOTH');
        else this.changeZoomableAxis('X');
        return;
      }
      d3.selectAll(`.${this.props.chartName}-ctx.brush>.overlay`)
        .attr('pointer-events', 'none')
        .attr('cursor', 'not-allowed');
      d3.selectAll(`.${this.props.chartName}-ctx.brush>.handle`).style('display', 'none');
      if (!axisLocked.y) {
        this.changeZoomableAxis('Y');
        return;
      }
      this.changeZoomableAxis('NONE');
    };

    onChangeInput = (e) => {
      const { maxYdomain, minYdomain } = this.state;
      this.setState({
        yAxisUpperLimit: e.target.value
      });
      if (parseFloat(e.target.value)) {
        const value = parseFloat(e.target.value);
        if (value > maxYdomain || value < minYdomain) {
          this.setState({
            yAxisError: `Please enter a value between ${minYdomain} and ${maxYdomain}`
          });
          return;
        }
        this.setState({
          yAxisError: ''
        });
      } else {
        this.setState({
          yAxisError: 'Not a valid number'
        });
      }
      if (e.target.value === '') {
        this.setState({
          yAxisError: ''
        });
      }
    }

    changeYAxisScale = (value) => {
      const oldDomain = this.y2.domain();

      // 0 should be in the initial location for all charts
      const newLowerDomain = (oldDomain[0] / oldDomain[1]) * value;
      this.y.domain([newLowerDomain, value]);
      const ky = (this.y2.domain()[1] - this.y2.domain()[0]) / (value - newLowerDomain);

      const height = this.container.getBoundingClientRect().height;

      // will work where y axis is linear scale (IMPORTANT).
      const perpixel = height / (value - newLowerDomain);
      const oldTransform = d3_xyzoom.xyzoomTransform(this.chart);

      const transformY = (value - this.y2.domain()[1]) * perpixel;
      const t = d3_xyzoom.xyzoomIdentity
        .translate(oldTransform.x, transformY)
        .scale(oldTransform.kx, ky);

      if (this.props.storeTransform) {
        this.props.storeTransform({ transformY, ky });
        return;
      }
      if (this.zoom) {
        this.setState({
          transformY,
          ky
        }, () => d3.select(this.chart).call(this.zoom.transform, t));
      }
    };

    onSaveYAxislimit = () => {
      const { yAxisUpperLimit, yAxisError } = this.state;
      if (parseFloat(yAxisUpperLimit) && !yAxisError) {
        const value = parseFloat(yAxisUpperLimit);
        this.changeYAxisScale(value);
        this.setState({
          yAxisModalOpen: false
        });
      }
    }

    handleEnter = (e) => {
      if (e.key === 'Enter') {
        this.onSaveYAxislimit();
      }
    }

    onClickReset = () => {
      if (this.props.resetChartsZoom) {
        this.props.resetChartsZoom();
      } else this.resetChartZoom();
    };

    render() {
      return (
        <Fragment>
          <div style={{ position: 'relative' }}>
            <LineChartContainer
              innerRef={(node) => { this.container = node; }}
              chartName={this.props.chartName}
              applyGradient={this.props.applyGradient || (typeof this.props.filterMinVal === 'number' && typeof this.props.filterMaxVal === 'number') || this.props.enableOnOffIndicator}
              height={this.props.height}
              top={this.props.type === 'overallAlarm'}
              noMarginBottom={this.props.type === 'context' || !this.props.contextChart}
              xAxisLocked={this.state.axisLocked.x}
              miniMode={this.props.miniMode}
              hierarchyViewPane={this.props.hierarchyViewPane}
            >
              {!_.isEmpty(this.props.data) && !NoChartZoomTypes.includes(this.props.type) && this.state.resetActive && (
                <SvgContainer absolute right="-0px" onClick={this.onClickReset}>
                  <ResetSvg />
                </SvgContainer>
              )}
              {this.state.yAxisModalOpen && (
                <YAxisDomainModal>
                  <FlexContainer justifyContent="space-between">
                    <Label header>Set y-axis limit</Label>
                    <SvgContainer onClick={() => this.setState({ yAxisModalOpen: false })}>
                      <CrossSvg width={20} height={20} />
                    </SvgContainer>
                  </FlexContainer>
                  <InputField type="text" value={this.state.yAxisUpperLimit} onChange={this.onChangeInput} onKeyPress={this.handleEnter} />
                  <YAxisModalInfo>
                    <SvgContainer nohover marginright="5px"><Info color="#999B95" /></SvgContainer>
                    <FlexContainer direction="column">
                      <Label>Max allowed value is {this.state.maxYdomain}</Label>
                      <Label>Min allowed value is {this.state.minYdomain}</Label>
                    </FlexContainer>
                  </YAxisModalInfo>
                  {this.state.yAxisError && (
                    <FlexContainer>
                      <SvgContainer nohover marginright="5px">
                        <ExclamationTriangle color="red" />
                      </SvgContainer>
                      <Label color="red">{this.state.yAxisError}</Label>
                    </FlexContainer>
                  )}
                  <FlexContainer justifyContent="flex-start" margintop="5px">
                  <Fragment>
                    <ButtonT disabled={this.state.yAxisError !== '' || this.state.yAxisUpperLimit === '' || this.state.yAxisUpperLimit === this.y.domain()[1].toFixed(2)} onClick={this.onSaveYAxislimit}>
                      Save
                    </ButtonT>
                  </Fragment>
                  </FlexContainer>
                </YAxisDomainModal>
              )}
              {this.props.type === 'overallAlarm' && (
                <ButtonContainer>
                  <Button onClick={this.increaseYAxis}>
                    <Plus color="#9ea09a" />
                  </Button>
                  <Button onClick={this.decreaseYAxis} disabled={this.state.decreaseDisabled}>
                    <Minus color="#9ea09a" />
                  </Button>
                </ButtonContainer>
              )}
              <svg ref={(node) => { this.chart = node; }} />
              <WrappedComponent {...this.props} ref={this.setRef} />

              {(((!_.isEmpty(this.props.data) && this.props.contextChart === true && this.props.type !== 'overallAlarm' && this.props.type !== 'utilization') && !this.props.miniMode) || this.props.showZoomLocks) && (
              <Fragment>
                {(!this.props.showZoomLocks || (this.props.showZoomLocks && (this.props.showZoomLocks === 'x' || this.props.showZoomLocks === 'xy'))) && (
                  <ButtonX onClick={() => this.toggleBools('x')}>
                    {this.state.axisLocked.x ? <LockedZoomSVG /> : <UnlockedZoomSVG /> }
                  </ButtonX>
                )}
                {(!this.props.showZoomLocks || (this.props.showZoomLocks && (this.props.showZoomLocks === 'y' || this.props.showZoomLocks === 'xy'))) && (
                  <ButtonY onClick={() => this.toggleBools('y')}>
                    {this.state.axisLocked.y ? <LockedZoomSVG /> : <UnlockedZoomSVG />}
                  </ButtonY>
                )}
                {(this.props.lockYAxis || (this.props.showZoomLocks && this.props.showZoomLocks === 'x')) ? '' : (
                  <ButtonY top="15px" left="-55px" onClick={() => this.changeYAxisDomain()}>
                    <EditIcon width={12} height={12} fill={this.state.yAxisModalOpen ? '#91b846' : '#999B95'} />
                  </ButtonY>
                )}
                {(this.props.isMultiSetActive) && (
                  <MultiSetItem
                    type="checkbox"
                    top="110px"
                    left="-55px"
                    onClick={this.props.setMultiSetItem}
                    value={this.props.isMultiSetItem}
                  />
                )}
              </Fragment>)}

            </LineChartContainer>
            {this.props.paginationLoading && (
              <Loader>
                <LoadingSvg />
              </Loader>
            )}
          </div>
          {this.props.contextChart && !this.props.miniMode && (
            <div style={{ position: 'relative' }}>
              <ContextChart
                ref={(node) => { this.lineContext = node; }}
                type="context"
                autoFill
                height={this.props.contextHeight || this.state.contextHeight}
                xAxis={this.props.xAxis}
                chartName={`${this.props.chartName}-ctx`}
                data={this.props.data}
                range={this.props.range}
                chartType={this.props.type}
                selectedRange={this.props.selectedRange}
                brushCb={this.props.brushCb}
                colors={this.props.colors}
                xUnit={this.props.xUnit}
                ampType={this.props.ampType}
                applyGradient={this.props.applyGradient}
                // brushSelected={this.props.brushSelected}
                xScale={this.state.xScale}
                yScale={this.state.yScale}
                activeData={this.props.activeData}
                utilCtx={this.props.type === 'utilization'} // TODO: switch logic
                parent={this} // TODO: fix to separate props
                xAxisLocked={this.state.axisLocked.x}
                resetChartZoom={this.resetChartZoom}
                multiBrush={this.props.multiBrush}
                disableInitialBrush={this.props.disableInitialBrush}
                tag_type={this.props.tag_type}
                hierarchyViewPane={this.props.hierarchyViewPane}
              />
              {this.props.paginationLoading && (
                <Loader>
                  <LoadingSvg />
                </Loader>
              )}
            </div>
          )
          }
        </Fragment>
      );
    }
  }

  LineChartWrapper.propTypes = {
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
      y: PropTypes.number.isRequired
    }))).isRequired,
    activeData: PropTypes.array,
    chartName: PropTypes.string,
    contextChart: PropTypes.bool,
    applyGradient: PropTypes.bool,
    assetConditionLevels: PropTypes.number,
    range: PropTypes.array,
    domain: PropTypes.array,
    xAxisTicks: PropTypes.number,
    multiBrush: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number
  };

  LineChartWrapper.defaultProps = {
    activeData: [true],
    chartName: undefined,
    contextChart: false,
    assetConditionLevels: 3,
    applyGradient: false,
    range: [0, 10],
    domain: undefined,
    xAxisTicks: 6,
    multiBrush: false,
    height: undefined,
    width: undefined
  };

  LineChartWrapper.displayName = 'LineChartWrapper';

  return LineChartWrapper;
};

export const ContextChart = LineChart(LineContext);
