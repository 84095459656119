import { toastr } from 'react-redux-toastr';
import * as _ from 'lodash';

import * as definitionService from './definition.service';
import definitionTypes from './definition.types';

export const clearCurrentDefinition = () => ({
  type: definitionTypes.CLEAR_CURRENT_DEFINITION
});

export const clearDefinitionList = () => ({
  type: definitionTypes.CLEAR_DEFINITION_LIST
});

export const searchParams = params => ({
  type: definitionTypes.SEARCH_PARAMS,
  params
});

export const getDefinitionList = (params = {}, load = true) => {
  return (dispatch) => {
    if (load) dispatch(request());
    return definitionService.getDefinitions(params).then(
      (res) => {
        dispatch(success(res.templates));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: definitionTypes.GET_DEFINITION_LIST_REQUEST };
  }
  function success(definitions) {
    _.forEach(definitions, (def) => {
      if (def.subscriber_roles) def.subscribers.unshift(...def.subscriber_roles);
      delete def.subscriber_roles;
    });
    return { type: definitionTypes.GET_DEFINITION_LIST_SUCCESS, definitions };
  }
  function failure(error) {
    return { type: definitionTypes.GET_DEFINITION_LIST_FAILURE, error };
  }
};

export const createDefinition = (definition, closeFunc) => {
  return (dispatch) => {
    dispatch(request());
    return definitionService.createDefinition(definition).then(
      () => {
        dispatch(getDefinitionList());
        closeFunc();
      },
      (error) => {
        dispatch(failure(error));
        throw error.message;
      }
    );
  };

  function request() {
    return { type: definitionTypes.CREATE_DEFINITION_REQUEST };
  }
  function failure(error) {
    return { type: definitionTypes.CREATE_DEFINITION_FAILURE, error };
  }
};

export const getDefinitionsMetadata = () => {
  const request = () => ({ type: definitionTypes.GET_DEFINITIONS_METADATA_REQUEST });
  const success = data => ({ type: definitionTypes.GET_DEFINITIONS_METADATA_SUCCESS, data });
  const failure = error => ({ type: definitionTypes.GET_DEFINITIONS_METADATA_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    return definitionService.getDefinitionsMetadata().then(
      (res) => {
        dispatch(success(res));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const getResourcesBasedOnType = (resource_type) => {
  return (dispatch) => {
    const params = {
      resource_type
    };

    dispatch(request());
    return definitionService.getResources(params).then(
      (res) => {
        const resources = res.result.map(r => ({ value: r.id, text: r.name }));
        dispatch(success(resources));
        return resources;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: definitionTypes.GET_RESOURCES_REQUEST };
  }
  function success(resources) {
    return { type: definitionTypes.GET_RESOURCES_SUCCESS, resources };
  }
  function failure(error) {
    return { type: definitionTypes.GET_RESOURCES_FAILURE, error };
  }
};

export const getUsers = () => {
  const resource_type = 'user';
  return (dispatch) => {
    const params = {
      resource_type
    };

    dispatch(request());
    return definitionService.getResources(params).then(
      (res) => {
        const users = [];
        // _.forEach(definitionConstants.roles, (role) => { users.push(role); });
        if (res.result) {
          res.result.forEach((userObj) => {
            users.push(userObj);
          });
        }
        dispatch(success(users));
        return users;
      },
      (error) => {
        dispatch(failure(error));
        throw error.message;
      }
    );
  };

  function request() {
    return { type: definitionTypes.GET_USERS_REQUEST };
  }
  function success(users) {
    return { type: definitionTypes.GET_USERS_SUCCESS, users };
  }
  function failure(error) {
    return { type: definitionTypes.GET_USERS_FAILURE, error };
  }
};

export const getDefinition = (template_id) => {
  return (dispatch) => {
    dispatch(request());
    return definitionService.getDefinition(template_id).then(
      (definition = {}) => {
        dispatch(success(definition));
        return definition;
      },
      (error) => {
        dispatch(failure(error));
        throw error.message;
      }
    );
  };

  function request() {
    return { type: definitionTypes.GET_DEFINITION_REQUEST };
  }
  function success(definition) {
    return { type: definitionTypes.GET_DEFINITION_SUCCESS, definition };
  }
  function failure(error) {
    return { type: definitionTypes.GET_DEFINITION_FAILURE, error };
  }
};

export const deleteDefinition = (template_id, account_id, closeFunc) => {
  return (dispatch) => {
    dispatch(request());
    return definitionService.deleteDefinition(template_id).then(
      () => {
        dispatch(getDefinitionList());
        closeFunc();
      },
      (error) => {
        dispatch(failure(error));
        toastr.error(error.message);
      }
    );
  };

  function request() {
    return { type: definitionTypes.DELETE_DEFINITION_REQUEST };
  }
  function failure(error) {
    return { type: definitionTypes.DELETE_DEFINITION_FAILURE, error };
  }
};

export const updateTriggerKeyValue = (template_id, parameter_type, sequence_no, key, value) => {
  const body = {
    [key]: value,
    sequence_no,
    parameter_type
  };
  return dispatch =>
    definitionService.updateTrigger(template_id, body).then(
      (res) => {
        if (res.success) {
          dispatch(getDefinitionList());
        } else {
          dispatch(getDefinition(template_id));
        }
      },
      (error) => {
        dispatch(getDefinition(template_id));
        toastr.error(error.message);
      }
    );
};

export const updateDefinitionName = (template_id, name) => ({
  type: 'UPDATE_DEFINITION_NAME',
  name,
  id: template_id
});

export const updateSubscription = (body) => {
  const { event_template_id } = body;
  return () =>
    definitionService.updateSubscription(event_template_id, body).then(
      () => {},
      (error) => {
        toastr.error(error.message);
        throw error.message;
      }
    );
};

export const updateEventsAccountPreference = (account_id, args) => dispatch =>
  definitionService.updateEventsAccountPreference(account_id, args).then(
    () => {},
    (error) => {
      toastr.error(error.message);
      throw error.message;
    }
  );

export const updateAction = (template_id, actions) => () =>
  definitionService.updateAction(template_id, actions).then(
    () => {},
    (error) => {
      toastr.error(error.message);
      throw error.message;
    }
  );

export const addResource = (template_data) => {
  const { event_template_id } = template_data;

  return (dispatch, getState) => {
    const { list } = getState().settings.eventDefinitions;
    return definitionService.addResource(event_template_id, template_data).then(
      () => {
        dispatch(getDefinitionList(list.params, false));
      },
      (error) => {
        toastr.error(error.message);
        throw error.message;
      }
    );
  };
};

export const syncResource = (template_data) => {
  const { event_template_id } = template_data;

  return (dispatch, getState) => {
    const { list } = getState().settings.eventDefinitions;
    return definitionService.syncResource(event_template_id, template_data).then(
      (res) => {
        dispatch(getDefinitionList(list.params, false));
        toastr.success(res.message);
      },
      (error) => {
        toastr.error(error.message);
        throw error.message;
      }
    );
  };
};

export const deleteResource = (template_data) => {
  const { event_template_id } = template_data;

  return (dispatch, getState) => {
    const { list } = getState().settings.eventDefinitions;
    return definitionService.deleteResource(event_template_id, template_data).then(
      () => {
        dispatch(getDefinitionList(list.params, false));
      },
      (error) => {
        toastr.error(error.message);
        throw error.message;
      }
    );
  };
};

export const deleteSubscription = (body) => {
  const { event_template_id } = body;

  return () =>
    definitionService.deleteSubscription(event_template_id, body).then(
      () => {},
      (error) => {
        toastr.error(error.message);
        throw error.message;
      }
    );
};
