import React, { Component } from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FlexContainer from 'common/components/atoms/FlexContainer';
import AccountMenuItems from 'common/components/organisms/AccountMenuItems';
import MoreOptions from 'common/components/organisms/MoreOptions';
import MsgContainer from 'common/components/atoms/MsgContainer';
import Checkbox from 'common/components/atoms/Checkbox';
import SearchFilters from 'common/components/atoms/SearchFilters';
import { isFilterApplied } from 'common/utils';
import Button from 'common/components/atoms/Button';
import { isPetasenseAdmin, isPetasenseViewer, isPartnerAdmin } from 'common/rbac/util';
import Widget from 'home/Widget';
import { widgetActions } from 'home/Widget/actions/widget.actions';
import Filter from '../../common/components/molecules/Filter';
import * as sensorActions from '../Sensors/actions/sensors.actions';

const FilterItem = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  text-align: left;
  width: max-content;
  min-width: 150px;
  border: none;
  outline: none;
  background-color: white;
  font-family: 'Petasense Open Sans';
  font-weight: 600;
  color: ${props => props.theme.colors.lightGray};
  ${props => props.selected && `color: ${props.theme.colors.black};`}
  &:hover {
    color: ${props => props.theme.colors.black};
    background-color: #f7f7f7;
  }
  &:first-of-type {
    padding-top: 1em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    padding-bottom: 1em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  span {
    margin-left: 0.5em;
  }
`;

const ChartsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  grid-template-columns: repeat(3, minmax(370px, 1fr));
`;

const DevicesHealthContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  min-height: calc(100vh - 168px);
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #E5E8E1;
  }
`;

class DevicesHealth extends Component {
  constructor() {
    super();
    this.initialSearchParams = {
      account_search_key: '',
      accounts: []
    };
    this.state = {
      filtersOpen: {
        accounts: false,
      },
      searchParams: {
        ...this.initialSearchParams,
      },
      accounts: [],
      allAccounts: [],
      filters: [],
    };
  }

  componentDidMount() {
    const title = this.props.title;
    document.title = `${title} | Devices Health`;

    this.mounted = true;
    const {
      getResourcesBasedOnType
    } = this.props.sensorActions;

    if (isPetasenseAdmin(this.props.user) || isPetasenseViewer(this.props.user) || isPartnerAdmin(this.props.user)) {
      getResourcesBasedOnType('account').then((res) => {
        let accountsList = [...res];
        if (isPetasenseAdmin(this.props.user) || isPetasenseViewer(this.props.user)) {
          accountsList = [{ text: 'Unassociated', value: null }, ...res];
        }
        this.setState({
          accounts: accountsList,
          allAccounts: accountsList,
        });
      });
    }

    this.props.widgetActions.getWidgetsMetadata();
  }

  toggleFilterOpen = (filter) => {
    this.setState(prevState => ({
      filtersOpen: {
        ...prevState.filtersOpen,
        [filter]: !prevState.filtersOpen[filter]
      }
    }));
  };

  searchAccountFilter = (value) => {
    this.setState(prevState => ({
      accounts: prevState.allAccounts.filter(u =>
        u.text.toLowerCase().includes(value.toLowerCase())
      ),
      searchParams: {
        ...prevState.searchParams,
        account_search_key: value
      }
    }));
  };

  renderFilterOptions = (options, name) => {
    if (_.isEmpty(options)) {
      return (
        <MsgContainer>
          <span>No {name} are available</span>
        </MsgContainer>
      );
    }

    return options.map((option, index) => {
      const selected = _.includes(this.state.searchParams[name], option.value);
      return (
          <FilterItem
            key={index.toString()}
            className="dropdown-item"
            selected={selected}
            onClick={() => {
              let types = [];
              if (selected) {
                types = _.filter(
                  this.state.searchParams[name],
                  t => t !== option.value
                );
              } else {
                types = [...this.state.searchParams[name], option.value];
              }
              this.changeFilter(name, types, selected);
            }}
          >
            <Checkbox value={selected} />
            <span>{option.text}</span>
          </FilterItem>
      );
    });
  };

  clearAllFilters = () => {
    const searchParams = {
      ...this.initialSearchParams
    };
    if (this.state.machineSensors) {
      searchParams.machines = this.state.searchParams.machines;
    }
    this.setState({ searchParams });
    if (this.state.dateExpanded) this.toggleDateExpanded();
    this.search(searchParams);
  }

  changeFilter = (filter, value) => {
    const searchParams = {
      ...this.state.searchParams,
      [filter]: value
    };
    this.setState({
      searchParams
    });
    return this.search(searchParams);
  };

  search = (params = null) => {
    const { searchParams: stateParams } = this.state;
    const searchParams = params || stateParams;
    const filters = [
      { name: 'account_id', op: 'in', value: searchParams.accounts },
    ];
    this.setState({
      filters
    });
  };

  selectGivenItems = (selected, key) => {
    this.setState(
      prevState => ({
        searchParams: {
          ...prevState.searchParams,
          [key]: selected
        },
        filtersOpen: {
          ...prevState.filtersOpen,
          [key]: false
        }
      }),
      this.search
    );
  };

  getChartFilters = () => {
    const filters = [];
    if (this.props.adminDashboard.adminOpen) {
      filters.push({ name: 'all', op: 'eq', value: true });
    }
    if (!_.isEmpty(this.state.searchParams.accounts)) {
      filters.push({ name: 'account_id', op: 'in', value: this.state.searchParams.accounts });
    }
    return filters;
  };

  render() {
    let accountTextValue = '';
    accountTextValue = this.state.searchParams.accounts.reduce((acc, id, idx) => {
      const name = this.state.allAccounts.filter(accounts => accounts.value === id);
      if (idx === 0) return `${name[0].text}`;
      if (idx === 1) return `${acc}, ${name[0].text}...`;
      return acc;
    }, '');

    const chartFilters = this.getChartFilters();

    return (
      <div>
        <MoreOptions>
          <AccountMenuItems />
        </MoreOptions>
        {this.props.adminDashboard.adminOpen && (
          <SearchFilters height="60px">
            <Filter
              title="Account:"
              value={accountTextValue}
              open={this.state.filtersOpen.accounts}
              toggleOpen={() => this.toggleFilterOpen('accounts')}
              onChangeSearch={this.searchAccountFilter}
              searchValue={this.state.searchParams.account_search_key}
              viewAllItems={this.state.allAccounts}
              selectedItems={this.state.searchParams.accounts}
              selectGivenItems={selected =>
                this.selectGivenItems(selected, 'accounts')
                }
              itemName="account"
            >
                {this.renderFilterOptions(this.state.accounts, 'accounts')}
            </Filter>
          {isFilterApplied(this.state.searchParams) && (
              <Button text onClick={this.clearAllFilters} fontSize="11px" margin="0 20px">
                Clear Filters
              </Button>
          )}
          </SearchFilters>
        )}
        <DevicesHealthContainer>
          <ChartsContainer>
            {!this.props.widgetsMetadata.loading && !_.isEmpty(this.props.widgetsMetadata.object) && (
              <>
                <Widget filters={chartFilters} showBorder metadata={this.props.widgetsMetadata.object.device_reachability_breakdown} height="220px" />
                <Widget filters={chartFilters} showBorder metadata={this.props.widgetsMetadata.object.device_battery_level_breakdown} height="220px" />
                <Widget filters={chartFilters} showBorder metadata={this.props.widgetsMetadata.object.device_wifi_strength_breakdown} height="220px" />
                <Widget filters={chartFilters} showBorder metadata={this.props.widgetsMetadata.object.firmware_version_breakdown} height="220px" />
                <Widget filters={chartFilters} gridColumn="span 2" showBorder metadata={this.props.widgetsMetadata.object.device_unreachable_trend} widgetInterval={7} height="220px" />
              </>
            )}
          </ChartsContainer>
        </DevicesHealthContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  adminDashboard: state.adminDashboard,
  widgetsMetadata: state.widgets.metadata,
  user: state.user.user,
  title: state.companyReducer.partner.title
});

const mapDispatchToProps = dispatch => ({
  sensorActions: bindActionCreators(sensorActions, dispatch),
  widgetActions: bindActionCreators(widgetActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesHealth);
