import { reportActionConstants } from 'home/Reports/actions/reports.constants';

const initialState = {
  plantHealthSummary: false,
  assetHealthSummary: false,
  inDepthAssetAnalysis: false,
  assetCharts: false,
  deviceHealthSummary: false
};

export const reportOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportActionConstants.INIT_REPORTS: {
      const resetState = {
        plantHealthSummary: false,
        assetHealthSummary: false,
        inDepthAssetAnalysis: false,
        assetCharts: false,
        deviceHealthSummary: false
      };
      return {
        ...resetState
      };
    }
    case reportActionConstants.SET_REPORT_OPTIONS: {
      return {
        ...state,
        ...action.options
      };
    }
    default:
      return state;
  }
};
