import { taskConstants } from '../constants/constants';

const initialState = {
  open: false,
  taskId: null,
  openTaskDetails: {}
};

export const taskModal = (state = initialState, action) => {
  switch (action.type) {
    case taskConstants.SET_TASK_MODAL_OPEN:
      return {
        ...state,
        open: true,
        taskId: action.taskId,
        openTaskDetails: {
          ...(action.details || {})
        }
      };

    case taskConstants.SET_TASK_MODAL_CLOSE:
      return {
        ...initialState
      };

    case taskConstants.GET_TASK_DETAILS_REQUEST:
      return {
        ...state,
        openTaskDetails: {
          ...state.openTaskDetails,
          loading: !action.refreshHistory,
          historyLoading: !!action.refreshHistory
        }
      };

    case taskConstants.GET_TASK_DETAILS_SUCCESS:
      return {
        ...state,
        openTaskDetails: {
          ...action.task
        }
      };

    case taskConstants.GET_TASK_DETAILS_FAILURE:
      return {
        ...state,
        openTaskDetails: {
          loading: false,
          historyLoading: false,
          error: action.error
        }
      };

    case taskConstants.UPDATE_TASK_SUCCESS:
      return {
        ...state,
        openTaskDetails: {
          ...state.openTaskDetails,
          ...action.updatedDetails,
        }
      };

    case taskConstants.UPDATE_TASK_NOTE:
      return {
        ...state,
        openTaskDetails: {
          ...state.openTaskDetails,
          notes: state.openTaskDetails.notes.map(
            (note) => {
              if (note.id !== action.note.id) return note;
              return {
                ...note,
                ...action.note
              };
            }
          )
        }
      };

    default:
      return state;
  }
};
