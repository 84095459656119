import { userConstants } from '../user.constants';

export function forgotPasswordState(state = {}, action) {
  switch (action.type) {
    case userConstants.FORGOT_PASSWORD_REQUEST:
      return {
        loading: true
      };
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        result: true,
      };
    case userConstants.FORGOT_PASSWORD_FAILED:
      return {
        result: false,
      };
    default:
      return state;
  }
}
