import React, { useEffect } from 'react';
import styled from 'styled-components';
import { NotificationIcon } from 'common/images/FaIcons';
import { connect } from 'react-redux';
import { FlexContainer } from 'common/components/atoms';
import { bindActionCreators } from 'redux';
import * as activityActions from '../actions/activity.actions';

const StyledNotificationCount = styled.span`
  position: absolute;
  right: -4px;
  top: 0.5%;
  margin-top: 10px; 
  font-size: 70%; 
  padding: 0.4em; 
  border-radius: 999px;
  line-height: 0.75em; 
  color: white;
  background: rgba(255, 0, 0, 0.85);
  text-align: center;
  min-width: 2em;
  font-weight: bold;
`;

function ActivityTabHeader({ activityUnreadActions, user, activityUnreadCount, machine_id }) {
  useEffect(() => {
    const { getActivityUnreadCount } = activityUnreadActions;
    const { account_id } = user.user;
    getActivityUnreadCount({ account_id, machine_id });
  }, []);

  return (
      <FlexContainer alignItems="center">
        Activity{' '}
        {activityUnreadCount > 0 && (
         <>
          <StyledNotificationCount>{activityUnreadCount}</StyledNotificationCount>
          <NotificationIcon style={{ marginLeft: '6px' }} />
         </>
        )}
      </FlexContainer>
  );
}

const mapStateToProps = state => ({
  activityUnreadCount: state.activityUnreadCount.unreadCount,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  activityUnreadActions: bindActionCreators(activityActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTabHeader);
