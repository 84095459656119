import React, { Component } from 'react';
import * as d3 from 'd3';
import * as _ from 'lodash';
import { withTheme } from 'styled-components';

import { KEYBOARD_CODES } from '../../../../../common/constants';
import { LineChart } from '../../../../../common/components/Chart/hoc/LineChart';
import { isElementPartiallyVisible } from '../../../../../common/utils';
import * as graphUtils from '../../../../../common/components/Chart/utils/graphUtils';

class TrendChart extends Component {
  constructor(props) {
    super(props);

    this.drawAlarmAndBaselineLines = this.drawAlarmAndBaselineLines.bind(this);
    this.drawOutliers = this.drawOutliers.bind(this);
    this.drawFdp = this.drawFdp.bind(this);
    this.drawStartTime = this.drawStartTime.bind(this);
    this.scaleYaxisToShowAlarmLines = this.scaleYaxisToShowAlarmLines.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyStrokes);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyStrokes);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.warningThreshold !== this.props.warningThreshold ||
      prevProps.criticalThreshold !== this.props.criticalThreshold ||
      prevProps.baselineValue !== this.props.baselineValue
    ) {
      this.scaleYaxisToShowAlarmLines();
      this.drawAlarmAndBaselineLines();
      this.drawOutliers();
    }
    if (typeof this.props.filterMinVal === 'number' && typeof this.props.filterMaxVal === 'number' && (!_.isEqual(this.props.filterMinVal, prevProps.filterMinVal) || !_.isEqual(this.props.filterMaxVal, prevProps.filterMaxVal))) {
      this.applyFilterGradient(this.ctx);
    } else if ((!_.isEqual(this.props.filterMinVal, prevProps.filterMinVal) || !_.isEqual(this.props.filterMaxVal, prevProps.filterMaxVal))) {
      const { chartName, colors } = this.props;
      const dataline = d3.select(`.${chartName}.dataline.idx-0`);
      dataline.attr('stroke', colors ? colors[0] : '#91B846');
    }
    if (prevProps.editRULSettings !== this.props.editRULSettings || prevProps.rulFdpDatapoint !== this.props.rulFdpDatapoint) this.drawFdp();
    if (prevProps.editRULSettings !== this.props.editRULSettings || prevProps.rulStartTimeDatapoint !== this.props.rulStartTimeDatapoint) this.drawStartTime();
  }

  drawGraph(ctx) {
    this.ctx = ctx;

    this.scaleYaxisToShowAlarmLines();
    this.drawAlarmAndBaselineLines();
    this.drawOutliers();
    this.drawFdp();
    this.drawStartTime();
  }

  redraw(ctx) {
    this.ctx = ctx;

    this.drawAlarmAndBaselineLines();
    this.drawOutliers();
    this.drawFdp();
    this.drawStartTime();
  }

  redrawDataLines = (x, y, ctx) => {
    this.props.data.forEach((dataSet, idx) => {
      if (this.props.activeData[idx]) {
        if (!ctx) return;
        const dataline = ctx.chartDatalines[idx]
          .attr('d', d3.line()
            .curve(d3.curveLinear)
            .x(d => x(d.x))
            .y(d => y(d.y)));
        dataline.attr('stroke', this.props.colors ? this.props.colors[idx] : '#91B846');
        this.applyValidRangeGradient(ctx);

        if (!this.props.filterMinVal || !this.props.filterMaxVal) return;
        this.applyFilterGradient(ctx);
      }
    });
  };

  applyFilterGradient = (ctx) => {
    const { filterMinVal, filterMaxVal, filterKey } = this.props;
    const { chartName } = this.props;
    const colorCodes = {
      green: '#91B846',
      grey: '#d8dcd3'
    };
    let currentColor = 'green';
    const data = this.props.data[0];
    const xDomain = ctx.x.domain();
    const xRange = ctx.x.range();
    const gradientArray = [];
    data.forEach((cur, idx) => {
      if (cur.x < xDomain[0] || cur.x > xDomain[1]) return;
      if (idx === 0) {
        if (cur[filterKey] >= filterMinVal && cur[filterKey] <= filterMaxVal) {
          gradientArray.push({
            offset: '0%', color: colorCodes.green
          });
        } else {
          gradientArray.push({
            offset: '0%', color: colorCodes.grey
          });
          currentColor = 'grey';
        }
      } else if (cur[filterKey] >= filterMinVal && cur[filterKey] <= filterMaxVal && currentColor !== 'green') {
        const curXOffset = ctx.x(cur.x);
        const prevXOffset = ctx.x(data[idx - 1].x);
        const xOffset = ((curXOffset + prevXOffset) / 2) / (xRange[1] - xRange[0]) * 100;
        gradientArray.push({
          offset: `${xOffset}%`, color: colorCodes.grey
        }, {
          offset: `${xOffset}%`, color: colorCodes.green
        });
        currentColor = 'green';
      } else if ((cur[filterKey] < filterMinVal || cur[filterKey] > filterMaxVal) && currentColor !== 'grey') {
        const curXOffset = ctx.x(cur.x);
        const prevXOffset = ctx.x(data[idx - 1].x);
        const xOffset = ((curXOffset + prevXOffset) / 2) / (xRange[1] - xRange[0]) * 100;
        gradientArray.push({
          offset: `${xOffset}%`, color: colorCodes.green
        }, {
          offset: `${xOffset}%`, color: colorCodes.grey
        });
        currentColor = 'grey';
      }
    });
    d3.select(`defs.${chartName}`).select('#line-gradient').remove();
    d3.select(`defs.${chartName}`)
      .append('linearGradient')
      .attr('id', 'line-gradient')
      .attr('class', `${chartName}`)
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', xRange[1])
      .attr('y2', 0)
      .selectAll('stop')
      .data(gradientArray)
      .enter()
      .append('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color);
  };

  applyValidRangeGradient = (ctx) => {
    const { color } = this.props;

    if (!this.props.enableOnOffIndicator) return;
    const data = this.props.data[0];

    const colors = {
      default: color,
      invalid: '#d8dcd3',
    };
    const { chartName } = this.props;
    const gradientArray = [];
    const xDomain = ctx.x.domain();
    const xRange = ctx.x.range();

    data.forEach((cur, idx) => {
      if (cur.x < xDomain[0] || cur.x > xDomain[1]) return;
      const prev = Math.max(idx - 1, 0);

      const curXOffset = ctx.x(cur.x);
      const prevXOffset = ctx.x(data[prev].x);
      const xOffset = ((curXOffset + prevXOffset) / 2) / (xRange[1] - xRange[0]) * 100;

      gradientArray.push({
        offset: `${xOffset}%`, color: Number(cur.on_off_reading) === 1 ? colors.default : colors.invalid
      });
    });
    d3.select(`defs.${chartName}`).select('#line-gradient').remove();
    d3.select(`defs.${chartName}`)
      .append('linearGradient')
      .attr('id', 'line-gradient')
      .attr('class', `${chartName}`)
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', xRange[1])
      .attr('y2', 0)
      .selectAll('stop')
      .data(gradientArray)
      .enter()
      .append('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color);
  }

  handleKeyStrokes = (e) => {
    if (!this.ctx || !this.ctx.chart || !this.ctx.x || this.props.lastHoveredChart !== this.props.type) return;
    if (e.keyCode !== KEYBOARD_CODES.LEFT_ARROW && e.keyCode !== KEYBOARD_CODES.RIGHT_ARROW && e.keyCode !== KEYBOARD_CODES.ENTER) return;

    const { waveAndSpecSelection, data, selection } = _.cloneDeep(this.props);
    const isVisible = isElementPartiallyVisible(this.ctx.chart);

    if (!waveAndSpecSelection || !isVisible || !data.length) return;

    let idx = data[0].findIndex(d => d.measurement_id === selection.measurement_id);
    if (idx === -1) return;

    const minIndex = 0;
    const maxIndex = data[0].length - 1;
    switch (e.keyCode) {
      case KEYBOARD_CODES.LEFT_ARROW:
        idx -= 1;
        if (idx < minIndex) return;

        // some entries of data randomly have dataIdx key (BUG)
        if (selection.dataIdx) delete selection.dataIdx;

        this.ctx.props.mouseover(_.cloneDeep(data[0][idx], this.ctx.props.chartName));
        break;

      case KEYBOARD_CODES.RIGHT_ARROW:
        idx += 1;
        if (idx > maxIndex) return;
        if (selection.dataIdx) delete selection.dataIdx;

        this.ctx.props.mouseover(_.cloneDeep(data[0][idx], this.ctx.props.chartName));
        break;

      case KEYBOARD_CODES.ENTER:
        this.props.mouseClick(_.cloneDeep(data[0][idx]));
        break;

      default:
        break;
    }
  };

  scaleYaxisToShowAlarmLines() {
    const { criticalThreshold, baselineValue } = this.props;

    let lines = [criticalThreshold];
    if (this.props.showBaseline) {
      lines = [criticalThreshold, baselineValue];
    }
    const maxLineValue = Math.max(...lines.filter(l => l));

    if (
      maxLineValue &&
      (this.props.enableAlarmAndBaselineLines || parseInt(this.props.height, 10) >= 300) &&
      this.props.range[1] - maxLineValue < 0.1 * maxLineValue
    ) {
      this.ctx.y.domain([this.props.range[0], 1.1 * maxLineValue]);
      this.ctx.y2.domain([this.props.range[0], 1.1 * maxLineValue]);
      this.ctx.drawAxis();
      this.ctx.drawDataLines();
      if (this.props.contextChart) this.ctx.enableZoom();
    }
  }

  drawOutliers() {
    const { outliersArray, chartName, height } = this.props;
    d3.selectAll(`.${chartName}.outliers`).remove();
    if (this.ctx && outliersArray) {
      outliersArray.forEach((d) => {
        if (this.ctx.x(d.x) > 0 && this.ctx.x(d.x) < this.ctx.chart.getBoundingClientRect().width
              && this.ctx.y(d.y) > 0 && this.ctx.y(d.y) < parseInt(height, 10)) {
          graphUtils.drawCircleOnPos(this.ctx.chart, this.ctx.x(d.x), this.ctx.y(d.y), 2.5, `${chartName} outliers`).attr('fill', 'gray');
        }
      });
    }
  }

  drawFdp() {
    const { chartName, editRULSettings, rulFdpDatapoint } = this.props;
    d3.selectAll(`.${chartName}.fdp`).remove();
    if (editRULSettings && this.ctx && !_.isEmpty(rulFdpDatapoint)) {
      graphUtils.drawCircleOnPos(this.ctx.chart, this.ctx.x(rulFdpDatapoint.x), this.ctx.y(rulFdpDatapoint.y), 2.5, `${chartName} fdp`).attr('fill', 'blue');
      graphUtils.drawLineOnPos(this.ctx.chart, this.ctx.x(rulFdpDatapoint.x), 0, this.ctx.x(rulFdpDatapoint.x), this.ctx.chart.getBoundingClientRect().height, `${chartName} fdp`).attr('stroke', 'blue');
    }
  }

  drawStartTime() {
    const { chartName, editRULSettings, rulStartTimeDatapoint } = this.props;
    d3.selectAll(`.${chartName}.startTime`).remove();
    if (editRULSettings && this.ctx && !_.isEmpty(rulStartTimeDatapoint)) {
      graphUtils.drawCircleOnPos(this.ctx.chart, this.ctx.x(rulStartTimeDatapoint.x), this.ctx.y(rulStartTimeDatapoint.y), 2.5, `${chartName} startTime`).attr('fill', 'green');
      graphUtils.drawLineOnPos(this.ctx.chart, this.ctx.x(rulStartTimeDatapoint.x), 0, this.ctx.x(rulStartTimeDatapoint.x), this.ctx.chart.getBoundingClientRect().height, `${chartName} startTime`).attr('stroke', 'green');
    }
  }

  drawAlarmAndBaselineLines() {
    const { warningThreshold, criticalThreshold, baselineValue } = this.props;
    const chartHeight = parseInt(this.props.height, 10);
    if (!this.props.enableAlarmAndBaselineLines && chartHeight < 300) {
      d3.selectAll(`.${this.props.chartName}.warning`).remove();
      d3.selectAll(`.${this.props.chartName}.critical`).remove();
      d3.selectAll(`.${this.props.chartName}.baseline`).remove();
      return;
    }

    // warning section
    if (this.props.tag_type === 'vibration' && ((this.props.ampType !== 'velocity' && this.props.ampType !== 'demod' && this.props.ampType !== 'acceleration') || this.props.feature === 'crest_factor')) {
      d3.selectAll(`.${this.props.chartName}.warning`).remove();
      d3.selectAll(`.${this.props.chartName}.critical`).remove();
      return;
    }

    d3.selectAll(`.${this.props.chartName}.warning`).remove();
    if (warningThreshold !== undefined && warningThreshold !== null) {
      if (this.ctx.y(warningThreshold) > 0 && this.ctx.y(warningThreshold) < chartHeight) {
        this.warningLine = graphUtils.drawLineOnPos(
          this.ctx.chart,
          0,
          this.ctx.y(warningThreshold),
          this.ctx.chart.getBoundingClientRect().width,
          this.ctx.y(warningThreshold),
          `${this.props.chartName} warning`
        );
        this.warningLine.attr('stroke', this.props.theme.colors.alarmWarning);
      }
    }

    // critical section

    d3.selectAll(`.${this.props.chartName}.critical`).remove();
    if (criticalThreshold !== undefined && criticalThreshold !== null) {
      if (this.ctx.y(criticalThreshold) > 0 && this.ctx.y(criticalThreshold) < chartHeight) {
        this.baselineLine = graphUtils.drawLineOnPos(
          this.ctx.chart,
          0,
          this.ctx.y(criticalThreshold),
          this.ctx.chart.getBoundingClientRect().width,
          this.ctx.y(criticalThreshold),
          `${this.props.chartName} critical`
        );
        this.baselineLine.attr('stroke', this.props.theme.colors.alarmCritical);
      }
    }


    // baseline value section

    d3.selectAll(`.${this.props.chartName}.baseline`).remove();
    if (this.props.showBaseline && baselineValue !== undefined && baselineValue !== null) {
      if (this.ctx.y(baselineValue) > 0 && this.ctx.y(baselineValue) < chartHeight) {
        this.criticalLine = graphUtils.drawLineOnPos(
          this.ctx.chart,
          0,
          this.ctx.y(baselineValue),
          this.ctx.chart.getBoundingClientRect().width,
          this.ctx.y(baselineValue),
          `${this.props.chartName} baseline`
        );
        this.criticalLine.attr('stroke', this.props.theme.colors.baselineLine);
      }
    }
  }

  render() {
    return null;
  }
}

TrendChart.displayName = 'TrendChart';

export default withTheme(LineChart(TrendChart));
