import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RBAC from 'common/rbac/RBAC';
import { mapComponentToResource, operations } from 'common/rbac/constants';
import InfoModal from '../../../../../../common/components/atoms/InfoModal';
import { ArrowLeft, ArrowRight, } from '../../../../../../common/images/FaIcons';
import DeleteIconSvg from '../../../../../../common/images/BearingModal/DeleteIconSvg';
import EditIcon from '../../../../../../common/images/BearingModal/EditIcon';
import TickSvg from '../../../../../../common/images/BearingModal/TickSvg';
import LoadingSvg from '../../../../../../common/images/LoadingSvg';
import FlexContainer from '../../../../../../common/components/atoms/FlexContainer';
import SvgBtn from '../../../../../../common/components/atoms/SvgBtn';
// import RotateIcon from '../../../../../../common/images/RotateIcon.png';
import CrossSvg from '../../../../../../common/images/CrossSvg';
import ReactPanZoom from 'react-image-pan-zoom-rotate'


const CarouselContainer = styled.div`
  position: relative;
  user-select: none;
  & > svg {
    position: absolute;
    z-index: 1;
    top: calc(50%);
    cursor: pointer;
  }
  & > svg:first-of-type {
    left: calc(20px);
  }
  & > svg:last-of-type {
    right: calc(20px);
  }
`;

const ImageLoader = styled.img`
  display: none; 
`;

const ImageContainer = styled.div`
  border-radius: 5px;
  background: black;
  margin: 0 10px;
  min-height: 400px;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 0 40px 0 40px;
  img {
    height: calc(99vh - 150px);
    object-fit: contain;
  }
`;

const H4 = styled.span`
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
`;

const TopRow = styled(FlexContainer).attrs({
  direction: 'row',
  justifyContent: 'flex-start',
})`
  position: absolute;
  z-index: 1;
  top: calc(0% - 22px);
  right: calc(0% + 22px);
  left: calc(0% + 22px);
  * > { cursor: pointer; }
  ${H4} { margin-right: auto; cursor: pointer; }
  ${SvgBtn} { margin-left: 12px; }
`;

const BottomRow = styled.div`
  position: absolute;
  bottom: calc(-20px);
  right: 0%;
  left: 0%;
  text-align: center;
  ${SvgBtn} {
    display: none;
  }
  &:hover {
    ${SvgBtn} {
      display: inline;
    }
  }
`;

const Label = styled.span.attrs({
  contentEditable: props => props.isEditable ? 'true' : 'false'
})`
  outline: none; 
  color: #D8DCD3;
  font-size: 14px;
  font-weight: 600;
  background: transparent;
  border: none;
  width: 130px;
  cursor: ${props => props.isEditable ? 'text' : 'default'};
`;

const Carousel = ({
  images,
  index,
  performDelete,
  close,
  updateLabel,
  deleting
}) => {
  const [idx, setIdx] = useState(index);
  const [showInfoModal, toggleShowInfoModal] = useState(false);
  const [isEditable, toggleEditable] = useState(false);
  const [label, setLabel] = useState('');
  const [loadedImages, setLoadedImages] = useState([]);
  const focusRef = useRef();
  let spanRef = useRef();
  useEffect(() => {
    if (!isEditable) {
      focusRef.current.focus();
      setLabel(images[idx].label);
    } else {
      spanRef.focus();
    }
  });

  return (
    <div
      style={{
        background: 'rgba(56,56,56,0.96)',
        position: 'fixed',
        zIndex: 1000,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center' }}
      onKeyDown={ev => onKeyPressed(ev)}
      ref={focusRef}
      tabindex="0"
      // onClick={close}
    >
    {showInfoModal ? (
      <InfoModal
        close={() => toggleShowInfoModal(false)}
        accept={() => performDelete(images[idx].id)}
        reject={() => toggleShowInfoModal(false)}
        lineOne="Delete this attachment?"
        lineTwo="Caution: This cannot be undone."
        acceptText="Delete"
        deleting={images[idx].id && deleting === images[idx].id}
      />) : undefined}
      <CarouselContainer>
        <TopRow>
          <H4>{`${idx + 1} of ${images && images.length}`}</H4>
          {/* <img style={{ position: 'relative' }} src={RotateIcon} /> */}
          <RBAC
            resource={mapComponentToResource.Attachments}
            operation={operations.Delete}
            yes={(
              <SvgBtn onClick={() => toggleShowInfoModal(true)}>
                <DeleteIconSvg fill="#ffffff" stroke="#ffffff" />
              </SvgBtn>
            )}
          />
          <SvgBtn onClick={close}>
            <CrossSvg fill="#ffffff" stroke="#ffffff" height={23} width={23} />
          </SvgBtn>
        </TopRow>
        <ArrowLeft
          color="white"
          size="2x"
          onClick={() => idx === 0 ? setIdx(images.length - 1) : setIdx(idx - 1)}
          style={{ opacity: idx === 0 ? 0.1 : 1 }}
        />
        <ArrowRight
          color="white"
          size="2x"
          onClick={() => idx === images.length - 1 ? setIdx(0) : setIdx(idx + 1)}
          style={{ opacity: idx === images.length - 1 ? 0.1 : 1 }}
        />
        <ImageContainer>
          {!loadedImages.includes(images[idx].url) ? (
            <div
              style={{
                position: 'absolute',
                right: 'calc(50% - 90px)',
                top: '50%'
              }}
            >
              <LoadingSvg />
            </div>
          ) : (<ReactPanZoom image={images[idx].url} />)}
          <ImageLoader
            src={images[idx].url}
            onLoad={() => !loadedImages.includes(images[idx].url) ? setLoadedImages([...loadedImages, images[idx].url]) : null}
          />
        </ImageContainer>
        <BottomRow>
          <Label
            contentEditable={isEditable}
            onInput={e => setLabel(e.target.innerText)}
            placeholder={images[idx].label}
            innerRef={(node) => { spanRef = node; }}
          >
            {images[idx].label}
          </Label>
          <SvgBtn
            margin="0 5px"
            onClick={handleEdit}
          >
            {isEditable ? <TickSvg height={14} fill="#edffcc" /> : <EditIcon fill="#ffffff" />}
          </SvgBtn>
        </BottomRow>
      </CarouselContainer>
    </div>
  );

  function onKeyPressed(event) {
    event.persist();
    if (event.key === 'ArrowRight' && !isEditable) {
      if (idx < images.length - 1) setIdx(idx + 1);
    }
    if (event.key === 'ArrowLeft' && !isEditable) {
      if (idx > 0) setIdx(idx - 1);
    }
  }

  function handleEdit() {
    setLabel(images[idx].label);
    if (isEditable && label && label.length > 0) {
      updateLabel(label, images[idx].id);
      toggleEditable(false);
    } else {
      toggleEditable(true);
    }
  }
};

Carousel.propTypes = {
  index: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  close: PropTypes.func.isRequired
};

Carousel.defaultProps = {
  index: 0,
};

export default Carousel;
