import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';
import InputField_T from 'common/components/atoms/InputField';
import AlertPrompt from 'common/components/organisms/AlertPrompt';

import * as machineInfoActions from '../../../Machines/MachineDetails/MachineInfo/actions/machineInfo.actions';


const AssetCopyOptionsContainer = styled.div`
  display: grid;
  grid-gap: 0 1em;
  grid-template-columns: auto auto
  border: 1px solid grey;
  border-spacing: 15px;
  border-color:${props => props.theme.colors.lightGray};
  border-radius: 8px;
  padding: 20px;
  width: 60%;
  margin-bottom: 24px;
`;

const InputField = styled(InputField_T)`
  flex-basis: 50%
  margin-bottom: 0;
  padding: 0.5em
`;

const paramsOptions = [{
  text: 'Information',
  value: 'info',
  initial_param: true,
  coupled_option: null,
  tag_property: false
},
{
  text: 'Rules Assigned',
  value: 'diagnostic_rules',
  initial_param: true,
  coupled_option: null,
  tag_property: false
}, {
  text: 'Tag Overall Alarm Thresholds',
  value: 'alarm_thresholds',
  initial_param: false,
  coupled_option: 'overall_alarm_settings',
  tag_property: true
// }, {
//   text: 'Tag Overall Alarm Settings',
//   value: 'overall_alarm_settings',
//   initial_param: false,
//   coupled_option: 'alarm_thresholds',
//   tag_property: true
// }, {
//   text: 'Tag Envelope Settings',
//   value: 'envelope_settings',
//   initial_param: false,
//   coupled_option: 'null',
//   tag_property: true
}];

class AssetPasteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: this.getInitialParams()
    };
  }

  getAssetCopyMessage = () => {
    const { assetInfo: { node_name } } = this.props;
    return `Please select the items you want to duplicate of ${node_name} :`;
  }

  getInitialParams = () => {
    const initialParams = {};
    paramsOptions.forEach((p) => {
      initialParams[p.value] = p.initial_param;
    });
    return initialParams;
  }

  coupledOption = (param) => {
    const option = paramsOptions.find(p => p.value === param);
    return option && option.coupled_option;
  }

  toggleParamsOption = (param) => {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        [param]: !prevState.params[param],
        ...(this.coupledOption(param) && { [this.coupledOption(param)]: false }),
      }
    }));
  }

  createResponseForMachineCopy = (res) => {
    const { params } = this.state;
    const result = [{}];
    let position = -1;
    const innerNodes = (res, nodes, level = 0) => {
      nodes.forEach((node, idx) => {
        if (level !== 0) {
          res[idx].component_type = node.component_type;
          res[idx].level = level;
          res[idx].position = position;
        }
        position++;
        _.entries(node).forEach(([key, value]) => {
          // measurement settings ignored temporarily until issue it is sorted out in BE
          if (['id', 'component_type', 'locations', 'components', 'unique_id', 'area_name', 'measurement_settings'].includes(key)) return;
          if (key === 'name') {
            if (level !== 0) res[idx][key] = value;
            return;
          }
          if (key === 'bearings') {
            if (!_.isEmpty(value)) {
              res[idx].bearing_models = {};
              node.bearings.forEach((bearing) => {
                const loc = bearing.location_type_id ? bearing.description : bearing.name;
                res[idx].bearing_models[loc] = bearing.properties && bearing.properties.model_id;
                if (!res[idx].locations) res[idx].locations = [];
                if (!bearing.location_type_id) {
                  res[idx].locations.push({
                    description: bearing.description,
                    is_bearing: true,
                    name: bearing.name,
                    bearing_model_id: bearing.properties && bearing.properties.model_id ? bearing.properties.model_id : undefined,
                  });
                }
              });
            }
            return;
          }
          if (key === 'diagnostic_rules') {
            if (params[key]) res[idx][key] = value.map(v => v.id);
            return;
          }
          if (params.info) {
            if (key === 'properties') {
              res[idx].properties = _.pickBy(value, prop => prop !== null);
              return;
            }
            if (value !== null) {
              res[idx][key] = value;
            }
          }
          // copy speed info even if info is not selected and if one of tag properties are selected
          if ((params.overall_alarm_settings || params.alarm_thresholds) && !params.info) {
            if (key === 'properties' && value.vfd) {
              res[idx].properties = _.pick(value, ['speed_bins_config', 'vfd', 'seed_speed']);
            }
          }
        });
        res[idx].components = node.components.map(() => ({}));
        innerNodes(res[idx].components, node.components, level + 1);
      });
    };
    innerNodes(result, [res]);
    const tagProperties = _.pick(params, paramsOptions.filter(p => p.tag_property).map(p => p.value));
    if (_.some(_.values(tagProperties))) {
      result[0].asset_copy_details = {
        original_machine_id: res.id,
        tag_properties: tagProperties
      };
    }
    return result[0];
  }

  renderAssetCopyOptionsField = () => {
    const { params } = this.state;
    return (
      <AssetCopyOptionsContainer>
        {paramsOptions.map(option => (
          <InputField
            type="checkbox"
            value={params[option.value]}
            title={option.text}
            onClick={() => this.toggleParamsOption(option.value)}
          />
        ))}
      </AssetCopyOptionsContainer>
    );
  }

  onProceed = () => {
    const { assetInfo, setCopiedAssetDetails, closeModal } = this.props;
    this.props.machineInfoActions.getMachineInfo(assetInfo.id, true).then(
      (res) => {
        toastr.success(`Asset ${assetInfo.node_name} details copied sucessfully`);
        const details = this.createResponseForMachineCopy(res);
        setCopiedAssetDetails(details);
        closeModal();
      },
      (error) => {
        toastr.error(error.message);
      }
    );
  }

  render() {
    const { closeModal, theme } = this.props;
    return (
      <AlertPrompt
        message={this.getAssetCopyMessage()}
        onCancel={closeModal}
        onProceed={this.onProceed}
        component={this.renderAssetCopyOptionsField()}
        proceedText="COPY"
        proceedColor={theme.primaryColor}
      />
    );
  }
}

AssetPasteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  assetInfo: PropTypes.object.isRequired,
  setCopiedAssetDetails: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  machineInfoActions: bindActionCreators(machineInfoActions, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withTheme(AssetPasteModal));
