export const dashboardConstants = {
  GET_DASHBOARD_CHARTS_DATA_REQUEST: 'GET_DASHBOARD_CHARTS_DATA_REQUEST',
  GET_DASHBOARD_CHARTS_DATA_SUCCESS: 'GET_DASHBOARD_CHARTS_DATA_SUCCESS',
  GET_DASHBOARD_CHARTS_DATA_FAILURE: 'GET_DASHBOARD_CHARTS_DATA_FAILURE',

  GET_ACCOUNT_DASHBOARDS_REQUEST: 'GET_ACCOUNT_DASHBOARDS_REQUEST',
  GET_ACCOUNT_DASHBOARDS_SUCCESS: 'GET_ACCOUNT_DASHBOARDS_SUCCESS',
  GET_ACCOUNT_DASHBOARDS_FAILURE: 'GET_ACCOUNT_DASHBOARDS_FAILURE',

  GET_DASHBOARDS_REQUEST: 'GET_DASHBOARDS_REQUEST',
  GET_DASHBOARDS_SUCCESS: 'GET_DASHBOARDS_SUCCESS',
  GET_DASHBOARDS_FAILURE: 'GET_DASHBOARDS_FAILURE',

  CREATE_DASHBOARD_REQUEST: 'CREATE_DASHBOARD_REQUEST',
  CREATE_DASHBOARD_SUCCESS: 'CREATE_DASHBOARD_SUCCESS',
  CREATE_DASHBOARD_FAILURE: 'CREATE_DASHBOARD_FAILURE',

  DELETE_DASHBOARD_REQUEST: 'DELETE_DASHBOARD_REQUEST',
  DELETE_DASHBOARD_SUCCESS: 'DELETE_DASHBOARD_SUCCESS',
  DELETE_DASHBOARD_FAILURE: 'DELETE_DASHBOARD_FAILURE',

  UPDATE_DASHBOARD_REQUEST: 'UPDATE_DASHBOARD_REQUEST',
  UPDATE_DASHBOARD_SUCCESS: 'UPDATE_DASHBOARD_SUCCESS',
  UPDATE_DASHBOARD_FAILURE: 'UPDATE_DASHBOARD_FAILURE',

  UPDATE_ACCOUNT_DASHBOARD_REQUEST: 'UPDATE_ACCOUNT_DASHBOARD_REQUEST',
  UPDATE_ACCOUNT_DASHBOARD_SUCCESS: 'UPDATE_ACCOUNT_DASHBOARD_SUCCESS',
  UPDATE_ACCOUNT_DASHBOARD_FAILURE: 'UPDATE_ACCOUNT_DASHBOARD_FAILURE',

  SET_ACTIVE_DASHBOARD: 'SET_ACTIVE_DASHBOARD',

  GET_DASHBOARD_MACHINES_REQUEST: 'GET_DASHBOARD_MACHINES_REQUEST',
  GET_DASHBOARD_MACHINES_SUCCESS: 'GET_DASHBOARD_MACHINES_SUCCESS',
  GET_DASHBOARD_MACHINES_FAILURE: 'GET_DASHBOARD_MACHINES_FAILURE',

  GET_DASHBOARD_ACTIVITY_REQUEST: 'GET_DASHBOARD_ACTIVITY_REQUEST',
  GET_DASHBOARD_ACTIVITY_SUCCESS: 'GET_DASHBOARD_ACTIVITY_SUCCESS',
  GET_DASHBOARD_ACTIVITY_FAILURE: 'GET_DASHBOARD_ACTIVITY_FAILURE',

  CLEAR_DASHBOARD_ACTIVITY_LIST: 'CLEAR_DASHBOARD_ACTIVITY_LIST',

  SET_DASHBOARD_MACHINES_SORTER: 'SET_DASHBOARD_MACHINES_SORTER',
  SET_DASHBOARD_ACTIVITY_SORTER: 'SET_DASHBOARD_ACTIVITY_SORTER',

  NUMBER_OF_CRITICAL_MACHINES: 10,
  NUMBER_OF_ATTENTION_MACHINES: 8,
  NUMBER_OF_ACTIVITY_CARDS: 5,

  UPDATE_DASHBOARD_SETTINGS_REQUEST: 'UPDATE_DASHBOARD_SETTINGS_REQUEST',
  UPDATE_DASHBOARD_SETTINGS_SUCCESS: 'UPDATE_DASHBOARD_SETTINGS_SUCCESS',
  UPDATE_DASHBOARD_SETTINGS_FAILURE: 'UPDATE_DASHBOARD_SETTINGS_FAILURE'
};

export const accountDashboardConstants = {
  CREATE_ACCOUNT_DASHBOARD_REQUEST: 'CREATE_ACCOUNT_DASHBOARD_REQUEST',
  CREATE_ACCOUNT_DASHBOARD_SUCCESS: 'CREATE_ACCOUNT_DASHBOARD_SUCCESS',
  CREATE_ACCOUNT_DASHBOARD_FAILURE: 'CREATE_ACCOUNT_DASHBOARD_FAILURE',
  TYPES: {
    TEMPERATURE: 'temperature'
  }
};
