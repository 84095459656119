import React from 'react';
import { Popup } from 'semantic-ui-react';
import { withTheme } from 'styled-components';
import { Info } from 'common/images/FaIcons';

const InfoPopup = ({ info, theme }) => (
  <Popup
    trigger={(
      <span style={{ marginLeft: '10px' }}>
        <Info size="sm" style={{ color: theme.colors.greyD }} />
      </span>
    )}
  >
    <div style={{ width: '50rem' }}>
      {info}
    </div>
  </Popup>
);

export default withTheme(InfoPopup);
