import PropTypes from 'prop-types';
import React from 'react';

import FlexContainer from 'common/components/atoms/FlexContainer';
import H2 from 'common/typography/H2/H2';
import InputField from 'common/components/atoms/InputField';

import {
  AVG_DAYS_OPTIONS,
  AVG_READINGS_OPTIONS,
  BASE_GRAPH_OPTIONS,
} from '../../../constants/envelope.constants';


const BaseGraphSettings = ({
  settings,
  updateEnvelopeSettings
}) => (
  <FlexContainer marginright="2em" direction="column">
    <H2>Base graph</H2>
    <InputField
      disabled={settings.type !== BASE_GRAPH_OPTIONS.AVG_OF_LAST_READINGS}
      label="Avg. of last"
      options={AVG_READINGS_OPTIONS}
      onClickSelectable={() => {
        if (settings.type === BASE_GRAPH_OPTIONS.AVG_OF_LAST_READINGS) return;
        updateEnvelopeSettings('base_graph', {
          type: BASE_GRAPH_OPTIONS.AVG_OF_LAST_READINGS,
          value: AVG_READINGS_OPTIONS[0].value
        });
      }}
      onChange={(e, data) => {
        updateEnvelopeSettings('base_graph', {
          ...settings,
          value: data.value
        });
      }}
      prefix="readings"
      selectable="radio"
      selectableValue={settings.type === BASE_GRAPH_OPTIONS.AVG_OF_LAST_READINGS}
      type="select"
      value={settings.type === BASE_GRAPH_OPTIONS.AVG_OF_LAST_READINGS ? settings.value : ''}
    />
    <InputField
      disabled={settings.type !== BASE_GRAPH_OPTIONS.AVG_OF_LAST_DAYS}
      label="Avg. of readings from the last"
      options={AVG_DAYS_OPTIONS}
      onClickSelectable={() => {
        if (settings.type === BASE_GRAPH_OPTIONS.AVG_OF_LAST_DAYS) return;
        updateEnvelopeSettings('base_graph', {
          type: BASE_GRAPH_OPTIONS.AVG_OF_LAST_DAYS,
          value: AVG_DAYS_OPTIONS[0].value
        });
      }}
      onChange={(e, data) => {
        updateEnvelopeSettings('base_graph', {
          ...settings,
          value: data.value
        });
      }}
      prefix="days"
      selectable="radio"
      selectableValue={settings.type === BASE_GRAPH_OPTIONS.AVG_OF_LAST_DAYS}
      type="select"
      value={settings.type === BASE_GRAPH_OPTIONS.AVG_OF_LAST_DAYS ? settings.value : ''}
    />
    <InputField
      onChange={() => {
        if (settings.type === BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE) return;
        updateEnvelopeSettings('base_graph', {
          type: BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE,
          value: ''
        });
      }}
      title="The currently visible reading"
      type="radio"
      value={settings.type === BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE}
    />
  </FlexContainer>
);

BaseGraphSettings.propTypes = {
  updateEnvelopeSettings: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired
};


export default BaseGraphSettings;
