import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'common/components/atoms/FlexContainer';
import InputField_T from 'common/components/atoms/InputField';
import colors from 'common/styles/colors';

import CreateAndDisplayEnvelope from './CreateAndDisplayEnvelope';

const TagDescription = styled(FlexContainer)`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const InputField = styled(InputField_T)`
  margin-right: 1em;
  position: absolute;
  top: 210px;
  left: 10px;
  height: max-content;
`;

const ChartsContainer = styled.div`
  position: relative;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid ${props => props.selected ? props.theme.primaryColor : colors.greyXD};
  margin-bottom: 20px;
`;


const EnvelopeAlarmPreviewItem = ({
  config,
  envelopeSettings,
  frequencyUnits,
  loading,
  selected,
  spectralEnvelope,
  spectrum,
  tag,
  toggleSelectedTag,
  unitSystem,
  componentNode,
  assetType,
  spectrumXScale,
  setSpectrumXScale,
  spectrumXUnit,
  setSpectrumXUnit
}) => (
  <ChartsContainer selected={selected}>
    <TagDescription>{tag.description}</TagDescription>
    {!loading && !spectrum && (
      <FlexContainer>No data available for this tag</FlexContainer>
    )}
    {!loading && spectrum && (
      <>
        <InputField
          onClick={() => toggleSelectedTag(tag.id)}
          type="checkbox"
          value={selected}
        />
        <FlexContainer style={{ margin: '0px 20px' }} direction="column" flexBasis="100%">
          <CreateAndDisplayEnvelope
            config={config}
            componentNode={componentNode}
            chartName={`envelope-spectrum-item-${tag.id}`}
            disableInitialBrush
            envelopeSettings={envelopeSettings}
            frequencyUnits={frequencyUnits}
            spectralEnvelope={spectralEnvelope}
            spectrum={spectrum}
            tag={tag}
            unitSystem={unitSystem}
            computeEnvelope={false}
            assetType={assetType}
            spectrumXScale={spectrumXScale}
            setSpectrumXScale={setSpectrumXScale}
            spectrumXUnit={spectrumXUnit}
            setSpectrumXUnit={setSpectrumXUnit}
            disableXaxisToggle
          />
        </FlexContainer>
      </>
    )}
  </ChartsContainer>
);

EnvelopeAlarmPreviewItem.propTypes = {
  envelopeSettings: PropTypes.object.isRequired,
  frequencyUnits: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
  spectralEnvelope: PropTypes.object,
  spectrum: PropTypes.object,
  tag: PropTypes.object.isRequired,
  toggleSelectedTag: PropTypes.func.isRequired,
  unitSystem: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  assetType: PropTypes.string.isRequired
};

EnvelopeAlarmPreviewItem.defaultProps = {
  selected: true,
  spectralEnvelope: null,
  spectrum: null
};

export default EnvelopeAlarmPreviewItem;
