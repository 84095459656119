import _ from 'lodash';
import { axiosInstance, ENDPOINT } from 'common/constants';
import { toastr } from 'react-redux-toastr';
import { handleResponse } from 'common/helpers';
import activityTypes from 'home/Activity/actions/activity.types';
import { getMachineDetails } from 'home/Machines/MachineDetails/machineDetails.actions';
import { getTaskDetails } from './tasks.actions';
import { repairConstants } from '../constants/constants';
import { uploadAttachment } from './attachments.actions';

export const addRepair = (params) => {
  const request = () => ({ type: repairConstants.ADD_REPAIR_REQUEST });
  const success = () => ({ type: repairConstants.ADD_REPAIR_SUCCESS });
  const failure = () => ({ type: repairConstants.ADD_REPAIR_FAILURE });

  return (dispatch, getState) => {
    dispatch(request());

    return handleResponse(axiosInstance.post(ENDPOINT.ADD_REPAIR, { ...params }))
      .then(
        (res) => {
          const files = getState().attachments.files && getState().attachments.files.repair;

          dispatch(success(res.result));

          if (!params.task_id) dispatch(getMachineDetails(params.machine_id));
          else dispatch(getTaskDetails(params.task_id));

          if (!_.isEmpty(files)) {
            dispatch(uploadAttachment('repair', res.id, files, 0));
          }

          toastr.success('Repair created successfully');
          return res;
        },
        (error) => {
          dispatch(failure(error));
          toastr.error(error.message);
          throw error;
        }
      );
  };
};

export const deleteRepair = (repairId, task_id = null) => {
  const request = () => ({ type: activityTypes.DELETE_REPAIR_REQUEST });
  const success = repairId => ({ type: activityTypes.DELETE_REPAIR_SUCCESS, repairId });
  const failure = () => ({ type: activityTypes.DELETE_REPAIR_FAILURE });

  return (dispatch) => {
    dispatch(request());

    return handleResponse(axiosInstance.delete(ENDPOINT.REPAIR(repairId)))
      .then(
        (res) => {
          dispatch(success(repairId));
          if (task_id) dispatch(getTaskDetails(task_id));
          toastr.success('Repair deleted successfully');
          return res;
        },
        (error) => {
          dispatch(failure(error));
          toastr.error(error.message);
          throw error;
        }
      );
  };
};

export const updateRepair = (repairId, params) => {
  const request = () => ({ type: repairConstants.UPDATE_REPAIR_REQUEST });
  const success = repair => ({ type: repairConstants.UPDATE_REPAIR_SUCCESS, repair });
  const failure = () => ({ type: repairConstants.UPDATE_REPAIR_FAILURE });

  return (dispatch) => {
    dispatch(request());

    return handleResponse(axiosInstance.patch(ENDPOINT.REPAIR(repairId), { ...params }))
      .then(
        (res) => {
          dispatch(success({ ...res, activity_type: 'repair' }));
          if (res.task_id) dispatch(getTaskDetails(res.task_id));
          toastr.success('Repair updated successfully');
          return res;
        },
        (error) => {
          dispatch(failure(error));
          toastr.error(error.message);
          throw error;
        }
      );
  };
};

export const getRepairDetails = (id) => {
  const request = () => ({ type: repairConstants.GET_REPAIR_DETAILS_REQUEST });
  const success = repair => ({ type: repairConstants.GET_REPAIR_DETAILS_SUCCESS, repair });
  const failure = error => ({ type: repairConstants.GET_REPAIR_DETAILS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.REPAIR(id)))
      .then(
        (res) => {
          dispatch(success(res));
        },
        (error) => {
          dispatch(failure(error));
          toastr.error(error.message);
        }
      );
  };
};

export const openRepairModal = (repairId = null) => {
  const setModalOpen = () => ({ type: repairConstants.SET_REPAIR_MODAL_OPEN, repairId });
  return (dispatch) => {
    dispatch(setModalOpen());
    if (repairId) dispatch(getRepairDetails(repairId));
  };
};

export const closeRepairModal = () => {
  const setModalClose = () => ({ type: repairConstants.SET_REPAIR_MODAL_CLOSE });
  return dispatch => dispatch(setModalClose());
};
