import { accountAnalyticsConstants } from './constants/accountAnalytics.constants';

export const accountAnalyticsReducer = (state = {}, action) => {
  switch (action.type) {
    case accountAnalyticsConstants.GET_ACCOUNT_ANALYTICS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case accountAnalyticsConstants.GET_ACCOUNT_ANALYTICS_SUCCESS: {
      return {
        ...state,
        ...action.data,
        loading: false
      };
    }
    case accountAnalyticsConstants.GET_ACCOUNT_ANALYTICS_FAILURE: {
      return {
        ...state,
        error: action.error.message,
        loading: false
      };
    }
    case accountAnalyticsConstants.GET_ACCOUNT_ANALYTICS_SUMMARY_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case accountAnalyticsConstants.GET_ACCOUNT_ANALYTICS_SUMMARY_SUCCESS: {
      return {
        ...state,
        ...action.data,
        loading: false
      };
    }
    case accountAnalyticsConstants.GET_ACCOUNT_ANALYTICS_SUMMARY_FAILURE: {
      return {
        ...state,
        error: action.error.message,
        loading: false
      };
    }
    default:
      return state;
  }
};
