import _ from 'lodash';
import { REVERSE_CONDITION_MAP, REVERSE_MACHINE_STATE_MAP, REVERSE_CRITICALITY_MAP } from 'common/constants';
import { filtersIncludedInCharts, machinesAnalyticsMetadata, customLabels } from 'home/MachinesAnalytics/machinesAnalytics.constants';
import { CapitalizeEachWord, convertTimestringsToDateObjects } from 'common/utils';
import { humanize } from 'common/helpers';
import colors from 'common/styles/colors';
import moment from 'moment';

export const getShaftName = (shaftNumber, shaftCount) => {
  switch (shaftNumber) {
    case 1:
      return 'Input Shaft';
    case shaftCount:
      return 'Output Shaft';
    default:
      return `Intermediate Shaft ${shaftNumber - 1}`;
  }
};

export const getHSColor = (score, levels) => {
  if (score === null) return null;
  const { health_score: hsMetadata } = machinesAnalyticsMetadata;
  for (let i = 0; i < hsMetadata.filterOptions[levels].length; i++) {
    const r = hsMetadata.filterOptions[levels][i].value;
    if (r !== 'unknown' && (
      r[0] === 0 && score === 0 || r[0] < score && score <= r[1]
    )) {
      return hsMetadata.colors[levels][i];
    }
  }
  return null;
};

export const getSearchParamFilters = (searchParams, forCharts = false) => {
  const filters = [];
  let filterKeyName = '';
  Object.keys(searchParams).forEach((filterKey) => {
    if (
      filterKey === 'searchKey' ||
      (forCharts && !filtersIncludedInCharts.includes(filterKey))
    ) {
      return;
    }
    if (searchParams[filterKey].length > 0) {
      if (filterKey === 'state') {
        filterKeyName = 'current_state';
      } else if (filterKey === 'area') {
        filterKeyName = 'area_name';
      } else if (filterKey === 'transmission') {
        filterKeyName = 'component_subtype';
      } else if (filterKey === 'location') {
        filterKeyName = 'locations';
      } else {
        filterKeyName = filterKey;
      }
      const filter = {
        name: filterKeyName,
        op: 'in',
        value: searchParams[filterKey].map((filterValue) => {
          if (filterKey === 'criticality') {
            return REVERSE_CRITICALITY_MAP[filterValue];
          }
          if (filterKey === 'condition') {
            return REVERSE_CONDITION_MAP[filterValue];
          } if (filterKey === 'state') {
            return REVERSE_MACHINE_STATE_MAP[filterValue];
          } if (
            filterKey === 'location' ||
            filterKey === 'health_score'
          ) {
            return filterValue.value;
          } if (filterKey === 'area') {
            return filterValue.name;
          } return filterValue;
        })
      };
      if (filterKey === 'vfd' || filterKey === 'asset_setup' || filterKey === 'events') {
        filter.op = 'eq';
        filter.value = searchParams[filterKey][0].value;
      }
      filters.push(filter);
    }
  });
  return filters;
};

export const formatAssetSetupData = (data, metadata) => {
  const filterOptions = [];

  const numberOfBars = Object.keys(data).length;
  let finalData = [...Array(numberOfBars + 1)].map(() => [null]);
  finalData[0][0] = 'Type of Setup';
  if (metadata.sortOrder) {
    Object.keys(metadata.sortOrder).forEach((step_grp, sortIndex) => {
      filterOptions.push({ groupName: step_grp });
      finalData[sortIndex + 1][0] = customLabels[step_grp] || humanize(step_grp);
      const fields = !_.isEmpty(metadata.sortOrder[step_grp]) ? metadata.sortOrder[step_grp] : Object.keys(data[step_grp]);
      fields.forEach((field) => {
        if (_.isNil(data[step_grp][field])) return;
        filterOptions.push({ text: field, value: { field, step_grp } });
        finalData[0].push({
          label: customLabels[field] || humanize(field),
          type: 'number'
        });
        for (let i = 1; i <= numberOfBars; i++) {
          if (i !== (sortIndex + 1)) finalData[i].push(null);
          else finalData[i].push(data[step_grp][field]);
        }
      });
    });

    finalData = finalData.filter(row => _.some(row.slice(1), Boolean));
  }

  if (finalData.length === 1) {
    finalData = 'Setup Complete';
  }

  return [finalData, filterOptions];
};

export const formatEventsData = (data, metadata) => {
  const finalData = Object.keys(data)
    .map(item => [item, data[item]]);
  const filterOptions = Object.keys(data)
    .map(key => ({ text: key, value: key }));
  let colorIdx = 0;
  for (let i = 0; i < finalData.length; i++) {
    if (finalData[i][0].includes('critical') || finalData[i][0] === 'device_unreachable') {
      finalData[i].push(colors.critical);
    } else if (finalData[i][0].includes('warning')) {
      finalData[i].push(colors.warning);
    } else {
      finalData[i].push(metadata.colors[colorIdx]);
      colorIdx = (colorIdx + 1) % metadata.colors.length;
    }
    finalData[i][0] = customLabels[finalData[i][0]] || humanize(finalData[i][0]);
  }
  return [finalData, filterOptions];
};

export const formatPlantTrendData = (data, metadata) => {
  const titleRow = ['Asset Condition', ...metadata.conditionLabels];
  const keys = metadata.conditionLabels.map(l => l.toLowerCase());
  let trendData = data.data.map((obj) => {
    const healthObj = Object.assign({}, obj);
    healthObj.time = new Date(obj.time * 1000);
    healthObj.day = `${healthObj.time.toLocaleString('en-us', { month: 'short' })} ${healthObj.time.getDate()}`;
    return healthObj;
  });
  trendData = trendData.slice(trendData.length - 7, trendData.length);
  const formattedData = trendData.map(d => [
    d.day, ...keys.map(key => d[key])
  ]);
  return [[titleRow, ...formattedData], null];
};

export const formatEventTrendData = (res) => {
  const data = [['Events count', 'Open', 'Closed']];
  const yDataOpen = res.open_count;
  const yDataClosed = res.closed_count;
  const xData = convertTimestringsToDateObjects(res.timestamps);
  if (!yDataOpen || !yDataClosed || !xData) return data;
  for (let i = 0; i < xData.length; i++) {
    data[i + 1] = [moment(xData[i]).format('MMM DD'), yDataOpen[i], yDataClosed[i]];
  }
  return [data, null];
};

export const formatDefectsTrendData = (res, metadata) => {
  const barData = [['Total defects', 'Number of defects', { role: 'style' }, { type: 'string', role: 'tooltip' }]];
  let colorIdx = 0;
  res.bar.forEach((defect, i) => {
    const label = CapitalizeEachWord(defect.name).split(' ').reduce((prev, curr) => prev + curr[0], '');
    const tooltip = `${defect.name} (${defect.count})`;
    barData[i + 1] = [
      label, defect.count, metadata.colors[colorIdx], tooltip
    ];
    colorIdx += 1;
  });
  barData.sort((a, b) => {
    if (a[3] === 'Others' || a[1] < b[1]) return 1;
    if (b[3] === 'Others' || a[1] > b[1]) return -1;
    return 0;
  });
  const rawData = res.bar;
  res.bar = barData;
  return [res, { account_id: metadata.account_id, rawData }];
};
