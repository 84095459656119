import React from 'react';
import PropTypes from 'prop-types';

const DownloadSvg = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 14 16">
    <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3-5-2-Machines-Expanded-Dropdown" transform="translate(-1010.000000, -441.000000)">
        <g id="dropdown/active" transform="translate(993.000000, 229.000000)">
          <g id="download" transform="translate(16.000000, 212.000000)">
            <g id="download/active-icon">
              <rect id="bounds" x="0" y="0" width={height} height={height} />
              <g id="icon" transform="translate(2.000000, 1.000000)" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                <path d="M0,10.5 L0,12.6 C0,13.3731986 0.586099964,14 1.30909091,14 L10.4727273,14 C11.1957182,14 11.7818182,13.3731986 11.7818182,12.6 L11.7818182,10.5" id="Shape" />
                <polyline id="Shape" points="3 7 6 9.8 9 7" />
                <path d="M6,0 L6,9" id="Shape" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

DownloadSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

DownloadSvg.defaultProps = {
  width: 14,
  height: 16,
  fill: '#000000'
};


export default DownloadSvg;
