import colors from 'common/styles/colors';
import { formatAssetSetupData, formatDefectsTrendData, formatEventsData, formatEventTrendData, formatPlantTrendData } from 'home/Machines/utils';
import { navToActiviyPage, navToAssetsPage, navToDevicesPage } from '../helpers/helpers';

export const widgetConstants = {
  GET_WIDGET_METADATA_REQUEST: 'GET_WIDGET_METADATA_REQUEST',
  GET_WIDGET_METADATA_SUCCESS: 'GET_WIDGET_METADATA_SUCCESS',
  GET_WIDGET_METADATA_FAILURE: 'GET_WIDGET_METADATA_FAILURE',

  GET_WIDGET_DATA_REQUEST: 'GET_WIDGET_DATA_REQUEST',
  GET_WIDGET_DATA_SUCCESS: 'GET_WIDGET_DATA_SUCCESS',
  GET_WIDGET_DATA_FAILURE: 'GET_WIDGET_DATA_FAILURE',
};

const VFD_OPTIONS = {
  VFD_ROTATING: 'vfd_rotating',
  NON_VFD_ROTATING: 'non_vfd_rotating',
  OTHER: 'other'
};

export const customLabels = {
  vfd_rotating: 'VFD Rotating',
  non_vfd_rotating: 'Non-VFD Rotating',
  pole_count_speed: 'Pole Count / Rated Speed',
};

export const widgetCalendarOptions = [
  { text: '1D', value: 1 },
  { text: '7D', value: 7 },
  { text: '14D', value: 14 },
  { text: '30D', value: 30 },
  { text: '90D', value: 90 },
  { text: 'All', value: 'all' },
];

const defectWidgetOptions = {
  orientation: 'horizontal',
  isInteractive: true,
  isStacked: false,
  chartArea: { width: '75%', left: '15%' },
  dataFormatter: formatDefectsTrendData,
  yLabelFontSize: 8,
  hasCustomTooltip: true,
  colors: [
    '#FF9764',
    '#FFE25F',
    '#37B6B8',
    '#05FBA1',
    '#4babfa',
    '#A6FF36',
    '#ff8282',
    '#FB056A',
    '#b14aff',
    '#ff4a4a',
    colors.greyXD
  ],
  sortOrder: ['total_count'],
  handleClick: (id, filterOptions) => data => navToActiviyPage(id, data, filterOptions)
};

export const keyMetadataMap = {
  device_reachability_breakdown: {
    formatData: true,
    colors: [colors.normal, colors.critical, colors.unknown],
    sortOrder: ['reachable', 'unreachable', 'unassigned'],
    filters: [
      { name: 'last_measurement', op: 'in', value: [false] },
      { name: 'last_measurement', op: 'in', value: [true] },
      { name: 'last_measurement', op: 'in', value: ['unassigned'] }
    ],
    handleClick: (id, filterOptions) => (data, index) => navToDevicesPage(id, index, filterOptions)
  },
  device_battery_level_breakdown: {
    formatData: true,
    colors: [colors.normal, colors.warning, colors.critical, colors.unknown],
    sortOrder: ['> 20%', '< 20%', '< 10%', 'unknown'],
    filters: [
      { name: 'battery_level', op: 'in', value: [1] },
      { name: 'battery_level', op: 'in', value: [2] },
      { name: 'battery_level', op: 'in', value: [3] },
      { name: 'battery_level', op: 'in', value: [null] }
    ],
    handleClick: (id, filterOptions) => (data, index) => navToDevicesPage(id, index, filterOptions)
  },
  device_wifi_strength_breakdown: {
    formatData: true,
    colors: [colors.normal, colors.slight, colors.moderate, colors.warning, colors.critical, colors.unknown],
    sortOrder: ['strong', 'good', 'normal', 'weak', 'very weak', 'unknown'],
    filters: [
      { name: 'wifi_signal', op: 'in', value: [4] },
      { name: 'wifi_signal', op: 'in', value: [3] },
      { name: 'wifi_signal', op: 'in', value: [2] },
      { name: 'wifi_signal', op: 'in', value: [1] },
      { name: 'wifi_signal', op: 'in', value: [0] },
      { name: 'wifi_signal', op: 'in', value: [null] }
    ],
    handleClick: (id, filterOptions) => (data, index) => navToDevicesPage(id, index, filterOptions)
  },
  firmware_version_breakdown: {
    formatData: true,
    colors: [colors.normal, colors.slight, colors.moderate],
  },
  device_unreachable_trend: {
    colors: [colors.green],
  },
  missed_measurements_trend: {
    orientation: 'vertical',
    colors: [colors.greyXD],
  },
  asset_health_scores_breakdown: {
    filterName: 'health_score',
    hasSublevels: true,
    // path in redux store
    sublevelPath: 'currentAccount.preferences.machine_condition_levels',
    5: {
      resourceName: 'Machines',
      formatData: true,
      colors: [
        colors.normal,
        colors.slight,
        colors.moderate,
        colors.warning,
        colors.critical,
        colors.unknown
      ],
      sortOrder: ['< 10', '< 8', '< 6', '< 4', '< 2', 'unknown'],
      filters: [
        { text: '< 10', value: [8, 10] },
        { text: '< 8', value: [6, 8] },
        { text: '< 6', value: [4, 6] },
        { text: '< 4', value: [2, 4] },
        { text: '< 2', value: [0, 2] },
        { text: 'unknown', value: 'unknown' }
      ],
      handleClick: (id, filterOptions) => (data, index) => navToAssetsPage(id, index, filterOptions, 'health_score')
    },
    3: {
      resourceName: 'Machines',
      formatData: true,
      colors: [
        colors.normal,
        colors.warning,
        colors.critical,
        colors.unknown
      ],
      sortOrder: ['< 10', '< 7', '< 4', 'unknown'],
      filters: [
        { text: '< 10', value: [7, 10] },
        { text: '< 7', value: [4, 7] },
        { text: '< 4', value: [0, 4] },
        { text: 'unknown', value: 'unknown' }
      ],
      handleClick: (id, filterOptions) => (data, index) => navToAssetsPage(id, index, filterOptions, 'health_score')
    }
  },
  vfd_breakdown: {
    filterName: 'vfd',
    formatData: true,
    sortOrder: [
      VFD_OPTIONS.VFD_ROTATING,
      VFD_OPTIONS.NON_VFD_ROTATING,
      VFD_OPTIONS.OTHER
    ],
    colors: [colors.normal, colors.slight, colors.unknown],
    filters: [
      { text: VFD_OPTIONS.VFD_ROTATING, value: VFD_OPTIONS.VFD_ROTATING },
      { text: VFD_OPTIONS.NON_VFD_ROTATING, value: VFD_OPTIONS.NON_VFD_ROTATING },
      { text: VFD_OPTIONS.OTHER, value: VFD_OPTIONS.OTHER }
    ],
    handleClick: (id, filterOptions) => (data, index) => navToAssetsPage(id, index, filterOptions, 'vfd')
  },
  alarm_review_and_action_report: {
    filterName: 'alarms_review_and_action_report',
    isInteractive: true,
    filters: [
      { text: 'machines_with_alarm_but_no_task', value: 'machines_with_alarm_but_no_task' },
      { text: 'machines_with_task_but_no_alarm', value: 'machines_with_task_but_no_alarm' },
      { text: 'recent_open_alarms', value: 'recent_open_alarms' }
    ],
    handleClick: (id, filterOptions) => (data, switchAccount) => navToAssetsPage(id, data, filterOptions, 'alarm_review_and_action_report', switchAccount)
  },
  open_events_breakdown: {
    resourceName: 'Machines',
    isStacked: false,
    orientation: 'vertical',
    addTitleRow: true,
    chartArea: { width: '80%' },
    filterName: 'events',
    dataFormatter: formatEventsData,
    colors: [
      '#37B6B8',
      '#05FBA1',
      '#4babfa',
      '#A6FF36',
    ],
    handleClick: (id, filterOptions) => (data, index) => navToAssetsPage(id, index, filterOptions, 'events')
  },
  incomplete_asset_setup_breakdown: {
    resourceName: 'Machines',
    isStacked: true,
    sortOrder: {
      baseline: ['partially_configured', 'not_configured'],
      alarms: ['partially_configured', 'not_configured'],
      speed_info: ['pole_count_speed', 'line_frequency', 'gear_ratio', 'speed_ratio'],
      others: []
    },
    filterName: 'asset_setup',
    colors: [
      // for baseline
      colors.slight, colors.moderate,
      // for alarm thresholds
      '#FF9764', '#FFE25F',
      // for speed info
      '#37B6B8', '#05FBA1', '#4babfa', '#A6FF36',
      // for others
      '#ff8282', '#FB056A', '#b14aff', '#ff4a4a',
    ],
    chartArea: { width: '75%', left: '20%', bottom: '30%' },
    dataFormatter: formatAssetSetupData,
    yLabelFontSize: 10,
    handleClick: (id, filterOptions) => (data, index) => navToAssetsPage(id, index, filterOptions, 'asset_setup')
  },
  recent_assets_requiring_attention: {
    // eslint-disable-next-line global-require
    component: () => require('home/Dashboard/components/machines_list/AttentionMachinesPage').default,
    filters: {
      roles: ['assembler', 'admin', 'technician', 'manager', 'developer'],
      filters: [{ name: 'attention_criteria', op: 'in', value: ['tasks'] }]
    }
  },
  plant_asset_health: {
    hasSublevels: true,
    // path in redux store
    sublevelPath: 'currentAccount.preferences.machine_condition_levels',
    5: {
      resourceName: 'Machines',
      formatData: true,
      colors: [
        colors.normal,
        colors.slight,
        colors.moderate,
        colors.warning,
        colors.critical,
        colors.unknown
      ],
      sortOrder: ['good', 'slight', 'moderate', 'warning', 'critical', 'unknown'],
      handleClick: (id, filterOptions) => data => navToAssetsPage(id, data, filterOptions)
    },
    3: {
      resourceName: 'Machines',
      formatData: true,
      colors: [
        colors.normal,
        colors.warning,
        colors.critical,
        colors.unknown
      ],
      sortOrder: ['good', 'warning', 'critical', 'unknown'],
      handleClick: (id, filterOptions) => data => navToAssetsPage(id, data, filterOptions)
    }
  },
  plant_asset_health_trend: {
    hasSublevels: true,
    // path in redux store
    sublevelPath: 'currentAccount.preferences.machine_condition_levels',
    3: {
      orientation: 'vertical',
      isInteractive: false,
      chartArea: { width: '80%', bottom: '30%' },
      dataFormatter: formatPlantTrendData,
      conditionLabels: ['Good', 'Warning', 'Critical', 'Unknown'],
      colors: [
        colors.normal,
        colors.warning,
        colors.critical,
        colors.unknown
      ],
    },
    5: {
      orientation: 'vertical',
      isInteractive: false,
      chartArea: { width: '80%', bottom: '30%' },
      dataFormatter: formatPlantTrendData,
      conditionLabels: ['Good', 'Slight', 'Moderate', 'Warning', 'Critical', 'Unknown'],
      colors: [
        colors.normal,
        colors.slight,
        colors.moderate,
        colors.warning,
        colors.critical,
        colors.unknown
      ],
    }
  },
  current_plant_utilization: {
    resourceName: 'Machines',
    formatData: true,
    colors: [colors.green, colors.greenXL, colors.greyL],
    sortOrder: [
      'ON', 'OFF', 'NA'
    ],
    handleClick: (id, filterOptions) => data => navToAssetsPage(id, data, filterOptions)
  },
  recent_activity: {
    // eslint-disable-next-line global-require
    component: () => require('home/Dashboard/components/activity_list/ActivityPage').default
  },
  open_closed_events_trend: {
    orientation: 'vertical',
    isInteractive: false,
    chartArea: { width: '80%', bottom: '30%' },
    dataFormatter: formatEventTrendData,
    colors: [
      colors.green,
      colors.unknown
    ]
  },
  open_closed_events: {
    sortOrder: ['open', 'closed'],
  },
  diagnostic_rule_result_count: {
    sortOrder: ['open', 'review_pending'],
  },
  active_inactive_tags: {
    sortOrder: ['active', 'inactive'],
  },
  open_closed_tasks: {
    sortOrder: ['open', 'completed'],
  },
  open_closed_repairs: {
    sortOrder: ['open', 'completed'],
  },
  total_defects_detected: {
    ...defectWidgetOptions
  },
  active_defects_detected: {
    ...defectWidgetOptions
  },
  not_analysed_open_alarms: {
    isInteractive: true,
    handleClick: (id, filterOptions) => (data, switchAccount) => navToActiviyPage(id, data, filterOptions, switchAccount)
  },
  device_unreachable_above_fourteen_days_count: {
    sortOrder: ['unreachable'],
    formatData: true,
    filters: [
      { name: 'last_measurement', op: 'in', value: ['unreachable_extended'] },
    ],
    isInteractive: true,
    handleClick: (id, filterOptions) => (data, switchAccount) => navToDevicesPage(id, 0, filterOptions, switchAccount)
  },
  devices_with_continuous_outliers_count: {
    sortOrder: ['total_count'],
    formatData: true,
    isInteractive: true,
    filters: [
      { name: 'other_filters', op: 'in', value: ['devices_with_continuous_outliers'] },
    ],
    handleClick: (id, filterOptions) => (data, switchAccount) => navToDevicesPage(id, 0, filterOptions, switchAccount)
  },
  devices_with_inconsistent_vibration_levels: {
    sortOrder: ['total_count'],
    formatData: true,
    filters: [
      { name: 'other_filters', op: 'in', value: ['devices_with_inconsistent_vibrations'] },
    ],
    isInteractive: true,
    handleClick: (id, filterOptions) => (data, switchAccount) => navToDevicesPage(id, 0, filterOptions, switchAccount)
  },
  area_temperature_monitoring_table: {
    // eslint-disable-next-line global-require
    component: () => require('home/Dashboard/components/temperature_table/AreaTemperaturePage').default
  }
};
