import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import Button from 'common/components/atoms/Button';
import LoadingSvg from 'common/images/LoadingSvg';
import LoadingFullScreen from 'common/components/atoms/LoadingFullScreen';
import FlexContainer from 'common/components/atoms/FlexContainer';
import {
  getAmpUnitConversionFactor,
  getFreqConversionFactor,
  getScaledEnvelope,
  round
} from 'common/utils';
import { measurementOptions, getBinFilterOptions, ASSET_TYPE } from 'common/charts/constants';
import InputField_T from 'common/components/atoms/InputField';
import RBAC from 'common/rbac/RBAC';
import {
  mapComponentToResource,
  operations
} from 'common/rbac/constants';
import { getShaftSpeed } from 'home/AssetHierarchy/helpers/helpers';
import { getEnvelopeSettingsInPrefUnits, transformFromEnvelopeKeys } from 'home/AssetHierarchy/utils/envelopeUtils';

import EnvelopeAlarmPreview from './EnvelopeAlarmPreview';
import EnvelopeAlarmSettings from './EnvelopeAlarmSettings';
import EnvelopeWithTrend from './EnvelopeWithTrend';
import Footer from '../../molecules/Footer';
import {
  BASE_GRAPH_OPTIONS,
  AVG_DAYS_OPTIONS,
  ENVELOPE_STATES,
  RANGES,
  SPECTRAL_WINDOW_TYPE_OPTIONS,
  THRESHOLD_OPTIONS,
  MESSAGE,
  X_SCALE,
  initialEnvelopeSettings,
  initialError,
  ENVELOPE_SETTING_KEYS,
  SPECTRAL_ENVELOPE_KEYS
} from '../../../constants/envelope.constants';

import * as assetDetailActions from '../../../actions/assetDetails.actions';
import * as chartsUpdateActions from '../../../actions/chartUpdate.actions';
import * as envelopeActions from '../../../actions/envelope.actions';

const MContent = styled.div`
  padding: 1em 2em;
  margin-bottom: 60px;
`;

const CancelButton = styled(Button)`
  &:disabled:hover {
    border: 0px;
  };
`;

const InputField = styled(InputField_T)`
  ${props => props.margin && `margin: ${props.margin};`}
`;

const MachineCheckBoxContainer = styled(FlexContainer)`
  align-items: flex-start;
  width: 100%;
  position: relative;
  label {
    font-size: 14px;
  }
`;


class EnvelopeAlarmModal extends Component {
  constructor(props) {
    super(props);
    this.binFilterOptions = this.getBinOptions();
    this.assetType = this.getAssetType();
    this.state = {
      envelopeSettings: _.cloneDeep(this.getEnvelopeSettings()),
      envelopeState: ENVELOPE_STATES.VIEW,
      error: {},
      binSelectorError: '',
      showSaveLoader: false,
      showCopySettingsLoader: false,
      isEnabledForMachine: false,
      showPreview: false,
      activeSpeedRangeTab: this.getInitialActiveTab(),
      spectralEnvelope: _.cloneDeep(this.getEnvelopeData()),

      // orders or frequency units
      spectrumXScale: this.assetType === ASSET_TYPE.VFD ? X_SCALE.ORDERS : props.currentUser.spectrum_x_scale,
      spectrumXUnit: props.currentUser.frequency_units
    };
  }

  componentDidMount() {
    const {
      tag,
      currentUser: { frequency_units, unit_system },
      breadcrumb: { machine, site }
    } = this.props;
    if (!_.isEqual(tag.unit_system, unit_system) || !_.isEqual(tag.frequency_units, frequency_units)) {
      this.props.assetDetailActions.getTags(machine.id, site.id);
    }
    if (this.state.spectrumXScale === X_SCALE.ORDERS) {
      this.setSpectrumXUnit('Orders');
    }
  }

  componentDidUpdate(prevProps) {
    const { config, tag, currentUser } = this.props;
    const { spectrumXUnit } = this.state;
    if (
      prevProps.config.spectrum &&
      config.spectrum &&
      !_.isEqual(prevProps.config.spectrum.timestamp, config.spectrum.timestamp)
    ) {
      this.updateEnvelopeSpectrumIfRequired();
    }

    if (
      !_.isEqual(prevProps.tag.envelope_settings, tag.envelope_settings) ||
      !_.isEqual(prevProps.tag.spectral_envelope, tag.spectral_envelope)
    ) {
      const shaft_speed = this.getShaftSpeed();
      const oldUnit = this.assetType === ASSET_TYPE.VFD ? 'Orders' : currentUser.frequency_units;
      const envelopeSettings = getEnvelopeSettingsInPrefUnits(
        this.getEnvelopeSettings(),
        oldUnit,
        spectrumXUnit,
        shaft_speed,
        currentUser.frequency_units
      );
      this.setState({
        envelopeSettings,
        spectralEnvelope: _.cloneDeep(this.getEnvelopeData())
      });
    }
  }

  setSpectrumXScale = (spectrumXScale) => {
    this.setState({ spectrumXScale });
  }

  setSpectrumXUnit = (spectrumXUnit) => {
    const { config } = this.props;
    this.setState((prevState) => {
      if (!prevState.envelopeSettings) return {
        spectrumXUnit
      };
      const shaft_speed = this.getShaftSpeed();
      let oldUnit;
      if (this.assetType === ASSET_TYPE.VFD) {
        oldUnit = 'Orders';
      } else {
        oldUnit = prevState.spectrumXUnit;
      }
      const newEnvelopeSettings = getEnvelopeSettingsInPrefUnits(
        prevState.envelopeSettings,
        oldUnit,
        spectrumXUnit,
        shaft_speed,
        config.spectrum.freq_units
      );
      return {
        spectrumXUnit,
        envelopeSettings: newEnvelopeSettings
      };
    });
  }

  getShaftSpeed = () => {
    const {
      componentNode,
      waveAndSpecSelection,
      frequencyUnits,
      config,
      tag
    } = this.props;

    const useSpectrumShaftSpeed = this.assetType === ASSET_TYPE.VFD;
    const spectrumShaftSpeed = config.spectrum.shaft_speed;

    let spectrumFeatures;
    if (config.spectrumFeatures && tag && tag.forcingFrequencies) {
      spectrumFeatures = {
        items: config.spectrumFeatures.items.concat(tag.forcingFrequencies.items)
      };
    } else if (config.spectrumFeatures) {
      spectrumFeatures = config.spectrumFeatures;
    } else if (tag && tag.forcingFrequencies) {
      spectrumFeatures = tag.forcingFrequencies;
    }

    const measurementTimestamp = waveAndSpecSelection && waveAndSpecSelection.x;

    const shaftSpeed = getShaftSpeed(
      spectrumShaftSpeed,
      useSpectrumShaftSpeed,
      componentNode,
      measurementTimestamp,
      frequencyUnits,
      spectrumFeatures
    );

    return shaftSpeed;
  }

  getBinOptions = () => {
    const { tag } = this.props;
    const binFilterOptions = getBinFilterOptions(
      tag && tag.no_of_bins ? tag.no_of_bins : 0,
      !tag.no_of_bins
    );
    return binFilterOptions;
  }

  getAssetType = () => {
    const { tag } = this.props;
    if (tag && tag.no_of_bins) return ASSET_TYPE.VFD;
    return ASSET_TYPE.FIXED_SPEED;
  }

  getInitialActiveTab = () => {
    const { config: { spectrum: { speed_bin } } } = this.props;
    if (speed_bin) return speed_bin;
    return this.binFilterOptions[0].value;
  }

  getEnvelopeSettingsBins = () => {
    const { envelopeSettings } = this.state;
    let bins = [];
    if (envelopeSettings) {
      bins = Object.keys(envelopeSettings).map(bin_no => parseInt(bin_no.split('_')[1], 10));
    }
    return bins;
  }

  getBinsWithError = () => {
    const bins = this.getEnvelopeSettingsBins();
    return bins.filter(bin => this.isError(`bin_${bin}`));
  }

  updateEnvelopeSpectrumIfRequired = () => {
    const { envelopeSettings, envelopeState, activeSpeedRangeTab: bin } = this.state;
    const { measurement_type, amp_type } = this.props;
    const bin_no = `bin_${bin}`;
    if (
      !envelopeSettings ||
      !envelopeSettings[bin_no] ||
      envelopeState === ENVELOPE_STATES.EDIT ||
      envelopeState === ENVELOPE_STATES.VIEW
    ) return;
    const { tag, config } = this.props;
    const { base_graph } = envelopeSettings[bin_no];
    const params = {
      base_graph_type: BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE,
      timestamps: [config.spectrum.timestamp],
      measurement_type,
      amp_type
    };
    if (bin) params.bin_no = bin;
    if (base_graph.type === BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE) {
      this.fetchBaseGraphSpectrums(tag.id, params);
    }
  }

  onContinue = () => {
    this.setState({ showPreview: true });
  };

  setEnvelopeState = (envelopeState) => {
    this.setState({ envelopeState });
  }

  createEnvelope = () => {
    const { config: { spectrum: { speed_bin } } } = this.props;
    if (speed_bin || (!speed_bin && this.assetType === ASSET_TYPE.FIXED_SPEED)) this.onSetSettingsClick();
    this.setState({
      envelopeState: ENVELOPE_STATES.CREATE
    });
  }

  onEdit = () => {
    this.setEnvelopeState(ENVELOPE_STATES.EDIT);
  }

  resetBaseGraph = () => {
    this.setEnvelopeState(ENVELOPE_STATES.RESET);
    const { tag } = this.props;
    const { activeSpeedRangeTab: bin } = this.state;
    const defaultValues = this.getDefaultParamsAndSettings();
    const params = { ...defaultValues.params };
    if (bin) params.bin_no = bin;
    this.fetchBaseGraphSpectrums(tag.id, params);
    this.updateEnvelopeSettings('base_graph', {
      ...defaultValues.settings.base_graph
    });
  }

  updateEnvelopeData = (allEnvelopeData, bins) => {
    const newSpectralEnvelope = {};
    bins.forEach((bin_no) => {
      if (allEnvelopeData[bin_no]) {
        newSpectralEnvelope[bin_no] = {
          envelope_data: { ...allEnvelopeData[bin_no] }
        };
      }
    });
    this.setState(prevState => ({
      spectralEnvelope: {
        ...prevState.spectralEnvelope,
        ...newSpectralEnvelope
      }
    }));
  }

  setBaseGraphError = (error) => {
    const bin_no = `bin_${this.state.activeSpeedRangeTab}`;
    this.setState(prevState => ({
      error: {
        ...prevState.error,
        [bin_no]: {
          ...prevState.error[bin_no],
          base_graph: error
        }
      }
    }));
  }

  setBinSelectorError = (errorBins = []) => {
    let message = '';
    if (!_.isEmpty(errorBins)) {
      errorBins.forEach(bin => this.clearEnvelopeSettingAndEnvelope(bin));
      message = `${MESSAGE.FETCH_SPECTRUM_DATA_ERROR + errorBins.sort().join(', ')}, ${MESSAGE.CHANGE_BASE_GRAPH_SETTING}`;
    }
    this.setState({
      binSelectorError: message
    });
  }

  fetchBaseGraphSpectrumsForAllBins = (tag_id, params, bins) => {
    const promises = [];
    bins.forEach((bin) => {
      const newParams = { ...params };
      if (bin !== 0) newParams.bin_no = bin;
      const promise = this.fetchBaseGraphSpectrums(tag_id, newParams, false, false);
      promises.push(promise);
    });
    Promise.all(promises).then(
      (results) => {
        this.setState({ showCopySettingsLoader: false });
        const successBins = [];
        const errorBins = [];
        results.forEach((res) => {
          if (res.success) successBins.push(res.success);
          if (res.error) errorBins.push(res.error);
        });
        if (!_.isEmpty(successBins)) toastr.success(`${MESSAGE.COPIED_SETTINGS} ${successBins.sort().join(', ')}`);
        if (!_.isEmpty(errorBins)) this.setBinSelectorError(errorBins);
      }
    );
  }

  fetchBaseGraphSpectrums = (tag_id, params, bulk = false, setBaseGraphError = true) => {
    const bin_no = params.bin_no || 0;
    return new Promise((resolve) => {
      this.props.envelopeActions.getTagSpectrums(
        tag_id,
        {
          ...params,
          ...(bin_no && { bin_nos: [bin_no] })
        },
        bin_no
      ).then(
        () => {
          if (!bulk) this.setBaseGraphError('');
          resolve({ success: bin_no });
        },
        (error) => {
          const { base_graph_type } = params;
          let base_graph_error = 'Select a different measurement point or base graph option';
          let fetchError;
          if (!bulk) {
            if (this.assetType === ASSET_TYPE.FIXED_SPEED) fetchError = error.message;
            else fetchError = `${error.message} for Speed Range ${bin_no}`;
          }
          if (base_graph_type !== BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE) {
            base_graph_error = 'Select a different base graph option';
          }
          if (!bulk && setBaseGraphError) {
            if (fetchError) this.setBaseGraphError(`${fetchError}. ${base_graph_error}`);
            else this.setBaseGraphError(base_graph_error);
          }
          resolve({ error: bin_no });
        }
      );
    });
  }

  getBaseGraphDataDebounced = _.debounce((tag_id, params) => {
    this.fetchBaseGraphSpectrums(tag_id, params);
  }, 500);

  fetchDataIfRequired = (settingOf) => {
    const { envelopeSettings, activeSpeedRangeTab: bin } = this.state;
    const { measurement_type, amp_type } = this.props;
    const bin_no = `bin_${bin}`;
    if (settingOf === 'base_graph') {
      const { base_graph: { type, value } } = envelopeSettings[bin_no];
      const { tag, config } = this.props;
      const params = {
        base_graph_type: type,
        measurement_type,
        amp_type
      };
      if (value) params.base_graph_type_val = value;
      if (type === BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE) {
        params.timestamps = [config.spectrum.timestamp];
      }
      if (bin) params.bin_no = bin;
      this.getBaseGraphDataDebounced(tag.id, params);
    }
  }

  validateEnvelopeSettings = (settingOf, args) => {
    const error = {};
    const { config, currentUser: { unit_system } } = this.props;
    const { spectrumXUnit } = this.state;
    const shaft_speed = this.getShaftSpeed();
    const defaultSettingsUnits = this.assetType === ASSET_TYPE.VFD ? 'Orders' : 'Hz';
    const freq_fact = getFreqConversionFactor(defaultSettingsUnits, spectrumXUnit, shaft_speed, config.spectrum.freq_units);
    const amp_fact = getAmpUnitConversionFactor(1, unit_system);
    if (settingOf === 'threshold') {
      const { type, value, starting_frequency } = args;

      if (type === THRESHOLD_OPTIONS.PERCENT_ABOVE_BASE_GRAPH) {
        if (value === '' || value < RANGES.PERCENT_ABOVE_BASE_GRAPH[0] || value > RANGES.PERCENT_ABOVE_BASE_GRAPH[1]) {
          error.percent_above_base_graph = `Enter value between ${RANGES.PERCENT_ABOVE_BASE_GRAPH[0]} to ${RANGES.PERCENT_ABOVE_BASE_GRAPH[1]}`;
        } else {
          error.percent_above_base_graph = '';
        }
      }
      if (type === THRESHOLD_OPTIONS.STD_ABOVE_BASE_GRAPH) {
        if (value === '' || value < RANGES.STD_ABOVE_BASE_GRAPH[0] || value > RANGES.STD_ABOVE_BASE_GRAPH[1]) {
          error.std_deviation_above_base_graph = `Enter value between ${RANGES.STD_ABOVE_BASE_GRAPH[0]} to ${RANGES.STD_ABOVE_BASE_GRAPH[1]}`;
        } else {
          error.std_deviation_above_base_graph = '';
        }
      }

      if (starting_frequency === '' ||
        starting_frequency < round(RANGES[`STARTING_FREQUENCY_${this.assetType}`][0] * freq_fact, 2) ||
        starting_frequency > round(RANGES[`STARTING_FREQUENCY_${this.assetType}`][1] * freq_fact, 2)) {
        error.starting_frequency = `Enter value between ${
          round(RANGES[`STARTING_FREQUENCY_${this.assetType}`][0] * freq_fact, 2)
        } to ${
          round(RANGES[`STARTING_FREQUENCY_${this.assetType}`][1] * freq_fact, 2)
        }`;
      } else {
        error.starting_frequency = '';
      }
    } else if (settingOf === 'spectral_window') {
      const { type, width, min_caution_level } = args;

      if (type === SPECTRAL_WINDOW_TYPE_OPTIONS[0].value || type === SPECTRAL_WINDOW_TYPE_OPTIONS[1].value) {
        if (width === '' ||
          width < round(RANGES[`SPECTRAL_WINDOW_WIDTH_FIXED_${this.assetType}`][0] * freq_fact, 2) ||
          width > round(RANGES[`SPECTRAL_WINDOW_WIDTH_FIXED_${this.assetType}`][1] * freq_fact, 2)) {
          error.spectral_window_width = `Enter width between ${
            round(RANGES[`SPECTRAL_WINDOW_WIDTH_FIXED_${this.assetType}`][0] * freq_fact, 2)
          } to ${
            round(RANGES[`SPECTRAL_WINDOW_WIDTH_FIXED_${this.assetType}`][1] * freq_fact, 2)
          }`;
        } else {
          error.spectral_window_width = '';
        }
      }

      if (type === SPECTRAL_WINDOW_TYPE_OPTIONS[2].value) {
        if (width === '' || width < RANGES.SPECTRAL_WINDOW_WIDTH_CENTER_FREQ[0] || width > RANGES.SPECTRAL_WINDOW_WIDTH_CENTER_FREQ[1]) {
          error.spectral_window_width = `Enter value between ${RANGES.SPECTRAL_WINDOW_WIDTH_CENTER_FREQ[0]} to ${RANGES.SPECTRAL_WINDOW_WIDTH_CENTER_FREQ[1]}`;
        } else {
          error.spectral_window_width = '';
        }
      }

      let tagType = 'vibration';
      if (config && config.trends_data && config.trends_data[0]) {
        tagType = config.trends_data[0].tag_type;
      }

      if (min_caution_level === '' || min_caution_level < round(RANGES.MIN_CAUTION_LEVEL[tagType][0] * amp_fact) || min_caution_level > round(RANGES.MIN_CAUTION_LEVEL[tagType][1] * amp_fact)) {
        error.min_caution_level = `Enter value between ${round(RANGES.MIN_CAUTION_LEVEL[tagType][0] * amp_fact)} to ${round(RANGES.MIN_CAUTION_LEVEL[tagType][1] * amp_fact)}`;
      } else {
        error.min_caution_level = '';
      }
    }

    return error;
  }

  updateEnvelopeSettings = (settingOf, args) => {
    const error = this.validateEnvelopeSettings(settingOf, args);
    const { envelopeSettings, activeSpeedRangeTab } = this.state;
    const defaultValues = this.getDefaultParamsAndSettings();
    /*
      selected threshold option is standard deviation and user change the base graph type to currently visible reading
      then change the thresold to percent above base graph`
    */
    const bin_no = `bin_${activeSpeedRangeTab}`;
    if (
      envelopeSettings[bin_no].threshold.type === THRESHOLD_OPTIONS.STD_ABOVE_BASE_GRAPH &&
      settingOf === 'base_graph' &&
      args.type === BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE
    ) {
      error.std_deviation_above_base_graph = '';
      this.setState(prevState => ({
        envelopeSettings: {
          ...prevState.envelopeSettings,
          [bin_no]: {
            ...prevState.envelopeSettings[bin_no],
            threshold: {
              ...defaultValues.settings.threshold,
              starting_frequency: prevState.envelopeSettings[bin_no].threshold.starting_frequency
            }
          }
        }
      }));
    }
    this.setState(prevState => ({
      error: {
        ...prevState.error,
        [bin_no]: {
          ...prevState.error[bin_no],
          ...error
        }
      },
      envelopeSettings: {
        ...prevState.envelopeSettings,
        [bin_no]: {
          ...prevState.envelopeSettings[bin_no],
          [settingOf]: {
            ...args
          }
        }
      }
    }), () => this.fetchDataIfRequired(settingOf, args));
  }

  onCancel = () => {
    this.setState({
      envelopeSettings: _.cloneDeep(this.getEnvelopeSettings()),
      envelopeState: ENVELOPE_STATES.VIEW,
      spectralEnvelope: _.cloneDeep(this.getEnvelopeData()),
      error: {},
      binSelectorError: ''
    });
    if (this.assetType === ASSET_TYPE.VFD) this.props.filterTrendChart({ bin_nos: [] });
  }

  toggleMachineSettings = () => {
    this.setState(prevState => ({
      isEnabledForMachine: !prevState.isEnabledForMachine
    }));
  }

  onBinChange = (selectedBins) => {
    const { tag, measurement_type, amp_type } = this.props;
    const alarmSettings = _.cloneDeep(this.getActiveEnvelopeSetting());
    if (alarmSettings && alarmSettings.base_graph.timestamps) {
      delete alarmSettings.base_graph.timestamps;
    }
    const newEnvelopeSettings = this.formEnvelopeSettingsforSelectedBins(selectedBins, alarmSettings);
    const { base_graph: { type, value } } = alarmSettings;
    const params = {
      base_graph_type: type,
      base_graph_type_val: value || null,
      measurement_type,
      amp_type
    };
    this.fetchBaseGraphSpectrumsForAllBins(tag.id, params, selectedBins);
    this.setState(prevState => ({
      envelopeSettings: {
        ...prevState.envelopeSettings,
        ...newEnvelopeSettings
      },
      showCopySettingsLoader: true
    }));
  }

  getDefaultParamsAndSettings = () => {
    const { config, measurement_type, amp_type, currentUser } = this.props;
    if (this.assetType === ASSET_TYPE.VFD) {
      return {
        settings: {
          ..._.cloneDeep(initialEnvelopeSettings(this.state.spectrumXScale, this.assetType, currentUser.unit_system))
        },
        params: {
          base_graph_type: BASE_GRAPH_OPTIONS.AVG_OF_LAST_DAYS,
          base_graph_type_val: AVG_DAYS_OPTIONS[0].value,
          measurement_type,
          amp_type
        }
      };
    }
    return {
      settings: {
        ..._.cloneDeep(initialEnvelopeSettings(this.state.spectrumXScale, this.assetType, currentUser.unit_system))
      },
      params: {
        base_graph_type: BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE,
        timestamps: [config.spectrum.timestamp],
        measurement_type,
        amp_type
      }
    };
  }

  getActiveEnvelopeSetting = () => {
    const { envelopeSettings, activeSpeedRangeTab: bin } = this.state;
    return envelopeSettings && envelopeSettings[`bin_${bin}`] ?
      envelopeSettings[`bin_${bin}`] : null;
  }

  onSetSettingsClick = () => {
    const { tag } = this.props;
    const { activeSpeedRangeTab: bin } = this.state;
    const bin_no = `bin_${bin}`;
    const defaultValues = this.getDefaultParamsAndSettings();
    this.setState(prevState => ({
      envelopeSettings: {
        ...prevState.envelopeSettings,
        [bin_no]: defaultValues.settings
      },
      error: {
        ...prevState.error,
        [bin_no]: _.cloneDeep(initialError)
      },
      envelopeState: ENVELOPE_STATES.CREATE
    }));
    const params = { ...defaultValues.params };
    if (bin) params.bin_no = bin;
    this.fetchBaseGraphSpectrums(tag.id, params);
  }

  onTabClick = (speedRangeValue) => {
    this.props.filterTrendChart({ bin_nos: [speedRangeValue] });
    let envelopeState = ENVELOPE_STATES.CREATE;
    const { tag } = this.props;
    if (tag.envelope_settings && tag.envelope_settings[speedRangeValue]) envelopeState = ENVELOPE_STATES.EDIT;
    this.setState({
      activeSpeedRangeTab: this.binFilterOptions.find(
        option => option.value === speedRangeValue
      ).value,
      envelopeState
    });
  }

  envelopeToBeShown = () => {
    const { spectralEnvelope, activeSpeedRangeTab, envelopeState } = this.state;
    const { config: { spectrum: { speed_bin } } } = this.props;
    let bin = activeSpeedRangeTab;
    if (speed_bin && envelopeState === ENVELOPE_STATES.VIEW) {
      bin = speed_bin;
    }
    const bin_no = `bin_${bin}`;
    return spectralEnvelope && spectralEnvelope[bin_no] ? spectralEnvelope[bin_no] : {
      envelope_data: {
        start_frequencies: [],
        end_frequencies: [],
        amplitudes: []
      }
    };
  }

  clearEnvelopeAlarm = () => {
    const { measurement_type, tag } = this.props;
    const envelope_settings = _.cloneDeep(tag.envelope_settings);
    const spectral_envelope = _.cloneDeep(tag.spectral_envelope);

    const bins = Object.keys(this.state.envelopeSettings);
    const prefix = this.props.amp_type === 'velocity' ? '' : `${this.props.amp_type}_`;

    bins.forEach((bin_no) => {
      const setting_keys = ENVELOPE_SETTING_KEYS.map(key => prefix + key);
      setting_keys.forEach((key) => {
        delete envelope_settings[measurement_type][bin_no][key];
      });
      if (_.isEmpty(envelope_settings[measurement_type][bin_no])) {
        delete envelope_settings[measurement_type][bin_no];
      }

      const spectral_keys = SPECTRAL_ENVELOPE_KEYS.map(key => prefix + key);
      spectral_keys.forEach((key) => {
        delete spectral_envelope[measurement_type][bin_no][key];
      });
      if (_.isEmpty(spectral_envelope[measurement_type][bin_no])) {
        delete spectral_envelope[measurement_type][bin_no];
      }
    });

    this.updateTag({
      envelope_settings,
      spectral_envelope
    }).then(
      () => {
        this.setState({
          envelopeState: ENVELOPE_STATES.VIEW
        });
      }
    );
  }

  updateTag = (params) => {
    const { tag, breadcrumb: { site, machine } } = this.props;
    return this.props.chartsUpdateActions.updateTag(tag.id, params).then(
      (res) => {
        toastr.success('Envelope updated successfully');
        this.props.assetDetailActions.getTags(machine.id, site.id);
        return res;
      },
      (err) => {
        toastr.error(err);
        throw err;
      }
    );
  }

  onSave = () => {
    const { envelopeSettings } = this.state;
    if (_.isEmpty(envelopeSettings)) {
      this.clearEnvelopeAlarm();
      return;
    }
    const params = this.getEnvelopeParams();
    this.setState({ showSaveLoader: true });
    this.updateTag(params).then(
      () => {
        this.setEnvelopeState(ENVELOPE_STATES.VIEW);
        this.setState({ showSaveLoader: false });
      }
    );
  }

  isError = (bin_no) => {
    const { error } = this.state;
    if (_.isEmpty(error) || !error[bin_no]) return false;
    return Object.values(error[bin_no]).some(e => e);
  }

  isSaveDisabled = () => {
    const { tag } = this.props;
    const { envelopeSettings } = this.state;
    const prev_settings = _.cloneDeep(this.getEnvelopeSettings());
    const new_settings = _.cloneDeep(envelopeSettings);

    let bins = [];
    if (new_settings) bins = Object.keys(new_settings);

    for (let i = 0; i < bins.length; i++) {
      const bin_no = bins[i];

      if (this.isError(bin_no)) return true;

      if (prev_settings && prev_settings[bin_no]) {
        prev_settings[bin_no].base_graph.timestamps = _.sortBy(prev_settings[bin_no].base_graph.timestamps);
      }

      if (!new_settings[bin_no].base_graph.value) new_settings[bin_no].base_graph.value = null;

      if (tag.envelope_spectrums && tag.envelope_spectrums[bin_no]) {
        const timestamps = this.getBaseGraphTimestamps(parseInt(bin_no.split('_')[1], 10));
        new_settings[bin_no].base_graph.timestamps = _.sortBy(timestamps);
      }

      if (tag.envelope_spectrums && tag.envelope_spectrums[bin_no] && tag.envelope_spectrums[bin_no].loading) return true;
    }

    if (_.isEqual(prev_settings, new_settings)) return true;

    return false;
  }

  isContinueDisabled = () => {
    const { envelopeSettings } = this.state;
    return !envelopeSettings || _.isEmpty(envelopeSettings) ||
      _.some(Object.keys(envelopeSettings), bin_no => this.isError(bin_no));
  }

  getBaseGraphTimestamps = (bin = 0) => {
    const { tag } = this.props;
    const bin_no = `bin_${bin}`;
    return tag.envelope_spectrums[bin_no].data.items.filter(s => !s.exclude).map(s => s.timestamp);
  }

  getEnvelopeParams = () => {
    const { envelopeSettings, spectralEnvelope, spectrumXUnit } = this.state;
    const { tag, measurement_type, currentUser } = this.props;

    const bins = Object.keys(envelopeSettings);
    const xScaleSavePref = this.assetType === ASSET_TYPE.VFD ? X_SCALE.ORDERS : X_SCALE.FREQUENCY;
    let xUnitSavePref;
    if (xScaleSavePref === X_SCALE.ORDERS) {
      xUnitSavePref = 'Orders';
    } else {
      xUnitSavePref = currentUser.frequency_units;
    }
    const shaft_speed = this.getShaftSpeed();
    const envelope_settings = getEnvelopeSettingsInPrefUnits(
      envelopeSettings,
      spectrumXUnit,
      xUnitSavePref,
      shaft_speed,
      currentUser.frequency_units
    );

    bins.forEach((bin_no) => {
      const timestamps = this.getBaseGraphTimestamps(parseInt(bin_no.split('_')[1], 10));
      envelope_settings[bin_no].base_graph.timestamps = timestamps;
      if ([BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE, BASE_GRAPH_OPTIONS.BASELINE].includes(envelope_settings[bin_no].base_graph.type)) {
        envelope_settings[bin_no].base_graph.value = null;
      }
      envelope_settings[bin_no].x_scale = xScaleSavePref;
    });

    const spectral_envelope = _.cloneDeep(spectralEnvelope);
    if (xScaleSavePref === X_SCALE.ORDERS) {
      Object.keys(spectral_envelope).forEach((bin) => {
        spectral_envelope[bin] = getScaledEnvelope(
          spectral_envelope[bin],
          currentUser.frequency_units,
          xUnitSavePref,
          shaft_speed,
          currentUser.frequency_units
        );
      });
    }

    // transform data based on amp type
    const prefix = this.props.amp_type === 'velocity' ? '' : `${this.props.amp_type}_`;
    const params = {
      envelope_settings: _.cloneDeep(tag.envelope_settings),
      spectral_envelope: _.cloneDeep(tag.spectral_envelope)
    };

    bins.forEach((bin_no) => {
      const envelope = envelope_settings[bin_no];
      const envelope_keys = Object.keys(envelope);
      envelope_keys.forEach((key) => {
        if (key.startsWith(prefix)) return;
        const new_key = `${prefix}${key}`;
        envelope[new_key] = envelope[key];
        delete envelope[key];
        _.unset(params, `envelope_settings.${measurement_type}.${bin_no}.${new_key}`);
      });

      const spectral = spectral_envelope[bin_no];
      const spectral_keys = Object.keys(spectral);
      spectral_keys.forEach((key) => {
        if (key.startsWith(prefix)) return;
        const new_key = `${prefix}${key}`;
        spectral[new_key] = spectral[key];
        delete spectral[key];
        _.unset(params, `spectral_envelope.${measurement_type}.${bin_no}.${new_key}`);
      });
    });

    return {
      envelope_settings: _.merge(params.envelope_settings, {
        [measurement_type]: {
          ...envelope_settings
        }
      }),
      spectral_envelope: _.merge(params.spectral_envelope, {
        [measurement_type]: {
          ...spectral_envelope
        }
      }),
    };
  }

  closePreview = () => {
    this.setState({ showPreview: false });
  }

  formEnvelopeSettingsforSelectedBins = (selectedBins, settings) => {
    const newEnvelopeSettings = {};
    selectedBins.forEach((bin) => {
      const bin_no = `bin_${bin}`;
      newEnvelopeSettings[bin_no] = settings;
    });
    return newEnvelopeSettings;
  }

  clearEnvelopeSettingAndEnvelope = (bin) => {
    const { envelopeSettings, spectralEnvelope } = this.state;
    if (!envelopeSettings) return;
    const newEnvelopeSettings = _.cloneDeep(envelopeSettings);
    const newSpectralEnvelope = _.cloneDeep(spectralEnvelope);
    if (newEnvelopeSettings && newEnvelopeSettings[`bin_${bin}`]) {
      delete newEnvelopeSettings[`bin_${bin}`];
    }
    if (newSpectralEnvelope && newSpectralEnvelope[`bin_${bin}`]) {
      delete newSpectralEnvelope[`bin_${bin}`];
    }
    this.setState({
      envelopeSettings: newEnvelopeSettings,
      spectralEnvelope: newSpectralEnvelope
    });
  }

  getEnvelopeData = () => {
    const { measurement_type, tag: { spectral_envelope }, amp_type } = this.props;
    return spectral_envelope
      && transformFromEnvelopeKeys(spectral_envelope[measurement_type], amp_type, SPECTRAL_ENVELOPE_KEYS);
  }

  getEnvelopeSettings = () => {
    const { measurement_type, tag: { envelope_settings }, amp_type } = this.props;
    return envelope_settings
      && transformFromEnvelopeKeys(envelope_settings[measurement_type], amp_type, ENVELOPE_SETTING_KEYS);
  }

  getFooter = () => {
    const { envelopeSettings, spectralEnvelope, envelopeState, isEnabledForMachine, showSaveLoader } = this.state;
    const { measurement_type } = this.props;
    const measurementTypeText = measurementOptions.filter(op => op.value === measurement_type)[0].text;

    if (envelopeState === ENVELOPE_STATES.VIEW) {
      let create_or_edit = (
        <>
          <Button danger onClick={this.clearEnvelopeAlarm}>{`Clear Alarm for ${measurementTypeText}`}</Button>
          <Button left onClick={this.onEdit}>{`Edit Settings for ${measurementTypeText}`} </Button>
        </>
      );
      if (!spectralEnvelope || !envelopeSettings) {
        create_or_edit = <Button left onClick={this.createEnvelope}>{`Set Envelope Alarm for ${measurementTypeText}`}  </Button>;
      }
      return (
        <>
          <RBAC
            operation={operations.Update}
            resource={mapComponentToResource.Tags}
            yes={create_or_edit}
          />
          <Button cancel onClick={this.props.close}>Close</Button>
        </>
      );
    }

    if (envelopeState !== ENVELOPE_STATES.VIEW) {
      return (
        <>
          <MachineCheckBoxContainer direction="row">
            <InputField
              margin="0"
              title="Enable this setting for entire machine"
              type="checkbox"
              value={isEnabledForMachine}
              disabled={!envelopeSettings || _.isEmpty(envelopeSettings)}
              onClick={this.toggleMachineSettings}
            />
          </MachineCheckBoxContainer>
          <CancelButton cancel disabled={showSaveLoader} onClick={this.onCancel}>Cancel</CancelButton>
          {isEnabledForMachine ? (
            <Button
              disabled={this.isContinueDisabled()}
              onClick={this.onContinue}
            >
                Continue
            </Button>
          ) : (
            <Button disabled={this.isSaveDisabled()} onClick={this.onSave}>
              {showSaveLoader ? (
                <LoadingSvg fill="white" width="2.5rem" />
              ) :
                'Save'
              }
            </Button>
          )}
        </>
      );
    }

    return null;
  }

  getPreviewSpectrumXUnit = () => {
    const { currentUser } = this.props;
    if (this.assetType === ASSET_TYPE.VFD || currentUser.spectrum_x_scale === 'orders') {
      return 'Orders';
    }
    return currentUser.frequency_units;
  };


  render() {
    const {
      amp_type,
      config,
      currentUser,
      feature,
      measurement_type,
      showWidth,
      tag,
      componentNode,
      waveAndSpecSelection,
      filterTrendChart
    } = this.props;

    const {
      envelopeSettings,
      envelopeState,
      error,
      showPreview,
      activeSpeedRangeTab,
      binSelectorError,
      showCopySettingsLoader,
      spectrumXScale,
      spectrumXUnit
    } = this.state;

    return (
      <MContent>
        {config && (
          <EnvelopeWithTrend
            ampType={amp_type}
            config={config}
            envelopeSettings={envelopeSettings}
            feature={feature}
            frequencyUnits={currentUser.frequency_units}
            assetType={this.assetType}
            isError={this.isError(`bin_${activeSpeedRangeTab}`)}
            spectralEnvelope={this.envelopeToBeShown()}
            envelopeState={envelopeState}
            spectrum={config.spectrum}
            tag={tag}
            unitSystem={currentUser.unit_system}
            updateEnvelopeData={this.updateEnvelopeData}
            componentNode={componentNode}
            measurementTimestamp={waveAndSpecSelection && waveAndSpecSelection.x}
            activeTabValue={activeSpeedRangeTab}
            spectrumXScale={spectrumXScale}
            setSpectrumXScale={this.setSpectrumXScale}
            spectrumXUnit={spectrumXUnit}
            setSpectrumXUnit={this.setSpectrumXUnit}
            getShaftSpeed={this.getShaftSpeed}
          />
        )}
        {envelopeState !== ENVELOPE_STATES.VIEW && (
          <EnvelopeAlarmSettings
            envelopeSettings={envelopeSettings}
            envelopeSettingsError={error[`bin_${activeSpeedRangeTab}`] || initialError}
            binsWithError={this.getBinsWithError()}
            envelopeState={envelopeState}
            onBinChange={this.onBinChange}
            binFilterOptions={this.binFilterOptions}
            assetType={this.assetType}
            settingsShown={this.getActiveEnvelopeSetting()}
            spectralEnvelope={this.envelopeToBeShown()}
            fetchBaseGraphSpectrums={this.fetchBaseGraphSpectrums}
            measurement_type={measurement_type}
            amp_type={amp_type}
            resetBaseGraph={this.resetBaseGraph}
            tag={tag}
            config={config}
            updateEnvelopeSettings={this.updateEnvelopeSettings}
            activeTabValue={activeSpeedRangeTab}
            onTabClick={this.onTabClick}
            onSetSettingsClick={this.onSetSettingsClick}
            filterTrendChart={filterTrendChart}
            binSelectorError={binSelectorError}
            setBinSelectorError={this.setBinSelectorError}
            clearEnvelopeSettingAndEnvelope={this.clearEnvelopeSettingAndEnvelope}
            spectrumXScale={spectrumXScale}
            spectrumXUnit={spectrumXUnit}
          />
        )}
        {showPreview && (
          <EnvelopeAlarmPreview
            config={config}
            close={this.closePreview}
            envelopeSettings={envelopeSettings}
            setEnvelopeState={this.setEnvelopeState}
            getBaseGraphTimestamps={this.getBaseGraphTimestamps}
            measurement_type={measurement_type}
            selectedBins={this.getEnvelopeSettingsBins()}
            tag={tag}
            binFilterOptions={this.binFilterOptions}
            componentNode={componentNode}
            assetType={this.assetType}
            spectrumXScale={spectrumXScale}
            setSpectrumXScale={this.setSpectrumXScale}
            spectrumXUnit={this.getPreviewSpectrumXUnit()}
            settingsUnits={spectrumXUnit}
            setSpectrumXUnit={() => {}}
            getShaftSpeed={this.getShaftSpeed}
          />
        )}
        <Footer
          footer={this.getFooter}
          showWidth={showWidth}
        />
        {showCopySettingsLoader && <LoadingFullScreen msg={MESSAGE.COPYING_SETTINGS} />}
      </MContent>
    );
  }
}

const mapStateToProps = state => ({
  breadcrumb: state.breadcrumb,
  currentUser: state.user.user
});

const mapDispatchToProps = dispatch => ({
  assetDetailActions: bindActionCreators(assetDetailActions, dispatch),
  chartsUpdateActions: bindActionCreators(chartsUpdateActions, dispatch),
  envelopeActions: bindActionCreators(envelopeActions, dispatch)
});

EnvelopeAlarmModal.propTypes = {
  amp_type: PropTypes.string,
  close: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  feature: PropTypes.string,
  measurement_type: PropTypes.number,
  showWidth: PropTypes.string,
  tag: PropTypes.object.isRequired,
};

EnvelopeAlarmModal.defaultProps = {
  amp_type: 'velocity',
  feature: 'rms',
  measurement_type: 1,
  showWidth: '100%',
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EnvelopeAlarmModal));
