import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AnalystSummary = ({ stroke, height, width }) => (
<svg width={width} height={height} viewBox="0 0 18 18">
      <g id="Activity" fill="none" fillRule="evenodd">
          <g id="1-0-0-Activity" transform="translate(-105 -263)">
              <g id="activity" transform="translate(80 148)">
                  <g id="1" transform="translate(0 86)">
                      <g id="analyst_summary-icon" transform="translate(24 28)">
                          <rect id="icon-bg" width="20" height="20" />
                          <g
                            id="icon"
                            transform="translate(2 2)"
                            stroke={stroke}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          >
                              <path
                                d="M9.086,15.5 L1.5,15.5 C0.94771525,15.5 0.5,15.0522847 0.5,14.5 L0.5,1.5 C0.5,0.94771525 0.94771525,0.5 1.5,0.5 L14.5,0.5 C15.0522847,0.5 15.5,0.94771525 15.5,1.5 L15.5,9.086 C15.5,9.35103147 15.3946788,9.60520107 15.2073333,9.79266667 L9.79266667,15.2073333 C9.60520107,15.3946788 9.35103147,15.5 9.086,15.5 Z"
                                id="Path"
                              />
                              <path
                                d="M9.5,15.41 L9.5,10.5 C9.5,9.94771525 9.94771525,9.5 10.5,9.5 L15.41,9.5"
                                id="Path"
                              />
                              <path d="M4.5,4.5 L12.5,4.5" id="Path" />
                              <path d="M4.5,7.5 L8,7.5" id="Path" />
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
</svg>
);

AnalystSummary.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  stroke: PropTypes.string
};

AnalystSummary.defaultProps = {
  width: 18,
  height: 18,
  stroke: '#3C3D3A'
};

export default styled(AnalystSummary)``;
