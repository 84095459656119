import { mapComponentToResource, operations } from 'common/rbac/constants';

export const taskConstants = {
  ADD_TASK_REQUEST: 'ADD_TASK_REQUEST',
  ADD_TASK_SUCCESS: 'ADD_TASK_SUCCESS',
  ADD_TASK_FAILURE: 'ADD_TASK_FAILURE',

  UPDATE_TASK_REQUEST: 'UPDATE_TASK_REQUEST',
  UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',
  UPDATE_TASK_FAILURE: 'UPDATE_TASK_FAILURE',

  SET_TASK_MODAL_OPEN: 'SET_TASK_MODAL_OPEN',
  SET_TASK_MODAL_CLOSE: 'SET_TASK_MODAL_CLOSE',

  GET_TASK_DETAILS_REQUEST: 'GET_TASK_DETAILS_REQUEST',
  GET_TASK_DETAILS_SUCCESS: 'GET_TASK_DETAILS_SUCCESS',
  GET_TASK_DETAILS_FAILURE: 'GET_TASK_DETAILS_FAILURE',

  UPDATE_TASK_NOTE: 'UPDATE_TASK_NOTE'
};

export const repairConstants = {
  ADD_REPAIR_REQUEST: 'ADD_REPAIR_REQUEST',
  ADD_REPAIR_SUCCESS: 'ADD_REPAIR_SUCCESS',
  ADD_REPAIR_FAILURE: 'ADD_REPAIR_FAILURE',

  UPDATE_REPAIR_REQUEST: 'UPDATE_REPAIR_REQUEST',
  UPDATE_REPAIR_SUCCESS: 'UPDATE_REPAIR_SUCCESS',
  UPDATE_REPAIR_FAILURE: 'UPDATE_REPAIR_FAILURE',

  SET_REPAIR_MODAL_OPEN: 'SET_REPAIR_MODAL_OPEN',
  SET_REPAIR_MODAL_CLOSE: 'SET_REPAIR_MODAL_CLOSE',

  GET_REPAIR_DETAILS_REQUEST: 'GET_REPAIR_DETAILS_REQUEST',
  GET_REPAIR_DETAILS_SUCCESS: 'GET_REPAIR_DETAILS_SUCCESS',
  GET_REPAIR_DETAILS_FAILURE: 'GET_REPAIR_DETAILS_FAILURE',
};

export const noteConstants = {
  SET_NOTE_MODAL_OPEN: 'SET_NOTE_MODAL_OPEN',
  SET_NOTE_MODAL_CLOSE: 'SET_NOTE_MODAL_CLOSE',

  SET_NOTE_DETAILS: 'SET_NOTE_DETAILS'
};

export const templateConstants = {
  ADD_TEMPLATE_REQUEST: 'ADD_TEMPLATE_REQUEST',
  ADD_TEMPLATE_SUCCESS: 'ADD_TEMPLATE_SUCCESS',
  ADD_TEMPLATE_FAILURE: 'ADD_TEMPLATE_FAILURE',

  UPDATE_TEMPLATE_REQUEST: 'UPDATE_TEMPLATE_REQUEST',
  UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
  UPDATE_TEMPLATE_FAILURE: 'UPDATE_TEMPLATE_FAILURE',

  GET_TEMPLATE_DETAILS_REQUEST: 'GET_TEMPLATE_DETAILS_REQUEST',
  GET_TEMPLATE_DETAILS_SUCCESS: 'GET_TEMPLATE_DETAILS_SUCCESS',
  GET_TEMPLATE_DETAILS_FAILURE: 'GET_TEMPLATE_DETAILS_FAILURE',

  GET_TEMPLATE_LIST_REQUEST: 'GET_TEMPLATE_LIST_REQUEST',
  GET_TEMPLATE_LIST: 'GET_TEMPLATE_LIST',
  GET_TEMPLATE_LIST_SUCCESS: 'GET_TEMPLATE_LIST_SUCCESS',
  GET_TEMPLATE_LIST_FAILURE: 'GET_TEMPLATE_LIST_FAILURE',

  GET_TASK_TEMPLATE_METADATA_REQUEST: 'GET_TASK_TEMPLATE_METADATA_REQUEST',
  GET_TASK_TEMPLATE_METADATA_SUCCESS: 'GET_TASK_TEMPLATE_METADATA_SUCCESS',
  GET_TASK_TEMPLATE_METADATA_FAILURE: 'GET_TASK_TEMPLATE_METADATA_FAILURE',


  SAVE_TASK_TEMPLATE_DETAILS: 'SAVE_TASK_TEMPLATE_DETAILS',
  SAVE_TASK_TEMPLATE_DETAILS_REQUEST: 'SAVE_TASk_TEMPLATE_DETAILS_REQUEST',
  SAVE_TASK_TEMPLATE_DETAILS_SUCCESS: 'SAVE_TASK_TEMPLATE_DETAILS_SUCCESS',
  SAVE_TASK_TEMPLATE_DETAILS_FAILURE: 'SAVE_TAK_TEMPLATE_DETAILS_FAILURE',

  UPDATE_TASK_TEMPLATE_DETAILS: 'UPDATE_TASK_TEMPLATE_DETAILS',
  UPDATE_TASK_TEMPLATE_DETAILS_REQUEST: 'UPDATE_TASK_TEMPLATE_DETAILS_REQUEST',
  UPDATE_TASK_TEMPLATE_DETAILS_SUCCESS: 'UPDATE_TASK_TEMPLATE_DETAILS_SUCCESS',
  UPDATE_TASK_TEMPLATE_DETAILS_FAILURE: 'UPDATE_TASK_TEMPLATE_DETAILS_FAILURE',

  DELETE_TASK_TEMPLATE: 'DELETE_TASk_TEMPLATE',
  DELETE_TASK_TEMPLATE_REQUEST: 'DELETE_TASK_TEMPLATE_REQUEST',
  DELETE_TASK_TEMPLATE_SUCCESS: 'DELETE_TASK_TEMPLATE_SUCCESS',
  DELETE_TASK_TEMPLATE_FAILURE: 'DELETE_TASK_TEMPLATE_FAILURE',

  REMOVE_TASK_TEMPLATE_FROM_LIST: 'REMOVE_TASK_TEMPLATE_FROM_LIST',

  SELECT_TASK_TEMPLATE: 'SELECT_TASK_TEMPLATE',
  ADD_NEW_TASK_TEMPLATE_TO_LIST: 'ADD_NEW_TASK_TEMPLATE_TO_LIST',
  ADD_TASK_TEMPLATE_TO_LIST: 'ADD_TASK_TEMPLATE_TO_LIST',
  SET_TASK_TEMPLATE_SEARCH_PARAMS: 'SET_TASK_TEMPLATE_SEARCH_PARAMS',
  CLEAR_TASK_TEMPLATE_SEARCH_PARAMS: 'CLEAR_TASK_TEMPLATE_SEARCH_PARAMS',
  CLEAR_TASK_TEMPLATES: 'CLEAR_TASK_TEMPLATES',
  SET_TASK_TEMPLATE_PAGINATER: 'SET_TASK_TEMPLATE_PAGINATER',
  SET_TASK_TEMPLATE_SORTER: 'SET_TASK_TEMPLATE_SORTER',
};

export const MACHINE_LEVEL_ADD_OPTIONS = [
  { text: 'Task', value: 'task', resource: mapComponentToResource.Tasks, operation: operations.Create },
  { text: 'Repair Info', value: 'repair', resource: mapComponentToResource.Repairs, operation: operations.Create },
  { text: 'Note', value: 'note', resource: mapComponentToResource.Notes, operation: operations.Create },
];
