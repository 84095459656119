import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import H2_T from 'common/typography/H2/H2';
import InputField_T from 'common/components/atoms/InputField';
import OutsideAlerter from 'common/OutsideAlerter';
import Label from 'common/typography/Label/Label';
import EditIcon from 'common/images/BearingModal/EditIcon';
import Footer from 'home/HomePage/components/atoms/Footer';
import { FlexContainer } from '../atoms';

const H2 = styled(H2_T)`
  padding-top: 0;
  font-weight: 600;
`;

const EditIconContainer = styled.span`
  margin-left: 10px;
`;

const InputField = styled(InputField_T)`
  textarea {
    font-size: 14px;
    line-height: 140%;
  }
  textarea:disabled {
    background-color: ${props => props.theme.colors.greyXXXL};
    color: ${props => props.theme.colors.black};
    font-weight: 400;
  }
`;

const InputEditOnDemand = ({
  creationMode,
  inputType,
  value,
  label,
  onSave,
  onCancel,
  isSaveDisabled,
  nameError,
  titleEditMode,
  editDisabled,
  ...rest
}) => {
  const [editField, setEditField] = useState(false);

  const onSaveHandler = (enterTriggerCheck = false, e = null) => {
    if (!_.isEmpty(nameError)) return;
    if (enterTriggerCheck && e && e.key && e.key !== 'Enter') return;
    if (creationMode) return;
    if (isSaveDisabled) {
      setEditField(false);
      return;
    }
    onSave(value);
    setEditField(false);
  };

  return (
    <div>
      {inputType === 'title' && (
        <FlexContainer>
          {!creationMode && !editField && (
            <H2
              className="title"
              onClick={() => !editDisabled && titleEditMode === 'click' && setEditField(true)}
            >
              {value}
            </H2>
          )}
          {(creationMode || editField) && (
            <OutsideAlerter
              open
              event="click"
              handleClick={onSaveHandler}
              flexDirection="column"
              style={{ width: rest.width ? rest.width : '50%' }}
            >
              <InputField
                value={value}
                error={nameError}
                {...rest}
                onKeyPress={e => onSaveHandler(true, e)}
              />
            </OutsideAlerter>
          )}
          {!editField && titleEditMode === 'editicon' && !editDisabled && (
            <EditIconContainer
              onClick={() => setEditField(prevState => !prevState)}
            >
              <EditIcon />
            </EditIconContainer>
          )}
        </FlexContainer>
      )}
      {inputType === 'text' && (
        <>
          <FlexContainer>
            <Label>{label}</Label>
            {!creationMode && (
              <EditIconContainer
                onClick={() => setEditField(prevState => !prevState)}
              >
                <EditIcon />
              </EditIconContainer>
            )}
          </FlexContainer>
          <InputField
            value={value}
            disabled={!creationMode && !editField}
            marginBottom={editField ? '0' : '1.5em'}
            {...rest}
          />
          {editField && (
            <Footer
              onCancel={() => {
                setEditField(false);
                onCancel();
              }}
              onSave={onSaveHandler}
              saveDisabled={isSaveDisabled}
            />
          )}
        </>
      )}
    </div>
  );
};

InputEditOnDemand.propTypes = {
  inputType: PropTypes.oneOf(['title', 'text']).isRequired,
  creationMode: PropTypes.bool,
  onCancel: PropTypes.func,
  nameError: PropTypes.string,
  titleEditMode: PropTypes.oneOf(['click', 'editicon', null])
};

InputEditOnDemand.defaultProps = {
  creationMode: true,
  onCancel: () => {},
  nameError: '',
  titleEditMode: 'click'
};

export default InputEditOnDemand;
