import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import Modal from '../organisms/Modal';
import NavButton from '../atoms/NavButton';
import ProgressBar from '../atoms/ProgressBar';
import FlexContainer from '../atoms/FlexContainer';

const Title = styled.span`
  font-size: 20px;
  color: ${props => props.theme.colors.greyXXD};
  margin-right: auto;
`;

const ProgressBarContainer = styled(FlexContainer).attrs({
  direction: 'column',
  flexGap: '9px'
})`
  max-height: 370px;
  overflow-y: auto;
  & > * {
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 38px;
    width: calc(100% - 2px);
  }
  margin-bottom: 20px;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #E5E8E1;
  }
`;

const ContentContainer = styled.div`

`;

const FileUploadModal = ({
  rejectedFiles,
  files,
  close,
  addClick,
  doneClick,
  title,
  upload
}) => {
  const completed = files.every(file => file.uploaded);
  return (
    <Modal width="600px" padding="0 31.5px 31.5px 31.5px" outerPadding="0 31.5px 31.5px 31.5px" dialog close={close} title={() => <Title>{title}</Title>}>
      <ContentContainer>
        <ProgressBarContainer direction="column">
        {!isEmpty(files) && files.map((file, idx) => (
          file.uploaded === true ? (<ProgressBar large key={file.name} progress={100}>{file.name}</ProgressBar>) : (<ProgressBar large key={file.name} progress={upload.index === idx ? upload.progress : ''}>{file.name}</ProgressBar>)
        ))}
        {!isEmpty(rejectedFiles) ? rejectedFiles.map(file => (
          <ProgressBar rejected large key={file.name}>{file.name.concat(' upload failed')}</ProgressBar>
        )) : undefined}
        </ProgressBarContainer>
        <div style={{ display: 'flex' }}>
          <NavButton left borderButton="green" onClick={addClick}>
            ADD MORE FILES
          </NavButton>
          <NavButton
            right
            borderButton="green"
            onClick={completed ? doneClick : undefined}
            disabled={!completed}
            full={completed}
          >
            DONE
          </NavButton>
        </div>
      </ContentContainer>
    </Modal>
  );
};


FileUploadModal.propTypes = {
  title: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.object),
  rejectedFiles: PropTypes.arrayOf(PropTypes.object),
  close: PropTypes.func.isRequired,
  addClick: PropTypes.func,
  doneClick: PropTypes.func,
  upload: PropTypes.object
};

FileUploadModal.defaultProps = {
  title: 'Add Pictures',
  files: [],
  rejectedFiles: [],
  addClick: () => {},
  doneClick: () => {},
  upload: {}
};

export default FileUploadModal;
