import React from 'react';
import styled from 'styled-components';

import FlexContainer from '../../atoms/FlexContainer';
import ChartPopupContainer from '../atoms/ChartPopupContainer';
import OutsideAlerter from '../../../OutsideAlerter';
import H3 from '../../../typography/H3/H3';
import H5_T from '../../../typography/H5/H5';
import Button from '../../atoms/Button';
import * as utils from '../../../utils';

const H5 = styled(H5_T)`
    font-weight: bold;
`;

const OutlierReadingModal = ({
  close,
  markReadingAsOutlier,
  deleteOutlierReading,
  dataPoint,
  ...rest
}) => (
    <OutsideAlerter handleClick={close} open>
      <ChartPopupContainer {...rest}>
        <FlexContainer direction="column" marginbottom="24px">
          <H5>Amplitude</H5>
          <H3>{dataPoint.y} {dataPoint.yUnit}</H3>
        </FlexContainer>
        <FlexContainer direction="column" marginbottom="24px">
          <H5>Time</H5>
          <H3>{utils.formatDate(dataPoint.x)}</H3>
        </FlexContainer>
        {dataPoint.is_outlier ? (
          <Button text onClick={deleteOutlierReading}>Include measurement</Button>
        ) : (
          <Button text onClick={markReadingAsOutlier}>Exclude measurement</Button>
        )}
      </ChartPopupContainer>
    </OutsideAlerter>
);

export default OutlierReadingModal;
