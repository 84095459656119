import { axiosInstance, ENDPOINT } from '../../../common/constants';
import { handleResponse } from '../../../common/helpers';
import assetDetailsConstants from '../constants/assetHierarchy.constants';
import * as assetDetailsActions from './assetDetails.actions';
import { getSelectedPathFromBreadcrumb } from '../utils/assetHierarchyUtils';


export const putChartsConfig = (config_id, trends_config, name, defaultChart = false) => {
  const request = () => ({ type: assetDetailsConstants.PUT_HIERARCHY_CHARTS_CONFIG_REQUEST });
  const success = () => ({
    type: assetDetailsConstants.PUT_HIERARCHY_CHARTS_CONFIG_SUCCESS,
    config_id,
    trends_config,
    name
  });
  const failure = error => ({ type: assetDetailsConstants.PUT_HIERARCHY_CHARTS_CONFIG_FAILURE, error });

  return (dispatch) => {
    const data = {
      name,
      trends_config
    };
    dispatch(request());
    return handleResponse(
      axiosInstance.put(ENDPOINT.CHARTS_CONFIG(config_id), data)
    ).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const deleteChartsConfig = (config_id) => {
  const request = () => ({ type: assetDetailsConstants.DELETE_HIERARCHY_CHARTS_CONFIG_REQUEST });
  const success = () => ({ type: assetDetailsConstants.DELETE_HIERARCHY_CHARTS_CONFIG_SUCCESS, config_id });
  const failure = error => ({ type: assetDetailsConstants.DELETE_HIERARCHY_CHARTS_CONFIG_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.delete(ENDPOINT.CHARTS_CONFIG(config_id))).then(
      () => {
        dispatch(assetDetailsActions.getHierarchy(false));
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

export const updateTag = (tagId, params) => {
  const request = () => ({ type: assetDetailsConstants.UPDATE_HIERARCHY_TAG_REQUEST });
  const success = (machine_id, site_id) => ({ type: assetDetailsConstants.UPDATE_HIERARCHY_TAG_SUCCESS, params, tagId, machine_id, site_id });
  const failure = error => ({ type: assetDetailsConstants.UPDATE_HIERARCHY_TAG_FAILURE, error });
  return (dispatch, getState) => {
    const { machine_id, site_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    dispatch(request());

    return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_TAG(tagId), params))
      .then(
        () => {
          dispatch(success(machine_id, site_id));
          return tagId;
        },
        (error) => {
          dispatch(failure(error));
          throw error.message;
        },
      );
  };
};
