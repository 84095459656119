import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccessRestricted from 'common/components/AccessRestricted/AccessRestricted';
import Widget from 'home/Widget';
import RBAC from 'common/rbac/RBAC';
import { widgetActions } from 'home/Widget/actions/widget.actions';
import { operations } from 'common/rbac/constants';
import { isPetasenseAdmin, isPetasenseViewer, isPartnerAdmin } from 'common/rbac/util';
import MsgContainer from 'common/components/atoms/MsgContainer';
import Checkbox from 'common/components/atoms/Checkbox';
import SearchFilters from 'common/components/atoms/SearchFilters';
import Filter from 'common/components/molecules/Filter';
import { isFilterApplied } from 'common/utils';
import Button from 'common/components/atoms/Button';
import AccountMenuItems from 'common/components/organisms/AccountMenuItems';
import MoreOptions from 'common/components/organisms/MoreOptions';
import * as sensorActions from '../../../Sensors/actions/sensors.actions';
import * as accountActions from '../../../Accounts/actions/account.actions';
import DevicesHealthContainer from '../../components/DevicesHealthContainer';

const ChartsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  grid-template-columns: repeat(3, minmax(370px, 1fr));
`;

const FilterItem = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  text-align: left;
  width: max-content;
  min-width: 150px;
  border: none;
  outline: none;
  background-color: white;
  font-family: 'Petasense Open Sans';
  font-weight: 600;
  color: ${props => props.theme.colors.lightGray};
  ${props => props.selected && `color: ${props.theme.colors.black};`}
  &:hover {
    color: ${props => props.theme.colors.black};
    background-color: #f7f7f7;
  }
  &:first-of-type {
    padding-top: 1em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    padding-bottom: 1em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  span {
    margin-left: 0.5em;
  }
`;

const MeasurementDeviceMonitor = (props) => {
  const title = props.title;
  document.title = `${title} | Measurement and Device Monitor`;
  const initialSearchParams = {
    account_search_key: '',
    accounts: []
  };
  const [filtersOpen, setFiltersOpen] = useState({ accounts: false });
  const [accounts, setAccounts] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [accountTextValue, setAccountTextValue] = useState('');

  useEffect(() => {
    props.accountActions.getAccountsList(null, 1, false, 300);
    props.widgetActions.getWidgetsMetadata();
  }, []);

  useEffect(() => {
    const fetchAccounts = async () => {
      if (isPetasenseAdmin(props.user) || isPetasenseViewer(props.user) || isPartnerAdmin(props.user)) {
        const res = _.map(props.accounts, account => ({ text: account.name, value: account.id }));
        let accountsList = [...res];
        if (isPetasenseAdmin(props.user) || isPetasenseViewer(props.user)) {
          accountsList = [{ text: 'Unassociated', value: null }, ...res];
        }
        setAccounts(accountsList);
        setAllAccounts(accountsList);
      }
    };
    fetchAccounts();
    const newAccountTextValue = searchParams.accounts.reduce((acc, id, idx) => {
      const name = allAccounts.find(account => account.value === id);
      if (idx === 0) return `${name.text}`;
      if (idx === 1) return `${acc}, ${name.text}...`;
      return acc;
    }, '');

    setAccountTextValue(newAccountTextValue);
  }, [props.user, props.accounts]);

  const toggleFilterOpen = useCallback((filter) => {
    setFiltersOpen(prevState => ({
      ...prevState,
      [filter]: !prevState[filter]
    }));
  }, []);

  const searchAccountFilter = (value) => {
    setSearchParams(prevParams => ({
      ...prevParams,
      account_search_key: value
    }));
    setAccounts(allAccounts.filter(account =>
      account.text.toLowerCase().includes(value.toLowerCase())
    ));
  };

  const renderFilterOptions = (options, name) => {
    if (_.isEmpty(options)) {
      return (
        <MsgContainer>
          <span>No {name} available</span>
        </MsgContainer>
      );
    }

    return options.map((option, index) => {
      const selected = _.includes(searchParams[name], option.value);
      return (
        <FilterItem
          key={index.toString()}
          className="dropdown-item"
          selected={selected}
          onClick={() => {
            let updatedTypes;
            if (selected) {
              updatedTypes = _.filter(searchParams[name], t => t !== option.value);
            } else {
              updatedTypes = [...searchParams[name], option.value];
            }
            changeFilter(name, updatedTypes);
          }}
        >
          <Checkbox value={selected} />
          <span>{option.text}</span>
        </FilterItem>
      );
    });
  };

  const clearAllFilters = () => {
    setSearchParams({ ...initialSearchParams });
    setAccounts(allAccounts);
  };

  const changeFilter = (filter, value) => {
    const updatedSearchParams = { ...searchParams, [filter]: value };
    setSearchParams(updatedSearchParams);
  };

  const getChartFilters = useMemo(() => {
    const filters = [{ name: 'all', op: 'eq', value: true }];
    if (!_.isEmpty(searchParams.accounts)) {
      filters.push({ name: 'account_id', op: 'in', value: searchParams.accounts });
    }
    return filters;
  }, [searchParams]);
  return (
    <div>
      <MoreOptions>
        <AccountMenuItems />
      </MoreOptions>
      {props.adminDashboard.adminOpen && (
        <SearchFilters height="60px">
          <Filter
            title="Account:"
            value={accountTextValue}
            open={filtersOpen.accounts}
            toggleOpen={() => toggleFilterOpen('accounts')}
            onChangeSearch={searchAccountFilter}
            searchValue={searchParams.account_search_key}
            viewAllItems={allAccounts}
            selectedItems={searchParams.accounts}
            selectGivenItems={selected =>
              changeFilter('accounts', selected)
            }
            itemName="account"
          >
            {renderFilterOptions(accounts, 'accounts')}
          </Filter>
          {isFilterApplied(searchParams) && (
            <Button text onClick={clearAllFilters} fontSize="11px" margin="0 20px">
              Clear Filters
            </Button>
          )}
        </SearchFilters>
      )}
      <RBAC
        operation={operations.Read}
        yes={(
            <DevicesHealthContainer>
              <ChartsContainer>
                {!_.isEmpty(props.widgetsMetadata) && !_.isEmpty(props.widgetsMetadata.object) && (
                  <>
                    <Widget showBorder filters={getChartFilters} metadata={props.widgetsMetadata.object.device_unreachable_above_fourteen_days_count} height="220px" />
                    <Widget showBorder filters={getChartFilters} metadata={props.widgetsMetadata.object.devices_with_continuous_outliers_count} height="220px" />
                    <Widget showBorder filters={getChartFilters} metadata={props.widgetsMetadata.object.devices_with_inconsistent_vibration_levels} height="220px" />
                  </>
                )}
              </ChartsContainer>
            </DevicesHealthContainer>
        )}
        no={<AccessRestricted />}
      />
    </div>
  );
};


const mapStateToProps = state => ({
  adminDashboard: state.adminDashboard,
  measurementDeviceMonitorSummary: state.measurementDeviceMonitorSummary,
  title: state.companyReducer.partner.title,
  user: state.user.user,
  widgetsMetadata: state.widgets.metadata,
  accounts: state.accounts.accountsList.object,
});

const mapDispatchToProps = dispatch => ({
  sensorActions: bindActionCreators(sensorActions, dispatch),
  widgetActions: bindActionCreators(widgetActions, dispatch),
  accountActions: bindActionCreators(accountActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeasurementDeviceMonitor);
