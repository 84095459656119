import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import * as d3 from 'd3';

import Loading from '../../../../../common/components/atoms/Loading';
import { Chart, DonutChart } from '../../../../../common/components/Chart';
import { utilColors } from '../machineCharts.constants';
import FlexContainer from '../../../../../common/components/atoms/FlexContainer';

import AddNoteChartOverlay from './AddNoteChartOverlay';

const Options = styled.div`
  display: inline-block;
  width: 220px;
  padding-left: 1em;
`;

const TrendChartContainer = styled.div`
  display: inline-block;
  position: relative;
  ${props => (props.optionsVisible ? `
    width: calc(100% - 220px);`
    : 'width: 100%;'
  )}
`;

class UtilizationChart extends Component {
  // use new static method to derive state from props
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_.isEqual(nextProps.trend, prevState.trend)) {
      return {
        trend: nextProps.trend
      };
    }

    // do not update state otherwise
    return null;
  }

  constructor(props) {
    super(props);
    this.renderUtilizationChart = this.renderUtilizationChart.bind(this);
    this.brushCb = this.brushCb.bind(this);
    this.setDonutData = this.setDonutData.bind(this);
    this.addNote = this.addNote.bind(this);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.expanded && this.state.trend && this.state.trend.trend_data && this.state.trend.trend_data.length) {
      this.setDonutData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.expanded !== this.props.expanded) {
      if (this.state.trend && this.state.trend.trend_data && this.state.trend.trend_data.length) {
        this.setDonutData();
      }
    }
    if (!_.isEqual(prevState.trend, this.state.trend)) {
      if (this.state.trend && this.state.trend.trend_data && this.state.trend.trend_data.length) {
        this.setDonutData();
      }
    }
    if (this.props.skipOverlay) {
      this.addNote();
    }
  }

  setDonutData() {
    const { trend } = this.state;
    this.brushCb([new Date(trend.trend_data[0].x), new Date(trend.trend_data[trend.trend_data.length - 1].x)]);
  }

  brushCb(selection) {
    // TODO: d3 helper get data subset from selection :thinking:
    const bisectX = d3.bisector(d => d.x).left; // function to know x-axis array position of 'new value' from click

    const indexStart = bisectX(this.state.trend.trend_data, selection[0]);
    const indexEnd = bisectX(this.state.trend.trend_data, selection[1]);

    const selectedTrend = this.state.trend.trend_data.slice(indexStart, indexEnd + 1);
    const res = [0, 0, 0];

    for (let i = 0; i < selectedTrend.length - 1; i++) {
      const d = selectedTrend[i];
      // if d not between dates
      if (i === 0) {
        res[(d.y + 1) % 3] += d.x - selection[0];
      } else if (i === selectedTrend.length - 2) {
        res[(d.y + 1) % 3] += selection[1] - d.x;
      } else {
        const d2 = selectedTrend[i + 1];
        res[(d.y + 1) % 3] += d2.x - d.x;
      }
    }

    if (_.isEqual(res, [0, 0, 0])) {
      let previousPoint = indexStart - 1;
      previousPoint = previousPoint < 0 ? 0 : previousPoint;
      const d = this.state.trend.trend_data[previousPoint];
      res[(d.y + 1) % 3] = 1;
    }
    this.setState({
      donutData: res,
      brushSelection: selection
    });
  }

  addNote() {
    this.props.addNote({
      title: this.props.type,
      start: this.state.brushSelection ? this.state.brushSelection[0] : undefined,
      end: this.state.brushSelection ? this.state.brushSelection[1] : undefined,
      data: this.props.trend.trend_data,
      xAxisType: this.props.xAxis,
      tag_id: this.props.tagId
    });
  }

  renderUtilizationChart() {
    const {
      trend,
      tagId,
      config_id,
      expanded,
      addingNote,
      skipOverlay
    } = this.props;

    return (
      <TrendChartContainer optionsVisible={expanded}>
        {trend.loading &&
          <Loading />
        }
        {trend.trend_data && trend.trend_data.length && (
          <Chart
            type="utilization"
            chartName={`tag-${tagId}-${config_id}-trend`}
            data={[trend.trend_data, trend.trend_data, trend.trend_data]}
            range={[-1, 3]}
            contextChart={expanded}
            mouseover={this.props.mouseoverChart}
            selection={this.props.selection}
            selectedRange={this.props.selectedRange}
            colors={utilColors}
            brushCb={this.brushCb}
            ylabel="Status"
            title="Utilization"
            activeData={[true, true, true]}
            height="100px"
          />
        )}
        {addingNote && !skipOverlay &&
          <AddNoteChartOverlay onClick={this.addNote} text={this.props.type} />
        }
      </TrendChartContainer>
    );
  }

  render() {
    const {
      trend,
      expanded
    } = this.props;

    return (
      <Fragment>
        {trend && !expanded && this.renderUtilizationChart()}
        {trend && trend.trend_data && expanded && this.state.donutData && (
          <FlexContainer>
            {this.renderUtilizationChart()}
            <Options>
              <DonutChart
                data={this.state.donutData}
                colors={utilColors}
              />
            </Options>
          </FlexContainer>
        )}
      </Fragment>
    );
  }
}

UtilizationChart.propTypes = {
  trend: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  tagId: PropTypes.number.isRequired
};


export default UtilizationChart;
