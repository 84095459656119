import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import FlexContainer from '../atoms/FlexContainer';
import Container_T from '../atoms/Container';
import PlusSvg from '../../images/PlusSvg';
import MinusSvg from '../../images/MinusSvg';

const Container = styled(Container_T)`
  padding: 1em;
  border: 1px solid ${props => props.expanded ? props.theme.primaryColor : props.theme.colors.borderGray};
  border-radius: 4px;
  margin: 0.7em 0;
`;

const TitleContainer = styled(FlexContainer).attrs({ justifyContent: 'space-between' })`
  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.label`
  font-family: 'Petasense Open Sans';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled(FlexContainer).attrs({ alignItems: 'center', justifyContent: 'center' })`
  z-index: 3;
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid ${props => props.theme.colors.grey};
  border-radius: 4px;
  margin-right: 1em;
  background-color: ${props => props.theme.colors.white};
  margin-left: 1em;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonContainer = styled(FlexContainer)`
`;

const ContentContainer = styled.div`
  max-height: 0;
  /* transition: max-height 0.5s ease-out;
  -webkit-transition: max-height 0.5s ease-out; */
  visibility: hidden;
  ${props =>
    props.expanded && `
      max-height: 100000px;
      // transition: max-height 0.5s ease-out;
      // -webkit-transition: max-height 0.5s ease-out;
      visibility: visible;
      `}
`;

class ExpandableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.defaultState
    };
  }

  render() {
    const {
      title,
      children,
      count,
      theme,
      key
    } = this.props;
    const { expanded } = this.state;
    return (
    <Container key={key} expanded={expanded}>
      <TitleContainer expanded={expanded} onClick={() => this.setState(prevState => ({ expanded: !prevState.expanded }))}>
        <Title>{title}</Title>
        <ButtonContainer>
          {count &&
            <Title>{`${count} selected`}</Title>
          }
          <Icon expanded={expanded}>
            {expanded
              ? <MinusSvg width={8} height={8} fill={theme.colors.greyXD} />
              : <PlusSvg width={8} height={15} fill={theme.colors.greyXD} />
            }
          </Icon>
        </ButtonContainer>
      </TitleContainer>
      <ContentContainer expanded={expanded}>
        {children}
      </ContentContainer>
    </Container>
    );
  }
}

ExpandableContainer.propTypes = {
  title: PropTypes.string,
  defaultState: PropTypes.bool
};

ExpandableContainer.defaultProps = {
  title: '',
  defaultState: false
};

export default withTheme(ExpandableContainer);
