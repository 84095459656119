import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Button from 'common/components/atoms/Button';
import FlexContainer from 'common/components/atoms/FlexContainer';

import OverallAlarmThresholdSettings from './OverallAlarmThresholdSettings';
import OverallAlarmManualFields from './OverallAlarmManualFields';
import BaselineOption from '../EnvelopeAlarm/BaselineOption';
import BaseGraphSettings from './BaseGraphSettings';
import { OVERALL_ALARM_STATES } from '../../../constants/overallAlarm.constants';
import OverallAlarmOtherSettings from './OverallAlarmOtherSettings';
import BaselineModal from '../../../../Machines/MachineDetails/Health/components/BaselineModal';
import Error_T from '../../atoms/Error';

import * as assetDetailActions from '../../../actions/assetDetails.actions';


const Error = styled(Error_T)`
  margin-bottom: 10px;
  margin-top: -16px;
  padding: 0
`;

const TabContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  line {
    color: #EEEEED;
    background-color: #EEEEED;
    height: 1px;
  }
`;

const Tab = styled(Button).attrs({
  text: true,
  fontSize: '12px'
})`
  padding: 1.3em 2em 1em;
  ${props => props.selected ? `
    border: 1px solid;
    border-color: #EEEEED #EEEEED #FFFFFF;
    border-radius: 4px 4px 0 0;
  ` : ''}
`;

class OverallAlarmSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBaselineModal: false,
      baselineSet: props.tag.baseline_set,
      settingsDisabled: false
    };
  }

  closeBaselineModal = () => {
    const { breadcrumb: { site, machine } } = this.props;
    this.props.assetDetailActions.getTags(machine.id, site.id, false).then(
      (tags) => {
        const tag = _.find(tags, (tag => tag.id === this.props.tag.id));
        this.setState({ baselineSet: tag.baseline_set });
      }
    );
    this.setState({ showBaselineModal: false });
  }

  openBaselineModal = () => this.setState({ showBaselineModal: true })

  setSettingsDisabled = (settingsDisabled) => {
    if (settingsDisabled !== this.state.settingsDisabled) this.setState({ settingsDisabled });
  }

  render() {
    const {
      tag,
      alarmState,
      alarmMode,
      breadcrumb,
      setAlarmMode,
      settingsShown,
      base_graph_error,
      updateOverallAlarmSettings,
      overallAlarmThresholds,
      overallAlarmSettings,
      updateManualAlarmThresholds,
      binFilterOptions,
      selectedBins,
      onBinChange,
      yUnit
    } = this.props;
    const { showBaselineModal, baselineSet, settingsDisabled } = this.state;
    const { machine } = breadcrumb;

    return (
      <>
      {showBaselineModal && (
        <BaselineModal
          close={this.closeBaselineModal}
          machineId={machine.id}
        />
      )}
      {alarmState !== OVERALL_ALARM_STATES.VIEW && (
        <FlexContainer padding="0 0 0 4em" direction="column">
          <TabContainer>
            <FlexContainer>
              <Tab
                selected={alarmMode === 'automatic'}
                onClick={() => setAlarmMode('automatic')}
              >
                AUTOMATIC
              </Tab>
              <Tab
                selected={alarmMode === 'manual'}
                onClick={() => setAlarmMode('manual')}
              >
                MANUAL
              </Tab>
            </FlexContainer>
            <line />
          </TabContainer>
          {alarmMode === 'automatic' && (
            <>
              <FlexContainer margintop="20px" direction="column">
                {base_graph_error && (
                  <Error>{base_graph_error}</Error>
                )}
                {tag.type === 'vibration' && (
                  <FlexContainer direction="row">
                    <BaselineOption
                      baselineSet={baselineSet}
                      openBaselineModal={this.openBaselineModal}
                      settings={settingsShown.base_graph}
                      updateSettings={updateOverallAlarmSettings}
                    />
                  </FlexContainer>
                )}
              </FlexContainer>
              <FlexContainer direction="row">
                <BaseGraphSettings
                  disabled={settingsDisabled}
                  baseGraphSettings={settingsShown.base_graph}
                  updateOverallAlarmSettings={updateOverallAlarmSettings}
                />
                <OverallAlarmThresholdSettings
                  disabled={settingsDisabled}
                  thresholdSettings={settingsShown.threshold}
                  updateOverallAlarmSettings={updateOverallAlarmSettings}
                  yUnit={yUnit}
                />
                <OverallAlarmOtherSettings
                  disabled={settingsDisabled}
                  setSettingsDisabled={this.setSettingsDisabled}
                  selectedBins={selectedBins}
                  onBinChange={onBinChange}
                  binFilterOptions={binFilterOptions}
                  updateOverallAlarmSettings={updateOverallAlarmSettings}
                  outlierLimit={settingsShown.lower_outlier_limit}
                  yUnit={yUnit}
                  settingsShown={settingsShown}
                  overallAlarmSettings={overallAlarmSettings}
                />
              </FlexContainer>
            </>
          )}
          {alarmMode === 'manual' && (
            <OverallAlarmManualFields
              overallAlarmThresholds={overallAlarmThresholds}
              updateManualAlarmThresholds={updateManualAlarmThresholds}
              binFilterOptions={binFilterOptions}
              yUnit={yUnit}
            />
          )}
        </FlexContainer>
      )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  breadcrumb: state.breadcrumb,
  currentUser: state.user.user
});

const mapDispatchToProps = dispatch => ({
  assetDetailActions: bindActionCreators(assetDetailActions, dispatch),
});

OverallAlarmSettings.propTypes = {
  alarmMode: PropTypes.string,
  alarmState: PropTypes.string,
  base_graph_error: PropTypes.string,
  selectedBins: PropTypes.array,
  overallAlarmThresholds: PropTypes.array,
  settingsShown: PropTypes.object,
  updateManualAlarmThresholds: PropTypes.func,
  updateOverallAlarmSettings: PropTypes.func,
  setAlarmMode: PropTypes.func,
  onBinChange: PropTypes.func,
  binFilterOptions: PropTypes.array,
  tag: PropTypes.object.isRequired,
  yUnit: PropTypes.string
};

OverallAlarmSettings.defaultProps = {
  updateOverallAlarmSettings: () => {},
  updateManualAlarmThresholds: () => {},
  setAlarmMode: () => {},
  onBinChange: () => {},
  alarmMode: 'automatic',
  alarmState: 'view',
  base_graph_error: '',
  selectedBins: [],
  overallAlarmThresholds: [],
  settingsShown: {},
  binFilterOptions: [],
  yUnit: 'mm/s'
};

export default connect(mapStateToProps, mapDispatchToProps)(OverallAlarmSettings);
