import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AnalyzeView from 'home/Activity/components/AnalyzeView';
import { HomePage } from '../../home';

class ProtectedLayout extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/machines/:machine_id/events-analysis" component={AnalyzeView} />
        <Route path="/" component={HomePage} />
      </Switch>
    );
  }
}

export default ProtectedLayout;
