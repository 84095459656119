import * as _ from 'lodash';
import { toastr } from 'react-redux-toastr';

import { getActivePartnerBasedOnDomain } from 'home/DomainName/DomainConstants';
import { userConstants } from './user.constants';
import { axiosInstance, ENDPOINT } from '../common/constants';

import * as userService from './user.service';
import { history, handleResponse } from '../common/helpers';
import { alertActions } from '../alert';


export const userActions = {
  login,
  forgotPassword,
  resetPassword,
  logout,
  register,
  getTokenDetails,
  associateUserToAccount,
  getTimezones,
  getRBACPermissions,
  selectAccount,
  getUser,
  verifyOtp,
  resendOtp,
  resetOtpState,
  clearStore,
};

// const clearUserStatus = () => ({ type: userConstants.CLEAR_USER_STATUS });

function clearStore() {
  return (dispatch) => {
    dispatch({ type: userActions.LOGOUT });
  };
}

function login(loginData, cookies, destinationPath, destinationSearchParams = '') {
  return (dispatch) => {
    dispatch(request(loginData));
    dispatch(alertActions.clear());
    return userService.login(loginData)
      .then(
        (res) => {
          // dispatch(clearUserStatus());
          dispatch(getRBACPermissions());
          dispatch(getCurrentAccount());
          if (res.show_confirmation) {
            dispatch({ type: userConstants.RESET_OTP_STATE });
            cookies.set('otp_try_count', res.otp_try_count);
            history.push('/two_factor_auth', { flow_type: res.flow_type, phone: res.phone_last_3_digits, destinationPath, destinationSearchParams });
          } else {
            const accountIdExists = res.accounts.find(acc => acc.id === res.account_id);
            if (!accountIdExists) {
              dispatch(selectAccount(res.accounts[0].id));
              res.account_id = res.accounts[0].id;
            }
            dispatch(success(res));
            if (destinationPath) history.push(`${destinationPath}${destinationSearchParams}`);
            else history.push('/');
          }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
          // toastr.error(error.data.message);
        },
      );
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }
}

function logout() {
  return (dispatch) => {
    userService.logout()
      .then(
        () => {
          dispatch({ type: userConstants.LOGOUT });
          // dispatch(clearUserStatus());
          history.push('/login');
        },
        (error) => {
          dispatch(alertActions.error(error.message));
          // toastr.error(error.data.message);
        }
      );
  };
}

function register(user, cookies) {
  return (dispatch) => {
    dispatch(request(user));
    dispatch(alertActions.clear());
    const params = _.cloneDeep(user);
    userService.register(params)
      .then(
        (user) => {
          dispatch(success());
          if (user.show_confirmation) {
            dispatch({ type: userConstants.RESET_OTP_STATE });
            cookies.set('otp_try_count', user.otp_try_count);
            history.push('/two_factor_auth', { flow_type: user.flow_type, phone: user.phone_last_3_digits });
          } else {
            history.push('/');
          }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        },
      );
  };

  function request(user) { return { type: userConstants.REGISTER_REQUEST, user }; }
  function success(user) { return { type: userConstants.REGISTER_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.REGISTER_FAILURE, error }; }
}

export const verifyPhoneOrEmailOtp = (otp, flow_type, value) => {
  const success = result => ({ type: userConstants.UPDATE_USER_SUCCESS, result });
  return dispatch =>
    handleResponse(axiosInstance.post(ENDPOINT.VERIFY_USER_UPDATE_OTP, {
      otp,
      flow_type
    })).then(
      (res) => {
        if (value) dispatch(success({ ...value }));
        return res;
      },
      (error) => {
        throw error.message;
      }
    );
};

function verifyOtp(otp, trustDevice, flow_type, destinationPath, cookies, destinationSearchParams = '') {
  return (dispatch) => {
    dispatch(request());
    return userService.verifyOtp(otp, flow_type, trustDevice)
      .then(
        (res) => {
          dispatch(success(trustDevice));
          dispatch({ type: userConstants.GET_USER_SUCCESS, res });
          if (destinationPath) history.push(`${destinationPath}${destinationSearchParams}`);
          else history.push('/');
          return res;
        },
        (error) => {
          cookies.set('otp_try_count', error.pending_try_count);
          dispatch(failure());
          throw error;
          // dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.VERIFY_OTP_REQUEST }; }
  function success() { return { type: userConstants.VERIFY_OTP_SUCCESS, trustDevice }; }
  function failure() { return { type: userConstants.VERIFY_OTP_FAILURE }; }
}

function resendOtp(cookies, flow_type) {
  return (dispatch) => {
    dispatch(request());
    return userService.resendOtp(flow_type)
      .then(
        (res) => {
          // backend needs to be changes to support res.otp_try_count
          cookies.set('otp_try_count', res.otp_try_count);
          dispatch(success());
          return res;
        },
        (error) => {
          dispatch(failure());
          dispatch(alertActions.error(error.message));
          throw error;
        }
      );
  };

  function request() { return { type: userConstants.RESEND_OTP_REQUEST }; }
  function success() { return { type: userConstants.RESEND_OTP_SUCCESS }; }
  function failure() { return { type: userConstants.RESEND_OTP_FAILURE }; }
}

function resetOtpState() {
  return (dispatch) => {
    dispatch({ type: userConstants.RESET_OTP_STATE });
  };
}

export function getUser() {
  return (dispatch) => {
    dispatch(request());

    return userService.getUser()
      .then(
        (data) => {
          const accountIdExists = data.accounts.find(acc => acc.id === data.account_id);
          if (!accountIdExists) {
            dispatch(selectAccount(data.accounts[0].id));
            data.account_id = data.accounts[0].id;
          }
          dispatch(success(data));
          return data;
        },
        (error) => {
          dispatch(failure(error));
        },
      );
  };

  function request() { return { type: userConstants.GET_USER_REQUEST }; }
  function success(user) { return { type: userConstants.GET_USER_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.GET_USER_FAILURE, error }; }
}

export function selectAccount(id, redirect = true) {
  return (dispatch) => {
    dispatch(request(id));
    return handleResponse(axiosInstance.post(ENDPOINT.SWITCH_ACCOUNT, JSON.stringify({ account_id: id })))
      .then(
        (user) => {
          dispatch(getRBACPermissions());
          dispatch(getCurrentAccount());
          dispatch(getUser());
          if (redirect) history.push('/');
          dispatch(success(user));
          return user;
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };
  function request(id) { return { type: userConstants.SELECT_ACCOUNT_REQUEST, id }; }
  function success(user) { return { type: userConstants.SELECT_ACCOUNT_SUCCESS, user }; }
  function failure() { return { type: userConstants.SELECT_ACCOUNT_FAILURE }; }
}

function resetPassword(data) {
  return (dispatch) => {
    dispatch(request());
    userService.resetPassword(data)
      .then(
        () => {
          dispatch(success());
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };
  function request() { return { type: userConstants.RESET_PASSWORD_REQUEST }; }
  function success() { return { type: userConstants.RESET_PASSWORD_SUCCESS }; }
  function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILED, error }; }
}

function getTokenDetails(token) {
  return (dispatch) => {
    dispatch(request());

    userService.getTokenDetails(token)
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
          // toastr.error(error.data.message);
        },
      );
  };

  function request() { return { type: userConstants.TOKEN_DETAILS_REQUEST }; }
  function success(details) { return { type: userConstants.TOKEN_DETAILS_SUCCESS, details }; }
  function failure(error) { return { type: userConstants.TOKEN_DETAILS_FAILURE, error }; }
}

function associateUserToAccount(token) {
  return () =>
    userService.getAssociateTokenDetails(token)
      .then(
        data => data,
        (error) => {
          throw error.message;
        },
      );
}

export function getTimezones(token) {
  return (dispatch) => {
    dispatch(request());
    return userService.getTimezones(token)
      .then(
        (data) => {
          dispatch(success(data.items));
          return data.items;
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
          throw error.message;
          // toastr.error(error.data.message);
        },
      );
  };

  function request() { return { type: userConstants.TIMEZONE_REQUEST }; }
  function success(timezones) { return { type: userConstants.TIMEZONE_SUCCESS, timezones }; }
  function failure(error) { return { type: userConstants.TIMEZONE_FAILURE, error }; }
}

function forgotPassword(username) {
  return (dispatch) => {
    dispatch(request());
    dispatch(alertActions.clear());

    userService.forgotPassword(username)
      .then(
        () => {
          dispatch(success());
        },
        (error) => {
          dispatch(failure());
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() { return { type: userConstants.FORGOT_PASSWORD_REQUEST }; }
  function success() { return { type: userConstants.FORGOT_PASSWORD_SUCCESS }; }
  function failure() { return { type: userConstants.FORGOT_PASSWORD_FAILED }; }
}

export const saasAgreementAction = data =>
  dispatch =>
    userService.saasAgreementAction(data)
      .then(
        (result) => {
          dispatch({ type: userConstants.GET_CURRENT_ACCOUNT_SUCCESS, result });
        },
        (error) => {
          dispatch(alertActions.error(error.message));
        }
      );

export const changePassword = data =>
  dispatch =>
    userService.changePassword(data)
      .then(
        () => {
        },
        (error) => {
          // toastr.error(error);
          dispatch(alertActions.error(error.message));
          throw error.message;
        }
      );


export const clearChartsTags = () => ({ type: 'CLEAR_CHARTS_TAGS' });

export const updateUser = (data, value) => {
  const request = () => ({ type: userConstants.UPDATE_USER_REQUEST });
  const success = result => ({ type: userConstants.UPDATE_USER_SUCCESS, result });
  const failure = error => ({ type: userConstants.UPDATE_USER_FAILURE, error });
  return (dispatch, getState) => {
    dispatch(request());
    return userService.updateUser(data)
      .then(
        (result) => {
          if (data.trusted_devices) {
            const user = getState().user.user;
            user.trusted_devices.devices.splice(value, 1);
            user.trusted_devices.last_active.splice(value, 1);
            user.trusted_devices.user_agent.splice(value, 1);
            dispatch(success(user));
          } else if (value) dispatch(success({ ...value }));
          else if (data.phone) dispatch(success({}));
          else dispatch(success(data));
          return result;
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
          throw error.message;
        }
      );
  };
};

export const updateAccount = data =>
  dispatch =>
    userService.updateAccount(data)
      .then(
        () => {},
        (error) => { dispatch(alertActions.error(error.message)); }
      );

export const updateAccountLogo = file =>
  (dispatch) => {
    const data = new FormData();
    data.append('logo', file, file.name);
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };
    return userService.updateAccountLogo(data, config)
      .then(
        () => { dispatch(getCurrentAccount()); },
        (error) => { dispatch(alertActions.error(error.message)); }
      );
  };

export const deleteAccountLogo = () =>
  dispatch => (
    userService.deleteAccountLogo()
      .then(
        () => { dispatch(getCurrentAccount()); },
        (error) => { dispatch(alertActions.error(error.message)); }
      )
  );

export const updatePreferences = data =>
  dispatch =>
    userService.updatePreferences(data)
      .then(
        (res) => {
          if (res.task_id) {
            dispatch(getTaskStatus(res.task_id));
          }
          return res;
        },
        (error) => {
          dispatch(alertActions.error(error.message));
          throw error;
        }
      );

export const getCurrentAccount = () => {
  const request = () => ({ type: userConstants.GET_CURRENT_ACCOUNT_REQUEST });
  const success = result => ({ type: userConstants.GET_CURRENT_ACCOUNT_SUCCESS, result });
  const failure = error => ({ type: userConstants.GET_CURRENT_ACCOUNT_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    return userService.getCurrentAccount()
      .then(
        (result) => {
          dispatch(success(result));
          return result;
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };
};

export function getRBACPermissions() {
  const request = () => ({ type: userConstants.GET_RBAC_PERMISSIONS_REQUEST });
  const success = result => ({ type: userConstants.GET_RBAC_PERMISSIONS_SUCCESS, result });
  const failure = error => ({ type: userConstants.GET_RBAC_PERMISSIONS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_RBAC_PERMISSIONS)).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}


export const getTaskStatus = (taskId) => {
  const success = result => ({ type: userConstants.GET_TASK_STATUS, ...result, taskId });
  const done = result => ({ type: userConstants.GET_TASK_DONE, ...result, taskId });
  return dispatch => handleResponse(axiosInstance.get(
    ENDPOINT.GET_TASK_STATUS(taskId)
  )).then(
    (res) => {
      if (res.state === 'FAILURE') {
        dispatch(done(res));
        toastr.error(res.status);
      } else if (res.state !== 'SUCCESS') {
        dispatch(success(res));
        setTimeout(() => dispatch(getTaskStatus(taskId)), 1500);
      } else {
        dispatch(done(res));
        window.location.reload();
      }
    }
  );
};
