import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'common/components/atoms/FlexContainer';
import H2_T from 'common/typography/H2/H2';
import InputField_T from 'common/components/atoms/InputField';

const InputField = styled(InputField_T)`
  width: 200px;
  margin-right: 20px;
`;

const H2 = styled(H2_T)`
  font-size: 20px;
  padding-bottom: 4px;
  width: 200px
`;

const OverallAlarmManualFields = ({
  overallAlarmThresholds,
  updateManualAlarmThresholds,
  binFilterOptions,
  yUnit
}) => (
  <FlexContainer direction="column" padding="20px 0 40px 0">
    {binFilterOptions.map(bin => (
      <FlexContainer alignItems="flex-end" padding="0 0 5px 20px">
        {binFilterOptions.length > 1 && (
          <H2>{bin.text}</H2>
        )}
        <InputField
          minWidth="150px"
          type="number"
          label="Warning Lvl - Above"
          value={overallAlarmThresholds[bin.value] ? overallAlarmThresholds[bin.value].warning : null}
          marginBottom="0"
          prefix={yUnit}
          onChange={(e) => {
            const thresholds = {
              ...overallAlarmThresholds[bin.value],
              warning: parseFloat(e.target.value) || null
            };
            updateManualAlarmThresholds(
              bin.value,
              thresholds,
            );
          }}
        />
        <InputField
          minWidth="150px"
          type="number"
          label="Critical Lvl - Above"
          value={overallAlarmThresholds[bin.value] && overallAlarmThresholds[bin.value].critical ? overallAlarmThresholds[bin.value].critical : null}
          marginBottom="0"
          prefix={yUnit}
          onChange={(e) => {
            const thresholds = {
              ...overallAlarmThresholds[bin.value],
              critical: parseFloat(e.target.value) || null
            };
            updateManualAlarmThresholds(
              bin.value,
              thresholds,
            );
          }}
        />
      </FlexContainer>
    ))}
  </FlexContainer>
);

OverallAlarmManualFields.propTypes = {
  overallAlarmThresholds: PropTypes.array.isRequired,
  updateManualAlarmThresholds: PropTypes.func.isRequired,
  binFilterOptions: PropTypes.array.isRequired,
  yUnit: PropTypes.string.isRequired
};

export default OverallAlarmManualFields;
