import React from 'react';
import PropTypes from 'prop-types';

const LockedZoomSvg = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g id="Machine/Charts" fill="none" fillRule="evenodd">
          <g id="1-6-0-Machine-Charts-Expanded" transform="translate(-144 -268)">
              <g id="charts" transform="translate(80 212)">
                  <g id="1">
                      <g id="trend" transform="translate(0 16)">
                          <g id="graph" transform="translate(20 40)">
                              <g id="y-axis" transform="translate(38)">
                                  <g id="locked_zoom-icon" transform="translate(6)">
                                      <rect id="btn-bg" fill={fill} width="16" height="16" />
                                      <g id="icon" transform="translate(4 3)" stroke="#AFAFAE" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                                          <path
                                            d="M1.83333333,4.0625 L6.16666667,4.0625 C6.99509379,4.0625 7.66666667,4.73407288 7.66666667,5.5625 L7.66666667,8.1875 C7.66666667,9.01592712 6.99509379,9.6875 6.16666667,9.6875 L1.83333333,9.6875 C1.00490621,9.6875 0.333333333,9.01592712 0.333333333,8.1875 L0.333333333,5.5625 C0.333333333,4.73407288 1.00490621,4.0625 1.83333333,4.0625 Z"
                                            id="Rectangle-path"
                                          />
                                          <path
                                            d="M1.66666667,4.0625 L1.66666667,2.5 C1.66666667,1.29187711 2.71133558,0.3125 4,0.3125 C5.28866442,0.3125 6.33333333,1.29187711 6.33333333,2.5 L6.33333333,4.0625"
                                            id="Shape"
                                          />
                                          <path
                                            d="M4.02083333,6.56250011 C3.91727994,6.56250011 3.83333333,6.64644672 3.83333333,6.75000011 C3.83333333,6.8535535 3.91727994,6.93750011 4.02083333,6.93750011 C4.12438672,6.93750011 4.20833333,6.8535535 4.20833333,6.75000011 C4.20846658,6.70023129 4.18875497,6.65246247 4.15356297,6.61727047 C4.11837097,6.58207848 4.07060216,6.56236686 4.02083333,6.56250011"
                                            id="Shape"
                                          />
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);

LockedZoomSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

LockedZoomSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: 'none'
};

export default LockedZoomSvg;
