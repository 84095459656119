import styled from 'styled-components';


const MaybeContainer = styled.div`
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.width === '100%' && props.footer && `
    height: calc(100vh - 112px);
    overflow: auto;
  `}
  ${props => props.noOverflow && 'overflow: hidden;'}
`;

MaybeContainer.displayName = 'MaybeContainer';

export default styled(MaybeContainer)``;
