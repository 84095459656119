import FileSaver from 'file-saver';

export function download(data, fileName) {
  const fileToSave = new Blob([JSON.stringify(data)], {
    type: 'application/json',
    name: fileName
  });

  FileSaver.saveAs(fileToSave, fileName);
}

export function downloadAsCSV(data, mapPropertiesToHeader, fileName) {
  const columnNames = Object.values(mapPropertiesToHeader);
  const lineArray = [];
  const keys = Object.keys(mapPropertiesToHeader);
  data.forEach((obj, index) => {
    const vals = [];
    keys.forEach((k) => { vals.push(obj[k]); });
    const line = vals.join(',');
    if (index === 0) {
      lineArray.push(columnNames.join(','));
    }
    lineArray.push(line);
  });
  const result = lineArray.join('\n');
  const fileToSave = new Blob([result], {
    type: 'text/csv',
    name: fileName
  });

  FileSaver.saveAs(fileToSave, fileName);
}
