import _ from 'lodash';
import AccountTypes from '../actions/accountUsers.types';

const intialState = {
  loading: false,
  accountId: undefined,
  users: [],
  currentUser: {},
  error: undefined
};

export default (state = intialState, action) => {
  switch (action.type) {
    case AccountTypes.GET_ACCOUNT_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case AccountTypes.GET_ACCOUNT_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        accountId: action.accountId,
        users: [...action.userData.result]
      };
    case AccountTypes.GET_ACCOUNT_USERS_FAILURE:
      return {
        error: action.error.message
      };
    case AccountTypes.DELETE_USER_SUCCESS: {
      return {
        ...state,
        users: _.filter(state.users, u => u.id !== action.user_id)
      };
    }
    case AccountTypes.SET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          first_name: action.user.first_name,
          last_name: action.user.last_name,
          roles: action.user.roles,
        }
      };
    case AccountTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        currentUser: {
          ...action.data
        },
        users: state.users.map(user => action.user_id === user.id ? { ...user, ...action.data } : user)
      };
    }

    default:
      return state;
  }
};
