export const OVERALL_ALARM_ACTIONS = {
  GET_TAG_TREND_FAILURE: 'GET_TAG_TREND_FAILURE',
  GET_TAG_TREND_REQUEST: 'GET_TAG_TREND_REQUEST,',
  GET_TAG_TREND_SUCCESS: 'GET_TAG_TREND_SUCCESS',
};

export const BASE_GRAPH_OPTIONS = {
  LAST_DAYS: 'last_days',
  LAST_READINGS: 'last_measurements',
  BASELINE: 'from_baseline'
};

export const ALGO_OPTIONS = [
  { text: 'Multiplier', value: 'multiplier' },
  { text: 'Standard Deviation', value: 'standard_deviation' },
  { text: 'OffSet', value: 'offset' },
];

export const DAYS_OPTIONS = [
  { text: '7 days', value: 7 },
  { text: '15 days', value: 15 },
  { text: '30 days', value: 30 }
];

export const READINGS_OPTIONS = [
  { text: '10 readings', value: 10 },
  { text: '20 readings', value: 20 },
  { text: '30 readings', value: 30 }
];

export const OVERALL_ALARM_STATES = {
  CREATE: 'create',
  EDIT: 'edit',
  RESET: 'reset',
  VIEW: 'view'
};

export const commonDefaultOverallAlarmSettings = {
  base_graph: {
    type: BASE_GRAPH_OPTIONS.LAST_DAYS,
    value: 30
  },
  threshold: {
    type: 'multiplier',
    warning_factor: 1.5,
    critical_factor: 2.5
  },
  lower_outlier_limit: null
};

export const ALARMS_ROUND_TO_NUMBER = 3;
