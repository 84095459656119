export const EXCLUDE_FROM_MACHINE_PROPERTIES = {
  rotating: [
    'sub_type'
  ],
  non_rotating: [
    'foundation',
    'orientation',
    'state_detection_enabled',
    'bluetooth_p2p_enabled',
    'alarms_type',
    'alarms_limits',
    'warning_limit',
    'critical_limit',
    'lower_bandpass_cutoff',
    'upper_bandpass_cutoff',
    'forcing_frequencies',
    'state_detection_threshold_in_gauss'
  ]
};

export const EXCLUDE_FROM_TEMPLATE_PROPERTIES = {
  rotating: [],
  non_rotating: [
    'foundation',
    'orientation',
    'state_detection_enabled',
    'bluetooth_p2p_enabled',
    'alarms_type',
    'alarms_limits',
    'warning_limit',
    'critical_limit',
    'lower_bandpass_cutoff',
    'upper_bandpass_cutoff',
    'forcing_frequencies'
  ]
};

export const COMPONENT_TYPE_UPDATE = {
  confirmation: 'Are you sure you want to change the component type?',
  select_req: 'Please select the new Component Type'
};

export const MEASUREMENT_INTERVAL = {
  overall: 10800,
  full_bandwidth: 43200,
  high_resolution: 43200
};

export const MEASUREMENT_TYPE = {
  overall: '0',
  full_bandwidth: '1',
  high_resolution: '2'
};

export const AGGRESSIVE_SETTINGS = {
  num_aggressive_readings: 3,
  aggressive_reading_interval: 300
};

export const NUM_AGGRESSIVE_READINGS = [
  { text: '1 times', value: 1 },
  { text: '2 times', value: 2 },
  { text: '3 times', value: 3 },
  { text: '4 times', value: 4 },
  { text: '5 times', value: 5 },
  { text: '6 times', value: 6 }
];

export const AGGRESSIVE_INTERVALS = [
  { text: 'Every 3 minutes', value: 180 },
  { text: 'Every 4 minutes', value: 240 },
  { text: 'Every 5 minutes', value: 300 },
  { text: 'Every 10 minutes', value: 600 },
  { text: 'Every 15 minutes', value: 900 },
  { text: 'Every 30 minutes', value: 1800 },
  { text: 'Every 1 hour', value: 3600 }
];

export const ADDITIONAL_AGGRESSIVE_MEASUREMENT_INTERVALS = [
  { text: 'Once in two days', value: 172800 },
  { text: 'Once in three days', value: 259200 },
  { text: 'Once in four days', value: 345600 },
];
