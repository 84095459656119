import React from 'react';
import PropTypes from 'prop-types';

const MoteUnreachableNew = ({ width, height, fill }) => (
<svg width={width} height={height} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g id="Web---Single" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="1.0.0-Single-Mote_unreachable" transform="translate(-455.000000, -582.000000)">
      <g transform="translate(413.000000, 199.000000)" id="event">
        <g transform="translate(40.000000, 381.000000)">
          <g id="mote_unreachable-icon">
            <rect id="icon-bg" x={0} y={0} width={20} height={20} />
            <g id="icon" transform="translate(2.000000, 2.000000)" fillRule="nonzero">
              <g id="sensor" transform="translate(3.000000, 1.000000)" stroke={fill} strokeWidth="1.3">
                <path d="M2.22575758,11.0036709 L9.41060606,11.0036709 L9.41060606,1.98700855 C9.41060606,1.55070359 9.05691101,1.19700855 8.62060606,1.19700855 L3.01575758,1.19700855 C2.57945262,1.19700855 2.22575758,1.55070359 2.22575758,1.98700855 L2.22575758,11.0036709 Z" id="Rectangle-2" />
                <rect id="Rectangle" x="1.37727273" y="10.9747863" width="8.88181818" height="3.14444444" rx="0.54" />
              </g>
              <g id="vertical_line">
                <path d="M14.2268138,0.701113164 L14.6537351,1.13038682 C14.9281068,1.40627032 14.9281068,1.85356584 14.6537351,2.12944933 L2.33966674,14.511369 C2.06529505,14.7872525 1.62045066,14.7872525 1.34607897,14.511369 L0.919157672,14.0820953 C0.644785984,13.8062118 0.644785984,13.3589163 0.919157672,13.0830328 L13.233226,0.701113164 C13.5075977,0.425229671 13.9524421,0.425229671 14.2268138,0.701113164 Z" id="Rectangle-5" fill={fill} />
                <path d="M14.9392408,1.65517013 C15.2082327,1.92564414 15.2082327,2.36416916 14.9392408,2.63464317 L2.82906306,14.8115488 C2.56007121,15.0820228 2.12394925,15.0820228 1.8549574,14.8115488 C1.58596555,14.5410748 1.58596555,14.1025498 1.8549574,13.8320757 L13.9651352,1.65517013 C14.234127,1.38469611 14.670249,1.38469611 14.9392408,1.65517013 Z" id="Rectangle-5" fill={fill} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

);

MoteUnreachableNew.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

MoteUnreachableNew.defaultProps = {
  width: 16,
  height: 16,
  fill: '#3C3D3A'
};


export default MoteUnreachableNew;
