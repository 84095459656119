import * as _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { machineAddActionConstants } from 'home/Machines/MachineAdd/actions/machineAdd.constants';
import { handleResponse } from 'common/helpers';
import {
  axiosInstance,
  ENDPOINT,
} from 'common/constants';

const chooseAddMethod = method => (dispatch) => {
  dispatch({ type: machineAddActionConstants.CHOOSE_ADD_METHOD, method });
};

const chooseMcType = (mcType, cb = () => {}) => (dispatch) => {
  dispatch({ type: machineAddActionConstants.CHOOSE_MC_TYPE, mcType });
  cb();
};

const chooseNonRotatingSubtype = subtype => (dispatch) => {
  dispatch({ type: machineAddActionConstants.CHOOSE_NON_ROTATING_SUBTYPE, subtype });
};

const setStage = stage => (dispatch) => {
  dispatch({ type: machineAddActionConstants.SET_STAGE, stage });
};

const reset = () => (dispatch) => {
  dispatch({ type: machineAddActionConstants.RESET });
};

const bulkCreateAssets = (templates, cb) => dispatch => handleResponse(axiosInstance.post(ENDPOINT.CREATE_ASSETS_FROM_TEMPLATES, { templates })).then(
  (res) => {
    cb(res);
    toastr.success(res.message);
    const machine_ids = res.machine_ids;
    dispatch({ type: machineAddActionConstants.BULK_CREATE_ASSETS, machine_ids });
  },
  (err) => {
    toastr.error(err.message);
    cb(false, err);
  }
);

const machineAddActions = {
  chooseAddMethod,
  chooseMcType,
  chooseNonRotatingSubtype,
  setStage,
  reset,
  bulkCreateAssets
};

export default machineAddActions;
