import React from 'react';
import PropTypes from 'prop-types';

const RadiobuttonSvg = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 16 16">
    <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="PSWAv.1.0.0-Machines_v1.8.10" transform="translate(-244.000000, -212.000000)">
        <g id="bearings">
          <g id="card" transform="translate(204.000000, 24.000000)">
              <g id="list" transform="translate(24.000000, 128.000000)">
                <g id="row1" transform="translate(0.000000, 46.000000)">
                  <g id="1.0">
                    <g id="check_box/selected" transform="translate(16.000000, 14.000000)">
                      <rect id="Check_box" stroke={fill} x="0.5" y="0.5" width="15" height="15" rx="7.5" />
                      <rect id="Check_box" fill={fill} x="3" y="3" width="10" height="10" rx="5" />
                    </g>
                  </g>
                </g>
              </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

RadiobuttonSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

RadiobuttonSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: '#000000'
};


export default RadiobuttonSvg;
