import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputField_T from 'common/components/atoms/InputField';
import { Grid, Segment } from 'semantic-ui-react';

const InputField = styled(InputField_T)`
  font-size: 12px;
  ${props => props.paddingtop && `padding-top: ${props.paddingtop};`}
  width: 100%;
  min-width:160px;
  label {
    font-weight: 300;
  }
  padding-bottom: 0px;
  margin-bottom: 0px;
  input{
    ${props => props.fontSettings ? props.fontSettings : props.theme.fonts.lightLabel}}
    .ui.fluid.dropdown {
      ${props => props.fontSettings ? props.fontSettings : props.theme.fonts.lightLabel}
    }
  .ui.selection.active.dropdown .menu > .item {
    ${props => props.optionFontSettings ? props.optionFontSettings : props.theme.fonts.lightLabel}
  }
`;

const PreferredAPSettings = (props) => {
  const {
    bssid,
    channel,
    errorMessages,
    handleInputClick,
    model
  } = props;
  return (
    <>
      <Grid columns={2} doubling stackable>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <InputField
                type="text"
                label="BSSID"
                name="bssid"
                value={bssid}
                error={errorMessages.bssid_error}
                onChange={e => handleInputClick(e, {}, model)}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <InputField
                type="number"
                label="Channel"
                name="channel"
                value={channel}
                error={errorMessages.channel_error}
                onChange={e => handleInputClick(e, {}, model)}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

PreferredAPSettings.propTypes = {
  bssid: PropTypes.string,
  channel: PropTypes.string
};

PreferredAPSettings.defaultProps = {
  bssid: '',
  channel: ''
};

export default PreferredAPSettings;
