import { templatesActionConstants } from 'home/Templates/actions/templates.constants';
import { handleResponse } from 'common/helpers';
import {
  axiosInstance,
  ENDPOINT,
} from 'common/constants';

const getAllTemplates = (type = 'ALL') => dispatch => handleResponse(axiosInstance.get(ENDPOINT.GET_ASSET_TEMPLATES)).then(
  (res) => {
    const templates = [];
    res.items.forEach((element) => {
      templates.push({
        id: element.id,
        name: element.name,
        desc: element.description,
        numBearings: element.no_of_bearings,
        type: element.asset_type,
        subType: element.sub_type,
        account_id: element.account_id
      });
    });
    const templateArr = (type === 'ALL') ? templates : templates.filter(template => template.type === type);
    dispatch({ type: templatesActionConstants.GET_ALL_TEMPLATES, templateArr });
    return (res);
  },
  error => Promise.reject(error)
);

export const getTemplateInfo = (templateId, localOnly = false) => (dispatch) => {
  const request = () => ({ type: templatesActionConstants.GET_TEMPLATE_INFO_REQUEST });
  const success = details => ({ type: templatesActionConstants.GET_TEMPLATE_INFO_SUCCESS, details });
  const failure = error => ({ type: templatesActionConstants.GET_TEMPLATE_INFO_FAILURE, error });

  if (!localOnly) dispatch(request());
  return handleResponse(axiosInstance.get(ENDPOINT.GET_ASSET_TEMPLATE(templateId))).then(
    (templateInfo) => {
      if (!localOnly) dispatch(success(templateInfo));
      return templateInfo;
    },
    (error) => {
      if (!localOnly) dispatch(failure(error.message));
      return error;
    }
  );
};

export const updateTemplateInfo = (templateId, info) => () => {
  const params = info;
  return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_ASSET_TEMPLATE(templateId), params));
};

export const deleteTemplate = templateId => () => handleResponse(axiosInstance.delete(ENDPOINT.DELETE_ASSET_TEMPLATE(templateId)));

const selectTemplateForCreation = templateArr => (dispatch) => {
  dispatch({ type: templatesActionConstants.SELECT_TEMPLATE_FOR_CREATION, templateArr });
};

const removeTemplateForCreation = templateArr => (dispatch) => {
  dispatch({ type: templatesActionConstants.REMOVE_TEMPLATE_FOR_CREATION, templateArr });
};

const clearSelectedTemplates = () => (dispatch) => {
  dispatch({ type: templatesActionConstants.CLEAR_SELECTED_TEMPLATES });
};

const updateTemplateNums = (id, num) => (dispatch) => {
  dispatch({ type: templatesActionConstants.UPDATE_TEMPLATE_NUMS, id, num });
};

const setSaveAsGlobal = value => (dispatch) => {
  dispatch({ type: templatesActionConstants.SET_SAVE_AS_GLOBAL, value });
};

const templatesActions = {
  getAllTemplates,
  getTemplateInfo,
  updateTemplateInfo,
  deleteTemplate,
  selectTemplateForCreation,
  removeTemplateForCreation,
  clearSelectedTemplates,
  updateTemplateNums,
  setSaveAsGlobal,
};

export default templatesActions;
