import styled from 'styled-components';


const ModalScrollContainer = styled.div`
  position: relative;
  min-height: 100%;
  height: 100%;
  width: 100%;
  z-index: 999;
  overflow-x: ${props => props.innerScroll ? 'hidden' : 'auto'};
  overflow-y: ${props => props.innerScroll ? 'hidden' : 'auto'};
  ${props => (props.verticalCenter ? `
    display: flex;
    align-items: center;
    justify-content: center;
  ` : '')}
`;

ModalScrollContainer.displayName = 'ModalScrollContainer';

export default styled(ModalScrollContainer)``;
