import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'common/styles/colors';

const FlexContainer = styled.div.attrs(props => ({ className: props.className, id: props.id }))`
  display: flex;
  border: ${props => props.border ? props.border : ''};
  border-radius: ${props => props.borderRadius ? props.borderRadius : ''};
  height: ${props => props.height};
  flex-direction: ${props => props.direction};
  flex-wrap: ${props => props.flexWrap};
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  flex-grow: ${props => props.flexGrow};
  flex-basis: ${props => props.flexBasis};
  pointer-events: ${props => props.pointerEvents ? 'none' : 'all'};
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor}`};
  ${props => props.width && `width: ${props.width};`}
  ${props => props.alignSelf && `align-self: ${props.alignSelf};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.paddingleft && `padding-left: ${props.paddingleft};`}
  ${props => props.paddingtop && `padding-top: ${props.paddingtop};`}
  ${props => props.paddingbottom && `padding-bottom: ${props.paddingbottom};`}
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.position && `position: ${props.position};`}
  ${props => props.margintop && `margin-top: ${props.margintop};`}
  ${props => props.marginbottom && `margin-bottom: ${props.marginbottom};`}
  ${props => props.marginright && `margin-right: ${props.marginright};`}
  ${props => props.marginleft && `margin-left: ${props.marginleft};`}
  ${props => props.flexGap && (props.direction === 'row' ? `
    padding: 0 calc(${props.flexGap} / 2);
    & > * {
      margin: 0 calc(${props.flexGap} / 2);
    }` : `
    padding: calc(${props.flexGap} / 2) 0;
    & > * {
      margin: calc(${props.flexGap} / 2) 0;
    }`
  )}
  ${props => props.color && `color: ${props.color};`}
  font-family: "Petasense Open Sans";
  ${props => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.greyL};
  }
`;

FlexContainer.propTypes = {
  direction: PropTypes.oneOf(['row', 'column', 'row-reverse', 'column-reverse']),
  flexWrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  alignItems: PropTypes.oneOf(['stretch', 'center', 'flex-start', 'flex-end', 'baseline', 'initial', 'inherit']),
  alignSelf: PropTypes.oneOf(['auto', 'flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
  justifyContent: PropTypes.oneOf(['space-between', 'space-around', 'center', 'flex-start', 'flex-end', 'initial', 'inherit']),
  flexGrow: PropTypes.number,
  flexBasis: PropTypes.string,
  pointerEvents: PropTypes.bool
};

FlexContainer.defaultProps = {
  direction: 'row',
  flexWrap: 'nowrap',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  flexGrow: 0,
  flexBasis: 'auto',
  pointerEvents: false
};

FlexContainer.displayName = 'FlexContainer';

export default FlexContainer;
