const initialState = {
  mobileCurrentWidth: 0,
  mobilePreviousWidth: window.innerWidth,
};

export default function hierarchyViewPaneReducer(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_HIERARCHY_VIEW_ON_MOBILE':
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
}
