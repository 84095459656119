import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Component that alerts if you click outside of it
 */

const Container = styled.div`
  display: ${props => props.display || 'flex'};
  flex-direction: ${props => props.flexDirection || 'row'};
  ${props => props.styles}
`;

export default class OutsideAlerter extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener(this.props.event, this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener(this.props.event, this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      const { handleClick, open } = this.props;
      if (open) {
        handleClick(event);
      }
      // alert('You clicked outside of me!');
    }
  }

  render() {
    return <Container innerRef={this.setWrapperRef} {...this.props}>{this.props.children}</Container>;
  }
}

OutsideAlerter.propTypes = {
  event: PropTypes.oneOf(['click', 'mousedown']),
  handleClick: PropTypes.func.isRequired,
  open: PropTypes.bool
};

OutsideAlerter.defaultProps = {
  event: 'mousedown',
  open: false
};
