import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse } from 'common/helpers';
import { getNoteDetails } from 'home/Activity/actions/activity.actions';
import { machineDetailsConstants } from 'home/Machines/MachineDetails/machineDetails.constants';
import { machineDetailsService } from 'home/Machines/MachineDetails/machineDetails.service';
import { toastr } from 'react-redux-toastr';
import { attachmentConstants } from '../constants/attachments.constants';
import { getTaskDetails } from './tasks.actions';
import { getRepairDetails } from './repairs.actions';

function changeUploadProgress(index, value, originType) {
  return { type: attachmentConstants.CHANGE_UPLOAD_PROGRESS, index, value, originType };
}

export const uploadAttachment = (origin_type, origin_id, files, index = 0) => {
  const request = () => ({ type: attachmentConstants.UPLOAD_ATTACHMENT_REQUEST });
  function success(files) {
    return { type: attachmentConstants.UPLOAD_ATTACHMENT_SUCCESS, files };
  }
  function failure(files) {
    return { type: attachmentConstants.UPLOAD_ATTACHMENT_FAILURE, files };
  }

  return (dispatch) => {
    if (files.length <= index) {
      return null;
    }
    const file = files[index];
    if (file.uploaded === true) {
      return (
        dispatch(uploadAttachment(origin_type, origin_id, files, ++index))
      );
    }

    const config = {
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);

        if (percent >= 100) {
          dispatch(changeUploadProgress(index, 100, origin_type));
        } else {
          dispatch(changeUploadProgress(index, percent, origin_type));
        }
      },
      headers: { 'content-type': 'multipart/form-data' }
    };

    const data = new FormData();
    data.append(`file${index}`, file, file.name);

    dispatch(request());
    return handleResponse(axiosInstance.post(ENDPOINT.UPLOAD_ATTACHMENT(origin_type, origin_id), data, config)).then(
      () => {
        files[index].uploaded = true;
        dispatch(success(files));
        if (origin_type === 'task') dispatch(getTaskDetails(origin_id));
        if (origin_type === 'repair') dispatch(getRepairDetails(origin_id));
        if (origin_type === 'note') dispatch(getNoteDetails(origin_id, 'note'));
        if (origin_type === 'machine') dispatch(getMachineAttachments(origin_id));
        dispatch(uploadAttachment(origin_type, origin_id, files, ++index));
      },
      () => {
        files[index].uploaded = false;
        dispatch(failure(files));
        dispatch(uploadAttachment(origin_type, origin_id, files, ++index));
      },
    );
  };
};

export const deleteAttachment = (origin_type, origin_id, attachment_id, callBackFn = () => {}) => {
  const request = () => ({ type: attachmentConstants.DELETE_ATTACHMENT_REQUEST });
  const success = () => ({ type: attachmentConstants.DELETE_ATTACHMENT_SUCCESS });
  const failure = () => ({ type: attachmentConstants.DELETE_ATTACHMENT_FAILURE });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.delete(ENDPOINT.DELETE_ATTACHMENT(attachment_id)))
      .then(
        () => {
          dispatch(success());
          toastr.success('Attachment deleted sucessfully');
          callBackFn();
          if (origin_type === 'task') dispatch(getTaskDetails(origin_id));
          if (origin_type === 'repair') dispatch(getRepairDetails(origin_id));
          if (origin_type === 'note') dispatch(getNoteDetails(origin_id, 'note'));
          if (origin_type === 'machine') dispatch(getMachineAttachments(origin_id));
        },
        (error) => {
          dispatch(failure());
          toastr.error(error.message);
        },
      );
  };
};

export const getMachineAttachments = (machineId) => {
  return (dispatch) => {
    dispatch(request());
    return machineDetailsService.getMachineAttachments(machineId).then(
      (res) => {
        dispatch(success(res.attachments));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: machineDetailsConstants.GET_MACHINE_ATTACHMENTS };
  }
  function success(attachments) {
    return { type: machineDetailsConstants.GET_MACHINE_ATTACHMENTS_SUCCESS, attachments };
  }
  function failure(error) {
    return { type: machineDetailsConstants.GET_MACHINE_ATTACHMENTS_FAILURE, error };
  }
};

export const setAttachmentsFiles = (files, originType) => (
  dispatch => dispatch({ type: attachmentConstants.SET_ATTACHMENT_FILES, files, originType })
);

export const clearAttachmentsFiles = originType => (
  dispatch => dispatch({ type: attachmentConstants.CLEAR_ATTACHMENT_FILES, originType })
);

export const resetUpload = originType => (
  dispatch => dispatch({ type: attachmentConstants.RESET_UPLOAD, originType })
);

export const removeAttachmentFile = (filename, originType) => (
  dispatch => dispatch({ type: attachmentConstants.REMOVE_ATTACHMENT_FILE, filename, originType })
);
