import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Chart from 'react-google-charts';
import { FlexContainer } from 'common/components/atoms';
import colors from 'common/styles/colors';
import { chartOptions, donutChartEvents } from './constants';

const ChartContainer = styled(FlexContainer).attrs({
  direction: 'column',
  alignItems: 'center',
})`
  ${props => props.isInteractive && `
    & svg g path, & svg g text {
      cursor: pointer;
    }
  `}
  div.google-visualization-tooltip, div.google-visualization-tooltip * {
    pointer-events: none;
  }
  svg g { font-weight: 600; }
`;

const TITLE_HEIGHT = 40;
const Title = styled.div`
  color: ${colors.darkGray};
  font-weight: 600;
  width: 100%;
  padding: 1em 0 0 1em;
  height: ${TITLE_HEIGHT}px;
`;

const formatChartData = data => (
  data.map(d => [d.label, d.value])
);

const addValuesToLegendData = data => (
  data.map(d => [`${d[0]} (${d[1]})`, d[1], `${d[0]} (${d[1]})`])
);

const DonutChart = ({
  title,
  data,
  resourceName,
  colors,
  handleClick,
  formatData,
  isInteractive
}) => {
  if (formatData) data = formatChartData(data);
  let pieData = addValuesToLegendData(data);
  pieData = [[title, `Number of ${resourceName}`, { role: 'tooltip' }]].concat(pieData);
  const options = { ...chartOptions.PieChart, colors };

  const titleHeight = title !== '' ? TITLE_HEIGHT : 0;

  const containerRef = useRef();
  const [height, setHeight] = useState(0);
  const { current: resizeObserver } = useRef(new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      setHeight(entry.target.offsetHeight);
    });
  }));

  useEffect(() => {
    resizeObserver.observe(containerRef.current.parentElement);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <ChartContainer
      innerRef={containerRef}
      isInteractive={isInteractive}
    >
      {title !== '' && <Title className="title">{title}</Title>}
      {data.length >= 1 ? (
        <Chart
          key={height}
          width="100%"
          height={height - titleHeight}
          chartType="PieChart"
          data={pieData}
          options={options}
          chartEvents={donutChartEvents(handleClick)}
        />
      ) :
        <h3>Data unavailable</h3>
      }
    </ChartContainer>
  );
};

DonutChart.propTypes = {
  handleClick: PropTypes.func,
  title: PropTypes.string,
  formatData: PropTypes.bool,
  resourceName: PropTypes.string,
  isInteractive: PropTypes.bool,
};

DonutChart.defaultProps = {
  handleClick: () => {},
  title: '',
  formatData: false,
  resourceName: '',
  isInteractive: true,
};

export default DonutChart;
