import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingSvg from 'common/images/LoadingSvg';

const LoadingContainer = styled.div`
  ${props => props.position && `position: ${props.position}`};
  ${props => props.display && `display: ${props.display}`};
  width: 100vw;
  justify-content: center;
  padding: 2em 0;
  top: 0px;
  height: 100vh;
  left: 0px;
  align-items: center;
  background-color: rgba(255,255,255,0.9);
  z-index: 10000;
  flex-direction: column;
  ${props => props.center && 'margin: auto;'}
`;

const LoadingMsg = styled.div`
padding: 1rem;
color: #333;
font-size: 1.2rem;
white-space: pre-line;
text-align: center;
`;

class LoadingFullScreen extends Component {
  render() {
    const {
      position,
      center,
      display,
      msg,
      id,
      className,
    } = this.props;

    return (
      <LoadingContainer position={position} center={center} display={display} id={id} className={className}>
        <LoadingSvg />
        <LoadingMsg className="loader-fullscreen-msg">
          {msg}
        </LoadingMsg>
      </LoadingContainer>
    );
  }
}

LoadingFullScreen.propTypes = {
  position: PropTypes.string,
  center: PropTypes.bool,
  display: PropTypes.string,
  msg: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
};

LoadingFullScreen.defaultProps = {
  position: 'fixed',
  display: 'flex',
  msg: '',
  center: false,
  id: 'loaderFullScreen',
  className: 'loader',
};

export default LoadingFullScreen;
