import { thirdPartyTagsConstants } from './thirdPartyTags.constants';

const initialState = {
  loading: false,
  object: [],
  hasMore: false,
  page: 1
};

export const thirdPartyTagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case thirdPartyTagsConstants.GET_THIRD_PARTY_TAGS_LIST_REQUEST: {
      if (action.page === 1) {
        return {
          ...state,
          loading: true,
          object: []
        };
      }
      return {
        ...state,
        loading: false,
        listLoading: true
      };
    }
    case thirdPartyTagsConstants.GET_THIRD_PARTY_TAGS_LIST_SUCCESS: {
      let thirdPartyTagsList = [];
      if (action.page === 1) thirdPartyTagsList = action.thirdPartyTags;
      else thirdPartyTagsList = state.object.concat(action.thirdPartyTags);
      return {
        hasMore: action.page < action.total_pages,
        object: thirdPartyTagsList,
        page: action.page,
        total_count: action.total_count,
        loading: false,
        listLoading: false
      };
    }
    case thirdPartyTagsConstants.GET_THIRD_PARTY_TAGS_LIST_FAILURE:
      return {
        ...state,
        hasMore: false,
        loading: false,
        listLoading: false,
        error: action.error.message
      };
    case thirdPartyTagsConstants.UPDATE_THIRD_PARTY_TAG:
      return {
        ...state,
        object: state.object.map(item => action.tagId === item.id ? { ...item, ...action.updatedData } : item)
      };
    case thirdPartyTagsConstants.CLEAR_THIRD_PARTY_TAGS_LIST:
      return {
        ...state,
        object: []
      };
    case thirdPartyTagsConstants.INCREASE_PAGE:
      return {
        ...state,
        page: action.page
      };
    default:
      return state;
  }
};

const initialStateFilters = {
  filters: [],
  search_key: ''
};

export const thirdPartyTagsFilters = (state = initialStateFilters, action) => {
  switch (action.type) {
    case thirdPartyTagsConstants.SET_THIRD_PARTY_TAGS_FILTERS:
      return {
        filters: action.filters,
        search_key: action.search_key
      };
    default:
      return state;
  }
};

export const tagTypes = (state = { tag_types: [] }, action) => {
  switch (action.type) {
    case thirdPartyTagsConstants.GET_ALL_TAG_TYPES:
      return {
        tag_types: action.tag_types
      };
    default:
      return state;
  }
};
