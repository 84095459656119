import _ from 'lodash';
import MachineChartsTypes from '../actions/machineCharts.types';

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MachineChartsTypes.GET_CHARTS_DATA_REQUEST:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.config_id) return item;
            return {
              ...item,
              trends_data: {
                ...item.trends_data,
                loading: true
              }
            };
          })
        }
      };
    case MachineChartsTypes.GET_CHARTS_DATA_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.config_id) return item;
            return {
              ...item,
              time_range: action.time_range,
              trends_data: action.data
            };
          })
        }
      };
    case MachineChartsTypes.GET_CHARTS_DATA_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.config_id) return item;
            return {
              ...item,
              trends_data: {
                ...item.trends_data,
                error: action.error.message
              }
            };
          })
        }
      };
    case MachineChartsTypes.CLEAR_CHARTS_TAGS:
      return {
        ...state,
        tags: undefined,
        config: undefined
      };
    case MachineChartsTypes.CHARTS_FILTERS:
      return {
        ...state,
        chartsFilters: action.filters
      };
    case MachineChartsTypes.GET_MACHINE_CHARTS_TAGS_REQUEST:
      return {
        ...state,
        machine_id: parseInt(action.machineId, 10),
        loading: true
      };
    case MachineChartsTypes.GET_MACHINE_CHARTS_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: action.tags
      };
    case MachineChartsTypes.GET_MACHINE_CHARTS_TAGS_FAILURE:
      return {
        ...state,
        loading: false
      };

    case MachineChartsTypes.GET_TAG_TREND_REQUEST:
      return {
        ...state,
        tags: _.map(state.tags, (t) => {
          if (t.id !== action.tagId) return t;
          return {
            ...t,
            trend: {
              ...t.trend,
              loading: true
            }
          };
        })
      };
    case MachineChartsTypes.GET_TAG_TREND_SUCCESS:
      return {
        ...state,
        tags: _.map(state.tags, (t) => {
          if (t.id !== action.tagId) return t;
          return {
            ...t,
            trend: { ...action.trend, loading: false }
          };
        })
      };
    case MachineChartsTypes.GET_TAG_TREND_FAILURE:
      return {
        ...state,
        tags: _.map(state.tags, (t) => {
          if (t.id !== action.tagId) return t;
          return {
            ...t,
            trend: {
              loading: false,
              error: action.error.message
            }
          };
        })
      };

    case MachineChartsTypes.GET_TAG_WAVEFORM_REQUEST:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              waveform: {
                ...item.waveform,
                loading: true,
              }
            };
          })
        }
      };
    case MachineChartsTypes.GET_TAG_WAVEFORM_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              waveform: action.waveform
            };
          })
        }
      };
    case MachineChartsTypes.GET_TAG_WAVEFORM_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              waveform: {
                loading: false,
                error: action.error.message
              }
            };
          })
        }
      };

    case MachineChartsTypes.GET_TAG_DEMOD_SPECTRUM_REQUEST:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              demod_spectrum: {
                ...item.demod_spectrum,
                loading: true,
              }
            };
          })
        }
      };

    case MachineChartsTypes.GET_TAG_DEMOD_SPECTRUM_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              demod_spectrum: action.spectrum
            };
          })
        }
      };

    case MachineChartsTypes.GET_TAG_DEMOD_SPECTRUM_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              demod_spectrum: {
                loading: false,
                error: action.error,
              }
            };
          })
        }
      };

    case MachineChartsTypes.GET_TAG_SPECTRUM_REQUEST:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              spectrum: {
                ...item.spectrum,
                loading: true,
              }
            };
          })
        }
      };

    case MachineChartsTypes.GET_TAG_SPECTRUM_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              spectrum: action.spectrum
            };
          })
        }
      };

    case MachineChartsTypes.GET_TAG_SPECTRUM_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              spectrum: {
                loading: false,
                error: action.error.message
              }
            };
          })
        }
      };

    case MachineChartsTypes.spectrumFeature.GET_FEATURES_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              spectrumFeatures: { items: action.features }
            };
          })
        }
      };

    case MachineChartsTypes.spectrumFeature.GET_FEATURES_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              spectrumFeatures: { items: [] }
            };
          })
        }
      };

    case MachineChartsTypes.spectrumFeature.GET_FORCING_FREQUENCIES_SUCCESS:
      return {
        ...state,
        tags: _.map(state.tags, (t) => {
          if (t.id !== action.tagId) return t;
          return {
            ...t,
            forcingFrequencies: { items: action.features }
          };
        })
      };

    case MachineChartsTypes.spectrumFeature.GET_FORCING_FREQUENCIES_FAILURE:
      return {
        ...state,
        tags: _.map(state.tags, (t) => {
          if (t.id !== action.tagId) return t;
          return {
            ...t,
            forcingFrequencies: { items: [], error: action.error.message }
          };
        })
      };

    case MachineChartsTypes.spectrumFeature.CREATE_FEATURE_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              spectrumFeatures: { items: [...(item.spectrumFeatures.items || []), action.feature] }
            };
          })
        }
      };

    case MachineChartsTypes.spectrumFeature.UPDATE_FEATURE_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              spectrumFeatures: {
                items: _.map(item.spectrumFeatures.items, (i) => {
                  if (i.id !== action.feature.id) return i;
                  return action.feature;
                })
              }
            };
          })
        }
      };

    case MachineChartsTypes.spectrumFeature.DELETE_FEATURE_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              spectrumFeatures: {
                items: _.filter(item.spectrumFeatures.items, i => i.id !== action.featureId)
              }
            };
          })
        }
      };

    case MachineChartsTypes.spectrumFeature.GET_FEATURE_TREND_SUCCESS:
      return {
        ...state,
        featureTrend: { featureId: action.featureId, data: action.result, amp_units: action.amp_units }
      };

    case MachineChartsTypes.CREATE_MACHINE_NOTE_REQUEST:
      return {
        ...state,
        note: {
          loading: true
        }
      };

    case MachineChartsTypes.CREATE_MACHINE_NOTE_SUCCESS:
      return {
        ...state,
        note: {
          loading: false
        }
      };

    case MachineChartsTypes.CREATE_MACHINE_NOTE_FAILURE:
      return {
        ...state,
        note: {
          loading: false,
          error: action.error.message
        }
      };

    case MachineChartsTypes.UPDATE_TAG_REQUEST:
      return {
        ...state
      };
    // NOTE: undefined check is important, don not change it to || operator
    case MachineChartsTypes.UPDATE_TAG_SUCCESS:
      return {
        ...state,
        tags: _.map(state.tags, (t) => {
          if (t.id !== action.tagId) return t;
          return {
            ...t,
            bandpass_lower_cutoff: action.params.bandpass_lower_cutoff === 'undefined' ? t.bandpass_lower_cutoff : action.params.bandpass_lower_cutoff,
            bandpass_upper_cutoff: action.params.bandpass_upper_cutoff === 'undefined' ? t.bandpass_upper_cutoff : action.params.bandpass_upper_cutoff,
            warning_threshold: action.params.warning_threshold === 'undefined' ? t.warning_threshold : action.params.warning_threshold,
            critical_threshold: action.params.critical_threshold === 'undefined' ? t.critical_threshold : action.params.critical_threshold
          };
        })
      };
    case MachineChartsTypes.UPDATE_TAG_FAILURE:
      return {
        ...state
      };

    case MachineChartsTypes.GET_CHARTS_CONFIG_REQUEST:
      return {
        ...state,
        machine_id: parseInt(action.machine_id, 10),
        config: {
          loading: true
        }
      };
    case MachineChartsTypes.GET_CHARTS_CONFIG_SUCCESS:
      return {
        ...state,
        config: {
          metadata: state.config.metadata || null,
          items: action.config,
        }
      };
    case MachineChartsTypes.REORDER_CHARTS:
      return {
        ...state,
        config: {
          ...state.config,
          items: action.items
        }
      };
    case MachineChartsTypes.GET_CHARTS_CONFIG_FAILURE:
      return {
        ...state,
        config: {
          error: action.error.message
        }
      };

    case MachineChartsTypes.POST_CHARTS_CONFIG_REQUEST:
      return {
        ...state,
        config: {
          ...state.config,
          ...state.items,
          loading: true,
        }
      };
    case MachineChartsTypes.POST_CHARTS_CONFIG_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          items: [...(state.config.items || []), action.config],
          loading: false,
          error: undefined
        }
      };
    case MachineChartsTypes.POST_CHARTS_CONFIG_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          error: action.error.message,
          loading: false
        }
      };

    case MachineChartsTypes.POST_DERIVED_TAG_REQUEST:
      return {
        ...state,
        config: {
          ...state.config,
          ...state.items,
          loading: true,
        }
      };
    case MachineChartsTypes.POST_DERIVED_TAG_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          loading: false,
          error: undefined
        }
      };
    case MachineChartsTypes.POST_DERIVED_TAG_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          error: action.error.message,
          loading: false
        }
      };

    case MachineChartsTypes.PUT_CHARTS_CONFIG_REQUEST:
      return {
        ...state
      };
    case MachineChartsTypes.PUT_CHARTS_CONFIG_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.config_id) return item;
            return {
              ...item,
              name: action.name,
              trends_config: action.trends_config,
            };
          })
        }
      };
    case MachineChartsTypes.PUT_CHARTS_CONFIG_FAILURE:
      return {
        ...state
      };

    case MachineChartsTypes.DELETE_CHARTS_CONFIG_REQUEST:
      return {
        ...state
      };
    case MachineChartsTypes.DELETE_CHARTS_CONFIG_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.filter(state.config.items, item => item.config_id !== action.config_id)
        }
      };
    case MachineChartsTypes.DELETE_CHARTS_CONFIG_FAILURE:
      return {
        ...state
      };

    case MachineChartsTypes.GET_CHARTS_CONFIG_METADATA_REQUEST:
      return {
        ...state
      };
    case MachineChartsTypes.GET_CHARTS_CONFIG_METADATA_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          metadata: action.metadata
        }
      };
    case MachineChartsTypes.GET_CHARTS_CONFIG_METADATA_FAILURE:
      return {
        ...state
      };

    case MachineChartsTypes.GET_TAG_WATERFALL_SPECTRUM_REQUEST:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              waterfall_spectrum: {
                ...item.waterfall_spectrum,
                loading: true
              }
            };
          })
        }
      };

    case MachineChartsTypes.GET_TAG_WATERFALL_SPECTRUM_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              waterfall_spectrum: action.data
            };
          })
        }
      };

    case MachineChartsTypes.GET_TAG_WATERFALL_SPECTRUM_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          items: _.map(state.config.items, (item) => {
            if (item.config_id !== action.configId) return item;
            return {
              ...item,
              waterfall_spectrum: {
                loading: false,
                error: action.error,
              }
            };
          })
        }
      };

    default:
      return state;
  }
};
