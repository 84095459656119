export const mapNames = {
  rms: 'RMS',
  p2p: 'P2P',
  peak: 'Peak',
  crest_factor: 'Crest Factor',
  velocity: 'Velocity',
  acceleration: 'Acceleration',
  Hz: 'Hz',
  RPM: 'RPM'
};

export const mapUtilizationStatus = [
  'NA',
  'OFF',
  'ON',
];

export const utilColors = [
  '#d3d3d3',
  '#ced7a9',
  '#91b846'
];
