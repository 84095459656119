import styled from 'styled-components';
import Container from '../../../common/components/atoms/Container';

export const ListContainer = styled(Container)`
  margin-top: 30px;
  /* margin-bottom: 50px; */
  flex: 0.5;
  position: relative;
  margin-right: 15px;
  margin-left: 25px;
  ${props => props.first && 'margin-left: 35px;'}
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.minWidth && `min-width: ${props.minWidth};`}
  ${props => props.flex && `flex: ${props.flex};`}
  border-radius: 8px;
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 60px;
  height: calc(100vh-60px);
`;

export const ListFooter = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: center;
  margin: 20px;
  a {
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.primaryColor};
    border-radius: 4px;
    color: ${props => props.theme.primaryColor};
    width: 100%;
    height: 100%;
    padding: 5px;
    :hover {
      background-color: ${props => props.theme.primaryColor};
      color: ${props => props.theme.colors.white};
    }
  }
`;
