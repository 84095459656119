import moment from 'moment-timezone';
import { change } from 'redux-form';

import { machineDetailsConstants } from './machineDetails.constants';
// import { sensorsTabConstants } from './sensorsTab/sensorsTab.constants';
import { machineDetailsService } from './machineDetails.service';
import { alertActions } from '../../../alert';
import { getHierarchy } from '../../AssetHierarchy/actions/assetDetails.actions';
import * as hierarchyActions from '../../AssetHierarchy/actions/hierarchy.actions';
import { selectAccount } from '../../../onboarding/user.actions';
import { axiosInstance, ENDPOINT } from '../../../common/constants';
import { handleResponse } from '../../../common/helpers';

// export const clearSensorsTabItems = () => (dispatch) => {
//   dispatch({ type: sensorsTabConstants.CLEAR_MACHINE_MOTE_LIST });
//   dispatch({ type: sensorsTabConstants.CLEAR_MACHINE_TX_LIST });
//   // dispatch({ type: sensorsTabConstants.CLEAR_MACHINE_OTHERS_LIST });
// };

export const getMachineDetails = (machineId) => {
  return (dispatch, getState) => {
    dispatch(request());

    dispatch(alertActions.clear());
    const params = {
      resource_version: 3
    };
    return machineDetailsService.getMachineDetails(machineId, params).then(
      (res) => {
        const user = getState().user.user;
        if (res && res.account_id && user.account_id !== res.account_id) {
          dispatch(selectAccount(res.account_id, false)).then(
            dispatch(getHierarchy(true)).then(() => {
              dispatch(hierarchyActions.selectNode(machineId, 'machine'));
            })
          );
          return;
        }
        dispatch(success(res));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request() {
    return { type: machineDetailsConstants.GET_MACHINE_DETAILS_REQUEST };
  }
  function success(machine) {
    return { type: machineDetailsConstants.GET_MACHINE_DETAILS_SUCCESS, machine };
  }
  function failure(error) {
    return { type: machineDetailsConstants.GET_MACHINE_DETAILS_FAILURE, error };
  }
};

export const postMarkasAnalyzed = (machineId, notes = null) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(alertActions.clear());
    const params = {
      notes
    };
    return machineDetailsService.postMachineMarkasAnalyze(machineId, params).then(
      (res) => {
        dispatch(success(res.message));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
        throw error;
      }
    );
  };

  function request() {
    return { type: machineDetailsConstants.MARK_AS_ANALYSED_REQUEST };
  }
  function success(machine) {
    return { type: machineDetailsConstants.MARK_AS_ANALYSED_SUCCESS, machine };
  }
  function failure(error) {
    return { type: machineDetailsConstants.MARK_AS_ANALYSED_FAILURE, error };
  }
};

export const updateMachineDetails = (machineId, params) => {
  return (dispatch) => {
    dispatch(request());

    dispatch(alertActions.clear());

    return machineDetailsService.updateMachineDetails(machineId, params).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
        // toastr.error(error.data.message);
      }
    );
  };

  function request() {
    return { type: machineDetailsConstants.UPDATE_MACHINE_DETAILS_REQUEST };
  }
  function success(msg) {
    return { type: machineDetailsConstants.UPDATE_MACHINE_DETAILS_SUCCESS, msg };
  }
  function failure(error) {
    return { type: machineDetailsConstants.UPDATE_MACHINE_DETAILS_FAILURE, error };
  }
};

export const getMachineTags = (machineId) => {
  return (dispatch) => {
    dispatch(request());

    dispatch(alertActions.clear());

    return machineDetailsService.getMachineTags(machineId).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
        // toastr.error(error.data.message);
      }
    );
  };

  function request() {
    return { type: machineDetailsConstants.GET_MACHINE_DETAILS_CHARTS_TAGS_REQUEST };
  }
  function success(tags) {
    return { type: machineDetailsConstants.GET_MACHINE_DETAILS_CHARTS_TAGS_SUCCESS, tags };
  }
  function failure(error) {
    return { type: machineDetailsConstants.GET_MACHINE_DETAILS_CHARTS_TAGS_FAILURE, error };
  }
};

export const swapMachineTags = (oldIndex, newIndex) => (dispatch) => {
  dispatch({
    type: machineDetailsConstants.SWAP_MACHINE_DETAILS_CHARTS_TAGS,
    oldIndex,
    newIndex
  });
};

export const clearMachineTags = () => (dispatch) => {
  dispatch({ type: machineDetailsConstants.CLEAR_MACHINE_DETAILS_TAGS });
};

export const getTagTrend = (tagId, params) => {
  return (dispatch) => {
    dispatch(request(tagId));

    dispatch(alertActions.clear());

    const { days, ...rest } = params;

    return machineDetailsService.getTagTrend(tagId, days === 'all' ? rest : params).then(
      (res) => {
        dispatch(success(tagId, res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request(tagId) {
    return { type: machineDetailsConstants.tagChart.TREND_REQUEST, tagId };
  }
  function success(tagId, trend) {
    return { type: machineDetailsConstants.tagChart.TREND_SUCCESS, tagId, trend };
  }
  function failure(tagId, error) {
    return { type: machineDetailsConstants.tagChart.TREND_FAILURE, tagId, error };
  }
};

export const getTagWaveform = (tagId, params) => {
  params.timestamp = moment(params.timestamp).toISOString();

  return (dispatch) => {
    dispatch(request(tagId));

    dispatch(alertActions.clear());

    return machineDetailsService.getTagWaveform(tagId, params).then(
      (res) => {
        dispatch(success(tagId, res));
      },
      (error) => {
        dispatch(failure(tagId, error));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request(tagId) {
    return { type: machineDetailsConstants.tagChart.WAVEFORM_REQUEST, tagId };
  }
  function success(tagId, waveform) {
    return { type: machineDetailsConstants.tagChart.WAVEFORM_SUCCESS, tagId, waveform };
  }
  function failure(tagId, error) {
    return { type: machineDetailsConstants.tagChart.WAVEFORM_FAILURE, tagId, error };
  }
};

export const getTagSpectrum = (tagId, params) => {
  params.timestamp = moment(params.timestamp).toISOString();

  return (dispatch) => {
    dispatch(request(tagId));

    dispatch(alertActions.clear());

    return machineDetailsService.getTagSpectrum(tagId, params).then(
      (res) => {
        dispatch(success(tagId, res));
      },
      (error) => {
        dispatch(failure(tagId, error));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request(tagId) {
    return { type: machineDetailsConstants.tagChart.SPECTRUM_REQUEST, tagId };
  }
  function success(tagId, spectrum) {
    return { type: machineDetailsConstants.tagChart.SPECTRUM_SUCCESS, tagId, spectrum };
  }
  function failure(tagId, error) {
    return { type: machineDetailsConstants.tagChart.SPECTRUM_FAILURE, tagId, error };
  }
};

export const showSpectrumFeature = tagId => (dispatch) => {
  dispatch({ type: machineDetailsConstants.tagChart.SPECTRUM_SHOW_FEATURE_MODAL, tagId });
};

export const hideSpectrumFeature = tagId => (dispatch) => {
  dispatch({ type: machineDetailsConstants.tagChart.SPECTRUM_HIDE_FEATURE_MODAL, tagId });
};

export const trendNote = (machineId, params) =>
  addNote(machineId, { chart_type: 'trend', ...params });

export const spectrumNote = (machineId, params) =>
  addNote(machineId, { chart_type: 'spectrum', ...params });

export const waveformNote = (machineId, params) =>
  addNote(machineId, { chart_type: 'waveform', ...params });

export const addNote = (machineId, params) => {
  return (dispatch) => {
    dispatch(request());

    dispatch(alertActions.clear());

    return machineDetailsService.addNote(machineId, params).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
        // toastr.error(error.data.message);
      }
    );
  };

  function request() {
    return { type: machineDetailsConstants.ADD_NOTE_REQUEST };
  }
  function success(msg) {
    return { type: machineDetailsConstants.ADD_NOTE_SUCCESS, msg };
  }
  function failure(error) {
    return { type: machineDetailsConstants.ADD_NOTE_FAILURE, error };
  }
};

// SPECTRUM FEATURE ACTIONS
export const getSpectrumFeatures = (tagId) => {
  return (dispatch) => {
    dispatch(request(tagId));

    dispatch(alertActions.clear());

    return machineDetailsService.getSpectrumFeatures(tagId).then(
      (res) => {
        dispatch(success(tagId, res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request() {
    return { type: machineDetailsConstants.tagChart.spectrumFeature.GET_FEATURES_REQUEST };
  }
  function success(tagId, features) {
    return {
      type: machineDetailsConstants.tagChart.spectrumFeature.GET_FEATURES_SUCCESS,
      tagId,
      features
    };
  }
  function failure(error) {
    return { type: machineDetailsConstants.tagChart.spectrumFeature.GET_FEATURES_FAILURE, error };
  }
};

export const createSpectrumFeature = (tagId, params) => {
  return (dispatch) => {
    dispatch(request());

    dispatch(alertActions.clear());

    return machineDetailsService
      .createSpectrumFeature({
        ...params,
        tag_id: tagId
      })
      .then(
        (res) => {
          dispatch(success(res));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: machineDetailsConstants.tagChart.spectrumFeature.CREATE_FEATURE_REQUEST };
  }
  function success(feature) {
    return {
      type: machineDetailsConstants.tagChart.spectrumFeature.CREATE_FEATURE_SUCCESS,
      feature,
      tagId
    };
  }
  function failure(error) {
    return { type: machineDetailsConstants.tagChart.spectrumFeature.CREATE_FEATURE_FAILURE, error };
  }
};

export const updateSpectrumFeature = (tagId, featureId, params) => {
  return (dispatch) => {
    dispatch(request());

    dispatch(alertActions.clear());

    return machineDetailsService.updateSpectrumFeature(featureId, params).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request() {
    return { type: machineDetailsConstants.tagChart.spectrumFeature.UPDATE_FEATURE_REQUEST };
  }
  function success(res) {
    return {
      type: machineDetailsConstants.tagChart.spectrumFeature.UPDATE_FEATURE_SUCCESS,
      feature: res,
      tagId
    };
  }
  function failure(error) {
    return { type: machineDetailsConstants.tagChart.spectrumFeature.UPDATE_FEATURE_FAILURE, error };
  }
};

export const deleteSpectrumFeature = (featureId, tagId) => {
  return (dispatch) => {
    dispatch(request(featureId));
    dispatch(alertActions.clear());

    return machineDetailsService.deleteSpectrumFeature(featureId).then(
      () => {
        dispatch(success(featureId));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request() {
    return { type: machineDetailsConstants.tagChart.spectrumFeature.DELETE_FEATURE_REQUEST };
  }
  function success(featureId) {
    return {
      type: machineDetailsConstants.tagChart.spectrumFeature.DELETE_FEATURE_SUCCESS,
      featureId,
      tagId
    };
  }
  function failure(error) {
    return { type: machineDetailsConstants.tagChart.spectrumFeature.DELETE_FEATURE_FAILURE, error };
  }
};

export const getMachineCompletionStatus = (machine_id) => {
  const request = () => ({ type: machineDetailsConstants.GET_MACHINE_COMPLETION_STATUS_REQUEST });
  const success = completionStatus => ({ type: machineDetailsConstants.GET_MACHINE_COMPLETION_STATUS_SUCCESS, completionStatus, machine_id });
  const failure = error => ({ type: machineDetailsConstants.GET_MACHINE_COMPLETION_STATUS_FAILURE, error, machine_id });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_COMPLETION_STATUS(machine_id))).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      },
    );
  };
};

export const changeFrequency = frequency => (dispatch) => {
  dispatch(change('FeatureForm', 'type', 'individual'));
  dispatch(change('FeatureForm', 'frequency', frequency));
  dispatch(change('FeatureForm', 'frequency_start', null));
  dispatch(change('FeatureForm', 'frequency_end', null));
};

export const changeFrequencyRange = (f1, f2) => (dispatch) => {
  dispatch(change('FeatureForm', 'type', 'range'));
  dispatch(change('FeatureForm', 'frequency', null));
  dispatch(change('FeatureForm', 'frequency_start', f1));
  dispatch(change('FeatureForm', 'frequency_end', f2));
};
