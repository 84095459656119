import { repairConstants } from '../constants/constants';

const initialState = {
  open: false,
  repairId: null,
  openRepairDetails: {}
};

export const repairModal = (state = initialState, action) => {
  switch (action.type) {
    case repairConstants.SET_REPAIR_MODAL_OPEN:
      return {
        ...state,
        open: true,
        repairId: action.repairId
      };

    case repairConstants.SET_REPAIR_MODAL_CLOSE:
      return {
        ...initialState
      };

    case repairConstants.GET_REPAIR_DETAILS_REQUEST:
      return {
        ...state,
        openRepairDetails: {
          loading: true
        }
      };

    case repairConstants.GET_REPAIR_DETAILS_SUCCESS:
      return {
        ...state,
        openRepairDetails: {
          ...action.repair
        }
      };

    case repairConstants.GET_REPAIR_DETAILS_FAILURE:
      return {
        ...state,
        openRepairDetails: {
          loading: false,
          error: action.error
        }
      };

    default:
      return state;
  }
};
