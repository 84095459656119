import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './app/index.css';
import App from './app/App';
import { unregister } from './app/registerServiceWorker';
import { store } from './app/store';
import theme from './common/styles/theme';

unregister();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    release: 'R41.2',
    dsn: process.env.REACT_APP_SENTRY_DSN,
    ignoreErrors: [
      'Non-Error promise rejection captured', 'ResizeObserver loop limit exceeded'
    ],
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(process.env.REACT_APP_TRACE_SAMPLING_RATE),
  });
}

if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'testing') {
  window.store = store;
}

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById('main-view')
);
