import { toastr } from 'react-redux-toastr';

import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse } from 'common/helpers';
import MachineBuilderConstants from '../machineBuilder.constants';
import * as assetDetailsActions from '../../../AssetHierarchy/actions/assetDetails.actions';

export const getMachineBuilderMetadata = () => {
  const request = () => ({ type: MachineBuilderConstants.GET_MACHINES_BUILDER_METADATA_REQUEST });
  const success = data => ({ type: MachineBuilderConstants.GET_MACHINES_BUILDER_METADATA_SUCCESS, data });
  const failure = error => ({ type: MachineBuilderConstants.GET_MACHINES_BUILDER_METADATA_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINES_BUILDER_METADATA))
      .then(
        res => dispatch(success(res)),
        error => dispatch(failure(error.message))
      );
  };
};

export const createMachine = (details) => {
  const request = () => ({ type: MachineBuilderConstants.CREATE_MACHINES_BUILDER_REQUEST });
  const success = data => ({ type: MachineBuilderConstants.CREATE_MACHINES_BUILDER_SUCCESS, data });
  const failure = error => ({ type: MachineBuilderConstants.CREATE_MACHINES_BUILDER_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.post(ENDPOINT.MACHINES(), details)).then(
      (res) => {
        dispatch(success(res));
        dispatch(assetDetailsActions.getHierarchy(false));
        return res;
      },
      (error) => {
        // temp handling in FE for better message
        if (error.message === 'components: Shorter than minimum length 1.') {
          toastr.error('Asset should contain atleast one component');
        } else toastr.error(error.message);
        dispatch(failure(error.message));
        throw error.message;
      }
    );
  };
};

export const createTemplate = (details) => {
  const request = () => ({ type: MachineBuilderConstants.CREATE_MACHINES_BUILDER_REQUEST });
  const success = data => ({ type: MachineBuilderConstants.CREATE_MACHINES_BUILDER_SUCCESS, data });
  const failure = error => ({ type: MachineBuilderConstants.CREATE_MACHINES_BUILDER_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.post(ENDPOINT.CREATE_ASSET_TEMPLATE, details)).then(
      (res) => {
        dispatch(success(res));
        return res;
      },
      (error) => {
        // temp handling in FE for better message
        if (error.message === 'components: Shorter than minimum length 1.') {
          toastr.error('Template should contain atleast one component');
        } else toastr.error(error.message);
        dispatch(failure(error.message));
        throw error.message;
      }
    );
  };
};
