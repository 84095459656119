

import { toastr } from 'react-redux-toastr';
import * as _ from 'lodash';
import { axiosInstance, ENDPOINT } from '../../../common/constants';
import { AccountsTypes } from '../constants/account.constants';

export const getAccountsList = (searchkey, page = 1, all = false, perpage = 80) => (dispatch, getState) => {
  const request = () => ({ type: AccountsTypes.GET_ACCOUNTSLIST_DATA_REQUEST });
  const success = (data, page, total_pages, total_count) => ({
    type: AccountsTypes.GET_ACCOUNTSLIST_DATA_SUCCESS,
    data,
    page,
    total_pages,
    total_count,
  });
  const failure = error => ({ type: AccountsTypes.GET_ACCOUNTSLIST_DATA_FAILURE, error });

  const accountsState = getState().accounts.accountsSorter;
  const params = {
    search_key: searchkey,
    page,
    per_page: perpage,
    all,
  };
  if (!_.isEmpty(accountsState.sorter)) {
    params.order_by = { field: accountsState.sorter.name, direction: accountsState.sorter.order };
  }
  dispatch(request());
  return axiosInstance.get(ENDPOINT.GET_ACCOUNTSLIST, { params }).then(
    (res) => {
      dispatch(success(res.data.items, res.data.page, res.data.total_pages, res.data.total_count));
    },
    error => dispatch(failure(error.message))
  );
};


export const setAccountsSorter = sorter => (dispatch) => {
  dispatch({ type: AccountsTypes.SET_ACCOUNTS_SORTER, sorter });
  dispatch(getAccountsList(null));
};

export const loadMoreAccounts = () => (dispatch, getState) => {
  const accounts = getState().accounts.accountsList;
  const nextPage = accounts.page + 1;
  const hasMoreData = accounts.hasMore;
  if (!hasMoreData || accounts.listLoading) {
    return;
  }
  dispatch({ type: AccountsTypes.INCREASE_ACCOUNTSLIST_PAGE, page: nextPage });
  dispatch(getAccountsList(accounts.searchkey, nextPage));
};


export const addAccount = newAccountData => (dispatch) => {
  axiosInstance.post(ENDPOINT.ADD_ACCOUNT, newAccountData).then(
    (res) => {
      toastr.success('Account created successfully');
      dispatch(getAccountsList(null, 1));
      return res;
    },
    (err) => {
      toastr.error(err.message);
    }
  );
};
