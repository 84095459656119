export default {
  GET_RULES_METADATA: 'GET_RULES_METADATA',
  GET_RULES_METADATA_REQUEST: 'GET_RULES_METADATA_REQUEST',
  GET_RULES_METADATA_SUCCESS: 'GET_RULES_METADATA_SUCCESS',
  GET_RULES_METADATA_FAILURE: 'GET_RULES_METADATA_FAILURE',

  GET_RULES_LIST: 'GET_RULES_LIST',
  GET_RULES_LIST_REQUEST: 'GET_RULES_LIST_REQUEST',
  GET_RULES_LIST_SUCCESS: 'GET_RULES_LIST_SUCCESS',
  GET_RULES_LIST_FAILURE: 'GET_RULES_LIST_FAILURE',

  GET_RULE_DETAILS: 'GET_RULE_DETAILS',
  GET_RULE_DETAILS_REQUEST: 'GET_RULE_DETAILS_REQUEST',
  GET_RULE_DETAILS_SUCCESS: 'GET_RULE_DETAILS_SUCCESS',
  GET_RULE_DETAILS_FAILURE: 'GET_RULE_DETAILS_FAILURE',

  SAVE_RULE_DETAILS: 'SAVE_RULE_DETAILS',
  SAVE_RULE_DETAILS_REQUEST: 'SAVE_RULE_DETAILS_REQUEST',
  SAVE_RULE_DETAILS_SUCCESS: 'SAVE_RULE_DETAILS_SUCCESS',
  SAVE_RULE_DETAILS_FAILURE: 'SAVE_RULE_DETAILS_FAILURE',

  UPDATE_RULE_DETAILS: 'UPDATE_RULE_DETAILS',
  UPDATE_RULE_DETAILS_REQUEST: 'UPDATE_RULE_DETAILS_REQUEST',
  UPDATE_RULE_DETAILS_SUCCESS: 'UPDATE_RULE_DETAILS_SUCCESS',
  UPDATE_RULE_DETAILS_FAILURE: 'UPDATE_RULE_DETAILS_FAILURE',

  DELETE_RULE: 'DELETE_RULE',
  DELETE_RULE_REQUEST: 'DELETE_RULE_REQUEST',
  DELETE_RULE_SUCCESS: 'DELETE_RULE_SUCCESS',
  DELETE_RULE_FAILURE: 'DELETE_RULE_FAILURE',

  REMOVE_RULE_FROM_LIST: 'REMOVE_RULE_FROM_LIST',

  GET_RULES_RESULT_LIST: 'GET_RULES_RESULT_LIST',
  GET_RULES_RESULT_LIST_SUCCESS: 'GET_RULES_RESULT_LIST_SUCCESS',
  GET_RULES_RESULT_LIST_REQUEST: 'GET_RULES_RESULT_LIST_REQUEST',
  GET_RULES_RESULT_LIST_FAILURE: 'GET_RULES_RESULT_LIST_FAILURE',
  DIAGNOSTIC_RULES_TEST_RUN_RESULT_SUCCESS: 'DIAGNOSTIC_RULES_TEST_RUN_RESULT_SUCCESS',
  DIAGNOSTIC_RULES_TEST_RUN_RESULT_REQUEST: 'DIAGNOSTIC_RULES_TEST_RUN_RESULT_REQUEST',
  DIAGNOSTIC_RULES_TEST_RUN_RESULT_FAILURE: 'DIAGNOSTIC_RULES_TEST_RUN_RESULT_FAILURE',

  UPDATE_RULE_RESULT_STATE: 'UPDATE_RULE_RESULT_STATE',
  UPDATE_RULE_RESULT_STATE_REQUEST: 'UPDATE_RULE_RESULT_STATE_REQUEST',
  UPDATE_RULE_RESULT_STATE_SUCCESS: 'UPDATE_RULE_RESULT_STATE_SUCCESS',
  UPDATE_RULE_RESULT_STATE_FAILURE: 'UPDATE_RULE_RESULT_STATE_FAILURE',

  GET_PARAMETERS_TREND: 'GET_PARAMETERS_TREND',
  GET_PARAMETERS_TREND_REQUEST: 'GET_PARAMETERS_TREND_REQUEST',
  GET_PARAMETERS_TREND_SUCCESS: 'GET_PARAMETERS_TREND_SUCCESS',
  GET_PARAMETERS_TREND_FAILURE: 'GET_PARAMETERS_TREND_FAILURE',

  SELECT_RULE: 'SELECT_RULE',
  ADD_NEW_RULE_TO_LIST: 'ADD_NEW_RULE_TO_LIST',
  ADD_RULE_TO_LIST: 'ADD_RULE_TO_LIST',
  NUMBER_OF_RULESS_PER_PAGE: 'NUMBER_OF_RULESS_PER_PAGE',
  SET_RULES_SEARCH_PARAMS: 'SET_RULES_SEARCH_PARAMS',
  CLEAR_RULES_SEARCH_PARAMS: 'CLEAR_RULES_SEARCH_PARAMS',
  CLEAR_RULES: 'CLEAR_RULES',
  SET_RULES_PAGINATER: 'SET_RULES_PAGINATER',
  SET_RULES_SORTER: 'SET_RULES_SORTER',
  NUMBER_OF_RULES_PER_PAGE: 50
};

export const OPERATOR_TYPES = {
  All: 'and',
  Any: 'or'
};

export const OPERATOR_TYPES_REVERSE_MAP = {
  and: 'All',
  or: 'Any'
};

export const COMP_OPTIONS = [{
  text: '>',
  value: '>'
}, {
  text: '<',
  value: '<'
}, {
  text: '<=',
  value: '<='
}, {
  text: '>=',
  value: '>='
}];

export const OPERATOR_OPTIONS = [{
  text: 'All',
  value: 'and'
}, {
  text: 'Any',
  value: 'or'
}];

export const NODE_TYPES = {
  rule: 'rule',
  operator: 'operator'
};

export const TRIGGER_COUNT_OPTIONS = [1, 2, 3, 4];

export const RULE_TYPES = {
  CROSS_LOCATION: 'cross_location',
  SINGLE_LOCATION: 'single_location',
};

export const DIRECTIONS = [
  'vertical',
  'horizontal',
  'axial'
];
