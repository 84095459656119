import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import FlexContainer from 'common/components/atoms/FlexContainer';
import LogoSvg from 'common/images/LogoSvg';
import { history } from 'common/helpers';
import { ENDPOINT } from 'common/constants';
import { sliceString } from 'common/utils';
import colors from 'common/styles/colors';
import SitesDropdown from './SitesDropdown';
import BreadcrumbContainer from './components/atoms/BreadCrumbContainer';
import { getBaseUrl } from '../DomainName/DomainConstants';

const Title = styled.div`
  font-size: 10px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  padding-top: 5px;
  font-family: 'Petasense Open Sans';
  color: ${props => props.disable ? 'gray' : '#3C3D3A'};

  &:hover {
    overflow: visible;
    text-overflow: initial;
  }
`;

const LogoContainer = styled.div`
  margin: 0 20px;
  display: flex;
  align-items: center;
  svg {
    margin-top: 5px;
  }
  cursor: pointer;
`;

const SpeaceBetweenContent = styled(FlexContainer)`
  width: calc(100vw - 60px);
`;

const BreadCrumbHeaderContainer = styled(FlexContainer)`
  border-top: 2px solid ${colors.greyXL}; 
  margin-top:10px;
  padding-top: 5px;
  padding-left:15px;
  width: 100%;
  font-family: 'Petasense Open Sans';
  background-color: white;
`;

const BreadcrumbSection = styled(Breadcrumb.Section)`
  font-family: 'Petasense Open Sans';
  font-size: 12px;
  font-weight: 600;
  color: ${colors.greyXD}; 
  text-overflow: clip;
`;

const BreadcrumbDivider = styled(Breadcrumb.Divider)`
  color: ${colors.greyXD}; 
`;

class BreadCrumbHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      localBreadcrumb: [],
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    this.setTitleAndBreadcrumb();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.location, this.props.location) ||
      !_.isEqual(_.cloneDeep(prevProps.reduxBreadcrumb), _.cloneDeep(this.props.reduxBreadcrumb))
    ) {
      this.setTitleAndBreadcrumb();
    }
  }

  humanize = (str) => {
    if (!str) return '';
    return str.split('-').map(n => n.charAt(0).toUpperCase() + n.slice(1)).join(' ');
  }

  getBreadcrumb = () => {
    const { reduxBreadcrumb } = this.props;
    const breadcrumb = [];
    if (reduxBreadcrumb.area.id && reduxBreadcrumb.selected.type !== 'area') {
      breadcrumb.push(reduxBreadcrumb.area.name);
    }
    if (reduxBreadcrumb.machine.id && reduxBreadcrumb.selected.type !== 'machine') {
      breadcrumb.push(reduxBreadcrumb.machine.name);
    }
    if (reduxBreadcrumb.machine_charts.id && reduxBreadcrumb.selected.type !== 'machine_charts') {
      breadcrumb.push(reduxBreadcrumb.machine_charts.name);
    }
    if (reduxBreadcrumb.component.id && reduxBreadcrumb.selected.type !== 'component') {
      breadcrumb.push(reduxBreadcrumb.component.name);
    }
    if (reduxBreadcrumb.location.id && reduxBreadcrumb.selected.type !== 'location') {
      breadcrumb.push(reduxBreadcrumb.location.name);
    }
    return breadcrumb;
  }

  setTitleAndBreadcrumb = () => {
    const { location, reduxBreadcrumb, adminDashboard } = this.props;
    const paths = location.pathname.split('/');
    if (location.pathname === '/') paths[1] = 'dashboard';
    let title = reduxBreadcrumb.selected.type !== 'site' ? reduxBreadcrumb.selected.name : '';
    const breadcrumb = this.getBreadcrumb();
    if (!title || adminDashboard.adminOpen) title = paths[1];
    this.setState({ title: this.humanize(title), localBreadcrumb: this.props.breadCrumb ? this.props.breadCrumb : breadcrumb });
  };

  onLogoClick = () => {
    const { reduxBreadcrumb } = this.props;
    history.push('/');
    if (reduxBreadcrumb.site.id) this.props.handleNodeClick(reduxBreadcrumb.site.id, 'site');
  }

  getLogoUrl = () => (
    `${getBaseUrl()}/${ENDPOINT.ACCOUNT_LOGO}?name=${this.props.logoUrl}`
  );


  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  render() {
    const {
      accountsArray,
      user: { user },
      editMode,
      handleLogoClick,
      reduxBreadcrumb,
      checkNewSummaryExists,
      setAnalystSummaryWarning,
      discardAnalystSummary,
      setDiscardAnalystSummary,
      adminDashboard,
      logoUrl
    } = this.props;
    const { localBreadcrumb, title } = this.state;
    const { windowWidth } = this.state;
    const showMinimizedView = reduxBreadcrumb.machine.id && windowWidth < 1300;
    return (
      <>
        <BreadcrumbContainer>
          <Breadcrumb>
            <FlexContainer>
              <LogoContainer onClick={() => handleLogoClick(reduxBreadcrumb.site.id, 'site')}>
                {logoUrl ? (
                  <img height="25px" src={this.getLogoUrl()} alt="" />
                ) : (
                  <LogoSvg />
                )}
              </LogoContainer>
              <SpeaceBetweenContent justifyContent="space-between">
                <FlexContainer direction="column" width="45%">
                  {!adminDashboard.adminOpen && (
                    <FlexContainer>
                      {!showMinimizedView && (
                        <BreadcrumbSection>
                          {accountsArray && accountsArray.length > 0 && title !== 'Profile' && (
                            <SitesDropdown
                              checkNewSummaryExists={checkNewSummaryExists}
                              setAnalystSummaryWarning={setAnalystSummaryWarning}
                              discardAnalystSummary={discardAnalystSummary}
                              setDiscardAnalystSummary={setDiscardAnalystSummary}
                            />
                          )}
                          {title === 'Profile' && `${user.first_name} ${user.last_name}`}
                        </BreadcrumbSection>
                      )}
                      {this.props.breadCrumb && this.props.accountsName && (
                        <BreadcrumbSection title={this.props.accountsName}>
                          {sliceString(this.props.accountsName, 15)}
                        </BreadcrumbSection>
                      )}
                      {!showMinimizedView && localBreadcrumb.length > 0 && (
                        <>
                          {localBreadcrumb.map((path, index) => (
                            <div key={index}>
                              <BreadcrumbDivider icon="right angle" />
                              <BreadcrumbSection title={path}>
                                {(index === localBreadcrumb.length - 1) ? (
                                  <div>{path}</div>
                                ) : (
                                  <div>{sliceString(path, 20)}</div>
                                )}
                              </BreadcrumbSection>
                            </div>
                          ))}
                        </>
                      )}
                    </FlexContainer>
                  )}
                  <Title disable={editMode}>
                    {title}
                  </Title>
                </FlexContainer>
              </SpeaceBetweenContent>
            </FlexContainer>
          </Breadcrumb>
          {showMinimizedView && localBreadcrumb.length > 0 && (
            <BreadCrumbHeaderContainer>
              <BreadcrumbSection>
                {accountsArray && accountsArray.length > 0 && title !== 'Profile' && (
                  <SitesDropdown
                    checkNewSummaryExists={checkNewSummaryExists}
                    setAnalystSummaryWarning={setAnalystSummaryWarning}
                    discardAnalystSummary={discardAnalystSummary}
                    setDiscardAnalystSummary={setDiscardAnalystSummary}
                  />
                )}
                {title === 'Profile' && `${user.first_name} ${user.last_name}`}
              </BreadcrumbSection>
              {localBreadcrumb.map(path => (
                <>
                  <BreadcrumbDivider icon="right angle" />
                  <BreadcrumbSection title={path}>
                    {sliceString(path, 45)}
                  </BreadcrumbSection>
                </>
              ))}
            </BreadCrumbHeaderContainer>
          )}
        </BreadcrumbContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { machineDetails, sensors, machines, events, breadcrumb, user, adminDashboard, currentAccount, companyReducer } = state;
  return {
    machineDetails,
    sensors,
    machines,
    breadcrumb,
    events,
    reduxBreadcrumb: breadcrumb,
    user,
    adminDashboard,
    logoUrl: currentAccount && currentAccount.logo_url,
    companyReducer
  };
};

export default connect(mapStateToProps)(BreadCrumbHeader);
