import React, { Fragment } from 'react';
import styled from 'styled-components';

import _ from 'lodash';
import { chartColors } from 'common/constants';
import FlexContainer from 'common/components/atoms/FlexContainer';
import { humanize } from 'common/helpers/humanize';
import { formatDate, toFixedXIfNumber } from '../../../utils';
import Text from '../../../typography/Text/Text';
import SelectionContainer from '../atoms/SelectionContainer';
import ColoredCircle from '../atoms/ColoredCircle';

const UpperText = styled(Text)`
  display: block;
  font-size: 10px;
  font-weight: 600;
`;

const ValueText = styled(Text)`
  font-size: 10px;
  color: ${props => props.theme.colors.darkGray};
  margin-right: 5px;
`;

const getTitleToDisplay = (type, selections, xUnit, spectrumFreqUnit, shaftSpeed) => {
  switch (type) {
    case 'spectrum':
      return getSpectrumTitle(selections, xUnit, spectrumFreqUnit, shaftSpeed);
    case 'demod_spectrum':
      return `Frequency: ${toFixedXIfNumber(selections[0].x)} ${xUnit}`;
    case 'waveform':
      return `Time: ${toFixedXIfNumber(selections[0].x)} ms`;
    default:
      return formatDate(selections[0].x);
  }
};

const getSpectrumTitle = (selections, xUnit, spectrumFreqUnit, shaftSpeed) => {
  if (xUnit === 'Orders' && shaftSpeed) return `Frequency: ${toFixedXIfNumber(selections[0].x * shaftSpeed)} ${spectrumFreqUnit} (${toFixedXIfNumber(selections[0].x)} ${xUnit})`;
  return `Frequency: ${toFixedXIfNumber(selections[0].x)} ${xUnit}`;
};

const Selection = ({
  selections,
  type,
  spectrumFreqUnit,
  shaftSpeed,
  xUnit,
  yUnits,
  colors,
  yParameters,
  yParametersReference,
  config
}) => (
    <SelectionContainer>
      <FlexContainer direction="column">
        <UpperText>{getTitleToDisplay(type, selections, xUnit, spectrumFreqUnit, shaftSpeed)}</UpperText>
        <FlexContainer direction="row" flexWrap="wrap" padding="0 4em" justifyContent="center">
          {selections.map((selection, idx) => (
            <FlexContainer direction="row" alignItems="center" padding="0 2px">
              {_.isNumber(selection.dataIdx) ? (
                <>
                  <ColoredCircle color={colors[selection.dataIdx] || chartColors[selection.dataIdx]} />
                  <ValueText className="machine-name">
                    {config && config.machines.length > 1 ? (`${config.trends_config[selection.dataIdx].machine_name} ` || '') : ''}
                  </ValueText>
                </>
              ) : ''}
              <ValueText>
                {`${humanize(yParameters[idx])}: `}
                {toFixedXIfNumber(selection.realY || selection.y)}{' '}
                {yUnits[idx] == null ? 'mm/s' : yUnits[idx]}
              </ValueText>
              {yParametersReference && yParametersReference[idx] && (
                <ValueText>
                  {`( ${toFixedXIfNumber(yParametersReference[idx])} ${yUnits[idx]} )`}
                </ValueText>
              )}
            </FlexContainer>
          ))}
        </FlexContainer>
      </FlexContainer>
    </SelectionContainer>
);

export default Selection;
