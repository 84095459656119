import React from 'react';
import styled from 'styled-components';

import * as _ from 'lodash';
import colors from 'common/styles/colors';
import FlexContainer from '../../atoms/FlexContainer';
import { formatDate, toFixedXIfNumber } from '../../../utils';
import Text from '../../../typography/Text/Text';

import { mapUtilizationStatus } from '../../../../home/Machines/MachineDetails/MachineCharts/machineCharts.constants';


const SelectionContainer = styled(FlexContainer).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})`
  flex-basis: 33.33%;
  line-height: 14px;
  position: relative;
  padding: 0.5em 0;
  ${props => props.header && 'right: 40px;'}
  max-height: 50px;
  margin-bottom: -0.5em;
`;

const UpperText = styled(Text)`
  font-size: 10px;
  font-weight: 600;
`;

const ValueText = styled(Text)`
  font-size: 10px;
  color: ${props => props.theme.colors.darkGray};
  margin-right: 5px;
`;

const FilterContainer = styled.div`
  position: absolute;
  right: 0;
`;

const InfoContainer = styled.div`
  align-self: center;
  align-text: center;
`;

const ColoredCircle = styled.div`
  position: relative;
  top: -1px;
  background-color: ${props => props.color || props.theme.colors.default};
  border-radius: 50%;
  display: inline-block;
  margin: 0 0.4em;
  width: 0.33em;
  height: 0.33em;
`;


const getFilterDisplay = (filterSettings) => {
  if (!filterSettings) return null;
  const lowerFilter = filterSettings.lowerFilter ?
    `${filterSettings.lowerFilter} ${filterSettings.units}` :
    'Lower filter not set';

  const upperFilter = filterSettings.upperFilter ?
    `${filterSettings.upperFilter} ${filterSettings.units}` :
    'Upper filter not set';

  return (
    <>
      <ValueText> {filterSettings.filterTitle} </ValueText>
      <UpperText> {lowerFilter} - {upperFilter} </UpperText>
    </>
  );
};

const Selection = ({
  data,
  initialXunit,
  shaftSpeed,
  toFixedDecimalPoints,
  header,
  filterSettings,
  title
}) => {
  let titleTodisplay = `${data.xTitle}: ${toFixedXIfNumber(data.x)} ${data.xUnit}`;
  if (title === 'Cepstrum') titleTodisplay += ` (Frequency: ${toFixedXIfNumber(1000 / data.x)} Hz)`;
  if (data && data.xUnit === 'Orders' && initialXunit && shaftSpeed) {
    titleTodisplay = `${data.xTitle}: ${toFixedXIfNumber(data.x * shaftSpeed)} ${initialXunit} (${toFixedXIfNumber(data.x)} ${data.xUnit})`;
  }
  return (
    <>
      {data.x !== undefined && (
        <SelectionContainer header={header}>
          <InfoContainer>
            <UpperText>
              {data.isDate ? (
                formatDate(data.x)
              ) : (
                titleTodisplay
              )}
            </UpperText>
            {data.y !== undefined && data.y !== null && (
              <>
                <ColoredCircle color={data.color} />
                <ValueText>
                  {data.yTitle}: {data.yTitle === 'Status' ? mapUtilizationStatus[data.y] : toFixedXIfNumber(data.y, toFixedDecimalPoints)} {data.yUnit}
                </ValueText>
                <ValueText>
                {(data.speed && data.speed >= 0) ? `Speed: ${data.speed.toFixed(2)} ${data.speedUnit}` : ''}
                </ValueText>
              </>
            )
            }
            {_.isNumber(data.envelopeAmplitude) && (
              <>
                <ColoredCircle color={colors.alarmCritical} />
                <ValueText>
                  {`Envelope Amplitude: ${data.envelopeAmplitude.toFixed(2)} ${data.yUnit}`}
                </ValueText>
              </>
            )}
            {data.anomaly_score && (
              <>
                <ColoredCircle color={data.anomaly_result !== 1 ? colors.red : colors.green} />
                <ValueText>
                  {`Anomaly Score: ${data.anomaly_score}`}
                </ValueText>
              </>
            )}
          </InfoContainer>
          {filterSettings && (
            <FilterContainer>
              {getFilterDisplay(filterSettings)}
            </FilterContainer>
          )}
        </SelectionContainer>
      )
      }
    </>
  );
};


export default Selection;
