import React from 'react';
import PropTypes from 'prop-types';
import { SEVERITY_CSS_CLASS_MAP } from '../constants';
import { timeSince } from '../helpers';

const SeverityMark = ({ machine, showTimeInfo, ...props }) => (
  <span
    className={
      `${props.className} ${
        SEVERITY_CSS_CLASS_MAP[machine.condition || machine.state]
      }`}
    title={!showTimeInfo && timeSince(machine.condition_since)}
  >
    {showTimeInfo ? timeSince(machine.condition_since) : ''}
  </span>
);

SeverityMark.propTypes = {
  showTimeInfo: PropTypes.bool
};

SeverityMark.defaultProps = {
  showTimeInfo: true
};

export default SeverityMark;
