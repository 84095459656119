import { machinesAnalyticsConstants } from './machinesAnalytics.constants';

export const machinesAnalyticsReducer = (state = {}, action) => {
  switch (action.type) {
    case machinesAnalyticsConstants.GET_MACHINES_ANALYTICS_DATA_REQUEST:
      return {
        ...state,
        [action.analytics_type]: {
          loading: true
        }
      };
    case machinesAnalyticsConstants.GET_MACHINES_ANALYTICS_DATA_SUCCESS: {
      return {
        ...state,
        [action.analytics_type]: {
          ...state[action.analytics_type],
          loading: false,
          data: action.data,
          filterOptions: action.filterOptions
        },
        total_num: action.total_num
      };
    }
    case machinesAnalyticsConstants.GET_MACHINES_ANALYTICS_DATA_FAILURE: {
      return {
        ...state,
        [action.analytics_type]: {
          error: action.error.message,
          loading: false
        }
      };
    }
    default:
      return state;
  }
};
