import breadcrumbConstants from '../breadcrumb.constants';

const breadcrumbInitialState = {
  site: {
    name: '',
    id: null
  },
  area: {
    name: '',
    id: null
  },
  machine: {
    name: '',
    id: null
  },
  machine_charts: {
    name: '',
    id: null
  },
  component: {
    name: '',
    id: null
  },
  location: {
    name: '',
    id: null
  },
  config: {
    name: '',
    id: null
  },
  selected: {
    name: '',
    id: null,
    type: ''
  },
};

export const breadcrumb = (state = breadcrumbInitialState, action) => {
  switch (action.type) {
    case breadcrumbConstants.UPDATE_BREADCRUMB:
      return {
        ...state,
        ...action.params
      };
    default:
      return state;
  }
};
