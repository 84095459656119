import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import Label from 'common/typography/Label/Label';
import Button from 'common/components/atoms/Button';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Modal from 'common/components/organisms/ModalV2';
import InputField from 'common/components/atoms/InputField';
import {
  DEVICE_TYPES,
  FIRMWARE_TYPES,
  ASSOCIATION_TYPES,
  firmwareDeploymentConstants
} from '../constants/firmwareDeployment.constants';

const DeploymentModalContainer = styled(FlexContainer)`
  line-height: 0.2em;
`;

class UpdateDeploymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firmwareId: this.props.details.association_type === 'devices' ? this.props.details.target_firmware_id : this.props.details.target_firmware_version,
      associations: this.props.details.association_values.map(assoc => assoc.id)
    };
  }

  renderFirmwareOptions = () => {
    const { details, metadata } = this.props;

    const firmwares = metadata.firmwares.map(
      fw => ({ value: fw.id, text: fw.version, model_version: fw.model_version })
    ) || [];

    return firmwares.filter((fw) => {
      if (details.device_type === firmwareDeploymentConstants.DEVICE_TYPE_MOTE) {
        return firmwareDeploymentConstants.MOTE_MODEL_VERSION === fw.model_version;
      }
      return firmwareDeploymentConstants.TX_MODEL_VERSION === fw.model_version;
    });
  }

  renderDeviceOptions = () => {
    const {
      firmwareId,
    } = this.state;
    const firmware = firmwareId && this.props.metadata.firmwares.find(it => it.id === firmwareId);
    const { details } = this.props;
    if (!firmware) return [];

    return _.filter(
      details.device_type === 'tx' ? this.props.metadata.tx : this.props.metadata.motes,
      device => (_.isEmpty(firmware.account_ids) || _.includes(firmware.account_ids, device.account_id)) && device.model === firmware.model_version
    ).map(
      device => ({ value: device.id, text: device.serial_number })
    );
  }

  updateDisabled = () => {
    const propAssoctions = this.props.details.association_values.map(assoc => assoc.id);
    const propFirmwareId = this.props.details.association_type === 'devices' ? this.props.details.target_firmware_id : this.props.details.target_firmware_version;
    return _.isEqual(propAssoctions, this.state.associations) && _.isEqual(propFirmwareId, this.state.firmwareId);
  }

  render() {
    const {
      close,
      update,
      details
    } = this.props;

    const {
      firmwareId,
      associations
    } = this.state;

    return (
    <Modal
      width="30%"
      close={close}
    >
      <DeploymentModalContainer direction="column" marginbottom="40px" margintop="40px">
        <FlexContainer direction="column" alignItems="left" marginbottom="40px">
          <Label fontWeight="300" fontSize="20px" marginBottom="8px">Update Deployment</Label>
        </FlexContainer>
          <InputField
            label="Deployment Name"
            maxLength={50}
            placeholder="Enter deployment name"
            type="text"
            value={details.name}
            disabled
          />
          <InputField
            label="Device Type"
            maxLength={50}
            placeholder="Click to select device type"
            value={details.device_type}
            type="select"
            options={DEVICE_TYPES}
            disabled
          />
          <InputField
            label="Firmware Type"
            maxLength={50}
            placeholder="Click to select Firmware type"
            type="select"
            value={details.target_firmware_type}
            options={FIRMWARE_TYPES}
            disabled
          />
          {details.target_firmware_type && details.association_type === 'devices' ? (
            <InputField
              label="Target Firmware Version"
              maxLength={50}
              onChange={(e, d) => this.setState({ firmwareId: d.value })}
              placeholder="Click to select target firmware version"
              type="select"
              search
              value={this.state.firmwareId}
              options={this.renderFirmwareOptions()}
            />
          ) :
            (
            <InputField
              label="Target Firmware Version"
              maxLength={50}
              placeholder="Click to select target firmware version"
              type="text"
              value={this.state.firmwareId}
              disabled
            />
            )
        }
          {details.target_firmware_version && (
            <InputField
              label="Association Type"
              maxLength={50}
              placeholder="Click to select target firmware version"
              type="select"
              value={details.association_type}
              options={ASSOCIATION_TYPES}
              disabled
            />
          )}

          {details.association_type === 'devices' && (
            <InputField
              label="Associated Devices"
              onChange={(e, d) => this.setState({ associations: d.value })}
              placeholder="Associated Devices"
              multiple
              type="select"
              search
              value={this.state.associations}
              options={this.renderDeviceOptions()}
            />
          )}
          {details.association_type === 'firmwares' && (
            <InputField
              label="Associated Firmwares"
              onChange={(e, d) => this.setState({ associations: d.value })}
              placeholder="Associated Firmwares"
              multiple
              type="select"
              search
              value={this.state.associations}
              options={this.renderFirmwareOptions()}
            />
          )}
        <FlexContainer justifyContent="flex-end">
          <Button onClick={close} cancel margin="0 20px 0 0">Close</Button>
          <Button
            onClick={() => update(associations, firmwareId)}
            disabled={this.updateDisabled()}
          >
            Update
          </Button>
        </FlexContainer>
      </DeploymentModalContainer>
    </Modal>
    );
  }
}


UpdateDeploymentModal.propTypes = {
  update: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired
};

export default UpdateDeploymentModal;
