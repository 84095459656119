import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { validateAndSwitchAccountWithCallback } from 'home/AssetHierarchy/actions/assetDetails.actions';
import styled, { withTheme } from 'styled-components';
import { FlexContainer } from '../components/atoms';
import { humanize } from '../helpers';

const ChartContainer = styled(FlexContainer).attrs({
  alignItems: 'center',
  direction: 'column'
})`
  height: 100%;
`;

const TITLE_HEIGHT = 40;
const Title = styled.div`
  color: ${props => props.theme.colors.darkGray};
  font-weight: 600;
  width: 100%;
  padding: 1em 0 0 1em;
  height: ${TITLE_HEIGHT}px;
`;

const MetricContainer = styled(FlexContainer).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})`
  color: ${props => props.color};
  padding: ${props => props.padding};
  ${props => props.theme.fonts.heavyTitle}

  ${props => props.isInteractive && `
    cursor: pointer;
  `}

  .metric-name {
    font-size: ${props => props.labelSize}px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .metric-value {
    font-size: ${props => props.valueSize}px;
    text-align: center;
  }
`;

function Metrics({ title, data, theme, valueSize, labelSize, padding, handleClick, isInteractive, switchAccount }) {
  return (
    <ChartContainer>
      {title && <Title className="title">{title}</Title>}
      <FlexContainer height="100%" width="100%" direction="column" justifyContent="center" alignItems="flex-start">
        {Object.keys(data).map((key, idx) => (
          <MetricContainer
            key={idx}
            color={idx % 2 === 0 ? theme.colors.greyXXD : theme.colors.greyXD}
            valueSize={valueSize}
            labelSize={labelSize}
            padding={padding}
            isInteractive={isInteractive}
            onClick={handleClick ? () => handleClick(key, switchAccount) : () => {}}
          >
            <span className="metric-name">{humanize(key)}</span>&nbsp;:&nbsp;
            <span className="metric-value">{data[key]}</span>
          </MetricContainer>
        ))}
      </FlexContainer>
    </ChartContainer>
  );
}

Metrics.propTypes = {
  valueSize: PropTypes.number,
  labelSize: PropTypes.number,
  padding: PropTypes.string,
};

Metrics.defaultProps = {
  valueSize: 16,
  labelSize: 16,
  padding: '8px 14px',
};

const mapDispatchToProps = dispatch => ({
  switchAccount: bindActionCreators(validateAndSwitchAccountWithCallback, dispatch)
});

export default connect(null, mapDispatchToProps)(withTheme(Metrics));
