import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Modal from 'common/components/organisms/Modal';
import { FlexContainer } from 'common/components/atoms';
import Button_T from 'common/components/atoms/Button';
import InputField from 'common/components/atoms/InputField';
import LoadingSvg from 'common/images/LoadingSvg';
import * as machineInfoActions from 'home/Machines/MachineDetails/MachineInfo/actions/machineInfo.actions';
import H2 from 'common/typography/H2/H2';
import { COMPONENT_TYPE_UPDATE } from '../../constants/NodeDetails.constants';

const ButtonContainer = styled(FlexContainer)`
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
`;

const Button = styled(Button_T)`
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-top: 0;
`;

class ComponentTypeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      componentTypeValue: '',
      loading: false
    };
  }

  getDropdownOptions = () => {
    const {
      componentTypeText,
      rotatingComponentTypes
    } = this.props;
    let options;
    rotatingComponentTypes.forEach((type) => {
      const required_type = type.children.find(
        child => child.text === componentTypeText
      );
      if (!required_type) return;
      const component_type = required_type.component_type;
      options = type.children.filter(child =>
        child.component_type === component_type &&
        child.text !== componentTypeText
      );
    });
    return options;
  }

  typeChangeHandler = (e, data) => {
    this.setState({ componentTypeValue: data.value });
  }

  onSave = () => {
    const { componentId } = this.props;
    const { componentTypeValue } = this.state;
    const params = {
      component_type: componentTypeValue
    };

    this.setState({ loading: true });
    this.props.machineInfoActions.updateComponentInfo(componentId, params).then(
      () => {
        toastr.success('Component info updated');
        this.setState({ loading: false });
        this.props.machineInfoActions.getMachineInfo(this.props.machineId);
        this.props.closeModal();
      },
      (error) => {
        toastr.error(error);
        throw error;
      }
    );
  }

  render() {
    const {
      closeModal
    } = this.props;

    const {
      componentTypeValue,
      loading
    } = this.state;

    return (
      <Modal
        id="component-type-modal"
        title={(
          <H2>{COMPONENT_TYPE_UPDATE.confirmation}</H2>
        )}
        close={closeModal}
        width="40%"
        padding="0"
        outerPadding="0 1.5rem 1.5rem 1.5rem"
      >
        <InputField
          label={COMPONENT_TYPE_UPDATE.select_req}
          type="select"
          name="component_type"
          options={this.getDropdownOptions()}
          placeholder="Select"
          onChange={this.typeChangeHandler}
        />
        <ButtonContainer>
          <Button disabled={componentTypeValue === ''} onClick={this.onSave}>
            {loading ? <LoadingSvg fill="white" width="2.5rem" /> : 'Save'}
          </Button>
          <Button text onClick={closeModal} alignVertical>
            Cancel
          </Button>
        </ButtonContainer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  machineId: state.machineDetails.details.object.id
});

const mapDispatchToProps = dispatch => ({
  machineInfoActions: bindActionCreators(machineInfoActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ComponentTypeModal);
