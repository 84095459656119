import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import H1 from 'common/typography/H1/H1';
import { Copy, Check } from 'common/images/FaIcons';
import { toastr } from 'react-redux-toastr';

const TaskId = styled.span`
  ${props => props.theme.fonts.selectOption}
  color: ${props => props.theme.primaryColor};
  margin-left: 12px;
  padding: 4px;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.primaryColor};
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease;
  
  > span {
    position: relative;
    
    &:before {
      content: '#';
      margin-right: 3px;
      color: ${props => props.theme.primaryColor};
    }
  }

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);

    ${CopyButton} {
      opacity: 1;
    }
  }
`;

const CopyButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.primaryColor};
  opacity: 0.7;
  transition: all 0.2s ease;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 14px;
    height: 14px;
  }
`;

const TaskIdWithCopy = ({ id }) => {
  const [copied, setCopied] = React.useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(id.toString());
      setCopied(true);
      toastr.success('Task ID copied to clipboard');
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (error) {
      // Fallback for browsers that don't support clipboard API
      const textArea = document.createElement('textarea');
      textArea.value = id.toString();
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        setCopied(true);
        toastr.success('Task ID copied to clipboard');
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (err) {
        toastr.error('Failed to copy Task ID');
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <TaskId>
      <span>{id}</span>
      <CopyButton
        onClick={handleCopy}
        title="Copy Task ID"
        type="button"
        aria-label={copied ? 'Copied!' : 'Copy Task ID'}
      >
        {copied ? <Check /> : <Copy />}
      </CopyButton>
    </TaskId>
  );
};

const TaskTitleWithId = ({ isEdit, taskId, machineName }) => (
  <H1>
    <span>
      {`${machineName} > Tasks > `}
      {isEdit ? 'Edit Task' : 'Create Task'}
      {isEdit && taskId && (
        <TaskIdWithCopy id={taskId} />
      )}
    </span>
  </H1>
);

TaskTitleWithId.propTypes = {
  isEdit: PropTypes.bool,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  machineName: PropTypes.string.isRequired
};

TaskTitleWithId.defaultProps = {
  isEdit: false,
  taskId: null
};

export default TaskTitleWithId;
