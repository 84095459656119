export default {
  GET_ANALYST_SUMMARY_REQUEST: 'GET_ANALYST_SUMMARY_REQUEST',
  GET_ANALYST_SUMMARY_SUCCESS: 'GET_ANALYST_SUMMARY_SUCCESS',
  GET_ANALYST_SUMMARY_FAILURE: 'GET_ANALYST_SUMMARY_FAILURE',

  POST_ANALYST_SUMMARY_REQUEST: 'POST_ANALYST_SUMMARY_REQUEST',
  POST_ANALYST_SUMMARY_SUCCESS: 'POST_ANALYST_SUMMARY_SUCCESS',
  POST_ANALYST_SUMMARY_FAILURE: 'POST_ANALYST_SUMMARY_FAILURE',

  PUT_ANALYST_SUMMARY_REQUEST: 'PUT_ANALYST_SUMMARY_REQUEST',
  PUT_ANALYST_SUMMARY_SUCCESS: 'PUT_ANALYST_SUMMARY_SUCCESS',
  PUT_ANALYST_SUMMARY_FAILURE: 'PUT_ANALYST_SUMMARY_FAILURE',

  EDIT_EXISTING_ANALYST_SUMMARY: 'EDIT_EXISTING_ANALYST_SUMMARY',
};
