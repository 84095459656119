import * as _ from 'lodash';
import InstrumentationAlertTypes from '../actions/instrumentationAlerts.types';

const intialState = {
  loading: true,
  loadingDetails: false,
  list: [],
  nextPage: 1,
  error: null,
  hasMore: true
};
export default (state = intialState, action) => {
  switch (action.type) {
    case InstrumentationAlertTypes.GET_INSTRUMENTATION_ALERTS_REQUEST:
      return {
        ...state,
        list: action.newContextInstrumentationAlerts ? [] : [...state.list],
        loading: true
      };
    case InstrumentationAlertTypes.GET_INSTRUMENTATION_ALERTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.events
      };
    case InstrumentationAlertTypes.GET_INSTRUMENTATION_ALERTS_FAILURE:
      return {
        ...state,
        list: [],
        loading: false,
        error: action.error.message
      };
    case InstrumentationAlertTypes.UPDATE_ALERT_CLOSE_REQUEST:
      return {
        ...state
      };
    case InstrumentationAlertTypes.UPDATE_ALERT_CLOSE_SUCCESS:
      return {
        ...state,
        list: _.map(state.list, (alert) => {
          if (action.payload.find(a => a.sensor_number === alert.sensor_number)) {
            if (action.action === 'close') alert.state = 'close';
            else if (action.action === 'open') alert.state = 'open';
          }
          return alert;
        })
      };
    case InstrumentationAlertTypes.UPDATE_ALERT_CLOSE_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
};
