import { activityConstants } from '../constants/activity.constants';

const initialState = {
  unreadCount: null,
  loading: false,
};

export const activityUnreadCount = (state = initialState, action) => {
  switch (action.type) {
    case activityConstants.GET_ACTIVITY_UNREAD_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        unreadCount: null
      };

    case activityConstants.GET_ACTIVITY_UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        unreadCount: action.payload.unread_count,
        loading: false,
      };

    case activityConstants.GET_ACTIVITY_UNREAD_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
