import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import RibbonItem from 'common/components/atoms/RibbonItem';
import { history } from 'common/helpers/history';
import { ChevronDown as ChevronDown_T } from 'common/images/FaIcons';
import InlineModal from 'common/components/molecules/InlineModal';
import OutsideAlerter from 'common/OutsideAlerter';

const ChevronDown = styled(ChevronDown_T)`
  margin-left: 5px;
`;

const MoreDropdownTab = ({ activeIndex, items }) => {
  const [itemMenuOpen, setItemMenuOpen] = useState(false);

  return (
    <Menu.Item
      className="more-tab"
      onClick={() => setItemMenuOpen(true)}
      active={activeIndex >= 0}
    >
      More
      <ChevronDown size="xs" />
      {itemMenuOpen && (
        <InlineModal noOverlay minWidthInline="120px" marginTop="5px" noScroll close={() => setItemMenuOpen(false)}>
          <OutsideAlerter event="click" open handleClick={() => setItemMenuOpen(false)}>
            <div onClick={(e) => { setItemMenuOpen(false); e.stopPropagation(); }}>
              {items.map((item, idx) => (
                <RibbonItem
                  key={item.key}
                  selected={idx === activeIndex}
                  name={item.key}
                  fontSize="14px"
                  width="max-content"
                  onClick={() => history.push(item.path)}
                >
                  {item.title}
                </RibbonItem>
              ))}
            </div>
          </OutsideAlerter>
        </InlineModal>
      )}
    </Menu.Item>
  );
};

MoreDropdownTab.propTypes = {
  items: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired
};

export default MoreDropdownTab;
