import styled from 'styled-components';
import FlexContainer from 'common/components/atoms/FlexContainer';
import colors from 'common/styles/colors';

const Error = styled(FlexContainer)`
  font-family: 'Petasense Open Sans';
  font-size: 12px;
  color: ${colors.error};
  padding: 2em;
  ${props => props.padding && `padding: ${props.padding}`}
`;
export default styled(Error)``;
