import styled from 'styled-components';
import FlexContainer from 'common/components/atoms/FlexContainer';

const StatsContainer = styled(FlexContainer).attrs({
  direction: 'column',
  justifyContent: 'center',
  paddingleft: '1.2rem',
  paddingtop: '1rem',
  paddingbottom: '1rem',
  width: '318px',
  backgroundColor: 'white',
  borderRadius: '4px'
})``;

export default styled(StatsContainer)``;
