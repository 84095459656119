import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FlexContainer } from 'common/components/atoms';
import MoreOptions from 'common/components/organisms/MoreOptions';
import RibbonItem from 'common/components/atoms/RibbonItem';
import * as taskActions from 'home/HomePage/actions/tasks.actions';
import colors from 'common/styles/colors';
import UnreachableMark from 'common/OnboardingComponents/UnreachableMark';
import { localTimeString } from '../../../common/helpers';
import SeverityMark from '../../../common/OnboardingComponents/SeverityMark';
import ListItem_T from '../../../common/components/atoms/ListItem';
import { MACHINE_STATE } from '../../../common/constants';
import { getHSColor } from '../utils';
import ReasonItem from './ReasonItem';
import { ARA_ITEM_OPTIONS } from '../machines.constants';


const Td = styled.td`
  ${props => props.color && `color: ${props.color};`}
  ${props => props.centered && 'text-align: center;'}
`;

const ListItem = styled(ListItem_T)`
  ${props => props.attention_machine_viewed && `
    background: #F7F8F6;
    color: #afafae;
  `}
  ${props => props.backgroundColor && `
    background-color: ${props.backgroundColor}
  `}
`;

class MachineItem extends Component {
  render() {
    const {
      item,
      columnSizes,
      onMachineSelect,
      assetConditionLevels,
      machinesRequiringAttention,
      taskActions
    } = this.props;

    const openModal = (type) => {
      switch (type) {
        case 'task':
          taskActions.openTaskModal(
            null,
            { machine_id: item.id,
              machine_name: item.name,
              ara_id: item.ara_id,
              allowed_triggers: Object.keys(
                item.reason_for_attention
              ).filter(r => r !== 'open_tasks')
            }
          );
          break;
        default:
          break;
      }
    };

    return (
      <>
        <table width="100%">
        <ListItem
          className="machine-list-item"
          attention_machine_viewed={item.attention_machine_viewed}
          showBorderBottom={!machinesRequiringAttention}
        >
          <Td width={`${columnSizes[0]}%`} onClick={() => onMachineSelect(item.id)}>
            <Link id="machine-name" className="ps-hover-pointer" to={`/machines/${item.id}`}>
              <FlexContainer alignItems="center">
                <SeverityMark machine={item} showTimeInfo={false} />
                { item.has_unreachable_sensors && <UnreachableMark marginRight="7px" />}
                {item.name}
              </FlexContainer>
            </Link>
          </Td>
          <Td width={`${columnSizes[1]}%`}>
            {item.area_path ? item.area_path : '--'}
          </Td>
          <Td width={`${columnSizes[2]}%`} centered>
            {MACHINE_STATE[item.current_state] || 'NA'}
          </Td>
          <Td width={`${columnSizes[3]}%`} className="machine-info-container" centered>
            {localTimeString(item.last_measurement_time)}
          </Td>
          <Td width={`${columnSizes[4]}%`} centered>
          {item.setup_completion_percent !== null ? `${item.setup_completion_percent} %` : '--'}
          </Td>
          <Td width={`${columnSizes[5]}%`} color={getHSColor(item.health_score, assetConditionLevels)} centered>
            {item.health_score !== null ? `${item.health_score}` : '--'}
          </Td>
        </ListItem>
        </table>
        {machinesRequiringAttention && (
          <table width="100%">
            <ListItem backgroundColor={colors.white} tdPadding="7px">
              <Td width="100%">
                <FlexContainer alignItems="center" justifyContent="space-between">
                  <ReasonItem reason={item.reason_for_attention} metadata={item.ara_metadata} machineId={item.id} />
                  <MoreOptions position="relative" top="0" right="0">
                  {ARA_ITEM_OPTIONS.map((item, idx) => (
                    <RibbonItem
                      key={idx}
                      fontSize="14px"
                      width="max-content"
                      onClick={() => openModal(item.value)}
                    >
                      {item.text}
                    </RibbonItem>
                  ))}
                  </MoreOptions>
                </FlexContainer>
              </Td>
            </ListItem>
          </table>
        )}
      </>
    );
  }
}

MachineItem.propTypes = {
  columnSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  item: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  assetConditionLevels: state.currentAccount.preferences.machine_condition_levels,
  machinesRequiringAttention: state.machines.machinesRequiringAttention.show
});

const mapDispatchToProps = dispatch => ({
  taskActions: bindActionCreators(taskActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MachineItem);
