import media from './media';
import colors from './colors';
import fonts from './fonts';
import utils from './utils';
import { scrollbar } from './scrollbar';

export default {
  colors,
  media,
  fonts,
  utils,
  scrollbar,
  primaryColor: 'green',
  secondaryColor: colors.greenA,
};
