import moment from 'moment';
import * as _ from 'lodash';
import { axiosInstance, ENDPOINT } from '../../common/constants';
import { handleResponse } from '../../common/helpers';

import { devicesHealthConstants, devicesHealthMetadata } from './devicesHealth.constants';
import { CapitalizeEachWord, formatChartData, convertTimestringsToDateObjects, formatMissedMeasurementsData } from '../../common/utils';


export const getDevicesHealthData = (analytics_type, filters, start_date = null, end_date = null) => {
  return (dispatch, getState) => {
    const adminDashboardState = getState().adminDashboard;
    dispatch(request());
    if (analytics_type === 'missed_measurements' || analytics_type === 'weekly_unreachable') {
      const end_time = end_date || moment().toDate();
      const start_time = start_date || moment().subtract(7, 'days').startOf('day').toDate();
      let params = {
        analytics_type,
        all: adminDashboardState.adminOpen,
        start_time,
        end_time,
        filters
      };
      if (analytics_type === 'missed_measurements') params = { ...params, data_format: 'overall' };
      return handleResponse(axiosInstance.get(ENDPOINT.GET_DEVICES_HEALTH_DATA, { params })).then(
        (res) => {
          let formattedData;
          if (analytics_type === 'missed_measurements') {
            formattedData = formatMissedMeasurementsData(
              res[analytics_type].counts,
              convertTimestringsToDateObjects(res[analytics_type].timestamps)
            );
          } else {
            formattedData = formatChartData(
              res[analytics_type].counts,
              convertTimestringsToDateObjects(res[analytics_type].timestamps)
            );
            formattedData.sort((a, b) => (a.x > b.x ? 1 : -1));
          }
          dispatch(success({
            [analytics_type]: formattedData
          }));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
    }
    return handleResponse(axiosInstance.get(ENDPOINT.GET_DEVICES_HEALTH_DATA, { params: { analytics_type, all: adminDashboardState.adminOpen, filters } })).then(
      (res) => {
        const sortOrder = devicesHealthMetadata[analytics_type].sortOrder;
        const filterOptions = devicesHealthMetadata[analytics_type].filters;
        let analyticsDataList = [];

        if (sortOrder) analyticsDataList = sortOrder.map(item => ({ value: res[analytics_type][item], label: CapitalizeEachWord(item) }));
        else {
          const data = res[analytics_type];
          Object.keys(data).forEach((key) => {
            if (key !== 'other') analyticsDataList.push({ label: key, value: data[key] });
          });
          if (data.other) analyticsDataList.push({ label: 'Other', value: data.other });
        }
        dispatch(success({
          total_num: res.total_num,
          [analytics_type]: analyticsDataList
        }));

        return [analyticsDataList, filterOptions];
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: devicesHealthConstants.GET_DEVICES_HEALTH_DATA_REQUEST, analytics_type };
  }

  function success(devicesHealthData) {
    return { type: devicesHealthConstants.GET_DEVICES_HEALTH_DATA_SUCCESS, devicesHealthData };
  }

  function failure(error) {
    return { type: devicesHealthConstants.GET_DEVICES_HEALTH_DATA_FAILURE, analytics_type, error };
  }
};

export const getMissedMeasurementsList = (page = null, startTime = null, endTime = null, search_key = null, filters = null) =>
  (dispatch, getState) => {
    const request = page => ({ type: devicesHealthConstants.GET_MISSED_MEASUREMENTS_LIST_REQUEST, page });
    const success = (data, page, total_pages, total_count) =>
      ({
        type: devicesHealthConstants.GET_MISSED_MEASUREMENTS_LIST_SUCCESS,
        payload: {
          data,
          page,
          total_pages,
          total_count
        }
      });
    const failure = error => ({ type: devicesHealthConstants.GET_MISSED_MEASUREMENTS_LIST_FAILURE, error });

    const missedMeasurementsList = getState().devicesHealth.missedMeasurements.missedMeasurementsList;
    const missedMeasurementsFilters = getState().devicesHealth.missedMeasurements.missedMeasurementsFilters;
    const missedMeasurementsSorter = getState().devicesHealth.missedMeasurements.missedMeasurementsSorter;
    const adminDashboardState = getState().adminDashboard;
    const params = {
      analytics_type: 'missed_measurements',
      per_page: devicesHealthConstants.NUMBER_OF_SENSORS_PER_PAGE,
      page: page || missedMeasurementsList.page,
      start_time: startTime || missedMeasurementsFilters.startTime,
      end_time: endTime || missedMeasurementsFilters.endTime,
      search_key: search_key || missedMeasurementsFilters.searchKey,
      all: adminDashboardState.adminOpen,
      filters: filters || missedMeasurementsFilters.filters
    };
    if (!_.isEmpty(missedMeasurementsSorter)) {
      params.order_by = { field: missedMeasurementsSorter.sorter.name, direction: missedMeasurementsSorter.sorter.order };
    }
    dispatch(request(params.page));
    return handleResponse(axiosInstance.get(ENDPOINT.GET_DEVICES_HEALTH_DATA, { params })).then(
      (res) => {
        res = res.missed_measurements;
        dispatch(success(res.items, res.page, res.total_pages, res.total_count));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

export const setMissedMeasurementsFilters = (startTime, endTime, searchKey, filters) => (dispatch) => {
  dispatch({
    type: devicesHealthConstants.SET_MISSED_MEASUREMENTS_LIST_FILTERS,
    payload: {
      startTime,
      endTime,
      searchKey,
      filters
    }
  });
  dispatch({ type: devicesHealthConstants.CLEAR_MISSED_MEASUREMENTS_LIST });
  return dispatch(getMissedMeasurementsList(1, startTime, endTime, searchKey, filters));
};

export const setMissedMeasurementsSorter = sorter => (dispatch) => {
  dispatch({ type: devicesHealthConstants.SET_MISSED_MEASUREMENTS_SORTER, sorter });
  dispatch({ type: devicesHealthConstants.CLEAR_MISSED_MEASUREMENTS_LIST });
  dispatch(getMissedMeasurementsList(1));
};

export const loadMore = () => (dispatch, getState) => {
  const missedMeasurementsList = getState().devicesHealth.missedMeasurements.missedMeasurementsList;
  const page = missedMeasurementsList.page;
  if (missedMeasurementsList.loading) return;
  dispatch({ type: devicesHealthConstants.INCREASE_MISSED_MEASUREMENTS_PAGE, page: page + 1 });
  dispatch(getMissedMeasurementsList(page + 1));
};

export const selectSensor = serial_number => (dispatch) => {
  dispatch({ type: devicesHealthConstants.SELECT_SENSOR, serial_number });
  return dispatch(getSensorMissedTimestampsList(1, serial_number));
};

export const deselectSensor = () => (dispatch) => {
  dispatch({ type: devicesHealthConstants.CLEAR_SENSOR_MISSED_MEASUREMENTS_LIST });
  return dispatch({ type: devicesHealthConstants.DESELECT_SENSOR });
};

export const getSensorMissedTimestampsList = (page = null, serial_number = null) => (dispatch, getState) => {
  const request = page => ({ type: devicesHealthConstants.GET_SENSOR_MISSED_MEASUREMENTS_LIST_REQUEST, page });
  const success = (data, page, total_pages, total_count) =>
    ({
      type: devicesHealthConstants.GET_SENSOR_MISSED_MEASUREMENTS_LIST_SUCCESS,
      payload: {
        data,
        page,
        total_pages,
        total_count
      }
    });
  const failure = error => ({ type: devicesHealthConstants.GET_SENSOR_MISSED_MEASUREMENTS_LIST_FAILURE, error });

  const sensorMissedTimestampsList = getState().devicesHealth.missedMeasurements.sensorMissedTimestampsList;
  const sensorMissedTimestampsSorter = getState().devicesHealth.missedMeasurements.sensorMissedTimestampsSorter;
  const missedMeasurementsFilters = getState().devicesHealth.missedMeasurements.missedMeasurementsFilters;
  const selectedSensor = getState().devicesHealth.missedMeasurements.selectedSensor;
  const params = {
    analytics_type: 'missed_measurements',
    per_page: devicesHealthConstants.NUMBER_OF_TIMESTAMPS_PER_PAGE,
    page: page || sensorMissedTimestampsList.page,
    serial_number: serial_number || selectedSensor.serial_number,
    start_time: missedMeasurementsFilters.startTime,
    end_time: missedMeasurementsFilters.endTime
  };
  if (!_.isEmpty(sensorMissedTimestampsSorter)) {
    params.order_by = { field: sensorMissedTimestampsSorter.sorter.name, direction: sensorMissedTimestampsSorter.sorter.order };
  }
  dispatch(request(params.page));
  return handleResponse(axiosInstance.get(ENDPOINT.GET_DEVICES_HEALTH_DATA, { params })).then(
    (res) => {
      res = res.missed_measurements;
      dispatch(success(res.items, res.page, res.total_pages, res.total_count));
    },
    (error) => {
      dispatch(failure(error));
    }
  );
};

export const setSensorMissedTimestampsSorter = sorter => (dispatch) => {
  dispatch({ type: devicesHealthConstants.SET_SENSOR_MISSED_MEASUREMENTS_SORTER, sorter });
  dispatch({ type: devicesHealthConstants.CLEAR_SENSOR_MISSED_MEASUREMENTS_LIST });
  dispatch(getSensorMissedTimestampsList(1));
};

export const sensorLoadMore = () => (dispatch, getState) => {
  const sensorMissedTimestampsList = getState().devicesHealth.missedMeasurements.sensorMissedTimestampsList;
  const page = sensorMissedTimestampsList.page;
  if (sensorMissedTimestampsList.loading) return;
  dispatch({ type: devicesHealthConstants.INCREASE_SENSOR_MISSED_MEASUREMENTS_PAGE, page: page + 1 });
  dispatch(getSensorMissedTimestampsList(page + 1));
};
