import React from 'react';
import H2 from 'common/typography/H2/H2';

export const lineColors = ['#333', '#e4e409'];

export const bhsLinks = {
  bearingsIntro: 'https://www.americanbearings.org/page/what_are_bearings',
  bearingsGuide: 'https://www.skf.com/binary/12-69177/457640.pdf',
  howBearingsWork: 'https://www.youtube.com/watch?v=Jt0ou8Q5Fo8',
  lubricationPractices: 'https://www.plantengineering.com/articles/best-practices-in-lubrication/',
  bearingTypes: 'https://www.nskamericas.com/en/services/what-s-a-bearing/types.html',
  bearingSelection: 'https://www.ntnglobal.com/en/products/catalog/pdf/2202E_a02.pdf',
  commonMistakes: 'https://www.efficientplantmag.com/2008/07/the-fundamentals-installation-errors-shorten-bearing-life/',
  spectrumAnalysis: 'http://www.vibrationschool.com/mans/SpecInter/SpecInter24.htm'
};

export const defaultMovingWindowSize = 10;

export const rulCustomLabels = {
  nonlinear_regression: 'Nonlinear Regression Model'
};

export const rulMethodsToShow = [
  'trained_model'
];

export const rulMethods = [
  { text: 'Trained Model', value: 'trained_model' },
  { text: 'Nonlinear Regression Model', value: 'nonlinear_regression' }
];

export const featureTypeOptionNames = {
  band_energies: 'band_energies',
  component_specific: 'component_specific'
};

export const featureTypeOptions = [
  { text: 'Band Energy Features', value: featureTypeOptionNames.band_energies },
  { text: 'Component Specific Features', value: featureTypeOptionNames.component_specific },
  { text: 'Auto', value: 'auto' }
];

export const validationInfo = theme => ({
  slope: (
    <span>
      <H2>Slope</H2>
      Slope is calculated from linear regression. This check is essential to assess the presence of underlying trends in the baseline data.
      A low slope value indicates minimal or no trend, which is desirable for a stable baseline.
        <br /><br />
      Accepted value: <b style={{ color: theme.primaryColor }}>&lt; 0.02 /day</b>
    </span>
  ),
  cv: (
    <span>
      <H2>Coefficient of Variation</H2>
      This parameter is defined as a ratio of standard deviation and mean. A higher CV indicates a higher relative variation in the data compared to the mean, while a lower CV suggests that the data points are closer to the mean, indicating lower relative variation.
      <br /><br />
      <b>Note:</b> For parameters like temperature, day-night variations are expected, leading to natural fluctuations and, in turn, a slightly higher CV. In this case, users can &apos;Override and Save&apos; the baseline.
      <br /><br />
      Accepted value: <b style={{ color: theme.primaryColor }}>&lt; 0.17</b>
    </span>
  ),
  outlier: (
    <span>
      <H2>Outliers</H2>
      Outliers are the data points that deviate from the other data points in the baseline zone.
      <br /><br />
      Accepted: if <b style={{ color: theme.primaryColor }}>NO</b> outliers are present
    </span>
  )
});

export const rulFeatures = [
  { text: 'RMS Acceleration', value: 'rms_acceleration' },
  { text: 'RMS Velocity', value: 'rms_velocity' }
];
