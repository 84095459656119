import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlexContainer } from 'common/components/atoms';
import Checkbox from 'common/components/atoms/Checkbox';
import H3 from 'common/typography/H3/H3';
import H2 from 'common/typography/H2/H2';
import colors from 'common/styles/colors';

const SubTitle = styled(H3)`
  color: ${props => props.theme.colors.greyXXD};
  font-size: 12px;
`;

const Title = styled(H2)`
  color: ${props => props.theme.colors.greyXXD};
  font-size: 16px;
  font-weight: 600;
  padding: 0;
`;

const CheckboxContainer = styled.span`
  padding: 1rem;
  padding-left: 0;
`;

const CompareTagsListItem = ({ title, subTitle, selected, onTagItemClick }) => (
  <FlexContainer
    borderRadius="5px"
    backgroundColor="#fff"
    padding="0.75em 1rem"
    margin="1rem"
    marginbottom="0"
    border={`${selected ? `1px solid ${colors.borderActive}` : `1px solid ${colors.borderGray}`}`}
  >
    <CheckboxContainer>
      <Checkbox
        id={`tag-item-checkbox-${subTitle}`}
        value={selected}
        onClick={onTagItemClick}
      />
    </CheckboxContainer>
    <FlexContainer direction="column" justifyContent="center">
      <Title>{title}</Title>
      <SubTitle>
        {subTitle}
      </SubTitle>
    </FlexContainer>
  </FlexContainer>
);

CompareTagsListItem.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  onTagItemClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
};

export default CompareTagsListItem;
