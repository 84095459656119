import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ListItem from 'common/components/atoms/ListItem';
import _ from 'lodash';
import DropdownSelect from 'common/components/molecules/DropdownSelect';
import { sensorChannelOrientations } from '../constants/sensors.constants';

const DropdownContainer = styled.div`
  width: 60%;
`;

const AssignSensorListItem = ({
  item,
  columnSizes,
  channelOptions,
  onChannelSelect,
  selectedChannels
}) => {
  const sensorOrientationMap = sensorChannelOrientations[item.sensor_type];
  const orientation = (!_.isNil(item.orientation) && sensorOrientationMap) ? sensorOrientationMap[item.orientation] : null;

  const filteredChannelOptions = _.map(channelOptions, tx => ({
    ...tx,
    children: tx.children.filter(channel => channel.value === item.channel_id || !selectedChannels.includes(channel.value))
  }));

  const onClickChannel = (portChannelText, channelId, txSerialNumber) => {
    onChannelSelect(item.id, channelId, item.orientation, item.channel_id, txSerialNumber);
  };

  const getSelectedChannel = (channelId) => {
    if (!channelId) return null;
    let channelText = null;
    channelOptions.forEach((tx) => {
      tx.children.forEach((channel) => {
        if (channel.value === channelId) channelText = `${tx.text} P${channel.port_position} C${channel.channel_position}`;
      });
    });
    return channelText;
  };

  return (
    <ListItem>
      <td width={`${columnSizes[0]}%`}>
        {`${item.serial_number}${orientation ? `_${orientation}` : ''}`}
      </td>
      <td width={`${columnSizes[1]}%`}>
        <DropdownContainer>
          <DropdownSelect
            data={filteredChannelOptions}
            marginBottom="0"
            onClickItem={onClickChannel}
            value={getSelectedChannel(item.channel_id)}
            clearable
          />
        </DropdownContainer>
      </td>
    </ListItem>
  );
};

AssignSensorListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    channel_id: PropTypes.number,
    serial_number: PropTypes.string,
    orientation: PropTypes.number
  }).isRequired,
  columnSizes: PropTypes.array.isRequired,
  channelOptions: PropTypes.array.isRequired,
  selectedChannels: PropTypes.array.isRequired,
  onChannelSelect: PropTypes.func
};

AssignSensorListItem.defaultProps = {
  onChannelSelect: () => {}
};

export default AssignSensorListItem;
