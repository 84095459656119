import { combineReducers } from 'redux';

import {
  erroneousReadingsReducer,
  erroneousReadingsFilters,
  sensorErroneousReadingsReducer,
  sensorErroneousReadingsFilters
} from './erroneousReadings.reducer';

export const erroneousReadings = combineReducers({
  summarizedErroneousReadings: combineReducers({
    items: erroneousReadingsReducer,
    filters: erroneousReadingsFilters
  }),
  sensorErroneousReadings: combineReducers({
    items: sensorErroneousReadingsReducer,
    filters: sensorErroneousReadingsFilters
  })
});
