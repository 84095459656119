import React from 'react';
import styled from 'styled-components';
import LogoSvg from 'common/images/LogoSvg';

const ModalHeaderBreadCrumb = styled.div`
    font-size: 12px;
    line-height: 17px;
    color: #999B95;
`;

const ModalHeaderLabel = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #3c3d3a;
`;

const ModalHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: space-around;
    margin-left: 16px;
`;

const SelectTagsModalHeader = ({ accountName, subHeader, header }) => (
  <>
    <LogoSvg />
    <ModalHeaderContainer>
      <ModalHeaderBreadCrumb>
        {accountName} &gt; {subHeader}
      </ModalHeaderBreadCrumb>
      <ModalHeaderLabel>
        {header}
      </ModalHeaderLabel>
    </ModalHeaderContainer>
  </>
);

export default SelectTagsModalHeader;
