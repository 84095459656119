import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import _ from 'lodash';


import { history } from 'common/helpers';
import ScrollContainer from 'common/components/atoms/ScrollContainer';
import Modal from 'common/components/organisms/Modal';
import AlertPrompt from 'common/components/organisms/AlertPrompt';
import H2 from 'common/typography/H2/H2';
import H3 from 'common/typography/H3/H3';
import OutsideAlerter from 'common/OutsideAlerter';
import Label_T from 'common/typography/Label/Label';
import FlexContainer from 'common/components/atoms/FlexContainer';
import MoreOptions from 'common/components/organisms/MoreOptions';
import RibbonItem from 'common/components/atoms/RibbonItem';
import Loading from 'common/components/atoms/Loading';
import List from 'common/components/molecules/List';
import Button from 'common/components/atoms/Button';
import { Search } from 'common/images/FaIcons';
import { InputField } from 'common/components/atoms';

import UpdateDeploymentModal from './UpdateDeploymentModal';
import AffectedDeviceItem from './molecules/AffectedDeviceItem';

import * as firmwareDeploymentActions from '../actions/firmwareDeployment.actions';
import * as sensorActions from '../../Sensors/actions/sensors.actions';


const Label = styled(Label_T)`
  padding-top: 2em
`;

const TabContainer = styled(FlexContainer).attrs({ direction: 'column', marginbottom: '1em' })`
  line {
    color: #EEEEED;
    background-color: #EEEEED;
    height: 1px;
  }
`;

const Container = styled(FlexContainer).attrs({ direction: 'column' })`
  overflow: auto;
  width: 100%;
  height: 100%;
  ${props => props.border && `border: 1px solid ${props.theme.colors.greyL};`}
`;

const Tab = styled(Button).attrs({
  text: true,
  fontSize: '12px'
})`
  padding: 1.3em 2em 1em;
  ${props => props.selected ? `
    border: 1px solid;
    border-color: #EEEEED #EEEEED #FFFFFF;
    border-radius: 4px 4px 0 0;
  ` : ''}
`;

const AbsoluteLoading = styled(Loading)`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e447;
`;

class FirmwareDeploymentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateModalOpen: false,
      tabOpen: 'info',
      selectedDevices: [],
      deployment_id: null,
      deviceSearchKey: ''
    };
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params.deployment_id) {
      this.setState({
        deployment_id: parseInt(this.props.match.params.deployment_id, 10)
      }, () => this.getFirmwareDetails());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match && this.props.match && prevProps.match.params.deployment_id !== this.props.match.params.deployment_id) {
      this.setState({
        deployment_id: parseInt(this.props.match.params.deployment_id, 10)
      }, () => this.getFirmwareDetails());
    }
  }

  getFirmwareDetails = () => {
    const { getFirmwareDeploymentDetails, getDeploymentDevices } = this.props.firmwareDeploymentActions;
    const { deployment_id, deviceSearchKey } = this.state;
    getFirmwareDeploymentDetails(deployment_id);
    getDeploymentDevices(deployment_id, deviceSearchKey);
  }

  getDeploymentDevicesDebounce = _.debounce(
    () => {
      const { deployment_id, deviceSearchKey } = this.state;
      this.props.firmwareDeploymentActions.getDeploymentDevices(deployment_id, deviceSearchKey);
    }, 500);

  updateDeployment = (associations, fw_id) => {
    let params = {};
    const { deploymentDetails } = this.props;
    const { deployment_id } = this.state;
    const { updateFirmwareDeployment } = this.props.firmwareDeploymentActions;
    switch (deploymentDetails.details.association_type) {
      case 'devices':
        params = {
          target_firmware_id: fw_id,
          device_ids: associations,
          firmware_ids: []
        };
        break;
      case 'firmwares':
        params = {
          firmware_ids: associations,
          device_ids: []
        };
        break;
      default:
    }
    updateFirmwareDeployment(deployment_id, params).then(
      () => { this.getFirmwareDetails(); }
    );

    this.closeUpdateModalOpen();
  }

  deleteFirmwareDeployment = () => {
    const { deployment_id } = this.state;
    const { firmwareDeploymentActions } = this.props;
    firmwareDeploymentActions.deleteFirmwareDeployment(deployment_id);
    this.closeDeleteDeploymentPrompt();
    this.closeModal();
  }

  retryFirmwareDeployment = (devices) => {
    const { deployment_id } = this.state;
    const { firmwareDeploymentActions } = this.props;
    firmwareDeploymentActions.retryDeployment(deployment_id, devices).then(
      () => {
        this.getFirmwareDetails();
      }
    );
    this.setState({ selectedDevices: [] });
  }

  renderInfo = () => {
    const {
      deploymentDetails,
    } = this.props;
    const { details } = deploymentDetails;
    const { deployment_id } = this.state;

    return (
      <Container>
        <FlexContainer justifyContent="space-between">
          <H2>{details.name}</H2>
          <FlexContainer>
            <OutsideAlerter
              styles="align-items: center; flex-basis: 100%; margin: auto;"
              open
              handleClick={() => {}}
            >
              <MoreOptions position="relative" top="0px" right="0px">
              <FlexContainer direction="column">
                {details.association_type !== 'accounts' && (
                  <RibbonItem
                    onClick={this.openUpdateModalOpen}
                    fontSize="14px"
                    width="max-content"
                  >
                    Update Deployment
                  </RibbonItem>
                )}
                <RibbonItem
                  onClick={this.openDeleteDeploymentPrompt}
                  fontSize="14px"
                  width="max-content"
                >
                  Delete Deployment
                </RibbonItem>
              </FlexContainer>
              </MoreOptions>
            </OutsideAlerter>
          </FlexContainer>
        </FlexContainer>
        <ScrollContainer height="50vh">
        <Label>Deployment Id</Label>
        <H3>{deployment_id || '--'}</H3>
        <Label>Firmware Type</Label>
        <H3>{details.target_firmware_type || '--'}</H3>
        <Label>Target Firmware Version</Label>
        <H3>{details.target_firmware_version || '--'}</H3>
        <Label>Association Type</Label>
        <H3>{details.association_type || '--'}</H3>
        <Label>Device Type</Label>
        <H3>{details.device_type || '--'}</H3>
        <Label>Created By</Label>
        <H3>{details.created_by || '--'}</H3>
        <Label>Created At</Label>
        <H3>{details.created_at || '--'}</H3>
        <Label>Updated At</Label>
        <H3>{details.updated_at || '--'}</H3>
        </ScrollContainer>
      </Container>
    );
  }

  getAffectedDevicesColumns = () => {
    const {
      deploymentDetails,
    } = this.props;
    const { affectedDevices } = deploymentDetails;
    return affectedDevices.map(device => ({
      id: device.id,
      serial_number: device.serial_number,
      account_name: device.account_name,
      target_version: device.firmware_target_versions && device.firmware_target_versions.mcu,
      state: device.firmware_update_states && device.firmware_update_states.mcu && device.firmware_update_states.mcu.state,
      current_version: device.firmware_versions && device.firmware_versions.mcu,
    }));
  }

  renderAffectedDevices = () => {
    const { selectedDevices, deviceSearchKey } = this.state;

    const headers = [{
      name: 'serial_number',
      label: 'Serial Number',
      noSorter: true,
    }, {
      name: 'account_name',
      label: 'Account Name',
      noSorter: true,
    }, {
      name: 'target_version',
      label: 'Firmware Target Version',
      noSorter: true,
    }, {
      name: 'state',
      label: 'State',
      noSorter: true,
    }, {
      name: 'current_version',
      label: 'Current Firmware Version',
      noSorter: true,
    }];
    return (
      <FlexContainer direction="column">
          <InputField
            name="devices-search-field"
            type="text"
            prefix={<Search />}
            prefixSide="left"
            placeholder="Search..."
            onChange={
              e => this.changeDeviceSearchKey(e.target.value)
            }
            value={deviceSearchKey}
            searchBox
            clearable
            clearInput={() => this.changeDeviceSearchKey()}
          />
      <FlexContainer style={{ overflow: 'auto' }}>
        <Container border>
            <List
              columnSizes={[20, 20, 20, 20, 20]}
              headers={headers}
              maxHeightAfterScroll="calc(50vh - 125px)"
              emptyMsg="No affected devices found"
              items={{
                object: this.getAffectedDevicesColumns()
              }}
              ItemComponent={AffectedDeviceItem}
              selectedDevices={selectedDevices}
              toggleSelectedDevice={this.toggleSelectedDevice}
              padding="0 1rem"
            />
        </Container>
      </FlexContainer>
      </FlexContainer>
    );
  }

  toggleSelectedDevice = (id) => {
    const selected = _.includes(this.state.selectedDevices, id);
    let selectedDevices = [];
    if (selected) {
      selectedDevices = _.filter(this.state.selectedDevices, s => s !== id);
    } else {
      selectedDevices = [...this.state.selectedDevices, id];
    }
    this.setState({ selectedDevices });
  }

  changeDeviceSearchKey = (deviceSearchKey = '') => {
    this.setState({
      deviceSearchKey
    }, () => this.getDeploymentDevicesDebounce());
  }

  openUpdateModalOpen = () => this.setState({ updateModalOpen: true })

  closeUpdateModalOpen = () => this.setState({ updateModalOpen: false })

  openDeleteDeploymentPrompt = () => this.setState({ confirmDeleteDeploymentPrompt: true })

  closeDeleteDeploymentPrompt = () => this.setState({ confirmDeleteDeploymentPrompt: false })

  setTabOpen = tabOpen => this.setState({ tabOpen })

  closeModal = () => {
    if (this.props.location.state && this.props.location.state.from_app) {
      history.goBack();
    }
    history.push('/admin-dashboard/firmware-deployments');
  }

  render() {
    const {
      deploymentDetails,
      metadata,
    } = this.props;
    const { loading, error, details } = deploymentDetails;
    const { tabOpen, selectedDevices } = this.state;

    return (
      <>
      <Modal id="deployment-details" title="Deployment Details" close={this.closeModal} width="50%">
        <FlexContainer
          height="60vh"
          position="relative"
        >
          {loading && !error && (<AbsoluteLoading />)}
          <Container direction="column">
              <TabContainer>
                <FlexContainer>
                  <Tab
                    selected={tabOpen === 'info'}
                    onClick={() => this.setTabOpen('info')}
                  >
                    Info
                  </Tab>
                  <Tab
                    selected={tabOpen === 'affected_devices'}
                    onClick={() => this.setTabOpen('affected_devices')}
                  >
                    Affected Devices
                  </Tab>
                </FlexContainer>
                <line />
              </TabContainer>
                {tabOpen === 'info' ? (
                  this.renderInfo()
                ) : (
                  this.renderAffectedDevices()
                )}
              <FlexContainer justifyContent="flex-end" margintop="0.5em">
                <Button cancel onClick={this.closeModal}> Close</Button>
                {tabOpen === 'affected_devices' && (
                  <Button onClick={() => this.retryFirmwareDeployment(selectedDevices)} disabled={selectedDevices.length === 0} margin="0 0 0 20px">Retry</Button>
                )}
              </FlexContainer>
          </Container>
        </FlexContainer>
      </Modal>
      {this.state.updateModalOpen && (
        <UpdateDeploymentModal
          details={details}
          metadata={metadata}
          update={this.updateDeployment}
          close={this.closeUpdateModalOpen}
        />
      )}
      {this.state.confirmDeleteDeploymentPrompt && (
          <AlertPrompt
            message={`Are you sure you want to delete deployment ${details.name} ?`}
            secondaryMessage="This action can not be undone"
            onProceed={this.deleteFirmwareDeployment}
            onCancel={this.closeDeleteDeploymentPrompt}
          />
      )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  deployments: state.firmwareDeployments.deployments,
  metadata: state.firmwareDeployments.metadata,
  deploymentDetails: state.firmwareDeployments.details,
  adminDashboard: state.adminDashboard,
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  firmwareDeploymentActions: bindActionCreators(firmwareDeploymentActions, dispatch),
  sensorActions: bindActionCreators(sensorActions, dispatch)
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirmwareDeploymentDetails);
