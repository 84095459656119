
import MachineBuilderConstants from '../machineBuilder.constants';

const initialState = {
  metadata: {},
  create: {
    loading: false
  }
};

const machineBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case MachineBuilderConstants.GET_MACHINES_BUILDER_METADATA_REQUEST:
      return {
        ...state,
        metadata: {
          loading: true
        }
      };
    case MachineBuilderConstants.GET_MACHINES_BUILDER_METADATA_SUCCESS:
      return {
        ...state,
        metadata: action.data
      };
    case MachineBuilderConstants.GET_MACHINES_BUILDER_METADATA_FAILURE:
      return {
        ...state,
        metadata: {
          error: action.error,
          loading: false
        }
      };
    case MachineBuilderConstants.CREATE_MACHINES_BUILDER_REQUEST:
      return {
        ...state,
        create: {
          loading: true
        }
      };
    case MachineBuilderConstants.CREATE_MACHINES_BUILDER_SUCCESS:
      return {
        ...state,
        create: {
          loading: false
        }
      };
    case MachineBuilderConstants.CREATE_MACHINES_BUILDER_FAILURE:
      return {
        ...state,
        create: {
          loading: false
        }
      };
    default:
      return state;
  }
};

export default machineBuilderReducer;
