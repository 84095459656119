import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';
import * as d3 from 'd3';


import { Chart } from 'common/components/Chart';
import Loading from 'common/components/atoms/Loading';
import { CapitalizeEachWord, chartRange, convertTimestringsToDateObjects } from 'common/utils';
import FlexContainer from 'common/components/atoms/FlexContainer';
import MultilineSelection from 'common/components/Chart/components/MultilineSelection';
import { chartColors } from 'common/constants';
import Button, { AnimatedButton } from 'common/components/atoms/Button';
import * as helpers from 'common/components/Chart/utils/helpers';
import NoDataChart from '../../MachineCharts/components/NoDataChart';
import machineMLTypes from '../actions/machineML.types';
import BaselineValidator from './organisms/BaselineValidator';
import BaselineDetails from './BaselineDetails';


const ActionButtons = styled(FlexContainer).attrs({ direction: 'row', justifyContent: 'space-between' })`
  margin-bottom: 2em;
  div ${Button} {
    margin-left: 1em;
  }
  ${AnimatedButton} {
    margin-left: 1em;
  }
  span {
    color: ${props => props.theme.colors.green};
  }
`;

const ChartContainer = styled.div`
  position: relative;
`;

const AbsoluteLoading = styled(Loading)`
  position: absolute;
  height: 91%;
  width: 97.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e447;
  margin-left: 53px;
`;

const NoDataContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  padding-bottom: 1em;
  margin-top: 1em;
`;

class MultiTagsBaselineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      baseline_info: props.baseline_info,
      showValidationOutliers: {}
    };
  }

  componentDidMount() {
    this.calculateChartDataModifications();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isMultiSetItem } = this.props;
    if (!_.isEqual(this.props.component, prevProps.component)) {
      this.calculateChartDataModifications();
    }
    if (this.props.multiSetBaselineInfo && isMultiSetItem && !_.isEqual(prevProps.isMultiSetItem, isMultiSetItem)) {
      this.updateBaseline(this.props.multiSetBaselineInfo);
    }
    if (isMultiSetItem && !_.isEqual(prevProps.multiSetBaselineInfo, this.props.multiSetBaselineInfo) && !_.isEqual(this.state.baseline_info, this.props.multiSetBaselineInfo)) {
      this.updateBaseline(this.props.multiSetBaselineInfo);
    }
    if (isMultiSetItem && !_.isEqual(prevState.baseline_info, this.state.baseline_info)) {
      this.props.setBaselineValue(this.state.baseline_info);
    }
    if (!_.isEqual(prevProps.baseline_info, this.props.baseline_info)) {
      this.cancel();
    }
  }

  calculateChartDataModifications = () => {
    const { component, unit_system } = this.props;
    const tags_data = component.tags_data.filter(t => !_.isEmpty(t.trend));
    const multiline_data = tags_data.map(t => t.trend);
    const yUnits = tags_data.map(t => t.units);
    const tagTypes = tags_data.map(t => t.tag_type);
    // base range for which the chart will be rendered and data scaled to
    const endRange = [0, 10];
    // object for holding ranges of individual unit types,
    // e.g. { in/s: [min, max] } will hold min and max values from all datasets that are in/s
    const unitRanges = {};
    // boolean to determine existance of data
    let hasData = false;
    const domain = [];
    if (multiline_data && multiline_data.length > 0) {
      multiline_data.forEach((item, idx) => {
        if (item.length > 0) {
          const dataDomain = d3.extent(item, d => d.x);
          if ((dataDomain[0] !== undefined && dataDomain[0] < domain[0]) || !domain[0]) domain[
            0] = dataDomain[0];
          if ((dataDomain[1] !== undefined && dataDomain[1] > domain[1]) || !domain[1]) domain[
            1] = dataDomain[1];
          hasData = true;
          const yUnit = yUnits[idx] || 'mm/s';
          const tag_type = tagTypes[idx].type || 'vibration';
          const itemRange = chartRange(item, unit_system, yUnit, '', tag_type, 'trend');
          if (unitRanges[yUnit] === undefined) {
            unitRanges[yUnit] = itemRange;
          } else {
            if (itemRange[1] > unitRanges[yUnit][1]) unitRanges[yUnit][1] = itemRange[1];
            if (itemRange[0] < unitRanges[yUnit][0]) unitRanges[yUnit][0] = itemRange[0];
          }
        }
      });
    }

    // map over all datapoints and give a scaled value for y
    // insert new key realY which will give the initial value of this datapoint (used on hover selection)
    let data = [];
    if (hasData) {
      data = _.map(multiline_data, (d, idx) => {
        if (_.isEqual(d, [])) return [];
        const dataWithScale = _.map(d, dataEntry => ({
          ...dataEntry,
          y: helpers.scaleDataEntry(dataEntry.y, unitRanges[yUnits[idx] || 'mm/s'], endRange),
          realY: dataEntry.y
        }));
        return dataWithScale;
      });
    }
    this.setState(
      {
        data,
        domain,
        range: endRange
      },
      () => this.setInitialSelection()
    );
  }

  setInitialSelection = () => {
    const trendsWithData = _.filter(this.state.data, d => d.length > 0);
    const selections = trendsWithData.map(data => ({
      ...data[data.length - 1],
    }));
    if (selections) {
      this.setState({
        selections
      });
    }
  }

  mouseoverChart = (selections) => {
    this.setState({
      selections
    });
  }

  updateBaseline = (params) => {
    this.setState(prevState => ({
      baseline_info: {
        ...prevState.baseline_info,
        ...params
      }
    }), () => {
      this.props.save(
        this.state.baseline_info,
        this.props.component.tags_data.filter(t => !_.isEmpty(t.trend)).map(t => t.tag_id),
        'validate'
      );
    });
  }

  toggleValidationOutliers = (tagId) => {
    this.setState(prevState => ({
      showValidationOutliers: {
        ...prevState.showValidationOutliers,
        [tagId]: !prevState.showValidationOutliers[tagId]
      }
    }));
  }

  updateLimit = (limit, value) => {
    if (!Number.isNaN(parseFloat(value)) || value === '') {
      this.updateBaseline({
        [limit]: value
      });
    }
  }

  cancel = () => {
    this.setState({
      baseline_info: this.props.baseline_info
    });
    const tagIds = this.props.component.tags_data.map(t => t.tag_id);
    this.props.clearValidationResult(tagIds);
  }

  save = () => {
    const { save, component } = this.props;
    const { baseline_info } = this.state;
    save(baseline_info, component.tags_data.filter(t => !_.isEmpty(t.trend)).map(t => t.tag_id));
  }

  deleteBaselines = () => {
    const { deleteBaselines } = this.props;
    this.toggleDeleteConfirm();
    deleteBaselines();
  }

  toggleDeleteConfirm = () => {
    this.setState(prevState => ({ deleteConfirm: !prevState.deleteConfirm }));
  }

  renderNoDataChart = () => {
    const { days, component } = this.props;
    let title = '';
    if (_.isEmpty(component.tags_data) && !component.loading) {
      title = `No sensors associated to the component ${component.name}`;
    } else if (component.loading) {
      title = '';
    } else {
      title = `No data available for this component ${component.name}`;
      if (days) title += ` ${days} days`;
    }

    return (
    <NoDataContainer>
      <NoDataChart
        configId={`${component.id}-all`}
        height="120px"
        title={title}
        loading={component.loading}
      />
    </NoDataContainer>
    );
  }

  deleteDisabled = () => {
    const { component } = this.props;
    return !_.some(component.tags_data.map(t => t.baseline_info && t.baseline_info.start_time));
  }

  getOutliers = () => {
    const { showValidationOutliers, data } = this.state;
    const { component } = this.props;

    const outliers = [];
    if (_.isEmpty(showValidationOutliers)) return outliers;

    const tagsData = component && component.tags_data &&
      component.tags_data.filter(t => !_.isEmpty(t.trend));

    tagsData.forEach((tagData, idx) => {
      if (!showValidationOutliers[tagData.tag_id]) return;
      if (_.isEmpty(data[idx])) return;

      const validation = tagData.validation;
      if (_.isEmpty(validation) || _.isEmpty(validation.outliers_validation)) return;

      let tagOutliers = validation.outliers_validation.outliers;
      if (_.isEmpty(tagOutliers)) return;

      tagOutliers = convertTimestringsToDateObjects(tagOutliers);

      data[idx].forEach((point) => {
        const is_outlier = !!_.find(tagOutliers, d => d.valueOf() === point.x.valueOf());
        if (is_outlier) {
          outliers.push(point);
        }
      });
    });

    return outliers;
  }

  render() {
    const { component, isMultiSetActive, isMultiSetItem, theme, ahsFeaturesInfo, tagTypes, isDetailsViewOpen } = this.props;
    const { data, domain, range, baseline_info, showValidationOutliers } = this.state;
    const tags_data = component.tags_data.filter(t => !_.isEmpty(t.trend));
    const tag_ids = component.tags_data.filter(t => !_.isEmpty(t.trend)).map(t => t.tag_id);
    const yUnit = !_.isEmpty(tags_data) ? tags_data[0].units : '';

    const validationLoading = machineMLTypes.VALIDATE_COMPONENT_BASELINE in this.props.loadingReducer ?
      this.props.loadingReducer[machineMLTypes.VALIDATE_COMPONENT_BASELINE] : false;
    const saveLoading = tags_data && _.some(tags_data.map(tagData => tagData.saveLoading));

    if (_.isEmpty(tags_data) || _.isEmpty(data) || _.every(data, d => _.isEmpty(d))) {
      return (
        <>
        {this.renderNoDataChart()}
        </>
      );
    }
    return (
      <>
      <FlexContainer direction="row" width="80%" alignSelf="center">
        {!_.isEmpty(this.state.selections) && (
            <MultilineSelection
              selections={this.state.selections}
              yUnits={tags_data.map(t => t.units)}
              colors={chartColors}
              yParameters={tags_data.map(t => `${t.location_description || t.location_name || component.name} ${CapitalizeEachWord(t.tag_type)}`)}
            />
        )}
      </FlexContainer>
      <ChartContainer>
        <>
          {component.loading && (
            <AbsoluteLoading />
          )}
          <Chart
            type="baseline"
            chartName={`all-baseline-${component.id}`}
            data={data}
            colors={[theme.primaryColor, ...chartColors.slice(1)]}
            activeData={data.map(() => true)}
            title={`Set ${component.name} Baselines`}
            contextChart
            height="120px"
            loading={component.loading}
            range={range}
            domain={domain}
            updateBaseline={this.updateBaseline}
            baselineStart={baseline_info.start_time}
            baselineEnd={baseline_info.end_time}
            outliersArray={this.getOutliers()}
            isMultiSetActive={isMultiSetActive}
            setMultiSetItem={() => this.props.multiSetComponentHandler(component.id, component.name, tag_ids, isMultiSetItem, 'component')}
            isMultiSetItem={isMultiSetItem}
            ylabel="Multiline"
            yUnit={yUnit}
            mouseover={this.mouseoverChart}
            chart_size="normal"
          />
        </>
      </ChartContainer>
      <ActionButtons>
        {this.state.deleteConfirm ? (
          <FlexContainer alignItems="center">
            <span>Delete all baselines ?</span>
            <Button onClick={this.deleteBaselines}>Yes</Button>
            <Button onClick={this.toggleDeleteConfirm}>No</Button>
          </FlexContainer>
        ) : (
          <Button
            disabled={this.deleteDisabled() || isMultiSetItem || saveLoading}
            onClick={this.toggleDeleteConfirm}
          >
            Delete
          </Button>
        )}
        <FlexContainer>
          {this.state.saved && !this.state.edited &&
            <span>New baseline saved</span>
          }
          <BaselineValidator
            validationLoading={validationLoading}
            saveLoading={saveLoading}
            buttonDisabled={this.props.isMultiSetItem || _.isEqual(baseline_info, this.props.baseline_info)}
            component={this.props.component}
            toggleOutliers={this.toggleValidationOutliers}
            outliersShown={showValidationOutliers}
            isMultiSetActive={isMultiSetActive}
            onSave={this.save}
            user={this.props.user}
          />
          <Button
            disabled={saveLoading || this.props.isMultiSetItem || _.isEqual(baseline_info, this.props.baseline_info) && !this.props.seedHealthScoreChanged}
            onClick={this.cancel}
          >
            Clear
          </Button>
        </FlexContainer>
      </ActionButtons>
        {isDetailsViewOpen && (
          <BaselineDetails
            baselineInfo={baseline_info}
            ahsFeaturesInfo={ahsFeaturesInfo}
            tagTypes={tagTypes}
            componentId={component.id}
            component={component}
            startTime={baseline_info.start_time}
            endTime={baseline_info.end_time}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  loadingReducer: state.loadingReducer,
  user: state.user.user
});

export default connect(mapStateToProps, null)(withTheme(MultiTagsBaselineItem));
