import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as _ from 'lodash';

import Label_T from 'common/typography/Label/Label';
import FlexContainer from 'common/components/atoms/FlexContainer';
import { CapitalizeEachWord } from 'common/utils';
import RBAC from 'common/rbac/RBAC';
import {
  mapComponentToResource,
  operations
} from 'common/rbac/constants';
import { humanize } from 'common/helpers/humanize';

import InputFieldBold from '../molecules/InputFieldBold/InputFieldBold';
import RowContainer from '../atoms/RowContainer/RowContainer';

const SettingsLabel = styled(Label_T)`
  color: #3C3D3A;
  font-size: 20px;
  font-weight: 300;
  margin-top:0.8em;
  margin-bottom: 1.2em;                                                                                                                                                                                                                                                                        
`;

const WarningDescription = styled(FlexContainer)`
  font-size: 12px;
  color: #999B95;
  margin-bottom: 19px;
  margin-top: -10px;
  font-weight: 800;
`;

const Dimensions = (props) => {
  const {
    dimensions,
    value,
    onChangeField,
    isIncomplete
  } = props;

  return (
    <>
        <SettingsLabel>Dimensions</SettingsLabel>
        <FlexContainer marginbottom="3em" width="50%">
        {dimensions.map(([dimensionKey, dimensionProperty], dimensionIdx) => {
          const inputProps = {
            id: dimensionKey,
            name: dimensionKey,
            value: value[dimensionKey] === 0 ? '' : value[dimensionKey],
            type: 'number',
            label: dimensionProperty.display_name || CapitalizeEachWord(dimensionKey.replace(/[_]/g, ' ')),
            selectOnBlur: false,
            prefix: dimensionProperty.units,
            placeholder: dimensionProperty.placeholder ? dimensionProperty.placeholder : 'Enter Value',
            onChange: (e, data) => {
              let newValue = null;
              if (e.target) {
                newValue = e.target.value;
              } else if (data) {
                newValue = data.value;
              }
              onChangeField({}, { value: { ...value, [dimensionKey]: Number(newValue) }, name: 'dimensions' });
            }
          };
          return (
            <RBAC
              key={`componentDetails-dimensions.${dimensionKey}`}
              resource={mapComponentToResource.ComponentDetails}
              operation={operations.Update}
              yes={(
                <FlexContainer marginright={dimensionIdx + 1 === _.size(dimensions) ? '0px' : '5px'} direction="column">
                  <InputFieldBold {...inputProps} />
                  {isIncomplete && (
                    <WarningDescription>
                      Recommended to set {humanize(inputProps.id)} for better Analysis
                    </WarningDescription>
                  )}
                </FlexContainer>
            )}
              no={(
                <RowContainer>
                  <InputFieldBold {...inputProps} disabled />
                </RowContainer>
            )}
            />
          );
        }
        )}
        </FlexContainer>
    </>
  );
};

Dimensions.propTypes = {
  dimensions: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  onChangeField: PropTypes.func.isRequired,
  isIncomplete: PropTypes.bool
};

Dimensions.defaultProps = {
  isIncomplete: false
};

export default Dimensions;
