export const PAGE_STATE = {
  SELECT_ASSETS: 'SELECT_ASSETS',
  SELECT_TAGS: 'SELECT_TAGS',
  CHART_PREVIEW: 'CHART_PREVIEW'
};

export const HEADERS = {
  MACHINE_SELECTED: 'Please select up to 10 assets that you wish to compare with ',
  MACHINE_NOT_SELECTED: 'Please select up to 10 assets that you wish to compare tags from',
  EDIT_MODE: 'Please add more assets to the config if required or '
};

export const CHART_ACTIONS = {
  GET_COMPARE_TAGS_CHARTS_RESULTS: 'GET_COMPARE_TAGS_CHARTS_RESULTS',
  CREATE_COMPARE_TAGS_CHARTS: 'CREATE_COMPARE_TAGS_CHARTS'
};
