import React from 'react';
import * as _ from 'lodash';
import InfoboxContainer from '../../atoms/InfoboxContainer/InfoboxContainer';
import SelectionTitle from '../../atoms/SelectionTitle/SelectionTitle';

const InfoboxTitle = ({ children, ...rest }) => (
  <InfoboxContainer>
    <SelectionTitle>{children}</SelectionTitle>
  </InfoboxContainer>
);


export default InfoboxTitle;
