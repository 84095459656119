import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Check, Minus } from '../../images/FaIcons';
import Label_T from '../../typography/Label/Label';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: block !important;
  visibility: visible;
  opacity: 0;
`;

const Label = styled(Label_T)`
  margin-bottom: 0px;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
  ${props => (props.disabled && `
    &:hover {
      cursor: not-allowed;
    }
    opacity: 0.5;
  `)}
`;

const ButtonElement = styled.label`
  display: flex;
  align-items: center;
  position: absolute;
  color: ${props => (props.selected ? 'black' : 'grey')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 1px solid ${props => props.theme.colors.borderGray};
  border-radius: 4px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.white};
  padding: 3px;
  svg:not(:root) {
    color: ${props => props.theme.primaryColor} !important;
    overflow: visible;
    width: 150%;
    height: 150%;
  }
  ${props => (props.selected ? `
    border-color: ${props.theme.primaryColor};
  ` : '')}
  ${props => (props.error ? `
    border-color: ${props.theme.colors.error};
  ` : '')}
  ${props => (props.disabled && `
    cursor: not-allowed;
    opacity: 0.5;
  `)}
`;

const Container = styled.div`
  position: relative;
  display: inline-flex;
  margin: 0;
  height: 1.2em;
  width: 1.2em;
  z-index: 2;
  ${HiddenCheckbox}:focus {
    + ${ButtonElement} {
      border-color: ${props => props.theme.colors.borderActive};
    }
  }
  ${ButtonElement}:hover {
    border-color: ${props => props.theme.colors.borderGrayHover};
  }
  ${props => props.inputField && `
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  `}
`;

const Checkbox = ({
  id,
  disabled,
  value,
  onChange,
  error,
  onClick,
  className,
  minus,
  title,
  inputField
}) => {
  let checkbox;
  return (
    <>
      <Container
        id={id}
        className={className}
        checked={value}
        inputField={inputField}
        onClick={(() => {
          if (!disabled) {
            if (onClick) onClick();
            else onChange(!checkbox.checked);
          }
        })}
      >
        <HiddenCheckbox disabled={disabled} checked={value} innerRef={(ref) => { checkbox = ref; }} readOnly />
        <ButtonElement disabled={disabled} selected={value} error={error}>
          {value && <Check />}
          {minus && <Minus />}
        </ButtonElement>
      </Container>
      {title && (
        <Label
          onClick={(() => {
            if (!disabled) {
              if (onClick) onClick();
              else onChange(!checkbox.checked);
            }
          })}
          disabled={disabled}
        >{title}
        </Label>
      )}
    </>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string
};

Checkbox.defaultProps = {
  disabled: false,
  value: false,
  onChange: () => {},
  error: undefined
};

Checkbox.displayName = 'Checkbox';

export default styled(Checkbox)``;
