import React from 'react';
import PropTypes from 'prop-types';

const NoteSvgNew = ({ width, height, fill }) => (
<svg width={width} height={height} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g id="Web---Single" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="1.0.0-Single-New_reply" transform="translate(-455.000000, -582.000000)">
      <g transform="translate(413.000000, 199.000000)" id="event">
        <g transform="translate(40.000000, 381.000000)">
          <g id="note-icon">
            <rect id="icon-bg" x={0} y={0} width={20} height={20} />
            <g id="icon" transform="translate(3.000000, 3.000000)">
              <path d="M14,9.33333333 C14,10.1924429 13.3035541,10.8888889 12.4444444,10.8888889 L3.11111111,10.8888889 L0,14 L0,1.55555556 C0,0.696445945 0.696445945,0 1.55555556,0 L12.4444444,0 C13.3035541,0 14,0.696445945 14,1.55555556 L14,9.33333333 Z" id="Shape" stroke="#3C3D3A" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
              <rect id="line" fill={fill} fillRule="nonzero" x={3} y={2} width={8} height="1.3" rx="0.65" />
              <rect id="line" fill={fill} fillRule="nonzero" x={3} y="4.5" width={8} height="1.3" rx="0.65" />
              <rect id="line" fill={fill} fillRule="nonzero" x={3} y={7} width={4} height="1.3" rx="0.65" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

);

NoteSvgNew.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

NoteSvgNew.defaultProps = {
  width: 16,
  height: 16,
  fill: '#3C3D3A'
};


export default NoteSvgNew;
