import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';

import LineChart from './components/LineChart';
import DonutChart from './components/DonutChart';
import UtilizationChart from './components/UtilizationChart';
import BaselineChart from '../../../home/Machines/MachineDetails/Health/components/BaselineChart';
import TrendChart from '../../../home/Machines/MachineDetails/MachineCharts/components/TrendChart';
import WaveformChart from '../../../home/Machines/MachineDetails/MachineCharts/components/WaveformChart';
import SpectrumChart from '../../../home/Machines/MachineDetails/MachineCharts/components/SpectrumChart';
import FeatureChart from '../../../home/Machines/MachineDetails/MachineCharts/components/FeatureChart';
import OverallAlarmChart from '../../../home/Machines/MachineDetails/MachineCharts/components/OverallAlarmChart';
import MultilineChart from '../../../home/Machines/MachineDetails/MachineCharts/components/MultilineChart';
import SimpleLineContext from './components/SimpleLineContext';

const Container = styled.div`
  ${props => props.topPadding && 'padding-top: 20px;'} padding-left: 55px;
  padding-bottom: ${props => props.noBottomPadding ? '0' : '16px'};
  ${props =>
    props.noPadding && `
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  `}
  ${props =>
    props.noLabel && `
    padding-left: 30px;
  `}
  flex-grow: 1;
  svg:not(:root) {
    overflow: visible;
  }
  g line {
    stroke: lightgray;
    stroke-width: 1px;
  }
  ${props => props.noTopPadding && 'padding-top: 0;'}
`;

const Chart = ({ type, features, noLabel, ...rest }) => (
  <Container
    className={`chartContainer ${type}`}
    noPadding={type === 'donut'}
    noTopPadding={type === 'overallAlarm'}
    noBottomPadding={rest.miniMode}
    noLabel={noLabel}
    topPadding={
      (features && features.items && features.items.length > 0) || (features && features.length > 0)
    }
  >
    {type === 'line' && <LineChart type={type} {...rest} />}
    {type === 'multiline' && <MultilineChart type={type} {...rest} />}
    {(type === 'spectrum' || type === 'demod_spectrum') && <SpectrumChart type={type} features={features} {...rest} />}
    {type === 'feature' && <FeatureChart type={type} features={features} {...rest} />}
    {type === 'overallAlarm' && <OverallAlarmChart {...rest} type={type} />}
    {type === 'waveform' && <WaveformChart type={type} {...rest} />}
    {(type === 'trend' || type === 'demod_trend') && <TrendChart type={type} {...rest} />}
    {type === 'baseline' && <BaselineChart type={type} {...rest} />}
    {type === 'utilization' && <UtilizationChart type={type} {...rest} />}
    {type === 'donut' && <DonutChart type={type} {...rest} />}
    {type === 'context' && <SimpleLineContext type={type} {...rest} />}
  </Container>
);

Chart.propTypes = {
  type: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  hierarchyViewPane: state.hierarchyViewPane
});

Chart.defaultProps = {};

Chart.displayName = 'Chart';

export default connect(mapStateToProps, {})(withTheme(Chart));
