/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as _ from 'lodash';

import LoadingSvg from 'common/components/atoms/Loading';
import FooterFlex_T from 'common/components/atoms/FooterFlex';

import MachineBuilder from '../../MachineBuilder/components/MachineBuilder';
import * as machineBuilderActions from '../../MachineBuilder/actions/machineBuilder.actions';
import * as machineInfoActions from './actions/machineInfo.actions';
import { machinesActions } from '../../machines.actions';
import * as sensorsActions from '../../../Sensors/actions/sensors.actions';

const FooterFlex = styled(FooterFlex_T)`
  align-self: flex-end;
  position: relative;
  width: 100%;
`;

const Container = styled.div`
  background-color: white;
  height: calc(100vh - 165px);
`;

const MachineInfo = (props) => {
  const [footer, setFooter] = useState(null);
  useEffect(() => {
    const partner = props.partner;
    const title = partner.title;
    document.title = `${title} | Info `;
    if (_.isEmpty(props.machineBuilder.metadata)) {
      props.machineBuilderActions.getMachineBuilderMetadata();
    }
    if (
      (_.isEmpty(props.info.details) && !props.info.loadingDetails) ||
      (!_.isEmpty(props.info.details) && props.info.details.id !== props.match.params.machine_id)
    ) {
      props.machineInfoActions.getMachineInfo(props.match.params.machine_id);
    }
    if (_.isEmpty(props.sensorsMetadata)) {
      props.sensorsActions.getSensorsMetadata();
    }
  }, []);

  return (
    <Container>
      {(_.isEmpty(props.machineBuilder.metadata) || props.machineBuilder.metadata.loading || _.isEmpty(props.info.details) || props.info.loadingDetails) ? (
        <div style={{ height: '100%' }}>
          <LoadingSvg center />
        </div>
      ) : (
        !(props.machineBuilder.metadata.error || props.info.error) ? (
            <MachineBuilder
              isInfo
              setFooter={setFooter}
              buildType="machine"
            />
        ) : (
              <span>{props.machineBuilder.metadata.error || props.info.error}</span>
        )
      )}
      {footer && (
        <FooterFlex left="0">
          {typeof footer === 'function' ? footer()
            : footer.items.map(Item =>
              <Item.Tag {...Item.props}>{Item.children}</Item.Tag>)}
        </FooterFlex>)
      }
    </Container>
  );
};

const mapStateToProps = state => ({
  machineBuilder: state.machines.machineBuilder,
  info: state.machineDetails.info,
  sensorsMetadata: state.sensors.metadata.metadata,
  partner: state.companyReducer.partner
});

const mapDispatchToProps = dispatch => ({
  machineBuilderActions: bindActionCreators(machineBuilderActions, dispatch),
  machineInfoActions: bindActionCreators(machineInfoActions, dispatch),
  machinesActions: bindActionCreators(machinesActions, dispatch),
  sensorsActions: bindActionCreators(sensorsActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MachineInfo);
