import colors from 'common/styles/colors';

export const companyData = {
  Petasense: {
    name: 'Petasense',
    corporation: 'Petasense Inc',
    logo: '',
    theme: {
      primaryColor: colors.green,
      secondaryColor: '#a6d69f',
      backgroundColor: '#EDF3E1',
      grey: '#999B95',
      backgroundA: a => `rgba(145, 184, 70, ${a})`
    },
    title: 'Petasense ARO Webapp',
    urlKey: 'petasense'
  },
  ntn: {
    name: 'NTN',
    corporation: 'NTN Bearing Corporation of America',
    logo: '',
    theme: {
      primaryColor: 'rgb(0, 131, 202)',
      secondaryColor: '#acdde8',
      backgroundColor: '#e4f4f7',
      grey: '#7f8a88',
      backgroundA: a => `rgb(0, 131, 202, ${a})`
    },
    title: 'NTN',
    urlKey: 'ntnguardian'
  },
};

export const getActivePartnerBasedOnDomain = () => {
  const fullHostName = window.location.hostname;
  const lowerCaseHostName = fullHostName.toLowerCase();
  let partner;
  if (lowerCaseHostName.includes('ntn')) {
    partner = companyData.ntn;
  } else {
    partner = companyData.Petasense;
  }


  const link = document.querySelector('link[rel="icon"]');
  if (partner.name === 'NTN') {
    link.setAttribute('href', '/favicon-ntn.ico');
  } else {
    link.setAttribute('href', '/favicon.ico');
  }

  return partner;
};

export const getBaseUrl = () => {
  const partner = getActivePartnerBasedOnDomain();
  let url = process.env.REACT_APP_BASE_URL;
  if (partner.name !== 'Petasense') {
    url = url.replace(companyData.Petasense.urlKey, partner.urlKey);
  }
  return url;
};
