import { combineReducers } from 'redux';

import {
  devicesHealthReducer,
  missedMeasurementsList,
  missedMeasurementsFilters,
  missedMeasurementsSorter,
  sensorMissedTimestampsList,
  sensorMissedTimestampsSorter,
  selectedSensor
} from './devicesHealth.reducer';

const missedMeasurements = combineReducers({
  missedMeasurementsList,
  missedMeasurementsFilters,
  missedMeasurementsSorter,
  sensorMissedTimestampsList,
  sensorMissedTimestampsSorter,
  selectedSensor
});

export const devicesHealth = combineReducers({
  devicesHealth: devicesHealthReducer,
  missedMeasurements
});
