import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import InputField from 'common/components/atoms/InputField';
import { Loading, FlexContainer } from 'common/components/atoms';
import * as machineChartsFeaturesActions from 'home/Machines/MachineDetails/MachineCharts/actions/machineChartsFeatures.actions';
import ListContainer from './atoms/ListContainer';
import Footer from './atoms/Footer';
import Attachments from '../organisms/Attachments';

function NoteDetails(props) {
  const [noteText, setNoteText] = useState(props.noteDetails.note_text);
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [attachmentsError, setAttachmentsError] = useState(false);

  const onNoteSave = () => {
    const params = {
      note_text: noteText
    };
    if (props.taskId) params.task_id = props.taskId;
    setShowSaveLoader(true);
    props.machineChartsFeaturesActions.addNote(props.machineId || props.noteDetails.machine_id, params).then(
      () => {
        if (_.isEmpty(props.files)) {
          setShowSaveLoader(false);
          if (!props.taskId) props.closeModal();
          else props.toggleExpand();
        }
      },
      () => {
        setShowSaveLoader(false);
      }
    );
  };

  const onNoteUpdate = () => {
    const params = {
      note_text: noteText
    };
    const noteId = props.noteDetails.activity_id || props.noteDetails.id;
    setShowSaveLoader(true);
    props.machineChartsFeaturesActions.updateNote(noteId, params).then(
      () => {
        if (!props.taskId) props.closeModal();
        setShowSaveLoader(false);
      },
      () => {
        setShowSaveLoader(false);
      }
    );
  };

  const isSaveDisabled = () => (
    showSaveLoader || attachmentsError ||
    (props.isEditMode ? noteText === props.noteDetails.note_text : noteText === '')
  );

  useEffect(() => {
    // checks if upload has been completed and closes the modal
    const completed = props.upload && props.upload.index === props.files.length - 1 && props.upload.progress >= 100;
    if (!props.isEditMode && completed) {
      setShowSaveLoader(false);
      if (!props.taskId) props.closeModal();
      else props.toggleExpand();
    }
  }, [props.upload]);

  return (
    <>
      <FlexContainer position="relative">
        <ListContainer width="100%" paddingBottom="0">
          <InputField
            type="textarea"
            placeholder="Type your note here..."
            height="100px"
            value={noteText}
            onChange={e => setNoteText(e.target.value)}
            disabled={props.isEditMode && props.noteDetails.user_id !== props.user.id}
          />
          <Attachments
            originType="note"
            originId={props.noteDetails.id}
            attachments={props.noteDetails.attachments}
            allowUpload={props.isEditMode}
            showExistingAtts={props.isEditMode}
            showSelectedFiles={!props.isEditMode}
            setAttachmentsError={setAttachmentsError}
          />
        </ListContainer>
        {showSaveLoader && <Loading position="absolute" height="100%" backgroundColor="#e4e4e447" />}
      </FlexContainer>
      <Footer
        paddingTop="0"
        showCancel={props.showCancel}
        onCancel={props.closeModal}
        onSave={props.isEditMode ? onNoteUpdate : onNoteSave}
        saveDisabled={isSaveDisabled()}
      />
    </>
  );
}

const mapStateToProps = state => ({
  machineId: state.machineDetails.details.object && state.machineDetails.details.object.id,
  user: state.user.user,
  noteDetails: state.noteModal.openNoteDetails,
  files: state.attachments.files && state.attachments.files.note,
  upload: state.attachments.upload && state.attachments.upload.note
});

const mapDispatchToProps = dispatch => ({
  machineChartsFeaturesActions: bindActionCreators(machineChartsFeaturesActions, dispatch),
});

NoteDetails.propTypes = {
  note_text: PropTypes.string,
  toggleExpand: PropTypes.func,
  closeModal: PropTypes.func,
  isEditMode: PropTypes.bool,
  taskId: PropTypes.number,
  machine_id: PropTypes.number,
  showCancel: PropTypes.bool
};

NoteDetails.defaultProps = {
  note_text: '',
  toggleExpand: () => {},
  closeModal: () => {},
  isEditMode: false,
  taskId: null,
  machine_id: null,
  showCancel: true
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteDetails);
