import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import FlexContainer from 'common/components/atoms/FlexContainer';
import H2 from 'common/typography/H2/H2';
import InputField_T from 'common/components/atoms/InputField';
import MultiSelectDropdown_T from 'common/components/molecules/MultiSelectDropdown';
import Button_T from 'common/components/atoms/Button';
import Error_T from '../../atoms/Error';

const Button = styled(Button_T)`
  margin-right: 20px;
`;
const Error = styled(Error_T)`
  padding: 0 0 10px 0;
`;

const MultiSelectDropdown = styled(MultiSelectDropdown_T)`
  max-width: 500px;
`;

const InputField = styled(InputField_T)`
  max-width: 250px;
`;

class OverallAlarmOtherSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBins: props.selectedBins
    };
  }

  componentDidMount() {
    const { binFilterOptions, selectedBins, setSettingsDisabled } = this.props;
    if (binFilterOptions.length > 1 && selectedBins.length === 0) {
      setSettingsDisabled(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.selectedBins, this.props.selectedBins)) {
      const { binFilterOptions, selectedBins, setSettingsDisabled } = this.props;
      this.setState({ selectedBins });
      if (binFilterOptions.length > 1 && selectedBins.length === 0) {
        setSettingsDisabled(true);
      }
    }
  }

  toggleOption = (value) => {
    let selectedBins = _.cloneDeep(this.state.selectedBins);
    if (selectedBins.includes(value)) {
      selectedBins = _.filter(selectedBins, bin => bin !== value);
    } else selectedBins.push(value);

    if (selectedBins.length === 0 || !_.isEqual(selectedBins, this.props.selectedBins)) this.props.setSettingsDisabled(true);
    else this.props.setSettingsDisabled(false);

    this.setState({ selectedBins });
  };

  isViewSettingsDisabled = () => {
    const { selectedBins } = this.state;
    const { overallAlarmSettings, settingsShown } = this.props;
    return _.isEmpty(selectedBins) ||
    _.some(selectedBins.map(bin => !overallAlarmSettings[bin.toString()] ||
      !_.isEqual(overallAlarmSettings[bin.toString()], overallAlarmSettings[selectedBins[0].toString()]))) ||
    _.isEqual(this.state.selectedBins, this.props.selectedBins) && _.isEqual(settingsShown, overallAlarmSettings[selectedBins[0].toString()]);
  }

  isApplySettingsDisabled = () => _.isEmpty(this.state.selectedBins) ||
   _.isEqual(this.state.selectedBins, this.props.selectedBins)

  onClickViewSettings = () => {
    this.props.onBinChange(this.state.selectedBins, false);
    this.props.setSettingsDisabled(false);
  }

  onClickApplySettings = () => {
    this.props.onBinChange(this.state.selectedBins);
    this.props.setSettingsDisabled(false);
  }

  render() {
    const {
      outlierLimit,
      binFilterOptions,
      updateOverallAlarmSettings,
      yUnit,
      disabled
    } = this.props;
    const { selectedBins } = this.state;
    return (
      <FlexContainer direction="column">
        <H2>&nbsp;</H2>
        {binFilterOptions.length > 1 && (
          <FlexContainer direction="column" alignItems="flex-start" padding="0 0 20px 0">
            <MultiSelectDropdown
              className="ed-multi-select-dropdown"
              optionsAvailable={binFilterOptions}
              selectedOptions={selectedBins}
              disableSelectAll
              toggleOption={this.toggleOption}
              typeOfOptions="Speed Ranges"
              id="vfd-bins-select"
              label="VFD Speed Ranges"
              placeholder="Select VFD Speed Ranges"
            />
            {(_.isEmpty(selectedBins) || _.isEmpty(this.props.selectedBins)) && (
              <Error>Please select a bin</Error>
            )}
            <FlexContainer>
              <Button
                text
                onMouseDown={this.onClickApplySettings}
                disabled={this.isApplySettingsDisabled()}
              >
                Apply Settings
              </Button>
              <Button
                text
                onMouseDown={this.onClickViewSettings}
                disabled={this.isViewSettingsDisabled()}
              >
                View Settings
              </Button>
            </FlexContainer>
          </FlexContainer>
        )}
        <InputField
          type="number"
          label="Outlier limit"
          prefix={yUnit}
          value={outlierLimit}
          onChange={(e) => {
            updateOverallAlarmSettings('lower_outlier_limit', e.target.value);
          }}
          disabled={disabled}
        />
      </FlexContainer>
    );
  }
}

OverallAlarmOtherSettings.propTypes = {
  updateOverallAlarmSettings: PropTypes.func.isRequired,
  outlierLimit: PropTypes.number,
  selectedBins: PropTypes.array.isRequired,
  onBinChange: PropTypes.func.isRequired,
  binFilterOptions: PropTypes.array.isRequired,
  yUnit: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

OverallAlarmOtherSettings.defaultProps = {
  outlierLimit: null,
  disabled: false,
};

export default OverallAlarmOtherSettings;
