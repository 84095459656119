import { toastr } from 'react-redux-toastr';

import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse } from 'common/helpers';

import { firmwareDeploymentConstants } from '../constants/firmwareDeployment.constants';

export const getFirmwareDeployments = (filters, page, all = false) => (dispatch, getState) => {
  const request = page => ({ type: firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_REQUEST, page });
  const success = (items, page, total_pages, total_count, all) => (
    { type: firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_SUCCESS, items, page, total_pages, total_count, all }
  );
  const failure = error => ({ type: firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_FAILURE, error });
  const firmwareDeploymentsState = getState().firmwareDeployments;
  const params = {
    page: page || firmwareDeploymentsState.deployments.page,
    filters: filters || firmwareDeploymentsState.filters.filters,
    per_page: firmwareDeploymentConstants.NUMBER_OF_FIRMWARE_DEPLOYMENTS_PER_PAGE,
    all
  };
  dispatch(request(params.page));

  return handleResponse(axiosInstance.get(ENDPOINT.GET_FIRMWARE_DEPLOYMENTS, { params })).then(
    (res) => {
      dispatch(success(res.items, res.page, res.total_pages, res.items.length, all));
      return res;
    },
    (err) => {
      dispatch(failure(err));
      toastr.error(err.message);
    }
  );
};

export const loadMoreFirmwareDeployments = () => (dispatch, getState) => {
  const deployments = getState().firmwareDeployments.deployments;
  const page = deployments.page;
  if (deployments.listLoading) return;
  dispatch({ type: firmwareDeploymentConstants.INCREASE_FIRMWARE_DEPLOYMENT_PAGE, page: page + 1 });
  dispatch(getFirmwareDeployments(null, page + 1));
};

export const setFirmwareDeploymentFilters = filters => (dispatch) => {
  dispatch({ type: firmwareDeploymentConstants.SET_FIRMWARE_DEPLOYMENT_FILTERS, filters });
  dispatch(getFirmwareDeployments(filters, 1, true), 1);
};

export const getFirmwares = params => (dispatch) => {
  const request = () => ({ type: firmwareDeploymentConstants.GET_FIRMWARES_REQUEST });
  const success = items => ({ type: firmwareDeploymentConstants.GET_FIRMWARES_SUCCESS, items });
  const failure = error => ({ type: firmwareDeploymentConstants.GET_FIRMWARES_FAILURE, error });

  dispatch(request());
  return handleResponse(axiosInstance.get(ENDPOINT.GET_FIRMWARES, { params })).then(
    (res) => {
      dispatch(success(res.items));
      return res;
    },
    (err) => {
      dispatch(failure(err));
      toastr.error(err.message);
    });
};

export const addFirmwareDeployment = params => dispatch => handleResponse(
  axiosInstance.post(ENDPOINT.CREATE_FIRMWARE_DEPLOYMENT, params)).then(
  (res) => {
    toastr.success('Firmware deployment created successfully');
    dispatch(getFirmwareDeployments(null, 1, true));
    return res;
  },
  (err) => {
    toastr.error(err.message);
  }
);

export const verifyDeviceDeployment = params => () => handleResponse(
  axiosInstance.get(ENDPOINT.VERIFY_DEVICE_DEPLOYMENTS, { params })).then(
  res => res,
  (err) => {
    toastr.error(err.message);
    throw err;
  }
);
export const updateFirmwareDeployment = (deployment_id, params) => dispatch => handleResponse(
  axiosInstance.put(ENDPOINT.UPDATE_FIRMWARE_DEPLOYMENT(deployment_id), params)).then(
  (res) => {
    toastr.success('Firmware deployment updated successfully');
    dispatch(getFirmwareDeployments(null, 1, true));
    return res;
  },
  (err) => {
    toastr.error(err.message);
  }
);

export const getAllOpsTx = (page = 1, pagination = 0) => (dispatch) => {
  const request = () => ({ type: firmwareDeploymentConstants.GET_ALL_OPS_TX_SENSOR_REQUEST });
  const success = items => ({ type: firmwareDeploymentConstants.GET_ALL_OPS_TX_SENSOR_SUCCESS, items });
  const failure = error => ({ type: firmwareDeploymentConstants.GET_ALL_OPS_TX_SENSOR_FAILURE, error });

  dispatch(request());
  return handleResponse(axiosInstance.get(ENDPOINT.GET_ALL_OPS_TX(page, pagination))).then(
    (res) => {
      dispatch(success(res.objects));
      return res;
    },
    (err) => {
      dispatch(failure(err));
    });
};

export const getAllOpsMotes = (page = 1, pagination = 0) => (dispatch) => {
  const request = () => ({ type: firmwareDeploymentConstants.GET_ALL_OPS_MOTE_REQUEST });
  const success = items => ({ type: firmwareDeploymentConstants.GET_ALL_OPS_MOTE_SUCCESS, items });
  const failure = error => ({ type: firmwareDeploymentConstants.GET_ALL_OPS_MOTE_FAILURE, error });

  dispatch(request());

  return handleResponse(axiosInstance.get(ENDPOINT.GET_ALL_OPS_MOTES(page, pagination))).then(
    (res) => {
      dispatch(success(res.objects));
      return res;
    },
    (err) => {
      dispatch(failure(err));
    });
};

export const deleteFirmwareDeployment = deployment_id => dispatch => handleResponse(
  axiosInstance.delete(ENDPOINT.DELETE_FIRMWARE_DEPLOYMENT(deployment_id))).then(
  (res) => {
    toastr.success('Firmware deployment deleted successfully');
    dispatch(getFirmwareDeployments(null, 1, true));
    return res;
  },
  (err) => {
    toastr.error(err.message);
  }
);

export const getFirmwareDeploymentDetails = deployment_id => (dispatch) => {
  const request = () => ({ type: firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_DETAILS_REQUEST });
  const success = details => ({ type: firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_DETAILS_SUCCESS, details });
  const failure = error => ({ type: firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_DETAILS_FAILURE, error });

  dispatch(request());
  return handleResponse(axiosInstance.get(ENDPOINT.GET_FIRMWARE_DEPLOYMENT_DETAILS(deployment_id))).then(
    (res) => {
      dispatch(success(res));
    },
    (err) => {
      dispatch(failure(err.message));
      toastr.error(err.message);
    });
};

export const getDeploymentDevices = (deployment_id, search_key = '') => (dispatch) => {
  const request = () => ({ type: firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_AFFECTED_DEVICES_REQUEST });
  const success = devices => ({ type: firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_AFFECTED_DEVICES_SUCCESS, devices });
  const failure = error => ({ type: firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_DETAILS_FAILURE, error });

  const params = {
    search_key
  };
  dispatch(request());
  return handleResponse(axiosInstance.get(ENDPOINT.GET_FIRMWARE_DEPLOYMENT_DEVICES(deployment_id), { params })).then(
    (res) => {
      dispatch(success(res.affected_devices));
    },
    (err) => {
      dispatch(failure(err.message));
      toastr.error(err.message);
    });
};

export const retryDeployment = (deployment_id, device_ids) => (dispatch) => {
  const request = () => ({ type: firmwareDeploymentConstants.RETRY_FIRMWARE_DEPLOYMENT_DEVICES_REQUEST });
  const success = () => ({ type: firmwareDeploymentConstants.RETRY_FIRMWARE_DEPLOYMENT_DEVICES_SUCCESS });
  const failure = error => ({ type: firmwareDeploymentConstants.RETRY_FIRMWARE_DEPLOYMENT_DEVICES_FAILURE, error });

  dispatch(request());
  return handleResponse(
    axiosInstance.post(ENDPOINT.RETRY_FIRMWARE_DEPLOYMENT(deployment_id), { device_ids })).then(
    (res) => {
      dispatch(success());
      toastr.success(res.message);
      return res;
    },
    (err) => {
      dispatch(failure(err.message));
      toastr.error(err.message);
    }
  );
};
