import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  InputField,
  ScrollContainer,
} from 'common/components/atoms';

import {
  CapitalizeEachWord,
} from 'common/utils';

import machineAddActions from 'home/Machines/MachineAdd/actions/machineAdd.actions';
import templatesActions from 'home/Templates/actions/templates.actions';

import Modal from 'common/components/organisms/Modal';
import Button_T from 'common/components/atoms/Button';
import { isPetasenseAdmin } from 'common/rbac/util';

const Button = styled(Button_T)`
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  font-size: ${props => props.fontSize ? props.fontSize : '20px'};
  padding: 12px 16px;
  width: ${props => props.width ? props.width : 'auto'};
`;


const HeadingContainer = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between'};
  align-items: center;
  flex-direction: row-reverse;
`;

const ModalHeading = styled.div`
  font-size: 20px;
  line-height: 27px;
`;

const toLowerCaseUnderscore = (arr = []) => {
  const retArray = [];
  arr.forEach((item) => {
    retArray.push(item.toLowerCase().replace(/ /g, '_'));
  });
  return retArray;
};

class SaveTemplateModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      machineSubtypes: this.props.MACHINE_SUBTYPES.filter(subtype => subtype.type === this.props.mcType),
    };
  }

  componentDidMount() {
    this.props.saveTemplateDetails('assetType', this.props.mcType);
  }

  handleSubtypeAddition = (e, { value }) => {
    this.setState(prevState => ({ machineSubtypes: [{ text: value, value }, ...prevState.machineSubtypes] }));
    this.props.saveTemplateDetails('assetSubtype', value);
  };

  handleTypeChange = (ev, { value }) => {
    this.props.saveTemplateDetails('assetType', value);
    this.props.chooseMcType(value);
    this.setState({ machineSubtypes: this.props.MACHINE_SUBTYPES.filter(subtype => subtype.type === value) });
  };

  render() {
    const {
      currentUser,
      saveTemplateDetails,
      templateName,
      proceed,
      close,
      mcType,
    } = this.props;

    const machineTypeOptions = [];
    this.props.MACHINE_TYPES.forEach((type) => {
      machineTypeOptions.push({
        key: type,
        text: CapitalizeEachWord(type),
        value: type,
      });
    });

    return (
      <Modal
        width="50%"
        padding="0px 0px"
        headerHeight="88px"
        headerPadding="0px 12px"
        outerPadding="0px 12px 24px"
        title={() => (<ModalHeading>Save as Template</ModalHeading>)}
        close={close}
      >
        <ScrollContainer height="80vh" enabled style={{ padding: '0px 12px' }}>
          <InputField
            type="select"
            fluid
            selection
            placeholder="Select"
            marginBottom="24px"
            label="Type"
            value={mcType}
            style={{ alignItems: 'center' }}
            options={machineTypeOptions}
            onChange={this.handleTypeChange}
          />
          <InputField
            type="select"
            placeholder="Add/Select Machine Subtype"
            search
            options={this.state.machineSubtypes}
            allowAdditions
            onAddItem={this.handleSubtypeAddition}
            marginBottom="24px"
            label="Subtype"
            style={{ alignItems: 'center' }}
            onChange={(ev, { value }) => {
              saveTemplateDetails('assetSubtype', value);
            }}
          />
          <InputField
            type="text"
            value={templateName}
            placeholder="Template Name"
            marginBottom="24px"
            label="Name"
            style={{ alignItems: 'center' }}
            onChange={(ev) => {
              saveTemplateDetails('nodeName', ev.target.value);
            }}
          />
          <InputField
            type="text"
            placeholder="Template Description"
            marginBottom="24px"
            label="Description"
            style={{ alignItems: 'center' }}
            onChange={(ev) => {
              saveTemplateDetails('nodeDescription', ev.target.value);
            }}
          />
          {isPetasenseAdmin(currentUser) && (
            <InputField
              type="checkbox"
              marginBottom="24px"
              title="Save as Global Template"
              value={this.props.saveAsGlobal}
              onChange={(value) => {
                this.props.setSaveAsGlobal(value);
              }}
            />
          )}
          <HeadingContainer style={{ marginTop: '40px' }} justifyContent="flex-start">
            <Button success fontSize="12px" marginLeft="16px" onClick={proceed}> SAVE </Button>
            <Button secondary fontSize="12px" onClick={close}> CANCEL </Button>
          </HeadingContainer>
        </ScrollContainer>
      </Modal>
    );
  }
}

SaveTemplateModal.propTypes = {
  close: PropTypes.func.isRequired,
};

SaveTemplateModal.defaultProps = {
};

const mapStateToProps = (state) => {
  const mcType = state.machines.machineAdd.machineAddOptions.mcType;
  const asset_types = state.machines.machineBuilder.metadata.asset_types;
  const asset_subtypes = state.machines.machineBuilder.metadata.asset_sub_types;
  const available_subtypes = state.machines.machineBuilder.metadata.available_sub_types;
  const mappedSubtypes = [];
  asset_types.forEach((type) => {
    const arr = _.uniq(toLowerCaseUnderscore(_.union(available_subtypes[type], asset_subtypes[type])));
    arr.forEach((subtype) => {
      mappedSubtypes.push({
        key: subtype,
        text: CapitalizeEachWord(subtype),
        value: subtype,
        type,
      });
    });
  });
  return ({
    mcType,
    MACHINE_TYPES: state.machines.machineBuilder.metadata.asset_types,
    MACHINE_SUBTYPES: mappedSubtypes,
    saveAsGlobal: state.templates.saveAsGlobal,
    currentUser: state.user.user
  });
};

const mapDispatchToProps = dispatch => ({
  setSaveAsGlobal: bindActionCreators(templatesActions.setSaveAsGlobal, dispatch),
  chooseMcType: bindActionCreators(machineAddActions.chooseMcType, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveTemplateModal);
