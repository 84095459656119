import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import FilterIcon from 'common/images/FilterIconSvg';
import OutsideAlerter from 'common/OutsideAlerter';
import FilterItem from 'common/components/atoms/FilterItem';
import { calendarOptions } from 'common/charts/constants';

import { connect } from 'react-redux';
import { mapDayToString } from '../../constants';
import H1 from '../../typography/H1/H1';
import FlexContainer from '../atoms/FlexContainer';
import FooterFlex from '../atoms/FooterFlex';
import Background from '../atoms/Background';
import CrossSvg from '../../images/CrossSvg';
import Maybe from '../atoms/Maybe';
import CalendarSvg from '../../images/CalendarSvg';
import MaybeContainer from '../atoms/MaybeContainer';
import ModalScrollContainer from '../atoms/ModalScrollContainer';
import FilterDropdown from '../atoms/FilterDropdown';
import SvgContainer from '../atoms/SvgContainer';
import ModalContainer from '../molecules/ModalContainer';
import ModalContent from '../molecules/ModalContent';
import Filter_T from '../molecules/Filter';
import Radiobutton from '../molecules/Radiobutton';
import InputField from '../atoms/InputField';
import Button from '../atoms/Button';
import Calendar from '../molecules/ReactCalendar';

const Filter = styled(Filter_T)`
  margin: 0 0.5em;
  padding: 0 0.3em;
`;

const ListOption = styled.span`
  margin-left: 10px;
  color: ${props => props.selected ? props.theme.colors.black : props.theme.colors.greyXD};
  font-weight: ${props => props.selected && 600};
  white-space: pre;
`;

const HeaderRow = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 2em'
})`
  ${props => props.fixed || 'position: fixed'};
  ${props => !props.fixed || 'padding: 0'};
  ${props => props.headerRadius && `border-radius: ${props.headerRadius}`}
  padding: ${props => props.headerPadding ? props.headerPadding : 'auto'};
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background: white;
  ${props => props.fixed || 'border-bottom: 1px solid #eff0ee'};
  ${props => props.showHeaderBorder && 'border-bottom: 1px solid #eff0ee'};
  height: ${props => props.height || '57px'};
  border-radius: 0.5em;
  box-sizing: border-box;
  ${H1} {
    font-size: ${props => props.titleSize};
    color: #3C3D3A;
    padding: 0;
  }
  & > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    margin-right: auto;
  }
  .header-location-dropdown {
    top: 30px;
    width: fit-content;
  }
`;

const SwitchButtonsContainer = styled(FlexContainer)`
  border-right: 1px solid #D8D8D6;
  align-items: center;
`;

const SwitchSpan = styled.span`
  color: ${props => props.active ? props.theme.primaryColor : '#999B95'};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-right: 2em;
  font-family: "Petasense Open Sans";
  &:after {
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 12px !important;
    font-family: "Petasense Open Sans" !important;
    padding: 0.5em !important;
  }
  &:hover {
    ${props => props.active || 'color: black;'}
    cursor: pointer;
  }
`;

const DateContainer = styled(FlexContainer).attrs({
  justifyContent: 'flex-start',
  flexDirection: 'column',
})`
  flex-direction: column;
  height: 100%;
  display: inline-flex;
  vertical-align: top;
  width: 119px;
  overflow: hidden;
`;

const DateSelector = styled.div`
  height: 100%;
  display: inline-block;
  width: 250px;
  vertical-align: top;
  padding: 10px 15px;
  box-sizing: border-box;
  ${InputField} {
    margin-bottom: 13.5px;
  }
  input {
    font-size: 1em;
    padding: .57857143em 1em;
  }
  label {
    font-weight: 600;
    margin-bottom: 3px;
  }
`;

const CalenderContainer = styled.div`
  position: absolute;
  z-index: 3;
  width: 220px;
  top: ${props => props.to ? '103px' : '33px'};
`;

const formatDate = (locale, date) => {
  const day = date.getDay();
  return mapDayToString[day][0];
};
class Modal extends Component {
  /*
    known bug:
    if modal will grow dynamically on runtime over the vertical height of the window the sizing is misagligned
  */
  constructor(props) {
    super(props);
    this.scrollRef = null;
    this.checkVerticalSize = this.checkVerticalSize.bind(this);
    this.correctOverflow = this.correctOverflow.bind(this);
    this.state = {
      verticalCenter: true,
      correctOverflow: props.correctOverflow,
      menuExpand: false,
      openFilterModal: false,
      calendarExpanded: false,
      customDateExpanded: false,
      customDayFrom: null,
      customDayTo: null
    };
  }

  componentDidMount() {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';
    this.checkVerticalSize();
    // registers the close function with ChartItem to ensure only one modal is active at a time
    if (this.props.modalSwitch) {
      setTimeout(() => this.props.modalSwitch(this.props.close), 200);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.verticalCenter === prevState.verticalCenter && !_.isEqual(prevProps.children, this.props.children)) {
      this.checkVerticalSize();
    }
    if (this.state.correctOverflow && this.modal.getBoundingClientRect().height > window.innerHeight) {
      this.correctOverflow(this.modal.getBoundingClientRect().height - window.innerHeight);
    }
  }

  openFilterInlineModal = () => {
    this.setState({
      openFilterModal: true
    });
  };

  componentWillUnmount() {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'visible';
    if (this.props.modalSwitch) this.props.modalSwitch();
  }

  correctOverflow(y) {
    // prevents new elements in the modal being loaded into overflow
    this.scrollRef.scroll(0, y);
  }

  checkVerticalSize() {
    // see if modal content is larger than window height (disable vertical centering in this case)
    if (this.modal.getBoundingClientRect().height > window.innerHeight && this.state.verticalCenter !== false) {
      this.setState({ verticalCenter: false });
    }
  }

  calendarExpand = () => {
    this.setState(prevState => ({ calendarExpanded: !prevState.calendarExpanded }));
  }

  toggleCustomDateExpanded = () => {
    this.setState(prevState => ({ customDateExpanded: !prevState.customDateExpanded }));
  };

  openCustomCalendar = (str) => {
    this.setState({
      customCalendarOpen: str
    });
  };

  closeCustomCalendar = () => {
    this.setState({ customCalendarOpen: null });
  };

  calendarDaySelect = (value, label) => {
    if (label === 'from') {
      this.setState({
        customDayFrom: value,
        customCalendarOpen: null
      });
    } else if (label === 'to') {
      this.setState({
        customDayTo: value,
        customCalendarOpen: null
      });
    }
  };

  render() {
    const {
      title,
      close,
      children,
      width,
      minHeight,
      maxHeight,
      saved,
      padding,
      minWidth,
      menuItems,
      footer,
      header,
      innerScroll,
      headerHeight,
      headerPadding,
      flexGap,
      filterContainer,
      filterContainerOpen,
      setFilterContainerOpen,
      filterIconActive,
      dialog,
      outerPadding,
      headerOptions,
      switchButtons,
      showCalander,
      selectedDays,
      calendarSelect,
      customDateSubmit,
      headerRadius,
      showHeaderBorder,
      primaryColor,
      ...rest
    } = this.props;
    const {
      calendarExpanded,
      customDateExpanded,
      customCalendarOpen,
      customDayFrom,
      customDayTo
    } = this.state;
    return (
      <Background
        fullScreen={width === '100%'}
        {...rest}
      >
      {/* Header and Footer are functions, header and footer objects. If experiencing problems with performance */
      /* or focus use the object format - functions trigger full re-render for every change - example can be found on BearingModal.js */}
        <ModalScrollContainer
          verticalCenter={this.state.verticalCenter}
          innerRef={(node) => { this.scrollRef = node; }}
          innerScroll={innerScroll}
        >
          <ModalContainer
            outerPadding={outerPadding}
            width={width}
            maxHeight={maxHeight}
            fullScreen={width === '100%'}
            innerRef={(node) => { this.modal = node; }}
            minHeight={minHeight}
          >
            <HeaderRow
              titleSize={rest.titleSize}
              fixed={width !== '100%'}
              height={headerHeight}
              headerPadding={headerPadding}
              headerRadius={headerRadius}
              showHeaderBorder={showHeaderBorder}
            >
              <div>
                {typeof title === 'function' ? title() : <H1>{_.isArray(title) ? title.join(' > ') : title}</H1>}
                {headerOptions ? (
                  <FilterDropdown
                    chevron
                    altStyle
                    itemPadding="10px 20px"
                    data={headerOptions}
                    modalClassName="header-location-dropdown"
                    alignHorizontal="center"
                  />
                ) : ''}
              </div>
              {showCalander && (
                <Filter
                  open={calendarExpanded}
                  toggleOpen={this.calendarExpand}
                  Icon={() => calendarExpanded ? <CalendarSvg fill={primaryColor} /> : <CalendarSvg fill="#999B95" />}
                  alignHorizontal="right"
                  width="max-content"
                  zIndex={3}
                >
                  <DateContainer>
                  {calendarOptions.map((option, idx) => {
                    let selected = selectedDays === option.value;
                    if (customDateExpanded) selected = option.value === 'custom';
                    return (
                        <FilterItem
                          key={idx.toString()}
                          width="119px"
                          selected={selected}
                          onClick={() => {
                            if (option.value === 'custom') {
                              return this.toggleCustomDateExpanded();
                            }
                            calendarSelect(option.value);
                            this.calendarExpand();
                            if (customDateExpanded) {
                              this.closeCustomCalendar();
                              this.toggleCustomDateExpanded();
                            }
                          }}
                        >
                          <Radiobutton margin="0px 0px 0px 12px" value={selected} />
                          <ListOption selected={selected}>{option.text}</ListOption>
                        </FilterItem>
                    );
                  })}
                  </DateContainer>
                  {customDateExpanded && (
                    <DateSelector>
                      {customCalendarOpen === 'from' && (
                        <OutsideAlerter open={customCalendarOpen === 'from'} handleClick={this.closeCustomCalendar}>
                          <CalenderContainer>
                            <Calendar
                              formatShortWeekday={(locale, date) => formatDate(locale, date)}
                              maxDetail="month"
                              maxDate={customDayTo || new Date()}
                              showNeighboringMonth={false}
                              onClickDay={value => this.calendarDaySelect(value, 'from')}
                              value={customDayFrom}
                            />
                          </CalenderContainer>
                        </OutsideAlerter>
                      )}
                      {customCalendarOpen === 'to' && (
                        <OutsideAlerter open={customCalendarOpen === 'to'} handleClick={this.closeCustomCalendar}>
                          <CalenderContainer to="true">
                            <Calendar
                              formatShortWeekday={(locale, date) => formatDate(locale, date)}
                              maxDetail="month"
                              maxDate={new Date()}
                              minDate={customDayFrom}
                              value={customDayTo}
                              showNeighboringMonth={false}
                              onClickDay={value => this.calendarDaySelect(value, 'to')}
                            />
                          </CalenderContainer>
                        </OutsideAlerter>
                      )}
                      <InputField label="From" readOnly value={customDayFrom ? customDayFrom.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalendar('from')} />
                      <InputField label="To" readOnly value={customDayTo ? customDayTo.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalendar('to')} />
                      <FlexContainer justifyContent="space-between">
                        <Button
                          text
                          disabled={!customDayFrom || !customDayTo}
                          onClick={() => {
                            customDateSubmit(customDayFrom, customDayTo);
                            this.calendarExpand();
                          }}
                        >
                          Submit
                        </Button>
                      </FlexContainer>
                    </DateSelector>)
                }
                </Filter>)}
              {typeof header === 'function' && header()}
              {typeof header === 'object' && header.items.map(({ Tag, props }) => <Tag {...props} />)}
              {switchButtons && (
                <SwitchButtonsContainer>
                  {switchButtons.map((val, idx) => (
                    // val.icon ? val.icon : (
                      <SwitchSpan key={`switch-span-idx-${idx}`} onClick={val.onClick} active={val.active} data-tooltip={val.tooltip} data-position="bottom center" data-inverted="">
                        {val.icon ? val.icon : val.text}
                      </SwitchSpan>
                    // )
                  ))}
                </SwitchButtonsContainer>
              )}
              {filterContainer && (
                <div style={{ marginLeft: '7px' }} onClick={() => setFilterContainerOpen(true)}>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  <FilterIcon fill={filterContainerOpen ? '#3C3D3A' : filterIconActive ? primaryColor : '#888'} />
                  {filterContainerOpen && (
                    <OutsideAlerter open handleClick={() => setFilterContainerOpen(false)}>
                      {filterContainer}
                    </OutsideAlerter>
                  )}
                </div>
              )}
              {menuItems && (
                <FilterDropdown
                  itemPadding="10px 20px"
                  data={menuItems}
                  activeFill="#999B95"
                  fill={primaryColor}
                  {...rest}
                />
              )}
              <SvgContainer className="modal-close" onClick={close} marginLeft="7px" paddingTop={!title && !header && '1em'}>
                <CrossSvg width={25} height={25} fill="#888" />
              </SvgContainer>
            </HeaderRow>
             {/* eslint-disable-next-line no-nested-ternary */}
            <MaybeContainer
              footer={footer}
              width={width}
              margin={this.props.margin}
            >
              <Maybe
                Component={ModalContent}
                condition={dialog}
                consequence="div"
                padding={padding}
                flexGap={flexGap}
                correctOverflow={this.correctOverflow}
                innerScroll={innerScroll}
              >
                {children}
              </Maybe>
            </MaybeContainer>
            { footer && (
          <FooterFlex left="0">
            {typeof footer === 'function' ? footer()
              : footer.items.map(Item =>
                <Item.Tag {...Item.props}>{Item.children}</Item.Tag>)}
          </FooterFlex>)}
          </ModalContainer>
        </ModalScrollContainer>
      </Background>
    );
  }
}

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.func]),
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  width: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  correctOverflow: PropTypes.bool,
  modalSwitch: PropTypes.func,
  innerScroll: PropTypes.bool,
  titleSize: PropTypes.string
};

Modal.defaultProps = {
  title: undefined,
  footer: undefined,
  header: undefined,
  width: '100%',
  padding: '1em 2em',
  correctOverflow: false,
  minHeight: 'none',
  maxHeight: 'none',
  height: 'auto',
  modalSwitch: undefined,
  innerScroll: false,
  titleSize: '14px'
};

const mapStateToProps = state => ({
  primaryColor: state.companyReducer.partner.theme.primaryColor
});
export default connect(mapStateToProps)(Modal);
export { FooterFlex };
