import { userRoles, operations } from './constants';

export function isPetasenseAdmin(user) {
  if (user && user.roles && user.roles.includes(userRoles.PetasenseAdmin)) return true;
  return false;
}

export function isPetasenseViewer(user) {
  if (user && user.roles && user.roles.includes(userRoles.PetasenseViewer)) return true;
  return false;
}

export function isPartnerAdmin(user) {
  if (user && user.roles && user.roles.includes(userRoles.PartnerAdmin) &&
    user.accounts.map(acc => acc.id).includes(user.account_id)) return true;
  return false;
}

export function isAnalyst(user) {
  if (user && user.roles && user.roles.includes(userRoles.Analyst)) return true;
  return false;
}

export function isAdmin(user) {
  if (user && user.roles && user.roles.includes(userRoles.Admin)) return true;
  return false;
}

export function getHighestPrivilegeRole(roles) {
  const rolesList = [
    userRoles.PetasenseAdmin,
    userRoles.Analyst,
    userRoles.Manager,
    userRoles.Technician,
    userRoles.Admin,
    userRoles.PetasenseViewer
  ];
  return rolesList.filter(r => roles.includes(r))[0];
}

export function checkPermissions(resource, permissions, operation, user) {
  if (isPetasenseAdmin(user) || isPartnerAdmin(user) || (isPetasenseViewer(user) && (operation === operations.Read))) return true;
  if (!user || !permissions || !resource || !operation) return false;
  const userRoles = user.roles;
  for (let i = 0; i < userRoles.length; i++) {
    if (
      permissions[resource] &&
      permissions[resource][userRoles[i]] &&
      permissions[resource][userRoles[i]][operation]
    ) return true;
  }
  return false;
}
