import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'common/styles/colors';
import { Table } from 'semantic-ui-react';
import * as _ from 'lodash';
import { TemperatureHalf } from 'common/images/FaIcons';
import NewBatteryIcon from 'common/images/NewBatteryIcon';
import MoteUnreachableNew from 'common/images/MoteUnreachableNew';

const H3 = styled.span`
  font-size: 12px;
  font-family: Petasense Open Sans;
  display: block;
  text-align: center; 
`;

const TEMPERATURE_LIMITS = {
  GREEN_LOWER: 60,
  GREEN_UPPER: 90,
  YELLOW_LOWER: 0,
  YELLOW_UPPER: 200
};

const StyledTableCell = styled(Table.Cell)`
  &.warning-cell {
    background-color: ${colors.battery_level_warning};
  }
  &.good-cell {
    background-color:  ${colors.default};
  }
  &.critical-cell {
    background-color: ${colors.alarmCritical};
  }
  font-weight: 800;
  color: black;
`;

const ToolTipContainer = styled.span`
  &:before {
    display: none;
  }

  &:after {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    transform: none !important;
    transition-property: none !important;
    animation: none !important;
    -webkit-transform-origin: unset;
    transform-origin: unset;
    background: #000000 !important;
    color: #EEEEEE !important;
    padding: 5px !important;
    font-size: 12px !important;
  }
`;

const getTemperatureCellProps = (value, isUnreachable, limits) => {
  let isGood = false;
  let isWarning = false;
  let isCritical = false;

  if (isUnreachable || value === null || Number.isNaN(value)) {
    return { isGood: false, isWarning: false, isCritical: false };
  }

  if (value >= limits.GREEN_LOWER && value <= limits.GREEN_UPPER) {
    isGood = true;
  } else if (limits.YELLOW_LOWER !== undefined
    && limits.YELLOW_UPPER !== undefined &&
    value >= limits.YELLOW_LOWER &&
    value <= limits.YELLOW_UPPER
  ) {
    isWarning = true;
  } else if (value < limits.YELLOW_LOWER || value > limits.YELLOW_UPPER) {
    isCritical = true;
  }

  return { isGood, isWarning, isCritical };
};

const getCell = (item, feature_key, temperatureLimits) => {
  const value = item[feature_key] !== undefined && item[feature_key] !== null ? _.round(item[feature_key], 2) : '--';
  const { isGood, isWarning, isCritical } = getTemperatureCellProps(item[feature_key], item.unreachable, temperatureLimits);

  let className = '';
  if (isGood) {
    className = 'good-cell';
  } else if (isCritical) {
    className = 'critical-cell';
  } else if (isWarning) {
    className = 'warning-cell';
  }

  return (
    <StyledTableCell className={className}>
      <H3>
        {value}
      </H3>
    </StyledTableCell>
  );
};

const getTemperatureLimits = (dashboardConfig) => {
  if (dashboardConfig && dashboardConfig.type === 'temperature') {
    return {
      GREEN_LOWER: parseFloat(dashboardConfig.green_lower_limit) || TEMPERATURE_LIMITS.GREEN_LOWER,
      GREEN_UPPER: parseFloat(dashboardConfig.green_upper_limit) || TEMPERATURE_LIMITS.GREEN_UPPER,
      YELLOW_LOWER: parseFloat(dashboardConfig.yellow_lower_limit) || TEMPERATURE_LIMITS.YELLOW_LOWER,
      YELLOW_UPPER: parseFloat(dashboardConfig.yellow_upper_limit) || TEMPERATURE_LIMITS.YELLOW_UPPER
    };
  }
  return TEMPERATURE_LIMITS;
};

const AreaTemperatureTable = ({ data, dashboardConfig }) => {
  const [temperatureLimits, setTemperatureLimits] = useState(TEMPERATURE_LIMITS);

  useEffect(() => {
    const newLimits = getTemperatureLimits(dashboardConfig);
    setTemperatureLimits(newLimits);
  }, [dashboardConfig]);

  if (data.length === 0) {
    return (
      <div
        style={{
          borderRadius: '4px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ fontSize: '1em', fontWeight: 'bold', color: 'gray' }}>--</div>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: 'white',
        height: '100%',
        borderRadius: '4px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <div>
        <Table celled unstackable compact="very">
          <thead>
            <tr>
              <th style={{ paddingTop: '5px', paddingBottom: '5px', color: 'grey' }}>Location</th>
              <th style={{ paddingTop: '5px', paddingBottom: '5px', display: 'flex', alignItems: 'center' }}>
                <ToolTipContainer data-tooltip="Temperature" data-position="left">
                  <TemperatureHalf color={colors.greyXXD} height="19px" width="19px" />
                </ToolTipContainer>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((area, index) => (
              <tr key={index} style={{ fontSize: '0.9em' }}>
                <td>
                  <Link
                    title="Go to sensor"
                    className="ps-hover-pointer"
                    to={{ pathname: '/configs', search: `?tag_id=${area.tag_id}` }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      {area.name}
                      {area.low_battery && (
                        <ToolTipContainer data-tooltip="Low Battery" data-position="left">
                          <NewBatteryIcon fill={colors.red} />
                        </ToolTipContainer>
                      )}
                      {area.unreachable && (
                        <ToolTipContainer data-tooltip="Unreachable" data-position="left">
                          <MoteUnreachableNew fill={colors.red} width={15} height={15} />
                        </ToolTipContainer>
                      )}
                    </div>
                  </Link>
                </td>
                {getCell(area, 'last_temperature', temperatureLimits)}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.user,
  dashboardConfig: state.dashboards.activeDashboard.dashboard_config
});

export default connect(
  mapStateToProps
)(AreaTemperatureTable);
