
import styled from 'styled-components';
import { Tab as Tab_T } from 'semantic-ui-react';

export const TAB_ITEM_MARGIN = {
  FIRST_ITEM_LEFT: 24,
  RIGHT: 26
}; // in pixels

const Tab = styled(Tab_T)`
  .ui.secondary.menu {
    margin: 0;
    width: 100%;
    z-index: 1;
    background: white;
    border-bottom: 1px solid #D8DCD3;

    .item {
      font-weight: 600;
      font-size: 12px;
      color: #3C3D3A;
      padding: 20px 3px;
      margin-right: ${TAB_ITEM_MARGIN.RIGHT}px;
      margin-left: 0px;
      &:first-of-type:not(.more-tab) {
        margin-left: ${TAB_ITEM_MARGIN.FIRST_ITEM_LEFT}px;
      }
      &.active {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        background-color: white;
        position: relative;

        &:after {
          content: '';
          height: 3px;
          width: 100%;
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          background: ${props => props.theme.primaryColor};
        }
      }
    }

    a {
      &.item {
        &:hover {
          background: white;
        }
      }
    }
  }
`;

export default Tab;
