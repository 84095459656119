import { combineReducers } from 'redux';

import {
  thirdPartyTagsReducer,
  thirdPartyTagsFilters,
  tagTypes
} from './thirdPartyTags.reducers';

export const thirdPartyTags = combineReducers({
  items: thirdPartyTagsReducer,
  filters: thirdPartyTagsFilters,
  tagTypes
});
