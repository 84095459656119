import { combineReducers } from 'redux';

import { reportOptionsReducer } from 'home/Reports/reducers/reportOptions.reducer';
import { reportsListReducer } from 'home/Reports/reducers/reportsList.reducer';
import { reportHealthSummaryReducer } from 'home/Reports/reducers/reportHealthSummary.reducer';
import { reportAssetsChartsReducer } from 'home/Reports/reducers/reportAssetsCharts.reducer';
import { reportLoadersReducer } from 'home/Reports/reducers/reportLoaders.reducer';

export const reportsReducer = combineReducers({
  reportOptions: reportOptionsReducer,
  reportsList: reportsListReducer,
  reportHealthSummary: reportHealthSummaryReducer,
  reportAssetsCharts: reportAssetsChartsReducer,
  reportLoaders: reportLoadersReducer,
});
