import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TaskDetailsModal from 'home/HomePage/components/TaskDetailsModal';
import RepairDetailsModal from 'home/HomePage/components/RepairDetailsModal';
import NoteDetailsModal from 'home/HomePage/components/NoteDetailsModal';
import * as taskActions from 'home/HomePage/actions/tasks.actions';
import * as repairActions from 'home/HomePage/actions/repairs.actions';
import * as noteActions from 'home/HomePage/actions/notes.actions';

function HomePageModals({
  taskModal,
  taskActions,
  repairModal,
  repairActions,
  noteModal,
  noteActions
}) {
  return (
    <>
      {taskModal.open && (
        <TaskDetailsModal
          isEditMode={!_.isEmpty(taskModal.openTaskDetails)}
          closeModal={taskActions.closeTaskModal}
        />
      )}
      {repairModal.open && (
        <RepairDetailsModal
          isEditMode={!_.isEmpty(repairModal.openRepairDetails)}
          closeModal={repairActions.closeRepairModal}
        />
      )}
      {noteModal.open && (
        <NoteDetailsModal
          isEditMode={!_.isEmpty(noteModal.openNoteDetails)}
          closeModal={noteActions.closeNoteModal}
        />
      )}
    </>
  );
}

const mapStateToProps = state => ({
  taskModal: state.taskModal,
  repairModal: state.repairModal,
  noteModal: state.noteModal
});

const mapDispatchToProps = dispatch => ({
  taskActions: bindActionCreators(taskActions, dispatch),
  repairActions: bindActionCreators(repairActions, dispatch),
  noteActions: bindActionCreators(noteActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageModals);
