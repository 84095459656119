import styled from 'styled-components';
import colors from 'common/styles/colors';

const HomePageContainer = styled.div`
  background: ${props => props.theme.colors.backgroundGray};
  .ui.menu.vertical {
    box-shadow: none;
  }
  #profile-dropdown {
    position: fixed;
    left: unset;
    right: 18px;
  }
  #admin {
    position: fixed;
    left: unset;
    right: 70px;
    top: 21px;
    color: ${colors.green};
  }
  .ui.active.visible.item {
    z-index: 1000;
  }
`;

export default HomePageContainer;
