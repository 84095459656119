import React, { useState } from 'react';
import styled from 'styled-components';
import { FlexContainer, Button } from 'common/components/atoms';
import colors from 'common/styles/colors';
import Label_T from 'common/typography/Label/Label';
import MinusSvg from 'common/images/MinusSvg';
import PlusSvg from 'common/images/PlusSvg';

const SvgContainer = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  margin: 0 0.5em;
  &:hover #edit-icon-stroke {
    stroke: ${props => props.theme.colors.black};
  }
  &:hover #delete-icon-stroke, &:hover #minimise-icon {
    stroke: ${props => props.theme.colors.black};
  }
  &:hover #plus-icon, &:hover #minus-icon {
    fill: ${props => props.theme.colors.black};
  }
  ${props => props.icon && `
    display: flex;
    border-radius: 2px;
    border: 1px solid #E5E8E1;
    justify-content: center;
    align-items: center;
    height: 17px;
    width: 17px;
  `}
`;

const Label = styled(Label_T)`
  margin: 0;
`;

const ActionIcons = styled(FlexContainer).attrs({ alignItems: 'center' })`
  margin-left: auto;
  ${Button} {
    margin-top: 1em;
    margin-right: 1em;
  }
`;

function CollapsibleBox(props) {
  return (
    <FlexContainer
      direction="column"
      border={`1px solid ${colors.greyL}`}
      borderRadius="5px"
      marginbottom="1.5em"
    >
      <FlexContainer alignItems="center" padding="10px">
        <Label>{props.label}</Label>
        <ActionIcons>
          <SvgContainer onClick={props.toggleExpand} icon="true">
            {props.expanded ? <MinusSvg height={8} width={8} />
              : <PlusSvg height={15} width={8} fill="#999B95" />}
          </SvgContainer>
        </ActionIcons>
      </FlexContainer>
      {props.expanded && props.children}
    </FlexContainer>
  );
}

export default CollapsibleBox;
