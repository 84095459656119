import { dashboardConstants } from '../constants/dashboard.constants';

export function dashboardActivityList(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.GET_DASHBOARD_ACTIVITY_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.GET_DASHBOARD_ACTIVITY_SUCCESS: {
      return {
        loading: false,
        ...action.activity
      };
    }
    case dashboardConstants.GET_DASHBOARD_ACTIVITY_FAILURE:
      return {
        loading: false,
        error: action.error.message
      };
    case dashboardConstants.CLEAR_DASHBOARD_ACTIVITY_LIST:
      return {};
    default:
      return state;
  }
}

export function DashboardActivitySorter(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.SET_DASHBOARD_ACTIVITY_SORTER:
      return {
        sorter: action.sorter
      };
    default:
      return state;
  }
}
