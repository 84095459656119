
import React from 'react';
import PropTypes from 'prop-types';

const MinusSvg = ({ width, height, fill, transform, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 8 2">
    <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="minus-icon" transform={`translate(-83.000000, -151.000000) ${transform}`} fill={fill}>
        <g id="minimise-icon" transform={`translate(60.000000, 128.000000) ${transform}`} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
          <g id="minimise">
            <g id="minus-expand-icon" transform={`translate(19.000000, 16.000000) ${transform}`}>
              <path d="M5,8 L11,8" id="minus-expand" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

MinusSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  transform: PropTypes.string
};

MinusSvg.defaultProps = {
  width: 12,
  height: 12,
  fill: '#999B95',
  stroke: '#999B95',
  transform: ''
};


export default MinusSvg;
