import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';

import ButtonConfirm from 'common/components/molecules/ButtonConfirm';
import { humanize } from 'common/helpers/humanize';
import UnassignSensorAlertPrompt from 'common/components/organisms/AlertPrompt';

import InputField_T from 'common/components/atoms/InputField';
import colors from 'common/styles/colors';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Button from 'common/components/atoms/Button';
import Label_T from 'common/typography/Label/Label';
import RBAC from 'common/rbac/RBAC';
import {
  mapComponentToResource,
  operations
} from 'common/rbac/constants';
import DeleteIconSvg from 'common/images/BearingModal/DeleteIconSvg';
import EditIcon from 'common/images/BearingModal/EditIcon';
import TickSvg from 'common/images/BearingModal/TickSvg';
import CrossSvg from 'common/images/CrossSvg';
import PlusSvg from 'common/images/PlusSvg';
import { ResourceInputField, ResourceItemLabel, ResourceSection } from 'common/components/atoms/ResourceDetails';
import InputFieldBold from '../../../MachineBuilder/components/molecules/InputFieldBold/InputFieldBold';

const InputField = styled(InputField_T)`
  width: 30%;
  min-width: 300px;
  label {
    font-weight: 800;
  }
  margin-bottom: 5px;
`;

const LocationDetailsContainer = styled.div`
  order: ${props => props.order};
  margin-bottom: 1.5em;
  max-width: 950px;
`;

const BearingLocation = styled(FlexContainer)`
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0px'};
`;

const BearingContainer = styled.div`
  &:not(:first-of-type) {
    margin-top: 1.5em;
  }
  border: 2px solid #f0f0f0;
  padding: 20px 20px 5px 20px;
`;

const InlineButton = styled(Button)`
  margin-left: 12px;
  ${props => props.order && `order: ${props.order};`}
`;

const LinksContainer = styled(FlexContainer).attrs({ alignItems: 'center' })`
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
`;

const DescriptionLabel = styled(Label_T)`
  font-weight: 300;
  margin-bottom: 0;
  margin-left: 8px;
  margin-top: 0px;
`;

const Label = styled(Label_T).attrs({ fontWeight: '800' })``;

const Value = styled.div`
  font-weight: ${props => props.fontWeight ? props.fontWeight : 'normal'};
  color: ${props => props.theme.colors.greyXXD};
  border-radius: 4px;
  font-size: ${props => props.fontSize ? props.fontSize : '14px'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SvgContainer = styled.span`
  padding-left: 0.7em;
  ${props => props.marginTop && `margin-top: ${props.marginTop}`};
  &:hover {
    cursor: ${props => (props.valid ? 'pointer' : 'default')};
    #edit-icon-stroke {
      stroke: ${props => props.theme.colors.black};
    }
  }
`;

class OtherLocationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items ? [...props.items] : [],
      newLocationOpen: false,
      newLocationName: '',
      newLocationDescription: '',
      newSensorOpen: {},
      unassociatedSensorOptions: this.props.unassociatedSensors.object
        .map(sensor => ({
          value: sensor.serial_number,
          text: sensor.serial_number
        })),
      selectedSensor: {},
      unassignSensorSerialNumber: null,
      unassignSensorLocationId: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.setState({
        items: [...this.props.items],
        newLocationOpen: false,
        newLocationName: '',
        newLocationDescription: ''
      });
    }
    if (this.props.unassociatedSensors.object !== prevProps.unassociatedSensors.object) {
      this.setState({
        unassociatedSensorOptions: this.props.unassociatedSensors.object
          .map(sensor => ({
            value: sensor.serial_number,
            text: sensor.serial_number
          }))
      });
    }
  }

  toggleEdit = (idx) => {
    const { items } = this.state;
    items[idx] = { ...items[idx], edit: !items[idx].edit };
    this.setState({ items });
  };

  setLocationName = (name, idx) => {
    const { items } = this.state;
    items[idx] = { ...items[idx], name };
    this.setState({ items });
  };

  setLocationDescription = (description, idx) => {
    const { items } = this.state;
    items[idx] = { ...items[idx], description };
    this.setState({ items });
  };

  resetNameAndDescription = (idx) => {
    const location = this.props.items.find(item => item.id === this.state.items[idx].id);
    if (!location) return;
    this.setLocationName(location.name, idx);
    this.setLocationDescription(location.description, idx);
    this.toggleEdit(idx);
  };

  updateLocation = (idx) => {
    const { items } = this.state;
    const locationId = items[idx].id;
    const name = items[idx].name;
    const description = items[idx].description;
    if (!name || !name.trim()) return;
    this.props.updateLocation(locationId, { name, description });
    this.toggleEdit(idx);
  };

  clearAndCloseNewLocation = () => {
    this.setState({
      newLocationOpen: false,
      newLocationName: '',
      newLocationDescription: ''
    });
  };

  onLocationNameSubmit = () => {
    const { newLocationName, newLocationDescription } = this.state;
    if (!newLocationName && !newLocationName.trim()) return;
    this.props.onLocationNameSubmit(newLocationName.trim(), newLocationDescription.trim());
    this.clearAndCloseNewLocation();
  }

  clearAndCloseNewSensor = (locationId) => {
    this.setState(prevState => ({
      newSensorOpen: {
        ...prevState.newSensorOpen,
        [locationId]: false
      },
      selectedSensor: {
        ...prevState.selectedSensor,
        [locationId]: null
      }
    }));
  }

  unassignSensor = (serialNumber, locationId) => {
    const params = {
      other: {
        type: 'other',
        location_id: null,
        serial_number: [serialNumber]
      }
    };
    this.props.unassignSensor(locationId, params, serialNumber, 'other');
    this.unassignSensorModalClose();
  }

  assignSensor = (locationId) => {
    const selectedSensor = this.state.selectedSensor[locationId];
    if (!selectedSensor) return;
    const serialNumber = selectedSensor.serial_number;
    const params = {
      other: {
        type: 'other',
        location_id: locationId,
        serial_number: [serialNumber]
      }
    };
    this.props.assignSensor(locationId, params, serialNumber, 'other', null);
    this.setState(prevState => ({
      newSensorOpen: {
        ...prevState.newSensorOpen,
        [locationId]: false
      },
      selectedSensor: {
        ...prevState.selectedSensor,
        [locationId]: null
      }
    }));
  }

  changeSelectedSensor = (e, data, locationId) => {
    const serial_number = (e.target.value !== null && e.target.value !== undefined) ? e.target.value : data.value;
    const sensor = this.props.unassociatedSensors.object.find(sensor => sensor.serial_number === serial_number);
    this.setState(prevState => ({
      selectedSensor: {
        ...prevState.selectedSensor,
        [locationId]: sensor
      }
    }));
  }

  unassignSensorModalOpen = (serialNumber, locationId) => {
    this.setState({
      unassignSensorLocationId: locationId,
      unassignSensorSerialNumber: serialNumber
    });
  }

  unassignSensorModalClose = () => {
    this.setState({
      unassignSensorLocationId: null,
      unassignSensorSerialNumber: null
    });
  }

  render() {
    const { newLocationOpen, items, newLocationName, newSensorOpen, selectedSensor, unassignSensorSerialNumber, unassignSensorLocationId } = this.state;
    const {
      order,
      deleteLocation
    } = this.props;
    return (
      <>
        <FlexContainer>
          <ResourceSection width="145px" paddingBottom="15px">Other Locations</ResourceSection>
          {!newLocationOpen && (
            <RBAC
              resource={mapComponentToResource.Machines}
              operation={operations.Create}
              yes={(
                <LinksContainer>
                  <PlusSvg onClick={() => this.setState({ newLocationOpen: true })} />
                </LinksContainer>
              )}
            />
          )}
        </FlexContainer>
        {items.length !== 0 && (
          <LocationDetailsContainer order={order}>
            {items.map((item, idx) => (
              <BearingContainer key={idx} style={{ position: 'relative' }}>
                <ResourceItemLabel> Location {idx + 1}</ResourceItemLabel>
                <BearingLocation alignItems="center" >
                  {!item.edit && (
                    <>
                      <Value>{humanize(item.name)}</Value>
                      {item.description && (
                        <DescriptionLabel>{humanize(item.description)}</DescriptionLabel>
                      )}
                      {!(this.props.editLocationDisabled && item.location_type_id) && (
                        <RBAC
                          resource={mapComponentToResource.BearingDetails}
                          operation={operations.Update}
                          yes={(
                            <InlineButton
                              marginBottom="0px"
                              text
                              onClick={() => this.toggleEdit(idx)}
                            >
                              <EditIcon />
                            </InlineButton>
                          )}
                        />
                      )}
                      {!item.location_type_id && (
                        <RBAC
                          resource={mapComponentToResource.BearingDetails}
                          operation={operations.Delete}
                          yes={(
                            <ButtonConfirm
                              onProceed={() => deleteLocation(item.id, item.name)}
                              tooltip="Delete Location"
                              buttonContent={<DeleteIconSvg />}
                              promptMessage="Delete Location"
                              promptSecondaryMessage="This Location and its related Data will be deleted. This action cannot be undone."
                            />
                          )}
                        />
                      )}
                    </>
                  )}
                  {item.edit && (
                    <>
                      <LinksContainer>
                        <ResourceInputField
                          placeholder="Location Name"
                          value={item.name}
                          onChange={e => this.setLocationName(e.target.value, idx)}
                        />
                        <ResourceInputField
                          style={{ marginLeft: '1rem' }}
                          placeholder="Description"
                          value={item.description}
                          onChange={e => this.setLocationDescription(e.target.value, idx)}
                        />
                        <SvgContainer
                          id={`location-name-edit-icon-${idx}`}
                          marginBottom="0px"
                          valid={item.name && item.name.trim()}
                          onClick={() => this.updateLocation(idx)}
                        >
                          <TickSvg inactive={!item.name || !item.name.trim()} />
                        </SvgContainer>
                        <SvgContainer
                          id="location-name-cross-icon"
                          marginTop="4px"
                          valid
                          onClick={() => this.resetNameAndDescription(idx)}
                        >
                          <CrossSvg width={20} height={20} fill="#888" />
                        </SvgContainer>
                      </LinksContainer>
                    </>
                  )}
                </BearingLocation>
                {item.tx_sensors.map((tx_sensor, idx) => (
                  <>
                    <FlexContainer>
                      <FlexContainer direction="column" margintop="10px" marginright="10px">
                        <Label>
                          Sensor {parseInt(idx, 10) + 1}
                        </Label>
                        <InputFieldBold
                          value={tx_sensor.serial_number}
                          disabled
                        />
                      </FlexContainer>
                      <RBAC
                        resource={mapComponentToResource.Sensors}
                        operation={operations.Update}
                        yes={(
                          <FlexContainer margintop="2.1em">
                            <Button
                              onClick={() => this.unassignSensorModalOpen(tx_sensor.serial_number, item.id)}
                              text
                              fontSize="12px"
                              color={colors.red}
                            >
                              Unassign Sensor
                            </Button>
                          </FlexContainer>
                        )}
                      />
                    </FlexContainer>
                  </>
                ))}
                {newSensorOpen[item.id] && (
                  <FlexContainer margintop="16px">
                    <FlexContainer direction="column" marginright="15px">
                      <Label>
                        Sensor {parseInt(item.tx_sensors.length, 10) + 1}
                      </Label>
                      <InputField
                        type="select"
                        search
                        options={this.state.unassociatedSensorOptions}
                        name="sensors"
                        key="sensors"
                        onChange={(e, data) => this.changeSelectedSensor(e, data, item.id)}
                      />
                    </FlexContainer>
                    <SvgContainer
                      id="new-sensor-name-add-icon"
                      marginTop="40px"
                      valid={selectedSensor[item.id]}
                      onClick={() => this.assignSensor(item.id)}
                    >
                      <TickSvg inactive={!selectedSensor[item.id]} />
                    </SvgContainer>
                    <SvgContainer
                      id="new-sensor-name-cross-icon"
                      marginTop="40px"
                      valid
                      onClick={() => this.clearAndCloseNewSensor(item.id)}
                    >
                      <CrossSvg width={20} height={20} fill="#888" />
                    </SvgContainer>
                  </FlexContainer>
                )}
                {!this.props.newSensorDisabled && !newSensorOpen[item.id] && (
                  <RBAC
                    resource={mapComponentToResource.Sensors}
                    operation={operations.Update}
                    yes={(
                      <FlexContainer margintop="20px">
                        <Button
                          style={{ position: 'absolute', top: '8px', right: '10px' }}
                          onClick={() => { this.setState({ newSensorOpen: { ...newSensorOpen, [item.id]: true } }); }}
                          text
                          fontSize="11px"
                        >
                          Add Sensor
                        </Button>
                      </FlexContainer>
                    )}
                  />
                )}
              </BearingContainer>
            ))}
          </LocationDetailsContainer>
        )}
        {newLocationOpen && (
          <LinksContainer marginBottom="2.5rem">
            <ResourceInputField
              placeholder="Location Name"
              onChange={e => this.setState({ newLocationName: e.target.value })}
            />
            <ResourceInputField
              style={{ marginLeft: '1rem' }}
              placeholder="Description"
              onChange={e => this.setState({ newLocationDescription: e.target.value })}
            />
            <SvgContainer
              id="new-location-name-edit-icon"
              marginBottom="0px"
              valid={newLocationName && newLocationName.trim()}
              onClick={this.onLocationNameSubmit}
            >
              <TickSvg inactive={!newLocationName || !newLocationName.trim()} />
            </SvgContainer>
            <SvgContainer
              id="new-location-name-cross-icon"
              marginTop="4px"
              valid
              onClick={this.clearAndCloseNewLocation}
            >
              <CrossSvg width={20} height={20} fill="#888" />
            </SvgContainer>
          </LinksContainer>
        )}
        {unassignSensorSerialNumber && (
          <UnassignSensorAlertPrompt
            message={`Are you sure you want to unassign ${unassignSensorSerialNumber} ?`}
            onCancel={this.unassignSensorModalClose}
            onProceed={() => this.unassignSensor(unassignSensorSerialNumber, unassignSensorLocationId)}
          />
        )}
      </>
    );
  }
}

OtherLocationDetails.propTypes = {
  order: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  deleteLocation: PropTypes.func,
  updateLocation: PropTypes.func,
  onLocationNameSubmit: PropTypes.func
};

OtherLocationDetails.defaultProps = {
  deleteLocation: () => { },
  updateLocation: () => { },
  onLocationNameSubmit: () => { }
};

export default withTheme(OtherLocationDetails);
