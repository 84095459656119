import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'common/components/organisms/Modal';
import { FlexContainer } from 'common/components/atoms';
import Label_T from 'common/typography/Label/Label';
import styled, { css } from 'styled-components';
import InputField_T from 'common/components/Chart/atoms/InputField';
import CalendarFilter from 'common/components/Chart/components/CalendarFilter';
import Chart from 'react-google-charts';
import H3_T from 'common/typography/H3/H3';
import LoadingSvg from 'common/images/LoadingSvg';
import DownloadSvg from 'common/images/DownloadSvg';
import { downloadAsCSV } from 'common/helpers';
import H4_T from 'common/typography/H4/H4';
import * as sensorActions from '../actions/sensors.actions';
import { CHART_TYPES_FOR_VM3, CHART_TYPES } from '../constants/sensors.constants';

const Labelcontainer = styled(FlexContainer)`
  padding-top: 1em;
  width: 100%;
  height: max-content;
  &:after {
    content: "";
    display: inline-block;
    height: 0.5em;
    align-self: center;
    width: Calc(100% - 25px);
    margin-right: -880px;
    margin-left: 10px;
    border-top: 1px solid ${props => props.theme.colors.greyD};
    margin-top: 0.5em;
  }
`;

const EmptyMsgContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em;
  margin: 2em;
  border: 2px dotted ${props => props.theme.colors.lightGray};
  border-radius: ${props => props.theme.utils.borderRadius};
  background-color: ${props => props.theme.colors.white};
  span {
    color: ${props => props.theme.colors.lightGray};
    font-weight: bold;
  }
`;
const Loader = styled.div`
  top: 0;
  left: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const Label = styled(Label_T)`
  padding-top: 1em;
  font-weight: 800;
`;

const H3 = styled(H3_T)`
  padding-left: 10px;
`;

const H4 = styled(H4_T)`
  padding-top:7px;
  font-weight:300;
  font-size:13px;
`;

const defaultFontSettings = props => css`
  font-family: ${props.theme.fonts.lightLabel};
`;

const InputField = styled(InputField_T)`
  font-size: 12px;
  ${props => props.paddingTop && `padding-top: ${props.paddingTop};`}
  width: 100%;
  min-width: 224px;
  label {
    font-weight: 300;
  }
  padding-bottom: 0px;
  margin-bottom: 0px;
  .ui.fluid.dropdown {
    ${props => (props.fontSettings ? props.fontSettings : defaultFontSettings(props))}
  }
  .ui.selection.active.dropdown .menu > .item {
    ${props => (props.optionFontSettings ? props.optionFontSettings : defaultFontSettings(props))}
  }
`;


function DeviceParameterAnalysis(props) {
  const { getDeviceLogsData } = props.sensorActions;
  const { data, loading } = props.deviceLogsData;
  const { mote_id } = props.moteId;
  const model = props.model;
  const [calendarExpanded, setCalendarExpanded] = useState(false);
  const [customDayFrom, setCustomDayFrom] = useState(null);
  const [customDayTo, setCustomDayTo] = useState(null);
  const [days, setDays] = useState('30');
  const [selectedChart, setSelectedChart] = useState('Battery Voltage');
  const [calenderValue, setCalenderValue] = useState('30');

  const calendarOptions = [
    { value: '15', text: '15 Days' },
    { value: '30', text: '30 Days' },
    { value: '3months', text: '3 months' },
    { value: '1year', text: '1 year' },
    { value: 'custom', text: 'Custom' }
  ];

  const handleChartChange = (value) => {
    setSelectedChart(value);
  };

  const calendarExpand = () => {
    setCalendarExpanded(!calendarExpanded);
  };

  useEffect(() => {
    const mote_id = props.moteId;
    setCalendarExpanded(false);
    getDeviceLogsData(mote_id, days);
  }, [mote_id]);

  const convertToTimestamps = (fromDate, toDate) => {
    const fromDateTimestamp = fromDate
      ? Math.floor(fromDate.getTime() / 1000)
      : null;
    const toDateTimestamp = toDate ? Math.floor(toDate.getTime() / 1000) : null;
    return { fromDateTimestamp, toDateTimestamp };
  };

  const calendarSelect = (value, fromDate, toDate) => {
    const mote_id = props.moteId;
    setCalenderValue(value);
    let selectedDays = value;

    if (value === 'custom') {
      setCustomDayFrom(fromDate);
      setCustomDayTo(toDate);
      selectedDays = 365;
      const { fromDateTimestamp, toDateTimestamp } = convertToTimestamps(
        fromDate,
        toDate
      );
      const formattedFromDate = fromDate ? fromDate.toLocaleDateString() : '';
      const formattedToDate = toDate ? toDate.toLocaleDateString() : '';
      setCalenderValue(`${formattedFromDate} - ${formattedToDate}`);
      getDeviceLogsData(
        mote_id,
        selectedDays,
        fromDateTimestamp,
        toDateTimestamp,
      );
    } else if (value === '3months') {
      selectedDays = 90;
      getDeviceLogsData(mote_id, selectedDays);
    } else if (value === '1year') {
      selectedDays = 365;
      getDeviceLogsData(mote_id, selectedDays);
    } else {
      setCalenderValue(value);
      getDeviceLogsData(mote_id, selectedDays);
    }
    setCalendarExpanded(false);
    setDays(selectedDays);
  };

  const splitData = (data) => {
    if (!data || !data.data) {
      return {
        batteryVoltageData: [],
        coulombCountData: [],
        wifiRSSILevelData: [],
        measurementCycleTimeData: [],
        wifiConnectTimeData: [],
        dataExchangeTimeData: [],
        minBatteryVoltage: null,
        maxBatteryVoltage: null,
        minCoulombCount: null,
        maxCoulombCount: null,
        minWifiRSSILevel: null,
        maxWifiRSSILevel: null,
        minMeasurementCycleTime: null,
        maxMeasurementCycleTime: null,
        minWifiConnectTime: null,
        maxWifiConnectTime: null,
        minDataExchangeTime: null,
        maxDataExchangeTime: null
      };
    }

    const dataArray = data.data;
    const batteryVoltageData = [];
    const coulombCountData = [];
    const wifiRSSILevelData = [];
    const measurementCycleTimeData = [];
    const wifiConnectTimeData = [];
    const dataExchangeTimeData = [];

    let minBatteryVoltage = Infinity;
    let maxBatteryVoltage = -Infinity;
    let minCoulombCount = Infinity;
    let maxCoulombCount = -Infinity;
    let minWifiRSSILevel = Infinity;
    let maxWifiRSSILevel = -Infinity;
    let minMeasurementCycleTime = Infinity;
    let maxMeasurementCycleTime = -Infinity;
    let minWifiConnectTime = Infinity;
    let maxWifiConnectTime = -Infinity;
    let minDataExchangeTime = Infinity;
    let maxDataExchangeTime = -Infinity;

    dataArray.forEach((itemArray) => {
      itemArray.forEach((item) => {
        const timestamp = new Date(item.timestamp);
        const batteryVoltage = item.battery_voltage;
        const coulombCount = item.coulomb_count;
        const wifiRSSILevel = item.wifi_rssi;
        const measurementCycleTime = item.measurement_cycle_time;
        const wifiConnectTime = item.wifi_connect_time;
        const dataExchangeTime = item.data_exchange_time;

        if (batteryVoltage !== undefined) {
          batteryVoltageData.push([timestamp, batteryVoltage]);
          minBatteryVoltage = Math.min(minBatteryVoltage, batteryVoltage);
          maxBatteryVoltage = Math.max(maxBatteryVoltage, batteryVoltage);
        }
        if (coulombCount !== undefined) {
          coulombCountData.push([timestamp, coulombCount]);
          minCoulombCount = Math.min(minCoulombCount, coulombCount);
          maxCoulombCount = Math.max(maxCoulombCount, coulombCount);
        }
        if (wifiRSSILevel !== undefined) {
          wifiRSSILevelData.push([timestamp, wifiRSSILevel]);
          minWifiRSSILevel = Math.min(minWifiRSSILevel, wifiRSSILevel);
          maxWifiRSSILevel = Math.max(maxWifiRSSILevel, wifiRSSILevel);
        }
        if (measurementCycleTime !== undefined) {
          measurementCycleTimeData.push([timestamp, measurementCycleTime]);
          minMeasurementCycleTime = Math.min(minMeasurementCycleTime, measurementCycleTime);
          maxMeasurementCycleTime = Math.max(maxMeasurementCycleTime, measurementCycleTime
          );
        }
        if (wifiConnectTime !== undefined) {
          wifiConnectTimeData.push([timestamp, wifiConnectTime]);
          minWifiConnectTime = Math.min(minWifiConnectTime, wifiConnectTime);
          maxWifiConnectTime = Math.max(maxWifiConnectTime, wifiConnectTime);
        }
        if (dataExchangeTime !== undefined) {
          dataExchangeTimeData.push([timestamp, dataExchangeTime]);
          minDataExchangeTime = Math.min(minDataExchangeTime, dataExchangeTime);
          maxDataExchangeTime = Math.max(maxDataExchangeTime, dataExchangeTime);
        }
      });
    });


    return {
      batteryVoltageData,
      coulombCountData,
      wifiRSSILevelData,
      measurementCycleTimeData,
      wifiConnectTimeData,
      dataExchangeTimeData,
      minBatteryVoltage,
      maxBatteryVoltage,
      minCoulombCount,
      maxCoulombCount,
      minWifiRSSILevel,
      maxWifiRSSILevel,
      minMeasurementCycleTime,
      maxMeasurementCycleTime,
      minWifiConnectTime,
      maxWifiConnectTime,
      minDataExchangeTime,
      maxDataExchangeTime
    };
  };

  const {
    batteryVoltageData,
    coulombCountData,
    wifiRSSILevelData,
    measurementCycleTimeData,
    wifiConnectTimeData,
    dataExchangeTimeData,
    minCoulombCount,
    maxCoulombCount,
    minBatteryVoltage,
    maxBatteryVoltage,
    minDataExchangeTime,
    maxDataExchangeTime,
    minMeasurementCycleTime,
    maxMeasurementCycleTime,
    minWifiConnectTime,
    maxWifiConnectTime,
    minWifiRSSILevel,
    maxWifiRSSILevel,
  } = splitData(data);

  const yAxisMinBatteryVoltage = minBatteryVoltage > 2.2 ? 2.2 : minBatteryVoltage - 0.2;
  const yAxisMaxBatteryVoltage = maxBatteryVoltage < 3.4 ? 3.4 : maxBatteryVoltage + 0.2;
  const yAxisMinWifiRSSILevel = minWifiRSSILevel < -30 ? -30 : minWifiRSSILevel - 2;
  const yAxisMaxWifiRSSILevel = maxWifiRSSILevel > -90 ? -90 : maxWifiRSSILevel - 2;
  const yAxisMinDataExchangeTime = minDataExchangeTime > 0 ? 0 : minDataExchangeTime - 2;
  const yAxisMaxDataExchangeTime = maxDataExchangeTime < 12 ? 12 : maxDataExchangeTime + 2;
  const yAxisMinWifiConnectTime = minWifiConnectTime > 0 ? 0 : minWifiConnectTime - 2;
  const yAxisMaxWifiConnectTime = maxWifiConnectTime < 60 ? 60 : maxWifiConnectTime + 2;
  const yAxisMinMeasurementCycleTime = minMeasurementCycleTime > 10 ? 10 : minMeasurementCycleTime - 2;
  const yAxisMaxMeasurementCycleTime = maxMeasurementCycleTime < 60 ? 60 : maxMeasurementCycleTime + 2;

  const chartConfigurations = [
    {
      chartType: 'Battery Voltage',
      data: [['Timestamp', 'Battery Voltage'], ...batteryVoltageData],
      vAxisTitle: 'Battery Voltage(V)',
      yAxisMin: yAxisMinBatteryVoltage,
      yAxisMax: yAxisMaxBatteryVoltage
    },
    {
      chartType: 'Coulomb Count',
      data: [['Timestamp', 'Coulomb Count'], ...coulombCountData],
      vAxisTitle: 'Coulomb Count(C)',
      yAxisMin: minCoulombCount,
      yAxisMax: maxCoulombCount
    },
    {
      chartType: 'Wifi RSSI Level',
      data: [['Timestamp', 'Wifi RSSI Level'], ...wifiRSSILevelData],
      vAxisTitle: 'Wifi RSSI (dB)',
      yAxisMin: yAxisMinWifiRSSILevel,
      yAxisMax: yAxisMaxWifiRSSILevel
    },
    {
      chartType: 'Measurement Cycle Time',
      data: [
        ['Timestamp', 'Measurement Cycle Time'],
        ...measurementCycleTimeData
      ],
      vAxisTitle: 'Measurement Cycle Time(s)',
      yAxisMin: yAxisMinMeasurementCycleTime,
      yAxisMax: yAxisMaxMeasurementCycleTime
    },
    {
      chartType: 'Data Exchange Time',
      data: [['Timestamp', 'Data Exchange Time'], ...dataExchangeTimeData],
      vAxisTitle: 'Data Exchange Time(s)',
      yAxisMin: yAxisMinDataExchangeTime,
      yAxisMax: yAxisMaxDataExchangeTime
    },
    {
      chartType: 'Wifi Connect Time',
      data: [['Timestamp', 'Wifi Connect Time'], ...wifiConnectTimeData],
      vAxisTitle: 'Wifi Connect Time(s)',
      yAxisMin: yAxisMinWifiConnectTime,
      yAxisMax: yAxisMaxWifiConnectTime
    }
  ];

  const selectedChartConfig = chartConfigurations.find(
    config => config.chartType === selectedChart
  );

  let vAxisConfig;

  if (selectedChart === 'Battery Voltage') {
    vAxisConfig = {
      title: 'Battery Voltage(V)',
      viewWindow: {
        min: 2.2,
        max: 3.4,
      },
      format: '#.#',

    };
  } else if (selectedChart === 'Wifi RSSI Level') {
    vAxisConfig = {
      title: 'Wifi RSSI Level(dB)',
      format: '# dB',
      viewWindow: {
        min: -40,
        max: -90
      }
    };
  } else if (selectedChart === 'Coulomb Count') {
    vAxisConfig = {
      title: 'Coulomb Count(C)',
      viewWindow: {
        min: 0,
        max: 100
      }
    };
  } else {
    vAxisConfig = {
      title: selectedChartConfig.vAxisTitle,
      format:
        selectedChartConfig.vAxisTitle === 'Battery Voltage(V)' ? '# V' : '# s',
      viewWindow: {
        min: 0,
        max: 100
      }
    };
  }

  function handleDownload() {
    const mergedData = {};

    const processData = (dataArray, key) => {
      dataArray.forEach(([timestamp, value]) => {
        if (!mergedData[timestamp]) {
          mergedData[timestamp] = { timestamp };
        }
        mergedData[timestamp][key] = value;
      });
    };

    processData(batteryVoltageData, 'battery_voltage');
    processData(coulombCountData, 'coulomb_count');
    processData(wifiRSSILevelData, 'wifi_rssi');
    processData(measurementCycleTimeData, 'measurement_cycle_time');
    processData(wifiConnectTimeData, 'wifi_connect_time');
    processData(dataExchangeTimeData, 'data_exchange_time');

    const structuredData = Object.values(mergedData).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    const propertiesToHeaderMap = {
      timestamp: 'Timestamp',
      battery_voltage: 'Battery Voltage',
      coulomb_count: 'Coulomb Count',
      wifi_rssi: 'Wifi RSSI',
      measurement_cycle_time: 'Measurement Cycle Time',
      wifi_connect_time: 'Wifi Connect Time',
      data_exchange_time: 'Data Exchange Time'
    };

    const title = `Device Logs - ${props.serialNumber}`;
    if (mergedData) downloadAsCSV(structuredData, propertiesToHeaderMap, `${title}.csv`);
  }

  return (
    <Modal
      width="1100px"
      padding="0 10px"
      close={() => {
        props.onCancel();
      }}
      minHeight="500px"
    >
      <H3>Device Parameter Charts- {props.serialNumber}</H3>
      <Labelcontainer>
        <Label
          grey
          fontsize="14px"
          marginbottom="0"
          paddingtop="0"
          width="max-content"
        />
      </Labelcontainer>
      <FlexContainer justifyContent="space-between" padding="10px">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CalendarFilter
              calendarExpanded={calendarExpanded}
              calendarSelect={calendarSelect}
              customDayFrom={customDayFrom}
              customDayTo={customDayTo}
              days={calenderValue}
              calendarOptions={calendarOptions}
              calendarExpand={calendarExpand}
            />
          </div>
          <div style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handleDownload()}>
            <DownloadSvg />
          </div>
        </div>
        <div>
          <H4>Last Reported At - {props.last_report}</H4>
        </div>
        {model === 'VM1' || model === 'VM2' ? (
          <div>
            <InputField
              options={CHART_TYPES}
              label=""
              type="select"
              placeholder="Select"
              onChange={(e, d) => handleChartChange(d.value)}
              value={selectedChart}
            />
          </div>
        ) : (
          ['VM3', 'VM4P', 'GSP', 'VM4', 'GS'].includes(model) && (
            <div>
              <InputField
                options={CHART_TYPES_FOR_VM3}
                label=""
                type="select"
                placeholder="Select"
                onChange={(e, d) => handleChartChange(d.value)}
                value={selectedChart}
              />
            </div>
          )
        )}
      </FlexContainer>

      {!loading &&
        (() => {
          let dataFound = false;

          const charts = chartConfigurations.map((config) => {
            if (
              selectedChart === config.chartType &&
              data &&
              data.data &&
              data.data.length > 0
            ) {
              dataFound = true;
              const { yAxisMin, yAxisMax } = config;
              return (
                <Chart
                  key={config.chartType}
                  chartType="LineChart"
                  data={config.data}
                  height={400}
                  width="100%"
                  options={{
                    title: config.title,
                    curveType: 'function',
                    legend: 'none',
                    vAxis: {
                      title: vAxisConfig.title,
                      format: vAxisConfig.format,
                      viewWindow: {
                        min: yAxisMin,
                        max: yAxisMax
                      }
                    },
                    explorer: {
                      actions: ['dragToZoom', 'rightClickToReset'],
                      axis: 'both',
                      keepInBounds: 'both',
                      maxZoomIn: 0.1
                    },
                    colors: ['rgb(145, 184, 70)']
                  }}
                />
              );
            }

            return null;
          });

          if (!dataFound) {
            return (
              <div>
                {charts}
                <EmptyMsgContainer>No data found for the selected days</EmptyMsgContainer>
              </div>
            );
          }

          return charts;
        })()}

      {(loading || (data && data.data && data.data.length === 0)) && (
        <Loader>
          <LoadingSvg />
        </Loader>
      )}
    </Modal>
  );
}

const mapStateToProps = state => ({
  deviceLogsData: state.sensors.deviceLogsData
});

const mapDispatchToProps = dispatch => ({
  sensorActions: bindActionCreators(sensorActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceParameterAnalysis);
