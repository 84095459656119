import React from 'react';

export default () => (
  <svg width="12px" height="12px" viewBox="0 0 17 17" version="1.1">
    <g id="P_W-v.2.0-Machines-v.1.1.0" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(-910.000000, -201.000000)">
        <g id="machine_details-tabs_overview" transform="translate(26.000000, 199.000000)">
            <g id="info" transform="translate(882.000000, 0.000000)">
                <g id="info-icon">
                    <rect id="bg" fill="#F7F8F6" opacity="0" x="0" y="0" width="20" height="20" />
                    <path d="M11.35,13.475 L11.7751636,13.475 C12.2445153,13.475 12.625,13.8522851 12.625,14.325 C12.625,14.794442 12.2478409,15.175 11.7751636,15.175 L9.22483637,15.175 C8.7554847,15.175 8.375,14.7977149 8.375,14.325 C8.375,13.855558 8.75215915,13.475 9.22483637,13.475 L9.65,13.475 L9.65,10.925 L9.2275156,10.925 C8.75668423,10.925 8.375,10.5477149 8.375,10.075 C8.375,9.60555796 8.75178581,9.225 9.2275156,9.225 L10.4974844,9.225 C10.7343076,9.225 10.9464724,9.31997719 11.1002275,9.47371589 C11.2549182,9.62689891 11.35,9.83906315 11.35,10.075 L11.35,13.475 Z M10.5,19 C5.80557963,19 2,15.1944204 2,10.5 C2,5.80557963 5.80557963,2 10.5,2 C15.1944204,2 19,5.80557963 19,10.5 C19,15.1944204 15.1944204,19 10.5,19 Z M10.5,17.3 C14.2555363,17.3 17.3,14.2555363 17.3,10.5 C17.3,6.7444637 14.2555363,3.7 10.5,3.7 C6.7444637,3.7 3.7,6.7444637 3.7,10.5 C3.7,14.2555363 6.7444637,17.3 10.5,17.3 Z M10.5,8.375 C9.79583694,8.375 9.225,7.80416306 9.225,7.1 C9.225,6.39583694 9.79583694,5.825 10.5,5.825 C11.2041631,5.825 11.775,6.39583694 11.775,7.1 C11.775,7.80416306 11.2041631,8.375 10.5,8.375 Z" id="Combined-Shape" fill="#999B95" />
                </g>
            </g>
        </g>
    </g>
  </svg>
);
