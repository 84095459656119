import theme from '../../common/styles/theme';
import MissedMeasurementModal from './Components/MissedMeasurementsModal';

export const devicesHealthConstants = {
  GET_DEVICES_HEALTH_DATA_REQUEST: 'GET_DEVICES_HEALTH_DATA_REQUEST',
  GET_DEVICES_HEALTH_DATA_SUCCESS: 'GET_DEVICES_HEALTH_DATA_SUCCESS',
  GET_DEVICES_HEALTH_DATA_FAILURE: 'GET_DEVICES_HEALTH_DATA_FAILURE',
  GET_MISSED_MEASUREMENTS_LIST_REQUEST: 'GET_MISSED_MEASUREMENTS_LIST_REQUEST',
  GET_MISSED_MEASUREMENTS_LIST_SUCCESS: 'GET_MISSED_MEASUREMENTS_LIST_SUCCESS',
  GET_MISSED_MEASUREMENTS_LIST_FAILURE: 'GET_MISSED_MEASUREMENTS_LIST_FAILURE',
  SET_MISSED_MEASUREMENTS_LIST_FILTERS: 'SET_MISSED_MEASUREMENTS_LIST_FILTERS',
  CLEAR_MISSED_MEASUREMENTS_LIST: 'CLEAR_MISSED_MEASUREMENTS_LIST',
  INCREASE_MISSED_MEASUREMENTS_PAGE: 'INCREASE_MISSED_MEASUREMENTS_PAGE',
  SET_MISSED_MEASUREMENTS_SORTER: 'SET_MISSED_MEASUREMENTS_SORTER',
  SELECT_SENSOR: 'SELECT_SENSOR',
  DESELECT_SENSOR: 'DESELECT_SENSOR',
  GET_SENSOR_MISSED_MEASUREMENTS_LIST_REQUEST: 'GET_SENSOR_MISSED_MEASUREMENTS_LIST_REQUEST',
  GET_SENSOR_MISSED_MEASUREMENTS_LIST_SUCCESS: 'GET_SENSOR_MISSED_MEASUREMENTS_LIST_SUCCESS',
  GET_SENSOR_MISSED_MEASUREMENTS_LIST_FAILURE: 'GET_SENSOR_MISSED_MEASUREMENTS_LIST_FAILURE',
  SET_SENSOR_MISSED_MEASUREMENTS_SORTER: 'SET_SENSOR_MISSED_MEASUREMENTS_SORTER',
  CLEAR_SENSOR_MISSED_MEASUREMENTS_LIST: 'CLEAR_SENSOR_MISSED_MEASUREMENTS_LIST',
  INCREASE_SENSOR_MISSED_MEASUREMENTS_PAGE: 'INCREASE_SENSOR_MISSED_MEASUREMENTS_PAGE',
  NUMBER_OF_SENSORS_PER_PAGE: 80,
  NUMBER_OF_TIMESTAMPS_PER_PAGE: 80
};

export const widgetTypes = {
  DONUT_CHART: 'DONUT_CHART',
  LINE_CHART: 'LINE_CHART',
  BAR_CHART: 'BAR_CHART'
};

export const devicesHealthMetadata = {
  reachability: {
    sortOrder: ['reachable', 'unreachable', 'unassigned'],
    colors: [theme.colors.normal, theme.colors.critical, theme.colors.unknown],
    route: '/devices',
    filters: [
      { name: 'last_measurement', op: 'in', value: [false] },
      { name: 'last_measurement', op: 'in', value: [true] },
      { name: 'last_measurement', op: 'in', value: ['unassigned'] }
    ],
    title: 'Reachability',
    id: 'devices-health-reachability-chart',
    widgetType: widgetTypes.DONUT_CHART
  },
  battery: {
    sortOrder: ['> 20%', '< 20%', '< 10%', 'unknown'],
    colors: [theme.colors.normal, theme.colors.warning, theme.colors.critical, theme.colors.unknown],
    route: '/devices',
    filters: [
      { name: 'battery_level', op: 'in', value: [1] },
      { name: 'battery_level', op: 'in', value: [2] },
      { name: 'battery_level', op: 'in', value: [3] },
      { name: 'battery_level', op: 'in', value: [null] }
    ],
    title: 'Battery',
    id: 'devices-health-battery-chart',
    widgetType: widgetTypes.DONUT_CHART
  },
  wifi: {
    sortOrder: ['strong', 'good', 'normal', 'weak', 'very weak', 'unknown'],
    colors: [theme.colors.normal, theme.colors.slight, theme.colors.moderate, theme.colors.warning, theme.colors.critical, theme.colors.unknown],
    route: '/devices',
    filters: [
      { name: 'wifi_signal', op: 'in', value: [4] },
      { name: 'wifi_signal', op: 'in', value: [3] },
      { name: 'wifi_signal', op: 'in', value: [2] },
      { name: 'wifi_signal', op: 'in', value: [1] },
      { name: 'wifi_signal', op: 'in', value: [0] },
      { name: 'wifi_signal', op: 'in', value: [null] }
    ],
    title: 'WiFi',
    id: 'devices-health-wifi-chart',
    widgetType: widgetTypes.DONUT_CHART
  },
  firmware: {
    colors: [theme.colors.normal, theme.colors.slight, theme.colors.moderate],
    route: '/devices',
    filters: [
      { name: 'firmwares', op: 'in', value: 'legendData' },
      { name: 'firmwares', op: 'in', value: 'legendData' },
      { name: 'firmwares', op: 'not_in', value: 'legendOther', allDropdownsKey: 'allFirmwares' }
    ],
    title: 'Firmware',
    id: 'devices-health-firmware-chart',
    widgetType: widgetTypes.DONUT_CHART
  },
  missed_measurements: {
    route: '/devices',
    widgetType: widgetTypes.BAR_CHART,
    title: 'Missed Measurements',
    id: 'missed-measurements',
    detailedView: MissedMeasurementModal,
    chartArea: { width: '90%', height: '100%' }
  },
  weekly_unreachable: {
    route: '/devices',
    widgetType: widgetTypes.LINE_CHART,
    title: 'Unreachable Since',
    id: 'weekly_unreachable'
  }
};
