import React from 'react';
import PropTypes from 'prop-types';

const SpeedFilterSvg = ({ width, height, fill }) => (
<svg width="18px" height="16px" viewBox="0 0 18 16">
    <g id="AH_v1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2-6-5-ah1-trend" transform="translate(-819.000000, -76.000000)">
            <g id="options" transform="translate(320.000000, 64.000000)">
                <g id="speed-pressed-icon" transform="translate(500.000000, 12.000000)">
                    <rect id="bounds" fill="#FFFFFF" x="0" y="0" width={width} height={height} />
                    <g id="speedometer" transform="translate(0.000000, 3.000000)" fill={fill} fillRule="nonzero" stroke={fill} strokeWidth="0.5">
                        <path d="M9.486,7.61381818 C9.48090909,7.554 9.46381818,7.49418182 9.434,7.43727273 L5.68254545,0.358363636 C5.57181818,0.150363636 5.322,0.0521818182 5.09545455,0.128 C4.86854545,0.204909091 4.73309091,0.432363636 4.77618182,0.664363636 L6.23818182,8.514 C6.24963636,8.57636364 6.27381818,8.63218182 6.30672727,8.68309091 C6.32090909,8.75018182 6.33818182,8.81690909 6.36127273,8.88381818 C6.59672727,9.55545455 7.24090909,10.0081818 7.96363636,10.0081818 C8.15036364,10.0081818 8.336,9.978 8.51345455,9.91781818 C8.94145455,9.77381818 9.28672727,9.47454545 9.48563636,9.07490909 C9.68472727,8.67527273 9.71309091,8.224 9.56636364,7.80327273 C9.544,7.73727273 9.51636364,7.67454545 9.486,7.61381818 Z M7.96418182,0.642363636 C7.69672727,0.642363636 7.48018182,0.855090909 7.48018182,1.11745455 C7.48018182,1.37981818 7.69672727,1.59272727 7.96418182,1.59272727 C11.822,1.59272727 14.9598182,4.67654545 14.9598182,8.46654545 C14.9598182,8.72872727 15.1770909,8.94163636 15.4441818,8.94163636 C15.712,8.94163636 15.9285455,8.72872727 15.9285455,8.46654545 C15.9281818,4.15163636 12.3556364,0.642363636 7.96418182,0.642363636 Z M2.95927273,2.37945455 C1.07872727,3.87381818 0,6.09218182 0,8.46636364 C0,8.72872727 0.216545455,8.94181818 0.484181818,8.94181818 C0.752181818,8.94181818 0.968727273,8.72872727 0.968727273,8.46636364 C0.968727273,6.38090909 1.91618182,4.432 3.56836364,3.11927273 C3.77672727,2.95418182 3.80836364,2.65454545 3.64054545,2.45036364 C3.47272727,2.24636364 3.16781818,2.21436364 2.95927273,2.37945455 Z" id="Shape" />
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

SpeedFilterSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

SpeedFilterSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: '#000000'
};


export default SpeedFilterSvg;
