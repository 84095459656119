import React from 'react';
import PropTypes from 'prop-types';

const AlarmFlagSvg = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 14 16">
    <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3-6-0-Machines-Expanded-Dropdown-Note" transform="translate(-1010.000000, -357.000000)">
        <g id="dropdown" transform="translate(993.000000, 229.000000)">
          <g id="add_event" transform="translate(16.000000, 128.000000)">
            <g id="event-icon">
              <rect id="bounds" x="0" y="0" width={width} height={width} />
              <g id="icon" transform="translate(2.000000, 1.000000)" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                <path d="M0,9.1 C0,9.1 0.72,8.4 2.88,8.4 C5.04,8.4 6.48,9.8 8.64,9.8 C10.8,9.8 11.52,9.1 11.52,9.1 L11.52,0.7 C11.52,0.7 10.8,1.4 8.64,1.4 C6.48,1.4 5.04,0 2.88,0 C0.72,0 0,0.7 0,0.7 L0,9.1 Z" id="Shape" />
                <path d="M0,14 L0,9.1" id="Shape" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

AlarmFlagSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

AlarmFlagSvg.defaultProps = {
  width: 14,
  height: 16,
  fill: '#000000'
};


export default AlarmFlagSvg;
