import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';

import Button from 'common/components/atoms/Button';
import H2 from 'common/typography/H2/H2';
import InputField from 'common/components/atoms/InputField';
import FlexContainer from 'common/components/atoms/FlexContainer';
import * as chartUpdateActions from '../../actions/chartUpdate.actions';
import Footer from '../molecules/Footer';


const ModalFooter = (close, saveSettings, saveDisabled) =>
  <>
    <Button secondary="black" onClick={() => close()}>Cancel</Button>
    <Button onClick={saveSettings} disabled={saveDisabled}>Save</Button>
  </>;

const Container = styled(FlexContainer).attrs({
  direction: 'column',
  alignItems: 'flex-start'
})`
`;

class DemodSpectrumSettingsModal extends React.Component {
  constructor(props) {
    super(props);
    const { tags, errorBandpass } = props;
    this.state = {
      bandpass_lower_cutoff: !errorBandpass ? tags[0].bandpass_lower_cutoff : '',
      bandpass_upper_cutoff: !errorBandpass ? tags[0].bandpass_upper_cutoff : '',
      min_allowed_error: '',
      max_allowed_error: ''
    };
  }

  calculateMinAndMaxAllowedValues = () => {
    const { shaft_speed, fmax } = this.props.config.spectrum;
    const { items: features } = this.props.tags[0].forcingFrequencies;
    const bpfi = features.find(f => f.name === 'bpfi').frequency;
    const bpfo = features.find(f => f.name === 'bpfo').frequency;
    const ftf = features.find(f => f.name === 'ftf').frequency;
    const bsf = features.find(f => f.name === 'bsf').frequency;
    this.setState({
      min_allowed: Math.round(Math.min(3 * shaft_speed, 0.75 * fmax) * 100) / 100,
      max_allowed: Math.round(3 * Math.max(bpfi, bpfo, ftf, bsf) * 100) / 100
    });
  };

  saveSettings = () => {
    const { tags } = this.props;
    const { bandpass_lower_cutoff, bandpass_upper_cutoff } = this.state;
    tags.forEach((tag, idx) => {
      this.props.chartUpdateActions.updateTag(tag.id, {
        bandpass_lower_cutoff: parseFloat(bandpass_lower_cutoff) || null,
        bandpass_upper_cutoff: parseFloat(bandpass_upper_cutoff) || null
      }).then(() => idx === tags.length - 1 && this.props.close(true), error => this.setState({ error }));
    });
  };

  resetDemodSettings = () => {
    const { tags } = this.props;
    tags.forEach((tag, idx) => {
      this.props.chartUpdateActions.updateTag(tag.id, {
        bandpass_lower_cutoff: null,
        bandpass_upper_cutoff: null
      }).then(() => {
        if (idx === tags.length - 1) {
          this.setState({
            bandpass_lower_cutoff: '',
            bandpass_upper_cutoff: ''
          });
          this.props.close(true);
        }
      }, (error) => {
        this.setState({ error });
      });
    });
  };

  onChangeField = (e, type) => {
    const { value } = e.target;
    if (type === 'lower_cutoff') {
      this.setState({
        bandpass_lower_cutoff: value
      }, this.validateFilterCutOffs);
    } else if (type === 'upper_cutoff') {
      this.setState({
        bandpass_upper_cutoff: value
      }, this.validateFilterCutOffs);
    }
  };

  validateFilterCutOffs = () => {
    if (Number(this.state.bandpass_lower_cutoff) > Number(this.state.bandpass_upper_cutoff)) {
      if (!this.state.max_allowed_error) this.setState({
        max_allowed_error: 'Bandpass upper cutoff should be higher than Bandpass lower cutoff'
      });
    } else if (this.state.max_allowed_error) this.setState({ max_allowed_error: '' });
  };

  render() {
    const {
      bandpass_lower_cutoff,
      bandpass_upper_cutoff,
      min_allowed_error,
      max_allowed_error,
      error
    } = this.state;
    const { freq_units, tags } = this.props;
    const disableSave = _.isEqual(tags[0].bandpass_lower_cutoff || '', bandpass_lower_cutoff) && _.isEqual(tags[0].bandpass_upper_cutoff || '', bandpass_upper_cutoff);
    return (
      <>
      <div style={{ marginLeft: '20px' }}>
        {error || (
          <>
            <H2>Band Pass Filter</H2>
            <Container>
              <InputField
                type="number"
                label="Lower Cut Off"
                value={bandpass_lower_cutoff}
                onChange={e => this.onChangeField(e, 'lower_cutoff')}
                prefix={freq_units}
                error={min_allowed_error}
              />
              <InputField
                type="number"
                label="Upper Cut Off"
                value={bandpass_upper_cutoff}
                onChange={e => this.onChangeField(e, 'upper_cutoff')}
                prefix={freq_units}
                error={max_allowed_error}
              />
              <FlexContainer>
                {/* <Button text disabled={_.every(tags, tag => !tag.bandpass_lower_cutoff && !tag.bandpass_upper_cutoff)} onClick={this.resetDemodSettings}>Reset Demod</Button> */}
              </FlexContainer>
            </Container>
          </>
        )}
      </div>
      <Footer footer={() => ModalFooter(this.props.close, this.saveSettings, this.state.max_allowed_error || disableSave)} showWidth={this.props.showWidth} />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  chartUpdateActions: bindActionCreators(chartUpdateActions, dispatch)
});

export default connect(null, mapDispatchToProps)(withTheme(DemodSpectrumSettingsModal));
