import React from 'react';
import { bindActionCreators } from 'redux';
import { Router, Switch } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/styles/theme';
import { connect } from 'react-redux';
import { getActivePartnerBasedOnDomain } from 'home/DomainName/DomainConstants';
import * as currentDomainActions from 'home/DomainName/currentDomain.actions';
import { history } from '../common/helpers';
import './App.css';
import { PrivateRoute, ProtectedLayout, PublicRoute, PublicLayout } from '../common/OnboardingComponents';


const StyledReactToastr = styled(ReduxToastr)`
  .toastr {
    &.rrt-error {
      background-color: #FF4F39 !important;
    }
    &.rrt-success {
      background-color: #91b846 !important;
    }
    &.rrt-warning {
      background-color: #F0C039 !important;
    }
    .rrt-left-container {
      display: none !important;
    }
    .rrt-middle-container {
      margin-left: 0 !important;
      width: 80%;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      padding-left: 16px !important;
      padding-right: 0 !important;
      > .rrt-title {
        font-size: 12px !important;
        font-family: 'Petasense Open Sans' !important;
      }
    }
    .rrt-right-container {
      > button {
        font-size: 30px !important;
        width: 17% !important;
      }
    }
  }
`;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = { theme };
  }

  componentDidMount() {
    const partner = getActivePartnerBasedOnDomain();
    document.title = partner.name;
    const { setActivePartner } = this.props.setActivePartner;

    const { theme: { primaryColor, secondaryColor, backgroundColor, grey, backgroundA } } = partner || {};
    const updatedTheme = {
      ...theme,
      primaryColor,
      secondaryColor,
      backgroundColor,
      grey,
      backgroundA
    };
    this.setState({ theme: updatedTheme });
    setActivePartner(partner);
  }


  render() {
    return (
      <div>
        <ThemeProvider theme={this.state.theme}>
          <Router history={history}>
            <Switch>
              <PublicRoute path="/(login|sso|signup|forgot_password|reset_password|two_factor_auth|terms|associate-with-site)/" component={PublicLayout} />
              <PrivateRoute path="/" component={ProtectedLayout} />
            </Switch>
          </Router>
        </ThemeProvider>
        <StyledReactToastr
          timeOut={3000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  partnerName: state.companyReducer.partner
});


const mapDispatchToProps = dispatch => ({
  setActivePartner: bindActionCreators(currentDomainActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
