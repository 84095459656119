import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

const TickSvg = ({ width, height, fill, inactive, theme }) => {
  if (!fill) fill = theme.primaryColor;
  return (
    <svg width={width} height={height} viewBox="0 0 14 10" version="1.1">
      <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity={inactive ? '0.4' : '1'}>
          <g id="PSWAv.1.0.0-Machines_v1.8.9" transform="translate(-1021.000000, -215.000000)">
              <g id="bearings">
                  <g id="card" transform="translate(204.000000, 24.000000)">
                      <g id="list" transform="translate(24.000000, 128.000000)">
                          <g id="row1" transform="translate(0.000000, 46.000000)">
                              <g id="1.0">
                                  <g id="set/active-icon" transform="translate(792.000000, 14.000000)">
                                      <rect id="bounds" x="0" y="0" width="16" height="16" />
                                      <g id="icon" transform="translate(2.000000, 4.000000)" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                          <polyline id="Shape" points="12 0 3.75 8 0 4.36363636" />
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  );
};

TickSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  inactive: PropTypes.bool
};

TickSvg.defaultProps = {
  width: 14,
  height: 10,
  fill: null,
  inactive: true
};

export default withTheme(TickSvg);
