import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import moment from 'moment-timezone';
import * as d3 from 'd3';
import color from 'common/styles/colors';
import PlusSvg from 'common/images/PlusSvg';
import RefreshSvg from 'common/images/RefreshSvg';
import DuplicateSvg from 'common/images/DuplicateSvg';
import EditSvg from 'common/images/BearingModal/EditIcon';
import DeleteSvg from 'common/images/BearingModal/DeleteIconSvg';
import { SortAlphaUp, SortAlphaDown } from 'common/images/FaIcons';
import CompareIcon from 'common/images/CompareSvg';
import { humanize } from './helpers';
import { mapDayToString } from './constants';


export const HumanizeKeyValue = key => key.replace(/[_]/g, ' ').toUpperCase();
export const CapitalizeEachWord = str =>
  str.split('_').join(' ').toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
export const CapitalizeOnlyFirstLetter = str =>
  str.split('_').join(' ').replace(/\b\w/g, l => l.toUpperCase());
export const getInitials = name =>
  name
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ')
    .map(namePortion => namePortion[0].toUpperCase())
    .join('');

export const findScrollPos = (componentRef) => {
  const domNode = ReactDOM.findDOMNode(componentRef);
  if (domNode) {
    return domNode.offsetTop;
  }
  return false;
};

export const userRoles = {
  petasense_admin: 'Petasense Admin',
  analyst: 'Analyst',
  assembler: 'Assembler',
  admin: 'Account Admin',
  technician: 'Technician',
  manager: 'Manager',
  developer: 'Developer'
};

export const getDateStartLetter = (date) => {
  const day = date.getDay();
  return mapDayToString[day][0];
};

export const formatDate = (date, showTime = true) => {
  if (!showTime) return moment(date).format('MMM Do, YYYY, z');
  return moment(date).format('MMM Do, YYYY h:mm A, z');
};

export const calendarFormatDay = (locale, date) => {
  const day = date.getDay();
  return mapDayToString[day][0];
};

export const padNumber = number =>
  number < 10 && number >= 0 ? `0${number}` : number;

export const formatChartData = (yData, xData, outliers = null, ...additionalValues) => {
  const res = [];
  if (!yData || !xData) return res;

  for (let i = 0; i < yData.length; i++) {
    res[i] = {
      y: yData[i],
      x: xData[i]
    };
    if (outliers) res[i].is_outlier = !!_.find(outliers, d => d.valueOf() === res[i].x.valueOf());
  }
  // if additional data to be included for each data point
  additionalValues.forEach(obj =>
    Object.keys(obj).forEach((key) => {
      if (!obj[key]) return;
      obj[key].forEach((dataPoint, idx) => {
        res[idx][key] = dataPoint;
      });
    })
  );
  return res;
};

export const formatMissedMeasurementsData = (yData, xData) => {
  const res = [['Date', 'Count']];
  if (!yData || !xData) return res;

  for (let i = 0; i < yData.length; i++) {
    res[i + 1] = [moment(xData[i]).format('MMM DD'), yData[i]];
  }
  return res;
};

export const convertTimestringsToDateObjects = (arr) => {
  const res = [];
  if (arr) {
    arr.forEach(time => res.push(moment(time)));
  }
  return res;
};

export const findExcludedMeasurementIds = (
  excludedMeasurements,
  start,
  end,
  fullMeasurements
) => {
  const res = _.map(excludedMeasurements, id =>
    _.find(fullMeasurements, m => m.measurement_id === id)
  );
  return res;
};

export const toFixedXIfNumber = (value, decimals = 2) => {
  if (typeof decimals === 'number') {
    return typeof value === 'number' ? value.toFixed(decimals) : value;
  }
  return value;
};

export function isValidDatetime(value) {
  return moment(value, moment.ISO_8601, true).isValid();
}

export const makeObjectASortedArray = (obj, sortByField) =>
  _(obj)
    .map((value, key) => {
      value.field_name = key;
      return value;
    })
    .sortBy(sortByField)
    .value();

export const isDemodSupported = tag_type => tag_type === 'vibration' || tag_type == 'current';

export const chartRange = (
  data,
  unit_system,
  ampUnitOrType,
  title,
  tag_type,
  type
) => {
  const [dataMax, dataMin] = [d3.max(data, d => d.y), d3.min(data, d => d.y)];
  if (tag_type === 'vibration') {
    let rangeMin; let rangeMax;
    if (type === 'waveform' || type === 'cepstrum') {
      rangeMin = dataMin * 1.2;
      rangeMax = dataMax * 1.2;
    } else if (type === 'demod_spectrum') {
      rangeMax = 1.4 * dataMax;
      rangeMin = 0;
    } else if (type === 'demod_trend') {
      rangeMax = 1.2 * dataMax;
      rangeMin = 0;
    } else {
      // set default values
      let defaultYAxisMax = (type === 'spectrum') ? 1 : 4; // mm/s

      if (unit_system === 2) {
        // units imperial
        defaultYAxisMax = (type === 'spectrum') ? 0.05 : 0.15; // in/s
      }
      if (ampUnitOrType === 'g' || ampUnitOrType === 'acceleration') {
        defaultYAxisMax = (type === 'spectrum') ? 0.1 : 0.4; // g
      }
      rangeMin = 1.2 * dataMin < 0 ? 1.2 * dataMin : 0;
      rangeMax = 1.2 * dataMax > defaultYAxisMax ?
        1.2 * dataMax :
        defaultYAxisMax;
    }
    const range = [rangeMin, rangeMax];
    return range;
  }
  let rangeMax = dataMax < 0 ?
    dataMax * 0.9 :
    dataMax * 1.1;
    // if (rangeMax < 0.01) rangeMax = 0.01;
  let rangeMin = dataMin < 0 ?
    dataMin * 1.1 : 0;

  if (tag_type === 'current' && type === 'spectrum') rangeMin = dataMin;

  rangeMax = Math.max(rangeMax, (dataMax + 0.2 * (dataMax - dataMin)));

  const range = [rangeMin, rangeMax];
  return range;
};

export const getStepGraphRange = (step_graph) => {
  const { amplitudes } = step_graph;
  const max_amp = d3.max(amplitudes);
  const min_amp = d3.min(amplitudes);
  const rangeMax = max_amp < 0
    ? max_amp * 0.9
    : max_amp * 1.1;
  const rangeMin = min_amp < 0
    ? min_amp * 1.1 : 0;
  const range = [rangeMin, rangeMax];
  return range;
};

export const canAccessAdvancedFeatures = (roles) => {
  const canAccess = ['analyst', 'petasense_admin', 'admin'];
  for (let i = 0; i < roles.length; i++) if (canAccess.includes(roles[i])) return true;
  return false;
};

export const isPetasenseAdmin = (roles) => {
  for (let i = 0; i < roles.length; i++) {
    if (roles[i] === 'petasense_admin') return true;
  }
  return false;
};

export const isTechnician = roles => roles.length === 1 && roles[0] === 'technician';

export const canAccessSiteUserManagement = (roles) => {
  const canAccess = ['petasense_admin', 'admin'];
  for (let i = 0; i < roles.length; i++) {
    if (canAccess.includes(roles[i])) return true;
  }
  return false;
};

export const convertSecondsToHours = (seconds) => {
  if (!_.isNumber(seconds)) return null;
  const date = new Date(null);
  date.setSeconds(Math.abs(seconds));
  const result = date.toISOString().substr(11, 5);
  if (seconds < 0) return `-${result}`;
  return `+${result}`;
};

export const makeTimezoneString = (value) => {
  const pst_offset = value.utc_offset;
  const pstOffset = convertSecondsToHours(pst_offset);
  const location = _.replace(value.name, /\//g, ', ').replace('_', ' ');
  return {
    ...value,
    text: `(GMT ${pstOffset} - Greenwich Mean Time) ${location}`,
    value: value.id
  };
};

export const convertTimezones = timezones =>
  _.map(timezones, makeTimezoneString);

export const convertTimeTo12hrFormat = (time) => {
  if (!time) return undefined;
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? ' AM' : ' PM';
    time[0] = +time[0] % 12 || 12;
  }
  return time.join('');
};

export const timelineLabels = (desiredStartTime, interval, period) => {
  const periodsInADay = moment.duration(1, 'day').as(period);

  const timeLabels = [];
  const startTimeMoment = moment(desiredStartTime, 'hh:mm');
  for (let i = 0; i < periodsInADay; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, period);
    timeLabels.push({
      text: startTimeMoment.format('hh:mm A'),
      value: startTimeMoment.format('HH:mm')
    });
  }
  return timeLabels;
};

export const isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isElementCompletelyVisible = (el) => {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  const elemRight = rect.right;
  const elemLeft = rect.left;
  // Only completely visible elements return true:
  const isVisible = (elemTop >= 0)
    && (elemBottom <= (window.innerHeight || document.documentElement.clientHeight))
    && (elemRight <= (window.innerWidth || document.documentElement.clientWidth))
    && (elemLeft >= 0);
  return isVisible;
};

export const isElementPartiallyVisible = (el) => {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  // Partially visible elements return true:
  const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
};

export const formatError = error => error.split(':').pop();

export const getIndexForSameIntegerPart = (arr, num) => {
  const number = parseInt(num, 10);
  for (let i = 0; i < arr.length; i++) {
    if (parseInt(arr[i], 10) === number) return i;
  }
  return -1;
};

export const getMultiplyFactorForAlarm = (feature) => {
  if (feature === 'peak') return Math.sqrt(2);
  if (feature === 'p2p') return 2;
  return 1;
};

export const formattedISOString = timestamp =>
  /* converts iso_8601_string to the following format
    "9th Oct 2019" */
  moment(timestamp).format('Do MMM YYYY');

export const getOperationSvg = (operation, selected = false, theme) => {
  const fill = selected ? theme.primaryColor : color.greyXD;
  const height = 14;
  const width = 14;
  switch (operation) {
    case 'create':
      return <PlusSvg fill={fill} height={height} width={width} />;
    case 'copy':
    case 'paste':
      return <DuplicateSvg fill={fill} />;
    case 'update':
      return <EditSvg fill={fill} height={height} width={width} />;
    case 'delete':
      return <DeleteSvg fill={fill} />;
    case 'compare':
      return <CompareIcon fill={fill} />;
    case 'sort-ascending':
      return <SortAlphaDown style={{ color: fill, height, width }} />;
    case 'sort-descending':
      return <SortAlphaUp style={{ color: fill, height, width }} />;
    case 'restore-list':
      return <RefreshSvg fill={fill} height={height} width={width} />;
    default:
      return null;
  }
};

export const getFreqConversionFactor = (from_freq = 'Hz', to_freq = 'CPM', shaftSpeed = null, shaftSpeedUnit = 'Hz') => {
  from_freq = from_freq.toLowerCase();
  to_freq = to_freq.toLowerCase();
  shaftSpeedUnit = shaftSpeedUnit.toLowerCase();
  let factor = 1;
  if (from_freq !== to_freq) {
    if (from_freq === 'hz' && to_freq === 'cpm') factor = 60;
    else if (from_freq === 'hz' && to_freq === 'orders' && shaftSpeed) {
      factor = 1 / shaftSpeed;
      if (shaftSpeedUnit === 'cpm') factor *= 60;
    } else if (from_freq === 'cpm' && to_freq === 'hz') factor = 1 / 60;
    else if (from_freq === 'cpm' && to_freq === 'orders' && shaftSpeed) {
      factor = 1 / shaftSpeed;
      if (shaftSpeedUnit === 'hz') factor /= 60;
    } else if (from_freq === 'orders' && to_freq === 'hz' && shaftSpeed) {
      factor = shaftSpeed;
      if (shaftSpeedUnit === 'cpm') factor /= 60;
    } else if (from_freq === 'orders' && to_freq === 'cpm' && shaftSpeed) {
      factor = shaftSpeed;
      if (shaftSpeedUnit === 'hz') factor *= 60;
    }
  }
  return factor;
};

export const amplitudeConversionUnitsReferenceMap = {
  'mm/s': 10 ** (-6),
  'in/s': (10 ** (-6)) * 0.0393701,
  g: 10 ** (-8),
  a: 10 ** (-3)
};

export const getAmplitudeConversionReference = (from_units, to_units) => {
  from_units = from_units.toLowerCase();
  to_units = to_units.toLowerCase();
  let reference = null;

  if (to_units === 'db') {
    reference = amplitudeConversionUnitsReferenceMap[from_units];
  } else if (from_units === 'db') {
    reference = amplitudeConversionUnitsReferenceMap[to_units];
  }

  return reference;
};

export const convertToDbScale = (value, reference) => {
  if (!value) value = 0.0001;
  return 20 * Math.log10(value / reference);
};

export const convertFromDbScale = (value, reference) => reference * (10 ** (value / 20));

export const getScaledEnvelope = (spectralEnvelope, currXunit, newUnit, shaftSpeed, initialXUnit) => {
  if (spectralEnvelope && !_.isEmpty(spectralEnvelope.envelope_data)) {
    const conversion_factor = getFreqConversionFactor(currXunit, newUnit, shaftSpeed, initialXUnit);
    if (conversion_factor === 1) return spectralEnvelope;
    let { start_frequencies, end_frequencies } = spectralEnvelope.envelope_data;
    start_frequencies = _.map(start_frequencies, d => d * conversion_factor);
    end_frequencies = _.map(end_frequencies, d => d * conversion_factor);
    return {
      ...spectralEnvelope,
      envelope_data: {
        ...spectralEnvelope.envelope_data,
        start_frequencies,
        end_frequencies,
        x_scale: newUnit === 'Orders' ? 'orders' : 'frequency',
        x_units: newUnit
      }
    };
  }
  return spectralEnvelope;
};

export const getAmpUnitConversionFactor = (from_us, to_us) => {
  let factor = 1;
  if (from_us !== to_us) {
    const num = 0.0393701;
    if (from_us === 1) factor = num;
    else factor = 1 / num;
  }
  return factor;
};

export const convertTemperature = (from_us, to_us, value) => {
  if (from_us !== to_us) {
    if (from_us === 1) return (value * 1.8) + 32;
    if (from_us === 2) return (value - 32) / 1.8;
  }
  return value;
};

export const convertDifferentialTemperature = (from_us, to_us, value) => {
  if (from_us !== to_us) {
    if (from_us === 1) return value * 1.8;
    if (from_us === 2) return value / 1.8;
  }
  return value;
};

export const round = (num, decimal = 3) => Math.round((num + Number.EPSILON) * 10 ** decimal) / 10 ** decimal;

export const dayDifference = (date1, date2) => {
  date1 = new Date(Date.parse(date1));
  date2 = new Date(Date.parse(date2));
  const date_difference = date1 - date2;
  return Math.ceil(date_difference / (1000 * 60 * 60 * 24));
};

export const generateRandomPassword = (length = 10) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%';
  let password = '';
  for (let i = 0; i < length; i++) {
    password += charset[Math.round(Math.random() * charset.length)];
  }
  return password;
};

export const splitRangeIntoEqualParts = (max, min, no_of_bins) => {
  const delta = (max - min) / no_of_bins;
  const bins = [];
  for (let i = 0; i < no_of_bins; i++) {
    const bin = {
      bin_range: [Math.round(min), Math.round(min + delta)],
      bin_no: i + 1
    };
    bins.push(bin);
    min += delta;
  }
  return bins;
};

export const sliceString = (string, limit) => {
  if (string.length > limit) {
    string = string.slice(0, limit);
    string += '...';
  }
  return string;
};

export const isFilterApplied = (searchParams) => {
  let check = false;
  Object.keys(searchParams).forEach((filterKey) => {
    const filterValue = searchParams[filterKey];
    if ((_.isBoolean(filterValue) && filterValue) || (filterValue && filterValue.length > 0)) {
      check = true;
    }
  });
  return check;
};

export const getVibrationDirectionOrders = (vibDirectionPreference = 1) => {
  let order = ['radial', 'tangential', 'axial'];
  if (vibDirectionPreference === 2) order = ['vertical', 'horizontal', 'axial'];
  return order;
};

export const orderObjectsInVibrationDirection = (objs, vibrationDirectionPreference = 1) => {
  const order = getVibrationDirectionOrders(vibrationDirectionPreference);
  objs.sort((a, b) => {
    const A = a.direction;
    const B = b.direction;
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    }
    return -1;
  });
  return objs;
};

export const oneToOneMapping = (dictionary, input) => {
  const replacehandler = (key, dictionary) => dictionary[key];
  const patterns = []; // \b is used to mark boundaries "foo" doesn't match food
  const patternHash = {};
  let oldkey;
  let key;
  let index = 0;
  const output = [];

  Object.keys(dictionary).forEach((key) => {
    key = (oldkey = key).toLowerCase();
    dictionary[key] = dictionary[oldkey];

    // Sanitize the key, and push it in the list
    patterns.push(`\\b(?:${key.replace(/([[^$.|?*+(){}])/g, '\\$1')})\\b`);

    // Add entry to hash variable, for an optimized backtracking at the next loop
    patternHash[key] = index++;
  });

  let pattern = new RegExp(patterns.join('|'), 'gi');
  let lastIndex = 0;

  // reject empty strings
  while (key = pattern.exec(input)) {
    // Case-insensitivity
    key = key[0].toLowerCase();

    // Add to output buffer
    output.push(input.substring(lastIndex, pattern.lastIndex - key.length));

    // actual replacement method
    output.push(replacehandler(key, dictionary));

    // Update lastIndex variable
    lastIndex = pattern.lastIndex;

    // Don't match again by removing the matched word, create new pattern
    patterns[patternHash[key]] = '^';
    pattern = new RegExp(patterns.join('|'), 'gi');

    // IMPORTANT: Update lastIndex property. Otherwise, enjoy an infinite loop
    pattern.lastIndex = lastIndex;
  }
  output.push(input.substring(lastIndex, input.length));
  return output.join('');
};

export const stringWithBlankPrefix = (string, numberOfSpaces) => Array(numberOfSpaces).fill('\xa0').join('') + string;

export const isEnterprise = (subscription_type) => {
  const entSubscription = new RegExp('^ENT-');
  return entSubscription.test(subscription_type);
};

export const convertArrayToString = (values, numberOfValuesToShow = 2) => {
  if (values.length <= numberOfValuesToShow) return values.join(', ');
  return `${values.slice(0, numberOfValuesToShow).join(',  ')}...`;
};

export const getDropdownOptions = (values, humanizeValue = true) => values.map(value => ({
  text: humanizeValue ? humanize(value) : value,
  value
}));

export const isItemEmpty = (item) => {
  if (typeof item === 'number' || typeof item === 'boolean') return false;
  return _.isEmpty(item);
};

export const generateQueryString = (obj, excludeKeys = []) => {
  // filter empty keys
  const params = Object.keys(obj)
    .filter(k => !isItemEmpty(obj[k]) && !excludeKeys.includes(k))
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});

  // handle nested objects
  Object.keys(params).forEach((k) => {
    params[k] = typeof params[k] === 'object' ? JSON.stringify(params[k]) : params[k];
  });

  return new URLSearchParams(params).toString();
};

export const getLocationId = ({ pathname, search, hash }) => (
  pathname + (search ? `?${search}` : '') + (hash ? `#${hash}` : '')
);

export const truncateArray = (options, customLabels = {}, truncateAfterIdx = 2) => (
  options.reduce((prev, curr, idx) => {
    const option = typeof curr === 'string' ? customLabels[curr] || humanize(curr) : curr;

    if (idx === 0) return option;
    if (idx < truncateAfterIdx) return <>{prev}, {option}</>;
    if (idx === truncateAfterIdx) return <>{prev}...</>;
    return <>{prev}</>;
  }, '')
);

export const escapeRegExp = text => (
  text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
);

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const endsWithNumber = text => /\d$/.test(text);

export const getFileExtension = filename => (
  filename.split('.').pop().toLowerCase()
);

export const getReqControllerSignal = (state, type) => {
  let signal = null;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
  if (matches && state) {
    const [, requestName] = matches;
    const controller = state.abortControllers[requestName];
    if (controller) signal = controller.signal;
  }
  return signal;
};

export const splitOnLastDelimiter = (str, delimiter) => {
  const lastIndex = str.lastIndexOf(delimiter);

  // If the delimiter is not found, return the original string
  if (lastIndex === -1) {
    return [str];
  }

  const part1 = str.slice(0, lastIndex); // Everything before the last delimiter
  const part2 = str.slice(lastIndex + 1); // Everything after the last delimiter

  return [part1, part2];
};
