import { userConstants } from '../../onboarding/user.constants';
import { store } from '../../app/store';

export const handleResponse = axiosPromise =>
  axiosPromise
    .then(response => response.data)
    .catch((error) => {
      if (!error.response) {
        // Network error
        return Promise.reject('Network error');
      }
      // server error
      const code = error.response.status;
      const response = error.response.data;

      if (code === 401) { // Unauthorized, so forcibly logging out.
        store.dispatch({ type: userConstants.GET_USER_FAILURE, error });
      }
      if (code === 404) {
        response.code = code;
      }

      return Promise.reject(response);
    });
