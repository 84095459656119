import _ from 'lodash';
import { axiosInstance, ENDPOINT } from '../../../../../common/constants';
import { handleResponse } from '../../../../../common/helpers';
import AnalystSummaryTypes from './analystSummary.types';

export const getLatestAnalystSummary = (machine_id) => {
  const request = machine_id => ({ type: AnalystSummaryTypes.GET_ANALYST_SUMMARY_REQUEST, machine_id });
  const success = summary => ({ type: AnalystSummaryTypes.GET_ANALYST_SUMMARY_SUCCESS, summary });
  const failure = error => ({ type: AnalystSummaryTypes.GET_ANALYST_SUMMARY_FAILURE, error });
  return (dispatch) => {
    dispatch(request(machine_id));
    return handleResponse(axiosInstance.get(ENDPOINT.GET_ANALYST_SUMMARY(machine_id)))
      .then(
        (res) => {
          dispatch(success(res));
        },
        (err) => {
          dispatch(failure(err));
        }
      );
  };
};

export const putAnalystSummary = (machine_id, summary_id, params) => {
  const request = (machine_id, summary_id, params) => ({ type: AnalystSummaryTypes.PUT_ANALYST_SUMMARY_REQUEST, machine_id, summary_id, params });
  const success = () => ({ type: AnalystSummaryTypes.PUT_ANALYST_SUMMARY_SUCCESS });
  const failure = err => ({ type: AnalystSummaryTypes.PUT_ANALYST_SUMMARY_FAILURE, err });
  return (dispatch) => {
    dispatch(request(machine_id, summary_id, params));
    return handleResponse(axiosInstance.put(ENDPOINT.PUT_ANALYST_SUMMARY(machine_id, summary_id), { ...params }))
      .then(
        (res) => {
          dispatch(success());
        },
        (err) => {
          dispatch(failure(err));
        }
      );
  };
};

export const postAnalystSummary = (machine_id, params) => {
  const request = (machine_id, params) => ({ type: AnalystSummaryTypes.POST_ANALYST_SUMMARY_REQUEST, machine_id, params });
  const success = items => ({ type: AnalystSummaryTypes.POST_ANALYST_SUMMARY_SUCCESS, items });
  const failure = error => ({ type: AnalystSummaryTypes.POST_ANALYST_SUMMARY_FAILURE, error });
  return (dispatch) => {
    dispatch(request(machine_id, params));
    return handleResponse(axiosInstance.post(ENDPOINT.POST_ANALYST_SUMMARY(machine_id), { ...params }))
      .then(
        (res) => {
          dispatch(success(res.items));
        },
        (err) => {
          dispatch(failure(err));
        }
      );
  };
};

export const setAnalystSummaryWindow = (value) => {
  return (dispatch) => {
    dispatch({ type: AnalystSummaryTypes.EDIT_EXISTING_ANALYST_SUMMARY, value });
  };
};
