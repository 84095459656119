import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withCookies } from 'react-cookie';

import LoginForm from './LoginForm';
import { userActions } from '../user.actions';
import { OnboardingHeader } from '../../common/OnboardingComponents';

export class LoginPage extends React.Component {
  componentDidMount() {
    const title = this.props.title;
    document.title = `${title}`;

    const { cookies } = this.props;
    cookies.set('otp_try_count', undefined);
  }

  handleSubmit = (values) => {
    const { login, cookies } = this.props;
    const destinationLocation = this.props.location.state;
    const path = destinationLocation ? destinationLocation.pathname : null;
    const searchParams = destinationLocation ? destinationLocation.search : '';
    login(values, cookies, path, searchParams);
  };

  render() {
    return (
      <div className="auth-page">
        <OnboardingHeader />
        <LoginForm onSubmit={this.handleSubmit} />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  title: state.companyReducer.partner.title
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login: userActions.login,
    },
    dispatch,
  );

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
