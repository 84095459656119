import _ from 'lodash';
import AnalystSummaryTypes from '../actions/analystSummary.types';

const initialState = {
  summary: {
    loading: false
  },
  window: {
    edit: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AnalystSummaryTypes.GET_ANALYST_SUMMARY_REQUEST:
      return {
        ...state,
        summary: {
          ...state.summary,
          loading: true
        }
      };

    case AnalystSummaryTypes.GET_ANALYST_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: {
          ...action.summary,
          loading: false,
        }
      };

    case AnalystSummaryTypes.GET_ANALYST_SUMMARY_FAILURE:
      return {
        ...state,
        summary: {
          loading: false,
          error: action.error.message
        }
      };

    case AnalystSummaryTypes.POST_ANALYST_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: {
          ...state.summary,
          loading: false,
        }
      };
    case AnalystSummaryTypes.EDIT_EXISTING_ANALYST_SUMMARY:
      return {
        ...state,
        window: {
          edit: action.value
        }
      };
    default:
      return state;
  }
};
