import React from 'react';
import styled, { withTheme } from 'styled-components';

import FlexContainer from 'common/components/atoms/FlexContainer';
import RibbonItem_T from 'common/components/atoms/RibbonItem';
import RBAC from 'common/rbac/RBAC';
import { getOperationSvg } from 'common/utils';

const IconContainer = styled(FlexContainer)`
  margin: auto;
  padding-left: 0.5em;
`;

const RibbonItem = styled(RibbonItem_T)`
  font-size: 14px;
  white-space: nowrap;
`;

const MenuOptions = ({ items, theme }) => (
  <FlexContainer direction="column">
    {items.map((item, idx) => (
      <RBAC
        key={`${item.resource}-${idx}`}
        resource={item.resource}
        operation={item.operation}
        yes={(
          <FlexContainer onClick={item.onClick}>
            <IconContainer>{getOperationSvg(item.iconOperation || item.operation, item.selected, theme)}</IconContainer>
            <RibbonItem className={`${item.resource}-${item.operation}-enabled`} selected={item.selected}>{item.text}</RibbonItem>
          </FlexContainer>
        )}
        no={(
          <FlexContainer>
            <IconContainer>{getOperationSvg(item.iconOperation || item.operation, false, theme)}</IconContainer>
            <RibbonItem className={`${item.resource}-${item.operation}-disabled`} disabled>{item.text}</RibbonItem>
          </FlexContainer>
        )}
      />
    ))}
  </FlexContainer>
);

export default withTheme(MenuOptions);
