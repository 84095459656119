import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as _ from 'lodash';

import AggressiveReadingIcon from 'common/images/AggressiveReadingIcon';

import * as sensorsActions from '../actions/sensors.actions';
import { localTimeString } from '../../../common/helpers/time';
import { isElementInViewport } from '../../../common/utils';
import { BatteryIcon } from '../../../common/images/BatteryIcon';
import { PoeIcon } from '../../../common/images/PoeIcon';
import WiFiSvg from '../../../common/images/WiFiSvg';
import ListItem from '../../../common/components/atoms/ListItem';
import Checkbox from '../../../common/components/atoms/Checkbox';
import { humanize } from '../../../common/helpers/humanize';
import {
  StandardSensors,
  VectorSensors
} from '../constants/sensors.constants';

const SensorListItem = styled(ListItem)`
  cursor: pointer;
  ${props => props.selected && 'background-color: rgba(145,184,70,0.08);'}
  td {
    height: 54px;
  }
`;

const CheckBoxContainer = styled.div`
  display: inline-flex;
  margin-right: 0.5em;
  vertical-align: middle;
`;

class SensorsItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemRef = React.createRef();
  }

  componentDidMount() {
    if (!this.itemRef || !this.itemRef.current) return;
    const { item: { serial_number }, selectedList } = this.props;
    if (selectedList[0] === serial_number && !isElementInViewport(this.itemRef.current)) {
      this.itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.selectedList, this.props.selectedList) && !this.props.editMode) {
      if (!this.itemRef || !this.itemRef.current) return;
      const { item: { serial_number }, selectedList } = this.props;
      if (selectedList[0] === serial_number && !isElementInViewport(this.itemRef.current)) {
        this.itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }


  getDetails = () => {
    const { selectSensor, getSensorDetails, clearDetails, selectGivenSensor } = this.props.sensorsActons;
    const { selectedList, item: { serial_number, model, type }, editMode, details } = this.props;
    if (editMode || this.props.adminDashboard.adminOpen) {
      if (selectedList.includes(serial_number)) {
        clearDetails([...StandardSensors, ...VectorSensors].includes(type) ? model : 'other', serial_number);
      } else {
        if (!this.props.adminDashboard.adminOpen) getSensorDetails(serial_number);
        selectSensor(serial_number);
      }
    } else {
      const newModel = [...StandardSensors, ...VectorSensors].includes(type) ? model : 'other';
      if (!(details[newModel] && details[newModel].findIndex(s => s.serial_number === serial_number) !== -1)) getSensorDetails(serial_number);
      selectGivenSensor(serial_number);
    }
  };

  getTitle = (item) => {
    let title = null;
    if (item.is_master) {
      title = item.type === 'VSX' ? 'Master VSX' : 'Master Mote';
    }
    return title;
  }

  render() {
    const {
      item,
      columnSizes,
      editMode,
      selectedList,
      columns
    } = this.props;
    const selected = selectedList.includes(item.serial_number);
    let aggressiveIconHoverText = 'Device in aggressive measurement mode, this will impact battery life.';
    if (item.poe || item.tx_poe) {
      aggressiveIconHoverText = 'Device in aggressive measurement mode.';
    }
    return (
      <SensorListItem innerRef={this.itemRef} onClick={this.getDetails} editMode={editMode} selected={selected}>
        {columns.map((column) => {
          switch (column) {
            case 'serial_number':
              return (
                <td width={`${columnSizes[0]}%`} className="device-serial-number">
                  {(editMode !== undefined && editMode || this.props.adminDashboard.adminOpen) && (
                    <CheckBoxContainer>
                      <Checkbox value={selected} />
                    </CheckBoxContainer>
                  )}
                  <span title={this.getTitle(item)}>
                    {item.is_master ? `${item.serial_number}*` : item.serial_number}
                  </span>
                  {item.aggressive_measurement && (
                    <span
                      style={{ float: 'right', paddingRight: '25px' }}
                      title={aggressiveIconHoverText}
                    >
                      <AggressiveReadingIcon />
                    </span>
                  )}
                </td>
              );
            case 'machine':
              return (
                <td width={`${columnSizes[1]}%`}>
                  {item.machine || '--'}
                </td>
              );
            case 'location':
              return (
                <td width={`${columnSizes[2]}%`}>
                  {(() => {
                    let loc = humanize(item.location);
                    if (item.location_description && item.location && item.location.length < 4) {
                      loc += ` - ${humanize(item.location_description)}`;
                    }
                    return loc;
                  })()}
                </td>
              );
            case 'battery_level':
              return (
                <td width={`${columnSizes[3]}%`}>
                  {(() => {
                    let icon = null;
                    if (item.type === 'Transmitter' && item.poe) {
                      icon = <PoeIcon />;
                    } else {
                      icon = <BatteryIcon batteryLevel={item.battery_level} />;
                    }
                    return (icon);
                  })()}
                </td>
              );
            case 'wifi_signal':
              return (
                <td width={`${columnSizes[4]}%`}>
                  {(() => {
                    let svg = null;
                    if (!_.isNull(item.wifi_signal) && (item.model === 'TX' || item.model.includes('VM'))) {
                      svg = <WiFiSvg width={17} height={17} wifiLevel={item.wifi_signal} />;
                    } else if (!item.wifi_signal && item.wifi_rssi) {
                      svg = <div title={`Last WiFi Signal Strength: ${item.wifi_rssi} dB`}>N/A</div>;
                    } else if (!item.wifi_signal && !item.wifi_rssi) {
                      svg = <div>N/A</div>;
                    } else {
                      svg = '--';
                    }
                    return (svg);
                  })()}
                </td>
              );
            case 'last_reported_at':
              return (
                <td width={`${columnSizes[5]}%`}>
                  {localTimeString(item.last_reported_at)}
                </td>
              );
            case 'last_measurement_time':
              return (
                <td width={`${columnSizes[5]}%`}>
                  {localTimeString(item.last_measurement)}
                </td>
              );
            case 'account_id':
              return (
                <td width={`${columnSizes[6]}%`}>
                  {item.account_name || '--'}
                </td>
              );
            case 'firmware':
              return (
                <td width={`${columnSizes[6]}%`}>
                  {item.mcu_firmware || '--'}
                </td>
              );
            default:
              return <></>;
          }
        })}
      </SensorListItem>
    );
  }
}

const mapStateToProps = state => ({
  selectedId: state.sensors.selectedId,
  selectedList: state.sensors.selectedSensorList,
  details: state.sensors.details.details,
  adminDashboard: state.adminDashboard
});

const mapDispatchToProps = dispatch => ({
  sensorsActons: bindActionCreators(sensorsActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorsItem);
