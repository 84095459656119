import React from 'react';
import PropTypes from 'prop-types';

import ListItem from 'common/components/atoms/ListItem';
import { localTimeString } from 'common/helpers/time';

class MissedMeasurementsListItem extends React.Component {
  render() {
    const {
      item,
      columnSizes,
      adminOpen
    } = this.props;

    return (
      <ListItem>
        <td width={`${columnSizes[0]}%`} style={{ textAlign: 'center' }}>
          {localTimeString(item.timestamp)}
        </td>
        {adminOpen && (
          <td width={`${columnSizes[1]}%`} style={{ textAlign: 'center' }}>
           {item.account_name}
          </td>
        )}
      </ListItem>
    );
  }
}


MissedMeasurementsListItem.propTypes = {
  item: PropTypes.string.isRequired,
  columnSizes: PropTypes.array.isRequired,
  adminOpen: PropTypes.bool.isRequired
};

export default MissedMeasurementsListItem;
