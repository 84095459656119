
import { combineReducers } from 'redux';

import MachineDiagnosticsConstants from '../constants/machineDiagnostics.constants';

const rulesInitialState = {
  list: [],
  metadata: {},
  selectedRule: null,
  details: {},
  result: [],
  loading: true
};

const rule = {
  // initial rule structure
  id: -1,
  name: 'New Rule'
};

const rules = (state = rulesInitialState, action) => {
  switch (action.type) {
    case MachineDiagnosticsConstants.GET_RULES_METADATA_SUCCESS:
      return {
        ...state,
        metadata: action.data
      };

    case MachineDiagnosticsConstants.GET_RULES_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case MachineDiagnosticsConstants.GET_RULES_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };

    case MachineDiagnosticsConstants.GET_RULES_LIST_SUCCESS:
      return {
        ...state,
        list: action.data,
        selectedRule: null,
        loading: false
      };

    case MachineDiagnosticsConstants.GET_RULES_RESULT_LIST_SUCCESS:
      return {
        ...state,
        result: action.data
      };

    case MachineDiagnosticsConstants.UPDATE_RULE_RESULT_STATE_SUCCESS:
      if (!action.state) {
        return {
          ...state,
          result: state.result.filter(r => r.id !== action.id)
        };
      }
      return {
        ...state,
        result: state.result.map(r => r.id === action.id ? { ...r, state: 'open' } : r)
      };
    case MachineDiagnosticsConstants.GET_RULE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.data
      };
    case MachineDiagnosticsConstants.UPDATE_RULE_DETAILS_SUCCESS:
      return {
        ...state,
        list: state.list.map(r => r.id === action.id ? { ...r, ...action.data } : r),
        details: {
          ...state.details,
          ...action.data,
        }
      };
    case MachineDiagnosticsConstants.SAVE_RULE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.data
      };
    case MachineDiagnosticsConstants.SELECT_RULE:
      return {
        ...state,
        selectedRule: action.id === state.selectedRule ? null : action.id
      };
    case MachineDiagnosticsConstants.ADD_NEW_RULE_TO_LIST:
      return {
        ...state,
        list: [{ ...rule }, ...state.list]
      };
    case MachineDiagnosticsConstants.REMOVE_RULE_FROM_LIST:
      return {
        ...state,
        list: state.list.filter(rule => rule.id !== action.id)
      };
    case MachineDiagnosticsConstants.ADD_RULE_TO_LIST:
      return {
        ...state,
        list: state.list.map(rule => rule.id === -1 ? action.data : rule)
      };
    case MachineDiagnosticsConstants.CLEAR_RULES:
      return {
        ...state,
        list: []
      };
    default:
      return state;
  }
};

const parameters_trend = (state = {}, action) => {
  switch (action.type) {
    case MachineDiagnosticsConstants.GET_PARAMETERS_TREND_SUCCESS:
      return {
        trends_data: action.data
      };
    default:
      return state;
  }
};

const searchParams = (state = {}, action) => {
  switch (action.type) {
    case MachineDiagnosticsConstants.SET_RULES_SEARCH_PARAMS:
      return action.searchParams;
    case MachineDiagnosticsConstants.CLEAR_RULES_SEARCH_PARAMS:
      return {};
    default:
      return state;
  }
};

const sorter = (state = {}, action) => {
  switch (action.type) {
    case MachineDiagnosticsConstants.SET_RULES_SORTER:
      return {
        sorter: action.sorter
      };
    default:
      return state;
  }
};

const paginater = (state = {}, action) => {
  switch (action.type) {
    case MachineDiagnosticsConstants.SET_RULES_PAGINATER:
      return {
        page: action.page
      };
    default:
      return state;
  }
};

export const machineDiagnostics = combineReducers({
  rules,
  parameters_trend,
  paginater,
  searchParams,
  sorter
});
