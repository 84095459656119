import moment from 'moment-timezone';
import { axiosInstance, ENDPOINT } from 'common/constants';
import { formatChartData } from 'common/utils';
import { handleResponse } from 'common/helpers';
import { ENVELOPE_ACTIONS } from '../constants/envelope.constants';
import { getSelectedPathFromBreadcrumb } from '../utils/assetHierarchyUtils';


export const getTagSpectrums = (tag_id, params, bin_no) => {
  const request = (site_id, machine_id, tag_id, bin_no) => ({
    type: ENVELOPE_ACTIONS.GET_TAG_ENVELOPE_SPECTRUMS_REQUEST,
    payload: {
      machine_id,
      site_id,
      tag_id,
      bin_no
    }
  });
  const success = (site_id, machine_id, tag_id, bin_no, data) => ({
    type: ENVELOPE_ACTIONS.GET_TAG_ENVELOPE_SPECTRUMS_SUCCESS,
    payload: {
      data,
      machine_id,
      site_id,
      tag_id,
      bin_no
    }
  });
  const failure = (site_id, machine_id, tag_id, bin_no, error) => ({
    type: ENVELOPE_ACTIONS.GET_TAG_ENVELOPE_SPECTRUMS_FAILURE,
    payload: {
      error,
      machine_id,
      site_id,
      tag_id,
      bin_no
    }
  });

  if (params.timestamps) {
    const timestamps = [];
    params.timestamps.forEach(timestamp => timestamps.push(moment(timestamp).toISOString()));
    params.timestamps = timestamps;
  }

  return (dispatch, getState) => {
    const { site_id, machine_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);

    dispatch(request(site_id, machine_id, tag_id, bin_no));
    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUMS(tag_id), { params })).then(
      (res) => {
        const data = {
          ...res,
          items: res.items.map((item) => {
            const spectrum_data = formatChartData(item.spectrum_data.mag, item.spectrum_data.freq);
            return {
              ...item,
              spectrum_data,
              exclude: false
            };
          })
        };
        dispatch(success(site_id, machine_id, tag_id, bin_no, data));
        return data;
      },
      (error) => {
        dispatch(failure(site_id, machine_id, tag_id, bin_no, error));
        throw error;
      }
    );
  };
};

export const bulkUpdateTags = (machine_id, data) => () => handleResponse(
  axiosInstance.patch(ENDPOINT.UPDATE_TAGS(machine_id), data)).then(
  res => res,
  (error) => {
    throw error;
  }
);

export const createEnvelopeThresholds = params => () =>
  handleResponse(axiosInstance.post(ENDPOINT.CREATE_ENVELOPE_THRESHOLDS, params)).then(
    res => res,
    (error) => { throw error; }
  );

export const getComputedEnvelopeThresholds = taskId => () =>
  new Promise((resolve, reject) => {
    handleResponse(axiosInstance.get(ENDPOINT.GET_COMPUTED_ENVELOPE_THRESHOLDS(taskId))).then(
      (res) => {
        if (res.state === 'SUCCESS') resolve(res);
        else setTimeout(() => resolve(res), 1500);
      },
      (error) => { reject(error); }
    );
  });

export const recurTillTaskCompleted = (taskId, onSuccess, onPending, onError) =>
  dispatch => dispatch(getComputedEnvelopeThresholds(taskId)).then(
    (res) => {
      if (res.state === 'SUCCESS') {
        onSuccess(res);
        return;
      }
      if (res.state === 'STARTED') {
        onPending(res);
      }
      dispatch(recurTillTaskCompleted(taskId, onSuccess, onPending, onError));
    },
    (error) => {
      onError(error);
    }
  );

export const excludeSpectrums = (tag_id, bin_no, timestamps = []) => (dispatch, getState) => {
  const { site_id, machine_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
  const payload = {
    machine_id,
    site_id,
    tag_id,
    timestamps,
    bin_no
  };
  dispatch({ type: ENVELOPE_ACTIONS.EXCLUDE_ENVELOPE_SPECTRUMS, payload });
};
