import { axiosInstance, ENDPOINT } from '../../../../../../common/constants';
import { handleResponse } from '../../../../../../common/helpers';

export const getBearings = params =>
  handleResponse(axiosInstance.get(ENDPOINT.GET_BEARING_LIST, { params }));

export const addBearing = data =>
  handleResponse(axiosInstance.post(ENDPOINT.ADD_BEARING, data));

export const updateBearing = (data, id) =>
  handleResponse(axiosInstance.put(ENDPOINT.UPDATE_BEARING(id), data));

export const deleteBearing = id =>
  handleResponse(axiosInstance.delete(ENDPOINT.DELETE_BEARING(id)));

export const getAllManufacturers = () =>
  handleResponse(axiosInstance.get(ENDPOINT.GET_ALL_MANUFACTURERS));

export const associateBearing = (data, id) =>
  handleResponse(axiosInstance.post(ENDPOINT.ASSOCIATE_BEARING(id), data));

export const unassignBearing = (data, id) =>
  handleResponse(axiosInstance.put(ENDPOINT.UNASSIGN_BEARING(id), data));
