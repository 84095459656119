import styled from 'styled-components';
import FlexContainer from 'common/components/atoms/FlexContainer';


const NodeItem = styled(FlexContainer).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
})`
  position: relative;
  width: 100%;
  padding: ${props => props.padding || '0px'};
  ${props => props.paddingRight && `padding-right: ${props.paddingRight}`};
  ${props => props.paddingLeft && `padding-left: ${props.paddingLeft}`};
  background-color: transparent;
  height: ${props => props.height || '50px'};
  border-bottom: ${props => props.noBorder ? 'none' : '1px solid #D8DCD3'};
  ${props => props.selected ? `
    background-color: rgba(60,61,58,0.04);
    cursor: default;` : `
    background-color: transparent
    cursor: pointer;
  `};
  }
  &:hover {
    cursor: pointer;
    ${props => props.backgroundColor ? 'background-color: #E5E8E1;' : 'background-color: rgba(60,61,58,0.04);'}
  }
  ${props => props.nohover && `
    &:hover {
      cursor: default;
      ${props => props.backgroundColor ? 'background-color: #E5E8E1;' : 'background-color: initial;'}
    }
  `}
  ${props => props.selected && props.selectedWidth && `
    width: max-content;
  `}
  ${props => props.borders && `
    border: 1px solid #D8DCD3;
    border-radius: 4px;
  `}
  ${props => props.backgroundColor && `
    background-color: #E5E8E1
  `}
`;

export default styled(NodeItem)``;
