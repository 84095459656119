import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';
import InputField_T from 'common/components/atoms/InputField';
import FlexContainer from 'common/components/atoms/FlexContainer';
import AlertPrompt from 'common/components/organisms/AlertPrompt';

import * as assetDetailsActions from '../../../actions/assetDetails.actions';
import * as envelopeActions from '../../../actions/envelope.actions';

const OverallAlarmsOptionsContainer = styled(FlexContainer)`
  border: 1px solid grey;
  border-spacing: 15px;
  border-color:${props => props.theme.colors.lightGray};
  border-radius: 8px;
  padding: 20px;
  width: 50%;
  margin-bottom: 24px;
  flex-wrap: wrap;
`;

const InputField = styled(InputField_T)`
  flex-basis: 50%
  margin-bottom: 0.25em;
`;

const paramsOptions = [
  {
    text: 'Overall Alarms (Velocity)',
    value: 'velocity'
  },
  {
    text: 'Overall Alarms (Acceleration)',
    value: 'acceleration'
  },
  {
    text: 'Demod Alarms',
    value: 'demod'
  },
  {
    text: 'Temperature',
    value: 'temperature'
  },
  {
    text: 'Others',
    value: 'others'
  },
];

class DeleteOverallAlarmModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: this.getInitialParams()
    };
  }

  getOverallAlarmDeleteMessage = () => {
    const { assetInfo: { node_name } } = this.props;
    return `Please select the overall alarms of ${node_name} which you want to delete:`;
  }

  getInitialParams = () => paramsOptions.reduce(
    (params, option) => ({ ...params, [option.value]: true }), {}
  )

  toggleParamsOption = (param) => {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        [param]: !prevState.params[param]
      }
    }));
  }

  renderDeleteOptionsField = () => {
    const { params } = this.state;
    return (
      <OverallAlarmsOptionsContainer>
        {paramsOptions.map(option => (
          <InputField
            type="checkbox"
            value={params[option.value]}
            title={option.text}
            onClick={() => this.toggleParamsOption(option.value)}
          />
        ))}
      </OverallAlarmsOptionsContainer>
    );
  }

  deleteTagAlarms = (tag) => {
    const { params } = this.state;

    let overallAlarmThresholds = _.cloneDeep(tag.alarm_thresholds);
    let overallAlarmSettings = _.cloneDeep(tag.overall_alarm_settings);
    if (tag.type === 'vibration') {
      const thresholdsKeys = ['critical', 'warning'];
      const settingsKeys = ['base_graph', 'threshold', 'lower_outlier_limit'];
      const prefixes = [];

      if (params.velocity) prefixes.push('');
      if (params.demod) prefixes.push('demod_');
      if (params.acceleration) prefixes.push('acceleration_');

      _.forEach(overallAlarmSettings, (value, bin) => {
        _.forEach(settingsKeys, (key) => {
          _.forEach(prefixes, (prefix) => {
            delete overallAlarmSettings[bin][`${prefix}${key}`];
          });
        });
        if (_.isEmpty(overallAlarmSettings[bin])) delete overallAlarmSettings[bin];
      }
      );
      _.forEach(overallAlarmThresholds, (value, bin) => {
        _.forEach(thresholdsKeys, (key) => {
          _.forEach(prefixes, (prefix) => {
            delete overallAlarmThresholds[bin][`${prefix}${key}`];
          });
        });
        if (_.isEmpty(overallAlarmThresholds[bin])) delete overallAlarmThresholds[bin];
      }
      );
    } else if (tag.type === 'temperature') {
      if (params.temperature) {
        overallAlarmThresholds = null;
        overallAlarmSettings = null;
      }
    } else if (params.others) {
      overallAlarmThresholds = null;
      overallAlarmSettings = null;
    }

    const updatedTag = {
      id: tag.id,
      alarm_thresholds: !_.isEmpty(overallAlarmThresholds) ? overallAlarmThresholds : null,
      overall_alarm_settings: !_.isEmpty(overallAlarmSettings) ? overallAlarmSettings : null
    };
    return updatedTag;
  }

  onDeleteOverallAlarms = () => {
    const { site, closeModal } = this.props;
    const node_id = this.props.assetInfo.id;
    this.props.assetDetailsActions.getTags(node_id, site.id, false).then(
      (res) => {
        const tags = _.map(res, t => (this.deleteTagAlarms(t)));
        this.props.envelopeActions.bulkUpdateTags(node_id, { tags }).then(
          (res) => {
            toastr.success(res.message);
            this.props.assetDetailsActions.getTags(node_id, site.id);
            closeModal();
          },
          (error) => {
            toastr.error(error.message);
          }
        );
      }
    );
  }

  isProceedDisabled = () => !_.some(this.state.params);

  render() {
    const { closeModal, theme } = this.props;
    return (
      <AlertPrompt
        message={this.getOverallAlarmDeleteMessage()}
        secondaryMessage="This action can not be undone"
        onCancel={closeModal}
        onProceedDisabled={this.isProceedDisabled()}
        onProceed={this.onDeleteOverallAlarms}
        component={this.renderDeleteOptionsField()}
        proceedText="PROCEED"
        proceedColor={theme.primaryColor}
      />
    );
  }
}

DeleteOverallAlarmModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  assetInfo: PropTypes.object.isRequired,
  site: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch),
  envelopeActions: bindActionCreators(envelopeActions, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(withTheme(DeleteOverallAlarmModal));
