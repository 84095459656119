import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';

import {
  ToggleOff as FaToggleOff,
  ToggleOn as FaToggleOn,
} from 'common/images/FaIcons';
import { CapitalizeEachWord } from 'common/utils';

import H3 from 'common/typography/H3/H3';
import Modal from 'common/components/organisms/Modal';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Button from 'common/components/atoms/Button';
import MinusSvg from 'common/images/MinusSvg';
import LoadingSvg from 'common/images/LoadingSvg';
import PlusSvg from 'common/images/PlusSvg';
import DeleteIconSvg from 'common/images/BearingModal/DeleteIconSvg';
import InputField from 'common/components/atoms/InputField';
import Label_T from 'common/typography/Label/Label';
import * as assetDetailsActions from 'home/AssetHierarchy/actions/assetDetails.actions';
import AddTrendDetailsContainer from './AddTrendDetailsContainer';
import AddDerivedTagContainer from './AddDerivedTagContainer';
import * as machineChartsActions from '../actions/machineCharts.actions';
import { mapNames } from '../machineCharts.constants';


const ConfigContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  border: 1px solid ${props => props.theme.colors.greyL};
  border-radius: 4px;
  margin-bottom: 1em;
  padding: 1em;
`;

const ConfigListItemContainer = styled(FlexContainer).attrs({ alignItems: 'center' })``;

const ColoredCircle = styled.div`
  background-color: ${props => (props.color ? props.color : props.theme.colors.black)};
  border-radius: 50%;
  width: 0.6em;
  height: 0.6em;
  margin-left: 0.5em;
  ${props =>
    props.small &&
    `
    width: 0.2em;
    height: 0.2em;
    margin-left: 0;
  `}
`;

const Label = styled(Label_T)`
color: ${props => props.theme.colors.greyXXD};
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 12px;
  display: block;
`;

const Title = styled.span`
  ${props => props.theme.fonts.lightTitle}
  height: 22px;
  line-height: 22px;
  text-transform: capitalize;
  padding-left: 1.5em;
  padding-right: 2em;
`;

const Property = styled.span`
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 0 0.4em;
`;

const ActionIcons = styled(FlexContainer).attrs({ alignItems: 'center' })`
  margin-left: auto;
  ${Button} {
    margin-top: 1em;
    margin-right: 1em;
  }
`;

const LoadingContainer = styled.div`
  margin: auto;
`;

const SvgContainer = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  border: ${props => props.border};
  margin: 0 0.5em;
  &:hover #edit-icon-stroke {
    stroke: ${props => props.theme.colors.black};
  }
  &:hover #delete-icon-stroke, &:hover #minimise-icon {
    stroke: ${props => props.theme.colors.black};
  }
  &:hover #plus-icon, &:hover #minus-icon {
    fill: ${props => props.theme.colors.black};
  }
  ${props => props.disabled && `
    cursor: not-allowed;
  `}
  ${props => props.icon && `
    display: flex;
    border-radius: 2px;
    border: 1px solid #E5E8E1;
    justify-content: center;
    align-items: center;
    height: 17px;
    width: 17px;
  `}
  transform: ${props => props.transform || ''};
`;

const ChartNameInput = styled(InputField)`
  width: 50%;
  margin: 2em 0 3em;
  label {
    color: ${props => props.theme.colors.greyXXD};
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    line-height: normal;
  display: block;
  }
  > ${FlexContainer} {
    font-size: 12px;
    margin-top: 2em;
  }
`;

const AbsoluteBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: -0.2em;
  background: ${props => props.theme.colors.greyL};
  opacity: 0.5;
  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    opacity: 1;
  }
`;

const AbsoluteLoading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 4;
`;

const SubTitle = styled(H3)`
  font-weight: 600;
  font-size: 10px;
  color: #333;
  margin-bottom: 5px;
  /* padding: 0 0.4em; */
  ${props => props.hover && `
    &:hover {
      cursor: pointer;
      color: ${props.theme.primaryColor};
    }
  `}
`;

const ConfigList = styled.div`
  margin-top: 1em;`;

const TitleContainer = styled(FlexContainer).attrs({ alignItems: 'center' })`
  ${Property}:first-of-type {
    padding-left: 0;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  margin-right: 0.5em;
  cursor: pointer;
`;

const ToggleOn = styled(FaToggleOn)`
  color: ${props => props.theme.primaryColor};
  font-size: 1.5em;
  cursor: pointer;
`;

const ToggleOff = styled(FaToggleOff)`
  color: ${props => props.theme.colors.greyD};
  font-size: 1.5em;
  cursor: pointer;
`;

const ConfigListItem = ({ item, edit, remove, editing, defaultChart, subTitle }) => (
  <ConfigListItemContainer>
    <Title>{CapitalizeEachWord(item.tag_type)}</Title>
    <FlexContainer direction="column">
      <TitleContainer>
        {item.description && <Property>{item.description}</Property>}
        {item.description && (item.amp_type || item.feature) && <ColoredCircle small />}
        {item.amp_type && <Property>{item.amp_type}</Property>}
        {item.amp_type && item.feature && <ColoredCircle small />}
        {item.feature && <Property>{mapNames[item.feature]}</Property>}
      </TitleContainer>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </FlexContainer>
    <ActionIcons>
      <SvgContainer onClick={edit} icon="true">
        {editing ? <MinusSvg height={8} width={8} />
          : <PlusSvg height={15} width={8} fill="#999B95" />}
      </SvgContainer>
      {!defaultChart && (
        <SvgContainer onClick={remove}>
          <DeleteIconSvg />
        </SvgContainer>
      )}
    </ActionIcons>
  </ConfigListItemContainer>
);

const ConstructDeviceLabel = (sensor_serial_number, tx_info) => {
  if (tx_info && tx_info.serial_number) {
    return `${sensor_serial_number} on P${tx_info.port_position} C${tx_info.channel_position} ${tx_info.serial_number}`;
  }
  if (sensor_serial_number) return `${sensor_serial_number}`;
  return '';
};

// Note: This Modal is opened to create non-default multiline charts and Edit Chart
class AddTrendModal extends Component {
  constructor(props) {
    super(props);
    this.addTrendConfig = this.addTrendConfig.bind(this);
    this.editTrendConfig = this.editTrendConfig.bind(this);
    this.submitTrendConfig = this.submitTrendConfig.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.changeChartName = this.changeChartName.bind(this);
    this.removeTrendConfig = this.removeTrendConfig.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.deleteChart = this.deleteChart.bind(this);
    this.validateTrendConfig = this.validateTrendConfig.bind(this);
    this.validateChartConfig = this.validateChartConfig.bind(this);
    this.updateConfig = this.updateConfig.bind(this);
    this.close = this.close.bind(this);

    this.state = {
      chartName: this.props.config ? this.props.config.name : '',
      trends_config: this.props.trends_config,
      selectedGroup: this.props.defaultChart ? this.getGroup(this.props.trends_config[0]) : '',
      nameError: undefined,
      saveEnabled: false,
      editingTrendConfig: this.props.defaultChart,
      derivedTag: false,
      derivedTagConfig: null,
      tags: this.props.tags,
      machineId: this.props.machineId ? this.props.machineId : this.props.machineIdFromStore
    };
  }

  componentDidMount() {
    if (!this.props.configMetadata) {
      this.props.machineChartsActions.getChartsConfigMetadata();
    }
    if (!this.state.tags || this.props.machineIdFromStore !== this.state.machineId) {
      const storeInRedux = this.props.machineIdFromStore === this.state.machineId;
      this.props.machineChartsActions.getMachineTags(this.state.machineId, storeInRedux).then(
        res => this.setState({ tags: res })
      );
    }
    if (!(this.props.config && this.props.config.trends_config)) {
      this.setState(
        {
          new: true
        },
        () => this.addTrendConfig()
      );
      this.componentDidUpdate(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.derivedTag) {
      if (this.state.trends_config.length === 0 && this.state.saveEnabled) {
        // if saveEnabled but trends_config has no items, set saveEnabled to false
        this.setState({
          saveEnabled: false
        });
      } else if (
        !_.isEqual(this.state.trends_config, this.props.trends_config) &&
        !this.state.saveEnabled
      ) {
        // if trends_config is different from what is received from props it has changed, try to validate the config and set saveEnabled true if valid
        if (this.validateChartConfig()) {
          this.setState({
            saveEnabled: true
          });
        }
      } else if (
        !_.isEqual(this.state.trends_config, this.props.trends_config) &&
        this.state.saveEnabled
      ) {
        // if trends_config is different from what is received from props it has changed, try to validate the config and set saveEnabled false if invalid
        if (!this.validateChartConfig()) {
          this.setState({
            saveEnabled: false
          });
        }
      } else if (_.isEqual(this.state.trends_config, this.props.trends_config) && this.state.saveEnabled) {
        // if trends_config is the same as what is received from props it has not changed
        // if this.props.config exists then the config is pre-existing and we check that the new config name is the same as old one
        this.setState({
          saveEnabled: false
        });
      }
      if (!_.isEqual(prevProps.trends_config, this.props.trends_config) && this.state.chartName === '') {
        this.setState({
          chartName: this.props.config ? this.props.config.name : '',
          trends_config: this.props.trends_config
        });
      } else if (!_.isEqual(prevProps.trends_config, this.props.trends_config)) {
        this.setState({
          trends_config: this.props.trends_config
        });
      }
      if (this.props.config) {
        if (this.state.chartName && !_.isEqual(this.props.config.name, this.state.chartName) && !this.state.saveEnabled && !this.state.saveEnabledName) {
          this.setState({
            saveEnabledName: true
          });
        } else if (_.isEqual(this.props.config.name, this.state.chartName) && this.state.saveEnabledName) {
          this.setState({
            saveEnabledName: false
          });
        }
      }
    } else if (this.state.derivedTag) {
      if (this.state.saveEnabled && !this.state.derivedTagConfig) this.setState({
        saveEnabled: false
      });
      else if (!this.state.saveEnabled && this.state.derivedTagConfig) this.setState({
        saveEnabled: true
      });
    }
  }

  changeChartName(e) {
    if (this.state.nameError) {
      this.setState({ nameError: undefined });
    }
    const newState = {
      chartName: e.target.value
    };

    this.setState({
      ...newState
    });
  }

  addTrendConfig() {
    this.setState({
      editingTrendConfig: true,
      new: true,
      selectedGroup: ''
    });
  }

  editTrendConfig(group) {
    if (this.state.editingTrendConfig && this.state.selectedGroup === group) {
      this.setState({
        editingTrendConfig: false,
        selectedGroup: ''
      });
    } else {
      this.setState({
        editingTrendConfig: true,
        selectedGroup: group
      });
    }
  }

  removeTrendConfig(group) {
    const newState = {
      trends_config: _.filter(this.state.trends_config, config => !_.isEqual(this.getGroup(config), group))
    };
    if (_.isEqual(group, this.state.selectedGroup)) {
      newState.editingTrendConfig = false;
      newState.selectedTrendConfig = undefined;
      newState.selectedGroup = '';
    }
    this.setState({
      ...newState
    });
  }

  deleteChart() {
    this.props.close();
    this.props.machineChartsActions.deleteChartsConfig(this.props.config.config_id, this.props.config.from_custom_derived_tag);
  }

  openDeleteModal() {
    this.setState({
      deleteModal: true
    });
  }

  closeDeleteModal() {
    this.setState({
      deleteModal: false
    });
  }

  submitTrendConfig() {
    if (!this.state.derivedTag && this.props.config && this.props.config.config_id) {
      this.setState({ loading: true });
      this.props.machineChartsActions.putChartsConfig(
        this.props.config.config_id,
        this.state.trends_config,
        this.state.chartName,
        this.props.defaultChart,
        this.props.globalDays
      ).then(
        (res) => {
          this.setState({
            nameError: undefined,
            loading: false
          });
          this.close();
        },
        (error) => {
          this.setState({
            nameError: error.toString(),
            loading: false
          });
        },
      );
    } else if (!this.state.derivedTag) {
      this.setState({ loading: true });
      this.props.machineChartsActions.postChartsConfig(
        this.state.machineId,
        this.state.trends_config,
        this.state.chartName,
        this.state.machineId === this.props.machineIdFromStore
      ).then(
        (res) => {
          this.props.postNewConfig(res);
          this.setState({
            nameError: undefined,
            loading: false
          });
          this.props.assetDetailsActions.getHierarchy(true);
          this.close();
        },
        (error) => {
          this.setState({
            nameError: error.toString(),
            loading: false
          });
        },
      );
    } else if (this.state.derivedTag) {
      const tagType = this.state.derivedTagConfig.tagType === 'temperature' &&
        this.state.derivedTagConfig.operation === 'difference' ?
        'differential_temperature' : this.state.derivedTagConfig.tagType;
      const params = {
        name: this.state.chartName,
        machine_id: this.state.machineId,
        tag_ids: this.state.derivedTagConfig.tagIds,
        operation: this.state.derivedTagConfig.operation,
        tag_type: tagType,
        account_id: this.props.accountId
      };
      this.props.machineChartsActions.postDerivedTag(
        params,
        this.state.machineId === this.props.machineIdFromStore
      ).then(
        (res) => {
          this.setState({
            nameError: undefined,
            loading: false
          });
          this.close(true);
        },
        (error) => {
          this.setState({
            nameError: error.toString(),
            loading: false
          });
        }
      );
    }
  }

  cancelEdit() {
    this.setState({
      editingTrendConfig: false,
      selectedGroup: ''
    });
  }

  getGroup = (trend_config) => {
    const { tag_type, amp_type, feature } = trend_config;
    return `${tag_type}_${amp_type}_${feature}`;
  }

  getTakenColors = groupedConfig => groupedConfig.config_list.map(item => item.color)

  getGroupedConfigs = () => {
    const { trends_config } = this.state;
    const groupedConfigs = {};

    if (trends_config && !_.isEmpty(trends_config)) {
      trends_config.forEach((trend_config) => {
        const { tag_type, amp_type, feature } = trend_config;
        const key = this.getGroup(trend_config);

        if (!groupedConfigs[key]) {
          groupedConfigs[key] = {
            tag_type,
            amp_type,
            feature,
            config_list: []
          };
        }

        groupedConfigs[key].config_list.push({ ...trend_config });
      });
    }

    return groupedConfigs;
  }

  updateConfig(config, selectedTagsList) {
    const { selectedGroup, editingTrendConfig } = this.state;
    const groupedConfigs = this.getGroupedConfigs();

    const { tag_type, amp_type, feature } = config;
    const key = this.getGroup(config);

    const configList = selectedTagsList.map(tagItem => ({
      ...config,
      tag_id: tagItem.tag_id,
      color: tagItem.color
    }));

    if (selectedGroup !== '' && editingTrendConfig) {
      if (key !== selectedGroup && Object.keys(groupedConfigs).includes(key)) {
        toastr.error('Selected configuration of Tag Type, Amplitude and Feature already exists!');
        return;
      }
      if (key !== selectedGroup) {
        delete groupedConfigs[selectedGroup];
      }
    } else if (selectedGroup === '' && Object.keys(groupedConfigs).includes(key)) {
      toastr.error('Selected configuration of Tag Type, Amplitude and Feature already exists!');
      return;
    }

    groupedConfigs[key] = {
      tag_type,
      amp_type,
      feature,
      config_list: [...configList]
    };

    let trends_config = [];
    Object.keys(groupedConfigs).forEach(
      (key) => {
        trends_config = [...trends_config, ...groupedConfigs[key].config_list];
      });

    this.setState({
      trends_config,
      selectedGroup: '',
      editingTrendConfig: false
    });
  }

  isAddTagTypeDisabled = () => (
    this.state.selectedGroup === '' && this.state.editingTrendConfig &&
      !_.isEmpty(this.state.trends_config)
  );

  updateDerivedTagConfig = (config) => {
    this.setState({
      derivedTagConfig: config
    });
  }

  validateTrendConfig(trend) {
    if (!trend.tag_id) return false;
    const trendConfigValid = this.props.configMetadata[trend.tag_type].chart_config.every(
      cfg => trend[cfg.name] !== undefined
    );
    return trendConfigValid;
  }

  validateChartConfig() {
    if (this.state.trends_config.length === 0) return false;
    const trendsConfigValid = this.state.trends_config.every(trend =>
      this.validateTrendConfig(trend)
    );
    return trendsConfigValid;
  }

  close(reload = false) {
    const existingChart = this.props.config && this.props.config.config_id;
    if (existingChart) {
      this.props.close(false, reload);
    } else this.props.close(true, reload);
  }

  toggleDerivedTag = () => {
    this.setState(prevState => ({
      derivedTag: !prevState.derivedTag
    }));
  }

  render() {
    // this.state.editingTrendConfig ||
    // (this.props.trends_config && this.props.trends_config.length === 5);
    // if (!this.props.configMetadata) return null;
    const { trends_config, new: newEntry, saveEnabled, saveEnabledName, tags } = this.state;
    const existingChart = this.props.config && this.props.config.config_id;
    const groupedConfigs = this.getGroupedConfigs();

    const takenColors = trends_config ? trends_config.map(cfg => cfg.color) : [];
    const takenColorsByGroup = this.state.selectedGroup !== '' ? this.getTakenColors(groupedConfigs[this.state.selectedGroup]) : [];

    let canSave = false;
    if (!saveEnabled && saveEnabledName) {
      if (this.validateChartConfig()) {
        canSave = true;
      }
    } else if (saveEnabled && this.state.chartName) {
      canSave = true;
    }
    const footer = () => (
    <Fragment>
      {!this.props.defaultChart && (
        <Button
          danger
          onClick={this.openDeleteModal}
          left
          disabled={!existingChart}
        >
          {'Delete'}
        </Button>
      )}
      <Button
        cancel
        onClick={this.close}
      >
        {'Cancel'}
      </Button>
      <Button
        disabled={!canSave}
        onClick={this.submitTrendConfig}
      >
          {'Save'}
      </Button>
    </Fragment>
    );
    const newChartTitle = this.state.chartName ? `${this.state.chartName} > New Chart` : 'New Chart';
    return (
      <Modal
        title={existingChart ? `${this.state.chartName} > Edit Trend` : newChartTitle}
        close={this.close}
        padding="0 8.7em"
        footer={footer}
      >
        {this.state.loading && (
          <>
            <AbsoluteBackground />
            <AbsoluteLoading>
              <LoadingSvg />
            </AbsoluteLoading>
          </>
        )}
        {!this.props.configMetadata ? (
          <LoadingContainer>
            <LoadingSvg />
          </LoadingContainer>
        ) : (
          <FlexContainer direction="column" justifyContent="space-between" flexGrow={1}>
            <div>
              <ChartNameInput
                type="text"
                label="Chart name"
                placeholder="Enter a name for this chart"
                value={this.state.chartName}
                onChange={this.changeChartName}
                maxLength={60}
                error={this.state.nameError}
              />
                <>
                  <FlexContainer>
                    <Label>Chart data</Label>
                  </FlexContainer>
                  {_.isEmpty(this.props.config) && !this.props.defaultChart && (
                    <FlexContainer margintop="0.5em">
                      <BtnContainer
                        onClick={this.toggleDerivedTag}
                      >
                          {this.state.derivedTag ? (
                            <ToggleOn />
                          ) : (
                            <ToggleOff />
                          )}
                      </BtnContainer>
                      <H3>Derived Tag</H3>
                    </FlexContainer>
                  )}
                  <ConfigList>
                    {!this.state.derivedTag && !(this.props.config && this.props.config.from_custom_derived_tag) && trends_config
                      && !_.isEmpty(groupedConfigs) && Object.keys(groupedConfigs).map(key => (
                        <ConfigContainer key={key}>
                          <ConfigListItem
                            key={key}
                            item={{ ...groupedConfigs[key] }}
                            edit={() => this.editTrendConfig(key)}
                            remove={() => this.removeTrendConfig(key)}
                            editing={_.isEqual(this.state.selectedGroup, key)}
                            defaultChart={this.props.defaultChart}
                          />
                          {_.isEqual(this.state.selectedGroup, key)
                            && this.state.editingTrendConfig && (
                              <AddTrendDetailsContainer
                                configGroup={groupedConfigs[key]}
                                selectedGroup={this.state.selectedGroup}
                                cancelEdit={this.cancelEdit}
                                configMetadata={this.props.configMetadata}
                                tags={tags}
                                takenColors={_.filter(
                                  takenColors,
                                  color => !takenColorsByGroup.includes(color)
                                )}
                                updateConfig={this.updateConfig}
                                defaultChart={this.props.defaultChart}
                                loading={this.state.loading}
                              />
                          )}
                        </ConfigContainer>
                    ))}
                      {this.props.config && this.props.config.from_custom_derived_tag && trends_config && trends_config.length > 0 && (
                        <ConfigContainer>
                          <AddDerivedTagContainer
                            title="Derived Tag Configuration"
                            tags={tags}
                            updateConfig={this.updateDerivedTagConfig}
                            config={trends_config[0]}
                          />
                        </ConfigContainer>
                      )}
                    {this.state.selectedGroup === '' && this.state.editingTrendConfig && !this.state.derivedTag && (
                        <ConfigContainer>
                          <AddTrendDetailsContainer
                            title="Tag configuration"
                            cancelEdit={this.cancelEdit}
                            configGroup={{}}
                            selectedGroup={this.state.selectedGroup}
                            configMetadata={this.props.configMetadata}
                            tags={tags}
                            takenColors={takenColors}
                            updateConfig={this.updateConfig}
                            padding="0 0 28px 28px"
                          />
                        </ConfigContainer>
                    )}
                    {this.state.derivedTag && (
                      <ConfigContainer>
                        <AddDerivedTagContainer
                          title="Derived Tag Configuration"
                          tags={tags}
                          updateConfig={this.updateDerivedTagConfig}
                        />
                      </ConfigContainer>
                    )}
                  </ConfigList>
                  {!this.props.defaultChart && !this.state.derivedTag &&
                    !(this.props.config && this.props.config.from_custom_derived_tag) && (
                    <Button
                      text
                      fontSize="12px"
                      onClick={this.addTrendConfig}
                      disabled={this.isAddTagTypeDisabled()}
                    >
                      Add New Tag Type
                    </Button>
                  )}
                </>
            </div>

            {this.state.deleteModal && (
              <Modal title="Delete config" close={this.closeDeleteModal} width="50%" padding="0">
                <span>Are you sure you want to delete this chart config?</span>
                <ActionIcons>
                  <Button secondary={this.props.theme.colors.greyD} onClick={this.closeDeleteModal}>Cancel</Button>
                  <Button secondary={this.props.theme.colors.red} onClick={this.deleteChart}>Delete</Button>
                </ActionIcons>
              </Modal>
            )}
          </FlexContainer>
        )}
      </Modal>
    );
  }
}

AddTrendModal.propTypes = {
  config: PropTypes.object,
  close: PropTypes.func.isRequired,
  postNewConfig: PropTypes.func,
  machineId: PropTypes.number,
  defaultChart: PropTypes.bool
};

AddTrendModal.defaultProps = {
  config: {},
  postNewConfig: () => {},
  machineId: undefined,
  defaultChart: false
};

const mapStateToProps = (state, ownProps) => ({
  machineIdFromStore: !_.isEmpty(state.machineDetails.details) && state.machineDetails.details.object.id,
  configMetadata: state.machineDetails.charts.config ?
    state.machineDetails.charts.config.metadata :
    null,
  trends_config:
    ownProps.config && state.machineDetails.charts.config
      ? _.find(
        state.machineDetails.charts.config.items,
        item => item.config_id === ownProps.config.config_id
      ).trends_config
      : [],
  tags: state.machineDetails.charts.tags,
  accountId: state.user.user.account_id
});

const mapDispatchToProps = dispatch => ({
  machineChartsActions: bindActionCreators(machineChartsActions, dispatch),
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(AddTrendModal));
