import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse } from 'common/helpers';

export const createCompareChartsTags = params => () =>
  handleResponse(axiosInstance.post(ENDPOINT.CREATE_COMPARE_TAGS_CHARTS, params)).then(
    res => res,
    (error) => { throw error; }
  );

export const getCompareTagsCharts = taskId => () =>
  new Promise((resolve, reject) => {
    handleResponse(axiosInstance.get(ENDPOINT.GET_COMPARE_TAGS_CHARTS_RESULTS(taskId))).then(
      (res) => {
        if (res.state === 'SUCCESS') resolve(res);
        else setTimeout(() => resolve(res), 1500);
      },
      (error) => { reject(error); }
    );
  });

export const recurTillChartTaskCompleted = (taskId, onSuccess, onError) =>
  dispatch => dispatch(getCompareTagsCharts(taskId)).then(
    (res) => {
      if (res.state === 'SUCCESS') {
        onSuccess(res);
        return;
      }
      dispatch(recurTillChartTaskCompleted(taskId, onSuccess, onError));
    },
    (error) => {
      onError(error);
    }
  );
