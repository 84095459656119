import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';

import { Chart } from 'common/components/Chart';
import Loading from 'common/components/atoms/Loading';
import FlexContainer from 'common/components/atoms/FlexContainer';
import MultilineSelection from 'common/components/Chart/components/MultilineSelection';
import { chartColors } from 'common/constants';
import Button, { AnimatedButton } from 'common/components/atoms/Button';
import H2 from 'common/typography/H2/H2';
import InputField from 'common/components/atoms/InputField';
import CrossSvg from 'common/images/CrossSvg';
import { CapitalizeEachWord, chartRange, convertTimestringsToDateObjects } from 'common/utils';
import OutlierSettingsModal from './atoms/OutlierSettingsModal';
import NoDataChart from '../../MachineCharts/components/NoDataChart';
import BaselineValidator from './organisms/BaselineValidator';
import machineMLTypes from '../actions/machineML.types';
import BaselineDetails from './BaselineDetails';


const ActionButtons = styled(FlexContainer).attrs({ direction: 'row', justifyContent: 'space-between' })`
  margin-bottom: 2em;
  div ${Button} {
    margin-left: 1em;
  }
  ${AnimatedButton} {
    margin-left: 1em;
  }
  span {
    color: ${props => props.theme.colors.green};
  }
`;

const LimitInputField = styled(InputField)`
  margin-bottom: 12px;
  input {
    font-size: 12px;
  }
  label {
    margin-bottom: 3px;
  }
`;


const ChartContainer = styled.div`
  position: relative;
`;

const AbsoluteLoading = styled(Loading)`
  position: absolute;
  height: 91%;
  width: 97.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e447;
  margin-left: 53px;
`;

const OutlierSettingsContainer = styled.div`
  position: relative;
`;

const Label = styled(H2)`
  padding: 0;
`;

const NoDataContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  padding-bottom: 1em;
  margin-top: 1em;
`;

const RightSideContainer = styled(FlexContainer).attrs({ direction: 'row' })`
  margin-left: auto;
  padding-right: 3%;
`;

class TagsBaselineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      baseline_info: props.tagInfo.baseline_info,
      showValidationOutliers: {},
      startTime: props.tagInfo.baseline_info && props.tagInfo.baseline_info.start_time,
      endTime: props.tagInfo.baseline_info && props.tagInfo.baseline_info.end_time
    };
  }

  componentDidMount() {
    this.calculateChartDataModifications();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isMultiSetItem } = this.props;

    if (!_.isEqual(this.state.data, this.getDataFromProps())) {
      this.calculateChartDataModifications();
    }
    if (prevProps.tagInfo && !_.isEqual(prevProps.tagInfo.baseline_info, this.props.tagInfo.baseline_info)) {
      this.cancel();
    }
    if (isMultiSetItem && !_.isEqual(prevState.baseline_info, this.state.baseline_info)) {
      this.props.setBaselineValue(this.state.baseline_info, this.props.tagInfo.tag_type);
    }
    if (this.props.multiSetBaselineInfo && isMultiSetItem && !_.isEqual(prevProps.isMultiSetItem, isMultiSetItem)) {
      this.updateBaseline(this.props.multiSetBaselineInfo && this.props.multiSetOutlierInfo);
    }
    if (isMultiSetItem && !_.isEqual(prevProps.multiSetBaselineInfo, this.props.multiSetBaselineInfo)) {
      this.updateBaseline(this.props.multiSetBaselineInfo);
    }
    if (isMultiSetItem && !_.isEqual(prevProps.multiSetOutlierInfo, this.props.multiSetOutlierInfo)) {
      this.updateBaseline(this.props.multiSetOutlierInfo);
    }
  }

  calculateChartDataModifications = () => {
    const { component, tagInfo, unit_system } = this.props;
    const tags_data = component.tags_data.filter(t => _.includes(tagInfo.tag_ids, t.tag_id) && !_.isEmpty(t.trend));
    const data = tags_data.map(t => t.trend);
    let range = null;
    tags_data.forEach((tag) => {
      const itemRange = chartRange(tag.trend, unit_system, tag.units, '', tag.tag_type, 'trend');
      if (!range) {
        range = itemRange;
      } else {
        if (itemRange[1] > range[1]) range[1] = itemRange[1];
        if (itemRange[0] < range[0]) range[0] = itemRange[0];
      }
    });
    this.setState({
      data,
      range
    }, () => this.setInitialSelection());
  }

  setInitialSelection = () => {
    const trendsWithData = _.filter(this.state.data, d => d.length > 0);
    const selections = trendsWithData.map(data => ({
      ...data[data.length - 1],
    }));
    if (selections) {
      this.setState({
        selections
      });
    }
  }

  getDataFromProps = () => {
    const { component, tagInfo } = this.props;
    return component.tags_data.filter(t => _.includes(tagInfo.tag_ids, t.tag_id) && !_.isEmpty(t.trend)).map(t => t.trend);
  }

  mouseoverChart = (selections) => {
    this.setState({
      selections
    });
  }

  updateBaseline = (params) => {
    this.setState(prevState => ({
      baseline_info: {
        ...prevState.baseline_info,
        ...params
      }
    }), () => {
      this.props.save(
        this.state.baseline_info,
        this.props.component.tags_data.filter(t => _.includes(this.props.tagInfo.tag_ids, t.tag_id) && !_.isEmpty(t.trend)).map(t => t.tag_id),
        'validate'
      );
    });
  }

  updateLimit = (limit, value) => {
    if (!Number.isNaN(parseFloat(value)) || value === '') {
      this.updateBaseline({
        [limit]: value
      });
    }
  }

  cancel = () => {
    this.setState({
      baseline_info: this.props.tagInfo.baseline_info
    });
    const tagIds = this.props.tagInfo.tag_ids;
    this.props.clearValidationResult(tagIds);
  }

  save = () => {
    const { tagInfo, save, component } = this.props;
    const { baseline_info } = this.state;
    const tagIds = component.tags_data.filter(t => _.includes(tagInfo.tag_ids, t.tag_id) && !_.isEmpty(t.trend)).map(t => t.tag_id);
    this.setState({
      startTime: baseline_info.start_time,
      endTime: baseline_info.end_time
    })
    save(baseline_info, tagIds);
  }

  deleteBaselines = () => {
    const { tagInfo, deleteBaselines } = this.props;
    this.toggleDeleteConfirm();
    deleteBaselines(tagInfo.tag_ids);
  }

  toggleDeleteConfirm = () => {
    this.setState(prevState => ({ deleteConfirm: !prevState.deleteConfirm }));
  }

  toggleValidationOutliers = (tagId) => {
    this.setState(prevState => ({
      showValidationOutliers: {
        ...prevState.showValidationOutliers,
        [tagId]: !prevState.showValidationOutliers[tagId]
      }
    }));
  }

  getOutliers = () => {
    const { showValidationOutliers } = this.state;
    const { component } = this.props;

    const outliers = [];
    if (_.isEmpty(showValidationOutliers)) return outliers;

    const tagsData = component && component.tags_data;
    tagsData.forEach((tagData) => {
      if (!showValidationOutliers[tagData.tag_id]) return;
      if (_.isEmpty(tagData.trend)) return;

      const validation = tagData.validation;
      if (_.isEmpty(validation) || _.isEmpty(validation.outliers_validation)) return;

      let tagOutliers = validation.outliers_validation.outliers;
      if (_.isEmpty(tagOutliers)) return;

      tagOutliers = convertTimestringsToDateObjects(tagOutliers);

      tagData.trend.forEach((point) => {
        const is_outlier = !!_.find(tagOutliers, d => d.valueOf() === point.x.valueOf());
        if (is_outlier) {
          outliers.push(point);
        }
      });
    });

    return outliers;
  }

  renderNoDataChart = () => {
    const { tagInfo, days, component } = this.props;
    let title = '';
    if (_.isEmpty(component.tags_data) && !component.loading) {
      title = `No sensors associated to the component ${component.name}`;
    } else if (component.loading) {
      title = '';
    } else {
      title = `No ${tagInfo.tag_type} data available for this component ${component.name}`;
      if (days) title += ` ${days} days`;
    }

    return (
    <NoDataContainer>
      <NoDataChart
        tagType={tagInfo.tag_type}
        configId={`${component.id}-${tagInfo.tag_type}`}
        height="120px"
        title={title}
        loading={component.loading}
      />
    </NoDataContainer>
    );
  }

  render() {
    const { component, tagInfo, isMultiSetActive, multiSetComponentHandler, isMultiSetItem, theme, ahsFeaturesInfo, isDetailsViewOpen } = this.props;
    const { data, range, baseline_info, showValidationOutliers, startTime, endTime } = this.state;
    const tags_data = component.tags_data.filter(t => _.includes(tagInfo.tag_ids, t.tag_id) && !_.isEmpty(t.trend));
    const yUnit = !_.isEmpty(tags_data) ? tags_data[0].units : '';
    const validationLoading = machineMLTypes.VALIDATE_COMPONENT_BASELINE in this.props.loadingReducer ?
      this.props.loadingReducer[machineMLTypes.VALIDATE_COMPONENT_BASELINE] : false;
    const saveLoading = tags_data && _.some(tags_data.map(tagData => tagData.saveLoading));

    if (_.isEmpty(tags_data) || _.isEmpty(data) || _.every(data, d => _.isEmpty(d))) {
      return (
        <>
        {this.renderNoDataChart()}
        </>
      );
    }
    return (
      <>
      <RightSideContainer>
        <OutlierSettingsContainer>
          <Button
            text
            onClick={() => this.setState({ showOutlierSettings: true })}
            width="150px"
            disabled={!baseline_info.start_time}
          >
            Outlier Settings
          </Button>
          {this.state.showOutlierSettings && (
            <OutlierSettingsModal>
              <FlexContainer justifyContent="space-between">
                <Label>Outlier Settings</Label>
                <CrossSvg width={20} height={20} onClick={() => this.setState({ showOutlierSettings: false })} />
              </FlexContainer>
              <LimitInputField
                label="Lower Limit"
                prefix={yUnit}
                type="number"
                value={baseline_info.outlier_lower_limit}
                onChange={e => this.updateLimit('outlier_lower_limit', e.target.value)}
              />
              <LimitInputField
                label="Upper Limit"
                prefix={yUnit}
                type="number"
                value={baseline_info.outlier_upper_limit}
                onChange={e => this.updateLimit('outlier_upper_limit', e.target.value)}
              />
            </OutlierSettingsModal>
          )}
        </OutlierSettingsContainer>
      </RightSideContainer>
      <FlexContainer direction="row" width="80%" alignSelf="center">
        {!_.isEmpty(this.state.selections) && (
          <MultilineSelection
            selections={this.state.selections}
            yUnits={tags_data.map(t => t.units)}
            colors={chartColors}
            yParameters={tags_data.map(t => t.location_description || t.location_name || component.name)}
          />
        )}
      </FlexContainer>
      <ChartContainer>
        <>
          {component.loading && (
            <AbsoluteLoading />
          )}
          <Chart
            type="baseline"
            chartName={`${tagInfo.tag_type}-baseline-${component.id}`}
            data={data}
            colors={[theme.primaryColor, ...chartColors.slice(1)]}
            activeData={data.map(() => true)}
            title={`Set ${component.name} ${CapitalizeEachWord(tagInfo.tag_type)} Baselines`}
            contextChart
            height="120px"
            loading={component.loading}
            range={range}
            updateBaseline={this.updateBaseline}
            baselineStart={baseline_info.start_time}
            baselineEnd={baseline_info.end_time}
            isMultiSetActive={isMultiSetActive}
            isMultiSetItem={isMultiSetItem}
            outliersArray={this.getOutliers()}
            setMultiSetItem={() => multiSetComponentHandler(component.id, component.name, [tags_data[0].tag_id], isMultiSetItem, 'tag')}
            outlierLowerLimit={baseline_info.outlier_lower_limit}
            outlierUpperLimit={baseline_info.outlier_upper_limit}
            ylabel="Amplitude"
            mouseover={this.mouseoverChart}
            chart_size="normal"
          />
        </>
      </ChartContainer>
      <ActionButtons>
        {this.state.deleteConfirm ? (
          <FlexContainer alignItems="center">
            <span>Delete {CapitalizeEachWord(tagInfo.tag_type)} baselines ?</span>
            <Button onClick={this.deleteBaselines}>Yes</Button>
            <Button onClick={this.toggleDeleteConfirm}>No</Button>
          </FlexContainer>
        ) : (
          <Button
            disabled={saveLoading || !tagInfo.baseline_info.start_time || isMultiSetItem}
            onClick={this.toggleDeleteConfirm}
          >
            Delete
          </Button>
        )}
        <FlexContainer alignItems="center">
          <BaselineValidator
            validationLoading={validationLoading}
            saveLoading={saveLoading}
            buttonDisabled={(_.isEqual(baseline_info, tagInfo.baseline_info) && !this.props.seedHealthScoreChanged) || this.props.isMultiSetItem}
            component={this.props.component}
            tagIds={this.props.tagInfo.tag_ids}
            toggleOutliers={this.toggleValidationOutliers}
            outliersShown={showValidationOutliers}
            isMultiSetActive={isMultiSetActive}
            onSave={this.save}
            user={this.props.user}
          />
          <Button
            disabled={saveLoading || (_.isEqual(baseline_info, tagInfo.baseline_info) && !this.props.seedHealthScoreChanged)}
            onClick={this.cancel}
          >
            Clear
          </Button>
        </FlexContainer>
      </ActionButtons>
      {isDetailsViewOpen && (
        <BaselineDetails
          baselineInfo={tagInfo.baseline_info}
          ahsFeaturesInfo={ahsFeaturesInfo}
          tagTypes={[tagInfo.tag_type]}
          componentId={component.id}
          component={component}
          tagIds={tagInfo.tag_ids}
          startTime={baseline_info.start_time}
          endTime={baseline_info.end_time}
        />
      )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  loadingReducer: state.loadingReducer,
  user: state.user.user
});

export default connect(mapStateToProps, null)(withTheme(TagsBaselineItem));
