import { reportActionConstants } from 'home/Reports/actions/reports.constants';
import _ from 'lodash';

const initialState = {
  selectedAssets: [],
  selectedAssetForCharts: '',
  selectedCharts: {},
  assetDetailsImgs: {},
  assetDetailsTasks: {},
  chartsAssetSorterState: {
    sorter: {
      order: 'asc',
      name: 'name'
    }
  },
};

export const reportAssetsChartsReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportActionConstants.INIT_REPORTS: {
      const resetState = {
        selectedAssets: [],
        selectedAssetForCharts: '',
        selectedCharts: {},
        assetDetailsImgs: {},
        assetDetailsTasks: {},
      };
      return {
        ...resetState
      };
    }
    case reportActionConstants.ADD_ASSET_TO_REPORT: {
      const tempArr = state.selectedAssets.filter(value => value.id !== action.asset.id);
      return {
        ...state,
        selectedAssets: _.union(tempArr, [action.asset])
      };
    }
    case reportActionConstants.SET_ASSET_OVERVIEW_AVAILABLE: {
      return {
        ...state,
        assetOverviewAvailable: true,
      };
    }
    case reportActionConstants.CLEAR_ASSET_OVERVIEW_AVAILABLE: {
      const nextState = _.cloneDeep(state);
      delete nextState.assetOverviewAvailable;
      return {
        ...nextState,
      };
    }
    case reportActionConstants.REMOVE_ASSET_FROM_REPORT: {
      const tempArr = state.selectedAssets.filter(value => value.id !== action.assetId);
      return {
        ...state,
        selectedAssets: tempArr
      };
    }
    case reportActionConstants.SELECT_ASSET_FOR_CHARTS: {
      const tempObj = state.selectedCharts;
      tempObj[action.assetId] = tempObj[action.assetId] || [];
      return {
        ...state,
        selectedAssetForCharts: action.assetId,
        selectedCharts: {
          ...tempObj,
        },
      };
    }
    case reportActionConstants.ADD_CHART_TO_REPORT: {
      const tempObj = _.cloneDeep(state.selectedCharts);
      const tempArr = tempObj[action.assetId].filter(chart => chart.config_id === action.chart.config_id);
      if (tempArr.length > 0) {
        tempArr[0].selected[action.chartType] = true;
        const tempArr_1 = tempObj[action.assetId].filter(chart => chart.config_id !== action.chart.config_id);
        tempObj[action.assetId] = tempArr_1;
        tempObj[action.assetId].push(tempArr[0]);
      } else {
        action.chart.selected = {};
        action.chart.selected[action.chartType] = true;
        tempObj[action.assetId].push(action.chart);
      }
      return {
        ...state,
        selectedCharts: {
          ...tempObj,
        }
      };
    }
    case reportActionConstants.REMOVE_CHART_FROM_REPORT: {
      const tempObj = state.selectedCharts;
      const tempArr = tempObj[action.assetId].filter(chart => chart.config_id === action.configId);
      tempArr[0].selected[action.chartType] = false;
      const tempArr_1 = tempObj[action.assetId].filter(chart => chart.config_id !== action.configId);
      if (!tempArr[0].selected.trend && !tempArr[0].selected.spectrum && !tempArr[0].selected.waveform) {
        tempObj[action.assetId] = tempArr_1;
      } else {
        tempObj[action.assetId] = tempArr_1;
        tempObj[action.assetId].push(tempArr[0]);
      }
      return {
        ...state,
        selectedCharts: {
          ...tempObj,
        }
      };
    }
    case reportActionConstants.ADD_CHART_IMAGE_TO_REPORT: {
      const tempObj = _.cloneDeep(state.selectedCharts);
      const chartObj = tempObj[action.assetId].filter(chart => (chart.config_id === action.configId));
      chartObj[0].imgData = action.dataUri;
      return {
        ...state,
        selectedCharts: {
          ...tempObj
        }
      };
    }
    case reportActionConstants.ADD_ASSET_DETAILS_IMAGE_TO_REPORT: {
      const imgObjects = _.cloneDeep(state.assetDetailsImgs);
      imgObjects[action.assetId] = imgObjects[action.assetId] || [];
      imgObjects[action.assetId].push(action.dataUri);
      return {
        ...state,
        assetDetailsImgs: {
          ...imgObjects
        }
      };
    }
    case reportActionConstants.ADD_TASKS_TO_REPORT: {
      const dataOdj = _.cloneDeep(state.assetDetailsTasks);
      dataOdj[action.assetId] = dataOdj[action.assetId] || [];
      dataOdj[action.assetId].push(...action.data);
      return {
        ...state,
        assetDetailsTasks: {
          ...dataOdj
        }
      };
    }
    case reportActionConstants.SET_CHARTS_ASSETS_SORTER: {
      const nextState = _.cloneDeep(state);
      nextState.selectedAssets.sort((a, b) => (action.sorterState.order === 'asc' ? a[action.sorterState.name].localeCompare(b[action.sorterState.name]) : b[action.sorterState.name].localeCompare(a[action.sorterState.name])));
      nextState.chartsAssetSorterState.sorter = action.sorterState;
      return {
        ...nextState,
      };
    }
    default:
      return state;
  }
};
