import styled from 'styled-components';

import FlexContainer from '../../atoms/FlexContainer';

const DateContainer = styled(FlexContainer).attrs({
  justifyContent: 'flex-start',
  flexDirection: 'column',
})`
  flex-direction: column;
  height: 100%;
  display: inline-flex;
  vertical-align: top;
  width: 119px;
  overflow: hidden;
`;

export default styled(DateContainer)``;
