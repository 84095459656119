import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'common/components/atoms/Button';
import colors from 'common/styles/colors';

const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 10px;
  ${props => props.paddingTop && `padding-top: ${props.paddingTop}`};
  ${props => props.showBorderTop && `border-top: 1px solid ${colors.greyXL}`};
`;

function Footer({ onCancel, onSave, showCreateTaskTemplate, saveDisabled, paddingTop, showBorderTop, showCancel, onCreateTaskTemplate }) {
  return (
    <ModalFooterContainer paddingTop={paddingTop} showBorderTop={showBorderTop}>
      {showCancel && (
        <Button id="footer-cancel" cancel onClick={onCancel}>
          CANCEL
        </Button>
      )}
       {showCreateTaskTemplate && (
        <Button id="footer-cancel" margin="0 0 0 1rem" onClick={onCreateTaskTemplate} disabled={saveDisabled}>
          Create Template
        </Button>
       )}
      <Button id="footer-generate-continue" margin="0 0 0 1rem" onClick={onSave} disabled={saveDisabled}>
        SAVE
      </Button>
    </ModalFooterContainer>
  );
}

Footer.propTypes = {
  showCancel: PropTypes.bool,
  showCreateTaskTemplate: PropTypes.bool,
};

Footer.defaultProps = {
  showCancel: true,
  showCreateTaskTemplate: false
};

export default Footer;
