import styled from 'styled-components';

const Text = styled.span`
  font-size: 17px;
  color: ${props => props.theme.colors.black};
  white-space: pre-line;
  ${props => props.bold && 'font-weight: 600;'}
`;

Text.displayName = 'Text';

export default Text;
