import styled from 'styled-components';
import FlexContainer from './FlexContainer';
import SecondaryText from '../../typography/SecondaryText/SecondaryText';

const MetadataContainer = styled(FlexContainer).attrs({ flexWrap: 'wrap' })`
  margin-top: 0.5em;
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
  ${SecondaryText} {
    position: relative;
    &:not(:last-child) {
      padding-right: 20px;
      &:after {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${props => props.theme.colors.darkGray};
        position: absolute;
        right: 8px; // 20px space from padding, move to center would be 10px but width is 4px so move 2px more -> 8px
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

export default styled(MetadataContainer)``;
