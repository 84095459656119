export default {
  GET_MACHINE_CHARTS_TAGS_REQUEST: 'GET_MACHINE_CHARTS_TAGS_REQUEST',
  GET_MACHINE_CHARTS_TAGS_SUCCESS: 'GET_MACHINE_CHARTS_TAGS_SUCCESS',
  GET_MACHINE_CHARTS_TAGS_FAILURE: 'GET_MACHINE_CHARTS_TAGS_FAILURE',

  GET_TAG_TREND_REQUEST: 'MACHINE_DETAILS_TAG_CHART/GET_TAG_TREND_REQUEST',
  GET_TAG_TREND_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/GET_TAG_TREND_SUCCESS',
  GET_TAG_TREND_FAILURE: 'MACHINE_DETAILS_TAG_CHART/GET_TAG_TREND_FAILURE',

  GET_TAG_WAVEFORM_REQUEST: 'GET_TAG_WAVEFORM_REQUEST',
  GET_TAG_WAVEFORM_SUCCESS: 'GET_TAG_WAVEFORM_SUCCESS',
  GET_TAG_WAVEFORM_FAILURE: 'GET_TAG_WAVEFORM_FAILURE',

  GET_TAG_SPECTRUM_REQUEST: 'GET_TAG_SPECTRUM_REQUEST',
  GET_TAG_SPECTRUM_SUCCESS: 'GET_TAG_SPECTRUM_SUCCESS',
  GET_TAG_SPECTRUM_FAILURE: 'GET_TAG_SPECTRUM_FAILURE',

  GET_TAG_DEMOD_SPECTRUM_REQUEST: 'GET_TAG_DEMOD_SPECTRUM_REQUEST',
  GET_TAG_DEMOD_SPECTRUM_SUCCESS: 'GET_TAG_DEMOD_SPECTRUM_SUCCESS',
  GET_TAG_DEMOD_SPECTRUM_FAILURE: 'GET_TAG_DEMOD_SPECTRUM_FAILURE',


  GET_TAG_WATERFALL_SPECTRUM_REQUEST: 'GET_TAG_WATERFALL_SPECTRUM_REQUEST',
  GET_TAG_WATERFALL_SPECTRUM_SUCCESS: 'GET_TAG_WATERFALL_SPECTRUM_SUCCESS',
  GET_TAG_WATERFALL_SPECTRUM_FAILURE: 'GET_TAG_WATERFALL_SPECTRUM_FAILURE',

  CHARTS_FILTERS: 'CHARTS_FILTERS',

  REORDER_CHARTS: 'REORDER_CHARTS',
  REORDER_CHARTS_FAILURE: 'REORDER_CHARTS_FAILURE',

  CLEAR_CHARTS_TAGS: 'CLEAR_CHARTS_TAGS',

  spectrumFeature: {
    GET_FEATURES_REQUEST: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/GET_FEATURES_REQUEST',
    GET_FEATURES_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/GET_FEATURES_SUCCESS',
    GET_FEATURES_FAILURE: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/GET_FEATURES_FAILURE',

    CREATE_FEATURE_REQUEST: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/CREATE_FEATURE_REQUEST',
    CREATE_FEATURE_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/CREATE_FEATURE_SUCCESS',
    CREATE_FEATURE_FAILURE: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/CREATE_FEATURE_FAILURE',

    UPDATE_FEATURE_REQUEST: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/UPDATE_FEATURE_REQUEST',
    UPDATE_FEATURE_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/UPDATE_FEATURE_SUCCESS',
    UPDATE_FEATURE_FAILURE: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/UPDATE_FEATURE_FAILURE',

    DELETE_FEATURE_REQUEST: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/DELETE_FEATURE_REQUEST',
    DELETE_FEATURE_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/DELETE_FEATURE_SUCCESS',
    DELETE_FEATURE_FAILURE: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/DELETE_FEATURE_FAILURE',

    GET_FEATURE_TREND_REQUEST: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/GET_FEATURE_TREND_REQUEST',
    GET_FEATURE_TREND_SUCCESS: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/GET_FEATURE_TREND_SUCCESS',
    GET_FEATURE_TREND_FAILURE: 'MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES/GET_FEATURE_TREND_FAILURE',

    GET_FORCING_FREQUENCIES_REQUEST: 'GET_FORCING_FREQUENCIES_REQUEST',
    GET_FORCING_FREQUENCIES_SUCCESS: 'GET_FORCING_FREQUENCIES_SUCCESS',
    GET_FORCING_FREQUENCIES_FAILURE: 'GET_FORCING_FREQUENCIES_FAILURE'
  },
  CREATE_MACHINE_NOTE_REQUEST: 'CREATE_MACHINE_NOTE_REQUEST',
  CREATE_MACHINE_NOTE_SUCCESS: 'CREATE_MACHINE_NOTE_SUCCESS',
  CREATE_MACHINE_NOTE_FAILURE: 'CREATE_MACHINE_NOTE_FAILURE',

  UPDATE_MACHINE_NOTE_REQUEST: 'UPDATE_MACHINE_NOTE_REQUEST',
  UPDATE_MACHINE_NOTE_SUCCESS: 'UPDATE_MACHINE_NOTE_SUCCESS',
  UPDATE_MACHINE_NOTE_FAILURE: 'UPDATE_MACHINE_NOTE_FAILURE',

  UPDATE_TAG_REQUEST: 'UPDATE_TAG_REQUEST',
  UPDATE_TAG_SUCCESS: 'UPDATE_TAG_SUCCESS',
  UPDATE_TAG_FAILURE: 'UPDATE_TAG_FAILURE',

  GET_CHARTS_CONFIG_REQUEST: 'GET_CHARTS_CONFIG_REQUEST',
  GET_CHARTS_CONFIG_SUCCESS: 'GET_CHARTS_CONFIG_SUCCESS',
  GET_CHARTS_CONFIG_FAILURE: 'GET_CHARTS_CONFIG_FAILURE',

  POST_CHARTS_CONFIG_REQUEST: 'POST_CHARTS_CONFIG_REQUEST',
  POST_CHARTS_CONFIG_SUCCESS: 'POST_CHARTS_CONFIG_SUCCESS',
  POST_CHARTS_CONFIG_FAILURE: 'POST_CHARTS_CONFIG_FAILURE',

  PUT_CHARTS_CONFIG_REQUEST: 'PUT_CHARTS_CONFIG_REQUEST',
  PUT_CHARTS_CONFIG_SUCCESS: 'PUT_CHARTS_CONFIG_SUCCESS',
  PUT_CHARTS_CONFIG_FAILURE: 'PUT_CHARTS_CONFIG_FAILURE',

  DELETE_CHARTS_CONFIG_REQUEST: 'DELETE_CHARTS_CONFIG_REQUEST',
  DELETE_CHARTS_CONFIG_SUCCESS: 'DELETE_CHARTS_CONFIG_SUCCESS',
  DELETE_CHARTS_CONFIG_FAILURE: 'DELETE_CHARTS_CONFIG_FAILURE',

  GET_CHARTS_CONFIG_METADATA_REQUEST: 'GET_CHARTS_CONFIG_METADATA_REQUEST',
  GET_CHARTS_CONFIG_METADATA_SUCCESS: 'GET_CHARTS_CONFIG_METADATA_SUCCESS',
  GET_CHARTS_CONFIG_METADATA_FAILURE: 'GET_CHARTS_CONFIG_METADATA_FAILURE',

  GET_CHARTS_DATA_REQUEST: 'GET_CHARTS_DATA_REQUEST',
  GET_CHARTS_DATA_SUCCESS: 'GET_CHARTS_DATA_SUCCESS',
  GET_CHARTS_DATA_FAILURE: 'GET_CHARTS_DATA_FAILURE',

  POST_OUTLIER_READING_SUCCESS: 'POST_OUTLIER_READING_SUCCESS',
  POST_OUTLIER_READING_FAILURE: 'POST_OUTLIER_READING_FAILURE',

  DELETE_OUTLIER_READING_SUCCESS: 'DELETE_OUTLIER_READING_SUCCESS',
  DELETE_OUTLIER_READING_FAILURE: 'DELETE_OUTLIER_READING_FAILURE',

  POST_DERIVED_TAG_REQUEST: 'POST_DERIVED_TAG_REQUEST',
  POST_DERIVED_TAG_SUCCESS: 'POST_DERIVED_TAG_SUCCESS',
  POST_DERIVED_TAG_FAILURE: 'POST_DERIVED_TAG_FAILURE'
};
