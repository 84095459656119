import styled from 'styled-components';

const ChartContainer = styled.div`
  max-height: 700px;
  transition: max-height 0.3s ease-out;
  ${props =>
    props.minimizeHeight &&
    'max-height: 0;transition: max-height 0.3s ease-out;visibility: hidden;'};
`;
export default styled(ChartContainer)``;
