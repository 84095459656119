import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import InputField_T from 'common/components/atoms/InputField';
import { timelineLabels } from 'common/utils';
import { Grid, Segment } from 'semantic-ui-react';

const InputField = styled(InputField_T)`
 font-size: 12px;
 ${props => props.paddingtop && `padding-top: ${props.paddingtop};`}
 width: 100%;
 min-width:160px;
 label {
 font-weight: 300;
 }
 padding-bottom: 0px;
 margin-bottom: 0px;
 input{
  ${props => props.fontSettings ? props.fontSettings : props.theme.fonts.lightLabel}}
  .ui.fluid.dropdown {
    ${props => props.fontSettings ? props.fontSettings : props.theme.fonts.lightLabel}
  }
 .ui.selection.active.dropdown .menu > .item {
    ${props => props.optionFontSettings ? props.optionFontSettings : props.theme.fonts.lightLabel}
  }
`;

const WifiSetting = (props) => {
  const {
    ssid,
    passphrase,
    errorMessages,
    wifi_schedule_date,
    wifi_schedule_time,
    handleInputClick,
    model,
  } = props;
  return (
    <>
      <Grid columns={4} doubling stackable>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <InputField
                type="text"
                label="Wi-Fi SSID"
                name="ssid"
                value={ssid}
                error={errorMessages.ssid_error}
                onChange={e => handleInputClick(e, {}, model)}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <InputField
                type="password"
                label="Wi-Fi Password"
                name="passphrase"
                value={passphrase}
                error={errorMessages.passphrase_error}
                onChange={e => handleInputClick(e, {}, model)}
                autoComplete="new-password"
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <InputField
                type="date"
                min={moment().format('YYYY-MM-DD')}
                label="Reconfigure Date"
                name="wifi_schedule_date"
                value={wifi_schedule_date}
                onChange={e => handleInputClick(e, {}, model)}
                medium
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <InputField
                type="select"
                label="Reconfigure Time"
                placeholder="Select Time"
                name="wifi_schedule_time"
                options={wifi_schedule_date === moment().format('YYYY-MM-DD') ?
                  timelineLabels('00:00', 30, 'minutes').filter(label => label.value >= moment().format('HH:mm')) :
                  timelineLabels('00:00', 30, 'minutes')
                }
                value={wifi_schedule_time}
                onChange={(e, data) => handleInputClick(e, data, model)}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

WifiSetting.propTypes = {
  ssid: PropTypes.string,
  passphrase: PropTypes.string,
  errorMessages: PropTypes.object,
  wifi_schedule_date: PropTypes.string,
  wifi_schedule_time: PropTypes.string,
  handleInputClick: PropTypes.func.isRequired,
  model: PropTypes.string,
};

WifiSetting.defaultProps = {
  ssid: '',
  passphrase: '',
  errorMessages: {},
  wifi_schedule_date: '',
  wifi_schedule_time: '',
  model: ''
};

export default WifiSetting;
