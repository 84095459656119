import styled from 'styled-components';


const BreadcrumbContainer = styled.div`
  top: 0;
  left: 0;
  padding-top: 10px;
  padding-bottom: 14px;
  background: #fff;
  border-bottom: 2px solid ${props => props.theme.colors.greyL};
  width: 100%;
  z-index: 3;
  .ui {
    &.breadcrumb {
      padding: 0;
      background: transparent;
      i.right.angle {
        margin: 0 !important;
      }
      .menu.transition.visible {
        z-index: 1000;
      }
      .section {
        color: #999b95;
        font-size: 12px;
        font-weight: 600;
        a {
          color: #999b95;
        }
      }

      a.section {
        color: #999b95;
        font-size: 12px;
        margin-top: 2px;
      }

      .divider {
        font-size: 12px;
      }
    }
  }
`;

export default BreadcrumbContainer;
