import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button_T from 'common/components/atoms/Button';
import Label_T from 'common/typography/Label/Label';
import FlexContainer from 'common/components/atoms/FlexContainer';
import SpeedInputField from '../atoms/SpeedInputField';
import FilterInlineModal from '../atoms/FilterInlineModal';

const Button = styled(Button_T)`
  margin-left: 55px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding: 0.5em !important;
  ${props => props.maxWidth && 'margin-left: 0;'}
`;

const Label = styled(Label_T)`
  ${props => props.header && `
    color: #3C3D3A;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
  `}
  ${props => props.error && `
    color: red;
  `}
`;

const SpeedFilter = (props) => {
  const [filterMin, setFilterMin] = useState(props.filterMin);
  const [filterMax, setFilterMax] = useState(props.filterMax);
  const [filterError, setFilterError] = useState('');
  const buttonRef = useRef(null);

  useEffect(() => {
    if (Number(filterMin) > Number(filterMax) && filterMax !== '') {
      if (!filterError) {
        setFilterError('Min value cannot be greater than Max value');
      }
    } else if (Number(filterMin) < 0) {
      if (!filterError) {
        setFilterError('Min value cannot be lower than 0');
      }
    } else if (filterError) setFilterError('');
  }, [filterMin, filterMax]);

  const clearFilterValues = (e) => {
    e.stopPropagation();
    setFilterMin('');
    setFilterMax('');
  };

  const saveFilterValue = (e) => {
    e.stopPropagation();
    props.onSave(Number(filterMin), Number(filterMax));
  };

  return (
    <FilterInlineModal>
      <FlexContainer direction="column">
        <FlexContainer justifyContent="space-between" alignItems="center">
          <Label header>Highlight by Speed</Label>
          <Button color="#999B95" text onClick={clearFilterValues}>CLEAR</Button>
        </FlexContainer>
        <FlexContainer>
          <SpeedInputField
            label="Min"
            type="number"
            value={filterMin}
            prefix={props.units}
            onChange={e => setFilterMin(e.target.value)}
          />
          <SpeedInputField
            marginLeft="10px"
            type="number"
            label="Max"
            prefix={props.units}
            value={filterMax}
            onChange={e => setFilterMax(e.target.value)}
          />
        </FlexContainer>
        <Button
          innerRef={buttonRef}
          maxWidth
          disabled={!!((!filterMin || !filterMax) || filterError)}
          onClick={saveFilterValue}
        >
          Save
        </Button>
        {filterError && (
          <Label error>{filterError}</Label>
        )}
      </FlexContainer>
    </FilterInlineModal>
  );
};

SpeedInputField.propTypes = {
  filterMin: PropTypes.string,
  filterMax: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  units: PropTypes.string
};

SpeedInputField.defaultProps = {
  filterMin: '',
  filterMax: '',
  units: 'Hz'
};

export default styled(SpeedFilter)``;
