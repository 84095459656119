import React from 'react';
import PropTypes from 'prop-types';

const MachineSvg = ({ width, height, fill }) => (
<svg width={width} height={height} viewBox="0 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g id="Web---Single" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="1.0.0-Single-Machine_condition" transform="translate(-459.000000, -586.000000)">
      <g transform="translate(413.000000, 199.000000)" id="event">
        <g transform="translate(40.000000, 381.000000)">
          <g id="critical-icon">
            <rect id="icon-bg" x={0} y={0} width={20} height={20} />
            <circle id="oval" fill={fill} fillRule="nonzero" cx={10} cy={10} r={4} />
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

);

MachineSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

MachineSvg.defaultProps = {
  width: 8,
  height: 8,
  fill: '#FF4F39'
};

export default MachineSvg;
