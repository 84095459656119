import styled from 'styled-components';
import FlexContainer from 'common/components/atoms/FlexContainer';

const AssetContainer = styled(FlexContainer).attrs({
  direction: 'column',
  alignItems: 'center',
})`
  border: 1px solid #D8DCD3;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px solid black;
  }
`;
export default styled(AssetContainer)``;
