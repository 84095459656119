import theme from 'common/styles/theme';

export const accountAnalyticsConstants = {
  GET_ACCOUNT_ANALYTICS_REQUEST: 'GET_ACCOUNT_ANALYTICS_REQUEST',
  GET_ACCOUNT_ANALYTICS_SUCCESS: 'GET_ACCOUNT_ANALYTICS_SUCCESS',
  GET_ACCOUNT_ANALYTICS_FAILURE: 'GET_ACCOUNT_ANALYTICS_FAILURE',
  GET_ACCOUNT_ANALYTICS_SUMMARY_REQUEST: 'GET_ACCOUNT_ANALYTICS_SUMMARY_REQUEST',
  GET_ACCOUNT_ANALYTICS_SUMMARY_SUCCESS: 'GET_ACCOUNT_ANALYTICS_SUMMARY_SUCCESS',
  GET_ACCOUNT_ANALYTICS_SUMMARY_FAILURE: 'GET_ACCOUNT_ANALYTICS_SUMMARY_FAILURE',
};

export const accountAnalyticsMetadata = {
  measurements: {
    sortOrder: [
      'vibration',
      'pressure',
      'temperature',
      'current',
      'ultrasound',
      'utilization'
    ],
    colors: [theme.colors.green, theme.colors.yellow, theme.colors.red, theme.colors.pink, theme.colors.blue, theme.colors.greenXD],
    route: '/devices',
    title: 'Measurements',
    id: 'account-analytics-measurements',
    widgetType: 'DONUT_CHART'
  },
  channels: {
    sortOrder: [
      'Active Mote',
      'Active Tx',
      'Available Mote',
      'Available Tx'
    ],
    colors: [theme.colors.green, theme.colors.yellow, theme.colors.red, theme.colors.blue],
    route: '/devices',
    title: 'Channels',
    id: 'account-analytics-channels',
    widgetType: 'DONUT_CHART'
  },
  tags: {
    sortOrder: [
      'Active',
      'Inactive'
    ],
    colors: [theme.colors.green, theme.colors.yellow],
    route: '/devices',
    title: 'Tags',
    id: 'account-analytics-tags',
    widgetType: 'DONUT_CHART'
  }
};
