export const userRoles = {
  PetasenseAdmin: 'petasense_admin',
  PetasenseViewer: 'petasense_viewer',
  Analyst: 'analyst',
  Admin: 'admin',
  Technician: 'technician',
  Manager: 'manager',
  PartnerAdmin: 'partner_admin'
};

export const operations = {
  Create: 'create',
  Read: 'read',
  Update: 'update',
  Delete: 'delete'
};

export const mapComponentToResource = {
  Accounts: 'accounts',
  AccountUsers: 'users',
  Activity: 'activity',
  Areas: 'areas',
  BearingDetails: 'bearing_models',
  BhsItem: 'charts',
  ChartItem: 'charts',
  ChartMultilineItem: 'charts',
  ComponentDetails: 'components',
  EventDefinition: 'event_definition',
  MachineCharts: 'charts',
  MachineDetails: 'machines',
  Sensors: 'sensors',
  SiteProfile: 'accounts',
  TermsPage: 'accounts',
  Machines: 'machines',
  HierarchyView: 'accounts',
  DiagnosticRules: 'diagnostic_rules',
  DiagnosticResults: 'diagnostic_results',
  Tags: 'tags',
  DiagnosticTestRun: 'diagnostic_rules_test_run',
  ThirdPartyIntegrations: 'third_party_integration',
  TagTemplates: 'tag_templates',
  TaskTemplates: 'task_templates',
  FirmwareDeployments: 'firmware_deployment',
  Attachments: 'attachments',
  Dashboard: 'dashboard',
  AccountDashboard: 'account_dashboard',
  RUL: 'rul',
  Notes: 'notes',
  Tasks: 'tasks',
  Repairs: 'repairs',
  FeatureNormalization: 'feature_normalization',
  InstrumentationAlerts: 'instrumentation_alerts'
};
