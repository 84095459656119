import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSvg = styled.svg`
    cursor: pointer;
`;

const CompareIcon = ({ height, width, fill }) => (
  <StyledSvg width={width} height={height} viewBox="0 0 18 18" version="1.1">
      <g id="Site" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="3-0-1-Site-Compare_tags" transform="translate(-189.000000, -356.000000)">
              <g id="more_dd" transform="translate(169.000000, 193.000000)">
                  <g id="compare-icon" transform="translate(20.000000, 164.000000)">
                      <rect id="bounds" x="0" y="0" width="16" height="16" />
                      <g id="icon" transform="translate(1.000000, 0.000000)" stroke="#737473" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.6">
                          <line x1="15.5" y1="0.498666667" x2="0.5" y2="15.4986667" id="Path" />
                          <polyline id="Path" points="15.5 4.99866667 15.5 0.498666667 11 0.498666667" />
                          <line x1="0.5" y1="0.498666667" x2="6" y2="5.99866667" id="Path" />
                          <polyline id="Path" points="15.5 10.9986667 15.5 15.4986667 11 15.4986667" />
                          <line x1="10" y1="9.99866667" x2="15.5" y2="15.4986667" id="Path" />
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </StyledSvg>
);

CompareIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

CompareIcon.defaultProps = {
  width: 14,
  height: 14,
  fill: '#999B95'
};

export default CompareIcon;
