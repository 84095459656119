import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ChevronContainer from 'home/Sensors/components/atoms/ChevronContainer';
import { ChevronRight, ChevronLeft } from 'common/images/FaIcons';
import { FlexContainer, ScrollContainer } from 'common/components/atoms';

const ChartsContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  position: relative;
  border-left: solid 1px;
  border-color: #bdbdbd;
`;

const ChartsHideContainer = styled.div`
  display: ${props => props.hide ? 'none' : null};
`;

const CollapsibleWidgetsContainer = (props) => {
  const [chartsView, setChartsView] = useState(true);

  useEffect(() => {
    const chartsView = JSON.parse(localStorage.getItem(`set${props.componentName}ViewPaneInLocal`));
    setChartsView(chartsView);
  }, []);

  const setViewPaneInLocal = (val) => {
    localStorage.setItem(`set${props.componentName}ViewPaneInLocal`, JSON.stringify(val));
  };

  const onChevronClick = () => {
    setViewPaneInLocal(!chartsView);
    setChartsView(prevState => !prevState);
  };

  return (
    <ChartsContainer>
      {chartsView && (
        <ChevronContainer left onClick={onChevronClick}>
          <FlexContainer height="inherit" justifyContent="center" alignItems="center">
            <ChevronRight size="sm" />
          </FlexContainer>
        </ChevronContainer>
      )}
      {!chartsView && (
        <ChevronContainer right onClick={onChevronClick}>
          <FlexContainer height="inherit" justifyContent="center" alignItems="center">
            <ChevronLeft size="sm" />
          </FlexContainer>
        </ChevronContainer>
      )}
      <ChartsHideContainer hide={!chartsView}>
        <ScrollContainer key={chartsView} innerRef={React.createRef()} height="Calc(100vh - 180px)">
          {props.children}
        </ScrollContainer>
      </ChartsHideContainer>
    </ChartsContainer>
  );
};

export default CollapsibleWidgetsContainer;
