export const MODAL_TITLES = {
  ENABLE: 'Enable Simultaneous Measurement',
  DISABLE: 'Disable Simultaneous Measurement'
};

export const MODAL_SUBTITLES = {
  SIMULTANEOUS_MEASUREMENT: 'Simultaneous Measurement'
};

export const SAMPLING_LIMIT = 20000;

export const MODAL_MESSAGES = {
  ENABLE_DESCRIPTION: 'This setting will allow all sensors on this port to measure simultaneously.',
  SAMPLING_LIMIT_NOTE: `In Simultaneous Measurement mode, only sampling options with ${SAMPLING_LIMIT} samples or fewer are available.`
};

export const TOOLTIP_MESSAGES = {
  REQUIRES_MULTIPLE_OR_TRIAXIAL: 'Simultaneous measurement requires either multiple sensors or a triaxial sensor',
  REQUIRES_SAME_MODEL: 'All sensors must be of the same model for simultaneous measurement',
  ENABLE: 'Enable simultaneous measurement for this port',
  DISABLE: 'Disable simultaneous measurement',
  STATUS_ENABLED: 'Simultaneous measurement is enabled',
  STATUS_DISABLED: 'Simultaneous measurement is disabled',
  REQUIRES_ADMIN: '(Requires admin privileges to modify)'
};

export const BUTTON_LABELS = {
  ASSIGN_SENSOR: 'Assign Sensor',
  UNASSIGN: 'Unassign',
  CANCEL: 'Cancel',
  APPLY: 'Apply'
};

export const SECTION_TITLES = {
  PORTS: 'Ports',
  UPDATE_SAMPLING_SETTINGS: 'Update Sampling Settings'
};

export const SAMPLING_LABELS = {
  FULL_BANDWIDTH: 'Full Bandwidth',
  HIGH_RESOLUTION: 'High Resolution'
};

export const DROPDOWN_PLACEHOLDERS = {
  SELECT_RESOLUTION: 'Select resolution'
};

export const MACHINE_LABELS = {
  ON: 'on',
  NOT_ASSOCIATED: '(not associated to any machine)'
};

export const CHANNEL_LABEL = 'Channel';

export const PORT_LABEL = 'Port';

export const NOTE_LABEL = 'Note';

export const MODAL_DIMENSIONS = {
  WIDTH: '500px',
  OUTER_PADDING: '0',
  HEADER_PADDING: '1.5rem',
  PADDING: '1.5rem'
};

export const FMAX_LOR_CONVERSION_FACTOR = 2.56;
