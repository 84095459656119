import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as _ from 'lodash';

import CrossSvg from 'common/images/CrossSvg';
import { Search } from 'common/images/FaIcons';
import FlexContainer from '../atoms/FlexContainer';
import { InputField } from '../atoms';

const SelectInput = styled.div.attrs({
  tabIndex: '1'
})`
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px
  padding-left: 16px;
  box-sizing: border-box;
  background-color: ${props =>
    props.pointerEvents ? 'hsl(90, 6%, 94%)' : 'white'};
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  ${props =>
    props.heightLimit
      ? `
    padding: 10px;
  `
      : ''}
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
  `}
  &::after {
    ${props => (props.clearable || props.disabled ? '' : 'content: "▼";')}
    right: 13px;
    position: relative;
    font-size: 9px;
    font-stretch: semi-condensed;
    transform: scaleX(1.3) scaleY(0.7);
  }
`;

const DropdownSide = styled(FlexContainer).attrs({
  direction: 'column'
})`
  position: absolute;
  left: 99.9%;
  min-width: 200px;
  top: 0;
  z-index: 5;
  border: none;
  box-shadow: 0 0 8px 0 rgba(60, 61, 58, 0.24);
  max-height: 350px;
  overflow-y: scroll;
`;

const SelectHeader = styled.span`
  padding: 5px 8px 0 16px;
  background-color: white;
  color: ${props => props.theme.colors.greyXD};
  font-weight: 300;
  text-transform: capitalize;
`;

const SelectOption = styled(FlexContainer).attrs({
  alignItems: 'center'
})`
  position: relative;
  height: 38px;
  padding: 10px;
  padding-left: 16px;
  box-sizing: border-box;
  background-color: white;
  color: ${props => props.color || props.theme.colors.greyXXD};
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  ${props =>
    props.dropside &&
    `
  justify-content: space-between;
    &::after {
      content: "›";
      right: 13px;
      position: relative;
      font-size: 19px;
    }
  `}
  ${props =>
    props.type === 'bullet'
      ? `
    &::before {
      content: "";
      height: 8px;
      width: 8px;
      border-radius: 4px;
      background: ${props.color || props.theme.colors.greyXXD};
      right: 13px;
      position: relative;
      font-size: 19px;
      margin-left: 16px;
    }
  `
      : ''}
  ${DropdownSide} {
    display: none;
  }
  &:hover {
    background-color: #EFF0EE;
    ${DropdownSide} {
      display: block;
    }
  }
`;

const DropdownContainer = styled(FlexContainer).attrs({
  direction: 'column',
  tabIndex: '2'
})`
  ${props => props.padding && `padding: ${props.padding};`}
  outline: none;
  border-radius: 4px;
  border: 1px solid #d8dcd3;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e5e8e1;
  }
  ${props =>
    props.heightLimit
      ? `
    overflow: auto;
    max-height: 350px;
  `
      : ''}
  ${props => props.width && `width: ${props.width};`};
  ${props => props.minwidth && `min-width: ${props.minwidth};`};
  ${props =>
    props.open &&
    `
    position: absolute;
    left: 0px;
    right: 0%;
    z-index: 4;
    border: none;
    box-shadow: 0 0 8px 0 rgba(60,61,58,0.24);
  `}
`;

const RelativeContainer = styled.div`
  position: relative;
  min-height: 44px;
  ${props => props.minHeight && `min-height: ${props.minHeight};`};
  box-sizing: border-box;
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom : '1.5em'};
  ${props => (props.label ? 'padding-top: 9px;' : '')}
`;
const OptionDisplay = styled.div`
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  color: ${props =>
    props.placeholder === 'true'
      ? props.theme.colors.greyXD
      : 'rgba(0,0,0,0.87)'};
  ${props =>
    props.type === 'bullet'
      ? `
    ${props.color ? `color: ${props.color}` : 'initial'};
    &::before {
      content: "";
      display: inline-block;
      height: 8px;
      width: 8px;
      border-radius: 4px;
      bottom: 1.5px;
      background: ${props.color || props.theme.colors.greyXXD};
      right: 13px;
      position: relative;
      font-size: 19px;
      margin-left: 16px;
    }
  `
      : ''}
`;

const SpanLabel = styled.span`
  font-weight: 300;
  width: 100%;
  margin-bottom: 9px;
  font-size: 12px;
  position: relative;
  top: -9px;
`;

const CrossSvgContainer = styled.div`
  margin-left: auto;
  margin-right: 2.5%;
  padding-top: 1.5%;
  z-index: 1;
`;

class DropdownSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchkey: ''
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }

  selectOption(option, parentOption) {
    this.setState({ open: false }, () => {
      this.props.onSelect(parentOption, { value: option });
    });
  }

  // remove this in future
  optionDetails = (text, value, parentText) => {
    this.setState({ open: false }, () => {
      if (this.props.onClickItem) this.props.onClickItem(text, value, parentText);
    });
  };

  handleBlur(e) {
    e.persist();
    if (!e.relatedTarget || e.target.className === e.relatedTarget.className) this.setState({ open: false });
  }

  handleSearchChange = (e) => {
    this.setState({ searchkey: e.target.value });
  };

  handleClearInput = () => {
    this.setState({ searchkey: '' });
  };

  render() {
    const {
      placeholder,
      value,
      data,
      label,
      heightLimit,
      type,
      disabled,
      clearable,
      fontSize,
      minHeight,
      height,
      searchIcon,
      ...rest
    } = this.props;

    return (
      <RelativeContainer {...rest} label={label} minHeight={minHeight}>
        {label && <SpanLabel className="span-label">{label}</SpanLabel>}
        <DropdownContainer
          heightLimit={heightLimit}
          open={this.state.open}
          onBlur={this.handleBlur}
          {...rest}
        >
          <SelectInput
            disabled={disabled}
            heightLimit={heightLimit}
            onClick={() => !disabled && this.handleClick()}
            clearable={clearable && value}
            {...rest}
          >
            <OptionDisplay
              fontSize={fontSize}
              placeholder={(!value).toString()}
              type={value ? type : undefined}
              color={
                type === 'bullet' && value
                  ? data.filter(
                    datum => datum.text.toLowerCase() === value.toLowerCase()
                  )[0].color
                  : undefined
              }
            >
              {value || placeholder}
            </OptionDisplay>
            {clearable && value && (
              <CrossSvgContainer>
                <CrossSvg
                  onClick={(e) => {
                    e.stopPropagation();
                    this.optionDetails(null, null, null);
                  }}
                  strokeWidth="thin"
                  width={18}
                  height={18}
                />
              </CrossSvgContainer>
            )}
          </SelectInput>
          {this.state.open && (
            <>
              {data.map((datum, idx) => (
                <>
                  {(idx === 0 || data[idx - 1].header !== datum.header) && (
                    <SelectHeader>{datum.header}</SelectHeader>
                  )}
                  <SelectOption
                    key={`selectoption-${idx}`}
                    dropside={datum.children && !_.isEmpty(datum.children)}
                    onClick={
                      datum.children
                        ? null
                        : () => {
                          this.selectOption(datum.text || datum.name, null);
                          this.optionDetails(
                            datum.text || datum.name,
                            datum.value,
                            null
                          );
                        }
                    }
                    color={datum.color || undefined}
                    type={datum.type || undefined}
                  >
                    {datum.text || datum.name}
                    <DropdownSide>
                      {searchIcon && (
                        <InputField
                          type="text"
                          name="component-type-search-box"
                          prefix={<Search />}
                          prefixSide="left"
                          onChange={this.handleSearchChange}
                          value={this.state.searchkey}
                          placeholder="Search..."
                          searchBox
                          clearable
                          clearInput={this.handleClearInput}
                          fontWeight="300"
                        />
                      )}
                      {datum.children &&
                        datum.children
                          .filter(
                            child =>
                              !this.state.searchkey ||
                              child.text
                                .toLowerCase()
                                .includes(this.state.searchkey.toLowerCase())
                          )
                          .map((child, idx) => (
                            <SelectOption
                              key={`child-${idx}`}
                              onClick={() => {
                                this.selectOption(
                                  child.text || child.name,
                                  datum.text || datum.name
                                );
                                this.optionDetails(
                                  child.text || child.name,
                                  child.value,
                                  datum.text || datum.name
                                );
                              }}
                            >
                              {child.text}
                            </SelectOption>
                          ))}
                    </DropdownSide>
                  </SelectOption>
                </>
              ))}
            </>
          )}
        </DropdownContainer>
      </RelativeContainer>
    );
  }
}

DropdownSelect.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onClickItem: PropTypes.func,
  disabled: PropTypes.bool
};

DropdownSelect.defaultProps = {
  label: undefined,
  value: undefined,
  type: undefined,
  placeholder: 'Select',
  onSelect: () => { },
  onClickItem: () => { },
  disabled: false
};

export default styled(DropdownSelect)``;
