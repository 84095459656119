import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import update from 'immutability-helper';
import * as _ from 'lodash';
import moment from 'moment';

import { toastr } from 'react-redux-toastr';
import RBAC from 'common/rbac/RBAC';
import { ResourceInputField, ResourceItemLabel, ResourceItem } from 'common/components/atoms/ResourceDetails';
import { Grid, Segment } from 'semantic-ui-react';
import Label_T from 'common/typography/Label/Label';
import H2_T from 'common/typography/H2/H2';
import * as sensorsActions from '../../actions/sensors.actions';
import LoadingSvg from '../../../../common/images/LoadingSvg';
import FlexContainer from '../../../../common/components/atoms/FlexContainer';
import CrossSvg from '../../../../common/images/CrossSvg';
import Button_T from '../../../../common/components/atoms/Button';
import ExpandableContainer from '../../../../common/components/molecules/ExpandableContainer';
import { ExclamationTriangle, Info } from '../../../../common/images/FaIcons';
import MeasurementScheduleModal from '../MeasurementScheduleModal';
import * as sensorUtils from '../../utils/sensorUtils';
import {
  AGGRESSIVE_INTERVAL_THRESHOLD,
  StandardSensors,
  VectorSensors,
  ErrorMessages,
  vibrationMeasurementTypeEnum,
  oldTxMeasurementVersion
} from '../../constants/sensors.constants';
import WifiSetting from '../WifiSettings';
import TxPorts from '../TxPorts';
import PreferredAPSettings from '../PreferredAPSettings';

const Container = styled(FlexContainer).attrs({ direction: 'column' })`
  padding: 2em;
  min-height: Calc(100vh - 138px);
`;

const SvgContainer = styled.span`
  cursor: pointer;
  margin-left: auto;
`;

const ResourceHeaderSection = styled(H2_T)`
  width: 100%;
  font-size: 18px;
  padding-bottom: 18px;
`;

const Button = styled(Button_T)`
  width: max-content;
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
`;

const SetButton = styled(Button_T)`
  width: max-content;
  padding-left: 165px;
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
`;

const H2 = styled(H2_T)`
  padding: 0px;
`;

const LoadingContainer = styled.div`
  align-self: center;
  margin: auto;
`;

const ButtonsContainer = styled(FlexContainer)`
  padding-top: 2em;
  button:first-child {
    margin-right: 15px;
  }
`;

const AbsoluteLoading = styled.div`
  background: #e9e9e9;
  position: absolute;
  align-self: center;
  margin: auto;
  opacity: 0.5;
  width: 100%;
  height: calc(100vh - 138px);
  overflow: none;
  svg {
    transform: translateX(35vw) translateY(50vh) translateY(-69px);
  }
`;

const TextLabel = styled(Label_T)`
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 8px;
  margin-top: -2px;
`;

const Labelcontainer = styled(FlexContainer)`
  padding-top: 2em;
  height: max-content;
  &:after {
    content:"";
    display: inline-block;
    height: 0.5em;
    align-self: center;
    width: Calc(100% - 270px);
    margin-right: -880px;
    margin-left: 10px;
    border-top: 1px solid ${props => props.theme.colors.greyD};
    margin-top: 0.5em;
  }
`;

const HeaderContainer = styled(FlexContainer).attrs({
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  padding-bottom: 1em;
`;

const CalculateOtherSensorGroupedName = arr => arr.reduce((acc, cur, idx) => {
  if (acc.includes(cur.type)) return acc;
  if (idx === 0) return `${cur.type} - ${cur.model}`;
  if (idx === 2) return `${acc} ...`;
  if (idx > 2) return acc;
  return `${acc} & ${cur.type} - ${cur.model}`;
}, '');

class GroupedSensors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sensorList: props.sensorList || [],
      details: {},
      fieldValues: {},
      initialFieldValues: {},
      error: false,
      editMode: props.editMode,
      saveEnabled: false,
      expandedContainers: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const sensorSerialNoList = this.props.sensorList.map(s => s.serial_number);
    const filteredDetails = {};
    Object.keys(this.props.details).forEach((model) => {
      filteredDetails[model] = this.props.details[model].filter(s => sensorSerialNoList.includes(s.serial_number));
      if (filteredDetails[model].length === 0) delete filteredDetails[model];
    });
    const newDetails = this.splitDetailsAsPerTXConfig(filteredDetails);
    if (!_.isEqual(this.state.details, newDetails)) {
      this.setState({
        details: newDetails
      }, this.calculateGroupedModelFieldValues);
    }
    if (!_.isEqual(this.state.sensorList, this.props.sensorList)) {
      this.setState({
        sensorList: this.props.sensorList
      });
    }
    if (prevState.editMode !== this.props.editMode) {
      this.setState({
        editMode: this.props.editMode
      });
    }
    if (_.isEqual(this.state.fieldValues, this.state.initialFieldValues)) {
      if (this.state.saveEnabled !== false) {
        this.setState({
          saveEnabled: false
        });
      }
    } else if (this.state.saveEnabled !== true) this.setState({ saveEnabled: true });
  }

  splitDetailsAsPerTXConfig = (details) => {
    const newDetails = {};
    Object.keys(details).forEach((model) => {
      newDetails[model] = details[model].filter(s => s.transmitter_version !== '0804');
      newDetails[`${model} - 0804`] = details[model].filter(s => s.transmitter_version === '0804');
      if (newDetails[model].length === 0) delete newDetails[model];
      if (newDetails[`${model} - 0804`].length === 0) delete newDetails[`${model} - 0804`];
    });
    return newDetails;
  };

  unassignSensorFromTx = (sensorSerialNumber, txSerialNumber) => {
    this.props.sensorsActions.unassignSensorFromTx({
      serial_numbers: [sensorSerialNumber],
      tx_serial_no: txSerialNumber
    }).then(
      () => {
        this.state.details.TX.forEach((tx) => {
          if (tx.serial_number === txSerialNumber) return;
          let txUpdated = false;
          tx.ports.forEach((port) => {
            if (_.some(port.sensors, sensor => sensor.serial_number === sensorSerialNumber)) txUpdated = true;
          });
          if (txUpdated) this.props.sensorsActions.getSensorDetails(tx.serial_number);
        });
        this.setState(prevState => ({ expandedContainers: { ...prevState.expandedContainers, TX: true } }));
      }
    );
  }

  calculateGroupedModelFieldValues = () => {
    const { details } = this.state;
    const fieldValues = Object.entries(details).reduce((acc, cur) => {
      if ([...StandardSensors, ...VectorSensors].includes(cur[1][0].type)) {
        acc[cur[0]] = this.calculateSameFieldForType(cur[1], cur[1][0].model, cur[1][0].type);
      } else acc[cur[0]] = this.calculateSameFieldForType(cur[1], 'other', 'other');
      return acc;
    }, {});
    this.setState({ fieldValues, initialFieldValues: fieldValues });
  };

  calculateSameFieldForType = (arr, model, type) => {
    const { metadata } = this.props;
    if (!metadata[model]) {
      this.setState({
        error: `No metadata for given model! ${model}`
      });
      return null;
    }
    if (type === 'Vibration Mote') {
      let overall = {};
      let full_bandwidth = {};
      let high_res = {};
      let measurement_settings_type = '';
      let reporting_interval = '';
      const serial_number = [];
      arr.forEach((data, idx) => {
        if (idx === 0) {
          overall = _.cloneDeep(data.overall);
          full_bandwidth = _.cloneDeep(data.full_bandwidth);
          high_res = _.cloneDeep(data.high_res);
          measurement_settings_type = data.overall.type;
          reporting_interval = _.cloneDeep(data.reporting_interval);
        } else {
          if (overall.sampling && !_.isEqual(overall.sampling, data.overall.sampling)) {
            overall.sampling = '';
          }
          if (full_bandwidth.sampling && !_.isEqual(full_bandwidth.sampling, data.full_bandwidth.sampling)) {
            full_bandwidth.sampling = '';
          }
          if (high_res.sampling && !_.isEqual(high_res.sampling, data.high_res.sampling)) {
            high_res.sampling = '';
          }
          if (reporting_interval && !_.isEqual(reporting_interval, data.reporting_interval)) {
            reporting_interval = '';
          }
          if (!_.isEqual(overall.schedule, data.overall.schedule)) {
            overall.schedule = [];
            overall.stops = [];
          }
          if (!_.isEqual(full_bandwidth.schedule, data.full_bandwidth.schedule)) {
            full_bandwidth.schedule = [];
            full_bandwidth.stops = [];
          }
          if (!_.isEqual(high_res.schedule, data.high_res.schedule)) {
            high_res.schedule = [];
            high_res.stops = [];
          }
          if (measurement_settings_type && !_.isEqual(overall.type, data.overall.type)) {
            measurement_settings_type = '';
            overall.schedule = [];
            overall.stops = [];
            full_bandwidth.schedule = [];
            full_bandwidth.stops = [];
            high_res.schedule = [];
            high_res.stops = [];
          }
        }
        serial_number.push(data.serial_number);
      });
      overall.type = measurement_settings_type;
      full_bandwidth.type = measurement_settings_type;
      high_res.type = measurement_settings_type;
      return {
        overall,
        full_bandwidth,
        high_res,
        reporting_interval,
        serial_number,
        type,
        model,
        scheduled_saved: false,
        wifi_schedule_time: '00:00',
        wifi_schedule_date: moment().add(1, 'days').format('YYYY-MM-DD'),
      };
    }
    if (VectorSensors.includes(type)) {
      let measurement_type = '';
      let measurement_interval = '';
      let full_bandwidth = {};
      let high_res = {};
      const serial_number = [];
      arr.forEach((data, idx) => {
        if (idx === 0) {
          measurement_type = data.measurement_type;
          measurement_interval = data.measurement_interval;
          full_bandwidth = data.full_bandwidth;
          high_res = data.high_res;
        } else {
          if (!_.isEqual(full_bandwidth.sampling.resolution, data.full_bandwidth.sampling.resolution)) {
            full_bandwidth.sampling.resolution = {};
          }
          if (!_.isEqual(high_res.sampling.resolution, data.high_res.sampling.resolution)) {
            high_res.sampling.resolution = {};
          }
          if (!_.isEqual(measurement_type, data.measurement_type)) {
            measurement_type = '';
          }
          if (!_.isEqual(full_bandwidth.high_pass_filter_cutoff, data.full_bandwidth.high_pass_filter_cutoff)) {
            full_bandwidth.high_pass_filter_cutoff = '';
          }
          if (!_.isEqual(high_res.high_pass_filter_cutoff, data.high_res.high_pass_filter_cutoff)) {
            high_res.high_pass_filter_cutoff = '';
          }
          if (!_.isEqual(full_bandwidth.measurement.schedule, data.full_bandwidth.measurement.schedule)) {
            full_bandwidth.measurement.schedule = [];
          }
          if (!_.isEqual(high_res.measurement.schedule, data.high_res.measurement.schedule)) {
            high_res.measurement.schedule = [];
          }
          if (!_.isEqual(full_bandwidth.measurement.stops, data.full_bandwidth.measurement.stops)) {
            full_bandwidth.measurement.stops = [];
          }
          if (!_.isEqual(high_res.measurement.stops, data.high_res.measurement.stops)) {
            high_res.measurement.stops = [];
          }
          if (!_.isEqual(measurement_interval, data.measurement_interval)) {
            measurement_interval = '';
          }
        }
        serial_number.push(data.serial_number);
      });
      return {
        measurement_type,
        measurement_interval,
        full_bandwidth,
        high_res,
        serial_number,
        model,
        transmitter_version: arr[0].transmitter_version,
        scheduled_saved: false,
        type
      };
    }
    if (type === 'Transmitter') {
      let reporting_interval = '';
      const serial_number = [];
      arr.forEach((data, idx) => {
        if (idx === 0) {
          reporting_interval = data.reporting_interval;
        } else if (!_.isEqual(reporting_interval, data.reporting_interval)) {
          reporting_interval = '';
        }
        serial_number.push(data.serial_number);
      });
      return {
        reporting_interval,
        serial_number,
        model,
        type,
        wifi_schedule_time: '00:00',
        wifi_schedule_date: moment().add(1, 'days').format('YYYY-MM-DD'),
      };
    }
    if (type === 'other') {
      let overall_measurement_type = '';
      let overall_measurement_interval = '';
      let overall_measurement_schedule = [];
      let overall_measurement_stops = [];
      const serial_number = [];
      arr.forEach((data, idx) => {
        if (idx === 0) {
          overall_measurement_type = data.overall_measurement_type;
          overall_measurement_interval = data.overall_measurement_interval;
          overall_measurement_schedule = data.overall_measurement_schedule;
          overall_measurement_stops = data.overall_measurement_stops;
        } else {
          if (!_.isEqual(overall_measurement_type, data.overall_measurement_type)) {
            overall_measurement_type = '';
          }
          if (!_.isEqual(overall_measurement_schedule, data.overall_measurement_schedule)) {
            overall_measurement_schedule = [];
          }
          if (!_.isEqual(overall_measurement_stops, data.overall_measurement_stops)) {
            overall_measurement_stops = [];
          }
          if (!_.isEqual(overall_measurement_interval, data.overall_measurement_interval)) {
            overall_measurement_interval = '';
          }
        }
        serial_number.push(data.serial_number);
      });
      return {
        overall_measurement_schedule,
        overall_measurement_stops,
        overall_measurement_type,
        overall_measurement_interval,
        serial_number,
        model,
        transmitter_version: arr[0].transmitter_version,
        scheduled_saved: false,
        type
      };
    }
  };

  isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleInputClick = (e, data, model) => {
    const targetValue = e.target.value || data.value || '';
    const targetName = e.target.name || data.name;

    this.onChangeField(e, { value: targetValue }, targetName, model);
  };

  onChangeField = (e, { value }, name, model) => {
    const { fieldValues } = this.state;
    let newDetails = _.cloneDeep(fieldValues[model]);
    const [newName, option] = name.split('.');

    if (name === 'spectrum_sampling_1' || name === 'spectrum_sampling_0') {
      newDetails[name] = {
        ...newDetails[name],
        resolution: this.isJson(value) ? JSON.parse(value) : value
      };
    } else if (name === 'spectrum_measurement_type' || name === 'measurement_settings_type') {
      newDetails = {
        ...newDetails,
        overall: { ...newDetails.overall, type: value },
        full_bandwidth: { ...newDetails.full_bandwidth, type: value },
        high_res: { ...newDetails.high_res, type: value },
      };
    } else if (option === 'sampling' && !_.startsWith(model, 'VM')) {
      newDetails[newName] = {
        ...newDetails[newName],
        sampling: {
          ...newDetails[newName].sampling,
          resolution: this.isJson(value) ? JSON.parse(value) : value
        }
      };
    } else if (option) {
      newDetails[newName] = {
        ...newDetails[newName],
        [option]: this.isJson(value) ? JSON.parse(value) : value
      };
    } else {
      newDetails[name] = this.isJson(value) ? JSON.parse(value) : value;
    }

    this.setState(prevState => ({
      fieldValues: {
        ...prevState.fieldValues,
        [model]: newDetails
      }
    }));
  };

  makeResponseForType = (data) => {
    let resData = {};
    if (data.type === 'Vibration Mote') {
      resData = {
        aggressive_measurement_timeout: data.aggressive_measurement_timeout,
        measurement_settings: {
          0: data.overall,
          1: data.full_bandwidth,
          2: data.high_res
        },
        serial_number: data.serial_number,
        type: data.type,
        ...(data.reporting_interval && { reporting: { interval: data.reporting_interval } })
      };

      if (data.ssid && data.passphrase) {
        resData.wifi_config = {
          ssid: data.ssid,
          passphrase: data.passphrase,
          start_time: sensorUtils.getEpochFromDateTime(data.wifi_schedule_date, data.wifi_schedule_time),
        };
      }

      if (data.bssid && data.channel) {
        resData.wifi_ap_config = {
          bssid: data.bssid,
          channel: data.channel,
        };
      }

      if (!data.scheduled_saved) {
        delete data.overall.schedule;
        delete data.overall.stops;
        delete data.full_bandwidth.schedule;
        delete data.full_bandwidth.stops;
        delete data.high_res.schedule;
        delete data.high_res.stops;
      }
    }

    if (VectorSensors.includes(data.type)) {
      const highResEnabled = !_.includes(oldTxMeasurementVersion, this.props.currentAccount.preferences.tx_measurement_version) &&
        (data.type === 'Vibration Sensor' || data.type === 'VSX');
      resData = {
        aggressive_measurement_timeout: data.aggressive_measurement_timeout,
        spectrum_measurements: {
          1: {},
          2: highResEnabled ? {} : null
        },
        serial_number: data.serial_number,
        type: data.type
      };

      if (data.measurement_type) {
        if (data.scheduled_saved) {
          resData.spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth].measurement = {
            type: data.measurement_type,
            schedule: data.full_bandwidth.measurement.schedule,
            stops: data.full_bandwidth.measurement.stops
          };
        } else {
          resData.spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth].measurement = {
            type: data.measurement_type
          };
        }
      }

      if (!_.isEmpty(data.full_bandwidth.sampling.resolution)) {
        resData.spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth].sampling = {
          resolution: data.full_bandwidth.sampling.resolution
        };
      }

      if (data.full_bandwidth.high_pass_filter_cutoff) {
        resData.spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth].high_pass_filter_cutoff = data.full_bandwidth.high_pass_filter_cutoff;
      }

      if (data.measurement_interval) {
        resData.spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth].measurement_interval = data.measurement_interval;
      }

      if (highResEnabled) {
        if (data.measurement_type) {
          if (data.scheduled_saved) {
            resData.spectrum_measurements[vibrationMeasurementTypeEnum.highRes].measurement = {
              type: data.measurement_type,
              schedule: data.high_res.measurement.schedule,
              stops: data.high_res.measurement.stops
            };
          } else {
            resData.spectrum_measurements[vibrationMeasurementTypeEnum.highRes].measurement = {
              type: data.measurement_type
            };
          }
        }

        if (!_.isEmpty(data.high_res.sampling.resolution)) {
          resData.spectrum_measurements[vibrationMeasurementTypeEnum.highRes].sampling = {
            resolution: data.high_res.sampling.resolution
          };
        }

        if (data.high_res.high_pass_filter_cutoff) {
          resData.spectrum_measurements[vibrationMeasurementTypeEnum.highRes].high_pass_filter_cutoff = data.high_res.high_pass_filter_cutoff;
        }

        if (data.measurement_interval) {
          resData.spectrum_measurements[vibrationMeasurementTypeEnum.highRes].measurement_interval = data.measurement_interval;
        }
      }

      if (_.isEmpty(resData.spectrum_measurements)) {
        delete resData.spectrum_measurements;
      }
    }

    if (data.type === 'Transmitter') {
      resData = {
        aggressive_measurement_timeout: data.aggressive_measurement_timeout,
        reporting: {
          interval: '',
        },
        serial_number: data.serial_number,
        type: data.type
      };
      if (data.reporting_interval) {
        resData.reporting.interval = data.reporting_interval;
        resData.aggressive_measurement = (
          data.reporting_interval <= AGGRESSIVE_INTERVAL_THRESHOLD
        );
      } else delete resData.reporting;

      if (data.ssid && data.passphrase) {
        resData.wifi_config = {
          ssid: data.ssid,
          passphrase: data.passphrase,
          start_time: sensorUtils.getEpochFromDateTime(data.wifi_schedule_date, data.wifi_schedule_time),
        };
      }

      if (data.bssid && data.channel) {
        resData.wifi_ap_config = {
          bssid: data.bssid,
          channel: data.channel,
        };
      }
    }
    if (data.type === 'other') {
      resData = {
        overall_measurements: {
          measurement: { type: '' },
          measurement_interval: ''
        },
        serial_number: data.serial_number,
        type: data.type
      };
      if (data.overall_measurement_type && data.scheduled_saved) {
        resData.overall_measurements.measurement.type = data.overall_measurement_type;
        resData.overall_measurements.measurement.schedule = data.overall_measurement_schedule;
        resData.overall_measurements.measurement.stops = data.spectrum_measurement_stops;
      } else delete resData.overall_measurements.measurement;
      if (data.overall_measurement_interval) {
        resData.overall_measurements.measurement_interval = data.overall_measurement_interval;
      } else delete resData.overall_measurements.measurement_interval;
      if (_.isEmpty(resData.overall_measurements)) {
        delete resData.overall_measurements;
      }
    }
    return resData;
  };

  validateInput = (model) => {
    const { fieldValues } = this.state;
    const { ssid, passphrase, bssid, channel } = fieldValues[model];

    const errorMessages = {};

    if (ssid || passphrase) {
      if (!passphrase) errorMessages.passphrase_error = ErrorMessages.wifi_password_required;
      if (!ssid) errorMessages.ssid_error = ErrorMessages.wifi_ssid_required;
    }

    if (!_.isEmpty(bssid)) {
      if (!bssid.match(/^([A-Fa-f0-9]{12})$/)) {
        errorMessages.bssid_error = ErrorMessages.invalid_bssid;
      }
    }

    const channelInt = parseInt(channel, 10);
    if (_.isNumber(channelInt) && (channelInt < 1 || channelInt > 13)) {
      errorMessages.channel_error = ErrorMessages.invalid_channel;
    }

    const newDetails = update(fieldValues, {
      [model]: { errorMessages: { $set: errorMessages } }
    });

    this.setState({ fieldValues: newDetails });
  }

  onSave = () => {
    const { updateSensorDetails } = this.props.sensorsActions;
    const { fieldValues } = this.state;
    const data = {};
    Object.entries(fieldValues).forEach((entry) => {
      data[entry[1].model] = this.makeResponseForType(entry[1]);
    });
    return updateSensorDetails(data).then(
      () => this.setState({ initialFieldValues: fieldValues }),
      error => toastr.error(error)
    );
  };

  onCancel = () => {
    this.calculateGroupedModelFieldValues();
  };

  stringifyOptions = options =>
    options.map(option =>
      ({
        ...option,
        value: _.isObject(option.value) ? JSON.stringify(option.value) : option.value
      }));

  renderField = (type, label, options_T, value_T, disabled, name, model, ri_upper_threshold = null) => {
    const { editMode } = this.state;
    const { updating } = this.props;

    if (['VM3', 'VM4P', 'GSP', 'VM4', 'GS'].includes(model) && name === 'reporting_interval' && ri_upper_threshold) {
      options_T = options_T.filter(o => o.value <= ri_upper_threshold);
    }

    const options = this.stringifyOptions(options_T);
    const value = _.isObject(value_T) || _.isArray(value_T) ? JSON.stringify(value_T) : value_T;
    if (editMode) {
      return (
        <ResourceInputField
          type={type}
          label={label}
          options={options}
          value={value}
          name={name}
          key={`${name}-${model}`}
          disabled={disabled || updating}
          onChange={(e, value) => this.onChangeField(e, value, name, model)}
          selectOnBlur={false}
          placeholder={_.isEmpty(value) ? 'Multiple Values' : ''}
        />
      );
    }
    let textValue = '';
    if (options) {
      const element = options.filter(option => _.isEqual(option.value, value))[0];
      if (element) textValue = element.text;
      else textValue = '--';
    }
    return (
      <Fragment>
        <ResourceItemLabel>{label}</ResourceItemLabel>
        <ResourceItem>{textValue}</ResourceItem>
      </Fragment>
    );
  };

  renderContent = (type, model, actualModel, ri_upper_threshold) => {
    const { metadata } = this.props;
    if (![...StandardSensors, ...VectorSensors].includes(type)) type = 'other';
    const { fieldValues } = this.state;
    if (!fieldValues[model]) return null;
    if (type !== 'other' && !metadata[actualModel]) {
      this.setState({
        error: 'No metadata for given model!'
      });
      return null;
    }
    if (type === 'other' && !metadata.other) {
      this.setState({
        error: 'No metadata for given model!'
      });
      return null;
    }
    const highResEnabled = !_.includes(oldTxMeasurementVersion, this.props.currentAccount.preferences.tx_measurement_version);
    switch (type) {
      case 'Vibration Mote': {
        return (
          <Fragment>
            <Labelcontainer>
              <ResourceItemLabel grey fontsize="14px" marginbottom="0" paddingtop="0" width="max-content">
                Full Spectrum Measurement Settings
              </ResourceItemLabel>
            </Labelcontainer>
            <ResourceHeaderSection>Sampling</ResourceHeaderSection>
            <Grid columns={3} doubling stackable>
              <Grid.Row>
                <Grid.Column>
                  <Segment>
                   {this.renderField(
                     'select',
                     'High Resolution',
                     metadata[model].measurement_settings.sampling[vibrationMeasurementTypeEnum.highRes],
                     fieldValues[model].high_res.sampling,
                     false,
                     'high_res.sampling',
                     model
                   )}
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment>
                   {this.renderField(
                     'select',
                     'Full Bandwidth',
                     metadata[model].measurement_settings.sampling[vibrationMeasurementTypeEnum.fullBandwidth],
                     fieldValues[model].full_bandwidth.sampling,
                     false,
                     'full_bandwidth.sampling',
                     model
                   )}
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment>
                   <ResourceItemLabel>Overalls</ResourceItemLabel>
                   <FlexContainer marginbottom="0.5em">
                    <Info aria-hidden="true" size="1x" />
                    <TextLabel>RMS, Peak and Peak 2 Peak are computed using a different sampling configuration.</TextLabel>
                   </FlexContainer>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <ResourceHeaderSection>High Pass Filter Cutoff</ResourceHeaderSection>
            <Grid columns={3} doubling stackable>
              <Grid.Row>
                <Grid.Column>
                  <Segment>
                   {this.renderField(
                     'select',
                     'High Resolution',
                     metadata[model].measurement_settings.high_pass_filter_cutoff[vibrationMeasurementTypeEnum.highRes],
                     fieldValues[model].high_res.high_pass_filter_cutoff,
                     false,
                     'high_res.high_pass_filter_cutoff',
                     model
                   )}
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment>
                    { metadata[model].measurement_settings.high_pass_filter_cutoff[vibrationMeasurementTypeEnum.fullBandwidth] &&
                     this.renderField(
                       'select',
                       'Full Bandwidth',
                       metadata[model].measurement_settings.high_pass_filter_cutoff[vibrationMeasurementTypeEnum.fullBandwidth],
                       fieldValues[model].full_bandwidth.high_pass_filter_cutoff,
                       false,
                       'full_bandwidth.high_pass_filter_cutoff',
                       model
                     )}
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <ResourceHeaderSection>Measurement</ResourceHeaderSection>
            <Fragment>
             <Grid.Column>
              <Segment>
                <div style={{ display: 'flex' }}>
                  <div>
                   <ResourceItemLabel>Type</ResourceItemLabel>
                   {fieldValues[model].overall.type === 'schedule_based' && (
                      <SetButton text onClick={() => this.setState({ openScheduler: true, modelOpened: model, typeOpened: type })} fontsize="12px">
                         Set
                      </SetButton>)
                   }
                   {this.renderField(
                     'select',
                     '',
                     sensorUtils.filterStateBasedOption(metadata[model].measurement_settings.type, true),
                     fieldValues[model].overall.type,
                     false,
                     'spectrum_measurement_type',
                     model)
                    }
                  </div>
                </div>
              </Segment>
             </Grid.Column>
            </Fragment>
            <ResourceHeaderSection>Reporting</ResourceHeaderSection>
            {model === 'VM1' || model === 'VM2' ? (
              <Grid columns={3} doubling stackable>
               <Grid.Row>
                <Grid.Column>
                  <Segment>
                    <ResourceItemLabel>High Resolution</ResourceItemLabel>
                    <FlexContainer marginbottom="0.5em">
                     <Info aria-hidden="true" size="1x" />
                     <TextLabel>High Resolution samples are reported immediately upon sampling.</TextLabel>
                    </FlexContainer>
                  </Segment>
                </Grid.Column>
               <Grid.Column>
                <Segment>
                 <ResourceItemLabel>Full Bandwidth</ResourceItemLabel>
                 <FlexContainer marginbottom="0.5em">
                   <Info aria-hidden="true" size="1x" />
                   <TextLabel>Full Bandwidth samples are reported immediately upon sampling.</TextLabel>
                 </FlexContainer>
                </Segment>
               </Grid.Column>
               <Grid.Column>
                <Segment>
                 <ResourceItemLabel>Overalls</ResourceItemLabel>
                 <FlexContainer marginbottom="0.5em">
                  <Info aria-hidden="true" size="1x" />
                  <TextLabel>Overalls are reported at the next reporting interval.</TextLabel>
                 </FlexContainer>
                </Segment>
               </Grid.Column>
               </Grid.Row>
              </Grid>
            ) : (
             <Grid columns={3}>
              <Grid.Row>
                <Grid.Column>
                  <Segment>
                    {this.renderField(
                      'select',
                      'Interval',
                      metadata[model].reporting.interval,
                      fieldValues[model].reporting_interval,
                      false,
                      'reporting_interval',
                      model,
                      ri_upper_threshold
                    )}
                  </Segment>
                </Grid.Column>
              </Grid.Row>
             </Grid>
            )}
            {['VM3', 'VM1', 'VM2', 'VM4P', 'VM4'].includes(model) && (
              <RBAC
                yes={(
                  <>
                    <ResourceHeaderSection>Wi-Fi Settings</ResourceHeaderSection>
                    <WifiSetting
                      ssid={fieldValues[model].ssid}
                      passphrase={fieldValues[model].passphrase}
                      errorMessages={fieldValues[model].errorMessages || {}}
                      wifi_schedule_date={fieldValues[model].wifi_schedule_date}
                      wifi_schedule_time={fieldValues[model].wifi_schedule_time}
                      preferredTimeOptions={this.preferredTimeOptions}
                      handleInputClick={this.handleInputClick}
                      model={model}
                    />
                    <ResourceHeaderSection>Preferred AP Settings</ResourceHeaderSection>
                    <PreferredAPSettings
                      bssid={fieldValues[model].bssid}
                      channel={fieldValues[model].channel}
                      errorMessages={fieldValues[model].errorMessages || {}}
                      handleInputClick={this.handleInputClick}
                      model={model}
                    />
                  </>
              )}
              />
            )}
          </Fragment>
        );
      }
      case 'Ultrasound Vector Sensor':
      case 'Magnetometer Sensor':
      case 'Current Sensor': {
        return (
          <Fragment>
            <Labelcontainer>
              <ResourceItemLabel grey fontsize="14px" marginbottom="0" paddingtop="0" width="max-content">
                Full Spectrum Measurement Settings
              </ResourceItemLabel>
            </Labelcontainer>
            <ResourceHeaderSection>Sampling</ResourceHeaderSection>
            <Grid columns={3} doubling stackable>
             <Grid.Row>
              {highResEnabled && (<>
                <Grid.Column>
                  <Segment>
                   {this.renderField(
                     'select',
                     'High Resolution',
                     metadata[model].spectrum_measurements.sampling.resolution[vibrationMeasurementTypeEnum.highRes],
                     fieldValues[model].high_res.sampling.resolution,
                     false,
                     'high_res.sampling',
                     model
                   )}
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment>
                   {this.renderField(
                     'select',
                     'Full Bandwidth',
                     metadata[model].spectrum_measurements.sampling.resolution[vibrationMeasurementTypeEnum.fullBandwidth],
                     fieldValues[model].full_bandwidth.sampling.resolution,
                     false,
                     'full_bandwidth.sampling',
                     model
                   )}
                  </Segment>
                </Grid.Column>
              </>)}
              {!highResEnabled && (
               <Grid.Column>
                <Segment>
                 {this.renderField(
                   'select',
                   'Resolution',
                   metadata[actualModel].spectrum_measurements.sampling.resolution[vibrationMeasurementTypeEnum.fullBandwidth],
                   fieldValues[model].full_bandwidth.sampling.resolution,
                   false,
                   'full_bandwidth.sampling',
                   model
                 )}
                </Segment>
               </Grid.Column>
              )}
             </Grid.Row>
            </Grid>

            <ResourceHeaderSection>Measurement</ResourceHeaderSection>
            <Grid.Column>
              <Segment>
                <div style={{ display: 'flex' }}>
                  <div>
                   <ResourceItemLabel>Type</ResourceItemLabel>
                   {
                    (
                      !_.includes(oldTxMeasurementVersion, this.props.currentAccount.preferences.tx_measurement_version) &&
                       fieldValues[model].measurement_type === 'schedule_based' &&
                       fieldValues[model].transmitter_version !== '0804'
                    ) &&
                       <Button onClick={() => this.setState({ openScheduler: true, modelOpened: model, typeOpened: type })} text fontsize="12px" style={{ paddingLeft: '120px' }}>Set </Button>
                   }

                   {!_.includes(oldTxMeasurementVersion, this.props.currentAccount.preferences.tx_measurement_version) &&
                   fieldValues[model].transmitter_version !== '0804' && (
                    <>
                     {this.renderField(
                       'select',
                       '',
                       metadata[actualModel].spectrum_measurements.measurement.type,
                       fieldValues[model].measurement_type,
                       false,
                       'measurement_type',
                       model
                     )}
                   </>
                   )
                  }
                  </div>
                </div>
              </Segment>
            </Grid.Column>
           {this.props.currentAccount.preferences.tx_measurement_version === 'v1' ||
              fieldValues[model].transmitter_version === '0804' && (
               <Grid.Column>
                 {this.renderField(
                   'select',
                   'Interval',
                   metadata[actualModel].spectrum_measurements.measurement.intervals,
                   fieldValues[model].measurement_interval,
                   false,
                   'measurement_interval',
                   model
                 )}
               </Grid.Column>
              )
            }
            <ResourceHeaderSection>Reporting</ResourceHeaderSection>
            <FlexContainer margintop="5px">
              <ExclamationTriangle size="sm" />
              <TextLabel>Reporting interval to be defined in Transmitter</TextLabel>
            </FlexContainer>
          </Fragment>
        );
      }
      case 'VSX':
      case 'Vibration Sensor': {
        return (
          <Fragment>
            <Labelcontainer>
              <ResourceItemLabel grey fontsize="14px" marginbottom="0" paddingtop="0" width="max-content">
                Full Spectrum Measurement Settings
              </ResourceItemLabel>
            </Labelcontainer>
            <ResourceHeaderSection>Sampling</ResourceHeaderSection>
            <Grid columns={3} doubling stackable>
              <Grid.Row>
               {highResEnabled && (<>
                <Grid.Column>
                 <Segment>
                  {this.renderField(
                    'select',
                    'High Resolution',
                    metadata[model].spectrum_measurements.sampling.resolution[vibrationMeasurementTypeEnum.highRes],
                    fieldValues[model].high_res.sampling.resolution,
                    false,
                    'high_res.sampling',
                    model
                  )}
                 </Segment>
                </Grid.Column>
                <Grid.Column>
                 <Segment>
                  {this.renderField(
                    'select',
                    'Full Bandwidth',
                    metadata[model].spectrum_measurements.sampling.resolution[vibrationMeasurementTypeEnum.fullBandwidth],
                    fieldValues[model].full_bandwidth.sampling.resolution,
                    false,
                    'full_bandwidth.sampling',
                    model
                  )}
                 </Segment>
                </Grid.Column>
               </>)}
               {!highResEnabled && (
                <Grid.Column>
                 <Segment>
                  {this.renderField(
                    'select',
                    'Resolution',
                    metadata[actualModel].spectrum_measurements.sampling.resolution[vibrationMeasurementTypeEnum.fullBandwidth],
                    fieldValues[model].full_bandwidth.sampling.resolution,
                    false,
                    'full_bandwidth.sampling',
                    model
                  )}
                 </Segment>
                </Grid.Column>
               )}
              </Grid.Row>
            </Grid>

          <ResourceHeaderSection>High Pass Filter Cutoff</ResourceHeaderSection>
          <Grid columns={3} doubling stackable>
            <Grid.Row>
             {highResEnabled && (<>
              <Grid.Column>
               <Segment>
                 {this.renderField(
                   'select',
                   'High Resolution',
                   metadata[model].spectrum_measurements.high_pass_filter_cutoff[vibrationMeasurementTypeEnum.highRes],
                   fieldValues[model].high_res.high_pass_filter_cutoff,
                   false,
                   'high_res.high_pass_filter_cutoff',
                   model
                 )}
               </Segment>
              </Grid.Column>
              <Grid.Column>
               <Segment>
                {this.renderField(
                  'select',
                  'Full Bandwidth',
                  metadata[model].spectrum_measurements.high_pass_filter_cutoff[vibrationMeasurementTypeEnum.fullBandwidth],
                  fieldValues[model].full_bandwidth.high_pass_filter_cutoff,
                  false,
                  'full_bandwidth.high_pass_filter_cutoff',
                  model
                )}
               </Segment>
              </Grid.Column>
             </>)}
            {!highResEnabled && (
             <Grid.Column>
              <Segment>
                 {this.renderField(
                   'select',
                   '',
                   metadata[actualModel].spectrum_measurements.high_pass_filter_cutoff[vibrationMeasurementTypeEnum.fullBandwidth],
                   fieldValues[model].full_bandwidth.high_pass_filter_cutoff,
                   false,
                   'full_bandwidth.high_pass_filter_cutoff',
                   model
                 )}
              </Segment>
             </Grid.Column>
            )}
            </Grid.Row>
          </Grid>
            {/* <FlexContainer margintop="5px">
              <ExclamationTriangle size="sm" />
              <TextLabel>Minimum allowed measurement interval with selected sampling is 6 hours.</TextLabel>
            </FlexContainer> */}
          <ResourceHeaderSection>Measurement</ResourceHeaderSection>
            <Grid.Column>
              <Segment>
                <div style={{ display: 'flex' }}>
                  <div>
                   <ResourceItemLabel>Type</ResourceItemLabel>
                   {(!_.includes(oldTxMeasurementVersion, this.props.currentAccount.preferences.tx_measurement_version) &&
                     fieldValues[model].measurement_type === 'schedule_based' && fieldValues[model].transmitter_version !== '0804') &&
                    <SetButton onClick={() => this.setState({ openScheduler: true, modelOpened: model, typeOpened: type })} text fontsize="12px">Set</SetButton>
                   }

                   {(!_.includes(oldTxMeasurementVersion, this.props.currentAccount.preferences.tx_measurement_version) &&
                   fieldValues[model].transmitter_version !== '0804') && (
                     this.renderField(
                       'select',
                       '',
                       metadata[actualModel].spectrum_measurements.measurement.type,
                       fieldValues[model].measurement_type,
                       false,
                       'measurement_type',
                       model))
                  }
                  </div>
                </div>
              </Segment>
            </Grid.Column>

            {(this.props.currentAccount.preferences.tx_measurement_version === 'v1' || fieldValues[model].transmitter_version === '0804') && (
             <Grid.Column>
              <Segment>
               {this.renderField(
                 'select',
                 'Interval',
                 metadata[actualModel].spectrum_measurements.measurement.intervals,
                 fieldValues[model].measurement_interval,
                 false,
                 'measurement_interval',
                 model
               )}
              </Segment>
             </Grid.Column>
            )}

            <ResourceHeaderSection>Reporting</ResourceHeaderSection>
            <FlexContainer margintop="5px">
              <ExclamationTriangle size="sm" />
              <TextLabel>Reporting interval to be defined in Transmitter</TextLabel>
            </FlexContainer>
          </Fragment>
        );
      }
      case 'Transmitter': {
        return (
          <Fragment>
           <ResourceHeaderSection>Reporting</ResourceHeaderSection>
            <Grid columns={3} doubling stackable>
              <Grid.Row>
               <Grid.Column>
                <Segment>
                 {this.renderField(
                   'select',
                   'Interval',
                   metadata[model].reporting.interval,
                   fieldValues[model].reporting_interval,
                   false,
                   'reporting_interval',
                   model
                 )}
                </Segment>
               </Grid.Column>
              </Grid.Row>
            </Grid>
            <RBAC
              yes={(
                <>
                  <ResourceHeaderSection>Wi-Fi Settings</ResourceHeaderSection>
                  <WifiSetting
                    ssid={fieldValues[model].ssid}
                    passphrase={fieldValues[model].passphrase}
                    errorMessages={fieldValues[model].errorMessages || {}}
                    wifi_schedule_date={fieldValues[model].wifi_schedule_date}
                    wifi_schedule_time={fieldValues[model].wifi_schedule_time}
                    preferredTimeOptions={this.preferredTimeOptions}
                    handleInputClick={this.handleInputClick}
                    model={model}
                  />
                  <ResourceHeaderSection>Preferred AP Settings</ResourceHeaderSection>
                  <PreferredAPSettings
                    bssid={fieldValues[model].bssid}
                    channel={fieldValues[model].channel}
                    errorMessages={fieldValues[model].errorMessages || {}}
                    handleInputClick={this.handleInputClick}
                    model={model}
                  />
                </>
              )}
            />
            {this.props.assignSensorVersionV2 && (
              <TxPorts
                txs={this.state.details.TX}
                editMode
                unassignSensor={this.unassignSensorFromTx}
                onAssignSensor={() => this.setState(prevState => ({ expandedContainers: { ...prevState.expandedContainers, TX: true } }))}
                onUpdateSensorSettings={this.props.sensorsActions.updateSensorDetails}
                onRefreshTxDetails={this.refreshTxDetails}
                user={this.props.user}
              />
            )}
          </Fragment>
        );
      }
      case 'other': {
        return (
          <Fragment>
            <Labelcontainer>
              <ResourceItemLabel grey fontsize="14px" marginbottom="0" paddingtop="0" width="350px">
                Overalls Measurement Settings
              </ResourceItemLabel>
            </Labelcontainer>
            <ResourceHeaderSection>Measurement</ResourceHeaderSection>

            <Grid columns={3} doubling stackable>
             <Grid.Row>
              <Grid.Column>
                <Segment>
                 <div style={{ display: 'flex' }}>
                  <div>
                   <ResourceItemLabel>Type</ResourceItemLabel>
                   {(!_.includes(oldTxMeasurementVersion, this.props.currentAccount.preferences.tx_measurement_version) &&
                    fieldValues[model].overall_measurement_type === 'schedule_based' &&
                    fieldValues[model].transmitter_version !== '0804') && (
                     <Button text onClick={() => this.setState({ openScheduler: true, modelOpened: model, typeOpened: type })} fontsize="12px">
                       Set Schedule
                     </Button>
                   )}
                   {(!_.includes(oldTxMeasurementVersion, this.props.currentAccount.preferences.tx_measurement_version) &&
                    fieldValues[model].transmitter_version !== '0804') && this.renderField(
                     'select',
                     '',
                     metadata.other.measurement_settings.type,
                     fieldValues[model].overall_measurement_type,
                     false,
                     'overall_measurement_type',
                     model)}
                  </div>
                 </div>
                </Segment>
              </Grid.Column>
              {(this.props.currentAccount.preferences.tx_measurement_version === 'v1' || fieldValues[model].transmitter_version === '0804') && (
               <Grid.Column>
                <Segment>
                 {this.renderField(
                   'select',
                   'Interval',
                   metadata.other.measurement_settings.intervals,
                   fieldValues[model].overall_measurement_interval,
                   false,
                   'overall_measurement_interval',
                   model
                 )}
                </Segment>
               </Grid.Column>
              )}
             </Grid.Row>
            </Grid>
            <ResourceHeaderSection>Reporting</ResourceHeaderSection>
            <FlexContainer>
              <ExclamationTriangle size="sm" />
              <TextLabel>Reporting interval to be defined in Transmitter</TextLabel>
            </FlexContainer>
          </Fragment>
        );
      }
      default:
        return 'Invalid type';
    }
  };

  saveSchedule = (schedule, stops) => {
    const { fieldValues, modelOpened, typeOpened } = this.state;
    if (typeOpened === 'Vibration Mote') {
      const newDetails = update(fieldValues, {
        [modelOpened]: {
          overall: { schedule: { $set: schedule[0] }, stops: { $set: stops[0] } },
          full_bandwidth: { schedule: { $set: schedule[1] }, stops: { $set: stops[1] } },
          high_res: { schedule: { $set: schedule[2] }, stops: { $set: stops[2] } },
          scheduled_saved: { $set: true }
        }
      });
      this.setState({ fieldValues: newDetails, openScheduler: false }, this.onSave);
    } else if (VectorSensors.includes(typeOpened)) {
      const newDetails = update(fieldValues, {
        [modelOpened]: {
          full_bandwidth: { measurement: {
            schedule: { $set: schedule[1] },
            stops: { $set: stops[1] }
          } },
          high_res: { measurement: {
            schedule: { $set: schedule[2] },
            stops: { $set: stops[2] }
          } },
          scheduled_saved: { $set: true }
        }
      });
      this.setState({ fieldValues: newDetails, openScheduler: false }, this.onSave);
    } else if (typeOpened === 'other') {
      const newDetails = update(fieldValues, {
        [modelOpened]: {
          overall_measurement_schedule: { $set: schedule[0] },
          overall_measurement_stops: { $set: stops[0] },
          scheduled_saved: { $set: true }
        }
      });
      this.setState({ fieldValues: newDetails, openScheduler: false }, this.onSave);
    }
  };

  saveDailySchedule = (schedule, stops) => {
    const { fieldValues } = this.state;
    const newDetails = update(fieldValues, {
      other: {
        overall_measurement_schedule: { $set: schedule },
        overall_measurement_stops: { $set: stops }
      }
    });
    this.setState({ fieldValues: newDetails });
  };

  renderAggressiveModal = (modelOpened) => {
    const { metadata } = this.props;
    const { details } = this.state;
    return (
      <div> {
        this.renderField(
          'select',
          'Measurement Time Out Interval',
          metadata.aggressive_measurement_timeout.interval,
          details.aggressive_measurement_timeout,
          false,
          'aggressive_measurement_timeout',
          modelOpened
        )
      }
      {
        metadata[modelOpened].reporting &&
        metadata[modelOpened].reporting.interval &&
        this.renderField(
          'select',
          'Reporting Interval',
          _.filter(
            metadata[modelOpened].reporting.interval,
            o => o.value <= AGGRESSIVE_INTERVAL_THRESHOLD
          ),
          details.reporting_interval,
          false,
          'reporting_interval',
          modelOpened
        )
      }
      </div>
    );
  }

  renderReportingIntervalModal = (modelOpened, ri_upper_threshold) => {
    const { metadata } = this.props;
    const { details } = this.state;

    return (
      <div>
        {
          metadata[modelOpened].reporting &&
          metadata[modelOpened].reporting.interval &&
          this.renderField(
            'select',
            'Reporting Interval',
            _.filter(
              metadata[modelOpened].reporting.interval,
              o => o.value > AGGRESSIVE_INTERVAL_THRESHOLD
            ),
            details[modelOpened].reporting_interval,
            false,
            'reporting_interval',
            modelOpened,
            ri_upper_threshold
          )
      }
      </div>
    );
  }

  refreshTxDetails = () => {
    const { details } = this.state;
    if (details && details.TX) {
      details.TX.forEach(tx => (
        this.props.sensorsActions.getSensorDetails(tx.serial_number)
      ));
    }
  };

  render() {
    const {
      sensorList,
      saveEnabled,
      error,
      details,
      fieldValues,
      modelOpened,
      typeOpened
    } = this.state;
    const { metadata } = this.props;
    const { loading, detailsError, errorList, updating, utcOffset } = this.props;
    let overall = [];
    let overall_stops = [];
    let full_bandwidth = [];
    let full_bandwidth_stops = [];
    let high_res = [];
    let high_res_stops = [];
    let intervals = [];
    let disabledRows = {};
    let component = null;
    let reportingIntervalComponent = null;
    let disableReportingProceed = null;

    let ri_upper_threshold = 1e9;
    if (details && details.VM3) {
      const thresolds = [];
      details.VM3.forEach(
        entry => thresolds.push(sensorUtils.getVersionThreshold(entry.firmware_versions.mcu))
      );
      if (!_.isEmpty(thresolds)) ri_upper_threshold = _.min(thresolds);
    }

    if (details && details.VM4P) {
      const thresolds = [];
      details.VM4P.forEach(
        entry => thresolds.push(sensorUtils.getVersionThreshold(entry.firmware_versions.mcu))
      );
      if (!_.isEmpty(thresolds)) ri_upper_threshold = _.min(thresolds);
    }

    if (details && details.GSP) {
      const thresolds = [];
      details.GSP.forEach(
        entry => thresolds.push(sensorUtils.getVersionThreshold(entry.firmware_versions.mcu))
      );
      if (!_.isEmpty(thresolds)) ri_upper_threshold = _.min(thresolds);
    }

    if (details && details.VM4) {
      const thresolds = [];
      details.VM4.forEach(
        entry => thresolds.push(sensorUtils.getVersionThreshold(entry.firmware_versions.mcu))
      );
      if (!_.isEmpty(thresolds)) ri_upper_threshold = _.min(thresolds);
    }

    if (details && details.GS) {
      const thresolds = [];
      details.GS.forEach(
        entry => thresolds.push(sensorUtils.getVersionThreshold(entry.firmware_versions.mcu))
      );
      if (!_.isEmpty(thresolds)) ri_upper_threshold = _.min(thresolds);
    }

    if (typeOpened === 'Vibration Mote') {
      overall = fieldValues[modelOpened].overall.schedule;
      full_bandwidth = fieldValues[modelOpened].full_bandwidth.schedule;
      high_res = fieldValues[modelOpened].high_res.schedule;
      overall_stops = fieldValues[modelOpened].overall.stops;
      full_bandwidth_stops = fieldValues[modelOpened].full_bandwidth.stops;
      high_res_stops = fieldValues[modelOpened].high_res.stops;
      intervals = metadata[modelOpened].measurement_settings.intervals;
      disabledRows = {
        0: false,
        1: false,
        2: false
      };
      component = this.renderAggressiveModal(modelOpened);
      reportingIntervalComponent = (
        ['VM1', 'VM2'].includes(modelOpened) ?
          null : this.renderReportingIntervalModal(modelOpened, ri_upper_threshold)
      );
      disableReportingProceed = (
        ['VM3', 'VM4P', 'GSP'].includes(modelOpened) &&
        details[modelOpened].reporting_interval < AGGRESSIVE_INTERVAL_THRESHOLD
      );
    } else if (VectorSensors.includes(typeOpened)) {
      const highResEnabled = !_.includes(oldTxMeasurementVersion, this.props.currentAccount.preferences.tx_measurement_version) &&
        (typeOpened === 'Vibration Sensor' || typeOpened === 'Current Sensor' || typeOpened === 'VSX');
      overall = [];
      overall_stops = [];
      full_bandwidth = [];
      full_bandwidth_stops = [];
      high_res = [];
      high_res_stops = [];
      if (fieldValues[modelOpened].full_bandwidth.measurement.schedule) {
        full_bandwidth = fieldValues[modelOpened].full_bandwidth.measurement.schedule;
        full_bandwidth_stops = fieldValues[modelOpened].full_bandwidth.measurement.stops;
      }
      if (highResEnabled && fieldValues[modelOpened].high_res.measurement.schedule) {
        high_res = fieldValues[modelOpened].high_res.measurement.schedule;
        high_res_stops = fieldValues[modelOpened].high_res.measurement.stops;
      }
      intervals = metadata[modelOpened].spectrum_measurements.measurement.intervals;
      disabledRows = {
        0: true,
        1: false,
        2: !highResEnabled
      };
      component = this.renderAggressiveModal(modelOpened);
    } else if (typeOpened === 'other') {
      overall = fieldValues.other.overall_measurement_schedule;
      overall_stops = fieldValues.other.overall_measurement_stops;
      high_res = [];
      high_res_stops = [];
      full_bandwidth = [];
      full_bandwidth_stops = [];
      intervals = metadata.other.measurement_settings.intervals;
      disabledRows = {
        0: false,
        1: true,
        2: true
      };
    }

    return (
      <Fragment>
        {updating && (
          <AbsoluteLoading>
            <LoadingSvg />
          </AbsoluteLoading>)
        }
        {this.state.openScheduler && (
          <MeasurementScheduleModal
            close={() => this.setState({ openScheduler: false })}
            editMode={this.state.editMode}
            schedule={{
              0: overall,
              1: full_bandwidth,
              2: high_res
            }}
            stops={{
              0: overall_stops,
              1: full_bandwidth_stops,
              2: high_res_stops
            }}
            disabledRows={disabledRows}
            intervals={intervals}
            saveSchedule={this.saveSchedule}
            utcOffset={utcOffset}
            component={() => component}
            reportingIntervalComponent={reportingIntervalComponent}
            onProceedDisabledReporting={disableReportingProceed}
            restrictHighRes={typeOpened === 'Vibration Mote'}
          />
        )}
        <Container>
          {loading && !error && !detailsError && (
            <LoadingContainer>
              <LoadingSvg />
            </LoadingContainer>)}
          {!loading && (error || detailsError) && (
            <div>{error || detailsError} <b>(serial numbers: {errorList.join(', ')})</b></div>
          )
          }
          {!loading && !error && (
            <Fragment>
              <HeaderContainer>
                <H2>Edit {sensorList.length} sensors</H2>
                <SvgContainer onClick={this.props.close}>
                  <CrossSvg width={25} height={25} />
                </SvgContainer>
              </HeaderContainer>
              {Object.entries(details).map((entry, idx) => (
                entry[1][0] && (
                <ExpandableContainer
                  key={idx}
                  title={['other', 'other - 0804'].includes(entry[0]) ? CalculateOtherSensorGroupedName(entry[1]) : `${entry[1][0].type} - ${entry[0]}`}
                  count={entry[1].length}
                  defaultState={this.state.expandedContainers[entry[0]]}
                >
                  {this.renderContent(entry[1][0].type, entry[0], entry[1][0].model, ri_upper_threshold)}
                </ExpandableContainer>)
              ))}
              <ButtonsContainer>
                <Button disabled={!saveEnabled} onClick={this.onSave}>Save</Button>
                <Button secondary="#3C3D3A" disabled={!saveEnabled} onClick={this.onCancel}>Cancel</Button>
              </ButtonsContainer>
            </Fragment>
          )}
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  metadata: state.sensors.metadata.metadata,
  details: state.sensors.details.details,
  currentAccount: state.currentAccount,
  loading: state.sensors.details.loading,
  detailsError: state.sensors.details.error,
  updating: state.sensors.details.updating,
  errorList: state.sensors.details.errorList
  // sensorsList: state.sensors.selectedSensorList
});

const mapDispatchToProps = dispatch => ({
  sensorsActions: bindActionCreators(sensorsActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(GroupedSensors);
