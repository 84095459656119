import styled from 'styled-components';
import FooterFlex_T from 'common/components/atoms/FooterFlex';
import PropTypes from 'prop-types';

const FooterFlex = styled(FooterFlex_T)`
  ${props => props.alignHorizontal && `${props.alignHorizontal}: auto`}
  ${props => props.right && 'right: auto'}
  width: ${props => props.width};
`;

FooterFlex.propTypes = {
  alignHorizontal: PropTypes.oneOf(['left', 'right']),
  width: PropTypes.string
};

FooterFlex.defaultProps = {
  alignHorizontal: 'left',
  width: '100%'
};

export default styled(FooterFlex)``;
