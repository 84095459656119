import React, { Component } from 'react';
import {
  connect
} from 'react-redux';
import {
  bindActionCreators
} from 'redux';
import styled from 'styled-components';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { getMachineConditionLabels } from 'common/constants';

import MoreOptions from 'common/components/organisms/MoreOptions';
import FilterCountContainer from 'common/components/atoms/FilterCountContainer';
import PlusSvg from 'common/images/PlusSvg';
import Button_T from 'common/components/atoms/Button';
import Checkbox from 'common/components/atoms/Checkbox';
import { CollapsibleWidgetsContainer } from 'common/components/molecules';
import { isPetasenseAdmin, isPetasenseViewer, isAnalyst } from 'common/rbac/util';
import SearchFilters from 'common/components/atoms/SearchFilters';
import { humanize } from 'common/helpers/humanize';
import { isFilterApplied, generateQueryString, isJsonString } from 'common/utils';
import { FlexContainer } from 'common/components/atoms';
import { Bullhorn } from 'common/images/FaIcons';
import {
  machinesAnalyticsMetadata,
  machineAnalyticsFilters,
  filtersIncludedInCharts,
  customLabels,
  alarmMetricsMetadata
} from 'home/MachinesAnalytics/machinesAnalytics.constants';
import { getMachinesAnalyticsData } from 'home/MachinesAnalytics/machinesAnalytics.actions';
import Widget from 'home/Widget';
import DynamicFilters from 'common/components/organisms/DynamicFilters';
import { history } from 'common/helpers/history';
import { widgetActions } from 'home/Widget/actions/widget.actions';
import {
  mapComponentToResource,
  operations
} from 'common/rbac/constants';
import RBAC from 'common/rbac/RBAC';
import FilterV2 from 'common/components/molecules/FilterV2';
import {
  machinesActions
} from '../machines.actions';
import List from '../../../common/components/molecules/List';
import InputField from '../../../common/components/atoms/InputField';
import MachineItem from './MachineItem';
import {
  Search
} from '../../../common/images/FaIcons';
import MachineBuilderModal from '../MachineBuilder/MachineBuilderModal';
import * as hierarchyActions from '../../AssetHierarchy/actions/hierarchy.actions';
import AccountMenuItems from '../../../common/components/organisms/AccountMenuItems';
import * as machineBuilderActions from '../MachineBuilder/actions/machineBuilder.actions';
import { getSearchParamFilters } from '../utils';

const Button = styled(Button_T)`
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  font-size: ${props => props.fontSize ? props.fontSize : '20px'};
  padding: 12px 9px;
  border: none !important;
  width: ${props => props.width ? props.width : 'auto'};
`;

const MachineAttentionButton = styled(Button_T)`
  font-family: "Petasense Open Sans";
  font-weight: 600;
  font-size: 12px;
  margin-left: 10px;
  ${props => !props.selected && `
    background-color: ${props.theme.colors.green};
    color: white;
    border-color: ${props.theme.colors.green};
 `}
  ${props => props.selected && `
    background-color: ${props.theme.colors.yellow};
    color: white;
    border-color: ${props.theme.colors.yellow};
  `}
  margin-right: 7px ;
`;

const MachinesContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  margin: ${props => props.margin ? props.margin : '0px'};
  border: ${props => props.border ? props.border : '0px'};
  border-radius: ${props => props.borderRadius ? props.borderRadius : '0px'};
  height: 100%;
`;

const ListContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  position: relative;
  overflow: auto;
  width: ${props => props.width};
  min-width: 45rem;
  height: 100%;
  ${props => props.border && `border: ${props.border}`}
  ${props => props.borderRadius && `border-radius: ${props.borderRadius}`}
`;

const AreaSearch = styled.div`
  > ${InputField} {
      margin-top: 1em;
      margin-left: 1em;
      margin-right: 1em;
      margin-bottom: 0px;
      min-width: 160px;
      max-width: 100%;
      height: 32px;
      border-radius: 4px;
      svg {
        color: ${props => props.theme.colors.lightGray};
      }
      input {
        padding-left: 0;
    }
  }
`;

const FilterItem = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  text-align: left;
  width: 100%;
  min-width: ${props => props.minWidth || '150px'};
  border: none;
  outline: none;
  background-color: white;
  font-family: "Petasense Open Sans";
  font-weight: 600;
  color: ${props => props.theme.colors.lightGray};
  ${props => props.selected && `color: ${props.theme.colors.black};`}
  &:hover:not(.group-name) {
    color: ${props => props.theme.colors.black};
    background-color: #f7f7f7;
  }
  &:first-of-type {
    padding-top: 1em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    padding-bottom: 1em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  span {
    margin-left: 0.5em;
  }
  &.group-name {
    cursor: default;
    text-align: center;
    padding: 0.5em 0;
    color: ${props => props.theme.colors.black};
    background-color: ${props => props.theme.colors.greyL}
  }
  &.group-name span {
    width: 100%;
    margin: 0;
  }

  ${props => props.paddingBottom && 'padding-bottom: 15px;'}
`;

class MachinesPage extends Component {
  constructor(props) {
    super(props);
    this.stateOptions = [
      'ON',
      'OFF',
      'NA'
    ];

    this.orientationOptions = ['horizontal', 'vertical'];
    this.componentTypes = [
      'ac_induction_motor',
      'ac_synchronous_motor',
      'dc_motor',
      'steam_turbine',
      'agitator',
      'axial_compressor',
      'axial_fan',
      'axial_pump',
      'ball_mill',
      'centrifugal_compressor',
      'centrifugal_fan',
      'centrifugal_pump',
      'conveyor',
      'gear_pump',
      'reciprocating_compressor',
      'sag_mill',
      'screw_compressor',
      'screw_pump'
    ];
    this.conditionOptions = getMachineConditionLabels(this.props.assetConditionLevels);
    this.criticalityOptions = [
      'Very High',
      'High',
      'Medium',
      'Low',
      'Very Low'
    ];
    this.initialSearchParams = this.getInitialSearchParams();
    this.state = {
      searchParams: {
        ...this.initialSearchParams
      },
      areaSearchKey: '',
      areaOptions: [],
      areaDisplay: [],
      locationTypes: [],
      transmissionOptions: [],
      reasonsForAttention: [],
      filtersOpen: {
        state: false,
        condtion: false,
        criticality: false,
        area: false,
        orientation: false,
        component_subtype: false,
        location: false,
        transmission: false
      },
      customFilters: [],
      customFilterOptions: {},
      selected: _.cloneDeep(this.props.selectedOptions) || []
    };
  }

  componentDidMount() {
    const title = this.props.title;
    document.title = `${title} | Assets`;

    const {
      setMachinePaginater,
      initMachinePaginater,
      setMachineSearchParams,
      getAreas
    } = this.props.machinesActions;

    this.isAssetsPage = this.props.location.pathname === '/assets';

    if (this.props.location.state && this.props.location.state.filter) {
      this.selectFilterFromCharts(this.props.location.state.filter);
      setMachinePaginater(1);
    } else if (!_.isEmpty(this.props.preFilters)) {
      this.setState(prevState => ({
        searchParams: {
          ...prevState.searchParams,
          ...this.props.preFilters
        }
      }));
      setMachineSearchParams(this.props.preFilters);
      this.props.toggleModelResource(this.state.selected);
    } else if (!_.isEmpty(this.props.location.search) && this.isAssetsPage) {
      this.selectFiltersFromUrl();
    } else initMachinePaginater();
    getAreas().then((res) => {
      this.setState({
        areaOptions: res.result,
        areaDisplay: res.result
      });
    });

    const { getMachineBuilderMetadata } = this.props.machineBuilderActions;
    getMachineBuilderMetadata().then((res) => {
      this.setState({
        locationTypes: res.data.available_location_types,
        transmissionOptions: res.data.components.transmission.map(x => x.value),
        reasonsForAttention: res.data.reasons_for_attention
      });
    });

    this.props.widgetActions.getWidgetsMetadata();
  }

  componentDidUpdate(prevProps) {
    if (this.isAssetsPage && !_.isEqual(this.props.location.search, prevProps.location.search)) {
      this.selectFiltersFromUrl();
    }
  }

  selectFilterFromCharts = (filter) => {
    let searchParams = {
      ...this.initialSearchParams
    };
    searchParams = {
      ...searchParams,
      [filter.name]: filter.value
    };
    this.setState({ searchParams }, this.updateUrlQueryString);
    this.search(searchParams);
  }

  getInitialSearchParams = () => {
    const { currentUser } = this.props;
    let attentionFilters = ['tasks'];
    if (isAnalyst(currentUser) || isPetasenseAdmin(currentUser) || isPetasenseViewer(currentUser)) {
      attentionFilters = [];
    }
    return {
      searchKey: '',
      state: [],
      condition: [],
      criticality: [],
      area: [],
      orientation: [],
      component_subtype: [],
      location: [],
      transmission: [],
      vfd: [],
      health_score: [],
      asset_setup: [],
      events: [],
      attention_criteria: attentionFilters,
      alarm_metrics: []
    };
  }

  selectFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const searchParams = {
      ...this.initialSearchParams
    };
    searchParams.area = [...this.state.searchParams.area];
    const customFilters = [];
    const excludeKeys = ['searchKey'];
    Object.keys(searchParams).forEach((key) => {
      if (urlParams.get(key)) {
        searchParams[key] = isJsonString(urlParams.get(key)) ?
          JSON.parse(urlParams.get(key)) :
          urlParams.get(key);
      }
      if (urlParams.get(key) && !this.props.filters.includes(key) && !excludeKeys.includes(key)) {
        customFilters.push(key);
      }
    });
    if (!_.isEqual(this.state.searchParams, searchParams)) {
      this.setState({ searchParams, customFilters });
      this.search(searchParams);
    } else {
      this.setState({ customFilters });
    }
  }

  clearAllFilters = () => {
    let searchParams = {
      ...this.initialSearchParams
    };

    if (!_.isEmpty(this.props.preFilters)) {
      searchParams = {
        ...searchParams,
        ...this.props.preFilters
      };
    }

    const fetchChartData = _.some(filtersIncludedInCharts, filter =>
      !_.isEqual(searchParams[filter], this.state.searchParams[filter])
    ) && this.props.showChartsView;

    this.setState({
      searchParams,
      customFilters: []
    }, this.updateUrlQueryString);

    this.search(searchParams);
    if (fetchChartData) this.fetchChartData();
  }

  getNextMachines = () => {
    const {
      increaseMachinePaginater
    } = this.props.machinesActions;
    increaseMachinePaginater();
  };

  toggleFilterOpen(filter) {
    this.setState(prevState => ({
      filtersOpen: {
        ...prevState.filtersOpen,
        [filter]: !prevState.filtersOpen[filter]
      }
    }));
  }

  nameSearchDebounce = _.debounce(
    () => {
      this.search();
    }, 500);

  searchArea(key) {
    this.setState((prevState) => {
      const filteredAreas = _.filter(prevState.areaOptions, area => area.path.toLowerCase().includes(key.toLowerCase()));
      return ({
        areaSearchKey: key,
        areaDisplay: filteredAreas
      });
    });
  }


  search(params) {
    if (!params) {
      params = {
        ...this.state.searchParams
      };
    }
    const searchParams = {
      ...params,
    };
    this.props.machinesActions.setMachineSearchParams(searchParams);
  }

  changeFilter(filter, value) {
    this.setState((prevState) => {
      const searchParams = {
        ...prevState.searchParams,
        [filter]: value
      };
      return ({
        searchParams
      });
    }, () => {
      if (filter === 'searchKey') {
        this.nameSearchDebounce();
      } else {
        if (this.props.location.pathname === '/assets') this.updateUrlQueryString();
        this.search();
      }
    });
  }

  fetchChartData = () => {
    machineAnalyticsFilters.forEach(filter =>
      this.props.getMachinesAnalyticsData(filter)
    );
  }

  updateUrlQueryString = () => {
    const queryString = generateQueryString(
      this.state.searchParams,
      ['area', 'attention_criteria']
    );
    const pathname = this.props.location.pathname;
    history.replace({
      pathname,
      search: queryString
    });
  }

  selectMachine = (machine_id) => {
    this.props.hierarchyActions.selectNode(machine_id, 'machine');
    if (this.props.machinesRequiringAttention) {
      this.props.machinesActions.setAttentionMachineViewed(machine_id);
    }
  }

  toggleResource = (value) => {
    const { selected } = this.state;
    const index = selected.indexOf(value);
    if (index === -1) selected.push(value);
    else selected.splice(index, 1);
    this.setState({
      selected
    });
    this.props.toggleModelResource(selected);
  }

  toggleSelectAll = () => {
    const { items } = this.props;
    const selectAll = items.object && items.object.length === this.state.selected.length;
    const selectSome = this.state.selected.length > 0;
    const ids = [];
    if (!(selectAll || selectSome)) {
      items.object.forEach((option) => {
        ids.push(option.id);
      });
    }
    this.setState({
      selected: ids
    });
    this.props.toggleModelResource(ids);
  };

  renderFilterOptions = (options, name, key = '', singleSelect = false, labelKey = '') => {
    if (_.isEmpty(options)) return null;
    return options.map((option, index) => {
      const selected = this.state.searchParams[name] && this.state.searchParams[name].find(opt => _.isEqual(opt, option));
      const optionKey = labelKey || key;
      return (
        <FilterItem
          key={index.toString()}
          className={`dropdown-item ${option.groupName ? 'group-name' : ''}`}
          selected={selected}
          onClick={() => {
            if (option.groupName) return;
            let options = [];
            if (selected) {
              options = _.filter(
                this.state.searchParams[name],
                t => key ? t[key] !== option[key] : t !== option
              );
            } else if (singleSelect) {
              options = [option];
            } else {
              options = [...this.state.searchParams[name], option];
            }
            this.changeFilter(name, options);
          }}
        >
          {option.groupName ? (
            <span>{humanize(option.groupName)}</span>
          ) : (
            <>
              {!singleSelect && (<Checkbox value={selected} />)}
              <span>
                {optionKey ? customLabels[option[optionKey]] || humanize(option[optionKey]) : humanize(option)}
              </span>
            </>
          )}
        </FilterItem>
      );
    });
  };

  renderFilter = (filter) => {
    const filterMetaData = this.getFilterMetadata(filter);

    if (filter === 'area') {
      const areaNameValue = this.state.searchParams.area.reduce((acc, areaName, idx) => {
        const areas = this.state.areaOptions.filter(area => area.name === areaName.name);
        if (idx === 0) return `${areas[0].name}`;
        if (idx === 1) return `${acc}, ${areas[0].name}...`;
        return acc;
      }, '');
      return (
        <FilterV2
          title="Areas"
          className="filter-container"
          value={areaNameValue}
          open={this.state.filtersOpen.area}
          toggleOpen={() => this.toggleFilterOpen('area')}
          selectedItems={this.state.searchParams.area}
          viewAllItems={this.state.areaOptions}
        >
          <AreaSearch>
            <InputField
              type="text"
              prefix={<Search />}
              prefixSide="left"
              onChange={e => this.searchArea(e.target.value)}
              value={this.state.areaSearchKey}
            />
          </AreaSearch>
          {this.renderFilterOptions(this.state.areaDisplay, 'area', 'name', false, 'path')}
        </FilterV2>
      );
    }

    return (
      <FilterV2
        className="filter-container"
        title={filterMetaData.title}
        value={this.getFilterText(filter)}
        open={this.state.filtersOpen[filter]}
        toggleOpen={() => this.toggleFilterOpen(filter)}
        selectedItems={this.state.searchParams[filter]}
      >
        {this.renderFilterOptions(
          filterMetaData.options, filter, filterMetaData.key, filterMetaData.singleSelect
        )}
      </FilterV2>
    );
  }

  getFilterText = (filter) => {
    let typeText = '';
    const options = this.state.searchParams[filter];
    const filters = ['attention_criteria', 'location', 'component_subtype', 'condition', 'criticality', 'alarm_metrics', ...machineAnalyticsFilters];

    if (!_.isEmpty(options)) {
      if (filters.includes(filter)) {
        typeText = options.reduce((acc, id, idx) => {
          const name = options.filter(
            type => type.value ? type.value === id.value : type === id).map(
            x => (x.text ? customLabels[x.text] || humanize(x.text) : humanize(x))
          );
          if (idx === 0) return name;
          if (idx === 1) return `${acc}, ${name}...`;
          return acc;
        }, '');
      } else {
        typeText = this.state.searchParams[filter].map(x => humanize(x)).join(', ');
      }
    }

    return typeText;
  }

  setFilterOptions = (filter, options) => {
    this.setState(prevState => ({
      customFilterOptions: {
        ...prevState.customFilterOptions,
        [filter]: options
      }
    }));
  }

  getFilterMetadata = (filter) => {
    switch (filter) {
      case 'state':
        return {
          title: 'State',
          options: this.stateOptions,
          key: ''
        };
      case 'condition':
        return {
          title: 'Condition',
          options: this.conditionOptions,
          key: ''
        };
      case 'criticality':
        return {
          title: 'Criticality',
          options: this.criticalityOptions,
          key: ''
        };
      case 'area':
        return {
          title: 'Areas',
          options: this.state.areaDisplay,
          key: 'name'
        };
      case 'orientation':
        return {
          title: 'Orientation',
          options: this.orientationOptions,
          key: ''
        };
      case 'component_subtype':
        return {
          title: 'Component Types',
          options: this.componentTypes,
          key: ''
        };
      case 'location':
        return {
          title: 'Location Types',
          options: this.state.locationTypes,
          key: 'text'
        };
      case 'transmission':
        return {
          title: 'Transmission',
          options: this.state.transmissionOptions,
          key: ''
        };
      case 'vfd':
        return {
          title: 'VFD',
          options: machinesAnalyticsMetadata[filter].filterOptions,
          key: 'text',
          singleSelect: true
        };
      case 'events':
        return {
          title: 'Events',
          options: this.state.customFilterOptions &&
            this.state.customFilterOptions.events,
          key: 'text',
          singleSelect: true
        };
      case 'health_score':
        return {
          title: 'Health Score',
          options: machinesAnalyticsMetadata[filter].filterOptions[
            this.props.assetConditionLevels
          ],
          key: 'text'
        };
      case 'asset_setup':
        return {
          title: 'Asset Setup',
          options: this.state.customFilterOptions &&
            this.state.customFilterOptions.asset_setup,
          key: 'text',
          singleSelect: true
        };
      case 'attention_criteria':
        return {
          title: 'Attention Criteria',
          options: this.state.reasonsForAttention
        };
      case 'alarm_metrics':
        return {
          title: 'Alarm Metrics',
          options: alarmMetricsMetadata.filterOptions,
          singleSelect: true
        };
      default:
        return null;
    }
  }

  getChartFilters = () => {
    const filters = getSearchParamFilters(this.state.searchParams, true);
    filters.push({ name: 'machines_requiring_attention', op: 'eq', value: !!this.props.machinesRequiringAttention });
    return filters;
  };

  renderWidget = (chartFilters, metadata) => (
    <Widget
      setFilterOptions={this.setFilterOptions}
      filters={chartFilters}
      metadata={metadata}
      width="310px"
      height="220px"
    />
  );

  render() {
    const {
      items,
      sorterState,
      showMoreOptions,
      ItemComponent,
      selectedId,
      border,
      borderRadius,
      margin,
      showFilterBorderBottom,
      listProps,
      showChartsView,
      listContainerBorder,
      listContainerBorderRadius,
      machinesRequiringAttention,
      headers,
      columnSizes,
      widgetsMetadata
    } = this.props;
    const {
      setMachineSorter,
      toggleMachinesRequiringAttention
    } = this.props.machinesActions;

    const filters = this.props.filters.concat(this.state.customFilters);
    const chartFilters = this.getChartFilters();

    return (
      <MachinesContainer id="machines-container" borderRadius={borderRadius} border={border} margin={margin}>
        {showMoreOptions && (
          <MoreOptions>
            <AccountMenuItems />
          </MoreOptions>
        )}
        <SearchFilters showBorderBottom={showFilterBorderBottom}>
          <FlexContainer className="filter-misc-items" alignItems="center">
            {items && (
              <FilterCountContainer>{items.total_count}</FilterCountContainer>
            )}
            {this.props.allowAssetCreation && (
              <RBAC
                resource={mapComponentToResource.Machines}
                operation={operations.Create}
                yes={(
                <Button secondary marginLeft="10px">
                  <Link title="Create New Asset" to="/machines/new"><PlusSvg width="16" height="16" /></Link>
                </Button>
                )}
              />
            )}
            <InputField
              name="assets-search-box"
              type="text"
              prefix={<Search />}
              prefixSide="left"
              placeholder="Search..."
              onChange={
                e => this.changeFilter('searchKey', e.target.value)
              }
              value={
                this.state.searchParams.searchKey
              }
              searchBox
              clearable
              clearInput={() => this.changeFilter('searchKey', '')}
              marginBottom="0"
            />
            {this.isAssetsPage && (
              <>
                <MachineAttentionButton
                  secondary
                  selected={machinesRequiringAttention}
                  title={machinesRequiringAttention ? 'Show all Assets' : 'Show Assets Requiring Attention'}
                  onClick={() => {
                    toggleMachinesRequiringAttention();
                  }}
                >
                  <Bullhorn size="1x" />
                </MachineAttentionButton>
                {machinesRequiringAttention && (
                  this.renderFilter('attention_criteria')
                )}
              </>
            )}
          </FlexContainer>
          <DynamicFilters
            panes={filters && filters.map(filter => this.renderFilter(filter))}
            bufferSpace={20}
            showClearFilter={isFilterApplied(this.state.searchParams)}
            clearAllFilters={this.clearAllFilters}
          />
        </SearchFilters>
        <FlexContainer style={{ overflow: 'auto' }}>
          <ListContainer border={listContainerBorder} borderRadius={listContainerBorderRadius}>
            <List
              height={this.props.height}
              columnSizes={columnSizes}
              headers={headers}
              maxHeightAfterScroll={this.props.maxHeightAfterScroll}
              sorterState={sorterState}
              setSorter={setMachineSorter}
              items={items}
              hasMore={items.hasMore}
              loading={items.loading}
              loadMoreItems={this.getNextMachines}
              ItemComponent={ItemComponent}
              onMachineSelect={this.selectMachine}
              emptyMsg="No results found"
              padding={this.props.padding}
              allSelected={items.object && items.object.length === this.state.selected.length}
              selectAll={this.toggleSelectAll}
              toggleResource={id => this.toggleResource(id)}
              selectedId={selectedId}
              selectedOptions={this.state.selected}
              editMode={this.props.editMode}
              border={this.props.border}
              {...listProps}
            />
          </ListContainer>
          {showChartsView && (
            <CollapsibleWidgetsContainer componentName="Machines">
              {!widgetsMetadata.loading && !_.isEmpty(widgetsMetadata.object) && (
                <>
                  {this.renderWidget(chartFilters, widgetsMetadata.object.incomplete_asset_setup_breakdown)}
                  {this.renderWidget(chartFilters, widgetsMetadata.object.open_events_breakdown)}
                  {this.renderWidget(chartFilters, widgetsMetadata.object.vfd_breakdown)}
                  {this.renderWidget(chartFilters, widgetsMetadata.object.asset_health_scores_breakdown)}
                </>
              )}
            </CollapsibleWidgetsContainer>
          )}
        </FlexContainer>
        {
          this.state.showMachineBuilderModal && (
            <MachineBuilderModal
              close={(id) => {
                this.setState({ showMachineBuilderModal: false });
                if (id) this.props.machinesActions.initMachinePaginater();
              }}
            />
          )
        }
      </MachinesContainer>
    );
  }
}

MachinesPage.propTypes = {
  showMoreOptions: PropTypes.bool,
  columnSizes: PropTypes.arrayOf(PropTypes.number),
  headers: PropTypes.arrayOf(PropTypes.object),
  ItemComponent: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.string),
  selectedId: PropTypes.number,
  selectedOptions: PropTypes.arrayOf(PropTypes.number),
  editMode: PropTypes.bool,
  border: PropTypes.string,
  preFilters: PropTypes.object,
  padding: PropTypes.string,
  height: PropTypes.string,
  maxHeightAfterScroll: PropTypes.string,
  allowAssetCreation: PropTypes.bool,
  showFilterBorderBottom: PropTypes.bool,
  listProps: PropTypes.object,
  showChartsView: PropTypes.bool,
  listContainerBorder: PropTypes.string,
  listContainerBorderRadius: PropTypes.string,
};

MachinesPage.defaultProps = {
  showMoreOptions: true,
  columnSizes: [25, 12.5, 12.5, 12.5, 12.5, 12.5],
  headers: [{
    name: 'name',
    label: 'Machine'
  }, {
    name: 'area_name',
    label: 'Area'
  }, {
    name: 'current_state',
    label: 'State',
    alignCenter: true
  }, {
    name: 'last_measurement_time',
    label: 'Last Measurement',
    alignCenter: true
  }, {
    name: 'setup_completion_percent',
    label: 'Setup completion %',
    alignCenter: true
  }, {
    name: 'health_score',
    label: 'Health Score',
    alignCenter: true
  }],
  ItemComponent: MachineItem,
  filters: [
    'area',
    'condition',
    'criticality',
    'state',
    'orientation',
    'component_subtype',
  ],
  selectedId: null,
  selectedOptions: null,
  editMode: null,
  border: null,
  preFilters: {},
  padding: '0 1em',
  height: 'calc(100vh - 226px)',
  maxHeightAfterScroll: 'calc(100vh - 226px)',
  allowAssetCreation: true,
  showFilterBorderBottom: true,
  listProps: null,
  showChartsView: true,
  listContainerBorder: '',
  listContainerBorderRadius: ''
};

const mapStateToProps = state => ({
  query: state.machines.query,
  items: state.machines.items,
  sorterState: state.machines.sorter,
  machinesRequiringAttention: state.machines.machinesRequiringAttention.show,
  currentUser: state.user.user,
  assetConditionLevels: state.currentAccount.preferences.machine_condition_levels,
  analyticsFilters: state.machinesAnalytics,
  widgetsMetadata: state.widgets.metadata,
  title: state.companyReducer.partner.title
});

const mapDispatchToProps = dispatch => ({
  machinesActions: bindActionCreators(machinesActions, dispatch),
  hierarchyActions: bindActionCreators(hierarchyActions, dispatch),
  machineBuilderActions: bindActionCreators(machineBuilderActions, dispatch),
  getMachinesAnalyticsData: bindActionCreators(getMachinesAnalyticsData, dispatch),
  widgetActions: bindActionCreators(widgetActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MachinesPage));
