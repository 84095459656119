import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import FooterFlex from '../atoms/FooterFlex';
import Background from '../atoms/Background';
import Maybe from '../atoms/Maybe';
import MaybeContainer from '../atoms/MaybeContainer';
import ModalScrollContainer from '../atoms/ModalScrollContainer';
import ModalContainer from '../molecules/ModalContainer';
import ModalContent from '../molecules/ModalContent';


class Modal extends Component {
  /*
    known bug:
    if modal will grow dynamically on runtime over the vertical height of the window the sizing is misagligned
  */
  constructor(props) {
    super(props);
    this.scrollRef = null;
    this.checkVerticalSize = this.checkVerticalSize.bind(this);
    this.correctOverflow = this.correctOverflow.bind(this);
    this.state = {
      verticalCenter: true,
      correctOverflow: props.correctOverflow
    };
  }

  componentDidMount() {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';
    this.checkVerticalSize();
    // registers the close function with ChartItem to ensure only one modal is active at a time
    if (this.props.modalSwitch) {
      setTimeout(() => this.props.modalSwitch(this.props.close), 200);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.verticalCenter === prevState.verticalCenter && !_.isEqual(prevProps.children, this.props.children)) {
      this.checkVerticalSize();
    }
    if (this.state.correctOverflow && this.modal.getBoundingClientRect().height > window.innerHeight) {
      this.correctOverflow(this.modal.getBoundingClientRect().height - window.innerHeight);
    }
  }

  componentWillUnmount() {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'visible';
    if (this.props.modalSwitch) this.props.modalSwitch();
  }

  correctOverflow(y) {
    // prevents new elements in the modal being loaded into overflow
    this.scrollRef.scroll(0, y);
  }

  checkVerticalSize() {
    // see if modal content is larger than window height (disable vertical centering in this case)
    if (this.modal.getBoundingClientRect().height > window.innerHeight && this.state.verticalCenter !== false) {
      this.setState({ verticalCenter: false });
    }
  }

  render() {
    const {
      title,
      close,
      children,
      width,
      minHeight,
      maxHeight,
      saved,
      padding,
      minWidth,
      menuItems,
      footer,
      header,
      innerScroll,
      headerHeight,
      flexGap,
      filterContainer,
      filterContainerOpen,
      setFilterContainerOpen,
      filterIconActive,
      dialog,
      outerPadding,
      headerOptions,
      switchButtons,
      version,
      ...rest
    } = this.props;
    return (
      <Background
        fullScreen={width === '100%'}
        version="v2"
        {...rest}
      >
      {/* Header and Footer are functions, header and footer objects. If experiencing problems with performance */
      /* or focus use the object format - functions trigger full re-render for every change - example can be found on BearingModal.js */}
        <ModalScrollContainer
          verticalCenter={this.state.verticalCenter}
          innerRef={(node) => { this.scrollRef = node; }}
          innerScroll={innerScroll}
        >
          <ModalContainer
            outerPadding={outerPadding}
            width={width}
            maxHeight={maxHeight}
            fullScreen={width === '100%'}
            innerRef={(node) => { this.modal = node; }}
            version={version}
          >
             {/* eslint-disable-next-line no-nested-ternary */}
            <MaybeContainer
              footer={footer}
              width={width}
              margin={this.props.margin}
              noOverflow={this.props.noOverflow}
            >
              <Maybe
                Component={ModalContent}
                condition={dialog}
                consequence="div"
                padding={padding}
                flexGap={flexGap}
                correctOverflow={this.correctOverflow}
                innerScroll={innerScroll}
              >
                {children}
              </Maybe>
            </MaybeContainer>
            { footer && (
          <FooterFlex left="0">
            {typeof footer === 'function' ? footer()
              : footer.items.map(Item =>
                <Item.Tag {...Item.props}>{Item.children}</Item.Tag>)}
          </FooterFlex>)}
          </ModalContainer>
        </ModalScrollContainer>
      </Background>
    );
  }
}

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.func]),
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  width: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  correctOverflow: PropTypes.bool,
  modalSwitch: PropTypes.func,
  innerScroll: PropTypes.bool,
};

Modal.defaultProps = {
  title: undefined,
  header: undefined,
  width: '100%',
  padding: '1em 2em',
  correctOverflow: false,
  minHeight: 'none',
  maxHeight: 'none',
  height: 'auto',
  modalSwitch: undefined,
  innerScroll: false
};

export default Modal;
export { FooterFlex };
