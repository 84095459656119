import * as d3 from 'd3';

// append horizontal grid linex (they start from the yAxis)
// ticks are the text + a line that are generally marking chart data lines
// tickFormat '' is a way to not apply any text to the ticks (draw only lines)
// setting the tickSize to the node width we can draw a grid

export const yAxisGridlines = (node, scale, ticks, chartName) =>
  d3.select(node)
    .append('g')
    .attr('class', `${chartName} axis axis--y`)
    .call(d3.axisRight(scale)
      .ticks(ticks)
      .tickSize(node.getBoundingClientRect().width)
      .tickFormat(''));

// same as y axis but on the vertical side
// need to transform the origin by height so that it is beginning from the bottom
export const xAxisGridlines = (node, scale, ticks, chartName) =>
  d3.select(node)
    .append('g')
    .attr('transform', `translate(0, ${node.getBoundingClientRect().height})`)
    .attr('class', `${chartName} axis axis--x`)
    .call(d3.axisTop(scale)
      .ticks(ticks)
      .tickSize(node.getBoundingClientRect().height)
      .tickFormat(''));

// these will display the text since they have unmodified tickFormat,
// class tickline-hidden enables the possibility to hide overlapping ticks if desired
export const ticksBottom = (
  node,
  scale,
  ticks,
  chartName,
  translateY = node.getBoundingClientRect().height
) =>
  d3.select(node)
    .append('g')
    .attr('transform', `translate(0, ${translateY})`)
    .attr('class', `${chartName} axis tickline-hidden`)
    .call(d3.axisBottom(scale)
      .ticks(ticks));

export const ticksLeft = (node, scale, ticks, chartName) =>
  d3.select(node)
    .append('g')
    .attr('class', `${chartName} axis tickline-hidden ticks-left`)
    .call(d3.axisLeft(scale)
      .ticks(ticks)
      .tickSize(10));
