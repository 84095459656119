import styled from 'styled-components';
import colors from 'common/styles/colors';
import { svgConstants } from '../../constants/waterfall.constants';

const WaterfallContainer = styled.div`
margin: 0px;
position: relative;
g.zLine path {
  fill: none;
  stroke: #AFAFAE;
  stroke-width: 1px;
}

g.zLine-connect path {
  fill: none;
  stroke: ${colors.red};
  stroke-width: 1px;
}

g.focused.zLine-connect path {
  fill: none;
  stroke: ${colors.red};
  stroke-width: 2px;
}

.voronoi path {
  fill: none;
  stroke: red;
  stroke-opacity: 0.2;

  pointer-events: all;
}

g.spectrum path {
  fill: none;
  stroke-width: 1px;
  clip-path: url(#waterfall-clip);
}

g.spectrum:nth-child(3n-2) {
  path {
    stroke: ${colors.green};
  }
  .zTick {
    fill: ${colors.green};
  }
}

g.spectrum:nth-child(3n-1) {
  path {
    stroke: ${colors.black};
  }
  .zTick {
    fill: ${colors.black};
  }
}

g.spectrum:nth-child(3n) {
  path {
    stroke: ${colors.blue};
  }
  .zTick {
    fill: ${colors.blue};
  }
}

g.spectrum.baseline {
  path {
    stroke: ${colors.yellow};
  }
  .zTick {
    fill: ${colors.yellow};
  }
}

g.spectrum.focused path{
  stroke-width: 2px;
}

g.spectrum.exclude path {
  stroke-width: 1px;
  stroke: ${colors.greyD};
}

g.spectrum.focused.exclude path {
  stroke-width: 2px;
}

g.spectrum .zTick {
  stroke: none;
  font-size:10px;
  font-weight: 600;
  font-family: "Petasense Open Sans";
}
[class*='paint_context_chart'] {
  .selection {
    fill-opacity: 0.2;
  }
  .handle {
    fill: #afafae;
    height: ${svgConstants.CONTEXT_CHART_HEIGHT}px;
    width: 4px;
    y: 0;
  }
  .tick > text {
    color: ${colors.greyXD};
  }
  .tick > line {
    stroke-width: 0;
  }
}

.axis line {
  stroke-width: 1px;
  stroke: #AFAFAE;
}

.axis path {
  stroke-width: 1px;
  stroke: #AFAFAE;
}

.tickline-hidden{
  .tick > line, path {
    visibility: hidden;
  }
}

.tick text {
  font-weight: 600;
  font-family: "Petasense Open Sans";
}

.title, .baseline-mark {
  font-size: 30px;
  font-weight: 600;
  fill: ${colors.divBackgroundGray};
  line-height: 1;
  text-transform: capitalize;
}

.ylabel {
  font-size: 10px;
  fill: ${colors.greyXD};
  font-weight: 600;
}

.tooltip-label{
  font-size: 10px;
  fill: ${colors.greyXD};
  font-weight: 600;
}

.tooltip-label.bold {
  fill: ${colors.black};
}

& .slider-container {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 1px;
}

& .vertical-slider {
  transform-origin: 100% 50%;
  transform: rotate(-90deg);
}

& .vertical-slider .prefix-label {
  transform: rotate(90deg);
}
`;

export default styled(WaterfallContainer)``;
