import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Container } from 'semantic-ui-react';
import Modal from 'common/components/organisms/Modal';
import { FlexContainer } from 'common/components/atoms';
import Label from 'common/typography/Label/Label';
import Button from 'common/components/atoms/Button';
import InputField_T from 'common/components/Chart/atoms/InputField';

import styled from 'styled-components';
import * as accountsListActions from '../actions/account.actions';
import { INDUSTRY_TYPES } from '../constants/account.constants';

const TFlexContainer = styled(FlexContainer)`
  line-height: 1.3em;
  padding: 10px;
  margin:1px;
`;
const CancelButton = styled(Button)`
  margin-right: 20px
`;

const InputField = styled(InputField_T)`
  padding-top: 0.5em;
  padding-left:0.5em;
  label {
    font-weight: 800;
  }
  margin-bottom: 3px;
`;

function AddAccount(props) {
  const [newAccountData, setNewAccountData] = useState({
    name: '',
    industry: '',
    description: ''
  });
  const handleIndustryChange = (value) => {
    setNewAccountData((prevData) => {
      if (value !== 'Others') {
        return { ...prevData, industry: value, industryType: '' };
      }
      return { ...prevData, industry: value };
    });
  };
  const handleCreateAccount = () => {
    const { addAccount } = props.accountsListActions;
    addAccount(newAccountData);
    props.onCancel();
  };

  return (
    <div>
      <Modal
        width="550px"
        padding="0 20px"
        close={props.onCancel}
      >
        <TFlexContainer direction="column" marginbottom="40px" margintop="40px">
          <FlexContainer direction="column" alignItems="center" justifyContent="center" marginbottom="15px">
            <Label fontWeight="400" fontSize="25px" marginBottom="2px">Add Account</Label>
          </FlexContainer>
          <Container>
            <InputField
              label="Account Name"
              type="text"
              name="name"
              maxLength={70}
              value={newAccountData.name}
              onChange={e => setNewAccountData({ ...newAccountData, name: e.target.value })}
              placeholder=" Enter Account Name"
              search
            />
            <InputField
              label="Industry"
              type="select"
              value={newAccountData.industry}
              onChange={(e, d) => handleIndustryChange(d.value)}
              options={INDUSTRY_TYPES}
              placeholder="Select Industry Type"
            />
            {newAccountData.industry === 'Others' && (
              <InputField
                label="Industry Type"
                type="text"
                value={newAccountData.industryType}
                onChange={e => setNewAccountData({ ...newAccountData, industryType: e.target.value })}
                placeholder="Enter Industry Type"
                search
              />
            )}
            <InputField
              label="Description"
              type="textarea"
              name="description"
              value={newAccountData.description}
              onChange={e => setNewAccountData({ ...newAccountData, description: e.target.value })}
              placeholder="Description"
              search
              rows={5}
              style={{ resize: 'vertical', minHeight: '100px' }}
            />
            <FlexContainer justifyContent="right" padding-left="100px">
              <CancelButton onClick={props.onCancel} secondary="#000">CANCEL</CancelButton>
              <Button
                onClick={handleCreateAccount}
                disabled={
                  (!newAccountData.name || !newAccountData.industry || (newAccountData.industry === 'Others' && !newAccountData.industryType) || !newAccountData.description)
                }
              >
                ADD
              </Button>
            </FlexContainer>
          </Container>
        </TFlexContainer>
      </Modal>
    </div>

  );
}

const mapDispatchToProps = dispatch => ({
  accountsListActions: bindActionCreators(accountsListActions, dispatch)
});

export default connect(null, mapDispatchToProps)(AddAccount);
