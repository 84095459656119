import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../atoms/Button';

const MsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  margin-top: 70px;
  background-color: ${props => props.theme.colors.white};
  border: 2px dotted ${props => props.theme.colors.lightGray};
  border-radius: ${props => props.theme.utils.borderRadius};
  span {
    color: ${props => props.theme.colors.lightGray};
    font-weight: bold;
    margin-bottom: 1em;
  }
`;

const PageNotFound = () => (
  <MsgContainer>
    <span>Unfortunately the page you are looking for does not exist</span>
    <Link to="/">
      <Button>Go to Dashboard</Button>
    </Link>
  </MsgContainer>
);

export default PageNotFound;
