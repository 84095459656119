export const adminDashboardConstants = {
  TOGGLE_ADMIN_DASHBOARD: 'TOGGLE_ADMIN_DASHBOARD'
};

export const MISSED_MEASUREMENTS = {
  initialSearchParams: {
    type: [],
    account_search_key: '',
    accounts: [],
    date: ['7'],
    measurement: []
  },
  typeOptions: [
    { text: 'Mote', value: 'Mote' },
    { text: 'Other', value: 'Other' }
  ],
  stateOptions: [
    { text: 'State Based', value: 'state_based' },
    { text: 'Schedule Based', value: 'schedule_based' }
  ],
  dateOptions: [
    {
      text: 'Today',
      value: '1',
      name: 'Today'
    },
    {
      text: 'Last 7 Days',
      value: '7',
      name: 'Last 7 Days'
    },
    {
      text: 'Last 30 Days',
      value: '30',
      name: 'Last 30 Days'
    },
    {
      text: 'This Month',
      value: 'thisMonth',
      name: 'This Month'
    },
    {
      text: 'Last Month',
      value: 'lastMonth',
      name: 'Last Month'
    },
    {
      text: 'Custom',
      value: 'custom',
      name: 'Custom'
    }
  ]
};
