import { Component } from 'react';
import * as d3 from 'd3';

import { LineChart } from '../hoc/LineChart';
import * as helpers from '../utils/helpers';
import * as graphUtils from '../utils/graphUtils';


class UtilizationChart extends Component {
  drawGraph(ctx) {
    if (this.props.mouseover) {
      graphUtils.addMouseoverEvent(ctx.chart, () => {
        const dataPoint = helpers.closestExactDataPoint(ctx);
        ctx.props.mouseover(dataPoint);
      });
    }
  }

  drawAxis(ctx) {
    // rename yaxis labels
    const { chartName } = this.props;
    d3.selectAll(`.${chartName}.ticks-left`).remove();
    const yAxisLabels = ['NA', 'OFF', 'ON'];

    d3.select(ctx.chart)
      .append('g')
      .attr('class', `${chartName} axis tickline-hidden ticks-left`)
      .call(d3.axisLeft(ctx.y)
        .tickValues([-1, 0, 1, 2, 3])
        .tickSize(10)
        .tickFormat(d => yAxisLabels[d]));
  }

  /* eslint-disable */
  redrawDataLines(x, y, ctx) {
    ctx.props.data.forEach((dataSet, idx) => {
      const orderValue = (idx + 1) % 3;
      if (ctx.props.activeData[idx]) {
        ctx.chartDatalines[idx]
          .attr('d', d3.line()
            .defined((d) => {
              if (d.y === idx) {
                return true;
              } return false;
            })
            .x(d => ctx.x(d.x))
            .y(d => ctx.y(orderValue)));
      }
    });

    // if (!ctx.props.utilCtx) {
    //   // redraw dots in chart for single datapoints
    //   d3.selectAll(`.${ctx.props.chartName}.datadot`).remove();
    //   ctx.props.data.forEach((dataSet, idx) => {
    //     ctx.chartDataDots = d3.select(ctx.chart)
    //       .selectAll(`${ctx.props.chartName} datadot idx-${idx}`)
    //         .data(dataSet.filter(function(d) { return d; }))
    //       .enter().append('circle')
    //         .filter(function(d, i) {
    //           return i > 1
    //           && dataSet[i - 1].y !== d.y
    //           && dataSet[i + 1].y !== d.y;
    //         })
    //         .attr('class', `${ctx.props.chartName} datadot idx-${idx}`)
    //         .attr('cx', d => ctx.x(d.x))
    //         .attr('cy', d => ctx.y((d.y + 1) % 3))
    //         .attr('r', 3)
    //         .attr('fill', d => ctx.props.colors[(d.y + 1) % 3]);
    //   });
    // }
  }

  drawDataLinesOverride(ctx) {

    const { chartName } = this.props;

    d3.selectAll(`.${chartName}.dataline`).remove();

    ctx.chartDatalines = [];

    ctx.props.data.forEach((dataSet, idx) => {
      const orderValue = (idx + 1) % 3;
      const dataline = graphUtils.drawDataline(
        ctx.chart,
        dataSet,
        ctx.x,
        ctx.y,
        `${chartName} dataline util idx-${idx}`,
        {
          lineFunc: d3.line()
            .defined((d) => {
              if (d.y === idx) {
                return true;
              } return false;
            })
            .x(d => ctx.x(d.x))
            .y(d => ctx.y(orderValue))
        }
      );
      dataline
        .attr('stroke', (d, i) => ctx.props.colors[orderValue]);

      if (!ctx.props.activeData[idx]) dataline.attr('opacity', 0);
      ctx.chartDatalines.push(dataline);
    });

    // if (!ctx.props.utilCtx) {
    //   // data-points as circles in cases of separated data for easier comprehension of actual data
    //   ctx.props.data.forEach((dataSet, idx) => {
    //     ctx.chartDataDots = d3.select(ctx.chart)
    //       .selectAll(`${chartName} datadot idx-${idx}`)
    //         .data(dataSet.filter(function(d) { return d; }))
    //       .enter().append('circle')
    //         .filter(function(d, i) {
    //           return i > 1
    //           && dataSet[i - 1].y !== d.y
    //           && dataSet[i + 1].y !== d.y;
    //         })
    //         .attr('class', `${chartName} datadot idx-${idx}`)
    //         .attr('cx', d => ctx.x(d.x))
    //         .attr('cy', d => ctx.y((d.y + 1) % 3))
    //         .attr('r', 3)
    //         .attr('fill', d => ctx.props.colors[(d.y + 1) % 3]);
    //   });
    // }
  }

  selection(ctx, selection) {
    d3.selectAll(`.${ctx.props.chartName}.selection.line`).remove();
    graphUtils.drawLineOnPos(ctx.chart, ctx.x(selection.x), 0, ctx.x(selection.x), ctx.chart.getBoundingClientRect().height, `${this.props.chartName} selection`);

    d3.selectAll(`.${ctx.props.chartName}.selection.circle`).remove();
    graphUtils.drawCircleOnPos(ctx.chart, ctx.x(selection.x), ctx.y(selection.y), 3, `${this.props.chartName} selection`);
  }

  render() {
    return null;
  }
}

UtilizationChart.displayName = 'UtilizationChart';

export default LineChart(UtilizationChart);
