import { axiosInstance, ENDPOINT } from '../../../common/constants';
import { handleResponse } from '../../../common/helpers';

export const getDefinitions = params =>
  handleResponse(axiosInstance.get(ENDPOINT.GET_DEFINITIONS, { params }));

export const createDefinition = definition =>
  handleResponse(axiosInstance.post(ENDPOINT.CREATE_DEFINITION, definition));

export const getResources = params =>
  handleResponse(axiosInstance.get(ENDPOINT.GET_FILTER_RESOURCES, { params }));

export const getDefinition = template_id =>
  handleResponse(axiosInstance.get(ENDPOINT.GET_EVENT_DEFINITION(template_id)));

export const deleteDefinition = template_id =>
  handleResponse(axiosInstance.delete(ENDPOINT.DELETE_EVENT_DEFINITION(template_id)));

export const updateTrigger = (template_id, data) =>
  handleResponse(axiosInstance.put(ENDPOINT.UPDATE_DEFINITION_TRIGGER(template_id), data));

export const updateAction = (template_id, data) =>
  handleResponse(axiosInstance.put(ENDPOINT.UPDATE_ACTION(template_id), data));

export const updateSubscription = (template_id, data) =>
  handleResponse(axiosInstance.post(ENDPOINT.UPDATE_SUBSCRIPTION(template_id), data));

export const deleteSubscription = (template_id, data) =>
  handleResponse(axiosInstance.delete(ENDPOINT.UPDATE_SUBSCRIPTION(template_id), { data }));

export const addResource = (template_id, data) =>
  handleResponse(axiosInstance.post(ENDPOINT.ADD_DEFINTION_RESOURCE(template_id), data));

export const syncResource = (template_id, data) =>
  handleResponse(axiosInstance.put(ENDPOINT.SYNC_DEFINTION_RESOURCE(template_id), data));

export const deleteResource = (template_id, data) =>
  handleResponse(axiosInstance.delete(ENDPOINT.DELETE_DEFINTION_RESOURCE(template_id), { data }));

export const getDefinitionsMetadata = () =>
  handleResponse(axiosInstance.get(ENDPOINT.GET_EVENT_DEFINITION_METADATA));

export const updateEventsAccountPreference = (account_id, data) =>
  handleResponse(axiosInstance.put(ENDPOINT.UPDATE_EVENT_PREFERENCES(account_id), { ...data }));
