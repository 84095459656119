import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';
import styled from 'styled-components';

import FlexContainer from 'common/components/atoms/FlexContainer';
import Button from 'common/components/atoms/Button';
import Label from 'common/typography/Label/Label';
import Text from 'common/typography/Text/Text';
import LoadingSvg from 'common/images/LoadingSvg';
import { mapNames } from 'common/charts/constants';
import TagChart from '../../../Machines/MachineDetails/MachineCharts/components/TagChart';
import * as spectrumFeaturesActions from '../../actions/spectrumFeatures.actions';
import * as utils from '../../utils/assetHierarchyUtils';
import Footer from '../molecules/Footer';

const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const FeatureDetails = styled.div`
  padding-top: 4em;
  ${FlexContainer} {
    margin-right: 1em;
    margin-bottom: 1em;
    min-width: 300px;
  }
`;

const MContent = styled.div`
  padding: 1em 2em;
`;

class FeatureTrendModal extends React.Component {
  componentDidMount() {
    const feature = _.get(this.props, 'feature');
    const { tag } = this.props;
    if (feature) {
      const params = {
        days: 10,
        amp_type: this.props.ampType,
        frequency: feature.frequency,
        feature_id: feature.id
      };
      if (feature.feature_type === 'bearing' || feature.feature_type === 'forcing_frequencies') {
        this.props.spectrumFeaturesActions.initiateCustomFeatureTrend(
          tag.id,
          feature.id,
          params
        );
      } else this.props.spectrumFeaturesActions.initiateSpectrumFeatureTrend(feature.id, params);
    }
  }

  render() {
    const { close, feature, tag } = this.props;
    const { siteId, machineId, accountsInfo } = this.props;
    const featureTrend = utils.getFeatureTrend(machineId, siteId, accountsInfo);
    const footer = () => (
      <>
        <Button
          danger
          onClick={close}
          right
        >
          {'Close'}
        </Button>
      </>
    );
    return (
      <>
        <MContent>
        <div className="feature-modal-main">
          <div className="feature-modal-chart-container">
            {featureTrend &&
            featureTrend.data &&
            featureTrend.featureId === feature.id ? (
              <TagChart
                type="line"
                tag_type={tag.type}
                unit_system={this.props.user.unit_system}
                data={featureTrend.data}
                tagId={tag.id}
                expanded={false}
                yTitle={mapNames[tag.feature] || tag.type}
                yUnit={featureTrend.amp_units}
                xIsDate
                mouseoverChart={() => undefined}
                mouseClick={() => undefined}
                height="250px"
                noHover
              />
              ) : (
              <LoadingContainer>
                <LoadingSvg height={20} />
              </LoadingContainer>
              )}
          </div>
          <FeatureDetails>
            <FlexContainer>
              <FlexContainer direction="column">
                <Label>Marker</Label>
                <Text>{feature.marker}</Text>
              </FlexContainer>
              {feature.description && (
                <FlexContainer direction="column">
                  <Label>Description</Label>
                  <Text>{feature.description}</Text>
                </FlexContainer>
              )}
            </FlexContainer>
            <FlexContainer>
              <FlexContainer direction="column">
                <Label>Type</Label>
                <Text>{feature.type}</Text>
              </FlexContainer>
              <FlexContainer direction="column">
                <Label>Frequency</Label>
                {feature.frequency !== null && (
                  <Text>
                    {feature.frequency} {feature.freq_units}
                  </Text>
                )}
                {feature.frequency_start !== null && (
                  <Text>
                    {feature.frequency_start} {feature.freq_units} - {feature.frequency_end}{' '}
                    {feature.freq_units}
                  </Text>
                )}
              </FlexContainer>
            </FlexContainer>
          </FeatureDetails>
        </div>
        </MContent>
        <Footer footer={footer} showWidth={this.props.showWidth} />
        </>
    );
  }
}

const mapStateToProps = state => ({
  charts: state.machineDetails.charts,
  user: state.user.user,
  machineId: state.breadcrumb.machine.id,
  siteId: state.breadcrumb.site.id,
  accountsInfo: state.assetHierarchyReducer.assetInfo.sites
});

const mapDispatchToProps = dispatch => ({
  spectrumFeaturesActions: bindActionCreators(spectrumFeaturesActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureTrendModal);
