import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userActions } from '../../onboarding';
import { getCurrentAccount } from '../../onboarding/user.actions';
import LoadingSvg from '../images/LoadingSvg';
import AssociateWithSite from '../../onboarding/components/AssociateWithSite';

class PublicRoute extends React.Component {
  componentDidMount() {
    const title = this.props.companyReducer.partner.title;
    document.title = `${title}`;
    const { user, getUser, getRBACPermissions, getCurrentAccount } = this.props;
    if (!user || !user.user || user.error) {
      getUser();
      getCurrentAccount();
      getRBACPermissions();
    }
  }

  render() {
    const { component: Component, user, ...rest } = this.props;
    const primaryColor = this.props.companyReducer && this.props.companyReducer.partner.theme && this.props.companyReducer.partner.theme.primaryColor;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (user.user) {
            if (this.props.location.pathname === '/associate-with-site') {
              return <AssociateWithSite currentUser={user.user} locationName={this.props.location} />;
            }
            return <Redirect to="/" />;
          }
          if (user.error) {
            if (this.props.location.pathname === '/associate-with-site') {
              return <AssociateWithSite locationName={this.props.location} />;
            }
            return <Component {...props} />;
          }
          return (
            <div className="full-screen-center">
              <LoadingSvg />
            </div>
          );
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  const { user, companyReducer } = state;
  return { user, companyReducer };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser: userActions.getUser,
      getRBACPermissions: userActions.getRBACPermissions,
      getCurrentAccount
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
