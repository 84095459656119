import { dashboardConstants } from '../constants/dashboard.constants';

export function DashboardMachinesList(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.GET_DASHBOARD_MACHINES_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.GET_DASHBOARD_MACHINES_SUCCESS: {
      return {
        object: action.machines,
        loading: false
      };
    }
    case dashboardConstants.GET_DASHBOARD_MACHINES_FAILURE:
      return {
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
