import moment from 'moment';
import { getComponentTypeShortHand } from '../constants/assetHierarchy.constants';

export const paginateAndFetchChartData = (params, callBackFn, tags, fetchDataFn) => {
  if (params.days && params.days < 365) {
    return fetchDataFn(params, false, callBackFn);
  }
  const dateRanges = [];
  let dayFrom; let dayTo;
  if (params.days) {
    let last_measurement_time = tags.map(t => t.last_measurement_time).reduce((a, b) => (a > b ? a : b));
    last_measurement_time = last_measurement_time || moment.utc();
    dayFrom = moment(last_measurement_time).subtract(params.days, 'days').startOf('day');
    dayTo = moment(last_measurement_time);
  } else if (params.from_timestamp && params.to_timestamp) {
    dayFrom = moment(params.from_timestamp);
    dayTo = moment(params.to_timestamp);
  } else {
    const created_at = tags.map(t => t.created_at).reduce((a, b) => (a < b ? a : b));
    let last_measurement_time = tags.map(t => t.last_measurement_time).reduce((a, b) => (a > b ? a : b));
    last_measurement_time = last_measurement_time || moment.utc();
    dayFrom = moment(created_at);
    dayTo = moment(last_measurement_time);
  }
  while (dayTo.diff(dayFrom, 'months') > 3) {
    dateRanges.push({
      to_timestamp: dayTo.toDate(),
      from_timestamp: dayTo.subtract(3, 'months').toDate()
    });
  }
  dateRanges.push({
    from_timestamp: dayFrom.toDate(),
    to_timestamp: dayTo.toDate()
  });

  const uniqueKey = Math.random().toString().slice(2);
  const promises = [];
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const [i, range] of dateRanges.entries()) {
    promises.push(fetchDataFn({
      ...params,
      from_timestamp: range.from_timestamp,
      to_timestamp: range.to_timestamp
    }, true, i === 0 ? callBackFn : () => {}, dateRanges.length, uniqueKey));
  }

  return Promise.all(promises);
};


export const getShaftSpeed = (spectrumShaftSpeed, useSpectrumShaftSpeed, componentNode, measurementTimestamp, initialXUnit, spectrumFeatures) => {
  let shaft_speed = spectrumShaftSpeed;
  if (useSpectrumShaftSpeed) return shaft_speed;

  if (spectrumFeatures) {
    const Marker1X = spectrumFeatures.items.find(
      feature => feature.marker === `1${componentNode ? getComponentTypeShortHand(componentNode.node_details.component_type) : 'X'}`
    );
    if (Marker1X) shaft_speed = Marker1X.frequency;
  }

  if (componentNode && measurementTimestamp) {
    const measurementTimestampInISO = moment(measurementTimestamp).toISOString();
    const componentLocalMarkers = getComponentLocalCustomMarkers(componentNode);

    if (componentLocalMarkers && componentLocalMarkers[measurementTimestampInISO]) {
      const newPeakValue = componentLocalMarkers[measurementTimestampInISO].speed;
      shaft_speed = initialXUnit === 'CPM' ? newPeakValue * 60 : newPeakValue;
    }
  }
  return shaft_speed;
};

const getComponentLocalCustomMarkers = (componentNode) => {
  const localCustomMarkers = JSON.parse(localStorage.getItem('custom_markers'));
  if (!componentNode || !localCustomMarkers || !componentNode.id) return null;
  return localCustomMarkers[componentNode.id];
};
