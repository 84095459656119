import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Search } from '../../images/FaIcons';
import InputField from '../atoms/InputField';
import Button_T from '../atoms/Button';
import FullListModal from '../../../home/Settings/EventDefinitions/FullListModal';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: ${props => props.zIndex ? (props.zIndex - 1) : 2};
  ${props => props.noOverlay && 'display: none;'}
`;
const Button = styled(Button_T)`
  margin: 1em;
`;

const InlineModalContent = styled.div`
  position: absolute;
  ${props => props.alignVertical === 'top' && 'bottom: 100%;'}
  ${props => props.alignVertical === 'bottom' && 'top: 100%;'}
  ${props => props.alignHorizontal === 'left' && 'left: 0;'}
  ${props => props.alignHorizontal === 'right' && 'right: 0;'}
  ${props => props.alignHorizontal === 'center' && `
    left: 0;
    right: 0;
    margin: auto;
  `}
  ${props => props.marginTop && `margin-top: ${props.marginTop}`}
  min-width: ${props => props.minWidthInline ? props.minWidthInline : '120px'};
  border-radius: 4px;
  z-index: ${props => props.zIndex ? props.zIndex : 5};
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 0 8px 0 rgba(60,61,58,0.24);
`;

const ScrollContainer = styled.div`
 ${props => props.noScroll || 'max-height: 300px;overflow-y: auto;'}
  overflow-x: visible;
  ${props => props.minWidth && `min-width: ${props.minWidth}`};
  ${props => props.width && `width: ${props.width}`};
`;

const DropdownSearch = styled(InputField)`
  margin: 1em 1em 0 1em;
  margin-bottom: 8px;
  height: 32px;
  width: auto;
  font-size: 12px;
  font-weight: 600;
  input {
    padding-left: 0;
    width: 80%;
  }
`;


class InlineModal extends React.Component {
  constructor() {
    super();
    this.state = {
      openModal: false
    };
  }

  render() {
    const {
      alignVertical,
      alignHorizontal,
      close,
      children,
      onChangeSearch,
      searchValue,
      viewAllItems,
      selectedItems,
      selectGivenItems,
      itemName,
      zIndex,
      noOverlay,
      autoFocusSearch,
      ...rest
    } = this.props;
    return (
      <Fragment>
        <InlineModalContent
          alignVertical={alignVertical}
          alignHorizontal={alignHorizontal}
          zIndex={zIndex}
          {...rest}
        >
          {onChangeSearch && (
            <DropdownSearch
              type="text"
              prefix={<Search />}
              prefixSide="left"
              onChange={e => onChangeSearch(e.target.value)}
              value={searchValue}
              autoFocus={autoFocusSearch}
            />
          )
          }
          <ScrollContainer
            minWidth={rest.minWidth}
            width={rest.width}
            noScroll={rest.noScroll}
          >
            {children}
          </ScrollContainer>
          {selectGivenItems && (
            <Button text onClick={() => this.setState({ openModal: true })}>
              View all {itemName}s
            </Button>
          )}
          {this.state.openModal && (
            <FullListModal
              selectedOptions={selectedItems}
              optionsAvailable={viewAllItems}
              resourceType={itemName}
              addGivenResources={selectGivenItems}
              close={() => this.setState({ openModal: false })}
              title={`All ${itemName}s`}
              selectAll={selectedItems.length === viewAllItems.length}
              selectSome={selectedItems.length > 0}
            />
          )}
        </InlineModalContent>
        <Overlay className="modal-overlay" onClick={close} zIndex={zIndex} noOverlay={noOverlay} />
      </Fragment>
    );
  }
}

InlineModal.propTypes = {
  alignVertical: PropTypes.oneOf(['top', 'bottom']),
  alignHorizontal: PropTypes.oneOf(['left', 'center', 'right']),
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  noScroll: PropTypes.bool,
  autoFocusSearch: PropTypes.bool
};

InlineModal.defaultProps = {
  alignVertical: 'bottom',
  alignHorizontal: 'right',
  noScroll: false,
  autoFocusSearch: false
};

InlineModal.displayName = 'InlineModal';

export default styled(InlineModal)``;
