import { axiosInstance, ENDPOINT } from 'common/constants';
import { formatChartData, convertTimestringsToDateObjects } from 'common/utils';
import { handleResponse } from 'common/helpers';
import { OVERALL_ALARM_ACTIONS, BASE_GRAPH_OPTIONS } from '../constants/overallAlarm.constants';
import { getSelectedPathFromBreadcrumb } from '../utils/assetHierarchyUtils';


export const getTagTrend = (tag_id, params, bins) => {
  const request = (site_id, machine_id, tag_id) => ({
    type: OVERALL_ALARM_ACTIONS.GET_TAG_TREND_REQUEST,
    payload: {
      machine_id,
      site_id,
      tag_id
    }
  });
  const success = (site_id, machine_id, tag_id, packet) => ({
    type: OVERALL_ALARM_ACTIONS.GET_TAG_TREND_SUCCESS,
    payload: {
      packet,
      machine_id,
      site_id,
      tag_id
    }
  });
  const failure = (site_id, machine_id, tag_id, error) => ({
    type: OVERALL_ALARM_ACTIONS.GET_TAG_TREND_FAILURE,
    payload: {
      error,
      machine_id,
      site_id,
      tag_id
    }
  });

  return (dispatch, getState) => {
    const { site_id, machine_id } = getSelectedPathFromBreadcrumb(getState().breadcrumb);
    dispatch(request(site_id, machine_id, tag_id));
    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_TREND(tag_id), { params })).then(
      (res) => {
        let bin_0_count_limit = null;
        if (params.base_graph_type === BASE_GRAPH_OPTIONS.LAST_READINGS) {
          bin_0_count_limit = params.base_graph_type_val;
        }
        const packet = splitAndFormatTrendsData(res.trend_data, bins, bin_0_count_limit);
        dispatch(success(site_id, machine_id, tag_id, packet));
        return packet;
      },
      (error) => {
        dispatch(failure(site_id, machine_id, tag_id, error));
        throw error;
      }
    );
  };
};

const splitAndFormatTrendsData = (trend_data, bins, bin_0_count_limit) => {
  const splitData = {};
  bins.forEach((bin) => {
    let data = [];
    let time = [];
    let speed = [];
    if (bin === 0) {
      data = trend_data.data;
      time = trend_data.time;
      speed = trend_data.speed || [];
      if (bin_0_count_limit) {
        const slice_value = Math.max(trend_data.data.length - bin_0_count_limit, 0);
        data = data.slice(slice_value);
        time = time.slice(slice_value);
        speed = speed.slice(slice_value);
      }
    } else if (trend_data.speed_bins) {
      trend_data.speed_bins.forEach((point, idx) => {
        if (point === bin) {
          data.push(trend_data.data[idx]);
          time.push(trend_data.time[idx]);
          if (trend_data.speed) speed.push(trend_data.speed[idx]);
        }
      });
    }
    splitData[bin] = formatChartData(
      data,
      convertTimestringsToDateObjects(time),
      null,
      { speed }
    );
  });
  return splitData;
};
