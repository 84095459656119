export const sensorsConstants = {
  GET_MOTE_LIST_REQUEST: 'GET_MOTE_LIST_REQUEST',
  GET_MOTE_LIST_SUCCESS: 'GET_MOTE_LIST_SUCCESS',
  GET_MOTE_LIST_FAILURE: 'GET_MOTE_LIST_FAILURE',

  GET_DEVICE_LOGS_DATA_REQUEST: 'GET_DEVICE_LOGS_DATA_REQUEST',
  GET_DEVICE_LOGS_DATA_SUCCESS: 'GET_DEVICE_LOGS_DATA_SUCCESS',
  GET_DEVICE_LOGS_DATA_FAILURE: 'GET_DEVICE_LOGS_DATA_FAILURE',

  GET_DEVICE_LAST_REPORTED_INFO_REQUEST: 'GET_DEVICE_LAST_REPORTED_INFO_REQUEST',
  GET_DEVICE_LAST_REPORTED_INFO_SUCCESS: 'GET_DEVICE_LAST_REPORTED_INFO_SUCCESS',
  GET_DEVICE_LAST_REPORTED_INFO_FAILURE: 'GET_DEVICE_LAST_REPORTED_INFO_FAILURE',
  // For new APIs
  GET_SENSORS_LIST_REQUEST: 'GET_SENSORS_LIST_REQUEST',
  GET_SENSORS_LIST_SUCCESS: 'GET_SENSORS_LIST_SUCCESS',
  GET_SENSORS_LIST_FAILURE: 'GET_SENSORS_LIST_FAILURE',

  GET_UNASSOCIATED_SENSORS_LIST_REQUEST: 'GET_UNASSOCIATED_SENSORS_LIST_REQUEST',
  GET_UNASSOCIATED_SENSORS_LIST_SUCCESS: 'GET_UNASSOCIATED_SENSORS_LIST_SUCCESS',
  GET_UNASSOCIATED_SENSORS_LIST_FAILURE: 'GET_UNASSOCIATED_SENSORS_LIST_FAILURE',

  GET_SENSORS_DETAILS_REQUEST: 'GET_SENSORS_DETAILS_REQUEST',
  GET_SENSORS_DETAILS_SUCCESS: 'GET_SENSORS_DETAILS_SUCCESS',
  GET_SENSORS_DETAILS_FAILURE: 'GET_SENSORS_DETAILS_FAILURE',

  SET_SENSOR_DETAILS_TO_INITIAL_STATE: 'SET_SENSOR_DETAILS_TO_INITIAL_STATE',

  GET_SENSORS_METADATA_SUCCESS: 'GET_SENSORS_METADATA_SUCCESS',
  GET_SENSORS_METADATA_FAILURE: 'GET_SENSORS_METADATA_FAILURE',
  GET_SENSORS_METADATA: 'GET_SENSORS_METADATA',

  GET_FILTER_RESOURCE_SUCCESS: 'GET_FILTER_RESOURCE_SUCCESS',

  ADD_SENSOR_TO_LIST: 'ADD_SENSOR_TO_LIST',

  GET_COMPONENT_LIST_SUCCESS: 'GET_COMPONENT_LIST_SUCCESS',
  GET_LOCATION_LIST_SUCCESS: 'GET_LOCATION_LIST_SUCCESS',

  UPDATE_DETAILS_REQUEST: 'UPDATE_DETAILS_REQUEST',
  UPDATE_DETAILS_SUCCESS: 'UPDATE_DETAILS_SUCCESS',
  UPDATE_DETAILS_FAILURE: 'UPDATE_DETAILS_FAILURE',

  UPDATE_SERIAL_NUMBER_REQUEST: 'UPDATE_SERIAL_NUMBER_REQUEST',
  UPDATE_SERIAL_NUMBER_SUCCESS: 'UPDATE_SERIAL_NUMBER_SUCCESS',
  UPDATE_SERIAL_NUMBER_FAILURE: 'UPDATE_SERIAL_NUMBER_FAILURE',

  DELETE_SENSOR_REQUEST: 'DELETE_SENSOR_REQUEST',
  DELETE_SENSOR_FAILURE: 'DELETE_SENSOR_FAILURE',

  SET_SENSORS_SORTER: 'SET_SENSORS_SORTER',

  SET_SENSORS_FILTERS: 'SET_SENSORS_FILTERS',

  INCREASE_PAGE: 'INCREASE_PAGE',

  SELECT_SENSOR: 'SELECT_SENSOR',

  SELECT_GIVEN_SENSOR: 'SELECT_GIVEN_SENSOR',

  CLEAR_SELECTED_SENSOR: 'CLEAR_SELECTED_SENSOR',

  CLEAR_SENSOR_DETAILS: 'CLEAR_SENSOR_DETAILS',

  CLEAR_SENSORS_LIST: 'CLEAR_SENSORS_LIST',

  GET_UNASSIGNED_SENSORS_REQUEST: 'GET_UNASSIGNED_SENSORS_REQUEST',
  GET_UNASSIGNED_SENSORS_SUCCESS: 'GET_UNASSIGNED_SENSORS_SUCCESS',
  GET_UNASSIGNED_SENSORS_FAILURE: 'GET_UNASSIGNED_SENSORS_FAILURE',
  //
  GET_TX_LIST_REQUEST: 'GET_TX_LIST_REQUEST',
  GET_TX_LIST_SUCCESS: 'GET_TX_LIST_SUCCESS',
  GET_TX_LIST_FAILURE: 'GET_TX_LIST_FAILURE',

  ASSIGN_SENSORS_TO_TX: 'ASSIGN_SENSORS_TO_TX',
  ASSIGN_SENSORS_TO_TX_REQUEST: 'ASSIGN_SENSORS_TO_TX_REQUEST',
  ASSIGN_SENSORS_TO_TX_SUCCESS: 'ASSIGN_SENSORS_TO_TX_SUCCESS',
  ASSIGN_SENSORS_TO_TX_FAILURE: 'ASSIGN_SENSORS_TO_TX_FAILURE',

  GET_OTHERS_LIST_REQUEST: 'GET_OTHERS_LIST_REQUEST',
  GET_OTHERS_LIST_SUCCESS: 'GET_OTHERS_LIST_SUCCESS',
  GET_OTHERS_LIST_FAILURE: 'GET_OTHERS_LIST_FAILURE',

  CLEAR_VIBRATION_MOTE_LIST: 'CLEAR_VIBRATION_MOTE_LIST',
  CLEAR_TX_LIST: 'CLEAR_TX_LIST',
  CLEAR_OTHERS_LIST: 'CLEAR_OTHERS_LIST',

  SET_SENSORS_QUERY: 'SET_SENSORS_QUERY',
  SET_SENSORS_ACTIVE_TAB: 'SET_SENSORS_ACTIVE_TAB',

  SET_VIBRATION_MOTE_PAGINATER: 'SET_VIBRATION_MOTE_PAGINATER',
  SET_TX_PAGINATER: 'SET_TX_PAGINATER',
  SET_OTHERS_PAGINATER: 'SET_OTHERS_PAGINATER',

  SET_VIBRATION_MOTE_SORTER: 'SET_VIBRATION_MOTE_SORTER',
  SET_TX_SORTER: 'SET_TX_SORTER',
  SET_OTHERS_SORTER: 'SET_OTHERS_SORTER',

  NUMBER_OF_SENSORS_PER_PAGE: 80,

  CREATE_SENSOR_REQUEST: 'CREATE_SENSOR_REQUEST',
  CREATE_SENSOR_SUCCESS: 'CREATE_SENSOR_SUCCESS',
  CREATE_SENSOR_FAILURE: 'CREATE_SENSOR_FAILURE',
};
