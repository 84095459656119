import styled from 'styled-components';

const MainColumn = styled.div`
  width: ${props => props.width || '75%'};
  flex-grow: 0;
  /* height: inherit; */
  border: 1px solid #D8DCD3;
  border-top: none;
  border-bottom: ${props => props.borderBottom ? props.borderBottom : '1px solid #D8DCD3'};
  border-right: ${props => props.borderRight ? props.borderRight : '1px solid #D8DCD3'};
  padding: 20px 26px 60px;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #E5E8E1;
  }
`;

export default styled(MainColumn)``;
