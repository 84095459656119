import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse } from 'common/helpers';
import * as _ from 'lodash';
import { toastr } from 'react-redux-toastr';

import { formatChartData, convertTimestringsToDateObjects } from 'common/utils';
import { formatError } from '../../../common/utils';
import MachineDiagnosticTestRunTypes from '../../Machines/MachineDetails/Health/actions/DiagnosticTestRun.types';
import MachineDiagnosticsConstants from '../constants/machineDiagnostics.constants';


export const getRuleGeneratorMetadata = () => {
  const request = () => ({ type: MachineDiagnosticsConstants.GET_RULES_METADATA_REQUEST });
  const success = data => ({ type: MachineDiagnosticsConstants.GET_RULES_METADATA_SUCCESS, data });
  const failure = error => ({ type: MachineDiagnosticsConstants.GET_RULES_METADATA_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.RULES_METADATA)).then(
      (res) => {
        dispatch(success(res));
        return res;
      },
      (error) => {
        dispatch(failure(error.message));
        throw error.message;
      }
    );
  };
};

export const getRulesResultList = (params) => {
  const request = () => ({ type: MachineDiagnosticsConstants.GET_RULES_RESULT_LIST_REQUEST });
  const success = data => ({ type: MachineDiagnosticsConstants.GET_RULES_RESULT_LIST_SUCCESS, data });
  const failure = error => ({ type: MachineDiagnosticsConstants.GET_RULES_RESULT_LIST_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.RULES_RESULT(), { params })).then(
      (res) => {
        dispatch(success(res.items));
        return res.items;
      },
      (error) => {
        dispatch(failure(error.message));
        throw error.message;
      }
    );
  };
};

export const setTestResultSorter = sorter => (dispatch) => {
  dispatch({ type: MachineDiagnosticsConstants.SET_RULES_SORTER, sorter });
  dispatch({ type: MachineDiagnosticsConstants.SET_RULES_PAGINATER, page: 1 });
  dispatch(getRuleTestResults());
};

export const increaseTestRulePaginator = () => (dispatch, getState) => {
  const machineDiagnostics = getState().machineDiagnostics;
  const { paginater } = machineDiagnostics;

  dispatch(setTestResultPaginater(paginater.page + 1));
};


export const setTestResultPaginater = page => (dispatch) => {
  dispatch({ type: MachineDiagnosticsConstants.SET_RULES_PAGINATER, page });
  dispatch(getRuleTestResults());
};

export const updateRuleResultState = (params, id) => {
  const request = () => ({ type: MachineDiagnosticsConstants.UPDATE_RULE_RESULT_STATE_REQUEST });
  const success = () => ({ type: MachineDiagnosticsConstants.UPDATE_RULE_RESULT_STATE_SUCCESS, id, state: params.false_positive ? null : 'open' });
  const failure = error => ({ type: MachineDiagnosticsConstants.UPDATE_RULE_RESULT_STATE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.patch(ENDPOINT.RULES_RESULT(id), { ...params })).then(
      (res) => {
        dispatch(success());
        return res;
      },
      (error) => {
        dispatch(failure(error.message));
        throw error;
      }
    );
  };
};

export const getRuleDetails = (rule_id) => {
  const request = () => ({ type: MachineDiagnosticsConstants.GET_RULE_DETAILS_REQUEST });
  const success = data => ({ type: MachineDiagnosticsConstants.GET_RULE_DETAILS_SUCCESS, data });
  const failure = error => ({ type: MachineDiagnosticsConstants.GET_RULE_DETAILS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.RULES(rule_id))).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        toastr.error(formatError(error.message));
        dispatch(failure(error.message));
      }
    );
  };
};

export const saveRule = (params) => {
  const request = () => ({ type: MachineDiagnosticsConstants.SAVE_RULE_DETAILS_REQUEST });
  const success = data => ({ type: MachineDiagnosticsConstants.SAVE_RULE_DETAILS_SUCCESS, data });
  const failure = error => ({ type: MachineDiagnosticsConstants.SAVE_RULE_DETAILS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.post(ENDPOINT.RULES(), { ...params, assets: params.assets.map(x => x.id) })).then(
      (res) => {
        dispatch(success(params));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        toastr.error(formatError(error.message));
        throw error;
      }
    );
  };
};

export const getParameterTrend = (result_id, params) => {
  const request = () => ({ type: MachineDiagnosticsConstants.GET_PARAMETERS_TREND_REQUEST });
  const success = data => ({ type: MachineDiagnosticsConstants.GET_PARAMETERS_TREND_SUCCESS, data });
  const failure = error => ({ type: MachineDiagnosticsConstants.GET_PARAMETERS_TREND_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    handleResponse(axiosInstance.get(ENDPOINT.PARAMETERS_TREND(result_id), { params })).then(
      (res) => {
        const trends_config = res.parameters.map(d => ({
          ...d,
          parameter_name: d.name,
          baseline: d.baseline,
          trend_data: formatChartData(
            d.data,
            convertTimestringsToDateObjects(d.timestamp),
            {}
          )
        }));
        dispatch(success(trends_config));
      },
      (error) => {
        dispatch(failure(error.message));
      }
    );
  };
};

export const updateRule = (params, id) => {
  const request = () => ({ type: MachineDiagnosticsConstants.UPDATE_RULE_DETAILS_REQUEST });
  const success = data => ({ type: MachineDiagnosticsConstants.UPDATE_RULE_DETAILS_SUCCESS, data, id });
  const failure = error => ({ type: MachineDiagnosticsConstants.UPDATE_RULE_DETAILS_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    const { details } = getState().machineDiagnostics.rules;
    const changedDetails = {};
    Object.entries(params).forEach(([key, value]) => {
      if (!_.isEqual(details[key], value)) changedDetails[key] = value;
    });
    return handleResponse(axiosInstance.patch(ENDPOINT.RULES(id), {
      ...changedDetails,
      ...(changedDetails.assets && { assets: changedDetails.assets.map(x => x.id) })
    })).then(
      (res) => {
        dispatch(success(changedDetails));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        throw error;
      }
    );
  };
};

export const deleteRule = (rule_id) => {
  const request = () => ({ type: MachineDiagnosticsConstants.DELETE_RULE_REQUEST });
  const success = () => ({ type: MachineDiagnosticsConstants.DELETE_RULE_SUCCESS });
  const failure = error => ({ type: MachineDiagnosticsConstants.DELETE_RULE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.delete(ENDPOINT.RULES(rule_id))).then(
      (res) => {
        dispatch(success(res));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        throw error;
      }
    );
  };
};

export const selectRule = id => (dispatch) => {
  dispatch({ type: MachineDiagnosticsConstants.SELECT_RULE, id });
};

export const addNewRuleToList = () => (dispatch) => {
  dispatch({ type: MachineDiagnosticsConstants.ADD_NEW_RULE_TO_LIST });
  dispatch({ type: MachineDiagnosticsConstants.SELECT_RULE, id: -1 });
};

export const addRuleToList = data => (dispatch) => {
  dispatch({ type: MachineDiagnosticsConstants.ADD_RULE_TO_LIST, data });
};

export const removeRuleFromList = id => dispatch =>
  dispatch({ type: MachineDiagnosticsConstants.REMOVE_RULE_FROM_LIST, id });

export const initRulesPaginater = () => (dispatch) => {
  dispatch({ type: MachineDiagnosticsConstants.CLEAR_RULES });
  dispatch({ type: MachineDiagnosticsConstants.CLEAR_RULES_SEARCH_PARAMS });
  dispatch(setRulePaginater(1));
};

export const setRulePaginater = page => (dispatch) => {
  dispatch({ type: MachineDiagnosticsConstants.SET_RULES_PAGINATER, page });
  dispatch(getRules());
};

export const setRulesSearchParams = searchParams => (dispatch) => {
  dispatch({
    type: MachineDiagnosticsConstants.SET_RULES_SEARCH_PARAMS,
    searchParams
  });
  dispatch({
    type: MachineDiagnosticsConstants.SET_RULES_PAGINATER,
    page: 1
  });
  dispatch(getRules());
};

export const setRuleSorter = sorter => (dispatch) => {
  dispatch({ type: MachineDiagnosticsConstants.SET_RULES_SORTER, sorter });
  dispatch({ type: MachineDiagnosticsConstants.CLEAR_RULES });
  dispatch({ type: MachineDiagnosticsConstants.SET_RULES_PAGINATER, page: 1 });

  dispatch(getRules());
};

const getRules = () => {
  const request = () => ({
    type: MachineDiagnosticsConstants.GET_RULES_LIST_REQUEST
  });
  const success = (data, page, total_count) => ({
    type: MachineDiagnosticsConstants.GET_RULES_LIST_SUCCESS,
    data,
    page,
    total_count
  });
  const failure = error => ({
    type: MachineDiagnosticsConstants.GET_RULES_LIST_FAILURE,
    error
  });

  return (dispatch, getState) => {
    dispatch(request());
    const machineDiagnostics = getState().machineDiagnostics;
    const prevSorter = machineDiagnostics.sorter;
    const { paginater } = machineDiagnostics;
    const { searchParams } = machineDiagnostics;

    const order_by = { field: 'name', direction: 'asc' };
    if (prevSorter && prevSorter.sorter && prevSorter.sorter.order) {
      order_by.field = prevSorter.sorter.name;
      order_by.direction = prevSorter.sorter.order;
    }

    const params = {
      page: paginater.page,
      per_page: MachineDiagnosticsConstants.NUMBER_OF_RULES_PER_PAGE,
      order_by,
      search_key: searchParams.searchKey,
      resource_version: 3
    };

    if (searchParams) {
      params.filters = [];
      let filterKeyName = '';
      Object.keys(searchParams).forEach((filterKey) => {
        if (filterKey === 'searchKey') return;
        if (searchParams[filterKey].length > 0) {
          if (filterKey === 'assetType') filterKeyName = 'asset_type';
          else if (filterKey === 'failureMode') filterKeyName = 'failure_mode';
          else filterKeyName = filterKey;
          const filter = {
            name: filterKeyName,
            op: 'in',
            value: searchParams[filterKey].map((filterValue) => {
              if (filterKey === 'component_type') {
                return filterValue;
              }
              if (filterKey === 'machine') {
                return filterValue;
              }
              return filterValue.value;
            })
          };
          params.filters.push(filter);
        }
      });
    }

    return handleResponse(axiosInstance.get(ENDPOINT.RULES(), { params })).then(
      (res) => {
        dispatch(success(res.items, paginater.page, res.total_count));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

export const getRuleTestResults = (params = {}) => {
  const request = () => ({ type: MachineDiagnosticTestRunTypes.DIAGNOSTIC_RULES_TEST_RUN_RESULT_REQUEST });
  const success = data => ({
    type: MachineDiagnosticTestRunTypes.DIAGNOSTIC_RULES_TEST_RUN_RESULT_SUCCESS,
    data
  });
  const failure = error => ({ type: MachineDiagnosticTestRunTypes.DIAGNOSTIC_RULES_TEST_RUN_RESULT_FAILURE, error });
  const update = (rule_test_info, machine_id) => ({ type: MachineDiagnosticTestRunTypes.UPDATE_RULES_TEST_RUN_TIME_SUCCESS, rule_test_info, machine_id });

  return (dispatch, getState) => {
    dispatch(request());
    const machineDiagnostics = getState().machineDiagnostics;
    const machineDetails = getState().machineDetails;
    const prevSorter = machineDiagnostics.sorter;
    const { paginater } = machineDiagnostics;
    const order_by = { field: 'name', direction: 'asc' };

    if (prevSorter && prevSorter.sorter && prevSorter.sorter.order) {
      order_by.field = prevSorter.sorter.name;
      order_by.direction = prevSorter.sorter.order;
    }

    params.order_by = order_by;
    params.per_page = MachineDiagnosticsConstants.NUMBER_OF_RULES_PER_PAGE;
    params.page = paginater.page;

    if (!_.isEmpty(machineDetails.DiagnosticTestRun.test_run_time)) {
      params.machine_id = machineDetails.DiagnosticTestRun.test_run_time.machine_id;
    }

    return handleResponse(axiosInstance.get(ENDPOINT.RULE_TEST_RUN(), { params }).then(
      (res) => {
        dispatch(success(res.data));
        dispatch(update(res.data.rule_test_info));
      },
      (error) => {
        dispatch(failure(error));
      }
    ));
  };
};
