import { axiosInstance, ENDPOINT } from '../../../common/constants';
import { handleResponse } from '../../../common/helpers';

export const machineDetailsService = {
  getMachineDetails,
  getMachineTags,
  getTagTrend,
  getTagWaveform,
  getTagSpectrum,
  updateMachineDetails,
  addNote,
  getSpectrumFeatures,
  createSpectrumFeature,
  updateSpectrumFeature,
  deleteSpectrumFeature,
  getMachineAttachments,
  postMachineMarkasAnalyze
};

function getMachineDetails(machineId, params) {
  return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_DETAILS(machineId), { params }));
}

function postMachineMarkasAnalyze(machineId, params) {
  return handleResponse(axiosInstance.post(ENDPOINT.MARK_AS_ANALYSED(machineId), { ...params }));
}
function updateMachineDetails(machineId, params) {
  return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_MACHINE_INFO(machineId), params));
}

function getMachineTags(machineId) {
  return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_TAGS(machineId)));
}

function getTagTrend(tagId, params) {
  return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_TREND(tagId), { params }));
}

function getTagWaveform(tagId, params) {
  return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_WAVEFORM(tagId), { params }));
}

function getTagSpectrum(tagId, params) {
  return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUM(tagId), { params }));
}
function getMachineAttachments(machineId, params) {
  return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_ATTACHMENTS(machineId), { params }));
}

function addNote(params) {
  return handleResponse(axiosInstance.post(ENDPOINT.ADD_NOTE, params));
}

// Spectrum features service
function getSpectrumFeatures(tagId) {
  return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUM_FEATURES(tagId)));
}

function createSpectrumFeature(params) {
  return handleResponse(axiosInstance.post(ENDPOINT.CREATE_TAG_SPECTRUM_FEATURES, params));
}

function updateSpectrumFeature(featureId, params) {
  return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_TAG_SPECTRUM_FEATURES(featureId), params));
}

function deleteSpectrumFeature(featureId) {
  return handleResponse(axiosInstance.delete(ENDPOINT.UPDATE_TAG_SPECTRUM_FEATURES(featureId)));
}
