import React from 'react';
import { Link } from 'react-router-dom';
import StyledLink from 'common/components/atoms/Link';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import InputField from 'common/components/atoms/InputField';
import { Button } from 'common/components/atoms';
import {
  checkEmpty,
  checkEmail,
  renderInputField
} from 'common/OnboardingComponents/form-fields';

const Message = styled.div`
  color: #b3b3b3;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
`;
class LoginWithSSOForm extends React.Component {
  render() {
    const { errorMsg, useEmail, handleSubmit, toggleUseEmail } = this.props;
    return (
      <form name="form" onSubmit={handleSubmit}>
        <div className="form-group">
          {useEmail ? (
            <Field
              name="email"
              component={renderInputField}
              type="text"
              validate={[checkEmpty, checkEmail]}
              label="Email"
            />) : (
            <>
              <Field
                name="slug"
                component={renderInputField}
                type="text"
                validate={[checkEmpty]}
                label="Workspace Slug"
              />
              <Message>Your slug can be found under site settings.</Message>
            </>)
          }
          <InputField
            type="checkbox"
            label="Use Email to login"
            medium
            labelSide="right"
            onChange={toggleUseEmail}
            value={useEmail}
          />

          <Button style={{ width: '100%' }}>Submit</Button>
          <div className="warning-msg red">
              {errorMsg}
          </div>
        </div>
        <StyledLink to="/login">Log in without SSO</StyledLink>
      </form>
    );
  }
}

const createReduxForm = reduxForm({
  form: 'LoginWithSSO',
  enableReinitialize: true
});

export default createReduxForm(LoginWithSSOForm);
