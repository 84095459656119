import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const DuplicateSvg = ({ width, height, fill }) => (
  <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 16 16">
    <title>AF27595A-9CFC-49F9-B4B6-BB8A141D73BC</title>
    <g id="Machines/Add" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1-1-0-Machines/Add" transform="translate(-245.000000, -244.000000)">
        <g id="edit_asset_hierarchy">
          <g id="more_dd" transform="translate(225.000000, 152.000000)">
            <g id="3" transform="translate(20.000000, 92.000000)">
              <g id="duplicate-icon">
                <rect id="bounds" x="0" y="0" width="16" height="16" />
                <g id="icon" fill={fill} fillRule="nonzero">
                  <path d="M11.52,0 L0.64,0 C0.28671875,0 0,0.28671875 0,0.64 L0,11.52 C0,11.8732812 0.28671875,12.16 0.64,12.16 L11.52,12.16 C11.8732812,12.16 12.16,11.8732812 12.16,11.52 L12.16,0.64 C12.16,0.28671875 11.8732812,0 11.52,0 Z M10.88,10.88 L1.28,10.88 L1.28,1.28 L10.88,1.28 L10.88,10.88 L10.88,10.88 Z" id="Shape" />
                  <path d="M14.72,13.12 L14.72,14.72 L13.12,14.72 L13.12,16 L15.36,16 C15.7132812,16 16,15.7132812 16,15.36 L16,13.12 L14.72,13.12 Z" id="Path" />
                  <rect id="Rectangle" x="8.19840625" y="14.72" width="3.4431875" height="1.28" />
                  <path d="M5.12,14.72 L5.12,13.12 L3.84,13.12 L3.84,15.36 C3.84,15.7132812 4.12671875,16 4.48,16 L6.72,16 L6.72,14.72 L5.12,14.72 Z" id="Path" />
                  <path d="M15.36,3.84 L13.12,3.84 L13.12,5.12 L14.72,5.12 L14.72,6.72 L16,6.72 L16,4.48 C16,4.12671875 15.7132812,3.84 15.36,3.84 Z" id="Path" />
                  <rect id="Rectangle" x="14.72" y="8.19840625" width="1.28" height="3.4431875" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

DuplicateSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

DuplicateSvg.defaultProps = {
  width: 12,
  height: 12,
  fill: '#737473',
};


export default styled(DuplicateSvg)``;
