import React from 'react';
import PropTypes from 'prop-types';

const UnlockedZoomSvg = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/Svg">
    <g id="Machine/Charts" fill="none" fillRule="evenodd">
        <g id="1-6-0-Machine-Charts-Expanded" transform="translate(-1028 -391)">
            <g id="charts" transform="translate(80 212)">
                <g id="1">
                    <g id="trend" transform="translate(0 16)">
                        <g id="graph" transform="translate(20 40)">
                            <g id="x-axis" transform="translate(68 123)">
                                <g id="unlocked_zoom-icon" transform="translate(860)">
                                    <rect id="btn-bg" fill={fill} width="16" height="16" />
                                    <g
                                      id="icon"
                                      transform="translate(3 3)"
                                      stroke="#91B846"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                    >
                                        <path
                                          d="M0.3125,4.0625 L0.3125,2.5 C0.3125,1.29187711 1.29187711,0.3125 2.5,0.3125 C3.70812289,0.3125 4.6875,1.29187711 4.6875,2.5 L4.6875,4.0625"
                                          id="Shape"
                                        />
                                        <rect
                                          id="Rectangle-path"
                                          x="2.813"
                                          y="4.063"
                                          width="6.875"
                                          height="5.625"
                                          rx="1.5"
                                        />
                                        <path
                                          d="M6.28125,6.56250011 C6.17769661,6.56250011 6.09375,6.64644672 6.09375,6.75000011 C6.09375,6.8535535 6.17769661,6.93750011 6.28125,6.93750011 C6.38480339,6.93750011 6.46875,6.8535535 6.46875,6.75000011 C6.46888325,6.70023129 6.44917164,6.65246247 6.41397964,6.61727047 C6.37878764,6.58207848 6.33101882,6.56236686 6.28125,6.56250011"
                                          id="Shape"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
);
UnlockedZoomSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

UnlockedZoomSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: 'none'
};
export default UnlockedZoomSvg;
