import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';
import CrossSvg_T from 'common/images/CrossSvg';
import Text from 'common/typography/Text/Text';

const AlertContainer = styled.div`
  padding : 10px 1% 10px 1%;
  border-radius: 4px;
  position: fixed;
  overflow: hidden;
  z-index: 3;
  top: 0.6%;
  left: 50%;
  transform: translate( -50%, 0%);
  background-color: ${props => props.backgroundColor}
`;
const CrossSvg = styled(CrossSvg_T)`
  position: absolute;
  top: 0;
  right: 0;
`;
const InfoTitle = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.color ? props.color : colors.white};
`;
const Banner = ({
  backgroundColor,
  primaryText,
  secondaryText,
  secondaryColor,
  close
}) => (
    <AlertContainer backgroundColor={backgroundColor}>
      <InfoTitle>{primaryText}</InfoTitle>
      {secondaryText && (
        <InfoTitle color={secondaryColor}>{secondaryText}</InfoTitle>
      )}
      {close && (<CrossSvg onClick={close} fill="white" />)}
    </AlertContainer>
);

Banner.propTypes = {
  backgroundColor: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  secondaryColor: PropTypes.string,
  close: PropTypes.func
};

Banner.defaultProps = {
  backgroundColor: colors.RedL,
  primaryText: '',
  secondaryText: '',
  secondaryColor: colors.black,
  close: null
};

export default Banner;
