import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import FlexContainer from 'common/components/atoms/FlexContainer';
import H2 from 'common/typography/H2/H2';
import SpeedRangeMultiSelect from 'common/components/molecules/SpeedRangeMultiSelect';
import { BASE_GRAPH_OPTIONS, MESSAGE } from '../../../constants/envelope.constants';

class EnvelopeAlarmVFDSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBins: [],
      error: ''
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.binSelectorError !== prevProps.binSelectorError) {
      this.setState({ error: this.props.binSelectorError });
    }
  }

  toggleOption = (value) => {
    const { base_graph_type } = this.props;
    const bins = this.state.selectedBins;
    let selectedBins = _.cloneDeep(bins);
    if (selectedBins.includes(value)) {
      selectedBins = _.filter(selectedBins, bin => bin !== value);
    } else selectedBins.push(value);

    if (base_graph_type === BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE) {
      let error = '';
      if (!_.isEmpty(selectedBins)) {
        error = MESSAGE.BASE_GRAPH_TYPE_CURRENTLY_VISIBLE + MESSAGE.CHANGE_BASE_GRAPH_SETTING;
      }
      this.setState({ error });
    }
    if (this.props.binSelectorError !== '') this.props.setBinSelectorError();
    this.setState({ selectedBins });
  };

  isCopySettingsDisabled = () => _.isEmpty(this.state.selectedBins) ||
    this.props.base_graph_type === BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE

  onClickCopySettings = () => {
    this.props.onBinChange(this.state.selectedBins);
    this.setState({ selectedBins: [] });
  }

  getAvailableOptions = () => {
    const { binFilterOptions, activeTabValue } = this.props;
    return binFilterOptions.filter(option => option.value !== activeTabValue);
  }

  render() {
    const { selectedBins, error } = this.state;
    return (
      <FlexContainer direction="column" marginright="2em">
        <H2>&nbsp;</H2>
        <SpeedRangeMultiSelect
          optionsAvailable={this.getAvailableOptions()}
          selectedOptions={selectedBins}
          toggleOption={this.toggleOption}
          error={error}
          onClickCopySettings={this.onClickCopySettings}
          disabledCopyButton={this.isCopySettingsDisabled()}
        />
      </FlexContainer>
    );
  }
}

EnvelopeAlarmVFDSettings.propTypes = {
  onBinChange: PropTypes.func.isRequired,
  binFilterOptions: PropTypes.array.isRequired,
  base_graph_type: PropTypes.string.isRequired,
  binSelectorError: PropTypes.string.isRequired,
  setBinSelectorError: PropTypes.func.isRequired,
  activeTabValue: PropTypes.number.isRequired
};

export default EnvelopeAlarmVFDSettings;
