import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DonutChart from 'common/charts/GoogleCharts/DonutChart';
import BarChart from 'common/charts/GoogleCharts/BarChart';
import WidgetChartContainer from 'common/components/molecules/WidgetChartContainer';
import LoadingSvg from 'common/images/LoadingSvg';
import Chart from 'react-google-charts';
import H3 from 'common/typography/H3/H3';
import SecondaryText from 'common/typography/SecondaryText/SecondaryText';
import { eventAnalyticsMetadata, widgetTypes } from '../constants/eventAnalytics.constants';
import * as eventAnalyticsActions from '../eventAnalytics.actions';

const ChartContainer = styled.div`
  background-color: white;
  height: 220px;
  border-radius: 4px;
`;

const EventAnalyticsWidget = (props) => {
  const eventAnalyticsState = props.eventAnalytics;
  const data = eventAnalyticsState[props.analytics_type];
  const metadata = eventAnalyticsMetadata(props.theme)[props.analytics_type];

  if (eventAnalyticsState.loading) {
    return (
      <>
        <WidgetChartContainer className="ps-align-center">
            <LoadingSvg />
        </WidgetChartContainer>
      </>
    );
  }
  if (eventAnalyticsState.total === 0 || !data) {
    return (
      <>
        <WidgetChartContainer className="ps-align-center">
            <SecondaryText>{metadata.title}</SecondaryText>
            <H3>No Event data to show</H3>
        </WidgetChartContainer>
      </>
    );
  }
  if (data.error) {
    return (
    <>
      <div>Error loading Account Analytics</div>
    </>
    );
  }
  return (
    <ChartContainer>
      {metadata.widgetType === widgetTypes.DONUT_CHART && (
        <DonutChart
          title={metadata.title}
          data={data}
          resourceName="Devices"
          colors={metadata.colors}
          formatData
          {...props}
        />
      )}
      {metadata.widgetType === widgetTypes.BAR_CHART && (
        <BarChart
          height={180}
          title={metadata.title}
          data={data}
          colors={metadata.colors}
          orientation="horizontal"
          chartArea={{ width: '80%', left: '15%' }}
          formatData
          {...props}
        />
      )}
      {metadata.widgetType === widgetTypes.HISTOGRAM && (
        <Chart
          chartType="Histogram"
          data={data}
          width="100%"
          height="100%"
          options={metadata}
        />
      )}
    </ChartContainer>
  );
};

const mapStateToProps = state => ({
  adminDashboard: state.adminDashboard,
  eventAnalytics: state.eventAnalytics
});

const mapDispatchToProps = dispatch => ({
  eventAnalyticsActions: bindActionCreators(eventAnalyticsActions, dispatch)
});

EventAnalyticsWidget.propTypes = {
  first: PropTypes.bool,
  titlePaddingBottom: PropTypes.string,
  analytics_type: PropTypes.string,
  showTitle: PropTypes.bool,
  filters: PropTypes.array
};

EventAnalyticsWidget.defaultProps = {
  first: false,
  titlePaddingBottom: null,
  analytics_type: null,
  showTitle: true,
  filters: []
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(EventAnalyticsWidget));
