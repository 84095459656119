import React, { Component } from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Chart from 'react-google-charts';

import AccessRestricted from 'common/components/AccessRestricted/AccessRestricted';
import FlexContainer from 'common/components/atoms/FlexContainer';
import RBAC from 'common/rbac/RBAC';
import AccountMenuItems from 'common/components/organisms/AccountMenuItems';
import MoreOptions from 'common/components/organisms/MoreOptions';
import MsgContainer from 'common/components/atoms/MsgContainer';
import RadioButton from 'common/components/molecules/Radiobutton';
import Checkbox from 'common/components/atoms/Checkbox';
import SearchFilters from 'common/components/atoms/SearchFilters';
import Filter from 'common/components/molecules/Filter';
import FilterV2 from 'common/components/molecules/FilterV2';
import { isFilterApplied } from 'common/utils';
import Button from 'common/components/atoms/Button';
import H3_T from 'common/typography/H3/H3';
import InputField from 'common/components/atoms/InputField';
import { mapDayToString } from 'common/constants';
import OutsideAlerter from 'common/OutsideAlerter';
import Calendar from 'common/components/molecules/ReactCalendar';
import H2 from 'common/typography/H2/H2';
import CrossSvg from 'common/images/CrossSvg';
import Label from 'common/typography/Label/Label';
import LoadingSvg from 'common/images/LoadingSvg';
import { cutTo2Decimals } from 'common/helpers';
import FilterItem_T from 'common/components/atoms/FilterItem';
import DateSelector from 'common/components/Chart/atoms/DateSelector';
import CalendarContainer from 'common/components/Chart/atoms/CalendarContainer';
import { operations } from 'common/rbac/constants';
import DevicesHealthContainer from '../../components/DevicesHealthContainer';
import StatsContainer from '../../components/StatsContainer';
import EventAnalyticsWidget from './EventAnalytics.widget';
import * as sensorActions from '../../../Sensors/actions/sensors.actions';
import * as eventAnalyticsActions from '../eventAnalytics.actions';

const Loader = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.text`
  color: ${props => props.theme.primaryColor};
`;

const CheckboxContainer = styled(FlexContainer).attrs({ alignItems: 'center' })`
  margin-left: 20px;
  margin-right: 9px;
`;

const FilterLabel = styled.span`
  white-space: pre;
  margin-left: 0.5em;
  color: gray;
`;

const H3 = styled(H3_T)`
  font-weight: 600;
  padding: 10px 0;
`;

const H1 = styled.h1`
  color: ${props => props.theme.primaryColor};
`;

const FilterItem = styled(FilterItem_T)`
  width: auto;
  span {
    margin-left: 0.5em;
  }
`;

const ChartsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  grid-template-columns: repeat(${props => props.perRow ? props.perRow : 3}, minmax(370px, 1fr));
`;

const SvgContainer = styled(FlexContainer)`
  cursor: pointer;
  padding: 0.5em;
  padding-left: 2em;
`;

const formatDate = (locale, date) => {
  const day = date.getDay();
  return mapDayToString[day][0];
};

class EventAnalytics extends Component {
  constructor() {
    super();
    this.initialSearchParams = {
      date: ['7'],
      account_search_key: '',
      machine_search_key: '',
      accounts: [],
      sensors: [],
      state: [],
      trigger: [],
      machines: []
    };
    this.state = {
      machine_condition: 0,
      alarms: 0,
      sensors_unreachable: 0,
      health_score: 0,
      battery: 0,
      others: 0,
      total: 0,
      new: 0,
      closed: 0,
      data: [],
      percent: null,
      percentText: null,
      symbol: null,
      accounts: [],
      machines: [],
      allAccounts: [],
      allMachines: [],
      filters: [],
      dates: {},
      filtersOpen: {
        accounts: false,
        sensors: false,
        state: false,
        trigger: false,
        machines: false
      },
      searchParams: {
        ...this.initialSearchParams,
      },
      dateExpanded: false,
      calenderOpen: null,
      customDayFrom: false,
      customDayTo: false,
      sensorOptions: [
        { text: 'VM1', value: 'VM1' },
        { text: 'VM2', value: 'VM2' },
        { text: 'VM3', value: 'VM3' },
        { text: 'VM4P', value: 'VM4P' },
        { text: 'GSP', value: 'GSP' },
        { text: 'VM4', value: 'VM4' },
        { text: 'GS', value: 'GS' },
        { text: 'TX', value: 'TX' }
      ],
      stateOptions: [
        { text: 'Open', value: 'Open' },
        { text: 'Closed', value: 'Closed' }
      ],
      triggerOptions: [
        { text: 'RMS Velocity', value: 'rms_velocity' },
        { text: 'Machine Condition', value: 'machine_condition' },
        { text: 'Health Score', value: 'health_score' },
        { text: 'Battery Level', value: 'battery_level' },
        { text: 'Tx Battery Level', value: 'tx_battery_level' },
        { text: 'Mote Unreachable', value: 'mote_unreachable' },
        { text: 'Tx Unreachable', value: 'tx_unreachable' },
        { text: 'RMS Current', value: 'rms_current' },
        { text: 'Temperature', value: 'temperature' },
        { text: 'Pressure', value: 'pressure' },
        { text: 'Ultrasound', value: 'ultrasound' }
      ],
      pastWeekAnalytics: false
    };
    this.chartOptions = {
      title: 'Events Past Week',
      legend: { position: 'none' },
      hAxis: {
        title: 'Day'
      },
      vAxis: {
        title: 'Total',
        minValue: 0
      },
      titleTextStyle: {
        fontSize: 16,
        bold: true
      }
    };
    this.dateOptions = [
      {
        text: 'Today',
        value: 'today',
        name: 'Today'
      },
      {
        text: 'Yesterday',
        value: 'yesterday',
        name: 'Yesterday'
      },
      {
        text: 'Last 7 Days',
        value: '7',
        name: 'Last 7 Days'
      },
      {
        text: 'Last 30 Days',
        value: '30',
        name: 'Last 30 Days'
      },
      {
        text: 'This Month',
        value: 'thisMonth',
        name: 'This Month'
      },
      {
        text: 'Last Month',
        value: 'lastMonth',
        name: 'Last Month'
      },
      {
        text: 'Custom',
        value: 'custom',
        name: 'Custom'
      }
    ];
  }

  componentDidMount() {
    const title = this.props.title;
    document.title = `${title} | Event Analytics`;

    this.mounted = true;
    const {
      getResourcesBasedOnType
    } = this.props.sensorActions;

    getResourcesBasedOnType('account').then((res) => {
      this.setState({
        accounts: res,
        allAccounts: res,
      });
    });
    this.props.eventAnalyticsActions.getEventAnalytics();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      getEventAnalytics,
      getMachineResources,
      getPastWeekAnalytics
    } = this.props.eventAnalyticsActions;

    if (prevState.filters !== this.state.filters || prevState.dates !== this.state.dates) {
      if (!this.state.pastWeekAnalytics) getEventAnalytics(this.state.filters, this.state.dates);
      else if (this.state.pastWeekAnalytics) getPastWeekAnalytics(this.state.filters[0]).then((res) => {
        const event = res.event_type_count;
        this.setState({
          machine_condition:
            (event.machine_condition_critical || 0) +
            (event.machine_condition_warning || 0),
          alarms:
            (event.vibration_warning || 0) +
            (event.vibration_critical || 0) +
            (event.rms_current || 0) +
            (event.rms_current_critical || 0) +
            (event.temperature_warning || 0) +
            (event.temperature_warning_critical || 0) +
            (event.pressure_warning || 0) +
            (event.pressure_critical || 0) +
            (event.ultrasound_warning || 0) +
            (event.ultrasound_critical || 0),
          sensors_unreachable:
            (event.mote_unreachable || 0) + (event.tx_unreachable || 0),
          health_score:
            (event.health_score_critical || 0) + (event.health_score_warning || 0),
          battery:
            (event.battery_level_critical || 0) + (event.battery_level_warning || 0),
          others: event.other || 0,
          total: res.total || 0,
          new: res.new || 0,
          closed: res.closed || 0,
          data: [
            ['Day', 'Count', { role: 'style' }],
            [res.event_count_labels[0], res.event_count_day_wise[0], '#91B846'],
            [res.event_count_labels[1], res.event_count_day_wise[1], '#91B846'],
            [res.event_count_labels[2], res.event_count_day_wise[2], '#91B846'],
            [res.event_count_labels[3], res.event_count_day_wise[3], '#91B846'],
            [res.event_count_labels[4], res.event_count_day_wise[4], '#91B846'],
            [res.event_count_labels[5], res.event_count_day_wise[5], '#91B846'],
            [res.event_count_labels[6], res.event_count_day_wise[6], '#91B846']
          ],
        });
        this.percentText(res.new, res.last_to_last_week_events);
      });
    }
    if (!this.state.pastWeekAnalytics) {
      if (
        (!_.isUndefined(prevState.filters[0]) && !_.isUndefined(this.state.filters[0]) && prevState.filters[0].value !== this.state.filters[0].value)
        || (_.isUndefined(prevState.filters[0]) && !_.isUndefined(this.state.filters[0]))
      ) {
        const machinesList = [];
        for (let i = 0; i < this.state.filters[0].value.length; i++) {
          getMachineResources(this.state.filters[0].value[i]).then((res) => {
            for (let j = 0; j < res.machines.length; j++) {
              machinesList.push({
                text: res.machines[j].name,
                value: res.machines[j].id
              });
            }
          });
        }
        this.setState({
          machines: machinesList,
          allMachines: machinesList
        });
      }
    }
  }

  percentText = (_new, last_to_last_week) => {
    let percentage;
    if (last_to_last_week) {
      percentage = ((_new - last_to_last_week) / last_to_last_week) * 100;
      if (percentage >= 100) {
        this.setState({
          percent: _new / last_to_last_week,
          percentText: 'greater than last week',
          symbol: 'x'
        });
      } else if (percentage < 0) {
        this.setState({
          percent: -1 * (percentage),
          percentText: 'less than last week',
          symbol: '%'
        });
      } else {
        this.setState({
          percent: percentage,
          percentText: 'greater than last week',
          symbol: '%'
        });
      }
    } else {
      this.setState({
        percent: null,
        percentText: null,
        symbol: null
      });
    }
  };

  togglePastWeekAnalytics = () => {
    this.setState(prevState => ({
      pastWeekAnalytics: !prevState.pastWeekAnalytics
    }));
    const {
      getPastWeekAnalytics,
      getEventAnalytics,
      getMachineResources
    } = this.props.eventAnalyticsActions;
    if (this.state.pastWeekAnalytics && !_.isEmpty(this.state.filters)) {
      getEventAnalytics(this.state.filters);
      const machinesList = [];
      for (let i = 0; i < this.state.filters[0].value.length; i++) {
        getMachineResources(this.state.filters[0].value[i]).then((res) => {
          for (let j = 0; j < res.machines.length; j++) {
            machinesList.push({
              text: res.machines[j].name,
              value: res.machines[j].id
            });
          }
        });
      }
      this.setState({
        machines: machinesList,
        allMachines: machinesList
      });
    }
    if (!this.state.pastWeekAnalytics) {
      getPastWeekAnalytics(this.state.filters[0]).then((res) => {
        const event = res.event_type_count;
        this.setState({
          machine_condition:
          (event.machine_condition_critical || 0) +
          (event.machine_condition_warning || 0),
          alarms:
          (event.vibration_warning || 0) +
          (event.vibration_critical || 0) +
          (event.rms_current || 0) +
          (event.rms_current_critical || 0) +
          (event.temperature_warning || 0) +
          (event.temperature_warning_critical || 0) +
          (event.pressure_warning || 0) +
          (event.pressure_critical || 0) +
          (event.ultrasound_warning || 0) +
          (event.ultrasound_critical || 0),
          sensors_unreachable:
          (event.mote_unreachable || 0) + (event.tx_unreachable || 0),
          health_score:
          (event.health_score_critical || 0) + (event.health_score_warning || 0),
          battery:
          (event.battery_level_critical || 0) + (event.battery_level_warning || 0),
          others: event.other || 0,
          total: res.total || 0,
          new: res.new || 0,
          closed: res.closed || 0,
          data: [
            ['Day', 'Count', { role: 'style' }],
            [res.event_count_labels[0], res.event_count_day_wise[0], '#91B846'],
            [res.event_count_labels[1], res.event_count_day_wise[1], '#91B846'],
            [res.event_count_labels[2], res.event_count_day_wise[2], '#91B846'],
            [res.event_count_labels[3], res.event_count_day_wise[3], '#91B846'],

            [res.event_count_labels[4], res.event_count_day_wise[4], '#91B846'],
            [res.event_count_labels[5], res.event_count_day_wise[5], '#91B846'],
            [res.event_count_labels[6], res.event_count_day_wise[6], '#91B846']
          ],
        });
        this.percentText(res.new, res.last_to_last_week_events);
      });
    }
  }

  toggleFilterOpen = (filter) => {
    this.setState(prevState => ({
      filtersOpen: {
        ...prevState.filtersOpen,
        [filter]: !prevState.filtersOpen[filter]
      }
    }));
  };

  openCustomCalender = (str) => {
    this.setState({
      calenderOpen: str
    });
  };

  searchAccountFilter = (value) => {
    this.setState(prevState => ({
      accounts: prevState.allAccounts.filter(u =>
        u.text.toLowerCase().includes(value.toLowerCase())
      ),
      searchParams: {
        ...prevState.searchParams,
        account_search_key: value
      }
    }));
  };

  searchMachineFilter = (value) => {
    this.setState(prevState => ({
      machines: prevState.allMachines.filter(u =>
        u.text.toLowerCase().includes(value.toLowerCase())
      ),
      searchParams: {
        ...prevState.searchParams,
        machine_search_key: value
      }
    }));
  };

  renderFilterOptions = (options, name) => {
    if (_.isEmpty(options)) {
      return (
        <MsgContainer>
          <span>No {name} are available</span>
        </MsgContainer>
      );
    }

    return options.map((option, index) => {
      const selected = _.includes(this.state.searchParams[name], option.value);
      if (name !== 'date') {
        return (
          <FilterItem
            key={index.toString()}
            className="dropdown-item"
            selected={selected}
            onClick={() => {
              let types = [];
              if (selected) {
                types = _.filter(
                  this.state.searchParams[name],
                  t => t !== option.value
                );
              } else {
                types = [...this.state.searchParams[name], option.value];
              }
              this.changeFilter(name, types, selected);
            }}
          >
            <Checkbox value={selected} />
            <span>{option.text}</span>
          </FilterItem>
        );
      }
      return (
          <FilterItem
            key={index.toString()}
            className="dropdown-item"
            selected={selected}
            onClick={() => {
              let types = [];
              if (!selected) {
                types = [option.value];
              }
              if (option.value === 'custom' || this.state.dateExpanded) {
                this.toggleDateExpanded('dateExpanded');
              }
              this.changeDateFilter(name, types, selected);
            }}
          >
            <RadioButton value={selected} />
            <span>{option.text}</span>
          </FilterItem>
      );
    });
  };

  toggleDateExpanded = () => {
    this.setState(prevState => ({
      dateExpanded: !prevState.dateExpanded
    }));
  };

  changeDateFilter = (filter, value) => {
    let start_date;
    let end_date;
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth();

    if (value.includes('custom')) {
      start_date = new Date(new Date().setDate(current.getDate() - 7));
      end_date = current;
    } else if (value.includes('today')) {
      start_date = new Date(new Date().setHours(0, 0, 0, 0));
      end_date = current;
    } else if (value.includes('yesterday')) {
      start_date = new Date(
        new Date(current.setHours(0, 0, 0, 0)).setDate(
          current.getDate() - 1
        )
      );
      end_date = new Date(current.setHours(0, 0, 0, 0));
    } else if (value.includes('7')) {
      start_date = new Date(new Date().setDate(current.getDate() - 7));
      end_date = current;
    } else if (value.includes('30')) {
      start_date = new Date(new Date().setDate(current.getDate() - 30));
      end_date = current;
    } else if (value.includes('thisMonth')) {
      start_date = new Date(year, month, 1);
      end_date = new Date(year, month + 1, 0);
    } else if (value.includes('lastMonth')) {
      start_date = new Date(year, month - 1, 1);
      end_date = new Date(year, month, 0);
    }

    const searchParams = {
      ...this.state.searchParams,
      [filter]: value,
      date_from: start_date,
      date_to: end_date
    };
    this.setState({
      searchParams,
      customDayFrom: false,
      customDayTo: false
    });
    return this.search(searchParams);
  };

  onClickDay = (value, label) => {
    let searchParams = {};
    if (label === 'from') {
      searchParams = {
        ...this.state.searchParams,
        date_from: value
      };
      this.setState({
        searchParams,
        customDayFrom: value,
        calenderOpen: null
      });
    } else if (label === 'to') {
      searchParams = {
        ...this.state.searchParams,
        date_to: value
      };
      this.setState({
        searchParams,
        customDayTo: value,
        calenderOpen: null
      });
    }
    this.search(searchParams);
  };

  closeCalender = () => {
    this.setState({ calenderOpen: null });
  };

  clearDate = () => {
    const searchParams = {
      ...this.state.searchParams,
      date_from: new Date(new Date().setDate(new Date().getDate() - 7)),
      date_to: new Date()
    };
    this.setState({
      searchParams,
      customDayFrom: false,
      customDayTo: false
    });
    this.search(searchParams);
  };

  clearAllFilters = () => {
    const searchParams = {
      ...this.initialSearchParams
    };
    if (this.state.machineSensors) {
      searchParams.machines = this.state.searchParams.machines;
    }
    this.setState({ searchParams });
    if (this.state.dateExpanded) this.toggleDateExpanded();
    this.search(searchParams);
  }

  changeFilter = (filter, value) => {
    const searchParams = {
      ...this.state.searchParams,
      [filter]: value
    };
    this.setState({
      searchParams
    });
    return this.search(searchParams);
  };

  search = (params = null) => {
    const { searchParams: stateParams } = this.state;
    const searchParams = params || stateParams;
    const start_date = searchParams.date_from;
    const end_date = searchParams.date_to;

    const filters = [
      { name: 'account_id', op: 'in', value: searchParams.accounts },
      { name: 'sensor_type', op: 'in', value: searchParams.sensors },
      { name: 'state', op: 'in', value: searchParams.state },
      { name: 'trigger_type', op: 'in', value: searchParams.trigger },
      { name: 'machine_id', op: 'in', value: searchParams.machines }
    ];
    const dates = {
      start_date,
      end_date
    };
    this.setState({
      filters,
      dates
    });
  };

  selectGivenItems = (selected, key) => {
    this.setState(
      prevState => ({
        searchParams: {
          ...prevState.searchParams,
          [key]: selected
        },
        filtersOpen: {
          ...prevState.filtersOpen,
          [key]: false
        }
      }),
      this.search
    );
  };

  render() {
    const dateTextValue = this.state.searchParams.date.reduce(
      (acc, id, idx) => {
        const name = this.dateOptions.filter(s => s.value === id);
        if (!_.isEmpty(name)) {
          if (idx === 0) return `${name[0].name}`;
          if (idx === 1) return `${acc}, ${name[0].name}...`;
        }
        return acc;
      },
      ''
    );
    const accountTextValue = this.state.searchParams.accounts.reduce((acc, id, idx) => {
      const name = this.state.allAccounts.filter(accounts => accounts.value === id);
      if (!_.isEmpty(name)) {
        if (idx === 0) return `${name[0].text}`;
        if (idx === 1) return `${acc}, ${name[0].text}...`;
      }
      return acc;
    }, '');

    const machineTextValue = this.state.searchParams.machines.reduce((acc, id, idx) => {
      const name = this.state.allMachines.filter(machine => machine.value === id);
      if (!_.isEmpty(name)) {
        if (idx === 0) return `${name[0].text}`;
        if (idx === 1) return `${acc}, ${name[0].text}...`;
      }
      return acc;
    }, '');

    const sensorTextValue = this.state.searchParams.sensors.reduce(
      (acc, id, idx) => {
        const name = this.state.sensorOptions.filter(machine => machine.value === id);
        if (!_.isEmpty(name)) {
          if (idx === 0) return `${name[0].text}`;
          if (idx === 1) return `${acc}, ${name[0].text}...`;
        }
        return acc;
      },
      ''
    );

    const stateTextValue = this.state.searchParams.state.reduce(
      (acc, id, idx) => {
        const name = this.state.stateOptions.filter(state => state.value === id);
        if (!_.isEmpty(name)) {
          if (idx === 0) return `${name[0].text}`;
          if (idx === 1) return `${acc}, ${name[0].text}...`;
        }
        return acc;
      },
      ''
    );

    const triggerTextValue = this.state.searchParams.trigger.reduce(
      (acc, id, idx) => {
        const name = this.state.triggerOptions.filter(trigger => trigger.value === id);
        if (!_.isEmpty(name)) {
          if (idx === 0) return `${name[0].text}`;
          if (idx === 1) return `${acc}, ${name[0].text}...`;
        }
        return acc;
      },
      ''
    );

    return (
      <div>
        <MoreOptions>
          <AccountMenuItems />
        </MoreOptions>
          <SearchFilters height="60px">
            <>
            {!this.state.pastWeekAnalytics && (
              <>
              <FilterV2
                title="Type"
                value={sensorTextValue}
                open={this.state.filtersOpen.sensors}
                toggleOpen={() => this.toggleFilterOpen('sensors')}
              >
                {this.renderFilterOptions(this.state.sensorOptions, 'sensors')}
              </FilterV2>
              <FilterV2
                title="State"
                value={stateTextValue}
                open={this.state.filtersOpen.state}
                toggleOpen={() => this.toggleFilterOpen('state')}
              >
                  {this.renderFilterOptions(this.state.stateOptions, 'state')}
              </FilterV2>
              <FilterV2
                title="Trigger"
                value={triggerTextValue}
                open={this.state.filtersOpen.trigger}
                toggleOpen={() => this.toggleFilterOpen('trigger')}
              >
                  {this.renderFilterOptions(this.state.triggerOptions, 'trigger')}
              </FilterV2>
              <FilterV2
                title="Date Range"
                value={dateTextValue}
                open={this.state.filtersOpen.date}
                noScroll
                toggleOpen={() => this.toggleFilterOpen('date')}
              >
                  <FlexContainer
                    direction="row"
                  >
                    <FlexContainer
                      direction="column"
                    >
                      {this.renderFilterOptions(this.dateOptions, 'date')}
                    </FlexContainer>
                    {this.state.dateExpanded && (
                      <DateSelector>
                        {this.state.calenderOpen === 'from' && (
                          <OutsideAlerter open={this.state.calenderOpen === 'from'} handleClick={this.closeCalender}>
                            <CalendarContainer>
                              <Calendar
                                formatShortWeekday={(locale, date) => formatDate(locale, date)}
                                maxDetail="month"
                                maxDate={this.state.customDayTo || new Date()}
                                showNeighboringMonth={false}
                                onClickDay={value => this.onClickDay(value, 'from')}
                                value={this.state.customDayFrom}
                              />
                            </CalendarContainer>
                          </OutsideAlerter>
                        )}
                        {this.state.calenderOpen === 'to' && (
                          <OutsideAlerter open={this.state.calenderOpen === 'to'} handleClick={this.closeCalender}>
                            <CalendarContainer to="true">
                              <Calendar
                                formatShortWeekday={(locale, date) => formatDate(locale, date)}
                                maxDetail="month"
                                maxDate={new Date()}
                                minDate={this.state.customDayFrom}
                                value={this.state.customDayTo}
                                showNeighboringMonth={false}
                                onClickDay={value => this.onClickDay(value, 'to')}
                              />
                            </CalendarContainer>
                          </OutsideAlerter>
                        )}
                        <FlexContainer direction="row" justifyContent="space-between">
                          <H2>Custom</H2>
                          <SvgContainer
                            onClick={() => this.toggleFilterOpen('date')}
                          >
                            <CrossSvg width={30} height={30} fill="#999B95" />
                          </SvgContainer>
                        </FlexContainer>
                        <InputField label="From" placeholder="Select" readOnly value={this.state.customDayFrom ? this.state.customDayFrom.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalender('from')} />
                        <InputField label="To" placeholder="Select " readOnly value={this.state.customDayTo ? this.state.customDayTo.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalender('to')} />
                        <Button
                          text
                          onClick={this.clearDate}
                          disabled={!this.state.customDayFrom && !this.state.customDayTo}
                        >
                          CLEAR
                        </Button>
                      </DateSelector>)}
                  </FlexContainer>
              </FilterV2>
              </>
            )}
            <FilterV2
              title="Account"
              value={accountTextValue}
              open={this.state.filtersOpen.accounts}
              toggleOpen={() => this.toggleFilterOpen('accounts')}
              onChangeSearch={this.searchAccountFilter}
              searchValue={this.state.searchParams.account_search_key}
              viewAllItems={this.state.allAccounts}
              selectedItems={this.state.searchParams.accounts}
              selectGivenItems={selected =>
                this.selectGivenItems(selected, 'accounts')
              }
              itemName="account"
            >
              {this.renderFilterOptions(this.state.accounts, 'accounts')}
            </FilterV2>
            {(!_.isEmpty(this.state.searchParams.accounts) && !this.state.pastWeekAnalytics) && (
              <FilterV2
                title="Machine"
                value={machineTextValue}
                open={this.state.filtersOpen.machines}
                toggleOpen={() => this.toggleFilterOpen('machines')}
                onChangeSearch={this.searchMachineFilter}
                searchValue={this.state.searchParams.machine_search_key}
                viewAllItems={this.state.allMachines}
                selectedItems={this.state.searchParams.machines}
                selectGivenItems={selected =>
                  this.selectGivenItems(selected, 'machines')
              }
                itemName="machine"
              >
                {this.renderFilterOptions(this.state.machines, 'machines')}
              </FilterV2>
            )}
            <CheckboxContainer>
            <Checkbox
              value={this.state.pastWeekAnalytics}
              onClick={() => this.togglePastWeekAnalytics()}
            />
              <FilterLabel>
                Past Week Analytics
              </FilterLabel>
            </CheckboxContainer>
            {isFilterApplied(this.state.searchParams) && (
              <Button text onClick={this.clearAllFilters} fontSize="11px" margin="0 20px">
                Clear Filters
              </Button>
            )}
            </>
          </SearchFilters>
        <RBAC
          operation={operations.Read}
          yes={(
            <DevicesHealthContainer>
            {this.state.pastWeekAnalytics ? (
            <ChartsContainer>
              <StatsContainer>
                <Label>Event Type Count</Label>
                <H3><Text>Machine Condition:</Text> {this.state.machine_condition}</H3>
                <H3><Text>Alarms:</Text> {this.state.alarms}</H3>
                <H3><Text>Sensors Unreachable:</Text> {this.state.sensors_unreachable}</H3>
                <H3><Text>Health Score:</Text> {this.state.health_score}</H3>
                <H3><Text>Battery:</Text> {this.state.battery}</H3>
                <H3><Text>Others:</Text> {this.state.others}</H3>
              </StatsContainer>
              <StatsContainer>
                {!_.isEmpty(this.state.data) ? (
                <Chart
                  chartType="ColumnChart"
                  data={this.state.data}
                  options={this.chartOptions}
                  loader={(
                    <Loader><LoadingSvg /></Loader>
                  )}
                />
                ) : (
                <Loader><LoadingSvg /></Loader>
                )}
              </StatsContainer>
              <StatsContainer>
                <H3>Total: {this.state.total}, New: {this.state.new},  Closed: {this.state.closed}</H3>
                <H1>{cutTo2Decimals(this.state.percent)}{this.state.symbol} {this.state.percentText}</H1>
              </StatsContainer>
            </ChartsContainer>
            ) : (
              <>
                <ChartsContainer>
                  <EventAnalyticsWidget first analytics_type="breakdown_by_state" filters={this.state.filters} />
                  <EventAnalyticsWidget analytics_type="duration_of_events" filters={this.state.filters} />
                  <StatsContainer>
                    <H3>Total Events: {this.props.eventAnalytics.total ? this.props.eventAnalytics.total : '0'}</H3>
                    <H3>Average Duration of Events: {this.props.eventAnalytics.avg_event_duration ? this.props.eventAnalytics.avg_event_duration : '0'}</H3>
                    <H3>Total Event Comments: {this.props.eventAnalytics.comment_count ? this.props.eventAnalytics.comment_count : '0'}</H3>
                  </StatsContainer>
                </ChartsContainer>
                 <ChartsContainer perRow={2}>
                  <EventAnalyticsWidget first analytics_type="avg_duration" filters={this.state.filters} />
                  <EventAnalyticsWidget analytics_type="num_of_events" filters={this.state.filters} />
                 </ChartsContainer>
              </>
            )}

            </DevicesHealthContainer>
              )}
          no={<AccessRestricted />}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  adminDashboard: state.adminDashboard,
  accountAnalytics: state.accountAnalytics,
  eventAnalytics: state.eventAnalytics,
  title: state.companyReducer.partner.title
});

const mapDispatchToProps = dispatch => ({
  sensorActions: bindActionCreators(sensorActions, dispatch),
  eventAnalyticsActions: bindActionCreators(eventAnalyticsActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventAnalytics);
