import React from 'react';
import styled from 'styled-components';
import colors from 'common/styles/colors';
import AreaTemperatureTable from './AreaTemperatureTable';

const TITLE_HEIGHT = 30;
const Title = styled.div`
  color: ${colors.black};
  font-weight: 800;
  width: 100%;
  padding: 0.5em 0 0 0.5em;
  height: ${TITLE_HEIGHT}px;
  display: flex;
  border-bottom: 1px solid ${colors.lightGray};
  border-radius: 4px 4px 0 0;
  background-color: ${colors.white};
  top: 0;
  font-size: 1em;
`;

const ToolTipContainer = styled.span`
  &:before {
    display: none;
  }

  &:after {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    transform: none !important;
    transition-property: none !important;
    animation: none !important;
    -webkit-transform-origin: unset;
    transform-origin: unset;
    background: #000000 !important;
    color: #EEEEEE !important;
    padding: 5px !important;
    font-size: 12px !important;
  }
`;

const AreaTemperaturePage = ({ data }) => (
    <div style={{
      backgroundColor: 'white',
      height: '100%',
      borderRadius: '4px',
      position: 'relative',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#eeeeee #ffffff',
      maxHeight: '600px'
    }}
    >
      <div>
        <Title title={data.title || 'Unknown'}>
          <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {data.title}
          </div>
          <ToolTipContainer data-tooltip={data.title || 'Unknown'} data-position="top" />
        </Title>
        <AreaTemperatureTable data={data.items} />
      </div>
    </div>
);

export default AreaTemperaturePage;
