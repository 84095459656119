export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  SET_TIMEZONE_REQUEST: 'SET_TIMEZONE_REQUEST',
  SET_TIMEZONE_SUCCESS: 'SET_TIMEZONE_SUCCESS',
  SET_TIMEZONE_FAILURE: 'SET_TIMEZONE_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  SASS_AGREEMENT_REQUEST: 'SASS_AGREEMENT_REQUEST',
  SASS_AGREEMENT_SUCCESS: 'SASS_AGREEMENT_SUCCESS',
  SASS_AGREEMENT_FAILURE: 'SASS_AGREEMENT_FAILURE',

  GET_CURRENT_ACCOUNT_REQUEST: 'GET_CURRENT_ACCOUNT_REQUEST',
  GET_CURRENT_ACCOUNT_SUCCESS: 'GET_CURRENT_ACCOUNT_SUCCESS',
  GET_CURRENT_ACCOUNT_FAILURE: 'GET_CURRENT_ACCOUNT_FAILURE',

  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',
  CLEAR_USER_STATUS: 'CLEAR_USER_STATUS',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED',

  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILED: 'FORGOT_PASSWORD_FAILED',

  TOKEN_DETAILS_REQUEST: 'TOKEN_DETAILS_REQUEST',
  TOKEN_DETAILS_SUCCESS: 'TOKEN_DETAILS_SUCCESS',
  TOKEN_DETAILS_FAILURE: 'TOKEN_DETAILS_FAILURE',

  ASSOCIATE_TOKEN_DETAILS_REQUEST: 'ASSOCIATE_TOKEN_DETAILS_REQUEST',
  ASSOCIATE_TOKEN_DETAILS_SUCCESS: 'ASSOCIATE_TOKEN_DETAILS_SUCCESS',
  ASSOCIATE_TOKEN_DETAILS_FAILURE: 'ASSOCIATE_TOKEN_DETAILS_FAILURE',

  TIMEZONE_REQUEST: 'TIMEZONE_REQUEST',
  TIMEZONE_SUCCESS: 'TIMEZONE_SUCCESS',
  TIMEZONE_FAILURE: 'TIMEZONE_FAILURE',

  SELECT_ACCOUNT_REQUEST: 'SELECT_ACCOUNT_REQUEST',
  SELECT_ACCOUNT_SUCCESS: 'SELECT_ACCOUNT_SUCCESS',
  SELECT_ACCOUNT_FAILURE: 'SELECT_ACCOUNT_FAILURE',

  VERIFY_OTP_REQUEST: 'VERIFY_OTP_REQUEST',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE',

  RESEND_OTP_REQUEST: 'RESEND_OTP_REQUEST',
  RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
  RESEND_OTP_FAILURE: 'RESEND_OTP_FAILURE',

  RESET_OTP_STATE: 'RESET_OTP_STATE',

  GET_RBAC_PERMISSIONS_REQUEST: 'GET_RBAC_PERMISSIONS_REQUEST',
  GET_RBAC_PERMISSIONS_SUCCESS: 'GET_RBAC_PERMISSIONS_SUCCESS',
  GET_RBAC_PERMISSIONS_FAILURE: 'GET_RBAC_PERMISSIONS_FAILURE',

  GET_TASK_STATUS: 'GET_TASK_STATUS',
  GET_TASK_DONE: 'GET_TASK_DONE'
};
