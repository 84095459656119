import { combineReducers } from 'redux';

import {
  sensorsModelListReducer,
  sensorsModelsSorter,
  sensorModelDetailsReducer,
  sensorSearchParams
} from './reducers/sensorModels.reducer';

export const sensorModels = combineReducers({
  list: sensorsModelListReducer,
  details: sensorModelDetailsReducer,
  sorter: sensorsModelsSorter,
  searchParams: sensorSearchParams
});
