import React from 'react';
import * as _ from 'lodash';
import color from 'common/styles/colors';
import AssetIcon from 'common/images/machineInactiveSvg';
import AreaIcon from 'common/images/AssetHierarchy/AreaIcon';
import ComponentIcon from 'common/images/AssetHierarchy/ComponentIcon';
import ComponentActiveIcon from 'common/images/AssetHierarchy/ComponentActiveIcon';
import FilterIcon from 'common/images/FilterIconSvg';
import SiteIcon from 'common/images/AssetHierarchy/SiteIcon';
import TagIcon from 'common/images/AssetHierarchy/TagIcon';
import { getDirectionOrder, tagTypeOrder, phaseOrder } from 'common/charts/constants';
import { getActivePartnerBasedOnDomain } from 'home/DomainName/DomainConstants';
import { findNode } from '../actions/hierarchy.actions';

export function getConfigList(machineId, siteId, sites) {
  const site = sites.find(s => s.site_id === siteId);
  if (!site) return [];
  const machine = site.machines.find(i => i.machine_id === machineId);
  if (!machine || !machine.configs) return [];
  return machine.configs;
}

export const getNodeListFromHierarchy = (nodes, nodeType) => {
  const result = [];
  const getNodeListHelper = (nodes, nodeType, result) => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].node_type === nodeType) result.push(nodes[i]);
      getNodeListHelper(nodes[i].children, nodeType, result);
    }
  };
  getNodeListHelper(nodes, nodeType, result);
  return result;
};
export function getTagsList(machineId, siteId, sites) {
  const site = sites.find(s => s.site_id === siteId);
  if (!site) return [];
  const machine = site.machines.find(i => i.machine_id === machineId);
  if (!machine) return [];
  return machine.tags;
}

export function getMachinesUpdatedInfo(machineId, siteId, sites, key = 'configs') {
  const site = sites.find(item => item.site_id === siteId);
  if (!site) return null;
  const machineIndex = _.findIndex(site.machines, machine => machine.machine_id === machineId);
  if (machineIndex === -1) return [...site.machines, { machine_id: machineId, [key]: { loading: true } }];
  return _.map(site.machines, (machine) => {
    if (machine.machine_id !== machineId) return machine;
    return {
      ...machine,
      [key]: {
        loading: true
      }
    };
  });
}

const recurCheckNodeForChange = (node, nodeIdx, prevNode, updatedAreaList, updatedMachineList, prevHierarchy) => {
  if (node.node_type === 'site') {
    updatedAreaList[node.id] = {
      account_id: node.id,
      areas: {},
      updated: false
    };
    updatedMachineList[node.id] = {};
    node.children.forEach((child, childIdx) => {
      child.siteIdx = nodeIdx;
      child.site = node;
      recurCheckNodeForChange(child, childIdx, prevNode.children[childIdx], updatedAreaList, updatedMachineList, prevHierarchy);
    });
  } else if (node.node_type === 'area') {
    if (node.id !== prevNode.id) {
      updatedAreaList[node.site.id].updated = true;
      // search in previous Hierarchy to get the area and check for change in machine, if any machine / area positon is changed mark updated true
      prevNode = findNode(node.id, node.node_type, prevHierarchy, null, null);
    }
    updatedAreaList[node.site.id].areas[node.id] = {
      id: node.id,
      position: nodeIdx,
    };
    updatedMachineList[node.site.id][node.id] = {
      account_id: node.site.id,
      area_id: node.id,
      machines: [],
      updated: false
    };
    node.children.forEach((child, childIdx) => {
      child.site = node.site;
      child.areaId = node.id;
      recurCheckNodeForChange(child, childIdx, prevNode.children[childIdx], updatedAreaList, updatedMachineList, prevHierarchy);
    });
  } else if (node.node_type === 'machine') {
    if (node.id !== prevNode.id) {
      updatedMachineList[node.site.id][node.areaId].updated = true;
    }
    updatedMachineList[node.site.id][node.areaId].machines.push({
      id: node.id,
      position: nodeIdx
    });
  }
};

export const getUpdatedAreasAndMachines = (prevHierarchy, currentHierarchy) => {
  let updatedAreaList = {};
  let updatedMachineList = {};
  currentHierarchy.forEach((site, siteIdx) => {
    recurCheckNodeForChange(site, siteIdx, prevHierarchy[siteIdx], updatedAreaList, updatedMachineList, prevHierarchy[siteIdx]);
  });

  updatedAreaList = Object.keys(updatedAreaList).filter(
    key => updatedAreaList[key].updated
  ).map((key) => {
    const site = updatedAreaList[key];
    const areas = Object.keys(site.areas).filter(
      key => site.areas[key].id > 0
    ).map(key => site.areas[key]);
    return {
      ...site,
      areas
    };
  });
  // extra all the list which are updated to send it to backend
  updatedMachineList = Object.keys(updatedMachineList).map((key) => {
    const site = updatedMachineList[key];
    const updatedAreas = Object.keys(site).filter(key => site[key].updated).map(key => site[key]);
    return updatedAreas;
  });

  updatedMachineList = [].concat(...updatedMachineList);
  updatedMachineList = _.filter(updatedMachineList, el => el.machines.length !== 0);

  return [updatedAreaList, updatedMachineList];
};

export function getFeatureTrend(machineId, siteId, sites) {
  const site = sites.find(s => s.site_id === siteId);
  if (!site) return {};
  const machine = site.machines.find(i => i.machine_id === machineId);
  if (!machine) return {};
  return machine.featureTrend;
}

export const haspath = (nodes, nodeId, nodeType, path) => {
  for (let i = 0; i < nodes.length; i++) {
    path.push({
      id: nodes[i].id,
      name: nodes[i].node_name,
      type: nodes[i].node_type
    });
    if (nodes[i].id === nodeId && nodes[i].node_type === nodeType) return true;
    if (haspath(nodes[i].children, nodeId, nodeType, path)) return true;
  }
  path.pop();
  return false;
};

export const getHierarchyParams = (path, nodeId, nodeType) => {
  const params = {
    site: {
      name: '',
      id: null
    },
    area: {
      name: '',
      id: null
    },
    machine: {
      name: '',
      id: null
    },
    machine_charts: {
      name: '',
      id: null
    },
    location: {
      name: '',
      id: null
    },
    component: {
      name: '',
      id: null
    },
    config: {
      name: '',
      id: null
    },
    selected: {
      name: '',
      id: null,
      type: ''
    }
  };
  path.forEach((node) => {
    if (node.type === 'machine') {
      params.machine.id = node.id;
      params.machine.name = node.name;
    }
    if (node.type === 'component') {
      params.component.id = node.id;
      params.component.name = node.name;
    }
    if (node.type === 'location') {
      params.location.id = node.id;
      params.location.name = node.name;
    }
    if (node.type === 'site') {
      params.site.id = node.id;
      params.site.name = node.name;
    }
    if (node.type === 'area') {
      params.area.id = node.id;
      params.area.name = node.name;
    }
    if (node.type === 'machine_charts') {
      params.machine_charts.id = node.id;
      params.machine_charts.name = node.name;
    }
    if (node.type === 'config') {
      params.config.id = node.id;
      params.config.name = node.name;
    }
  });

  let selectedNode = _.filter(path, item => item.id === nodeId && item.type === nodeType);
  if (selectedNode.length > 0) {
    selectedNode = selectedNode[0];
  }
  params.selected.id = selectedNode.id;
  params.selected.type = selectedNode.type;
  params.selected.name = selectedNode.name;
  return params;
};

const includesNodeInPath = (node, path) => {
  for (let i = 0; i < path.length; i++) {
    if (path[i].id === node.id && path[i].type === node.node_type) return true;
  }
  return false;
};

export const expandPathNodes = (nodes, path) => {
  nodes.forEach((node) => {
    if (includesNodeInPath(node, path)) {
      node.expanded = true;
    }
    expandPathNodes(node.children, path);
  });
};

export const getNode = (nodes, nodeId, nodeType) => {
  if (!nodeId || !nodeType) return null;
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].id === nodeId && nodes[i].node_type === nodeType) return nodes[i];
    const node = getNode(nodes[i].children, nodeId, nodeType);
    if (node) return node;
  }
  return null;
};

export const getNextConfigByMachineId = (nodes, machineId) => {
  if (!machineId) return null;
  const machines = getNodeListFromHierarchy(nodes, 'machine');
  const machineIdx = _.findIndex(machines, m => m.id === machineId);
  if (machineIdx < 0) return null;
  for (let i = machineIdx; i < machines.length; i++) {
    const components = machines[i].children;
    for (let j = 0; j < components.length; j++) {
      for (let loc_idx = 0; loc_idx < components[j].length; loc_idx++) {
        if (components[j][loc_idx].children.length > 0) return components[j][loc_idx].children[0];
      }
    }
  }
  return null;
};

export const getComponentsFromMachineId = (nodes, machineId) => {
  const components = getNodeListFromHierarchy(nodes, 'component');
  return components.filter(c => c.node_details.machine_id === machineId);
};

export const getConfigsFromLocationId = (nodes, locationId) => {
  if (!locationId) return [];
  const locations = getNodeListFromHierarchy(nodes, 'location');
  if (_.isEmpty(locations)) return [];
  const location = _.find(locations, l => l.id === locationId);
  if (!location) return [];
  return location.children;
};

export const isBearing = (nodes, locationId) => {
  if (!locationId) return false;
  const locations = getNodeListFromHierarchy(nodes, 'location');
  if (_.isEmpty(locations)) return false;
  const location = _.find(locations, l => l.id === locationId);
  if (!location) return false;
  return location.bearing;
};

export const getSelectedPathFromBreadcrumb = breadcrumb => ({
  site_id: breadcrumb.site.id,
  area_id: breadcrumb.area.id,
  machine_id: breadcrumb.machine.id,
  component_id: breadcrumb.component.id,
  config_id: breadcrumb.config.id,
  location_id: breadcrumb.location.id
});

export const flattenHierarchy = (hierarchy) => {
  const result = [];
  const flattenHierarchyHelper = (nodes, result) => {
    nodes.forEach((node) => {
      result.push(node);
      flattenHierarchyHelper(node.children, result);
    });
  };
  flattenHierarchyHelper(hierarchy, result);
  return result;
};

export const getSvg = (type, selected = false) => {
  const partner = getActivePartnerBasedOnDomain();
  const primaryColor = partner.theme.primaryColor;
  const fill = selected ? primaryColor : color.greyXD;
  switch (type) {
    case 'area':
      return <AreaIcon fill={fill} />;

    case 'component': {
      if (selected) {
        return <ComponentActiveIcon />;
      }
      return <ComponentIcon />;
    }
    case 'config':
      return <TagIcon fill={fill} />;

    case 'filter':
      return <FilterIcon width={18} height={18} fill={fill} />;

    case 'machine':
      return <AssetIcon fill={fill} />;

    case 'site':
      return <SiteIcon fill={fill} />;

    default:
      return null;
  }
};

export const getFirstConfig = (nodes) => {
  const result = [];
  const getFirstConfigHelper = (nodes, result) => {
    nodes.forEach((node) => {
      if (node.node_type === 'config' && _.isEmpty(result)) result.push(node);
      else getFirstConfigHelper(node.children, result);
    });
  };
  getFirstConfigHelper(nodes, result);
  return result;
};

export const sortNodeList = (nodes, sortHierarchyAscending) => {
  if (sortHierarchyAscending === null) return nodes;
  const val = sortHierarchyAscending ? 1 : -1;
  nodes.map((node) => {
    node.children.sort((a, b) => (a.node_name.toUpperCase() > b.node_name.toUpperCase()) ? val : -val);
    node.children.map(child => child.children.sort((a, b) => (a.node_name.toUpperCase() > b.node_name.toUpperCase()) ? val : -val));
    return node;
  });
  return nodes;
};

export const sortLocationConfigs = (treeNodes, vibrationDirectionPreference) => {
  const nodes = _.cloneDeep(treeNodes);
  const sortLocationConfigsHelper = (nodes) => {
    nodes.forEach((node) => {
      if (node.node_type === 'location') {
        node.children = configSorter(node.children, vibrationDirectionPreference);
      }
      sortLocationConfigsHelper(node.children);
    });
  };
  sortLocationConfigsHelper(nodes);
  return nodes;
};

export const configSorter = (configs, vibration_direction_preference) => {
  const directionOrder = getDirectionOrder(vibration_direction_preference);
  configs.sort((a, b) => {
    const tagTypeIndexA = tagTypeOrder.indexOf(a.tag_type) !== -1 ? tagTypeOrder.indexOf(a.tag_type) : 100;
    const tagTypeIndexB = tagTypeOrder.indexOf(b.tag_type) !== -1 ? tagTypeOrder.indexOf(b.tag_type) : 100;
    if (a.tag_type === 'vibration' && (tagTypeIndexA === tagTypeIndexB)) {
      const directionIndexA = directionOrder.indexOf(a.direction);
      const directionIndexB = directionOrder.indexOf(b.direction);
      return directionIndexA > directionIndexB ? 1 : -1;
    }
    if (a.tag_type === 'current' && (tagTypeIndexA === tagTypeIndexB)) {
      const phaseIndexA = phaseOrder.indexOf(a.phase);
      const phaseIndexB = phaseOrder.indexOf(b.phase);
      return phaseIndexA > phaseIndexB ? 1 : -1;
    }
    return tagTypeIndexA > tagTypeIndexB ? 1 : -1;
  });
  return configs;
};

export const getComponentNodeByConfigId = (nodes, config_id) => {
  const path = [];
  haspath(nodes, config_id, 'config', path);
  const component = _.find(path, node => node.type === 'component');
  if (!component) return null;
  return getNode(nodes, component.id, 'component');
};
