import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import _ from 'lodash';

import AlertPrompt from 'common/components/organisms/AlertPrompt';
import { ChevronDown, ChevronUp, Search } from 'common/images/FaIcons';
import FlexContainer from 'common/components/atoms/FlexContainer';
import EditIcon from 'common/images/BearingModal/EditIcon';
import RBAC from 'common/rbac/RBAC';
import {
  mapComponentToResource,
  operations
} from 'common/rbac/constants';
import { KEYBOARD_CODES } from 'common/constants';
import Button from 'common/components/atoms/Button';
import InputField_T from 'common/components/atoms/InputField';
import Filter from 'common/components/molecules/Filter';
import InlineModal from 'common/components/molecules/InlineModal';
import FilterItem from 'common/components/atoms/FilterItem';
import AddTrendModal from 'home/Machines/MachineDetails/MachineCharts/components/AddTrendModal';
import CompareTagsModal from 'home/Machines/CompareTags/components/CompareTagsModal';
import { history } from 'common/helpers';
import * as assetDetailsActions from '../../actions/assetDetails.actions';
import * as hierarchyUpdateActions from '../../actions/hierarchy.actions';
import * as machineInfoActions from '../../../Machines/MachineDetails/MachineInfo/actions/machineInfo.actions';
import * as envelopeActions from '../../actions/envelope.actions';

import LeftColumn from '../atoms/LeftColumn';
import HeaderContainer_T from '../atoms/HeaderContainer';
import FooterFlex from '../atoms/FooterFlex';
import NodeList from '../organisms/NodeList';
import AddAreaModal from '../organisms/AddAreaModal';
import AssetPasteModal from '../organisms/AssetPasteModal';
import AssetCopyModal from '../organisms/AssetCopyModal';
import * as hierarchyUtils from '../../utils/assetHierarchyUtils';
import SearchList from '../organisms/SearchList';
import ScrollContainer from '../../../HomePage/components/atoms/ScrollContainer';
import DeleteOverallAlarmsModal from '../organisms/OverallAlarm/DeleteOverallAlarmsModal';


const Content = styled(FlexContainer)`
  background: white;
  width: 100%;
`;

const ColumnLayout = styled(FlexContainer).attrs({})`
  ${props => props.editMode ? 'height: calc(100% - 55px);' : 'height: 100%;'}
`;

const SvgRowContainer = styled(FlexContainer)`
  padding: 0;
  margin-left: auto;
  width: auto;
`;

const InputField = styled(InputField_T)`
  margin: auto 0;
`;

const SvgContainer = styled(FlexContainer)`
  cursor: pointer;
  padding: 0px 1.5em;
  .fa-sm {
    font-size: 1.2em;
  }
  align-items: center;
`;

const HeaderContainer = styled(HeaderContainer_T)`
  padding: 5px 2px 10px 2px;
  ${InlineModal} {
    left: 10px;
    width: max-content;
  }
  ${Filter} {
    margin-right: 10px;
    width: max-content
  }
  border-right: 2px solid ${props => props.theme.colors.greyXL};
  z-index: 5;
  background: white;
  display: ${props => props.isVisible ? 'flex' : 'none'};
`;

const ListOption = styled.span`
  margin-left: 10px;
  color: ${props => props.selected ? props.theme.colors.black : props.theme.colors.greyXD};
  font-weight: ${props => props.selected && 600};
  white-space: pre;
`;

const CustomScrollContainer = styled(ScrollContainer)`
    // 110px = height of top bar (BreadCrumb header) + height of HierarchyView search filters bar
    height: calc(100vh - 110px);
`;

class HierarchyView extends React.Component {
  constructor(props) {
    super(props);
    this.sortHierarchyAscending = JSON.parse(localStorage.getItem('sortHierarchyAscending'));
    const nodes = this.sortHierarchy(_.cloneDeep(this.props.assetHierarchy.hierarchy), this.sortHierarchyAscending);
    this.state = {
      treeNodes: nodes,
      saveDisabled: true,
      currentConfigIndex: -1,
      configList: hierarchyUtils.getNodeListFromHierarchy(nodes, 'config'),
      searchKey: '',
      searchActive: false,
      nodeFilterExpanded: false,
      nodeFilterSelected: null,
      node: null,
      editNode: null,
      deleteModal: false,
      deleteInactiveTagsModal: false,
      addAreaModal: false,
      editAreaModal: false,
      assetCopyModal: false,
      assetPasteModal: false,
      deleteOverallAlarmsModal: false,
      copiedAssetDetails: null,
      addTrendModal: false,
      compareTagsModal: false
    };
    this.filterOptions = [
      { text: 'Area', node_type: 'area' },
      { text: 'Asset', node_type: 'machine' }
    ];
  }

  componentDidMount() {
    this.setCurrentConfigIndex();
  }

  setCurrentConfigIndex = () => {
    const { machine_id, config_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    const { configList, treeNodes } = this.state;
    if (config_id && config_id >= 0) {
      const currentConfigIndex = _.findIndex(configList, config => config.id === config_id);
      this.setState({ currentConfigIndex });
    } else if (machine_id) {
      const config = hierarchyUtils.getNextConfigByMachineId(treeNodes, machine_id);
      if (config) {
        const configIndx = _.findIndex(configList, cf => cf.id === config.id);
        const currentConfigIndex = configIndx - 1;
        this.setState({ currentConfigIndex });
      }
    } else {
      this.setState({ currentConfigIndex: -1 });
    }
  }

  onClickUpArrow = () => {
    let { currentConfigIndex } = this.state;
    const { configList } = this.state;
    if (currentConfigIndex - 1 >= 0) {
      currentConfigIndex--;
      this.setState({ currentConfigIndex });
      const selectedConfig = configList[currentConfigIndex];
      this.selectNode(selectedConfig.id, selectedConfig.node_type);
    }
  }

  onClickDownArrow = () => {
    let { currentConfigIndex } = this.state;
    const { configList } = this.state;
    if (currentConfigIndex + 1 < configList.length) {
      currentConfigIndex++;
      this.setState({ currentConfigIndex });
      const selectedConfig = configList[currentConfigIndex];
      this.selectNode(selectedConfig.id, selectedConfig.node_type);
    }
  }

  handleKeyStrokes = (e) => {
    const { currentConfigIndex, configList } = this.state;
    if (e.keyCode !== KEYBOARD_CODES.UP_ARROW && e.keyCode !== KEYBOARD_CODES.DOWN_ARROW) return;
    if (e.keyCode === KEYBOARD_CODES.DOWN_ARROW && currentConfigIndex + 1 < configList.length) {
      this.onClickDownArrow();
    } else if (e.keyCode === KEYBOARD_CODES.UP_ARROW && currentConfigIndex - 1 >= 0) {
      this.onClickUpArrow();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.assetHierarchy.hierarchy !== prevProps.assetHierarchy.hierarchy) {
      this.setTreeNodesAndConfigList();
    }
    if (
      prevProps.breadcrumb.machine.id !== this.props.breadcrumb.machine.id ||
      prevProps.breadcrumb.config.id !== this.props.breadcrumb.config.id
    ) {
      this.setCurrentConfigIndex();
    }
  }

  setSavedisableState = (saveDisabled) => {
    this.setState({ saveDisabled });
  }

  reorderTree = (oldIndex, newIndex, node_type, node_id, nodes = this.state.treeNodes) => {
    nodes.forEach((node) => {
      if (node.node_type === node_type && node.id === node_id) {
        const el = nodes.splice(oldIndex, 1);
        nodes.splice(newIndex, 0, el[0]);
        this.setState({
          treeNodes: this.state.treeNodes,
          saveDisabled: false
        });
        // eslint-disable-next-line no-throw-literal
        throw null;
      }
      this.reorderTree(oldIndex, newIndex, node_type, node_id, node.children);
    });
  };

  setOrToggleNodeExpand = (nodeId, nodeType, nodes = this.state.treeNodes, expand = false) => {
    nodes.forEach((node) => {
      if (node.id === nodeId && node.node_type === nodeType) {
        node.expanded = !node.expanded;
        if (expand) node.expanded = true;
        this.setState({
          treeNodes: this.state.treeNodes
        });
        this.props.hierarchyUpdateActions.toggleNodeExpand(nodeId, nodeType);
        return;
      }
      this.setOrToggleNodeExpand(nodeId, nodeType, node.children, expand);
    });
  };

  selectNode = (nodeId, nodeType, expand = false, onlyToggleExpand = false) => {
    if (nodeType !== 'site') this.setOrToggleNodeExpand(nodeId, nodeType, this.state.treeNodes, expand);
    if (this.props.editMode) return;
    const { selected } = this.props.breadcrumb;
    if (nodeType === 'machine' && selected.id === nodeId && selected.type === nodeType && !expand) return;
    if (nodeType === 'location' && selected.id === nodeId && selected.type === nodeType) return;
    if (onlyToggleExpand) return;
    this.props.handleNodeClick(nodeId, nodeType, 'overview', false, null, true);
  };

  onEdit = (editMode) => {
    if (editMode) {
      this.props.setEditModeState(editMode);
    } else this.onCancel();
  }

  onCancel = () => {
    this.setTreeNodesAndConfigList();
    this.props.setEditModeState(false);
    this.setSavedisableState(true);
  }

  setSearchKey = key => this.setState({ searchKey: key });


  onSave = () => {
    const [areasPerSite, machinesPerArea] = hierarchyUtils.getUpdatedAreasAndMachines(this.props.assetHierarchy.hierarchy, this.state.treeNodes);
    const { assetDetailsActions } = this.props;
    machinesPerArea.forEach((area, idx) => assetDetailsActions.updateMachines(area, idx === machinesPerArea.length - 1));
    areasPerSite.forEach((site, idx) => assetDetailsActions.updateAreas(site, idx === areasPerSite.length - 1));
    this.setSavedisableState(true);
    this.props.setEditModeState(false);
  }

  toggleFilter = () => {
    this.setState(prevState => ({
      nodeFilterExpanded: !prevState.nodeFilterExpanded
    }));
  }

  selectFilter = (filter) => {
    this.setState({ nodeFilterSelected: filter });
  }

  handleFilterItemClick = (option) => {
    const { nodeFilterSelected } = this.state;
    this.toggleFilter();
    if (option.node_type === nodeFilterSelected) {
      this.setState({ nodeFilterSelected: null });
    } else {
      this.selectFilter(option.node_type);
      this.focusSearch();
    }
  }

  addWindowEventListener = () => {
    window.addEventListener('keydown', this.handleKeyStrokes);
  }

  removeWindowEvenListener = () => {
    window.removeEventListener('keydown', this.handleKeyStrokes);
  }

  focusSearch = () => {
    const input = document.querySelector('input[name="hierarchy-search"]');
    input.focus();
  }

  scrollNodeInView = (nodeType, nodeId) => {
    // TODO: use something beter than setTimeout
    setTimeout(() => {
      const node = document.querySelector(`span[name="${nodeType}_${nodeId}"]`);
      if (node) node.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 0);
  }

  deleteArea = (node) => {
    this.props.hierarchyUpdateActions.deleteArea(node.id).then(
      (res) => {
        toastr.success(res.message);
        this.props.assetDetailsActions.getHierarchy(false);
      },
      (error) => {
        toastr.error(error.message);
      }
    );
  }

  deleteMachine = (node) => {
    this.props.machineInfoActions.deleteMachine(node.id).then(
      (res) => {
        toastr.success(res.message);
        this.props.assetDetailsActions.getHierarchy(false);
      },
      (error) => {
        toastr.error(error.message);
      }
    );
  }


  deleteInactiveTags = (node) => {
    this.props.hierarchyUpdateActions.deleteTags(node).then(
      (res) => {
        toastr.success(res.message);
      },
      (error) => {
        toastr.error(error.message);
      }
    );
    this.toggleDeleteInactiveTagsModal();
  }

  getNodeMenuOptions = (node) => {
    const nodeType = node.node_type;
    switch (nodeType) {
      case 'site': {
        const options = [
          {
            text: 'Add Node',
            onClick: () => this.openAddNodeModal(node),
            resource: mapComponentToResource.Areas,
            operation: operations.Create
          },
          {
            text: 'Edit',
            onClick: () => history.push('/site-profile'),
            resource: mapComponentToResource.Accounts,
            operation: operations.Update
          },
          {
            text: 'Sort A - Z',
            onClick: () => this.setSortOrder(true),
            iconOperation: 'sort-ascending',
            selected: this.sortHierarchyAscending === true
          },
          {
            text: 'Sort Z - A',
            onClick: () => this.setSortOrder(false),
            iconOperation: 'sort-descending',
            selected: this.sortHierarchyAscending === false
          },
          {
            text: 'Delete all Inactive Tags',
            onClick: () => this.toggleDeleteInactiveTagsModal({ account_id: node.id }),
            resource: mapComponentToResource.Tags,
            operation: operations.Delete
          },
          {
            text: 'Restore List Order',
            onClick: () => this.restoreOrginalOrder(),
            iconOperation: 'restore-list',
            selected: this.sortHierarchyAscending === null
          }
        ];
        return options;
      }
      case 'area': {
        const options = [
          {
            text: 'Add Node',
            onClick: () => this.openAddNodeModal(node),
            resource: mapComponentToResource.Areas,
            operation: operations.Create
          },
          {
            text: 'Edit',
            onClick: () => this.openEditAreaModal(node, true),
            resource: mapComponentToResource.Areas,
            operation: operations.Update
          },
          {
            text: 'Compare',
            onClick: () => this.openCompareTagsModal(),
            resource: mapComponentToResource.ChartMultilineItem,
            iconOperation: 'compare',
            operation: operations.Create
          },
          {
            text: 'Delete all Inactive Tags',
            onClick: () => this.toggleDeleteInactiveTagsModal({ area_id: node.id, account_id: this.props.currentUser.account_id }),
            resource: mapComponentToResource.Tags,
            operation: operations.Delete
          },
          {
            text: 'Delete',
            onClick: () => this.openDeleteModal(node),
            resource: mapComponentToResource.Areas,
            operation: operations.Delete
          }
        ];
        if (node.children.length) options.pop();
        if (this.state.copiedAssetDetails) {
          options.push({
            text: 'Paste Asset',
            onClick: () => this.openAssetPasteModal(node),
            resource: mapComponentToResource.Machines,
            iconOperation: 'paste',
            operation: operations.Create
          });
        }
        return options;
      }
      case 'machine':
        return [
          {
            text: 'Edit',
            onClick: () => {
              this.selectNode(node.id, node.node_type, true);
              history.push(`/machines/${node.id}/info`);
            },
            resource: mapComponentToResource.Machines,
            operation: operations.Update
          },
          {
            text: 'Add Chart',
            onClick: () => this.openAddTrendModal(node),
            resource: mapComponentToResource.ChartMultilineItem,
            operation: operations.Create
          },
          {
            text: 'Copy Asset',
            onClick: () => this.openAssetCopyModal(node),
            resource: mapComponentToResource.Machines,
            iconOperation: 'copy',
            operation: operations.Create
          },
          {
            text: 'Delete',
            onClick: () => this.openDeleteModal(node),
            resource: mapComponentToResource.Machines,
            operation: operations.Delete
          },
          {
            text: 'Delete Envelope Alarms',
            onClick: () => this.openDeleteModal({ node_type: 'tags_envelopes', id: node.id }),
            resource: mapComponentToResource.Tags,
            operation: operations.Delete
          },
          {
            text: 'Delete Overall Alarms',
            onClick: () => this.openDeleteOverallAlarmsModal(node),
            resource: mapComponentToResource.Tags,
            operation: operations.Delete
          },
          {
            text: 'Delete all Inactive Tags',
            onClick: () => this.toggleDeleteInactiveTagsModal({ machine_id: node.id, account_id: this.props.currentUser.account_id }),
            resource: mapComponentToResource.Tags,
            operation: operations.Delete
          },
          {
            text: 'Compare',
            onClick: () => this.openCompareTagsModal(node),
            resource: mapComponentToResource.ChartMultilineItem,
            iconOperation: 'compare',
            operation: operations.Create
          }
        ];
      default:
        return [];
    }
  }

  openDeleteModal = (node) => {
    this.setState({
      deleteModal: true,
      editNode: { ...node }
    });
  }

  closeDeleteModal = () => {
    this.setState({
      deleteModal: false,
      editNode: null
    });
  }

  toggleDeleteInactiveTagsModal = (node = {}) => {
    this.setState(
      prevState => ({
        deleteInactiveTagsModal: !prevState.deleteInactiveTagsModal,
        editNode: { ...node }
      }));
  }

  onDeleteEnvelopeAlarms = (node) => {
    const { breadcrumb: { site } } = this.props;
    this.props.assetDetailsActions.getTags(node.id, site.id, false).then(
      (res) => {
        let tags = _.filter(res, (tag => tag.type === 'vibration'));
        tags = _.map(tags, t => ({ id: t.id, spectral_envelope: null, envelope_settings: null }));
        this.props.envelopeActions.bulkUpdateTags(node.id, { tags }).then(
          (res) => {
            toastr.success(res.message);
            this.props.assetDetailsActions.getTags(node.id, site.id);
          },
          (error) => {
            toastr.error(error.message);
          }
        );
      }
    );
  }

  deleteNode = (node) => {
    const nodeType = node.node_type;
    switch (nodeType) {
      case 'area':
        this.deleteArea(node);
        break;
      case 'machine':
        this.deleteMachine(node);
        break;
      case 'tags_envelopes':
        this.onDeleteEnvelopeAlarms(node);
        break;
      default:
        break;
    }
    this.closeDeleteModal();
  }

  openEditAreaModal = (node) => {
    this.setState({
      editAreaModal: true,
      editNode: { ...node }
    });
  }

  openAddNodeModal = (node) => {
    this.setState({
      addAreaModal: true,
      node: { ...node }
    });
  }

  closeAddNodeModal = () => {
    this.setState({
      addAreaModal: false,
      node: null
    });
  }

  closeAreaEditModal = () => {
    this.setState({
      editAreaModal: false,
      editNode: null
    });
  }

  openAssetCopyModal = (node) => {
    this.setState({
      assetCopyModal: true,
      editNode: { ...node }
    });
  }

  closeAssetCopyModal = () => {
    this.setState({
      assetCopyModal: false,
      editNode: null
    });
  }

  openAssetPasteModal = (node) => {
    this.setState({
      assetPasteModal: true,
      editNode: { ...node }
    });
  }

  closeAssetPasteModal = () => {
    this.setState({
      assetPasteModal: false,
      editNode: null
    });
  }

  openAddTrendModal = (node) => {
    this.setState({
      addTrendModal: true,
      editNode: { ...node }
    });
  }

  closeAddTrendModal = () => {
    this.setState({
      addTrendModal: false,
      editNode: null
    });
  }

  openCompareTagsModal = (node) => {
    this.setState({
      compareTagsModal: true,
      editNode: { ...node }
    });
  }

  closeCompareTagsModal = () => {
    this.setState({
      compareTagsModal: false,
      editNode: null
    });
  }

  openDeleteOverallAlarmsModal = (node) => {
    this.setState({
      deleteOverallAlarmsModal: true,
      editNode: { ...node }
    });
  }

  closeDeleteOverallAlarmsModal = () => {
    this.setState({
      deleteOverallAlarmsModal: false,
      editNode: null
    });
  }

  setCopiedAssetDetails = details => this.setState({ copiedAssetDetails: details })

  getAlertMessage = () => {
    const { editNode: { node_type } } = this.state;
    let placeholder = `this ${node_type}`;
    if (node_type === 'config') placeholder = 'this chart';
    else if (node_type === 'tags_envelopes') placeholder = 'all envelope alarms on this asset';
    return `Are you sure you want to delete ${placeholder} ?`;
  }

  getAlertMessageforInactiveTagsDeletion = () => {
    const { editNode: { node_type } } = this.state;
    const placeholder = `this ${node_type}`;
    return `Are you sure you want to delete all the inactive tags on ${placeholder} ?`;
  }

  getHierarchyFilterSvg = () => {
    const { nodeFilterSelected, nodeFilterExpanded } = this.state;
    return hierarchyUtils.getSvg(
      (nodeFilterSelected || 'filter'),
      (nodeFilterExpanded || nodeFilterSelected)
    );
  }


  sortHierarchy = (nodes, sortHierarchyAscending) => {
    const { currentAccount } = this.props;
    let treeNodes = hierarchyUtils.sortNodeList(nodes, sortHierarchyAscending);
    treeNodes = hierarchyUtils.sortLocationConfigs(treeNodes, currentAccount.preferences.vibration_direction_preference);
    return treeNodes;
  };


  setTreeNodesAndConfigList = (nodes = this.props.assetHierarchy.hierarchy) => {
    const treeNodes = this.sortHierarchy(_.cloneDeep(nodes), this.sortHierarchyAscending);
    this.setState({
      treeNodes,
      configList: hierarchyUtils.getNodeListFromHierarchy(treeNodes, 'config')
    }, this.setCurrentConfigIndex);
  }

  setSortOrder = (sortAscending) => {
    if (this.sortHierarchyAscending === sortAscending) return;
    this.sortHierarchyAscending = sortAscending;
    localStorage.setItem('sortHierarchyAscending', JSON.stringify(sortAscending));
    this.setTreeNodesAndConfigList(this.state.treeNodes);
  }

  restoreOrginalOrder = () => {
    if (this.sortHierarchyAscending !== null) {
      this.sortHierarchyAscending = null;
      localStorage.setItem('sortHierarchyAscending', null);
      this.setTreeNodesAndConfigList();
    }
  }

  render() {
    const {
      currentConfigIndex,
      configList,
      deleteModal,
      deleteInactiveTagsModal,
      addAreaModal,
      editAreaModal,
      node,
      editNode,
      assetCopyModal,
      assetPasteModal,
      deleteOverallAlarmsModal,
      nodeFilterExpanded,
      nodeFilterSelected,
      searchKey,
      searchActive,
      treeNodes,
      addTrendModal,
      compareTagsModal
    } = this.state;
    const { breadcrumb, hierarchyViewPane, primaryColor } = this.props;

    return (
    <>
      {deleteModal && (
        <AlertPrompt
          message={this.getAlertMessage()}
          secondaryMessage="This action can not be undone"
          onCancel={this.closeDeleteModal}
          onProceed={() => this.deleteNode(editNode)}
        />
      )}
      {deleteInactiveTagsModal && (
        <AlertPrompt
          message={this.getAlertMessageforInactiveTagsDeletion()}
          secondaryMessage="This action can not be undone"
          infoMessage="Data from disassociated sensors are considered as inactive tags"
          onCancel={this.toggleDeleteInactiveTagsModal}
          onProceed={() => this.deleteInactiveTags(editNode)}
        />
      )}
      {editAreaModal && (
        <AddAreaModal
          node={editNode}
          closeModal={this.closeAreaEditModal}
          editMode
          scrollNodeInView={this.scrollNodeInView}
        />
      )}
      {addAreaModal && (
        <AddAreaModal
          node={node}
          closeModal={this.closeAddNodeModal}
          scrollNodeInView={this.scrollNodeInView}
        />
      )}
      {assetCopyModal && (
        <AssetCopyModal
          assetInfo={editNode}
          setCopiedAssetDetails={this.setCopiedAssetDetails}
          closeModal={this.closeAssetCopyModal}
        />
      )}
      {assetPasteModal && (
        <AssetPasteModal
          areaInfo={editNode}
          closeModal={this.closeAssetPasteModal}
          details={this.state.copiedAssetDetails}
        />
      )}
      {deleteOverallAlarmsModal && (
        <DeleteOverallAlarmsModal
          assetInfo={editNode}
          site={breadcrumb.site}
          closeModal={this.closeDeleteOverallAlarmsModal}
        />
      )
      }
      {addTrendModal && (
        <AddTrendModal
          close={this.closeAddTrendModal}
          machineId={editNode.id}
        />
      )}
      {compareTagsModal && (
        <CompareTagsModal
          close={this.closeCompareTagsModal}
          machine={editNode.node_type === 'machine' ? editNode : undefined}
        />
      )}
      <Content direction="column">
        <HeaderContainer isVisible={!!hierarchyViewPane.currentWidth}>
          <Filter
            id="hierarchy-filter"
            open={nodeFilterExpanded}
            toggleOpen={this.toggleFilter}
            Icon={this.getHierarchyFilterSvg}
            alignHorizontal="left"
          >
            <FlexContainer direction="column" id="hierarchy-filter-options">
              {this.filterOptions.map((option, idx) => {
                const selected = option.node_type === nodeFilterSelected;
                return (
                  <FilterItem
                    key={idx.toString()}
                    width="119px"
                    selected={selected}
                    onClick={() => this.handleFilterItemClick(option)}
                  >
                    {hierarchyUtils.getSvg(option.node_type, selected)}
                    <ListOption selected={selected}>{option.text}</ListOption>
                  </FilterItem>
                );
              })}
            </FlexContainer>
          </Filter>
          <InputField
            name="hierarchy-search"
            onFocus={() => this.setState({ searchActive: true })}
            onBlur={() => !searchKey && this.setState({ searchActive: false })}
            type="text"
            prefix={<Search />}
            prefixSide="left"
            onChange={e => this.setSearchKey(e.target.value)}
            value={this.state.searchKey}
            placeholder="Search..."
            searchBox
            clearable
            clearInput={() => this.setSearchKey('')}
          />
          {(!searchActive && !nodeFilterSelected) && (
            <RBAC
              resource={mapComponentToResource.HierarchyView}
              operation={operations.Update}
              yes={(
                <SvgRowContainer>
                  <FlexContainer>
                    <SvgContainer
                      onClick={this.onClickUpArrow}
                    >
                      <ChevronUp style={{ color: currentConfigIndex < 1 ? '#D8D8D6' : '#999B95' }} />
                    </SvgContainer>
                    <SvgContainer
                      onClick={this.onClickDownArrow}
                    >
                      <ChevronDown style={{ color: currentConfigIndex >= configList.length - 1 ? '#D8D8D6' : '#999B95' }} />
                    </SvgContainer>
                    <SvgContainer onClick={() => this.onEdit(!this.props.editMode)}>
                      <EditIcon fill={this.props.editMode ? primaryColor : '#999B95'} />
                    </SvgContainer>
                  </FlexContainer>
                </SvgRowContainer>
              )}
              no={(
                <FlexContainer padding="0 2em 0 0">
                  <SvgRowContainer>
                    <SvgContainer
                      onClick={this.onClickUpArrow}
                    >
                      <ChevronUp style={{ color: currentConfigIndex < 1 ? '#D8D8D6' : '#999B95' }} />
                    </SvgContainer>
                    <SvgContainer
                      onClick={this.onClickDownArrow}
                    >
                      <ChevronDown style={{ color: currentConfigIndex >= configList.length - 1 ? '#D8D8D6' : '#999B95' }} />
                    </SvgContainer>
                  </SvgRowContainer>
                </FlexContainer>
              )}
            />
          )}
        </HeaderContainer>
        <CustomScrollContainer id="hierarchy" edit={this.props.editMode} open={!!hierarchyViewPane.currentWidth}>
        <ColumnLayout editMode={this.props.editMode}>
          {!searchKey && !nodeFilterSelected && (
            <LeftColumn
              onMouseEnter={this.addWindowEventListener}
              onMouseLeave={this.removeWindowEvenListener}
              width="100%"
            >
                <NodeList
                  treeNodes={treeNodes}
                  switchNode={this.selectNode}
                  selectedNodeId={breadcrumb.selected.id}
                  selectedNodeType={breadcrumb.selected.type}
                  reorderTree={this.reorderTree}
                  editMode={this.props.editMode}
                  getNodeMenuOptions={this.getNodeMenuOptions}
                />
            </LeftColumn>
          )}
          {(searchKey || nodeFilterSelected) && (
            <LeftColumn width="100%">
              <SearchList
                searchKey={searchKey}
                selectedNodeType={nodeFilterSelected}
                treeNodes={treeNodes}
                switchNode={this.selectNode}
                clearSearch={() => this.setSearchKey('')}
                clearFilter={() => this.selectFilter(null)}
                scrollNodeInView={this.scrollNodeInView}
              />
            </LeftColumn>
          )}
          {this.props.editMode && (
          <FooterFlex width={`${this.props.hierarchyViewPane.currentWidth}px`} alignHorizontal="right">
            <Button
              cancel
              onClick={this.onCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.saveDisabled}
              onClick={this.onSave}
            >
              Save
            </Button>
          </FooterFlex>
          )}
        </ColumnLayout>
        </CustomScrollContainer>
      </Content>
    </>
    );
  }
}

const mapStateToProps = state => ({
  assetHierarchy: state.assetHierarchyReducer.assetInfo,
  currentUser: state.user.user,
  currentAccount: state.currentAccount,
  breadcrumb: state.breadcrumb,
  hierarchyViewPane: state.hierarchyViewPane,
  primaryColor: state.companyReducer.partner.theme.primaryColor
});

const mapDispatchToProps = dispatch => ({
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch),
  hierarchyUpdateActions: bindActionCreators(hierarchyUpdateActions, dispatch),
  machineInfoActions: bindActionCreators(machineInfoActions, dispatch),
  envelopeActions: bindActionCreators(envelopeActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HierarchyView);
