import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';

import {
  getFreqConversionFactor,
  getScaledEnvelope,
  getAmplitudeConversionReference,
  convertToDbScale,
  convertFromDbScale
} from 'common/utils';
import colors from 'common/styles/colors';
import { getShaftSpeed } from 'home/AssetHierarchy/helpers/helpers';
import { ASSET_TYPE } from 'common/charts/constants';
import { X_SCALE } from 'home/AssetHierarchy/constants/envelope.constants';

import { LineChart } from '../../../../../common/components/Chart/hoc/LineChart';
import InlineModal from '../../../../../common/components/Chart/components/InlineModal';
import { KEYBOARD_CODES, markersToSkip } from '../../../../../common/constants';
import { getComponentTypeShortHand } from '../../../../AssetHierarchy/constants/assetHierarchy.constants';
import Text from '../../../../../common/typography/Text/Text';
import Button from '../../../../../common/components/atoms/Button';
import Label from '../../../../../common/typography/Label/Label';
import FlexContainer from '../../../../../common/components/atoms/FlexContainer';
import NewPeakModal from '../../../../../common/components/Chart/components/NewPeakModal';

import * as graphUtils from '../../../../../common/components/Chart/utils/graphUtils';
import * as helpers from '../../../../../common/components/Chart/utils/helpers';
import { toFixedXIfNumber, isElementPartiallyVisible } from '../../../../../common/utils';
import { humanize } from '../../../../../common/helpers/humanize';

const FrequencyTypeContainer = styled(Label)`
  color: ${colors.greyXD};
  margin-right: 5px;
`;

const FreqContributionContainer = styled(Label)`
  margin-right: 8px;
  font-size: 10px;
`;

const FeatureInfoContainer = styled.div`
  margin: 0;
  margin-bottom: 1em;
  margin-right: 1.5em;
  ${Label} {
    display: block;
  }
  ${Text} {
    font-size: 14px;
  }
`;
const FeatureContainerButton = styled(Button)`
  margin: 0;
  margin-top: 1em;
  margin-right: 1.5em;
  font-weight: 600;
`;

const FrequencyConversionButton = styled(Button)`
  position: relative;
  left: ${props => props.left}px;
  top: 18px;
  font-size: 13px;
  font-weight: 600;
  &:disabled {
    color: ${colors.green};
    cursor: not-allowed;
  }
`;

const ComputeContainer = styled(FlexContainer)`
  border: 0.1px ${colors.white} solid;
  border-radius: 4px;
  width: auto;
  height: 20px;
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.16);
  position: absolute;
  top: 5px;
  right: 30px;
`;

const X_UNITS_MIN_PIXEL_DIFFERENCE = 30;

class SpectrumChart extends Component {
  constructor(props) {
    super(props);
    this.drawCursor = this.drawCursor.bind(this);
    this.drawCursors = this.drawCursors.bind(this);
    this.drawCursorInfotext = this.drawCursorInfotext.bind(this);
    this.drawFeatures = this.drawFeatures.bind(this);
    this.drawFeature = this.drawFeature.bind(this);
    this.highlightSelectedFeature = this.highlightSelectedFeature.bind(this);
    this.closeFeatureContainer = this.closeFeatureContainer.bind(this);
    this.addDoubleClickEventListener = this.addDoubleClickEventListener.bind(this);
    this.addRightMouseClickEvent = this.addRightMouseClickEvent.bind(this);
    this.toggleXaxisUnits = this.toggleXaxisUnits.bind(this);
    this.toggleYaxisUnits = this.toggleYaxisUnits.bind(this);
    this.storeBrushSelection = this.storeBrushSelection.bind(this);

    const desiredXScale = props.assetType === ASSET_TYPE.VFD ? X_SCALE.ORDERS : props.currentUser.spectrum_x_scale;
    this.state = {
      dataPos: {
        y: false,
        x: false
      },
      showFeatureContainer: null,
      harmonicNumber: 1,
      dataToDisplay: null,
      xUnit: props.xUnit,
      yUnit: props.yUnit,
      envelopeXUnit: props.envelopeXUnit,
      features: props.features ? this.getFeatures() : { items: [] },
      storeBrushSelection: [],
      dataPoint: {},
      cursorType: null,
      spectralEnvelope: props.spectralEnvelope,
      points: [],
      showNewPeakModal: false,
      frequencyContribution: {},
      allowYUnitToggle: this.allowYUnitToggle(),
      spectrumXScale: props.spectrumXScale ? props.spectrumXScale : desiredXScale
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyStrokes);
    if (!_.isEmpty(this.props.data)) {
      const shaft_speed = this.getShaftSpeed();
      if (this.state.spectrumXScale === 'orders' && shaft_speed) this.toggleXaxisUnits('Orders');
    }
    if (this.props.tag_type === 'vibration') this.calculateFrequenciesContribution();

    this.scaleEnvelopeIfRequired();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyStrokes);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.originalData, prevProps.originalData)) {
      const { initialYunit } = this.props;
      this.setState({
        yUnit: initialYunit,
        features: this.getFeatures(),
      },
      () => {
        this.drawFeatures();
        const shaft_speed = this.getShaftSpeed();
        if (this.state.spectrumXScale === 'orders' && shaft_speed) this.toggleXaxisUnits('Orders');
      });
      if (this.props.tag_type === 'vibration') this.calculateFrequenciesContribution();
    }

    if (!_.isEqual(this.props.tag_type, prevProps.tag_type) ||
      !_.isEqual(this.props.updateEnvelopeData, prevProps.updateEnvelopeData)) {
      this.setState({
        allowYUnitToggle: this.allowYUnitToggle()
      });
    }

    if (_.isEmpty(prevProps.data) && !_.isEmpty(this.props.data)) {
      const shaft_speed = this.getShaftSpeed();
      if (this.state.spectrumXScale && shaft_speed) this.toggleXaxisUnits('Orders');
    }

    if (!_.isEqual(this.props.xUnit, prevProps.xUnit)) {
      this.setState({ xUnit: this.props.xUnit });
    }

    if (!_.isEqual(prevProps.features, this.props.features) && this.props.features) {
      this.drawFeatures();
    }

    if (prevProps.shaftSpeed && this.props.shaftSpeed && !_.isEqual(prevProps.shaftSpeed, this.props.shaftSpeed)) {
      const { xUnit } = this.props;
      if (xUnit === 'Orders') {
        this.setState({
          features: this.getFeatures(),
        }, this.toggleXaxisUnits('Orders'));
      }
    }

    if (!_.isEqual(prevState.features, this.state.features)) {
      this.drawFeatures();
    }
    if (this.props.excluded_features && !_.isEqual(prevProps.excluded_features, this.props.excluded_features)) {
      this.drawFeatures();
      this.highlightSelectedFeature();
    }
    if (this.props.measurementTimestamp && !_.isEqual(prevProps.measurementTimestamp, this.props.measurementTimestamp)) {
      this.setState({ features: this.getFeatures() });
      this.drawFeatures();
    }
    if (!_.isEqual(prevState.dataToDisplay, this.state.dataToDisplay)) {
      this.highlightSelectedFeature();
    }

    if (!_.isEqual(this.props.spectralEnvelope, prevProps.spectralEnvelope)) {
      this.setState({
        spectralEnvelope: this.props.spectralEnvelope,
        envelopeXUnit: this.props.envelopeXUnit
      }, this.drawEnvelope);
    }

    this.scaleEnvelopeIfRequired();
  }

  scaleEnvelopeIfRequired = () => {
    const { spectralEnvelope, envelopeXUnit, xUnit } = this.state;
    const { shaftSpeedUnit } = this.props;
    if (envelopeXUnit !== xUnit) {
      const newEnvelope = getScaledEnvelope(
        spectralEnvelope,
        envelopeXUnit,
        xUnit,
        this.getShaftSpeed(),
        shaftSpeedUnit
      );
      this.setState({
        spectralEnvelope: newEnvelope,
        envelopeXUnit: xUnit
      }, this.drawEnvelope);
    }
  }

  allowYUnitToggle = () => {
    const { tag_type, updateEnvelopeData, toggleSpectrumAmplitudeUnits } = this.props;
    if ((tag_type === 'vibration') && !updateEnvelopeData && toggleSpectrumAmplitudeUnits) return true;
    return false;
  }

  getShaftSpeed = () => {
    const {
      shaftSpeed: spectrumShaftSpeed,
      componentNode,
      measurementTimestamp,
      initialXunit,
      setShaftSpeedState,
      features,
      useSpectrumShaftSpeed
    } = this.props;

    const shaftSpeed = getShaftSpeed(spectrumShaftSpeed, useSpectrumShaftSpeed, componentNode, measurementTimestamp, initialXunit, features);
    if (setShaftSpeedState) setShaftSpeedState(shaftSpeed);
    return shaftSpeed;
  }

  getComponentLocalCustomMarkers = () => {
    const localCustomMarkers = JSON.parse(localStorage.getItem('custom_markers'));
    const { componentNode } = this.props;
    if (!componentNode || !localCustomMarkers || !componentNode.id) return null;
    return localCustomMarkers[componentNode.id];
  }

  getDataInCorrectXUnit = (newXUnit, shaftSpeed) => {
    const { initialXunit, originalData, data } = this.props;
    const factor = getFreqConversionFactor(initialXunit, newXUnit, shaftSpeed, initialXunit);
    const spectrumData = originalData.map((dataset, dataidx) => dataset.map((d, didx) => ({ x: d.x * factor, y: data[dataidx][didx].y })));
    return spectrumData;
  }

  getDataInCorrectYunit = (newYUnit) => {
    const { initialYunit, originalData, data } = this.props;
    const reference = getAmplitudeConversionReference(initialYunit, newYUnit);
    if (!reference) return originalData.map((dataset, dataidx) => dataset.map((d, didx) => ({
      x: data[dataidx][didx].x,
      y: d.y
    })));
    let spectrumData = null;
    if (newYUnit.toLowerCase() === 'db') {
      spectrumData = originalData.map((dataset, dataidx) => dataset.map((d, didx) => ({
        x: data[dataidx][didx].x,
        y: convertToDbScale(d.y, reference)
      })));
    } else if (initialYunit.toLowerCase() === 'db') {
      spectrumData = originalData.map((dataset, dataidx) => dataset.map((d, didx) => ({
        x: data[dataidx][didx].x,
        y: convertFromDbScale(d.y, reference)
      })));
    }
    return spectrumData;
  }

  getFeatures = () => {
    const features = _.cloneDeep(this.props.features);
    if (!this.props.componentNode) return features;
    const { componentNode: { node_details: { component_type } } } = this.props;

    if (!this.props.measurementTimestamp) return features;
    const measurementTimestampInISO = moment(this.props.measurementTimestamp).toISOString();

    const componentLocalMarkers = this.getComponentLocalCustomMarkers();
    if (!componentLocalMarkers || !componentLocalMarkers[measurementTimestampInISO]) {
      if (!features) return { items: [] };
      return features;
    }

    const newPeakValue = componentLocalMarkers[measurementTimestampInISO].speed;

    let isSpeedMarkerPresent = false;

    features.items.forEach((feature) => {
      if (feature.feature_type === 'forcing_frequencies' && feature.multiplier === 1) {
        isSpeedMarkerPresent = true;
      }
    });

    const unitConversionFactor = getFreqConversionFactor('Hz', this.props.xUnit, newPeakValue);

    if (!isSpeedMarkerPresent) {
      // set this so that new marker is updated
      // Create new feature marker from template
      const marker = {
        marker: `1${getComponentTypeShortHand(component_type)}`,
        frequency: newPeakValue * unitConversionFactor,
        freq_units: this.props.xUnit,
        name: 'Shaft Speed',
        description: 'Shaft speed',
        type: 'individual',
        user_id: null,
        tag_id: null,
        feature_type: 'forcing_frequencies',
      };
      features.items.push(marker);
    }

    features.items = features.items.map((feature) => {
      if ((feature.feature_type === 'forcing_frequencies') && !markersToSkip.has(feature.marker) && !_.isNil(feature.multiplier)) {
        feature.frequency = newPeakValue * unitConversionFactor * feature.multiplier;
        feature.freq_units = this.props.xUnit;
      }
      return feature;
    });

    return features;
  }

  calculateFrequenciesContribution = () => {
    const { fmax, lines_of_resolution, originalData } = this.props;
    const shaftSpeed = this.getShaftSpeed();
    if (!shaftSpeed || !lines_of_resolution) return;
    const offset = 2 * fmax / lines_of_resolution;
    let sync = 0;
    let subSync = 0;
    let nonSync = 0;
    originalData[0].forEach((dataPoint) => {
      const freq = _.round(dataPoint.x, 1);
      const amplitude = dataPoint.y;
      const remainder = freq % shaftSpeed;
      if (remainder <= offset || (shaftSpeed - remainder) <= offset) { // frequency lies in offset region
        sync += (amplitude ** 2);
      } else if (freq < shaftSpeed) subSync += (amplitude ** 2);
      else {
        nonSync += (amplitude ** 2);
      }
    });
    sync = Math.sqrt(sync);
    subSync = Math.sqrt(subSync);
    nonSync = Math.sqrt(nonSync);
    const totalAmplitudeSum = sync + subSync + nonSync;
    this.setState({
      frequencyContribution: {
        sync: _.round(sync / totalAmplitudeSum * 100, 1),
        subSync: _.round(subSync / totalAmplitudeSum * 100, 1),
        nonSync: _.round(nonSync / totalAmplitudeSum * 100, 1),
        totalAmplitudeSum
      }
    });
  };

  selectedFrequencyContribution = () => {
    const { fmax, lines_of_resolution, originalData } = this.props;
    if (!lines_of_resolution || _.isEmpty(originalData)) return 0;
    const factor = getFreqConversionFactor(this.state.xUnit, 'Hz', this.props.shaftSpeed, this.props.initialXunit);
    const selectedFrequency = this.getXDataPoint() * factor;
    const offset = 2 * fmax / lines_of_resolution;
    let selectedFreqContribution = 0;
    let peakAmplitudeForHarmonic = 0;
    originalData[0].forEach((dataPoint) => {
      const freq = _.round(dataPoint.x, 1);
      const amplitude = _.round(dataPoint.y, 3);
      const remainder = freq % selectedFrequency;
      if (remainder <= offset || (selectedFrequency - remainder) <= offset) {
        peakAmplitudeForHarmonic = _.max([peakAmplitudeForHarmonic, amplitude]);
      } else {
        selectedFreqContribution += peakAmplitudeForHarmonic;
        peakAmplitudeForHarmonic = 0;
      }
    });
    selectedFreqContribution += peakAmplitudeForHarmonic;
    return _.round(selectedFreqContribution / this.state.frequencyContribution.totalAmplitudeSum * 100, 0);
  }

  setNewPeak = () => {
    const { componentNode } = this.props;
    if (!componentNode) return;
    const localCustomMarkers = JSON.parse(localStorage.getItem('custom_markers')) || {};
    const componentLocalMarkers = this.getComponentLocalCustomMarkers() || {};

    if (!this.props.measurementTimestamp) return;

    const measurementTimestampInISO = moment(this.props.measurementTimestamp).toISOString();

    const selection = this.ctx.props.selection;
    let speed = _.isArray(selection) ? selection[0].x : selection.x;

    const factor = getFreqConversionFactor(this.state.xUnit, 'Hz', this.props.shaftSpeed, this.props.initialXunit);
    // speed is always stored in HZ
    speed *= factor;

    componentLocalMarkers[measurementTimestampInISO] = { speed };
    localCustomMarkers[componentNode.id] = componentLocalMarkers;
    localStorage.setItem('custom_markers', JSON.stringify(localCustomMarkers));

    this.getShaftSpeed();
    // Close the Modal and set new features
    this.setState({
      showNewPeakModal: false,
      features: this.getFeatures()
    });
  }

  setHarmonicNumber = (e) => {
    this.setState({ harmonicNumber: parseInt(e.target.value, 10) });
  }

  updateHarmonicFrequencies = () => {
    if (this.state.harmonicNumber < 1 || this.state.harmonicNumber > 500 || _.isNaN(this.state.harmonicNumber)) {
      this.setState({
        harmonicNumber: parseInt('1', 10),
        showNewPeakModal: false
      });
      return;
    }

    if (this.state.cursorType) this.disableCursors();

    const p = [
      _.isArray(this.ctx.props.selection) ? _.cloneDeep(this.ctx.props.selection[0]) : _.cloneDeep(this.ctx.props.selection),
      _.isArray(this.ctx.props.selection) ? _.cloneDeep(this.ctx.props.selection[0]) : _.cloneDeep(this.ctx.props.selection)
    ];
    this.props.setPointsToCorrectUnits(p);
    this.setState(
      {
        points: p
      }, this.setNthHarmonic
    );
  }

  // keyCode left: 37, right: 39
  handleKeyStrokes = (e) => {
    if (!this.ctx || !this.ctx.chart || !this.ctx.x || this.props.lastHoveredChart !== this.props.type) return;

    if (e.keyCode === KEYBOARD_CODES.H && !this.props.split) {
      const p = [
        _.isArray(this.ctx.props.selection) ? _.cloneDeep(this.ctx.props.selection[0]) : _.cloneDeep(this.ctx.props.selection),
        _.isArray(this.ctx.props.selection) ? _.cloneDeep(this.ctx.props.selection[0]) : _.cloneDeep(this.ctx.props.selection)
      ];
      this.props.setPointsToCorrectUnits(p);
      this.setState(
        {
          harmonicNumber: 1,
          points: p
        },
        this.setNthHarmonic
      );
    }

    if (e.keyCode === KEYBOARD_CODES.ENTER && this.state.showNewPeakModal) {
      this.updateHarmonicFrequencies();
    }

    if (e.keyCode === KEYBOARD_CODES.S) {
      if (!this.state.cursorType) {
        this.ctx.props.mouseClick();

        if (this.state.points.length === 0) return;

        const x1 = this.state.points[0].x;
        const indexR = this.state.points[0].indexOnDataset;

        let nextPoint = null;
        let nextIndex = indexR;
        while (!nextPoint) {
          nextIndex += 1;
          if (this.ctx.x(this.ctx.props.data[0][nextIndex].x - x1) >= X_UNITS_MIN_PIXEL_DIFFERENCE) {
            nextPoint = this.ctx.props.data[0][nextIndex];
          }
        }

        this.drawCursors(this.ctx, nextPoint, 'sideband');
        nextPoint.indexOnDataset = nextIndex;
        this.setState({
          dataPoint: nextPoint,
          cursorType: 'sideband'
        });

        this.drawCursorInfotext(this.ctx, nextPoint, 'sideband');
        this.ctx.props.mouseClick();
      } else if (_.isEqual(this.state.cursorType, 'sideband')) {
        // sidebands already enabled, disable it
        this.ctx.props.mouseClick();
        this.disableCursors();
      }
    }

    if (e.keyCode !== KEYBOARD_CODES.LEFT_ARROW && e.keyCode !== KEYBOARD_CODES.RIGHT_ARROW) return;
    const isVisible = isElementPartiallyVisible(this.ctx.chart);
    if (!isVisible) return;
    const { dataPoint, cursorType, points } = _.cloneDeep(this.state);
    const { data } = this.props;

    if (cursorType === 'sideband') {
      const { indexOnDataset } = dataPoint;
      const { indexOnDataset: referenceIndex } = points[0];
      let newIndex = -1;
      if (e.keyCode === KEYBOARD_CODES.LEFT_ARROW) {
        if (Math.abs(indexOnDataset - referenceIndex) < 10) return;
        if (indexOnDataset < referenceIndex) newIndex = indexOnDataset + 1;
        else newIndex = indexOnDataset - 1;

        if (newIndex < 0 || newIndex > data[0].length - 1) return;
        dataPoint.x = data[0][newIndex].x;
        dataPoint.indexOnDataset = newIndex;
      } else if (e.keyCode === KEYBOARD_CODES.RIGHT_ARROW) {
        if (indexOnDataset < referenceIndex) newIndex = indexOnDataset - 1;
        else newIndex = indexOnDataset + 1;

        if (newIndex < 0 || newIndex > data[0].length - 1) return;
        dataPoint.x = data[0][newIndex].x;
        dataPoint.indexOnDataset = newIndex;
      }

      const diffXvalue = Math.abs(dataPoint.x - this.state.points[0].x);
      dataPoint.text = Math.round(diffXvalue);

      this.setState({ dataPoint });
      this.drawCursors(this.ctx, dataPoint, cursorType);
      this.drawCursorInfotext(this.ctx, dataPoint, cursorType);
    } else if (cursorType === 'harmonic') {
      const { indexOnDataset } = points[0];
      let newIndex = -1;
      if (e.keyCode === KEYBOARD_CODES.LEFT_ARROW) {
        newIndex = indexOnDataset - 1;
        if (newIndex < 1) return;
        points.forEach((p) => {
          p.x = data[0][newIndex].x;
          p.indexOnDataset = newIndex;
          p.y = data[0][newIndex].y;
        });
      } else if (e.keyCode === KEYBOARD_CODES.RIGHT_ARROW) {
        newIndex = indexOnDataset + 1;
        if (newIndex > data[0].length) return;
        points.forEach((p) => {
          p.x = data[0][newIndex].x;
          p.indexOnDataset = newIndex;
          p.y = data[0][newIndex].y;
        });
      }
      this.props.setPointsToCorrectUnits(points);
      this.setState({ points },
        () => {
          this.drawCursors(this.ctx, dataPoint, cursorType);
          this.drawCursorInfotext(this.ctx, points[0], cursorType);
        });
    } else {
      const direction = e.keyCode === KEYBOARD_CODES.LEFT_ARROW ? 'left' : 'right';
      let selections = this.ctx.props.selection;

      if (!_.isArray(selections)) {
        selections = [selections];
      }

      const newSelections = selections.map((selection, idx) => {
        const dataset = this.ctx.props.data[idx];
        return ({ ...selection, ...helpers.getNextDataPoint(dataset, selection.x, direction) });
      });

      this.ctx.props.mouseover(newSelections.length > 1 ? newSelections : newSelections[0]);
    }
  }

  /* eslint-disable */
  drawSelection(ctx, selection){
    d3.selectAll(`.${ctx.props.chartName}.selection`).remove();
    let selections = [];
    if (!_.isArray(selection)) selections = [_.cloneDeep(selection)]
    else selections = selection
    const height = ctx.chart.getBoundingClientRect().height
    selections.forEach((dataPoint) => {
      graphUtils.drawLineOnPos(ctx.chart, ctx.x(dataPoint.x), 0, ctx.x(dataPoint.x), height, `${ctx.props.chartName} selection`)
        .attr('pointer-events', 'none');
    });

    selections.forEach((dataPoint) => {
      graphUtils.drawCircleOnPos(ctx.chart, ctx.x(dataPoint.x), ctx.y(dataPoint.y), 3, `${ctx.props.chartName} selection`)
        .attr('pointer-events', 'none')
        .attr('fill', ctx.props.colors ? ctx.props.colors[dataPoint.dataIdx] : colors.green);
    });
  }

  drawEnvelope = () => {
    if (!this.ctx) return;
    const { chartName } = this.props;
    const { spectralEnvelope } = this.state;
    if (!spectralEnvelope) return;
    d3.selectAll(`.${chartName}.step-graph.dataline`).remove();
    const { start_frequencies, end_frequencies, amplitudes } = spectralEnvelope.envelope_data;
    graphUtils.drawStepGraph(
      start_frequencies,
      end_frequencies,
      amplitudes,
      chartName,
      this.ctx.chart,
      this.ctx.x,
      this.ctx.y
    );
  }

  drawEnvelopeToolTip = (point) => {
    const { chartName } = this.props;
    d3.selectAll(`.${chartName}.step-graph.circle`).remove();
    if (!this.ctx || !point || !point.y) return;
    const xPos = this.ctx.x(point.x);
    if (xPos > 0 && xPos < this.ctx.chart.getBoundingClientRect().width) {
      graphUtils.drawCircleOnPos(
        this.ctx.chart,
        xPos,
        this.ctx.y(point.y),
        3,
        `${chartName} step-graph`
      ).attr('pointer-events', 'none');
    }
  }

  drawGraph(ctx) {
    if (this.cursors) {
      this.redrawCursors(ctx);
    }

    this.ctx = ctx;
    this.cursorContainer = d3.select(ctx.chart)
      .append('g')
      .attr('width', ctx.chart.getBoundingClientRect().width)
      .attr('height', ctx.chart.getBoundingClientRect().height)
      .attr('class', `${this.props.chartName} cursorGroupContainer`);

    this.featureContainer = d3.select(ctx.chart)
      .append('g')
      .attr('width', ctx.chart.getBoundingClientRect().width)
      .attr('height', ctx.chart.getBoundingClientRect().height)
      .attr('class', `${this.props.chartName} featureGroupContainer`);

    this.drawFeatures();
    this.addDoubleClickEventListener(ctx);
    this.addRightMouseClickEvent(ctx);
    if (this.props.spectralEnvelope) this.drawEnvelope();
  }

  drawPoints(ctx, points) {
    points.map(point => helpers.closestXDataPointOnDataset(ctx.props.data[0],point.x))
    if (points.length === 1) {
      this.drawCursor(ctx, { ...points[0], text: 'R' }, 'sideband');
      this.setState({
        points,
        drawingCursor: true
      });
    } else if (points.length === 2) {
      this.setState({
        points,
        drawingCursor: false
      });
    } else if (points.length === 0) {
      this.disableCursors();
    }
  }

  disableCursors = () => {
    this.cursors = [];
    d3.selectAll(`.${this.props.chartName}.cursorGroup`).remove();
    d3.selectAll(`.${this.props.chartName}.cursorInfo`).remove();
    this.setState({
      points: [],
      drawingCursor: false,
      dataPoint: {},
      cursorType: null
    });
  }

  redrawCursors(ctx){
    d3.selectAll(`.${this.props.chartName}.cursorGroup`).remove();

    this.cursors.forEach((cursor) => {
      if (ctx.x(cursor.x) > ctx.chart.getBoundingClientRect().width || ctx.x(cursor.x) < 0) return;
      this.drawCursor(ctx, cursor, this.state.cursorType);
    });
  }

  drawCursorInfotext(ctx, dataPoint, cursorType, xUnit = null) {
    const { harmonicNumber } = this.state;
    const { shaftSpeed } = this.props;
    const data = dataPoint.x / harmonicNumber;
    const { initialXunit } = this.props; 
    let titleToDisplay;
    if (ctx.props.xUnit === 'Orders' && initialXunit && shaftSpeed) {
      titleToDisplay = `Harmonics of ${toFixedXIfNumber(data * shaftSpeed,2)}${initialXunit} (${toFixedXIfNumber(data,2)}${xUnit || ctx.props.xUnit})`;
    }
    else titleToDisplay = `Harmonics of ${toFixedXIfNumber(data,2)} ${xUnit || ctx.props.xUnit}`

    d3.selectAll(`.${this.props.chartName}.cursorInfo`).remove();
    let featureSpace = 0;
    if (this.props.features && this.props.features.items && this.props.features.items.length > 0) {
      featureSpace = -16;
    }
    if (cursorType === 'sideband') {
      d3.select(ctx.chart)
        .append('text')
        .attr('class', `${this.props.chartName} cursorInfo`)
        .attr('dx', 0)
        .attr('dy', -20 + featureSpace)
        .text(`Reference: ${toFixedXIfNumber(this.state.points[0].x, 0)} ${xUnit || ctx.props.xUnit}`);

      d3.select(ctx.chart)
        .append('text')
        .attr('class', `${this.props.chartName} cursorInfo`)
        .attr('dx', 0)
        .attr('dy', -5 + featureSpace)
        .text(`Sideband: ${toFixedXIfNumber(Math.abs(dataPoint.x - this.state.points[0].x), 1)} ${xUnit || ctx.props.xUnit}`);
    } else if (cursorType === 'harmonic') {
      d3.select(ctx.chart)
        .append('text')
        .attr('class', `${this.props.chartName} cursorInfo`)
        .attr('dx', 0)
        .attr('dy', -5 + featureSpace)
        .text(titleToDisplay);
    }
  }

  addMouseoverEventListener(ctx) {
    if (this.mouseover) return;
    // this is really slow on linechart
    this.mouseover = graphUtils.addMouseoverEvent(ctx.chart, () => {
      if (this.state.showNewPeakModal) return;
      const dataPoint = helpers.closestExactDataPoint(ctx);
      
      if (this.state.drawingCursor) {
        this.drawCursors(ctx, dataPoint, 'sideband');
        this.setState({
          dataPoint,
          cursorType: 'sideband'
        });
        this.drawCursorInfotext(ctx, dataPoint, 'sideband');
      } else {
        const { spectralEnvelope } = this.state;
        const selection = { ...dataPoint };
        if (spectralEnvelope) {
          const envelopeDataPoint = helpers.getClosestStepGraphPoint(
            spectralEnvelope.envelope_data,
            dataPoint.x
          );
          this.drawEnvelopeToolTip(envelopeDataPoint);
          if (!_.isEmpty(envelopeDataPoint)) {
            selection.envelopeAmplitude = envelopeDataPoint.y;
          }
        }
        ctx.props.mouseover(selection, this.props.chartName);
      }
    });
  }

  addDoubleClickEventListener(ctx) {
    if (this.doubleClick && !this.props.doubleClick) return;

    this.doubleClick = graphUtils.addDoubleClickEvent(ctx.chart, () => {
      const p = [
        _.isArray(this.ctx.props.selection) ? _.cloneDeep(this.ctx.props.selection[0]) : _.cloneDeep(this.ctx.props.selection),
        _.isArray(this.ctx.props.selection) ? _.cloneDeep(this.ctx.props.selection[0]) : _.cloneDeep(this.ctx.props.selection)
      ];
      this.props.setPointsToCorrectUnits(p);
      this.setState(
        {
          harmonicNumber: 1,
          points: p
        }, this.setNthHarmonic
      )
    });
  }

  addRightMouseClickEvent(ctx) {
    if(this.rightClick) return;

    this.rightClick = graphUtils.addRightMouseClickEvent(ctx.chart, () => {
      const selection = this.ctx.props.selection;
      const dataPos = {
        x: this.ctx.x(_.isArray(selection) ? selection[0].x : selection.x) - 60, // '60' to compensate for the value in ChartPopupContainer
        y: this.ctx.y(_.isArray(selection) ? selection[0].y : selection.y)
      };
      this.setState({
        dataPos,
        showNewPeakModal: true
      });
    })
  }

  setNthHarmonic = () => {
    if (!this.state.cursorType) {
      if (this.state.points.length === 0) return;
      const dataPoint = helpers.closestXDataPointOnDataset(this.ctx.props.data[0], 0);
      this.drawCursors(this.ctx, dataPoint, 'harmonic');
      this.drawCursorInfotext(this.ctx, this.ctx.props.points[0], 'harmonic');
      this.setState({
        drawingCursor: false,
        dataPoint,
        cursorType: 'harmonic',
      });
    } else if (_.isEqual(this.state.cursorType, 'harmonic')) {
      this.disableCursors();
    }

    this.setState({
      showNewPeakModal: false
    });
  }

  drawCursor(ctx, point, cursorType=null) { 
    if (!cursorType) return;

    const points = ctx.props.data.map((dataSet) => {
      if (cursorType === 'harmonic') {
        const yValue = helpers.interpolateYDataPointOnDataset(dataSet, point.x);
        return {
          ...point,
          y: yValue.y
        }
      }
      return helpers.closestXDataPointOnDataset(dataSet, point.x);
    });
    
    const cursorG = this.cursorContainer
      .append('g')
      .attr('class', `${this.props.chartName} cursorGroup`)
      .attr('transform', `translate(${ctx.x(point.x)}, 0)`);

    graphUtils.drawLineOnPos(cursorG.node(), 0, 0, 0, ctx.chart.getBoundingClientRect().height, `${this.props.chartName} cursorSelection`)
      .attr('stroke-width', 3)
      .attr('stroke-dasharray', (5, 5));

    cursorG.append('text')
      .attr('class', `${this.props.chartName} cursorValue`)
      .attr('dx', 3)
      .attr('dy', 12)
      .text(point.text || toFixedXIfNumber(point.x, 0));

    points.map((point,idx) => graphUtils.drawCircleOnPos(cursorG.node(), 0, ctx.y(point.y), 4,`${this.props.chartName} cursorSelection`)
      .attr('fill', ctx.props.colors ? ctx.props.colors[idx] : colors.green));
  }

  get1xMarkerFrequency() {
    const { features } = this.state;
    if (features && features.length > 0) {
    const features = this.state.features.items;
    const feature = features.find(feature => feature.marker === '1x');
    return feature ? feature.frequency : -1;
    } else {
      return -1
    }
  }

  drawCursors(ctx, point, cursorType) {
    // calculate distance of hover position (point) to the first clicked position (this.props.points[0])
    if (!cursorType) return;

    let shaftSpeed = this.getShaftSpeed();
    let peakSearchThreshold = 60;
    const stfactor = getFreqConversionFactor('Hz', this.state.xUnit, shaftSpeed, this.state.initialXunit);
    peakSearchThreshold *= stfactor;
    
    const { features, componentNode } = this.props;
    if (features) {
      const Marker1X = features.items.find(
        feature => feature.marker === `1${componentNode ? getComponentTypeShortHand(componentNode.node_details.component_type) : 'X'}`
      );
      if (Marker1X) {
        peakSearchThreshold = Marker1X.frequency;
        const stfactor = getFreqConversionFactor(this.props.initialXunit, this.state.xUnit, shaftSpeed, this.state.initialXunit);
        peakSearchThreshold *= stfactor;
      }
    }

    const factor = getFreqConversionFactor(this.props.initialXunit, this.state.xUnit, shaftSpeed, this.props.initialXunit);
    shaftSpeed *= factor;

    const { harmonicNumber, xUnit } = this.state;
    let diffXvalue;
    if (cursorType === 'harmonic') {
      diffXvalue = helpers.interpolatePeakFrequency(this.state.points[0], ctx.props.data, xUnit) / harmonicNumber;
    } else {
      diffXvalue = Math.abs((point.x - this.state.points[0].x) / harmonicNumber);
    }
    const diffXcoord = Math.abs(ctx.x((point.x - this.state.points[0].x)/ harmonicNumber));
    let initialCursorText = cursorType === 'sideband' ? 'R' : harmonicNumber;
    let harmonicsMarkerSuffix = '';
    let harmonicsStartPoint;

    const selectedPoint = {
      ...ctx.props.points[0],
      x: ctx.props.points[0] / harmonicNumber
    }

    if (cursorType === 'harmonic') { 
      const peakFrequency = this.get1xMarkerFrequency();
      const pointClosestToPeak = helpers.getRelevantPointClosestToPeak(this.ctx.props.data[0], peakFrequency); 

      if (selectedPoint.x === pointClosestToPeak.x) {
        harmonicsMarkerSuffix = 'x';
        harmonicsStartPoint = pointClosestToPeak;
      }
    }

    //guard against too many lines
    if ((diffXcoord < 20 || diffXvalue < 1) && cursorType === 'sideband'){
      d3.selectAll(`.${this.props.chartName}.cursorGroup`).remove();
      this.drawCursor(ctx, { ...this.state.points[0], text: `${initialCursorText}${harmonicsMarkerSuffix}` }, cursorType);
      return;
    }

    const rightCursors = (ctx.props.data[0][ctx.props.data[0].length - 1].x - this.state.points[0].x) / diffXvalue;
    const leftCursors = Math.floor(this.state.points[0].x / diffXvalue);

    d3.selectAll(`.${this.props.chartName}.cursorGroup`).remove();
    this.cursors = [{ ...this.state.points[0], text: `${initialCursorText}${harmonicsMarkerSuffix}` }];
    const coordStep = Math.ceil(20 / diffXcoord);
    for (let i = 1; i < leftCursors; i++) {
      let closestPoint;
      if (cursorType === 'sideband') {
        const xValue = this.state.points[0].x - diffXvalue * i;
        closestPoint = helpers.closestXDataPointOnDataset(ctx.props.data[0], xValue);
        closestPoint.text = Math.round(diffXvalue * i) * (-1);
      }
      else if (cursorType === 'harmonic') {
        if (coordStep > 1 && i % coordStep !== 0 ) continue;
        const xValue = this.state.points[0].x - diffXvalue * i;
        const point = helpers.findClosestPeak(ctx.props.data, Math.round(leftCursors - i), diffXvalue, shaftSpeed, peakSearchThreshold);
        closestPoint = point || { y: 0, x: xValue };
        closestPoint = { ...closestPoint, text: `${Math.round(leftCursors - i)}${harmonicsMarkerSuffix}` };
      }     
      this.cursors.push(closestPoint);
    }
    for (let i = 1; i <= rightCursors; i++) {
      const xValue = (harmonicsStartPoint ? harmonicsStartPoint.x : this.state.points[0].x) + diffXvalue * i;
      let closestPoint;
      if (cursorType === 'sideband') {
        closestPoint = helpers.closestXDataPointOnDataset(ctx.props.data[0], xValue);
        closestPoint.text = Math.round(diffXvalue * i);
      }
      else if (cursorType === 'harmonic') {
        if (coordStep > 1 && i % coordStep !== 0 ) continue;
        const point = helpers.findClosestPeak(ctx.props.data, i + harmonicNumber, diffXvalue, shaftSpeed, peakSearchThreshold);
        closestPoint = point || { y: 0, x: xValue };
        closestPoint = { 
          ...closestPoint, 
          text: (harmonicsMarkerSuffix === 'x' && harmonicNumber > 1) 
              ? `${i + harmonicNumber -1}${harmonicsMarkerSuffix}`
              : `${i + harmonicNumber}${harmonicsMarkerSuffix}` 
        };
      }
      this.cursors.push(closestPoint);
    }

    this.cursors.forEach((cursor) => {
      if (ctx.x(cursor.x) > ctx.chart.getBoundingClientRect().width || ctx.x(cursor.x) < 0) return;
      this.drawCursor(ctx, cursor, cursorType);
    });
  }

  drawFeature(ctx, feature, idx = null) {
    const id = idx ? idx : feature.id;
    // dont draw individual features that are off the selected width of chart
    if (feature.type === 'individual' && (this.ctx.x(feature.frequency) > this.ctx.chart.getBoundingClientRect().width || this.ctx.x(feature.frequency) < 0)) return;

    // TODO: support for area like features (should be done similarly as here just using a rect instead of a line)
    if (!(feature.frequency || (feature.frequency_start && feature.frequency_end))) return;

    d3.select(`#feature-${this.props.chartName}-${id}`).remove();
    // draw single feature line into the container
    const freq_range = feature.type === 'range' ? (feature.frequency_end - feature.frequency_start) : 0;
    const featureG = this.featureContainer
      .append('g')
      .attr('class', `${this.props.chartName} featureGroup`)
      .attr('id', `feature-${this.props.chartName}-${id}`)
      .attr('transform', `translate(${ctx.x(feature.type === 'range' ? feature.frequency_start + (freq_range / 2) : feature.frequency)}, 0)`);

    if (feature.type === 'range') {
      const options = {
        extent: [
          ctx.x(feature.frequency_start),
          ctx.x(feature.frequency_end)
        ]
      };
      d3.select(`.feature-${this.props.chartName}-${id}.brush`).remove();
      this.ctx.brush = null;
      this.ctx.brush = graphUtils.addHorizontalBrush(this.ctx.chart, `feature-${this.props.chartName}-${id} featureBrush`, options);

      const brushEl = d3.select(`.feature-${this.props.chartName}-${id}.brush`);

      brushEl.select('.overlay')
        .attr('pointer-events', 'none');

      brushEl.select('.selection')
        .attr('pointer-events', 'none');

      brushEl.selectAll('.handle')
        .attr('pointer-events', 'none');
    } else {
      graphUtils.drawLineOnPos(featureG.node(), 0, 0, 0, ctx.chart.getBoundingClientRect().height, `${this.props.chartName} featurePosition`)
        .attr('stroke-width', 3)
        .attr('fill', '#383838');
    }
    // TODO: draw rect on pos and draw text on pos util funcs to be added (also overwrite from places where this would be used otherwise)
    let opacity = 1;
    if (this.props.isFeatureIncluded && !this.props.isFeatureIncluded(feature.frequency)) opacity = 0.5;

    const featureRect = featureG.append('rect')
      .attr('height', 16)
      .attr('width', 0)
      .attr('fill', '#BCBFB8')
      .attr('id', `feature-${this.props.chartName}-${id}_tooltip_rect`)
      .attr('cursor', 'pointer')
      .attr('opacity', opacity)
      .attr('pointer-events', 'all');

    if (this.props.onFeatureDetailsClick) {
      graphUtils.addMouseClickEvent(featureRect.node(), (e) => {
        e = e || window.event;
        const dataPoint = helpers.closestExactDataPoint(ctx);
        const dataPos = {
          x: ctx.x(dataPoint.x),
          y: -8
        };
        this.setState({
          dataPos,
          dataToDisplay: feature,
          showFeatureContainer: true,
          showNewPeakModal: false
        });
        if (e) e.stopPropagation();
      });
    }

    const featureText = featureG.append('text')
      .attr('dx', 0)
      .attr('dy', 12)
      .attr('fill', '#fff')
      .attr('font-size', '10px')
      .text(this.props.lowerCaseFeatures ? feature.marker : feature.marker.toUpperCase())
      .attr('pointer-events', 'none');

    const widthOfFeatureLabel = featureText.node().getBBox().width;
    d3.select(`#feature-${this.props.chartName}-${id}_tooltip_rect`)
      .attr('width', widthOfFeatureLabel + 10)
      .attr('transform', `translate(-${(widthOfFeatureLabel + 10) / 2}, -16)`);

    d3.select(`#feature-${this.props.chartName}-${id} text`)
      .attr('transform', `translate(-${(widthOfFeatureLabel) / 2}, -16)`);

    // dont draw range type features label rect if middle of range (where label rect is) is out of bounds of chart
    if (feature.type === 'range') {
      const featureMiddle = feature.frequency_end - (feature.frequency_end - feature.frequency_start) / 2;
      if (this.ctx.x(featureMiddle) > this.ctx.chart.getBoundingClientRect().width || this.ctx.x(featureMiddle) < 0) {
        d3.select(`#feature-${this.props.chartName}-${id}_tooltip_rect`).remove();
        d3.select(`#feature-${this.props.chartName}-${id} text`).remove();
      }
    }
  }

  drawFeatures() {
    if (!this.ctx || !this.props.features || !this.state.features) return;
    d3.selectAll(`.${this.props.chartName}.featureGroup`).remove();

    if (this.state.features.items) {
      this.state.features.items.forEach((feature, idx) => {
        this.drawFeature(this.ctx, feature, idx + 1);
      });
    }
  }

  highlightSelectedFeature() {
    const { dataToDisplay } = this.state;

    // reset previous selection
    d3.selectAll(`.${this.props.chartName}.featureGroup line`)
      .classed('selected', false);
    d3.selectAll(`.${this.props.chartName}.featureGroup rect`)
      .attr('fill', '#BCBFB8');
    d3.selectAll('.featureBrush.brush .selection')
      .attr('fill', '#777');

    if (dataToDisplay) {
      this.drawFeature(this.ctx, dataToDisplay);
      d3.select(`#feature-${this.props.chartName}-${dataToDisplay.id} line`)
        .classed('selected', true);
      d3.select(`#feature-${this.props.chartName}-${dataToDisplay.id} rect`)
        .attr('fill', '#91b846');
      d3.select(`.feature-${this.props.chartName}-${dataToDisplay.id}.brush .selection`)
        .attr('fill', '#91b846');
    }
  }

  closeFeatureContainer() {
    this.setState({
      showFeatureContainer: false,
      dataToDisplay: {}
    });
  }

  findNewXunit(currXunit) {
    const { shaftSpeed } = this.props;
    if (currXunit === 'Hz') return 'CPM'
    else if (currXunit === 'CPM') return shaftSpeed ? 'Orders' : 'Hz';
    return 'Hz'
  }

  findNewYunit(currYunit) {
    if (currYunit === 'dB') return this.props.initialYunit;
    return 'dB'; 
  }

  redraw(ctx) {
    this.ctx = ctx; // set ctx again (features has wrong context otherwise)
    if (this.cursors) {
      d3.selectAll(`.${this.props.chartName}.cursorGroup`).remove();

      this.cursors.forEach((cursor) => {
        if (ctx.x(cursor.x) > ctx.chart.getBoundingClientRect().width || ctx.x(cursor.x) < 0) return;
        this.drawCursor(ctx, cursor, this.state.cursorType);
      });
    }
    this.closeFeatureContainer();
    this.drawFeatures();
    if (this.props.spectralEnvelope) {
      d3.selectAll(`.${this.props.chartName}.step-graph.circle`).remove();
      this.drawEnvelope();
    }
  }

  // Do not remove this, its being used by LineChart hoc
  storeBrushSelection(selection) {
    this.setState({
      storeBrushSelection: _.cloneDeep(selection)
    });
  }

  toggleYaxisUnits() {
    const { yUnit, points } = this.state;
    const { toggleSpectrumAmplitudeUnits, setPointsToCorrectUnits } = this.props;
    
    const newYunit = this.findNewYunit(yUnit);
    const reference = getAmplitudeConversionReference(yUnit, newYunit);

    const spectrumData = this.getDataInCorrectYunit(newYunit);

    if (this.cursors) {
      if (newYunit.toLowerCase() === 'db') {
        this.cursors.forEach(c => {c.y = convertToDbScale(c.y, reference)});
      } else if (yUnit.toLowerCase() === 'db') {
        this.cursors.forEach(c => {c.y = convertFromDbScale(c.y, reference)});
      }
    }

    let newPoints = _.cloneDeep(points);
    if (newYunit.toLowerCase() === 'db') {
      newPoints.forEach((p) => {
        p.y = convertToDbScale(p.y, reference)
        p.yUnit = newYunit;
      });
    } else if (yUnit.toLowerCase() === 'db') {
      newPoints.forEach((p) => {
        p.y = convertFromDbScale(p.y, reference)
        p.yUnit = newYunit;
      });
    }
    setPointsToCorrectUnits(newPoints);

    this.setState({ yUnit: newYunit, points: newPoints }, () => {
      this.updateCursorInfo();
    });

    if (toggleSpectrumAmplitudeUnits) toggleSpectrumAmplitudeUnits(newYunit, spectrumData);

    const { spectralEnvelope } = this.state;
    if (spectralEnvelope && spectralEnvelope.envelope_data && spectralEnvelope.envelope_data.amplitudes) {
      let newAmplitudes = [];
      if (newYunit.toLowerCase() === 'db') {
        newAmplitudes = this.state.spectralEnvelope.envelope_data.amplitudes.map(a => convertToDbScale(a, reference));
      } else if (yUnit.toLowerCase() === 'db') {
        newAmplitudes = this.state.spectralEnvelope.envelope_data.amplitudes.map(a => convertFromDbScale(a, reference));
      }
      this.setState(prevState => ({
        spectralEnvelope: {
          ...prevState.spectralEnvelope,
          envelope_data: {
            ...prevState.spectralEnvelope.envelope_data,
            amplitudes: newAmplitudes
          }
        }
      }), this.drawEnvelope);
    }
  }

  updateSpectrumXScale = (xScale) => {
    this.setState({ spectrumXScale: xScale });
    this.props.setSpectrumXScale(xScale);
  }

  toggleXaxisUnits(toggle_unit = null) {
    const { xUnit, envelopeXUnit, features, points } = _.cloneDeep(this.state);
    const {
      initialXunit,
      toggleSpectrumFrequencyUnits,
      setPointsToCorrectUnits,
    } = this.props;
    let newXunit = null;
    const shaft_speed = this.getShaftSpeed();

    if (toggle_unit) newXunit = toggle_unit;
    else newXunit = this.findNewXunit(xUnit);

    let factor = getFreqConversionFactor(xUnit, newXunit, shaft_speed, initialXunit);

    const spectrumData = this.getDataInCorrectXUnit(newXunit, shaft_speed);
    const newFreqUnitsFeatures = features.items.map((f) => {
      if (f.frequency) f.frequency *= factor;
      if (f.frequency_start) f.frequency_start *= factor;
      if (f.frequency_end) f.frequency_end *= factor;
      f.freq_units = newXunit;
      return f;
    });
  
    if (newXunit === 'Orders') {
      this.updateSpectrumXScale('orders');
    } else {
      this.updateSpectrumXScale('frequency');
    }
  
    if (this.cursors) {
      this.cursors.forEach((c) => {
        if (typeof c.text !== 'string') {
          c.text *= factor;
          c.text = Math.round(c.text);
          c.x *= factor;
        } else {
          c.x *= factor;
        }
      });
    }
    if (toggleSpectrumFrequencyUnits) toggleSpectrumFrequencyUnits(newXunit, spectrumData);
    points.forEach((p) => {
      p.x *= factor;
      p.xUnit = newXunit;
    });
    setPointsToCorrectUnits(points);
    this.setState({ xUnit: newXunit, features: { items: newFreqUnitsFeatures }, points }, this.updateCursorInfo);
  }

  updateCursorInfo = () => {
    if (this.state.cursorType) {
      this.drawCursorInfotext(this.ctx, this.state.cursorType === 'sideband' ? this.state.dataPoint: this.ctx.props.points[0], this.state.cursorType, this.state.points[0].xUnit)
    }
  };

  getFeatureTextToDisplay = (feature) => {
    const { xUnit, initialXunit, shaftSpeed } = this.props;
    return xUnit === 'Orders' ?
      `${toFixedXIfNumber(feature.frequency * shaftSpeed,2)} ${initialXunit} (${toFixedXIfNumber(feature.frequency,2)} ${xUnit})`
    :
      `${toFixedXIfNumber(feature.frequency,2)} ${feature.freq_units}`
  }

  getXDataPoint = () => {
    return _.isArray(this.ctx.props.selection) ? this.ctx.props.selection[0].x : this.ctx.props.selection.x;
  }

  getYDataPoint = () => {
    return _.isArray(this.ctx.props.selection) ? this.ctx.props.selection[0].y : this.ctx.props.selection.y;
  }

  renderComputeContainer = () => {
    const { frequencyContribution } = this.state;
    return (
      <ComputeContainer>
          <FrequencyTypeContainer>Sub-Sync:</FrequencyTypeContainer>
          <FreqContributionContainer>{frequencyContribution.subSync}%</FreqContributionContainer>
          <FrequencyTypeContainer>| Sync:</FrequencyTypeContainer>
          <FreqContributionContainer>{frequencyContribution.sync}%</FreqContributionContainer>
          <FrequencyTypeContainer>| Non-Sync:</FrequencyTypeContainer>
          <FreqContributionContainer>{frequencyContribution.nonSync}%</FreqContributionContainer>
      </ComputeContainer>
    )
  }

  render() {
    const feature = this.state.dataToDisplay;
    const { FeatureContainer, editModel, noXlabel, title } = this.props;
    const {frequencyContribution}  = this.state;
    const containerWidth = this.ctx && this.ctx.container.getBoundingClientRect().width;
    return (
      <Fragment>
        { !_.isEmpty(frequencyContribution )&& 
        !(_.isNaN(frequencyContribution.subSync) || _.isNaN(frequencyContribution.sync) || _.isNaN(frequencyContribution.nonSync)) 
        && this.renderComputeContainer()}
        {this.state.showNewPeakModal && this.props.measurementTimestamp && (
          <NewPeakModal
            {...this.state.dataPos}
            dataPoint={{
              x : this.getXDataPoint(),
              y : this.getYDataPoint(),
              xUnit: this.ctx.props.xUnit,
              yUnit: this.ctx.props.yUnit
            }}
            width="300"
            alignLeft={this.state.dataPos.x > containerWidth - 400}
            setNewPeak={this.setNewPeak}
            setHarmonicNumber={this.setHarmonicNumber}
            harmonicNumber={this.state.harmonicNumber}
            updateHarmonicFrequencies={this.updateHarmonicFrequencies}
            close={() => this.setState({ showNewPeakModal: false })}
            showSetHarmonicButtons={this.props.showSetHarmonicButtons}
            selectedFrequencyContribution={this.selectedFrequencyContribution()}
            title={title}
        />
        )}
        {!noXlabel && (
          <FrequencyConversionButton
            unit={this.state.xUnit}
            text
            disabled={this.props.disableXaxisToggle}
            onClick={() => this.toggleXaxisUnits()}
            left={containerWidth / 2}
          >
            {this.state.xUnit}
          </FrequencyConversionButton>
        )}
        {(this.state.showFeatureContainer && this.state.dataToDisplay) && (
           <InlineModal
             {...this.state.dataPos}
             height={feature.type === 'Feature' ? "150" : "195"}
             width="320"
             alignLeft={this.state.dataPos.x > containerWidth - 400}
             close={this.closeFeatureContainer}
           >
            {FeatureContainer ? (
              <FeatureContainer
                {...feature}
                editModel={editModel}
                isFeatureIncluded={this.props.isFeatureIncluded}
                changeModelFeatures={this.props.changeModelFeatures}
              />
            ) : (
              <>
                <FlexContainer direction="column">
                  <FlexContainer direction="row">
                    <FeatureInfoContainer>
                      <Label>Name</Label>
                      <div>
                        <Text>
                          {humanize(feature.name)}
                        </Text>
                      </div>
                    </FeatureInfoContainer>
                  </FlexContainer>
                  <FlexContainer>
                    <FeatureInfoContainer>
                      <Label>Type</Label>
                      <Text>{feature.type}</Text>
                    </FeatureInfoContainer>
                    <FeatureInfoContainer>
                      <Label>Frequency</Label>
                      {feature.frequency !== null &&
                        <Text>{this.getFeatureTextToDisplay(feature)}</Text>
                      }
                      {!_.isNil(feature.frequency_start) && (
                        <Text>{toFixedXIfNumber(feature.frequency_start)} {feature.freq_units} - {toFixedXIfNumber(feature.frequency_end)} {feature.freq_units}</Text>
                      )
                      }
                    </FeatureInfoContainer>
                  </FlexContainer>
                </FlexContainer>
              <FlexContainer>
                {feature.type !== 'Feature' && !this.props.analystMode && (
                  <FeatureContainerButton
                    text
                    disabled={false}
                    onClick={() => {
                      const feature = _.cloneDeep(this.state.dataToDisplay);
                      this.props.onFeatureDetailsClick(feature);
                      this.setState({ showFeatureContainer: false, dataToDisplay: null });
                    }}
                  >
                    Trend
                  </FeatureContainerButton>
                )}
                {feature.feature_type !== 'bearing' && feature.feature_type !== 'forcing_frequencies' && (
                  <FeatureContainerButton
                    text
                    disabled={false}
                    onClick={() => {
                      const feature = _.cloneDeep(this.state.dataToDisplay);
                      this.props.onFeatureEditClick(feature);
                      this.setState({ showFeatureContainer: false, dataToDisplay: null });
                    }}
                  >
                    Edit feature
                  </FeatureContainerButton>
                )
                }
              </FlexContainer>
              </>
            )}
           </InlineModal>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.user
});

SpectrumChart.displayName = 'SpectrumChart';

SpectrumChart.propTypes = {
  spectrumXScale: PropTypes.string,
  disableXaxisToggle: PropTypes.bool,
  showSetHarmonicButtons: PropTypes.bool,
  spectralEnvelope: PropTypes.object,
  useSpectrumShaftSpeed: PropTypes.bool,
  setSpectrumXScale: PropTypes.func
};

SpectrumChart.defaultProps = {
  spectrumXScale: null,
  disableXaxisToggle: false,
  showSetHarmonicButtons: true,
  spectralEnvelope: null,
  useSpectrumShaftSpeed: false,
  setSpectrumXScale: () => {}
};

export default connect(mapStateToProps, {})(LineChart(SpectrumChart));
