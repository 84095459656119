import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Chart from 'react-google-charts';
import { FlexContainer } from 'common/components/atoms';
import colors from 'common/styles/colors';
import { chartOptions, stackedChartEvents } from './constants';

const ChartContainer = styled(FlexContainer).attrs({
  direction: 'column',
  backgroundColor: 'white',
  alignItems: 'center',
})`
  ${props => props.isInteractive && `
    & svg g g g rect, svg g g g text {
      cursor: pointer;
    }
  `}

  div.google-visualization-tooltip, div.google-visualization-tooltip * {
    pointer-events: none;
  }
  svg g { font-weight: 600; }
`;

const TITLE_HEIGHT = 40;
const Title = styled.div`
  color: ${colors.darkGray};
  font-weight: 600;
  width: 100%;
  padding: 1em 0 0 1em;
  height: ${TITLE_HEIGHT}px;
`;

const getInitialOptions = (
  data,
  orientation,
  colors,
  isStacked,
  yLabelFontSize,
  chartArea,
  hAxisTitle
) => {
  // don't execute if data is a custom message
  if (typeof data === 'string') return {};

  // hide legend by default
  const series = {};
  data[0].slice(1).forEach((d, i) => {
    series[i] = {
      visibleInLegend: false
    };
  });

  // take full width for stacked bar chart
  const hAxis = {};
  if (isStacked) {
    hAxis.viewWindowMode = 'maximized';
  }
  if (hAxisTitle) {
    hAxis.title = hAxisTitle;
  }

  const chartType = orientation === 'vertical' ? 'ColumnChart' : 'BarChart';
  const options = {
    ...chartOptions[chartType],
    chartArea,
    colors,
    series,
    isStacked,
    hAxis: {
      ...chartOptions[chartType].hAxis,
      ...hAxis
    },
    vAxis: {
      ...chartOptions[chartType].vAxis,
      textStyle: {
        fontName: 'Petasense Open Sans',
        fontSize: yLabelFontSize
      },
    }
  };
  return options;
};

const BarChart = ({
  title,
  data,
  colors,
  handleClick,
  orientation,
  isInteractive,
  isStacked,
  addTitleRow,
  resourceName,
  yLabelFontSize,
  chartArea,
  hAxisTitle,
  hasCustomTooltip
}) => {
  const originalData = _.cloneDeep(data);

  if (addTitleRow) {
    data = [[title, `Number of ${resourceName}`, { role: 'style' }]].concat(data);
  }

  const [options, setOptions] = useState(
    getInitialOptions(
      data,
      orientation,
      colors,
      isStacked,
      yLabelFontSize,
      chartArea,
      hAxisTitle
    )
  );
  const { current: allEventListeners } = useRef([]);

  const titleHeight = title !== '' ? TITLE_HEIGHT : 0;

  const containerRef = useRef();
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0
  });
  const { current: resizeObserver } = useRef(new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      setDimensions({
        height: entry.target.offsetHeight,
        width: entry.target.offsetWidth
      });
    });
  }));

  useEffect(() => {
    resizeObserver.observe(containerRef.current.parentElement);
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (orientation === 'vertical') {
      const barWidth = (chartArea.width.slice(0, -1) * dimensions.width) / (originalData.length * 100);
      const groupWidth = Math.min((35 * 100) / barWidth, 75);
      setOptions(prevState => ({
        ...prevState,
        bar: { groupWidth: `${groupWidth}%` },
      }));
    }
  }, [dimensions.width]);

  const showSelectedLegends = (index) => {
    const titleRow = data[0].slice(1).map(d => d.label);
    const dataRow = data[index + 1];
    const series = {};
    dataRow.slice(1).forEach((d, i) => {
      if (d !== null) {
        series[i] = {
          visibleInLegend: true,
          labelInLegend: `${titleRow[i]} (${d})` // show numbers in legend
        };
      } else {
        series[i] = {
          visibleInLegend: false
        };
      }
    });
    setOptions(prevOptions => ({
      ...prevOptions,
      series
    }));
  };

  return (
    <ChartContainer
      innerRef={containerRef}
      isInteractive={isInteractive}
    >
      {title !== '' && <Title className="title">{title}</Title>}
      {typeof data === 'string' && (
        <h3>{data}</h3>
      )}
      {typeof data !== 'string' && (data.length >= 2 ? (
        <Chart
          key={dimensions.height}
          width="100%"
          height={dimensions.height - titleHeight}
          chartType={orientation === 'vertical' ? 'ColumnChart' : 'BarChart'}
          data={data}
          options={options}
          chartEvents={stackedChartEvents(
            handleClick,
            showSelectedLegends,
            allEventListeners,
            isStacked,
            hasCustomTooltip
          )}
        />
      ) :
        <h3>Data unavailable</h3>
      )}
    </ChartContainer>
  );
};

BarChart.propTypes = {
  handleClick: PropTypes.func,
  title: PropTypes.string,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  isInteractive: PropTypes.bool,
  isStacked: PropTypes.bool,
  resourceName: PropTypes.string,
  yLabelFontSize: PropTypes.number,
  chartArea: PropTypes.object,
  hasCustomTooltip: PropTypes.bool
};

BarChart.defaultProps = {
  handleClick: () => {},
  title: '',
  orientation: 'horizontal',
  isInteractive: true,
  isStacked: true,
  resourceName: '',
  yLabelFontSize: 12,
  chartArea: { bottom: '30%', width: '80%' },
  hasCustomTooltip: false
};

export default BarChart;
