import React from 'react';
import PropTypes from 'prop-types';
import MoteUnreachableNew from 'common/images/MoteUnreachableNew';

const UnreachableMark = ({ marginLeft, marginRight }) => (
  <span
    style={{ marginLeft, marginRight }}
    title="Asset has unreachable devices."
  >
      <MoteUnreachableNew fill="red" />
  </span>
);
UnreachableMark.propTypes = {
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string
};
UnreachableMark.defaultProps = {
  marginRight: '0px',
  marginLeft: '0px'
};
export default UnreachableMark;
