export default{
  CREATE_THIRD_PARTY_INTEGRATIONS: 'CREATE_THIRD_PARTY_INTEGRATIONS',
  CREATE_THIRD_PARTY_INTEGRATIONS_REQUEST: 'CREATE_THIRD_PARTY_INTEGRATIONS_REQUEST',
  CREATE_THIRD_PARTY_INTEGRATIONS_SUCCESS: 'CREATE_THIRD_PARTY_INTEGRATIONS_SUCCESS',
  CREATE_THIRD_PARTY_INTEGRATIONS_FAILURE: 'CREATE_THIRD_PARTY_INTEGRATIONS_FAILURE',

  UPDATE_THIRD_PARTY_INTEGRATIONS: 'UPDATE_THIRD_PARTY_INTEGRATIONS',
  UPDATE_THIRD_PARTY_INTEGRATIONS_REQUEST: 'UPDATE_THIRD_PARTY_INTEGRATIONS_REQUEST',
  UPDATE_THIRD_PARTY_INTEGRATIONS_SUCCESS: 'UPDATE_THIRD_PARTY_INTEGRATIONS_SUCCESS',
  UPDATE_THIRD_PARTY_INTEGRATIONS_FAILURE: 'UPDATE_THIRD_PARTY_INTEGRATIONS_FAILURE',

  DELETE_THIRD_PARTY_INTEGRATIONS: 'DELETE_THIRD_PARTY_INTEGRATIONS',
  DELETE_THIRD_PARTY_INTEGRATIONS_REQUEST: 'DELETE_THIRD_PARTY_INTEGRATIONS_REQUEST',
  DELETE_THIRD_PARTY_INTEGRATIONS_SUCCESS: 'DELETE_THIRD_PARTY_INTEGRATIONS_SUCCESS',
  DELETE_THIRD_PARTY_INTEGRATIONS_FAILURE: 'DELETE_THIRD_PARTY_INTEGRATIONS_FAILURE',

  GET_THIRD_PARTY_INTEGRATION_LIST: 'GET_THIRD_PARTY_INTEGRATION_LIST',
  GET_THIRD_PARTY_INTEGRATION_LIST_REQUEST: 'GET_THIRD_PARTY_INTEGRATION_LIST_REQUEST',
  GET_THIRD_PARTY_INTEGRATION_LIST_SUCCESS: 'GET_THIRD_PARTY_INTEGRATION_LIST_SUCCESS',
  GET_THIRD_PARTY_INTEGRATION_LIST_FAILURE: 'GET_THIRD_PARTY_INTEGRATION_LIST_FAILURE',

  GET_THIRD_PARTY_INTEGRATION_DETAILS: 'GET_THIRD_PARTY_INTEGRATION_DETAILS',
  GET_THIRD_PARTY_INTEGRATION_DETAILS_REQUEST: 'GET_THIRD_PARTY_INTEGRATION_DETAILS_REQUEST',
  GET_THIRD_PARTY_INTEGRATION_DETAILS_FAILURE: 'GET_THIRD_PARTY_INTEGRATION_DETAILS_FAILURE',
  GET_THIRD_PARTY_INTEGRATION_DETAILS_SUCCESS: 'GET_THIRD_PARTY_INTEGRATION_DETAILS_SUCCESS',

  GET_TPI_TAG_TYPES: 'GET_TPI_TAG_TYPES',

  NUMBER_OF_INTEGRATIONS_PER_PAGE: 20
};
