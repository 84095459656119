export const thirdPartyTagsConstants = {
  GET_THIRD_PARTY_TAGS_LIST_REQUEST: 'GET_THIRD_PARTY_TAGS_LIST_REQUEST',
  GET_THIRD_PARTY_TAGS_LIST_SUCCESS: 'GET_THIRD_PARTY_TAGS_LIST_SUCCESS',
  GET_THIRD_PARTY_TAGS_LIST_FAILURE: 'GET_THIRD_PARTY_TAGS_LIST_FAILURE',

  SET_THIRD_PARTY_TAGS_FILTERS: 'SET_THIRD_PARTY_TAGS_FILTERS',
  CLEAR_THIRD_PARTY_TAGS_LIST: 'CLEAR_THIRD_PARTY_TAGS_LIST',
  INCREASE_PAGE: 'INCREASE_PAGE',
  GET_ALL_TAG_TYPES: 'GET_ALL_TAG_TYPES',
  UPDATE_THIRD_PARTY_TAG: 'UPDATE_THIRD_PARTY_TAG',
  NUMBER_OF_TAGS_PER_PAGE: 50
};
