import MachineInfoTypes from '../actions/machineInfo.types';
import { bearingConstants } from '../components/BearingModal/bearing.constants';

const initialState = {
  loadingDetails: false,
  updatingDetails: false,
  details: {}
};

const machineInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case MachineInfoTypes.GET_MACHINE_INFO_REQUEST:
      return {
        ...state,
        error: '',
        loadingDetails: true,
      };
    case MachineInfoTypes.GET_MACHINE_INFO_SUCCESS:
      return {
        ...state,
        loadingDetails: false,
        details: action.details
      };
    case MachineInfoTypes.GET_MACHINE_INFO_FAILURE:
      return {
        ...state,
        loadingDetails: false,
        error: action.error,
      };

    case MachineInfoTypes.UPDATE_MACHINE_INFO_REQUEST:
      return {
        ...state,
        updatingDetails: true
      };
    case MachineInfoTypes.UPDATE_MACHINE_INFO_SUCCESS:
      return {
        ...state,
        updatingDetails: false,
        details: action.details
      };
    case MachineInfoTypes.UPDATE_MACHINE_INFO_FAILURE:
      return {
        ...state,
        updatingDetails: false
      };

    case MachineInfoTypes.UPDATE_COMPONENT_INFO_REQUEST:
      return {
        ...state,
        updatingDetails: true
      };
    case MachineInfoTypes.UPDATE_COMPONENT_INFO_SUCCESS:
      return {
        ...state,
        updatingDetails: false,
        details: action.details
      };
    case MachineInfoTypes.UPDATE_COMPONENT_INFO_FAILURE:
      return {
        ...state,
        updatingDetails: false
      };
    case MachineInfoTypes.ASSOCIATE_BEARING_TO_COMPONENT_REQUEST:
      return {
        ...state,
        updatingDetails: true
      };
    case MachineInfoTypes.ASSOCIATE_BEARING_TO_COMPONENT_SUCCESS:
      return {
        ...state,
        updatingDetails: false,
        details: action.details
      };
    case MachineInfoTypes.ASSOCIATE_BEARING_TO_COMPONENT_FAILURE:
      return {
        ...state,
        updatingDetails: false
      };
    default:
      return state;
  }
};

export default machineInfoReducer;
