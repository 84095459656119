import styled from 'styled-components';
import PropTypes from 'prop-types';

const MenuItem = styled.button`
  display: flex;
  font-size: 14px;
  color: ${props => props.theme.colors.greyXD};
  font-family: 'Petasense Open Sans';
  font-weight: 600;
  flex-direction: row;
  align-items: center;
  padding: 10px 1em;
  text-align: left;
  width: ${props => props.width || '100%'};
  border: none;
  outline: none;
  background-color: white;
  ${props =>
    props.disabled
      ? `
    color: ${props.theme.colors.grey};
    svg {
      color: ${props.theme.colors.grey};
      #icon {
        stroke: ${props.theme.colors.grey};
      }
    }
  `
      : `
    &:hover {
      color: ${props.theme.colors.greyXXD};
      svg {
        color: ${props.theme.colors.greyXXD};
        #icon {
          stroke: ${props.theme.colors.greyXXD};
        }
      }
    }
    &:action {
      color: ${props.theme.primaryColor};
    }
  `} &:first-of-type {
    padding-top: 1em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    padding-bottom: 1em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  span {
    margin-left: 1em;
  }
`;

MenuItem.propTypes = {
  disabled: PropTypes.bool
};

export default MenuItem;
