import * as d3 from 'd3';
import * as _ from 'lodash';
import { CapitalizeEachWord } from '../../../utils';
import { downloadAsCSV } from '../../../helpers';

export const distanceBetweenTwoPoints = (p1, p2) => Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);

export const closestXDataPointOnDataset = (dataset, x, xisDate = false) => {
  const bisectX = d3.bisector(d => d.x).left; // function to know x-axis array position of 'new value' from x pos

  const indexRight = xisDate ? bisectX(dataset.map(d => ({ ...d, x: d.x.valueOf() })), x.valueOf()) : bisectX(dataset, x);
  const indexLeft = indexRight === 0 ? 0 : indexRight - 1;

  const dRight = dataset[indexRight];
  const dLeft = dataset[indexLeft];

  if (!dRight) return dLeft;

  const closestXPoint = x - dLeft.x > dRight.x - x ? dRight : dLeft;

  return closestXPoint;
};

export const findClosestPeak = (dataset, order, fundamental, shaftSpeed, peakSearchThreshold) => {
  if (dataset[0].length < 2) return null;
  const freqResolution = dataset[0][1].x - dataset[0][0].x;

  let percentage;
  if (order <= 10) percentage = 0.04;
  else if (order > 10 && order <= 25) percentage = 0.06;
  else percentage = 0.08;

  let tolerance;
  if (shaftSpeed) {
    tolerance = Math.max(percentage * shaftSpeed, freqResolution);
  } else {
    tolerance = freqResolution;
  }

  if (fundamental < peakSearchThreshold) {
    tolerance = 0;
  }

  const searchRange = [(order * fundamental) - tolerance, (order * fundamental) + tolerance];

  // accumulate points in range from all directions
  const dataPointsInRange = [];
  dataset.forEach(direction => dataPointsInRange.push(
    ...direction.filter(point => searchRange[0] <= point.x && point.x <= searchRange[1])
  ));
  if (_.isEmpty(dataPointsInRange)) {
    return null;
  }

  // take point with max amplitude from all accumulated points
  const peakPoint = dataPointsInRange.reduce((prev, current) => (prev.y > current.y) ? prev : current);
  return peakPoint;
};

export const interpolatePeakFrequency = (selectedPoint, dataset, xUnit) => {
  // Prevent interpolation for multiline spectrum
  if (dataset.length > 1) return selectedPoint.x;

  const pointIdx = dataset[0].findIndex(point => point.x === selectedPoint.x);
  const prevPoint = dataset[0][pointIdx - 1];
  const nextPoint = dataset[0][pointIdx + 1];
  if (!prevPoint || !nextPoint) return selectedPoint.x;

  // Restrict interpolation only for frequencies below 60 Hz
  if (xUnit === 'Hz' && selectedPoint.x > 60) return selectedPoint.x;
  if (xUnit === 'Orders' && selectedPoint.x > 1.5) return selectedPoint.x;
  if (xUnit === 'CPM' && selectedPoint.x > 3600) return selectedPoint.x;

  const freqResolution = selectedPoint.x - prevPoint.x;
  let actualFreq;
  if (prevPoint.y > nextPoint.y) {
    const a = selectedPoint.y / prevPoint.y;
    const d = a / (1 + a);
    actualFreq = selectedPoint.x - freqResolution * (1 - d);
  } else {
    const a = nextPoint.y / selectedPoint.y;
    const d = a / (1 + a);
    actualFreq = selectedPoint.x + freqResolution * d;
  }

  return actualFreq;
};

export const interpolateYDataPointOnDataset = (dataset, x, xisDate = false) => {
  const bisectX = d3.bisector(d => d.x).left; // function to know x-axis array position of 'new value' from x pos

  const indexRight = xisDate ? bisectX(dataset.map(d => ({ ...d, x: d.x.valueOf() })), x.valueOf()) : bisectX(dataset, x);
  const indexLeft = indexRight === 0 ? 0 : indexRight - 1;

  const dRight = dataset[indexRight];
  const dLeft = dataset[indexLeft];

  if (!dRight) return dLeft;

  const intr = d3.interpolate(dLeft, dRight);
  const x1 = (x - dLeft.x) / (dRight.x - dLeft.x);

  // const closestXPoint = x - dLeft.x > dRight.x - x ? dRight : dLeft;

  return intr(x1);
};

export const getNextDataPoint = (dataset, x, direction) => {
  const bisect = (_.isEqual(direction, 'left') ? d3.bisector(d => d.x).left : d3.bisector(d => d.x).right);

  const indexRight = bisect(dataset, x);
  const indexLeft = indexRight === 0 ? 0 : indexRight - 1;

  const dRight = dataset[indexRight];
  const dLeft = dataset[indexLeft];

  if (!dLeft && !dRight) return x;
  if (_.isEqual(direction, 'left')) return dLeft;
  return dRight;
};

export const closestExactDataPoint = (ctx) => {
  const xPos = d3.mouse(ctx.chart)[0];
  const yPos = d3.mouse(ctx.chart)[1];

  const xValue = ctx.x.invert(xPos); // change mouse position to value on the x-axis = 'new value'

  const bisectX = d3.bisector(d => d.x).left; // function to know x-axis array position of 'new value' from click

  let result = null;
  ctx.props.data.forEach((dataSet, idx) => {
    if (!ctx.props.activeData[idx]) return;
    const index = bisectX(dataSet, xValue); // index of entry for 'new value'

    // see which side of the new entry is closest in the x-axel
    let d1 = dataSet[index];
    const d0 = dataSet[index - 1];
    let indexOnDataset = index;
    if (!d1) {
      d1 = dataSet[index - 1];
      indexOnDataset = index - 1;
    } // new index would be out of bounds for dataset
    let d = d1;
    if (d0) {
      if (xPos - ctx.x(d0.x) > ctx.x(d1.x) - xPos) {
        d = d1;
      } else {
        d = d0;
        indexOnDataset = index - 1;
      }
      // d = xPos - ctx.x(d0.x) > ctx.x(d1.x) - xPos ? d1 : d0; // closest point on the x-axis
    }
    if (!d) return;

    d.dataIdx = idx; // index on which dataset the closest point is (x-axis)
    // compare results between datasets to find the closest of them. Use pythagoran distance here.
    d.indexOnDataset = indexOnDataset;
    if (result === null) result = d;
    else {
      const resToMouse = distanceBetweenTwoPoints({ x: xPos, y: yPos }, { x: ctx.x(result.x), y: ctx.y(result.y) });
      const dToMouse = distanceBetweenTwoPoints({ x: xPos, y: yPos }, { x: ctx.x(d.x), y: ctx.y(d.y) });
      result = resToMouse > dToMouse ? d : result;
    }
  });

  return result;
};

export const getRelevantPointClosestToPeak = (dataset, peakFrequency) => {
  const closestPoint = closestXDataPointOnDataset(dataset, peakFrequency);

  // check if there is a point in graph which is exactly at peak
  if (closestPoint.x === peakFrequency) return closestPoint;

  // if closest point to peak is not exactly the point (in graph) at peak,
  // it means user cannot select the peak point, return a relevant close point

  // check if closest point is Left/Right of peak
  const otherPointDirection = (closestPoint.x >= peakFrequency) ? 'left' : 'right';

  // get point in other direction
  const otherPoint = getNextDataPoint(dataset, closestPoint.x, otherPointDirection);

  // if amplitude of both points is same, select the point nearest to the peak
  if (closestPoint.y === otherPoint.y) {
    const absoluteDifferenceClosestPoint = Math.abs(closestPoint.x - peakFrequency);
    const absoluteDifferenceOtherPoint = Math.abs(otherPoint.x - peakFrequency);
    return (absoluteDifferenceClosestPoint <= absoluteDifferenceOtherPoint) ? closestPoint : otherPoint;
  }

  // return the point with higher amplitude
  return (closestPoint.y >= otherPoint.y) ? closestPoint : otherPoint;
};

export const getAllDataPointsArray = (ctx) => {
  const xPos = d3.mouse(ctx.chart)[0];

  const xValue = ctx.x.invert(xPos); // change mouse position to value on the x-axis = 'new value'

  const bisectX = d3.bisector(d => d.x).left; // function to know x-axis array position of 'new value' from click

  const result = [];
  ctx.props.data.forEach((dataSet, idx) => {
    if (dataSet.length > 0) {
      const index = bisectX(dataSet, xValue); // index of entry for 'new value'

      // see which side of the new entry is closest in the x-axel
      let d1 = dataSet[index];
      const d0 = dataSet[index - 1];
      if (!d1) d1 = dataSet[index - 1]; // new index would be out of bounds for dataset
      let d = d1;
      if (d0) {
        d = xPos - ctx.x(d0.x) > ctx.x(d1.x) - xPos ? d1 : d0; // closest point on the x-axis
      }
      d = _.cloneDeep(d); // clone to avoid re-renders
      d.dataIdx = idx; // index on which dataset the closest point is (x-axis)
      result.push(d);
    }
  });

  // if we can find a way to display multiple lines of data lets return the hold result set
  return result;
};

export const downloadChartData = (props) => {
  const name = props.config.name;
  props.config.trends_data.forEach((obj) => {
    const tag_type = obj.tag_type ? `${obj.tag_type}` : '';
    const amp_type = obj.amp_type ? `_${obj.amp_type}_` : '';
    const feature = obj.feature ? `${obj.feature}` : '';
    const units = obj.units ? ` (${obj.units})` : '';

    const propertiesToHeaderMap = {
      y: `${tag_type}${amp_type}${feature}${units}`,
      x: 'timestamp',
      measurement_id: 'measurement_id'
    };
    const chartName = `${name}_${tag_type}${amp_type}${feature}`;
    if (obj.trend_data !== undefined && obj.trend_data.length > 0) {
      downloadAsCSV(obj.trend_data, propertiesToHeaderMap, `${chartName}.csv`);
    }
  });
};

export const scaleDataEntry = (entry, unitRange, endRange) =>
  endRange[1] * ((entry - unitRange[0]) / (unitRange[1] - unitRange[0]));

export const getClosestStepGraphPoint = (data, frequency) => {
  if (_.isEmpty(data)) return {};
  const { start_frequencies, end_frequencies, amplitudes } = data;
  if (frequency < start_frequencies[0]) return {};
  const bisector = d3.bisector(d => d).left;
  const idx = bisector(end_frequencies, frequency);
  const selected_idx = idx === end_frequencies.length ? idx - 1 : idx;
  return { x: frequency, y: amplitudes[selected_idx] };
};

export const getTagAmplitudeTypes = (tag) => {
  if (!tag) return [];
  const amplitude_types = [...tag.amplitude_types];
  if (tag.type === 'vibration') amplitude_types.push('demod');
  if (tag.type === 'current') {
    amplitude_types.push('normal', 'demod');
  }
  return amplitude_types;
};

export const getTagGroupName = (tag) => {
  const altKeyArr = [tag.location_description, tag.type, tag.direction, tag.amp_type, tag.feature];
  const altKey = altKeyArr.reduce((res, val) => val ? `${res}_${val}` : res);
  return CapitalizeEachWord(altKey);
};

export const getTagMatchKey = (cfg) => {
  let tag_id = cfg.tag_id;
  if (!tag_id) tag_id = cfg.id;

  const keys = [tag_id, cfg.amp_type, cfg.feature];
  return keys.reduce((res, val) => val ? `${res}_${val}` : res);
};
