import React from 'react';
import PropTypes from 'prop-types';

const ExpandSvg = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 16 16">
      <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="3-3-0-Machines-Charts" transform="translate(-1158.000000, -203.000000)">
              <g id="charts" transform="translate(80.000000, 147.000000)">
                  <g id="1" transform="translate(0.000000, 36.000000)">
                      <g id="top" transform="translate(20.000000, 20.000000)">
                          <g id="options" transform="translate(1022.000000, 0.000000)">
                              <g id="expand-icon" transform="translate(36.000000, 0.000000)">
                                  <rect id="bounds" fill="none" x="0" y="0" width={width} height={height} />
                                  <g id="icon" transform="translate(1.000000, 1.000000)" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                                      <path d="M3.88888889,0 L1.55555556,0 C0.696445945,0 0,0.696445945 0,1.55555556 L0,3.88888889 M14,3.88888889 L14,1.55555556 C14,0.696445945 13.3035541,0 12.4444444,0 L10.1111111,0 M10.1111111,14 L12.4444444,14 C13.3035541,14 14,13.3035541 14,12.4444444 L14,10.1111111 M0,10.1111111 L0,12.4444444 C0,13.3035541 0.696445945,14 1.55555556,14 L3.88888889,14" id="expand-icon" />
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);

ExpandSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

ExpandSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: '#000000'
};

export default ExpandSvg;
