import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { history } from '../helpers';
import { alertActions } from '../../alert';
import logo from '../../_images/logos/logo_light_bg.png';
import LogoSvg from '../images/LogoSvg';

class OnboardingHeader extends React.Component {
  constructor(props) {
    super(props);

    const { clearAlert } = this.props;
    history.listen(() => {
      // clear alert on location change
      clearAlert();
    });
  }

  render() {
    const { alert, partner } = this.props;
    return (
      <div>
       <div className="ps-logo-container">
          {partner === 'NTN' ? (
            <LogoSvg width={193} height={67} />
          ) : (
            <img alt="" src={logo} />
          )}
       </div>
        {alert && alert.message && (
          <div className="ps-align-center">
            <span className={`help-block${alert && alert.message && alert.type ? ' pop' : ''}`}>{alert.message}</span>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { partner } = state.companyReducer;
  const { alert } = state;
  return {
    alert,
    partner: partner.name
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    clearAlert: alertActions.clear
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingHeader);
