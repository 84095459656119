import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route, Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import _ from 'lodash';
import styled from 'styled-components';
import { Pane } from 'react-split-pane';
import StyledLink from 'common/components/atoms/Link';

import { applyBrowserDuplicateBlocker, history } from 'common/helpers/history';
import lazyRetry from 'common/helpers/componentLoader';
import LoadingSvg from 'common/components/atoms/Loading';
import FlexContainer from 'common/components/atoms/FlexContainer';
import { ChevronRight, ChevronLeft, User, Home } from 'common/images/FaIcons';
import PageNotFound from 'common/components/PageNotFound/PageNotFound';
import Overlay from 'common/components/atoms/Overlay';
import AlertPrompt from 'common/components/organisms/AlertPrompt';
import { isPartnerAdmin, isPetasenseAdmin, isPetasenseViewer } from 'common/rbac/util';
import DynamicTabs from 'common/components/organisms/DynamicTabs';

import MachineDetails from 'home/Machines/MachineDetails/MachineDetails';
import HomePageModals from 'home/HomePage/components/HomePageModals';
import TaskDetailsModal from 'home/HomePage/components/TaskDetailsModal';
import RepairDetailsModal from 'home/HomePage/components/RepairDetailsModal';
import * as taskActions from 'home/HomePage/actions/tasks.actions';
import * as repairActions from 'home/HomePage/actions/repairs.actions';
import { getPreferredTimezone, setPreferredTimezone } from 'common/helpers/time';
import * as assetDetailsActions from '../AssetHierarchy/actions/assetDetails.actions';
import * as hierarchyActions from '../AssetHierarchy/actions/hierarchy.actions';
import ProfileDropdown from '../Menu/ProfileDropdown';
import HierarchyView from '../AssetHierarchy/components/pages/HierarchyView';
import BreadCrumbHeader from '../Menu/BreadCrumbHeader';
import { selectAccount } from '../../onboarding/user.actions';
import * as breadcrumbActions from '../Menu/actions/breadcrumb.actions';
import * as adminDashboardActions from '../AdminDashboard/adminDashboard.actions';
import * as hierarchyUtils from '../AssetHierarchy/utils/assetHierarchyUtils';
import HomePageContainer from './components/atoms/HomePageContainer';
import ChevronContainer from './components/atoms/ChevronContainer';
import SubscriptionBanner from './components/SubscriptionBanner';
import AnalystSummaryView from './AnalystSummaryView';
import { isUnsavedAnalystSummaryExists, getFlattenedAccountMenuRoutes } from './utils';
import { homeTabItems, tabsOnlyForPetasenseAdmins, Routes } from './constants/routes.constants';
import AdminDashboard from '../AdminDashboard/components/AdminDashboard';
import { CustomSplitPane } from './components/atoms/CustomSplitPane';

const SensorAdd = lazy(() => lazyRetry(() => import('home/Sensors/SensorAdd/components/SensorAdd')));
const TemplateDetails = lazy(() => lazyRetry(() => import('home/Templates/components/pages/TemplateDetails')));
const ProfileSettings = lazy(() => lazyRetry(() => import('home/ProfileSettings/ProfileSettings')));
const MachineBuilderModal = lazy(() => lazyRetry(() => import('../Machines/MachineBuilder/MachineBuilderModal')));
const MachineAdd = lazy(() => lazyRetry(() => import('../Machines/MachineAdd/components/MachineAdd')));
const ChartsView = lazy(() => lazyRetry(() => import('../AssetHierarchy/components/pages/ChartsView')));
const LocationChartView = lazy(() => lazyRetry(() => import('../AssetHierarchy/components/pages/LocationChartView')));
const MultilineChartView = lazy(() => lazyRetry(() => import('../AssetHierarchy/components/pages/MultilineChartView')));
const DataOverview = lazy(() => lazyRetry(() => import('../AssetHierarchy/components/pages/DataOverview')));

const Content = styled.div`
  width: 100%;
`;

const SwitchContainer = styled.div`
  height: 100%;
  ${props => props.background && `background: ${props.background};`}
`;

const MainContainer = styled(FlexContainer)`
  position: relative;
`;

const HierarchyContainer = styled(FlexContainer)`
  width: 100%;
  height: calc(100vh - 55px);
`;

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'overview',
      editMode: false,
      analystSummaryWarning: false,
      // if user switches account
      discardAnalystSummary: false,
      isMobile: window.innerWidth < 768
    };

    this.tabItems = homeTabItems.map(item => item.key);
    this.analystSummaryRef = React.createRef();
  }

  componentDidMount() {
    applyBrowserDuplicateBlocker();
    this.switchAccountifRequired();
    this.updateTimezoneIfRequired();
    const { getHierarchy } = this.props.assetDetailsActions;
    const { account_id } = this.props.user.user;
    // directly machine route is called
    let machine_id = null;
    let config_id = null;
    let location_id = null;
    const path = this.props.history.location.pathname.split('/');
    if (path[1] === 'machines' && path.length > 2) machine_id = Number(path[2]);
    if (path[1] === 'configs' && path.length > 2) config_id = Number(path[2]);
    if (path[1] === 'location' && path.length > 2) location_id = Number(path[2]);
    if (this.props.adminDashboard.adminOpen) this.props.adminDashboardActions.toggleAdminDashboard();

    getHierarchy(account_id).then(() => {
      const machineTab = this.getMachineTab(path);
      if (machine_id) this.handleNodeClick(machine_id, 'machine', machineTab, false, this.props.history.location);
      if (config_id || path[1] === 'configs') this.handleNodeClick(config_id, 'config');
      if (location_id) this.handleNodeClick(location_id, 'location');
    });
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps) {
    this.updateTimezoneIfRequired(prevProps);
    const item = this.props.location.pathname.substring(1);
    const { activeItem } = this.state;
    // make this logic better
    if (item === '' && activeItem !== 'overview') this.setState({ activeItem: 'overview' });
    if (item === '' || this.tabItems.indexOf(item) !== -1) {
      if (activeItem !== item && item !== '') this.setState({ activeItem: item || 'overview' });
    } else if (activeItem !== null) this.setState({ activeItem: null });
    if (item === '' && activeItem === null) this.setState({ activeItem: 'overview' });
    let machine_id = null;
    let config_id = null;
    let location_id = null;
    if (prevProps && (
      !_.isEqual(_.omit(prevProps.location, ['key']), _.omit(this.props.location, ['key'])) ||
      (!prevProps.breadcrumb.machine.id && prevProps.breadcrumb.machine.id !== this.props.breadcrumb.machine.id)
    )) {
      const path = this.props.history.location.pathname.split('/');
      if (path[1] === 'machines' && path.length > 2) machine_id = Number(path[2]);
      if (path[1] === 'configs' && path.length > 2) config_id = Number(path[2]);
      if (path[1] === 'location' && path.length > 2) location_id = Number(path[2]);
      if (machine_id) {
        const machineTab = this.getMachineTab(path);
        this.handleNodeClick(machine_id, 'machine', machineTab, false, this.props.history.location);
      } else if (config_id || path[1] === 'configs') {
        this.handleNodeClick(config_id, 'config');
        if (!this.props.hierarchyViewPane.currentWidth) {
          this.props.hierarchyActions.toggleHierarchyView();
        }
      } else if (location_id) {
        this.handleNodeClick(location_id, 'location');
        if (!this.props.hierarchyViewPane.currentWidth) {
          this.props.hierarchyActions.toggleHierarchyView();
        }
      }
    }
  }

  handleResize = () => {
    const { updateMobileHierarchyViewPane } = this.props.hierarchyActions;
    const isMobile = window.innerWidth < 768;
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
    updateMobileHierarchyViewPane();
  };

  getMachineTab = path => path.length > 3 ? path[3] : 'overview'

  updateTimezoneIfRequired = (prevProps) => {
    const { user } = this.props;
    if (
      !prevProps ||
      (user.user && !_.isEqual(user.user, prevProps.user.user))
    ) {
      const timezone = getPreferredTimezone(user.user, this.props.currentAccount);
      setPreferredTimezone(timezone);
    }
  }

  updateConfig = (config) => {
    const path = this.getpath(config.config_id, 'config');
    const params = hierarchyUtils.getHierarchyParams(path, config.config_id, 'config');
    this.props.breadcrumbActions.updateBreadcrumb(params);
  };

  selectConfig = (node_id, nodePropertyType, nodePropertyValue, clearQueryParams = false) => {
    const { minimizeHierarchyViewPane } = this.props.hierarchyActions;
    const { isMobile } = this.state;
    if (isMobile) {
      minimizeHierarchyViewPane();
    }

    const config_id = this.props.hierarchyActions.selectNode(node_id, 'config', nodePropertyType, nodePropertyValue);
    if (!config_id) return;
    const { sites } = this.props.assetHierarchy;
    const { machine_id, site_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    const { getConfigs, getTags } = this.props.assetDetailsActions;
    const configList = hierarchyUtils.getConfigList(machine_id, site_id, sites);
    let search = this.props.location.search;
    if (clearQueryParams) search = '';
    if (configList && configList.length > 0) {
      let config = {};
      config = configList.find(item => item.config_id === config_id);
      if (!config) {
        getTags(machine_id, site_id);
        this.loading = true;
        getConfigs(machine_id, site_id).then(
          (res) => {
            if (!res) {
              this.props.history.push('/');
              return;
            }
            config = res.find(item => item.config_id === config_id);
            this.loading = false;
            this.setState({ config });
            this.props.history.replace({
              pathname: `/configs/${config_id}`,
              search
            });
          }
        );
      } else {
        this.setState({ config });
        this.props.history.replace({
          pathname: `/configs/${config_id}`,
          search
        });
      }
    } else {
      if (!site_id || !machine_id) return;
      getTags(machine_id, site_id);
      this.loading = true;
      getConfigs(machine_id, site_id).then(
        (res) => {
          if (!res) {
            this.props.history.push('/');
            return;
          }
          const config = res.find(item => item.config_id === config_id);
          this.loading = false;
          this.setState({ config });
          this.props.history.replace({
            pathname: `/configs/${config_id}`,
            search
          });
        }
      );
    }
  }

  selectLocation = (location_id) => {
    const { minimizeHierarchyViewPane } = this.props.hierarchyActions;
    const { isMobile } = this.state;
    if (isMobile) {
      minimizeHierarchyViewPane();
    }

    const { sites, hierarchy } = this.props.assetHierarchy;
    const isBearing = hierarchyUtils.isBearing(hierarchy, location_id);
    if (!isBearing) return;
    this.props.hierarchyActions.selectNode(location_id, 'location');
    const { machine_id, site_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    const { getConfigs, getTags } = this.props.assetDetailsActions;
    const configList = hierarchyUtils.getConfigList(machine_id, site_id, sites);
    const configIds = hierarchyUtils.getConfigsFromLocationId(hierarchy, location_id).map(c => c.id);
    if (configList.length > 0) {
      let configs = [];
      configs = configList.filter(i => _.includes(configIds, i.config_id));
      if (configs.length !== configIds.length) {
        getTags(machine_id, site_id);
        getConfigs(machine_id, site_id).then(
          (res) => {
            if (!res) {
              this.props.history.push('/');
            }
            this.props.history.push(`/location/${location_id}`);
          }
        );
      } else {
        this.props.history.push(`/location/${location_id}`);
      }
    } else {
      if (!site_id || !machine_id) return;
      getTags(machine_id, site_id);
      this.loading = true;
      getConfigs(machine_id, site_id).then(
        (res) => {
          if (!res) {
            this.props.history.push('/');
            return;
          }
          this.loading = false;
          this.props.history.push(`/location/${location_id}`);
        }
      );
    }
  }

  selectMachine = (machineId, machineTab = 'overview', location) => {
    const { minimizeHierarchyViewPane } = this.props.hierarchyActions;
    const { isMobile } = this.state;
    if (isMobile) {
      minimizeHierarchyViewPane();
    }

    let nodeType = 'machine';
    if (machineTab === 'charts') nodeType = 'machine_charts';
    else if (machineTab === 'data-overview') nodeType = 'data_overview';
    this.props.hierarchyActions.selectNode(machineId, nodeType);
    this.props.history.push(location || `/machines/${machineId}/${machineTab}`);
    this.setState({ config: null });
  }

  selectDataOverview = (machineId) => {
    const { minimizeHierarchyViewPane } = this.props.hierarchyActions;
    const { isMobile } = this.state;
    if (isMobile) {
      minimizeHierarchyViewPane();
    }

    this.props.hierarchyActions.selectNode(machineId, 'data_overview');
    this.props.history.push(`/machines/${machineId}/data-overview`);
  }

  selectSite = (siteId) => {
    const { minimizeHierarchyViewPane } = this.props.hierarchyActions;
    const { isMobile } = this.state;
    if (isMobile) {
      minimizeHierarchyViewPane();
    }

    this.setState({ config: null });
    this.props.hierarchyActions.selectNode(siteId, 'site');
    this.props.history.push('/');
  }

  switchAccountifRequired = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const search = this.props.location.search;
    if (urlParams.get('account_id') && urlParams.get('tag_id')) {
      const new_account_id = parseInt(urlParams.get('account_id'), 10);
      this.props.assetDetailsActions.validateAndSwitchAccountOnTagId(new_account_id, () => {
        this.props.history.replace({
          pathname: '/configs',
          search
        });
      });
    }
  }

  isMachineChanged = (nodeId, nodeType) => {
    const currentMachineId = this.props.breadcrumb.machine.id;
    if (nodeType === 'site' || (nodeType === 'machine' && nodeId !== currentMachineId)) return true;
    if (nodeType === 'config' || nodeType === 'location') {
      const path = this.getpath(nodeId, nodeType);
      const params = hierarchyUtils.getHierarchyParams(path, nodeId, nodeType);
      if (params.machine.id && params.machine.id !== currentMachineId) return true;
    }
    return false;
  }

  checkNewSummaryExists = () => {
    if (this.analystSummaryRef.current) {
      const localSummary = this.analystSummaryRef.current.wrappedInstance.getLocalSummary();
      const reduxSummary = this.props.analystSummary;
      return isUnsavedAnalystSummaryExists(localSummary, reduxSummary);
    }
    return false;
  }

  setAnalystSummaryWarning = (value) => {
    this.setState({ analystSummaryWarning: value });
  }

  handleNodeClick = (nodeId, nodeType, machineTab = 'overview', discardAnalystSummary = false, location = null, clearQueryParams = false) => {
    if (this.analystSummaryRef.current) {
      if (!discardAnalystSummary) {
        if (this.checkNewSummaryExists() && this.isMachineChanged(nodeId, nodeType)) {
          this.handleNodeClickPrevParmas = { nodeId, nodeType, machineTab };
          this.setAnalystSummaryWarning(true);
          return;
        }
      }
    }
    let nodePropertyType = null;
    let nodePropertyValue = null;
    if (nodeType === 'config' && !nodeId) {
      const urlParams = new URLSearchParams(this.props.location.search);
      nodePropertyValue = urlParams.get('tag_id') && parseInt(urlParams.get('tag_id'), 10);
      nodePropertyType = 'tag_id';
    }
    switch (nodeType) {
      case 'site':
        this.selectSite(nodeId);
        break;
      case 'machine':
        this.selectMachine(nodeId, machineTab, location);
        break;
      case 'config':
        this.selectConfig(nodeId, nodePropertyType, nodePropertyValue, clearQueryParams);
        break;
      case 'location':
        this.selectLocation(nodeId);
        break;
      case 'machine_charts':
        this.selectMachine(nodeId, 'charts', location);
        break;
      case 'data_overview':
        this.selectMachine(nodeId, 'data-overview');
        break;
      default:
        break;
    }
  };

  getpath = (nodeId, nodeType) => {
    const path = [];
    hierarchyUtils.haspath(this.props.assetHierarchy.hierarchy, nodeId, nodeType, path);
    return path;
  }

  onChevronclick = () => {
    localStorage.setItem('hierarchyViewPaneLocal', JSON.stringify({
      currentWidth: this.props.hierarchyViewPane.previousWidth,
      previousWidth: this.props.hierarchyViewPane.currentWidth
    }));

    const { toggleHierarchyView } = this.props.hierarchyActions;
    toggleHierarchyView();
  }

  onMobileChevronclick = () => {
    const { toggleHierarchyViewOnMobile, } = this.props.hierarchyActions;
    toggleHierarchyViewOnMobile();
  }


  setEditModeState = (editMode) => {
    this.setState({ editMode });
  }

  handleTabChange = (e, data) => {
    const { activeIndex } = data;
    this.setState({ activeItem: this.tabItems[activeIndex] });
  };

  handleProfileItemClick = (e) => {
    if (e.target.name === 'profile') {
      this.setState({ config: null });
    }
  }

  onCancelWarningWindow = () => {
    this.handleNodeClickPrevParmas = null;
    this.setAnalystSummaryWarning(false);
    this.setDiscardAnalystSummary(false);
  }

  showAnalystWindow = (value) => {
    this.analystSummaryRef.current.wrappedInstance.showAnalystWindow(value);
  }

  onProceedWarningWindow = () => {
    this.setAnalystSummaryWarning(false);
    this.showAnalystWindow(false);
    //  this.handleNodeClickParams is used to identify the call is because of site change or node click
    if (this.handleNodeClickPrevParmas) {
      const { nodeId, nodeType, machineTab } = this.handleNodeClickPrevParmas;
      this.handleNodeClick(nodeId, nodeType, machineTab, true);
      this.handleNodeClickPrevParmas = null;
    } else {
      this.setDiscardAnalystSummary(true);
    }
  }

  setDiscardAnalystSummary = (value) => {
    this.setState({ discardAnalystSummary: value });
  }

  getMenuItems = () => {
    const { activeItem } = this.state;
    const items = homeTabItems.map(item => ({
      menuItem: (
        <Menu.Item
          as={Link}
          to={item.path}
          key={item.key}
          active={activeItem === item.key}
        >
          {item.title}
        </Menu.Item>
      ),
      key: item.key,
      path: item.path,
      title: item.title
    }));

    return items;
  };

  onDragFinished = (size) => {
    if (typeof size === 'number') {
      const { updateHierarchyViewPane } = this.props.hierarchyActions;
      updateHierarchyViewPane(size);

      // save pane width locally
      localStorage.setItem('hierarchyViewPaneLocal', JSON.stringify(this.props.hierarchyViewPane));
    }
  }

  getRoutes = () => {
    let excludeRouteKeys = ['customer-support-portal'];
    if (!(isPetasenseAdmin(this.props.user.user) || isPetasenseViewer(this.props.user.user))) {
      excludeRouteKeys = excludeRouteKeys.concat(tabsOnlyForPetasenseAdmins);
    }
    return homeTabItems.concat(getFlattenedAccountMenuRoutes(excludeRouteKeys));
  }

  closeModal = () => (
    this.props.location.state && this.props.location.state.from_app ?
      history.goBack() :
      history.push('/activity')
  );

  render() {
    const {
      user,
      history,
      accountSelectionState,
      breadcrumb,
      hierarchyViewPane: { currentWidth },
      adminDashboard,
      primaryColor,
      mobileHierarchyViewPane: { mobileCurrentWidth }
    } = this.props;

    const { isMobile } = this.state;
    const { hierarchy } = this.props.assetHierarchy;
    const { config, activeItem, analystSummaryWarning, discardAnalystSummary } = this.state;
    const accountsArray = _.get(user, 'user.accounts');
    if (accountSelectionState.loadingId) {
      return (
        <div className="full-screen-center">
          <LoadingSvg />
        </div>
      );
    }
    const dynamicCurrentWidth = isMobile ? mobileCurrentWidth : currentWidth;
    const dynamicClick = isMobile ? this.onMobileChevronclick : this.onChevronclick;

    return (
      <HomePageContainer className="home-page">
        {(isPetasenseAdmin(this.props.user.user) || isPetasenseViewer(this.props.user.user) || isPartnerAdmin(this.props.user.user)) && (
          !this.props.adminDashboard.adminOpen ? (
            <StyledLink title="Admin Dashboard" to={Routes.admin}>
              <User style={{ color: primaryColor }} id="admin" onClick={this.props.adminDashboardActions.toggleAdminDashboard} />
            </StyledLink>
          ) : (
          <StyledLink title="Home Page" to={Routes.home}>
            <Home style={{ color: primaryColor }} id="admin" />
          </StyledLink>)
        )}

        <ProfileDropdown onClick={this.handleProfileItemClick} />
        {breadcrumb.machine.id && !adminDashboard.adminOpen && (
          <AnalystSummaryView
            ref={this.analystSummaryRef}
            machine_id={breadcrumb.machine.id}
            location={history.location}
          />
        )}
        {analystSummaryWarning && (
          <AlertPrompt
            message="Are you sure you want to leave?"
            secondaryMessage="Analyst summary changes will be discarded"
            onCancel={this.onCancelWarningWindow}
            onProceed={this.onProceedWarningWindow}
          />
        )}
        <Content>
          <SubscriptionBanner />
          <BreadCrumbHeader
            accountsArray={accountsArray}
            location={history.location}
            editMode={this.state.editMode}
            handleLogoClick={this.handleNodeClick}
            checkNewSummaryExists={this.checkNewSummaryExists}
            setAnalystSummaryWarning={this.setAnalystSummaryWarning}
            discardAnalystSummary={discardAnalystSummary}
            setDiscardAnalystSummary={this.setDiscardAnalystSummary}
          />
          <HierarchyContainer direction="row">
            <CustomSplitPane
              split="vertical"
              onDragFinished={this.onDragFinished}
              defaultSize={currentWidth}
              // visualViewportWidth : changes the width based on what the user sees instead of browser
              minSize={Math.max(window.visualViewport.width * 0.15, 230)}
              maxSize={Math.min(window.visualViewport.width * 0.35, window.visualViewport.width - 100)}
              width={currentWidth}
              disableDrag={!currentWidth}
            >
              <Pane>
                {this.props.loadingReducer.GET_ASSET_HIERARCHY && <LoadingSvg />}
                {hierarchy.length > 0 && (
                  <MainContainer id="hierarchy-page">
                    <HierarchyView
                      handleNodeClick={this.handleNodeClick}
                      config_id={this.state.config ? this.state.config.id || this.state.config.config_id : -1}
                      onChevronclick={this.onChevronclick}
                      siteName={!_.isEmpty(hierarchy) ? hierarchy[0].node_name : ''}
                      editMode={this.state.editMode}
                      setEditModeState={this.setEditModeState}
                      location={history.location}
                    />
                  </MainContainer>
                )}
                {this.state.editMode && (
                  <Overlay
                    left={`${this.props.hierarchyViewPane.currentWidth}px`}
                    width={`calc(100vw - ${this.props.hierarchyViewPane.currentWidth}px)`}
                    onClick={() => this.setEditModeState(false)}
                  />
                )}
              </Pane>
              <Pane hierarchyViewPane={this.props.hierarchyViewPane}>
                {!adminDashboard.adminOpen && (
                <>
                 {!dynamicCurrentWidth && !this.state.editMode && (
                  <ChevronContainer id="hierarchy-page-close" left onClick={dynamicClick}>
                    <FlexContainer height="inherit" justifyContent="center" alignItems="center">
                     <ChevronRight size="sm" />
                    </FlexContainer>
                  </ChevronContainer>
                 )}
                 {!!dynamicCurrentWidth && !this.state.editMode && (
                  <ChevronContainer id="hierarchy-page-open" right onClick={dynamicClick}>
                   <FlexContainer height="inherit" justifyContent="center" alignItems="center">
                     <ChevronLeft size="sm" />
                   </FlexContainer>
                  </ChevronContainer>
                 )}
               </>
                )}
                {!accountSelectionState.loadingId && (
                 <SwitchContainer id="home-page-switch-container" open={!!currentWidth && hierarchy.length > 0}>
                  {this.tabItems.indexOf(activeItem) !== -1 && (
                   <DynamicTabs
                     menu={{ secondary: true }}
                     panes={this.getMenuItems()}
                     activeIndex={this.tabItems.indexOf(activeItem)}
                     onTabChange={this.handleTabChange}
                     bufferSpace={45}
                   />
                  )}
                 <Suspense fallback={<LoadingSvg height="100%" />}>
                 <Switch>
                  <Route
                    exact
                    path="/machines/build"
                    render={() => <MachineBuilderModal close={() => this.props.history.goBack()} />}
                  />
                 <Route
                   exact
                   path="/templates/build"
                   render={() => <MachineBuilderModal buildType="template" close={() => this.props.history.goBack()} />}
                 />
                <Route
                  path="/machines/new"
                  render={() => <MachineAdd close={() => this.props.history.goBack()} />}
                />
                <Route
                  path="/templates/new"
                  render={() => <MachineAdd close={() => this.props.history.goBack()} addType="template" />}
                />
                <Route
                  path="/devices/new"
                  component={SensorAdd}
                />
                <Route path="/templates/:template_id" component={TemplateDetails} />
                <Route
                  path="/machines/:machine_id/data-overview"
                  render={() => <DataOverview />}
                />
                <Route path="/machines/:machine_id" component={MachineDetails} />
                <Route exact path="/profile" component={ProfileSettings} />
                { this.getRoutes().map(item => <Route key={item.key} exact path={item.path} component={item.component} />) }
                <Route
                  path="/configs/:config_id"
                  render={() => {
                    if (!config || (config && config.default)) return <ChartsView updateConfig={this.updateConfig} />;
                    return <MultilineChartView />;
                  }}
                />
                <Route
                  path="/configs"
                  render={() => <LoadingSvg height="100%" />}
                />
                <Route
                  path="/location/:location_id"
                  render={() => <LocationChartView />}
                />
                { (isPetasenseAdmin(this.props.user.user) || isPetasenseViewer(this.props.user.user) || isPartnerAdmin(this.props.user.user)) && (
                 <Route
                   path="/admin-dashboard"
                   component={AdminDashboard}
                 />
                )}
               <Route
                 path="/tasks/:task_id"
                 exact
                 render={props => (
                    <TaskDetailsModal
                      isEditMode
                      closeModal={() => {
                        this.closeModal();
                        this.props.taskActions.closeTaskModal();
                      }}
                      {...props}
                    />
                 )}
               />
               <Route
                 path="/repairs/:repair_id"
                 exact
                 render={props => (
                    <RepairDetailsModal
                      isEditMode
                      closeModal={() => {
                        this.closeModal();
                        this.props.repairActions.closeRepairModal();
                      }}
                      {...props}
                    />
                 )}
               />
               <Route exact path="*" component={PageNotFound} />
                 </Switch>
                 </Suspense>
                 </SwitchContainer>
                )}
             </Pane>
             </CustomSplitPane>
          </HierarchyContainer>
        </Content>
        <HomePageModals />
      </HomePageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    assetHierarchyReducer,
    accountSelectionState,
    breadcrumb,
    machineDetails,
    user,
    currentAccount,
    hierarchyViewPane,
    loadingReducer,
    adminDashboard,
    companyReducer,
    mobileHierarchyViewPane,
  } = state;
  return {
    user,
    currentAccount,
    accountSelectionState,
    assetHierarchy: assetHierarchyReducer.assetInfo,
    breadcrumb,
    analystSummary: machineDetails.AS.summary,
    hierarchyViewPane,
    loadingReducer,
    adminDashboard,
    primaryColor: companyReducer.partner.theme.primaryColor,
    mobileHierarchyViewPane,
  };
};

const mapDispatchToProps = dispatch => ({
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch),
  hierarchyActions: bindActionCreators(hierarchyActions, dispatch),
  selectSite: bindActionCreators(selectAccount, dispatch),
  breadcrumbActions: bindActionCreators(breadcrumbActions, dispatch),
  adminDashboardActions: bindActionCreators(adminDashboardActions, dispatch),
  taskActions: bindActionCreators(taskActions, dispatch),
  repairActions: bindActionCreators(repairActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
