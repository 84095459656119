import styled from 'styled-components';
import PropTypes from 'prop-types';


const ScrollContainer = styled.div`
  overflow: auto;
  overflow-x: hidden;
  ${props => props.enabled && `
    width: ${props.width};
    max-height: ${props.height};
  `}
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #E5E8E1;
  }
`;

ScrollContainer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  enabled: PropTypes.bool
};

ScrollContainer.defaultProps = {
  width: '100%',
  height: '100%',
  enabled: true
};

ScrollContainer.displayName = 'ScrollContainer';

export default styled(ScrollContainer)``;
