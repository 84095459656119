import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as _ from 'lodash';

import { getActivePartnerBasedOnDomain } from 'home/DomainName/DomainConstants';
import { mapDayToString } from '../../../constants';
import { calendarOptions } from '../../../charts/constants';
import FlexContainer from '../../atoms/FlexContainer';
import Calendar from '../../molecules/ReactCalendar';
import CalendarSvg from '../../../images/CalendarSvg';
import Button from '../../atoms/Button';
import Radiobutton from '../../molecules/Radiobutton';
import Filter_T from '../../molecules/Filter';
import FilterItem from '../../atoms/FilterItem';
import CalenderContainer from '../atoms/CalendarContainer';
import DateContainer from '../atoms/DateContainer';
import DateSelector from '../atoms/DateSelector';
import OutsideAlerter from '../../../OutsideAlerter';
import InputField from '../atoms/InputField';

const Filter = styled(Filter_T)`
  height: 20px;
  border-right: ${props => props.borderRight};
  padding-right: 1em;
  z-index: 3;
  width: ${props => props.setWidth};
`;

const ListOption = styled.span`
  margin-left: 10px;
  color: ${props => props.selected ? props.theme.colors.black : props.theme.colors.greyXD};
  font-weight: ${props => props.selected && 600};
  white-space: pre;
`;

const formatDate = (date) => {
  const day = date.getDay();
  return mapDayToString[day][0];
};

class CalendarFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCustomDayFrom: props.customDayFrom,
      newCustomDayTo: props.customDayTo,
      dateExpanded: false,
      calendarOpen: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { customDayFrom, customDayTo, calendarExpanded } = this.props;
    if (
      !_.isEqual(prevProps.calendarExpanded, calendarExpanded) && (
        !_.isEqual(prevState.newCustomDayFrom, customDayFrom) ||
        !_.isEqual(prevState.newCustomDayTo, customDayTo)
      )
    ) {
      this.setState({
        newCustomDayFrom: customDayFrom,
        newCustomDayTo: customDayTo
      });
    }
  }

  toggleDateExpanded = () => {
    this.setState(prevState => ({
      dateExpanded: !prevState.dateExpanded
    }));
  };

  toggleCustomCalendar = (str) => {
    this.setState({
      calendarOpen: str || null
    });
  };

  onClickDay = (value, label) => {
    if (label === 'from') {
      this.setState({
        newCustomDayFrom: value,
        calendarOpen: null
      });
    } else if (label === 'to') {
      this.setState({
        newCustomDayTo: value,
        calendarOpen: null
      });
    }
  };

  onClickOption = (option) => {
    if (option.value === 'custom') {
      const { newCustomDayFrom, newCustomDayTo, dateExpanded } = this.state;
      if (!dateExpanded && newCustomDayFrom && newCustomDayTo) {
        this.calendarSelect(option.value);
      }
      this.toggleDateExpanded();
    } else {
      this.calendarSelect(option.value);
    }
  }

  calendarSelect = (value) => {
    if (value !== 'custom') {
      this.props.calendarSelect(value);
      this.setState({
        dateExpanded: false,
        newCustomDayFrom: null,
        newCustomDayTo: null
      });
    } else this.props.calendarSelect(value, this.state.newCustomDayFrom, this.state.newCustomDayTo);
  }

  render() {
    const {
      alignHorizontal,
      calendarExpand,
      calendarExpanded,
      customDayFrom,
      customDayTo,
      disabled,
      days,
      borderRight,
      title
    } = this.props;
    const {
      dateExpanded,
      calendarOpen,
      newCustomDayFrom,
      newCustomDayTo,
    } = this.state;
    const primaryColor = this.props.primaryColor;
    const greyColor = this.props.greyColor;
    return (
      <>
        <Filter
          open={calendarExpanded}
          toggleOpen={() => { if (!disabled) calendarExpand(); }}
          Icon={!title && (() => <CalendarSvg fill={calendarExpanded ? primaryColor : greyColor} />)}
          title={title}
          alignHorizontal={alignHorizontal}
          width="max-content"
          zIndex={2}
          disabled={disabled}
          borderRight={borderRight}
        >
          <DateContainer>
            {this.props.calendarOptions.map((option, idx) => {
              let selected = days === option.value;
              if (dateExpanded) selected = option.value === 'custom';
              return (
                <FilterItem
                  key={idx.toString()}
                  width="119px"
                  selected={selected}
                  onClick={() => this.onClickOption(option)}
                >
                  <Radiobutton margin="0px 0px 0px 12px" value={selected} />
                  <ListOption selected={selected}>{option.text}</ListOption>
                </FilterItem>
              );
            })}
          </DateContainer>
          {dateExpanded && (
            <DateSelector>
              {calendarOpen === 'from' && (
                <OutsideAlerter open={calendarOpen === 'from'} handleClick={this.toggleCustomCalendar}>
                  <CalenderContainer>
                    <Calendar
                      formatShortWeekday={(locale, date) => formatDate(date)}
                      maxDetail="month"
                      maxDate={newCustomDayTo || new Date()}
                      showNeighboringMonth={false}
                      onClickDay={value => this.onClickDay(value, 'from')}
                      value={newCustomDayFrom}
                    />
                  </CalenderContainer>
                </OutsideAlerter>
              )}
              {calendarOpen === 'to' && (
                <OutsideAlerter open={calendarOpen === 'to'} handleClick={this.toggleCustomCalendar}>
                  <CalenderContainer to="true">
                    <Calendar
                      formatShortWeekday={(locale, date) => formatDate(date)}
                      maxDetail="month"
                      maxDate={new Date()}
                      minDate={newCustomDayFrom}
                      value={newCustomDayTo}
                      showNeighboringMonth={false}
                      onClickDay={value => this.onClickDay(value, 'to')}
                    />
                  </CalenderContainer>
                </OutsideAlerter>
              )}
              <InputField label="From" readOnly value={newCustomDayFrom ? newCustomDayFrom.toString().slice(0, 15) : ''} onFocus={() => this.toggleCustomCalendar('from')} />
              <InputField label="To" readOnly value={newCustomDayTo ? newCustomDayTo.toString().slice(0, 15) : ''} onFocus={() => this.toggleCustomCalendar('to')} />
              <FlexContainer justifyContent="space-between">
                <Button
                  text
                  danger
                  onClick={this.toggleDateExpanded}
                >
                  Close
                </Button>
                <Button
                  text
                  disabled={
                    !newCustomDayFrom ||
                    !newCustomDayTo ||
                    (new Date(newCustomDayFrom).getTime() === new Date(customDayFrom).getTime()
                      && new Date(newCustomDayTo).getTime() === new Date(customDayTo).getTime())
                  }
                  onClick={() => this.calendarSelect('custom')}
                >
                  Submit
                </Button>
              </FlexContainer>
            </DateSelector>)
          }
        </Filter>
      </>
    );
  }
}

CalendarFilter.propTypes = {
  calendarSelect: PropTypes.func,
  calendarExpand: PropTypes.func,
  calendarExpanded: PropTypes.bool,
  calendarOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]).isRequired,
      text: PropTypes.string.isRequired
    })
  ),
  disabled: PropTypes.bool,
  days: PropTypes.number.isRequired,
  borderRight: PropTypes.string,
  alignHorizontal: PropTypes.string
};

CalendarFilter.defaultProps = {
  calendarSelect: () => { },
  calendarExpand: () => { },
  calendarOptions,
  calendarExpanded: false,
  disabled: false,
  borderRight: '1px solid grey',
  alignHorizontal: 'left'
};

const mapStateToProps = state => ({
  primaryColor: state.companyReducer.partner.theme.primaryColor,
  greyColor: state.companyReducer.partner.theme.grey
});

export default connect(mapStateToProps)(CalendarFilter);
