import _ from 'lodash';
import moment from 'moment-timezone';
import { alertActions } from '../../../../../alert';
import { axiosInstance, ENDPOINT } from '../../../../../common/constants';
import { handleResponse } from '../../../../../common/helpers';
import machineChartsTypes from './machineCharts.types';
import { formatChartData, convertTimestringsToDateObjects } from '../../../../../common/utils';
import { getHierarchy } from '../../../../AssetHierarchy/actions/assetDetails.actions';

export const getChartsData = (params) => {
  const config_id = params.config_id;
  const request = () => ({ type: machineChartsTypes.GET_CHARTS_DATA_REQUEST, config_id });
  const success = (data, time_range) => ({ type: machineChartsTypes.GET_CHARTS_DATA_SUCCESS, config_id, data, time_range });
  const failure = error => ({ type: machineChartsTypes.GET_CHARTS_DATA_FAILURE, config_id, error });

  params.version = 2;
  return (dispatch, getState) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_CHARTS_DATA, { params: { ...params } })).then(
      (res) => {
        const data = res.response.map(d => ({
          ...d,
          trend_data: formatChartData(
            d.trend_data.data,
            convertTimestringsToDateObjects(d.trend_data.time),
            d.trend_data.outliers ? convertTimestringsToDateObjects(d.trend_data.outliers) : null,
            { measurement_id: d.trend_data.measurement_id, speed: d.trend_data.speed }
          ),
          days: params.days
        }));
        let time_range;
        if (res.time_range) {
          time_range = [
            moment(res.time_range.start_timestamp),
            moment(res.time_range.end_timestamp)
          ];
        }
        // order data to match the order of trends_config
        // TODO: this is not suitable as a unique identifier, there can be duplicates
        const machineDetails = getState().machineDetails;
        const configItems = machineDetails.charts.config ? machineDetails.charts.config.items : [];
        if (configItems !== undefined && configItems.length > 0) {
          const configItem = _.find(configItems, item => item.config_id === config_id);
          if (configItem !== undefined) {
            const orderedData = configItem.trends_config.map(config =>
              _.find(data, (d) => {
                let sameObject = true;
                if (config.tag_id) {
                  sameObject = d.tag_id === config.tag_id;
                }
                if (config.tag_type) {
                  sameObject = d.tag_type === config.tag_type;
                }
                if (config.feature) {
                  sameObject = d.feature === config.feature;
                }
                if (config.amp_type) {
                  sameObject = d.amp_type === config.amp_type;
                }
                if (config.color) {
                  sameObject = d.color === config.color;
                }
                return sameObject;
              })
            );
            // FIXME: undefined is found if two tags with same tag_id is present.
            const tempFix = orderedData.filter(d => d !== undefined);
            dispatch(success(tempFix, time_range));
          }
        } else {
          const tempFix = data.filter(d => d !== undefined);
          dispatch(success(tempFix, time_range));
        }
        return data;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

export const getMachineTags = (machineId, storeInRedux = true) => {
  const request = () => ({ type: machineChartsTypes.GET_MACHINE_CHARTS_TAGS_REQUEST, machineId });
  const success = tags => ({ type: machineChartsTypes.GET_MACHINE_CHARTS_TAGS_SUCCESS, tags });
  const failure = error => ({ type: machineChartsTypes.GET_MACHINE_CHARTS_TAGS_FAILURE, error });

  return (dispatch) => {
    if (storeInRedux) dispatch(request());

    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_TAGS(machineId))).then(
      (res) => {
        if (storeInRedux) dispatch(success(res));
        return res;
      },
      (error) => {
        if (storeInRedux) dispatch(failure(error));
        dispatch(alertActions.error(error.message));
      }
    );
  };
};

export const chartsFilters = filters => ({ type: machineChartsTypes.CHARTS_FILTERS, filters });

export const getTagTrend = (tagId, params) => {
  const request = tagId => ({ type: machineChartsTypes.GET_TAG_TREND_REQUEST, tagId });
  const success = (tagId, trend) => ({
    type: machineChartsTypes.GET_TAG_TREND_SUCCESS,
    tagId,
    trend
  });
  const failure = (tagId, error) => ({
    type: machineChartsTypes.GET_TAG_TREND_FAILURE,
    tagId,
    error
  });
  /* params:
    feature:
    amp_type:
    days:
    from_timestamp:
    to_timestamp:
  */
  return (dispatch) => {
    dispatch(request(tagId));

    // const { days, ...rest } = params;

    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_TREND(tagId), { params })).then(
      (res) => {
        const trend = {
          ...res,
          trend_data: formatChartData(
            res.trend_data.data,
            convertTimestringsToDateObjects(res.trend_data.time),
            null,
            { measurement_id: res.trend_data.measurement_id }
          ),
          days: params.days
        };
        dispatch(success(tagId, trend));
      },
      (error) => {
        dispatch(failure(tagId, error));
        dispatch(alertActions.error(error.message));
      }
    );
  };
};

export const getTagWaveform = (tagId, params, configId) => {
  const request = () => ({ type: machineChartsTypes.GET_TAG_WAVEFORM_REQUEST, configId });
  const success = waveform => ({
    type: machineChartsTypes.GET_TAG_WAVEFORM_SUCCESS,
    configId,
    waveform
  });
  const failure = error => ({ type: machineChartsTypes.GET_TAG_WAVEFORM_FAILURE, configId, error });

  params.timestamp = moment(params.timestamp).toISOString();

  return (dispatch) => {
    dispatch(request(tagId));

    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_WAVEFORM(tagId), { params })).then(
      (res) => {
        const waveform = {
          ...res,
          waveform_data: formatChartData(
            res.waveform_data,
            _.map(res.waveform_data, (d, i) => (1000 / res.sampling_rate) * i)
          )
        };
        dispatch(success(waveform));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

export const getTagSpectrum = (tagId, params, configId) => {
  const request = () => ({ type: machineChartsTypes.GET_TAG_SPECTRUM_REQUEST, configId });
  const success = spectrum => ({
    type: machineChartsTypes.GET_TAG_SPECTRUM_SUCCESS,
    configId,
    spectrum
  });
  const failure = error => ({ type: machineChartsTypes.GET_TAG_SPECTRUM_FAILURE, configId, error });

  params.timestamp = moment(params.timestamp).toISOString();

  return (dispatch) => {
    dispatch(request(tagId));
    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUM(tagId), { params })).then(
      (res) => {
        const spectrum = {
          ...res,
          spectrum_data: formatChartData(res.spectrum_data.mag, res.spectrum_data.freq)
        };
        dispatch(success(spectrum));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

export const getTagDemodSpectrum = (tagId, params, configId) => {
  const request = () => ({ type: machineChartsTypes.GET_TAG_DEMOD_SPECTRUM_REQUEST, configId });
  const success = spectrum => ({
    type: machineChartsTypes.GET_TAG_DEMOD_SPECTRUM_SUCCESS,
    configId,
    spectrum
  });
  const failure = error => ({ type: machineChartsTypes.GET_TAG_DEMOD_SPECTRUM_FAILURE, configId, error });

  params.timestamp = moment(params.timestamp).toISOString();
  return (dispatch) => {
    dispatch(request(tagId));
    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUM(tagId), { params })).then(
      (res) => {
        const spectrum = {
          ...res,
          spectrum_data: formatChartData(res.spectrum_data.mag, res.spectrum_data.freq)
        };
        dispatch(success(spectrum));
      },
      (error) => {
        dispatch(failure(error.message));
      }
    );
  };
};

export const reorderCharts = (items, machine_id) =>
  (dispatch, getState) => {
    const originalOrder = _.cloneDeep(getState().machineDetails.charts.config.items);
    const configMapArray = items.reduce((acc, cur, idx) => {
      const arrObj = {
        config_id: cur.config_id,
        sequence_no: originalOrder[idx].sequence_no
      };
      acc.push(arrObj);
      return acc;
    }, []);
    const newConfigOrder = items.map((cur, idx) => {
      cur.sequence_no = originalOrder[idx].sequence_no;
      return cur;
    });
    dispatch({ type: machineChartsTypes.REORDER_CHARTS, items: newConfigOrder });
    return handleResponse(axiosInstance.post(ENDPOINT.REORDER_CHARTS, { machine_id, config_map: configMapArray }))
      .then(
        (res) => {
          const newConfigOrder = items.map((cur, idx) => {
            cur.sequence_no = originalOrder[idx].sequence_no;
            return cur;
          });
          dispatch({ type: machineChartsTypes.REORDER_CHARTS, items: newConfigOrder });
        },
        (err) => {
          dispatch({ type: machineChartsTypes.REORDER_CHARTS_FAILURE });
        }
      );
  };

// FIXME: change to get components API instead of machine info.
export const getChartsConfig = (params) => {
  const machine_id = params.machine_id;
  const request = () => ({ type: machineChartsTypes.GET_CHARTS_CONFIG_REQUEST, machine_id });
  const success = config => ({ type: machineChartsTypes.GET_CHARTS_CONFIG_SUCCESS, config });
  const failure = error => ({ type: machineChartsTypes.GET_CHARTS_CONFIG_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    handleResponse(axiosInstance.get(ENDPOINT.CHARTS_CONFIG_LIST, { params }))
      .then(
        (res) => {
          const config = res.configurations;
          dispatch(success(config));
        },
        (error) => {
          dispatch(failure(error));
        },
      );
  };
};

export const getComponentOptions = machine_id =>
  dispatch =>
    handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_COMPONENTS(machine_id)))
      .then(res => res.items, (error) => {
        throw error.message;
      });

export const postChartsConfig = (machine_id, trends_config, name, storeInRedux = true) => {
  const request = () => ({ type: machineChartsTypes.POST_CHARTS_CONFIG_REQUEST });
  const success = config => ({ type: machineChartsTypes.POST_CHARTS_CONFIG_SUCCESS, config, name });
  const failure = error => ({ type: machineChartsTypes.POST_CHARTS_CONFIG_FAILURE, error });

  return (dispatch) => {
    if (storeInRedux) dispatch(request());
    return handleResponse(
      axiosInstance.post(ENDPOINT.CHARTS_CONFIG_LIST, { machine_id, trends_config, name })
    ).then(
      (res) => {
        if (storeInRedux) dispatch(success(res));
        return res;
      },
      (error) => {
        if (storeInRedux) dispatch(failure(error));
        throw error.message;
      }
    );
  };
};


export const postDerivedTag = (params, storeInRedux = true) => {
  const request = () => ({ type: machineChartsTypes.POST_DERIVED_TAG_REQUEST });
  const success = config => ({ type: machineChartsTypes.POST_DERIVED_TAG_SUCCESS, config });
  const failure = error => ({ type: machineChartsTypes.POST_DERIVED_TAG_FAILURE, error });

  return (dispatch) => {
    if (storeInRedux) dispatch(request());
    return handleResponse(
      axiosInstance.post(ENDPOINT.POST_DERIVED_TAG, params)
    ).then(
      (res) => {
        dispatch(getHierarchy());
        if (storeInRedux) dispatch(success(res));
        return res;
      },
      (error) => {
        if (storeInRedux) dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const putChartsConfig = (config_id, trends_config, name, defaultChart = false, storeInRedux = true) => {
  const request = () => ({ type: machineChartsTypes.PUT_CHARTS_CONFIG_REQUEST });
  const success = () => ({
    type: machineChartsTypes.PUT_CHARTS_CONFIG_SUCCESS,
    config_id,
    trends_config,
    name
  });
  const failure = error => ({ type: machineChartsTypes.PUT_CHARTS_CONFIG_FAILURE, error });

  return (dispatch) => {
    const data = {
      name,
      trends_config
    };
    if (storeInRedux) dispatch(request());
    return handleResponse(
      axiosInstance.put(ENDPOINT.CHARTS_CONFIG(config_id), data)
    ).then(
      () => {
        if (storeInRedux) dispatch(success());
      },
      (error) => {
        if (storeInRedux) dispatch(failure(error));
        throw error.message;
      }
    );
  };
};

export const deleteChartsConfig = (config_id, reloadHeirarchy = false) => {
  const request = () => ({ type: machineChartsTypes.DELETE_CHARTS_CONFIG_REQUEST });
  const success = () => ({ type: machineChartsTypes.DELETE_CHARTS_CONFIG_SUCCESS, config_id });
  const failure = error => ({ type: machineChartsTypes.DELETE_CHARTS_CONFIG_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.delete(ENDPOINT.CHARTS_CONFIG(config_id))).then(
      () => {
        if (reloadHeirarchy) dispatch(getHierarchy());
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

export const getChartsConfigMetadata = () => {
  const request = () => ({ type: machineChartsTypes.GET_CHARTS_CONFIG_METADATA_REQUEST });
  const success = metadata => ({
    type: machineChartsTypes.GET_CHARTS_CONFIG_METADATA_SUCCESS,
    metadata
  });
  const failure = error => ({ type: machineChartsTypes.GET_CHARTS_CONFIG_METADATA_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.CHARTS_CONFIG_METADATA)).then(
      (res) => {
        dispatch(success(res));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        throw error;
      }
    );
  };
};

export const getTagWaterfallSpectrum = (tagId, params, configId) => {
  const request = () => ({ type: machineChartsTypes.GET_TAG_WATERFALL_SPECTRUM_REQUEST, configId });
  const success = data => ({
    type: machineChartsTypes.GET_TAG_WATERFALL_SPECTRUM_SUCCESS,
    configId,
    data
  });
  const failure = error => ({ type: machineChartsTypes.GET_TAG_WATERFALL_SPECTRUM_FAILURE, configId, error });

  return (dispatch) => {
    dispatch(request(tagId));
    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUMS(tagId), { params })).then(
      (res) => {
        const data = {
          ...res,
          items: res.items.map((item) => {
            const spectrum_data = formatChartData(item.spectrum_data.mag, item.spectrum_data.freq);
            return {
              ...item,
              spectrum_data
            };
          })
        };
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.message));
      }
    );
  };
};

export const postOutlierReading = (tagId, timestamp) => {
  const success = (message, outlier_id) => ({ type: machineChartsTypes.POST_OUTLIER_READING_SUCCESS, message, outlier_id });
  const failure = error => ({ type: machineChartsTypes.POST_OUTLIER_READING_FAILURE, error });
  return dispatch => handleResponse(axiosInstance.post(ENDPOINT.POST_OUTLIER_READING(tagId, timestamp))).then(
    (res) => {
      dispatch(success(res.message, res.outlier_id));
      return res;
    },
    (error) => {
      dispatch(failure(error));
    }
  );
};

export const deleteOutlierReading = (tagId, timestamp) => {
  const success = message => ({ type: machineChartsTypes.DELETE_OUTLIER_READING_SUCCESS, message });
  const failure = error => ({ type: machineChartsTypes.DELETE_OUTLIER_READING_FAILURE, error });
  return dispatch => handleResponse(axiosInstance.delete(ENDPOINT.DELETE_OUTLIER_READING(tagId, timestamp))).then(
    (res) => {
      dispatch(success(res.message));
      return res;
    },
    (error) => {
      dispatch(failure(error));
    }
  );
};
