import { combineReducers } from 'redux';

import {
  sensorsListReducer,
  unAssociatedSensorList,
  sensorsSorter,
  sensorsFilters,
  selectSensor,
  sensorDetailsReducer,
  sensorsMetadataReducer,
  selectedSensorList,
  unassignedSensors,
  filterResourceList,
  componentList,
  locationList,
  deviceLogsData,
  lastReportedInfo
} from './reducers/sensors.reducers';


export const sensors = combineReducers({
  items: sensorsListReducer,
  sorter: sensorsSorter,
  filters: sensorsFilters,
  selectedId: selectSensor,
  selectedSensorList,
  details: sensorDetailsReducer,
  metadata: sensorsMetadataReducer,
  unassociatedItems: unAssociatedSensorList,
  unassignedSensors,
  filterResourceList,
  componentList,
  locationList,
  deviceLogsData,
  lastReportedInfo
});
