import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';

import InputField from 'common/components/atoms/InputField';
import Modal from 'common/components/organisms/Modal';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Button_T from 'common/components/atoms/Button';

import * as machineBuilderActions from '../../../Machines/MachineBuilder/actions/machineBuilder.actions';

const ButtonContainer = styled(FlexContainer)`
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
`;

const Button = styled(Button_T)`
  padding-left: 10px;
  margin-top: 0;
`;

class AssetPasteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      error: ''
    };
  }

  handleNameChange = (e) => {
    const value = e.target.value;
    this.setState({ name: value });
    if (!value) this.setState({ error: 'Asset name can not be empty' });
    else this.setState({ error: '' });
  }

  saveAsset = () => {
    const { machineBuilderActions, closeModal, areaInfo } = this.props;
    const name = this.state.name.trim();
    if (!name) {
      this.setState({ error: 'Asset name can not have whitespaces only' });
      return;
    }
    const details = _.cloneDeep(this.props.details);
    details.name = name;
    if (areaInfo) details.area_name = areaInfo.node_name;
    machineBuilderActions.createMachine(details).then(
      () => {
        toastr.success(`Asset ${name} created sucessfully`);
      }
    );
    closeModal();
  }

  render() {
    const { name, error } = this.state;
    const { closeModal } = this.props;
    return (
      <Modal id="asset-paste" title="Paste Asset" close={closeModal} width="40%">
        <InputField
          maxLength={100}
          onChange={this.handleNameChange}
          placeholder="Enter Asset Name"
          type="text"
          value={name}
          error={error}
        />
        <ButtonContainer>
          <Button disabled={name.length === 0 || !!error} onClick={this.saveAsset}>Save</Button>
          <Button text onClick={closeModal} alignVertical>
            Cancel
          </Button>
        </ButtonContainer>
      </Modal>
    );
  }
}

AssetPasteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  areaInfo: PropTypes.object,
  details: PropTypes.object.isRequired
};

AssetPasteModal.defaultProps = {
  areaInfo: null
};

const mapDispatchToProps = dispatch => ({
  machineBuilderActions: bindActionCreators(machineBuilderActions, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(AssetPasteModal);
