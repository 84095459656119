import styled from 'styled-components';

import Radiobutton from '../molecules/Radiobutton';

const FilterItem = styled.div`
  display: flex;
  align-items: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  padding: 10px;
  text-align: left;
  width: 100%;
  border: none;
  outline: none;
  background-color: white;
  font-size: 14px;
  font-family: 'Petasense Open Sans';
  font-weight: 600;
  color: ${props => props.theme.colors.lightGray};
  ${props => props.selected && `color: ${props.theme.colors.black};`}
   &:hover {
    ${props => !props.disabled && `color: ${props.theme.colors.black};`}
    background-color: #f7f7f7;
  }
  &:first-of-type {
    padding-top: 1em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    padding-bottom: 1em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  ${Radiobutton} {
    margin-left: 5px;
  }
`;

export default styled(FilterItem)``;
