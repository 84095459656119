import {
  templatesActionConstants,
} from 'home/Templates/actions/templates.constants';
import _ from 'lodash';

const initialState = {
  items: [],
  selectedItems: [],
  templateInfo: {
    details: {},
    loadingDetails: false,
    updatingDetails: false,
    error: '',
  },
  saveAsGlobal: false,
};

export const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case templatesActionConstants.GET_ALL_TEMPLATES: {
      return {
        ...state,
        items: action.templateArr,
      };
    }
    case templatesActionConstants.SELECT_TEMPLATE_FOR_CREATION: {
      return {
        ...state,
        selectedItems: _.union(state.selectedItems, action.templateArr),
      };
    }
    case templatesActionConstants.REMOVE_TEMPLATE_FOR_CREATION: {
      let tmpArray = _.cloneDeep(state.selectedItems);
      for (let i = 0; i < action.templateArr.length; i++) {
        tmpArray = tmpArray.filter(template => template.id !== action.templateArr[i].id);
      }
      return {
        ...state,
        selectedItems: tmpArray,
      };
    }
    case templatesActionConstants.CLEAR_SELECTED_TEMPLATES: {
      return {
        ...state,
        selectedItems: [],
      };
    }
    case templatesActionConstants.UPDATE_TEMPLATE_NUMS: {
      const updatedArr = state.selectedItems.map((item) => {
        if (item.id !== action.id) return item;
        return { ...item, num: action.num };
      });
      return {
        ...state,
        selectedItems: [
          ...updatedArr,
        ],
      };
    }
    case templatesActionConstants.GET_TEMPLATE_INFO_REQUEST: {
      return {
        ...state,
        templateInfo: {
          ...state.templateInfo,
          loadingDetails: true,
          error: '',
        },
      };
    }
    case templatesActionConstants.GET_TEMPLATE_INFO_SUCCESS: {
      const info = _.cloneDeep(action.details);
      for (let i = 0; i < info.components.length; i++) {
        info.components[i].id = i;
      }
      return {
        ...state,
        templateInfo: {
          ...state.templateInfo,
          details: info,
          loadingDetails: false,
          error: '',
        },
      };
    }
    case templatesActionConstants.GET_TEMPLATE_INFO_FAILURE: {
      return {
        ...state,
        templateInfo: {
          ...state.templateInfo,
          details: {},
          error: action.error,
          loadingDetails: false,
        },
      };
    }
    case templatesActionConstants.SET_SAVE_AS_GLOBAL: {
      return {
        ...state,
        saveAsGlobal: action.value,
      };
    }
    default:
      return state;
  }
};
