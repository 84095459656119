import styled from 'styled-components';
import FlexContainer from 'common/components/atoms/FlexContainer';

const DevicesHealthContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  height: calc(100vh - 110px);
  padding-bottom: 2em;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #E5E8E1;
  }
`;

export default styled(DevicesHealthContainer)``;
