import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toastr } from 'react-redux-toastr';
import * as _ from 'lodash';
import * as d3 from 'd3';

import { round } from 'common/utils';
import AlertPrompt from 'common/components/organisms/AlertPrompt';
import OutsideAlerter from '../../../common/OutsideAlerter';
import {
  calculateMaxReportingInterval,
  convertScheduleFormat,
  DayTimings,
  dayTimeArray,
  DayMap
} from '../utils/sensorUtils';

import FlexContainer from '../../../common/components/atoms/FlexContainer';
import Modal from '../../../common/components/organisms/Modal';
import InputField_T from '../../../common/components/atoms/InputField';
import Label_T from '../../../common/typography/Label/Label';
import CrossSvg from '../../../common/images/CrossSvg';
import DeleteSvg from '../../../common/images/BearingModal/DeleteIconSvg';
import Button_T from '../../../common/components/atoms/Button';

const SchedulerContainer = styled(FlexContainer)`
  position: relative;
  margin-bottom: 10px;
  .x-axis-label {
    font-size: 10px;
    font-family: 'Petasense Open Sans';
    font-weight: 600;
  }
  .measurement-interval-text-label {
    font-size: 11px;
    font-family: 'Petasense Open Sans';
    font-weight: 600;
    fill: #fff;
    text-anchor: middle;
    &.sm {
      font-size: 9px;
    }
  }
  .questionMark {
    font-size: 11px;
    font-family: 'Petasense Open Sans';
    font-weight: 600;
    fill: #fff;
    text-anchor: middle;
  }
  svg {
    cursor: pointer;
    .disabled {
      cursor: default;
    }
    ${props => !props.editMode && 'cursor: default;'}
  }
`;

const Label = styled(Label_T)`
  ${props => props.block && 'display: block;'}
`;

const H4 = styled.h4`
  width: max-content;
  margin: auto;
  font-size: 12px;
  color: #3C3D3A;
  font-family: 'Petasense Open Sans';
`;

const Button = styled(Button_T)`
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.block && 'display: block;'}
  ${props => props.marginbottom && `margin-bottom: ${props.marginbottom};`}
  ${props => props.marginright && `margin-right: ${props.marginright};`}
`;

const DayFlexContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  font-size: 10px;
  font-weight: 600;
`;

const InputField = styled(InputField_T)`
  ${props => props.marginbottom && `margin-bottom: ${props.marginbottom};`}
`;

const SvgContainer = styled.span`
  ${props => props.marginright && `margin-right: ${props.marginright};`}
  &:hover #delete-icon-stroke {
    stroke: #FF0000;
  }
  &:hover #cross-svg-path {
    stroke: ${props => props.theme.colors.black};
  }
`;

const DayContainer = styled.span`
  height: 78px;
  width: 150px;
  text-align: center;
  line-height: 78px;
  vertical-align: middle;
  background-color: #F7F8F6;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Petasense Open Sans';
  color: ${props => props.theme.colors.darkGray};
  border: 1px solid ${props => props.theme.colors.borderGray};
  ${props => props.marginBottom ? `margin-bottom: ${props.marginBottom};` : 'border-bottom: none;'}
`;

const Container = styled.div`
  padding: 0 3em;
`;

const AbsoluteDiv = styled.div`
  position: absolute;
  padding: 1em;
  width: 300px;
  top: ${props => `${props.top}px`};
  left: ${props => `${props.left}px`};
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.12);
  background-color: white;
  border-radius: 4px;
  z-index: 3;
`;

const Circle = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${props => props.background};
`;

const LabelContainer = styled(FlexContainer)`
  width: max-content;
  align-items: center;
  margin: auto;
  label {
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 20px;
    color: #999B95;
  }
`;

const ConvertIntervalToHours = (list, key) => {
  if (_.isArray(list)) return list.map(item => ({ ...item, [key]: item[key] / 3600 }));
  if (_.isPlainObject(list)) {
    const temp = {};
    Object.keys(list).forEach((k) => {
      temp[k] = list[k].map(item => ({ ...item, [key]: item[key] / 3600 }));
    });
    return temp;
  }
  return [];
};

const DayTimeString = (day, time) => {
  let suffix = 'am';
  // eslint-disable-next-line prefer-const
  let [hr, min] = time.split(':');
  if (Number(hr) >= 12) {
    suffix = 'pm';
    if (Number(hr) > 12) hr -= 12;
  }
  if (Number(hr) === 0) hr = '12';
  return `${day} at ${hr}:${min} ${suffix}`;
};

const CONSTANTS = {
  SVG_HEIGHT: 546,
  NO_OF_DAYS: 7,
  NO_OF_COLUMNS: 48,
  ROWS_PER_DAY: 3,
  HEIGHT_PER_ROW: 26,
  MEASUREMENT_TYPE_COLOR: {
    0: '#91b846',
    1: '#ec8243',
    2: '#8d2eba'
  }
};

const RoundOffToNearestSupportedTime = (start) => {
  let day = start[0];
  const time = start[1];
  let [hrs, min] = time.split(':');
  if (Number(min) > 45) {
    hrs = Number(hrs) + 1;
    min = '00';
    if (hrs > 23) {
      hrs = '00';
      day = Number(day) + 1;
      if (day > 6) day = '0';
    }
    if (hrs < 10) {
      hrs = `0${hrs}`;
    }
  } else if (Number(min) < 15) {
    min = '00';
  } else {
    min = '30';
  }
  return [`${day}`, `${hrs}:${min}`];
};

const MeasurementScheduleModal = (props) => {
  const MeasurementIntervals = useRef(ConvertIntervalToHours(props.intervals, 'value'));

  const RectRefHashMap = useRef({});

  const scheduleMapFromProps = useRef({});
  const stopsMapFromProps = useRef({});

  // 2 High res, 1 full bandwidth, 0 Overalls
  const scheduleMap = useRef({
    0: [],
    1: [],
    2: []
  });
  const stopsMap = useRef({
    0: [],
    1: [],
    2: []
  });
  const [saveEnabled, setSaveEnabled] = useState(false);

  const measurementModalOpen = useRef(false);
  const deleteMeasurement = useRef(false);
  const stopMeasurement = useRef(false);
  const disableInput = useRef(false);
  const enableMeasurement = useRef(false);
  const [selectedInterval, setSelectedInterval] = useState(0);
  const [selectedHighResInterval, setSelectedHighResInterval] = useState(0);
  const [aggressiveModal, setAggressiveModal] = useState(false);
  const [reportingModal, setReportingModal] = useState(false);
  const [maxReportingInterval, setMaxReportingInterval] = useState(null);

  const [selectedType, setSelectedType] = useState(1);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [selectedRow, setSelectedRow] = useState(1);
  const [selectedColumn, setSelectedColumn] = useState(1);

  const svgContainerRef = useRef(null);
  const svgRef = useRef(null);

  const useForceUpdate = () => {
    const [, setState] = useState();
    return () => setState({});
  };

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const schedule = {
      0: [],
      1: [],
      2: []
    };
    const stops = {
      0: [],
      1: [],
      2: []
    };
    Object.entries(props.schedule).forEach(([key, value]) => {
      const s = ConvertIntervalToHours(value, 'interval');
      schedule[key] = s.map(s1 => ({ ...s1, start: RoundOffToNearestSupportedTime(s1.start).join(), end: s1.end.join() }));
    });
    Object.entries(props.stops).forEach(([key, value]) => {
      stops[key] = value.map(s => s.join());
    });
    scheduleMap.current = schedule;
    stopsMap.current = stops;
    d3.select('.main_scheduler_svg')
      .attr('width', svgContainerRef.current.getBoundingClientRect().width - 150);
    initializeSvg();
    drawGraph();
    scheduleMapFromProps.current = _.cloneDeep(scheduleMap.current);
    stopsMapFromProps.current = _.cloneDeep(stopsMap.current);
  }, []);

  useEffect(() => {
    if (!_.isEqual(scheduleMap.current, scheduleMapFromProps.current) || !_.isEqual(stopsMap.current, stopsMapFromProps.current)) {
      if (!saveEnabled) setSaveEnabled(true);
    } else if (saveEnabled) setSaveEnabled(false);
  });

  const getNextRowColumn = (row, column, increment) => {
    let r = row;
    if (increment < 1) increment = 1;
    let c = column + increment;
    if (c <= 0) {
      r -= 3;
      c += 48;
    }
    if (c > 48) {
      r += 3;
      c -= 48;
    }
    if (r <= 0) r += 21;
    if (r > 21) r -= 21;
    return [r, c];
  };

  const getPreviousRowColumn = (row, column, decrement) => {
    let r = row;
    let c = column - decrement;
    if (c <= 0) {
      r -= 3;
      c += 48;
    }
    if (r <= 0) r += 21;
    return [r, c];
  };

  const initializeSvg = () => {
    const svg = d3.select('.main_scheduler_svg');

    const svgWidth = svgRef.current.getBoundingClientRect().width;
    const widthPerColumn = svgWidth / 48;

    svg.selectAll('*').remove();
    _.times(CONSTANTS.NO_OF_DAYS * CONSTANTS.ROWS_PER_DAY, (n) => {
      const rows = svg.selectAll(`rect .row-${(n + 1)}`)
        .data(d3.range(CONSTANTS.NO_OF_COLUMNS))
        .enter().append('rect')
        .attr('class', (d, i) => `square row-${(n + 1)} col-${(i + 1)} blank`)
        .attr('id', (d, i) => `s-${(n + 1)}${(i + 1)}`)
        .attr('width', widthPerColumn)
        .attr('height', CONSTANTS.HEIGHT_PER_ROW)
        .attr('x', (d, i) => i * widthPerColumn)
        .attr('y', n * CONSTANTS.HEIGHT_PER_ROW)
        .attr('fill', '#fff')
        // eslint-disable-next-line func-names
        .each(function (d, i) {
          RectRefHashMap.current[`${n + 1},${i + 1}`] = d3.select(this);
        });

      if (!props.restrictHighRes || n % 3 !== 0) {
        rows.on('mouseover', (d, i) => {
          if (measurementModalOpen.current || !props.editMode) return;
          d3.select('#grid-ref').text(() => DayTimeString(DayMap[Math.floor(n / CONSTANTS.ROWS_PER_DAY)], dayTimeArray[i + 1]));
          d3.selectAll('.square.blank').attr('fill', 'white');
          d3.selectAll('.highlighted-rect').remove();
          let x = null;
          let y = null;
          let blank = true;
          let blankBetween = false;
          if (d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.blank`).node() !== null || undefined) {
            x = d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.blank`).attr('x');
            y = d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.blank`).attr('y');
          } else if (d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.stopped`).node() !== null || undefined) {
            blank = false;
            x = d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.stopped`).attr('x');
            y = d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.stopped`).attr('y');
          } else if (d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.blankBetween`).node() !== null || undefined) {
            blankBetween = true;
            x = d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.blankBetween`).attr('x');
            y = d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.blankBetween`).attr('y');
          }

          if (x !== null && y !== null) {
            const rowInDay = n % CONSTANTS.ROWS_PER_DAY;
            const fill = CONSTANTS.MEASUREMENT_TYPE_COLOR[rowInDay];
            d3.select('.main_scheduler_svg').append('circle')
              .attr('class', 'highlighted-rect')
              .attr('cx', Number(x) + (widthPerColumn / 2))
              .attr('cy', Number(y) + (CONSTANTS.HEIGHT_PER_ROW / 2))
              .attr('r', 10)
              .attr('fill', fill)
              .style('opacity', 0.6)
              .on('click', blankBetween ? () => onClickBlankBetween(n + 1, i + 1) : () => openMeasurementModal(Number(x), Number(y), n + 1, i + 1, blank));
          }
        });

        rows.on('click', (d, i) => {
          if (measurementModalOpen.current || !props.editMode) return;
          let x = null;
          let y = null;
          let blank = true;
          if (d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.blank`).node() !== null || undefined) {
            x = d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.blank`).attr('x');
            y = d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.blank`).attr('y');
          } else if (d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.stopped`).node() !== null || undefined) {
            blank = false;
            x = d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.stopped`).attr('x');
            y = d3.select(`.square.row-${(n + 1)}.col-${(i + 1)}.stopped`).attr('y');
          }
          if (x !== null && y !== null) {
            openMeasurementModal(Number(x), Number(y), n + 1, i + 1, blank);
          }
        });
      } else {
        rows.attr('cursor', 'default');
      }
    });
    const xAxisLabels = svg.append('g')
      .attr('class', 'x-axis-labels');

    // adding x-axis time labels
    for (let i = 0; i < (CONSTANTS.NO_OF_COLUMNS / 2); i++) {
      xAxisLabels.append('text')
        .attr('class', 'x-axis-label')
        .attr('x', (i * (widthPerColumn * 2)) + 7)
        .attr('y', CONSTANTS.SVG_HEIGHT + 19)
        .text(DayTimings[i]);
    }

    const grid = svg.append('g')
      .attr('class', 'grid');

    for (let i = 0; i < 8; i++) {
      grid.append('g')
        .attr('class', 'tick')
        .attr('transform', `translate(0, ${i * (CONSTANTS.HEIGHT_PER_ROW * CONSTANTS.ROWS_PER_DAY)})`)
        .attr('opacity', 1)
        .append('line')
        .attr('stroke', '#e5e8e1')
        .attr('stroke-width', 1)
        .attr('shape-rendering', 'crispEdges')
        .attr('x2', svgWidth);
    }

    svg.append('line')
      .attr('x1', svgWidth - 1)
      .attr('y1', 0)
      .attr('x2', svgWidth - 1)
      .attr('y2', CONSTANTS.SVG_HEIGHT)
      .attr('stroke', '#ddd')
      .attr('stroke-width', 1)
      .attr('shape-rendering', 'crispEdges');
    disableRows();
  };

  const openMeasurementModal = (x, y, row, column, type) => {
    if (measurementModalOpen.current || !props.editMode) return;
    measurementModalOpen.current = true;
    deleteMeasurement.current = false;
    stopMeasurement.current = type;
    setLeft(x);
    setTop(y + 42);
    setSelectedInterval('');
    setSelectedHighResInterval('');
    setSelectedColumn(column);
    setSelectedRow(row);
    const rowInDay = (row - 1) % 3;
    setSelectedType(rowInDay + 1);
    const fill = CONSTANTS.MEASUREMENT_TYPE_COLOR[rowInDay];

    const svgWidth = svgRef.current.getBoundingClientRect().width;
    const widthPerColumn = svgWidth / 48;
    const group = d3.select('.main_scheduler_svg').append('g')
      .attr('class', 'unselected-interval-circle');

    // cirlce
    group.append('circle')
      .attr('cx', x + (widthPerColumn / 2))
      .attr('cy', y + (CONSTANTS.HEIGHT_PER_ROW / 2))
      .attr('r', 10)
      .attr('fill', fill);

    // question mark at center
    group.append('text')
      .attr('class', 'questionMark') // class available in SchedulerContainer
      .attr('x', x + (widthPerColumn / 2))
      .attr('y', y + (CONSTANTS.HEIGHT_PER_ROW / 2) + 4)
      .text('?');

    forceUpdate();
  };

  const closeMeasurementModal = (e) => {
    if (e) e.stopPropagation();
    measurementModalOpen.current = false;
    deleteMeasurement.current = false;
    stopMeasurement.current = false;
    disableInput.current = false;
    enableMeasurement.current = false;
    d3.select('.unselected-interval-circle').remove();
    forceUpdate();
  };

  const calculateIntervals = (interval) => {
    setSelectedInterval(interval);
    drawNewIntervalCircle(selectedRow, selectedColumn, interval);
    if (props.restrictHighRes && (selectedRow - 1) % 3 === 1 && interval > selectedHighResInterval) calculateHighResIntervals('none');
    if (!props.restrictHighRes) closeMeasurementModal();
    else {
      stopMeasurement.current = false;
      deleteMeasurement.current = true;
    }
  };

  const calculateHighResIntervals = (interval) => {
    setSelectedHighResInterval(interval);
    if (interval === 'none') {
      removeMeasurement(selectedRow - 1, selectedColumn, false);
      return;
    }
    drawNewIntervalCircle(selectedRow - 1, selectedColumn, interval);
    stopMeasurement.current = false;
    deleteMeasurement.current = true;
  };

  const getCorrectStopDayTime = (day, time, interval, row, column) => {
    if (interval >= 0.5) return [day, time];
    const [r, c] = getNextRowColumn(row, column, 1);
    const d = Math.floor((r - 1) / CONSTANTS.ROWS_PER_DAY);
    const t = dayTimeArray[c];
    const [h, m] = t.split(':');
    let newDay = '';
    let newHour = '';
    let newMin = '';
    const intervalInMinutes = interval * 60;
    if (m === '00') {
      newMin = `${60 - intervalInMinutes}`;
      if (h === '00') {
        newHour = '23';
        if (d === 0) newDay = 6;
        else newDay -= 1;
      } else {
        newHour = `${Number(h) - 1}`;
        newDay = d;
        if (newHour.length === 1) newHour = `0${newHour}`;
      }
    } else {
      newMin = `${Number(m) - intervalInMinutes}`;
      newHour = h;
      newDay = d;
    }
    return [newDay, `${newHour}:${newMin}`];
  };

  const drawNewIntervalCircle = (row, column, interval, endRow = row, endColumn = column) => {
    const rowInDay = (row - 1) % 3;
    d3.select(`.selected-interval-circle.row-${row}.col-${column}`).remove();

    const selection = RectRefHashMap.current[`${row},${column}`];
    const stopped = selection.classed('stopped');
    const stoppedStart = selection.classed('stoppedStart');
    let stoppedStartRow = null;
    let stoppedStartColumn = null;
    if (stoppedStart) {
      removeStopSign(row, column, false, !props.restrictHighRes);
    } else if (stopped) {
      stoppedStartRow = Number(selection.attr('startRow'));
      stoppedStartColumn = Number(selection.attr('startColumn'));
      removeStopSign(stoppedStartRow, stoppedStartColumn, false, !props.restrictHighRes);
    }

    const startRow = selection.attr('startRow');
    const startColumn = selection.attr('startColumn');
    if (startRow && startColumn) {
      selection
        .classed(`startRow-${startRow}`, false)
        .classed(`startColumn-${startColumn}`, false);
    }

    const group = d3.select('.main_scheduler_svg').append('g')
      .attr('class', `selected-interval-circle row-${row} col-${column}`)
      .attr('interval', interval);

    const x = selection.node() && selection.attr('x');
    const y = selection.node() && selection.attr('y');

    if (!x || !y) {
      return;
    }

    selection
      .classed('selected', true)
      .classed('blank', false)
      .classed('stopped', false)
      .classed('repeated', false)
      .classed('blankBetween', false)
      .classed(`startRow-${row}`, true)
      .classed(`startColumn-${column}`, true)
      .attr('startRow', row)
      .attr('startColumn', column)
      .attr('interval', interval);

    const svgWidth = svgRef.current.getBoundingClientRect().width;
    const widthPerColumn = svgWidth / 48;
    const fill = CONSTANTS.MEASUREMENT_TYPE_COLOR[rowInDay];

    // green circle
    group.append('circle')
      .attr('cx', Number(x) + (widthPerColumn / 2))
      .attr('cy', Number(y) + (CONSTANTS.HEIGHT_PER_ROW / 2))
      .attr('r', 10)
      .attr('fill', fill)
      .attr('cursor', props.restrictHighRes && rowInDay === 0 ? 'default' : 'pointer')
      .on('click', props.restrictHighRes && rowInDay === 0 ? () => {} : () => onClickSelectedInterval(row, column, interval));

    group.append('text')
      .attr('class', `measurement-interval-text-label ${interval < 0.5 ? 'sm' : ''}`) // class available in SchedulerContainer
      .attr('x', Number(x) + (widthPerColumn / 2))
      .attr('y', Number(y) + (CONSTANTS.HEIGHT_PER_ROW / 2) + 4)
      .text(round(interval, 2))
      .attr('cursor', props.restrictHighRes && rowInDay === 0 ? 'default' : 'pointer')
      .on('click', props.restrictHighRes && rowInDay === 0 ? () => {} : () => onClickSelectedInterval(row, column, interval));

    d3.select(`.square.row-${row}.col-${column}`)
      .attr('cursor', props.restrictHighRes && rowInDay === 0 ? 'default' : 'pointer')
      .on('click', props.restrictHighRes && rowInDay === 0 ? () => {} : () => onClickSelectedInterval(row, column, interval));

    if (stopped) addStopSign(stoppedStartRow, stoppedStartColumn, row, column, !props.restrictHighRes);
    drawRepeatedIntervals(row, column, interval, endRow, endColumn);
  };


  const drawRepeatedIntervals = (row, column, interval, endRow = row, endColumn = column) => {
    let [r, c] = getNextRowColumn(row, column, interval * 2);
    let prevRow = row;
    let prevColumn = column;

    const rowInDay = (row - 1) % 3;

    const fill = CONSTANTS.MEASUREMENT_TYPE_COLOR[rowInDay];

    d3.select(`.green-markers.row-${row}.col-${column}`).remove();
    // removing old interval in that location, if any at all
    d3.selectAll(`.square.repeated.startRow-${row}.startColumn-${column}`)
      .classed('repeated', false)
      .classed('stopped', false)
      .classed('blank', true)
      .classed('blankBetween', false)
      .classed('selected', false)
      .classed(`startRow-${row}`, false)
      .classed(`startColumn-${column}`, false)
      .attr('startRow', undefined)
      .attr('startColumn', undefined)
      .attr('interval', undefined);

    // removing old blank betweens in that location, if any at all
    d3.selectAll(`.square.blankBetween.startRow-${row}.startColumn-${column}`)
      .classed('repeated', false)
      .classed('stopped', false)
      .classed('blank', true)
      .classed('blankBetween', false)
      .classed('selected', false)
      .classed(`startRow-${row}`, false)
      .classed(`startColumn-${column}`, false)
      .attr('startRow', undefined)
      .attr('startColumn', undefined)
      .attr('interval', undefined);

    const group = d3.select('.main_scheduler_svg').append('g')
      .attr('class', `green-markers row-${row} col-${column}`);

    const svgWidth = svgRef.current.getBoundingClientRect().width;
    const widthPerColumn = svgWidth / 48;
    while (r !== endRow || c !== endColumn) {
      let [tempRow, tempColumn] = getNextRowColumn(prevRow, prevColumn, 1);

      while (tempRow !== r || tempColumn !== c) {
        const selection = RectRefHashMap.current[`${tempRow},${tempColumn}`];
        const repeated = selection.classed('repeated');
        const selected = selection.classed('selected');
        const stopped = selection.classed('stopped');
        const stoppedStart = selection.classed('stoppedStart');
        const blank = selection.classed('blank');
        if (repeated) {
          // write logic to remove repeated interval
          const startRow = selection.attr('startRow');
          const startColumn = selection.attr('startColumn');
          const interval = selection.attr('interval');
          drawNewIntervalCircle(Number(startRow), Number(startColumn), Number(interval));
        }
        if (selected || stopped || stoppedStart) {
          // stop
          const day = Math.floor((row - 1) / CONSTANTS.ROWS_PER_DAY);
          const time = dayTimeArray[column];
          const sd = Math.floor((prevRow - 1) / CONSTANTS.ROWS_PER_DAY);
          const st = dayTimeArray[prevColumn];

          const index = scheduleMap.current[2 - rowInDay].findIndex(s => s.start === `${day},${time}`);
          const [stopDay, stopTime] = getCorrectStopDayTime(sd, st, interval, prevRow, prevColumn);
          if (index === -1) scheduleMap.current[2 - rowInDay].push({
            start: `${day},${time}`,
            end: `${stopDay},${stopTime}`,
            interval
          });
          else {
            scheduleMap.current[2 - rowInDay][index].end = `${stopDay},${stopTime}`;
            scheduleMap.current[2 - rowInDay][index].interval = interval;
          }
          return;
        }
        if (blank) {
          selection
            .classed('blank', false)
            .classed('repeated', false)
            .classed('stopped', false)
            .classed('selected', false)
            .classed('blankBetween', true)
            .classed(`startRow-${row}`, true)
            .classed(`startColumn-${column}`, true)
            .attr('startRow', row)
            .attr('startColumn', column)
            .attr('interval', interval)
            .attr('cursor', props.restrictHighRes && rowInDay === 0 ? 'default' : 'pointer')
            // eslint-disable-next-line
            .on('click', props.restrictHighRes && rowInDay === 0 ? () => {} : (() => onClickBlankBetween(tempRow, tempColumn)));
        }
        [tempRow, tempColumn] = getNextRowColumn(tempRow, tempColumn, 1);
      }

      const selection = RectRefHashMap.current[`${tempRow},${tempColumn}`];
      const repeated = selection.classed('repeated');
      const selected = selection.classed('selected');
      const stopped = selection.classed('stopped');
      const stoppedStart = selection.classed('stoppedStart');
      let stopHighRes = false;
      if (props.restrictHighRes && rowInDay === 0) {
        const fbPoint = RectRefHashMap.current[`${tempRow + 1},${tempColumn}`];
        if (!fbPoint.classed('repeated') || Number(fbPoint.attr('startRow')) !== row + 1 || Number(fbPoint.attr('startColumn')) !== column) stopHighRes = true;
      }
      if (repeated) {
        // write logic to remove repeated interval
        const startRow = selection.attr('startRow');
        const startColumn = selection.attr('startColumn');
        const interval = selection.attr('interval');
        drawNewIntervalCircle(Number(startRow), Number(startColumn), Number(interval));
      }
      if (selected || stopped || stoppedStart || stopHighRes) {
        // stop
        const day = Math.floor((row - 1) / CONSTANTS.ROWS_PER_DAY);
        const time = dayTimeArray[column];
        const sd = Math.floor((prevRow - 1) / CONSTANTS.ROWS_PER_DAY);
        const st = dayTimeArray[prevColumn];
        const index = scheduleMap.current[2 - rowInDay].findIndex(s => s.start === `${day},${time}`);
        const [stopDay, stopTime] = getCorrectStopDayTime(sd, st, interval, prevRow, prevColumn);
        if (index === -1) scheduleMap.current[2 - rowInDay].push({
          start: `${day},${time}`,
          end: `${stopDay},${stopTime}`,
          interval
        });
        else {
          scheduleMap.current[2 - rowInDay][index].end = `${stopDay},${stopTime}`;
          scheduleMap.current[2 - rowInDay][index].interval = interval;
        }
        return;
      }

      const x = selection.attr('x');
      const y = selection.attr('y');
      if (interval >= 0.5) {
        group.append('circle')
          .attr('class', `green-marker-circle row-${r} col-${c}`)
          .attr('cx', Number(x) + (widthPerColumn / 2))
          .attr('cy', Number(y) + (CONSTANTS.HEIGHT_PER_ROW / 2))
          .attr('fill', fill)
          .attr('r', 3)
          .attr('cursor', props.restrictHighRes && rowInDay === 0 ? 'default' : 'pointer')
          // eslint-disable-next-line
          .on('click', props.restrictHighRes && rowInDay === 0 ? () => {} : ((r, c) => () => onClickRepeatedInterval(r, c))(r, c));
      } else {
        group.append('line')
          .attr('class', `green-marker-circle row-${r} col-${c}`)
          .attr('x1', Number(x))
          .attr('y1', Number(y) + (CONSTANTS.HEIGHT_PER_ROW / 2))
          .attr('x2', Number(x) + widthPerColumn)
          .attr('y2', Number(y) + (CONSTANTS.HEIGHT_PER_ROW / 2))
          .attr('stroke', fill)
          .attr('stroke-width', 4)
          .attr('cursor', props.restrictHighRes && rowInDay === 0 ? 'default' : 'pointer')
          // eslint-disable-next-line
          .on('click', props.restrictHighRes && rowInDay === 0 ? () => {} : ((r, c) => () => onClickRepeatedInterval(r, c))(r, c));
      }

      selection
        .classed('blank', false)
        .classed('repeated', true)
        .classed('stopped', false)
        .classed('selected', false)
        .classed(`startRow-${row}`, true)
        .classed(`startColumn-${column}`, true)
        .attr('startRow', row)
        .attr('startColumn', column)
        .attr('interval', interval)
        .attr('cursor', props.restrictHighRes && rowInDay === 0 ? 'default' : 'pointer')
        // eslint-disable-next-line
        .on('click', props.restrictHighRes && rowInDay === 0 ? () => {} : ((r, c) => () => onClickRepeatedInterval(r, c))(r, c));

      prevRow = r;
      prevColumn = c;

      [r, c] = getNextRowColumn(r, c, interval * 2);
    }

    let [tempRow, tempColumn] = getNextRowColumn(prevRow, prevColumn, 1);
    while (tempRow !== r || tempColumn !== c) {
      const selection = RectRefHashMap.current[`${tempRow},${tempColumn}`];
      const blank = selection.classed('blank');
      if (blank) {
        selection
          .classed('blank', false)
          .classed('repeated', false)
          .classed('stopped', false)
          .classed('selected', false)
          .classed('blankBetween', true)
          .classed(`startRow-${row}`, true)
          .classed(`startColumn-${column}`, true)
          .attr('startRow', row)
          .attr('startColumn', column)
          .attr('interval', interval)
          // eslint-disable-next-line
          .on('click', () => onClickBlankBetween(tempRow, tempColumn));
      }
      [tempRow, tempColumn] = getNextRowColumn(tempRow, tempColumn, 1);
    }

    if (row === endRow && column === endColumn) {
      const selection = RectRefHashMap.current[`${row},${column}`];
      // const repeated = selection.classed('repeated');
      const selected = selection.classed('selected');
      const stopped = selection.classed('stopped');
      const stoppedStart = selection.classed('stoppedStart');

      if (selected || stopped || stoppedStart) {
        // stop
        const day = Math.floor((row - 1) / CONSTANTS.ROWS_PER_DAY);
        const time = dayTimeArray[column];
        const sd = Math.floor((prevRow - 1) / CONSTANTS.ROWS_PER_DAY);
        const st = dayTimeArray[prevColumn];
        const index = scheduleMap.current[2 - rowInDay].findIndex(s => s.start === `${day},${time}`);
        const [stopDay, stopTime] = getCorrectStopDayTime(sd, st, interval, prevRow, prevColumn);
        if (index === -1) {
          scheduleMap.current[2 - rowInDay].push({
            start: `${day},${time}`,
            end: `${stopDay},${stopTime}`,
            interval
          });
        } else {
          scheduleMap.current[2 - rowInDay][index].end = `${stopDay},${stopTime}`;
          scheduleMap.current[2 - rowInDay][index].interval = interval;
        }
      }
    }
  };

  const onClickRepeatedInterval = (row, column) => {
    if (measurementModalOpen.current || !props.editMode) return;
    const selection = RectRefHashMap.current[`${row},${column}`];
    const x = selection.node() && selection.attr('x');
    const y = selection.node() && selection.attr('y');
    const interval = selection.node() && selection.attr('interval');

    if (!x || !y) {
      return;
    }
    const rowInDay = (row - 1) % 3;
    setSelectedType(rowInDay + 1);
    measurementModalOpen.current = true;
    deleteMeasurement.current = false;
    stopMeasurement.current = true;
    disableInput.current = true;
    setLeft(Number(x));
    setTop(Number(y) + 42);
    setSelectedInterval('');
    setSelectedColumn(column);
    setSelectedRow(row);
    setSelectedInterval(Number(interval));

    if (props.restrictHighRes && rowInDay === 1) {
      const highResSelection = RectRefHashMap.current[`${row - 1},${column}`];
      const highResInterval = highResSelection.node() && highResSelection.attr('interval');
      setSelectedHighResInterval(Number(highResInterval));
    }

    forceUpdate();
  };

  const onClickBlankBetween = (row, column) => {
    if (measurementModalOpen.current || !props.editMode) return;
    const selection = RectRefHashMap.current[`${row},${column}`];
    const x = selection.node() && selection.attr('x');
    const y = selection.node() && selection.attr('y');
    const interval = selection.node() && selection.attr('interval');

    if (!x || !y) {
      return;
    }
    const rowInDay = (row - 1) % 3;
    setSelectedType(rowInDay + 1);
    measurementModalOpen.current = true;
    deleteMeasurement.current = false;
    stopMeasurement.current = true;
    disableInput.current = true;
    setLeft(Number(x));
    setTop(Number(y) + 42);
    setSelectedInterval('');
    setSelectedColumn(column);
    setSelectedRow(row);
    setSelectedInterval(Number(interval));

    if (props.restrictHighRes && rowInDay === 1) {
      const highResSelection = RectRefHashMap.current[`${row - 1},${column}`];
      const highResInterval = highResSelection.node() && highResSelection.attr('interval');
      setSelectedHighResInterval(Number(highResInterval));
    }

    forceUpdate();
  };

  const onClickSelectedInterval = (row, column, interval) => {
    if (measurementModalOpen.current || !props.editMode) return;
    const selection = RectRefHashMap.current[`${row},${column}`];
    const x = selection.node() && selection.attr('x');
    const y = selection.node() && selection.attr('y');

    if (!x || !y) {
      return;
    }
    const rowInDay = (row - 1) % 3;
    setSelectedType(rowInDay + 1);
    measurementModalOpen.current = true;
    deleteMeasurement.current = true;
    stopMeasurement.current = false;
    disableInput.current = false;
    setLeft(Number(x));
    setTop(Number(y) + 42);
    setSelectedInterval('');
    setSelectedColumn(column);
    setSelectedRow(row);
    setSelectedInterval(Number(interval));

    if (props.restrictHighRes && rowInDay === 1) {
      const highResSelection = RectRefHashMap.current[`${row - 1},${column}`];
      const highResInterval = highResSelection.node() && highResSelection.attr('interval');
      setSelectedHighResInterval(Number(highResInterval));
    }

    forceUpdate();
  };

  const removeMeasurement = (row, column, closeModal = true) => {
    // When clicked on the delete icon, remove the interval and all the respective repeated intervals
    const rowInDay = (row - 1) % 3;
    d3.select(`.selected-interval-circle.row-${row}.col-${column}`).remove();

    const startSquare = d3.select(`.square.row-${row}.col-${column}`);
    if (!startSquare.classed('selected')) return;

    // make selected square normal again
    startSquare.classed('repeated', false)
      .classed('stopped', false)
      .classed('blank', true)
      .classed('selected', false)
      .classed('blankBetween', false)
      .classed(`startRow-${row}`, false)
      .classed(`startColumn-${column}`, false)
      .attr('startRow', undefined)
      .attr('startColumn', undefined)
      .attr('interval', undefined)
      .on('click', null);

    d3.select(`.green-markers.row-${row}.col-${column}`).remove();
    // make all repeated squares blank (back to initial normal state)
    d3.selectAll(`.square.repeated.startRow-${row}.startColumn-${column}`)
      .classed('repeated', false)
      .classed('stopped', false)
      .classed('blank', true)
      .classed('blankBetween', false)
      .classed('selected', false)
      .classed(`startRow-${row}`, false)
      .classed(`startColumn-${column}`, false)
      .attr('startRow', undefined)
      .attr('startColumn', undefined)
      .attr('interval', undefined)
      .on('click', null);

    d3.selectAll(`.square.blankBetween.startRow-${row}.startColumn-${column}`)
      .classed('repeated', false)
      .classed('stopped', false)
      .classed('blank', true)
      .classed('blankBetween', false)
      .classed('selected', false)
      .classed(`startRow-${row}`, false)
      .classed(`startColumn-${column}`, false)
      .attr('startRow', undefined)
      .attr('startColumn', undefined)
      .attr('interval', undefined)
      .on('click', null);

    const day = Math.floor((row - 1) / CONSTANTS.ROWS_PER_DAY);
    const time = dayTimeArray[column];
    const index = scheduleMap.current[2 - rowInDay].findIndex(s => s.start === `${day},${time}`);
    scheduleMap.current[2 - rowInDay].splice(index, 1);
    if (closeModal) closeMeasurementModal();
    if (props.restrictHighRes && rowInDay === 1) removeMeasurement(row - 1, column);

    let [r, c] = getNextRowColumn(row, column, -1);
    while (r !== row || c !== column) {
      const selection = RectRefHashMap.current[`${r},${c}`];
      const repeated = selection.classed('repeated');
      const selected = selection.classed('selected');
      const stopped = selection.classed('stopped');
      const stoppedStart = selection.classed('stoppedStart');
      if (stopped || stoppedStart) {
        const startRow = Number(selection.attr('startRow'));
        const startColumn = Number(selection.attr('startColumn'));
        removeStopSign(startRow, startColumn, false);
        return;
      }
      if (selected || repeated) {
        const startRow = selection.attr('startRow');
        const startColumn = selection.attr('startColumn');
        const interval = selection.attr('interval');
        drawNewIntervalCircle(Number(startRow), Number(startColumn), Number(interval));
        return;
      }
      [r, c] = getNextRowColumn(r, c, -1);
    }
  };

  const addStopSign = (row, column, endRow = row, endColumn = column, closeModal = true) => {
    const rowInDay = (row - 1) % 3;
    const svgWidth = svgRef.current.getBoundingClientRect().width;
    const widthPerColumn = svgWidth / 48;
    let [r, c] = getNextRowColumn(row, column, 1);

    const selection = RectRefHashMap.current[`${row},${column}`];
    const x = selection.node() && selection.attr('x');
    const y = selection.node() && selection.attr('y');
    const repeated = selection.classed('repeated');
    let repeatedStartRow = null;
    let repeatedStartColumn = null;
    let repeatedInterval = null;
    if (repeated) {
      repeatedStartRow = selection.attr('startRow');
      repeatedStartColumn = selection.attr('startColumn');
      repeatedInterval = selection.attr('interval');
    }
    const selected = selection.classed('selected');
    const blank = selection.classed('blank') || selection.classed('blankBetween');

    selection
      .classed('repeated', false)
      .classed('stopped', false)
      .classed('stoppedStart', true)
      .classed('blank', false)
      .classed('blankBetween', false)
      .classed('selected', false)
      .classed(`startRow-${row}`, true)
      .classed(`startColumn-${column}`, true)
      .attr('startRow', row)
      .attr('startColumn', column)
      .attr('fill', '#F7F8F6')
      .attr('cursor', props.restrictHighRes && rowInDay === 0 ? 'default' : 'pointer')
      .on('click', props.restrictHighRes && rowInDay === 0 ? () => {} : () => onClickStop(row, column, true));


    const fill = CONSTANTS.MEASUREMENT_TYPE_COLOR[rowInDay];

    const group = d3.select('.main_scheduler_svg').append('g')
      .attr('class', `stop-measurement-group row-${row} col-${column}`);

    group.append('circle')
      .attr('class', `stop-measurement-circle row-${row} col-${column}`)
      .attr('cx', Number(x) + (widthPerColumn / 2))
      .attr('cy', Number(y) + (CONSTANTS.HEIGHT_PER_ROW / 2))
      .attr('r', 10)
      .attr('fill', fill)
      .attr('cursor', props.restrictHighRes && rowInDay === 0 ? 'default' : 'pointer')
      .on('click', props.restrictHighRes && rowInDay === 0 ? () => {} : () => onClickStop(row, column, true));

    group.append('line')
      .attr('x1', Number(x) + (widthPerColumn / 2) - 5)
      .attr('y1', Number(y) + (CONSTANTS.HEIGHT_PER_ROW / 2))
      .attr('x2', Number(x) + (widthPerColumn / 2) + 5)
      .attr('y2', Number(y) + (CONSTANTS.HEIGHT_PER_ROW / 2))
      .attr('stroke', '#FFF')
      .attr('stroke-width', '2px')
      .attr('cursor', props.restrictHighRes && rowInDay === 0 ? 'default' : 'pointer')
      .on('click', props.restrictHighRes && rowInDay === 0 ? () => {} : () => onClickStop(row, column, true));

    if (selected) {
      if (closeModal) closeMeasurementModal();
      return;
    }
    if (repeated) {
      drawNewIntervalCircle(Number(repeatedStartRow), Number(repeatedStartColumn), Number(repeatedInterval));
    }

    const day = Math.floor((row - 1) / CONSTANTS.ROWS_PER_DAY);
    const time = dayTimeArray[column];
    stopsMap.current[2 - rowInDay].push(`${day},${time}`);

    if (props.restrictHighRes && rowInDay === 1) {
      let startRow = null;
      let startColumn = null;
      if (blank) {
        let [currRow, currCol] = getPreviousRowColumn(row, column, 1);
        while (!_.isEqual([currRow, currCol], [row, column])) {
          const currentPoint = RectRefHashMap.current[`${currRow},${currCol}`];
          if (currentPoint.classed('blank') || currentPoint.classed('blankBetween')) {
            [currRow, currCol] = getPreviousRowColumn(currRow, currCol, 1);
          } else if (currentPoint.classed('repeated') || currentPoint.classed('selected')) {
            startRow = currentPoint.attr('startRow');
            startColumn = currentPoint.attr('startColumn');
            break;
          } else break;
        }
      } else if (repeated) {
        startRow = repeatedStartRow;
        startColumn = repeatedStartColumn;
      }
      if (startRow && startColumn) {
        const highResStartPoint = RectRefHashMap.current[`${startRow - 1},${startColumn}`];
        if (highResStartPoint.classed('selected')) addStopSign(row - 1, column, row - 1, column, closeModal);
      }
    }

    while (r !== endRow || c !== endColumn) {
      const selection = RectRefHashMap.current[`${r},${c}`];
      const repeated = selection.classed('repeated');
      const selected = selection.classed('selected');
      const stopped = selection.classed('stopped');
      const stoppedStart = selection.classed('stoppedStart');
      const blankBetween = selection.classed('blankBetween');
      if (selected) {
        if (closeModal) closeMeasurementModal();
        return;
      }
      if (repeated || blankBetween) {
        const startRow = selection.attr('startRow');
        const startColumn = selection.attr('startColumn');
        const interval = selection.attr('interval');
        drawNewIntervalCircle(Number(startRow), Number(startColumn), Number(interval));
      } else if (stopped || stoppedStart) {
        removeStopSign(r, c, false);
      }

      selection
        .classed('repeated', false)
        .classed('stopped', true)
        .classed('blank', false)
        .classed('blankBetween', false)
        .classed('selected', false)
        .classed(`startRow-${row}`, true)
        .classed(`startColumn-${column}`, true)
        .attr('startRow', row)
        .attr('startColumn', column)
        .attr('fill', '#F7F8F6')
        .attr('cursor', props.restrictHighRes && rowInDay === 0 ? 'default' : 'pointer')
        .on('click', props.restrictHighRes && rowInDay === 0 ? () => {} : ((r, c) => () => onClickStop(r, c))(r, c));

      [r, c] = getNextRowColumn(r, c, 1);
    }
    if (closeModal) closeMeasurementModal();
  };

  const removeStopSign = (row, column, redrawPerviousInterval = true, closeModal = true) => {
    const rowInDay = (row - 1) % 3;
    const day = Math.floor((row - 1) / CONSTANTS.ROWS_PER_DAY);
    const time = dayTimeArray[column];
    stopsMap.current[2 - rowInDay] = stopsMap.current[2 - rowInDay].filter(t => t !== `${day},${time}`);
    // remove the group which has stop sign circle
    d3.select(`.stop-measurement-group.row-${row}.col-${column}`).remove();
    // make all the respective rows back to normal
    d3.select(`.square.stoppedStart.startRow-${row}.startColumn-${column}`)
      .classed('repeated', false)
      .classed('stopped', false)
      .classed('blank', true)
      .classed('blankBetween', false)
      .classed('selected', false)
      .classed('stoppedStart', false)
      .classed(`startRow-${row}`, false)
      .classed(`startColumn-${column}`, false)
      .attr('startRow', undefined)
      .attr('startColumn', undefined)
      .attr('fill', 'white')
      .on('click', null);
    d3.selectAll(`.square.stopped.startRow-${row}.startColumn-${column}`)
      .classed('repeated', false)
      .classed('stopped', false)
      .classed('blank', true)
      .classed('blankBetween', false)
      .classed('selected', false)
      .classed('stoppedStart', false)
      .classed(`startRow-${row}`, false)
      .classed(`startColumn-${column}`, false)
      .attr('startRow', undefined)
      .attr('startColumn', undefined)
      .attr('fill', 'white')
      .on('click', null);
    if (redrawPerviousInterval) {
      let [r, c] = getNextRowColumn(row, column, -1);

      while (r !== row || c !== column) {
        const selection = RectRefHashMap.current[`${r},${c}`];
        const repeated = selection.classed('repeated');
        const selected = selection.classed('selected');
        const stopped = selection.classed('stopped');
        const stoppedStart = selection.classed('stoppedStart');
        if (stopped || stoppedStart) return;
        if (selected || repeated) {
          const startRow = selection.attr('startRow');
          const startColumn = selection.attr('startColumn');
          const interval = selection.attr('interval');
          drawNewIntervalCircle(Number(startRow), Number(startColumn), Number(interval));
          if (closeModal) closeMeasurementModal();
          if (props.restrictHighRes && rowInDay === 1) {
            const highResStopPoint = RectRefHashMap.current[`${row - 1},${column}`];
            if (highResStopPoint.classed('stoppedStart')) removeStopSign(row - 1, column, true, closeModal);
          }
          return;
        }
        [r, c] = getNextRowColumn(r, c, -1);
      }
    }
    if (closeModal) closeMeasurementModal();
    if (props.restrictHighRes && rowInDay === 1) {
      const highResStopPoint = RectRefHashMap.current[`${row - 1},${column}`];
      if (highResStopPoint.classed('stoppedStart')) removeStopSign(row - 1, column, true, closeModal);
    }
  };

  const onClickStop = (row, column, enable = false) => {
    if (measurementModalOpen.current || !props.editMode) return;
    const selection = RectRefHashMap.current[`${row},${column}`];
    const x = selection.node() && selection.attr('x');
    const y = selection.node() && selection.attr('y');
    if (!x || !y) {
      return;
    }
    const rowInDay = (row - 1) % 3;
    setSelectedType(rowInDay + 1);
    setLeft(Number(x));
    setTop(Number(y) + 42);
    setSelectedInterval('');
    setSelectedColumn(column);
    setSelectedRow(row);
    measurementModalOpen.current = true;
    enableMeasurement.current = enable;
    deleteMeasurement.current = false;
    stopMeasurement.current = false;
    disableInput.current = false;
    forceUpdate();
  };

  const onClickClear = () => {
    // clear the whole svg
    scheduleMap.current = {
      0: [],
      1: [],
      2: []
    };
    stopsMap.current = {
      0: [],
      1: [],
      2: []
    };
    initializeSvg();
    drawGraph();
    forceUpdate();
  };

  const disableRows = () => {
    Object.entries(props.disabledRows).forEach(([key, value]) => {
      if (!value) return;
      const row = 2 - Number(key);
      let currentRow = row;
      for (let i = 0; i < 7; i++) {
        d3.selectAll(`.square.blank.row-${currentRow + 1}`)
          .classed('blank', false)
          .classed('disabled', true)
          .attr('fill', '#F7F8F6');
        currentRow += 3;
      }
    });
  };

  const drawGraph = () => {
    Object.entries(scheduleMap.current).forEach(([row, value]) => {
      value.forEach((schedule) => {
        const [startRow, startColumn] = getRowColumnFromDayTime(2 - Number(row), schedule.start);
        const [tempRow, tempColumn] = getRowColumnFromDayTime(2 - Number(row), schedule.end);
        const [endRow, endColumn] = getNextRowColumn(tempRow, tempColumn, schedule.interval);
        drawNewIntervalCircle(startRow, startColumn, schedule.interval, endRow, endColumn);
      });
    });
    Object.entries(stopsMap.current).forEach(([row, value]) => {
      value.forEach((stop) => {
        const [startRow, startColumn] = getRowColumnFromDayTime(2 - Number(row), stop);
        addStopSign(startRow, startColumn);
      });
    });
  };

  const getRowColumnFromDayTime = (rowInDay, dayTimeString) => {
    const [day, time] = dayTimeString.split(',');
    if (!day || !time) return [undefined, undefined];
    const row = (1 + rowInDay) + (Number(day) * 3);
    let column = dayTimeArray.findIndex(t => t === time);
    if (column === -1) {
      // eslint-disable-next-line prefer-const
      let [h, m] = time.split(':');
      if (Number(m) > 30) {
        m = '30';
      } else {
        m = '00';
      }
      column = dayTimeArray.findIndex(t => t === `${h}:${m}`);
    }
    return [row, column];
  };

  const isScheduleAggressive = (schedule) => {
    if (!schedule) return false;

    for (let i = 1; i < Object.keys(schedule).length; i++) {
      if (_.some(schedule[i], s => s.interval <= 1)) return true;
    }

    return false;
  };

  const onClickSave = () => {
    let schedule = _.cloneDeep(scheduleMap.current);
    if (isScheduleAggressive(schedule)) {
      if (props.maxMeasurementCount) {
        schedule = convertScheduleFormat(schedule);
        const maxInterval = calculateMaxReportingInterval([schedule[1], schedule[2]], props.maxMeasurementCount);
        setMaxReportingInterval(maxInterval);
      }
      setAggressiveModal(true);
    } else if (props.reportingIntervalComponent) {
      setReportingModal(true);
    } else {
      onSave();
    }
  };

  const onClickClose = () => {
    setAggressiveModal(false);
    setReportingModal(false);
  };

  const onSave = () => {
    // call API to save the data
    let schedule = _.cloneDeep(scheduleMap.current);
    const stops = _.cloneDeep(stopsMap.current);
    if (props.type === 'Vibration Mote') {
      let high_res_empty = false;
      let full_bandwidth_empty = false;
      Object.entries(schedule).forEach(([key, value]) => {
        if (Number(key) === 1) full_bandwidth_empty = value.length === 0;
        if (Number(key) === 2) high_res_empty = value.length === 0;
      });
      if (high_res_empty && full_bandwidth_empty) {
        toastr.error('Both Full Bandwidth and High Resolution cannot be empty for Vibration Mote');
        return;
      }
    }
    schedule = convertScheduleFormat(schedule);
    Object.entries(stops).forEach(([key, value]) => {
      value.sort((a, b) => {
        const [aDay, aTime] = a.split(',');
        const row1 = Number(aDay);
        const col1 = dayTimeArray.findIndex(t => t === aTime);

        const [bDay, bTime] = b.split(',');
        const row2 = Number(bDay);
        const col2 = dayTimeArray.findIndex(t => t === bTime);
        if (row1 < row2 || (row1 === row2 && col1 < col2)) {
          return -1;
        }
        if (row1 > row2 || (row1 === row2 && col1 > col2)) {
          return 1;
        }
        return 0;
      });
      stops[key] = value.map(s => s.split(','));
    });
    props.saveSchedule(schedule, stops, maxReportingInterval);
  };

  const footer = () => (
    <FlexContainer justifyContent="flex-end">
      <Button disabled={!props.editMode} secondary="#3C3D3A" marginright="10px" onClick={onClickClear}>Clear</Button>
      <Button disabled={!saveEnabled || !props.editMode} onClick={onClickSave}>Save</Button>
    </FlexContainer>
  );

  const getHighResIntervalOptions = () => {
    let highResOptions = [{ text: 'None', value: 'none' }];
    const allOptions = _.isArray(MeasurementIntervals.current) ? MeasurementIntervals.current : MeasurementIntervals.current[2];
    highResOptions = highResOptions.concat(allOptions.filter(option => option.value >= selectedInterval));
    return highResOptions;
  };

  return (
    <Modal
      title="Set full spectrum measurement schedule"
      close={props.close}
      width="100%"
      footer={footer}
    >
      {aggressiveModal && (
        <AlertPrompt
          message="Device will go into aggressive measurement from next reporting time"
          secondaryMessage="Aggressive monitoring impacts device battery life"
          onCancel={onClickClose}
          onProceed={onSave}
          component={props.component(maxReportingInterval)}
        />
      )}
      {reportingModal && (
        <AlertPrompt
          message="Select reporting interval"
          onCancel={onClickClose}
          onProceed={onSave}
          component={props.reportingIntervalComponent}
          onProceedDisabled={props.onProceedDisabledReporting}
        />
      )}
      <Container>
        <header style={{ marginBottom: '5px' }}>
          <LabelContainer>
            {!props.disabledRows[2] && (
              <>
                <Circle background={CONSTANTS.MEASUREMENT_TYPE_COLOR[0]} />
                <Label>High resolution</Label>
              </>
            )}
            {!props.disabledRows[1] && (
              <>
                <Circle background={CONSTANTS.MEASUREMENT_TYPE_COLOR[1]} />
                <Label>Full bandwidth</Label>
              </>
            )}
            {!props.disabledRows[0] && (
              <>
                <Circle background={CONSTANTS.MEASUREMENT_TYPE_COLOR[2]} />
                <Label>Overall</Label>
              </>
            )}
          </LabelContainer>
          <H4 id="grid-ref">{props.editMode ? '--' : ''}</H4>
        </header>
        <SchedulerContainer editMode={props.editMode} innerRef={svgContainerRef}>
          {measurementModalOpen.current && (
            <OutsideAlerter open handleClick={e => closeMeasurementModal(e)}>
              <AbsoluteDiv
                top={top}
                left={left}
              >
                <FlexContainer justifyContent="space-between">
                  <Label>Set measurement interval</Label>
                  <FlexContainer>
                    {deleteMeasurement.current && (
                      <SvgContainer marginright="10px" onClick={() => removeMeasurement(selectedRow, selectedColumn, 'delete')}>
                        <DeleteSvg />
                      </SvgContainer>)
                    }
                    <SvgContainer onClick={closeMeasurementModal}>
                      <CrossSvg width={22} height={22} fill="#888" />
                    </SvgContainer>
                  </FlexContainer>
                </FlexContainer>
                <InputField
                  type="select"
                  placeholder="Select..."
                  label={props.restrictHighRes && selectedType === 2 ? 'Full Bandwidth Interval' : 'Interval'}
                  options={_.isArray(MeasurementIntervals.current) ? MeasurementIntervals.current : MeasurementIntervals.current[3 - selectedType]}
                  onChange={(e, { value }) => calculateIntervals(value)}
                  selectOnBlur={false}
                  marginbottom="10px"
                  value={selectedInterval}
                  disabled={disableInput.current}
                />
                {props.restrictHighRes && selectedType === 2 && (
                  <InputField
                    type="select"
                    placeholder="None"
                    label="High Res Interval"
                    options={getHighResIntervalOptions()}
                    onChange={(e, { value }) => calculateHighResIntervals(value)}
                    selectOnBlur={false}
                    marginbottom="10px"
                    value={selectedHighResInterval}
                    disabled={disableInput.current || !selectedInterval}
                  />
                )}
                {stopMeasurement.current && (
                  <Button
                    fontsize="12px"
                    block
                    text
                    onClick={() => addStopSign(selectedRow, selectedColumn)}
                  >
                      Stop Measurement
                  </Button>)
                }
                {enableMeasurement.current && (
                  <Button
                    fontsize="12px"
                    block
                    text
                    onClick={() => removeStopSign(selectedRow, selectedColumn)}
                  >
                      Enable Measurement
                  </Button>)
                }
              </AbsoluteDiv>
            </OutsideAlerter>
          )
            }
          <DayFlexContainer>
            <DayContainer>
              Sunday
            </DayContainer>
            <DayContainer>
              Monday
            </DayContainer>
            <DayContainer>
              Tuesday
            </DayContainer>
            <DayContainer>
              Wednesday
            </DayContainer>
            <DayContainer>
              Thursday
            </DayContainer>
            <DayContainer>
              Friday
            </DayContainer>
            <DayContainer marginBottom="5px">
              Saturday
            </DayContainer>
            Site local time (GMT{props.utcOffset < 0 ? `${props.utcOffset / 3600}` : `+${props.utcOffset / 3600}`})
          </DayFlexContainer>
          <svg className="main_scheduler_svg" ref={svgRef} height="571" />
        </SchedulerContainer>
      </Container>
    </Modal>
  );
};

MeasurementScheduleModal.propTypes = {
  close: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  schedule: PropTypes.object.isRequired,
  stops: PropTypes.object.isRequired,
  disabledRows: PropTypes.object.isRequired,
  intervals: PropTypes.array.isRequired,
  saveSchedule: PropTypes.func.isRequired,
  utcOffset: PropTypes.number.isRequired,
  component: PropTypes.func,
  restrictHighRes: PropTypes.bool
};

MeasurementScheduleModal.defaultProps = {
  component: () => {},
  restrictHighRes: false
};

export default MeasurementScheduleModal;
