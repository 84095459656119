import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'common/components/organisms/Modal';
import H3 from 'common/typography/H3/H3';
import styled from 'styled-components';
import LoadingSvg from 'common/images/LoadingSvg';
import * as sensorActions from '../actions/sensors.actions';

const Loader = styled.div`
  top: 100px;
  left: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
`;

function DeviceReportedInfoModal(props) {
  const { data, loading } = props.lastReportedInfo;
  const { getDeviceLastReportedInfo } = props.sensorActions;
  useEffect(() => {
    const serialNumber = props.serialNumber;
    getDeviceLastReportedInfo(serialNumber);
  }, []);

  return (
    <Modal
      width="1100px"
      padding="0 10px"
      close={() => {
        props.onCancel();
      }}
      minHeight="500px"
    >
      <H3>Last Reported Info - {props.serialNumber} </H3>
      {!loading && data && (
        <div>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}

      {(loading || (data && data.data && data.data.length === 0)) && (
        <Loader>
          <LoadingSvg />
        </Loader>
      )}
    </Modal>
  );
}

const mapStateToProps = state => ({
  lastReportedInfo: state.sensors.lastReportedInfo
});

const mapDispatchToProps = dispatch => ({
  sensorActions: bindActionCreators(sensorActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceReportedInfoModal);
