import React from 'react';
import { connect } from 'react-redux';

import FlexContainer from 'common/components/atoms/FlexContainer';
import RibbonItem from 'common/components/atoms/RibbonItem';
import { history } from 'common/helpers';
import { isPetasenseAdmin, isPetasenseViewer, isAdmin, isAnalyst } from 'common/rbac/util';
import { getAccountMenuItems } from 'home/HomePage/utils';
import { tabsOnlyForPetasenseAdmins, tabsOnlyForAnalystsAndAdmins, tabsOnlyForAnalysts, groupsExcludedForPartners } from 'home/HomePage/constants/routes.constants';

const getRoutes = (user, isPartnerSite = false) => {
  let excludeGroupKeys = [];
  let excludeRouteKeys = [];
  if (!isPetasenseAdmin(user) && !isPetasenseViewer(user)) {
    excludeRouteKeys = tabsOnlyForPetasenseAdmins;
    if (!isAdmin(user) && !isAnalyst(user)) {
      excludeRouteKeys = excludeRouteKeys.concat(tabsOnlyForAnalystsAndAdmins);
    }
    if (!isAnalyst(user)) {
      excludeRouteKeys = excludeRouteKeys.concat(tabsOnlyForAnalysts);
    }
  }
  if (isPartnerSite) {
    excludeGroupKeys = excludeGroupKeys.concat(groupsExcludedForPartners);
  }
  return getAccountMenuItems(excludeRouteKeys, excludeGroupKeys);
};

const onClickItem = (item) => {
  if (item.external) window.open(item.path, '_blank');
  else history.push(item.path);
};

const AccountMenuItems = ({ user, partnerName }) => (
  <FlexContainer id="account-menu-items" direction="column">
    {getRoutes(user, partnerName !== 'Petasense').map(item => (
      item.groupName ? (
        <RibbonItem key={item.key} disabled disabledColor="#999B95">
          {item.groupName}
        </RibbonItem>)
        : (
          <RibbonItem
            key={item.key}
            name={item.key}
            fontSize="14px"
            width="max-content"
            onClick={() => onClickItem(item)}
          >
            {item.displayName}
          </RibbonItem>)
    ))}
  </FlexContainer>
);

const mapStateToProps = state => ({
  user: state.user.user,
  partnerName: state.companyReducer.partner.name
});

export default connect(mapStateToProps, null)(AccountMenuItems);
