import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import styled from 'styled-components';
import { history } from 'common/helpers';
import InputField from 'common/components/atoms/InputField';
import Modal from 'common/components/organisms/Modal';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Button_T from 'common/components/atoms/Button';
import AreaIcon from 'common/images/AssetHierarchy/AreaIcon';
import AssetIcon from 'common/images/AssetHierarchy/AssetIcon';

import * as hierarchyUpdateActions from '../../actions/hierarchy.actions';
import * as assetDetailsActions from '../../actions/assetDetails.actions';

const ButtonContainer = styled(FlexContainer)`
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
`;

const Button = styled(Button_T)`
  padding-left: 10px;
  margin-top: 0;
`;

const Icon = styled.span`
  padding-right: 10px;
  margin-top: 10px;
`;
export const AREA_TYPE_OPTIONS = [
  { text: <><Icon><AreaIcon /></Icon>Area</>, value: 'area' },
  { text: <><Icon><AreaIcon /></Icon>Sub-Area</>, value: 'sub-area' },
  { text: <><Icon><AreaIcon /></Icon>Building</>, value: 'building' },
  { text: <><Icon><AreaIcon /></Icon>Generic Node</>, value: 'generic-node' },
  { text: <><Icon><AssetIcon /></Icon>Asset</>, value: 'asset' },
];

class AddAreaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.editMode ? (this.props.node && this.props.node.node_name) : '',
      area_type: (this.props.node && this.props.node.node_sub_type) || '',
      error: ''
    };
  }

  handleAreaChange = (e) => {
    const value = e.target.value;
    this.setState({ name: value });
  }

  handleAreaTypeChange = (value) => {
    const { node, closeModal, editMode } = this.props;

    if (!value) this.setState({ error: 'area type can not be empty' });
    else if (value === 'asset') {
      if (node.node_type === 'site') {
        this.setState({ error: 'Asset cannot be assigned to site' });
        return;
      }
      if (editMode) {
        this.setState({ error: 'Cannot change Area to Asset' });
        return;
      }
      history.push({
        pathname: '/machines/new',
        state: {
          areaName: node.node_name,
          areaId: node.id,
        }
      });
      closeModal();
    } else {
      this.setState({ area_type: value });
      this.setState({ error: '' });
    }
  }

  saveArea = () => {
    const { node, closeModal, siteId, editMode } = this.props;
    const name = this.state.name.trim();
    const area_type = this.state.area_type;
    if (!name) {
      this.setState({ error: 'area name can not have whitespaces only' });
      return;
    }
    if (!area_type) {
      this.setState({ error: 'area type can not be empty' });
      return;
    }
    if (editMode && node.node_type === 'area') {
      this.props.hierarchyUpdateActions.editArea(node.id, { name, area_type }).then(
        (res) => {
          toastr.success(res.message);
          this.props.assetDetailsActions.getHierarchy(false);
          closeModal();
        },
        (error) => {
          this.setState({ error: error.message });
        }
      );
    } else {
      const parent_id = node.node_type === 'area' ? node.id : -1;
      this.props.hierarchyUpdateActions.createArea({ name, account_id: siteId, area_type, parent_id }).then(
        (res) => {
          toastr.success(res.message);
          this.props.assetDetailsActions.getHierarchy(false).then(
            () => {
              this.props.scrollNodeInView('area', res.id);
            }
          );
          closeModal();
        },
        (error) => {
          this.setState({ error: error.message });
        }
      );
    }
  }

  render() {
    const { name, error, area_type } = this.state;
    const { closeModal, editMode } = this.props;
    return (
      <Modal id="area-info" title={`${editMode ? 'Edit' : 'Add'} Node`} close={closeModal} width="40%">
        <InputField
          error={error}
          label="Type"
          maxLength={50}
          onChange={(e, d) => this.handleAreaTypeChange(d.value)}
          placeholder="Click to select a node type"
          type="select"
          value={area_type}
          options={AREA_TYPE_OPTIONS}
        />
        <InputField
          error={error}
          label="Name"
          maxLength={50}
          onChange={this.handleAreaChange}
          placeholder="Click to name your node"
          type="text"
          value={editMode ? name : null}
        />
        <ButtonContainer>
          <Button disabled={name.length === 0 || !!error} onClick={this.saveArea}>Save</Button>
          <Button text onClick={closeModal} alignVertical>
            Cancel
          </Button>
        </ButtonContainer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  siteId: state.breadcrumb.site.id,
});

const mapDispatchToProps = dispatch => ({
  hierarchyUpdateActions: bindActionCreators(hierarchyUpdateActions, dispatch),
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAreaModal);
