import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { history } from 'common/helpers';
import MoreOptions from 'common/components/organisms/MoreOptions';
import AccountMenuItems from 'common/components/organisms/AccountMenuItems';
import SearchFilters from 'common/components/atoms/SearchFilters';
import FilterItem_T from 'common/components/atoms/FilterItem';
import Filter from 'common/components/molecules/Filter';
import FilterCountContainer from 'common/components/atoms/FilterCountContainer';
import FlexContainer from 'common/components/atoms/FlexContainer';
import List from 'common/components/molecules/List';
import MsgContainer from 'common/components/atoms/MsgContainer';
import Checkbox from 'common/components/atoms/Checkbox';
import PlusSvg from 'common/images/PlusSvg';
import { isFilterApplied } from 'common/utils';
import Button, { ButtonTransparent } from 'common/components/atoms/Button';
import { operations, mapComponentToResource } from 'common/rbac/constants';
import RBAC from 'common/rbac/RBAC';

import FirmwareDeploymentItem from './FirmwareDeploymentItem';
import AddDeploymentModal from './AddDeploymentModal';

import * as sensorActions from '../../Sensors/actions/sensors.actions';
import * as firmwareDeploymentActions from '../actions/firmwareDeployment.actions';

const ListContainer = styled(FlexContainer).attrs({ direction: 'column' })`
position: relative;
overflow: auto;
width: ${props => props.width};
height: 100%;
${props =>
    props.width === '70%' &&
  `border-right: 2px solid ${props.theme.colors.greyL};`}
${props =>
    props.minWidth && `min-width: ${props.minWidth};`}
`;

const DeploymentsContainer = styled(FlexContainer).attrs({ direction: 'row' })`
  background-color: ${props => props.theme.colors.white};
  overflow: auto;
`;

const FilterItem = styled(FilterItem_T)`
  padding: 0.5em 1em;
  min-width: ${props => props.minWidth || '150px'};
  color: ${props => props.theme.colors.lightGray};
  span {
    margin-left: 0.5em;
  }
  ${props => props.paddingBottom && 'padding-bottom: 15px;'}
`;

class FirmwareDeployment extends Component {
  constructor() {
    super();
    this.initialSearchParams = {
      firmware_type: [],
      association_type: [],
      target_firmware_type: [],
      account_search_key: '',
      accounts: [],
    };
    this.state = {
      allAccounts: [],
      searchParams: {
        ...this.initialSearchParams,
      },
      filtersOpen: {
        firmware_type: false,
        association_type: false,
        target_firmware_type: false,
      },
      associationOptions: [
        { text: 'Accounts', value: 'accounts' },
        { text: 'Devices', value: 'devices' },
        { text: 'Firmwares', value: 'firmwares' }
      ],
      firmwareOptions: [
        { text: 'MCU', value: 'mcu' },
        { text: 'Wifi', value: 'wifi' },
        { text: 'Ethernet', value: 'ethernet' },
        { text: 'Bluetooth', value: 'bluetooth' },
        { text: 'Bootloader', value: 'bootloader' },
      ],
      targetFirmwareOptions: [],
      addDeploymentModalOpen: false,
    };
  }

  componentDidMount() {
    const title = this.props.title;
    document.title = `${title} | Firmware Deployment`;

    const { getResourcesBasedOnType } = this.props.sensorActions;
    const {
      setFirmwareDeploymentFilters,
      getAllOpsTx,
      getAllOpsMotes,
      getFirmwares
    } = this.props.firmwareDeploymentActions;

    getResourcesBasedOnType('mcu_firmware').then((res) => {
      this.setState({ targetFirmwareOptions: res });
    });
    const filters = [
      { name: 'account_id', op: 'in', value: [] },
      { name: 'firmware_type', op: 'in', value: [] },
      { name: 'association_type', op: 'in', value: [] },
      { name: 'target_version', op: 'in', value: [] },
    ];
    setFirmwareDeploymentFilters(filters);
    getAllOpsTx();
    getAllOpsMotes();
    getFirmwares({ all: true });
  }

  renderFilterOptions = (options, name) => {
    if (_.isEmpty(options)) {
      return (
        <MsgContainer>
          <span>No {name} are available</span>
        </MsgContainer>
      );
    }

    return options.map((option, index) => {
      const selected = _.includes(this.state.searchParams[name], option.value);
      return (
        <FilterItem
          key={index.toString()}
          className="dropdown-item"
          selected={selected}
          onClick={() => {
            let types = [];
            if (selected) {
              types = _.filter(
                this.state.searchParams[name],
                t => t !== option.value
              );
            } else {
              types = [...this.state.searchParams[name], option.value];
            }
            this.changeFilter(name, types, selected);
          }}
        >
          <Checkbox value={selected} />
          <span>{option.text}</span>
        </FilterItem>
      );
    });
  };

  toggleFilterOpen = (filter) => {
    this.setState(prevState => ({
      filtersOpen: {
        ...prevState.filtersOpen,
        [filter]: !prevState.filtersOpen[filter]
      }
    }));
  };

  toggleAddDeploymentModal = () => {
    this.setState(prevState => ({ addDeploymentModalOpen: !prevState.addDeploymentModalOpen }));
  }

  changeFilter = (filter, value) => {
    const searchParams = {
      ...this.state.searchParams,
      [filter]: value
    };
    this.setState({ searchParams });
    return this.search(searchParams);
  };

  search = (params = null) => {
    const { searchParams: stateParams } = this.state;
    const searchParams = params || stateParams;
    const { setFirmwareDeploymentFilters } = this.props.firmwareDeploymentActions;

    const filters = [
      { name: 'account_id', op: 'in', value: searchParams.accounts },
      { name: 'firmware_type', op: 'in', value: searchParams.firmware_type || [] },
      { name: 'association_type', op: 'in', value: searchParams.association_type || [] },
      { name: 'target_version', op: 'in', value: searchParams.target_firmware_type || [] },
    ];
    return setFirmwareDeploymentFilters(filters);
  };

  clearAllFilters = () => {
    const searchParams = {
      ...this.initialSearchParams
    };
    this.setState({ searchParams });
    this.search(searchParams);
  }

  searchAccountFilter = (value) => {
    this.setState(prevState => ({
      accounts: prevState.allAccounts.filter(u =>
        u.text.toLowerCase().includes(value.toLowerCase())
      ),
      searchParams: {
        ...prevState.searchParams,
        account_search_key: value
      }
    }));
  };

  addDeployment = (device_type, fw_id, name, association_type, associations) => {
    let paramsList = [];
    const { user } = this.props;
    const { addFirmwareDeployment } = this.props.firmwareDeploymentActions;
    switch (association_type) {
      case 'devices':
        paramsList = [{
          account_id: user.account_id,
          device_type,
          fw_id,
          name,
          association_type,
          device_ids: associations
        }];
        break;
      case 'firmwares':
        paramsList = [{
          account_id: user.account_id,
          device_type,
          fw_id,
          name,
          association_type,
          firmware_ids: associations
        }];
        break;
      case 'accounts':
        paramsList = associations.map(account_id => ({
          association_type,
          device_ids: [],
          device_type,
          firmware_ids: [],
          fw_id,
          name,
          account_id
        }));
        break;
      default:
    }
    paramsList.map(params => addFirmwareDeployment(params));

    this.toggleAddDeploymentModal();
  }

  selectGivenItems = (selected, key) => {
    this.setState(
      prevState => ({
        searchParams: {
          ...prevState.searchParams,
          [key]: selected
        },
        filtersOpen: {
          ...prevState.filtersOpen,
          [key]: false
        }
      }),
      this.search
    );
  };

  onClickDeployment = (deployment_id) => {
    history.push({
      pathname: `/admin-dashboard/firmware-deployments/${deployment_id}`,
      state: { from_app: true }
    });
  }

  render() {
    const {
      deployments: items,
      user,
      metadata
    } = this.props;
    const { loadMoreFirmwareDeployments } = this.props.firmwareDeploymentActions;
    const props = {
      columnSizes: [16, 20, 16, 16, 20, 16],
      headers: [
        {
          name: 'id',
          label: 'Deployment ID',
        },
        {
          name: 'name',
          label: 'Name',
        },
        {
          name: 'firmware_type',
          label: 'Firmware Type'
        },
        {
          name: 'association_type',
          label: 'Association Type'
        },
        {
          name: 'target_version',
          label: 'Target Version'
        },
        {
          name: 'account_name',
          label: 'Account Name'
        }
      ],
      maxHeightAfterScroll: 'calc(100vh - 160px)',
      triggerLoadMore: 'calc(100vh - 195px)',
      emptyMsg: 'No results found',
      ItemComponent: FirmwareDeploymentItem,
      onClick: this.onClickDeployment,
      items: { ...items, loading: items.loading || metadata.loading },
      loadMoreItems: loadMoreFirmwareDeployments,
      user
    };

    const getFilterText = (options, type) => {
      let typeText = '';
      if (!_.isEmpty(options)) {
        typeText = type.reduce((acc, id, idx) => {
          const name = options.filter(type => type.value === id);
          if (idx === 0) return name[0].text;
          if (idx === 1) return `${acc}, ${name[0].text}...`;
          return acc;
        }, '');
      }
      return typeText;
    };

    return (
      <div>
      <MoreOptions>
        <AccountMenuItems />
      </MoreOptions>
      <SearchFilters height="60px">
        {items && (
            <FilterCountContainer>{items.total_count}</FilterCountContainer>
        )}
        {this.props.adminDashboard.adminOpen ?
          (
            <RBAC
              resource={mapComponentToResource.FirmwareDeployments}
              operation={operations.Create}
              yes={(
            <ButtonTransparent title="Add Device" onClick={this.toggleAddDeploymentModal} secondary="#000" marginLeft="10px">
              <PlusSvg width={16} height={16} />
            </ButtonTransparent>
              )}
            />
          ) : null
          }
        <Filter
          title="Firmware Type:"
          value={getFilterText(this.state.firmwareOptions, this.state.searchParams.firmware_type)}
          open={this.state.filtersOpen.firmware_type}
          toggleOpen={() => this.toggleFilterOpen('firmware_type')}
        >
            {this.renderFilterOptions(this.state.firmwareOptions, 'firmware_type')}
        </Filter>
        <Filter
          title="Association Type:"
          value={getFilterText(this.state.associationOptions, this.state.searchParams.association_type)}
          open={this.state.filtersOpen.association_type}
          toggleOpen={() => this.toggleFilterOpen('association_type')}
        >
            {this.renderFilterOptions(this.state.associationOptions, 'association_type')}
        </Filter>
        <Filter
          title="Target Firmware Version:"
          value={getFilterText(this.state.targetFirmwareOptions, this.state.searchParams.target_firmware_type)}
          open={this.state.filtersOpen.target_firmware_type}
          toggleOpen={() => this.toggleFilterOpen('target_firmware_type')}
        >
            {this.renderFilterOptions(this.state.targetFirmwareOptions, 'target_firmware_type')}
        </Filter>
        {isFilterApplied(this.state.searchParams) && (
            <Button text onClick={this.clearAllFilters} fontSize="11px" margin="0 20px">
              Clear Filters
            </Button>
        )}
      </SearchFilters>
      <DeploymentsContainer>
          <ListContainer
            width="100%"
            minWidth="45rem"
          >
            <List
              {...props}
            />
          </ListContainer>
      </DeploymentsContainer>
      {this.state.addDeploymentModalOpen && (
        <AddDeploymentModal
          close={this.toggleAddDeploymentModal}
        />
      )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  deployments: state.firmwareDeployments.deployments,
  metadata: state.firmwareDeployments.metadata,
  adminDashboard: state.adminDashboard,
  user: state.user,
  title: state.companyReducer.partner.title
});

const mapDispatchToProps = dispatch => ({
  firmwareDeploymentActions: bindActionCreators(firmwareDeploymentActions, dispatch),
  sensorActions: bindActionCreators(sensorActions, dispatch)
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirmwareDeployment);
