import { combineReducers } from 'redux';

import { machineDetailsConstants } from '../machineDetails.constants';
import spectrum from './machineDetailsTagChartsSpectrum.reducer';

export const machineDetailsTagChartsReducer = combineReducers({
  trend,
  waveform,
  spectrum
});

function trend(state = {}, action) {
  switch (action.type) {
    case machineDetailsConstants.tagChart.TREND_REQUEST:
      return {
        loading: true
      };
    case machineDetailsConstants.tagChart.TREND_SUCCESS:
      return {
        data: action.trend
      };
    case machineDetailsConstants.tagChart.TREND_FAILURE:
      return {
        error: action.error.message
      };
    default:
      return state;
  }
}

function waveform(state = {}, action) {
  switch (action.type) {
    case machineDetailsConstants.tagChart.WAVEFORM_REQUEST:
      return {
        loading: true
      };
    case machineDetailsConstants.tagChart.WAVEFORM_SUCCESS:
      return {
        data: action.waveform
      };
    case machineDetailsConstants.tagChart.WAVEFORM_FAILURE:
      return {
        error: action.error.message
      };
    default:
      return state;
  }
}
