import styled from 'styled-components';

import InputField from './InputField';

const DateSelector = styled.div`
  height: 100%;
  display: inline-block;
  width: 250px;
  vertical-align: top;
  padding: 10px 15px;
  box-sizing: border-box;
  ${InputField} {
    margin-bottom: 13.5px;
  }
  input {
    font-size: 1em;
    padding: .57857143em 1em;
  }
  label {
    font-weight: 600;
    margin-bottom: 3px;
  }
`;

export default styled(DateSelector)``;
