import React from 'react';
import PropTypes from 'prop-types';

const TickingSvg = ({ width, height, fill, stroke, ...rest }) => (
  <svg
    viewBox="0 0 48 48"
    width={width}
    height={height}
    {...rest}
  >
    <g id="surface1">
      <path
        stroke={stroke}
        strokeWidth={5}
        d="M 40.601563 12.101563 L 17 35.699219 L 7.398438 26.101563 L 4.601563 29 L 17 41.300781 L 43.398438 14.898438 Z "
      />
    </g>
  </svg>
);

TickingSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
};

TickingSvg.defaultProps = {
  width: 14,
  height: 14,
  fill: 'white',
  stroke: 'white',
};

export default TickingSvg;
