
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'common/components/molecules';
import { FlexContainer } from 'common/components/atoms';
import styled from 'styled-components';
import MoreOptions from 'common/components/organisms/MoreOptions';
import { ButtonTransparent } from 'common/components/atoms/Button';
import AccountMenuItems from 'common/components/organisms/AccountMenuItems';
import PlusSvg from 'common/images/PlusSvg';
import * as _ from 'lodash';
import AddAccount from './AddAccount';
import * as accountsListActions from '../actions/account.actions';
import accountsItem from './AccountItem';
import { Search } from '../../../common/images/FaIcons';
import InputField from '../../../common/components/atoms/InputField';

const ListContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
`;

const AccountsContainer = styled(FlexContainer).attrs({ direction: 'row' })`
  background-color: ${props => props.theme.colors.white};
  overflow: auto;
`;

const SearchFiltersContainer = styled(FlexContainer)`
  padding: 0.5em;
  height: 53px;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.greyL}; 
`;

function Account(props) {
  const { getAccountsList, loadMoreAccounts, setAccountsSorter } = props.accountsListActions;
  const { object, hasMore, loading, listLoading, page, total_count } = props.accountsList;
  const { accountsSorter } = props;

  const [searchkey, setSearchKey] = useState('');
  const [showAddAccount, setShowAddAccount] = useState(false);

  const debouncedGetAccountsList = _.debounce((searchKey) => {
    getAccountsList(searchKey, 1, false, 300);
  }, 400);

  const handleSearchChange = (e) => {
    const newValue = e.target.value;
    setSearchKey(newValue);
  };

  const handleClearInput = () => setSearchKey('');

  const loadMore = () => {
    if (hasMore) {
      loadMoreAccounts();
    }
  };
  const handleAddAccount = () => {
    setShowAddAccount(true);
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-Us', options);
  }

  useEffect(() => {
    const { title } = props;
    document.title = `${title} | Accounts`;
    debouncedGetAccountsList(searchkey);
  }, [searchkey]);

  const items = object;
  const listProps = {
    formatDate,
    columnSizes: [15, 20, 20, 20],
    headers: [
      {
        name: 'id',
        label: 'Account ID',
      },
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'industry',
        label: 'Industry',
      },
      {
        name: 'created_at',
        label: 'Created On',
      }
    ],
    maxHeightAfterScroll: 'calc(100vh - 160px)',
    triggerLoadMore: 'calc(100vh - 195px)',
    ItemComponent: accountsItem,
    items: { object: items },
    setSorter: setAccountsSorter,
    sorterState: accountsSorter,
    loadMoreAccounts: loadMore,
    loading,
    listLoading,
    total_count,
    page
  };

  return (
    <div>
      <MoreOptions>
        <AccountMenuItems />
      </MoreOptions>
      <SearchFiltersContainer>
        <ButtonTransparent title="Add Account" onClick={handleAddAccount} secondary marginLeft="10px">
          <PlusSvg width="16" height="16" />
        </ButtonTransparent>
        <InputField
          type="text"
          name="account-search-box"
          prefix={<Search />}
          prefixSide="left"
          onChange={handleSearchChange}
          value={searchkey}
          placeholder="Search..."
          searchBox
          clearable
          clearInput={handleClearInput}
        />
        {showAddAccount && (
          <AddAccount onCancel={() => setShowAddAccount(false)} />
        )}

      </SearchFiltersContainer>
      <AccountsContainer>
        <ListContainer>
          <List {...listProps} />
        </ListContainer>
      </AccountsContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  accounts: state.accounts,
  accountsList: state.accounts.accountsList,
  accountsSorter: state.accounts.accountsSorter,
  title: state.companyReducer.partner.title
});

const mapDispatchToProps = dispatch => ({
  accountsListActions: bindActionCreators(accountsListActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
