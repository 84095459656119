
export const findNode = (nodeId, components) => {
  let node = null;
  const innerNodes = (components) => {
    components.forEach((component) => {
      if (component.id === nodeId) {
        node = component;
      } else innerNodes(component.components);
    });
  };
  innerNodes(components);
  return node;
};
