import React from 'react';
import PropTypes from 'prop-types';

const NewBatteryIcon = ({ width, height, fill }) => (
<svg width={width} height={height} viewBox="0 0 16 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g id="Web-app-UI-kit" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="3-0-1/p05/icons" transform="translate(-588.000000, -898.000000)">
      <g id="activity" transform="translate(80.000000, 578.000000)">
        <g id="warning_state" transform="translate(506.000000, 0.000000)">
          <g id="battery_>20%-icon" transform="translate(0.000000, 314.000000)">
            <rect id="icon-bg" x={0} y={0} width={20} height={20} />
            <g id="icon" transform="translate(2.000000, 6.000000)" fill={fill} fillRule="nonzero">
              <path d="M14.6065,1.80211321 L13.448,1.80211321 L13.448,1.24950943 C13.448,0.64090566 12.8855,0.14445283 12.1935,0.14445283 L1.25483333,0.14445283 C0.563166667,0.144603774 0,0.641207547 0,1.24950943 L0,6.77539623 C0,7.3850566 0.563166667,7.88030189 1.25483333,7.88030189 L12.1933333,7.88030189 C12.8853333,7.88030189 13.4478333,7.3850566 13.4478333,6.77539623 L13.4478333,6.2229434 L14.606,6.2229434 C15.2975,6.2229434 15.8606667,5.72739623 15.8606667,5.11773585 L15.8606667,2.90762264 C15.8611667,2.29871698 15.2981667,1.80211321 14.6065,1.80211321 Z M12.1933333,6.77569811 L1.22266667,6.77569811 L1.25483333,1.24966038 L12.2253333,1.24966038 L12.2288333,6.7645283 C12.2288333,6.7645283 12.2186667,6.77569811 12.1933333,6.77569811 Z M14.6065,5.11773585 L13.448,5.11773585 L13.448,2.90762264 L14.6388333,2.90762264 L14.6065,5.11773585 Z" id="Shape" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

);

NewBatteryIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

NewBatteryIcon.defaultProps = {
  width: 16,
  height: 8,
  fill: '#000000'
};


export default NewBatteryIcon;
