import resourceConstants from '../resource.constant';

const initialState = {
  areas: {
    items: [],
    loading: false,
    error: ''
  }
};

export const resources = (state = initialState, action) => {
  switch (action.type) {
    case resourceConstants.GET_AREAS_REQUEST:
      return {
        ...state,
        areas: {
          items: [],
          loading: true,
          error: ''
        }
      };
    case resourceConstants.GET_AREAS_SUCCESS:
      return {
        ...state,
        areas: {
          items: [...action.areas],
          loading: false,
          error: ''
        }
      };
    case resourceConstants.GET_AREAS_FAILURE:
      return {
        ...state,
        areas: {
          items: [],
          loading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
