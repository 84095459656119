import _ from 'lodash';
import MachineMLTypes from '../actions/machineML.types';


// FIXME: machine_health is being used somewhere else, currently its redundent, remove in future
const initialState = {
  health: {
    machine: {},
    components: [],
    health_prediction: {
      components_health_condition: [],
      machine_defect_options: []
    },
    loading: false,
    machine_loading: false,
    component_loading: false
  },
  baselines: {
    components: [],
    loading: false,
    error: ''
  },
  machine_health: {},
  recompute: {
    state: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MachineMLTypes.GET_MACHINE_HEALTH_CONDITION_REQUEST:
      return {
        ...state,
        health: {
          ...state.health,
          loading: true
        }
      };
    case MachineMLTypes.GET_MACHINE_HEALTH_CONDITION_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          health_prediction: action.prediction
        },
        machineId: action.machineId
      };
    case MachineMLTypes.GET_MACHINE_HEALTH_CONDITION_FAILURE:
      return {
        ...state,
        health: {
          ...state.health,
          loading: false,
          error: action.error.message
        }
      };
    case MachineMLTypes.UPDATE_COMPONENTS_HEALTH_SCORE_PARAMETERS_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          health_prediction: {
            ...state.health.health_prediction,
            components_health_condition: state.health.health_prediction.components_health_condition.map((c) => {
              const component_details = _.find(action.components, component => component.id === c.component_id);
              if (component_details) return {
                ...c,
                health_score_parameters: component_details.health_score_parameters,
                health_score_features_info: component_details.health_score_features_info
              };
              return c;
            })
          }
        }
      };
    case MachineMLTypes.UPDATE_COMPONENTS_HEALTH_SCORE_PARAMETERS_FAILURE:
      return {
        ...state,
        health: {
          ...state.health
        }
      };
    case MachineMLTypes.UPDATE_AHS_ENABLED_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          health_prediction: {
            ...state.health.health_prediction,
            ahs_enabled: action.ahs_enabled
          }
        }
      };
    case MachineMLTypes.UPDATE_AHS_ENABLED_FAILURE:
      return {
        ...state,
        health: {
          ...state.health
        }
      };
    case MachineMLTypes.GET_MACHINE_TRAIN_HEALTH_TREND_REQUEST:
      return {
        ...state,
        health: {
          ...state.health,
          loading: true
        }
      };
    case MachineMLTypes.GET_MACHINE_TRAIN_HEALTH_TREND_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          machine: action.health.machine,
          components: action.health.components,
          loading: false
        },
        machineId: action.machineId
      };
    case MachineMLTypes.GET_MACHINE_TRAIN_HEALTH_TREND_FAILURE:
      return {
        ...state,
        health: {
          ...state.health,
          loading: false,
          error: action.error.message
        }
      };
    case MachineMLTypes.MACHINE_HEALTH_SCORE_RECOMPUTE_TASK:
      return {
        ...state,
        recompute: {
          state: true,
          status: action.status || '',
          total: action.total,
          completed: action.completed
        }
      };
    case MachineMLTypes.MACHINE_HEALTH_SCORE_RECOMPUTE_TASK_DONE:
      return {
        ...state,
        recompute: {
          state: false
        }
      };
    case MachineMLTypes.MACHINE_HEALTH_SCORE_RECOMPUTE_TASK_CLEAR:
      return {
        ...state,
        recompute: {
          state: false
        }
      };
    case MachineMLTypes.GET_CHARTS_TAG_TREND_REQUEST: {
      return {
        ...state,
        baselines: {
          ...state.baselines,
          components: state.baselines.components.map((component) => {
            if (component.id !== action.componentId) return component;
            return {
              ...component,
              loading: true
            };
          })
        }
      };
    }
    case MachineMLTypes.GET_CHARTS_TAG_TREND_SUCCESS:
      return {
        ...state,
        baselines: {
          ...state.baselines,
          components: state.baselines.components.map((component) => {
            if (component.id !== action.componentId) return component;
            return {
              ...component,
              tags_data: component.tags_data.map((data) => {
                if (data.tag_id !== action.tagId) return data;
                return {
                  ...data,
                  trend: action.trend
                };
              })
            };
          })
        }
      };
    case MachineMLTypes.GET_CHARTS_TAG_TREND_FAILURE:
      return {
        ...state
      };
    case MachineMLTypes.GET_CHARTS_TAG_TREND_ALL_SUCCESS:
      return {
        ...state,
        baselines: {
          ...state.baselines,
          components: state.baselines.components.map((component) => {
            if (component.id !== action.componentId) return component;
            return {
              ...component,
              loading: false
            };
          })
        }
      };
    case MachineMLTypes.GET_MACHINE_HEALTH_TREND_REQUEST:
      return {
        ...state,
        health: {
          ...state.health,
          machine_loading: true
        },
        machine_health: {
          ...state.machine_health,
          loading: true
        }
      };
    case MachineMLTypes.GET_MACHINE_HEALTH_TREND_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          machine: action.machine_health,
          machine_loading: false
        },
        machine_health: action.machine_health
      };
    case MachineMLTypes.GET_MACHINE_HEALTH_TREND_FAILURE:
      return {
        ...state
      };

    case MachineMLTypes.GET_COMPONENT_HEALTH_TREND_REQUEST:
      return {
        ...state,
        health: {
          ...state.health,
          component_loading: action.componentId
        }
      };
    case MachineMLTypes.GET_COMPONENT_HEALTH_TREND_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          component_loading: false,
          components: state.health.components.map((component) => {
            if (component.component_id !== action.componentId) return component;
            return {
              ...component,
              health_trend: action.health
            };
          })
        }
      };
    case MachineMLTypes.GET_COMPONENT_HEALTH_TREND_FAILURE:
      return {
        ...state
      };
    case MachineMLTypes.GET_MACHINE_TREND_REQUEST:
      return {
        ...state,
        baselines: {
          components: [],
          loading: true,
          error: ''
        }
      };
    case MachineMLTypes.GET_MACHINE_TREND_SUCCESS:
      return {
        ...state,
        baselines: {
          components: action.components,
          loading: false,
          error: ''
        },
        machineId: action.machineId
      };
    case MachineMLTypes.GET_MACHINE_TREND_FAILURE:
      return {
        ...state,
        baselines: {
          ...state.baselines,
          components: [],
          loading: false,
          error: action.error
        }
      };
    case MachineMLTypes.SET_COMPONENTS_IN_BASELINES:
      return {
        ...state,
        baselines: {
          ...state.baselines,
          components: action.componentIds.map(id => ({
            id,
            name: '',
            tags_data: []
          }))
        }
      };
    case MachineMLTypes.GET_COMPONENT_TREND_REQUEST:
      return {
        ...state,
        baselines: {
          components: state.baselines.components.map((c) => {
            if (c.id !== action.componentId) return c;
            return {
              ...c,
              loading: true,
              error: ''
            };
          }),
        },
      };
    case MachineMLTypes.GET_COMPONENT_TREND_SUCCESS:
      return {
        ...state,
        baselines: {
          components: state.baselines.components.map((c) => {
            if (c.id !== action.componentId) return c;
            return {
              ...c,
              ...action.component,
              loading: false,
              error: ''
            };
          }),
        },
      };
    case MachineMLTypes.GET_COMPONENT_TREND_FAILURE:
      return {
        ...state,
        baselines: {
          components: state.baselines.components.map((c) => {
            if (c.id !== action.componentId) return c;
            return {
              ...c,
              loading: false,
              error: action.error
            };
          }),
        },
      };
    case MachineMLTypes.POST_COMPONENT_BASELINE_REQUEST:
      return {
        ...state,
        baselines: {
          components: state.baselines.components.map(component => ({
            ...component,
            tags_data: component.tags_data.map((tag_data) => {
              if (!_.includes(action.tagIds, tag_data.tag_id)) return tag_data;
              return {
                ...tag_data,
                saveLoading: true
              };
            })
          }))
        }
      };
    case MachineMLTypes.POST_COMPONENT_BASELINE_SUCCESS:
      return {
        ...state,
        baselines: {
          components: state.baselines.components.map(component => ({
            ...component,
            tags_data: component.tags_data.map((tag_data) => {
              if (!_.includes(action.tagIds, tag_data.tag_id)) return tag_data;
              return {
                ...tag_data,
                saveLoading: false,
                baseline_info: {
                  ...tag_data.baseline_info,
                  ...action.baseline_info
                },
                baseline_missing_tag_ids: action.error_tag_ids
              };
            })
          }))
        }
      };
    case MachineMLTypes.VALIDATE_COMPONENT_BASELINE_SUCCESS:
      if (action.key) {
        return {
          ...state,
          baselines: {
            components: state.baselines.components.map(component => ({
              ...component,
              tags_data: component.tags_data.map(tag_data => ({
                ...tag_data,
                validation: {
                  ...tag_data.validation,
                  // key is a combination of tag_type, amp_type and feature
                  [action.key]: action.result[tag_data.tag_id] || (tag_data.validation && tag_data.validation[action.key])
                }
              }))
            }))
          }
        };
      }
      return {
        ...state,
        baselines: {
          components: state.baselines.components.map(component => ({
            ...component,
            tags_data: component.tags_data.map(tag_data => ({
              ...tag_data,
              validation: {
                ...tag_data.validation,
                overall: action.result[tag_data.tag_id] || tag_data.validation.overall
              }
            }))
          }))
        }
      };
    case MachineMLTypes.CLEAR_BASELINE_VALIDATION:
      return {
        ...state,
        baselines: {
          components: state.baselines.components.map(component => ({
            ...component,
            tags_data: component.tags_data.map(tag_data => ({
              ...tag_data,
              validation: action.tagIds.includes(tag_data.tag_id) ? null : tag_data.validation
            }))
          }))
        }
      };
    case MachineMLTypes.POST_COMPONENT_BASELINE_FAILURE:
      return {
        ...state,
        baselines: {
          components: state.baselines.components.map(component => ({
            ...component,
            tags_data: component.tags_data.map((tag_data) => {
              if (!_.includes(action.tagIds, tag_data.tag_id)) return tag_data;
              return {
                ...tag_data,
                saveLoading: false
              };
            })
          }))
        }
      };
    case MachineMLTypes.DELETE_COMPONENT_BASELINE_REQUEST:
      return {
        ...state
      };
    case MachineMLTypes.DELETE_COMPONENT_BASELINE_SUCCESS:
      return {
        ...state,
        baselines: {
          components: state.baselines.components.map(component => ({
            ...component,
            tags_data: component.tags_data.map((tag_data) => {
              if (!_.isEmpty(action.tagIds) && !_.includes(action.tagIds, tag_data.tag_id)) return tag_data;
              return {
                ...tag_data,
                baseline_info: null,
                baseline_missing_tag_ids: []
              };
            })
          }))
        }
      };
    case MachineMLTypes.DELETE_COMPONENT_BASELINE_FAILURE:
      return {
        ...state
      };
    case MachineMLTypes.GET_AHS_FEATURES_INFO_REQUEST:
      return {
        ...state,
        health: {
          ...state.health,
          selected_ahs_features: {
            loading: true,
            list: []
          }
        }
      };
    case MachineMLTypes.GET_AHS_FEATURES_INFO_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          selected_ahs_features: {
            loading: false,
            list: action.features_info
          }
        }
      };
    case MachineMLTypes.GET_AHS_FEATURES_INFO_FAILURE:
      return {
        ...state,
        health: {
          ...state.health,
          selected_ahs_features: {
            loading: false,
            error: action.error
          }
        }
      };
    default:
      return state;
  }
};
