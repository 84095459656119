import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import _ from 'lodash';
import moment from 'moment';
import colors from 'common/styles/colors';
import { round } from 'common/utils';
import * as graphUtils from 'common/components/Chart/utils/graphUtils';
import InputField from 'common/components/atoms/InputField';
import TagChart from '../TagChart';
import WaterfallContainer from '../atoms/WaterfallContainer';
import { svgConstants as CONSTANTS } from '../../constants/waterfall.constants';

const TagChartContainer = styled.div`
  margin: 2rem;
`;

class WaterfallChart extends Component {
  constructor(props) {
    super(props);
    this.data = [];
    this.chartName = 'waterfall';
    this.state = {
      zLineChartData: [],
      doubleClickPoint: null,
      perspectiveX: CONSTANTS.PERSPECTIVE_OFFSET_X,
      perspectiveY: CONSTANTS.PERSPECTIVE_OFFSET_Y,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.spectrums, prevProps.spectrums)) {
      this.data = _.cloneDeep(this.props.spectrums);
      this.drawChart();
    }
    if (!_.isEqual(this.props.hoveredIndex, prevProps.hoveredIndex)) this.hoverSpectrum();
    if (!_.isEqual(this.props.excludedSpectrums, prevProps.excludedSpectrums)) this.excludeSpectrums();
    if (!_.isEqual(this.props.hierarchyViewPane, prevProps.hierarchyViewPane)) this.drawChart();
    if (
      this.state.perspectiveX !== prevState.perspectiveX ||
      this.state.perspectiveY !== prevState.perspectiveY
    ) this.translateChart();
  }

  hoverSpectrum = () => {
    const { hoveredIndex } = this.props;
    d3.selectAll('.spectrum').classed('focused', false);
    if (hoveredIndex) d3.select(`.spectrum-${hoveredIndex}`).classed('focused', true);
  }

  excludeSpectrums = () => {
    d3.selectAll('.spectrum').classed('exclude', false);
    this.props.excludedSpectrums.forEach((idx) => {
      d3.select(`.spectrum-${idx}`).classed('exclude', true);
    });
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.drawChart(), false);
    this.data = this.props.spectrums;
    this.showBaselineMark = _.some(this.data, d => d.is_baseline);
    this.drawChart();
    this.excludeSpectrums();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.drawChart(), false);
  }

  drawChart = () => {
    if (this.data.length === 0) {
      d3.select('.paint_focus_chart').remove();
      d3.select('.paint_context_chart').remove();
      d3.select(`defs.${this.chartName}`).remove();
      return;
    }
    this.initFocusChart();
    this.drawDefs();
    this.drawAxis();
    this.drawDataLines();
    this.drawZLines(false);
    this.drawTimestampLabels();
    this.drawYZPlane();
    this.initContextChart();
    this.addClickEventListener();
    this.addMouseoverEventListener();
    this.addDoubleClickEventListener();
    this.transformdata();
    this.setChartToCorrectHeight();
  }

  translateChart = () => {
    this.redrawTitleAndBaseline();
    this.focusChart.selectAll('.zLine-connect').remove();
    this.translateXYAxis();
    this.translateSpectra();
    this.translateAxisLeftTicks();
    this.redrawZLines();
    this.translateContextChart();
    this.transformdataDebounce();
    this.setChartToCorrectHeight();
  };

  translateSpectra = () => {
    d3.selectAll('.spectrum')
      .attr('transform', (d, i) => this.translate(this.getOffsetX(i + 2), this.getOffsetY(i + 2)));
  }

  translateAxisLeftTicks = () => {
    this.focusChart
      .selectAll('.axis.ticks-left')
      .attr('transform', this.translate(this.getOffsetX(CONSTANTS.OFFSET_Z_LINE + this.data.length), this.getOffsetY(CONSTANTS.OFFSET_Z_LINE + this.data.length)));

    this.focusChart
      .select('.axis.ticks-left')
      .attr('transform', this.translate(this.getOffsetX(this.data.length + CONSTANTS.OFFSET_Z_LINE), this.getOffsetY(this.data.length + CONSTANTS.OFFSET_Z_LINE) + CONSTANTS.LINE_HEIGHT));
  }

  translateXYAxis = () => {
    this.focusChart
      .select('.axis.axis--y')
      .attr('transform', this.translate(this.getOffsetX(CONSTANTS.XY_GRID_INDEX), this.getOffsetY(CONSTANTS.XY_GRID_INDEX)));

    this.focusChart
      .select('.axis.axis--x')
      .attr('transform', this.translate(this.getOffsetX(CONSTANTS.XY_GRID_INDEX), this.getOffsetY(CONSTANTS.XY_GRID_INDEX) + CONSTANTS.LINE_HEIGHT));

    this.focusChart
      .select('.axis.tickline-hidden.ticks-bottom')
      .attr('transform', this.translate(this.getOffsetX(this.data.length + CONSTANTS.OFFSET_Z_LINE), this.getOffsetY(this.data.length + CONSTANTS.OFFSET_Z_LINE) + CONSTANTS.LINE_HEIGHT));
  }

  translateContextChart = () => {
    this.contextChart
      .attr('transform', this.translate(this.getOffsetX(this.data.length + CONSTANTS.OFFSET_Z_LINE) + CONSTANTS.MARGIN_CONTEXT_CHART.LEFT, this.getOffsetY(this.data.length + CONSTANTS.OFFSET_Z_LINE) + CONSTANTS.MARGIN_CONTEXT_CHART.TOP));
  }

  redrawZLines = () => {
    this.zLineCoordinates = this.getZLineCoordinates();
    this.focusChart.selectAll('.zLine').remove();
    this.drawZLines(false);
    this.drawYZPlaneTopLine();
  }

  redrawTitleAndBaseline = () => {
    this.focusChart.selectAll('text.title,text.baseline-mark').remove();
    this.drawTitle();
    if (this.showBaselineMark) this.drawBaselineMark();
  }

  getZLineCoordinates = () => {
    const zLineCoordinates = [];
    for (let i = 1; i <= this.data.length + CONSTANTS.OFFSET_Z_LINE; i++) zLineCoordinates.push({ x: this.getOffsetX(i), y: this.getOffsetY(i) });
    return zLineCoordinates;
  }

  drawYZPlaneTopLine = () => {
    this.focusChart
      .append('g')
      .attr('class', 'yz-plane top-line')
      .classed('zLine', true)
      .attr('transform', this.translate(this.xScale(0), 0))
      .append('path')
      .attr('d', this.drawZLine(this.zLineCoordinates));
  }

  setChartToCorrectHeight = () => {
    const focusChart = d3.select('.paint_focus_chart').node();
    const contextChart = d3.select('.paint_context_chart').node();
    if (!focusChart || !contextChart) return;
    const focusChartHeight = focusChart.getBBox().height;
    const contextChartHeight = contextChart.getBBox().height;
    d3.select(this.chart)
      .attr('height', CONSTANTS.MARGIN_FOCUS_CHART.BOTTOM + focusChartHeight + contextChartHeight + 20);
  };

  transformdata = () => {
    this.dataPixel = this.data.map((spectrum, idx) => {
      const formattedData = spectrum.spectrum_data.map(d => ({
        ...d,
        xPixel: this.xScale(d.x) - (idx + 2) * this.state.perspectiveX + CONSTANTS.MARGIN_FOCUS_CHART.LEFT,
        yPixel: this.yScale(d.y) + this.getOffsetY(idx + 2),
        idx,
        spectrum
      }));
      return {
        ...spectrum,
        spectrum_data: formattedData
      };
    });
  };

  transformdataDebounce = _.debounce(this.transformdata, 500);

  getDistanceBtwTwoPoints = (a, b) => Math.sqrt((a.xPixel - b.xPixel) ** 2 + (a.yPixel - b.yPixel) ** 2);

  getClosestPoint = (data, target) => {
    const closest = data.reduce((prev, curr) => {
      if (Math.abs(this.getDistanceBtwTwoPoints(curr, target)) < Math.abs(this.getDistanceBtwTwoPoints(prev, target))) return curr;
      return prev;
    });
    return closest;
  }

  onMouseover = (doubleClick = false) => {
    const node = this.chart;
    const ymousePos = d3.mouse(node)[1] - CONSTANTS.MARGIN_FOCUS_CHART.TOP;
    const xmousePos = d3.mouse(node)[0] - this.offsetXByNoOfSpectra(this.data.length);
    const mousePos = {
      xPixel: xmousePos,
      yPixel: ymousePos
    };
    const maxYPixel = this.getOffsetY(this.data.length + CONSTANTS.XY_GRID_INDEX) + CONSTANTS.LINE_HEIGHT + CONSTANTS.MARGIN_FOCUS_CHART.TOP;
    const data = d3.merge(this.dataPixel.map(d => d.spectrum_data));
    const dataPoint = this.getClosestPoint(data, mousePos);
    if (doubleClick) this.setState({ doubleClickPoint: dataPoint });
    const domain = this.xScale.domain();
    if (dataPoint.x >= domain[0] && dataPoint.x <= domain[1] && mousePos.yPixel >= 0 && mousePos.yPixel <= maxYPixel) {
      const idx = dataPoint.idx + 1;
      this.drawSelection(dataPoint.x, doubleClick);
      this.drawTooltipText(dataPoint);
      d3.selectAll('.circle.waterfall-circle').remove();
      d3.select(`.spectrum-${idx}`).append('circle')
        .attr('class', 'circle waterfall-circle')
        .attr('cx', this.xScale(dataPoint.x))
        .attr('cy', this.yScale(dataPoint.y))
        .attr('r', 3)
        .attr('pointer-events', 'none');
      if (idx !== this.selectedSpectrumIdx) {
        d3.select(`.spectrum-${this.selectedSpectrumIdx}`).classed('focused', false);
        this.selectedSpectrumIdx = idx;
        d3.select(`.spectrum-${this.selectedSpectrumIdx}`).classed('focused', true);
      }
    } else {
      d3.select(`.spectrum-${this.selectedSpectrumIdx}`).classed('focused', false);
      this.selectedSpectrumIdx = null;
      d3.selectAll('.circle.waterfall-circle').remove();
      d3.selectAll('.selection.zLine-connect').remove();
    }
  }

  addMouseoverEventListener = () => {
    this.mouseover = graphUtils.addMouseoverEvent(this.chart, this.onMouseover);
  }

  addDoubleClickEventListener() {
    this.doubleClick = graphUtils.addDoubleClickEvent(this.chart, () => this.onMouseover(true));
  }

  addClickEventListener() {
    this.mouseClick = graphUtils.addMouseClickEvent(this.chart, (e) => {
      d3.selectAll('.focused.zLine-connect').remove();
      this.setState({ doubleClickPoint: null });
    });
  }

  drawTooltipText = (d) => {
    d3.selectAll(`.${this.chartName}-tooltip`).remove();
    this.tooltip = this.focusChart
      .append('g')
      .attr('class', `${this.chartName}-tooltip`);
    this.tooltip
      .append('text')
      .classed('tooltip-label', true)
      .attr('y', -15)
      .attr('x', this.svgWidth / 2)
      .style('text-anchor', 'middle')
      .style('fill', colors.black)
      .text(`Frequency: ${d.x} ${this.props.xUnit}`);
    this.tooltip
      .append('text')
      .classed('tooltip-label', true)
      .attr('y', 0)
      .attr('x', this.svgWidth / 2)
      .style('text-anchor', 'middle')
      .text(`Amplitude: ${round(d.y, 4)} ${this.props.yUnit}`);
  }

  createDefs = () => {
    this.defs = d3.select(this.chart)
      .append('defs')
      .attr('class', `${this.chartName}`);
  }

  addClipPath = () => {
    this.clipPath = d3.select(`defs.${this.chartName}`)
      .append('clipPath')
      .attr('id', `${this.chartName}-clip`)
      .attr('class', `${this.chartName}`)
      .append('rect')
      .attr('width', this.focusChartWidth)
      .attr('height', this.focusChartHeight);
  }

  drawDefs = () => {
    d3.select(`defs.${this.chartName}`).remove();
    this.createDefs();
    this.addClipPath();
  }

  setBottomTicks = () => {
    this.bottomTicks = this.axisTop.scale().ticks(CONSTANTS.NO_OF_TICKS_X);
  }

  onBrushChange = () => {
    this.xScale.domain(this.brushSelection.map(this.xContextScale.invert));
    this.spectrums.selectAll('path').attr('d', d => this.line(d.spectrum_data));
    this.focusChart.select('.ticks-bottom').call(this.axisBottom);
    this.focusChart.select('.axis--x').call(this.axisTop);
    this.setBottomTicks();
    this.drawZLines();
    const domain = this.xScale.domain();
    if (this.state.doubleClickPoint && this.state.doubleClickPoint.x >= domain[0] && this.state.doubleClickPoint.x <= domain[1]) {
      this.drawSelection(this.state.doubleClickPoint.x, true);
    } else {
      d3.select('.focused.zLine-connect').remove();
    }
    this.transformdata();
    d3.selectAll(`.${this.chartName}-tooltip`).remove();
    d3.selectAll('.circle.waterfall-circle').remove();
    d3.select('.selection.zLine-connect').remove();
  }

  brushed = () => {
    if ((d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') || !d3.event.selection) return;
    this.brushSelection = d3.event.selection;
    this.onBrushChange();
  }

  brushEnded = () => {
    if (!d3.event.selection) {
      this.brushSelection = this.xContextScale.range();
      this.onBrushChange();
    }
  }

  translate = (x, y) => `translate(${x}, ${y})`;

  formatTimestamp = d => moment(d).format('MMM Do YY, h:mm:ss a');

  drawTitle = () =>
    this.focusChart
      .append('g')
      .attr('transform', this.translate(this.getOffsetX(CONSTANTS.XY_GRID_INDEX), this.getOffsetY(CONSTANTS.XY_GRID_INDEX)))
      .append('text')
      .attr('class', 'title')
      .attr('pointer-events', 'none')
      .attr('dx', 10)
      .attr('dy', 35)
      .text('spectrum');

  drawBaselineMark = () =>
    this.focusChart
      .append('g')
      .attr('transform', this.translate(this.getOffsetX(CONSTANTS.XY_GRID_INDEX) + this.focusChartWidth, this.getOffsetY(CONSTANTS.XY_GRID_INDEX) + CONSTANTS.LINE_HEIGHT))
      .append('text')
      .attr('class', 'baseline-mark')
      .attr('pointer-events', 'none')
      .attr('dx', -170)
      .attr('dy', 40)
      .text('baseline');

  yAxisGridlines = () => {
    this.focusChart
      .append('g')
      .attr('class', 'axis axis--y')
      .attr('transform', this.translate(this.getOffsetX(CONSTANTS.XY_GRID_INDEX), this.getOffsetY(CONSTANTS.XY_GRID_INDEX)))
      .call(d3.axisRight(this.yScale)
        .ticks(CONSTANTS.NO_OF_TICKS_Y)
        .tickSizeInner(0)
        .tickSizeOuter(this.focusChartWidth)
        .tickFormat(''));
    this.focusChart
      .append('g')
      .attr('class', 'xy-plane top-line')
      .classed('zLine', true)
      .attr('transform', this.translate(this.xScale(0), this.yScale(0)))
      .append('path')
      .attr('d', this.drawZLine(this.zLineCoordinates));
  }

  ticksLeft = () => {
    this.focusChart
      .append('g')
      .attr('class', 'axis tickline-hidden ticks-left')
      .attr('transform', this.translate(this.getOffsetX(CONSTANTS.XY_GRID_INDEX), this.getOffsetY(CONSTANTS.XY_GRID_INDEX)))
      .call(this.axisLeft);
  }

  drawYZPlane = () => {
    this.focusChart
      .append('g')
      .attr('class', 'axis tickline-hidden ticks-left')
      .attr('transform', this.translate(this.getOffsetX(CONSTANTS.OFFSET_Z_LINE + this.data.length), this.getOffsetY(CONSTANTS.OFFSET_Z_LINE + this.data.length)))
      .call(d3.axisLeft(this.yScale)
        .ticks(CONSTANTS.NO_OF_TICKS_Y)
        .tickSize(10));

    this.focusChart
      .append('g')
      .attr('class', 'axis ticks-left')
      .attr('transform', this.translate(this.getOffsetX(CONSTANTS.OFFSET_Z_LINE + this.data.length), this.getOffsetY(CONSTANTS.OFFSET_Z_LINE + this.data.length)))
      .call(d3.axisRight(this.yScale)
        .ticks(CONSTANTS.NO_OF_TICKS_Y)
        .tickSize(0)
        .tickFormat(''))
      .append('text')
      .attr('class', 'ylabel')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - 2 * CONSTANTS.MARGIN_FOCUS_CHART.LEFT)
      .attr('x', 0 - (CONSTANTS.LINE_HEIGHT / 2))
      .style('text-anchor', 'middle')
      .text(`${this.props.yLabel}`);

    //  draw top line
    this.drawYZPlaneTopLine();

    // draw grid on YZ plane
    // this.focusChart
    //   .append('g')
    //   .selectAll('g.yz-lines')
    //   .data(this.leftTicks)
    //   .enter()
    //   .append('g')
    //   .attr('class', (d, i) => `yz-line-${i}`)
    //   .classed('zLine', true)
    //   .attr('transform', d => this.translate(0, this.yScale(d)))
    //   .append('path')
    //   .attr('d', this.drawZLine(this.zLineCoordinates));

    // draw Last Line
    this.focusChart
      .append('g')
      .attr('class', 'yz-last-tick')
      .classed('zLine', true)
      .attr('transform', this.translate(0, this.yScale(0)))
      .append('path')
      .attr('d', this.drawZLine(this.zLineCoordinates));
  }

  ticksBottom = () => {
    this.focusChart
      .append('g')
      .attr('transform', this.translate(this.getOffsetX(this.data.length + CONSTANTS.OFFSET_Z_LINE), this.getOffsetY(this.data.length + CONSTANTS.OFFSET_Z_LINE) + CONSTANTS.LINE_HEIGHT))
      .attr('class', 'axis tickline-hidden ticks-bottom')
      .call(this.axisBottom);
    this.focusChart
      .append('g')
      .attr('class', 'axis ticks-left')
      .attr('transform', this.translate(this.getOffsetX(this.data.length + CONSTANTS.OFFSET_Z_LINE), this.getOffsetY(this.data.length + CONSTANTS.OFFSET_Z_LINE) + CONSTANTS.LINE_HEIGHT))
      .call(d3.axisBottom(this.xScale)
        .ticks(CONSTANTS.NO_OF_TICKS_X)
        .tickSize(0)
        .tickFormat(''));
  }

  xAxisGridlines = () =>
    this.focusChart
      .append('g')
      .attr('transform', this.translate(this.getOffsetX(CONSTANTS.XY_GRID_INDEX), this.getOffsetY(CONSTANTS.XY_GRID_INDEX) + CONSTANTS.LINE_HEIGHT))
      .attr('class', 'axis axis--x')
      .call(this.axisTop);

  drawAxis = () => {
    this.yAxisGridlines();
    this.xAxisGridlines();
    // this.ticksLeft();
    this.ticksBottom();
  }

  drawTimestampLabels = () => {
    this.spectrums.append('text')
      .classed('zTick', true)
      .attr('x', this.xScale(0) + this.focusChartWidth + 10)
      .attr('y', this.yScale(0) + 5)
      .text(d => this.formatTimestamp(d.timestamp));
  }

  getOffsetX = i => this.offsetXByNoOfSpectra(this.data.length) - i * this.state.perspectiveX;

  getOffsetY = i => i * this.state.perspectiveY

  drawDataLines = () => {
    this.spectrums = this.focusChart
      .append('g')
      .classed('spectrums', true)
      .selectAll('g.spectrum')
      .data(this.data)
      .enter()
      .append('g')
      .attr('class', (d, i) => `spectrum-${i + 1}`)
      .classed('spectrum', true)
      .classed('baseline', d => d.is_baseline)
      .attr('transform', (d, i) => this.translate(this.getOffsetX(i + 2), this.getOffsetY(i + 2)));

    this.spectrums
      .append('path')
      .attr('d', d => this.line(d.spectrum_data));
  }

  drawSelection = (d, focused = false) => {
    const bisect = d3.bisector((d, x) => d.x - x).right;
    const coordinates = this.data.map((s, i) => {
      const data = s.spectrum_data;
      let idx = bisect(data, d);
      idx = idx === 0 ? idx : idx - 1;
      if (idx + 1 < data.length && (Math.abs(data[idx].x - d) > Math.abs(data[idx + 1].x - d))) idx += 1;
      return {
        ...data[idx],
        xPixel: this.getOffsetX(i + 2) + this.xScale(data[idx].x),
        yPixel: this.getOffsetY(i + 2) + this.yScale(data[idx].y)
      };
    });
    if (focused) {
      this.setState({ zLineChartData: _.sortBy(coordinates.map((data, i) => ({
        y: data.y,
        x: new Date(this.data[i].timestamp)
      })), item => item.x) });

      d3.selectAll('.focused.zLine-connect').remove();
      d3.select('.zLines')
        .append('g')
        .attr('class', 'zLine-connect')
        .classed('focused', true)
        .append('path')
        .attr('d', this.drawZConnectingLine(coordinates));
    } else {
      d3.selectAll('.selection.zLine-connect').remove();
      d3.select('.zLines')
        .append('g')
        .attr('class', 'selection')
        .classed('zLine-connect', true)
        .append('path')
        .attr('d', this.drawZConnectingLine(coordinates));
    }
  }

  drawZLines = (update = true) => {
    this.zLines = d3.select('.zLines')
      .selectAll('g.zLine')
      .data(this.bottomTicks);
    this.zLines
      .enter()
      .append('g')
      .attr('class', (d, i) => `zLine-${i}`)
      .classed('zLine', true)
      .attr('transform', d => this.translate(this.xScale(d), CONSTANTS.LINE_HEIGHT))
      .append('path')
      .attr('d', this.drawZLine(this.zLineCoordinates));
    this.zLines
      .attr('transform', d => this.translate(this.xScale(d), CONSTANTS.LINE_HEIGHT))
      .attr('d', this.drawZLine(this.zLineCoordinates));
    this.zLines.exit().remove();
    // Draw last line
    if (!update) {
      this.focusChart
        .append('g')
        .attr('class', 'zLine-last')
        .classed('zLine', true)
        .attr('transform', this.translate(this.focusChartWidth, CONSTANTS.LINE_HEIGHT))
        .append('path')
        .attr('d', this.drawZLine(this.zLineCoordinates));
    }
  }

  getRangeOfKey = (data, key) => {
    const min = data.reduce((min, p) => p[key] < min ? p[key] : min, data[0][key]);
    const max = data.reduce((max, p) => p[key] > max ? p[key] : max, data[0][key]);
    return [min, max];
  }

  initFocusChart = () => {
    const waterfallContainer = document.getElementById('waterfall_container');
    if (!waterfallContainer) return;
    const [windowHeight, windowWidth] = [window.innerHeight, waterfallContainer.clientWidth];
    this.svgHeight = windowHeight / 1.25;
    this.svgWidth = windowWidth;
    d3.select(this.chart)
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight);
    d3.select('.paint_focus_chart').remove();
    this.focusChart = d3.select('.main_waterfall_chart')
      .append('g')
      .classed('paint_focus_chart', true)
      .attr('transform', this.translate(CONSTANTS.MARGIN_FOCUS_CHART.LEFT, CONSTANTS.MARGIN_FOCUS_CHART.TOP));
    this.focusChartWidth = this.svgWidth - this.offsetXByNoOfSpectra(this.data.length) - CONSTANTS.MARGIN_FOCUS_CHART.LEFT - CONSTANTS.MARGIN_FOCUS_CHART.RIGHT;
    this.focusChartHeight = this.svgHeight - CONSTANTS.MARGIN_FOCUS_CHART.TOP - CONSTANTS.MARGIN_FOCUS_CHART.BOTTOM;
    this.freqRange = d3.extent([].concat(...this.data.map(sp => d3.values(this.getRangeOfKey(sp.spectrum_data, 'x')))));
    this.ampRange = d3.extent([].concat(...this.data.map(sp => d3.values(this.getRangeOfKey(sp.spectrum_data, 'y')))));
    this.xScale = d3.scaleLinear().domain(this.freqRange).range([0, this.focusChartWidth]);
    this.yScale = d3.scaleLinear().domain(this.ampRange).range([CONSTANTS.LINE_HEIGHT, 0]);
    this.axisTop = d3
      .axisTop(this.xScale)
      .ticks(CONSTANTS.NO_OF_TICKS_X)
      .tickSize(CONSTANTS.LINE_HEIGHT)
      .tickFormat('');
    this.axisLeft = d3.axisLeft(this.yScale)
      .ticks(CONSTANTS.NO_OF_TICKS_Y)
      .tickSize(10);
    this.axisRight = d3.axisRight(this.yScale)
      .ticks(CONSTANTS.NO_OF_TICKS_Y)
      .tickSize(this.focusChartWidth)
      .tickFormat('');
    this.axisBottom = d3.axisBottom(this.xScale)
      .ticks(CONSTANTS.NO_OF_TICKS_X);
    this.setBottomTicks();
    this.leftTicks = this.axisRight.scale().ticks(CONSTANTS.NO_OF_TICKS_Y);
    this.zLineCoordinates = this.getZLineCoordinates();
    this.line = d3.line()
      .curve(d3.curveLinear)
      .x(d => this.xScale(d.x))
      .y(d => this.yScale(d.y));
    this.drawTitle();
    if (this.showBaselineMark) this.drawBaselineMark();
    this.focusChart
      .append('g')
      .classed('zLines', true);
    this.drawZLine = d3.line()
      .curve(d3.curveLinear)
      .x(d => d.x)
      .y(d => d.y);
    this.drawZConnectingLine = d3.line()
      .curve(d3.curveLinear)
      .x(d => d.xPixel)
      .y(d => d.yPixel);
  }

  initContextChart = () => {
    this.contextChartWidth = this.svgWidth - this.offsetXByNoOfSpectra(this.data.length) - CONSTANTS.MARGIN_CONTEXT_CHART.LEFT - CONSTANTS.MARGIN_CONTEXT_CHART.RIGHT;
    this.xContextScale = d3.scaleLinear().domain(this.freqRange).range([0, this.contextChartWidth]);
    this.contextAxisBottom = d3.axisBottom(this.xContextScale)
      .ticks(CONSTANTS.NO_OF_TICKS_X)
      .tickFormat('')
      .tickSize(CONSTANTS.CONTEXT_CHART_HEIGHT);
    this.contextAxisTop = d3.axisTop(this.xContextScale)
      .ticks(CONSTANTS.NO_OF_TICKS_X);
    d3.select('.paint_context_chart').remove();
    this.contextChart = d3.select('.main_waterfall_chart')
      .append('g')
      .classed('paint_context_chart', true)
      .attr('transform', this.translate(this.getOffsetX(this.data.length + CONSTANTS.OFFSET_Z_LINE) + CONSTANTS.MARGIN_CONTEXT_CHART.LEFT, this.getOffsetY(this.data.length + CONSTANTS.OFFSET_Z_LINE) + CONSTANTS.MARGIN_CONTEXT_CHART.TOP));
    this.brush = d3.brushX()
      .extent([[0, 0], [this.contextChartWidth, CONSTANTS.CONTEXT_CHART_HEIGHT]])
      .on('brush', this.brushed)
      .on('end', this.brushEnded);

    this.contextChart.append('g')
      .attr('class', 'brush tick-hidden axis ticks-bottom')
      .call(this.contextAxisBottom);
    this.contextChart.append('g')
      .attr('class', 'brush tick-hidden axis ticks-top')
      .attr('transform', this.translate(0, CONSTANTS.CONTEXT_CHART_HEIGHT))
      .call(this.contextAxisTop);
    this.contextChart.append('g')
      .attr('class', 'brush')
      .call(this.brush)
      .call(this.brush.move, null);
  }

  offsetXByNoOfSpectra = len => (
    CONSTANTS.OFFSET_X +
    len * CONSTANTS.OFFSET_X_FACTOR.LEN -
    (CONSTANTS.PERS_SLIDER.X.MAX - this.state.perspectiveX) * CONSTANTS.OFFSET_X_FACTOR.PERS_X
  );

  render() {
    return (
      <>
        <WaterfallContainer id="waterfall_container">
          <svg ref={(node) => { this.chart = node; }} className="main_waterfall_chart" />
          {this.props.showPerspectiveChanger && (
            <div className="slider-container">
              <InputField
                type="range"
                min={CONSTANTS.PERS_SLIDER.X.MIN}
                max={CONSTANTS.PERS_SLIDER.X.MAX}
                value={this.state.perspectiveX}
                onChange={e => this.setState({ perspectiveX: e.target.value })}
                prefixLabel="X"
                marginBottom="0"
              />
              <InputField
                type="range"
                min={CONSTANTS.PERS_SLIDER.Y.MIN}
                max={CONSTANTS.PERS_SLIDER.Y.MAX}
                value={this.state.perspectiveY}
                className="vertical-slider"
                onChange={e => this.setState({ perspectiveY: e.target.value })}
                prefixLabel="Y"
                marginBottom="0"
              />
            </div>
          )}
        </WaterfallContainer>
        {this.props.showAmplitudeTagChart && this.state.doubleClickPoint && (
          <TagChartContainer>
            <TagChart
              type="trend"
              title={`Frequency: ${this.state.doubleClickPoint.x} ${this.props.xUnit}`}
              chartName="waterfall-amplitude-vs-timestamp"
              data={this.state.zLineChartData}
              expanded
              yUnit={this.props.yUnit}
              yTitle="Amplitude"
              xIsDate
              height="100px"
              color={colors.red}
              contextHeight="25px"
            />
          </TagChartContainer>
        )}
      </>
    );
  }
}

WaterfallChart.propTypes = {
  spectrums: PropTypes.array.isRequired,
  xUnit: PropTypes.string.isRequired,
  yUnit: PropTypes.string.isRequired,
  yLabel: PropTypes.string.isRequired,
  excludedSpectrums: PropTypes.array,
  showPerspectiveChanger: PropTypes.bool,
  showAmplitudeTagChart: PropTypes.bool
};

WaterfallChart.defaultProps = {
  excludedSpectrums: [],
  showPerspectiveChanger: false,
  showAmplitudeTagChart: false
};

export default WaterfallChart;
