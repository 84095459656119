import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';

import { FlexContainer, Loading } from 'common/components/atoms';
import InputField from 'common/components/atoms/InputField';
import { DropdownSelect } from 'common/components/molecules';
import { getDropdownOptions } from 'common/utils';
import { humanize } from 'common/helpers';
import InputEditOnDemand from 'common/components/molecules/InputEditOnDemand';
import { getMachineBuilderMetadata } from 'home/Machines/MachineBuilder/actions/machineBuilder.actions';
import * as repairActions from '../actions/repairs.actions';
import ListContainer from './atoms/ListContainer';
import Footer from './atoms/Footer';
import Attachments from '../organisms/Attachments';


const DateField = styled(InputField)`
  .input-holder {
    width: 100%;
  }
`;


function RepairDetails(props) {
  const onRepairSave = () => {
    const params = {
      name,
      state,
      description,
      machine_id: props.machineId || props.machine_id
    };

    if (!_.isEmpty(scheduled_at)) params.scheduled_at = scheduled_at;
    if (props.taskId) params.task_id = props.taskId;

    setShowSaveLoader(true);
    props.repairActions.addRepair(params).then(
      () => {
        if (_.isEmpty(props.files)) {
          setShowSaveLoader(false);
          if (!props.taskId) props.closeModal();
          else props.toggleExpand();
        }
      },
      () => {
        setShowSaveLoader(false);
      }
    );
  };

  const onRepairUpdate = (field, value) => {
    const params = {};
    params[field] = value;
    props.repairActions.updateRepair(props.repairDetails.id, params);
  };

  const onDropdownChange = (field, value) => {
    switch (field) {
      case 'state':
        setRepairState(value);
        break;
      case 'scheduled_at':
        setScheduledAt(value);
        break;
      default:
        break;
    }
    if (props.isEditMode && props.repairDetails[field] !== value) {
      onRepairUpdate(field, value);
    }
  };

  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [state, setRepairState] = useState('open');
  const [createDisabled, setCreateDisabled] = useState(true);
  const [scheduled_at, setScheduledAt] = useState('');
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [attachmentsError, setAttachmentsError] = useState(false);

  useEffect(() => {
    if (!props.repairMetadata) {
      props.getMachineBuilderMetadata();
    }

    if (props.match && props.match.params.repair_id) {
      props.repairActions.getRepairDetails(parseInt(props.match.params.repair_id, 10));
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(name) || _.isEmpty(description)) setCreateDisabled(true);
    else setCreateDisabled(false);
  }, [name, description]);

  useEffect(() => {
    if (props.isEditMode) {
      setName(props.repairDetails.name);
      setRepairState(props.repairDetails.state);
      setDescription(props.repairDetails.description);
      setScheduledAt(
        props.repairDetails.scheduled_at && props.repairDetails.scheduled_at !== '' ?
          moment(props.repairDetails.scheduled_at).format('YYYY-MM-DD') :
          ''
      );
    }
  }, [props.repairDetails]);

  useEffect(() => {
    // checks if upload has been completed and closes the modal
    const completed = props.upload && props.upload.index === props.files.length - 1 && props.upload.progress >= 100;
    if (!props.isEditMode && completed) {
      setShowSaveLoader(false);
      if (!props.taskId) props.closeModal();
      else props.toggleExpand();
    }
  }, [props.upload]);

  return (
    <>
      <FlexContainer position="relative">
        <ListContainer
          width="70%"
        >
          <InputEditOnDemand
            inputType="title"
            type="text"
            placeholder="Repair name"
            id="repair-name"
            value={name}
            onChange={e => setName(e.target.value)}
            onSave={value => onRepairUpdate('name', value)}
            isSaveDisabled={name === props.repairDetails.name}
            creationMode={!props.isEditMode}
          />
          <InputEditOnDemand
            inputType="text"
            type="textarea"
            placeholder="Description"
            height="100px"
            label="Description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            onSave={value => onRepairUpdate('description', value)}
            isSaveDisabled={description === props.repairDetails.description}
            creationMode={!props.isEditMode}
          />
          <Attachments
            originType="repair"
            originId={props.repairDetails.id}
            attachments={props.repairDetails.attachments}
            allowUpload={props.isEditMode}
            showExistingAtts={props.isEditMode}
            showSelectedFiles={!props.isEditMode}
            setAttachmentsError={setAttachmentsError}
          />
        </ListContainer>
        <ListContainer
          minWidth="16rem"
          width="30%"
          borderLeft
          borderRadius="0 0 5px 0"
          background
        >
          <DropdownSelect
            onClickItem={(txt, value, parTxt) => onDropdownChange('state', value)}
            placeholder="State"
            data={props.repairMetadata ? getDropdownOptions(props.repairMetadata.states) : []}
            value={humanize(state)}
            heightLimit
          />
          <DateField
            classNameProp="input-holder"
            type="date"
            label="Scheduled Date"
            name="repair_schedule_date"
            value={scheduled_at}
            onChange={e => onDropdownChange('scheduled_at', e.target.value)}
          />
        </ListContainer>
        {(props.repairDetails.loading || showSaveLoader) && <Loading position="absolute" height="100%" backgroundColor="#e4e4e447" />}
      </FlexContainer>
      {!props.isEditMode && (
        <Footer
          showBorderTop
          showCancel={props.showCancel}
          onCancel={props.closeModal}
          onSave={onRepairSave}
          saveDisabled={createDisabled || showSaveLoader || attachmentsError}
        />
      )}
    </>
  );
}

const mapStateToProps = state => ({
  repairMetadata: state.machines.machineBuilder.metadata.repair_metadata,
  machineId: state.machineDetails.details.object && state.machineDetails.details.object.id,
  repairDetails: state.repairModal.openRepairDetails,
  files: state.attachments.files && state.attachments.files.repair,
  upload: state.attachments.upload && state.attachments.upload.repair
});

const mapDispatchToProps = dispatch => ({
  getMachineBuilderMetadata: bindActionCreators(getMachineBuilderMetadata, dispatch),
  repairActions: bindActionCreators(repairActions, dispatch),
});

RepairDetails.propTypes = {
  toggleExpand: PropTypes.func,
  machine_id: PropTypes.number,
  taskId: PropTypes.number,
  isEditMode: PropTypes.bool,
  closeModal: PropTypes.func,
  showCancel: PropTypes.bool
};

RepairDetails.defaultProps = {
  toggleExpand: () => {},
  machine_id: null,
  taskId: null,
  isEditMode: false,
  closeModal: () => {},
  showCancel: true
};


export default connect(mapStateToProps, mapDispatchToProps)(RepairDetails);
