import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Checkbox, FlexContainer } from 'common/components/atoms';
import { humanize } from 'common/helpers';
import colors from 'common/styles/colors';
import Label from 'common/typography/Label/Label';

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.greyD};
  margin-bottom: 1rem;
  ${Label} {
    margin: 0;
  }
`;

const TriggersContainer = styled(FlexContainer)`
  .trigger-item {
    margin-left: 6px;
  }
  .trigger-container {
    margin: 0 0 10px 0;
    width: 100%;
    background-color: ${colors.greyXXXL};
    padding: 8px;
  }
`;

function TaskTriggers({
  allowedTriggers,
  selectedTriggers,
  toggleTrigger,
  toggleEvent,
  eventsDetails,
  isEditMode
}) {
  return (
    <div style={{ marginTop: '1rem', marginBottom: '2rem' }}>
      <LabelContainer>
        <Label>Triggers</Label>
      </LabelContainer>
      <TriggersContainer className="triggers-container">
        {Object.keys(allowedTriggers).map((trigger) => {
          const selected = selectedTriggers[trigger] && selectedTriggers[trigger].selected;
          return (
            <FlexContainer className="trigger-container" direction="column">
              <FlexContainer alignItems="center">
                <Checkbox minus={selected} value={selected} onClick={() => toggleTrigger(trigger)} />
                <span className="trigger-item">{humanize(trigger)}</span>
              </FlexContainer>
                {selected && trigger === 'alarms' && (
                  <>
                    {!isEditMode && (
                      !_.isEmpty(eventsDetails) && (
                        <FlexContainer direction="column" fontSize="12px" padding="8px">
                          {eventsDetails.map((detail, idx) => {
                            const eventSelected = selectedTriggers[trigger] &&
                              selectedTriggers[trigger].resources_info &&
                              selectedTriggers[trigger].resources_info.find(res => res.activity_id === detail.activity_id);
                            return (
                              <FlexContainer key={idx} alignItems="center" margin="4px 0 0 4px">
                                <Checkbox value={eventSelected} onClick={() => toggleEvent(trigger, detail)} />
                                <span className="trigger-item">{detail.description}</span>
                              </FlexContainer>
                            );
                          })}
                        </FlexContainer>
                      )
                    )}
                    {isEditMode && !_.isEmpty(allowedTriggers) && (
                      <FlexContainer direction="column" fontSize="12px" padding="8px">
                        {allowedTriggers[trigger].resources_info.map((detail, idx) => {
                          const eventSelected = selectedTriggers[trigger] &&
                            selectedTriggers[trigger].resources_info &&
                            selectedTriggers[trigger].resources_info.find(res => res.activity_id === detail.activity_id);
                          return (
                            <FlexContainer key={idx} alignItems="center" margin="4px 0 0 4px">
                              <Checkbox value={eventSelected} onClick={() => toggleEvent(trigger, detail)} />
                              <span className="trigger-item">
                                {detail.urlInfo ? (
                                  <Link to={{ pathname: detail.urlInfo.pathname, search: detail.urlInfo.searchParams }} target="_blank">
                                    {detail.description}
                                  </Link>
                                ) : (
                                  detail.description
                                )}
                              </span>
                            </FlexContainer>
                          );
                        })}
                      </FlexContainer>
                    )}
                  </>
                )}
            </FlexContainer>
          );
        })}
      </TriggersContainer>
    </div>
  );
}

export default TaskTriggers;
