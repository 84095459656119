export const picturesConstants = {
  GET_MACHINE_PICTURES_REQUEST: 'GET_MACHINE_PICTURES_REQUEST',
  GET_MACHINE_PICTURES_SUCCESS: 'GET_MACHINE_PICTURES_SUCCESS',
  GET_MACHINE_PICTURES_FAILURE: 'GET_MACHINE_PICTURES_FAILURE',

  PUT_PICTURE_LABEL_REQUEST: 'PUT_PICTURE_LABEL_REQUEST',
  PUT_PICTURE_LABEL_SUCCESS: 'PUT_PICTURE_LABEL_SUCCESS',
  PUT_PICTURE_LABEL_FAILURE: 'PUT_PICTURE_LABEL_FAILURE',

  ROTATE_PICTURE_REQUEST: 'ROTATE_PICTURE_REQUEST',
  ROTATE_PICTURE_SUCCESS: 'ROTATE_PICTURE_SUCCESS',
  ROTATE_PICTURE_FAILURE: 'ROTATE_PICTURE_FAILURE',

  DELETE_PICTURES_FAILURE: 'DELETE_PICTURES_FAILURE',
  APPEND_TO_FILE_STACK: 'APPEND_TO_FILE_STACK',
  CHANGE_UPLOAD_PROGRESS: 'CHANGE_UPLOAD_PROGRESS',
  FILE_TYPES: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
  TOGGLE_PROGRESS: 'TOGGLE_PROGRESS',
};
