import { userConstants } from '../user.constants';

export function twoFactorAuthState(state = {}, action) {
  switch (action.type) {
    case userConstants.VERIFY_OTP_REQUEST || userConstants.RESEND_OTP_REQUEST:
      return {
        loading: true,
      };
    case userConstants.VERIFY_OTP_SUCCESS:
      return {
        verify: true,
        trust: action.trustDevice
      };
    case userConstants.VERIFY_OTP_FAILURE:
      return {
        verify: false,
      };
    case userConstants.RESEND_OTP_SUCCESS:
      return {
        resend: true,
      };
    case userConstants.RESEND_OTP_FAILURE:
      return {
        resend: false,
      };
    case userConstants.RESET_OTP_STATE:
      return {};
    default:
      return state;
  }
}
