import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import * as _ from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';

import Label_T from 'common/typography/Label/Label';
import * as assetDetailsActions from 'home/AssetHierarchy/actions/assetDetails.actions';
import * as utils from 'common/utils';

import AlertPrompt from 'common/components/organisms/AlertPrompt';
import * as hierarchyUpdateActions from 'home/AssetHierarchy/actions/hierarchy.actions';
import DeleteIconSvg from 'common/images/BearingModal/DeleteIconSvg';
import { getTagAmplitudeTypes } from 'common/components/Chart/utils/helpers';
import FlexContainer from '../../atoms/FlexContainer';
import { mapNames, mapFeatureType, measurementOptions, spectraOptions, getBinFilterOptions } from '../../../charts/constants';
import OutsideAlerter from '../../../OutsideAlerter';
import MoreIconSvg from '../../../images/MoreIconSvg';
import RefreshSvg from '../../../images/RefreshSvg';
import DownloadSvg from '../../../images/DownloadSvg';
import AddNoteSvg from '../../../images/NoteSvg';
import SpeedFilterSvg from '../../../images/SpeedFilterSvg';
import EditIcon from '../../../images/BearingModal/EditIcon';
import SplitIconSvg from '../../../images/SplitIconSvg';
import {
  ToggleOff as FaToggleOff,
  ToggleOn as FaToggleOn,
} from '../../../images/FaIcons';
import FilterItem from '../../atoms/FilterItem';
import Filter_T from '../../molecules/Filter';
import ChartHeaderRowContainer from '../atoms/HeaderRowContainer';
import InputField from '../atoms/InputField';
import SpeedFilter from '../organisms/SpeedFilter';
import H4_T from '../../../typography/H4/H4';
import CalendarFilter from './CalendarFilter';
import { Checkbox } from '../../atoms';


const Header = styled(Label_T)`
  color: grey;
  padding: 0.5em 0 0 0.7em;
`;

const OptionsContainer = styled(FlexContainer)`
  justify-content: space-between;
  height: 44px;
  border-bottom: 1px solid #EFF0EE;
  box-sizing: border-box;
  padding-right: 25px;
`;

const BtnContainer = styled.div`
  display: flex;
  outline: none;
  border: none;
  padding: 0 0.3em;
  margin: 0 0.3em;
  z-index: 1;
  height: inherit;
  align-items: center;
  cursor: pointer;
  & * {
    cursor: pointer;
  }
  ${MoreIconSvg} {
    transform: rotate(90deg) translateX(6px);

  }
  ${props => props.disabled && `
    cursor: not-allowed;
    & * {
      cursor: not-allowed;
    }
  `}
`;

const Filter = styled(Filter_T)`
  height: 20px;
  border-right: 1px solid grey;
  padding-right: 1em;
  z-index: 3;
  width: ${props => props.setWidth};
`;

const BinFilter = styled(Filter_T)`
  height: 20px;
  z-index: 3;
  width: ${props => props.setWidth};
`;

const BorderContainer = styled.div`
  height: 20px;
  border-right: 1px solid grey;
`;

const SpeedFilterCont = styled.div`
  cursor: pointer;
  padding: 0 0.3em;
  margin: 0 0.3em;
  border-right: 1px solid grey;
  padding-right: 0.7em;
`;

const Label = styled(Label_T)`
  ${props => props.padding && `padding: ${props.padding}`};
  ${props => props.active && `color: ${props.theme.primaryColor}`};
  ${props => props.disabled && `color: ${props.theme.colors.greyD}`};
`;

const H4 = styled(H4_T)`
  color: ${props => props.active ? props.theme.primaryColor : props.theme.colors.black};
`;

const ToggleOn = styled(FaToggleOn)`
  color: ${props => props.theme.primaryColor};
  font-size: 1.5em;
  cursor: pointer;
  ${props => props.disabled && `
    cursor: not-allowed;
    & * {
      cursor: not-allowed;
    }
  `}
`;

const ToggleOff = styled(FaToggleOff)`
  color: ${props => props.theme.colors.greyD};
  font-size: 1.5em;
  cursor: pointer;
  ${props => props.disabled && `
    cursor: not-allowed;
    & * {
      cursor: not-allowed;
    }
  `}
`;

const ListOption = styled.span`
  margin-left: 10px;
  color: ${props => props.selected ? props.theme.colors.black : props.theme.colors.greyXD};
  white-space: pre;
`;

const SpectraInputField = styled(InputField)`
  margin-right: 0.7em;
  .ui.fluid.selection.dropdown {
    padding: .5em 2.1em .5em 1em;
    min-height: 2em;
  }
  .icon {
    padding: 0.6em !important;
  }
`;


const getSvg = (icon, selected, theme) => {
  const fill = selected ? theme.primaryColor : '#3C3D3A';
  switch (icon) {
    case 'refresh':
      return <RefreshSvg fill={fill} />;
    case 'download':
      return <DownloadSvg fill={fill} />;
    case 'addNote':
      return <AddNoteSvg fill={fill} />;
    case 'speedFilter':
      return <SpeedFilterSvg fill={fill} />;
    case 'editChart':
      return <EditIcon fill={fill} />;
    default:
      return null;
  }
};

class ChartOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ampOptionsExpanded: false,
      measurementOptionsExpanded: false,
      featureOptionsExpanded: false,
      binFilterExpanded: false,
      speedFilterExpanded: false,
      deleteTagModal: false,
      tagToDelete: {},
      showOutliers: !!assetDetailsActions.getChartSettingsFromSession().showOutliers,
      tagFeatures: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props
      && this.props.tag
      && this.props.tag.features
      && !_.isEqual(prevProps, this.props)
    ) this.updateTagFeatures();
  }

  updateTagFeatures = () => {
    if (this.props.tag.features.length > 0) {
      let features = this.props.tag.features;

      if (this.props.tag.spectrum_features && this.props.tag.spectrum_features.length > 0) {
        const spectrum_features = this.props.tag.spectrum_features.filter(feature => !['demod_1', 'demod_2'].includes(feature));
        features = [...this.props.tag.features, ...spectrum_features];
      }

      const broadbandFeatures = [];
      const narrowbandFeatures = [];

      features.forEach((feature) => {
        if (mapFeatureType[feature] === 'Broadband') {
          broadbandFeatures.push({
            text: mapNames[feature],
            value: feature
          });
        } else {
          narrowbandFeatures.push({
            text: mapNames[feature] || utils.CapitalizeOnlyFirstLetter(feature),
            value: feature
          });
        }
      });

      const tagFeatures = [];
      if (broadbandFeatures.length) tagFeatures.push({ header: 'Broadband', children: broadbandFeatures });
      if (narrowbandFeatures.length) tagFeatures.push({ header: 'Narrowband', children: narrowbandFeatures });
      this.setState({ tagFeatures });
    }
  }

  selectDeleteInactiveTag = (tag_id) => {
    this.setState({
      deleteTagModal: true,
      tagToDelete: { tag_id }
    });
  }

  deleteTag = (tag) => {
    this.setState({
      deleteTagModal: false,
      tagToDelete: {}
    });

    this.props.hierarchyUpdateActions.deleteTags({ tag_id: tag.tag_id, account_id: this.props.currentUser.account_id }).then(
      (res) => {
        toastr.success(res.message);
      },
      (error) => {
        toastr.error(error.message);
      }
    );
  }

  toggleShowOutliers = () => {
    this.setState(prevState => ({
      showOutliers: !prevState.showOutliers
    }));
    assetDetailsActions.toggleShowSettingInSession('showOutliers');
    this.props.refreshChart();
  }

  toggleAmpOptionsExpand = () => {
    this.setState(prevState => ({
      ampOptionsExpanded: !prevState.ampOptionsExpanded
    }));
  }

  ampOptionSelected = (amp_type) => {
    this.setState({ ampOptionsExpanded: false });
    this.props.getChartData({ amp_type });
  }

  toggleMeasurementOptionsExpand = () => {
    this.setState(prevState => ({
      measurementOptionsExpanded: !prevState.measurementOptionsExpanded
    }));
  }

  measurementOptionSelected = (measurement_type) => {
    this.setState({ measurementOptionsExpanded: false });
    this.props.getChartData({ measurement_type });
  }

  toggleFeatureOptionsExpand = () => {
    this.setState(prevState => ({
      featureOptionsExpanded: !prevState.featureOptionsExpanded
    }));
  }

  featureSelected = (feature) => {
    this.setState({ featureOptionsExpanded: false });
    this.props.getChartData({ feature });
  }

  toggleSpeedFilter = () => {
    this.setState(prevState => ({
      speedFilterExpanded: !prevState.speedFilterExpanded
    }));
  }

  onSaveSpeedFilter = (filterMin, filterMax) => {
    this.setState({ speedFilterExpanded: false });
    this.props.setSpeedFilterValues(filterMin, filterMax);
  }

  toggleBinFilter = () => {
    this.setState(prevState => ({
      binFilterExpanded: !prevState.binFilterExpanded
    }));
  }

  speedBinSelected = (bin_no, selected) => {
    let bin_nos = [];
    if (selected) {
      bin_nos = _.filter(
        this.props.bin_nos,
        b => b !== bin_no
      );
    } else {
      bin_nos = [
        ...this.props.bin_nos,
        bin_no
      ];
    }
    this.props.getChartData({ bin_nos });
  }

  getBinFilterText = () => {
    const { bin_nos } = this.props;
    if (bin_nos.length === 0) return '';
    let binFilterText = 'Speed Ranges ';
    bin_nos.forEach((bin_no, idx) => {
      if (idx) binFilterText += ',';
      binFilterText += bin_no;
    });
    return binFilterText;
  }

  render() {
    const {
      bin_nos,
      calendarSelect,
      calendarExpand,
      calendarExpanded,
      customDayFrom,
      customDayTo,
      selection,
      menuItems,
      viewItems,
      defaultChart,
      measurement_type,
      number_of_spectrums,
      handleSpectraChange,
      speedUnits,
      chartType,
      days,
      tag,
      amp_type,
      filterMin,
      filterMax,
      disableFilterOptions,
      disableBinFilter,
      showBaseline,
      loadingWaterfall,
      theme
    } = this.props;
    const {
      ampOptionsExpanded,
      binFilterExpanded,
      measurementOptionsExpanded,
      featureOptionsExpanded,
      speedFilterExpanded,
      deleteTagModal,
      tagToDelete
    } = this.state;

    const baselineButtonDisabled = tag && (!tag.baseline_set || loadingWaterfall);
    const measurementOptionValue = _.find(measurementOptions, option => option.value === measurement_type);

    return (
      <OptionsContainer>
        <ChartHeaderRowContainer>
          <FlexContainer
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            flexBasis={selection ? '33.33%' : '66.66%'}
          >
            <CalendarFilter
              calendarSelect={calendarSelect}
              calendarExpand={calendarExpand}
              calendarExpanded={calendarExpanded}
              disabled={disableFilterOptions}
              days={days}
              customDayFrom={customDayFrom}
              customDayTo={customDayTo}
            />
            {menuItems.map(item => (
              <BtnContainer
                onClick={() => {
                  if (!item.disabled) item.onClick();
                }}
                disabled={item.disabled}
              >
                {getSvg(item.text, item.active, theme)}
              </BtnContainer>
            ))}
            <BorderContainer />
            {defaultChart && tag && tag.structure_type === 'vector' && utils.isDemodSupported(tag.type) && (
              <Filter
                value={mapNames[amp_type]}
                open={ampOptionsExpanded}
                setWidth="max-content"
                toggleOpen={() => { if (!disableFilterOptions) this.toggleAmpOptionsExpand(); }}
                disabled={disableFilterOptions}
              >
                {_.map(getTagAmplitudeTypes(tag), amp_type => ({
                  text: mapNames[amp_type],
                  value: amp_type
                })).map((option, index) => {
                  const selected = option.value === amp_type;
                  return (
                    <FilterItem
                      key={index.toString()}
                      selected={selected}
                      onClick={() => this.ampOptionSelected(option.value)}
                    >
                      <span>{option.text}</span>
                    </FilterItem>
                  );
                })}
              </Filter>
            )}
            {defaultChart && tag && tag.structure_type === 'vector' && tag.features.length > 0 && chartType !== 'waterfall_spectrum' && this.state.tagFeatures && (
              <Filter
                value={mapNames[this.props.feature] || utils.CapitalizeOnlyFirstLetter(this.props.feature)}
                open={featureOptionsExpanded}
                disabled={disableFilterOptions}
                toggleOpen={() => { if (!disableFilterOptions) this.toggleFeatureOptionsExpand(); }}
                setWidth="max-content"
              >
                  {this.state.tagFeatures.map(feature => (
                        <>
                        <Header>{feature.header}</Header>
                        {feature.children.map(option => (
                            <FilterItem
                              key={option.text}
                              selected={option.text}
                              onClick={() => this.featureSelected(option.value)}
                            >
                            <span>{option.text}</span>
                            </FilterItem>
                        ))}
                      </>
                  ))}
              </Filter>
            )}
            {defaultChart && tag && ['vibration', 'current'].includes(tag.type) && (
              <Filter
                value={measurementOptionValue && measurementOptionValue.text}
                open={measurementOptionsExpanded}
                disabled={disableFilterOptions}
                toggleOpen={() => { if (!disableFilterOptions) this.toggleMeasurementOptionsExpand(); }}
                setWidth="max-content"
              >
                {measurementOptions.map((option, index) => {
                  const selected = option.value === measurement_type;
                  if (option.value === 0 && chartType !== 'trend') return null;
                  return (
                    <FilterItem
                      key={index.toString()}
                      selected={selected}
                      onClick={() => this.measurementOptionSelected(option.value)}
                    >
                      <span>{option.text}</span>
                    </FilterItem>
                  );
                })}
              </Filter>
            )}
            {speedUnits && defaultChart && tag && tag.type === 'vibration' && chartType === 'trend' && (
              <>
                <SpeedFilterCont onClick={() => this.toggleSpeedFilter()}>
                  {getSvg('speedFilter', speedFilterExpanded, theme)}
                </SpeedFilterCont>
                {speedFilterExpanded && (
                  <OutsideAlerter open handleClick={() => this.toggleSpeedFilter()}>
                    <SpeedFilter
                      onSave={this.onSaveSpeedFilter}
                      filterMin={filterMin}
                      filterMax={filterMax}
                      units={speedUnits}
                    />
                  </OutsideAlerter>)}
              </>
            )}
            {viewItems.map((item) => {
              if (!item.hidden) {
                return (
                  <BtnContainer
                    onClick={() => item.onClick()}
                  >
                    <span title={item.fullText}><H4 active={item.active}>{item.text}</H4></span>
                  </BtnContainer>
                );
              }
              return '';
            })
            }
            {_.some(viewItems.map(item => !item.hidden)) && (<BorderContainer />)}
            {this.props.toggleSplit && (
              <>
                {/* <BorderContainer /> */}
                <BtnContainer
                  onClick={this.props.toggleSplit}
                >
                  <span title="Split Chart"><SplitIconSvg active={this.props.split} primaryColor={theme.primaryColor} /></span>
                </BtnContainer>
                <BorderContainer />
              </>
            )}
            {chartType === 'waterfall_spectrum' && (
              <>
                <Label marginBottom="0px" padding="0 0.7em">Spectra</Label>
                <SpectraInputField
                  type="select"
                  options={spectraOptions}
                  value={number_of_spectrums}
                  onChange={handleSpectraChange}
                />
                <BorderContainer />
              </>
            )}
            {tag && (chartType === 'trend' || chartType === 'waterfall_spectrum') && (
              <>
                <BtnContainer
                  onClick={() => { if (!baselineButtonDisabled) this.props.toggleShowBaseline(); }}
                  disabled={baselineButtonDisabled}
                  title={!tag.baseline_set ? 'Baseline not set' : ''}
                >
                  <Checkbox value={showBaseline && tag.baseline_set} />
                  <Label
                    marginBottom="0px"
                    active={showBaseline}
                    padding="0 0 0 0.7em"
                    disabled={baselineButtonDisabled}
                  >
                    Baseline
                  </Label>
                </BtnContainer>
                <BorderContainer />
              </>
            )}
            <BtnContainer
              onClick={this.toggleShowOutliers}
            >
              {this.state.showOutliers ? (
                <span title="Outliers Shown">
                  <FlexContainer>
                    <Label marginBottom="0px" padding="0 0.5em 0 0"> Outliers </Label>
                    <ToggleOn />
                  </FlexContainer>
                </span>
              ) : (
                  <span title="Outliers Hidden">
                    <FlexContainer>
                      <Label marginBottom="0px" padding="0 0.5em 0 0"> Outliers </Label>
                      <ToggleOff />
                    </FlexContainer>
                  </span>
              )}
            </BtnContainer>
            {tag && tag.type === 'current' && (
              <>
                <BorderContainer />
                <BtnContainer
                  onClick={disableFilterOptions ? () => {} : () => this.props.getChartData({ log_scale: !this.props.logScale })}
                  disabled={disableFilterOptions}
                >
                  {this.props.logScale ? (
                    <span title="Log Scale Selected">
                      <FlexContainer>
                        <Label marginBottom="0px" padding="0 0.5em 0 0"> Log </Label>
                        <ToggleOn disabled={disableFilterOptions} />
                      </FlexContainer>
                    </span>
                  ) : (
                      <span title="Log Scale Deselected">
                        <FlexContainer>
                          <Label marginBottom="0px" padding="0 0.5em 0 0"> Log </Label>
                          <ToggleOff disabled={disableFilterOptions} />
                        </FlexContainer>
                      </span>
                  )}
                </BtnContainer>
              </>
            )}
            {defaultChart && tag && tag.active === false && (
              <>
              <BtnContainer
                onClick={() => this.selectDeleteInactiveTag(tag.id)}
              >
                {
                  <span title="Delete Tag">
                    <DeleteIconSvg />
                  </span>
                }
              </BtnContainer>
              </>
            )}
          </FlexContainer>
        </ChartHeaderRowContainer>
        <>
          {deleteTagModal && (
            <AlertPrompt
              message="Are you sure you want to delete this tag and all the charts associated with it?"
              secondaryMessage="This action can not be undone"
              infoMessage="Data from disassociated sensors are considered as inactive tags"
              onCancel={() => this.setState({
                deleteTagModal: false
              })}
              onProceed={() => this.deleteTag(tagToDelete)}
            />
          )}
          {defaultChart && tag && tag.type === 'vibration' && tag.no_of_bins && (
            <FlexContainer alignItems="center">
              <BinFilter
                title="Speed Range Filter:"
                value={this.getBinFilterText()}
                open={binFilterExpanded}
                disabled={disableBinFilter}
                toggleOpen={() => { if (!disableBinFilter) this.toggleBinFilter(); }}
                setWidth="max-content"
              >
                {getBinFilterOptions(tag.no_of_bins).map((option, index) => {
                  const selected = _.includes(bin_nos, option.value);
                  return (
                    <FilterItem
                      key={index.toString()}
                      selected={selected}
                      onClick={() => this.speedBinSelected(option.value, selected)}
                    >
                      <Checkbox value={selected} />
                      <ListOption selected={selected}>{option.text}</ListOption>
                    </FilterItem>
                  );
                })}
              </BinFilter>
            </FlexContainer>
          )}
        </>
      </OptionsContainer>
    );
  }
}

ChartOptions.propTypes = {
  calendarSelect: PropTypes.func,
  calendarExpand: PropTypes.func,
  calendarExpanded: PropTypes.bool,
  menuItems: PropTypes.array,
  disableBinFilter: PropTypes.bool,
  disableFilterOptions: PropTypes.bool,
  loadingWaterfall: PropTypes.bool
};

ChartOptions.defaultProps = {
  calendarSelect: () => {},
  calendarExpand: () => {},
  calendarExpanded: false,
  menuItems: [],
  disableBinFilter: false,
  disableFilterOptions: false,
  loadingWaterfall: false
};

const mapStateToProps = state => ({
  currentUser: state.user.user
});

const mapDispatchToProps = dispatch => ({
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch),
  hierarchyUpdateActions: bindActionCreators(hierarchyUpdateActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ChartOptions));
