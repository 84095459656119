import styled from 'styled-components';

import FlexContainer from '../atoms/FlexContainer';


const ModalContent = styled(FlexContainer).attrs({
  direction: 'column',
  flexGrow: 1,
  flexGap: props => props.flexGap
})`
  height: ${props => props.innerScroll ? 'inherit' : props.height};
  padding: ${props => props.padding || '0 1.6em'};
  ${props => props.block ? 'display: block;' : ''}
`;

ModalContent.displayName = 'ModalContainer';

export default styled(ModalContent)``;
