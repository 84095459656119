import React, { useState } from 'react';
import styled from 'styled-components';
import RibbonItem from 'common/components/atoms/RibbonItem';
import InlineModal from 'common/components/molecules/InlineModal';
import OutsideAlerter from 'common/OutsideAlerter';
import PlusSvg from 'common/images/PlusSvg';
import { FlexContainer } from 'common/components/atoms';
import Button from 'common/components/atoms/Button';
import { ListIcon } from 'common/images/FaIcons';
import RBAC from 'common/rbac/RBAC';
import { MACHINE_LEVEL_ADD_OPTIONS } from '../constants/constants';


const Container = styled(FlexContainer)`
  position: ${props => ('absolute')};
  z-index: ${props => props.zIndex ? props.zIndex : 1};
  top: ${props => (!props.isAnalyzeView ? '1.423em' : '1.7em')};
  right: ${props => (!props.isAnalyzeView ? '8em' : '12em')};
  ${Button} {
    margin-top: 1em;
    margin-right: 1em;
    margin-bottom: 1em;
  }
`;
const SummaryDropdown = ({ openModal, dropdownOptions, isAnalyzeView }) => {
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const containerZIndex = isAnalyzeView ? 998 : 999;

  return (
    <Container zIndex={containerZIndex} isAnalyzeView={isAnalyzeView}>
   { dropdownOptions && isAnalyzeView && (
      <div style={{ marginRight: '8px' }} onClick={() => setDropdownMenuOpen(prevState => !prevState)}>
      <ListIcon width={16} height={16} />
      </div>
   )}
   { !dropdownOptions && (
      <PlusSvg
        width={16}
        height={16}
        onClick={() => setDropdownMenuOpen(prevState => !prevState)}
        styled={{ padding: '10px' }}
      />
   )}
      {dropdownMenuOpen && (
        <InlineModal noOverlay minWidthInline="120px" marginTop="5px" noScroll close={() => setDropdownMenuOpen(false)}>
          <OutsideAlerter event="click" open handleClick={() => setDropdownMenuOpen(false)}>
            <div onClick={(e) => { setDropdownMenuOpen(false); e.stopPropagation(); }}>
              {dropdownOptions && (dropdownOptions.map((item, value) => (
                  <RibbonItem
                    key={value}
                    fontSize="12.5px"
                    width="max-content"
                    onClick={() => openModal(item.value)}
                  >
                    {item.text}
                  </RibbonItem>
              )))}
              {!dropdownOptions && MACHINE_LEVEL_ADD_OPTIONS.map((item, idx) => (
                <RBAC
                  resource={item.resource}
                  operation={item.operation}
                  yes={(
                    <RibbonItem
                      key={idx}
                      fontSize="14px"
                      width="max-content"
                      onClick={() => openModal(item.value)}
                    >
                      {item.text}
                    </RibbonItem>
                  )}
                />
              ))}
            </div>
          </OutsideAlerter>
        </InlineModal>
      )}
    </Container>
  );
};

export default SummaryDropdown;
