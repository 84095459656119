
import React, { lazy } from 'react';
import lazyRetry from 'common/helpers/componentLoader';
import Machines from '../../Machines/components/MachinesPage';
import ActivityTabHeader from '../components/ActivityTabHeader';

export const Routes = {
  createNewSensors: '/devices/new',
  admin: '/admin-dashboard',
  home: '/'
};

export const homeTabItems = [
  {
    path: '/',
    key: 'overview',
    title: 'Dashboards',
    component: lazy(() => lazyRetry(() => import('../../Dashboard')))
  },
  {
    path: '/activity',
    key: 'activity',
    title: (
        <ActivityTabHeader />
    ),
    component: lazy(() => lazyRetry(() => import('../../Activity/Activity'))),
  },
  {
    path: '/assets',
    key: 'assets',
    title: 'Assets',
    component: lazy(() => lazyRetry(() => import('../../Machines/components/MachinesPage')))
  },
  {
    path: '/templates',
    key: 'templates',
    title: 'Asset Templates',
    component: lazy(() => lazyRetry(() => import('../../Templates/components/pages/Templates')))
  },
  {
    path: '/devices',
    key: 'devices',
    title: 'Devices',
    component: lazy(() => lazyRetry(() => import('../../Sensors/components/Sensors')))
  },
  {
    path: '/event-definitions',
    key: 'event-definitions',
    title: 'Event Definitions',
    component: lazy(() => lazyRetry(() => import('../../Settings/EventDefinitions/DefinitionPage')))
  },
  {
    path: '/diagnostic-rules',
    key: 'diagnostic-rules',
    title: 'Diagnostic Rules',
    component: lazy(() => lazyRetry(() => import('../../MachineDiagnostics/RulesPage')))
  },
  {
    path: '/sensor-models',
    key: 'sensor-models',
    title: 'Sensor Models',
    component: lazy(() => lazyRetry(() => import('../../Settings/SensorModels/components/SensorModelPage')))
  },
  {
    path: '/automatic-alarms',
    key: 'automatic-alarms',
    title: 'Automatic Alarms',
    component: lazy(() => lazyRetry(() => import('home/AutomaticAlarms/components/pages/AutomaticAlarm')))
  },
  {
    path: '/reports',
    key: 'reports',
    title: 'Reports',
    component: lazy(() => lazyRetry(() => import('../../Reports/components/Reports')))
  },
  {
    path: '/instrumentation-alerts',
    key: 'instrumentation-alerts',
    title: 'Instrumentation Alerts',
    component: lazy(() => lazyRetry(() => import('../../InstrumentationAlerts/InstrumentationAlerts')))
  }
];

export const tabsOnlyForPetasenseAdmins = [
  'feeds',
];

export const tabsOnlyForAnalystsAndAdmins = [
  'audit-logs'
];

export const tabsOnlyForAnalysts = [
  'teams'
];

export const groupsExcludedForPartners = [
  'help'
];

export const accountMenuItems = [
  {
    key: 'settings',
    displayName: 'Settings',
    items: [
      {
        key: 'site-profile',
        displayName: 'Site',
        path: '/site-profile',
        component: lazy(() => lazyRetry(() => import('../../Settings/SiteProfile')))
      },
      {
        key: 'users',
        displayName: 'Users',
        path: '/users',
        component: lazy(() => lazyRetry(() => import('../../Settings/AccountUsers/AccountUsers')))
      },
      {
        key: 'teams',
        displayName: 'Teams',
        path: '/teams',
        component: lazy(() => lazyRetry(() => import('../../Settings/Teams/components/TeamsPage')))
      },
      {
        key: 'saas-agreement',
        displayName: 'SaaS Agreement',
        path: '/saas-agreement',
        component: lazy(() => lazyRetry(() => import('../../../onboarding/components/TermsPage')))
      },
      {
        key: 'third-party-tags',
        displayName: 'Third Party Tags',
        path: '/third-party-tags',
        component: lazy(() => lazyRetry(() => import('../../Settings/ThirdPartyTags/ThirdPartyTags')))
      },
      {
        key: 'third-party-integrations',
        displayName: 'Third Party Integrations',
        path: '/third-party-integrations',
        component: lazy(() => lazyRetry(() => import('../../Settings/ThirdPartyIntegrations/ThirdPartyIntegrations')))
      },
      {
        key: 'tag-templates',
        displayName: 'Tag Templates',
        path: '/tag-templates',
        component: lazy(() => lazyRetry(() => import('../../TagTemplates/TagTemplate')))
      },
      {
        key: 'task-templates',
        displayName: 'Maintenance Task Templates',
        path: '/task-templates',
        component: lazy(() => lazyRetry(() => import('../../TaskTemplates/TaskTemplates')))
      },
      {
        key: 'audit-logs',
        displayName: 'Audit Logs',
        path: '/audit-logs',
        component: lazy(() => lazyRetry(() => import('../../APIAuditLogs/APIAuditLogs')))
      },
    ]
  },
  {
    key: 'help',
    displayName: 'Help',
    items: [
      {
        key: 'customer-support-portal',
        displayName: 'Support',
        path: 'https://petasense.freshdesk.com/support/login',
        external: true
      },
    ]
  },
  {
    key: 'others',
    displayName: 'Others',
    items: [
      {
        key: 'devices-health',
        displayName: 'Devices Health',
        path: '/devices-health',
        component: lazy(() => lazyRetry(() => import('../../DevicesHealth/DevicesHealth')))
      },
    ]
  },
];
