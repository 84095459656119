import styled from 'styled-components';


const ColoredCircle = styled.div`
background-color: ${props => (props.color ? props.color : props.theme.colors.black)};
border-radius: 50%;
margin-right: 0.4em;
width: ${props => props.size || '0.33em'};
height: ${props => props.size || '0.33em'};
`;

export default ColoredCircle;
