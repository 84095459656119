import React, { useMemo } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FlexContainer } from 'common/components/atoms';
import { bindActionCreators } from 'redux';
import { splitOnLastDelimiter } from 'common/utils';
import { mapNames } from 'common/charts/constants';
import * as machineMLActions from '../actions/machineML.actions';
import FeatureChart from './FeatureChart';


const FeatureCharts = ({ features, tagType, componentId, component, startTime, endTime, tagIds }) => {
  const featureAmpTypeList = useMemo(() => {
    return features.map((name) => {
      const [feature, ampType] = splitOnLastDelimiter(name, '_');
      return [feature, ampType];
    });
  }, [features]);

  return (
    <FlexContainer flexWrap="wrap">
      {!_.isEmpty(featureAmpTypeList) && featureAmpTypeList.map((item, idx) => {
        const [feature, ampType] = item;
        const name = `${mapNames[feature]} ${mapNames[ampType]}`;

        return (
          <FeatureChart
            key={idx}
            feature={feature}
            ampType={ampType}
            tagType={tagType}
            componentId={componentId}
            featureDisplayName={name}
            component={component}
            startTime={startTime}
            endTime={endTime}
            tagIds={tagIds}
          />
        );
      })}
      {_.isEmpty(featureAmpTypeList) && tagType !== 'vibration' && (
        <FeatureChart
          tagType={tagType}
          componentId={componentId}
          component={component}
          startTime={startTime}
          endTime={endTime}
          tagIds={tagIds}
        />
      )}
    </FlexContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  machineMLActions: bindActionCreators(machineMLActions, dispatch)
});

export default connect(null, mapDispatchToProps)(FeatureCharts);
