import { arrayMove } from 'react-sortable-hoc';
import { machineDetailsConstants } from '../machineDetails.constants';
import { machineDetailsTagChartsReducer } from './machineDetailsTagCharts.reducer';

export function machineDetailsTags(state = {}, action) {
  const { tagId } = action;

  // Pass action to sub reducer if it is for tag charts
  if (action.type.startsWith('MACHINE_DETAILS_TAG_CHART/')) {
    if (state.object) {
      return {
        object: state.object.map((tagState) => {
          if (tagState.info.tag_id === tagId) {
            return {
              ...tagState,
              charts: machineDetailsTagChartsReducer(tagState.charts, action)
            };
          }
          return tagState;
        })
      };
    }
    return state;
  } else if (action.type === machineDetailsConstants.SWAP_MACHINE_DETAILS_CHARTS_TAGS) {
    if (state.object) {
      return {
        object: arrayMove(state.object, action.oldIndex, action.newIndex)
      };
    }
    return state;
  }

  switch (action.type) {
    case machineDetailsConstants.GET_MACHINE_DETAILS_CHARTS_TAGS_REQUEST:
      return {
        loading: true
      };
    case machineDetailsConstants.GET_MACHINE_DETAILS_CHARTS_TAGS_SUCCESS: {
      return {
        object: action.tags.map(tag => ({ info: tag }))
      };
    }
    case machineDetailsConstants.GET_MACHINE_DETAILS_CHARTS_TAGS_FAILURE:
      return {
        error: action.error.message
      };
    case machineDetailsConstants.CLEAR_MACHINE_DETAILS_TAGS:
      return {};

    default:
      return state;
  }
}
