import { userConstants } from '../user.constants';

export function timezones(state = {}, action) {
  switch (action.type) {
    case userConstants.TIMEZONE_REQUEST:
      return {
        fetching: true,
      };
    case userConstants.TIMEZONE_SUCCESS:
      return {
        timezones: action.timezones,
      };
    case userConstants.TIMEZONE_FAILURE:
      return {
        error: action.error.message
      };
    default:
      return state;
  }
}
