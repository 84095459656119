import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ListItem_T from 'common/components/atoms/ListItem';
import Checkbox from 'common/components/atoms/Checkbox';
import FlexContainer from 'common/components/atoms/FlexContainer';


const ListItem = styled(ListItem_T)`
  td {
    padding: 4px 20px 4px 0px;
    &:first-of-type {
      padding-left: 1.5rem;
    }
  }
`;

const CheckboxContainer = styled(FlexContainer)`
  margin-right: 0.5em
`;

class AffectedDeviceItem extends Component {
  render() {
    const { item, columnSizes, selectedDevices, toggleSelectedDevice } = this.props;
    const selected = selectedDevices.includes(item.id);
    return (
      <ListItem>
        <td width={`${columnSizes[0]}%`}>
          <FlexContainer direction="row" padding="1em 0">
            <CheckboxContainer>
              <Checkbox value={selected} onClick={() => toggleSelectedDevice(item.id)} />
            </CheckboxContainer>
            {item.serial_number}
          </FlexContainer>
        </td>
        <td width={`${columnSizes[1]}%`}>
          {item.account_name}
        </td>
        <td width={`${columnSizes[2]}%`}>
          {item.target_version}
        </td>
        <td width={`${columnSizes[3]}%`}>
          {item.state}
        </td>
        <td width={`${columnSizes[4]}%`}>
          {item.current_version}
        </td>
      </ListItem>
    );
  }
}

AffectedDeviceItem.propTypes = {
  item: PropTypes.object.isRequired,
  columnSizes: PropTypes.array.isRequired,
};

export default AffectedDeviceItem;
