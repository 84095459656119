import styled from 'styled-components';

const FilterInlineModal = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(60,61,58,0.24);
  min-width: 100px;
  min-height: 100px;
  z-index: 3;
  padding: 1em;
`;

export default styled(FilterInlineModal)``;
