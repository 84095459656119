import { userConstants } from '../user.constants';

export const rbacPermissions = (state = {}, action) => {
  switch (action.type) {
    case userConstants.GET_RBAC_PERMISSIONS_REQUEST:
      return {
        fetching: true,
      };
    case userConstants.GET_RBAC_PERMISSIONS_SUCCESS:
      return action.result;
    case userConstants.GET_RBAC_PERMISSIONS_FAILURE:
      return {
        error: action.error.message,
      };
    default:
      return state;
  }
};
