import React from 'react';
import PropTypes from 'prop-types';

const MinimizeSvg = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 16 16">
      <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="3-5-0-Machines-Expanded" transform="translate(-1158.000000, -203.000000)">
              <g id="charts" transform="translate(80.000000, 147.000000)">
                  <g id="1" transform="translate(0.000000, 36.000000)">
                      <g id="trend" transform="translate(0.000000, 16.000000)">
                          <g id="top" transform="translate(20.000000, 4.000000)">
                              <g id="options" transform="translate(1022.000000, 0.000000)">
                                  <g id="minimize_pressed-icon" transform="translate(36.000000, 0.000000)">
                                      <rect id="bounds" fill="#FFFFFF" x="0" y="0" width={width} height={height} />
                                      <g id="icon" transform="translate(1.000000, 1.000000)" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                          <path d="M3.88888889,0 L3.88888889,2.33333333 C3.88888889,3.19244294 3.19244294,3.88888889 2.33333333,3.88888889 L0,3.88888889 M14,3.88888889 L11.6666667,3.88888889 C10.8075571,3.88888889 10.1111111,3.19244294 10.1111111,2.33333333 L10.1111111,0 M10.1111111,14 L10.1111111,11.6666667 C10.1111111,10.8075571 10.8075571,10.1111111 11.6666667,10.1111111 L14,10.1111111 M0,10.1111111 L2.33333333,10.1111111 C3.19244294,10.1111111 3.88888889,10.8075571 3.88888889,11.6666667 L3.88888889,14" id="Shape" />
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);

MinimizeSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

MinimizeSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: '#000000'
};

export default MinimizeSvg;
