import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import CrossSvg from '../../images/CrossSvg';
import NavButton from './NavButton';
import SvgBtnT from './SvgBtn';
import FlexContainer from './FlexContainer';

const HeaderContainer = styled(FlexContainer).attrs({
  justifyContent: 'flex-end'
})``;

const TextContainer = styled(FlexContainer).attrs({
  direction: 'column',
})`
  margin-top: 26px;
`;

const ButtonContainer = styled(FlexContainer).attrs({
  justifyContent: 'center',
  flexGap: '15px'
})`
  margin-top: 23.5px;
`;

const SvgBtn = styled(SvgBtnT)``;

const Background = styled.div`
  background: rgba(56,56,56,0.96);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
`;

const ModalWindow = styled.div`
  background: white;
  border-radius: 8px;
  z-index: 1000;
  height: 180px;
  width: 600px;
  padding: 18px 18px 23.5px;
  margin: 80px auto 0;
`;


const InfoModal = ({
  close,
  accept,
  reject,
  lineOne,
  lineTwo,
  acceptText,
  deleting
}) => (
  <Background>
    <ModalWindow>
      <HeaderContainer>
        <SvgBtn onClick={close}>
          <CrossSvg height={21} width={21} />
        </SvgBtn>
      </HeaderContainer>
      <TextContainer>
        <span style={{ textAlign: 'center' }}>{lineOne}</span>
        <span style={{ textAlign: 'center' }}>{lineTwo}</span>
      </TextContainer>
      <ButtonContainer>
        <NavButton
          onClick={accept}
          borderButton="red"
          style={{
            width: '92px',
          }}
        >
          {deleting ? 'deleting' : acceptText}
        </NavButton>
        <NavButton
          onClick={reject}
          borderButton="black"
          style={{
            width: '92px',
          }}
        >
          Cancel
        </NavButton>
      </ButtonContainer>
    </ModalWindow>
  </Background>
);

InfoModal.propTypes = {
  close: propTypes.func.isRequired,
  accept: propTypes.func.isRequired,
  reject: propTypes.func.isRequired,
  lineOne: propTypes.string.isRequired,
  lineTwo: propTypes.string.isRequired,
  acceptText: propTypes.string
};

InfoModal.defaultProps = {
  acceptText: propTypes.string
};

export default InfoModal;
