import styled from 'styled-components';

import FlexContainer from 'common/components/atoms/FlexContainer';

const HeaderContainer = styled(FlexContainer)`
    direction: ${props => props.direction};
    height: ${props => props.height};
    border-bottom: ${props => props.border && '1px solid #EFF0EE'};
`;

export default styled(HeaderContainer)``;
