import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSvg = styled.svg`
    cursor: pointer;
    &:hover {
        #stroke-icon {
            stroke: #FF4F39;
        }
        cursor: pointer;
    }
`;

const Span = styled.span`
  &:hover {
    cursor: pointer;
  }
  padding: ${props => props.padding};
`;

const DeleteIconSvg = ({ height, width, stroke, padding, onClick }) => (
<Span onClick={onClick} padding={padding}>
  <StyledSvg width={width} stroke={stroke} height={height} viewBox="0 0 14 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="3-Machines" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="PSWAv.1.0.0-Machines_v1.8.6" transform="translate(-1021.000000, -212.000000)">
        <g id="bearings">
          <g id="card" transform="translate(204.000000, 24.000000)">
            <g id="list" transform="translate(24.000000, 128.000000)">
              <g id="row1" transform="translate(16.000000, 60.000000)">
                <g id={1.0}>
                  <g id="delete-icon" transform="translate(776.000000, 0.000000)">
                    <rect id="bounds" x={0} y={0} width={16} height={16} />
                    <g id="stroke-icon" transform="translate(2.000000, 1.000000)" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                      <polyline id="Shape" points="0 2.8 1.33333333 2.8 12 2.8" />
                      <path d="M10.6666667,2.8 L10.6666667,12.6 C10.6666667,13.3731986 10.069713,14 9.33333333,14 L2.66666667,14 C1.930287,14 1.33333333,13.3731986 1.33333333,12.6 L1.33333333,2.8 M3.33333333,2.8 L3.33333333,1.4 C3.33333333,0.62680135 3.930287,0 4.66666667,0 L7.33333333,0 C8.069713,0 8.66666667,0.62680135 8.66666667,1.4 L8.66666667,2.8" id="Shape" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </StyledSvg>
</Span>);

DeleteIconSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  padding: PropTypes.string,
  stroke: PropTypes.string,
  onClick: PropTypes.func
};

DeleteIconSvg.defaultProps = {
  width: 14,
  height: 16,
  padding: '0',
  stroke: '#999B95',
  onClick: () => {}
};

export default DeleteIconSvg;
