export const bearingConstants = {
  GET_BEARING_REQUEST: 'GET_BEARING_REQUEST',
  GET_BEARING_SUCCESS: 'GET_BEARING_SUCCESS',
  GET_BEARING_FAILURE: 'GET_BEARING_FAILURE',

  CLEAR_BEARING: 'CLEAR_BEARING',

  ADD_ROW_TO_TABLE: 'ADD_ROW_TO_TABLE',

  GET_ALL_MANUFACTURERS_SUCCESS: 'GET_ALL_MANUFACTURERS_SUCCESS',
  GET_ALL_MANUFACTURERS_FAILURE: 'GET_ALL_MANUFACTURERS_FAILURE',

  NEW_BEARING_ENTRY: 'NEW_BEARING_ENTRY',
  REMOVE_ROW_FROM_TABLE: 'REMOVE_ROW_FROM_TABLE',

  SET_BEARING_QUERY: 'SET_BEARING_QUERY',

  SET_BEARING_SORTER: 'SET_BEARING_SORTER',

  SELECT_BEARING_ROW: 'SELECT_BEARING_ROW',

  SELECTED_FALSE: 'SELECTED_FALSE',

  UPDATE_BEARING_SUCCESS: 'UPDATE_BEARING_SUCCESS',
  UPDATE_BEARING_FAILURE: 'UPDATE_BEARING_FAILURE',

  ASSOCIATE_BEARING_REQUEST: 'ASSOCIATE_BEARING_REQUEST',
  ASSOCIATE_BEARING_SUCCESS: 'ASSOCIATE_BEARING_SUCCESS',
  ASSOCIATE_BEARING_FAILURE: 'ASSOCIATE_BEARING_FAILURE',

  BEARINGS_ITEMS: 'BEARINGS_ITEMS',

  EDIT_ROW: 'EDIT_ROW',
  UPDATE_ROW: 'UPDATE_ROW',
  NUMBER_OF_BEARING_PER_PAGE: 50,
  SET_BEARING_PAGINATER: 'SET_BEARING_PAGINATER',
};
