import { combineReducers } from 'redux';
import { dashboardChartDetails } from './dashboard.charts.reducer';
import { DashboardMachinesList } from './dashboard.machines.reducer';
import { dashboardActivityList, DashboardActivitySorter } from './dashboard.activity.reducer';

export const dashboardReducer = combineReducers({
  chartsInfo: dashboardChartDetails,
  activities: dashboardActivityList,
  machinesList: DashboardMachinesList,
  ActivitySorter: DashboardActivitySorter
});
