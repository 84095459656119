import styled from 'styled-components';

const Container = styled.div.attrs(props => ({ className: props.className, id: props.id }))`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #fff;
  padding: ${props => props.padding || 0};
  position: ${props => props.position || 'initial'};
  ${props => props.margin};
  ${props => props.marginbottom && `margin-bottom: ${props.marginbottom};`}
`;

Container.displayName = 'Container';

export default styled(Container)``;
