import _ from 'lodash';
import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse } from 'common/helpers';
import { activityConstants } from '../constants/activity.constants';

export const getActivityUnreadCount = params => (dispatch) => {
  const { account_id, machine_id, type, event_type, days, start_date, end_date, filters } = params;

  const request = () => ({ type: activityConstants.GET_ACTIVITY_UNREAD_COUNT_REQUEST });
  const success = res => ({ type: activityConstants.GET_ACTIVITY_UNREAD_COUNT_SUCCESS, payload: res });
  const failure = error => ({ type: activityConstants.GET_ACTIVITY_UNREAD_COUNT_FAILURE, payload: error });

  const queryParams = {
    account_id,
    machine_id,
    type,
    event_type,
    days,
    start_date,
    end_date,
    filters,
  };

  dispatch(request());
  return handleResponse(
    axiosInstance.get(ENDPOINT.GET_ACTIVITY_UNREAD_COUNT, { params: queryParams })
  ).then(
    (res) => {
      dispatch(success(res));
    },
    (error) => {
      dispatch(failure(error));
    }
  );
};
