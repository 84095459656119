import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSvg = styled.svg`
    cursor: pointer;
`;

const EditIcon = ({ height, width, fill }) => (
  <StyledSvg width={width} height={height} viewBox="0 0 14 14" version="1.1">
    <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="PSWAv.1.0.0-Machines_v1.8.6" transform="translate(-372.000000, -213.000000)">
            {/* <rect fill="#FFFFFF" x="0" y="0" width="1280" height="1095" /> */}
            <g id="bearings">
                <g id="card" transform="translate(204.000000, 24.000000)">
                    <g id="list" transform="translate(24.000000, 128.000000)">
                        <g id="row1" transform="translate(16.000000, 60.000000)">
                            <g id="1.0">
                                <g id="edit-icon" transform="translate(127.000000, 0.000000)">
                                    <rect id="bounds" x="0" y="0" width="16" height="16" />
                                    <g id="edit-icon-stroke" transform="translate(2.000000, 2.000000)" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                                        <polygon id="Shape" points="8.66666667 0 12 3.33333333 3.33333333 12 0 12 0 8.66666667" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </StyledSvg>
);

EditIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

EditIcon.defaultProps = {
  width: 14,
  height: 14,
  fill: '#999B95'
};

export default EditIcon;
