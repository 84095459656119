import styled from 'styled-components';

const H5 = styled.h5`
  color: ${props => props.theme.colors.black};
  font-family: 'Petasense Open Sans';
  font-size: 12px;
  font-weight: 600;
`;

export default H5;
