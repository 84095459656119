import React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';

import Modal from '../../../common/components/organisms/Modal';
import FlexContainer from '../../../common/components/atoms/FlexContainer';
import InputField_T from '../../../common/components/atoms/InputField';
import { Search } from '../../../common/images/FaIcons';
import Checkbox from '../../../common/components/atoms/Checkbox';
import Label_T from '../../../common/typography/Label/Label';
import Button_T from '../../../common/components/atoms/Button';

const InputField = styled(InputField_T)`
`;

const Label = styled(Label_T)`
  margin-right: 1.5em;
`;

const MsgContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  padding: 2em;
  height: max-content;
  margin: 2em;
  border: 2px dotted ${props => props.theme.colors.lightGray};
  border-radius: ${props => props.theme.utils.borderRadius};
  span {
    color: ${props => props.theme.colors.lightGray};
    font-weight: bold;
    height: max-content;
  }
  `;

const FilterItem = styled.button`
  display: flex;
  padding: 0.5em;
  text-align: left;
  flex-basis: 50%;
  border: none;
  outline: none;
  height: max-content;
  background-color: white;
  font-family: "Petasense Open Sans";
  font-weight: 600;
  color: ${props => props.theme.colors.lightGray};
  ${props => props.selected && `color: ${props.theme.colors.black};`}
  &:hover {
    color: ${props => props.theme.colors.black};
  }
  span {
    margin-left: 0.5em;
  }
`;

const SelectAllItem = styled.button`
  display: flex;
  padding: 0.5em;
  text-align: left;
  border: none;
  outline: none;
  height: max-content;
  background-color: white;
  font-family: "Petasense Open Sans";
  font-weight: 600;
  color: ${props => props.theme.colors.lightGray};
  ${props => props.selected && `color: ${props.theme.colors.black};`}
  span {
    margin-left: 0.5em;
  }
`;

const Button = styled(Button_T)`
  ${props => props.text && `color: ${props.theme.colors.greyXD};margin-left: auto;margin-right: 10px`}
  &:first-of-type {
    margin-right: 2em;
  }
`;

const ListContainer = styled(FlexContainer)`
  height: 300px;
  overflow: auto;
  padding: 1em;
  border: 1px solid #E5E8E1;
  border-radius: 8px; 
  flex-wrap: wrap;
  align-content: flex-start;
  margin-bottom: 15px;
`;

class FullListModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.optionsAvailable,
      selected: _.cloneDeep(props.selectedOptions),
      search: '',
      selectAll: props.selectAll,
      selectSome: props.selectSome,
    };
  }

  searchResources = (e) => {
    const { optionsAvailable } = this.props;
    this.setState({
      search: e.target.value,
      options: optionsAvailable.filter(u => u.text.toLowerCase().includes(e.target.value.toLowerCase())),
    });
  }

  toggleResource = (value) => {
    const { selected, options } = this.state;
    const index = selected.indexOf(value);
    if (index === -1) selected.push(value);
    else selected.splice(index, 1);
    if (selected.length === options.length) {
      this.setState({
        selected,
        selectAll: true,
        selectSome: false
      });
    } else if (selected.length > 0) {
      this.setState({
        selected,
        selectAll: false,
        selectSome: true
      });
    } else {
      this.setState({
        selected,
        selectAll: false,
        selectSome: false
      });
    }
  }

  toggleSelectAll = () => {
    const { selected, options, selectAll, selectSome } = this.state;
    if (selectAll || selectSome) {
      selected.length = 0;
      this.setState({
        selected,
        selectAll: false,
        selectSome: false
      });
    } else {
      options.forEach((option) => {
        if (!selected.includes(option.value)) selected.push(option.value);
      });
      this.setState({
        selected,
        selectAll: true
      });
    }
  };

  saveResources = () => {
    const { addGivenResources, close } = this.props;
    addGivenResources(this.state.selected);
    close();
  };

  render() {
    const { resourceType } = this.props;
    const {
      options,
      search,
      selected,
      selectAll,
      selectSome,
    } = this.state;

    return (
      <Modal
        width="35%"
        padding="0"
        minWidth="700px"
        close={this.props.close}
        title={this.props.title}
        titleSize="18px"
      >
        <FlexContainer direction="column" flexBasis="100%">
          <InputField prefix={<Search />} prefixSide="left" value={search} onChange={this.searchResources} />
          <ListContainer>
            {options && options.map((option, index) => {
              const selectedState = selected.includes(option.value);
              return (
                <FilterItem
                  key={index.toString()}
                  selected={selectedState}
                  onClick={() => this.toggleResource(option.value)}
                >
                <Checkbox value={selectedState} />
                <span>{option.text}</span>
                </FilterItem>);
            })}
            {_.isEmpty(options) && (
              <MsgContainer>
                <span>No {resourceType}s are available</span>
              </MsgContainer>
            )}
          </ListContainer>
          <FlexContainer alignItems="center" justifyContent="space-between">
            <SelectAllItem
              selected={selectAll}
              onClick={() => this.toggleSelectAll()}
            >
              <Checkbox value={selectAll} minus={selectSome} />
              <span>Select all</span>
            </SelectAllItem>
            <Label marginBottom="0px">{selected.length} {selected.length > 1 ? `${resourceType}s` : resourceType} selected</Label>
            <Button onClick={this.saveResources}>DONE</Button>
          </FlexContainer>
        </FlexContainer>
      </Modal>
    );
  }
}

export default FullListModal;
