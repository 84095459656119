import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import FlexContainer from '../../common/components/atoms/FlexContainer';

export const Dropdown = styled(SemanticDropdown)`
  position: fixed;
  left: 95vw;
  top: 10px;
  .title {
    font-weight: 600;
  }
  &.ui.dropdown {
    > .dropdown.icon {
      display: none;
    }
    .menu {
      border-radius: 8px !important;
      bottom: 0;
      margin-left: 0.65em !important;
      box-shadow: 0 0 8px 0 rgba(60,61,58,0.24) !important;
      width: max-content;
      > .item {
        border-radius: 8px !important;
        margin: 0 !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
      &.visible {
        width: 120px;
        > .item {
          font-weight: 600 !important;
        }
        > .disabled.item {
          opacity: 1;
          color: grey !important;
        }
        padding: 10px 0px;
        left: -110px;
        height: max-content;
      }
    }
  }
`;

const MenuItemStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${props => props.theme.colors.black};
  > svg {
    margin-right: 1em;
    fill: none;
  }
  /* why is this horrible selector in home-page.scss ???? TODO: remove home-page scss and get rid of this sort of stuff... */
  > svg > g > g {
    fill: none !important;
  }
  &:hover,
  &:focus,
  &:active {
    color: ${props => props.theme.colors.black};
  }
`;

export const MenuLink = styled(Link)`
  ${MenuItemStyles};
`;

export const MenuFakeLink = styled(FlexContainer).attrs({ alignItems: 'center' })`
  ${MenuItemStyles};
`;

const SideMenuDropdown = ({ onClick, trigger, children, title, id, active }) => (
  <Dropdown
    id={id}
    selectOnBlur={false}
    item
    onClick={onClick}
    trigger={trigger}
    color="grey"
    className={active ? 'active' : ''}
  >
    <SemanticDropdown.Menu>
      {title && <SemanticDropdown.Item className="dropdown-title" disabled>{title}</SemanticDropdown.Item>}
      {children}
    </SemanticDropdown.Menu>
  </Dropdown>
);

SideMenuDropdown.propTypes = {
  onClick: PropTypes.func
};

SideMenuDropdown.defaultProps = {
  onClick: _ => _
};

export default SideMenuDropdown;
