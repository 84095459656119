import React from 'react';
import MultilineIconSvg from 'common/images/AssetHierarchy/MultilineIconSvg';
import UtilizationIconSvg from 'common/images/AssetHierarchy/UtilizationIconSvg';
import DefaultConfigIconSvg from 'common/images/AssetHierarchy/DefaultConfigIconSvg';

export default {
  GET_ASSET_HIERARCHY_REQUEST: 'GET_ASSET_HIERARCHY_REQUEST',
  GET_ASSET_HIERARCHY_SUCCESS: 'GET_ASSET_HIERARCHY_SUCCESS',
  GET_ASSET_HIERARCHY_FAILURE: 'GET_ASSET_HIERARCHY_FAILURE',

  REFRESH_HIERARCHY: 'REFRESH_HIERARCHY',

  GET_MACHINE_CHARTS_TAGS_REQUEST: 'GET_ACCOUNT_CHARTS_TAGS_REQUEST',
  GET_MACHINE_CHARTS_TAGS_SUCCESS: 'GET_ACCOUNT_CHARTS_TAGS_SUCCESS',
  GET_MACHINE_CHARTS_TAGS_FAILURE: 'GET_ACCOUNT_CHARTS_TAGS_FAILURE',

  GET_TAG_WAVEFORM_REQUEST: 'GET_ACCOUNT_TAG_WAVEFORM_REQUEST',
  GET_TAG_WAVEFORM_SUCCESS: 'GET_ACCOUNT_TAG_WAVEFORM_SUCCESS',
  GET_TAG_WAVEFORM_FAILURE: 'GET_ACCOUNT_TAG_WAVEFORM_FAILURE',

  GET_TAG_SPECTRUM_REQUEST: 'GET_ACCOUNT_TAG_SPECTRUM_REQUEST',
  GET_TAG_SPECTRUM_SUCCESS: 'GET_ACCOUNT_TAG_SPECTRUM_SUCCESS',
  GET_TAG_SPECTRUM_FAILURE: 'GET_ACCOUNT_TAG_SPECTRUM_FAILURE',

  GET_TAG_CEPSTRUM_REQUEST: 'GET_ACCOUNT_TAG_CEPSTRUM_REQUEST',
  GET_TAG_CEPSTRUM_SUCCESS: 'GET_ACCOUNT_TAG_CEPSTRUM_SUCCESS',
  GET_TAG_CEPSTRUM_FAILURE: 'GET_ACCOUNT_TAG_CEPSTRUM_FAILURE',

  UPDATE_SELECTED_MACHINE_AND_COMPONENT: 'UPDATE_SELECTED_MACHINE_AND_COMPONENT',

  GET_CHARTS_CONFIG_REQUEST: 'GET_ACCOUNT_CHARTS_CONFIG_REQUEST',
  GET_CHARTS_CONFIG_SUCCESS: 'GET_ACCOUNT_CHARTS_CONFIG_SUCCESS',
  GET_CHARTS_CONFIG_FAILURE: 'GET_ACCOUNT_CHARTS_CONFIG_FAILURE',

  GET_CHARTS_DATA_REQUEST: 'GET_ACCOUNT_CHARTS_DATA_REQUEST',
  GET_CHARTS_DATA_SUCCESS: 'GET_ACCOUNT_CHARTS_DATA_SUCCESS',
  GET_CHARTS_DATA_FAILURE: 'GET_ACCOUNT_CHARTS_DATA_FAILURE',

  GET_HIERARCHY_CHARTS_CONFIG_METADATA_REQUEST: 'GET_HIERARCHY_CHARTS_CONFIG_METADATA_REQUEST',
  GET_HIERARCHY_CHARTS_CONFIG_METADATA_SUCCESS: 'GET_HIERARCHY_CHARTS_CONFIG_METADATA_SUCCESS',
  GET_HIERARCHY_CHARTS_CONFIG_METADATA_FAILURE: 'GET_HIERARCHY_CHARTS_CONFIG_METADATA_FAILURE',

  PUT_HIERARCHY_CHARTS_CONFIG_REQUEST: 'PUT_HIERARCHY_CHARTS_CONFIG_REQUEST',
  PUT_HIERARCHY_CHARTS_CONFIG_SUCCESS: 'PUT_HIERARCHY_CHARTS_CONFIG_SUCCESS',
  PUT_HIERARCHY_CHARTS_CONFIG_FAILURE: 'PUT_HIERARCHY_CHARTS_CONFIG_FAILURE',

  DELETE_HIERARCHY_CHARTS_CONFIG_REQUEST: 'DELETE_HIERARCHY_CHARTS_CONFIG_REQUEST',
  DELETE_HIERARCHY_CHARTS_CONFIG_SUCCESS: 'DELETE_HIERARCHY_CHARTS_CONFIG_SUCCESS',
  DELETE_HIERARCHY_CHARTS_CONFIG_FAILURE: 'DELETE_HIERARCHY_CHARTS_CONFIG_FAILURE',

  UPDATE_HIERARCHY_TAG_REQUEST: 'UPDATE_HIERARCHY_TAG_REQUEST',
  UPDATE_HIERARCHY_TAG_SUCCESS: 'UPDATE_HIERARCHY_TAG_SUCCESS',
  UPDATE_HIERARCHY_TAG_FAILURE: 'UPDATE_HIERARCHY_TAG_FAILURE',

  GET_TAG_WATERFALL_SPECTRUM_REQUEST: 'GET_ACCOUNT_TAG_WATERFALL_SPECTRUM_REQUEST,',
  GET_TAG_WATERFALL_SPECTRUM_SUCCESS: 'GET_ACCOUNT_TAG_WATERFALL_SPECTRUM_SUCCESS',
  GET_TAG_WATERFALL_SPECTRUM_FAILURE: 'GET_ACCOUNT_TAG_WATERFALL_SPECTRUM_FAILURE',

  GET_SPECTRUM_FEATURES_REQUEST: 'GET_SPECTRUM_FEATURES_REQUEST',
  GET_SPECTRUM_FEATURES_SUCCESS: 'GET_SPECTRUM_FEATURES_SUCCESS',
  GET_SPECTRUM_FEATURES_FAILURE: 'GET_SPECTRUM_FEATURES_FAILURE',

  GET_SPECTRUM_FEATURE_TREND_SUCCESS: 'GET_SPECTRUM_FEATURE_TREND_SUCCESS',
  GET_SPECTRUM_FEATURE_TREND_FAILURE: 'GET_SPECTRUM_FEATURE_TREND_FAILURE',

  CREATE_SPECTRUM_FEATURE_REQUEST: 'CREATE_SPECTRUM_FEATURE_REQUEST',
  CREATE_SPECTRUM_FEATURE_SUCCESS: 'CREATE_SPECTRUM_FEATURE_SUCCESS',
  CREATE_SPECTRUM_FEATURE_FAILURE: 'CREATE_SPECTRUM_FEATURE_FAILURE',

  UPDATE_SPECTRUM_FEATURE_REQUEST: 'UPDATE_SPECTRUM_FEATURE_REQUEST',
  UPDATE_SPECTRUM_FEATURE_SUCCESS: 'UPDATE_SPECTRUM_FEATURE_SUCCESS',
  UPDATE_SPECTRUM_FEATURE_FAILURE: 'UPDATE_SPECTRUM_FEATURE_FAILURE',

  DELETE_SPECTRUM_FEATURE_REQUEST: 'DELETE_SPECTRUM_FEATURE_REQUEST',
  DELETE_SPECTRUM_FEATURE_SUCCESS: 'DELETE_SPECTRUM_FEATURE_SUCCESS',
  DELETE_SPECTRUM_FEATURE_FAILURE: 'DELETE_SPECTRUM_FEATURE_FAILURE',

  GET_TAG_DEMOD_SPECTRUM_REQUEST: 'GET_ACCOUNT_TAG_DEMOD_SPECTRUM_REQUEST,',
  GET_TAG_DEMOD_SPECTRUM_SUCCESS: 'GET_ACCOUNT_TAG_DEMOD_SPECTRUM_SUCCESS',
  GET_TAG_DEMOD_SPECTRUM_FAILURE: 'GET_ACCOUNT_TAG_DEMOD_SPECTRUM_FAILURE',

  GET_FORCING_FREQUENCY_REQUEST: 'GET_FORCING_FREQUENCY_REQUEST',
  GET_FORCING_FREQUENCY_SUCCESS: 'GET_FORCING_FREQUENCY_SUCCESS',
  GET_FORCING_FREQUENCY_FAILURE: 'GET_FORCING_FREQUENCY_FAILURE',

  DELETE_TAGS_REQUEST: 'DELETE_TAGS_REQUEST',
  DELETE_TAGS_SUCCESS: 'DELETE_TAGS_SUCCESS',
  DELETE_TAGS_FAILURE: 'DELETE_TAGS_FAILURE',

  UPDATE_MCHINE_LIST_IN_ANALYZE_SUCCESS: 'UPDATE_MACHINE_LIST_IN_ANALYZE_SUCCESS',

  GET_ASSET_DATA_OVERVIEW_REQUEST: 'GET_ASSET_DATA_OVERVIEW_REQUEST',
  GET_ASSET_DATA_OVERVIEW_SUCCESS: 'GET_ASSET_DATA_OVERVIEW_SUCCESS',
  GET_ASSET_DATA_OVERVIEW_FAILURE: 'GET_ASSET_DATA_OVERVIEW_FAILURE',
  GET_ASSET_DATA_OVERVIEW: 'GET_ASSET_DATA_OVERVIEW',

  DEMOD_TREND_LOWER_FILTER: 1000, // in hz
};

export const SHORTER_CHART_CONFIG_NAME_MAPPINGS = {
  'Motor Drive End': 'MDE',
  'Motor Free End': 'MFE',
  'Turbine Drive End': 'TDE',
  'Turbine Free End': 'TFE',
  'Pump Drive End': 'PDE',
  'Pump Free End': 'PFE',
  'Fan Drive End': 'FDE',
  'Fan Free End': 'FFE',
  'Compressor Drive End': 'CDE',
  'Compressor Free End': 'CFE',
  'Conveyor Drive End': 'CoDE',
  'Conveyor Free End': 'CoFE',
  'Agitator Drive End': 'AGE',
  'Agitator Free End': 'AFE',
  'Mill Drive End': 'MiDE',
  'Mill Free End': 'MiFE',
  Vibration: 'Vib',
  Gearbox: 'Gbx'
};

export const COMPONENT_TYPE_NAME_MAPPINGS = {
  ac_induction_motor: 'M',
  dc_motor: 'M',
  axial_fan: 'F',
  centrifugal_fan: 'F',
  axial_compressor: 'C',
  centrifugal_compressor: 'C',
  reciprocating_compressor: 'X',
  screw_compressor: 'C',
  axial_pump: 'P',
  centrifugal_pump: 'P',
  screw_pump: 'P',
  agitator: 'A',
  ball_mill: 'B',
  sag_mill: 'S',
  gearbox: 'Gbx',
  multi_stage_gearbox: 'Gbx'
};

export const CHART_TYPES = [
  'trend',
  'spectrum',
  'waveform',
  'TSW',
  'waterfall_spectrum',
  'cepstrum'
];

export const getComponentTypeShortHand = (component_type) => {
  if (!component_type) return '';
  if (!COMPONENT_TYPE_NAME_MAPPINGS[component_type]) return component_type[0].toUpperCase();
  return COMPONENT_TYPE_NAME_MAPPINGS[component_type];
};

export const getConfigIcon = (node) => {
  if (node.default === true) {
    if (node.node_name === 'Utilization Trend') {
      return <UtilizationIconSvg />;
    }
    if (node.active === false) {
      return <DefaultConfigIconSvg inactive />;
    }
    return <DefaultConfigIconSvg />;
  }
  return <MultilineIconSvg />;
};

export const dataOverviewColumns = [
  {
    name: 'Location',
    width: '2',
    key: 'location',
    footer: null
  },
  {
    name: 'Direction',
    width: '1',
    key: 'direction',
    footer: null
  },
  {
    name: 'RMS Vel',
    width: '1',
    key: 'rms_velocity',
    footer: {
      unitKey: 'velocity_unit',
      additionalInfo: '(w.r.t. Baseline)'
    }
  },
  {
    name: 'RMS Accel',
    width: '1',
    key: 'rms_acceleration',
    footer: {
      unitKey: 'acceleration_unit',
      additionalInfo: '(w.r.t. Baseline)'
    }
  },
  {
    name: 'RMS Demod',
    width: '1',
    key: 'rms_demod_acceleration',
    footer: {
      unitKey: 'acceleration_unit',
    }
  },
  {
    name: 'Peak Vel',
    width: '1',
    key: 'peak_velocity',
    footer: {
      unitKey: 'velocity_unit',
    }
  },
  {
    name: 'Peak Accel',
    width: '1',
    key: 'peak_acceleration',
    footer: {
      unitKey: 'acceleration_unit',
    }
  },
  {
    name: 'Vel-1X',
    width: '1',
    key: 'shaft_speed_1x_velocity',
    footer: {
      unitKey: 'velocity_unit',
    }
  },
  {
    name: 'Vel-2X',
    width: '1',
    key: 'shaft_speed_2x_velocity',
    footer: {
      unitKey: 'velocity_unit',
    }
  },
  {
    name: 'Vel-3X',
    width: '1',
    key: 'shaft_speed_3x_velocity',
    footer: {
      unitKey: 'velocity_unit',
    }
  },
];
