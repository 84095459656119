import React from 'react';
import PropTypes from 'prop-types';

const RadiobuttonSvg = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 16 16">
    <g id="3-Machines" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="PSWAv.1.0.0-Machines_v1.8.10" className="radiobuttonsvg" transform="translate(-244.000000, -261.000000)" stroke={fill}>
        <g id="bearings">
          <g id="card" transform="translate(204.000000, 24.000000)">
            <g id="list" transform="translate(24.000000, 128.000000)">
              <g id="row1" transform="translate(0.000000, 46.000000)">
                <g id="1.1" transform="translate(16.000000, 63.000000)">
                  <g id="check_box/static">
                    <rect id="Check_box" x="0.5" y="0.5" width="15" height="15" rx="7.5" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

RadiobuttonSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

RadiobuttonSvg.defaultProps = {
  width: 16,
  height: 16,
  fill: '#000000'
};


export default RadiobuttonSvg;
