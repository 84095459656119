import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AggressiveMeasurementAlertPrompt from 'common/components/organisms/AlertPrompt';

import RBAC from 'common/rbac/RBAC';
import { mapComponentToResource, operations } from 'common/rbac/constants';
import TickSvg from 'common/images/BearingModal/TickSvg';
import CrossSvg from 'common/images/CrossSvg';
import { Grid, Segment } from 'semantic-ui-react';
import {
  ResourceItemLabel,
  ResourceInputField,
  ResourceSection,
  ResourceSubSection,
  ResourceItem
} from 'common/components/atoms/ResourceDetails';
import DeleteSensorModal from '../deleteSensorModal';
import OutsideAlerter from '../../../../common/OutsideAlerter';
import Modal from '../../../../common/components/organisms/Modal';
import { history, humanize } from '../../../../common/helpers';
import * as sensorsActions from '../../actions/sensors.actions';
import { localTimeString } from '../../../../common/helpers/time';
import LoadingSvg from '../../../../common/images/LoadingSvg';
import FlexContainer from '../../../../common/components/atoms/FlexContainer';
import CloseSvg from '../../../../common/images/closeIconSvg';
import H2_T from '../../../../common/typography/H2/H2';
import H1_T from '../../../../common/typography/H1/H1';
import Label_T from '../../../../common/typography/Label/Label';
import InputField_T from '../../../../common/components/atoms/InputField';
import Button_T from '../../../../common/components/atoms/Button';
import { EllipsisV, Info } from '../../../../common/images/FaIcons';
import MeasurementScheduleModal from '../MeasurementScheduleModal';
import {
  AGGRESSIVE_INTERVAL_THRESHOLD,
  vibrationMeasurementTypeEnum,
  MESSAGE,
  reservedPrefixes,
  oldTxMeasurementVersion
} from '../../constants/sensors.constants';


const Container = styled(FlexContainer).attrs({ direction: 'column' })`
  padding: 2em;
  min-height: Calc(100vh - 138px);
`;

const H1 = styled(H1_T)`
  padding: ${props => (props.padding ? `${props.padding};` : '0;')};
  margin-bottom: 3px;
  min-width: max-content;
  width: 100%;
  cursor: pointer;
`;

const H2 = styled(H2_T)`
  width: 100%;
  font-size: 18px;
  padding-bottom: 7px;
`;

const SvgContainer = styled.div`
  position: relative;
  cursor: ${props => (props.cursor ? `${props.cursor}` : 'pointer')};
  margin: 0 10px;
  padding: ${props => (props.padding ? `${props.padding}` : '0.2em')};
  ${props => props.marginTop && `margin-top: ${props.marginTop};`}
`;

const Button = styled(Button_T)`
  width: max-content;
  padding-left: 52px;
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;

const ScheduleButton = styled(Button_T)`
  width: max-content;
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;

const SaveButton = styled(Button_T)`
  max-width: 200px;
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;

const LoadingContainer = styled.div`
  align-self: center;
  margin: auto;
`;
const ButtonsContainer = styled(FlexContainer)`
  padding-top: 2em;
  button:first-child {
    margin-right: 15px;
  }
`;

const AbsoluteLoading = styled.div`
  background: white;
  position: absolute;
  align-self: center;
  margin: auto;
  opacity: 0.4;
  width: 100%;
  height: calc(100vh - 138px);
  overflow: none;
  svg {
    transform: translateX(35vw) translateY(50vh) translateY(-69px);
  }
`;
const TextLabel = styled(Label_T)`
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 8px;
  margin-top: -2px;
`;

const BorderContainer = styled.div`
  border: 2px solid #f0f0f0;
  padding: 20px;
`;

const InputField = styled(InputField_T)`
  font-size: 12px;
  ${props => props.paddingtop && `padding-top: ${props.paddingtop};`}
  width: 90%;
  min-width: 224px;
  label {
    font-weight: 800;
  }
`;
const HeaderContainer = styled(FlexContainer).attrs({
  justifyContent: 'space-between',
  alignItems: 'center'
})``;

const CenterText = styled.div`
  font-family: "Petasense Open Sans";
  font-weight: 600;
  text-align: center;
`;

const SubMenuContainer = styled.div`
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

const InlineSpan = styled.span`
  box-shadow: 0 0 8px 0 rgba(60, 61, 58, 0.24);
  background: white;
  border-radius: 4px;
  position: absolute;
  right: 0px;
  span {
    font-family: "Petasense Open Sans";
    font-weight: 600;
    &:hover {
      color: ${props => props.theme.primaryColor};
    }
  }
  padding: 1em;
`;

class VectorSensor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        machine: props.machines || [],
        component: [],
        tx: [],
        location: null,
        orientation: [],
        phase: [],
        spectrum_sampling_resolution: [],
        spectrum_measurement_type: []
      },
      details: {
        serial_number: props.sensor.serial_number,
        type: props.sensor.type,
        model: props.sensor.model
      },
      error: false,
      editMode: props.editMode,
      saveEnabled: false,
      menuExpanded: false,
      highResEnabled: false,
      loading: {},
      editSerialNumber: false,
      updatedSerialNumber: props.sensor.serial_number,
      errorMessages: {}
    };
  }

  componentDidMount() {
    const { getResourcesBasedOnType } = this.props.sensorsActions;
    if (this.state.options.machine.length === 0) {
      getResourcesBasedOnType('area');
      getResourcesBasedOnType('machine').then(
        res =>
          this.setState(prevState => ({
            options: {
              ...prevState.options,
              machine: res
            }
          })),
        error => this.setState({ error })
      );
    }
    getResourcesBasedOnType('tx').then(
      res =>
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            tx: res
          }
        })),
      error => this.setState({ error })
    );
    const {
      details,
      sensor: { model, serial_number }
    } = this.props;
    let sensor = {};
    if (details[model]) {
      sensor = details[model].find(d => d.serial_number === serial_number);
    }
    if (sensor) {
      this.setState({
        details: _.cloneDeep(sensor),
        associatedMachineId: sensor.machine
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      metadata,
      details,
      sensor: { model, serial_number }
    } = this.props;
    const {
      details: prevDetails,
      sensor: { model: prevModel, serial_number: prevSerialNumber }
    } = prevProps;
    const {
      getLocationOptions,
      getComponentOptions
    } = this.props.sensorsActions;
    let sensor = {};
    let prevSensor = {};

    if (details[model]) {
      sensor = details[model].find(d => d.serial_number === serial_number);
    }
    if (prevDetails[prevModel]) {
      prevSensor = prevDetails[prevModel].find(
        d => d.serial_number === prevSerialNumber
      );
    }
    if (
      sensor &&
      (!_.isEqual(prevSensor, sensor) ||
        prevState.details.serial_number !== sensor.serial_number ||
        !_.isEqual(
          prevState.options.machine.length,
          this.state.options.machine.length
        ))
    ) {
      if (
        this.props.createdMachineId &&
        !_.isEmpty(this.state.options.machine)
      ) {
        const clonedDetails = _.cloneDeep(sensor);
        clonedDetails.machine = this.props.createdMachineId;
        const obj = this.state.options.machine.find(
          e => e.value === this.props.createdMachineId
        );
        clonedDetails.machine_name = (obj && obj.text) || '';
        this.setState({
          details: clonedDetails,
          associatedMachineId: this.props.createdMachineId,
          updatedSerialNumber: serial_number,
          editSerialNumber: false,
          errorMessages: {}
        });
      } else {
        this.setState({
          details: _.cloneDeep(sensor),
          associatedMachineId: sensor.machine,
          updatedSerialNumber: serial_number,
          editSerialNumber: false,
          errorMessages: {}
        });
      }
    }
    if (
      this.state.details.machine &&
      !this.state.loading.components &&
      (_.isEmpty(this.state.options.component) ||
        prevState.details.machine !== this.state.details.machine)
    ) {
      this.setState(prevState => ({
        loading: {
          ...prevState.loading,
          components: true
        }
      }));
      getComponentOptions(Number(this.state.details.machine)).then(
        (res) => {
          this.setState(prevState => ({
            options: {
              ...prevState.options,
              component: res
            },
            loading: {
              ...prevState.loading,
              components: false
            }
          }));
        },
        error => this.setState({ error })
      );
    }
    if (
      this.state.details.machine &&
      !this.state.loading.locations &&
      (!this.state.options.location ||
        prevState.details.machine !== this.state.details.machine ||
        prevState.details.component !== this.state.details.component)
    ) {
      this.setState(prevState => ({
        loading: {
          ...prevState.loading,
          locations: true
        }
      }));
      getLocationOptions(
        Number(this.state.details.machine),
        Number(this.state.details.component),
        true
      ).then(
        (res) => {
          this.setState(prevState => ({
            options: {
              ...prevState.options,
              location: res
            },
            loading: {
              ...prevState.loading,
              locations: false
            }
          }));
        },
        error => this.setState({ error })
      );
    }
    if (prevState.editMode !== this.props.editMode) {
      this.setState({
        editMode: this.props.editMode
      });
    }
    if (!sensor) return;
    const stateDetails = _.cloneDeep(this.state.details);
    const propsDetails = _.cloneDeep(sensor);
    delete stateDetails.machine;
    delete stateDetails.machine_name;
    delete stateDetails.location;
    delete stateDetails.location_name;
    delete stateDetails.component;
    delete stateDetails.component_name;
    delete propsDetails.machine;
    delete propsDetails.machine_name;
    delete propsDetails.location;
    delete propsDetails.location_name;
    delete propsDetails.component;
    delete propsDetails.component_name;

    const highResSamplingOptions =
      metadata[model] &&
      metadata[model].spectrum_measurements.sampling.resolution[
        vibrationMeasurementTypeEnum.highRes
      ];

    if (_.isEqual(this.state.details, sensor)) {
      if (this.state.saveEnabled !== false) {
        this.setState({
          saveEnabled: false
        });
      }
    } else if (_.isEqual(stateDetails, propsDetails)) {
      if (
        this.state.details.machine &&
        this.state.details.location &&
        !_.isEqual(this.state.details.location, sensor.location)
      ) {
        if (this.state.saveEnabled !== true) this.setState({ saveEnabled: true });
      } else if (this.state.saveEnabled !== false) {
        this.setState({
          saveEnabled: false
        });
      }
    } else if (this.state.saveEnabled !== true) this.setState({ saveEnabled: true });

    if (
      !_.includes(
        oldTxMeasurementVersion,
        this.props.currentAccount.preferences.tx_measurement_version
      ) &&
      this.state.details.transmitter_version !== '0804' &&
      _.includes(
        ['Vibration Sensor', 'VSX', 'Current Sensor'],
        this.state.details.type
      ) &&
      !_.isEmpty(highResSamplingOptions)
    ) {
      if (!this.state.highResEnabled) this.setState({
        highResEnabled: true
      });
    } else if (this.state.highResEnabled) {
      this.setState({
        highResEnabled: false
      });
    }
  }

  dissociateSensor = () => {
    this.setState(
      prevState => ({
        details: {
          ...prevState.details,
          location: null,
          location_name: null,
          machine: null,
          machine_name: null,
          component: null,
          component_name: null
        },
        associatedMachineId: null
      }),
      () => this.onSave()
    );
  };

  isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  stringifyOptions = options =>
    options &&
    options.map(option => ({
      ...option,
      value: _.isObject(option.value)
        ? JSON.stringify(option.value)
        : option.value
    }));

  renderField = (
    type,
    label,
    options_T,
    value_T,
    disabled,
    name,
    search = false
  ) => {
    const { editMode } = this.state;
    const { updating } = this.props;
    const options = this.stringifyOptions(options_T);
    const value = _.isObject(value_T) ? JSON.stringify(value_T) : value_T;
    if (editMode) {
      return (
        <ResourceInputField
          type={type}
          label={label}
          options={options}
          value={value}
          name={name}
          key={name}
          disabled={disabled || updating}
          onChange={(e, value) => this.onChangeField(e, value, name)}
          selectOnBlur={false}
          search={search}
        />
      );
    }
    let textValue = '';
    if (options) {
      const element = options.filter(option =>
        _.isEqual(option.value, value)
      )[0];
      if (element) textValue = element.text;
      else textValue = '--';
    }
    return (
      <Fragment>
        <ResourceItemLabel>{label}</ResourceItemLabel>
        {name === 'machine' ? (
          <ResourceItem
            onClick={() => this.redirectToMachine(value)}
            hover={textValue !== '--'}
          >
            {textValue}
          </ResourceItem>
        ) : (
          <ResourceItem>{textValue}</ResourceItem>
        )}
      </Fragment>
    );
  };

  onChangeField = (e, { value }, name) => {
    const { details } = this.state;
    const newDetails = _.cloneDeep(details);
    const [key1, key2] = name.split('.');

    if (name === 'machine' || name === 'component' || name === 'location') {
      const key = `${name}_name`;
      const text = this.state.options[name].find(e => e.value === value).text;
      newDetails[key] = text;
      newDetails[name] = value;
    } else if (name === 'measurement_interval') {
      newDetails[name] = this.isJson(value) ? JSON.parse(value) : value;
      newDetails.aggressive_measurement =
        value <= AGGRESSIVE_INTERVAL_THRESHOLD;
    } else if (key2 === 'sampling') {
      newDetails[key1] = {
        ...newDetails[key1],
        sampling: {
          ...newDetails[key1].sampling,
          resolution: this.isJson(value) ? JSON.parse(value) : value
        }
      };
    } else if (key2) {
      newDetails[key1] = {
        ...newDetails[key1],
        [key2]: this.isJson(value) ? JSON.parse(value) : value
      };
    } else {
      newDetails[name] = this.isJson(value) ? JSON.parse(value) : value;
    }

    this.setState({
      details: newDetails
    });
  };

  onAggressiveCancel = () => {
    this.setState({
      aggressiveModalOpen: false
    });
  };

  isScheduleAggressive = () => {
    const {
      full_bandwidth,
      high_res,
      transmitter_version,
      measurement_interval,
      measurement_type
    } = this.state.details;
    if (
      !_.includes(
        oldTxMeasurementVersion,
        this.props.currentAccount.preferences.tx_measurement_version
      ) &&
      measurement_type === 'schedule_based' &&
      transmitter_version !== '0804'
    ) {
      return _.some([full_bandwidth, high_res], setting =>
        _.some(
          setting.measurement && setting.measurement.schedule,
          s => s.interval <= AGGRESSIVE_INTERVAL_THRESHOLD
        )
      );
    }

    return measurement_interval <= AGGRESSIVE_INTERVAL_THRESHOLD;
  };

  onClickCheckAggressiveSave = () => {
    if (this.state.details.aggressive_measurement) {
      this.setState({
        aggressiveModalOpen: true
      });
    } else {
      this.onSave();
    }
  };

  onSave = () => {
    const { updateSensorDetails } = this.props.sensorsActions;
    const {
      details: {
        serial_number,
        model,
        orientation,
        phase,
        full_bandwidth,
        high_res,
        measurement_type,
        measurement_interval,
        location,
        location_name,
        machine,
        machine_name,
        component,
        component_name,
        tx_serial_number,
        tx_reporting_interval,
        aggressive_measurement_timeout,
        type
      },
      highResEnabled
    } = this.state;
    const propsDetails = this.props.details[this.props.sensor.model].find(
      d => d.serial_number === this.props.sensor.serial_number
    );
    const data = {
      [model]: {
        serial_number: [serial_number],
        orientation,
        phase,
        spectrum_measurements: {
          1: {
            ...full_bandwidth,
            measurement_interval,
            measurement: {
              ...full_bandwidth.measurement,
              type: measurement_type
            }
          },
          2: highResEnabled
            ? {
              ...high_res,
              measurement_interval,
              measurement: {
                ...high_res.measurement,
                type: measurement_type
              }
            }
            : null
        },
        location_id: location,
        location_name,
        component,
        component_name,
        machine,
        machine_name,
        type,
        aggressive_measurement: this.isScheduleAggressive(),
        aggressive_measurement_timeout
      }
    };
    if (tx_serial_number) {
      data.TX = {
        serial_number: [tx_serial_number],
        reporting: {
          interval: tx_reporting_interval
        },
        type: 'Transmitter',
        aggressive_measurement:
          tx_reporting_interval <= AGGRESSIVE_INTERVAL_THRESHOLD,
        aggressive_measurement_timeout
      };
    }

    if (_.isEqual(orientation, propsDetails.orientation)) delete data[model].orientation;
    if (_.isEqual(phase, propsDetails.phase)) delete data[model].phase;
    if (_.isEqual(location, propsDetails.location)) delete data[model].location_id;
    return updateSensorDetails(data).then(
      res => res,
      error => toastr.error(error),
      this.setState({
        aggressiveModalOpen: false
      })
    );
  };

  viewSensor = (serial_number) => {
    const { getSensorDetails } = this.props.sensorsActions;
    // const { details: { serial_number: txSerialNumber } } = this.state;

    const { appliedFilters } = this.props;
    delete appliedFilters.area_search_key;
    delete appliedFilters.machine_search_key;

    if (this.props.machine_id) {
      delete appliedFilters.machines;
      const filters = _.every(appliedFilters, value => _.isEmpty(value));
      const present = this.sensorPresentInTheList(serial_number);
      if (!filters && !present) {
        this.setState({
          changeFilterModal: true,
          changeFilterSerialNumber: serial_number
        });
        return;
      }

      getSensorDetails(serial_number, true);
    } else {
      const filters = _.every(appliedFilters, value => _.isEmpty(value));
      const present = this.sensorPresentInTheList(serial_number);
      if (!filters && !present) {
        this.setState({
          changeFilterModal: true,
          changeFilterSerialNumber: serial_number
        });
        return;
      }

      getSensorDetails(serial_number, true);
    }
  };

  clearAppliedFilters = () => {
    const { changeFilterSerialNumber } = this.state;
    if (!changeFilterSerialNumber) return;
    this.props.removeAppliedFilters(changeFilterSerialNumber);
  };

  sensorPresentInTheList = (serial_number) => {
    const { items } = this.props;
    return items.find(el => el.serial_number === serial_number);
  };

  onCancel = () => {
    const {
      details,
      sensor: { model, serial_number }
    } = this.props;
    let sensor = {};
    if (details[model]) {
      sensor = details[model].find(d => d.serial_number === serial_number);
    }
    if (sensor && !_.isEqual(this.state.details, sensor)) {
      this.setState({
        details: _.cloneDeep(sensor)
      });
    }
  };

  saveSchedule = (schedule, stops, mode) => {
    this.setState(
      prevState => ({
        details: {
          ...prevState.details,
          full_bandwidth: {
            ...prevState.details.full_bandwidth,
            measurement: {
              ...prevState.details.full_bandwidth.measurement,
              schedule: schedule[1],
              stops: stops[1]
            }
          },
          high_res: {
            ...prevState.details.high_res,
            measurement: {
              ...prevState.details.high_res.measurement,
              schedule: schedule[2],
              stops: stops[2]
            }
          },
          aggressive_measurement: mode
        },
        openScheduler: false
      }),
      () => this.onSave()
    );
  };

  redirectToMachine = (machineId) => {
    if (machineId) {
      history.push(`/machines/${machineId}/overview`);
    }
  };

  toggleMenuExpand = () => {
    this.setState(prevState => ({
      menuExpanded: !prevState.menuExpanded
    }));
  };

  renderAggressiveModal = () => {
    const { metadata } = this.props;
    const { details } = this.state;
    return (
      <div>
        {this.renderField(
          'select',
          'Measurement Time Out Interval',
          metadata.aggressive_measurement_timeout.interval,
          details.aggressive_measurement_timeout,
          false,
          'aggressive_measurement_timeout'
        )}
        {details.tx_serial_number &&
          metadata.TX.reporting &&
          metadata.TX.reporting.interval &&
          this.renderField(
            'select',
            'Reporting Interval',
            _.filter(
              metadata.TX.reporting.interval,
              o => o.value <= AGGRESSIVE_INTERVAL_THRESHOLD
            ),
            details.tx_reporting_interval,
            false,
            'tx_reporting_interval'
          )}
      </div>
    );
  };

  deleteSensor = () => {
    const {
      details: { tx_serial_number, serial_number }
    } = this.state;
    if (tx_serial_number) toastr.error(
      `${serial_number} is assigned to ${tx_serial_number}. First unassign and then delete.`
    );
    else this.setState({ deleteModalOpen: true });
  };

  onDelete = () => {
    const { deleteSensor } = this.props.sensorsActions;
    const data = {
      serial_numbers: [this.state.details.serial_number]
    };
    deleteSensor(data).then(
      () => this.props.close(),
      error =>
        this.setState({
          error,
          deleteModalOpen: false,
          menuExpanded: false
        })
    );
  };

  onDeleteCancel = () => {
    this.setState({
      deleteModalOpen: false,
      menuExpanded: false
    });
  };

  validateInput = () => {
    const {
      details: { type }
    } = this.state;
    let updatedSerialNumber = this.state.updatedSerialNumber;
    const errorMessages = {};
    if (updatedSerialNumber && type !== 'VSX') {
      updatedSerialNumber = _.toUpper(updatedSerialNumber);
      reservedPrefixes.forEach((keyword) => {
        if (_.startsWith(updatedSerialNumber, keyword)) errorMessages.serialNumber = MESSAGE.serialNumberError;
      });
      this.setState({ errorMessages });
    }
  };

  changeSerialNumber = (value) => {
    const regex = new RegExp('^[0-9a-zA-Z_-]+$');
    if (value && !regex.test(value)) return;
    this.setState(
      {
        updatedSerialNumber: value
      },
      this.validateInput
    );
  };

  saveSerialNumber = () => {
    const { updateSensorSerialNumber } = this.props.sensorsActions;
    updateSensorSerialNumber(
      this.state.details.serial_number,
      this.state.details.model,
      { new_serial_number: this.state.updatedSerialNumber }
    );
  };

  render() {
    const {
      details: {
        serial_number,
        type,
        model,
        last_measurement,
        machine,
        component,
        tx,
        tx_serial_number,
        orientation,
        phase,
        location,
        full_bandwidth,
        high_res,
        measurement_type,
        measurement_interval,
        transmitter_version,
        tx_reporting_interval,
        tx_poe
      },
      saveEnabled,
      error,
      highResEnabled,
      editSerialNumber,
      updatedSerialNumber,
      errorMessages
    } = this.state;
    const {
      loading,
      detailsError,
      errorList,
      metadata,
      updating,
      utcOffset,
      showOrientation,
      showPhase,
      showSamplingOptions,
      showHighPassFilterCutoff,
      primaryColor
    } = this.props;
    const details = this.state.details;

    let secondaryMessage = '';
    if (!tx_poe) {
      secondaryMessage = 'Aggressive monitoring impacts device battery life';
    }
    if (!loading && model && (!metadata || !metadata[model])) return `Metadata for the model ${model} doesn't exist`;
    if (!model || !full_bandwidth) {
      return (
        <AbsoluteLoading nobackground>
          <LoadingSvg />
        </AbsoluteLoading>
      );
    }
    return (
      <Fragment>
        {updating && (
          <AbsoluteLoading>
            <LoadingSvg />
          </AbsoluteLoading>
        )}
        {this.state.deleteModalOpen && (
          <DeleteSensorModal
            modelName={this.state.details.serial_number}
            closeModal={this.onDeleteCancel}
            deleteModel={this.onDelete}
          />
        )}
        <Container>
          {loading && !error && !detailsError && (
            <LoadingContainer>
              <LoadingSvg />
            </LoadingContainer>
          )}
         {!loading && (error || detailsError) && (
            <div>{error || detailsError} <b>(serial numbers: {errorList.join(', ')})</b></div>)
          }
          {this.state.aggressiveModalOpen && (
            <AggressiveMeasurementAlertPrompt
              message="Device will go into aggressive measurement from next reporting time"
              secondaryMessage={secondaryMessage}
              onCancel={this.onAggressiveCancel}
              onProceed={this.onSave}
              component={this.renderAggressiveModal()}
              onProceedDisabled={
                tx_reporting_interval > AGGRESSIVE_INTERVAL_THRESHOLD
              }
            />
          )}
          {this.state.openScheduler && (
            <MeasurementScheduleModal
              close={() => this.setState({ openScheduler: false })}
              editMode={this.state.editMode}
              schedule={{
                0: [],
                1: full_bandwidth.measurement.schedule,
                2:
                  highResEnabled && high_res.measurement
                    ? high_res.measurement.schedule
                    : []
              }}
              stops={{
                0: [],
                1: full_bandwidth.measurement.stops,
                2:
                  highResEnabled && high_res.measurement
                    ? high_res.measurement.stops
                    : []
              }}
              disabledRows={{
                0: true,
                1: false,
                2: !highResEnabled
              }}
              intervals={
                metadata[model].spectrum_measurements.measurement.intervals
              }
              saveSchedule={this.saveSchedule}
              utcOffset={utcOffset}
              component={this.renderAggressiveModal}
              onProceedDisabled={
                tx_reporting_interval > AGGRESSIVE_INTERVAL_THRESHOLD
              }
              secondaryMessage={secondaryMessage}
            />
          )}
          {!loading && !error && model && serial_number && (
            <Fragment>
              <HeaderContainer>
                {!editSerialNumber && (
                  <H1 onClick={() => this.setState({ editSerialNumber: true })}>
                    {serial_number}
                  </H1>
                )}
                {editSerialNumber && type !== 'VSX' && (
                  <FlexContainer>
                    <InputField
                      type="text"
                      name="serial_number"
                      value={updatedSerialNumber}
                      placeholder="Enter"
                      maxLength="100"
                      error={errorMessages.serialNumber}
                      onChange={e => this.changeSerialNumber(e.target.value)}
                    />
                    <SvgContainer
                      cursor={
                        errorMessages.serialNumber ||
                        updatedSerialNumber === serial_number ||
                        !updatedSerialNumber
                          ? 'default'
                          : 'pointer'
                      }
                      onClick={
                        errorMessages.serialNumber ||
                        updatedSerialNumber === serial_number ||
                        !updatedSerialNumber
                          ? () => {}
                          : this.saveSerialNumber
                      }
                      marginTop="1.5em"
                      padding="0 0 0 0.7em"
                    >
                      <TickSvg
                        inactive={
                          errorMessages.serialNumber ||
                          updatedSerialNumber === serial_number ||
                          !updatedSerialNumber
                        }
                        fill={primaryColor}
                      />
                    </SvgContainer>
                    <SvgContainer
                      valid
                      onClick={() =>
                        this.setState(prevState => ({
                          updatedSerialNumber: prevState.details.serial_number,
                          editSerialNumber: false
                        }))
                      }
                      marginTop="1.5em"
                      padding="0"
                    >
                      <CrossSvg width={20} height={20} fill="#888" />
                    </SvgContainer>
                  </FlexContainer>
                )}
                {editSerialNumber && type === 'VSX' && (
                  <FlexContainer>
                    <H2 padding="1em 0.3em 0 0">{`${
                      serial_number.split('-')[0]
                    }-`}
                    </H2>
                    <InputField
                      type="text"
                      name="serial_number"
                      value={updatedSerialNumber
                        .split('-')
                        .splice(1)
                        .join('-')}
                      placeholder="Enter"
                      maxLength="100"
                      error={errorMessages.serialNumber}
                      onChange={e =>
                        this.changeSerialNumber(
                          `${updatedSerialNumber.split('-')[0]}-${
                            e.target.value
                          }`
                        )
                      }
                    />
                    <SvgContainer
                      cursor={
                        errorMessages.serialNumber ||
                        updatedSerialNumber === serial_number ||
                        !updatedSerialNumber ||
                        !updatedSerialNumber.split('-')[1]
                          ? 'default'
                          : 'pointer'
                      }
                      onClick={
                        errorMessages.serialNumber ||
                        updatedSerialNumber === serial_number ||
                        !updatedSerialNumber ||
                        !updatedSerialNumber.split('-')[1]
                          ? () => {}
                          : this.saveSerialNumber
                      }
                      marginTop="1.5em"
                      padding="0 0 0 0.7em"
                    >
                      <TickSvg
                        inactive={
                          errorMessages.serialNumber ||
                          updatedSerialNumber === serial_number ||
                          !updatedSerialNumber ||
                          !updatedSerialNumber.split('-')[1]
                        }
                      />
                    </SvgContainer>
                    <SvgContainer
                      valid
                      onClick={() =>
                        this.setState(prevState => ({
                          updatedSerialNumber: prevState.details.serial_number,
                          editSerialNumber: false
                        }))
                      }
                      marginTop="1.5em"
                      padding="0"
                    >
                      <CrossSvg width={20} height={20} fill="#888" />
                    </SvgContainer>
                  </FlexContainer>
                )}
                <FlexContainer direction="row">
                  {!_.startsWith(model, 'VSX') && (
                    <RBAC
                      resource={mapComponentToResource.Sensors}
                      operations={operations.Delete}
                      yes={(
                       <FlexContainer padding="0.2em" direction="column">
                          <SvgContainer onClick={this.toggleMenuExpand}>
                            <EllipsisV size="1x" color="#9ea09a" />
                          </SvgContainer>
                          {this.state.menuExpanded && (
                            <OutsideAlerter
                              styles="align-items: center; flex-basis: 100%; margin: auto;"
                              open
                              handleClick={() => this.toggleMenuExpand()}
                            >
                              <SubMenuContainer>
                                <InlineSpan>
                                  <span onClick={() => this.deleteSensor()}>
                                    Delete
                                  </span>
                                </InlineSpan>
                              </SubMenuContainer>
                            </OutsideAlerter>
                          )}
                       </FlexContainer>
                      )}
                    />
                  )}
                  <span
                    style={{
                      cursor: 'pointer',
                      padding: '0.5em 0',
                      margin: '0 3px'
                    }}
                    onClick={this.props.close}
                  >
                    <CloseSvg width={14} height={14} />
                  </span>
                </FlexContainer>
              </HeaderContainer>
              <ResourceSection>Sensor Info</ResourceSection>
              <BorderContainer>
                <Grid columns={3} doubling stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Type</ResourceItemLabel>
                        <ResourceItem>{type}</ResourceItem>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel paddingTop="1em">
                          Model
                        </ResourceItemLabel>
                        <ResourceItem>{model}</ResourceItem>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel paddingTop="1em">
                          Transmitter
                        </ResourceItemLabel>
                        <ResourceItem
                          onClick={() => this.viewSensor(tx_serial_number)}
                          hover={!!tx}
                        >
                          {tx_serial_number || '--'}
                        </ResourceItem>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <div>
                            <ResourceItemLabel>Machine</ResourceItemLabel>
                            {this.state.editMode &&
                              this.state.associatedMachineId &&
                              !this.props.createdMachineId && (
                                <Button
                                  text
                                  fontsize="12px"
                                  onClick={this.dissociateSensor}
                                >
                                  Dissociate
                                </Button>
                            )}
                            {this.renderField(
                              'select',
                              '',
                              this.state.options.machine,
                              machine,
                              !!this.state.associatedMachineId ||
                                this.props.createdMachineId,
                              'machine',
                              true
                            )}
                          </div>
                        </div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        {this.renderField(
                          'select',
                          'Component',
                          this.state.options.component,
                          component,
                          false,
                          'component',
                          true
                        )}
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        {this.renderField(
                          'select',
                          'Location',
                          this.state.options.location ? this.state.options.location.filter(
                            l => l.component_id === component
                          ) : [],
                          location,
                          false,
                          'location',
                          true
                        )}
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        {showOrientation &&
                          this.renderField(
                            'select',
                            'Orientation',
                            metadata[model].orientation,
                            orientation,
                            false,
                            'orientation',
                            true
                          )}
                        {showPhase &&
                          this.renderField(
                            'select',
                            'Phase',
                            metadata[model].phase,
                            phase,
                            false,
                            'phase'
                          )}
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Last Measurement</ResourceItemLabel>
                        <ResourceItem>
                          {localTimeString(last_measurement)}
                        </ResourceItem>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </BorderContainer>
              {/* {this.renderField('select', 'Transmitter', this.state.options.tx, tx, true, 'tx')} */}
              <ResourceSection>Measurement Settings</ResourceSection>
              <BorderContainer>
                <Grid columns={3} doubling stackable>
                  {details.state_based_p2p && (
                    <FlexContainer marginbottom="0.5em">
                      <Info aria-hidden="true" size="1x" />
                      <TextLabel>
                        Measurements are controlled by state based measurement
                        if TX support P2P. Below measurement setting is used as
                        fallback schedule.
                      </TextLabel>
                    </FlexContainer>
                  )}

                  <Grid.Row columns={1}>
                    {!_.includes(
                      oldTxMeasurementVersion,
                      this.props.currentAccount.preferences
                        .tx_measurement_version
                    ) &&
                      transmitter_version !== '0804' && (
                        <Grid.Column>
                          <Segment>
                            <ResourceItemLabel>Schedule</ResourceItemLabel>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px'
                              }}
                            >
                              <ResourceItem>
                                {humanize(measurement_type)}
                              </ResourceItem>
                              <ScheduleButton
                                onClick={() =>
                                  this.setState({ openScheduler: true })
                                }
                                text
                                fontsize="12px"
                              >
                                {this.state.editMode ? 'Set' : 'View'}
                              </ScheduleButton>
                            </div>
                          </Segment>
                        </Grid.Column>
                    )}
                    {(this.props.currentAccount.preferences
                      .tx_measurement_version === 'v1' ||
                      transmitter_version === '0804') && (
                      <>
                        <Grid.Column>
                          <Segment>
                            {this.renderField(
                              'select',
                              'Interval',
                              metadata[model].spectrum_measurements.measurement
                                .intervals,
                              measurement_interval,
                              false,
                              'measurement_interval'
                            )}
                          </Segment>
                        </Grid.Column>
                      </>
                    )}
                  </Grid.Row>

                  <ResourceSubSection>Sampling</ResourceSubSection>
                  {showSamplingOptions && (
                    <>
                      <Grid.Row>
                        {highResEnabled && (
                          <>
                            <Grid.Column>
                              <Segment>
                                {this.renderField(
                                  'select',
                                  'High Resolution',
                                  metadata[model].spectrum_measurements.sampling
                                    .resolution[
                                      vibrationMeasurementTypeEnum.highRes
                                    ],
                                  high_res.sampling.resolution,
                                  false,
                                  'high_res.sampling'
                                )}
                              </Segment>
                            </Grid.Column>
                            <Grid.Column>
                              <Segment>
                                {this.renderField(
                                  'select',
                                  'Full Bandwidth',
                                  metadata[model].spectrum_measurements.sampling
                                    .resolution[
                                      vibrationMeasurementTypeEnum.fullBandwidth
                                    ],
                                  full_bandwidth.sampling.resolution,
                                  false,
                                  'full_bandwidth.sampling'
                                )}
                              </Segment>
                            </Grid.Column>
                          </>
                        )}
                        {!highResEnabled && (
                          <Grid.Column>
                            <Segment>
                              {this.renderField(
                                'select',
                                'Resolution',
                                metadata[model].spectrum_measurements.sampling
                                  .resolution[
                                    vibrationMeasurementTypeEnum.fullBandwidth
                                  ],
                                full_bandwidth.sampling.resolution,
                                false,
                                'full_bandwidth.sampling'
                              )}
                            </Segment>
                          </Grid.Column>
                        )}
                      </Grid.Row>
                      {showHighPassFilterCutoff && (
                        <>
                          <ResourceSubSection>
                            High Pass Filter Cutoff
                          </ResourceSubSection>
                          {highResEnabled && (
                            <>
                              <Grid.Row>
                                <Grid.Column>
                                  <Segment>
                                    {this.renderField(
                                      'select',
                                      'High Resolution',
                                      metadata[model].spectrum_measurements
                                        .high_pass_filter_cutoff[
                                          vibrationMeasurementTypeEnum.highRes
                                        ],
                                      high_res.high_pass_filter_cutoff,
                                      false,
                                      'high_res.high_pass_filter_cutoff'
                                    )}
                                  </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                  <Segment>
                                    {this.renderField(
                                      'select',
                                      'Full Bandwidth',
                                      metadata[model].spectrum_measurements
                                        .high_pass_filter_cutoff[
                                          vibrationMeasurementTypeEnum
                                            .fullBandwidth
                                        ],
                                      full_bandwidth.high_pass_filter_cutoff,
                                      false,
                                      'full_bandwidth.high_pass_filter_cutoff'
                                    )}
                                  </Segment>
                                </Grid.Column>
                              </Grid.Row>
                            </>
                          )}
                          {!highResEnabled && (
                            <>
                              <Grid.Column>
                                <Segment>
                                  {this.renderField(
                                    'select',
                                    '',
                                    metadata[model].spectrum_measurements
                                      .high_pass_filter_cutoff[
                                        vibrationMeasurementTypeEnum.fullBandwidth
                                      ],
                                    full_bandwidth.high_pass_filter_cutoff,
                                    false,
                                    'full_bandwidth.high_pass_filter_cutoff'
                                  )}
                                </Segment>
                              </Grid.Column>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </BorderContainer>

              {/* <FlexContainer margintop="5px">
                <ExclamationTriangle size="sm" />
                <TextLabel>Minimum allowed measurement interval with selected sampling is 6 hours.</TextLabel>
              </FlexContainer> */}
              {/* {details.machine && details.model === 'VSX1' && (
                  <>
                    <H2>Speed & Utilization Detection</H2>
                    <Grid.Row columns={1}>
                      <Grid.Column >
                        <Segment>
                          <ResourceItemLabel>Enable / Disable</ResourceItemLabel>
                          {details.utilization_detection ? (<H4>Enabled</H4>) : (<H4>Disabled</H4>)}
                          <FlexContainer paddingtop="10px" marginbottom="0.5em" >
                            <Info aria-hidden="true" size="1x" />
                            <TextLabel>This is an asset level setting and can be changed by going to the
                              Asset
                              <Link to={`/machines/${details.machine}/info`} >
                                <Button text style={{ padding: '0', margin: '0' }}>
                                  <span style={{ padding: '0 0.3em', display: 'inline-block' }}>Info</span>
                                </Button>
                              </Link>
                              tab under Settings.
                            </TextLabel>
                          </FlexContainer>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
              */}
              {/* <H2>Reporting</H2>
            <FlexContainer>
              <ExclamationTriangle size="sm" />
              <TextLabel>{`Reporting interval to be defined in Transmitter (${tx_serial_number || '--'})`}</TextLabel>
            </FlexContainer> */}
              {/* Overall measurement settings, uncomment after it gets implemented */}
              {/* <Labelcontainer>
                <Label grey fontsize="14px" marginbottom="0" paddingtop="0" width="max-content">
                  Overalls Measurement Settings
                </Label>
              </Labelcontainer>
              <H2>Measurement</H2>
              {this.renderField('select', 'Type', this.state.options.spectrum_measurement_type, spectrum_measurement_type)}
              <H2>Reporting</H2>
              {this.renderField('select', 'Interval', this.state.options.overall_reporting_interval)} */}
              {this.state.editMode && (
                <ButtonsContainer>
                  <SaveButton
                    disabled={!saveEnabled}
                    onClick={this.onClickCheckAggressiveSave}
                  >
                    Save
                  </SaveButton>
                  <SaveButton
                    secondary="#3C3D3A"
                    disabled={!saveEnabled}
                    onClick={this.onCancel}
                  >
                    Cancel
                  </SaveButton>
                </ButtonsContainer>
              )}
            </Fragment>
          )}
        </Container>
        {this.state.changeFilterModal && (
          <Modal
            width="40%"
            close={() =>
              this.setState({
                changeFilterModal: false,
                changeFilterSerialNumber: null
              })
            }
          >
            <CenterText>
              The sensor details cannot be displayed because there is a filter
              applied on this list. Would you like to remove the filter and
              proceed?
            </CenterText>
            <FlexContainer justifyContent="center">
              <ButtonsContainer>
                <Button width="70px" onClick={this.clearAppliedFilters}>
                  Ok
                </Button>
                <Button
                  secondary="#3C3D3A"
                  onClick={() =>
                    this.setState({
                      changeFilterModal: false,
                      changeFilterSerialNumber: null
                    })
                  }
                >
                  Cancel
                </Button>
              </ButtonsContainer>
            </FlexContainer>
          </Modal>
        )}
      </Fragment>
    );
  }
}

VectorSensor.propTypes = {
  sensor: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  machines: PropTypes.array,
  utcOffset: PropTypes.number.isRequired,
  appliedFilters: PropTypes.array.isRequired,
  removeAppliedFilters: PropTypes.func.isRequired,
  createdMachineId: PropTypes.number,
  showOrientation: PropTypes.bool.isRequired,
  showPhase: PropTypes.bool.isRequired,
  showSamplingOptions: PropTypes.bool.isRequired,
  showHighPassFilterCutoff: PropTypes.bool.isRequired
};

VectorSensor.defaultProps = {
  machines: [],
  createdMachineId: null
};

const mapStateToProps = state => ({
  items: state.sensors.items.object,
  metadata: state.sensors.metadata.metadata,
  currentAccount: state.currentAccount,
  details: state.sensors.details.details,
  loading: state.sensors.details.loading,
  detailsError: state.sensors.details.error,
  updating: state.sensors.details.updating,
  errorList: state.sensors.details.errorList,
  primaryColor: state.companyReducer.partner.theme.primaryColor
});

const mapDispatchToProps = dispatch => ({
  sensorsActions: bindActionCreators(sensorsActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true
})(VectorSensor);
