import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BodyClassName from 'react-body-classname';

import { getActivePartnerBasedOnDomain } from 'home/DomainName/DomainConstants';
import { userActions } from '../../onboarding';
import { getCurrentAccount } from '../../onboarding/user.actions';
import { history } from '../helpers';
import LoadingSvg from '../images/LoadingSvg';

class PrivateRoute extends React.Component {
  componentDidMount() {
    const title = this.props.companyReducer.partner.title;
    document.title = `${title}`;

    const { userState, getUser, getRBACPermissions, getCurrentAccount } = this.props;

    if (!userState || !userState.user || userState.error) {
      getUser();
      getCurrentAccount();
      getRBACPermissions();
    }
  }

  render() {
    const { component: Component, userState, currentAccount, ...rest } = this.props;
    if (userState.error) {
      history.replace({
        pathname: '/login',
        state: this.props.location
      });
      return null;
    }

    return (
      <Route
        {...rest}
        render={(props) => {
          if (userState.user && currentAccount.name) {
            return (
              <BodyClassName className="protected-page">
                <Component {...props} />
              </BodyClassName>
            );
          }
          return (
            <div className="full-screen-center">
              <LoadingSvg />
            </div>
          );
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  const { user, currentAccount, companyReducer } = state;
  return { userState: user, currentAccount, companyReducer };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser: userActions.getUser,
      getRBACPermissions: userActions.getRBACPermissions,
      getCurrentAccount
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
