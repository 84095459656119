import styled from 'styled-components';

import InputField_T from '../../atoms/InputField';

const InputField = styled(InputField_T)`
  border: none;
  margin: auto;
  ${props => props.minWidth && `min-width: ${props.minWidth}`}
`;

export default styled(InputField)``;
