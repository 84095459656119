import { combineReducers } from 'redux';

import { TYPES } from './constants';

const resources = (state = {}, action) => {
  switch (action.type) {
    case TYPES.GET_RESOURCES_BASED_ON_TYPE_SUCCESS:
      return {
        ...state,
        [action.resource_type]: action.resources
      };
    default:
      return state;
  }
};

export const common = combineReducers({
  resources
});
