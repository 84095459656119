import React from 'react';
import styled from 'styled-components';

import Container from '../../atoms/Container';
import Text from '../../../typography/Text/Text';

import CrossSvg from '../../../images/CrossSvg';

const InlineModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 2;
`;

const InlineModalContainer = styled(Container)`
  position: absolute;
  padding: 1em;
  min-height: ${props => props.height}px;
  width: ${props => props.width}px;
  display: flex;
  align-items: flex-start;
  ${props => `
    left: calc(${props.x}px + 34px);
    top: calc(${props.y}px - ${props.height / 2}px);
  `}
  ${props => (props.alignLeft ? `
    left: calc(${props.x}px - ${props.width}px - 20px);
  ` : '')}
  box-shadow: 0 0 8px 0 rgba(60,61,58,0.24);
  z-index: 10;
  ${Text} {
    padding-bottom: 1em;
  }
  &:after {
    position: absolute;
    top: 90px;
    content: '';
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    ${props => (props.alignLeft ? `
      border-left: 10px solid white;
      right: -8px;
    ` : `
      border-right: 10px solid white;
      left: -8px;
    `)}
  }
`;

const CrossContainer = styled.div`
  position: absolute;
  right: 0;
  margin-right: 1em;
  top: 0;
  margin-top: 1em;
  cursor: pointer;
`;

const InlineModal = ({
  close,
  children,
  ...rest
}) => (
  <>
    <InlineModalContainer {...rest}>
      <CrossContainer direction="column" alignItems="flex-end" onClick={close}>
        <CrossSvg width={20} height={20} fill="#888" />
      </CrossContainer>
      {children}
    </InlineModalContainer>
    <InlineModalOverlay onClick={close} />
  </>
);

export default InlineModal;
