import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';

import { getMachineDetails } from 'home/Machines/MachineDetails/machineDetails.actions';
import { getTaskDetails } from 'home/HomePage/actions/tasks.actions';
import { getNoteDetails } from 'home/Activity/actions/activity.actions';
import { uploadAttachment } from 'home/HomePage/actions/attachments.actions';
import { alertActions } from '../../../../../alert';
import { axiosInstance, ENDPOINT } from '../../../../../common/constants';
import { handleResponse } from '../../../../../common/helpers';
import machineChartsTypes from './machineCharts.types';
import { formatChartData, convertTimestringsToDateObjects } from '../../../../../common/utils';


// TODO: move this to machine level actions, will be done when machine page reworked
export const addNote = (machineId, noteParams) => {
  const request = () => ({ type: machineChartsTypes.CREATE_MACHINE_NOTE_REQUEST });
  const success = () => ({ type: machineChartsTypes.CREATE_MACHINE_NOTE_SUCCESS });
  const failure = error => ({ type: machineChartsTypes.CREATE_MACHINE_NOTE_FAILURE, error });

  const params = noteParams;
  params.machine_id = machineId;
  params.date = moment(params.date).format('YYYY-MM-DD');

  return (dispatch, getState) => {
    dispatch(request());

    return handleResponse(axiosInstance.post(ENDPOINT.ADD_NOTE, { ...params }))
      .then(
        (res) => {
          const files = getState().attachments.files && getState().attachments.files.note;

          dispatch(success());

          if (!params.task_id) dispatch(getMachineDetails(params.machine_id));
          else dispatch(getTaskDetails(params.task_id));

          if (!_.isEmpty(files)) {
            dispatch(uploadAttachment('note', res.id, files, 0));
          }

          toastr.success('Note created successfully');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        },
      );
  };
};

export const updateNote = (noteId, params) => {
  const request = () => ({ type: machineChartsTypes.UPDATE_MACHINE_NOTE_REQUEST });
  const success = () => ({ type: machineChartsTypes.UPDATE_MACHINE_NOTE_SUCCESS });
  const failure = () => ({ type: machineChartsTypes.UPDATE_MACHINE_NOTE_FAILURE });

  return (dispatch) => {
    dispatch(request());

    return handleResponse(axiosInstance.put(ENDPOINT.NOTE(noteId), { ...params }))
      .then(
        (res) => {
          dispatch(success(res.result));
          if (res.task_id) dispatch(getTaskDetails(res.task_id));
          else dispatch(getNoteDetails(noteId, 'note'));
          toastr.success('Note updated successfully');
          return res;
        },
        (error) => {
          dispatch(failure(error));
          toastr.error(error.message);
          throw error;
        }
      );
  };
};

// spectrum features actions
export const getSpectrumFeatures = (tagId, configId) => {
  const request = () => ({ type: machineChartsTypes.spectrumFeature.GET_FEATURES_REQUEST });
  const success = features => ({ type: machineChartsTypes.spectrumFeature.GET_FEATURES_SUCCESS, configId, features });
  const failure = () => ({ type: machineChartsTypes.spectrumFeature.GET_FEATURES_FAILURE, configId });

  return (dispatch) => {
    dispatch(request());

    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUM_FEATURES(tagId)))
      .then(
        (res) => {
          const features = res.features.map(feature => ({ ...feature, freq_units: res.freq_units }));
          dispatch(success(features));
        },
        (error) => {
          dispatch(failure());
        },
      );
  };
};

export const initiateCustomFeatureTrend = (tagId, featureId, params) => {
  const failure = error => ({ type: machineChartsTypes.spectrumFeature.GET_FEATURES_FAILURE, error });

  return (dispatch) => {
    dispatch(alertActions.clear());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_CUSTOM_FEATURE_TREND(tagId), { params })).then(
      (res) => {
        dispatch(getSpectrumFeatureTrendFromTask(res.task_id, featureId));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
      }
    );
  };
};

export const initiateSpectrumFeatureTrend = (featureId, params) => {
  const failure = error => ({ type: machineChartsTypes.spectrumFeature.GET_FEATURES_FAILURE, error });

  return (dispatch) => {
    dispatch(alertActions.clear());
    // return service.getSpectrumFeatureTrend(featureId, params)
    return handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUM_FEATURE_TREND(featureId), { params }))
      .then(
        (res) => {
          dispatch(getSpectrumFeatureTrendFromTask(res.task_id, featureId));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        },
      );
  };
};

export const getSpectrumFeatureTrendFromTask = (taskId, featureId) => {
  const success = (result, amp_units) => ({
    type: machineChartsTypes.spectrumFeature.GET_FEATURE_TREND_SUCCESS,
    featureId,
    result,
    amp_units
  });
  const failure = error => ({ type: machineChartsTypes.spectrumFeature.GET_FEATURE_TREND_FAILURE, error });

  return dispatch =>
    handleResponse(axiosInstance.get(ENDPOINT.GET_TAG_SPECTRUM_FEATURE_TREND_TASK(taskId)))
    // service.getSpectrumFeatureTrendFromTask(taskId)
      .then(
        (res) => {
          if (res.state && res.state === 'SUCCESS') {
            const trendFeatureData = formatChartData(
              res.result.amplitude,
              convertTimestringsToDateObjects(res.result.timestamp)
            );
            dispatch(success(trendFeatureData, res.result.amp_units));
          } else {
            setTimeout(() => dispatch(getSpectrumFeatureTrendFromTask(taskId, featureId)), 1200);
          }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        },
      );
};

export const createSpectrumFeature = (tagId, params, configId) => {
  const request = () => ({ type: machineChartsTypes.spectrumFeature.CREATE_FEATURE_REQUEST });
  const success = feature => ({ type: machineChartsTypes.spectrumFeature.CREATE_FEATURE_SUCCESS, feature, configId });
  const failure = error => ({ type: machineChartsTypes.spectrumFeature.CREATE_FEATURE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.post(ENDPOINT.CREATE_TAG_SPECTRUM_FEATURES, { ...params, tag_id: tagId }))
      .then(
        (res) => {
          dispatch(success(res));
        },
        (error) => {
          dispatch(failure(error));
        },
      );
  };
};

export const updateSpectrumFeature = (featureId, params, configId) => {
  const request = () => ({ type: machineChartsTypes.spectrumFeature.UPDATE_FEATURE_REQUEST });
  const success = res => ({ type: machineChartsTypes.spectrumFeature.UPDATE_FEATURE_SUCCESS, feature: res, configId });
  const failure = error => ({ type: machineChartsTypes.spectrumFeature.UPDATE_FEATURE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());

    return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_TAG_SPECTRUM_FEATURES(featureId), params))
      .then(
        (res) => {
          dispatch(success(res));
        },
        (error) => {
          dispatch(failure(error));
        },
      );
  };
};

export const deleteSpectrumFeature = (featureId, configId) => {
  const request = () => ({ type: machineChartsTypes.spectrumFeature.DELETE_FEATURE_REQUEST });
  const success = featureId => ({ type: machineChartsTypes.spectrumFeature.DELETE_FEATURE_SUCCESS, featureId, configId });
  const failure = error => ({ type: machineChartsTypes.spectrumFeature.DELETE_FEATURE_FAILURE, error });

  return (dispatch) => {
    dispatch(request(featureId));

    return handleResponse(axiosInstance.delete(ENDPOINT.UPDATE_TAG_SPECTRUM_FEATURES(featureId)))
    // return service.deleteSpectrumFeature(featureId)
      .then(
        () => {
          dispatch(success(featureId));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        },
      );
  };
};

export const updateTag = (tagId, params) => {
  const request = () => ({ type: machineChartsTypes.UPDATE_TAG_REQUEST });
  const success = () => ({ type: machineChartsTypes.UPDATE_TAG_SUCCESS, params, tagId });
  const failure = error => ({ type: machineChartsTypes.UPDATE_TAG_FAILURE, error });

  return (dispatch) => {
    dispatch(request());

    return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_TAG(tagId), params))
      .then(
        () => {
          dispatch(success(tagId));
          return tagId;
        },
        (error) => {
          dispatch(failure(error));
          throw error.message;
        },
      );
  };
};

export const getForcingFrequencies = (tagId, timestamp) => {
  const request = () => ({ type: machineChartsTypes.spectrumFeature.GET_FORCING_FREQUENCIES_REQUEST });
  const success = (features, tagId) => ({ type: machineChartsTypes.spectrumFeature.GET_FORCING_FREQUENCIES_SUCCESS, features, tagId });
  const failure = error => ({ type: machineChartsTypes.spectrumFeature.GET_FORCING_FREQUENCIES_FAILURE, tagId, error });
  return (dispatch) => {
    dispatch(request());

    return handleResponse(axiosInstance.get(ENDPOINT.GET_FORCING_FREQUENCIES(tagId), { params: { timestamp } })).then(
      (res) => {
        dispatch(success(res.features, tagId));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        // throw Error(error);
      }
    );
  };
};
