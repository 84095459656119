/* eslint-disable space-before-blocks */
import _ from 'lodash';

import * as utils from '../utils/assetHierarchyUtils';
import assetDetailsConstants from '../constants/assetHierarchy.constants';
import { ENVELOPE_ACTIONS } from '../constants/envelope.constants';
import { OVERALL_ALARM_ACTIONS } from '../constants/overallAlarm.constants';
import { getTagMatchKey } from '../../../common/components/Chart/utils/helpers';

const initialState = {
  hierarchy: [],
  sites: []
};


const populateTreeFromInfo = (details, reload = false, prevPath) => {
  const nodes = [{
    children: [],
    id: 1,
    expanded: true,
    depth: 0,
    active: null,
    node_type: 'site',
    node_name: '',
    node_details: {},
    condition: ''
  }];
  const rootNode = nodes[0];
  rootNode.id = details.id;
  rootNode.node_name = details.name;
  rootNode.node_type = details.type;
  const innerNodes = (children, childObjects, depth = 1) => {
    if (_.isEmpty(childObjects)) return;
    childObjects.forEach((obj) => {
      const child = {
        children: [],
        id: obj.id,
        expanded: false,
        depth,
        node_type: obj.type,
        node_sub_type: obj.sub_type,
        node_name: obj.name,
        node_details: {}
      };
      if (obj.type === 'area') {
        if (obj.id === prevPath.area_id) child.expanded = true;
        childObjects = [obj.machines, obj.areas];
      } else if (obj.type === 'machine') {
        child.node_details = {
          ...child.node_details,
          condition: obj.condition,
          has_unreachable_sensors: obj.has_unreachable_sensors,
          has_instrumentation_task: obj.has_instrumentation_task
        };
        if (obj.id === prevPath.machine_id) child.expanded = true;
        const allCharts = {
          children: [],
          id: obj.id,
          expanded: false,
          depth: depth + 1,
          node_type: 'machine_charts',
          node_name: 'All Charts',
          node_details: {}
        };
        const dataOverview = {
          children: [],
          id: obj.id,
          expanded: false,
          depth: depth + 1,
          node_type: 'data_overview',
          node_name: 'Data Overview',
          node_details: {}
        };
        child.children.push(dataOverview);
        child.children.push(allCharts);

        childObjects = [obj.components, obj.configs];
      } else if (obj.type === 'component') {
        child.node_details = {
          ...child.node_details,
          component_type: obj.component_type,
          machine_id: obj.machine_id
        };
        if (obj.id === prevPath.component_id) child.expanded = true;
        childObjects = [obj.configs, obj.locations, obj.components];
      } else if (obj.type === 'location') {
        if (obj.id === prevPath.machine_id) child.expanded = true;
        childObjects = [obj.configs];
        child.bearing = obj.bearing;
      } else if (obj.type === 'config') {
        childObjects = [];
        child.default = obj.default;
        child.active = obj.active;
        child.tag_type = obj.tag_type;
        child.tag_id = obj.tag_id;
        child.direction = obj.direction;
        child.phase = obj.phase;
      }
      childObjects.forEach(c => innerNodes(child.children, c, depth + 1));
      children.push(child);
    });
  };
  innerNodes(rootNode.children, details.areas);
  const sites = populateSites(nodes);
  return { hierarchy: nodes, sites };
};

export const populateSites = (hierarchy, sites = []) => {
  for (let i = 0; i < hierarchy.length; i++) {
    sites.push({
      site_id: hierarchy[i].id,
      machines: []
    });
  }
  return sites;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case assetDetailsConstants.REFRESH_HIERARCHY:
      return {
        ...state,
        hierarchy: action.hierarchy
      };
    case assetDetailsConstants.GET_ASSET_HIERARCHY_REQUEST:
      return {
        ...state,
        hierarchy: {
          loading: true
        }
      };
    case assetDetailsConstants.GET_ASSET_HIERARCHY_SUCCESS: {
      const { hierarchy, sites } = populateTreeFromInfo(action.data, action.reload, action.prevPath);
      if (action.reload) {
        return {
          ...state,
          hierarchy,
          sites
        };
      }
      return {
        ...state,
        hierarchy
      };
    }
    case assetDetailsConstants.GET_ASSET_HIERARCHY_FAILURE:
      return {
        ...state,
        hierarchy: {
          loading: false,
          error: action.error.message
        }
      };
    case assetDetailsConstants.GET_CHARTS_DATA_REQUEST: {
      const { machine_id, site_id, config_id, pagination, uniqueKey } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== config_id) return c;

                  // uniqueKey is used to uniquely identify a set of pagination calls,
                  // For a new set of calls, a new uniqueKey is used that resets the keepOldData key
                  const resetKeepOldData = c.uniqueKey !== uniqueKey;
                  if (pagination && c.keepOldData) {
                    return {
                      ...c,
                      keepOldData: !resetKeepOldData,
                      trends_data: {
                        ...c.trends_data,
                        loading: resetKeepOldData,
                      },
                    };
                  }
                  return {
                    ...c,
                    config_id,
                    trends_data: {
                      ...c.trends_data,
                      loading: true,
                    },
                    uniqueKey
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_CHARTS_DATA_SUCCESS: {
      const { machine_id, site_id, config_id, pagination, noOfRequests, uniqueKey } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== config_id) return c;

                  // if success's uniqueKey doesn't match the request's unique key, ignore and continue
                  if (c.uniqueKey !== uniqueKey) return c;

                  // keepOldData decides whether to append new data to old data or discard the old data and keep the new
                  // if pagination is true, from the second date range onwards keepOldData will be true. So new data will be appended to old

                  // requestsCompleted is used to keep track of no. of finished date ranges for which data has been fetched
                  // noOfRequests is no. of dateRanges (i.e. no of pages)
                  // if requestsCompleted == noOfRequests, reset the keepOldData key so that new requests don't append the data
                  if (pagination && c.keepOldData) {
                    return {
                      ...c,
                      config_id,
                      keepOldData: (c.requestsCompleted + 1 === noOfRequests) ? false : c.keepOldData,
                      requestsCompleted: c.requestsCompleted + 1,
                      trends_data: _.map(c.trends_data, (td, i) => {
                        if (!_.isObject(td)) return td; // skip the loading key value pair
                        return {
                          ...td,
                          trend_data: [...action.data[i].trend_data, ...td.trend_data].sort(
                            (a, b) => (a.x - b.x)
                          )
                        };
                      })
                    };
                  }

                  // order trend data by configs
                  let trends_data = [];
                  if (action.data.length > 1) {
                    c.trends_config.forEach((cfg) => {
                      const c_key = getTagMatchKey(cfg);
                      const t_data = action.data.find(d => getTagMatchKey(d) === c_key);
                      trends_data.push(t_data);
                    });
                  } else {
                    trends_data = action.data;
                  }

                  return {
                    ...c,
                    config_id,
                    trends_data,
                    keepOldData: pagination && (noOfRequests > 1),
                    time_range: !pagination ? action.time_range : undefined,
                    requestsCompleted: pagination && 1
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_CHARTS_DATA_FAILURE: {
      const { machine_id, site_id, config_id } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== config_id) return c;
                  return {
                    ...c,
                    config_id,
                    trends_data: {
                      ...c.trends_data,
                      loading: false,
                      error: action.error.message
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_TAG_WAVEFORM_REQUEST: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    configId,
                    waveform: {
                      ...c.waveform,
                      loading: true
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_TAG_WAVEFORM_SUCCESS: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    waveform: action.waveform
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_TAG_WAVEFORM_FAILURE: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    waveform: {
                      loading: false,
                      error: action.error.message
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_TAG_SPECTRUM_REQUEST: {
      const { machine_id, site_id, configId, isMultiline } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  if (isMultiline) {
                    if (!c.spectrums) {
                      return {
                        ...c,
                        spectrums: []
                      };
                    }
                    return {
                      ...c,
                      spectrums: [...c.spectrums]
                    };
                  }
                  return {
                    ...c,
                    spectrum: {
                      ...c.spectrum,
                      loading: true
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_TAG_SPECTRUM_SUCCESS: {
      const { machine_id, site_id, configId, isMultiline } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  if (isMultiline) {
                    if (!c.spectrums) c.spectrums = [];
                    const idx = c.spectrums.findIndex(s => s.tag_id === action.spectrum.tag_id && s.feature === action.spectrum.feature && s.amp_type === action.spectrum.amp_type);
                    if (idx > -1) {
                      const spectrums = c.spectrums;
                      spectrums[idx] = action.spectrum;
                      return {
                        ...c,
                        spectrums
                      };
                    }
                    return {
                      ...c,
                      spectrums: [...c.spectrums, action.spectrum]
                    };
                  }
                  return {
                    ...c,
                    spectrum: action.spectrum
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_TAG_SPECTRUM_FAILURE: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    spectrum: {
                      loading: false,
                      error: action.error.message
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_TAG_CEPSTRUM_REQUEST: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    configId,
                    cepstrum: {
                      ...c.cepstrum,
                      loading: true
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_TAG_CEPSTRUM_SUCCESS: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    cepstrum: action.cepstrum
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_TAG_CEPSTRUM_FAILURE: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    cepstrum: {
                      loading: false,
                      error: action.error.message
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_CHARTS_CONFIG_REQUEST: {
      const { site_id, machine_id } = action;
      const info = utils.getMachinesUpdatedInfo(machine_id, site_id, state.sites, 'configs');
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: info
          };
        })
      };
    }
    case assetDetailsConstants.GET_CHARTS_CONFIG_SUCCESS: {
      const { site_id, machine_id } = action;
      if (state.sites.length === 1) {
        const machine = {};
        machine.machine_id = machine_id;
        machine.configs = _.map(action.configs, c => ({
          ...c,
          id: c.config_id
        }));
        const site = {};
        site.site_id = site_id;
        site.machines = [machine];
        return {
          ...state,
          sites: [site]
        };
      }
      return {
        ...state,
        sites: state.sites.length > 0 ? state.sites.map((item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: item.machines.map((machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: action.configs.map(c => ({
                  ...c,
                  id: c.config_id
                }))
              };
            })
          };
        }) : state.sites // If no sites, return the original state.sites
      };
    }

    case assetDetailsConstants.GET_CHARTS_CONFIG_FAILURE: {
      const { site_id, machine_id } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: {
                  loading: false,
                  error: action.error.message
                }
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_HIERARCHY_CHARTS_CONFIG_METADATA_REQUEST:
      return {
        ...state,
        configMetadata: {
          loading: true
        }
      };
    case assetDetailsConstants.GET_HIERARCHY_CHARTS_CONFIG_METADATA_SUCCESS:
      return {
        ...state,
        configMetadata: action.metadata
      };
    case assetDetailsConstants.GET_HIERARCHY_CHARTS_CONFIG_METADATA_FAILURE:
      return {
        ...state,
        configMetadata: {
          loading: false,
          error: action.error
        }
      };
    case assetDetailsConstants.PUT_HIERARCHY_CHARTS_CONFIG_REQUEST:
      return {
        ...state
      };
    case assetDetailsConstants.PUT_HIERARCHY_CHARTS_CONFIG_SUCCESS: {
      const { machine_id, site_id, config_id } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== config_id) return c;
                  return {
                    ...c,
                    name: action.name,
                    trends_config: action.trends_config
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.PUT_HIERARCHY_CHARTS_CONFIG_FAILURE:
      return {
        ...state
      };

    case assetDetailsConstants.DELETE_HIERARCHY_CHARTS_CONFIG_REQUEST:
      return {
        ...state
      };
    case assetDetailsConstants.DELETE_HIERARCHY_CHARTS_CONFIG_SUCCESS: {
      const { machine_id, site_id } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.filter(machine.configs, c => c.config_id !== action.config_id)
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.DELETE_HIERARCHY_CHARTS_CONFIG_FAILURE:
      return {
        ...state
      };
    case assetDetailsConstants.GET_MACHINE_CHARTS_TAGS_REQUEST: {
      const { site_id, machine_id } = action;
      const info = utils.getMachinesUpdatedInfo(machine_id, site_id, state.sites, 'tags');
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: info
          };
        })
      };
    }
    case assetDetailsConstants.GET_MACHINE_CHARTS_TAGS_SUCCESS: {
      const { site_id, machine_id } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                tags: action.tags
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_MACHINE_CHARTS_TAGS_FAILURE: {
      const { site_id, machine_id } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                tags: {
                  error: action.error.message
                }
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.UPDATE_HIERARCHY_TAG_REQUEST:
      return {
        ...state
      };
    case assetDetailsConstants.UPDATE_HIERARCHY_TAG_SUCCESS: {
      const { site_id, machine_id } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                tags: _.map(machine.tags, (t) => {
                  if (t.id !== action.tagId) return t;
                  return {
                    ...t,
                    bandpass_lower_cutoff: action.params.bandpass_lower_cutoff === 'undefined' ? t.bandpass_lower_cutoff : action.params.bandpass_lower_cutoff,
                    bandpass_upper_cutoff: action.params.bandpass_upper_cutoff === 'undefined' ? t.bandpass_upper_cutoff : action.params.bandpass_upper_cutoff,
                    warning_threshold: action.params.warning_threshold === 'undefined' ? t.warning_threshold : action.params.warning_threshold,
                    critical_threshold: action.params.critical_threshold === 'undefined' ? t.critical_threshold : action.params.critical_threshold
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.UPDATE_HIERARCHY_TAG_FAILURE:
      return {
        ...state
      };
    case assetDetailsConstants.GET_TAG_WATERFALL_SPECTRUM_REQUEST: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    waterfall_spectrum: {
                      loading: true
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_TAG_WATERFALL_SPECTRUM_SUCCESS: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    waterfall_spectrum: action.data
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_TAG_WATERFALL_SPECTRUM_FAILURE: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    waterfall_spectrum: {
                      loading: false,
                      error: action.error.message
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_SPECTRUM_FEATURES_SUCCESS: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    spectrumFeatures: { items: action.features }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_SPECTRUM_FEATURES_FAILURE: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    spectrumFeatures: { items: [] }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_FORCING_FREQUENCY_SUCCESS: {
      const { machine_id, site_id, tagId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                tags: _.map(machine.tags, (t) => {
                  if (t.id !== tagId) return t;
                  return {
                    ...t,
                    forcingFrequencies: { items: action.features }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_FORCING_FREQUENCY_FAILURE: {
      const { machine_id, site_id, tagId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                tags: _.map(machine.tags, (t) => {
                  if (t.id !== tagId) return t;
                  return {
                    ...t,
                    forcingFrequencies: { items: [], error: action.error.message }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.CREATE_SPECTRUM_FEATURE_SUCCESS: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    spectrumFeatures: { items: [...(c.spectrumFeatures.items || []), action.feature] }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.UPDATE_SPECTRUM_FEATURE_SUCCESS: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    spectrumFeatures: {
                      items: _.map(c.spectrumFeatures.items, (i) => {
                        if (i.id !== action.feature.id) return i;
                        return action.feature;
                      })
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.DELETE_SPECTRUM_FEATURE_SUCCESS: {
      const { machine_id, site_id, configId } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                configs: _.map(machine.configs, (c) => {
                  if (c.config_id !== configId) return c;
                  return {
                    ...c,
                    spectrumFeatures: {
                      items: _.filter(c.spectrumFeatures.items, i => i.id !== action.featureId)
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case assetDetailsConstants.GET_SPECTRUM_FEATURE_TREND_SUCCESS: {
      const { machine_id, site_id, featureId, result, amp_units } = action;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                featureTrend: { featureId, data: result, amp_units }
              };
            })
          };
        })
      };
    }
    // envelope spectra handling
    case ENVELOPE_ACTIONS.GET_TAG_ENVELOPE_SPECTRUMS_REQUEST: {
      const { site_id, machine_id, tag_id, bin_no } = action.payload;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                tags: _.map(machine.tags, (tag) => {
                  if (tag.id !== tag_id) return tag;
                  return {
                    ...tag,
                    envelope_spectrums: {
                      ...tag.envelope_spectrums,
                      [`bin_${bin_no}`]: {
                        data: { items: [] },
                        loading: true
                      }
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case ENVELOPE_ACTIONS.GET_TAG_ENVELOPE_SPECTRUMS_SUCCESS: {
      const { site_id, machine_id, tag_id, bin_no, data } = action.payload;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                tags: _.map(machine.tags, (tag) => {
                  if (tag.id !== tag_id) return tag;
                  return {
                    ...tag,
                    envelope_spectrums: {
                      ...tag.envelope_spectrums,
                      [`bin_${bin_no}`]: {
                        data: { ...data },
                        loading: false
                      }
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case ENVELOPE_ACTIONS.GET_TAG_ENVELOPE_SPECTRUMS_FAILURE: {
      const { site_id, machine_id, tag_id, bin_no, error } = action.payload;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                tags: _.map(machine.tags, (tag) => {
                  if (tag.id !== tag_id) return tag;
                  return {
                    ...tag,
                    envelope_spectrums: {
                      ...tag.envelope_spectrums,
                      [`bin_${bin_no}`]: {
                        data: { items: [] },
                        loading: false,
                        error: error.message
                      }
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case ENVELOPE_ACTIONS.EXCLUDE_ENVELOPE_SPECTRUMS: {
      const { site_id, machine_id, tag_id, timestamps, bin_no } = action.payload;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                tags: _.map(machine.tags, (tag) => {
                  if (tag.id !== tag_id) return tag;
                  return {
                    ...tag,
                    envelope_spectrums: {
                      ...tag.envelope_spectrums,
                      [`bin_${bin_no}`]: {
                        ...tag.envelope_spectrums[`bin_${bin_no}`],
                        data: {
                          items: tag.envelope_spectrums[`bin_${bin_no}`].data.items.map((sp) => {
                            let exclude = false;
                            if (timestamps.includes(sp.timestamp)) exclude = true;
                            return { ...sp, exclude };
                          })
                        }
                      }
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    // overall alarm trends handling
    case OVERALL_ALARM_ACTIONS.GET_TAG_TREND_REQUEST: {
      const { site_id, machine_id, tag_id } = action.payload;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                tags: _.map(machine.tags, (tag) => {
                  if (tag.id !== tag_id) return tag;
                  return {
                    ...tag,
                    trends: {
                      data: {
                        ...(tag.trends && tag.trends.data ? tag.trends.data : {})
                      },
                      loading: true,
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case OVERALL_ALARM_ACTIONS.GET_TAG_TREND_SUCCESS: {
      const { site_id, machine_id, tag_id, packet } = action.payload;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                tags: _.map(machine.tags, (tag) => {
                  if (tag.id !== tag_id) return tag;
                  return {
                    ...tag,
                    trends: {
                      data: {
                        ...(tag.trends && tag.trends.data ? tag.trends.data : {}),
                        ...packet
                      },
                      loading: false
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    case OVERALL_ALARM_ACTIONS.GET_TAG_TREND_FAILURE: {
      const { site_id, machine_id, tag_id, error } = action.payload;
      return {
        ...state,
        sites: _.map(state.sites, (item) => {
          if (item.site_id !== site_id) return item;
          return {
            ...item,
            machines: _.map(item.machines, (machine) => {
              if (machine.machine_id !== machine_id) return machine;
              return {
                ...machine,
                tags: _.map(machine.tags, (tag) => {
                  if (tag.id !== tag_id) return tag;
                  return {
                    ...tag,
                    trends: {
                      data: {
                        ...(tag.trends && tag.trends.data ? tag.trends.data : {})
                      },
                      loading: false,
                      error: error.message
                    }
                  };
                })
              };
            })
          };
        })
      };
    }
    default:
      return state;
  }
};
