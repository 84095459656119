import React, { useState } from 'react';
import styled from 'styled-components';
import { SlidersIcon } from 'common/images/FaIcons';
import InlineModal from 'common/components/molecules/InlineModal';
import OutsideAlerter from 'common/OutsideAlerter';
import fonts from 'common/styles/fonts';
import colors from 'common/styles/colors';
import { FILTER_LEFT_MARGIN } from '../atoms/SearchFilters';
import { FlexContainer } from '../atoms';


const MoreFiltersContainer = styled(FlexContainer).attrs({
  alignItems: 'center'
})`
  ${fonts.boldLabel}
  white-space: nowrap;
  margin-left: ${FILTER_LEFT_MARGIN}px;
  margin-right: 2px;
  padding: 8px 12px;
  background-color: ${colors.greyXXL};
  border-radius: 8px;
  cursor: pointer;
  color: ${colors.greyXXD};
  position: relative;
  font-size: 11px;

  .filter-container {
    margin-left: 0;
  }

  svg {
    margin-right: 6px;
    width: 14px;
    height: 14px;
  }
`;

const MoreFilters = ({ filters }) => {
  const [itemMenuOpen, setItemMenuOpen] = useState(false);

  return (
    <MoreFiltersContainer
      className="filter-container"
      onClick={() => setItemMenuOpen(true)}
    >
      <SlidersIcon />
      More Filters
      {itemMenuOpen && (
        <OutsideAlerter event="click" open handleClick={(e) => { e.stopPropagation(); setItemMenuOpen(false); }}>
          <InlineModal noOverlay minWidthInline="120px" marginTop="5px" noScroll close={() => setItemMenuOpen(false)}>
            <FlexContainer onClick={(e) => { e.stopPropagation(); }} direction="column">
              {filters.filter(filter => filter !== null).map(filter => (
                <div style={{ padding: '10px' }}>
                  {filter}
                </div>
              ))}
            </FlexContainer>
          </InlineModal>
        </OutsideAlerter>
      )}
    </MoreFiltersContainer>
  );
};

MoreFilters.propTypes = {
  // items: PropTypes.array.isRequired,
  // activeIndex: PropTypes.number.isRequired
};

export default MoreFilters;
