/* eslint-disable no-nested-ternary */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { ColorAccToCondition } from 'common/constants';
import { history } from 'common/helpers/history';
import NoteSvgNew from 'common/images/NoteSvgNew';
import AnalystSummarySvg from 'common/images/AnalystSummarySvg';
import { Clock, EllipsisV, Eye, EyeSlash, StopWatch, WindowClose, LineChart } from 'common/images/FaIcons';
import InputField from 'common/components/atoms/InputField';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Container_T from 'common/components/atoms/Container';
import Button from 'common/components/atoms/Button';
import UserCircle from 'common/components/atoms/UserCircle';
import Chart from 'common/components/Chart/Chart';
import Loading from 'common/components/atoms/Loading';
import InlineModal from 'common/components/molecules/InlineModal';
import RBAC from 'common/rbac/RBAC';
import {
  mapComponentToResource,
  operations
} from 'common/rbac/constants';
import { isPetasenseAdmin, isPartnerAdmin, isAnalyst } from 'common/rbac/util';

import Text_T from 'common/typography/Text/Text';
import SecondaryText_T from 'common/typography/SecondaryText/SecondaryText';
import MetadataContainer from 'common/components/atoms/MetadataContainer';
import Checkbox from 'common/components/atoms/Checkbox';
import OutsideAlerter from 'common/OutsideAlerter';
import { humanize } from 'common/helpers';
import * as utils from 'common/utils';
import * as taskActions from 'home/HomePage/actions/tasks.actions';
import * as repairActions from 'home/HomePage/actions/repairs.actions';
import * as noteActions from 'home/HomePage/actions/notes.actions';
import EditIcon from 'common/images/BearingModal/EditIcon';
import AlertPrompt from 'common/components/organisms/AlertPrompt';
import colors from 'common/styles/colors';
import Attachments from 'home/HomePage/organisms/Attachments';
import * as activityActions from '../actions/activity.actions';
import activityFormat, { Text as Text_2 } from './ActivityFormat';
import Comment from './Comment';


const readByUserCount = 4;
const iconContainerWidth = '4em';

const Container = styled(Container_T)`
  ${props => props.read && `
    background: #F7F8F6;
  `}
`;

const Text = styled(Text_T)`
  overflow-wrap: break-word;
  font-size: 13px;
  ${props => props.hover && `
    &:hover {
      color: ${props.theme.primaryColor};;
    }
  `}
  ${props => props.read && 'color: black;'}
`;
const SecondaryText = styled(SecondaryText_T)`
  font-size: 12px;
`;

const ConditionContainer = styled.div`
  display: flex;
`;

const SummaryCondition = styled.span`
  color: ${props => props.theme.colors.greyXXD};
  font-size: 13px;
  text-transform: capitalize;
  color: ${props => props.color};
  ${props => props.marginLeft && `margin-left: ${props.marginLeft}`}
  &::before {
    content: "";
    position: relative;
    bottom: 1.5px;
    right: 9px;
    display: inline-block;
    height: 8px;
    width: 8px;
    margin-left: 10px;
    border-radius: 4px;
    font-size: 19px;
    background: ${props => props.color};
  }
`;

const ConditionLabel = styled.span`
  color: grey;
  font-size: 14px;
  margin-right: 5px;
`;

const DefectLabel = styled(ConditionLabel)`
  margin-left: 20px;
`;

const SummaryDefect = styled.span`
  color: ${props => props.theme.colors.greyXXD};
  font-size: 16px;
`;

const HeaderRow = styled(FlexContainer).attrs({ alignItems: 'center' })`
  padding: 1em 1em 1em 1em;
`;

// sections of header row
const IconContainer = styled(FlexContainer).attrs({ justifyContent: 'center' })`
  flex: 0 0 ${iconContainerWidth};
  ${props => props.compact && 'margin-left: -1em;'}
`;

const ContentContainer = styled(FlexContainer).attrs({ direction: 'column', flexGrow: 1 })`
  cursor: pointer;
  ${Text}{
    ${props => !props.read && 'color: black;'}
  }
  ${Text_2}{
    ${props => !props.read && 'color: black;'}
  }
  /* ${props => props.onClick === undefined && 'cursor: default;'} */
`;

const InfoContainer = styled(FlexContainer).attrs({ direction: 'row', alignItems: 'center' })``;

const EventStateContainer = styled(FlexContainer)`
  ${SecondaryText} {
    margin-top: 0.5em;
    text-transform: capitalize;
  }
`;

const SubMenuContainer = styled.div`
  position: relative;
  margin-left: 1em;
  margin-bottom: auto;
  ${Button} {
    width: 100%;
    padding: 1em;
    color: ${props => props.theme.colors.black};
    font-size: 12px;
    &:hover {
      color: ${props => props.theme.colors.greyXD}
    }
  }
`;

const SvgContainer = styled(FlexContainer)`
  cursor: pointer;
  padding: 0.5em;
`;

const ViewChartBtn = styled(Button)`
  margin-left: 10px;
`;

// opened details section
const DetailsRow = styled(FlexContainer).attrs({ direction: 'column' })`
  padding-left: calc(${iconContainerWidth} + 3.2em); // checkbox width and padding
  padding-right: 1em;
  padding-bottom: 1em;
  ${Button} {
    align-self: flex-start;
    outline: none;
  }
  > ${Text} {
    margin-bottom: 0.5em;
  }
`;

const NoteText = styled(Text)`
  padding-top: 1em;
  padding-bottom: 1em;
`;


const ReplyRow = styled(FlexContainer)`
  min-width: 45%;
  ${InputField} {
    margin-bottom: 0;
    width: 100%;
  }
  ${Button} {
    margin-left: 10px;
    margin-right: 20px;
  }
`;

const CheckboxContainer = styled(FlexContainer)`
  margin-left:1em;
`;

const UserModal = styled(Container)`
  position: absolute;
  padding: 20px;
  width: 200px;
  display: flex;
  align-items: flex-start;
  box-shadow: 0 0 8px 0 rgba(60,61,58,0.24);
  z-index: 10;
  top: 30px;
  left: -80px;
`;
const UserModalContainer = styled.div`
  position: relative;
`;

const UserNameText = styled(SecondaryText)`
  font-weight: 600;
  margin-left: 10px;
`;

const fontSettings = `
  font-family: "Petasense Open Sans";
  font-size: 13px;
  font-weight: 400;
`;

const ActivityItemContainer = styled.div`
  position: relative;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

class ActivityItem extends Component {
  constructor(props) {
    super(props);
    this.updateFieldValue = this.updateFieldValue.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.postComment = this.postComment.bind(this);
    this.toggleItemMenuOpen = this.toggleItemMenuOpen.bind(this);
    this.state = {
      comment: '',
      userModalOpen: false,
      confirmDelete: false
      // closeReason: ''
    };
  }

  componentDidUpdate() {
    // add an event listener to this element (submit comment on enter)
    // the element will be found on cases when the details section is open and the input field is there
    const el = document.getElementById(this.props.activity.activity_id);
    if (el) {
      el.addEventListener('keypress', this.handleKeyDown, false);
    }
  }

  componentWillUnmount() {
    const el = document.getElementById(this.props.activity.activity_id);
    if (el) {
      el.removeEventListener('keypress', this.handleKeyDown, false);
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 13 && this.state.comment !== '') {
      this.postComment(this.state.comment);
      const el = document.getElementById(this.props.activity.activity_id);
      el.blur();
    }
  }

  postComment() {
    const comment = this.state.comment.trim();
    if (comment) this.props.postComment(this.state.comment);
    this.setState({ comment: '' });
  }

  updateFieldValue(value, key) {
    this.setState({
      [key]: value
    });
  }

  toggleItemMenuOpen() {
    this.setState(prevState => ({ itemMenuOpen: !prevState.itemMenuOpen }));
  }

  closeDeletePrompt = () => this.setState({ confirmDelete: false });

  openDeletePrompt = () => this.setState({ confirmDelete: true });

  deleteResource = () => {
    const { activity } = this.props;
    switch (activity.type) {
      case 'note':
        this.props.activityActions.deleteNote(activity.activity_id);
        break;
      case 'task':
        this.props.taskActions.deleteTask(activity.activity_id);
        break;
      case 'repair':
        this.props.repairActions.deleteRepair(activity.activity_id);
        break;
      default:
        break;
    }
    this.toggleItemMenuOpen();
    this.closeDeletePrompt();
  }

  onMachineClick = (e, machine_id) => {
    e.stopPropagation();
    history.push(`/machines/${machine_id}/overview`);
  };

  getLatestActionText = (latestAction) => {
    const timestamp = utils.formatDate(latestAction.timestamp);
    const user = this.props.users ? this.props.users.find(user => user.id === latestAction.user_id) : undefined;
    const userTag = user ? `by ${user.first_name} ${user.last_name}` : '';
    switch (latestAction.action_type) {
      case 'observation':
        return `Put under observation ${userTag} on ${timestamp}`;
      case 'remove_from_observation':
        return `Removed from observation ${userTag} on ${timestamp}`;
      case 'comment':
        return `Latest comment ${userTag} on ${timestamp}`;
      case 'suspension':
        return `Suspended ${userTag} on ${timestamp}`;
      case 'remove_from_suspension':
        return `Resumed ${userTag} on ${timestamp}`;
      default:
        return '';
    }
  };

  getSuspensionExpiryTime = (activity) => {
    const suspension_expiry = utils.formatDate(activity.suspension_details.expiry_time);
    return `Suspended till ${suspension_expiry}`;
  };

  onContentClick = (activity) => {
    const { toggleOpen, markAsRead } = this.props;
    if (toggleOpen) toggleOpen();
    if (!activity.read && markAsRead) this.props.markAsRead(activity);
  }

  getChartSearchParams = (activity) => {
    let params = `?tag_id=${activity.tag_id}&timestamp=${activity.timestamp}`;
    if (activity.threshold_type) params = `${params}&threshold_type=${activity.threshold_type}`;
    if (activity.measurement_type) params = `${params}&measurement_type=${activity.measurement_type}`;
    if (activity.event_type && (activity.event_type === 'vibration_acceleration_warning' || activity.event_type === 'vibration_acceleration_critical')) params = `${params}&amp_type=acceleration`;
    else if (activity.event_type && (activity.event_type === 'vibration_demod_warning' || activity.event_type === 'vibration_demod_critical')) params = `${params}&amp_type=demod`;
    else if (activity.event_type && (activity.event_type === 'vibration_warning' || activity.event_type === 'vibration_critical')) params = `${params}&amp_type=velocity`;
    return params;
  }

  getActivityEventType = (activity) => {
    switch (activity.type) {
      case 'event':
        return activityFormat[activity.event_type] ? activity.event_type : 'custom';
      case 'ahs_feedback':
        return activity.feedback_type;
      case 'diagnosis':
      case 'task':
      case 'repair':
        return activity.type;
      default:
        return '';
    }
  }

  render() {
    const {
      className,
      activity,
      openDetails,
      compact,
      user,
      isLoading
    } = this.props;

    const isNote = activity.type === 'note';
    const isEvent = activity.type === 'event';
    const isAnalystSummary = activity.type === 'analyst_summary';
    const isTask = activity.type === 'task';
    const isRepair = activity.type === 'repair';
    const isDiagnosis = activity.type === 'diagnosis';
    const isAHSFeedback = activity.type === 'ahs_feedback';
    const activityEventType = this.getActivityEventType(activity);

    let data;
    let tag_id;
    let chart_timestamp;
    let chart_snapshot;
    if (isNote && activity.details && activity.details.chart_snapshot_data) {
      tag_id = activity.details.chart_snapshot_data.chart_context.tag_id;
      data = activity.details.chart_snapshot_data.data.chart_data;
      chart_snapshot = activity.details.chart_snapshot_data;
      chart_timestamp = activity.details.chart_snapshot_data.chart_context.date;
    }
    const isOwn = activity.user_name === `${this.props.user.first_name} ${this.props.user.last_name}`;
    const nrOfReplies = (activity.comments && activity.comments.length) || activity.comment_count;
    let range;
    if (openDetails && _.has(activity, 'details') && isNote && data) {
      range = utils.chartRange(
        data,
        user.unit_system,
        activity.details.chart_snapshot_data.chart_context.amp_type,
        activity.details.chart_snapshot_data.chart_type,
        activity.details.chart_snapshot_data.tag_type
      );
    }
    return (
      <ActivityItemContainer>
        {isLoading && (
          <LoaderOverlay>
            <Loading size="small" />
          </LoaderOverlay>
        )}
        <Container className={className} padding read={!compact && activity.read}>
          <HeaderRow>
            {!compact && (
              <CheckboxContainer>
                <Checkbox
                  value={this.props.selected}
                  onClick={() => this.props.toggleActivitySelection(activity)}
                />
              </CheckboxContainer>
            )}
            <IconContainer compact={compact}>
              {(isNote || isAnalystSummary) ? (
                isNote ? <NoteSvgNew /> : <AnalystSummarySvg />
              ) : (
                activityFormat[activityEventType] && activityFormat[activityEventType].icon(activity)
              )}
            </IconContainer>
            <ContentContainer onClick={() => this.onContentClick(activity)} read={!compact && !openDetails && activity.read}>
              {isNote &&
                <Text><Text bold hover onClick={e => this.onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> has a note by <Text bold>{activity.user_name}</Text></Text>
              }
              {isAnalystSummary &&
                <Text><Text bold hover onClick={e => this.onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> has a summary by <Text bold>{activity.user_name}</Text></Text>
              }
              {(isEvent || isDiagnosis || isAHSFeedback || isTask || isRepair) &&
                activityFormat[activityEventType].title(activity)
              }
              <MetadataContainer>
                <SecondaryText>
                {utils.formatDate(activity.timestamp)}
                {isTask && activity.condition && (
                  <SummaryCondition
                    color={ColorAccToCondition(activity.condition)}
                    marginLeft="10px"
                  >
                    {activity.condition}
                  </SummaryCondition>
                )}
                {!compact && activity.tag_id && <Link to={{ pathname: '/configs', search: this.getChartSearchParams(activity) }}><ViewChartBtn text> <LineChart padding="5px" /></ViewChartBtn></Link>}
                {!compact && (activity.event_type === 'health_score_critical' || activity.event_type === 'health_score_warning') && <Link to={{ pathname: `/machines/${activity.machine_id}/health` }}><ViewChartBtn text><LineChart padding="5px" /></ViewChartBtn></Link>}
                </SecondaryText>
                {!compact && activity.activity_id && <SecondaryText>#{activity.activity_id}</SecondaryText>}
                {isNote && activity.internal && (
                  <SecondaryText style={{ display: 'flex' }}>
                    <b>Petasense Only</b>
                  </SecondaryText>
                )}
                {isEvent && activity.last_vibrational_analysis && (
                  <SecondaryText style={{ display: 'flex' }}>
                    <b>Analysed on {utils.formatDate(activity.last_vibrational_analysis, false)}</b>
                  </SecondaryText>
                )}
                {isTask && activity.user_role_assigned && (
                  <SecondaryText style={{ display: 'flex' }}>
                    Assigned to
                    <UserCircle
                      margin="0 0 0 5px"
                      title={humanize(activity.user_role_assigned)}
                    >
                      {utils.getInitials(activity.user_role_assigned)}
                    </UserCircle>
                  </SecondaryText>
                )}
                {isRepair && activity.scheduled_at && (
                  <SecondaryText style={{ display: 'flex' }}>
                    Repair scheduled on {utils.formatDate(activity.scheduled_at, false)}
                  </SecondaryText>
                )}
                {chart_snapshot && (
                  <>
                    <SecondaryText>
                      Location: {humanize(chart_snapshot.location)}
                    </SecondaryText>
                    <SecondaryText>
                      Tag type: {humanize(chart_snapshot.chart_context.tag_type)}
                    </SecondaryText>
                    {chart_snapshot.direction && (
                      <SecondaryText>
                        Direction: {humanize(chart_snapshot.direction)}
                      </SecondaryText>
                    )}
                    <SecondaryText>
                      Chart type: {humanize(chart_snapshot.chart_type)}
                    </SecondaryText>
                  </>
                )}
                {!compact && nrOfReplies ? (
                  <SecondaryText>
                    {nrOfReplies} {nrOfReplies > 1 ? 'replies' : 'reply'}
                  </SecondaryText>
                ) : (
                  null
                )}
                {!compact && !_.isEmpty(activity.latest_action) && (
                  <SecondaryText>
                    {this.getLatestActionText(activity.latest_action)}
                  </SecondaryText>
                )}
                {!compact && (activity.suspension) && (
                  <SecondaryText>
                    {this.getSuspensionExpiryTime(activity)}
                  </SecondaryText>
                )}
                {!compact && activity.read_by.length > 0 && (
                  <FlexContainer>
                    {_.map(activity.read_by, (user_id, idx) => {
                      if (idx >= readByUserCount || _.isEmpty(this.props.users)) return '';
                      const user = this.props.users.find(user => user.id === user_id);
                      if (!user) return '';
                      const user_name = `${user.first_name} ${user.last_name}`;
                      return <UserCircle title={user_name}>{utils.getInitials(user_name)}</UserCircle>;
                    })}
                    {!_.isEmpty(activity.read_by) && activity.read_by.length > readByUserCount && (
                      <UserModalContainer>
                        <OutsideAlerter handleClick={() => this.setState({ userModalOpen: false })} open={this.state.userModalOpen}>
                          <UserCircle
                            onClick={(e) => {
                              e.stopPropagation();
                              this.setState(prevState => ({ userModalOpen: !prevState.userModalOpen }));
                            }}
                          >
                            +1
                          </UserCircle>
                          {this.state.userModalOpen && (
                              <UserModal>
                                { _.map(activity.read_by, (user_id, idx) => {
                                  if (idx < readByUserCount || _.isEmpty(this.props.users)) return '';
                                  const user = this.props.users.find(user => user.id === user_id);
                                  if (!user) return '';
                                  const user_name = `${user.first_name} ${user.last_name}`;
                                  return (
                                    <FlexContainer>
                                      <UserCircle marginright="20px">{utils.getInitials(user_name)}</UserCircle>
                                      <UserNameText>{user_name}</UserNameText>
                                    </FlexContainer>
                                  );
                                })}
                              </UserModal>
                          )}
                        </OutsideAlerter>
                      </UserModalContainer>
                    )}
                  </FlexContainer>
                )}
              </MetadataContainer>
            </ContentContainer>
            {!compact && (
              <InfoContainer>
                {isEvent && (
                  <EventStateContainer direction="column" alignItems="flex-end">
                    {activity.sensor_serial_number &&
                      <SecondaryText>{activity.sensor_serial_number}</SecondaryText>
                    }
                    <SecondaryText>{activity.state}</SecondaryText>
                  </EventStateContainer>
                )}
                {(isTask || isRepair) && <SecondaryText>{humanize(activity.state)}</SecondaryText>}
                {(isTask || isNote || isRepair) && isOwn && (
                  <SubMenuContainer>
                    <SvgContainer onClick={this.toggleItemMenuOpen}>
                      <EllipsisV color={this.props.theme.colors.greyD} />
                    </SvgContainer>
                    {this.state.itemMenuOpen && (
                      <InlineModal
                        close={this.toggleItemMenuOpen}
                      >
                        <Button
                          text
                          onClick={this.openDeletePrompt}
                        >
                          Delete
                        </Button>
                      </InlineModal>
                    )
                    }
                  </SubMenuContainer>
                )
                }
              </InfoContainer>
            )
            }
          </HeaderRow>
          {openDetails && activity.loading && !_.has(activity, 'details') &&
            <Loading />
          }
          {openDetails && (isAnalystSummary || isDiagnosis || isAHSFeedback || _.has(activity, 'details')) ? (
            <DetailsRow>
              {isAnalystSummary && (
              <ConditionContainer>
                <ConditionLabel>Asset condition:</ConditionLabel>
                <SummaryCondition
                  color={ColorAccToCondition(activity.condition)}
                >
                  {activity.condition}
                </SummaryCondition>
                {activity.suspected_defect && (
                  <>
                    <DefectLabel>Defect Type:</DefectLabel>
                    <SummaryDefect>{activity.suspected_defect}</SummaryDefect>
                  </>
                )}
              </ConditionContainer>
              )}
              {isNote && (
                <Fragment>
                  {data && (
                    <Link style={{ color: 'black' }} to={{ pathname: `/machines/${activity.machine_id}/charts`, search: `?tag_id=${tag_id}&timestamp=${chart_timestamp}` }}>
                      <Chart
                        type="line"
                        chartName={`activity-note-chart-${activity.activity_id}`}
                        data={[data]}
                        range={range}
                        xAxis={activity.details.chart_snapshot_data.chart_type !== 'trend' && 'linear'}
                        height="100px"
                        yLabel={activity.details.chart_snapshot_data.data.amp_units}
                      />
                    </Link>
                  )}
                  <NoteText>{activity.details.note}</NoteText>
                  <Attachments
                    originType="note"
                    originId={activity.activity_id}
                    attachments={activity.details.attachments}
                    allowUpload={false}
                    showExistingAtts
                    showBrowseButton={false}
                  />
                </Fragment>
              )
              }
              {(isAnalystSummary || isDiagnosis) && (
                <Fragment>
                  <NoteText>{activity.description || activity.recommendation}</NoteText>
                </Fragment>
              )
              }
              {isEvent && (
                <Text>{activityFormat[activityEventType].description(activity.details.description_metadata)}</Text>
              )}
              {isAHSFeedback && (
                <Text>{activityFormat[activityEventType].description(activity)}</Text>
              )}
              <FlexContainer>
                {(isDiagnosis || isAHSFeedback) && (
                  <Button text onClick={() => history.push(`/machines/${activity.machine_id}/health`, { result_id: activity.activity_id })}>View Details</Button>
                )}
                {(isDiagnosis && activity.state === 'review_pending' && (isAnalyst(this.props.user) || isPetasenseAdmin(this.props.user) || isPartnerAdmin(this.props.user))) && (
                  <Button text margin="0 0 0 1em" onClick={() => { this.props.confirmDiagnosticRuleResult(activity.activity_id); }}>Confirm</Button>
                )}
              </FlexContainer>
              {activity.comments && activity.comments.map(comment => (
                <Comment
                  key={comment.timestamp}
                  comment={comment}
                  user={this.props.user}
                  putComment={message => this.props.activityActions.putComment(activity.activity_id, message, activity.type, comment.comment_id)}
                  deleteComment={() => this.props.activityActions.deleteComment(activity.activity_id, activity.type, comment.comment_id)}
                />
              ))}
              {(
                <FlexContainer
                  justifyContent="space-between"
                  marginbottom="0.5em"
                  margintop="1em"
                >
                  <ReplyRow flexGrow="1">
                    <InputField
                      id={activity.activity_id}
                      type="textarea"
                      fontSettings={fontSettings}
                      placeholder="Reply..."
                      value={this.state.comment}
                      onChange={e => this.updateFieldValue(e.target.value, 'comment')}
                      backgroundColor={activity.read && '#f7f8f6'}
                      height="70px"
                    />
                    <Button onClick={this.postComment} fontSize="13px">Post</Button>
                  </ReplyRow>
                  <FlexContainer direction="row">
                    {isNote && activity.details.user_id === user.id && (
                      <Button
                        onClick={() => this.props.noteActions.openNoteModal(activity.activity_id)}
                        secondary="#000"
                        fontSize="14.5px"
                        title="Edit Note"
                        margin="0 5px 5px 5px"
                      >
                        <EditIcon />
                      </Button>
                    )}
                    {isEvent && activity.state === 'open' && (
                      <>
                      {/* Event under observation can not be suspended */}
                        {!activity.suspension && (
                          <RBAC
                            resource={mapComponentToResource.Activity}
                            operation={operations.Update}
                            yes={(
                                <>
                                {activity.observation ? (
                                  <Button
                                    onClick={() => this.props.itemObservationClick(activity)}
                                    secondary="#000"
                                    fontSize="14.5px"
                                    title="Remove from Observation"
                                    margin="0 5px 5px 5px"
                                  >
                                    <EyeSlash />
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => this.props.itemObservationClick(activity)}
                                    secondary="#000"
                                    fontSize="14.5px"
                                    title="Put Under Observation"
                                    margin="0 5px 5px 5px"
                                  >
                                    <Eye />
                                  </Button>
                                )}
                                </>
                              )}
                          />
                        )}
                      {/* Suspended event can not be put under observation */}
                        {!activity.observation && (
                          <RBAC
                            resource={mapComponentToResource.Activity}
                            operation={operations.Update}
                            yes={(
                                <>
                                {activity.suspension ? (
                                <Button
                                  onClick={() => this.props.itemSuspendClick(activity)}
                                  secondary="#000"
                                  fontSize="14.5px"
                                  title="Resume"
                                  margin="0 5px 5px 5px"
                                >
                                    <Clock />
                                </Button>
                                ) : (
                                  <Button
                                    onClick={() => this.props.itemSuspendClick(activity)}
                                    secondary="#000"
                                    fontSize="14.5px"
                                    title="Suspend"
                                    margin="0 5px 5px 5px"
                                  >
                                    <StopWatch />
                                  </Button>
                                )}
                                </>
                            )}
                          />
                        )}
                      </>
                    )}
                    {isAHSFeedback && activity.state === 'open' && (
                      <RBAC
                        resource={mapComponentToResource.Activity}
                        operation={operations.Update}
                        yes={(
                              <Button
                                onClick={() => this.props.closeAHSFeedback(activity)}
                                secondary="#000"
                                fontSize="14.5px"
                                title="Close feedback"
                                margin="0 5px 5px 5px"
                              >
                                <WindowClose />
                              </Button>
                          )}
                      />
                    )}
                    {isEvent && (
                      <FlexContainer margintop="15px" justifyContent="flex-end">
                        <SecondaryText>E{activity.activity_id}</SecondaryText>
                      </FlexContainer>
                    )}
                  </FlexContainer>
                </FlexContainer>)
              }
            </DetailsRow>
          ) : (
            null
          )}
          {this.state.confirmDelete && (
            <AlertPrompt
              message={`Are you sure you want delete this ${activity.type}?`}
              proceedColor={colors.red}
              onProceed={this.deleteResource}
              onCancel={this.closeDeletePrompt}
            />
          )}
        </Container>
      </ActivityItemContainer>
    );
  }
}

ActivityItem.propTypes = {
  className: PropTypes.string.isRequired,
  activity: PropTypes.shape({
    comments: PropTypes.number,
    description: PropTypes.string,
    activity_id: PropTypes.number,
    state: PropTypes.string,
    timestamp: PropTypes.string,
    views: PropTypes.number
  }).isRequired,
  toggleOpen: PropTypes.func,
  // closeEvent: PropTypes.func.isRequired,
  postComment: PropTypes.func,
  openDetails: PropTypes.bool,
  compact: PropTypes.bool,
  isLoading: PropTypes.bool
};

ActivityItem.defaultProps = {
  toggleOpen: undefined,
  postComment: undefined,
  openDetails: undefined,
  compact: false,
  isLoading: false
};

const mapStateToProps = state => ({
  user: state.user.user,
  users: state.settings.eventDefinitions.static_data.users,
});

const mapDispatchToProps = dispatch => ({
  activityActions: bindActionCreators(activityActions, dispatch),
  taskActions: bindActionCreators(taskActions, dispatch),
  repairActions: bindActionCreators(repairActions, dispatch),
  noteActions: bindActionCreators(noteActions, dispatch)
});

export default styled(connect(mapStateToProps, mapDispatchToProps)(withTheme(ActivityItem)))``;
