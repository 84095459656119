import React, { useRef } from 'react';
import styled from 'styled-components';
import DeleteIconSvg from 'common/images/BearingModal/DeleteIconSvg';
import SvgBtn from 'common/components/atoms/SvgBtn';
import colors from 'common/styles/colors';
import { WordFile, PdfFile } from 'common/images/FaIcons';
import { FlexContainer } from 'common/components/atoms';
import RBAC from 'common/rbac/RBAC';
import { mapComponentToResource, operations } from 'common/rbac/constants';

const ViewContainer = styled.div`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  font-size: 12px;
  min-width: 6rem;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: inherit;
  flex-direction: inherit;
  justify-content: flex-end;
  border-radius: inherit;
  cursor: pointer;
  ${SvgBtn} {
    opacity: 0;
  }
  ${props => props.deleted && `
    background: rgba(0,0,0,0.1);
    cursor: not-allowed;
  `}
  &:hover {
    ${props => !props.deleted && 'background: linear-gradient(90deg, rgba(0,0,0,0) 80%, rgba(0,0,0,0.40) 100%);'}
    ${SvgBtn} {
      opacity: 1;
    }
  }
`;

const IconContainer = styled.span`
  margin-right: 6px;
  color: ${colors.black};
`;

function DocumentView({
  id,
  filename,
  url,
  onDeleteHandler,
  itemDeleted,
  showFileName
}) {
  const anchorRef = useRef();

  const onClickHandler = () => {
    anchorRef.current.click();
  };

  const onDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteHandler(id);
  };

  const getIcon = () => {
    const extension = filename && filename.split('.').slice(-1)[0];
    if (extension === 'doc' || extension === 'docx') {
      return <WordFile />;
    }
    if (extension === 'pdf') {
      return <PdfFile />;
    }
    return null;
  };

  return (
    <ViewContainer onClick={() => !itemDeleted && onClickHandler()}>
      <Overlay title={!showFileName ? filename : ''} deleted={itemDeleted}>
        {itemDeleted && (
          <FlexContainer color={colors.error} alignItems="center" justifyContent="center">DELETED</FlexContainer>
        )}
        {!itemDeleted && (
          <RBAC
            resource={mapComponentToResource.Attachments}
            operation={operations.Delete}
            yes={(
              <SvgBtn title="Delete attachment" onClick={onDeleteClick}>
                <DeleteIconSvg
                  fill="#ffffff"
                  stroke="#ffffff"
                />
              </SvgBtn>
            )}
          />
        )}
      </Overlay>
      <a ref={anchorRef} href={url} download>
        <IconContainer>{getIcon()}</IconContainer>
        {showFileName ? filename : ''}
      </a>
    </ViewContainer>
  );
}

export default DocumentView;
