import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import * as utils from 'common/utils';
import FlexContainer from 'common/components/atoms/FlexContainer';
import { bearingFrequenciesDescription } from 'common/constants';

const TagInfoContainer = styled(FlexContainer)`
  margin-left: 25px;
  min-height: 24px;
  flex-wrap: wrap;
`;

const InfoText = styled.span`
  display: block;
  font-size: 12px;
  color: #999B95;
  padding-bottom: 0.4em;
  b {
    font-weight: 600;
    color: black;
  }
  margin-right: 2em;
`;

const TagInfo = ({
  chartType,
  config,
  noData,
  tag,
  waveAndSpecSelection,
}) => {
  if (noData || tag.structure_type !== 'vector' || chartType === 'waterfall_spectrum') return null;
  return (
    <TagInfoContainer>
      {config.spectrum && config.spectrum.fmax && (
        <>
          {waveAndSpecSelection && (
            <InfoText>
              <b>{utils.formatDate(waveAndSpecSelection.x)}</b>
            </InfoText>
          )}
          <InfoText>
            Fmax:&nbsp;
            <b>
              {config.spectrum.fmax} {config.spectrum.freq_units}
            </b>
          </InfoText>
          <InfoText>
            Lines of resolution: <b>{config.spectrum.lines_of_resolution}</b>
          </InfoText>
        </>
      )}
      {config.waveform && config.waveform.broadband_features && (
        <>
          <InfoText>
            RMS:&nbsp;
            <b>
              {config.waveform.broadband_features.rms.toFixed(2)}&nbsp;
              {config.waveform.amp_units}
            </b>
          </InfoText>
          {config.spectrum && config.spectrum.shaft_speed && (
            <InfoText>
              Shaft Speed:&nbsp; <b>{config.spectrum.shaft_speed} {config.spectrum.freq_units}</b>
            </InfoText>
          )}
          <InfoText>
            Peak:&nbsp;
            <b>
              {config.waveform.broadband_features.peak.toFixed(2)}&nbsp;
              {config.waveform.amp_units}
            </b>
          </InfoText>
          <InfoText>
            Peak to Peak:&nbsp;
            <b>
              {config.waveform.broadband_features.p2p.toFixed(2)}&nbsp;
              {config.waveform.amp_units}
            </b>
          </InfoText>
          <InfoText>
            Crest Factor:&nbsp;
            <b>{config.waveform.broadband_features.crest_factor.toFixed(2)}</b>
          </InfoText>
        </>
      )}
      {tag && tag.forcingFrequencies && tag.forcingFrequencies.items.map((item, idx) => {
        if (!bearingFrequenciesDescription.includes(item.description)) return null;
        return (
          <InfoText key={idx}>
            {item.marker.toUpperCase()}:&nbsp;
            <b>{item.frequency.toFixed(1)} {item.freq_units}</b>
          </InfoText>
        );
      })}
    </TagInfoContainer>
  );
};

TagInfo.propTypes = {
  chartType: PropTypes.string,
  config: PropTypes.object.isRequired,
  noData: PropTypes.bool,
  tag: PropTypes.object,
  waveAndSpecSelection: PropTypes.object
};

TagInfo.defaultProps = {
  chartType: '',
  noData: false,
  tag: null,
  waveAndSpecSelection: null
};

export default TagInfo;
