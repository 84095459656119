import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import Modal from 'common/components/organisms/Modal';
import FilterCountContainer from 'common/components/atoms/FilterCountContainer';
import { Search, ArrowLeft } from 'common/images/FaIcons';
import InputField from 'common/components/atoms/InputField';
import FlexContainer from 'common/components/atoms/FlexContainer';
import List from 'common/components/molecules/List';
import CalendarFilter from 'common/components/Chart/components/CalendarFilter';
import { calendarOptions } from 'common/charts/constants';
import MissedMeasurementsListItem from './MissedMeasurementsListItem';
import * as devicesHealthActions from '../devicesHealth.actions';
import SensorMissedMeasurementListItem from './SensorMissedMeasurementListItem';

const CalendarInputContainer = styled.div`
  position: relative;
  margin-top: 10px;
`;

const ListContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  position: relative;
  overflow: auto;
  border: 1px solid #ddd;
  margin-top: ${props => props.deviceLevel ? '0' : '1.5em'};
  border-radius: 10px;
  height: ${props => props.deviceLevel ? '460px' : '400px'};
`;

const StyledArrowLeft = styled(ArrowLeft)`
  margin-right: 10px;
  margin-bottom: 5px;
  opacity: 0.5;
  &:hover {
    cursor: pointer;
  }
`;

const MissedMeasurementModal = (props) => {
  const initialEndTime = moment().subtract(1, 'days').startOf('day').toDate();
  const initialStartTime = moment().subtract(7, 'days').startOf('day').toDate();

  const onSensorSelect = (serial_number) => {
    const { selectSensor } = props.devicesHealthActions;
    selectSensor(serial_number);
  };

  const listProps = {
    columnSizes: [7, 6],
    headers: [
      {
        name: 'serial_number',
        label: 'Serial Number'
      },
      {
        name: 'count',
        label: 'Count',
        alignCenter: true
      }
    ],
    maxHeightAfterScroll: 'calc(100vh - 100px)',
    triggerLoadMore: 'calc(100vh - 100px)',
    sorterState: props.missedMeasurementsSorter,
    setSorter: props.devicesHealthActions.setMissedMeasurementsSorter,
    hasMore: props.missedMeasurementsList.hasMore,
    loadMoreItems: props.devicesHealthActions.loadMore,
    loading: props.missedMeasurementsList.loading,
    items: props.missedMeasurementsList,
    onSensorSelect,
    adminOpen: props.adminDashboard.adminOpen,
    ItemComponent: MissedMeasurementsListItem,
    emptyMsg: 'No Results Found',
  };
  if (props.adminDashboard.adminOpen) {
    listProps.columnSizes = [...listProps.columnSizes, 5];
    listProps.headers = [
      ...listProps.headers,
      {
        name: 'account_name',
        label: 'Account',
        alignCenter: true
      }
    ];
  }

  listProps.columnSizes = [...listProps.columnSizes, 5];
  listProps.headers = [
    ...listProps.headers,
    {
      name: 'details',
      label: '',
      noSorter: true
    }
  ];

  const sensorListProps = {
    columnSizes: [10],
    headers: [
      {
        name: 'missed_timestamps',
        label: 'Missed Timestamps',
        alignCenter: true
      }
    ],
    maxHeightAfterScroll: 'calc(100vh - 100px)',
    triggerLoadMore: 'calc(100vh - 100px)',
    sorterState: props.sensorMissedTimestampsSorter,
    setSorter: props.devicesHealthActions.setSensorMissedTimestampsSorter,
    hasMore: props.sensorMissedTimestampsList.hasMore,
    loadMoreItems: props.devicesHealthActions.sensorLoadMore,
    loading: props.sensorMissedTimestampsList.loading,
    items: props.sensorMissedTimestampsList,
    adminOpen: props.adminDashboard.adminOpen,
    ItemComponent: SensorMissedMeasurementListItem,
    emptyMsg: 'No Results Found'
  };
  if (props.adminDashboard.adminOpen) {
    sensorListProps.columnSizes = [...sensorListProps.columnSizes, 5];
    sensorListProps.headers = [
      ...sensorListProps.headers,
      {
        name: 'account_name',
        label: 'Account',
        alignCenter: true
      }
    ];
  }

  const [calendarState, setCalendarState] = useState({
    calendarOpen: false,
    days: 7
  });

  const [searchParams, setSearchParams] = useState({
    searchKey: '',
    startTime: initialStartTime,
    endTime: initialEndTime
  });

  useEffect(() => {
    const { setMissedMeasurementsFilters } = props.devicesHealthActions;
    setMissedMeasurementsFilters(initialStartTime, initialEndTime, '', props.filters);
  }, []);

  const arrowLeft = () => (
      <StyledArrowLeft
        size="1x"
        onClick={returnToOverallModal}
      />
  );

  const toggleCalendarOpen = () => {
    setCalendarState(prevState => ({
      ...prevState,
      calendarOpen: !prevState.calendarOpen
    }));
  };

  const changeSearchKey = (value) => {
    const newSearchParams = {
      ...searchParams,
      searchKey: value
    };
    setSearchParams(newSearchParams);
    search(newSearchParams);
  };

  const search = (params) => {
    const { setMissedMeasurementsFilters } = props.devicesHealthActions;
    const newSearchParams = params || searchParams;
    return setMissedMeasurementsFilters(
      newSearchParams.startTime,
      newSearchParams.endTime,
      newSearchParams.searchKey,
      props.filters
    );
  };

  const returnToOverallModal = () => {
    const { deselectSensor } = props.devicesHealthActions;
    deselectSensor();
  };

  const closeFromDeviceLevelModal = () => {
    const { deselectSensor } = props.devicesHealthActions;
    deselectSensor();
    props.close();
  };

  const calendarSelect = (selectedRange, newCustomDayFrom = null, newCustomDayTo = null) => {
    if (selectedRange === 'custom') {
      const newSearchParams = {
        ...searchParams,
        startTime: newCustomDayFrom,
        endTime: newCustomDayTo
      };
      setCalendarState({
        calendarOpen: false,
        days: 'custom'
      });
      setSearchParams(newSearchParams);
      search(newSearchParams);
    } else {
      const days = parseInt(selectedRange, 10);
      const startTime = moment().subtract(days, 'days').startOf('day').toDate();
      const endTime = moment().subtract(1, 'days').startOf('day').toDate();
      const newSearchParams = {
        ...searchParams,
        startTime,
        endTime
      };
      setCalendarState({
        calendarOpen: false,
        days
      });
      setSearchParams(newSearchParams);
      search(newSearchParams);
    }
  };

  if (props.selectedSensor.serial_number) {
    return (
      <Modal
        width="35%"
        padding="0em 1em"
        headerPadding="3em 1em"
        minWidth="700px"
        close={closeFromDeviceLevelModal}
        title={props.selectedSensor.serial_number}
        titleSize="22px"
        minHeight="300px"
        header={arrowLeft}
      >
        <ListContainer deviceLevel>
          <List {...sensorListProps} />
        </ListContainer>
      </Modal>
    );
  }

  return (
    <Modal
      width="40%"
      padding="0em 1em"
      headerPadding="3em 1em"
      minWidth="700px"
      close={props.close}
      title="Devices Missed Readings"
      titleSize="22px"
      minHeight="300px"
    >
      <FlexContainer height="40px" direction="row">
        <FilterCountContainer>
          {props.missedMeasurementsList.total_count}
        </FilterCountContainer>
        <InputField
          type="text"
          name="device-search-box"
          prefix={<Search />}
          prefixSide="left"
          onChange={e => changeSearchKey(e.target.value)}
          value={searchParams.searchKey}
          placeholder="Search..."
          searchBox
          clearable
          clearInput={() => changeSearchKey('')}
        />
        <CalendarInputContainer>
          <CalendarFilter
            calendarSelect={calendarSelect}
            calendarExpand={toggleCalendarOpen}
            calendarExpanded={calendarState.calendarOpen}
            calendarOptions={calendarOptions}
            days={calendarState.days}
            customDayFrom={calendarState.days === 'custom' ? searchParams.startTime : null}
            customDayTo={calendarState.days === 'custom' ? searchParams.endTime : null}
            borderRight=""
          />
        </CalendarInputContainer>
      </FlexContainer>
      <ListContainer>
        <List {...listProps} />
      </ListContainer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  missedMeasurementsList: state.devicesHealth.missedMeasurements.missedMeasurementsList,
  missedMeasurementsFilters: state.devicesHealth.missedMeasurements.missedMeasurementsFilters,
  missedMeasurementsSorter: state.devicesHealth.missedMeasurements.missedMeasurementsSorter,
  sensorMissedTimestampsList: state.devicesHealth.missedMeasurements.sensorMissedTimestampsList,
  sensorMissedTimestampsSorter: state.devicesHealth.missedMeasurements.sensorMissedTimestampsSorter,
  selectedSensor: state.devicesHealth.missedMeasurements.selectedSensor,
  adminDashboard: state.adminDashboard
});

const mapDispatchToProps = dispatch => (
  {
    devicesHealthActions: bindActionCreators(devicesHealthActions, dispatch)
  }
);

MissedMeasurementModal.propTypes = {
  close: PropTypes.func
};

MissedMeasurementModal.defaultProps = {
  close: () => {}
};

export default connect(mapStateToProps, mapDispatchToProps)(MissedMeasurementModal);
