import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { withTheme } from 'styled-components';
import { toastr } from 'react-redux-toastr';
import * as d3 from 'd3';
import _ from 'lodash';

import * as utils from 'common/utils';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Button from 'common/components/atoms/Button';
import InputField from 'common/components/atoms/InputField';
import RBAC from 'common/rbac/RBAC';
import {
  mapComponentToResource,
  operations
} from 'common/rbac/constants';
import { getBinFilterOptions } from 'common/charts/constants';
import Error from '../../atoms/Error';
import Footer from '../../molecules/Footer';
import OverallAlarmSettings from './OverallAlarmSettings';
import * as assetDetailActions from '../../../actions/assetDetails.actions';
import * as overallAlarmActions from '../../../actions/overallAlarm.actions';
import * as chartsUpdateActions from '../../../actions/chartUpdate.actions';
import { OVERALL_ALARM_STATES, BASE_GRAPH_OPTIONS } from '../../../constants/overallAlarm.constants';
import OverallAlarmPreview from './OverallAlarmPreview';
import CreateAndDisplayTrendAlarm from './CreateAndDisplayTrendAlarm';
import { initialOverallAlarmSettings } from '../../../utils/overallAlarmUtils';

const MContent = styled.div`
  padding: 1em 2em 3em 2em;
`;

const MachineCheckBoxContainer = styled(FlexContainer)`
  align-items: flex-start;
  width: 100%;
  position: relative;
  label {
    font-size: 14px;
  }
`;

class OverallAlarmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alarmState: OVERALL_ALARM_STATES.VIEW,
      alarmMode: (props.tag.alarm_thresholds && !props.tag.overall_alarm_settings ? 'manual' : 'automatic'),
      overallAlarmSettings: this.getOverallAlarmSettings(),
      overallAlarmThresholds: this.getOverallAlarmThresholds(),
      selectedBins: [],
      isEnabledForMachine: false,
      showPreview: false,
      base_graph_error: '',
      threshold_error: ''
    };
  }

  componentDidMount() {
    const {
      tag,
      currentUser: { frequency_units, unit_system },
      breadcrumb: { machine, site }
    } = this.props;
    const { overallAlarmSettings } = this.state;

    if (!_.isEqual(tag.unit_system, unit_system) || !_.isEqual(tag.frequency_units, frequency_units)) {
      this.props.assetDetailActions.getTags(machine.id, site.id);
    }
    if (!_.isEmpty(overallAlarmSettings)) {
      const bin = d3.max(_.keys(overallAlarmSettings));
      const alarmSettings = overallAlarmSettings[bin];
      const params = this.getParamsToFetchTrendData(alarmSettings);
      const selectedBins = [parseInt(bin, 10)];
      this.fetchTrendData(tag.id, params, selectedBins);
      this.setState({ selectedBins });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.tag.overall_alarm_settings, this.props.tag.overall_alarm_settings) ||
     !_.isEqual(prevProps.tag.alarm_thresholds, this.props.tag.alarm_thresholds)
    ) {
      this.setState({
        overallAlarmSettings: this.getOverallAlarmSettings(),
        overallAlarmThresholds: this.getOverallAlarmThresholds(),
      });
    }
    if (!_.isEqual(prevState.overallAlarmThresholds, this.state.overallAlarmThresholds)) this.checkForThresholdError();
  }

  getParamsForUpdateTag = (overallAlarmSettings, overallAlarmThresholds, propAlarmSettings, propAlarmThresholds) => {
    overallAlarmThresholds = _.pickBy(overallAlarmThresholds, t => t && t.critical && t.warning);

    overallAlarmSettings = _.mapValues(overallAlarmSettings, (settings) => {
      if (settings.base_graph.type === BASE_GRAPH_OPTIONS.BASELINE) {
        settings.base_graph.value = null;
      }
      return settings;
    });

    const overall_alarm_settings = this.prepareObjectForAPI(overallAlarmSettings, propAlarmSettings);
    const alarm_thresholds = this.prepareObjectForAPI(overallAlarmThresholds, propAlarmThresholds);


    return {
      overall_alarm_settings,
      alarm_thresholds
    };
  }

  onSave = () => {
    const { tag } = this.props;
    const { overallAlarmSettings, overallAlarmThresholds } = this.state;
    const params = this.getParamsForUpdateTag(overallAlarmSettings, overallAlarmThresholds, tag.overall_alarm_settings, tag.alarm_thresholds);
    this.updateTag(params).then(
      () => this.setAlarmState(OVERALL_ALARM_STATES.VIEW)
    );
  }

  onCancel = () => {
    this.setState({
      overallAlarmSettings: this.getOverallAlarmSettings(),
      overallAlarmThresholds: this.getOverallAlarmThresholds(),
      alarmState: OVERALL_ALARM_STATES.VIEW
    });
  }

  prepareObjectForAPI = (object, propObject) => {
    const { tag } = this.props;
    if (!object || _.isEmpty(object)) return null;
    if (tag.type === 'vibration') {
      const prefix = this.props.ampType === 'velocity' ? '' : `${this.props.ampType}_`;

      object = _.transform(object, (result, value, bin) => {
        bin = `bin_${bin}`;
        result[bin] = {};
        _.forEach(value, ((v, key) => {
          result[bin][`${prefix}${key}`] = v;
        }));
      });
      return _.merge(propObject, object);
    }
    return object[0];
  }

  getOverallAlarmSettings = () => {
    const { tag } = this.props;
    const alarmSettings = _.cloneDeep(tag.overall_alarm_settings);
    if (!alarmSettings || _.isEmpty(alarmSettings)) return {};
    let overallAlarmSettings = {};
    if (tag.type === 'vibration') {
      const prefix = this.props.ampType === 'velocity' ? '' : `${this.props.ampType}_`;
      const keys = ['base_graph', 'threshold', 'lower_outlier_limit'];
      overallAlarmSettings = _.transform(_.pickBy(alarmSettings, bin => keys.every(k => `${prefix}${k}` in bin)), (result, value, bin) => {
        bin = parseInt(bin.split('_')[1], 10);
        result[bin] = {};
        _.forEach(keys, ((key) => {
          result[bin][key] = value[`${prefix}${key}`];
        }));
      });
    } else {
      overallAlarmSettings = { 0: alarmSettings };
    }
    return overallAlarmSettings;
  }

  getOverallAlarmThresholds = () => {
    const { tag } = this.props;
    const alarmThresholds = _.cloneDeep(tag.alarm_thresholds);
    if (!alarmThresholds || _.isEmpty(alarmThresholds)) return {};
    let overallAlarmThresholds = {};
    if (tag.type === 'vibration') {
      const prefix = this.props.ampType === 'velocity' ? '' : `${this.props.ampType}_`;
      const keys = ['warning', 'critical'];
      overallAlarmThresholds = _.transform(_.pickBy(alarmThresholds, bin => keys.every(k => `${prefix}${k}` in bin)), (result, value, bin) => {
        bin = parseInt(bin.split('_')[1], 10);
        result[bin] = {};
        _.forEach(keys, ((key) => {
          result[bin][key] = value[`${prefix}${key}`];
        }));
      });
    } else {
      overallAlarmThresholds = { 0: alarmThresholds };
    }
    return overallAlarmThresholds;
  }

  toggleMachineSettings = () => {
    this.setState(prevState => ({
      isEnabledForMachine: !prevState.isEnabledForMachine
    }));
  }

  updateTag = (params) => {
    const { tag, breadcrumb: { site, machine } } = this.props;
    return this.props.chartsUpdateActions.updateTag(tag.id, params).then(
      (res) => {
        toastr.success('Overall updated successfully');
        this.props.assetDetailActions.getTags(machine.id, site.id);
        return res;
      },
      (err) => {
        toastr.error(err);
        throw err;
      }
    );
  }

  clearOverallAlarms = () => {
    const { tag } = this.props;
    let overallAlarmThresholds = null;
    let overallAlarmSettings = null;

    if (tag.type === 'vibration') {
      overallAlarmThresholds = _.cloneDeep(this.props.tag.alarm_thresholds);
      overallAlarmSettings = _.cloneDeep(this.props.tag.overall_alarm_settings);
      const thresholdsKeys = ['critical', 'warning'];
      const settingsKeys = ['base_graph', 'threshold', 'lower_outlier_limit'];
      const prefix = this.props.ampType === 'velocity' ? '' : `${this.props.ampType}_`;

      _.forEach(overallAlarmSettings, (value, bin) => {
        _.forEach(settingsKeys, (key) => {
          delete overallAlarmSettings[bin][`${prefix}${key}`];
        });
        if (_.isEmpty(overallAlarmSettings[bin])) delete overallAlarmSettings[bin];
      }
      );
      _.forEach(overallAlarmThresholds, (value, bin) => {
        _.forEach(thresholdsKeys, (key) => {
          delete overallAlarmThresholds[bin][`${prefix}${key}`];
        });
        if (_.isEmpty(overallAlarmThresholds[bin])) delete overallAlarmThresholds[bin];
      }
      );
    }

    const params = {
      alarm_thresholds: !_.isEmpty(overallAlarmThresholds) ? overallAlarmThresholds : null,
      overall_alarm_settings: !_.isEmpty(overallAlarmSettings) ? overallAlarmSettings : null
    };
    this.updateTag(params).then(
      () => {
        this.setState({
          alarmState: OVERALL_ALARM_STATES.VIEW
        });
      }
    );
  }

  setAlarmMode = (alarmMode) => {
    const { tag } = this.props;
    const { overallAlarmSettings } = this.state;
    this.setState({ alarmMode });
    if (
      alarmMode === 'automatic' && _.isEmpty(overallAlarmSettings)
      && tag && !tag.no_of_bins
    ) {
      this.createInitialOverallAlarm();
    }
  }

  setAlarmState = (alarmState) => {
    this.setState({ alarmState });
  }

  onEdit = () => {
    this.setAlarmState(OVERALL_ALARM_STATES.EDIT);
  }

  onContinue = () => {
    this.setState({ showPreview: true });
  }

  closePreview = () => {
    this.setState({ showPreview: false });
  }

  onBinChange = (selectedBins, applySettings = true) => {
    const { tag } = this.props;
    if (!applySettings) this.setState({ selectedBins });
    else {
      const newOverallAlarmSettings = {};
      const alarmSettings = this.overallAlarmSettingsToBeShown();
      selectedBins.forEach((bin) => {
        newOverallAlarmSettings[bin] = alarmSettings;
      });
      const params = this.getParamsToFetchTrendData(alarmSettings);
      this.fetchTrendData(tag.id, params, selectedBins);
      this.setState(prevState => ({
        overallAlarmSettings: {
          ...prevState.overallAlarmSettings,
          ...newOverallAlarmSettings
        },
        selectedBins
      }));
    }
  }

  updateOverallAlarmThresholds = (bins, thresholdsList) => {
    const overallAlarmThresholds = _.cloneDeep(this.state.overallAlarmThresholds);
    bins.forEach((bin, idx) => {
      if (!thresholdsList[idx] && overallAlarmThresholds[bin]) delete overallAlarmThresholds[bin];
      else overallAlarmThresholds[bin] = thresholdsList[idx];
    });
    this.setState({
      overallAlarmThresholds,
    });
  }

  updateManualAlarmThresholds = (bin, thresholds) => {
    let selectedBins = _.cloneDeep(this.state.selectedBins);
    const overallAlarmThresholds = _.cloneDeep(this.state.overallAlarmThresholds);
    const overallAlarmSettings = _.cloneDeep(this.state.overallAlarmSettings);

    if ((!thresholds || (!thresholds.warning && !thresholds.critical))) delete overallAlarmThresholds[bin];
    else {
      overallAlarmThresholds[bin] = thresholds;
      if (overallAlarmSettings[bin]) delete overallAlarmSettings[bin];
      selectedBins = selectedBins.filter(b => b !== bin);
    }
    this.setState({
      overallAlarmThresholds,
      overallAlarmSettings,
      selectedBins
    });
  };

  updateOverallAlarmSettings = (settingOf, args) => {
    const { selectedBins } = this.state;
    const overallAlarmSettings = _.cloneDeep(this.state.overallAlarmSettings);
    const overallAlarmThresholds = _.cloneDeep(this.state.overallAlarmThresholds);
    selectedBins.forEach((bin) => {
      overallAlarmSettings[bin] = {
        ...(overallAlarmSettings[bin] ? overallAlarmSettings[bin] : {}),
        [settingOf]: args
      };
      delete overallAlarmThresholds[bin];
    });
    this.setState({
      overallAlarmSettings,
      overallAlarmThresholds,
    }, () => this.fetchDataIfRequired(settingOf));
  }

  generateErrorText = (error, bins) => {
    bins.forEach((bin, idx) => {
      const text = bin !== '0' ? ` ${bin}` : ' default';
      if (idx) error += ',';
      error += text;
    });
    return error;
  }

  setBaseGraphError = base_graph_error => this.setState({ base_graph_error })

  setThresholdError = threshold_error => this.setState({ threshold_error })

  fetchTrendData = (tag_id, params, bins, bulk = false) => {
    let base_graph_error = 'Select a different base graph option or decrease outlier limit for speed ranges -';
    this.props.overallAlarmActions.getTagTrend(tag_id, params, bins).then(
      (data) => {
        if (!bulk) {
          const noDataBins = _.keys(data).filter(bin => _.isEmpty(data[bin]));
          if (_.isEmpty(noDataBins)) this.setBaseGraphError('');
          else {
            base_graph_error = this.generateErrorText(base_graph_error, noDataBins);
            this.setBaseGraphError(base_graph_error);
          }
        }
      },
      (error) => {
        if (!bulk) {
          toastr.error(error.message);
        }
      }
    );
  }

  getTrendDataDebounced = _.debounce((tag_id, params, bins) => {
    this.fetchTrendData(tag_id, params, bins);
  }, 500);

  fetchDataIfRequired = (setting) => {
    if (setting === 'base_graph' || setting === 'lower_outlier_limit') {
      const { tag } = this.props;
      const { selectedBins } = this.state;
      const alarmSettings = this.overallAlarmSettingsToBeShown();
      const params = this.getParamsToFetchTrendData(alarmSettings);
      this.getTrendDataDebounced(tag.id, params, selectedBins);
    }
  }

  getParamsToFetchTrendData = (alarmSettings) => {
    const { base_graph: { type, value }, lower_outlier_limit } = alarmSettings;
    const params = {
      base_graph_type: type,
    };
    if (value) params.base_graph_type_val = value;
    if (this.props.ampType === 'demod') {
      params.feature = 'rms_demod';
      params.amp_type = 'acceleration';
    }
    if (this.props.ampType === 'acceleration') {
      params.amp_type = 'acceleration';
    }
    if (parseFloat(lower_outlier_limit)) params.lower_outlier_limit = parseFloat(lower_outlier_limit);
    return params;
  }

  createInitialOverallAlarm = () => {
    const { currentUser, tag, ampType } = this.props;
    const defaultAlarmSettings = initialOverallAlarmSettings(tag.type, currentUser.unit_system, ampType);
    const params = this.getParamsToFetchTrendData(defaultAlarmSettings);
    const bins = tag && tag.no_of_bins ? _.range(tag.no_of_bins + 1) : [0];
    this.fetchTrendData(tag.id, params, bins);

    const overallAlarmSettings = {};
    bins.forEach((bin) => { overallAlarmSettings[bin.toString()] = defaultAlarmSettings; });

    this.setState({
      overallAlarmSettings,
      alarmState: OVERALL_ALARM_STATES.CREATE,
      selectedBins: bins
    });
  }

  getFooter = () => {
    const { overallAlarmSettings, overallAlarmThresholds, alarmState, isEnabledForMachine } = this.state;
    if (alarmState === OVERALL_ALARM_STATES.VIEW) {
      let create_or_edit = (
        <>
          <Button danger onClick={this.clearOverallAlarms}>Clear Alarm</Button>
          <Button left onClick={this.onEdit}>Edit Settings </Button>
        </>
      );
      if (_.isEmpty(overallAlarmThresholds) && _.isEmpty(overallAlarmSettings)) {
        create_or_edit = <Button left onClick={this.createInitialOverallAlarm}>Set Overall Alarms </Button>;
      }
      return (
        <>
          <RBAC
            operation={operations.Update}
            resource={mapComponentToResource.Tags}
            yes={create_or_edit}
          />
          <Button cancel onClick={this.props.close}>Close</Button>
        </>
      );
    }

    if (alarmState !== OVERALL_ALARM_STATES.VIEW) {
      return (
        <>
          <MachineCheckBoxContainer direction="row">
            <InputField
              margin="0"
              title="Enable this setting for similar tags on the machine"
              type="checkbox"
              value={isEnabledForMachine}
              onClick={this.toggleMachineSettings}
            />
          </MachineCheckBoxContainer>
          {this.state.threshold_error && (
            <Error>{this.state.threshold_error}</Error>
          )}
          <Button cancel onClick={this.onCancel}>Cancel</Button>
          {isEnabledForMachine ? (
            <Button disabled={this.isContinueDisabled()} onClick={this.onContinue}>Continue</Button>
          ) :
            <Button disabled={this.isSaveDisabled()} onClick={this.onSave}>Save</Button>
          }
        </>
      );
    }

    return null;
  }

  getManualAlarmThresholds = () => {
    const { overallAlarmThresholds, overallAlarmSettings } = this.state;
    const manualAlarmThresholds = _.omit(overallAlarmThresholds, _.keys(overallAlarmSettings));
    return manualAlarmThresholds;
  }

  thresholdsToBeShown = () => {
    const { overallAlarmThresholds } = this.state;
    const multiplyFactorForFeature = utils.getMultiplyFactorForAlarm(this.props.feature);
    let bins = _.isEmpty(this.props.bin_nos) ? [0] : this.props.bin_nos;
    bins = bins.filter(bin => overallAlarmThresholds[bin.toString()]);
    const warningLimits = bins.map(bin => overallAlarmThresholds[bin.toString()].warning);
    const criticalLimits = bins.map(bin => overallAlarmThresholds[bin.toString()].critical);
    const maxWarningLimit = d3.max(warningLimits);
    const maxCriticalLimit = d3.max(criticalLimits);
    return {
      warning: maxWarningLimit ? maxWarningLimit * multiplyFactorForFeature : null,
      critical: maxCriticalLimit ? maxCriticalLimit * multiplyFactorForFeature : null
    };
  }

  overallAlarmSettingsToBeShown = () => {
    const { tag, currentUser, ampType } = this.props;
    const { selectedBins, overallAlarmSettings } = this.state;
    const defaultAlarmSettings = initialOverallAlarmSettings(tag.type, currentUser.unit_system, ampType);
    return !_.isEmpty(selectedBins) && overallAlarmSettings[selectedBins[0]] ?
      overallAlarmSettings[selectedBins[0]] : defaultAlarmSettings;
  }

  isSaveDisabled = () => {
    const { tag } = this.props;
    const { overallAlarmSettings, overallAlarmThresholds, threshold_error } = this.state;
    if (threshold_error) return true;
    const prev_settings = this.getOverallAlarmSettings();
    const prev_thresholds = this.getOverallAlarmThresholds();

    if (_.isEqual(prev_settings, overallAlarmSettings) && _.isEqual(prev_thresholds, overallAlarmThresholds)) return true;
    if (tag.trends && tag.trends.loading) return true;
    return false;
  }

  isContinueDisabled = () => {
    const { threshold_error } = this.state;
    if (threshold_error) return true;
    return false;
  }

  checkForThresholdError = () => {
    const thresholdErrorBins = _.keys(_.pickBy(
      this.state.overallAlarmThresholds,
      (t) => {
        if (!t) return false;
        return (
          !t.critical || parseFloat(t.critical) < 0) ||
        !t.warning || parseFloat(t.warning) < 0 ||
        (parseFloat(t.warning) >= parseFloat(t.critical));
      }
    ));
    let threshold_error = 'Set positive values for thresholds and critical threshold higher than warning threshold for speed ranges -';
    if (_.isEmpty(thresholdErrorBins)) threshold_error = '';
    else threshold_error = this.generateErrorText(threshold_error, thresholdErrorBins);
    this.setThresholdError(threshold_error);
  }

  render() {
    const { tag, config, feature, ampType, showWidth } = this.props;
    const { selectedBins, overallAlarmSettings, overallAlarmThresholds, showPreview } = this.state;
    const data = config.trends_data[0].trend_data;
    const yUnit = config.trends_data[0].units;
    const speedUnit = config.trends_data[0].speed_units;
    const binFilterOptions = getBinFilterOptions(tag && tag.no_of_bins ? tag.no_of_bins : 0, true);
    return (
      <>
      <MContent>
        <CreateAndDisplayTrendAlarm
          loading={config.trends_data.loading}
          updateOverallAlarmThresholds={this.updateOverallAlarmThresholds}
          overallAlarmSettings={overallAlarmSettings}
          warningThreshold={this.thresholdsToBeShown().warning}
          criticalThreshold={this.thresholdsToBeShown().critical}
          ampType={ampType}
          feature={feature}
          unitSystem={this.props.currentUser.unit_system}
          data={data}
          tag={tag}
          yUnit={yUnit}
          speedUnit={speedUnit}
          showNoDataChart
        />
        <OverallAlarmSettings
          alarmMode={this.state.alarmMode}
          alarmState={this.state.alarmState}
          base_graph_error={this.state.base_graph_error}
          setAlarmMode={this.setAlarmMode}
          selectedBins={selectedBins}
          onBinChange={this.onBinChange}
          tag={tag}
          overallAlarmThresholds={overallAlarmThresholds}
          overallAlarmSettings={overallAlarmSettings}
          settingsShown={this.overallAlarmSettingsToBeShown()}
          updateManualAlarmThresholds={this.updateManualAlarmThresholds}
          updateOverallAlarmSettings={this.updateOverallAlarmSettings}
          binFilterOptions={binFilterOptions}
          yUnit={yUnit}
        />
        {showPreview && (
          <OverallAlarmPreview
            close={this.closePreview}
            overallAlarmSettings={overallAlarmSettings}
            manualAlarmThresholds={this.getManualAlarmThresholds()}
            fetchTrendData={this.fetchTrendData}
            setAlarmState={this.setAlarmState}
            tagType={tag.type}
            ampType={ampType}
            feature={feature}
            getParamsToFetchTrendData={this.getParamsToFetchTrendData}
            getParamsForUpdateTag={this.getParamsForUpdateTag}
            binFilterOptions={binFilterOptions}
            yUnit={yUnit}
            speedUnit={speedUnit}
          />
        )}
        <Footer
          footer={this.getFooter}
          showWidth={showWidth}
        />
      </MContent>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.user,
  breadcrumb: state.breadcrumb,
});

const mapDispatchToProps = dispatch => ({
  chartsUpdateActions: bindActionCreators(chartsUpdateActions, dispatch),
  assetDetailActions: bindActionCreators(assetDetailActions, dispatch),
  overallAlarmActions: bindActionCreators(overallAlarmActions, dispatch)
});

OverallAlarmModal.propTypes = {
  config: PropTypes.object.isRequired,
  tag: PropTypes.object.isRequired,
  feature: PropTypes.string,
  ampType: PropTypes.string,
  showWidth: PropTypes.string.isRequired,
  close: PropTypes.func,
  bin_nos: PropTypes.array.isRequired
};

OverallAlarmModal.defaultProps = {
  feature: 'rms',
  ampType: 'velocity',
  close: () => {}
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(OverallAlarmModal));
