export default {
  CLEAR_CURRENT_DEFINITION: 'CLEAR_CURRENT_DEFINITION',

  SEARCH_PARAMS: 'SEARCH_PARAMS',

  CLOSE_EVENT_DETAILS_FLAP: 'CLOSE_EVENT_DETAILS_FLAP',
  OPEN_EVENT_DETAILS_FLAP: 'OPEN_EVENT_DETAILS_FLAP',

  GET_DEFINITION_LIST_REQUEST: 'GET_DEFINITION_LIST_REQUEST',
  GET_DEFINITION_LIST_SUCCESS: 'GET_DEFINITION_LIST_SUCCESS',
  GET_DEFINITION_LIST_FAILURE: 'GET_DEFINITION_LIST_FAILURE',

  CLEAR_DEFINITION_LIST: 'CLEAR_DEFINITION_LIST',

  CREATE_DEFINITION_REQUEST: 'CREATE_DEFINITION_REQUEST',
  CREATE_DEFINITION_SUCCESS: 'CREATE_DEFINITION_SUCCESS',
  CREATE_DEFINITION_FAILURE: 'CREATE_DEFINITION_FAILURE',

  GET_RESOURCES_REQUEST: 'GET_RESOURCES_REQUEST',
  GET_RESOURCES_SUCCESS: 'GET_RESOURCES_SUCCESS',
  GET_RESOURCES_FAILURE: 'GET_RESOURCES_FAILURE',

  GET_DEFINITIONS_METADATA_REQUEST: 'GET_DEFINITIONS_METADATA_REQUEST',
  GET_DEFINITIONS_METADATA_SUCCESS: 'GET_DEFINITIONS_METADATA_SUCCESS',
  GET_DEFINITIONS_METADATA_FAILURE: 'GET_DEFINITIONS_METADATA_FAILURE',

  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',

  GET_PARAMETERS_REQUEST: 'GET_PARAMETERS_REQUEST',
  GET_PARAMETERS_SUCCESS: 'GET_PARAMETERS_SUCCESS',
  GET_PARAMETERS_FAILURE: 'GET_PARAMETERS_FAILURE',

  GET_TRIGGER_RULE_OPERATORS_REQUEST: 'GET_TRIGGER_RULE_OPERATORS_REQUEST',
  GET_TRIGGER_RULE_OPERATORS_SUCCESS: 'GET_TRIGGER_RULE_OPERATORS_SUCCESS',
  GET_TRIGGER_RULE_OPERATORS_FAILURE: 'GET_TRIGGER_RULE_OPERATORS_FAILURE',

  GET_TRIGGER_THRESHOLDS_REQUEST: 'GET_TRIGGER_THRESHOLDS_REQUEST',
  GET_TRIGGER_THRESHOLDS_SUCCESS: 'GET_TRIGGER_THRESHOLDS_SUCCESS',
  GET_TRIGGER_THRESHOLDS_FAILURE: 'GET_TRIGGER_THRESHOLDS_FAILURE',

  GET_DEFINITION_REQUEST: 'GET_DEFINITION_REQUEST',
  GET_DEFINITION_SUCCESS: 'GET_DEFINITION_SUCCESS',
  GET_DEFINITION_FAILURE: 'GET_DEFINITION_FAILURE',

  DELETE_DEFINITION_REQUEST: 'DELETE_DEFINITION_REQUEST',
  DELETE_DEFINITION_SUCCESS: 'DELETE_DEFINITION_SUCCESS',
  DELETE_DEFINITION_FAILURE: 'DELETE_DEFINITION_FAILURE',

  CLEAR_SELECTED_TRIGGERS: 'CLEAR_SELECTED_TRIGGERS',

  UPDATE_DEFINITION_NAME: 'UPDATE_DEFINITION_NAME'
};
