import React from 'react';
import styled from 'styled-components';

const MsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  margin-top: 70px;
  background-color: ${props => props.theme.colors.white};
  border: 2px dotted ${props => props.theme.colors.lightGray};
  border-radius: ${props => props.theme.utils.borderRadius};
  span {
    color: ${props => props.theme.colors.lightGray};
    font-weight: bold;
    margin: 1em;
  }
`;

const AccessRestricted = () => (
  <MsgContainer>
    <span>User does not have access to this page</span>
  </MsgContainer>
);

export default AccessRestricted;
