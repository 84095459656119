import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FlexContainer from '../atoms/FlexContainer';
import { ChevronDown, ChevronUp } from '../../images/FaIcons';

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  cursor: pointer;
  ${props => props.marginbottom && `margin-bottom: ${props.marginbottom};`}
  ${props => props.marginTop && `margin-top: ${props.marginTop};`}
  &:after {
    position: absolute;
    top: 50%;
    transition: transformY(-50%);
    content: " ";
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${props => props.theme.colors.borderGray};
  }
`;

const BackgroundLineBlocker = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.color};
  z-index: 1;
  display: flex;
  align-items: center;
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props =>
    props.fontweight && `font-weight: ${props.fontweight};`}
  &:first-of-type {
    padding-right: 1em;
  }
  &:last-of-type {
    padding-left: 1em;
  }
  svg + h3 {
    padding-left: 14px;
  }
`;

const AccordionContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  ${props => props.width && `width: ${props.width};`}
  margin: 1em 0;
`;

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.toggleContent = this.toggleContent.bind(this);
    this.state = {
      open: this.props.open
    };
  }

  toggleContent() {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }

  render() {
    return (
      <AccordionContainer>
        <HeaderRow
          onClick={this.toggleContent}
          marginbottom={this.props.marginbottom}
        >
          <BackgroundLineBlocker
            fontsize={this.props.fontsize}
            color={this.props.color}
            fontweight={this.props.fontweight}
          >
            {this.props.icon}
            {this.props.title}
          </BackgroundLineBlocker>
          <BackgroundLineBlocker>
            {this.state.open ? (
              <ChevronUp color={this.props.color} />
            ) : (
              <ChevronDown color={this.props.color} />
            )}
          </BackgroundLineBlocker>
        </HeaderRow>
        {this.state.open && <>{this.props.children}</>}
      </AccordionContainer>
    );
  }
}

Accordion.propTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool
};

Accordion.defaultProps = {
  icon: null,
  open: false
};

export default Accordion;
