import styled, { keyframes } from 'styled-components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TickingSvg from '../../images/TickingSvg';

const OuterBar = styled.div`
  position: relative;
  width: ${props => props.width || (props.large ? '100%' : '200px')};
  background: ${props => props.large ? '#ffff' : '#D8DCD3'};
  border: ${props => props.large ? '1px solid #E5E8E1' : props.border};
  border-radius: ${props => props.large ? '4px' : props.borderradius};
  ${props => props.rejected ? `
    width: 100%;
    color: ${props.theme.colors.red};
    background: #e5e8e1b5;
  ` : ''}
`;

const InnerBar = styled.div`
  height: ${props => props.large ? '38px' : props.height || '8px'}
  background-color: ${props => props.background && !props.large ? props.background : props.theme.primaryColor};
  background-color: ${props => props.large ? props.theme.backgroundColor : props.background};
  border-radius: ${props => props.large ? '4px' : props.borderradius};
  width: ${props => props.width};
  transition: width 300ms linear;
`;

const Title = styled.span`
  top: 50%;
  transform: translate(12px, -50%);
  position: absolute;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
`;

const SlideIn = keyframes`
  0% {
    opacity: 0.1;
    display: inline-block;
    right: -18px;
  }
  100% {
    opacity: 1;
    right: 12px;
  }
`;

const Tick = styled(TickingSvg).attrs({
  stroke: props => props.theme.primaryColor,
})`
  position: absolute;
  ${props => props.progress && props.progress >= 100 ? `animation: ${SlideIn} 500ms ease-in-out 200ms 1` : 'opacity: 0'};  
  top: 59%;
  transform: translateY(-12px);
  animation-fill-mode: forwards
`;

const ProgressBar = ({
  progress,
  width,
  height,
  large,
  borderradius,
  background,
  children,
  rejected,
  ...rest
}) => {
  const [completed, setCompleted] = useState(false);
  const progressNum = typeof progress === 'number' ? progress : 0;
  if (!completed && progressNum >= 100) setCompleted(true);
  return (
  <OuterBar
    width={width}
    large={large}
    borderradius={borderradius}
    rejected={rejected}
    {...rest}
  >
    <InnerBar
      width={!completed ? `${progressNum}%` : '100%'}
      height={height}
      large={large}
      background={background}
      borderradius={borderradius}
      {...rest}
    >
      {(!!children && large) ? (
      <>
        <Title>
          {children}
        </Title>
        <Tick progress={!completed ? progressNum : 100} />
      </>) : ''}
    </InnerBar>
  </OuterBar>
  );
};

ProgressBar.propTypes = {
  borderradius: PropTypes.string,
  large: PropTypes.bool,
  progress: PropTypes.number,
};

ProgressBar.defaultProps = {
  progress: 0,
  borderradius: '6.5px',
  large: false
};

export default ProgressBar;
