import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as _ from 'lodash';

import { SortableHandle } from 'react-sortable-hoc';

import colors from 'common/styles/colors';
import { connect } from 'react-redux';
import FlexContainer from '../../atoms/FlexContainer';

import { mapDayToString } from '../../../constants';
import Calendar from '../../molecules/ReactCalendar';
import OutsideAlerter from '../../../OutsideAlerter';
import Button from '../../atoms/Button';
import H3 from '../../../typography/H3/H3';
import CalendarSvg from '../../../images/CalendarSvg';
import MoreIconSvg from '../../../images/MoreIconSvg';
import ExpandSvg from '../../../images/ExpandSvg';
import Filter_T from '../../molecules/Filter';
import InputField from '../../atoms/InputField';
import Radiobutton from '../../molecules/Radiobutton';

import MinimizeSvg from '../../../images/MinimizeSvg';
import DragHandlerIcon_T from '../../../images/DragHandlerIcon';
import Selection from './Selection';
import InlineModal from '../../molecules/InlineModal';

const DragHandlerIcon = () => (
  <div style={{ marginLeft: '1em' }}>
    <DragHandlerIcon_T />
  </div>
);

const SideDropdown = styled.div`
  display: none;
  position: absolute;
  left: -150px;
  margin-top: -25px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0 0 8px 0 rgba(60,61,58,0.24);
  z-index: 3;
`;

const HoverableSideDropdown = styled.div`
  &:hover {
    .sidedropdown {
      display: block;
    }
  }
`;

const DragHandle = SortableHandle(() => <DragHandlerIcon />);

const ChartHeaderRowContainer = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
})`
  position: relative;
  margin-bottom: 0.2em;
  min-height: 40px;
  ${Button} {
    padding: 0 0.5em;
  }
  h3:hover {
    span {
      visibility: visible;
    }
  }
`;

const CalenderContainer = styled.div`
  position: absolute;
  z-index: 3;
  width: 220px;
  top: ${props => props.to ? '103px' : '33px'};
`;

const BtnContainer = styled.div`
  display: flex;
  outline: none;
  border: none;
  padding: 0 0.3em;
  margin: 0 0.3em;
  cursor: pointer;
  &:last-of-type {
    padding-right: 0;
    margin-right: 0;
  }
  ${MoreIconSvg} {
    transform: rotate(90deg) translateX(6px);

  }
  ${props => props.disableExpand && `
    cursor: not-allowed;
  `}
`;

const Title = styled(H3)`
  margin: 0;
  ${props => props.marginLeft && `margin-left: ${props.marginLeft}`};
  font-weight: 600;
  font-size: 14px;
  color: #333;
`;

const SubTitle = styled(H3)`
  font-weight: 600;
  font-size: 10px;
  color: #333;
  margin-bottom: 5px;
  width: max-content;
  ${props => props.hover && `
    &:hover {
      cursor: pointer;
      color: ${props.theme.primaryColor};
    }
  `}
`;

const MenuItem = styled.button`
  display: flex;
  font-size: 14px;
  color: ${props => props.theme.colors.black};
  font-family: 'Petasense Open Sans';
  font-weight: 600;
  flex-direction: row;
  align-items: center;
  padding: 10px 1em;
  text-align: left;
  width: 100%;
  border: none;
  outline: none;
  background-color: white;
  ${props =>
    props.disabled
      ? `
    color: ${props.theme.colors.grey};
    svg {
      color: ${props.theme.colors.grey};
      #icon {
        stroke: ${props.theme.colors.grey};
      }
    }
  `
      : `
    &:hover {
      color: ${props.theme.primaryColor};
      svg {
        color: ${props.theme.primaryColor};
        #icon {
          stroke: ${props.theme.primaryColor};
        }
      }
      ${props.hoverable && `
        .sidedropdown {
          display: block;
        }
      `}
    }
    &:action {
      color: ${props.theme.primaryColor};
    }
  `} &:first-of-type {
    padding-top: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    padding-bottom: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const DateSelector = styled.div`
  height: 100%;
  display: inline-block;
  width: 250px;
  vertical-align: top;
  padding: 10px 15px;
  box-sizing: border-box;
  ${InputField} {
    margin-bottom: 13.5px;
  }
  input {
    font-size: 1em;
    padding: .57857143em 1em;
  }
  label {
    font-weight: 600;
    margin-bottom: 3px;
  }
`;

const DateContainer = styled(FlexContainer).attrs({
  justifyContent: 'flex-start',
  flexDirection: 'column',
})`
  flex-direction: column;
  height: 100%;
  display: inline-flex;
  vertical-align: top;
  width: 119px;
  overflow: hidden;
`;

const ListOption = styled.span`
  margin-left: 10px;
  color: ${props => props.selected ? props.theme.colors.black : props.theme.colors.greyXD};
  font-weight: ${props => props.selected && 600};
  white-space: pre;
`;

const FilterItem = styled.button`
  display: flex;
  align-items: center;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  width: 100%;
  border: none;
  outline: none;
  background-color: white;
  font-family: 'Petasense Open Sans';
  font-weight: 600;
  color: ${props => props.theme.colors.lightGray};
  ${props => props.selected && `color: ${props.theme.colors.black};`} &:hover {
    color: ${props => props.theme.colors.black};
    background-color: #f7f7f7;
  }
  &:first-of-type {
    padding-top: 1em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    padding-bottom: 1em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  ${Radiobutton} {
    margin-left: 5px;
  }
`;

const formatDate = (locale, date) => {
  const day = date.getDay();
  return mapDayToString[day][0];
  // if (day !== 6 && day !== 0) return mapDayToString[day][0];
  // return mapDayToString[day].slice(0, 2);
};

const Filter = styled(Filter_T)`
  margin: 0 0.3em;
  padding: 0 0.3em;
`;

const ChartHeaderRow = ({
  title,
  sensorSerialNumber,
  txInfo,
  expand,
  expanded,
  calendarOptions,
  calendarSelect,
  calendarExpand,
  calendarExpanded,
  selection,
  menuExpand,
  menuExpanded,
  menuItems,
  marginLeft,
  disableExpand,
  showExpand,
  showDragHandler,
  dateExpanded,
  calenderOpen,
  openCustomCalender,
  closeCalender,
  customDayTo,
  customDayFrom,
  calendarDaySelect,
  toggleDateExpanded,
  customDateSubmit,
  days,
  redirectToSensor,
  zIndex,
  primaryColor
}) => (
    <>
     <ChartHeaderRowContainer>
      <FlexContainer direction="column" flexBasis="40%">
        <Title marginLeft={marginLeft}>{title}</Title>
        {(txInfo && txInfo.serial_number) ? (
          <FlexContainer>
            <SubTitle hover onClick={() => redirectToSensor(sensorSerialNumber)}>{sensorSerialNumber}&nbsp;</SubTitle>
            <SubTitle>on P{txInfo.port_position} C{txInfo.channel_position}&nbsp;</SubTitle>
            <SubTitle hover onClick={() => redirectToSensor(txInfo.serial_number)}>{txInfo.serial_number}</SubTitle>
          </FlexContainer>) :
          <SubTitle hover onClick={() => redirectToSensor(sensorSerialNumber)}>{sensorSerialNumber}</SubTitle>}
      </FlexContainer>
      {selection && <Selection header data={selection} />}
      <FlexContainer
        className="chartMenuContainer"
        direction="row"
        justifyContent="flex-end"
        flexBasis={selection ? '33.33%' : '66.66%'}
      >
        {!_.isEmpty(calendarOptions) ? (
          <Filter
            open={calendarExpanded}
            toggleOpen={calendarExpand}
            Icon={() => calendarExpanded ? <CalendarSvg fill={primaryColor} /> : <CalendarSvg fill="#999B95" />}
            alignHorizontal="right"
            width="max-content"
            zIndex={zIndex}
          >
            <DateContainer>
            {calendarOptions.map((option, idx) => {
              let selected = days === option.value;
              if (dateExpanded) selected = option.value === 'custom';
              return (
                  <FilterItem
                    key={idx.toString()}
                    width="119px"
                    selected={selected}
                    onClick={() => {
                      if (option.value === 'custom') {
                        return toggleDateExpanded('dateExpanded');
                      }
                      calendarSelect(option.value);
                    }}
                  >
                    <Radiobutton margin="0px 0px 0px 12px" value={selected} />
                    <ListOption selected={selected}>{option.text}</ListOption>
                  </FilterItem>
              );
            })}
            </DateContainer>
            {dateExpanded && (
              <DateSelector>
                {calenderOpen === 'from' && (
                  <OutsideAlerter open={calenderOpen === 'from'} handleClick={closeCalender}>

                    <CalenderContainer>
                      <Calendar
                        formatShortWeekday={(locale, date) => formatDate(locale, date)}
                        maxDetail="month"
                        maxDate={customDayTo || new Date()}
                        showNeighboringMonth={false}
                        onClickDay={value => calendarDaySelect(value, 'from')}
                        value={customDayFrom}
                      />
                    </CalenderContainer>
                  </OutsideAlerter>
                )}
                {calenderOpen === 'to' && (
                  <OutsideAlerter open={calenderOpen === 'to'} handleClick={closeCalender}>
                    <CalenderContainer to="true">
                      <Calendar
                        formatShortWeekday={(locale, date) => formatDate(locale, date)}
                        maxDetail="month"
                        maxDate={new Date()}
                        minDate={customDayFrom}
                        value={customDayTo}
                        showNeighboringMonth={false}
                        onClickDay={value => calendarDaySelect(value, 'to')}
                      />
                    </CalenderContainer>
                  </OutsideAlerter>
                )}
                <InputField label="From" readOnly value={customDayFrom ? customDayFrom.toString().slice(0, 15) : ''} onFocus={() => openCustomCalender('from')} />
                <InputField label="To" readOnly value={customDayTo ? customDayTo.toString().slice(0, 15) : ''} onFocus={() => openCustomCalender('to')} />
                <FlexContainer justifyContent="space-between">
                  <Button
                    text
                    danger
                    onClick={toggleDateExpanded}
                  >
                    Close
                  </Button>
                  <Button
                    text
                    disabled={!customDayFrom || !customDayTo}
                    onClick={customDateSubmit}
                  >
                    Submit
                  </Button>
                </FlexContainer>
              </DateSelector>)
          }
          </Filter>) : ''}
        {menuExpand && (
          <BtnContainer onClick={menuExpand}>
            <MoreIconSvg fill={menuExpanded ? primaryColor : '#9ea09a'} />
          </BtnContainer>
        )}
        {showExpand && (
          <BtnContainer onClick={expand} disableExpand={disableExpand}>
            {expanded && !disableExpand ? <MinimizeSvg fill={primaryColor} /> : <ExpandSvg fill="#9ea09a" />}
          </BtnContainer>
        )}
        {showDragHandler && <span style={{ cursor: 'row-resize' }}><DragHandle /></span>}
      </FlexContainer>
      {menuExpanded && (
        <InlineModal noScroll close={menuExpand} zIndex={zIndex}>
          {menuItems.map((item, idx) => (
            <HoverableSideDropdown key={idx} hoverable={item.innerDropdown}>
              <MenuItem disabled={item.disabled} hoverable={item.innerDropdown} onClick={item.onClick} key={idx}>
                <span style={item.innerDropdown ? { marginLeft: '0' } : {}}>{item.text}</span>
              </MenuItem>
              {item.innerDropdown && (
                <SideDropdown className="sidedropdown" key={item.id}>
                  {item.innerDropdown.map((i, index) => (
                    <MenuItem onClick={i.onClick} disabled={i.disabled} key={`sidedropdown-${item.id}-idx-${index}`}>
                      <span>{i.text}</span>
                    </MenuItem>
                  ))}
                </SideDropdown>
              )}
            </HoverableSideDropdown>
          ))}
        </InlineModal>
      )}
     </ChartHeaderRowContainer>
    </>
);

ChartHeaderRow.propTypes = {
  calendarOptions: PropTypes.array.isRequired,
  calendarSelect: PropTypes.func,
  calendarExpand: PropTypes.func,
  calendarExpanded: PropTypes.bool,
  title: PropTypes.string.isRequired,
  expand: PropTypes.func,
  expanded: PropTypes.bool,
  selection: PropTypes.object,
  showExpand: PropTypes.bool,
  menuExpand: PropTypes.func,
  menuExpanded: PropTypes.bool,
  menuItems: PropTypes.array,
  showDragHandler: PropTypes.bool,
  redirectToSensor: PropTypes.func,
  zIndex: PropTypes.number
};

ChartHeaderRow.defaultProps = {
  calendarSelect: () => {},
  calendarExpand: () => {},
  calendarExpanded: false,
  expand: () => {},
  expanded: false,
  redirectToSensor: () => {},
  selection: undefined,
  menuExpand: undefined,
  menuExpanded: false,
  showDragHandler: true,
  showExpand: true,
  menuItems: [],
  zIndex: 2
};

const mapStateToProps = state => ({
  primaryColor: state.companyReducer.partner.theme.primaryColor
});

export default connect(mapStateToProps)(ChartHeaderRow);
