export const mapNames = {
  rms: 'RMS',
  p2p: 'P2P',
  peak: 'Peak',
  crest_factor: 'Crest Factor',
  velocity: 'Velocity',
  acceleration: 'Acceleration',
  normal: 'Normal',
  Hz: 'Hz',
  RPM: 'RPM',
  demod: 'Demod',
  shaft_speed_1x: '1x',
  shaft_speed_2x: '2x',
  shaft_speed_3x: '3x',
  shaft_speed_4x: '4x',
  shaft_speed_4x_to_6x_sum: 'Σ(4x-6x)',
  demod_1: 'Demod 1',
  demod_2: 'Demod 2',
  bpf_1x_to_3x_sum: 'Σ(1xBPF-4xBPF)',
  lf_1x_to_4x_sum: 'Σ(1xLF-4xLF)',
  vpf_1x_to_3x_sum: 'Σ(1xVPF-3xVPF)',
  lf_1x_to_6x_sum: 'Σ(1xLF-6xLF)',
  gmf_1x_to_3x_sum: 'Σ(1xGMF-3xGMF)',
  gmf: 'GMF',
  '1x_sidebands_gmf': '1x Sidebands GMF'
};

export const mapFeatureType = {
  rms: 'Broadband',
  p2p: 'Broadband',
  peak: 'Broadband',
  crest_factor: 'Broadband',
  demod: 'Narrowband',
  shaft_speed_1x: 'Narrowband',
  shaft_speed_2x: 'Narrowband',
  shaft_speed_3x: 'Narrowband',
  shaft_speed_4x: 'Narrowband',
  shaft_speed_4x_to_6x_sum: 'Narrowband',
  demod_1: 'Narrowband',
  demod_2: 'Narrowband',
  bpf_1x_to_3x_sum: 'Narrowband',
  lf_1x_to_4x_sum: 'Narrowband',
  vpf_1x_to_3x_sum: 'Narrowband',
  lf_1x_to_6x_sum: 'Narrowband',
  gmf_1x_to_3x_sum: 'Narrowband',
  gmf: 'Narrowband',
  '1x_sidebands_gmf': 'Narrowband'
};

export const mapUtilizationStatus = [
  'NA',
  'OFF',
  'ON',
];

export const utilColors = [
  '#d3d3d3',
  '#ced7a9',
  '#91b846'
];

export const calendarOptions = [
  { text: '1D', value: 1 },
  { text: '7D', value: 7 },
  { text: '30D', value: 30 },
  { text: '3M', value: 90 },
  { text: '1Y', value: 365 },
  { text: 'All', value: undefined },
  { text: 'Custom', value: 'custom' }
];

export const measurementOptions = [
  {
    text: 'High Resolution',
    value: 2,
  },
  {
    text: 'Full Bandwidth',
    value: 1,
  },
  {
    text: 'Overalls',
    value: 0,
  }
];

export const getBinFilterOptions = (bin_count, include_zero = false) => {
  const binFilterOptions = [];
  if (include_zero) binFilterOptions.push({
    text: 'Default',
    value: 0
  });
  for (let i = 1; i <= bin_count; i++) {
    binFilterOptions.push({
      text: `Speed Range ${i}`,
      value: i
    });
  }
  return binFilterOptions;
};

export const ASSET_TYPE = {
  VFD: 'VFD',
  FIXED_SPEED: 'FIXED_SPEED'
};

export const spectraOptions = [
  { text: '5', value: 5 },
  { text: '10', value: 10 },
  { text: '15', value: 15 }
];

export const defaultChartSettings = {
  scalar: {
    chartType: 'trend',
    feature: '',
    amp_type: '',
    measurement_type: 1
  },
  vector: {
    chartType: 'TSW',
    feature: 'rms',
    amp_type: 'velocity',
    measurement_type: 1
  }
};

export const vectorTagTypes = ['vibration', 'current', 'magnetic_flux', 'ultrasound_vector'];

export const getDirectionOrder = vibration_direction_preference => vibration_direction_preference === 1 ? ['radial', 'tangential', 'axial'] : ['vertical', 'horizontal', 'axial'];
export const phaseOrder = ['r', 'y', 'b'];

export const tagTypeOrder = ['vibration', 'temperature', 'shaft_speed', 'magnetic_flux'];
