import React, { useRef, useState, useEffect } from 'react';
import { Ref } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tab, { TAB_ITEM_MARGIN } from '../atoms/Tab';
import MoreDropdownTab from './MoreDropdownTab';

const DynamicTabs = ({
  bufferSpace,
  panes,
  activeIndex,
  ...props
}) => {
  const [tabsSplitIndex, setTabsSplitIndex] = useState(panes.length);
  const tabsContainerRef = useRef();
  const { current: tabItemLengths } = useRef([]);
  const renderAllTabs = useRef(true); // render all initially to calculate widths
  const { current: resizeObserver } = useRef(new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      findSplitIndex(entry.target.offsetWidth - bufferSpace);
    });
  }));

  useEffect(() => {
    calcTabItemsLengths();
    resizeObserver.observe(tabsContainerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const calcTabItemsLengths = () => {
    if (tabsContainerRef.current) {
      tabsContainerRef.current.querySelectorAll('a.item').forEach(
        item => tabItemLengths.push(item.offsetWidth)
      );
    }
  };

  const findSplitIndex = (availableWidth) => {
    const [moreTabWidth] = tabItemLengths.slice(-1);
    let sum = TAB_ITEM_MARGIN.FIRST_ITEM_LEFT + moreTabWidth;
    let splitIndex = tabItemLengths.length;

    for (let i = 0; i < tabItemLengths.length - 1; i++) {
      if (i === tabItemLengths.length - 2) sum -= moreTabWidth + TAB_ITEM_MARGIN.RIGHT;
      if (sum + tabItemLengths[i] + TAB_ITEM_MARGIN.RIGHT > availableWidth) {
        splitIndex = i;
        break;
      } else {
        sum += tabItemLengths[i] + TAB_ITEM_MARGIN.RIGHT;
      }
    }

    setTabsSplitIndex(splitIndex);
  };

  const splitAndGetTabItems = () => {
    const tabItems = panes.slice(0, tabsSplitIndex);
    if (renderAllTabs.current || !_.isEmpty(panes.slice(tabsSplitIndex))) {
      tabItems.push({
        menuItem: (
          <MoreDropdownTab
            items={panes.slice(tabsSplitIndex)}
            activeIndex={activeIndex - tabsSplitIndex}
          />
        ),
        key: 'more'
      });
    }
    renderAllTabs.current = false;
    return tabItems;
  };

  return (
    <Ref innerRef={tabsContainerRef}>
      <Tab
        panes={splitAndGetTabItems()}
        {...props}
      />
    </Ref>
  );
};

DynamicTabs.propTypes = {
  bufferSpace: PropTypes.number,
  panes: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired
};

DynamicTabs.defaultProps = {
  bufferSpace: 0
};

export default DynamicTabs;
