import React, { useState } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import {
  SortableElement,
  SortableContainer,
} from 'react-sortable-hoc';

import ConditionalFragment from '../../atoms/ConditionalFragment/ConditionalFragment';
import MachineNodeItem from '../../molecules/MachineNodeItem/MachineNodeItem';


const findNodeDepth = (currNode) => {
  const findTotalDepth = (nodes, currHeight = 0) => {
    nodes.forEach((node) => {
      currHeight += 1;
      if (!node || !node.expanded) return;
      const addedHeight = findTotalDepth(node.children);
      currHeight += addedHeight;
    });
    return currHeight;
  };
  const totalDepth = findTotalDepth([currNode]);
  if (!currNode.children.length) return totalDepth - 1;
  const removeDepth = findTotalDepth([currNode.children[currNode.children.length - 1]]);
  return totalDepth - removeDepth;
};

const SortableComponentContainer = SortableContainer(
  ({
    nodes,
    deleteNode,
    spawnNode,
    switchNode,
    selectedNodeId,
    selectedNodeDetails,
    toggleExpand,
    isInfo,
    editMode,
    editIconClick,
    sortEnd,
    sortStart,
    getMissingFields,
    editable
  }) => (
    <div>
      {Object.values(nodes).map((node, idx) => (
        <SortableComponentItem
          node={node}
          index={idx}
          key={`componenet_${node.id}`}
          nodes={nodes}
          deleteNode={deleteNode}
          spawnNode={spawnNode}
          switchNode={switchNode}
          selectedNodeId={selectedNodeId}
          selectedNodeDetails={selectedNodeDetails}
          toggleExpand={toggleExpand}
          isInfo={isInfo}
          editMode={editMode}
          editIconClick={editIconClick}
          onSortEnd={sortEnd}
          onSortStart={sortStart}
          sortEnd={sortEnd}
          sortStart={sortStart}
          getMissingFields={getMissingFields}
          editable={editable}
        />
      ))}
    </div>
  ));

const SortableComponentItem = SortableElement(
  ({
    node,
    index,
    nodes,
    deleteNode,
    spawnNode,
    switchNode,
    selectedNodeId,
    selectedNodeDetails,
    toggleExpand,
    isInfo,
    editMode,
    editIconClick,
    sortEnd,
    sortStart,
    getMissingFields,
    editable
  }) => (
    <ConditionalFragment
      showFrag={(node.children === undefined || node.children.length === 0)}
      height="100%"
      key={node.id}
      style={{ position: 'relative' }}
      depth={node.depth}
    >
      <MachineNodeItem
        node={node}
        depth={node.depth}
        family={{ siblings: nodes.length, childs: node.children.length, index }}
        key={node.node_type + node.id}
        activeDepth={findNodeDepth(node)}
        name={node.nodeName}
        deleteNode={() => deleteNode(node)}
        createChild={() => spawnNode(node.id, node.nodeType)}
        switchNode={() => switchNode(node.id, node.nodeType)}
        selected={selectedNodeId === node.id && selectedNodeDetails.nodeType === node.nodeType}
        toggleExpand={() => toggleExpand(node.id, node.nodeType)}
        expanded={node.expanded}
        isInfo={isInfo}
        editMode={editMode}
        editIconClick={editIconClick}
        getMissingFields={getMissingFields(node.id, node.nodeType)}
        editable={editable}
      />
    {!_.isEmpty(node.children) && node.expanded && (
      <SortableComponentContainer
        lockAxis="y"
        helperClass="hierarchy-drag-helper"
        getContainer={() => document.getElementById('asset-info')}
        useDragHandle
        index={index}
        nodes={node.children}
        deleteNode={deleteNode}
        spawnNode={spawnNode}
        switchNode={switchNode}
        selectedNodeId={selectedNodeId}
        selectedNodeDetails={selectedNodeDetails}
        toggleExpand={toggleExpand}
        isInfo={isInfo}
        editMode={editMode}
        editIconClick={editIconClick}
        onSortEnd={sortEnd}
        onSortStart={sortStart}
        sortEnd={sortEnd}
        sortStart={sortStart}
        getMissingFields={getMissingFields}
        editable={editable}
      />
    )}
    </ConditionalFragment>
  )
);
const NodeList = ({
  deleteNode,
  spawnNode,
  switchNode,
  toggleExpand,
  selectedNodeId,
  selectedNodeDetails,
  treeNodes,
  isInfo,
  editMode,
  editIconClick,
  reorderTree,
  getMissingFields,
  editable
}) => {
  const [draggedText, setDraggedText] = useState(null);
  const sortStart = ({ node }) => {
    const selectedSpan = node.querySelector('.nodeName');
    setDraggedText(selectedSpan.getAttribute('name'));
  };
  const sortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const [nodeType, nodeId] = draggedText.split('_');
      try {
        reorderTree(oldIndex, newIndex, nodeType, Number(nodeId));
        setDraggedText(null);
      } catch (e) { console.log(e); }
    }
  };
  return (
    <div>
      {Object.values(treeNodes)
        .map((node, idx) => (
          <ConditionalFragment
            showFrag={(node.children === undefined || node.children.length === 0)}
            height="100%"
            key={node.id}
            style={{ position: 'relative' }}
            depth={node.depth}
          >
            <MachineNodeItem
              node={node}
              depth={node.depth}
              family={{ siblings: treeNodes.length, childs: node.children.length, index: idx }}
              key={node.id}
              activeDepth={findNodeDepth(node)}
              name={node.nodeName}
              deleteNode={() => deleteNode(node)}
              createChild={() => spawnNode(node.id, node.nodeType)}
              switchNode={() => switchNode(node.id, node.nodeType)}
              selected={selectedNodeId === node.id && selectedNodeDetails.nodeType === node.nodeType}
              toggleExpand={() => toggleExpand(node.id, node.nodeType)}
              expanded={node.expanded}
              isInfo={isInfo}
              editMode={editMode}
              editIconClick={editIconClick}
              getMissingFields={getMissingFields(node.id, node.nodeType)}
              editable={editable}
            />
            {!_.isEmpty(node.children) && node.expanded && (
              <SortableComponentContainer
                lockAxis="y"
                helperClass="hierarchy-drag-helper"
                getContainer={() => document.getElementById('asset-info')}
                useDragHandle
                nodes={node.children}
                deleteNode={deleteNode}
                spawnNode={spawnNode}
                switchNode={switchNode}
                selectedNodeId={selectedNodeId}
                selectedNodeDetails={selectedNodeDetails}
                toggleExpand={toggleExpand}
                isInfo={isInfo}
                editMode={editMode}
                editIconClick={editIconClick}
                onSortEnd={sortEnd}
                onSortStart={sortStart}
                sortEnd={sortEnd}
                sortStart={sortStart}
                getMissingFields={getMissingFields}
                editable={editable}
              />
            )}
          </ConditionalFragment>
        ))
      }
    </div>
  );
};

NodeList.propTypes = {
  deleteNode: PropTypes.func,
  spawnNode: PropTypes.func,
  switchNode: PropTypes.func.isRequired,
  toggleExpand: PropTypes.func.isRequired,
  treeNodes: PropTypes.array.isRequired,
  selectedNodeId: PropTypes.number.isRequired,
  reorderTree: PropTypes.func
};

NodeList.defaultProps = {
  reorderTree: () => {}
};

export default NodeList;
