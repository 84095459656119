import types from './types';

export const analystReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_MACHINE_TRAIN_HEALTH_REQUEST: {
      return {
        loading: true
      };
    }
    case types.GET_MACHINE_TRAIN_HEALTH_SUCCESS: {
      return {
        object: action.result,
        machine_id: action.machine_id
      };
    }
    case types.GET_MACHINE_TRAIN_HEALTH_FAILURE: {
      return {
        error: action.error
      };
    }

    case types.SUBMIT_ANALYST_ASSESSMENT_REQUEST: {
      return {
        ...state,
        submitting: true
      };
    }
    case types.SUBMIT_ANALYST_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        submitting: false
      };
    }
    case types.SUBMIT_ANALYST_ASSESSMENT_FAILURE: {
      return {
        ...state,
        submitting: false
      };
    }

    default:
      return state;
  }
};
