import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import List from 'common/components/molecules/List';
import Button from 'common/components/atoms/Button';
import InputField from 'common/components/atoms/InputField';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Label from 'common/typography/Label/Label';
import Text from 'common/typography/Text/Text';
import H2 from 'common/typography/H2/H2';
import H3 from 'common/typography/H3/H3';
import * as utils from 'common/utils';
import TagSelectItem from './TagSelectItem';
import { mapNames } from '../machineCharts.constants';

const RowContainer = styled(FlexContainer).attrs({ justifyContent: 'space-between' })`
  ${InputField} {
    flex-basis: 49%;
    ${Label} {
      color: ${props => props.theme.colors.greyXXD};
      ${props => props.theme.fonts.boldLabel};
      margin-bottom: 6px
    }
  }
`;

const TrendWrapper = styled(FlexContainer)`
  padding: 0 24px 24px 24px;
`;

const ListContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  position: relative;
  overflow: auto;
  width: ${props => props.width};
  height: 100%;
  max-height: 17rem;
  ${props =>
    props.width === '30%' &&
    `border-right: 2px solid ${props.theme.colors.greyL};`}
`;

const TagsSelectionContainer = styled(FlexContainer)`
  border: 1px solid ${props => props.theme.colors.greyL};
  border-radius: 4px;
  margin-bottom: 1em;
`;

const SubTitle = styled(H3)`
  font-weight: 600;
  font-size: 10px;
  color: inherit;
  margin-bottom: 5px;
`;

class AddTrendDetailsContainer extends Component {
  constructor(props) {
    super(props);

    this.changeColor = this.changeColor.bind(this);
    this.changeConfig = this.changeConfig.bind(this);

    const tagTypeOptions = this.getTagTypeOptions(props.tags);

    let newConfig = {};
    let selectedTagsList = [];
    if (!_.isEmpty(props.configGroup) && !_.isEmpty(props.configGroup.config_list)) {
      newConfig = _.omit(props.configGroup.config_list[0], ['tag_id', 'color']);
      selectedTagsList = props.configGroup.config_list.map(item => ({
        color: item.color,
        tag_id: item.tag_id
      }));
    } else {
      newConfig = {
        amp_type: undefined,
        feature: undefined,
        tag_structure_type: undefined,
        tag_type: undefined
      };
    }

    this.state = {
      saveDisabled: true,
      config: newConfig,
      selectedTagsList,
      tagTypeOptions,
      tagOptions: _.filter(props.tags, tag => tag.type === newConfig.tag_type)
        .map(tag => ({
          text: <FlexContainer direction="column">
                {tag.description}
                  <SubTitle>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {tag.device_label ? `${tag.device_label}` : tag.type === 'shaft_speed' ? 'Auto-Detected' : ''}
                  </SubTitle>
                </FlexContainer>,
          value: tag.id
        }))
    };
  }

  componentDidUpdate(prevProps) {
    const propsConfigTagList = !_.isEmpty(this.props.configGroup) && !_.isEmpty(this.props.configGroup.config_list) ?
      this.props.configGroup.config_list.map(item => ({
        color: item.color,
        tag_id: item.tag_id
      })) :
      undefined;

    if (!this.state.saveDisabled &&
      this.getGroup(this.state.config) === this.props.selectedGroup &&
      _.isEqual(this.state.selectedTagsList, propsConfigTagList)
    ) {
      this.setState({ saveDisabled: true });
    }

    if (this.props.tags !== prevProps.tags) {
      this.setState(({ tagTypeOptions: this.getTagTypeOptions(this.props.tags) }));
    }
  }

  changeColor(tag_id, color) {
    if (this.props.takenColors.includes(color)) return;

    this.setState(prevState => ({
      selectedTagsList: prevState.selectedTagsList.map((tagItem) => {
        if (tagItem.tag_id !== tag_id) return tagItem;
        return {
          ...tagItem,
          color
        };
      }),
      saveDisabled: false
    }));
  }

  getTagTypeOptions = tags => (
    _.chain(tags)
      .map('type')
      .uniq()
      .filter(type => type !== 'utilization')
      .map(type => ({ text: utils.CapitalizeEachWord(type), value: type }))
      .value()
  )

  getGroup = (trend_config) => {
    const { tag_type, amp_type, feature } = trend_config;
    return `${tag_type}_${amp_type}_${feature}`;
  }

  getTagOptions = () => {
    const { defaultChart } = this.props;
    const { selectedTagsList } = this.state;
    let { tagOptions } = this.state;

    if (defaultChart && !_.isEmpty(selectedTagsList)) {
      tagOptions = tagOptions.filter(option =>
        selectedTagsList[0].tag_id === option.value);
    }

    return tagOptions.map((option) => {
      const selectedItem = _.find(selectedTagsList, tagItem => tagItem.tag_id === option.value);
      return {
        ...option,
        selected: !!selectedItem,
        color: selectedItem ? selectedItem.color : ''
      };
    });
  }

  toggleTagOption = (tag_id, color) => {
    if (this.props.defaultChart) return;

    let selectedTagsList;
    selectedTagsList = [...this.state.selectedTagsList];

    const existingTagItemIdx = _.findIndex(selectedTagsList, tag => tag.tag_id === tag_id);

    if (existingTagItemIdx >= 0) {
      selectedTagsList = _.filter(selectedTagsList, tag => tag.tag_id !== tag_id);
    } else {
      selectedTagsList = [...selectedTagsList, { tag_id, color }];
    }

    let saveDisabled = true;
    if (!_.isEmpty(selectedTagsList)) {
      const formFilled = this.props.configMetadata[this.state.config.tag_type].chart_config
        .every(cfg => this.state.config[cfg.name] !== undefined);
      saveDisabled = !formFilled;
    }

    this.setState({ selectedTagsList, saveDisabled });
  }

  changeConfig(e, data) {
    if (data.value === this.state.config[data.name]) return;

    const newState = {
      config: {
        ...this.state.config,
        [data.name]: data.value
      }
    };

    switch (data.name) {
      case 'tag_type': {
        newState.config.amp_type = undefined;
        newState.config.feature = undefined;
        newState.config.tag_structure_type = this.props.configMetadata[data.value].structure_type;
        newState.tagOptions = _.filter(this.props.tags, tag => tag.type === data.value)
          .map(tag => ({
            text: <FlexContainer direction="column">
                  {tag.description}
                    <SubTitle>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {tag.device_label ? `${tag.device_label}` : tag.type === 'shaft_speed' ? 'Auto-Detected' : ''}
                    </SubTitle>
                  </FlexContainer>,
            value: tag.id
          }));
        break;
      }
      case 'tag_id': {
        newState.config.amp_type = undefined;
        newState.config.feature = undefined;
        break;
      }
      default: {
        break;
      }
    }

    if (!_.isEmpty(this.state.selectedTagsList)) {
      const formFilled = this.props.configMetadata[newState.config.tag_type].chart_config
        .every(cfg => newState.config[cfg.name] !== undefined);
      newState.saveDisabled = !formFilled;
    }

    this.setState(prevState => ({
      ...prevState,
      ...newState
    }));
  }

  render() {
    const {
      config,
      config: {
        tag_type
      },
      selectedTagsList
    } = this.state;
    const {
      updateConfig,
      defaultChart,
      loading
    } = this.props;

    const takenColorsForConfig = selectedTagsList.map(tagItem => tagItem.color);

    const listProps = {
      columnSizes: [50, 50],
      headers: [
        {
          name: 'tag_name',
          label: 'Tag Name'
        },
        {
          name: 'tag_color',
          label: 'Tag Color'
        }
      ],
      hasMore: false,
      loading: false,
      items: {
        object: this.getTagOptions()
      },
      emptyMsg: `No ${tag_type} tags found on this machine`,
      editMode: true,
      defaultChart,
      takenColors: [...this.props.takenColors, ...takenColorsForConfig],
      ItemComponent: TagSelectItem,
      toggleOption: this.toggleTagOption,
      changeColor: this.changeColor
    };

    return (
      <TrendWrapper direction="column">
        <H2>{this.props.title}</H2>
        <RowContainer>
          <InputField
            label="Tag Type"
            type="select"
            name="tag_type"
            options={this.state.tagTypeOptions}
            placeholder="Click to select a tag type"
            value={tag_type}
            onChange={this.changeConfig}
            disabled={defaultChart || loading}
          />
        </RowContainer>
        <RowContainer>
          {this.props.configMetadata[tag_type] && this.props.configMetadata[tag_type].chart_config.map(((cfgOption, idx) => (
            <InputField
              key={idx}
              label={cfgOption.name === 'amp_type' ? 'Amplitude type' : utils.CapitalizeEachWord(utils.HumanizeKeyValue(cfgOption.name))}
              type="select"
              name={cfgOption.name}
              options={
                cfgOption.values.map(value => (
                  {
                    text: (cfgOption.name === 'feature' || cfgOption.name === 'units') ? mapNames[value] : utils.CapitalizeEachWord(value),
                    value
                  }
                ))
              }
              placeholder="Click to select"
              value={this.state.config[cfgOption.name]}
              onChange={this.changeConfig}
              disabled={loading}
            />
          )))}
        </RowContainer>

        {tag_type && (
          <TagsSelectionContainer>
            {this.state.tagOptions && this.state.tagOptions.length ? (
              <ListContainer
                width="100%"
              >
                <List {...listProps} />
              </ListContainer>
            ) : (
              <Text>No {tag_type} tags found on this machine</Text>
            )}
          </TagsSelectionContainer>
        )}

        <Button
          disabled={this.state.saveDisabled}
          left
          onClick={() => updateConfig(config, selectedTagsList)}
        >
          Save
        </Button>
      </TrendWrapper>
    );
  }
}

AddTrendDetailsContainer.propTypes = {
  title: PropTypes.string,
  selectedGroup: PropTypes.string.isRequired,
  configGroup: PropTypes.object.isRequired,
  configMetadata: PropTypes.object.isRequired,
  takenColors: PropTypes.array.isRequired,
  updateConfig: PropTypes.func.isRequired
};

AddTrendDetailsContainer.defaultProps = {
  title: ''
};

export default AddTrendDetailsContainer;
