import { combineReducers } from 'redux';

import { attachmentConstants } from '../constants/attachments.constants';

export const attachmentsReducer = combineReducers({
  upload,
  files
});

export function upload(state = {}, action) {
  switch (action.type) {
    case attachmentConstants.CHANGE_UPLOAD_PROGRESS:
      return {
        ...state,
        [action.originType]: {
          ...state[action.originType],
          index: action.index,
          progress: action.value,
        }
      };

    case attachmentConstants.RESET_UPLOAD:
      return {
        ...state,
        [action.originType]: {}
      };

    case attachmentConstants.UPLOAD_ATTACHMENT_SUCCESS:
      return {
        ...action.files,
      };

    case attachmentConstants.UPLOAD_ATTACHMENT_FAILURE:
      return {
        ...action.files
      };

    default:
      return state;
  }
}

export function files(state = {}, action) {
  switch (action.type) {
    case attachmentConstants.SET_ATTACHMENT_FILES:
      return {
        ...state,
        [action.originType]: [
          ...(state[action.originType] ? state[action.originType] : []),
          ...action.files
        ]
      };

    case attachmentConstants.CLEAR_ATTACHMENT_FILES:
      return {
        ...state,
        [action.originType]: []
      };

    case attachmentConstants.REMOVE_ATTACHMENT_FILE:
      return {
        ...state,
        [action.originType]: state[action.originType].filter(
          file => file.name !== action.filename
        )
      };
    default:
      return state;
  }
}
