import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import styled from 'styled-components';
import Modal from 'common/components/organisms/Modal';
import FlexContainer from 'common/components/atoms/FlexContainer';
import InputField from 'common/components/atoms/InputField';
import Button from 'common/components/atoms/Button';
import { Search } from 'common/images/FaIcons';

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: 15px;
`;

const SensorContainer = styled(FlexContainer).attrs({
  flexWrap: 'wrap'
})`
  width: 100%;
  overflow: auto;
  padding: 20px;
  border: 1px solid #E5E8E1;
  border-radius: 8px;
  max-height: 400px;
  & > * {
    font-size: 12px;
    flex-basis: 50%;
    margin-bottom: 20px;
    font-weight: 400;
  }
  ${InputField} > div {
    font-size: large
  }
`;

const InputFieldSearch = styled(InputField)`
  input {
    padding-left: 0;
  }
`;

const SelectSensorModal = (props) => {
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [sensors, updateSensors] = useState(props.sensors);
  const [searchKey, setSearchKey] = useState('');

  const addSensor = (sensor) => {
    let newSelectedSensors = [];
    const checked = selectedSensors.reduce((acc, curr) => {
      if (curr.serial_number === sensor.serial_number) return true;
      return acc;
    }, false);
    if (checked) {
      newSelectedSensors = selectedSensors.filter(s => s.serial_number !== sensor.serial_number);
    } else {
      newSelectedSensors = selectedSensors.concat([sensor]);
    }
    setSelectedSensors(newSelectedSensors);
  };

  const onSearch = (e) => {
    setSearchKey(e.target.value);
    const filtered = props.sensors.filter(sensor => sensor.serial_number.toLowerCase().includes(e.target.value.toLowerCase()));
    updateSensors(filtered);
  };

  return (
    <Modal
      title="Associate Sensors"
      close={props.close}
      width="600px"
      padding="0"
      maxHeight="600px"
    >
      <InputFieldSearch
        type="text"
        prefix={<Search />}
        prefixSide="left"
        value={searchKey}
        onChange={onSearch}
      />

      <SensorContainer>
        {sensors.map((sensor, idx) => {
          const checked = selectedSensors.reduce((acc, curr) => {
            if (curr.serial_number === sensor.serial_number) return true;
            return acc;
          }, false);
          return (
            <InputField
              onClick={() => addSensor(sensor)}
              color={checked ? '#3C3D3A' : '#999B95'}
              value={checked}
              type="checkbox"
              label={sensor.serial_number}
              labelSide="right"
              key={`${sensor.serial_number}-${idx}`}
            />
          );
        })}
      </SensorContainer>
      <ButtonContainer>
        <Button
          disabled={_.isEmpty(selectedSensors)}
          onClick={() => props.submitSensors(selectedSensors)}
        >
          Done
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

SelectSensorModal.propTypes = {
  sensors: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired
};

export default SelectSensorModal;
