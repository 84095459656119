import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SortableHandle } from 'react-sortable-hoc';

import FlexContainer from 'common/components/atoms/FlexContainer';
import NodeItem from 'common/components/atoms/NodeItem';
import DragHandlerIconT from 'common/images/DragHandlerIcon';
import PlusSvg from 'common/images/PlusSvg';
import MinusSvg from 'common/images/MinusSvg';
import SiteActiveSvg from 'common/images/SiteActiveSvg';
import MachineInactiveSvg from 'common/images/machineInactiveSvg';
import MoreOptions from 'common/components/organisms/MoreOptions';
import docxImages from 'home/Reports/images/docxImages';
import AllChartsIcon from 'common/images/AssetHierarchy/AllChartsIcon';
import { ListAlt } from 'common/images/FaIcons';
import { ColorAccToCondition } from 'common/constants';
import UnreachableMark from 'common/OnboardingComponents/UnreachableMark';
import InstrumentationTaskIcon from 'common/OnboardingComponents/InstrumentationTaskIcon';
import colors from 'common/styles/colors';
import MenuOptions from './MenuOptions';
import SvgBtn from '../atoms/SvgBtn';
import * as assetConstants from '../../constants/assetHierarchy.constants';
import { oneToOneMapping } from '../../../../common/utils';


const H3 = styled.span`
  display: block;
  position: relative;
  overflow: hidden;
  width: ${props => `${props.width - 25 * props.depth - 75}px`};
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 17px;
  height: 17px;
  font-size: 12px!important;
  ${props => props.selected && 'font-weight: bold!important'};
  font-family: Petasense Open Sans;
  ${props => props.theme.fonts.lightTitle};
  color: ${props => `${props.active === false ? '#939393' : '#3C3D3A'}`};
`;

const ItemContainer = styled(FlexContainer)`
  display: flex;
  flex-grow: 5;
  align-items: center;
  height: 100%;
  ${props => `
    margin-left: calc(25px * ${props.depth} + 4px)`
};
  ${SvgBtn} {
    position: relative;
    margin-right: 7.5px;
  }
`;
const FamilyLines = styled.svg`
  overflow: visible!important;
  height: inherit;
  width: 50px;
  position: absolute;
  left: ${props => `calc(25px * ${props.depth - 1})`};
`;

const ThroughPath = styled.path.attrs({
  d: props => props.d,
  stroke: '#999B95'
})`
  overflow: visible;
  stroke: #999B95;
  stroke-width: 1;
  stroke-dasharray: 3;
  fill: none;
`;

const ExternalIconContainer = styled(FlexContainer)`
  position: absolute;
  top: 6px;
  right: 20px;
  ${props => props.marginRight && `margin-right: ${props.marginRight}`}
`;

const BulletImg = styled.img`
  width: 14px;
  padding: 1px 6px 0px 0px;
`;

const DragHandlerIcon = () => (
  <div style={{ cursor: 'row-resize' }}>
    <DragHandlerIconT />
  </div>
);

const DragHandle = SortableHandle(() => <DragHandlerIcon />);

const HierarchyNodeItem = ({
  node,
  editMode,
  name,
  depth,
  currentWidth,
  expanded,
  switchNode,
  family: {
    childs,
  },
  activeDepth,
  selected,
  getNodeMenuOptions,
  newlyAddedMachineIds,
  assetHierarchyIcons,
  primaryColor
}) => (
  <NodeItem
    onClick={() => switchNode(false)}
    selectedWidth
    noBorder
    height="25px"
    padding="15px"
    paddingRight="17px"
  >
    <FamilyLines Lines depth={depth}>
      {childs && expanded && <ThroughPath d={`M 45 25 V ${12.5 + activeDepth * 30}`} />}
      {depth !== 0 && (
        <Fragment>
          <ThroughPath d="M 45 12.5 H 25" />
        </Fragment>)
      }
    </FamilyLines>
    <ItemContainer depth={depth} childs={!!childs}>
      {!!childs && node.node_type === 'machine' && (
        <SvgBtn>
          <MachineInactiveSvg fill={ColorAccToCondition(node.node_details.condition)} />
        </SvgBtn>
      )}
      {!!childs && node.node_type === 'machine' && node.node_details.has_unreachable_sensors && (
        <SvgBtn>
          <UnreachableMark />
        </SvgBtn>
      )}
      {!!childs && node.node_type === 'machine' && node.node_details.has_instrumentation_task && (
        <SvgBtn>
          <InstrumentationTaskIcon />
        </SvgBtn>
      )}
      {node.node_type === 'machine_charts' && (
        <SvgBtn>
          <AllChartsIcon />
        </SvgBtn>
      )}
      {node.node_type === 'data_overview' && (
        <SvgBtn>
          <ListAlt color={colors.greyXD} />
        </SvgBtn>
      )}
      {node.node_type === 'component' && (
        <SvgBtn>
          {assetHierarchyIcons && assetHierarchyIcons[node.node_details && node.node_details.component_type]}
        </SvgBtn>
      )}
      {node.node_type === 'config' && (
        <SvgBtn>
          {assetConstants.getConfigIcon(node)}
        </SvgBtn>
      )}
      {!!childs && node.node_type === 'site' && (
        <SvgBtn>
          <SiteActiveSvg fill={primaryColor} />
        </SvgBtn>
      )}
      {!!childs && node.node_type !== 'site' && node.node_type !== 'machine' && node.node_type !== 'component' && (
        <SvgBtn
          opaque
          greyBorder
          height="16px"
          width="16px"
          onClick={(e) => {
            e.stopPropagation();
            switchNode(true);
          }}
        >
          {expanded ? <MinusSvg height={8} width={8} /> : <PlusSvg height={8} width={8} />}
        </SvgBtn>
      )}
      {newlyAddedMachineIds.indexOf(node.id) !== -1 && node.node_type === 'machine' && (
        <BulletImg src={`data:image/png;base64,${encodeURIComponent(docxImages.redBullet)}`} />
      )}
      <H3
        className={`hierarchy-node hierarchy-item-${node.node_type} ${selected ? 'active-node' : ''}`}
        active={node.active}
        selected={selected}
        childs={!!childs}
        name={`${node.node_type}_${node.id}`}
        title={name}
        depth={depth}
        width={currentWidth}
      >
        { node.node_type === 'config' ? oneToOneMapping(assetConstants.SHORTER_CHART_CONFIG_NAME_MAPPINGS, name) : name}
      </H3>
      {!!childs && node.node_type === 'component' && (
        <SvgBtn>
          {expanded ? <MinusSvg height={6} width={6} /> : <PlusSvg height={6} width={6} />}
        </SvgBtn>
      )}
      {
        node.id > 0 && (
          <ExternalIconContainer>
            {
              editMode && (
                node.node_type === 'area' ||
                node.node_type === 'machine'
              ) &&
              (
              <DragHandle />
              )
            }
            {
              (node.node_type === 'site' ||
              node.node_type === 'area' ||
              node.node_type === 'machine') &&
              !editMode && (
                <MoreOptions
                  iconWidth={12}
                  minWidthInline="120px"
                  inlineWidth="max-content"
                  position="relative"
                  top="0"
                  right="0"
                  marginLeft="5px"
                >
                  <MenuOptions items={getNodeMenuOptions(node)} />
                </MoreOptions>
              )
            }
          </ExternalIconContainer>
        )
      }
    </ItemContainer>
  </NodeItem>
);


HierarchyNodeItem.propTypes = {
  node: PropTypes.object.isRequired,
  name: PropTypes.string,
  expanded: PropTypes.bool,
  depth: PropTypes.number,
  switchNode: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
};

HierarchyNodeItem.defaultProps = {
  name: undefined,
  depth: 0,
  expanded: true,
  toggleExpand: undefined,
  editMode: false
};

const mapStateToProps = state => ({
  primaryColor: state.companyReducer.partner.theme.primaryColor,
  breadcrumb: state.breadcrumb,
  currentWidth: state.hierarchyViewPane.currentWidth,
  newlyAddedMachineIds: state.machines.machineAdd.machineAddStage.newlyAddedMachineIds,
});

export default connect(mapStateToProps, {})(HierarchyNodeItem);
