import styled from 'styled-components';
import Button from './Button';

const TextButton = styled(Button).attrs({
  text: true,
  fontSize: '12px',
  color: props => props.red ? '#FF5C5C' : '91B846',
  right: props => props.right,
  left: props => props.left
})``;

export default styled(TextButton)``;
