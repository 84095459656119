import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import RadiobuttonSvg from '../../images/RadiobuttonSvg';
import RadiobuttonSelectedSvg from '../../images/RadiobuttonSelectedSvg';
import Label_T from '../../typography/Label/Label';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: block !important;
  visibility: visible;
  opacity: 0;
`;

const Label = styled(Label_T)`
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  margin-top: -3px;
`;

const Container = styled.div`
  position: relative;
  margin: ${props => props.margin || 0};
  height: 1em;
  width: 1em;
  z-index: 2;
  svg:hover {
    .radiobuttonsvg {
      stroke: ${props => props.theme.primaryColor};
    }
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  }
  ${props => props.inputField && `
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  `}
`;

const Radiobutton = ({
  disabled,
  value,
  onChange,
  error,
  theme,
  title,
  inputField,
  ...rest
}) => {
  let checkbox;
  return (
    <>
      <Container
        checked={value}
        error={error}
        inputField={inputField}
        disabled={disabled}
        onClick={() => {
          if (!disabled) onChange(!checkbox.checked);
        }}
        {...rest}
      >
        {value ? (
          <RadiobuttonSelectedSvg fill={theme.primaryColor} />
        ) : (
          <RadiobuttonSvg fill={theme.colors.grey} />
        )}
        <HiddenCheckbox disabled={disabled} checked={value} innerRef={(ref) => { checkbox = ref; }} readOnly />
      </Container>
      {title && (
        <Label>{title}</Label>
      )}
    </>
  );
};

Radiobutton.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string
};

Radiobutton.defaultProps = {
  disabled: false,
  value: false,
  onChange: () => {},
  error: undefined
};

Radiobutton.displayName = 'Radiobutton';

export default withTheme(Radiobutton);
