import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { mapNames } from 'common/charts/constants';
import Filter_T from 'common/components/molecules/Filter';
import FilterItem from 'common/components/atoms/FilterItem';
import { Checkbox, FlexContainer } from 'common/components/atoms';

const OptionsContainer = styled(FlexContainer)`
  position: absolute;
  top: 0;
`;

const OptionItem = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  border-right: 1px solid grey;
  padding: 0em 1em;
  z-index: 3;
  width: ${props => props.setWidth};
`;

const Filter = styled(Filter_T)`
  height: 20px;
  border-right: 1px solid grey;
  padding-right: 1em;
  z-index: 3;
  width: ${props => props.setWidth};
`;

class CompareTagsConfigOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: {}
    };
  }

  toggleOptionsExpand = (cfgOptionName) => {
    this.setState(prevState => ({
      isExpanded: {
        ...prevState.isExpanded,
        [cfgOptionName]: prevState.isExpanded[cfgOptionName] ?
          !prevState.isExpanded[cfgOptionName] : true
      }
    }));
  };

  render() {
    const {
      configMetadata,
      config,
      onConfigOptionClick,
      selectAll,
      toggleSelectAll
    } = this.props;

    const {
      isExpanded
    } = this.state;

    return (
      <OptionsContainer
        backgroundColor="white"
        height="2.5rem"
        alignItems="center"
        width="100%"
      >
        {configMetadata[config.tag_type] && configMetadata[config.tag_type].chart_config.map(((cfgOption, idx) => (
          <Filter
            key={idx}
            value={mapNames[config[cfgOption.name]]}
            open={isExpanded[cfgOption.name] || false}
            setWidth="max-content"
            toggleOpen={() => this.toggleOptionsExpand(cfgOption.name)}
          >
            {_.map(cfgOption.values, value => ({
              text: mapNames[value],
              value
            })).map((option, index) => {
              const selected = option.value === config[cfgOption.name];
              return (
                <FilterItem
                  key={index.toString()}
                  selected={selected}
                  onClick={() => {
                    onConfigOptionClick(cfgOption.name, option.value);
                    this.toggleOptionsExpand(cfgOption.name);
                  }}
                >
                  <span>{option.text}</span>
                </FilterItem>
              );
            })}
          </Filter>
        )))}
        <OptionItem>
          <Checkbox
            title="Select All"
            value={selectAll}
            onChange={toggleSelectAll}
          />
        </OptionItem>
      </OptionsContainer>
    );
  }
}

CompareTagsConfigOptions.propTypes = {
  config: PropTypes.object.isRequired,
  onConfigOptionClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  configMetadata: state.machineDetails.charts.config ?
    state.machineDetails.charts.config.metadata :
    null
});

export default connect(mapStateToProps)(CompareTagsConfigOptions);
