import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Span = styled.span`
  &:hover {
    cursor: pointer;
  }
  display: inline-block;
  padding: ${props => props.padding};
  height: ${props => props.height}px;
  width: ${props => props.width}px;
`;


const PlusSvg = ({ width, height, fill, transform, onClick, padding }) => (
  <Span onClick={onClick} padding={padding} height={height} width={width}>
    <svg width={width} height={height} viewBox="0 0 12 12">
      <g id="P_W-v.2.0-Machines-v.1.0.0" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform={`translate(-1152.000000, -84.000000) ${transform}`}>
        <g id="plus-icon" transform={`translate(26.000000, 78.000000) ${transform}`} fill={fill} fillRule="nonzero">
          <g id="add" transform={`translate(1116.000000, 0.000000) ${transform}`}>
            <g id="add-icon" transform={`translate(10.000000, 6.000000) ${transform}`}>
              <path d="M11.9822348,5.99133913 C11.9822348,6.57049565 11.5128783,7.03985217 10.9334087,7.03985217 L7.03956522,7.03985217 L7.03956522,10.9338783 C7.03956522,11.5131913 6.57005217,11.9825478 5.99097391,11.9823913 C5.70138261,11.9823913 5.43926087,11.8652087 5.24968696,11.6755304 C5.05987826,11.4855913 4.94253913,11.2236 4.94266957,10.9341652 L4.94251304,7.03969565 L1.0482,7.03969565 C0.758791304,7.03969565 0.496878261,6.92251304 0.306913043,6.73254783 C0.117417391,6.54305217 0.000156521739,6.28093043 0.000156521739,5.99133913 C-3.20679898e-16,5.41228696 0.469356522,4.94282609 1.04874783,4.94282609 L4.94266957,4.94282609 L4.94266957,1.04874783 C4.94266957,0.469513043 5.41228696,0 5.99141739,0 C6.57033913,0.000156521739 7.03953913,0.4692 7.03985217,1.04835652 L7.03985217,4.94282609 L10.9341652,4.94282609 C11.5131913,4.94313913 11.9819478,5.41228696 11.9822348,5.99133913 Z" id="Shape" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </Span>
);

PlusSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  transform: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.number
};

PlusSvg.defaultProps = {
  width: 12,
  height: 12,
  fill: '#999B95',
  transform: '',
  onClick: () => {},
  padding: 0
};


export default styled(PlusSvg)``;
