import styled from 'styled-components';
import Container from 'common/components/atoms/Container';

const WidgetChartContainer = styled(Container)`
  border-radius: 8px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  flex: 1;
  margin: 0 10px;
  ${props => props.first && 'margin-left: 30px'};
  ${props => props.paddingBottom && `padding-bottom: ${props.paddingBottom}`};
  ${props => props.paddingRight && `padding-right: ${props.paddingRight}`};
  ${props => props.alignItems && `align-items: ${props.alignItems}`};
  min-height: 220px;
  min-width: 280px;
  &:hover {
    cursor: ${props => props.hoverPointer ? 'pointer' : 'default'};
  }
`;

export default WidgetChartContainer;
