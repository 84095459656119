import { toastr } from 'react-redux-toastr';
import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse, humanize } from 'common/helpers';
import _ from 'lodash';

import { eventAnalyticsConstants } from './constants/eventAnalytics.constants';


export const getEventAnalytics = (filters = null, dates = null) => (dispatch) => {
  dispatch(request());
  const params = {
    filters,
    start_date: dates ? dates.start_date : null,
    end_date: dates ? dates.end_date : null
  };
  handleResponse(axiosInstance.get(ENDPOINT.EVENT_ANALYTICS, { params }).then(
    (res) => {
      const breakdown_by_state_data = [
        { value: res.data.open, label: 'Open' },
        { value: res.data.total - res.data.open, label: 'Closed' },
      ];
      let avg_duration_data = [];
      if (!_.isEmpty(res.data.avg_event_type_duration)) {
        avg_duration_data = _.entries(res.data.avg_event_type_duration).map(([key, duration]) => {
          const event_type = humanize(key.split('_').slice(1, -1).join('_'));
          return [event_type, duration];
        });

        avg_duration_data = [['Event Type', 'Duration'], ...avg_duration_data];
      }
      let num_of_events_data = [];
      if (!_.isEmpty(res.data.event_type_count)) {
        num_of_events_data = _.entries(res.data.event_type_count).map(([event_type, count]) => ([humanize(event_type), count]));
        num_of_events_data = [['Event Type', 'Count'], ...num_of_events_data];
      }
      const duration_of_events_data = [['Events', 'Duration'], ...res.data.event_duration];
      dispatch(success({
        breakdown_by_state: breakdown_by_state_data,
        avg_duration: avg_duration_data,
        num_of_events: num_of_events_data,
        duration_of_events: duration_of_events_data,
        total: res.data.total,
        avg_event_duration: res.data.avg_event_duration,
        comment_count: res.data.comment_count
      }));
    },
    (error) => {
      dispatch(failure(error));
    }
  ));
  function request() {
    return { type: eventAnalyticsConstants.GET_EVENT_ANALYTICS_REQUEST };
  }

  function success(data) {
    return { type: eventAnalyticsConstants.GET_EVENT_ANALYTICS_SUCCESS, data };
  }

  function failure(error) {
    return { type: eventAnalyticsConstants.GET_EVENT_ANALYTICS_FAILURE, error };
  }
};

export const getMachineResources = account_id => () => {
  const params = {
    account_id
  };
  return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_RESOURCES, { params })).then(
    res => res,
    (error) => {
      toastr.error(error.message);
    }
  );
};

export const getPastWeekAnalytics = (filters = null) => () => {
  const params = {
    filters
  };
  return handleResponse(axiosInstance.get(ENDPOINT.GET_PAST_WEEK_ANALYTICS, { params })).then(
    res => res,
    (error) => {
      toastr.error(error.message);
    }
  );
};
