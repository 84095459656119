import _ from 'lodash';
import { getNoteDetails } from 'home/Activity/actions/activity.actions';
import { noteConstants } from '../constants/constants';

export const openNoteModal = (noteId = null, taskId = null) => {
  const setModalOpen = () => ({ type: noteConstants.SET_NOTE_MODAL_OPEN, noteId });
  const setOpenNoteDetails = noteDetails => ({ type: noteConstants.SET_NOTE_DETAILS, noteDetails });

  return (dispatch, getState) => {
    if (noteId) {
      const taskModal = getState().taskModal;
      const events = getState().events.list;

      // find existing note details and update
      let note = (
        taskModal.openTaskDetails && taskModal.openTaskDetails.notes &&
        taskModal.openTaskDetails.notes.find(note => note.id === noteId)
      );
      note = note || (
        !_.isEmpty(events) && events.find(activity => (activity.activity_id === noteId && activity.type === 'note'))
      );
      dispatch(setOpenNoteDetails(note));

      // make a request to get new details if available
      dispatch(getNoteDetails(noteId, 'note', taskId !== null));
    }
    return dispatch(setModalOpen());
  };
};

export const closeNoteModal = () => {
  const setModalClose = () => ({ type: noteConstants.SET_NOTE_MODAL_CLOSE });
  return dispatch => dispatch(setModalClose());
};
