import React from 'react';
import ReactCountdownClock from 'react-countdown-clock';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withCookies } from 'react-cookie';
import styled, { withTheme } from 'styled-components';

import InputField from 'common/components/atoms/InputField';
import LoadingSvg from 'common/images/LoadingSvg';
import { history } from '../../common/helpers';
import { userActions } from '../user.actions';
import { OnboardingHeader } from '../../common/OnboardingComponents';

const CheckboxField = styled(InputField).attrs({ type: 'checkbox' })`
  margin-top: 5px
  label {
    color: ${props => props.theme.colors.black};
    font-weight: 300;
    font-size: small;
  }
`;

const ClockContainer = styled.div`
  padding-right: 55px;
  > .react-countdown-clock {
    margin: auto;
  }
`;

class TwoFactorAuthPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      otp: undefined,
      error: false,
      errMessage: '',
      user_locked: false,
      trustDevice: false
    };
  }

  componentDidMount = () => {
    const { cookies } = this.props;
    const tryCount = cookies.get('otp_try_count');
    if (!tryCount || tryCount === 'undefined') {
      this.setState({ error: true });
    }
  }

  resendOtp = () => {
    const { phone, flow_type, destinationPath, destinationSearchParams } = this.props.state;
    const { resendOtp, cookies } = this.props;
    resendOtp(cookies, this.props.state.flow_type)
      .then(() => {
        history.push('/two_factor_auth', { flow_type, phone, destinationSearchParams, destinationPath });
      }, (error) => {
        this.setState({
          error: true,
          errMessage: error.message
        });
      });
  }

  verifyOtp = () => {
    const { otp, trustDevice } = this.state;
    const { verifyOtp, cookies } = this.props;
    const { destinationPath, destinationSearchParams } = this.props.state;

    const tryCount = cookies.get('otp_try_count');
    if (tryCount === undefined) {
      this.setState({
        error: true
      });
    }

    if (otp) {
      verifyOtp(otp, trustDevice, this.props.state.flow_type, destinationPath, cookies, destinationSearchParams)
        .then(
          () => {},
          (error) => {
            if (error.err_code === 'user_locked') {
              this.setState({
                error: true,
                errMessage: error.message,
                user_locked: true
              });
            } else {
              this.setState({
                error: false,
                errMessage: error.message
              });
            }
          }
        );
    } else toastr.error('Code must be entered!');
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  redirectToLogin = () => {
    history.push('/login');
  };

  toggleTrustDevice = () => {
    this.setState(prevState => ({
      trustDevice: !prevState.trustDevice
    }));
  };

  render() {
    const lastThreeDigits = this.props.state.phone;
    const { error, errMessage, user_locked } = this.state;
    const {
      twoFactorAuthState,
      theme
    } = this.props;

    return (
      <div className="auth-page align-center">
        <OnboardingHeader />
        {!error && (
          <div>
            <div className="warning-msg black bold">
              Please enter the verification code sent
              {lastThreeDigits && <span> to your mobile number: ***-***-*{lastThreeDigits} and</span>}
              <span> to your registered email</span>
            </div>
            <form name="form">
              <div className={`form-groups${twoFactorAuthState && twoFactorAuthState.verify === false ? ' has-error' : ''}`}>
                <input type="text" className={`form-control${twoFactorAuthState && twoFactorAuthState.verify === false ? ' error' : ''}`} name="otp" placeholder="Enter verification code here" onChange={this.handleChange} />
              </div>
              <div id="error" className={`help-block bold large${twoFactorAuthState && twoFactorAuthState.verify === false ? ' pop' : ''}`}>
                {errMessage}
              </div>
              <CheckboxField
                value={this.state.trustDevice}
                onClick={this.toggleTrustDevice}
                labelSide="right"
                label="Trust this device"
              />
            </form>
          </div>
        )
        }
        {error && (
          <div className="warning-msg red large">
            {errMessage || 'Something went wrong, please login again.'}
          </div>
        )
        }
        <div className="form-group">
          {!error && (
            <button style={{ backgroundColor: theme.primaryColor }} className="btn btn-primary" onClick={() => this.verifyOtp()}>Verify</button>
          )
          }
          {/* eslint-disable */}
          {(!user_locked && !error) && (
            <button style={{ color: theme.primaryColor }} className="btn btn-link" onClick={() => this.resendOtp()}>Resend verification code</button>
          )}
          {(user_locked || error) && !errMessage && (
            <button style={{ color: theme.primaryColor }} className="btn btn-link" onClick={this.redirectToLogin}>Login</button>
          )}
          {!error && (
            <ClockContainer>
              <ReactCountdownClock seconds={300} color={theme.primaryColor} size={55} onComplete={() => this.setState({ error: true })} />
            </ClockContainer>
          )
          }
        </div>
        {twoFactorAuthState && twoFactorAuthState.loading === true && (
          <div id="loading" className="ps-align-center" style={{ marginTop: '65px' }}>
            <LoadingSvg/>
          </div>
        )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, twoFactorAuthState } = state;
  return {
    user,
    twoFactorAuthState
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    verifyOtp: userActions.verifyOtp,
    resendOtp: userActions.resendOtp,
    resetOtpState: userActions.resetOtpState
  }, dispatch);


export default withCookies(connect(mapStateToProps, mapDispatchToProps)(withTheme(TwoFactorAuthPage)));
