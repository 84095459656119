import styled from 'styled-components';

import FlexContainer from './FlexContainer';

const FilterCountContainer = styled(FlexContainer)`
  align-items: center;
  border-right: 1px solid ${props => props.theme.colors.grey};
  margin-right: 5px;
  padding-right: 15px;
  font-weight: 600;
  min-width: auto;
  min-height: ${props => props.minHeight ? props.minHeight : 'auto'};
`;

export default styled(FilterCountContainer)``;
