import { combineReducers } from 'redux';

import { machineAddReducer } from 'home/Machines/MachineAdd/reducers/machineAdd.reducers';
import { machinesConstants } from './machines.constants';
import machineBuilderReducer from './MachineBuilder/reducers/machineBuilder.reducer';

export const machinesReducer = combineReducers({
  query: machineQuery,
  sorter: machineSorter,
  paginater: machinePaginater,
  items: machineItems,
  searchParams: machineSearchParams,
  machineBuilder: machineBuilderReducer,
  machineAdd: machineAddReducer,
  machinesRequiringAttention
});

function machineItems(state = {}, action) {
  switch (action.type) {
    case machinesConstants.GET_MACHINES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case machinesConstants.GET_MACHINES_SUCCESS: {
      const machinesArray = action.machines;

      let newMachineArray = null;
      if (action.page === 1) {
        // reset machine arrays if returned result is page 1
        newMachineArray = action.machines;
      } else {
        newMachineArray = state.object ? [...state.object, ...action.machines] : action.machines;
      }

      return {
        hasMore: machinesArray.length === machinesConstants.NUMBER_OF_MACHINES_PER_PAGE,
        object: newMachineArray,
        laoding: false,
        total_count: action.total_count
      };
    }
    case machinesConstants.GET_MACHINES_FAILURE:
      return {
        ...state,
        loading: false,
        hasMore: false,
        error: action.error.message
      };
    case machinesConstants.CLEAR_MACHINES:
      return {};
    default:
      return state;
  }
}

function machineSorter(state = {}, action) {
  switch (action.type) {
    case machinesConstants.SET_MACHINE_SORTER:
      return {
        sorter: action.sorter
      };
    default:
      return state;
  }
}

function machinesRequiringAttention(state = {}, action) {
  switch (action.type) {
    case machinesConstants.SET_MACHINES_REQUIRING_ATTENTION_VIEW:
      return {
        ...state,
        show: !state.show
      };
    default:
      return state;
  }
}

function machineQuery(state = '', action) {
  switch (action.type) {
    case machinesConstants.SET_MACHINE_QUERY:
      return action.query;
    default:
      return state;
  }
}

function machineSearchParams(state = {}, action) {
  switch (action.type) {
    case machinesConstants.SET_MACHINE_SEARCH_PARAMS:
      return action.searchParams;
    case machinesConstants.CLEAR_MACHINE_SEARCH_PARAMS:
      return {};
    default:
      return state;
  }
}

function machinePaginater(state = {}, action) {
  switch (action.type) {
    case machinesConstants.SET_MACHINE_PAGINATER:
      return {
        page: action.page
      };
    default:
      return state;
  }
}
