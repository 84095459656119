import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse } from 'common/helpers';
import { toastr } from 'react-redux-toastr';

import { getTags } from 'home/AssetHierarchy/actions/assetDetails.actions';
import actionTypes from './rul.types';


export const getRULTrend = (componentId, machineId) => {
  const request = () => ({ type: actionTypes.GET_RUL_TREND_REQUEST, machineId });
  const success = info => ({ type: actionTypes.GET_RUL_TREND_SUCCESS, componentId, machineId, info });
  const failure = error => ({ type: actionTypes.GET_RUL_TREND_FAILURE, componentId, machineId, error });
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_RUL_TREND(componentId)))
      .then(
        (res) => {
          dispatch(success(res));
        },
        (err) => {
          dispatch(failure(err.message || err));
        }
      );
  };
};

export const getRULSettings = (componentId, machineId) => {
  const request = () => ({ type: actionTypes.GET_RUL_SETTINGS_REQUEST, machineId });
  const success = settings => ({ type: actionTypes.GET_RUL_SETTINGS_SUCCESS, componentId, machineId, settings });
  const failure = error => ({ type: actionTypes.GET_RUL_SETTINGS_FAILURE, componentId, machineId, error });
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_RUL_SETTINGS(componentId)))
      .then(
        (res) => {
          dispatch(success(res));
        },
        (err) => {
          dispatch(failure(err.message || (err.data && err.data.message) || err));
        }
      );
  };
};

export const updateRULSettings = (componentId, args, machineId = null, siteId = null, showSuccessToastrMessage = true) => {
  const request = () => ({ type: actionTypes.UPDATE_RUL_SETTINGS_REQUEST, componentId });
  const success = res => ({ type: actionTypes.UPDATE_RUL_SETTINGS_SUCCESS, componentId, res });
  const failure = error => ({ type: actionTypes.GET_RUL_TREND_FAILURE, componentId, error });
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.put(ENDPOINT.UPDATE_RUL_SETTINGS(componentId), args))
      .then(
        (res) => {
          dispatch(success(res));
          dispatch(getRULSettings(componentId, machineId));
          if (showSuccessToastrMessage) toastr.success(res.message);
          if (machineId && siteId) dispatch(getTags(machineId, siteId));
        },
        (err) => {
          dispatch(failure(err.message));
          toastr.error(`RUL settings update failed for component ID ${componentId}: ${err.message}`);
          throw err;
        }
      );
  };
};
