export const machineAddActionConstants = {
  CHOOSE_ADD_METHOD: 'CHOOSE_ADD_METHOD',
  CHOOSE_MC_TYPE: 'CHOOSE_MC_TYPE',
  CHOOSE_NON_ROTATING_SUBTYPE: 'CHOOSE_NON_ROTATING_SUBTYPE',
  SET_STAGE: 'SET_STAGE',
  RESET: 'RESET',
  BULK_CREATE_ASSETS: 'BULK_CREATE_ASSETS',
};

export const MACHINE_ADD_METHODS = {
  TEMPLATE: 'TEMPLATE',
  SCRATCH: 'SCRATCH',
};

export const MACHINE_STAGES = {
  CHOOSE_ADD_METHOD: 'CHOOSE_ADD_METHOD',
  CHOOSE_MC_TYPE: 'CHOOSE_MC_TYPE',
  CHOOSE_NON_ROTATING_SUBTYPE: 'CHOOSE_NON_ROTATING_SUBTYPE',
  SELECT_TEMPLATES: 'SELECT_TEMPLATES',
  REVIEW_TEMPLATES: 'REVIEW_TEMPLATES',
  GROUPING_TEMPLATES: 'GROUPING_TEMPLATES'
};
