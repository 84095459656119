import styled from 'styled-components';
import PropTypes from 'prop-types';

const Label = styled.label`
  color: ${props => (props.color ? props.color : props.theme.colors.black)};
  font-weight: ${props => props.fontWeight || 600};
  margin-bottom: ${props => props.marginBottom || '12px'};
  margin-top: ${props => props.marginTop || '0'};
  ${(props) => {
    let res = '';
    if (props.labelSide) {
      res += `
        margin-bottom: 0px;
        font-size: inherit;
        `;
      if (props.labelSide === 'right') {
        res += 'margin-left: 0.5em; order: 1;';
      } else res += 'margin-right: 0.5em; order: 0;';
    }
    return res;
  }}
  font-size: ${props => props.fontSize || '12px'};
  ${props => props.hover && 'cursor: pointer;'}
  ${props => props.margin && `margin: ${props.margin};`}
`;

Label.propTypes = {
  labelSide: PropTypes.oneOf(['right', 'left', false]),
  color: PropTypes.string
};

Label.defaultProps = {
  labelSide: false,
  color: undefined
};

export default Label;
