import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ListItem from 'common/components/atoms/ListItem';
import { history } from 'common/helpers';

const FirmwareDeploymentListItem = styled(ListItem)`
  cursor: pointer;
  ${props => props.selected && 'background-color: rgba(145,184,70,0.08);'}
  td {
    height: 54px;
  }
`;

class FirmwareDeploymentItem extends React.Component {
  render() {
    const {
      item,
      columnSizes,
      onClick,
      selected
    } = this.props;
    return (
      <>
      <FirmwareDeploymentListItem selected={selected} onClick={() => onClick(item.id)}>
          <td width={`${columnSizes[0]}%`}>
            {item.id || '--'}
          </td>
          <td width={`${columnSizes[1]}%`}>
            {item.name || '--'}
          </td>
          <td width={`${columnSizes[2]}%`}>
            {item.firmware_type || '--'}
          </td>
          <td width={`${columnSizes[3]}%`}>
            { item.association_type || '--'}
          </td>
          <td width={`${columnSizes[4]}%`}>
            {item.target_version || '--'}
          </td>
          <td width={`${columnSizes[5]}%`}>
            {item.account_name || '--'}
          </td>
      </FirmwareDeploymentListItem>
      </>
    );
  }
}

FirmwareDeploymentItem.propTypes = {
  item: PropTypes.object.isRequired,
  columnSizes: PropTypes.array.isRequired
};

export default FirmwareDeploymentItem;
