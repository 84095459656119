import styled from 'styled-components';

const InfoTitle = styled.span`
    font-size: 14px;
    font-weight: 600;
    ${props => props.display && `display: ${props.display}`};
    color: ${props => props.theme.colors.greyXXD}
    z-index: 1;
`;

export default styled(InfoTitle)``;
