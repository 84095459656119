import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';
import moment from 'moment';

import Modal from 'common/components/organisms/Modal';
import MoreOptions from 'common/components/organisms/MoreOptions';
import AccountMenuItems from 'common/components/organisms/AccountMenuItems';
import OutsideAlerter from 'common/OutsideAlerter';
import SearchFilters from 'common/components/atoms/SearchFilters';
import Filter from 'common/components/molecules/Filter';
import InputField from 'common/components/Chart/atoms/InputField';
import FilterCountContainer from 'common/components/atoms/FilterCountContainer';
import FlexContainer from 'common/components/atoms/FlexContainer';
import List from 'common/components/molecules/List';
import MsgContainer from 'common/components/atoms/MsgContainer';
import Radiobutton from 'common/components/molecules/Radiobutton';
import Checkbox from 'common/components/atoms/Checkbox';
import { calendarFormatDay, isFilterApplied } from 'common/utils';
import Button from 'common/components/atoms/Button';
import Calendar from 'common/components/molecules/ReactCalendar';
import H2 from 'common/typography/H2/H2';
import ListContainerT from 'home/HomePage/components/atoms/ListContainer';
import CrossSvg from 'common/images/CrossSvg';
import DateSelector from 'common/components/Chart/atoms/DateSelector';
import DevicesHealthWidget from 'home/DevicesHealth/devicesHealth.widget';
import { isPetasenseAdmin, isPetasenseViewer } from 'common/rbac/util';
import { MISSED_MEASUREMENTS } from '../../constants/adminDashboard.constants';
import MissedMeasurementsItem from './MissedMeasurementsItem';
import * as sensorActions from '../../../Sensors/actions/sensors.actions';
import * as devicesHealthActions from '../../../DevicesHealth/devicesHealth.actions';
import SensorMissedMeasurementListItem from '../../../DevicesHealth/Components/SensorMissedMeasurementListItem';

const SensorsContainer = styled(FlexContainer)`
  background-color: ${props => props.theme.colors.white};
  height: Calc(100vh - 368px);
  overflow: auto;
`;

const ListContainer = styled(ListContainerT)`
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const FilterItem = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  text-align: left;
  width: max-content;
  min-width: 150px;
  border: none;
  outline: none;
  background-color: white;
  font-family: 'Petasense Open Sans';
  font-weight: 600;
  color: ${props => props.theme.colors.lightGray};
  ${props => props.selected && `color: ${props.theme.colors.black};`}
  &:hover {
    color: ${props => props.theme.colors.black};
    background-color: #f7f7f7;
  }
  &:first-of-type {
    padding-top: 1em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    padding-bottom: 1em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  span {
    margin-left: 0.5em;
  }
`;

const CalenderContainer = styled.div`
  position: absolute;
  z-index: 3;
  width: 220px;
  top: ${props => props.to ? '103px' : '33px'};
`;

const SvgContainer = styled(FlexContainer)`
  cursor: pointer;
  padding: 0.5em;
  padding-left: 2em;
`;

class MissedMeasurements extends Component {
  constructor() {
    super();
    this.state = {
      accounts: [],
      allAccounts: [],
      searchParams: {
        ...MISSED_MEASUREMENTS.initialSearchParams
      },
      dateExpanded: false,
      customDayFrom: false,
      customDayTo: false,
      filtersOpen: {
        type: false,
        accounts: false,
        date: false,
        measurement: false
      }
    };
  }

  componentDidMount() {
    const title = this.props.title;
    document.title = `${title} | Missed Measurements`;

    const { getResourcesBasedOnType } = this.props.sensorActions;

    getResourcesBasedOnType('account').then((res) => {
      let accountsList = [...res];
      if (isPetasenseAdmin(this.props.user) || isPetasenseViewer(this.props.user)) {
        accountsList = [{ text: 'Unassociated', value: null }, ...res];
      }
      this.setState({
        accounts: accountsList,
        allAccounts: accountsList,
      });
    });
    this.search();
  }

  componentDidUpdate(prevProps) {
    if (this.props.adminDashboardOpen && !prevProps.adminDashboardOpen) {
      this.search();
    }
  }

  renderFilterOptions = (options, name) => {
    if (_.isEmpty(options)) {
      return (
        <MsgContainer>
          <span>No {name} are available</span>
        </MsgContainer>
      );
    }

    return options.map((option, index) => {
      const selected = _.includes(this.state.searchParams[name], option.value);
      if (name !== 'accounts' && name !== 'measurement') {
        return (
          <FilterItem
            key={index.toString()}
            className="dropdown-item"
            selected={selected}
            onClick={() => {
              let types = [];
              if (!selected) {
                types = [option.value];
              }
              if (option.value === 'custom' || this.state.dateExpanded) {
                this.toggleDateExpanded('dateExpanded');
              }
              this.changeDateFilter(name, types, selected);
            }}
          >
            <Radiobutton value={selected} />
            <span>{option.text}</span>
          </FilterItem>
        );
      }
      return (
          <FilterItem
            key={index.toString()}
            className="dropdown-item"
            selected={selected}
            onClick={() => {
              let types = [];
              if (selected) {
                types = _.filter(
                  this.state.searchParams[name],
                  t => t !== option.value
                );
              } else {
                types = [...this.state.searchParams[name], option.value];
              }
              this.changeFilter(name, types, selected);
            }}
          >
            <Checkbox value={selected} />
            <span>{option.text}</span>
          </FilterItem>
      );
    });
  };

  returnToOverallModal = () => {
    const { deselectSensor } = this.props.devicesHealthActions;
    deselectSensor();
  };

  toggleFilterOpen = (filter) => {
    this.setState(prevState => ({
      filtersOpen: {
        ...prevState.filtersOpen,
        [filter]: !prevState.filtersOpen[filter]
      }
    }));
  };

  changeFilter = (filter, value) => {
    const searchParams = {
      ...this.state.searchParams,
      [filter]: value
    };
    this.setState({
      searchParams
    });
    return this.search(searchParams);
  };

  search = (params = null) => {
    const { searchParams: stateParams } = this.state;
    const searchParams = params || stateParams;
    const { setMissedMeasurementsFilters } = this.props.devicesHealthActions;
    const [start_date, end_date] = this.getStartandEndDates(searchParams);

    const filters = this.getFilters(searchParams);

    return setMissedMeasurementsFilters(start_date, end_date, '', filters);
  };

  getFilters = params => (
    [
      { name: 'account_id', op: 'in', value: params.accounts },
      { name: 'sensor_type', op: 'in', value: params.type[0] || null },
      { name: 'measurement', op: 'in', value: params.measurement || [] }
    ]
  );

  getStartandEndDates = (searchParams) => {
    let start_date;
    let end_date;

    if (searchParams.date.includes('custom')) {
      start_date = searchParams.date_from;
      end_date = searchParams.date_to;
    } else if (searchParams.date.includes('1')) {
      start_date = moment().startOf('day').toDate();
      end_date = moment().toDate();
    } else if (searchParams.date.includes('7')) {
      start_date = moment().subtract(7, 'days').startOf('day').toDate();
      end_date = moment().toDate();
    } else if (searchParams.date.includes('30')) {
      start_date = moment().subtract(30, 'days').startOf('day').toDate();
      end_date = moment().toDate();
    } else if (searchParams.date.includes('thisMonth')) {
      start_date = moment().startOf('month').toDate();
      end_date = moment().toDate();
    } else if (searchParams.date.includes('lastMonth')) {
      start_date = moment().subtract(1, 'months').startOf('month').toDate();
      end_date = moment().subtract(1, 'months').endOf('month').toDate();
    }

    return [start_date, end_date];
  };

  returnToOverallModal = () => {
    const { deselectSensor } = this.props.devicesHealthActions;
    deselectSensor();
  };

  closeFromDeviceLevelModal = () => {
    const { deselectSensor } = this.props.devicesHealthActions;
    deselectSensor();
  }

  clearAllFilters = () => {
    const searchParams = {
      ...MISSED_MEASUREMENTS.initialSearchParams
    };
    if (this.state.machineSensors) {
      searchParams.machines = this.state.searchParams.machines;
    }
    this.setState({ searchParams });
    if (this.state.dateExpanded) this.toggleDateExpanded();
    this.search(searchParams);
  }

  searchAccountFilter = (value) => {
    this.setState(prevState => ({
      accounts: prevState.allAccounts.filter(u =>
        u.text.toLowerCase().includes(value.toLowerCase())
      ),
      searchParams: {
        ...prevState.searchParams,
        account_search_key: value
      }
    }));
  };

  selectGivenItems = (selected, key) => {
    this.setState(
      prevState => ({
        searchParams: {
          ...prevState.searchParams,
          [key]: selected
        },
        filtersOpen: {
          ...prevState.filtersOpen,
          [key]: false
        }
      }),
      this.search
    );
  };

  toggleDateExpanded = () => {
    this.setState(prevState => ({
      dateExpanded: !prevState.dateExpanded
    }));
  };

  changeDateFilter = (filter, value) => {
    const searchParams = {
      ...this.state.searchParams,
      [filter]: value,
      date_from: new Date(new Date().setDate(new Date().getDate() - 7)),
      date_to: new Date()
    };
    this.setState({
      searchParams,
      customDayFrom: false,
      customDayTo: false
    });
    return this.search(searchParams);
  };

  openCustomCalender = (str) => {
    this.setState({
      calenderOpen: str
    });
  };

  closeCalender = () => {
    this.setState({ calenderOpen: null });
  };

  onClickDay = (value, label) => {
    let searchParams = {};
    if (label === 'from') {
      searchParams = {
        ...this.state.searchParams,
        date_from: value
      };
      this.setState({
        searchParams,
        customDayFrom: value,
        calenderOpen: null
      });
    } else if (label === 'to') {
      searchParams = {
        ...this.state.searchParams,
        date_to: value
      };
      this.setState({
        searchParams,
        customDayTo: value,
        calenderOpen: null
      });
    }
    this.search(searchParams);
  };

  onSensorSelect = (serial_number) => {
    const { selectSensor } = this.props.devicesHealthActions;
    selectSensor(serial_number);
  };


  clearDate = () => {
    const searchParams = {
      ...this.state.searchParams,
      date_from: new Date(new Date().setDate(new Date().getDate() - 7)),
      date_to: new Date()
    };
    this.setState({
      searchParams,
      customDayFrom: false,
      customDayTo: false
    });
    this.search(searchParams);
  };

  render() {
    const sensorListProps = {
      columnSizes: [10],
      headers: [
        {
          name: 'missed_timestamps',
          label: 'Missed Timestamps',
          alignCenter: true
        }
      ],
      maxHeightAfterScroll: 'calc(100vh - 100px)',
      triggerLoadMore: 'calc(100vh - 100px)',
      sorterState: this.props.sensorMissedTimestampsSorter,
      setSorter: this.props.devicesHealthActions.setSensorMissedTimestampsSorter,
      hasMore: this.props.sensorMissedTimestampsList.hasMore,
      loadMoreItems: this.props.devicesHealthActions.sensorLoadMore,
      loading: this.props.sensorMissedTimestampsList.loading,
      items: this.props.sensorMissedTimestampsList,
      ItemComponent: SensorMissedMeasurementListItem,
      emptyMsg: 'No Results Found'
    };

    const props = {
      columnSizes: [30, 30, 40],
      headers: [
        {
          name: 'serial_number',
          label: 'Serial Number'
        },
        {
          name: 'count',
          label: 'Count',
          alignCenter: true
        },
        {
          name: 'account_name',
          label: 'Account',
          alignCenter: true
        }
      ],
      maxHeightAfterScroll: 'calc(100vh - 200px)',
      triggerLoadMore: 'calc(100vh - 195px)',
      sorterState: this.props.missedMeasurementsSorter,
      setSorter: this.props.devicesHealthActions.setMissedMeasurementsSorter,
      hasMore: this.props.missedMeasurementsList.hasMore,
      loadMoreItems: this.props.devicesHealthActions.loadMore,
      loading: this.props.missedMeasurementsList.loading,
      items: this.props.missedMeasurementsList,
      onSensorSelect: this.onSensorSelect,
      emptyMsg: 'No results found',
      ItemComponent: MissedMeasurementsItem,
    };
    let accountTextValue = '';
    accountTextValue = this.state.searchParams.accounts.reduce((acc, id, idx) => {
      const name = this.state.allAccounts.filter(accounts => accounts.value === id);
      if (idx === 0) return `${name[0].text}`;
      if (idx === 1) return `${acc}, ${name[0].text}...`;
      return acc;
    }, '');

    const typeTextValue = this.state.searchParams.type.reduce(
      (acc, id, idx) => {
        const name = MISSED_MEASUREMENTS.typeOptions.filter(machine => machine.value === id);
        if (idx === 0) return `${name[0].text}`;
        if (idx === 1) return `${acc}, ${name[0].text}...`;
        return acc;
      },
      ''
    );
    const stateBasedTextValue = this.state.searchParams.measurement.reduce(
      (acc, id, idx) => {
        const name = MISSED_MEASUREMENTS.stateOptions.filter(machine => machine.value === id);
        if (idx === 0) return `${name[0].text}`;
        if (idx === 1) return `${acc}, ${name[0].text}...`;
        return acc;
      },
      ''
    );

    const dateTextValue = this.state.searchParams.date.reduce(
      (acc, id, idx) => {
        const name = MISSED_MEASUREMENTS.dateOptions.filter(s => s.value === id);
        if (idx === 0) return `${name[0].name}`;
        if (idx === 1) return `${acc}, ${name[0].name}...`;
        return acc;
      },
      ''
    );

    const [start_date, end_date] = this.getStartandEndDates(this.state.searchParams);

    return (
      <div>
        <MoreOptions>
          <AccountMenuItems />
        </MoreOptions>
        <SearchFilters height="60px">
          {this.props.missedMeasurementsList && (
            <FilterCountContainer>{this.props.missedMeasurementsList.total_count}</FilterCountContainer>
          )}
          <Filter
            title="Type:"
            value={typeTextValue}
            open={this.state.filtersOpen.type}
            toggleOpen={() => this.toggleFilterOpen('type')}
          >
            {this.renderFilterOptions(MISSED_MEASUREMENTS.typeOptions, 'type')}
          </Filter>
          <Filter
            title="Measurement:"
            value={stateBasedTextValue}
            open={this.state.filtersOpen.measurement}
            toggleOpen={() => this.toggleFilterOpen('measurement')}
          >
            {this.renderFilterOptions(MISSED_MEASUREMENTS.stateOptions, 'measurement')}
          </Filter>
          <Filter
            title="Account:"
            value={accountTextValue}
            open={this.state.filtersOpen.accounts}
            toggleOpen={() => this.toggleFilterOpen('accounts')}
            onChangeSearch={this.searchAccountFilter}
            searchValue={this.state.searchParams.account_search_key}
            viewAllItems={this.state.allAccounts}
            selectedItems={this.state.searchParams.accounts}
            selectGivenItems={selected =>
              this.selectGivenItems(selected, 'accounts')
              }
            itemName="account"
          >
            {this.renderFilterOptions(this.state.accounts, 'accounts')}
          </Filter>
          <Filter
            title="Date Range:"
            value={dateTextValue}
            open={this.state.filtersOpen.date}
            noScroll
            toggleOpen={() => this.toggleFilterOpen('date')}
          >
            <FlexContainer
              direction="row"
            >
              <FlexContainer
                direction="column"
              >
                {this.renderFilterOptions(MISSED_MEASUREMENTS.dateOptions, 'date')}
              </FlexContainer>
              {this.state.dateExpanded && (
              <DateSelector>
                {this.state.calenderOpen === 'from' && (
                  <OutsideAlerter open={this.state.calenderOpen === 'from'} handleClick={this.closeCalender}>
                    <CalenderContainer>
                      <Calendar
                        formatShortWeekday={(locale, date) => calendarFormatDay(locale, date)}
                        maxDetail="month"
                        maxDate={this.state.customDayTo || new Date()}
                        showNeighboringMonth={false}
                        onClickDay={value => this.onClickDay(value, 'from')}
                        value={this.state.customDayFrom}
                      />
                    </CalenderContainer>
                  </OutsideAlerter>
                )}
                {this.state.calenderOpen === 'to' && (
                  <OutsideAlerter open={this.state.calenderOpen === 'to'} handleClick={this.closeCalender}>
                    <CalenderContainer to="true">
                      <Calendar
                        formatShortWeekday={(locale, date) => calendarFormatDay(locale, date)}
                        maxDetail="month"
                        maxDate={new Date()}
                        minDate={this.state.customDayFrom}
                        value={this.state.customDayTo}
                        showNeighboringMonth={false}
                        onClickDay={value => this.onClickDay(value, 'to')}
                      />
                    </CalenderContainer>
                  </OutsideAlerter>
                )}
                  <FlexContainer direction="row" justifyContent="space-between">
                    <H2>Custom</H2>
                    <SvgContainer
                      onClick={() => this.toggleFilterOpen('date')}
                    >
                      <CrossSvg width={30} height={30} fill="#999B95" />
                    </SvgContainer>
                  </FlexContainer>
                  <InputField label="From" placeholder="Select" readOnly value={this.state.customDayFrom ? this.state.customDayFrom.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalender('from')} />
                  <InputField label="To" placeholder="Select " readOnly value={this.state.customDayTo ? this.state.customDayTo.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalender('to')} />
                  <Button
                    text
                    onClick={this.clearDate}
                    disabled={!this.state.customDayFrom && !this.state.customDayTo}
                  >
                    CLEAR
                  </Button>
              </DateSelector>)
              }
            </FlexContainer>
          </Filter>
          {isFilterApplied(this.state.searchParams) && (
              <Button text onClick={this.clearAllFilters} fontSize="11px" margin="0 20px">
                Clear Filters
              </Button>
          )}
        </SearchFilters>
        <div style={{ height: '220px' }}>
          <DevicesHealthWidget
            analytics_type="missed_measurements"
            filters={this.getFilters(this.state.searchParams)}
            searchParams={this.state.searchParams}
            start_date={start_date}
            end_date={end_date}
          />
        </div>
        <SensorsContainer>
          <ListContainer
            width="100%"
            minWidth="45rem"
            padding="0"
          >
            <List {...props} />
          </ListContainer>
        </SensorsContainer>
        {this.props.selectedSensor.serial_number && (
          <Modal
            width="45%"
            padding="0em 1em"
            headerPadding="3em 1em"
            minWidth="700px"
            close={this.closeFromDeviceLevelModal}
            title={this.props.selectedSensor.serial_number}
            titleSize="22px"
            minHeight="300px"
          >
            <ListContainer height="400px" padding="0">
              <List {...sensorListProps} />
            </ListContainer>
          </Modal>
        )}
      </div>
    );
  }
}


const mapStateToProps = state => ({
  missedMeasurementsList: state.devicesHealth.missedMeasurements.missedMeasurementsList,
  missedMeasurementsFilters: state.devicesHealth.missedMeasurements.missedMeasurementsFilters,
  missedMeasurementsSorter: state.devicesHealth.missedMeasurements.missedMeasurementsSorter,
  sensorMissedTimestampsList: state.devicesHealth.missedMeasurements.sensorMissedTimestampsList,
  sensorMissedTimestampsSorter: state.devicesHealth.missedMeasurements.sensorMissedTimestampsSorter,
  selectedSensor: state.devicesHealth.missedMeasurements.selectedSensor,
  adminDashboardOpen: state.adminDashboard.adminOpen,
  title: state.companyReducer.partner.title
});

const mapDispatchToProps = dispatch => ({
  sensorActions: bindActionCreators(sensorActions, dispatch),
  devicesHealthActions: bindActionCreators(devicesHealthActions, dispatch)
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MissedMeasurements);
