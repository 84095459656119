import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { machinesActions } from 'home/Machines/machines.actions';
import NoProblemMachine from '_images/dashboard/ps-no_problem_machines.png';
import List from 'common/components/molecules/List';
import * as hierarchyActions from 'home/AssetHierarchy/actions/hierarchy.actions';
import ListContainer from 'home/HomePage/components/atoms/ListContainer';
import colors from 'common/styles/colors';
import { ThLarge } from 'common/images/FaIcons';
import AttentionMachinesItem from './AttentionMachinesItem';
import { ListFooter } from '../ListComponents';

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
`;

const TextContainer = styled.span`
  margin-top: 2em;
`;

const TITLE_HEIGHT = 40;
const Title = styled.div`
  color: ${colors.darkGray};
  font-weight: 600;
  width: 100%;
  padding: 1em 0 0 1em;
  height: ${TITLE_HEIGHT}px;
`;

class AttentionMachinesPage extends React.Component {
  selectMachine = (machine_id) => {
    this.props.hierarchyActions.selectNode(machine_id, 'machine');
  }

  setMachinesRequiringAttention = () => {
    if (!this.props.machinesRequiringAttention) {
      this.props.machinesActions.toggleMachinesRequiringAttention();
    }
  }

  render() {
    const data = this.props.data;
    return (
      <div style={{ backgroundColor: 'white', height: '100%', borderRadius: '4px', position: 'relative' }}>
        <Title>Assets Requiring Attention</Title>
        {data.length === 0 ? (
          <ImageContainer>
              <img height="198px" width="311px" alt="No Problem Machine" title="No Problem Machine" src={NoProblemMachine} />
              <TextContainer>All your machines seem to be humming a happy tune.</TextContainer>
          </ImageContainer>
        ) : (
          <ListContainer padding="0" height={`calc(100% - ${TITLE_HEIGHT}px)`}>
            <List
              columnSizes={[30, 70]}
              headers={[
                {
                  name: 'name',
                  label: 'Machine'
                },
                {
                  name: 'reason',
                  label: ''
                }
              ]}
              items={{ object: data }}
              ItemComponent={AttentionMachinesItem}
              onMachineSelect={this.selectMachine}
              emptyMsg="All machines operating in normal condition."
            />
          </ListContainer>
        )}
        <ListFooter>
          {data && (
            <Link title="View All Machines" className="ps-hover-pointer" to="/assets" onClick={this.setMachinesRequiringAttention}>
              <ThLarge />
            </Link>
          )}
        </ListFooter>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.user,
  machinesRequiringAttention: state.machines.machinesRequiringAttention.show
});

const mapDispatchToProps = dispatch => ({
  hierarchyActions: bindActionCreators(hierarchyActions, dispatch),
  machinesActions: bindActionCreators(machinesActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttentionMachinesPage);
