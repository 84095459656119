import MachineDiagnosticTestRunTypes from '../actions/DiagnosticTestRun.types';
import MachineDiagnosticsConstants from '../../../../MachineDiagnostics/constants/machineDiagnostics.constants';

const initialState = {
  test_run_status: [],
  test_run_time: {},
  test_rules_info: {
    items: [],
    tested_rules: [],
    loading: false,
    hasMore: false,
    error: ''
  },
  error: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MachineDiagnosticTestRunTypes.DIAGNOSTIC_RULES_TEST_RUN_SUCCESS:
      return {
        ...state,
        test_run_status: [{
          ...action.payload.result,
          progress: 1,
        }, ...state.test_run_status],
        test_run_time: {
          ...action.payload.params
        }
      };

    case MachineDiagnosticTestRunTypes.DIAGNOSTIC_RULES_TEST_RUN_FAILURE:
      return {
        ...state,
        test_run_status: [],
        test_run_time: {},
        error: action.error
      };

    case MachineDiagnosticTestRunTypes.UPDATE_RULES_TEST_RUN_TIME_SUCCESS:
      return {
        ...state,
        test_run_time: action.rule_test_info ? action.rule_test_info : {}
      };

    case MachineDiagnosticTestRunTypes.DELETE_DIAGNOSTIC_RULE_TEST_RUN_SUCCESS:
      return initialState;

    case MachineDiagnosticTestRunTypes.DELETE_DIAGNOSTIC_RULE_TEST_RUN_REQUEST:
      return {
        ...state,
        test_run_time: {}
      };

    case MachineDiagnosticTestRunTypes.DIAGNOSTIC_RULES_TEST_RUN_TASK_STATUS:
      return {
        ...state,
        test_run_status: state.test_run_status.map(r => r.task_id === action.taskId ? {
          ...r,
          status: action.status.status,
          progress: action.status.state === 'PENDING' ? 1 :
            (action.status.completed_measurements / (action.status.total_measurements + 1)) * 100
        } : r)
      };
    case MachineDiagnosticTestRunTypes.DIAGNOSTIC_RULES_TEST_RUN_STATUS_DONE:
      return {
        ...state,
        test_run_status: state.test_run_status.map(r => r.task_id === action.taskId ? {
          ...r,
          status: '',
          progress: 100
        } : r)
      };
    case MachineDiagnosticTestRunTypes.DIAGNOSTIC_RULES_TEST_RUN_RESULT_REQUEST:
      return {
        ...state,
        test_rules_info: {
          ...state.test_rules_info,
          loading: true
        }
      };

    case MachineDiagnosticTestRunTypes.DIAGNOSTIC_RULES_TEST_RUN_RESULT_FAILURE:
      return {
        ...state,
        test_rules_info: {
          items: [],
          tested_rules: [],
          loading: false,
          error: action.error,
          hasMore: false
        }
      };

    case MachineDiagnosticTestRunTypes.DIAGNOSTIC_RULES_TEST_RUN_RESULT_SUCCESS: {
      let newTestRunResults = null;
      if (action.data.page === 1) {
        newTestRunResults = action.data.items;
      } else {
        newTestRunResults = state.test_rules_info.items ?
          [...state.test_rules_info.items, ...action.data.items] : action.data.items;
      }
      return {
        ...state,
        test_rules_info: {
          items: action.data.items ? newTestRunResults : [],
          tested_rules: action.data.tested_rules,
          loading: false,
          hasMore: action.data.items.length === MachineDiagnosticsConstants.NUMBER_OF_RULES_PER_PAGE,
        }
      };
    }

    default:
      return state;
  }
};
