import styled, { css } from 'styled-components';
import MoreIconSvg from 'common/images/MoreIconSvg';
import Button from 'common/components/atoms/Button';
import InputField from 'common/components/atoms/InputField';
import FlexContainer from 'common/components/atoms/FlexContainer';
import DropdownSelect from 'common/components/molecules/DropdownSelect';

const RowContainer = styled(FlexContainer).attrs({
  direction: props => props.direction || 'row',
  justifyContent: 'flex-start',
  alignItems: props => props.alignItems || 'baseline',
  flexWrap: 'wrap',
})`
  ${InputField}, ${DropdownSelect} {
    ${({ removeFlexBasis }) =>
    !removeFlexBasis &&
      css`
        flex-basis: 50%;
      `}
  }
  ${Button}:nth-child(1) {
    margin-right: 16px;
  }
  ${MoreIconSvg} {
    transform: rotate(90deg);
  }
`;

export default styled(RowContainer)``;
