import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';

import MoreOptions from 'common/components/organisms/MoreOptions';
import AccountMenuItems from 'common/components/organisms/AccountMenuItems';
import OutsideAlerter from 'common/OutsideAlerter';
import SearchFilters from 'common/components/atoms/SearchFilters';
import Filter from 'common/components/molecules/Filter';
import InputField from 'common/components/Chart/atoms/InputField';
import FilterCountContainer from 'common/components/atoms/FilterCountContainer';
import FlexContainer from 'common/components/atoms/FlexContainer';
import List from 'common/components/molecules/List';
import MsgContainer from 'common/components/atoms/MsgContainer';
import Radiobutton from 'common/components/molecules/Radiobutton';
import Checkbox from 'common/components/atoms/Checkbox';
import { isFilterApplied } from 'common/utils';
import Button from 'common/components/atoms/Button';
import Calendar from 'common/components/molecules/ReactCalendar';
import { mapDayToString } from 'common/constants';
import H2 from 'common/typography/H2/H2';
import CrossSvg from 'common/images/CrossSvg';
import { isPetasenseAdmin, isPetasenseViewer } from 'common/rbac/util';

import ErroneousReadingsItem from './ErroneousReadingsItem';
import * as erroneousReadingsActions from '../erroneousReadings.actions';
import * as sensorActions from '../../../Sensors/actions/sensors.actions';

const ListContainer = styled(FlexContainer).attrs({ direction: 'column' })`
position: relative;
overflow: auto;
width: ${props => props.width};
height: 100%;
${props =>
    props.width === '30%' &&
  `border-right: 2px solid ${props.theme.colors.greyL};`}
${props =>
    props.minWidth && `min-width: ${props.minWidth};`}
`;

const SensorsContainer = styled(FlexContainer).attrs({ direction: 'row' })`
  background-color: ${props => props.theme.colors.white};
  overflow: auto;
`;

const FilterItem = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  text-align: left;
  width: max-content;
  min-width: 150px;
  border: none;
  outline: none;
  background-color: white;
  font-family: 'Petasense Open Sans';
  font-weight: 600;
  color: ${props => props.theme.colors.lightGray};
  ${props => props.selected && `color: ${props.theme.colors.black};`}
  &:hover {
    color: ${props => props.theme.colors.black};
    background-color: #f7f7f7;
  }
  &:first-of-type {
    padding-top: 1em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    padding-bottom: 1em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  span {
    margin-left: 0.5em;
  }
`;

const DateSelector = styled.div`
  height: 100%;
  display: inline-block;
  width: 250px;
  vertical-align: top;
  padding: 10px 15px;
  box-sizing: border-box;
  ${InputField} {
    margin-bottom: 13.5px;
  }
  input {
    font-size: 1em;
    padding: .57857143em 1em;
  }
  label {
    font-weight: 600;
    margin-bottom: 3px;
  }
`;


const CalenderContainer = styled.div`
  position: absolute;
  z-index: 3;
  width: 220px;
  top: ${props => props.to ? '103px' : '33px'};
`;

const formatDate = (locale, date) => {
  const day = date.getDay();
  return mapDayToString[day][0];
  // if (day !== 6 && day !== 0) return mapDayToString[day][0];
  // return mapDayToString[day].slice(0, 2);
};

const SvgContainer = styled(FlexContainer)`
  cursor: pointer;
  padding: 0.5em;
  padding-left: 2em;
`;

class ErroneousReadings extends Component {
  constructor() {
    super();
    this.initialSearchParams = {
      type: [],
      account_search_key: '',
      accounts: [],
      date: []
    };
    this.state = {
      accounts: [],
      allAccounts: [],
      searchParams: {
        ...this.initialSearchParams,
      },
      dateExpanded: false,
      customDayFrom: false,
      customDayTo: false,
      filtersOpen: {
        type: false,
        accounts: false,
        date: false
      },
      typeOptions: [
        { text: 'Mote', value: 'Mote' },
        { text: 'Other', value: 'Other' }
      ]
    };
    this.dateOptions = [
      {
        text: 'Last 7 Days',
        value: '7',
        name: 'Last 7 Days'
      },
      {
        text: 'Last 30 Days',
        value: '30',
        name: 'Last 30 Days'
      },
      {
        text: 'This Month',
        value: 'thisMonth',
        name: 'This Month'
      },
      {
        text: 'Last Month',
        value: 'lastMonth',
        name: 'Last Month'
      },
      {
        text: 'Custom',
        value: 'custom',
        name: 'Custom'
      }
    ];
  }

  componentDidMount() {
    const title = this.props.title;
    document.title = `${title} | Erroneous Readings`;

    const { getResourcesBasedOnType } = this.props.sensorActions;
    const { setSummarizedErroneousReadingsFilters } = this.props.erroneousReadingsActions;

    getResourcesBasedOnType('account').then((res) => {
      let accountsList = [...res];
      if (isPetasenseAdmin(this.props.user) || isPetasenseViewer(this.props.user)) {
        accountsList = [{ text: 'Unassociated', value: null }, ...res];
      }
      this.setState({
        accounts: accountsList,
        allAccounts: accountsList,
      });
    });
    const filters = [
      { name: 'account_id', op: 'in', value: [] },
      { name: 'sensor_type', op: 'in', value: [] }
    ];
    setSummarizedErroneousReadingsFilters(filters, null, null);
  }

  renderFilterOptions = (options, name) => {
    if (_.isEmpty(options)) {
      return (
        <MsgContainer>
          <span>No {name} are available</span>
        </MsgContainer>
      );
    }

    return options.map((option, index) => {
      const selected = _.includes(this.state.searchParams[name], option.value);
      if (name !== 'accounts') {
        return (
          <FilterItem
            key={index.toString()}
            className="dropdown-item"
            selected={selected}
            onClick={() => {
              let types = [];
              if (!selected) {
                types = [option.value];
              }
              if (option.value === 'custom' || this.state.dateExpanded) {
                this.toggleDateExpanded('dateExpanded');
              }
              this.changeDateFilter(name, types, selected);
            }}
          >
            <Radiobutton value={selected} />
            <span>{option.text}</span>
          </FilterItem>
        );
      }
      return (
          <FilterItem
            key={index.toString()}
            className="dropdown-item"
            selected={selected}
            onClick={() => {
              let types = [];
              if (selected) {
                types = _.filter(
                  this.state.searchParams[name],
                  t => t !== option.value
                );
              } else {
                types = [...this.state.searchParams[name], option.value];
              }
              this.changeFilter(name, types, selected);
            }}
          >
            <Checkbox value={selected} />
            <span>{option.text}</span>
          </FilterItem>
      );
    });
  };

  toggleFilterOpen = (filter) => {
    this.setState(prevState => ({
      filtersOpen: {
        ...prevState.filtersOpen,
        [filter]: !prevState.filtersOpen[filter]
      }
    }));
  };

  changeFilter = (filter, value) => {
    const searchParams = {
      ...this.state.searchParams,
      [filter]: value
    };
    this.setState({
      searchParams
    });
    return this.search(searchParams);
  };

  search = (params = null) => {
    const { searchParams: stateParams } = this.state;
    const searchParams = params || stateParams;
    const { setSummarizedErroneousReadingsFilters } = this.props.erroneousReadingsActions;
    let start_date;
    let end_date;

    if (searchParams.date.includes('custom')) {
      start_date = searchParams.date_from;
      end_date = searchParams.date_to;
    } else if (searchParams.date.includes('7')) {
      start_date = new Date(new Date().setDate(new Date().getDate() - 7));
      end_date = new Date();
    } else if (searchParams.date.includes('30')) {
      start_date = new Date(new Date().setDate(new Date().getDate() - 30));
      end_date = new Date();
    } else if (searchParams.date.includes('thisMonth')) {
      start_date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      end_date = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    } else if (searchParams.date.includes('lastMonth')) {
      start_date = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      end_date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    }

    const filters = [
      { name: 'account_id', op: 'in', value: searchParams.accounts },
      { name: 'sensor_type', op: 'in', value: searchParams.type[0] || [] }
    ];

    return setSummarizedErroneousReadingsFilters(filters, start_date, end_date);
  };

  clearAllFilters = () => {
    const searchParams = {
      ...this.initialSearchParams
    };
    if (this.state.machineSensors) {
      searchParams.machines = this.state.searchParams.machines;
    }
    this.setState({ searchParams });
    if (this.state.dateExpanded) this.toggleDateExpanded();
    this.search(searchParams);
  }

  searchAccountFilter = (value) => {
    this.setState(prevState => ({
      accounts: prevState.allAccounts.filter(u =>
        u.text.toLowerCase().includes(value.toLowerCase())
      ),
      searchParams: {
        ...prevState.searchParams,
        account_search_key: value
      }
    }));
  };

  selectGivenItems = (selected, key) => {
    this.setState(
      prevState => ({
        searchParams: {
          ...prevState.searchParams,
          [key]: selected
        },
        filtersOpen: {
          ...prevState.filtersOpen,
          [key]: false
        }
      }),
      this.search
    );
  };

  toggleDateExpanded = () => {
    this.setState(prevState => ({
      dateExpanded: !prevState.dateExpanded
    }));
  };

  changeDateFilter = (filter, value) => {
    const searchParams = {
      ...this.state.searchParams,
      [filter]: value,
      date_from: new Date(new Date().setDate(new Date().getDate() - 7)),
      date_to: new Date()
    };
    this.setState({
      searchParams,
      customDayFrom: false,
      customDayTo: false
    });
    return this.search(searchParams);
  };

  openCustomCalender = (str) => {
    this.setState({
      calenderOpen: str
    });
  };

  closeCalender = () => {
    this.setState({ calenderOpen: null });
  };

  onClickDay = (value, label) => {
    let searchParams = {};
    if (label === 'from') {
      searchParams = {
        ...this.state.searchParams,
        date_from: value
      };
      this.setState({
        searchParams,
        customDayFrom: value,
        calenderOpen: null
      });
    } else if (label === 'to') {
      searchParams = {
        ...this.state.searchParams,
        date_to: value
      };
      this.setState({
        searchParams,
        customDayTo: value,
        calenderOpen: null
      });
    }
    this.search(searchParams);
  };

  clearDate = () => {
    const searchParams = {
      ...this.state.searchParams,
      date_from: new Date(new Date().setDate(new Date().getDate() - 7)),
      date_to: new Date()
    };
    this.setState({
      searchParams,
      customDayFrom: false,
      customDayTo: false
    });
    this.search(searchParams);
  };

  render() {
    const {
      items
    } = this.props.summarizedErroneousReadings;

    const { loadMoreSummarizedErroneousReadings } = this.props.erroneousReadingsActions;
    const props = {
      columnSizes: [16, 24, 24, 10, 16, 10],
      headers: [
        {
          name: 'sensor',
          label: 'Sensor'
        },
        {
          name: 'machine',
          label: 'Machine'
        },
        {
          name: 'account',
          label: 'Account'
        },
        {
          name: 'component',
          label: 'Component'
        },
        {
          name: 'latest timestamp',
          label: 'Latest Timestamp'
        },
        {
          name: 'direction',
          label: 'Direction'
        }
      ],
      maxHeightAfterScroll: 'calc(100vh - 160px)',
      triggerLoadMore: 'calc(100vh - 195px)',
      hasMore: items.hasMore,
      loading: items.loading,
      emptyMsg: 'No results found',
      ItemComponent: ErroneousReadingsItem,
      items,
      loadMoreItems: loadMoreSummarizedErroneousReadings
    };
    let accountTextValue = '';
    accountTextValue = this.state.searchParams.accounts.reduce((acc, id, idx) => {
      const name = this.state.allAccounts.filter(accounts => accounts.value === id);
      if (idx === 0) return `${name[0].text}`;
      if (idx === 1) return `${acc}, ${name[0].text}...`;
      return acc;
    }, '');

    const typeTextValue = this.state.searchParams.type.reduce(
      (acc, id, idx) => {
        const name = this.state.typeOptions.filter(machine => machine.value === id);
        if (idx === 0) return `${name[0].text}`;
        if (idx === 1) return `${acc}, ${name[0].text}...`;
        return acc;
      },
      ''
    );

    const dateTextValue = this.state.searchParams.date.reduce(
      (acc, id, idx) => {
        const name = this.dateOptions.filter(s => s.value === id);
        if (idx === 0) return `${name[0].name}`;
        if (idx === 1) return `${acc}, ${name[0].name}...`;
        return acc;
      },
      ''
    );

    return (
      <div>
      <MoreOptions>
        <AccountMenuItems />
      </MoreOptions>
      <SearchFilters height="60px">
        {items && (
            <FilterCountContainer>{items.total_count}</FilterCountContainer>
        )}
        <Filter
          title="Type:"
          value={typeTextValue}
          open={this.state.filtersOpen.type}
          toggleOpen={() => this.toggleFilterOpen('type')}
        >
            {this.renderFilterOptions(this.state.typeOptions, 'type')}
        </Filter>
        <Filter
          title="Account:"
          value={accountTextValue}
          open={this.state.filtersOpen.accounts}
          toggleOpen={() => this.toggleFilterOpen('accounts')}
          onChangeSearch={this.searchAccountFilter}
          searchValue={this.state.searchParams.account_search_key}
          viewAllItems={this.state.allAccounts}
          selectedItems={this.state.searchParams.accounts}
          selectGivenItems={selected =>
            this.selectGivenItems(selected, 'accounts')
            }
          itemName="account"
        >
            {this.renderFilterOptions(this.state.accounts, 'accounts')}
        </Filter>
        <Filter
          title="Date Range:"
          value={dateTextValue}
          open={this.state.filtersOpen.date}
          noScroll
          toggleOpen={() => this.toggleFilterOpen('date')}
        >
            <FlexContainer
              direction="row"
            >
              <FlexContainer
                direction="column"
              >
                {this.renderFilterOptions(this.dateOptions, 'date')}
              </FlexContainer>
              {this.state.dateExpanded && (
              <DateSelector>
                {this.state.calenderOpen === 'from' && (
                  <OutsideAlerter open={this.state.calenderOpen === 'from'} handleClick={this.closeCalender}>
                    <CalenderContainer>
                      <Calendar
                        formatShortWeekday={(locale, date) => formatDate(locale, date)}
                        maxDetail="month"
                        maxDate={this.state.customDayTo || new Date()}
                        showNeighboringMonth={false}
                        onClickDay={value => this.onClickDay(value, 'from')}
                        value={this.state.customDayFrom}
                      />
                    </CalenderContainer>
                  </OutsideAlerter>
                )}
                {this.state.calenderOpen === 'to' && (
                  <OutsideAlerter open={this.state.calenderOpen === 'to'} handleClick={this.closeCalender}>
                    <CalenderContainer to="true">
                      <Calendar
                        formatShortWeekday={(locale, date) => formatDate(locale, date)}
                        maxDetail="month"
                        maxDate={new Date()}
                        minDate={this.state.customDayFrom}
                        value={this.state.customDayTo}
                        showNeighboringMonth={false}
                        onClickDay={value => this.onClickDay(value, 'to')}
                      />
                    </CalenderContainer>
                  </OutsideAlerter>
                )}
                  <FlexContainer direction="row" justifyContent="space-between">
                    <H2>Custom</H2>
                    <SvgContainer
                      onClick={() => this.toggleFilterOpen('date')}
                    >
                      <CrossSvg width={30} height={30} fill="#999B95" />
                    </SvgContainer>
                  </FlexContainer>
                  <InputField label="From" placeholder="Select" readOnly value={this.state.customDayFrom ? this.state.customDayFrom.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalender('from')} />
                  <InputField label="To" placeholder="Select " readOnly value={this.state.customDayTo ? this.state.customDayTo.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalender('to')} />
                  <Button
                    text
                    onClick={this.clearDate}
                    disabled={!this.state.customDayFrom && !this.state.customDayTo}
                  >
                    CLEAR
                  </Button>
              </DateSelector>)
              }
            </FlexContainer>
        </Filter>
        {isFilterApplied(this.state.searchParams) && (
            <Button text onClick={this.clearAllFilters} fontSize="11px" margin="0 20px">
              Clear Filters
            </Button>
        )}
      </SearchFilters>
      <SensorsContainer>
          <ListContainer
            width="100%"
            minWidth="45rem"
          >
            <List {...props} />
          </ListContainer>
      </SensorsContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  summarizedErroneousReadings: state.erroneousReadings.summarizedErroneousReadings,
  title: state.companyReducer.partner.title,
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  erroneousReadingsActions: bindActionCreators(erroneousReadingsActions, dispatch),
  sensorActions: bindActionCreators(sensorActions, dispatch)
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErroneousReadings);
