import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from 'common/styles/colors';

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  &:hover {
    opacity: ${props => props.disabled ? 0.5 : 0.8};
  }
`;

const SimultaneousMeasurementIcon = ({ width, height, fill, disabled, active, onClick }) => (
  <IconWrapper disabled={disabled} onClick={disabled ? undefined : onClick}>
    <svg width={width} height={height} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <g stroke={active ? colors.green : fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="16" cy="11" r="9" fill={active ? colors.greenA(0.2) : 'rgba(115, 116, 115, 0.05)'} />
        <line x1="16" y1="11" x2="16" y2="6" />
        <line x1="16" y1="11" x2="20" y2="13" />
        <circle cx="10" cy="18" r="1.5" fill={active ? colors.green : fill} />
        <circle cx="16" cy="18" r="1.5" fill={active ? colors.green : fill} />
        <circle cx="22" cy="18" r="1.5" fill={active ? colors.green : fill} />
        <line x1="10" y1="18" x2="10" y2="22" />
        <line x1="16" y1="18" x2="16" y2="22" />
        <line x1="22" y1="18" x2="22" y2="22" />
        <rect x="6" y="22" width="20" height="6" rx="1" ry="1" fill={active ? colors.green : fill} />
      </g>
    </svg>
  </IconWrapper>
);

SimultaneousMeasurementIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

SimultaneousMeasurementIcon.defaultProps = {
  width: 32,
  height: 32,
  fill: '#9EA09E',
  disabled: false,
  active: false,
  onClick: () => {}
};

export default SimultaneousMeasurementIcon;
