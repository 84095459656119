import * as _ from 'lodash';
import { repairConstants } from 'home/HomePage/constants/constants';
import ActivityTypes from '../actions/activity.types';

const intialState = {
  loading: true,
  loadingDetails: false,
  list: [],
  observation_list: [],
  nextPage: 1,
  error: null,
  hasMore: true,
  commentEditLoading: false,
  commentDeleteLoading: false
};
export default (state = intialState, action) => {
  switch (action.type) {
    case ActivityTypes.GET_EVENTS_REQUEST:
      return {
        ...state,
        list: action.newContext ? [] : [...state.list],
        observation_list: [],
        loading: true
      };
    case ActivityTypes.GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.events
      };
    case ActivityTypes.POOL_EVENTS_FOR_GROUPING:
      return {
        ...state,
        loading: true,
        ...action.events
      };
    case ActivityTypes.GET_EVENTS_FAILURE:
      return {
        ...state,
        list: [],
        observation_list: [],
        loading: false,
        error: action.error.message
      };
    case ActivityTypes.GET_ACTIVITY_COMMENTS_REQUEST:
      return {
        ...state,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.activity_id && activity.type === action.activity_type) {
            activity.loading = true;
          }
          return activity;
        })
      };
    case ActivityTypes.GET_ACTIVITY_COMMENTS_SUCCESS:
      return {
        ...state,
        loadingDetails: false,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.item.activity_id && activity.type === action.item.activity_type) {
            activity.comments = action.item.details.items;
            activity.comment_count = action.item.details.total_count;
            activity.loading = false;
          }
          return activity;
        }),
        observation_list: _.map(state.observation_list, (activity) => {
          if (activity.activity_id === action.item.activity_id && activity.type === action.item.activity_type) {
            activity.comments = action.item.details.items;
            activity.comment_count = action.item.details.total_count;
            activity.loading = false;
          }
          return activity;
        })
      };
    case ActivityTypes.GET_ACTIVITY_COMMENTS_FAILURE:
      return {
        ...state,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.error.activity_id && activity.type === action.error.activity_type) {
            activity.loading = false;
            activity.error = action.error.error.message;
          }
          return activity;
        })
      };

    case ActivityTypes.GET_NOTE_DETAILS_REQUEST:
      return {
        ...state,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.id && activity.type === action.activity_type) {
            activity.loading = true;
          }
          return activity;
        })
      };
    case ActivityTypes.GET_NOTE_DETAILS_SUCCESS:
      return {
        ...state,
        loadingDetails: false,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.note.id && activity.type === action.note.activity_type) {
            activity.details = action.note.details;
            activity.user_id = action.note.details && action.note.details.user_id;
            activity.loading = false;
          }
          return activity;
        })
      };
    case ActivityTypes.GET_NOTE_DETAILS_FAILURE:
      return {
        ...state,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.error.id && activity.type === action.error.activity_type) {
            activity.loading = false;
            activity.error = action.error.error.message;
          }
          return activity;
        })
      };

    case repairConstants.UPDATE_REPAIR_SUCCESS:
      return {
        ...state,
        loadingDetails: false,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.repair.id && activity.type === action.repair.activity_type) {
            activity = { ...activity, ...action.repair, loading: false };
          }
          return activity;
        })
      };

    case ActivityTypes.GET_EVENT_DETAILS_REQUEST:
      return {
        ...state,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.id && activity.type === action.activity_type) {
            activity.loading = true;
          }
          return activity;
        }),
        observation_list: _.map(state.observation_list, (activity) => {
          if (activity.activity_id === action.id) {
            activity.loading = true;
          }
          return activity;
        }),
      };
    case ActivityTypes.GET_EVENT_DETAILS_SUCCESS:
      // find event with correct id and update its details section.
      return {
        ...state,
        loadingDetails: false,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.event.id) {
            activity.details = action.event.details;
            activity.loading = false;
          }
          return activity;
        }),
        observation_list: _.map(state.observation_list, (activity) => {
          if (activity.activity_id === action.event.id && activity.type === action.event.activity_type) {
            activity.details = action.event.details;
            activity.loading = false;
          }
          return activity;
        })
      };
    case ActivityTypes.GET_EVENT_DETAILS_FAILURE:
      return {
        ...state,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.error.id && activity.type === action.error.activity_type) {
            activity.loading = false;
            activity.error = action.error.error.message;
          }
          return activity;
        }),
        observation_list: _.map(state.observation_list, (activity) => {
          if (activity.activity_id === action.error.id) {
            activity.loading = false;
            activity.error = action.error.error.message;
          }
          return activity;
        })

      };

      // TBD if some redux state changes needed in these events (post_event_comment and close_event.
      // conceptual thing to decide
    case ActivityTypes.POST_ACTIVITY_COMMENT_REQUEST:
      return {
        ...state
      };

    case ActivityTypes.POST_ACTIVITY_COMMENT_SUCCESS:
      return {
        ...state,
        loadingDetails: false,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.item.id && activity.type === action.item.type) {
            activity.comments.push(action.item.res);
            activity.comment_count = activity.comments.length;
            activity.loading = false;
          }
          return activity;
        })
      };
    case ActivityTypes.POST_ACTIVITY_COMMENT_FAILURE:
      return {
        ...state,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.error.id && activity.type === action.error.type) {
            activity.loading = false;
            activity.error = action.error.error.message;
          }
          return activity;
        })
      };

    case ActivityTypes.CLOSE_EVENT_REQUEST:
      return {
        ...state
      };
    case ActivityTypes.CLOSE_EVENT_SUCCESS:
      return {
        ...state
      };
    case ActivityTypes.CLOSE_EVENT_FAILURE:
      return {
        ...state
      };
    case ActivityTypes.PUT_ACTIVITY_COMMENT_REQUEST:
      return {
        ...state,
        commentEditLoading: true
      };
    case ActivityTypes.PUT_ACTIVITY_COMMENT_SUCCESS:
      return {
        ...state,
        commentEditLoading: false,
        loadingDetails: false,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.item.id && activity.type === action.item.type) {
            const idx = activity.comments.findIndex((comment => comment.comment_id === action.item.res.comment_id));
            activity.comments[idx] = action.item.res;
            activity.loading = false;
          }
          return activity;
        })
      };
    case ActivityTypes.PUT_ACTIVITY_COMMENT_FAILURE:
      return {
        ...state,
        commentEditLoading: false,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.error.id && activity.type === action.error.type) {
            activity.loading = false;
            activity.error = action.error.error.message;
          }
          return activity;
        })
      };

    case ActivityTypes.DELETE_ACTIVITY_COMMENT_REQUEST:
      return {
        ...state,
        commentDeleteLoading: true
      };
    case ActivityTypes.DELETE_ACTIVITY_COMMENT_SUCCESS:
      return {
        ...state,
        commentEditLoading: false,
        loadingDetails: false,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.item.id && activity.type === action.item.type) {
            const idx = activity.comments.findIndex((comment => comment.comment_id === action.item.res.comment_id));
            activity.comments.splice(idx, 1);
            activity.loading = false;
          }
          return activity;
        }),
        commentDeleteLoading: false
      };
    case ActivityTypes.DELETE_ACTIVITY_COMMENT_FAILURE:
      return {
        ...state,
        commentDeleteLoading: false,
        list: _.map(state.list, (activity) => {
          if (activity.activity_id === action.error.id && activity.type === action.error.type) {
            activity.loading = false;
            activity.error = action.error.error.message;
          }
          return activity;
        })
      };

    case ActivityTypes.DELETE_NOTE_REQUEST:
      return {
        ...state
      };
    case ActivityTypes.DELETE_NOTE_SUCCESS:
      return {
        ...state,
        list: _.filter(state.list, activity => activity.activity_id !== action.note_id)
      };
    case ActivityTypes.DELETE_NOTE_FAILURE:
      return {
        ...state
      };
    case ActivityTypes.DELETE_TASK_SUCCESS:
      return {
        ...state,
        list: _.filter(state.list, activity => activity.activity_id !== action.taskId)
      };
    case ActivityTypes.DELETE_REPAIR_SUCCESS:
      return {
        ...state,
        list: _.filter(state.list, activity => activity.activity_id !== action.repairId)
      };
    case ActivityTypes.UPDATE_ACTIVITY_REQUEST:
      return {
        ...state
      };
    case ActivityTypes.UPDATE_ACTIVITY_SUCCESS:
      if (['observation', 'remove_from_observation'].includes(action.action)) return state;
      return {
        ...state,
        list: _.map(state.list, (activity) => {
          if (action.payload.find(a => a.type === activity.type && a.activity_id === activity.activity_id)) {
            if (action.action === 'read') activity.read = true;
            else if (action.action === 'unread') activity.read = false;
          }
          return activity;
        }),
        observation_list: _.map(state.observation_list, (activity) => {
          if (action.payload.find(a => a.type === activity.type && a.activity_id === activity.activity_id)) {
            if (action.action === 'read') activity.read = true;
            else if (action.action === 'unread') activity.read = false;
          }
          return activity;
        })
      };
    case ActivityTypes.UPDATE_ACTIVITY_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
};
