import styled, { css } from 'styled-components';
import { prop, ifProp, ifNotProp, withProp, switchProp } from 'styled-tools';
import Button from './Button';

const NavButton = styled(Button).attrs({
  lineHeight: '0px'
})`
  height: 31px;
  letter-spacing: 1.2px;
  padding: 0.8em 1.6em;
  text-transform: uppercase;
  font-size: ${ifProp('borderButton', '12px', '12px')};
  ${ifNotProp('disabled', css`
    &:hover {
      border-color: initial; 
      cursor: pointer
    };`
  )}
  ${ifProp('small', css`
    width: 60px;
    height: 24px;
    font-size: 10px;
    padding: 0;
    text-align: center;
  `)}
  ${ifProp('critical', css`
    border-color: ${prop('theme.colors.critical')};
    background: ${prop('theme.colors.critical')};
  `)}
  ${ifProp('text', css`
    color: ${prop('text', 'white')};
  `)}
  ${ifProp('disabled', css`
    background: ${prop('theme.colors.greyXL', 'white')};
    pointer-events: none;
  `)}
  ${ifProp('borderButton', css`
    height: 33px;
    box-sizing: border-box;
    background: transparent;
    border-radius: 4px;
    letter-spacing: 0px;
    transition: all 0.3s;
  `)}
  ${switchProp('borderButton', {
    green: css`
      border: 1px solid ${prop('theme.primaryColor')};
      color: ${prop('theme.primaryColor')};
      ${ifProp('disabled', css`
        color: ${withProp('theme.backgroundA', prop => prop(0.3))};
        border-color: ${withProp('theme.backgroundA', prop => prop(0.4))};
      `)}
      ${ifNotProp('disabled', css`
        &:hover {
          border: 1px solid ${prop('theme.primaryColor')};
          color: white;
          background: ${prop('theme.primaryColor')};
        }
      `)}
      ${ifProp('full', css`
        background: ${prop('theme.primaryColor')};
        color: white;
      `)}
    `,
    red: css`
      border: 1px solid ${prop('theme.colors.critical')};
      color: ${prop('theme.colors.critical')};
      ${ifProp('disabled', css`
        color: ${withProp('theme.colors.criticalA', prop => prop(0.3))};
        border-color: ${withProp('theme.colors.criticalA', prop => prop(0.4))};
      `)}
      ${ifNotProp('disabled', css`
        &:hover {
          border: 1px solid ${prop('theme.colors.critical')};
          color: white;
          background: ${prop('theme.colors.critical')};
        }
      `)}
    `,
    black: css`
      border: 1px solid ${prop('theme.colors.greyXXD')};
      color: ${prop('theme.colors.greyXXD')};
      ${ifProp('disabled', css`
        color: ${withProp('theme.colors.greyXXDA', prop => prop(0.3))};
        border-color: ${withProp('theme.colors.greyXXDA', prop => prop(0.4))};
      `)}
      ${ifNotProp('disabled', css`
        &:hover {
          border: 1px solid ${prop('theme.colors.greyXXD')};
          color: white;
          background: ${prop('theme.colors.greyXXD')};
        }
      `)}
    `
  })}
`;

export default styled(NavButton)``;
