import styled from 'styled-components';

const H2 = styled.h2`
  font-size: 18px;
  font-weight: 300;
  font-family: 'Petasense Open Sans';
  color: ${props => props.theme.colors.black};
  margin: 0;
  padding: 1em 0;
`;

export default H2;
