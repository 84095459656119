import types from './machineOverview.types';
import { handleResponse } from '../../../../../common/helpers';
import { axiosInstance, ENDPOINT } from '../../../../../common/constants';

export const getConfig = (machine_id) => {
  const request = () => ({ type: types.GET_CONFIG_REQUEST });
  const success = config => ({ type: types.GET_CONFIG_SUCCESS, config, machine_id });
  const failure = error => ({ type: types.GET_CONFIG_FAILURE, error, machine_id });

  return (dispatch) => {
    // const params = { machine_id };
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_CONFIG(machine_id))).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      },
    );
  };
};

export const getMachineSummary = (machine_id) => {
  const request = () => ({ type: types.GET_MACHINE_SUMMARY_REQUEST });
  const success = summary => ({ type: types.GET_MACHINE_SUMMARY_SUCCESS, summary });
  const failure = error => ({ type: types.GET_MACHINE_SUMMARY_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_SUMMARY(machine_id), { params: { resource_version: 3 } })).then(
      (res) => {
        const assetConditionLevels = getState().currentAccount.preferences && getState().currentAccount.preferences.machine_condition_levels;
        if (res.health_summary.data) {
          const health = res.health_summary.data;
          res.health_summary = [
            parseInt(health.good, 10),
            parseInt(health.warning, 10),
            parseInt(health.critical, 10),
            parseInt(health.unknown, 10)
          ];
          if (assetConditionLevels === 5) {
            res.health_summary = [
              parseInt(health.good, 10),
              parseInt(health.slight, 10),
              parseInt(health.moderate, 10),
              parseInt(health.warning, 10),
              parseInt(health.critical, 10),
              parseInt(health.unknown, 10)
            ];
          }
        } else {
          res.health_summary = { error: res.health_summary.err };
        }
        if (res.utilization_summary.data) {
          const util = res.utilization_summary.data;
          res.utilization_summary = [
            parseInt(util.ON, 10),
            parseInt(util.OFF, 10),
            parseInt(util.NA, 10)
          ];
        } else {
          res.utilization_summary = { error: res.utilization_summary.err };
        }
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      },
    );
  };
};

export const getComponentSVG = (component_type, orientation) => {
  const request = () => ({ type: types.GET_COMPONENT_SVG_REQUEST, component_type: `${component_type}_${orientation}` });
  const success = svgString => ({ type: types.GET_COMPONENT_SVG_SUCCESS, svg: svgString, component_type: `${component_type}_${orientation}` });
  const failure = error => ({ type: types.GET_COMPONENT_SVG_FAILURE, error, component_type });

  return (dispatch, getState) => {
    const { graphics } = getState().machineDetails;
    if (graphics.data[`${component_type}_${orientation}`]) {
      const oParser = new DOMParser();
      const oDOM = oParser.parseFromString(graphics.data[`${component_type}_${orientation}`], 'image/svg+xml');
      return new Promise((resolve, reject) => {
        resolve(oDOM);
      });
    }
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.GET_COMPONENT_SVG(component_type), { headers: { 'Content-Type': 'image/svg+xml' }, params: { orientation } })).then(
      (res) => {
        const oParser = new DOMParser();
        dispatch(success(res));
        const oDOM = oParser.parseFromString(res, 'image/svg+xml');
        return oDOM;
      },
      (error) => {
        dispatch(failure(error.message));
      }
    );
  };
};
