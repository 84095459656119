import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { withTheme } from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { history } from 'common/helpers/history';
import { toastr } from 'react-redux-toastr';
import * as _ from 'lodash';

import AccountMenuItems from 'common/components/organisms/AccountMenuItems';
import MoreOptions from 'common/components/organisms/MoreOptions';
import MsgContainer from 'common/components/atoms/MsgContainer';
import FilterCountContainer from 'common/components/atoms/FilterCountContainer';
import { isFilterApplied, generateQueryString, isJsonString } from 'common/utils';
import PlusSvg from 'common/images/PlusSvg';
import { ButtonTransparent } from 'common/components/atoms/Button';
import { Routes } from 'home/HomePage/constants/routes.constants';
import SearchFilters from 'common/components/atoms/SearchFilters';
import { CollapsibleWidgetsContainer } from 'common/components/molecules';
import FooterFlex_T from 'common/components/atoms/FooterFlex';
import Label from 'common/typography/Label/Label';
import UnassignSensorAlertPrompt from 'common/components/organisms/AlertPrompt';
import colors from 'common/styles/colors';
import EditIcon from 'common/images/BearingModal/EditIcon';
import Widget from 'home/Widget';
import { humanize } from 'common/helpers';
import { widgetActions } from 'home/Widget/actions/widget.actions';
import Loading from 'common/components/atoms/Loading';
import DynamicFilters from 'common/components/organisms/DynamicFilters';
import { isPartnerAdmin } from 'common/rbac/util';
import AssignModel from './AssignToAccountModel';
import AddDeviceModel from './AddDeviceModal';

import * as modelActions from '../../Settings/SensorModels/actions/models.actions';
import * as machineDetailsActions from '../../Machines/MachineDetails/machineDetails.actions';
import * as sensorActions from '../actions/sensors.actions';
import InputField from '../../../common/components/atoms/InputField';
import { Search } from '../../../common/images/FaIcons';
import FilterV2 from '../../../common/components/molecules/FilterV2';
import List from '../../../common/components/molecules/List';
import OutsideAlerter from '../../../common/OutsideAlerter';
import SensorsDetails from './SensorsDetails';

import Checkbox from '../../../common/components/atoms/Checkbox';
import FlexContainer from '../../../common/components/atoms/FlexContainer';
import SensorsItem from './SensorsItem';
import { BatteryIcon } from '../../../common/images/BatteryIcon';
import WiFiSvg from '../../../common/images/WiFiSvg';
import Button from '../../../common/components/atoms/Button';
import RBAC from '../../../common/rbac/RBAC';
import {
  mapComponentToResource,
  operations
} from '../../../common/rbac/constants';
import Radiobutton from '../../../common/components/molecules/Radiobutton';
import Calendar from '../../../common/components/molecules/ReactCalendar';
import { mapDayToString } from '../../../common/constants';
import H2 from '../../../common/typography/H2/H2';
import CrossSvg from '../../../common/images/CrossSvg';
import { allSensorsPageColumns, defaultSensorsPageColumns } from '../constants/sensors.constants';
import EditDropdown from './EditDropdown';
import { sensorsConstants } from '../actions/sensors.constants';

const FooterFlex = styled(FooterFlex_T)`
  align-self: flex-end;
`;

const FilterItem = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  text-align: left;
  width: max-content;
  min-width: 150px;
  border: none;
  outline: none;
  background-color: white;
  font-family: 'Petasense Open Sans';
  font-weight: 600;
  color: ${props => props.theme.colors.lightGray};
  ${props => props.selected && `color: ${props.theme.colors.black};`}
  &:hover {
    color: ${props => props.theme.colors.black};
    background-color: #f7f7f7;
  }
  &:first-of-type {
    padding-top: 1em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    padding-bottom: 1em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  span {
    margin-left: 0.5em;
  }
`;

const DateSelector = styled.div`
  height: 100%;
  display: inline-block;
  width: 250px;
  vertical-align: top;
  padding: 10px 15px;
  box-sizing: border-box;
  ${InputField} {
    margin-bottom: 13.5px;
  }
  input {
    font-size: 1em;
    padding: .57857143em 1em;
  }
  label {
    font-weight: 600;
    margin-bottom: 3px;
  }
`;

const CalenderContainer = styled.div`
  position: absolute;
  z-index: 3;
  width: 220px;
  top: ${props => props.to ? '103px' : '33px'};
`;

const formatDate = (locale, date) => {
  const day = date.getDay();
  return mapDayToString[day][0];
  // if (day !== 6 && day !== 0) return mapDayToString[day][0];
  // return mapDayToString[day].slice(0, 2);
};

const SensorsContainer = styled(FlexContainer).attrs({
  direction: 'row'
})`
  background-color: ${props => props.theme.colors.white};
  height: Calc(100vh - 172px);
  overflow: auto;
`;

const ListContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  position: relative;
  overflow: auto;
  width: ${props => props.width};
  height: 100%;
  ${props =>
    props.width === '30%' &&
    `border-right: 2px solid ${props.theme.colors.greyL};`}
  ${props =>
    props.minWidth && `min-width: ${props.minWidth};`}
`;

const WifiDropdownText = ({ wifiLevel, text }) => (
  <Fragment>
    {wifiLevel !== null && <WiFiSvg wifiLevel={wifiLevel} />}
    <span style={{ marginLeft: '5px', marginTop: '-5px' }}>{text}</span>
  </Fragment>
);

const SvgContainer = styled(FlexContainer)`
  cursor: pointer;
  padding: 0.5em;
  padding-left: 2em;
`;

class Sensors extends Component {
  constructor(props) {
    super(props);
    this.initialSearchParams = {
      search_key: '',
      machine_search_key: '',
      area_search_key: '',
      account_search_key: '',
      firmware_search_key: '',
      type: [],
      machines: [],
      area: [],
      accounts: [],
      battery_level: [],
      wifi_signal: [],
      last_measurement: [],
      firmwares: [],
      last_measurement_from: '',
      last_measurement_to: '',
      unreachable_extended: '',
      devices_health_widget_filters: [],
      other_filters: []
    };
    this.state = {
      // selectedSerialNumber: null,
      selectedColumns: this.getPageColumns(props),
      machineSensors: false,
      machines: [],
      area: [],
      accounts: [],
      firmwares: [],
      allArea: [],
      allMachines: [],
      allAccounts: [],
      allFirmwares: [],
      editMode: false,
      calenderOpen: null,
      dateExpanded: false,
      customDayFrom: false,
      customDayTo: false,
      searchParams: {
        ...this.initialSearchParams,
      },
      filtersOpen: {
        type: false,
        machines: false,
        accounts: false,
        area: false,
        battery_level: false,
        wifi_signal: false,
        last_measurement: false,
        firmwares: false,
        other_filters: false
      },
      typeOptions: [],
      assignModelOpen: false,
      unassignModelOpen: false,
      addDeviceModelOpen: false
    };
    this.batteryLevelOptions = [
      { text: <BatteryIcon batteryLevel={3} />, value: 3, name: '< 10%' },
      { text: <BatteryIcon batteryLevel={2} />, value: 2, name: '< 20%' },
      { text: <BatteryIcon batteryLevel={1} />, value: 1, name: '> 20%' },
      { text: <BatteryIcon batteryLevel={-1} />, value: null, name: 'Unknown' }
    ];
    this.wifiSignalOptions = [
      {
        text: <WifiDropdownText wifiLevel={0} text="Very weak" />,
        value: 0,
        name: 'Very weak'
      },
      {
        text: <WifiDropdownText wifiLevel={1} text="Weak" />,
        value: 1,
        name: 'Weak'
      },
      {
        text: <WifiDropdownText wifiLevel={2} text="Normal" />,
        value: 2,
        name: 'Normal'
      },
      {
        text: <WifiDropdownText wifiLevel={3} text="Good" />,
        value: 3,
        name: 'Good'
      },
      {
        text: <WifiDropdownText wifiLevel={4} text="Strong" />,
        value: 4,
        name: 'Strong'
      },
      {
        text: <WifiDropdownText text="Unknown" />,
        value: null,
        name: 'Unknown'
      }
    ];
    this.lastMeasurementOptions = [
      {
        text: 'All reachable',
        value: false,
        name: 'All reachable'
      },
      {
        text: 'All unreachable',
        value: true,
        name: 'All unreachable'
      },
      {
        text: 'All unassigned',
        value: 'unassigned',
        name: 'All unassigned'
      },
      {
        text: 'All assigned',
        value: 'assigned',
        name: 'All assigned'
      },
      {
        text: 'Custom',
        value: 'custom',
        name: 'Custom'
      },
      {
        text: 'Unreachable for extended time',
        value: 'unreachable_extended',
        name: 'Unreachable for extended time'
      }
    ];
    this.otherFiltersOptions = [
      {
        text: 'Continuous bad data',
        value: 'devices_with_continuous_outliers',
        name: 'Continuous bad data'
      },
      {
        text: 'Uneven Vibrations',
        value: 'devices_with_inconsistent_vibrations',
        name: 'Uneven Vibrations'
      }
    ];
  }

  componentDidUpdate(prevProps) {
    const { getResourcesBasedOnType } = this.props.sensorActions;
    if (this.props.adminDashboard.adminOpen !== prevProps.adminDashboard.adminOpen) {
      if (this.props.adminDashboard.adminOpen) {
        getResourcesBasedOnType('account').then((res) => {
          let accountsList = [];
          if (isPartnerAdmin(this.props.user)) accountsList = [...res];
          else accountsList = [{ text: 'Unassociated', value: null }, ...res];
          this.setState({
            accounts: accountsList,
            allAccounts: accountsList,
          });
        });
      }
      this.search();
    }
    if (!_.isEqual(this.props.location.search, prevProps.location.search)) {
      this.selectFiltersFromUrl();
    }
  }

  componentDidMount() {
    const title = this.props.title;
    document.title = `${title} | Devices`;

    this.mounted = true;
    const { machine_id } = this.props.match.params;
    const {
      getSensorsMetadata,
      getResourcesBasedOnType,
      setSensorsFilters
    } = this.props.sensorActions;
    let p1;
    getResourcesBasedOnType('mcu_firmware').then((res) => {
      this.setState({ firmwares: res, allFirmwares: res }, this.selectFilterFromCharts);
    }
    );
    if (machine_id) {
      this.setState({
        machineSensors: true
      });
      p1 = this.changeFilter('machines', [Number(machine_id)]);
    } else {
      getResourcesBasedOnType('machine').then(res =>
        this.setState({
          machines: res,
          allMachines: res
        })
      );
      if (this.props.adminDashboard.adminOpen) {
        getResourcesBasedOnType('account').then((res) => {
          let accountsList = [];
          if (isPartnerAdmin(this.props.user)) accountsList = [...res];
          else accountsList = [{ text: 'Unassociated', value: null }, ...res];
          this.setState({
            accounts: accountsList,
            allAccounts: accountsList,
          });
        });
      }
      getResourcesBasedOnType('area').then(res =>
        this.setState({ area: res, allArea: res })
      );
      if (this.props.location.state && this.props.location.state.filter) p1 = this.selectFilterFromCharts();
      else if (!_.isEmpty(this.props.location.search) && this.props.location.pathname === '/devices') {
        this.selectFiltersFromUrl();
      } else {
        const deviceListSettings = JSON.parse(localStorage.getItem('device_list_settings'));
        const filters = [
          { name: 'machine_id', op: 'in', value: [] },
          { name: 'account_id', op: 'in', value: [] },
          { name: 'battery_level', op: 'in', value: [] },
          { name: 'type', op: 'in', value: [] },
          { name: 'wifi_signal', op: 'in', value: [] },
          { name: 'area_id', op: 'in', value: [] },
          { name: 'unreachable', op: 'in', value: [] },
          { name: 'mcu_firmware', op: 'in', value: [] },
          { name: 'last_measurement_from', op: 'ge', value: '' },
          { name: 'last_measurement_to', op: 'le', value: '' },
          { name: 'unreachable_extended', op: 'eq', value: '' },
          { name: 'other_filters', op: 'in', value: [] }
        ];
        if (deviceListSettings) {
          const lastMeasurementFilter = deviceListSettings.last_measurement_filter;
          if (lastMeasurementFilter === 'assigned') {
            filters.push({ name: 'machine', op: 'ne', value: '' });
            this.setState(prevState => ({
              searchParams: {
                ...prevState.searchParams,
                last_measurement: ['assigned']
              }
            }));
          }
        }
        p1 = setSensorsFilters(filters, '');
      }
    }
    const p2 = getSensorsMetadata();
    const serial_number =
      this.props.location.state && this.props.location.state.serial_number;
    if (serial_number) {
      Promise.all([p1, p2]).then(() => {
        const { getSensorDetails } = this.props.sensorActions;
        getSensorDetails(serial_number, true);
      });
    }
    p2.then(() => {
      this.setState(prevState => ({
        typeOptions: prevState.typeOptions.concat(this.props.sensors.metadata.metadata.sensor_types)
      }));
    });

    this.props.widgetActions.getWidgetsMetadata();
  }


  componentWillUnmount() {
    this.closeDetails();
    this.mounted = false;
  }

  updateUrlQueryString = () => {
    if (this.props.location.pathname !== '/devices') return;
    const searchParams = _.cloneDeep(this.state.searchParams);
    if (_.isArray(searchParams.last_measurement) && searchParams.last_measurement[0] === 'custom') {
      searchParams.last_measurement = [];
    }
    const queryString = generateQueryString(
      searchParams,
      ['type', 'area', 'machines', 'firmwares']
    );
    const pathname = this.props.location.pathname;
    history.replace({
      pathname,
      search: queryString
    });
  }

  getPageColumns = (props) => {
    const deviceListSettings = JSON.parse(localStorage.getItem('device_list_settings'));
    if (props.location.pathname === '/admin-dashboard/devices' ||
      !deviceListSettings || !deviceListSettings.columns
    ) {
      return [...defaultSensorsPageColumns];
    }
    return deviceListSettings.columns;
  }

  selectFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const searchParams = {
      ...this.initialSearchParams
    };
    Object.keys(searchParams).forEach((key) => {
      if (urlParams.get(key)) {
        searchParams[key] = isJsonString(urlParams.get(key)) ?
          JSON.parse(urlParams.get(key)) :
          urlParams.get(key);
      }
    });
    if (!_.isEqual(this.state.searchParams, searchParams)) {
      this.setState({ searchParams });
      this.search(searchParams);
    }
  }

  selectFilterFromCharts = (newFilter = null) => {
    let filter;
    if (newFilter) filter = newFilter;
    else if (this.props.location.state && this.props.location.state.filter) filter = this.props.location.state.filter;
    else return;

    let searchParams = {
      ...this.initialSearchParams
    };
    const filters = {};

    filter.forEach((f) => {
      if (f.op === 'in') filters[f.name] = f.value;
      if (f.op === 'not_in' && f.allDropdownsKey) {
        const allDropdowns = this.state[f.allDropdownsKey];
        filters[f.name] = allDropdowns.filter(item => !f.value.includes(item.value)).map(item => item.value);
      }
    });
    searchParams = {
      ...searchParams,
      ...filters
    };

    this.setState({ searchParams }, this.updateUrlQueryString);
    this.search(searchParams);
  }

  clearAllFilters = () => {
    const searchParams = {
      ...this.initialSearchParams
    };
    if (this.state.machineSensors) {
      searchParams.machines = this.state.searchParams.machines;
    }
    this.setState({ searchParams }, this.updateUrlQueryString);
    if (this.state.dateExpanded) this.toggleDateExpanded();
    this.search(searchParams);
  }

  removeAppliedFilters = (serial_number) => {
    const { machine_id } = this.props.match.params;
    const { setSensorsFilters } = this.props.sensorActions;
    this.setState(
      {
        searchParams: _.cloneDeep(this.initialSearchParams)
      },
      () => {
        let p1;
        if (machine_id) {
          p1 = this.changeFilter('machines', [Number(machine_id)]);
        } else {
          const filters = [
            { name: 'machine_id', op: 'in', value: [] },
            { name: 'account_id', op: 'in', value: [] },
            { name: 'battery_level', op: 'in', value: [] },
            { name: 'type', op: 'in', value: [] },
            { name: 'wifi_signal', op: 'in', value: [] },
            { name: 'area_id', op: 'in', value: [] },
            { name: 'unreachable', op: 'in', value: [] },
            { name: 'mcu_firmware', op: 'in', value: [] },
            { name: 'last_measurement_from', op: 'ge', value: '' },
            { name: 'last_measurement_to', op: 'le', value: '' },
            { name: 'unreachable_extended', op: 'eq', value: '' },
            { name: 'other_filters', op: 'in', value: [] }
          ];
          p1 = setSensorsFilters(filters, '');
          this.updateUrlQueryString();
        }
        Promise.all([p1]).then(() => {
          const { getSensorDetails } = this.props.sensorActions;
          getSensorDetails(serial_number, true);
        });
      }
    );
  };

  search = (params = null) => {
    const { searchParams: stateParams } = this.state;
    const searchParams = params || stateParams;
    const { setSensorsFilters } = this.props.sensorActions;
    let unreachable = [];
    let unreachable_extended_value = '';
    if (searchParams.last_measurement.includes(true) || searchParams.last_measurement.includes(false)) {
      unreachable = searchParams.last_measurement;
    }
    if (searchParams.last_measurement.includes('unreachable_extended')) {
      unreachable_extended_value = 14;
    }
    let wifi_signal = [];
    wifi_signal = [...searchParams.wifi_signal];
    if (wifi_signal.includes(4)) {
      wifi_signal.push(5);
      // both wifi_signal level 4,5 are mapped to Strong
    }
    const filters = [
      { name: 'machine_id', op: 'in', value: searchParams.machines },
      { name: 'account_id', op: 'in', value: searchParams.accounts },
      { name: 'battery_level', op: 'in', value: searchParams.battery_level },
      { name: 'type', op: 'in', value: searchParams.type },
      { name: 'wifi_signal', op: 'in', value: wifi_signal },
      { name: 'area_id', op: 'in', value: searchParams.area },
      { name: 'unreachable', op: 'in', value: unreachable },
      { name: 'last_measurement_from', op: 'ge', value: searchParams.last_measurement_from },
      { name: 'last_measurement_to', op: 'le', value: searchParams.last_measurement_to },
      { name: 'mcu_firmware', op: 'in', value: searchParams.firmwares },
      { name: 'unreachable_extended', op: 'eq', value: unreachable_extended_value },
      { name: 'other_filters', op: 'in', value: searchParams.other_filters }
    ];
    this.setState({
      devices_health_widget_filters: { name: 'account_id', op: 'in', value: searchParams.accounts }
    });
    let last_measurement_filter = null;
    if (searchParams.last_measurement.includes('assigned')) last_measurement_filter = 'assigned';
    this.updateDeviceListLocalStorage('last_measurement_filter', last_measurement_filter);

    if (searchParams.last_measurement.includes('unassigned')) filters.push({ name: 'machine', op: 'eq', value: '' });
    else if (searchParams.last_measurement.includes(true) || searchParams.last_measurement.includes(false) ||
      searchParams.last_measurement.includes('assigned') || searchParams.last_measurement.includes('unreachable_extended') ||
      searchParams.other_filters.includes('devices_with_continuous_outliers')) filters.push({ name: 'machine', op: 'ne', value: '' });
    return setSensorsFilters(filters, searchParams.search_key);
  };

  updateDeviceListLocalStorage = (key, value) => {
    let deviceListSettings = JSON.parse(localStorage.getItem('device_list_settings'));
    deviceListSettings = {
      ...deviceListSettings,
      [key]: value
    };
    localStorage.setItem('device_list_settings', JSON.stringify(deviceListSettings));
  }

  toggleFilterOpen = (filter) => {
    this.setState(prevState => ({
      filtersOpen: {
        ...prevState.filtersOpen,
        [filter]: !prevState.filtersOpen[filter]
      }
    }));
  };

  toggleDateExpanded = () => {
    this.setState(prevState => ({
      dateExpanded: !prevState.dateExpanded
    }));
  };

  clearDate = () => {
    const searchParams = {
      ...this.state.searchParams,
      last_measurement_from: '',
      last_measurement_to: ''
    };
    this.setState({
      searchParams,
      customDayFrom: false,
      customDayTo: false
    });
    this.search(searchParams);
  };

  closeCalender = () => {
    this.setState({ calenderOpen: null });
  };

  openCustomCalender = (str) => {
    this.setState({
      calenderOpen: str
    });
  };

  onClickDay = (value, label) => {
    let searchParams = {};
    if (label === 'from') {
      searchParams = {
        ...this.state.searchParams,
        last_measurement_from: value
      };
      this.setState({
        searchParams,
        customDayFrom: value,
        calenderOpen: null
      });
    } else if (label === 'to') {
      searchParams = {
        ...this.state.searchParams,
        last_measurement_to: value
      };
      this.setState({
        searchParams,
        customDayTo: value,
        calenderOpen: null
      });
    }
    this.search(searchParams);
  };

  changeFilter = (filter, value) => {
    const searchParams = {
      ...this.state.searchParams,
      [filter]: value
    };
    this.setState({
      searchParams
    }, this.updateUrlQueryString);
    if (filter === 'search_key') {
      this.nameSearchDebounce(searchParams);
    } else {
      this.search(searchParams);
    }
  };

  changeLastMeasurementFilter = (filter, value) => {
    const searchParams = {
      ...this.state.searchParams,
      [filter]: value,
      last_measurement_from: '',
      last_measurement_to: ''
    };
    this.setState({
      searchParams,
      customDayFrom: false,
      customDayTo: false
    }, this.updateUrlQueryString);
    return this.search(searchParams);
  };

  closeDetails = () => {
    const { clearSelectedSensor } = this.props.sensorActions;
    clearSelectedSensor();
  };

  nameSearchDebounce = _.debounce((params) => {
    this.search(params);
  }, 400);

  renderFilterOptions = (options, name) => {
    if (_.isEmpty(options)) {
      return (
        <MsgContainer>
          <span>No {name}s are available</span>
        </MsgContainer>
      );
    }

    return options.map((option, index) => {
      const selected = _.includes(this.state.searchParams[name], option.value);
      if (name === 'last_measurement') {
        return (
          <FilterItem
            key={index.toString()}
            className="dropdown-item"
            selected={selected}
            onClick={() => {
              let types = [];
              if (!selected) {
                types = [option.value];
              }
              if (option.value === 'custom' || this.state.dateExpanded) {
                this.toggleDateExpanded('dateExpanded');
              }
              this.changeLastMeasurementFilter(name, types, selected);
            }}
          >
            <Radiobutton value={selected} />
            <span>{option.text}</span>
          </FilterItem>
        );
      }
      if (name === 'other_filters') {
        return (
          <FilterItem
            key={index.toString()}
            className="dropdown-item"
            selected={selected}
            onClick={() => {
              let types = [];
              if (selected) {
                types = _.filter(
                  this.state.searchParams[name],
                  t => t !== option.value
                );
              } else {
                types = [...this.state.searchParams[name], option.value];
              }
              this.changeFilter(name, types, selected);
            }}
          >
            <Radiobutton value={selected} />
            <span>{option.text}</span>
          </FilterItem>
        );
      }
      return (
          <FilterItem
            key={index.toString()}
            className="dropdown-item"
            selected={selected}
            onClick={() => {
              let types = [];
              if (selected) {
                types = _.filter(
                  this.state.searchParams[name],
                  t => t !== option.value
                );
              } else {
                types = [...this.state.searchParams[name], option.value];
              }
              this.changeFilter(name, types, selected);
            }}
          >
            <Checkbox value={selected} />
            <span>{option.text}</span>
          </FilterItem>
      );
    });
  };

  searchMachineFilter = (value) => {
    this.setState(prevState => ({
      machines: prevState.allMachines.filter(u =>
        u.text.toLowerCase().includes(value.toLowerCase())
      ),
      searchParams: {
        ...prevState.searchParams,
        machine_search_key: value
      }
    }));
  };

  searchAreaFilter = (value) => {
    this.setState(prevState => ({
      area: prevState.allArea.filter(u =>
        u.text.toLowerCase().includes(value.toLowerCase())
      ),
      searchParams: {
        ...prevState.searchParams,
        area_search_key: value
      }
    }));
  };

  searchAccountFilter = (value) => {
    this.setState(prevState => ({
      accounts: prevState.allAccounts.filter(u =>
        u.text.toLowerCase().includes(value.toLowerCase())
      ),
      searchParams: {
        ...prevState.searchParams,
        account_search_key: value
      }
    }));
  };

  searchFirmwareFilter = (value) => {
    this.setState(prevState => ({
      firmwares: prevState.allFirmwares.filter(u =>
        u.text.toLowerCase().includes(value.toLowerCase())
      ),
      searchParams: {
        ...prevState.searchParams,
        firmware_search_key: value
      }
    }));
  }

  selectGivenItems = (selected, key) => {
    this.setState(
      prevState => ({
        searchParams: {
          ...prevState.searchParams,
          [key]: selected
        },
        filtersOpen: {
          ...prevState.filtersOpen,
          [key]: false
        }
      }),
      this.search
    );
  };

  toggleAssignModel = () => {
    this.setState(prevState => ({
      assignModelOpen: !prevState.assignModelOpen
    }));
  }

  toggleUnassignModel = () => {
    this.setState(prevState => ({
      unassignModelOpen: !prevState.unassignModelOpen
    }));
  }

  toggleAddDeviceModel = () => {
    this.setState(prevState => ({
      addDeviceModelOpen: !prevState.addDeviceModelOpen
    }));
  }

  unassignDevice = () => {
    const {
      items,
      selectedSensorList
    } = this.props.sensors;
    const serial_numbers = [];
    items.object.filter(item => selectedSensorList.includes(item.serial_number) && item.account_id !== null).forEach(item => serial_numbers.push(item.serial_number));
    const params = {
      account_id: null,
      serial_numbers
    };
    this.props.sensorActions.assignDevicesToAccount(params).then(() => {
      this.search();
    });
    this.toggleUnassignModel();
  }

  assignDevicesToAccount= (account_id) => {
    const {
      items,
      selectedSensorList
    } = this.props.sensors;
    const serial_numbers = [];
    items.object.filter(item => selectedSensorList.includes(item.serial_number) && item.account_id === null).forEach(item => serial_numbers.push(item.serial_number));
    const params = {
      account_id,
      serial_numbers
    };
    this.props.sensorActions.assignDevicesToAccount(params).then(() => {
      this.search();
    });
    this.toggleAssignModel();
  }

  addDevice = (device_type, account_id, serial_number, device_id, mac_address, reporting_interval, tx_model_id, mote_model_id) => {
    let params = {};
    switch (device_type) {
      case 'Transmitter':
        params = {
          account_id,
          serial_number,
          device_id,
          mac_address,
          reporting_interval,
          tx_model_id
        };
        this.props.sensorActions.createTx(params).then(() => {
          toastr.success('Transmitter created successfully');
          this.search();
        }).catch((e) => {
          toastr.error(e.toString());
        });
        break;
      case 'Mote':
        params = {
          account_id,
          serial_number,
          device_id,
          mac_address,
          mote_model_id
        };
        this.props.sensorActions.createMote(params).then(() => {
          toastr.success('Mote created successfully');
          this.search();
        }).catch((e) => {
          toastr.error(e.toString());
        });
        break;
      default:
        break;
    }
    this.toggleAddDeviceModel();
  }

  getChartFilters = () => {
    const filters = [];
    if (_.isEmpty(this.state.searchParams.accounts) && this.props.adminDashboard.adminOpen) {
      filters.push({ name: 'all', op: 'eq', value: true });
    }
    if (!_.isEmpty(this.state.searchParams.accounts)) {
      filters.push({ name: 'account_id', op: 'in', value: this.state.searchParams.accounts });
    }
    return filters;
  };

  renderFilter = (filter) => {
    let machineTextValue = '';
    let areaTextValue = '';
    let accountTextValue = '';
    if (!this.state.machineSensors) {
      machineTextValue = this.state.searchParams.machines.reduce(
        (acc, id, idx) => {
          const name = this.state.allMachines.filter(
            machine => machine.value === id
          );
          if (idx === 0) return `${name[0].text}`;
          if (idx === 1) return `${acc}, ${name[0].text}...`;
          return acc;
        },
        ''
      );

      areaTextValue = this.state.searchParams.area.reduce((acc, id, idx) => {
        const name = this.state.allArea.filter(area => area.value === id);
        if (idx === 0) return `${name[0].text}`;
        if (idx === 1) return `${acc}, ${name[0].text}...`;
        return acc;
      }, '');

      accountTextValue = this.state.searchParams.accounts.reduce((acc, id, idx) => {
        const name = this.state.allAccounts.filter(accounts => accounts.value === id);
        if (!_.isEmpty(name)) {
          if (idx === 0) return `${name[0].text}`;
          if (idx === 1) return `${acc}, ${name[0].text}...`;
        }
        return acc;
      }, '');
    }

    let firmwareTextValue = '';
    firmwareTextValue = this.state.searchParams.firmwares.reduce((acc, id, idx) => {
      if (idx === 0) return `${id}`;
      if (idx === 1) return `${acc}, ...`;
      return acc;
    }, '');

    const typeTextValue = !_.isEmpty(this.state.typeOptions) && this.state.searchParams.type.reduce(
      (acc, id, idx) => {
        const name = this.state.typeOptions.filter(machine => machine.value === id);
        if (idx === 0) return `${name[0].text}`;
        if (idx === 1) return `${acc}, ${name[0].text}...`;
        return acc;
      },
      ''
    );

    const wifiTextValue = this.state.searchParams.wifi_signal.reduce(
      (acc, id, idx) => {
        const name = this.wifiSignalOptions.filter(s => s.value === id);
        if (idx === 0) return `${name[0].name}`;
        if (idx === 1) return `${acc}, ${name[0].name}...`;
        return acc;
      },
      ''
    );

    const batteryTextValue = this.state.searchParams.battery_level.reduce(
      (acc, id, idx) => {
        const name = this.batteryLevelOptions.filter(s => s.value === id);
        if (idx === 0) return `${name[0].name}`;
        return `${acc}, ${name[0].name}`;
      },
      ''
    );

    const otherFiltersTextValue = this.state.searchParams.other_filters.reduce(
      (acc, id, idx) => {
        const name = this.otherFiltersOptions.filter(s => s.value === id);
        if (idx === 0) return `${name[0].name}`;
        return `${acc}, ${name[0].name}`;
      },
      ''
    );

    const lastMeasurementTextValue = this.state.searchParams.last_measurement.reduce(
      (acc, id, idx) => {
        const name = this.lastMeasurementOptions.filter(s => s.value === id);
        if (idx === 0) return `${name[0].name}`;
        if (idx === 1) return `${acc}, ${name[0].name}...`;
        return acc;
      },
      ''
    );
    switch (filter) {
      case 'type':
        return (
          <FilterV2
            className="filter-container"
            title="Type"
            value={typeTextValue}
            open={this.state.filtersOpen.type}
            toggleOpen={() => this.toggleFilterOpen('type')}
            selectedItems={this.state.searchParams.type}
          >
          {this.renderFilterOptions(this.state.typeOptions, 'type')}
          </FilterV2>
        );
      case 'area':
        if (!this.props.adminDashboard.adminOpen && !this.state.machineSensors) {
          return (
              <FilterV2
                className="filter-container"
                title="Area"
                value={areaTextValue}
                open={this.state.filtersOpen.area}
                toggleOpen={() => this.toggleFilterOpen('area')}
                onChangeSearch={this.searchAreaFilter}
                searchValue={this.state.searchParams.area_search_key}
                viewAllItems={this.state.allArea}
                selectedItems={this.state.searchParams.area}
                selectGivenItems={selected =>
                  this.selectGivenItems(selected, 'area')
                }
                itemName="area"
              >
                {this.renderFilterOptions(this.state.area, 'area')}
              </FilterV2>
          );
        }
        return null;

      case 'machine':
        if (!this.state.machineSensors) {
          return (
                <FilterV2
                  className="filter-container"
                  title="Machine"
                  value={machineTextValue}
                  open={this.state.filtersOpen.machines}
                  toggleOpen={() => this.toggleFilterOpen('machines')}
                  onChangeSearch={this.searchMachineFilter}
                  searchValue={this.state.searchParams.machine_search_key}
                  viewAllItems={this.state.allMachines}
                  selectedItems={this.state.searchParams.machines}
                  selectGivenItems={selected =>
                    this.selectGivenItems(selected, 'machines')
                }
                  itemName="machine"
                >
                {this.renderFilterOptions(this.state.machines, 'machines')}
                </FilterV2>
          );
        }
        return null;

      case 'battery_level':
        return (
          <FilterV2
            className="filter-container"
            title="Battery Level"
            value={batteryTextValue}
            open={this.state.filtersOpen.battery_level}
            toggleOpen={() => this.toggleFilterOpen('battery_level')}
          >
          {this.renderFilterOptions(
            this.batteryLevelOptions,
            'battery_level'
          )}
          </FilterV2>
        );

      case 'wifi_signal':
        return (
          <FilterV2
            className="filter-container"
            title="WiFi-Signal"
            value={wifiTextValue}
            open={this.state.filtersOpen.wifi_signal}
            toggleOpen={() => this.toggleFilterOpen('wifi_signal')}
            selectedItems={this.state.searchParams.wifi_signal}
          >
          {this.renderFilterOptions(this.wifiSignalOptions, 'wifi_signal')}
          </FilterV2>
        );

      case 'other_filters':
        return (
          <FilterV2
            className="filter-container"
            title="Other Filters"
            value={otherFiltersTextValue}
            open={this.state.filtersOpen.other_filters}
            toggleOpen={() => this.toggleFilterOpen('other_filters')}
          >
            {this.renderFilterOptions(this.otherFiltersOptions, 'other_filters')}
          </FilterV2>
        );

      case 'last_reported':
        return (
          <FilterV2
            className="filter-container"
            title="Last Reported"
            value={lastMeasurementTextValue}
            open={this.state.filtersOpen.last_measurement}
            noScroll
            toggleOpen={() => this.toggleFilterOpen('last_measurement')}
          >
          <FlexContainer
            direction="row"
          >
            <FlexContainer
              direction="column"
            >
              {this.renderFilterOptions(this.lastMeasurementOptions, 'last_measurement')}
            </FlexContainer>
            {this.state.dateExpanded && (
            <DateSelector>
              {this.state.calenderOpen === 'from' && (
                <OutsideAlerter open={this.state.calenderOpen === 'from'} handleClick={this.closeCalender}>
                  <CalenderContainer>
                    <Calendar
                      formatShortWeekday={(locale, date) => formatDate(locale, date)}
                      maxDetail="month"
                      maxDate={this.state.customDayTo || new Date()}
                      showNeighboringMonth={false}
                      onClickDay={value => this.onClickDay(value, 'from')}
                      value={this.state.customDayFrom}
                    />
                  </CalenderContainer>
                </OutsideAlerter>
              )}
              {this.state.calenderOpen === 'to' && (
                <OutsideAlerter open={this.state.calenderOpen === 'to'} handleClick={this.closeCalender}>
                  <CalenderContainer to="true">
                    <Calendar
                      formatShortWeekday={(locale, date) => formatDate(locale, date)}
                      maxDetail="month"
                      maxDate={new Date()}
                      minDate={this.state.customDayFrom}
                      value={this.state.customDayTo}
                      showNeighboringMonth={false}
                      onClickDay={value => this.onClickDay(value, 'to')}
                    />
                  </CalenderContainer>
                </OutsideAlerter>
              )}
                <FlexContainer direction="row" justifyContent="space-between">
                  <H2>Custom</H2>
                  <SvgContainer
                    onClick={() => this.toggleFilterOpen('last_measurement')}
                  >
                    <CrossSvg width={30} height={30} fill="#999B95" />
                  </SvgContainer>
                </FlexContainer>
                <InputField label="From" placeholder="Select" readOnly value={this.state.customDayFrom ? this.state.customDayFrom.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalender('from')} />
                <InputField label="To" placeholder="Select " readOnly value={this.state.customDayTo ? this.state.customDayTo.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalender('to')} />
                <Button
                  text
                  onClick={this.clearDate}
                  disabled={!this.state.customDayFrom && !this.state.customDayTo}
                >
                  CLEAR
                </Button>
            </DateSelector>)
            }
          </FlexContainer>
          </FilterV2>
        );
      case 'firmware':
        return (
          <FilterV2
            className="filter-container"
            title="Firmware"
            value={firmwareTextValue}
            open={this.state.filtersOpen.firmwares}
            toggleOpen={() => this.toggleFilterOpen('firmwares')}
            onChangeSearch={this.searchFirmwareFilter}
            searchValue={this.state.searchParams.firmware_search_key}
            viewAllItems={this.state.allFirmwares}
            selectedItems={this.state.searchParams.firmwares}
            selectGivenItems={selected =>
              this.selectGivenItems(selected, 'firmwares')
              }
            itemName="firmwares"
          >
          {this.renderFilterOptions(this.state.firmwares, 'firmwares')}
          </FilterV2>
        );
      case 'account':
        if (this.props.adminDashboard.adminOpen) {
          return (
              <FilterV2
                className="filter-container"
                title="Account"
                value={accountTextValue}
                open={this.state.filtersOpen.accounts}
                toggleOpen={() => this.toggleFilterOpen('accounts')}
                onChangeSearch={this.searchAccountFilter}
                searchValue={this.state.searchParams.account_search_key}
                viewAllItems={this.state.allAccounts}
                selectedItems={this.state.searchParams.accounts}
                selectGivenItems={selected =>
                  this.selectGivenItems(selected, 'accounts')
              }
                itemName="account"
              >
              {this.renderFilterOptions(this.state.accounts, 'accounts')}
              </FilterV2>
          );
        }
        return null;

      default:
        return null;
    }
  }


  render() {
    const {
      sorter,
      items,
      selectedSensorList
    } = this.props.sensors;

    const sensorsMetadataLoading = sensorsConstants.GET_SENSORS_METADATA in this.props.loadingReducer ?
      this.props.loadingReducer[sensorsConstants.GET_SENSORS_METADATA] : true;

    const { currentAccount, widgetsMetadata } = this.props;
    const { setSensorsSorter, loadMoreSensors } = this.props.sensorActions;
    const { selectedColumns } = this.state;
    const props = {
      columnSizes: selectedColumns.map(() => 100 / selectedColumns.length),
      headers: selectedColumns.map(column => ({
        label: humanize(column),
        name: column
      })),
      maxHeightAfterScroll: this.state.machineSensors
        ? 'calc(100vh - 225px)'
        : 'calc(100vh - 195px)',
      triggerLoadMore: this.state.machineSensors
        ? 'calc(100vh - 225px)'
        : 'calc(100vh - 195px)',
      hasMore: items.hasMore,
      loading: items.loading || currentAccount.fetching,
      sorterState: sorter,
      setSorter: setSensorsSorter,
      items,
      loadMoreItems: loadMoreSensors,
      ItemComponent: SensorsItem,
      emptyMsg: 'No results found',
      editMode: this.state.editMode,
      columns: selectedColumns
    };
    if (this.props.adminDashboard.adminOpen) {
      props.columnSizes = [...props.columnSizes, 12];
      props.headers = [
        ...props.headers,
        {
          name: 'account_id',
          label: 'Account'
        }
      ];
      props.columns = [...selectedColumns, 'account_id'];
    }
    if (selectedSensorList.length > 0 && !this.props.adminDashboard.adminOpen) {
      props.columnSizes = [100];
      props.columns = ['serial_number'];
      props.headers = [
        {
          name: 'serial_number',
          label: 'Serial Number'
        }
      ];
      props.sorterState = null;
    }

    const { machine_id } = this.props.match.params;

    let filters = ['type', 'battery_level', 'wifi_signal', 'last_reported', 'firmware', 'account', 'other_filters'];
    if (!this.props.adminDashboard.adminOpen && !this.state.machineSensors) {
      filters = ['type', 'area', 'machine', 'battery_level', 'wifi_signal', 'last_reported', 'firmware', 'account', 'other_filters'];
    }

    const chartFilters = this.getChartFilters();

    return (
      <div id="devices-page">
        <MoreOptions>
          <AccountMenuItems />
        </MoreOptions>
        <SearchFilters machineSensors={this.state.machineSensors}>
          {items && (
           <FlexContainer className="filter-misc-items" alignItems="center" paddingTop="10px">
            <FilterCountContainer>{items.total_count}</FilterCountContainer>
           </FlexContainer>
          )}
          {this.props.adminDashboard.adminOpen && !isPartnerAdmin(this.props.user) &&
            (
            <RBAC
              resource={mapComponentToResource.Sensors}
              operation={operations.Create}
              yes={(
            <ButtonTransparent title="Add Device" onClick={this.toggleAddDeviceModel} secondary marginLeft="10px">
              <PlusSvg width="16" height="16" />
            </ButtonTransparent>
              )}
            />
            )
          }
          {!this.props.adminDashboard.adminOpen &&
            (
            <RBAC
              resource={mapComponentToResource.Sensors}
              operation={operations.Create}
              yes={(
              <ButtonTransparent secondary marginLeft="10px">
                <Link title="Create New Sensors" to={Routes.createNewSensors}><PlusSvg width="16" height="16" /></Link>
              </ButtonTransparent>
            )}
            />
            )
          }
          <InputField
            type="text"
            name="device-search-box"
            prefix={<Search />}
            prefixSide="left"
            onChange={e => this.changeFilter('search_key', e.target.value)}
            value={this.state.searchParams.search_key}
            placeholder="Search..."
            searchBox
            clearable
            clearInput={() => this.changeFilter('search_key', '')}
          />

          <DynamicFilters
            bufferSpace={50}
            panes={filters && filters.map(filter => this.renderFilter(filter))}
            showClearFilter={isFilterApplied(this.state.searchParams)}
            clearAllFilters={this.clearAllFilters}
          />

          {!this.props.adminDashboard.adminOpen && (
            <RBAC
              resource={mapComponentToResource.Sensors}
              operation={operations.Update}
              yes={(
                <RBAC
                  resource={mapComponentToResource.Sensors}
                  operation={operations.Update}
                  yes={(
                    <FlexContainer
                      title="Edit Sensors"
                      onClick={() => {
                        this.setState(prevState =>
                          ({ editMode: !prevState.editMode, itemMenuOpen: false }));
                        if (this.props.selectedList.length === 1) return;
                        this.closeDetails();
                      }}
                      marginleft="auto"
                      margintop="auto"
                      marginbottom="auto"
                      className="filter-misc-items"
                    >
                      <EditIcon fill={this.state.editMode ? colors.red : colors.greenXD} />
                    </FlexContainer>
                  )}
                />
              )}
            />
          )}
          <FlexContainer
            title="Edit columns"
            marginleft="16px"
            margintop="auto"
            marginbottom="auto"
            className="filter-misc-items"
          >
            <EditDropdown
              allColumns={allSensorsPageColumns}
              selectedColumns={this.state.selectedColumns}
              toggleColumn={column => this.setState(prevState => (
                prevState.selectedColumns.includes(column) ? ({
                  selectedColumns: prevState.selectedColumns.filter(item => item !== column)
                }) : ({
                  // done this way to maintain order
                  selectedColumns: allSensorsPageColumns.filter(item => (
                    prevState.selectedColumns.includes(item) ||
                    item === column
                  ))
                })
              ), () => this.updateDeviceListLocalStorage('columns', this.state.selectedColumns))}
            />
          </FlexContainer>
        </SearchFilters>
        {currentAccount.error && (
          <div>{currentAccount.error}</div>
        )}
        {!currentAccount.error && (
            <>
              {sensorsMetadataLoading ? (
                <SensorsContainer>
                  <Loading position="absolute" style={{ top: 0, left: 0 }} height="100%" />
                </SensorsContainer>
              ) : (
                <SensorsContainer>
                    <ListContainer
                      width={selectedSensorList.length > 0 && !this.props.adminDashboard.adminOpen ? '30%' : '100%'}
                      minWidth={selectedSensorList.length === 0 && '45rem'}
                    >
                      <List {...props} />
                    </ListContainer>
                    {!this.state.machineSensors && selectedSensorList.length === 0 && (
                      <CollapsibleWidgetsContainer componentName="Sensors">
                        {!widgetsMetadata.loading && !_.isEmpty(widgetsMetadata.object) && (
                          <>
                            <Widget filters={chartFilters} metadata={widgetsMetadata.object.device_reachability_breakdown} width="310px" height="220px" />
                            <Widget filters={chartFilters} metadata={widgetsMetadata.object.device_battery_level_breakdown} width="310px" height="220px" />
                            <Widget filters={chartFilters} metadata={widgetsMetadata.object.device_wifi_strength_breakdown} width="310px" height="220px" />
                          </>
                        )}
                      </CollapsibleWidgetsContainer>
                    )}
                  {selectedSensorList.length > 0 && !this.props.adminDashboard.adminOpen && (
                    <SensorsDetails
                      sensorsList={items.object.filter(item =>
                        selectedSensorList.includes(item.serial_number)
                      )}
                      closeDetails={this.closeDetails}
                      editMode={this.state.editMode}
                      machines={this.state.allMachines}
                      machineSensors={this.state.machineSensors}
                      machine_id={parseInt(machine_id, 10)}
                      filters={this.state.filtersOpen}
                      searchParams={this.state.searchParams}
                      version={
                        currentAccount.preferences &&
                        currentAccount.preferences.measurement_version
                      }
                      utcOffset={
                        currentAccount.preferences &&
                        currentAccount.preferences.timezone.utc_offset
                      }
                      appliedFilters={_.cloneDeep(this.state.searchParams)}
                      removeAppliedFilters={this.removeAppliedFilters}
                    />
                  )}
                </SensorsContainer>
              )}
            </>
        )}
         {selectedSensorList.length > 0 && this.props.adminDashboard.adminOpen && (
              <FooterFlex
                justifyContent="space-between"
                width={this.props.hierarchyView ? '74.9%' : '100%'}
              >
                <FlexContainer>
                  <Label fontSize="14px"> {selectedSensorList.length} &nbsp;</Label>
                  <Label fontSize="14px" fontWeight="300"> {selectedSensorList.length === 1 ? 'Device' : 'Devices'} selected</Label>
                </FlexContainer>
                <FlexContainer>
                <RBAC
                  resource={mapComponentToResource.Sensors}
                  operation={operations.Update}
                  yes={(
                        <>
                        {items.object.filter(item =>
                          selectedSensorList.includes(item.serial_number) && item.account_id === null
                        ).length > 0 && (
                          <Button onClick={this.toggleAssignModel} secondary="#000">Assign To Account</Button>
                        )}
                        {items.object.filter(item =>
                          selectedSensorList.includes(item.serial_number) && item.account_id !== null
                        ).length > 0 && (
                          <Button onClick={this.toggleUnassignModel} secondary="#000">Unassign Device</Button>
                        )}
                        </>
                      )}
                />
                </FlexContainer>
              </FooterFlex>
         )}
         {this.state.assignModelOpen && (
           <AssignModel
             close={this.toggleAssignModel}
             assign={this.assignDevicesToAccount}
             text={
              items.object.filter(item => selectedSensorList.includes(item.serial_number) && item.account_id === null).length === 1
                ?
                items.object.filter(item => selectedSensorList.includes(item.serial_number) && item.account_id === null)[0].serial_number
                :
                `${items.object.filter(item => selectedSensorList.includes(item.serial_number) && item.account_id === null).length} devices`
              }
           />
         )}
         {this.state.unassignModelOpen && (
           <UnassignSensorAlertPrompt
             message={
              items.object.filter(item => selectedSensorList.includes(item.serial_number) && item.account_id !== null).length === 1 ?
                `Are you sure you want to unassign ${items.object.filter(item => selectedSensorList.includes(item.serial_number) && item.account_id !== null)[0].serial_number} ?`
                :
                `Are you sure you want to unassign ${items.object.filter(item => selectedSensorList.includes(item.serial_number) && item.account_id !== null).length} devices ?`
              }
             onCancel={this.toggleUnassignModel}
             onProceed={this.unassignDevice}
           />
         )}
          {this.state.addDeviceModelOpen && (
            <AddDeviceModel
              close={this.toggleAddDeviceModel}
              add={this.addDevice}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sensors: state.sensors,
  currentAccount: state.currentAccount,
  user: state.user.user,
  SensorModels: state.settings.SensorModels,
  selectedList: state.sensors.selectedSensorList,
  adminDashboard: state.adminDashboard,
  widgetsMetadata: state.widgets.metadata,
  loadingReducer: state.loadingReducer,
  primaryColor: state.companyReducer.partner.theme.primaryColor,
  title: state.companyReducer.partner.title
});

const mapDispatchToProps = dispatch => ({
  sensorActions: bindActionCreators(sensorActions, dispatch),
  machineDetailsActions: bindActionCreators(machineDetailsActions, dispatch),
  modelActions: bindActionCreators(modelActions, dispatch),
  widgetActions: bindActionCreators(widgetActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(Sensors)));
