import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';
import { humanize } from 'common/helpers/humanize';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Button, { AnimatedButton } from 'common/components/atoms/Button';
import LoadingSvg from 'common/images/LoadingSvg';
import InputField from 'common/components/atoms/InputField';
import Label_T from 'common/typography/Label/Label';
import * as assetDetailsActions from '../../actions/assetDetails.actions';
import * as chartsUpdateActions from '../../actions/chartUpdate.actions';
import * as utils from '../../utils/assetHierarchyUtils';
import Footer from '../molecules/Footer';


const RowContainer = styled(FlexContainer).attrs({ justifyContent: 'space-between' })`
  ${InputField} {
    flex-basis: 49%;
    ${Label} {
      color: ${props => props.theme.colors.greyXXD};
      ${props => props.theme.fonts.boldLabel};
      margin-bottom: 6px
    }
  }
`;

const Label = styled(Label_T)`
color: ${props => props.theme.colors.greyXXD};
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 12px;
  display: block;
`;

const LoadingContainer = styled.div`
  margin: auto;
`;

const TagNameInput = styled(InputField)`
  width: 50%;
  margin: 2em 0 3em;
  label {
    color: ${props => props.theme.colors.greyXXD};
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    line-height: normal;
  display: block;
  }
  > ${FlexContainer} {
    font-size: 12px;
    margin-top: 2em;
  }
`;

const MContent = styled.div`
  padding: 1em 2em;
`;

const fontSettings = `
  font-family: "Petasense Open Sans";
  font-size: 16px;
  font-weight: 400;
`;

class AddTagModal extends Component {
  constructor(props) {
    super(props);
    this.sourceOptions = [
      { text: 'Other', value: 'other' },
      { text: 'Petasense', value: 'petasense' }
    ];
    this.state = {
      id: this.props.tag.id,
      tagName: this.props.tag.name,
      nameError: undefined,
      saveEnabled: false,
      description: this.props.tag.description,
      type_id: this.getTypeId(),
      template_id: this.getTemplateId(),
      data_source: this.props.tag.data_source
    };
  }


  componentDidMount = () => {
    if (!this.props.configMetadata) {
      this.props.assetDetailsActions.getChartsConfigMetadata();
    }
    const { siteId, machineId, accountsInfo } = this.props;
    const tags = utils.getTagsList(machineId, siteId, accountsInfo);
    if (!tags) {
      this.props.assetDetailsActions.getTags(machineId, siteId);
    }
  }

  getTypeId = () => {
    const {
      metadata: { tag_types },
      tag: { type }
    } = this.props;

    if (tag_types) {
      const tag_type = _.find(tag_types, { text: humanize(type) });
      if (tag_type) return tag_type.value;
    }
    return undefined;
  }

  getTemplateId = () => {
    const {
      metadata: { tag_templates },
      tag: { type, template }
    } = this.props;

    if (tag_templates && type) {
      const template_obj = _.find(tag_templates[type], { text: humanize(template) });
      if (template_obj) return template_obj.value;
    }
    return undefined;
  }

  componentDidUpdate = () => {
    const {
      tag: {
        name,
        description,
        data_source,
        id
      }
    } = this.props;

    const { type_id, template_id } = this.state;

    if (this.state.id !== id && !this.props.loading) {
      const tag_type_id = this.getTypeId();
      const tag_template_id = this.getTemplateId();

      if (!type_id || type_id !== tag_type_id) {
        this.setState({ type_id: tag_type_id });
      }

      if (!template_id || template_id !== tag_template_id) {
        this.setState({
          template_id: tag_template_id
        });
      }

      this.setState({
        id,
        tagName: name,
        description,
        data_source
      });
    }

    const propDetails = {
      tagName: name,
      description,
      type_id: this.getTypeId(),
      template_id: this.getTemplateId()
    };

    const stateDetails = {
      tagName: this.state.tagName,
      description: this.state.description,
      template_id: this.state.template_id,
      type_id: this.state.type_id
    };

    if (_.isEqual(propDetails, stateDetails) && propDetails.type_id) {
      if (this.state.saveEnabled) this.setState({ saveEnabled: false });
    } else if (!this.state.saveEnabled) {
      this.setState({ saveEnabled: true });
    }
  }

  onClickSave = () => {
    const { id, tagName, description, template_id } = this.state;
    const { machineId, siteId } = this.props;

    if (_.isEmpty(tagName)) {
      toastr.error('Tag Name cannot be empty');
      return;
    }

    const params = {
      tag_template_id: template_id,
      name: tagName,
      description
    };

    this.setState({ loading: true });

    this.props.chartsUpdateActions.updateTag(id, params).then(
      () => {
        toastr.success('Tag updated successfully');
        this.props.assetDetailsActions.getTags(machineId, siteId);
        this.setState({ loading: false });
        this.close();
      },
      (err) => {
        this.setState({ loading: false });
        toastr.error(err);
      }
    );
  }

  updateField = (name, value) => {
    this.setState({
      [name]: value
    });
  }

  close = () => {
    const existingChart = this.props.config && this.props.config.config_id;
    if (existingChart) {
      this.props.close();
    } else this.props.close();
  }

  renderFooter = () => (
    <>
      <Button
        cancel
        onClick={this.close}
      >
      Cancel
      </Button>
      <AnimatedButton
        disabled={!this.state.saveEnabled || !this.state.tagName}
        onClick={this.onClickSave}
        loading={this.state.loading}
      >
      Save
      </AnimatedButton>
    </>
  );


  render() {
    const { loading, data_source } = this.state;
    const { type } = this.props.tag;

    return (
      <>
        {(!this.props.configMetadata || this.props.configMetadata.loading) ? (
          <LoadingContainer>
            <LoadingSvg />
          </LoadingContainer>
        ) : (
          <FlexContainer direction="column" justifyContent="space-between" flexGrow={1}>
            <MContent>
              <TagNameInput
                type="text"
                label="Tag name"
                placeholder="Enter the name of the tag"
                value={this.state.tagName}
                onChange={e => this.setState({ tagName: e.target.value })}
                maxLength={60}
                error={this.state.nameError}
              />
              <RowContainer>
              <InputField
                type="textarea"
                placeholder="Description"
                height="100px"
                label="Description"
                value={this.state.description}
                onChange={e => this.setState({ description: e.target.value })}
                disabled={loading || data_source === 'petasense'}
              />
              </RowContainer>
              <RowContainer>
                <InputField
                  label="Source"
                  type="select"
                  name="source"
                  options={this.sourceOptions}
                  placeholder="Click to select source"
                  value={data_source}
                  onChange={(e, { value }) => this.updateField('data_source', value)}
                  disabled
                />
              </RowContainer>
              <RowContainer>
                <InputField
                  label="Tag Type"
                  type="select"
                  name="tag_type"
                  options={this.props.metadata && this.props.metadata.tag_types}
                  placeholder="Click to select a tag type"
                  value={this.state.type_id}
                  onChange={(e, { value }) => this.updateField('type_id', value)}
                  disabled
                />
              </RowContainer>
              <RowContainer>
                <InputField
                  label="Tag template"
                  type="select"
                  name="tag template"
                  options={this.props.metadata && this.props.metadata.tag_templates[type]}
                  placeholder="Click to select tag template options"
                  value={this.state.template_id}
                  onChange={(e, { value }) => this.updateField('template_id', value)}
                  disabled={loading}
                  fontSettings={fontSettings}
                  optionFontSettings={fontSettings}
                  search
                />
              </RowContainer>
            </MContent>
            <Footer footer={this.renderFooter} showWidth={this.props.showWidth} />
          </FlexContainer>
        )}
      </>
    );
  }
}

AddTagModal.propTypes = {
  close: PropTypes.func.isRequired,
  metadata: PropTypes.object
};

AddTagModal.defaultProps = {
  metadata: {}
};

const mapStateToProps = state => ({
  machineId: state.breadcrumb.machine.id,
  siteId: state.breadcrumb.site.id,
  configMetadata: state.assetHierarchyReducer.assetInfo.configMetadata,
  accountsInfo: state.assetHierarchyReducer.assetInfo.sites,
  metadata: state.tagTemplates.templates.metadata,
  loading: state.tagTemplates.templates.loading
});

const mapDispatchToProps = dispatch => ({
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch),
  chartsUpdateActions: bindActionCreators(chartsUpdateActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(AddTagModal));
