import { ASSET_TYPE } from "common/charts/constants";
import { IMPERIAL_UNIT_SYSTEM } from "common/constants";

export const ENVELOPE_ACTIONS = {
  GET_TAG_ENVELOPE_SPECTRUMS_FAILURE: 'GET_TAG_ENVELOPE_SPECTRUMS_FAILURE',
  GET_TAG_ENVELOPE_SPECTRUMS_REQUEST: 'GET_TAG_ENVELOPE_SPECTRUMS_REQUEST,',
  GET_TAG_ENVELOPE_SPECTRUMS_SUCCESS: 'GET_TAG_ENVELOPE_SPECTRUMS_SUCCESS',

  EXCLUDE_ENVELOPE_SPECTRUMS: 'EXCLUDE_ENVELOPE_SPECTRUMS'
};

export const ENVELOPE_SETTING_KEYS = ['base_graph', 'threshold', 'spectral_window', 'x_scale'];

export const SPECTRAL_ENVELOPE_KEYS = ['envelope_data'];

export const X_SCALE = {
  ORDERS: 'orders',
  FREQUENCY: 'frequency'
};

export const BASE_GRAPH_OPTIONS = {
  AVG_OF_LAST_DAYS: 'last_days',
  AVG_OF_LAST_READINGS: 'last_measurements',
  BASELINE: 'from_baseline',
  CURRENTLY_VISIBLE: 'from_timestamp'
};

export const THRESHOLD_OPTIONS = {
  PERCENT_ABOVE_BASE_GRAPH: 'percent',
  STD_ABOVE_BASE_GRAPH: 'standard_deviation'
};

export const PEAK_MIN_DISTANCE = {
  X_SCALE_ORDERS: 0.01,
  X_SCALE_FREQUENCY: 0.5
};

export const MIN_CAUTION_LEVEL = {
  METRIC: 0.1,
  IMPERIAL: 0.01
};

export const SPECTRAL_WINDOW_TYPE = {
  FIXED: 'fixed',
  FIXED_CENTER_FREQUENCY: 'fixed_center_frequency',
  PERCENT_CENTER_FREQUENCY: 'percent_center_frequency'
};

export const SPECTRAL_WINDOW_TYPE_OPTIONS = [
  { text: 'Fixed', value: SPECTRAL_WINDOW_TYPE.FIXED },
  { text: 'Center Frequency (Fixed Width)', value: SPECTRAL_WINDOW_TYPE.FIXED_CENTER_FREQUENCY },
  { text: 'Center Frequency (Percentage)', value: SPECTRAL_WINDOW_TYPE.PERCENT_CENTER_FREQUENCY },
];

export const AVG_DAYS_OPTIONS = [
  { text: '7 days', value: 7 },
  { text: '15 days', value: 15 },
  { text: '30 days', value: 30 }
];

export const AVG_READINGS_OPTIONS = [
  { text: '5 readings', value: 5 },
  { text: '10 readings', value: 10 },
  { text: '15 readings', value: 15 }
];

export const ENVELOPE_STATES = {
  CREATE: 'create',
  EDIT: 'edit',
  RESET: 'reset',
  VIEW: 'view'
};

export const spectralWindowSettings = (xScale, unitSystem) => ({
  min_caution_level: unitSystem === IMPERIAL_UNIT_SYSTEM ? MIN_CAUTION_LEVEL.IMPERIAL : MIN_CAUTION_LEVEL.METRIC,
  type: SPECTRAL_WINDOW_TYPE.FIXED_CENTER_FREQUENCY,
  width: xScale === 'orders' ? 0.2 : 10,
});

export const initialEnvelopeSettings = (xScale, assetType, unitSystem) => ({
  base_graph:
    assetType === ASSET_TYPE.FIXED_SPEED ? ({
      type: BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE,
      value: ''
    }) : ({
      type: BASE_GRAPH_OPTIONS.AVG_OF_LAST_DAYS,
      value: AVG_DAYS_OPTIONS[0].value
    }),
  threshold: {
    starting_frequency: xScale === 'orders' ? 0.2 : 15,
    type: THRESHOLD_OPTIONS.PERCENT_ABOVE_BASE_GRAPH,
    value: 20
  },
  spectral_window: {
    ...spectralWindowSettings(xScale, unitSystem)
  }
});

export const initialEnvelopeSettingsForFixedSpeed = {
  base_graph: {
    type: BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE,
    value: ''
  },
  threshold: {
    starting_frequency: 15,
    type: THRESHOLD_OPTIONS.PERCENT_ABOVE_BASE_GRAPH,
    value: 20
  },
  spectral_window: {
    ...spectralWindowSettings
  }
};

export const initialEnvelopeSettingsForVFD = {
  base_graph: {
    type: BASE_GRAPH_OPTIONS.AVG_OF_LAST_DAYS,
    value: AVG_DAYS_OPTIONS[0].value
  },
  threshold: {
    starting_frequency: 0.2, // in orders
    type: THRESHOLD_OPTIONS.PERCENT_ABOVE_BASE_GRAPH,
    value: 20
  },
  spectral_window: {
    ...spectralWindowSettings
  }
};

// measurement units in metric and frequency units in Hz
export const RANGES = {
  MIN_CAUTION_LEVEL: {
    vibration: [0.01, 20],
    current: [-65, 0]
  },
  PERCENT_ABOVE_BASE_GRAPH: [0, 500],
  SPECTRAL_WINDOW_WIDTH_CENTER_FREQ: [5, 100], // in percent
  SPECTRAL_WINDOW_WIDTH_FIXED_VFD: [0.01, 2], // in orders
  SPECTRAL_WINDOW_WIDTH_FIXED_FIXED_SPEED: [0.1, 100],
  STARTING_FREQUENCY_VFD: [0, 2], // in orders
  STARTING_FREQUENCY_FIXED_SPEED: [0, 100],
  STD_ABOVE_BASE_GRAPH: [0, 20]
};

export const initialError = {
  base_graph: '',
  min_caution_level: '',
  percent_above_base_graph: '',
  spectral_window_width: '',
  starting_frequency: '',
  std_deviation_above_base_graph: ''
};

export const MESSAGE = {
  BASE_GRAPH_TYPE_CURRENTLY_VISIBLE: "Currently visible reading can't be copied to other speed ranges, ",
  CHANGE_BASE_GRAPH_SETTING: 'change base graph setting',
  FETCH_SPECTRUM_DATA_ERROR: "Settings can't be copied for speed ranges ",
  NO_SETTINGS_SET: 'No settings set for this speed range',
  NO_DATA_AVAILABLE: 'No data available for this speed range',
  COPIED_SETTINGS: 'Successfully copied settings for speed ranges',
  COPYING_SETTINGS: 'Copying Settings...'
};
