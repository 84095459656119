import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from './Input';
import TextArea from './TextArea';
import Label from '../../typography/Label/Label';
import Error from '../../typography/Error/Error';
import Dropdown from './Dropdown';
import ToggleButton from './ToggleButton';
import Typeahead from './Typeahead';
import Checkbox from './Checkbox';
import Radiobutton from '../molecules/Radiobutton';
import FlexContainer from './FlexContainer';
import RangeSlider from '../molecules/RangeSlider';

const Container = styled.div`
  display: flex;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '1.5em')};
  ${props => (props.labelSide ? `
    font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
    font-family: "Petasense Open Sans";
    flex-direction: row;
    align-items: center;
    color: ${props => props.theme.colors.greyXXD};
    flex-wrap: wrap;
  ` : `
    flex-direction: column;
  `)};
  ${Error} {
    flex-basis: 100%;
  }
`;

const InputField = ({
  className,
  error,
  input,
  label,
  labelSide,
  type,
  color,
  fontWeight,
  marginBottom,
  fluid,
  selection,
  ...rest
}) => (
  <Container id="input-container" labelSide={labelSide} className={className} marginBottom={marginBottom}>
    {!!label && type !== 'checkbox' && (
      <Label labelSide={labelSide} fontWeight={fontWeight} color={color}>
        {label}
      </Label>
    )}
    {!!label && type === 'checkbox' && (
      <Label
        onClick={rest.onClick || (() => {})}
        labelSide={labelSide}
        fontWeight={fontWeight}
        color={color}
        hover={!!rest.onClick}
      >
        {label}
      </Label>
    )}
    {/* wrap input and error message to eachother */}
    <FlexContainer>
      {type === 'select' && (
        <Dropdown
          fluid={fluid}
          selection={selection}
          error={error}
          {...rest}
        />
      )}
      {type === 'boolean' && (
        <ToggleButton
          error={error}
          {...rest}
        />
      )}
      {type === 'checkbox' && (
        <Checkbox
          error={error}
          {...rest}
          {...input}
        />
      )}
      {type === 'typeahead' && (
        <Typeahead
          error={error}
          {...rest}
        />
      )}
      {type === 'textarea' && (
        <TextArea
          error={error}
          {...rest}
        />
      )}
      {(type === 'text' || type === 'number' || type === 'password') && (
        <Input
          type={type}
          error={error}
          {...rest}
        />
      )}
      {(type === 'date') && (
        <Input
          type={type}
          error={error}
          {...rest}
        />
      )}
      {(type === 'radio') && (
        <Radiobutton
          error={error}
          {...rest}
        />
      )}
      {(type === 'range') && (
        <RangeSlider
          {...rest}
        />
      )}
    </FlexContainer>
    {!!error && (
        <Error name="input_field_error">
          {error}
        </Error>
    )}
  </Container>
);

InputField.propTypes = {
  className: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  labelSide: PropTypes.oneOf(['right', 'left', false]),
  color: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'boolean', 'select', 'checkbox', 'typeahead', 'textarea', 'password', 'radio', 'date', 'range']),
  fluid: PropTypes.bool,
  selection: PropTypes.bool,
  autoComplete: PropTypes.string
};

InputField.defaultProps = {
  error: undefined,
  color: undefined,
  label: undefined,
  labelSide: false,
  type: 'text',
  fluid: true,
  selection: true,
  autoComplete: 'off'
};

InputField.displayName = 'InputField';

export default styled(InputField)``;
