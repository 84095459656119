import { Component } from 'react';

import { LineChart as LineChartHoc } from '../hoc/LineChart';

class LineChart extends Component {
  render() {
    return null;
  }
}

LineChart.displayName = 'LineChart';

export default LineChartHoc(LineChart);
