import React from 'react';

import FlexContainer from 'common/components/atoms/FlexContainer';
import { escapeRegExp } from 'common/utils';
import Message from '../molecules/Message';
import SearchItemTitle from '../atoms/SearchItemTitle';
import SearchNodeItem from '../molecules/SearchNodeItem';
import * as utils from '../../utils/assetHierarchyUtils';

class SearchList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: [],
      selected: {
        id: null,
        node_type: '',
        node_name: ''
      }
    };
    this.flatHierarchy = utils.flattenHierarchy(this.props.treeNodes);
    this.singleClickNodeTypes = ['site', 'machine', 'config'];
    this.searchableNodeTypes = ['area', 'machine'];
  }

  componentDidMount() {
    this.setMatches();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.searchKey !== this.props.searchKey ||
      prevProps.selectedNodeType !== this.props.selectedNodeType
    ) {
      this.setMatches();
    }
  }

  setMatches = () => {
    const { searchKey, selectedNodeType } = this.props;
    const regex = new RegExp(escapeRegExp(searchKey), 'gi');
    const matches = this.flatHierarchy.filter((node) => {
      let isMatch = this.searchableNodeTypes.includes(node.node_type) && node.node_name.match(regex);
      if (selectedNodeType) {
        isMatch = isMatch && node.node_type === selectedNodeType;
      }
      return isMatch;
    });
    this.setState({ matches });
  }

  getFirstClickableNode = (node) => {
    let clickableNode = [];
    if (node.node_type === 'area') {
      if (node.children.length > 0) {
        clickableNode.push(node.children[0]);
      }
    } else {
      clickableNode = utils.getFirstConfig(node.children);
    }
    return clickableNode;
  }

  handleDoubleClick = (node) => {
    this.props.clearSearch();
    this.props.clearFilter();
    if (!this.singleClickNodeTypes.includes(node.node_type)) {
      const selectNode = this.getFirstClickableNode(node);
      if (selectNode.length > 0) {
        this.props.switchNode(selectNode[0].id, selectNode[0].node_type, true);
      }
    }
    this.props.scrollNodeInView(node.node_type, node.id);
  }

  handleSingleClick = (node) => {
    if (!this.singleClickNodeTypes.includes(node.node_type)) return;
    const { selected: { id, node_type } } = this.state;
    if (id === node.id && node.node_type === node_type) return;
    this.props.switchNode(node.id, node.node_type, true);
    this.setState({
      selected: {
        ...node
      }
    });
  }

  render() {
    const { matches, selected: { id, node_type } } = this.state;
    return (
      <FlexContainer direction="column" id="hierarchy-search-items">
        {matches.length === 0 && (
          <Message message="No results found" />
        )}
        {matches.map((node) => {
          const selected = node.id === id && node.node_type === node_type;
          return (
          <SearchNodeItem
            className="hierarchy-search-item"
            onClick={() => this.handleSingleClick(node)}
            onDoubleClick={() => this.handleDoubleClick(node)}
            noBorder
            key={`${node.node_type}_${node.id}`}
          >
            <FlexContainer key={`${node.node_type}_${node.id}`}>{utils.getSvg(node.node_type, selected)}</FlexContainer>
            <SearchItemTitle className="hierarchy-search-item-title" selected={selected}>
              {node.node_name}
            </SearchItemTitle>
          </SearchNodeItem>
          );
        })}
      </FlexContainer>
    );
  }
}


export default SearchList;
