import React from 'react';
import styled from 'styled-components';
import colors from 'common/styles/colors';
import { FlexContainer } from '../atoms';

const InputContainer = styled(FlexContainer)`
& .slider {
  -webkit-appearance: none;
  background: ${colors.grey};
  height: 4px;
  width: 120px;
  margin-left: 10px;
}

// -webkit- for Chrome
& .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${props => props.theme.primaryColor};
  cursor: pointer;
}

// -moz- for Firefox
& .slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => props.theme.primaryColor};
  cursor: pointer;
}

& .prefix-label {
  font-weight: 600;
  font-size: 20px;
  color: ${colors.greyXD};
}
`;

function RangeSlider({
  value,
  onChange,
  min,
  max,
  prefixLabel
}) {
  return (
    <InputContainer alignItems="center">
      {prefixLabel && <span className="prefix-label">{prefixLabel}</span>}
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        className="slider"
        id="myRange"
        onChange={onChange}
      />
    </InputContainer>
  );
}

export default RangeSlider;
