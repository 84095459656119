import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'common/components/organisms/Modal';
import RepairDetails from './RepairDetails';


function RepairDetailsModal(props) {
  return (
    <Modal
      title={[
        props.isEditMode ? props.repairDetailsMachineName : props.machineDetailsMachineName,
        'Repairs',
        props.isEditMode ? 'Edit Repair' : 'Create Repair'
      ]}
      padding="0"
      outerPadding="0"
      headerPadding="1rem"
      headerRadius="5px"
      width="60rem"
      close={props.closeModal}
      showHeaderBorder
    >
      <RepairDetails {...props} />
    </Modal>
  );
}

RepairDetailsModal.propTypes = {
  isEditMode: PropTypes.bool,
  closeModal: PropTypes.func,
};

RepairDetailsModal.defaultProps = {
  isEditMode: false,
  closeModal: () => {},
};

const mapStateToProps = state => ({
  // when creating, take from machineDetails reducer
  machineDetailsMachineName: state.machineDetails.details.object && state.machineDetails.details.object.name,
  // when editing, take from fetched repair details
  repairDetailsMachineName: state.repairModal.openRepairDetails.machine_name,
});

export default connect(mapStateToProps, null)(RepairDetailsModal);
