import { machineDetailsConstants } from '../machineDetails.constants';

export function machineDetails(state = {}, action) {
  switch (action.type) {
    case machineDetailsConstants.GET_MACHINE_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case machineDetailsConstants.GET_MACHINE_DETAILS_SUCCESS: {
      return {
        object: action.machine,
        loading: false
      };
    }
    case machineDetailsConstants.GET_MACHINE_DETAILS_FAILURE:
      return {
        error: action.error.message,
        loading: false
      };
    case machineDetailsConstants.UPDATE_MACHINE_NAME:
      return {
        ...state,
        object: {
          ...state.object,
          name: action.name
        }
      };
    default:
      return state;
  }
}


export function completionStatus(state = {}, action) {
  switch (action.type) {
    case machineDetailsConstants.GET_MACHINE_COMPLETION_STATUS_REQUEST: {
      return {
        loading: true,
      };
    }
    case machineDetailsConstants.GET_MACHINE_COMPLETION_STATUS_SUCCESS: {
      return {
        data: action.completionStatus,
        loading: false
      };
    }
    case machineDetailsConstants.GET_MACHINE_COMPLETION_STATUS_FAILURE: {
      return {
        error: action.error.message,
      };
    }
    default:
      return state;
  }
}
