import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { Paginate } from '../../hoc/Paginate';
import ListHeaders from '../ListHeaders/ListHeaders';
import Loading from '../atoms/Loading';

const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border: ${props => props.border ? props.border : '0px'};
  background-color: transparent;
  width: 100%;
  position: relative;
  table-layout: fixed;
  ${Loading} {
    position: absolute;
  }
  padding: ${props => props.padding ? props.padding : '0px'};
  ${props => props.noBottomBorder ?
    `tbody>tr {
      &:last-of-type {
        border-bottom: none;
      }
    }` : ''}
`;

const ScrollContainer = styled.div`
  ${(props) => {
    let styles = '';
    if (props.height) {
      styles += `
        overflow: auto;
        ::-webkit-scrollbar-track {
          background-color: transparent;
        }

        ::-webkit-scrollbar {
          width: 5px;
        }        ::-webkit-scrollbar-thumb {
          background-color: #E5E8E1;
        }
        `;
    }
    if (props.contentPadding) {
      styles += `padding: ${props.contentPadding};`;
    }
    if (props.emptyMsg) {
      styles += `
          max-height: ${props.height};
        `;
    } else {
      styles += `height: ${props.height}`;
    }
    return styles;
  }}
  `;

const MsgContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em;
  margin: 2em;
  border: 2px dotted ${props => props.theme.colors.lightGray};
  border-radius: ${props => props.theme.utils.borderRadius};
  span {
    color: ${props => props.theme.colors.lightGray};
    font-weight: bold;
  }
  `;

class List extends Component {
  constructor() {
    super();
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    if (this.scrollRef.current) {
      this.scrollRef.current.addEventListener('scroll', this.handleScroll);
    }
    window.addEventListener('scroll', this.windowScroll);
  }

  handleScroll = () => {
    if ((this.scrollRef.current.scrollHeight - this.scrollRef.current.offsetHeight) - this.scrollRef.current.scrollTop < 50) {
      const { loadMoreItems, hasMore, loading } = this.props;
      if (hasMore && !loading) {
        loadMoreItems();
      }
    }
  }

  getSelected = (selectedId, item, selectedOptions) => {
    if (!_.isEmpty(selectedOptions)) {
      return selectedOptions.includes(item.id);
    }

    return selectedId === item.id;
  };

  render() {
    const {
      columnSizes,
      headers,
      headerHeight,
      setSorter,
      sorterState,
      items,
      ItemComponent,
      emptyMsg,
      errorMsg,
      selectedId,
      maxHeightAfterScroll,
      newEntry,
      editMode,
      padding,
      contentPadding,
      noBottomBorder,
      allSelected,
      selectAll,
      toggleResource,
      selectedOptions,
      border
    } = this.props;
    if (newEntry && this.scrollRef.current) {
      this.scrollRef.current.scrollTop = 0;
    }
    let itemsArray = null;
    let disabledIds = null;
    if (items) itemsArray = items.object;
    if (items.disabled) disabledIds = items.disabled; else disabledIds = [];

    const emptyOrError = (items && !items.loading && itemsArray && !itemsArray.length) || (items && !items.loading && items.error && !itemsArray);
    return (
      <Fragment>
        <Table padding={padding} border={border}>
          <thead>
            <ListHeaders
              columnSizes={columnSizes}
              headers={headers}
              headerHeight={headerHeight}
              setSorter={setSorter}
              sorterState={sorterState}
              editMode={editMode}
              allSelected={allSelected}
              selectAll={selectAll}
            />
            {items && items.loading && !items.error && (
              <tr>
                <td>
                  <Loading position="absolute" />
                </td>
              </tr>
            )}
          </thead>
        </Table>
        <ScrollContainer emptyMsg={emptyOrError} innerRef={this.scrollRef} height={maxHeightAfterScroll} contentPadding={contentPadding}>
          {items && itemsArray && (
            <Table noBottomBorder={noBottomBorder} className={!items.loading && 'table-items'} border={border}>
              <tbody>
                {itemsArray.map((item, index) => (
                  <ItemComponent
                    key={index.toString()}
                    editMode={editMode}
                    selected={this.getSelected(selectedId, item, selectedOptions)}
                    disabled={disabledIds.includes(item.id)}
                    columnSizes={columnSizes}
                    item={item}
                    ref={(item) => { this[`item${index}`] = item; }}
                    {...this.props}
                    toggleResource={toggleResource}
                  />
                ))}
              </tbody>
            </Table>
          )}
        </ScrollContainer>
        {items && !items.loading && itemsArray && !itemsArray.length && ((typeof (emptyMsg) === 'function') ? emptyMsg() : (
          <MsgContainer>
            <span>{emptyMsg}</span>
          </MsgContainer>
        ))}
        {items && !items.loading && items.error && !itemsArray && (
          <MsgContainer>
            <span>{errorMsg}</span>
          </MsgContainer>
        )}
      </Fragment>
    );
  }
}

List.propTypes = {
  columnSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string
  })),
  sorterState: PropTypes.object,
  setSorter: PropTypes.func,
  ItemComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  items: PropTypes.object.isRequired,
  emptyMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  errorMsg: PropTypes.string,
  selectedId: PropTypes.number,
  contentPadding: PropTypes.string,
  noBottomBorder: PropTypes.bool,
  allSelected: PropTypes.bool,
  selectAll: PropTypes.func,
  toggleResource: PropTypes.func,
  selectedOptions: PropTypes.arrayOf(PropTypes.number),
  border: PropTypes.string
};

List.defaultProps = {
  headers: [],
  sorterState: null,
  setSorter: () => {},
  errorMsg: 'Unable to load data.',
  selectedId: undefined,
  contentPadding: '0px',
  noBottomBorder: false,
  allSelected: false,
  selectAll: null,
  toggleResource: null,
  selectedOptions: null,
  border: null,
};

export default Paginate(List);
