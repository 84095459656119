import { combineReducers } from 'redux';
import { bearingConstants } from './bearing.constants';

export const bearingReducer = combineReducers({
  query: bearingQuery,
  sorter: bearingSorter,
  paginater: bearingPaginater,
  items: bearingItems,
  selectedBearingId,
  manufacturers,
  newBearingEntry
});

const initialState = {
  loading: false,
  object: [],
  hasMore: false
};

// unable to use initialState, initialState equals udefined
function bearingItems(state = { loading: false, object: [], hasMore: false }, action) {
  switch (action.type) {
    case bearingConstants.GET_BEARING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case bearingConstants.GET_BEARING_SUCCESS: {
      let newBearingArray = null;
      if (action.page === 1) {
        // reset machine arrays if returned result is page 1
        newBearingArray = action.bearings;
      } else {
        newBearingArray = state.object ? [...state.object, ...action.bearings] : action.bearings;
      }

      return {
        hasMore: action.page < action.total_pages,
        object: newBearingArray,
        loading: false
      };
    }
    case bearingConstants.ADD_ROW_TO_TABLE:
      if (state.object) {
        const bearings = state.object;
        bearings.unshift({ ...action.data });
        return {
          hasMore: state.hasMore,
          object: bearings,
          loading: false
        };
      }
      return state;
    case bearingConstants.REMOVE_ROW_FROM_TABLE:
      if (state.object) {
        const index = state.object.findIndex(e => e.id === action.id);
        state.object.splice(index, 1);
        return {
          hasMore: state.hasMore,
          object: state.object,
          loading: false
        };
      }
      return state;
    case bearingConstants.UPDATE_ROW:
      if (state.object) {
        const index = state.object.findIndex(e => e.id === action.data.id);
        state.object[index] = action.data;
        return {
          hasMore: state.hasMore,
          object: state.object,
          loading: false
        };
      }
      return state;
    case bearingConstants.GET_BEARING_FAILURE:
      return {
        ...state,
        hasMore: false,
        error: action.error,
      };
    case bearingConstants.CLEAR_BEARING:
      return initialState;
    default:
      return state;
  }
}

function newBearingEntry(state = false, action) {
  switch (action.type) {
    case bearingConstants.NEW_BEARING_ENTRY:
      return action.state;
    case bearingConstants.UPDATE_ROW:
      return false;
    case bearingConstants.ADD_ROW_TO_TABLE:
      return false;
    default:
      return state;
  }
}

function manufacturers(state = {}, action) {
  switch (action.type) {
    case bearingConstants.GET_ALL_MANUFACTURERS_SUCCESS:
      return action.result;
    case bearingConstants.GET_ALL_MANUFACTURERS_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
}

function selectedBearingId(state = null, action) {
  switch (action.type) {
    case bearingConstants.SELECT_BEARING_ROW:
      if (action.id === state) {
        return null;
      }
      return action.id;
    case bearingConstants.SELECTED_FALSE:
      return null;
    default:
      return state;
  }
}

function bearingSorter(state = {}, action) {
  switch (action.type) {
    case bearingConstants.SET_BEARING_SORTER:
      return {
        sorter: action.sorter
      };
    default:
      return state;
  }
}

function bearingQuery(state = '', action) {
  switch (action.type) {
    case bearingConstants.SET_BEARING_QUERY:
      return action.query;
    default:
      return state;
  }
}

function bearingPaginater(state = { page: 1 }, action) {
  switch (action.type) {
    case bearingConstants.SET_BEARING_PAGINATER:
      return {
        page: action.page,
      };
    default:
      return state;
  }
}
