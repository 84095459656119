import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';
import * as d3 from 'd3';

import Button from 'common/components/atoms/Button';
import FeatureForm from '../../../Machines/MachineDetails/MachineCharts/components/FeatureForm';
import TagChart from '../../../Machines/MachineDetails/MachineCharts/components/TagChart';
import * as spectrumFeaturesActions from '../../actions/spectrumFeatures.actions';
import Footer from '../molecules/Footer';


const FeatureSelectionButtons = styled.div`
  padding-top: 1em;
  padding-left: calc(50px + 2em);
  width: calc(100% + 4em);
  margin-left: -2em;
  border-bottom: 1px solid ${props => props.theme.colors.grey};
`;

const FeatureButton = styled(Button)`
  border: 1px solid transparent;
  border-bottom: none;
  ${props => (props.selectedFeature ? `
    border: 1px solid ${props.theme.colors.grey};
    border-bottom: none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 6px;
      bottom: -3px;
      left: 0;
      background-color: white;
    }
  ` : '')}
`;

const MContent = styled.div`
  padding: 1em 2em 5em;
`;

class FeatureModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      features: [],
      selectedFeature: props.selectedFeature
    };
  }


  componentDidMount() {
    this.componentWillReceiveProps(this.props, {});
  }

  componentWillReceiveProps(nextProps, prevProps = this.props) {
    let newFeatures = _.get(nextProps, 'features');
    const features = _.get(prevProps, 'features');
    if (nextProps.spectrum.spectrum_data && features !== newFeatures) {
      const range = d3.extent(nextProps.spectrum.spectrum_data, d => d.x);
      newFeatures = _.sortBy(newFeatures.items, 'marker');
      let selectedFeature = { ...newFeatures[0] };
      if (this.state.selectedFeature !== undefined) {
        const idx = _.findIndex(newFeatures, (f) => {
          if (f.frequency !== null) {
            if (f.frequency !== parseInt(this.state.selectedFeature.frequency, 10)) return false;
          }
          if (f.frequency_start !== null) {
            if (f.frequency_start !== parseInt(this.state.selectedFeature.frequency_start, 10)) return false;
          }
          if (f.frequency_end !== null) {
            if (f.frequency_end !== parseInt(this.state.selectedFeature.frequency_end, 10)) return false;
          }
          if (f.marker !== this.state.selectedFeature.marker) return false;
          return true;
        });
        if (idx !== -1) {
          selectedFeature = { ...newFeatures[idx] };
        }
      }

      this.setState({
        selectedFeature,
        features: [...newFeatures,
          {
            marker: null,
            type: '',
            freq_units: nextProps.spectrum.freq_units,
            frequency: null,
            frequency_start: null,
            frequency_end: null,
            new: true
          }
        ]
      });
    }
  }

  selectFeature = (feature) => {
    this.setState({
      selectedFeature: feature
    });
  }

  validateFeature = (feature) => {
    const {
      marker, frequency, frequency_start, frequency_end
    } = feature;

    const range = d3.extent(this.props.spectrum.spectrum_data, d => d.x);

    const markerExists = (marker && marker.length > 0);
    if (!markerExists) return false;
    let readyToSubmit;
    if (feature.type === 'range') {
      const endLargerThanStart = frequency_end > frequency_start;
      const endAndStartOverOrZero = (frequency_end >= 0 && frequency_start >= 0);
      const endInRange = (frequency_end >= range[0] && frequency_end <= range[1]);
      const startInRange = (frequency_start >= range[0] && frequency_start <= range[1]);
      readyToSubmit = endLargerThanStart && endAndStartOverOrZero && endInRange && startInRange;
    } else if (feature.type === 'individual') {
      const freqOverOrZero = frequency >= 0;
      const frequencyInRange = (frequency >= range[0] && frequency <= range[1]);
      readyToSubmit = freqOverOrZero && frequencyInRange;
    }
    return readyToSubmit;
  }

  onChangeFeature = (feature) => {
    this.setState({
      selectedFeature: feature
    });
  }

  onSubmitFeature = (feature) => {
    const {
      spectrumFeaturesActions, tag, config
    } = this.props;

    if (this.validateFeature(feature)) {
      if (!feature.id) {
        spectrumFeaturesActions.createSpectrumFeature(tag.id, feature, config.config_id);
      } else {
        spectrumFeaturesActions.updateSpectrumFeature(feature.id, feature, config.config_id);
      }
    } else {
      const selectedIdx = _.findIndex(this.props.features.items, f => f.id === feature.id);
      // if we changed the type they will be different -> use new feature, else use old from props
      // if selectedIdx is -1 we are editing the '+' feature which has not yet been saved
      if (selectedIdx !== -1 && this.props.features.items[selectedIdx].type === feature.type) {
        feature = { ...this.props.features.items[selectedIdx] };
      }
    }
    this.setState({
      selectedFeature: feature
    });
  }

  onDeleteFeature = (feature) => {
    const {
      config, spectrumFeaturesActions
    } = this.props;
    spectrumFeaturesActions.deleteSpectrumFeature(feature.id, config.config_id);
  }

  draggedCallback = (feature) => {
    this.setState({
      selectedFeature: feature
    });
  }

  render() {
    const {
      spectrum, tag, close
    } = this.props;
    const footer = () => (
      <>
        <Button
          danger
          onClick={close}
          right
        >
          {'Close'}
        </Button>
      </>
    );
    return (
      <>
      <MContent>
        {spectrum.spectrum_data && (
          <TagChart
            type="feature"
            data={spectrum.spectrum_data}
            tagId={tag.id}
            expanded
            yTitle="Amplitude"
            yUnit={spectrum.amp_units}
            xTitle="Frequency"
            xUnit={spectrum.freq_units}
            xIsDate={false}
            height="250px"
            xAxisType="linear"
            features={this.state.features}
            featureLabelClick={this.selectFeature}
            mouseClick={() => undefined}
            mouseoverChart={() => undefined}
            doubleClick={() => undefined}
            selectedFeature={this.state.selectedFeature}
            dragCallback={feature => this.onSubmitFeature(feature)}
            draggedCallback={feature => this.draggedCallback(feature)}
          />
        )}
        <FeatureSelectionButtons>
          {this.state.features.map(feature => (
            <FeatureButton
              key={feature.id || '+'}
              selectedFeature={(feature.id === this.state.selectedFeature.id)}
              secondary={(feature.id === this.state.selectedFeature.id) ? this.props.theme.primaryColor : this.props.theme.colors.greyD}
              onClick={() => this.selectFeature(feature)}
            >
              {feature.marker || '+'}
            </FeatureButton>
          ))}
        </FeatureSelectionButtons>
        {this.state.selectedFeature && (
          <FeatureForm
            feature={{
              ...this.state.selectedFeature,
              yLabel: 'Frequency',
              yUnit: this.state.selectedFeature.freq_units
            }}
            onChangeFeature={this.onChangeFeature}
            onSubmitFeature={this.onSubmitFeature}
            onDeleteFeature={this.onDeleteFeature}
          />
        )}
        <Footer footer={footer} showWidth={this.props.showWidth} />
      </MContent>
      {/* <Footer footer={footer} showWidth={this.props.showWidth} /> */}
      </>
    );
  }
}

FeatureModal.propTypes = {
  selectedFeature: PropTypes.object
};

FeatureModal.defaultProps = {
  selectedFeature: undefined
};

const mapDispatchToProps = dispatch => ({
  spectrumFeaturesActions: bindActionCreators(spectrumFeaturesActions, dispatch)
});

export default connect(null, mapDispatchToProps)(withTheme(FeatureModal));
