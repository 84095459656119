import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Link from 'common/components/atoms/Link';
import { Button } from 'common/components/atoms';
import LoadingSvg from 'common/images/LoadingSvg';
import { checkEmpty, checkEmail, renderInputField } from '../../common/OnboardingComponents/form-fields';
import InputField from '../../common/components/atoms/InputField';

const CheckboxField = styled(InputField).attrs({ type: 'checkbox' })`
  label {
    color: ${props => props.theme.colors.black};
    font-weight: 300;
  }
`;

const SignInButton = styled(Button)`
  width: 100%;
  margin: 3px 0 10px;
`;

class LoginForm extends React.Component {
  getSSORedirectState = () => {
    const { location } = this.props;
  
    // First try to get from state (client-side navigation), triggered by PrivateRoute
    if (location.state) {
      return {
        pathname: '/sso',
        state: location.state
      };
    }

    // If no state, but we have pathname/search, use those, triggered when direct url navigation happens
    if (location.pathname !== '/login' && location.pathname !== '/sso') {
      return {
        pathname: '/sso',
        state: {
          pathname: location.pathname,
          search: location.search
        }
      };
    }

    // Default case - no destination specified
    return {
      pathname: '/sso'
    };
  };

  render() {
    const { loggingIn, handleSubmit } = this.props;
    return (
      <form name="form" onSubmit={handleSubmit}>
        <Field name="username" component={renderInputField} type="text" validate={[checkEmpty, checkEmail]} label="Email" />
        <Field name="password" component={renderInputField} type="password" validate={[checkEmpty]} label="Password" />
        <Field
          name="remember_me"
          component={CheckboxField}
          label="Remember me"
          labelSide="right"
        />

        <div className="form-group">
          {/* eslint-disable */}
          <SignInButton  id="LoginPage-Button-SignIn">Sign in</SignInButton>
          {loggingIn && (
            <div className="ps-align-center" id="LoginPage-Loader">
              <LoadingSvg/>
            </div>
          )
          }
          <Link to={this.getSSORedirectState()}>
            Log in with SSO
          </Link>
          <Link style={{width: '100%'}}to="/forgot_password" id="LoginPage-Link-ForgotPassword">Forgot Password?</Link>

          <div className="warning-msg" id="LoginPage-Message-Warning">
            <p>Warning:</p>
            <p>
            This system is restricted to authorized users for business purposes only.
            Unauthorized access or use is a violation of company policy and the law.
            This system may be monitored for administrative and security reasons.
            By proceeding, you acknowledge that (1) you have read and understand
            this notice and (2) you consent to the system monitoring.
            </p>
          </div>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
  };
}

const createReduxForm = reduxForm({ form: 'Login' });

export default connect(mapStateToProps)(withRouter(createReduxForm(LoginForm)));
