import { axiosInstance, ENDPOINT } from '../../common/constants';
import { handleResponse, humanize } from '../../common/helpers';
import { machinesAnalyticsConstants, machinesAnalyticsMetadata, customLabels } from './machinesAnalytics.constants';
import { getSearchParamFilters, formatAssetSetupData, formatEventsData } from '../Machines/utils';

export const getMachinesAnalyticsData = (analytics_type) => {
  const request = () => ({ type: machinesAnalyticsConstants.GET_MACHINES_ANALYTICS_DATA_REQUEST, analytics_type });
  const success = (total_num, analytics_type, data, filterOptions) => ({
    type: machinesAnalyticsConstants.GET_MACHINES_ANALYTICS_DATA_SUCCESS,
    total_num,
    analytics_type,
    data,
    filterOptions
  });
  const failure = error => ({ type: machinesAnalyticsConstants.GET_MACHINES_ANALYTICS_DATA_FAILURE, analytics_type, error });

  return (dispatch, getState) => {
    dispatch(request());
    const searchParams = getState().machines.searchParams;
    const machines_requiring_attention = getState().machines.machinesRequiringAttention.show;
    const params = {
      analytics_type,
      machines_requiring_attention
    };
    if (searchParams) {
      params.filters = getSearchParamFilters(searchParams, true);
    }
    return handleResponse(axiosInstance.get(ENDPOINT.GET_MACHINE_ANALYTICS, { params })).then(
      (res) => {
        const assetConditionLevels = getState().currentAccount.preferences.machine_condition_levels;
        let sortOrder;
        if (analytics_type === 'health_score') {
          sortOrder = machinesAnalyticsMetadata[analytics_type].sortOrder[assetConditionLevels];
        } else {
          sortOrder = machinesAnalyticsMetadata[analytics_type].sortOrder;
        }

        const data = res[analytics_type];
        let analyticsDataList = [];
        let filterOptions = [];

        if (analytics_type === 'asset_setup') {
          [analyticsDataList, filterOptions] = formatAssetSetupData(data, sortOrder);
        } else if (analytics_type === 'events') {
          [analyticsDataList, filterOptions] = formatEventsData(
            data,
            machinesAnalyticsMetadata[analytics_type].colors
          );
        } else {
          analyticsDataList = sortOrder.map(item => [customLabels[item] || humanize(item), data[item]]);
          filterOptions = machinesAnalyticsMetadata[analytics_type].filterOptions;
        }
        dispatch(success(res.total_num, analytics_type, analyticsDataList, filterOptions));
        return [analyticsDataList, filterOptions];
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};
