import React, { useMemo } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FlexContainer } from 'common/components/atoms';
import { splitOnLastDelimiter } from 'common/utils';
import { bindActionCreators } from 'redux';
import * as machineMLActions from '../actions/machineML.actions';
import FeatureChart from './FeatureChart';


const SpeedBinCharts = ({ speedBins, attribute, tagType, componentId, component, startTime, endTime, tagIds }) => {
  const featureAmpTypeDestruct = useMemo(() => {
    const [feature, ampType] = splitOnLastDelimiter(attribute, '_');
    return [feature, ampType];
  }, [attribute]);

  return (
    <FlexContainer flexWrap="wrap">
      {speedBins && Array.from({ length: speedBins }, (_, i) => i + 1).map((speedBin, idx) => {
        const [feature, ampType] = featureAmpTypeDestruct;
        return (
          <FeatureChart
            key={idx}
            feature={feature}
            ampType={ampType}
            tagType={tagType}
            componentId={componentId}
            featureDisplayName={`Speed Bin ${speedBin}`}
            component={component}
            startTime={startTime}
            endTime={endTime}
            tagIds={tagIds}
            speedBin={speedBin}
          />
        );
      })}
    </FlexContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  machineMLActions: bindActionCreators(machineMLActions, dispatch)
});

export default connect(null, mapDispatchToProps)(SpeedBinCharts);
