import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { history, humanize } from 'common/helpers';
import { customLabels } from 'home/MachinesAnalytics/machinesAnalytics.constants';
import { truncateArray } from 'common/utils';
import colors from 'common/styles/colors';
import fonts from 'common/styles/fonts';
import { SelectedOption } from 'common/components/atoms/SelectedOption';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Loading from 'common/components/atoms/Loading';
import { isAnalyst, isPetasenseAdmin, isPartnerAdmin } from 'common/rbac/util';
import { bindActionCreators } from 'redux';
import * as taskActions from 'home/HomePage/actions/tasks.actions';

const ValuesContainer = styled.div`
  ${props => props.expanded && `
    display: block;
    margin-left: 1rem;
  `}
  ${props => !props.expanded && `
    display: inline-block;
    margin-left: 0.5rem;
  `}
  .ed-multiselect-selected-option {
    display: inline-flex;
  }
`;

const RowItem = styled.div`
  padding: 0.5rem;
  border-radius: 5px;
  background-color: ${colors.white};
  border: 1px solid ${colors.borderGray};
  margin: 0 0 8px 8px;
  ${props => props.showHoverEffect && `
    cursor: pointer;
    &:hover {
      background-color: ${colors.greyXXXL}
      border: 1px solid ${colors.borderGrayHover};
    }
  `}
  .reason-header {
    ${fonts.lightLabel}
    font-weight: 600;
    color: gray;
  }
`;

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const LinkItemContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const BaseLinkItem = ({ linkTo, value, title, customiseVal, taskActions }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    if (linkTo.pathname.startsWith('/tasks/')) {
      setIsLoading(true);
      const taskId = linkTo.pathname.split('/tasks/')[1];
      const prevLocation = {
        pathname: window.location.pathname,
        search: window.location.search
      };
      try {
        const taskDetails = await taskActions.getTaskDetails(taskId, false);
        taskActions.openTaskModal(taskId, taskDetails);
        // Update URL without reload using only window.history
        window.history.replaceState(
          {
            prevLocation
          },
          '',
          `/tasks/${taskId}`
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      history.push(linkTo);
    }
  };

  return (
    <LinkItemContainer>
      {isLoading && (
        <LoaderOverlay>
          <Loading size="small" />
        </LoaderOverlay>
      )}
      <SelectedOption
        title={title}
        className="link-option"
        option={{ text: customiseVal ? customLabels[value] || humanize(value) : value }}
        onContainerClick={handleClick}
        disabled
      />
    </LinkItemContainer>
  );
};

const ReasonRowItem = ({
  reasonType,
  values,
  metadata,
  machineId,
  taskActions
}) => {
  const [expanded, setExpanded] = useState(false);
  const allowExpanding = values.length > 2;

  const toggleExpand = () => {
    if (allowExpanding) {
      setExpanded(prevState => !prevState);
    }
  };

  const getReasonOptions = (reasonType, values) => {
    switch (reasonType) {
      case 'tasks':
        return values.map((value, i) => (
          <BaseLinkItem
            linkTo={{
              pathname: `/tasks/${value.id ? value.id : value}`,
              state: { from_app: true }
            }}
            title={metadata ? metadata[i].name : ''}
            value={metadata ? metadata[i].name : ''}
            taskActions={taskActions}
          />
        ));
      case 'diagnostics':
        return values.map((value, i) => (
          <BaseLinkItem
            linkTo={{
              pathname: `/machines/${machineId}/health`,
              state: { result_id: metadata && metadata[i].rule_result_id }
            }}
            value={value}
            customiseVal
          />
        ));
      case 'baselines_status':
        return values.map((value, i) => (
          <BaseLinkItem
            linkTo={{
              pathname: `/machines/${machineId}/baseline`,
            }}
            value={value}
            customiseVal
          />
        ));
      case 'missing_asset_info':
        return values.map((value, i) => (
          <BaseLinkItem
            linkTo={{
              pathname: `/machines/${machineId}/info`,
            }}
            value={value}
            customiseVal
          />
        ));
      case 'health_score':
      case 'alarms':
        return values.map((value, i) => (
          <BaseLinkItem
            linkTo={{
              pathname: '/activity',
              state: { activity: { activity_id: metadata && metadata[i].event_id, type: 'event' } }
            }}
            value={value}
            customiseVal
          />
        ));
      default:
        return values;
    }
  };

  return (
    <RowItem onClick={toggleExpand} showHoverEffect={allowExpanding}>
      <span className="reason-header">{humanize(reasonType)}:</span>
      <ValuesContainer expanded={expanded}>
        {truncateArray(
          getReasonOptions(reasonType, values),
          customLabels,
          expanded ? values.length : 2
        )}
      </ValuesContainer>
    </RowItem>
  );
};

const ReasonItem = ({ reason, metadata, machineId, user, taskActions }) => (
  <FlexContainer
    className="reason-item"
    width="100%"
    flexWrap="wrap"
  >
    {Object.keys(reason)
      .filter(key => isPetasenseAdmin(user) || isAnalyst(user) || isPartnerAdmin(user) || key === 'tasks')
      .map((key, idx) => (
        <ReasonRowItem
          key={idx}
          reasonType={key}
          values={reason[key]}
          metadata={metadata[key]}
          machineId={machineId}
          taskActions={taskActions}
        />
      ))}
  </FlexContainer>
);

ReasonItem.propTypes = {
  reason: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  taskActions: bindActionCreators(taskActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReasonItem);
