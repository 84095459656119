import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ListItem } from 'common/components/atoms';

const AccountsItem = styled(ListItem)`
cursor: pointer;
${props => props.selected && 'background-color: rgba(145,184,70,0.08);'}
td {
  height: 54px;
}
`;
function accountsItem(props) {
  const { item, columnSizes, formatDate } = props;
  return (
    <>
      <AccountsItem>
        <td width={`${columnSizes[0]}%`}>
          {item.id || '--'}
        </td>
        <td width={`${columnSizes[1]}%`}>
          {item.name || '--'}
        </td>
        <td width={`${columnSizes[2]}%`}>
          {item.industry || '--'}
        </td>
        <td width={`${columnSizes[3]}%`}>
          {item.created_at ? formatDate(item.created_at) : '--'}
        </td>
      </AccountsItem>
    </>
  );
}

accountsItem.propTypes = {
  item: PropTypes.object.isRequired,
  columnSizes: PropTypes.array.isRequired
};

export default accountsItem;
