import styled from 'styled-components';

const UserCircle = styled.div`
position:relative;
width: 20px;
height: 20px;
background-color: ${props => props.theme.colors.greyXL};
border-radius: 50%;
border: 2px solid ${props => props.theme.colors.white};
display: flex;
align-items: center;
justify-content: center;
font-size: 8px;
font-weight: 600;
letter-spacing: 0.08em;
color: ${props => props.theme.colors.darkGray};
${props => props.margin && `margin: ${props.margin}`}
`;

export default UserCircle;
