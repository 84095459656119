export const svgConstants = {
  CONTEXT_CHART_HEIGHT: 25,
  LINE_HEIGHT: 150,
  NO_OF_TICKS_Y: 2,
  NO_OF_TICKS_X: 6,
  //  ratio of X: Y 5:4
  PERSPECTIVE_OFFSET_X: 15,
  PERSPECTIVE_OFFSET_Y: 15,
  OFFSET_X: 200,
  XY_GRID_INDEX: 1,
  OFFSET_Z_LINE: 2,
  MARGIN_FOCUS_CHART: {
    LEFT: 20,
    RIGHT: 135,
    TOP: 30,
    BOTTOM: 60
  },
  MARGIN_CONTEXT_CHART: {
    LEFT: 20,
    RIGHT: 135,
    TOP: 220,
    BOTTOM: 60
  },
  PERS_SLIDER: {
    X: { MIN: 0, MAX: 15 },
    Y: { MIN: 10, MAX: 25 }
  },
  OFFSET_X_FACTOR: {
    LEN: 8,
    PERS_X: 15
  }
};

export const spectraOptions = [
  { text: '5', value: 5 },
  { text: '10', value: 10 },
  { text: '15', value: 15 }
];

export const calendarOptions = [
  {
    text: '30D',
    value: 30
  },
  {
    text: '90D',
    value: 90
  },
  {
    text: '180D',
    value: 180
  },
  {
    text: 'All',
    value: undefined
  }
];
