import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../../../../../common/typography/Text/Text';
import FlexContainer from '../../../../../common/components/atoms/FlexContainer';

const AddNoteChartOverlayStyled = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 54px;
  width: Calc(100% - 54px);
  height: Calc(100% - 13px);
  background-color: rgba(239,240,238,0.75);
  cursor: pointer;
  ${Text} {
  }
`;

const AddNoteText = styled(Text)`
  pointer-events: none;
  position: absolute;
  color: ${props => props.theme.primaryColor};
  top: calc(50px - 8px);
`;

const AddNoteChartOverlay = ({ text, onClick }) => (
  <FlexContainer alignItems="center" justifyContent="center">
    <AddNoteChartOverlayStyled onClick={onClick} />
    <AddNoteText>Add note on {text}</AddNoteText>
  </FlexContainer>
);

AddNoteChartOverlay.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default AddNoteChartOverlay;
