import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { withTheme } from 'styled-components';
import update from 'immutability-helper';
import * as _ from 'lodash';
import moment from 'moment';
import AggressiveMeasurementAlertPrompt from 'common/components/organisms/AlertPrompt';
import Accordion from 'common/components/molecules/Accordion';
import { toastr } from 'react-redux-toastr';
import RBAC from 'common/rbac/RBAC';
import { isPartnerAdmin, isPetasenseAdmin } from 'common/rbac/util';
import { Grid, Segment } from 'semantic-ui-react';
import {
  ResourceSection,
  ResourceItemLabel,
  ResourceItem,
  ResourceInputField,
  ResourceSubSection
} from 'common/components/atoms/ResourceDetails';
import {ENDPOINT, getDeviceGCPLogsUrl, getFullEndPointUrl, getImpUrl} from 'common/constants';
import { getBaseUrl } from 'home/DomainName/DomainConstants';
import * as sensorsActions from '../../actions/sensors.actions';
import { localTimeString } from '../../../../common/helpers/time';
import LoadingSvg from '../../../../common/images/LoadingSvg';
import FlexContainer from '../../../../common/components/atoms/FlexContainer';
import CrossSvg from '../../../../common/images/CrossSvg';
import H1_T from '../../../../common/typography/H1/H1';
import H3 from '../../../../common/typography/H3/H3';
import H4 from '../../../../common/typography/H4/H4';
import Label_T from '../../../../common/typography/Label/Label';
import Button_T from '../../../../common/components/atoms/Button';
import { BatteryIcon } from '../../../../common/images/BatteryIcon';
import WiFiSvg from '../../../../common/images/WiFiSvg';
import { Info } from '../../../../common/images/FaIcons';
import Modal from '../../../../common/components/organisms/Modal';
import MeasurementScheduleModal from '../MeasurementScheduleModal';
import MoteOrientationGuide from '../../MoteOrientationGuide';
import * as sensorUtils from '../../utils/sensorUtils';
import {
  AGGRESSIVE_INTERVAL_THRESHOLD,
  ErrorMessages
} from '../../constants/sensors.constants';
import WifiSetting from '../WifiSettings';
import DeviceParameterAnalysis from '../DeviceParameterAnalysis';
import { humanize, history } from '../../../../common/helpers';
import DeviceReportedInfoModal from '../DeviceReportedInfoModal';
import PreferredAPSettings from '../PreferredAPSettings';


const Container = styled(FlexContainer).attrs({ direction: 'column' })`
  padding: 2em;
  min-height: Calc(100vh - 138px);
`;
const H1 = styled(H1_T)`
  padding: ${props => (props.padding ? `${props.padding};` : '0;')};
  margin-bottom: 3px;
  min-width: max-content;
  width: 100%;
  cursor: pointer;
`;
const WifiLabel = styled(H3)`
  padding-left: 0.5em;
  padding-top: 0.1em;
`;
const SvgContainer = styled.span`
  cursor: pointer;
  margin-left: auto;
`;
const Button = styled(Button_T)`
  width: max-content;
  z-index: 2;
  padding-left: ${props => props.paddingLeft ? props.paddingLeft : '95px'};
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.paddingBottom && `padding-bottom: ${props.paddingBottom};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;
const SaveButton = styled(Button_T)`
  max-width: 200px;
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;
const ScheduleButton = styled(Button_T)`
  width: max-content;
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;
const AnalysisButton = styled(Button_T)`
 width: max-content;
 ${props => props.fontsize && `font-size: ${props.fontsize};`}
 ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
 ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;
const LoadingContainer = styled.div`
  align-self: center;
  margin: auto;
`;
const BorderContainer = styled.div`
  border: 2px solid #f0f0f0;
  padding: 20px;
`;
const AbsoluteLoading = styled.div`
  background: white;
  position: absolute;
  align-self: center;
  margin: auto;
  opacity: 0.4;
  width: 100%;
  height: calc(100vh - 138px);
  overflow: none;
  svg {
    transform: translateX(35vw) translateY(50vh) translateY(-69px);
  }
`;
const ButtonsContainer = styled(FlexContainer)`
  padding-top: 2em;
  button:first-child {
    margin-right: 15px;
  }
`;
const TextLabel = styled(Label_T)`
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 8px;
  margin-top: -2px;
`;
const HeaderContainer = styled(FlexContainer).attrs({
  justifyContent: 'space-between',
  alignItems: 'center'
})``;
const aggressive_measurement_types = ['full_bandwidth', 'high_res'];
class VibrationMote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        machine: props.machines || [],
        component: [],
        location: [],
        orientation: [],
        spectrum_sampling_resolution: [],
        spectrum_measurement_type: [],
        aggressiveModal: false
      },
      details: {
        serial_number: props.sensor.serial_number,
        model: props.sensor.model,
        agent_url: props.sensor.agent_url,
        device_id: props.sensor.device_id,
        mac_address: props.sensor.mac_address,
        fs_range: props.sensor.fs_range,
        user: this.props.user,
        id: props.sensor.id,
        model: props.sensor.model,
        last_battery_change_time: props.sensor.last_battery_change_time
      },
      force_association: false,
      error: false,
      openScheduler: false,
      editMode: props.editMode,
      saveEnabled: false,
      loading: {},
      maxReportingInterval: null,
      ssid: '',
      passphrase: '',
      wifi_schedule_time: '00:00',
      wifi_schedule_date: moment()
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      errorMessages: {},
      showLogAnalysisModal: false,
      showLastReportedModal: false,
      bssid: '',
      channel: null
    };
  }
  componentDidMount() {
    const { getResourcesBasedOnType } = this.props.sensorsActions;
    if (this.state.options.machine.length === 0) {
      getResourcesBasedOnType('area');
      getResourcesBasedOnType('machine').then(
        res =>
          this.setState(prevState => ({
            options: {
              ...prevState.options,
              machine: res
            }
          })),
        error => this.setState({ error })
      );
    }
    const {
      details,
      sensor: { model, serial_number }
    } = this.props;
    let sensor = {};
    if (details[model]) sensor = details[model].find(d => d.serial_number === serial_number);
    if (sensor) {
      this.setState({
        details: _.cloneDeep(sensor),
        associatedMachineId: sensor.machine
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      details,
      sensor: { model, serial_number }
    } = this.props;
    const {
      details: prevDetails,
      sensor: { model: prevModel, serial_number: prevSerialNumber }
    } = prevProps;
    const {
      getLocationOptions,
      getComponentOptions
    } = this.props.sensorsActions;
    let sensor = {};
    let prevSensor = {};
    if (details[model]) {
      sensor = details[model].find(d => d.serial_number === serial_number);
    }
    if (prevDetails[prevModel]) {
      prevSensor = prevDetails[prevModel].find(
        d => d.serial_number === prevSerialNumber
      );
    }
    if (
      sensor &&
      (!_.isEqual(prevSensor, sensor) ||
        prevState.details.serial_number !== sensor.serial_number ||
        !_.isEqual(
          prevState.options.machine.length,
          this.state.options.machine.length
        ))
    ) {
      if (
        this.props.createdMachineId &&
        !_.isEmpty(this.state.options.machine)
      ) {
        const clonedDetails = _.cloneDeep(sensor);
        clonedDetails.machine = this.props.createdMachineId;
        const obj = this.state.options.machine.find(
          e => e.value === this.props.createdMachineId
        );
        clonedDetails.machine_name = (obj && obj.text) || '';
        this.setState({
          details: clonedDetails,
          associatedMachineId: this.props.createdMachineId
        });
      } else {
        this.setState({
          details: _.cloneDeep(sensor),
          associatedMachineId: sensor.machine
        });
      }
      if (
        !['VM1', 'VM2'].includes(sensor.model) &&
        sensor.full_bandwidth &&
        sensor.high_res &&
        this.isScheduleAggressive(sensor) &&
        this.props.metadata[model].reporting &&
        this.props.metadata[model].reporting.max_measurement_count
      ) {
        const schedule = [
          sensor.full_bandwidth.schedule,
          sensor.high_res.schedule
        ];
        const maxReportingInterval = sensorUtils.calculateMaxReportingInterval(
          schedule,
          this.props.metadata[model].reporting.max_measurement_count
        );
        this.setState({
          maxReportingInterval
        });
      }
    }
    if (
      this.state.details.machine &&
      !this.state.loading.components &&
      (_.isEmpty(this.state.options.component) ||
        prevState.details.machine !== this.state.details.machine)
    ) {
      this.setState(prevState => ({
        loading: {
          ...prevState.loading,
          components: true
        }
      }));
      getComponentOptions(Number(this.state.details.machine)).then(
        (res) => {
          this.setState(prevState => ({
            options: {
              ...prevState.options,
              component: res
            },
            loading: {
              ...prevState.loading,
              components: false
            }
          }));
        },
        error => this.setState({ error })
      );
    }
    if (
      this.state.details.machine &&
      !this.state.loading.locations &&
      (prevState.details.machine !== this.state.details.machine ||
        prevState.details.component !== this.state.details.component)
    ) {
      this.setState(prevState => ({
        loading: {
          ...prevState.loading,
          locations: true
        }
      }));
      getLocationOptions(
        Number(this.state.details.machine),
        Number(this.state.details.component),
        true
      ).then((res) => {
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            location: res
          },
          loading: {
            ...prevState.loading,
            locations: false
          }
        }));
      });
    }
    if (prevState.editMode !== this.props.editMode) {
      this.setState({
        editMode: this.props.editMode
      });
    }
    if (_.get(details, [model, '0', 'config_wifi_ssid']) !== _.get(prevDetails, [model, '0', 'config_wifi_ssid'])) {
      this.setState({ ssid: details[model][0].config_wifi_ssid });
    }
    if (_.get(details, [model, '0', 'config_wifi_passphrase']) !== _.get(prevDetails, [model, '0', 'config_wifi_passphrase'])) {
      this.setState({ passphrase: details[model][0].config_wifi_passphrase });
    }
    if (_.get(details, [model, '0', 'ap_bssid']) !== _.get(prevDetails, [model, '0', 'ap_bssid'])) {
      this.setState({ bssid: details[model][0].ap_bssid });
    }
    if (_.get(details, [model, '0', 'ap_channel']) !== _.get(prevDetails, [model, '0', 'ap_channel'])) {
      this.setState({ channel: details[model][0].ap_channel });
    }
    if (!sensor) return;
    const stateDetails = _.cloneDeep(this.state.details);
    const propsDetails = _.cloneDeep(sensor);
    delete stateDetails.machine;
    delete stateDetails.machine_name;
    delete stateDetails.location;
    delete stateDetails.location_name;
    delete stateDetails.component;
    delete stateDetails.component_name;
    delete propsDetails.machine;
    delete propsDetails.machine_name;
    delete propsDetails.location;
    delete propsDetails.location_name;
    delete propsDetails.component;
    delete propsDetails.component_name;
    this.enableSave(sensor, stateDetails, propsDetails);
  }
  enableSave = (sensor, stateDetails, propsDetails) => {
    const { details, errorMessages, saveEnabled, ssid, passphrase, bssid, channel } = this.state;
    const { updateFooter } = this.props;
    if (!_.isEqual(details, sensor) && _.isEmpty(errorMessages)) {
      if (saveEnabled !== true) {
        this.setState({ saveEnabled: true });
        if (updateFooter) updateFooter(true);
      }
    } else if (
      !_.isEmpty(ssid) && ssid !== _.get(
        this.props.details, [propsDetails.model, '0', 'config_wifi_ssid']
      ) && _.isEmpty(errorMessages)
    ) {
      if (saveEnabled !== true) {
        this.setState({ saveEnabled: true });
        if (updateFooter) updateFooter(true);
      }
    } else if (
      !_.isEmpty(passphrase) && passphrase !== _.get(
        this.props.details, [propsDetails.model, '0', 'config_wifi_passphrase']
      ) && _.isEmpty(errorMessages)
    ) {
      if (saveEnabled !== true) {
        this.setState({ saveEnabled: true });
        if (updateFooter) updateFooter(true);
      }
    } else if (
      !_.isEmpty(bssid) && bssid !== _.get(
        this.props.details, [propsDetails.model, '0', 'ap_bssid']
      ) && _.isEmpty(errorMessages)
    ) {
      if (saveEnabled !== true) {
        this.setState({ saveEnabled: true });
        if (updateFooter) updateFooter(true);
      }
    } else if (
      !_.isEmpty(channel) && channel !== _.get(
        this.props.details, [propsDetails.model, '0', 'ap_channel']
      ) && _.isEmpty(errorMessages)
    ) {
      if (saveEnabled !== true) {
        this.setState({ saveEnabled: true });
        if (updateFooter) updateFooter(true);
      }
    } else if (_.isEqual(stateDetails, propsDetails)) {
      if (
        details.machine &&
        details.location &&
        !_.isEqual(details.location, sensor.location)
      ) {
        if (saveEnabled !== true) {
          this.setState({ saveEnabled: true });
          if (updateFooter) updateFooter(true);
        }
      } else if (saveEnabled !== false) {
        this.setState({ saveEnabled: false });
        if (updateFooter) updateFooter(false);
      }
    } else if (saveEnabled !== false) {
      this.setState({ saveEnabled: false });
      if (updateFooter) updateFooter(false);
    }
  };
  dissociateSensor = () => {
    this.setState(
      prevState => ({
        details: {
          ...prevState.details,
          location: null,
          location_name: null,
          machine: null,
          machine_name: null,
          component: null,
          component_name: null
        },
        associatedMachineId: null
      }),
      () => this.onSave()
    );
  };

  filterReportingIntervals = () => {
    const { details } = this.state;
    const ri_upper_threshold = sensorUtils.getVersionThreshold(details.firmware_versions.mcu);

    const threshold =
      this.state.maxReportingInterval || AGGRESSIVE_INTERVAL_THRESHOLD;

    const { metadata } = this.props;
    if (this.isScheduleAggressive(details)) {
      return _.filter(
        metadata[details.model].reporting.interval,
        o => o.value <= threshold
      );
    }
    return _.filter(
      metadata[details.model].reporting.interval,
      o => o.value > AGGRESSIVE_INTERVAL_THRESHOLD && o.value <= ri_upper_threshold
    );
  };

  isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  stringifyOptions = options =>
    options.map(option => ({
      ...option,
      value: _.isObject(option.value)
        ? JSON.stringify(option.value)
        : option.value
    }));
  mapOptionsText = options =>
    options.map((option) => {
      if (option.text === 'HAV') {
        option.text = 'Vertical';
      } else if (option.text === 'VAH') {
        option.text = 'Horizontal';
      } else if (option.text === 'HVA') {
        option.text = 'Axial';
      }
      return option;
    });
  renderField = (
    type,
    label,
    options_T,
    value_T,
    disabled,
    name,
    search = false
  ) => {
    const { editMode } = this.state;
    const { updating } = this.props;
    let options = this.stringifyOptions(options_T);
    const value = _.isObject(value_T) ? JSON.stringify(value_T) : value_T;
    if (name === 'fs_range') {
      const fsRangeOptions = {
        '± 4g': 4,
        '± 8g': 8,
        '± 16g': 16
      };
      if (editMode) {
        return (
          <ResourceInputField
            type={type}
            label={label}
            options={Object.keys(fsRangeOptions).map(option => ({
              value: fsRangeOptions[option],
              text: option
            }))}
            value={value}
            name={name}
            key={name}
            disabled={disabled || updating}
            onChange={(e, value) => this.onChangeField(e, value, name)}
            selectOnBlur={false}
            search={search}
          />
        );
      }
      const textValue = fsRangeOptions[value] ? value : '--';
      return (
        <Fragment>
          <ResourceItemLabel>{label}</ResourceItemLabel>
          <ResourceItem>{textValue}</ResourceItem>
        </Fragment>
      );
    }
    if (name === 'orientation') {
      options = this.mapOptionsText(options);
      if (editMode) {
        return (
          <Fragment>
            <ResourceItemLabel>
              Orientation
              <span onClick={() => this.setState({ openMoteOrentation: true })}>
                <Info
                  aria-hidden="true"
                  size="1x"
                  style={{ marginLeft: '5px' }}
                />
              </span>
            </ResourceItemLabel>
            <ResourceInputField
              paddingtop="0"
              type={type}
              options={options}
              value={value}
              name={name}
              key={name}
              disabled={disabled || updating}
              onChange={(e, value) => this.onChangeField(e, value, name)}
              selectOnBlur={false}
              search={search}
            />
          </Fragment>
        );
      }
      let textValue = '';
      if (options) {
        const element = options.filter(option =>
          _.isEqual(option.value, value)
        )[0];
        if (element) textValue = element.text;
        else textValue = '--';
      }
      return (
        <Fragment>
          <ResourceItemLabel>
            Orientation
            <span onClick={() => this.setState({ openMoteOrentation: true })}>
              <Info
                aria-hidden="true"
                size="1x"
                style={{ marginLeft: '5px' }}
              />
            </span>
          </ResourceItemLabel>
          <ResourceItem>{textValue}</ResourceItem>
        </Fragment>
      );
    }
    if (editMode) {
      return (
        <ResourceInputField
          type={type}
          label={label}
          options={options}
          value={value}
          name={name}
          key={name}
          disabled={disabled || updating}
          onChange={(e, value) => this.onChangeField(e, value, name)}
          selectOnBlur={false}
          search={search}
        />
      );
    }
    let textValue = '';
    if (options) {
      const element = options.filter(option =>
        _.isEqual(option.value, value)
      )[0];
      if (element) textValue = element.text;
      else textValue = '--';
    }
    return (
      <Fragment>
        <ResourceItemLabel>{label}</ResourceItemLabel>
        {name === 'machine' ? (
          <ResourceItem
            onClick={() => this.redirectToMachine(value)}
            hover={textValue !== '--'}
          >
            {textValue}
          </ResourceItem>
        ) : (
          <ResourceItem>{textValue}</ResourceItem>
        )}
      </Fragment>
    );
  };
  handleInputClick = (e, data) => {
    const targetValue = e.target.value || data.value || '';
    const targetName = e.target.name || data.name;

    this.setState({ [targetName]: targetValue }, this.validateInput);
  };
  onChangeField = (e, { value }, name) => {
    const { details } = this.state;
    if (name === 'fs_range') {
      const updatedDetails = { ...details, fs_range: value };
      this.setState({ details: updatedDetails });
      return null;
    }
    if (name === 'machine' || name === 'component' || name === 'location') {
      const key = `${name}_name`;
      const text = this.state.options[name].find(e => e.value === value).text;
      const updatedDetails = update(details, {
        [key]: { $set: text },
        [name]: { $set: this.isJson(value) ? JSON.parse(value) : value }
      });
      this.setState({ details: updatedDetails });
      return null;
    }
    const [newName, option] = name.split('.');
    if (option) {
      let key = option;
      let val = value;
      if (key === 'state_based_schedule') {
        key = 'schedule';
        val = [{ start: ['0', '00:00'], end: null, interval: value }];
      }
      const updatedDetails = update(details, {
        [newName]: { [key]: { $set: this.isJson(val) ? JSON.parse(val) : val } }
      });
      this.setState({ details: updatedDetails });
      return null;
    }
    if (name === 'measurement_settings_type') {
      const defaultSchedule = [
        { start: ['0', '00:00'], end: null, interval: 10800 }
      ];
      const stops = [];
      const updatedDetails = update(details, {
        overall: {
          type: { $set: value },
          schedule: { $set: [] },
          stops: { $set: stops }
        },
        full_bandwidth: {
          type: { $set: value },
          schedule: { $set: value === 'state_based' ? defaultSchedule : [] },
          stops: { $set: stops }
        },
        high_res: {
          type: { $set: value },
          schedule: { $set: [] },
          stops: { $set: stops }
        }
      });
      this.setState({ details: updatedDetails });
      return null;
    }
    const updatedDetails = update(details, {
      [name]: { $set: this.isJson(value) ? JSON.parse(value) : value }
    });
    this.setState({ details: updatedDetails });
  };
  isScheduleChanged = (newDetails, oldDetails) =>
    _.some(
      aggressive_measurement_types,
      mt =>
        !_.isEqual(
          newDetails[mt] && newDetails[mt].schedule,
          oldDetails[mt] && oldDetails[mt].schedule
        )
    );
  isScheduleAggressive = details =>
    _.some(aggressive_measurement_types, mt =>
      _.some(
        details[mt].schedule,
        s => s.interval <= AGGRESSIVE_INTERVAL_THRESHOLD
      )
    );
  onClickCheckAggressive = () => {
    const { details: newDetails } = this.state;
    const { details: oldDetails } = this.props;
    if (
      ['VM3', 'VM4P', 'GSP'].includes(newDetails.model) &&
      newDetails.overall.type === 'state_based' &&
      this.isScheduleChanged(newDetails, oldDetails[newDetails.model][0]) &&
      this.isScheduleAggressive(newDetails)
    ) {
      this.setState({ aggressiveModal: true });
    } else {
      this.onSave();
    }
  };
  closeAggressiveModal = () => {
    this.setState({ aggressiveModal: false });
  };
  validateInput = () => {
    const { ssid, passphrase, bssid, channel } = this.state;
    const errorMessages = {};
    if (ssid || passphrase) {
      if (!passphrase) errorMessages.passphrase_error = ErrorMessages.wifi_password_required;
      if (!ssid) errorMessages.ssid_error = ErrorMessages.wifi_ssid_required;
    }
    if (!_.isEmpty(bssid)) {
      if (!bssid.match(/^([A-Fa-f0-9]{12})$/)) {
        errorMessages.bssid_error = ErrorMessages.invalid_bssid;
      }
    }
    const channelInt = parseInt(channel, 10);
    if (_.isNumber(channelInt) && (channelInt < 1 || channelInt > 13)) {
      errorMessages.channel_error = ErrorMessages.invalid_channel;
    }
    this.setState({ errorMessages });
  };
  onSave = () => {
    const { updateSensorDetails } = this.props.sensorsActions;
    const {
      details: {
        serial_number,
        model,
        orientation,
        overall,
        full_bandwidth,
        high_res,
        location,
        location_name,
        machine,
        machine_name,
        component,
        component_name,
        reporting_interval,
        aggressive_measurement_timeout,
        fs_range,
      },
      ssid,
      passphrase,
      wifi_schedule_date,
      wifi_schedule_time,
      bssid,
      channel
    } = this.state;
    const propsDetails = this.props.details[this.props.sensor.model].find(
      d => d.serial_number === this.props.sensor.serial_number
    );
    const start_time = sensorUtils.getEpochFromDateTime(
      wifi_schedule_date,
      wifi_schedule_time
    );
    const data = {
      [model]: {
        serial_number: [serial_number],
        fs_range,
        orientation,
        measurement_settings: {
          0: overall,
          1: full_bandwidth,
          2: high_res
        },
        reporting: {
          interval: reporting_interval
        },
        location_id: location,
        location_name,
        component,
        component_name,
        machine,
        machine_name,
        type: 'Vibration Mote',
        force_association: this.state.force_association,
        aggressive_measurement: this.isScheduleAggressive(this.state.details),
        aggressive_measurement_timeout
      }
    };
    if (ssid && passphrase) {
      data[model].wifi_config = {
        ssid,
        passphrase,
        start_time
      };
    }

    // Initialize wifi_ap_config
    data[model].wifi_ap_config = {};

    // Add bssid if it exists
    if (bssid && bssid.trim() !== '') {
      data[model].wifi_ap_config.bssid = bssid;
    }

    // Add channel if it exists and is valid
    if (channel !== null && channel !== undefined && channel !== '') {
      const channelInt = parseInt(channel, 10);
      if (!Number.isNaN(channelInt)) {
        data[model].wifi_ap_config.channel = channelInt;
      }
    }

    if (_.isEqual(orientation, propsDetails.orientation)) delete data[model].orientation;
    if (_.isEqual(location, propsDetails.location)) delete data[model].location_id;
    updateSensorDetails(data).then(
      (res) => {
        if (res && res[model]) {
          if (this.state.force_association) {
            this.setState({ force_association: false });
          }
          if (this.state.aggressiveModal) {
            this.closeAggressiveModal();
          }
        }
        return res;
      },
      (error) => {
        if (error.mote_exists) {
          this.setState({
            force_association: true,
            force_association_msg: error.message
          });
        } else {
          toastr.error(error.message);
        }
      }
    );
  };
  onCancel = () => {
    const {
      details,
      sensor: { model, serial_number }
    } = this.props;
    let sensor = {};
    if (details[model]) {
      sensor = details[model].find(d => d.serial_number === serial_number);
    }
    if (sensor && !_.isEqual(this.state.details, sensor)) {
      this.setState({
        details: _.cloneDeep(sensor)
      });
    }
    if (this.state.force_association) this.setState({ force_association: false });
  };
  saveSchedule = (schedule, stops, maxReportingInterval) => {
    const { details } = this.state;
    const updatedDetails = update(details, {
      overall: { schedule: { $set: schedule[0] }, stops: { $set: stops[0] } },
      full_bandwidth: {
        schedule: { $set: schedule[1] },
        stops: { $set: stops[1] }
      },
      high_res: { schedule: { $set: schedule[2] }, stops: { $set: stops[2] } }
    });
    this.setState(
      { details: updatedDetails, openScheduler: false, maxReportingInterval },
      () => this.onSave()
    );
  };
  redirectToMachine = (machineId) => {
    if (machineId) {
      history.push(`/machines/${machineId}/overview`);
    }
  };
  closeModal = () => {
    this.setState({ force_association: false });
  };
  getDownloadUrl = (mote_id) => {
    const url = `${getBaseUrl()}/i/v1/motes/${mote_id}/download-logs?output=csv`;
    return url;
  };
  getConfigs = (serial_number) => {
    const url = `${getBaseUrl()}/i/v2/sensors/${serial_number}/config`;
    return url;
  }
  getConfigHistories = (serial_number) => {
    const url = `${getBaseUrl()}/i/v2/sensors/${serial_number}/config-history`;
    return url;
  }
  getImpUrl = (device_id) => {
    const url = `https://impcentral.electricimp.com/ide/devices/${device_id}`;
    return url;
  }
  handleLogAnalysisClick = () => {
    this.setState(prevState => ({
      logAnalysisKey: prevState.logAnalysisKey + 1
    }));
    this.setState({ showLogAnalysisModal: true });
  };
  handleLastReportedClick = () => {
    this.setState(prevState => ({
      lastReportedkey: prevState.lastReportedKey + 1
    }));
    this.setState({ showLastReportedModal: true });
  };
  calculateDaysDifference = (targetDateStr) => {
    const targetDate = new Date(targetDateStr);
    const currentDate = new Date();
    const timeDifferenceMs = targetDate - currentDate;
    const daysDifference = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
    return Math.abs(daysDifference);
  }

  clearAPSettings = () => {
    this.setState({ bssid: '', channel: '' }, this.onSave);
  }

  renderAggressiveModal = (maxReportingInterval = null) => {
    const { metadata } = this.props;
    const { details } = this.state;
    const threshold = maxReportingInterval || AGGRESSIVE_INTERVAL_THRESHOLD;
    return (
      <div>
        {' '}
        {this.renderField(
          'select',
          'Measurement Time Out Interval',
          metadata.aggressive_measurement_timeout.interval,
          details.aggressive_measurement_timeout,
          false,
          'aggressive_measurement_timeout'
        )}
        {metadata[details.model].reporting &&
          metadata[details.model].reporting.interval &&
          this.renderField(
            'select',
            'Reporting Interval',
            _.filter(
              metadata[details.model].reporting.interval,
              o => o.value <= threshold
            ),
            details.reporting_interval,
            false,
            'reporting_interval'
          )}
      </div>
    );
  };

  renderReportingIntervalModal = () => {
    const { metadata } = this.props;
    const { details } = this.state;

    const version_threshold = sensorUtils.getVersionThreshold(details.firmware_versions.mcu);
    return (
      <div>
        {metadata[details.model].reporting &&
          metadata[details.model].reporting.interval &&
          this.renderField(
            'select',
            'Reporting Interval',
            _.filter(
              metadata[details.model].reporting.interval,
              o => o.value > AGGRESSIVE_INTERVAL_THRESHOLD && o.value <= version_threshold
            ),
            details.reporting_interval,
            false,
            'reporting_interval'
          )}
      </div>
    );
  };

  render() {
    const {
      aggressiveModal,
      details,
      saveEnabled,
      error,
      ssid,
      passphrase,
      errorMessages,
      wifi_schedule_date,
      wifi_schedule_time,
      showLogAnalysisModal,
      showLastReportedModal,
      bssid,
      channel
    } = this.state;
    const {
      metadata,
      loading,
      detailsError,
      errorList,
      updating,
      utcOffset,
      user,
      primaryColor
    } = this.props;
    const disableReportingProceed =
    ['VM3', 'VM4P', 'GSP'].includes(details.model) &&
      details.reporting_interval < AGGRESSIVE_INTERVAL_THRESHOLD;
    if (!loading && details.model && (!metadata || !metadata[details.model])) {
      return `Metadata for the given model ${details.model} doesn't exist`;
    }
    if (!details.model || !details.overall) {
      return (
        <AbsoluteLoading nobackground>
          <LoadingSvg />
        </AbsoluteLoading>
      );
    }
    const logQueryLink = {
      url: `https://console.cloud.google.com/logs/query?query=resource.type%3D%22generic_node%22%0Aresource.labels.node_id%3D%22${details.serial_number}%22%0A-jsonPayload.module%3D%22ImpAgent%22&project=petasense`,
    };
    return (
      <Fragment>
        {updating && (
          <AbsoluteLoading>
            <LoadingSvg />
          </AbsoluteLoading>
        )}
        {aggressiveModal && (
          <AggressiveMeasurementAlertPrompt
            message="Device will go into aggressive measurement from next reporting time"
            secondaryMessage="Aggressive monitoring impacts device battery life"
            onCancel={this.closeAggressiveModal}
            onProceed={this.onSave}
            component={this.renderAggressiveModal()}
          />
        )}
        {this.state.force_association && (
          <Modal width="70%" padding="0" close={this.closeModal}>
            <FlexContainer direction="column" alignItems="center">
              <H4>{this.state.force_association_msg}</H4>
              <FlexContainer>
                <Button
                  style={{ margin: '10px' }}
                  bordercolor={this.props.theme.colors.red}
                  secondary={this.props.theme.colors.red}
                  onClick={this.onSave}
                >
                  PROCEED
                </Button>
                <Button
                  style={{ margin: '10px' }}
                  bordercolor={this.props.theme.colors.black}
                  secondary={this.props.theme.colors.black}
                  onClick={this.onCancel}
                >
                  CANCEL
                </Button>
              </FlexContainer>
            </FlexContainer>
          </Modal>
        )}
        {this.state.openMoteOrentation && (
          <MoteOrientationGuide
            close={() => this.setState({ openMoteOrentation: false })}
          />
        )}
        {this.state.openScheduler && (
          <MeasurementScheduleModal
            close={() => this.setState({ openScheduler: false })}
            editMode={this.state.editMode}
            schedule={{
              0: details.overall.schedule,
              1: details.full_bandwidth.schedule,
              2: details.high_res.schedule
            }}
            stops={{
              0: details.overall.stops,
              1: details.full_bandwidth.stops,
              2: details.high_res.stops
            }}
            disabledRows={{
              0: false,
              1: false,
              2: false
            }}
            intervals={metadata[details.model].measurement_settings.intervals}
            saveSchedule={this.saveSchedule}
            utcOffset={utcOffset}
            type="Vibration Mote"
            reportingIntervalComponent={
              ['VM1', 'VM2'].includes(details.model)
                ? null
                : this.renderReportingIntervalModal()
            }
            component={(maxReportingInterval = null) =>
              this.renderAggressiveModal(maxReportingInterval)
            }
            onProceedDisabledReporting={disableReportingProceed}
            maxMeasurementCount={
              metadata[details.model].reporting
                ? metadata[details.model].reporting.max_measurement_count
                : null
            }
            restrictHighRes
          />
        )}
        <Container>
          {loading && !detailsError && !error && (
            <LoadingContainer>
              <LoadingSvg />
            </LoadingContainer>
          )}
          {!loading && (detailsError || error) && (
            <div>
              {detailsError || error}{' '}
              <b>(serial numbers: {errorList.join(', ')})</b>
            </div>
          )}
          {!loading && !error && !_.isEmpty(details) && (
            <Fragment>
              <HeaderContainer>
                <H1>{details.serial_number}</H1>
                <SvgContainer onClick={this.props.close}>
                  <CrossSvg width={25} height={25} />
                </SvgContainer>
              </HeaderContainer>
              <ResourceSection>Sensor Info</ResourceSection>
              <BorderContainer>
                <Grid columns={3} doubling stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Type</ResourceItemLabel>
                        <ResourceItem>{details.type}</ResourceItem>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Model</ResourceItemLabel>
                        <ResourceItem>{details.model}</ResourceItem>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Firmware</ResourceItemLabel>
                        <ResourceItem>
                          {details.firmware_versions
                            ? details.firmware_versions.mcu || '--'
                            : '--'}
                        </ResourceItem>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <ResourceItemLabel>Machine</ResourceItemLabel>
                            {this.state.editMode &&
                              this.state.associatedMachineId &&
                              !this.props.createdMachineId && (
                                <Button
                                  text
                                  fontsize="12px"
                                  onClick={this.dissociateSensor}
                                >
                                  Dissociate
                                </Button>
                            )}
                            {this.renderField(
                              'select',
                              '',
                              this.state.options.machine,
                              details.machine,
                              !!this.state.associatedMachineId ||
                                this.props.createdMachineId,
                              'machine',
                              true
                            )}
                          </div>
                        </div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        {this.renderField(
                          'select',
                          'Component',
                          this.state.options.component,
                          details.component,
                          false,
                          'component',
                          true
                        )}
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        {this.renderField(
                          'select',
                          'Location',
                          this.state.options.location.filter(
                            l => l.component_id === details.component
                          ),
                          details.location,
                          false,
                          'location'
                        )}
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        {this.renderField(
                          'select',
                          'Orientation',
                          metadata[details.model].orientation,
                          details.orientation,
                          false,
                          'orientation'
                        )}
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Last Report</ResourceItemLabel>
                        <ResourceItem>
                          {localTimeString(details.last_report)}
                        </ResourceItem>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Last Measurement</ResourceItemLabel>
                        <ResourceItem>
                          {localTimeString(details.last_measurement)}
                        </ResourceItem>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </BorderContainer>
              <ResourceSection>Battery &amp; Wireless</ResourceSection>
              <BorderContainer>
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Battery Level</ResourceItemLabel>
                        <FlexContainer direction="row">
                          <BatteryIcon batteryLevel={details.battery_level} />
                        </FlexContainer>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Wi-Fi Signal</ResourceItemLabel>
                        <FlexContainer direction="row">
                          {_.isNull(details.wifi_signal) && (
                            <div
                              title={
                                details.wifi_rssi &&
                                `Last WiFi Signal Strength: ${details.wifi_rssi} dB`
                              }
                            >
                              <ResourceItem>N/A</ResourceItem>
                            </div>
                          )}
                          {!_.isNull(details.wifi_signal) && (
                            <WiFiSvg wifiLevel={details.wifi_signal} />
                          )}
                          {!_.isNull(details.wifi_signal) && details.wifi_rssi && (
                            <WifiLabel>
                              <ResourceItem>
                                {details.wifi_rssi} dB
                              </ResourceItem>
                            </WifiLabel>
                          )}
                        </FlexContainer>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Wi-Fi SSID</ResourceItemLabel>
                        <FlexContainer direction="row">
                          {_.isNull(details.wifi_ssid) && (
                            <div>
                              <ResourceItem>N/A</ResourceItem>
                            </div>
                          )}
                          {details.wifi_ssid && (
                            <WifiLabel>
                              <ResourceItem>{details.wifi_ssid}</ResourceItem>
                            </WifiLabel>
                          )}
                        </FlexContainer>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {this.props.editMode && ['VM3', 'VM4P', 'GSP', 'VM4', 'GS'].includes(details.model) && (
                  <Grid columns={4} doubling stackable>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Segment>
                          <RBAC
                            yes={(
                                <Accordion
                                  title="Wi-Fi Settings"
                                  color="grey"
                                  fontsize="12px"
                                  marginbottom="18px"
                                >
                                <WifiSetting
                                  ssid={ssid}
                                  passphrase={passphrase}
                                  errorMessages={errorMessages}
                                  wifi_schedule_date={wifi_schedule_date}
                                  wifi_schedule_time={wifi_schedule_time}
                                  preferredTimeOptions={
                                    this.preferredTimeOptions
                                  }
                                  handleInputClick={this.handleInputClick}
                                />
                                </Accordion>
                           )}
                          />
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}
                {this.props.editMode && ['VM3', 'VM1', 'VM2', 'VM4P', 'VM4'].includes(details.model) && (
                  <Grid columns={4} doubling stackable>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Segment>
                          <RBAC
                            yes={(
                                <Accordion
                                  title="Preferred Access Point Settings"
                                  color="grey"
                                  fontsize="12px"
                                  marginbottom="18px"
                                >
                                  <Button
                                    text
                                    fontsize="12px"
                                    onClick={this.clearAPSettings}
                                    paddingLeft="0"
                                    paddingBottom="10px"
                                  >
                                    Clear AP Settings
                                  </Button>
                                <PreferredAPSettings
                                  bssid={bssid}
                                  channel={channel}
                                  errorMessages={errorMessages}
                                  handleInputClick={this.handleInputClick}
                                />
                                </Accordion>
                           )}
                          />
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}
              </BorderContainer>
              <ResourceSection>Measurement Settings</ResourceSection>
              <BorderContainer>
                <Grid columns={3} doubling stackable>
                  {!details.utilization_detection &&
                    details.bluetooth_p2p_enabled &&
                    details.master_device && (
                      <FlexContainer marginbottom="0.5em" margintop="0.8em">
                        <Info aria-hidden="true" />
                        <TextLabel>
                          Measurements are controlled by the master mote{' '}
                          {details.master_device} over bluetooth. Below
                          measurement setting is used as fallback schedule.
                        </TextLabel>
                      </FlexContainer>
                  )}
                  <Fragment>
                    <Grid.Row>
                      <Grid.Column>
                        <Segment>
                          <ResourceItemLabel>Schedule</ResourceItemLabel>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px'
                            }}
                          >
                            <ResourceItem>
                              {humanize(details.overall.type)}
                            </ResourceItem>
                            {(details.overall.type === 'schedule_based' ||
                              details.overall.type === 'state_based') && (
                              <ScheduleButton
                                text
                                onClick={() =>
                                  this.setState({ openScheduler: true })
                                }
                                fontSize="12px"
                                paddingBottom=""
                              >
                                {this.state.editMode ? 'Set' : 'View'}
                              </ScheduleButton>
                            )}
                          </div>
                        </Segment>
                      </Grid.Column>
                      {['VM3', 'VM4P', 'GSP', 'VM4', 'GS'].includes(details.model) && (
                        <Grid.Column>
                          <Segment>
                            {this.renderField(
                              'select',
                              'Reporting Interval',
                              this.filterReportingIntervals(),
                              details.reporting_interval,
                              false,
                              'reporting_interval'
                            )}
                          </Segment>
                        </Grid.Column>
                      )}
                      {['VM3', 'VM4P', 'GSP', 'VM4', 'GS'].includes(details.model) && (
                        <>
                          <Grid.Column>
                            <Segment>
                              <ResourceItemLabel>Fs Range</ResourceItemLabel>
                              {this.renderField(
                                'select',
                                '',
                                [4, 8, 16],
                                details.fs_range,
                                false,
                                'fs_range'
                              )}
                            </Segment>
                          </Grid.Column>
                        </>
                      )}
                    </Grid.Row>
                  </Fragment>
                  <ResourceSubSection>Sampling</ResourceSubSection>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        {this.renderField(
                          'select',
                          'High Resolution',
                          metadata[details.model].measurement_settings.sampling[
                            '2'
                          ],
                          details.high_res.sampling,
                          false,
                          'high_res.sampling'
                        )}
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        {this.renderField(
                          'select',
                          'Full Bandwidth',
                          metadata[details.model].measurement_settings.sampling[
                            '1'
                          ],
                          details.full_bandwidth.sampling,
                          false,
                          'full_bandwidth.sampling'
                        )}
                      </Segment>
                    </Grid.Column>
                    {/* <Grid.Column>
                    <Segment>
                      <FlexContainer direction="column">
                        <ResourceItemLabel>Overalls</ResourceItemLabel>
                        <FlexContainer marginbottom="0.5em">
                          <Info aria-hidden="true" size="1x" />
                          <TextLabel>RMS, Peak and Peak 2 Peak are computed using a different sampling configuration.</TextLabel>
                        </FlexContainer>
                      </FlexContainer>
                    </Segment>
                  </Grid.Column> */}
                  </Grid.Row>
                  <ResourceSubSection>
                    High Pass Filter Cutoff
                  </ResourceSubSection>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        {this.renderField(
                          'select',
                          'High Resolution',
                          metadata[details.model].measurement_settings
                            .high_pass_filter_cutoff['2'],
                          details.high_res.high_pass_filter_cutoff,
                          false,
                          'high_res.high_pass_filter_cutoff'
                        )}
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        {metadata[details.model].measurement_settings
                          .high_pass_filter_cutoff['1'] &&
                          this.renderField(
                            'select',
                            'Full Bandwidth',
                            metadata[details.model].measurement_settings
                              .high_pass_filter_cutoff['1'],
                            details.full_bandwidth.high_pass_filter_cutoff,
                            false,
                            'full_bandwidth.high_pass_filter_cutoff'
                          )}
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </BorderContainer>
              {(isPetasenseAdmin(user) || isPartnerAdmin(user)) && (
                <>
                  <ResourceSection>Advanced Device Details</ResourceSection>
                  <BorderContainer>
                    <Grid columns={3} doubling stackable>
                      <Grid.Row>
                        <Grid.Column>
                          <Segment>
                            <ResourceItemLabel>Device Id</ResourceItemLabel>
                            <ResourceItem>{details.device_id}</ResourceItem>
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                          <Segment>
                            <ResourceItemLabel>Mac Address</ResourceItemLabel>
                            <ResourceItem>{details.mac_address}</ResourceItem>
                          </Segment>
                        </Grid.Column>
                        {isPetasenseAdmin(user) && (
                          <Grid.Column>
                            {details.agent_url && (
                              <Segment>
                                <ResourceItemLabel>Imp Page</ResourceItemLabel>
                                <ResourceItem>
                                  <AnalysisButton text>
                                    <a
                                      href={getImpUrl(details.device_id)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        color: primaryColor,
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        letterSpacing: '1.2px'
                                      }}
                                    >
                                      URL
                                    </a>
                                  </AnalysisButton>
                                </ResourceItem>
                              </Segment>
                            )}
                          </Grid.Column>
                        )}
                      </Grid.Row>
                      <Grid.Row columns={3}>
                        {isPetasenseAdmin(user) && (
                          <>
                            {details.model === 'VM1' || details.model === 'VM2' ? (
                              <Grid.Column>
                                <Segment>
                                  <ResourceItemLabel>Logs</ResourceItemLabel>
                                  <ResourceItem>
                                    <a
                                      href={getFullEndPointUrl(ENDPOINT.DOWNLOAD_DEVICES_LOGS(details.id))}
                                      download
                                      style={{
                                        color: primaryColor,
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        letterSpacing: '1.2px'
                                      }}
                                    >
                                      Download
                                    </a>
                                  </ResourceItem>
                                </Segment>
                              </Grid.Column>
                            ) : (
                              ['VM3', 'VM4P', 'GSP', 'VM4', 'GS'].includes(details.model) && (
                                <>
                                  <Grid.Column>
                                    <Segment>
                                      <ResourceItemLabel>Logs</ResourceItemLabel>
                                      <ResourceItem>
                                        <a
                                          href={getDeviceGCPLogsUrl(details.serial_number)}
                                          style={{
                                            color: primaryColor,
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            letterSpacing: '1.2px'
                                          }}
                                          target="blank"
                                        >
                                          View
                                        </a>
                                      </ResourceItem>
                                    </Segment>
                                  </Grid.Column>
                                </>
                              )
                            )}
                          </>
                        )}
                        <Grid.Column>
                          <Segment>
                            <ResourceItemLabel>Analysis</ResourceItemLabel>
                            <ResourceItem>
                              <AnalysisButton
                                text
                                onClick={this.handleLogAnalysisClick}
                                fontsize="12px"
                                paddingBottom=""
                              >
                                View Analysis
                              </AnalysisButton>
                            </ResourceItem>
                          </Segment>
                        </Grid.Column>
                        {['VM3', 'VM4P', 'GSP', 'VM4', 'GS'].includes(details.model) && (
                          <Grid.Column>
                            <Segment>
                              <ResourceItemLabel>
                                Last Reported Info
                              </ResourceItemLabel>
                              <ResourceItem>
                                <AnalysisButton
                                  text
                                  onClick={this.handleLastReportedClick}
                                  fontsize="12px"
                                  paddingBottom=""
                                >
                                  View
                                </AnalysisButton>
                              </ResourceItem>
                            </Segment>
                          </Grid.Column>
                        )}
                      </Grid.Row>
                      <Grid.Row>
                      <Grid.Column>
                        <Segment>
                          <ResourceItemLabel>Config</ResourceItemLabel>
                          <ResourceItem style={{ color: 'red' }}>
                                    <a
                                      href={getFullEndPointUrl(ENDPOINT.DEVICE_CONFIG(details.serial_number))}
                                      style={{
                                        color: primaryColor,
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        letterSpacing: '1.2px'
                                      }}
                                      target="blank"
                                    >
                                      View
                                    </a>
                          </ResourceItem>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column>
                        <Segment>
                          <ResourceItemLabel>Config Histories</ResourceItemLabel>
                          <ResourceItem style={{ color: 'red' }}>
                                    <a
                                      href={getFullEndPointUrl(ENDPOINT.DEVICE_CONFIG_HISTORY(details.serial_number))}
                                      style={{
                                        color: primaryColor,
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        letterSpacing: '1.2px'
                                      }}
                                      target="blank"
                                    >
                                      View
                                    </a>
                          </ResourceItem>
                        </Segment>
                      </Grid.Column>
                      {details.last_battery_change_time && (
                        <Grid.Column>
                          <Segment>
                            <ResourceItemLabel>Last Battery Change Time</ResourceItemLabel>
                            <ResourceItem>{localTimeString(details.last_battery_change_time)}  ({this.calculateDaysDifference(details.last_battery_change_time)} days ago)</ResourceItem>
                          </Segment>
                        </Grid.Column>
                      )
                      }
                      </Grid.Row>
                    </Grid>
                  </BorderContainer>
                </>
              )}
              {showLogAnalysisModal && (
                <DeviceParameterAnalysis
                  moteId={details.id}
                  last_report={localTimeString(details.last_report)}
                  model={details.model}
                  serialNumber={details.serial_number}
                  onCancel={() =>
                    this.setState({ showLogAnalysisModal: false })
                  }
                />
              )}
              {showLastReportedModal && (
                <DeviceReportedInfoModal
                  serialNumber={details.serial_number}
                  onCancel={() =>
                    this.setState({ showLastReportedModal: false })
                  }
                />
              )}
              {this.state.editMode && (
                <ButtonsContainer>
                  <SaveButton
                    disabled={!saveEnabled}
                    onClick={this.onClickCheckAggressive}
                  >
                    Save
                  </SaveButton>
                  <SaveButton
                    secondary="#3C3D3A"
                    disabled={!saveEnabled}
                    onClick={this.onCancel}
                  >
                    Cancel
                  </SaveButton>
                </ButtonsContainer>
              )}
            </Fragment>
          )}
        </Container>
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  metadata: state.sensors.metadata.metadata,
  details: state.sensors.details.details,
  loading: state.sensors.details.loading,
  detailsError: state.sensors.details.error,
  updating: state.sensors.details.updating,
  errorList: state.sensors.details.errorList,
  user: state.user.user,
  primaryColor: state.companyReducer.partner.theme.primaryColor
});
const mapDispatchToProps = dispatch => ({
  sensorsActions: bindActionCreators(sensorsActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true
})(withTheme(VibrationMote));
