import React from 'react';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';
import { ToggleOn, ToggleOff } from 'common/images/FaIcons';
import { FlexContainer } from 'common/components/atoms';
import { ResourceSection } from 'common/components/atoms/ResourceDetails';
import { round } from 'common/utils';
import Label from 'common/typography/Label/Label';
import { humanize } from 'common/helpers';
import { connect } from 'react-redux';
import Error from 'home/AssetHierarchy/components/atoms/Error';
import InfoPopup from './InfoPopup';
import { validationInfo } from '../../../machineML.constants';

const ValidationItem = styled.span`
  color: ${props => props.isValid ? props.theme.colors.green : props.theme.colors.critical};
`;

const ValidationResults = ({ component, tagIds, toggleOutliers, outliersShown, theme, primaryColor, validationKey, showInline }) => {
  let tagsResults = component && component.tags_data;
  if (tagIds) {
    tagsResults = tagsResults.filter(res => tagIds.includes(res.tag_id));
  }
  return (
    <FlexContainer direction={showInline ? 'row' : 'column'} padding="10px">
      {!showInline && (
        <ResourceSection>Validation Results</ResourceSection>
      )}
      {!_.isEmpty(tagsResults) && tagsResults.map((tagResult) => {
        const validation = tagResult.validation[validationKey];
        const displayName = `${humanize(tagResult.tag_type)} on ${humanize(tagResult.location_description)}`;
        if (_.isEmpty(validation)) return <></>;
        return (
          <>
            {!showInline && (
              <Label fontSize="14px" marginBottom="0.5rem" marginTop="1.5rem">{displayName}</Label>
            )}
            {validation.error && (
              <span>
                <Error padding={showInline ? '0' : '1em 0'}>{validation.error}</Error>
              </span>
            )}
            {validation.slope_validation && (
              <span style={{ marginRight: '16px' }}>
                <Label labelSide="left">Slope<InfoPopup info={validationInfo(theme).slope} />:</Label>
                <ValidationItem isValid={validation.slope_validation.is_valid}>
                  {round(validation.slope_validation.slope, 5)}
                </ValidationItem>
              </span>
            )}
            {validation.cv_validation && (
              <span style={{ marginRight: '16px' }}>
                <Label labelSide="left">Std. Dev. / Mean<InfoPopup info={validationInfo(theme).cv} />:</Label>
                <ValidationItem isValid={validation.cv_validation.is_valid}>
                  {round(validation.cv_validation.cv, 5)}
                </ValidationItem>
              </span>
            )}
            {validation.outliers_validation && (
              <span>
                <Label labelSide="left">Local Outliers<InfoPopup info={validationInfo(theme).outlier} />:</Label>
                <ValidationItem isValid={validation.outliers_validation.is_valid}>
                  {validation.outliers_validation.is_valid ? 'No' : 'Yes'}
                </ValidationItem>
                {!validation.outliers_validation.is_valid && (
                  <span onClick={() => toggleOutliers(tagResult.tag_id)} style={{ marginLeft: '10px' }}>
                    {outliersShown[tagResult.tag_id] ? (
                      <span title="Outliers Shown">
                        <ToggleOn size="lg" style={{ color: { primaryColor }, cursor: 'pointer' }} />
                      </span>
                    ) : (
                      <span title="Outliers Hidden">
                        <ToggleOff size="lg" style={{ color: theme.colors.greyD, cursor: 'pointer' }} />
                      </span>
                    )}
                  </span>
                )}
              </span>
            )}
          </>
        );
      })}
    </FlexContainer>
  );
};

const mapStateToProps = state => ({
  primaryColor: state.companyReducer.partner.theme.primaryColor
});

export default connect(mapStateToProps)(withTheme(ValidationResults));
