import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FlexContainer from '../../../../../../common/components/atoms/FlexContainer';
import InfoText from '../atoms/InfoText';
import InfoTitle from '../atoms/InfoTitle';
import { ExclamationTriangle } from '../../../../../../common/images/FaIcons';

const AlertContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #EFF0EE;
  padding: 16px;
  border-radius: 4px;
  svg[data-icon = "exclamation-triangle"] {
  }
`;

const FlexColumn = styled(FlexContainer).attrs({ direction: 'column' })`
  display: inline-flex;
  margin-left: 1rem;
`;

const AlertMessage = ({ title, message, ...rest }) => (
  <AlertContainer {...rest}>
    <ExclamationTriangle size="1x" />
    <FlexColumn>
      {title ? <InfoTitle>{title}</InfoTitle> : ''}
      {message ? <InfoText>{message}</InfoText> : ''}
    </FlexColumn>
  </AlertContainer>
);

AlertMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
};

AlertMessage.defaultProps = {
  title: undefined,
  message: undefined
};

export default styled(AlertMessage)``;
