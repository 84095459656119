import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { withTheme } from 'styled-components';
import { history } from 'common/helpers/history';
import DonutChart from 'common/charts/GoogleCharts/DonutChart';
import WidgetChartContainer from 'common/components/molecules/WidgetChartContainer';
import Chart from 'common/components/Chart/Chart';
import FlexContainer from 'common/components/atoms/FlexContainer';
import H3 from 'common/typography/H3/H3';
import { chartRange } from 'common/utils';
import LoadingSvg from 'common/images/LoadingSvg';
import BarChart from 'common/charts/GoogleCharts/BarChart';
import { devicesHealthMetadata, widgetTypes } from './devicesHealth.constants';
import { getDevicesHealthData } from './devicesHealth.actions';

const DevicesHealthWidget = (props) => {
  useEffect(() => {
    const { getDevicesHealthData } = props;
    getDevicesHealthData(props.analytics_type, props.filters, props.start_date, props.end_date);
  }, [props.searchParams]);

  const [detailedView, setDetailedView] = useState(false);

  const data = props.devicesHealthData[props.analytics_type];
  const metadata = devicesHealthMetadata[props.analytics_type];

  const toggleDetailedView = () => {
    setDetailedView(!detailedView);
  };

  const handleDonutChartClick = (legendData, index) => {
    let route = metadata.route;
    const filter = [metadata.filters[index]];
    if (filter[0].value === 'legendData') filter[0].value = [legendData.text];
    if (filter[0].value === 'legendOther') {
      filter[0].value = [];
      data.forEach((item) => {
        if (item.label !== 'Other') filter[0].value.push(item.label);
      });
    }
    if (props.inDevicesTabClick) {
      props.inDevicesTabClick(filter);
    } else {
      const account_filter = props.filters.filter(x => x.name === 'account_id');
      if (props.adminDashboard.adminOpen) {
        route = `/admin-dashboard${route}`;
        if (!_.isEmpty(account_filter)) {
          account_filter[0].name = 'accounts';
          filter.push(account_filter[0]);
        }
      }
      history.push({
        pathname: route,
        state: {
          filter
        }
      });
    }
  };

  if (!data || data.loading) {
    return (
      <>
        <WidgetChartContainer className="ps-align-center">
          <LoadingSvg />
        </WidgetChartContainer>
      </>
    );
  }
  if (data.error) {
    return (
      <>
        <div>Error loading devices health data</div>
      </>
    );
  }
  return (
    <>
      {metadata.widgetType === widgetTypes.DONUT_CHART && (
        <DonutChart
          title={props.showTitle ? metadata.title : ''}
          data={data}
          resourceName="Devices"
          colors={metadata.colors}
          handleClick={handleDonutChartClick}
          containerPadding="1rem"
          containerHeight="220px"
          formatData
          {...props}
        />
      )}
      {metadata.widgetType === widgetTypes.LINE_CHART && (
        <>
          <WidgetChartContainer hoverPointer={!!metadata.detailedView} onClick={metadata.detailedView ? toggleDetailedView : () => { }} first={props.first} paddingBottom="30px" paddingRight="40px">
            <FlexContainer direction="column">
              <FlexContainer padding="20px 0px" alignItems="center" justifyContent="space-between">
                <H3>{metadata.title}</H3>
              </FlexContainer>
              <Chart
                type="line"
                chartName={metadata.id}
                data={[data]}
                range={chartRange(data)}
                height="200px"
                noLabel
                xAxisTicks={5}
              />
            </FlexContainer>
          </WidgetChartContainer>
          {metadata.detailedView && detailedView && <metadata.detailedView close={toggleDetailedView} filters={props.filters} />}
        </>
      )}
      {metadata.widgetType === widgetTypes.BAR_CHART && (
        <BarChart
          data={data}
          orientation="vertical"
          isInteractive={false}
          chartArea={{ width: '95%' }}
          colors={[props.theme.primaryColor]}
          yLabelFontSize={10}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  devicesHealthData: state.devicesHealth.devicesHealth,
  adminDashboard: state.adminDashboard
});

const mapDispatchToProps = dispatch => bindActionCreators({ getDevicesHealthData }, dispatch);

DevicesHealthWidget.propTypes = {
  first: PropTypes.bool,
  inDevicesTabClick: PropTypes.func,
  titlePaddingBottom: PropTypes.string,
  analytics_type: PropTypes.string,
  showTitle: PropTypes.bool,
  filters: PropTypes.array
};

DevicesHealthWidget.defaultProps = {
  first: false,
  inDevicesTabClick: null,
  titlePaddingBottom: null,
  analytics_type: null,
  showTitle: true,
  filters: []
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(DevicesHealthWidget));
