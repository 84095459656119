import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import moment from 'moment';
import * as _ from 'lodash';

import * as utils from 'common/utils';
import { history } from 'common/helpers';
import LoadingSvg from 'common/components/atoms/Loading';
import * as helpers from 'common/components/Chart/utils/helpers';
import FlexContainer from 'common/components/atoms/FlexContainer';
import ChartOptions from 'common/components/Chart/components/ChartOptions';
import { mapNames, utilColors, defaultChartSettings, vectorTagTypes } from 'common/charts/constants';
import { getTagTemplateMetadata } from 'home/TagTemplates/actions/tagTemplates.actions';
import CompareTagsModal from 'home/Machines/CompareTags/components/CompareTagsModal';
import { getChartSettingsFromSession, toggleShowSettingInSession } from 'home/AssetHierarchy/actions/assetDetails.actions';
import { CHART_TYPES } from '../../constants/assetHierarchy.constants';
import * as assetDetailsActions from '../../actions/assetDetails.actions';
import * as spectrumFeatureActions from '../../actions/spectrumFeatures.actions';
import * as userActions from '../../../../onboarding/user.actions';
import * as hierarchyUtils from '../../utils/assetHierarchyUtils';
import ChartItem from './ChartItem';
import ChartMultilineItem from './ChartMultilineItem';
import Error from '../atoms/Error';
import NoteForm from '../organisms/NoteForm';
import AddChartModal from '../organisms/AddChartModal';
import AddTagModal from '../organisms/AddTagModal';
import EnvelopeAlarmModal from '../organisms/EnvelopeAlarm/EnvelopeAlarmModal';
import OverallAlarmModal from '../organisms/OverallAlarm/OverallAlarmModal';
import FeatureTrendModal from '../organisms/FeatureTrendModal';
import FeatureModal from '../organisms/FeatureModal';
import DemodSpectrumSettingsModal from '../organisms/DemodSpectrumSettingsModal';
import ChartTab from '../../../../common/components/Chart/components/ChartTab';
import { paginateAndFetchChartData } from '../../helpers/helpers';

const ConfigContainer = styled.div`
  ${props => props.background && `background: ${props.background};`}
  overflow: auto;
  height: 100%;
`;

class ChartsView extends Component {
  constructor(props) {
    super(props);
    this.toggleCalendarExpand = this.toggleCalendarExpand.bind(this);
    this.getChartData = this.getChartData.bind(this);
    this.mouseoverChart = this.mouseoverChart.bind(this);
    this.calendarSelect = this.calendarSelect.bind(this);
    this.setInitialSelection = this.setInitialSelection.bind(this);
    this.getLabelAndUnits = this.getLabelAndUnits.bind(this);
    this.refreshChart = this.refreshChart.bind(this);
    this.openAddEditFeatures = this.openAddEditFeatures.bind(this);
    this.closeAddEditFeature = this.closeAddEditFeature.bind(this);
    this.getWaveAndSpecForId = this.getWaveAndSpecForId.bind(this);
    this.onFeatureDetailsClick = this.onFeatureDetailsClick.bind(this);
    this.closeFeatureDetailsClick = this.closeFeatureDetailsClick.bind(this);
    this.toggleOverallAlarm = this.toggleOverallAlarm.bind(this);
    this.toggleAddingNote = this.toggleAddingNote.bind(this);
    this.getWaveformAndSpectrum = this.getWaveformAndSpectrum.bind(this);
    this.openRULSettings = this.openRULSettings.bind(this);
    this.closeRULSettings = this.closeRULSettings.bind(this);

    this.state = {
      showCharts: true,
      brushSelected: null,
      amp_type: '',
      feature: '',
      config: {},
      tag: {},
      measurement_type: 1,
      chartType: '',
      addingNote: false,
      editTrend: false,
      envelopeAlarm: false,
      selectedRange: undefined,
      days: 30,
      calendarExpanded: false,
      customDayFrom: '',
      customDayTo: '',
      number_of_spectrums: 10,
      filterMin: '',
      filterMax: '',
      selection: {},
      bin_nos: [],
      componentNode: null,
      showBaseline: !!getChartSettingsFromSession().showBaseline,
      loadingWaterfall: false,
      logScale: true,
      paginationLoading: false,
      chartLoading: false
    };
  }

  componentDidMount = () => {
    // updates the calendar from url timestamp
    this.setSessionStorageFromUrl();

    this.updateCalendar();
    this.props.getTagTemplateMetadata();
    const { currentAccount } = this.props;
    if (_.isEmpty(currentAccount)) {
      this.props.userActions.getCurrentAccount();
    }
    this.updateBinNos();
    const config = this.props.previewConfig ? this.props.previewConfig : this.getConfigFromHierarchy(true);
    if (_.isEmpty(config)) return;
    this.setState({
      config,
      componentNode: hierarchyUtils.getComponentNodeByConfigId(this.props.assetHierarchy.hierarchy, config.id)
    });
    const tag = this.props.previewTag ? this.props.previewTag : this.getTagFromHierarchy();
    if (_.isEmpty(tag)) return;
    this.setState({ tag });
  }

  resetState = () => {
    this.setState({
      addingNote: false,
      showCharts: true,
      editTrend: false,
      editTagInfo: false,
      editDemod: false,
      editRULSettings: false,
      openFeature: false,
      openFeatureDetails: false,
      openOverallAlarm: false,
      envelopeAlarm: false,
      loadingWaterfall: false,
      loadingPreViewConfig: false,
      tag: {}
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const settings = this.props.searchParams ? this.props.searchParams : Object.fromEntries(urlParams);
    if (this.props.match.params.config_id !== prevProps.match.params.config_id) {
      this.resetState();
    }
    if (!_.isEqual(prevProps.breadcrumb.machine, this.props.breadcrumb.machine)) {
      // updates the bin_nos from url once machine_id is availbale
      this.setSessionStorageFromUrl();

      this.updateBinNos();
    }

    if (!this.props.previewConfig) {
      const config = this.getConfigFromHierarchy(this.props.match.params.config_id !== prevProps.match.params.config_id);
      if (_.isEmpty(config)) return;
      if (!_.isEqual(this.state.config, config)) {
        if (this.props.updateConfig) {
          this.props.updateConfig(config);
        }
        this.setState({
          config,
          brushSelected: null,
          componentNode: hierarchyUtils.getComponentNodeByConfigId(this.props.assetHierarchy.hierarchy, config.id)
        });
      }
    }

    if (!_.isEqual(prevState.chartType, this.state.chartType)) {
      this.setState({ brushSelected: null, addingNote: false });
    }

    if (!_.isEmpty(this.state.config) &&
        this.state.config.default &&
        !_.isEmpty(this.state.tag) &&
        prevState.tag.structure_type !== this.state.tag.structure_type
    ) {
      this.updateChartSettings();
    }

    const tag = this.props.previewTag ? this.props.previewTag : this.getTagFromHierarchy();
    if (_.isEmpty(tag)) {
      if (this.state.config && this.state.config.default) return;
    }
    if (!_.isEqual(this.state.tag, tag)) {
      const tag_amp_types = helpers.getTagAmplitudeTypes(tag);
      if (tag_amp_types && this.state.amp_type && !tag_amp_types.includes(this.state.amp_type)) {
        this.setState({
          tag,
          amp_type: tag.type === 'vibration' ? 'velocity' : tag_amp_types[0]
        }, this.updateUrlQueryString);
      } else {
        this.setState({ tag });
      }
      this.updateChartTypeIfRequired();
      if (this.props.machine_id ? this.props.machine_id : this.props.breadcrumb.machine.id) {
        this.setSessionStorageFromUrl(tag.structure_type);
      }
      if (this.state.feature && ['vibration', 'current'].includes(tag.type) &&
          ![...tag.features, ...tag.spectrum_features].includes(this.state.feature)) {
        this.setState({ feature: 'rms' });
      }
    }
    if ((!this.state.config.trends_data && this.state.config.config_id && !this.loading) ||
    (!_.isEqual(this.state.config.trends_config, prevState.config.trends_config))) {
      this.resetState();
      const params = this.getParamsForChartsData();
      this.loading = true;
      if (params.amp_type && !helpers.getTagAmplitudeTypes(tag).includes(params.amp_type)) {
        params.amp_type = 'velocity';
      }
      if (tag.type !== 'vibration') delete params.amp_type;
      if (tag.type !== 'vibration' && tag.type !== 'current') delete params.signal_type;

      if (this.props.previewConfig) {
        params.amp_type = settings.amp_type;
        if (params.amp_type !== 'demod') delete params.signal_type;
        if (settings.amp_type === 'demod') {
          params.signal_type = 'demod';
          params.amp_type = 'acceleration';
        }
      }

      this.setState({ paginationLoading: true, chartLoading: true });
      paginateAndFetchChartData(params, (res) => {
        if (!res) return;
        const tag_id = res[0].tag_id;
        const trendData = res[0].trend_data;
        if (trendData.length > 0 && this.state.tag.structure_type === 'vector') {
          const data = this.getDataFromTimestamp(trendData);
          const timestampFromData = data ? data.x : trendData[trendData.length - 1].x;
          const timestamp = this.props.previewConfig ? settings.timestamp : timestampFromData;
          const params = {
            tag_id,
            timestamp
          };
          if (this.state.amp_type) params.amp_type = this.state.amp_type;
          if (this.state.feature) params.feature = this.state.feature;
          this.getMeasurementData(tag_id, params);
        }

        this.loading = false;
        this.setInitialSelection();
      }, [tag], this.fetchChartData).then(
        () => this.setState({ paginationLoading: false, chartLoading: false })
      );
    }

    if (this.state.config && this.state.config.default && this.state.chartType === 'waterfall_spectrum') {
      this.fetchWaterfallDataIfRequired(prevState);
    }
    if (this.state.config && !this.state.config.default && !_.isEmpty(this.state.tag)) this.setState({ tag: {} });
  }

  updateBinNos = () => {
    const chartSettings = this.getChartSettingsFromSession();
    const machine_id = this.props.machine_id ? this.props.machine_id : this.props.breadcrumb.machine.id;
    if (chartSettings && chartSettings.bin_nos && chartSettings.bin_nos[machine_id]) {
      const bin_nos = chartSettings.bin_nos[machine_id];
      if (this.state.bin_nos !== bin_nos) this.setState({ bin_nos });
    } else {
      this.setState({ bin_nos: [] });
    }
  }

  updateChartTypeIfRequired = () => {
    const shownChartTypes = CHART_TYPES.filter(t => !this.isHidden(t));
    if (!shownChartTypes.includes(this.state.chartType)) {
      this.setState({ chartType: shownChartTypes[0] });
    }
  }

  updateChartSettings = () => {
    const { config } = this.state;
    const { structure_type } = this.state.tag;
    const chartSettings = this.getChartSettingsFromSession();
    const urlSettings = this.getChartSettingsFromUrl(structure_type);
    if (urlSettings && urlSettings[structure_type]) {
      this.setState({ ...urlSettings[structure_type] });
    } else if (chartSettings && chartSettings[structure_type]) {
      this.setState({
        ...chartSettings[structure_type]
      });
    } else {
      this.setState({
        ...defaultChartSettings[structure_type],
        ...(config.trends_config[0].amp_type && { amp_type: config.trends_config[0].amp_type }),
        ...(config.trends_config[0].feature && { feature: config.trends_config[0].feature })
      }, () => this.setChartSettingsInSession());
    }
  }

  updateCalendar = () => {
    const chartSettings = this.getChartSettingsFromSession();
    if (chartSettings && chartSettings.calendar) {
      const calendarSettings = chartSettings.calendar;
      if (calendarSettings.customDayFrom) calendarSettings.customDayFrom = new Date(calendarSettings.customDayFrom);
      if (calendarSettings.customDayTo) calendarSettings.customDayTo = new Date(calendarSettings.customDayTo);
      if (calendarSettings.days === null) calendarSettings.days = undefined;

      this.setState({
        ...chartSettings.calendar
      });
    }
  }

  fetchWaterfallDataIfRequired = (prevState) => {
    if (this.state.loadingWaterfall) return;
    const { tag, config, amp_type } = this.state;
    if (!tag || _.isEmpty(tag)) return;

    const params = {
      number_of_spectrums: this.state.number_of_spectrums,
      show_baseline: this.state.showBaseline
    };
    if (amp_type === 'demod') params.type = 'demod';
    if (amp_type) params.amp_type = amp_type === 'demod' ? 'acceleration' : amp_type;
    else params.amp_type = this.getAmpTypeAndFeature().amp_type;
    if (this.state.days !== 'custom' && this.state.days !== undefined) {
      params.days = this.state.days;
    } else if (this.state.days === 'custom') {
      params.from_timestamp = this.state.customDayFrom;
      params.to_timestamp = this.state.customDayTo;
    }
    if (this.state.measurement_type) params.measurement_type = this.state.measurement_type;
    if (!_.isEmpty(this.state.bin_nos)) params.bin_nos = this.state.bin_nos;
    if (
      !this.state.config.waterfall_spectrum ||
      (prevState.config && prevState.config.trends_config !== this.state.config.trends_config) ||
      prevState.customDayFrom !== this.state.customDayFrom ||
      prevState.customDayTo !== this.state.customDayTo ||
      prevState.days !== this.state.days ||
      prevState.number_of_spectrums !== this.state.number_of_spectrums ||
      prevState.amp_type !== this.state.amp_type ||
      prevState.measurement_type !== this.state.measurement_type ||
      (prevState.chartType !== this.state.chartType) ||
      prevState.tag.bandpass_lower_cutoff !== this.state.tag.bandpass_lower_cutoff ||
      prevState.tag.bandpass_upper_cutoff !== this.state.tag.bandpass_upper_cutoff ||
      prevState.bin_nos !== this.state.bin_nos ||
      prevState.showBaseline !== this.state.showBaseline
    ) {
      this.setState({ loadingWaterfall: true });
      if (this.props.previewConfig) {
        this.setState({ paginationLoading: true });
      }
      this.props.assetDetailsActions.getTagWaterfallSpectrum(tag.id, params, config.config_id).then(
        (res) => {
          this.setState(prevState => ({
            loadingWaterfall: false,
            paginationLoading: false,
            config: {
              ...prevState.config,
              waterfall_spectrum: res,
            },
          }));
        }
      )
        .catch((error) => {
          if (error) {
            this.setState({ loadingWaterfall: false, paginationLoading: false });
          }
        });
    }
  }

  getConfigFromHierarchy = (switchAccountIfRequired = false) => {
    const { sites } = this.props.assetHierarchy;
    const { machine_id, site_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    const config_id = Number.parseInt(this.props.match.params.config_id, 10);
    const configList = hierarchyUtils.getConfigList(machine_id || this.props.machine_id, site_id, sites);
    if (!configList || configList.loading) return {};
    const config = configList.find(item => item.config_id === config_id);
    if (config) return config;
    if (this.props.previewConfig) return this.props.previewConfig;
    if (switchAccountIfRequired) this.props.assetDetailsActions.validateAndSwitchAccountOnConfig(config_id);
    return {};
  }

  getTagFromHierarchy = () => {
    if (this.props.previewTag) return this.props.previewTag;
    const { sites } = this.props.assetHierarchy;
    const { machine_id, site_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    const tagsList = hierarchyUtils.getTagsList(machine_id || this.props.machine_id, site_id, sites);
    if (_.isEmpty(tagsList) || tagsList.loading ||
    !(this.state.config && this.state.config.default)) return {};
    return tagsList.find(item => item.id === this.state.config.trends_config[0].tag_id) || {};
  }

  setCalendarInSession = () => {
    const { days, customDayFrom, customDayTo } = this.state;
    const chartSettings = this.getChartSettingsFromSession();
    chartSettings.calendar = { days: days || null, customDayFrom, customDayTo };
    sessionStorage.setItem('chartSettings', JSON.stringify(chartSettings));
  }

  getChartSettingsFromSession = () => {
    const chartSettings = sessionStorage.getItem('chartSettings');
    return chartSettings ? JSON.parse(chartSettings) : {};
  }

  setChartSettingsInSession = () => {
    const { tag, chartType, amp_type, measurement_type, feature } = this.state;
    const chartSettings = this.getChartSettingsFromSession();
    chartSettings[tag.structure_type] = { amp_type, feature, measurement_type, chartType };
    sessionStorage.setItem('chartSettings', JSON.stringify(chartSettings));
    this.updateUrlQueryString();
  }

  setBinNosInSession = () => {
    const { bin_nos } = this.state;
    const { machine_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    const chartSettings = this.getChartSettingsFromSession();
    if (!chartSettings.bin_nos) chartSettings.bin_nos = {};
    chartSettings.bin_nos[machine_id] = bin_nos;
    sessionStorage.setItem('chartSettings', JSON.stringify(chartSettings));
    this.updateUrlQueryString();
  }

  setTimeStampInSession = (timestamp) => {
    const { machine_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    const chartSettings = this.getChartSettingsFromSession();
    if (!chartSettings.timestamp) chartSettings.timestamp = {};
    chartSettings.timestamp[machine_id] = timestamp;
    sessionStorage.setItem('chartSettings', JSON.stringify(chartSettings));
    this.updateUrlQueryString(timestamp);
  }

  updateUrlQueryString = (timestamp = null) => {
    const urlParams = new URLSearchParams(this.props.location.search);
    let settings = this.props.searchParams ? this.props.searchParams : Object.fromEntries(urlParams);
    if (timestamp) {
      if (timestamp instanceof Date) timestamp = timestamp.toISOString();
      else return;
      settings.timestamp = timestamp;
    }
    const { chartType, amp_type, measurement_type, feature, bin_nos } = this.state;
    settings = {
      timestamp: settings.timestamp,
      chartType,
      amp_type,
      measurement_type,
      feature
    };
    if (!_.isEmpty(bin_nos)) {
      settings.bin_nos = bin_nos.join(',');
    }
    const queryString = utils.generateQueryString(settings);
    const pathname = this.props.location.pathname;
    history.replace({
      pathname,
      search: queryString
    });
  }

  setSessionStorageFromUrl = (str_type) => {
    const chartSettings = {
      ...this.getChartSettingsFromSession(),
      ...this.getChartSettingsFromUrl(str_type)
    };
    if (!_.isEmpty(chartSettings)) sessionStorage.setItem('chartSettings', JSON.stringify(chartSettings));
  };

  getChartSettingsFromUrl = (str_type) => {
    const { machine_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    const urlParams = new URLSearchParams(this.props.searchParams ? this.props.searchParams : this.props.location.search);
    const settings = Object.fromEntries(urlParams);
    const oldSettings = this.getChartSettingsFromSession();
    if (settings.timestamp) {
      const currDate = new Date();
      let days = 30;
      if (oldSettings.calendar && oldSettings.calendar.days && !Number.isNaN(oldSettings.calendar.days)) {
        days = parseInt(oldSettings.calendar.days, 10);
      }
      const fromDate = new Date(moment().subtract(days, 'days').toString());
      const givenDate = new Date(moment(settings.timestamp).toString());
      if (givenDate.getTime() > fromDate.getTime() && givenDate.getTime() <= currDate.getTime()) {
        settings.calendar = {
          days,
          customDayFrom: null,
          customDayTo: null
        };
      } else {
        settings.calendar = {
          days: 'custom',
          customDayFrom: new Date(moment(settings.timestamp).subtract(10, 'days').toString()),
          customDayTo: new Date(moment.min(moment(), moment(settings.timestamp).add(4, 'days')).toString())
        };
      }
    }
    Object.keys(settings).forEach((key) => {
      if (key === 'timestamp') {
        if (machine_id) settings[key] = { [machine_id]: settings[key] };
        else delete settings[key];
      } else if (key === 'amp_type' || key === 'feature' || key === 'measurement_type' || key === 'chartType') {
        if (!str_type) return;
        settings[str_type] = { ...(settings[str_type] || defaultChartSettings[str_type]),
          [key]: key === 'measurement_type' ? parseInt(settings[key], 10) : settings[key]
        };
        delete settings[key];
      } else if (key === 'bin_nos' && machine_id) {
        settings[key] = { [machine_id]: settings[key].split(',').map(bin => parseInt(bin, 10)) };
      }
    });
    return settings;
  }

  getAmpTypeAndFeature = () => {
    const { config } = this.state;
    const chartSettings = this.getChartSettingsFromSession();
    const tag_type = config.trends_config[0].tag_type;
    const structure_type = vectorTagTypes.includes(tag_type) ? 'vector' : 'scalar';
    if (chartSettings && chartSettings[structure_type]) {
      return chartSettings[structure_type];
    }
    return {
      amp_type: config.trends_config[0].amp_type,
      feature: config.trends_config[0].feature,
      chartType: tag_type === 'current' ? 'spectrum' : 'TSW',
    };
  }

  getParamsForChartsData = () => {
    const { currentAccount } = this.props;
    const { amp_type, feature, config, tag, measurement_type, days, customDayFrom, customDayTo, bin_nos, logScale } = this.state;
    let chartType = _.cloneDeep(this.state.chartType);
    const params = {
      config_id: config.config_id
    };
    const chartSettings = this.getAmpTypeAndFeature();
    if (!_.isEmpty(tag)) {
      if (tag.type === 'current') params.log_scale = logScale;
    } else if (config && config.trends_config[0].tag_type === 'current') {
      params.log_scale = logScale;
    }
    if (config.default) {
      if (amp_type) params.amp_type = amp_type;
      else params.amp_type = chartSettings.amp_type;

      if (config.trends_config[0].feature) {
        if (feature) params.feature = feature;
        else params.feature = chartSettings.feature;
      }
      if (!chartType) {
        chartType = chartSettings.chartType;
        this.setState({ chartType });
      }
    }

    if (days !== 'custom' && days !== undefined) {
      params.days = days;
    } else if (days === 'custom') {
      params.from_timestamp = customDayFrom;
      params.to_timestamp = customDayTo;
    }
    if (params.amp_type === 'demod') {
      params.signal_type = 'demod';
    }

    if (['vibration', 'current'].includes(config.trends_config[0].tag_type)) {
      params.measurement_type = _.isNumber(chartSettings.measurement_type) ?
        chartSettings.measurement_type :
        measurement_type;
      if (params.signal_type === 'demod') {
        if (params.measurement_type !== 1) {
          params.measurement_type = 1;
        }
        if (currentAccount.preferences && (params.feature !== 'rms' && params.feature !== 'peak')) {
          params.feature = currentAccount.preferences.chart_feature_preference;
        }
        this.setState({
          amp_type: params.amp_type,
          measurement_type: params.measurement_type,
          feature: params.feature
        }, () => this.setChartSettingsInSession());
        if (config.trends_config[0].tag_type === 'vibration') {
          if (config.default && !_.isEmpty(bin_nos)) params.bin_nos = bin_nos;
          params.amp_type = 'acceleration';
          if (params.amp_type === 'normal') params.amp_type = 'velocity';
        }
      }
    }
    return params;
  }

  toggleCalendarExpand() {
    this.setState(prevState => ({
      calendarExpanded: !prevState.calendarExpanded,
      selectedTabItem: ''
    }));
  }

  toggleShowTabOptions = (selectedTabItem) => {
    this.setState(prevState => ({
      selectedTabItem: selectedTabItem === prevState.selectedTabItem ? '' : selectedTabItem
    }));
  }

  calendarSelect(selectedRange, newCustomDayFrom = null, newCustomDayTo = null) {
    const { config, amp_type, feature, measurement_type, tag, bin_nos, logScale } = this.state;
    if (!config.trends_data) return;
    const newState = { selectedRange };
    const days = parseInt(selectedRange, 10);
    const params = {
      config_id: config.config_id,
      measurement_type
    };

    if (tag.type === 'current') params.log_scale = logScale;

    if (config && config.default && config.trends_config[0].tag_type === 'vibration' && !_.isEmpty(bin_nos)) params.bin_nos = bin_nos;

    if (config && config.default && config.trends_config[0].amp_type) {
      if (amp_type) params.amp_type = amp_type;
      else params.amp_type = config.trends_config[0].amp_type;
    }
    if (config && config.default && config.trends_config[0].feature) {
      if (feature) params.feature = feature;
      else params.feature = config.trends_config[0].feature;
    }

    if (params.amp_type === 'demod') {
      params.signal_type = 'demod';
      params.amp_type = 'acceleration';
    }
    if (params.amp_type === 'normal') params.amp_type = 'velocity';
    if (tag.type !== 'vibration') delete params.amp_type;

    if (selectedRange === 'custom' && newCustomDayFrom && newCustomDayTo) {
      params.from_timestamp = newCustomDayFrom;
      params.to_timestamp = newCustomDayTo;
      newState.customDayFrom = newCustomDayFrom;
      newState.customDayTo = newCustomDayTo;
    } else {
      newState.customDayFrom = null;
      newState.customDayTo = null;
    }
    if (!Number.isNaN(days)) {
      params.days = days;
    }
    newState.days = params.days;
    if (selectedRange === 'custom') {
      newState.days = 'custom';
    }
    if (this.props.previewConfig) {
      this.setState({ loadingPreViewConfig: true });
    } else {
      this.setState({ paginationLoading: true });
    }
    paginateAndFetchChartData(params, (res) => {
      if (!res) return;
      const tag_id = res[0].tag_id;
      const trendData = res[0].trend_data;
      if (trendData.length > 0 && tag.structure_type === 'vector') {
        const lastPointTimestamp = trendData[trendData.length - 1].x;
        const params = {
          tag_id,
          timestamp: lastPointTimestamp
        };
        if (amp_type) params.amp_type = amp_type;
        if (feature) params.feature = feature;
        this.getMeasurementData(tag_id, params);
      }
    }, [this.state.tag], this.fetchChartData).then(
      () => this.setState({ paginationLoading: false })
    );
    this.setState({
      ...newState,
      calendarExpanded: false
    }, () => this.setCalendarInSession());
  }

  fetchChartData = (params, pagination, callBackFn, noOfRequests, uniqueKey) => (
    this.props.assetDetailsActions.getChartsData(params, pagination, noOfRequests, uniqueKey).then(
      (res) => {
        if (this.props.previewConfig) {
          // handle pagination, reset data if uniqueKey belongs to new set of pagination calls
          const shouldResetData = !pagination || this.state.config.uniqueKey !== uniqueKey;
          this.setState(prevState => ({
            config: {
              ...prevState.config,
              uniqueKey,
              trends_data: _.isEmpty(prevState.config.trends_data) || shouldResetData ?
                res :
                _.map(prevState.config.trends_data, (td, i) => {
                  if (!_.isObject(td)) return td; // skip the loading key value pair
                  return {
                    ...td,
                    trend_data: [...res[i].trend_data, ...td.trend_data].sort(
                      (a, b) => (a.x - b.x)
                    )
                  };
                })
            }
          }));
        }
        callBackFn(res);
      }
    )
  );

  refreshChart(point = undefined) {
    const { config, tag, amp_type, feature, waveAndSpecSelection } = this.state;
    const params = this.getParamsForChartsData();
    const selectedIdx = config.trends_data[0].trend_data.findIndex(d => _.isEqual(d.x, waveAndSpecSelection.x));
    if (tag.type !== 'vibration') delete params.amp_type;
    if (params.amp_type === 'normal') params.amp_type = 'velocity';

    if (this.props.previewConfig) {
      this.setState({ loadingPreViewConfig: true });
    } else {
      this.setState({ paginationLoading: true });
    } paginateAndFetchChartData(params, (res) => {
      if (!res) return;
      const tag_id = res[0].tag_id;
      const trendData = res[0].trend_data;
      if (trendData.length > 0 && tag.structure_type === 'vector') {
        const lastPointTimestamp = trendData[trendData.length - 1].x;
        const params = {
          tag_id
        };
        if (amp_type) params.amp_type = amp_type;
        if (feature) params.feature = feature;
        if (!(point && point.afterOutlier)) {
          if (waveAndSpecSelection && waveAndSpecSelection.measurement_id) {
            const { x, measurement_id } = waveAndSpecSelection;
            const idx = trendData.findIndex(d => d.measurement_id === measurement_id);
            if (idx > -1) {
              this.setSelection(trendData[idx]);
            }
            params.timestamp = x;
          } else {
            params.timestamp = lastPointTimestamp;
          }
        } else {
          const idx = trendData.length <= selectedIdx ? trendData.length - 1 : selectedIdx;
          this.setState({
            waveAndSpecSelection: trendData[idx],
          });
          params.timestamp = trendData[idx].x;
        }
        this.getMeasurementData(tag_id, params);
      }

      if (trendData.length > 0 && tag.structure_type === 'scalar' && point && point.afterOutlier) {
        const idx = trendData.length <= selectedIdx ? trendData.length - 1 : selectedIdx;
        this.setState({
          waveAndSpecSelection: trendData[idx],
        });
      }
    }, [this.state.tag], this.fetchChartData).then(
      () => this.setState({ paginationLoading: false })
    );
  }

  getChartData(newparams) {
    const { config, tag, amp_type, feature, days, customDayFrom, customDayTo, measurement_type, waveAndSpecSelection, bin_nos, logScale } = this.state;
    const params = {
      amp_type: this.props.previewConfig ? this.props.searchParams.amp_type : amp_type,
      feature,
      config_id: config.config_id,
      measurement_type,
      bin_nos,
      ...newparams
    };
    if (params.amp_type === 'demod') {
      params.signal_type = 'demod';
    }
    if (_.isNil(params.log_scale) && tag.type === 'current') params.log_scale = logScale;

    if (days === 'custom') {
      params.from_timestamp = customDayFrom;
      params.to_timestamp = customDayTo;
    } else {
      params.days = days;
    }
    if (
      (newparams.amp_type && amp_type !== newparams.amp_type) ||
      (newparams.feature && feature !== newparams.feature) ||
      (newparams.measurement_type !== undefined && newparams.measurement_type !== measurement_type) ||
      (newparams.bin_nos !== bin_nos) ||
      (!_.isNil(newparams.logScale) && newparams.logScale !== logScale)
    ) {
      if (waveAndSpecSelection) newparams.timestamp = waveAndSpecSelection.x;
      if (!newparams.measurement_type && !newparams.bin_nos) {
        this.getWaveformAndSpectrum(newparams);
      }
      this.setState({
        amp_type: params.amp_type,
        feature: params.feature,
        measurement_type: params.measurement_type,
        bin_nos: params.bin_nos,
        logScale: !_.isNil(params.log_scale) ? params.log_scale : logScale
      }, () => {
        this.setChartSettingsInSession();
        this.setBinNosInSession();
      });
      if (config && config.default && !config.trends_config[0].amp_type) {
        delete params.amp_type;
      }
      if (config && config.default && !config.trends_config[0].feature) {
        delete params.feature;
      }
      if (config && config.default && !['vibration', 'current'].includes(config.trends_config[0].tag_type)) {
        delete params.measurement_type;
        delete params.bin_nos;
      } else if (_.isEmpty(params.bin_nos)) {
        delete params.bin_nos;
      }
      if (params.amp_type === 'demod') params.amp_type = 'acceleration';
      if (params.amp_type === 'normal') params.amp_type = 'velocity';
      if (tag.type !== 'vibration') delete params.amp_type;
      this.setState({ paginationLoading: true });
      paginateAndFetchChartData(params, (data) => {
        if (!data) return;
        const trendData = data[0].trend_data;
        if (waveAndSpecSelection && waveAndSpecSelection.measurement_id && !newparams.measurement_type && !newparams.bin_nos) {
          const { measurement_id } = waveAndSpecSelection;
          const idx = trendData.findIndex(d => d.measurement_id === measurement_id);
          if (idx > -1) {
            this.setSelection(trendData[idx]);
          }
        } else {
          const data = this.getDataFromTimestamp(trendData) || trendData[trendData.length - 1];
          if (!data) return;
          newparams.timestamp = data.x;
          this.getWaveformAndSpectrum(newparams);
          this.setSelection(data);
        }
      }, [this.state.tag], this.fetchChartData).then(
        () => this.setState({ paginationLoading: false })
      );
    }
    return null;
  }

  getSpectrumMarkers(timestamp, config, tag) {
    if (_.isEmpty(tag) || !_.includes(['vibration', 'current'], tag.type)) return;
    if (timestamp) {
      this.props.spectrumFeatureActions.getForcingFrequencies(tag.id, timestamp);
    } else if (!tag.forcingFrequencies) {
      const trend_data = config.trends_data && config.trends_data[0] && config.trends_data[0].trend_data;
      if (!_.isEmpty(trend_data)) {
        this.props.spectrumFeatureActions.getForcingFrequencies(tag.id, trend_data[trend_data.length - 1].x);
      } else {
        this.props.spectrumFeatureActions.getForcingFrequencies(tag.id);
      }
    }
    if (!tag.spectrumFeatures) {
      this.props.spectrumFeatureActions.getSpectrumFeatures(tag.id, config.config_id);
    }
  }

  getWaveformAndSpectrum(newparams) {
    const { amp_type, feature, tag, config, logScale } = this.state;
    const { getTagSpectrum, getTagWaveform, getTagCepstrum } = this.props.assetDetailsActions;
    if (!tag || tag.structure_type !== 'vector' || tag.type === 'utilization') return;
    const params = {
      feature,
      amp_type,
      ...newparams
    };
    if (params.amp_type === 'demod') {
      params.amp_type = 'acceleration';
      params.type = 'demod';
    }
    if (params.amp_type === 'normal') params.amp_type = 'velocity';
    if (tag.type === 'current' && _.isNil(params.log_scale)) params.log_scale = logScale;
    if (!config.spectrumFeatures) {
      this.props.spectrumFeatureActions.getSpectrumFeatures(tag.id, config.config_id);
    }
    if (this.props.previewConfig) {
      this.updatePreviewConfig(tag, params);
    } else {
      getTagSpectrum(tag.id, params, config.config_id);
      getTagWaveform(tag.id, params, config.config_id);
      if (tag.type === 'vibration') getTagCepstrum(tag.id, params, config.config_id);
    }
    this.getSpectrumMarkers(params.timestamp, config, tag);
    if (params.timestamp) {
      this.setTimeStampInSession(params.timestamp);
    }
  }

  updatePreviewConfig = (tag, params) => {
    const { getTagSpectrum, getTagWaveform, getTagCepstrum } = this.props.assetDetailsActions;
    this.setState(prevState => ({
      config: {
        ...prevState.config,
        spectrum: {
          loading: true
        }
      }
    }));
    getTagSpectrum(tag.id, params, null).then(
      (res) => {
        this.setState(prevState => ({
          config: {
            ...prevState.config,
            spectrum: res
          }
        }));
      });

    this.setState(prevState => ({
      config: {
        ...prevState.config,
        waveform: {
          loading: true
        }
      }
    }));
    getTagWaveform(tag.id, params, null)
      .then((res) => {
        this.setState(prevState => ({
          config: {
            ...prevState.config,
            waveform: res
          }
        }));
      });

    if (tag.type === 'vibration') {
      this.setState(prevState => ({
        config: {
          ...prevState.config,
          cepstrum: {
            loading: true
          }
        }
      }));
      getTagCepstrum(tag.id, params, null)
        .then((res) => {
          this.setState(prevState => ({
            config: {
              ...prevState.config,
              cepstrum: res
            }
          }));
        });
    }
  }

  getMeasurementData = (tag_id, params) => {
    const { getTagSpectrum, getTagWaveform, getTagCepstrum } = this.props.assetDetailsActions;
    const { config, tag, logScale } = this.state;
    if (params.amp_type === 'demod') {
      params.type = 'demod';
      params.amp_type = 'acceleration';
    }
    if (params.amp_type === 'normal') params.amp_type = 'velocity';
    if (tag.type === 'current' && _.isNil(params.log_scale)) params.log_scale = logScale;
    if (!config.spectrumFeatures) {
      this.props.spectrumFeatureActions.getSpectrumFeatures(tag.id, config.config_id);
    }
    if (this.props.previewConfig) {
      this.updatePreviewConfig(tag, params);
    } else {
      getTagSpectrum(tag_id, params, config.config_id);
      getTagWaveform(tag_id, params, config.config_id);
      if (tag.type === 'vibration') getTagCepstrum(tag_id, params, config.config_id);
    }
    this.getSpectrumMarkers(params.timestamp, config, tag);
    this.setTimeStampInSession(params.timestamp);
  }

  setLastHoveredChart = (type) => {
    this.setState({ lastHoveredChart: type });
  };

  setSelection = (data) => {
    this.setState({
      waveAndSpecSelection: {
        ...data,
        isDate: true,
        ...this.getLabelAndUnits(data)
      },
      selection: {
        ...data,
        isDate: true,
        ...this.getLabelAndUnits(data)
      }
    });
  }

  getDataFromTimestamp = (dataset) => {
    const chartSettings = this.getChartSettingsFromSession();
    const { machine_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    if (!chartSettings.timestamp || !chartSettings.timestamp[machine_id]) return null;
    return helpers.closestXDataPointOnDataset(dataset, new Date(chartSettings.timestamp[machine_id]), true);
  }

  setInitialSelection() {
    const { config } = this.state;
    if (
      config.trends_data &&
      !config.trends_data.loading &&
      config.trends_data[0] &&
      config.trends_data[0].trend_data &&
      config.trends_data[0].trend_data.length
    ) {
      const dataset = config.trends_data[0].trend_data;
      const data = this.getDataFromTimestamp(dataset) || dataset[dataset.length - 1];
      this.setSelection(data);
    }
  }

  getLabelAndUnits(data) {
    const { config } = this.state;
    const colors = utilColors;
    if (!config.trends_data || config.trends_data.loading || !config.trends_data[0]) {
      return {};
    }
    if (config.trends_data[0].tag_type !== 'utilization') {
      return {
        yTitle:
          mapNames[config.trends_data[0].feature] ||
          utils.CapitalizeEachWord(config.trends_data[0].tag_type),
        yUnit: config.trends_data[0].units,
        speedUnit: config.trends_data[0].speed_units
      };
    }
    return {
      y: (data.y + 1) % 3,
      yTitle: 'Status',
      color: colors[(data.y + 1) % 3]
    };
  }

  getWaveAndSpecForIdDebounced = _.debounce((selection) => {
    this.getWaveformAndSpectrum({ timestamp: selection.x });
  }, 500);

  getWaveAndSpecForId(selection) {
    const { tag } = this.state;
    const timestamp = selection.x;
    if (timestamp) {
      this.setTimeStampInSession(timestamp);
    }
    if (tag.structure_type === 'vector') {
      this.getWaveAndSpecForIdDebounced(selection);
    }
    // TODO: since we are now using this for scalars as well
    // better to refactor waveAndSpecSelection and getWaveAndSpecForId
    if (!tag.is_derived) {
      this.setState(prevState => ({
        waveAndSpecSelection: { ...prevState.selection, ...selection },
      }));
    }
  }

  storeBrushSelection = (selection) => {
    this.setState({
      brushSelected: selection
    });
  };

  mouseoverChart(selection) {
    this.setState({
      selection: {
        ...selection,
        isDate: true,
        ...this.getLabelAndUnits(selection)
      }
    });
  }

  openAddEditFeatures(feature = undefined) {
    // TODO: check if amp type from tag.props is correct here or should we use amp_type from state
    const { config, tag } = this.state;
    if (!config.spectrumFeatures) {
      this.props.spectrumFeatureActions.getSpectrumFeatures(tag.id, config.config_id).then(
        (res) => {
          const { config } = this.state;
          config.spectrumFeatures = { items: res };
          this.setState({ config });
        });
    }
    if (!config.spectrum) {
      this.props.assetDetailsActions.getTagSpectrum(
        tag.id,
        { amp_type: tag.amp_type },
        config.config_id
      ).then(
        (res) => {
          this.setState((prevState) => {
            const updatedConfig = { ...prevState.config };
            updatedConfig.spectrum = res;
            return { config: updatedConfig };
          });
        });
    }
    this.setState({
      showCharts: false,
      openFeature: true,
      featureToOpen: feature,
      addingNote: false
    });
  }

  closeAddEditFeature() {
    this.setState({
      openFeature: false,
      showCharts: true
    });
  }

  edit = () => {
    if (this.state.editTrend) this.setState({ editTrend: false, showCharts: true });
    else if (this.state.openFeature) this.setState({ openFeature: false, showCharts: true });
    else if (this.state.openOverallAlarm) this.setState({ openOverallAlarm: false, showCharts: true });
    else if (this.state.envelopeAlarm) this.setState({ envelopeAlarm: false, showCharts: true });
    else if (this.state.editDemod) this.setState({ editDemod: false, showCharts: true });
    else if (this.state.editRULSettings) this.setState({ editRULSettings: false });
    else this.setState({ editTrend: true, showCharts: false, addingNote: false });
  }

  openEnvelopeAlarm = () => {
    this.setState({
      envelopeAlarm: true,
      showCharts: false,
      addingNote: false,
      editTagInfo: false,
      editRULSettings: false
    });
  }

  closeEnvelopeAlarm = () => {
    this.setState({
      showCharts: true,
      envelopeAlarm: false
    });
  }

  openEditTrend = () => {
    this.setState({
      editTrend: true,
      showCharts: false,
      addingNote: false,
      editTagInfo: false,
      editRULSettings: false
    });
  }

  openEditTagInfo = () => {
    this.setState({
      editTagInfo: true,
      editTrend: false,
      showCharts: false,
      addingNote: false,
      editRULSettings: false
    });
  }

  openRULSettings = () => {
    let newStartTime = null;
    const { tag } = this.state;
    if (tag.rul_settings && (tag.rul_settings.start_time || tag.rul_settings.fdp)) {
      if (tag.rul_settings.start_time) newStartTime = moment(tag.rul_settings.start_time);
      else newStartTime = moment(tag.rul_settings.fdp);
      newStartTime = new Date(newStartTime.subtract(10, 'days'));
    }
    this.setState({
      editTagInfo: false,
      editTrend: false,
      showCharts: true,
      addingNote: false,
      editRULSettings: true
    }, () => newStartTime ? this.calendarSelect('custom', newStartTime, new Date(moment())) : {});
  }

  closeRULSettings = () => {
    this.setState({
      editTagInfo: false,
      editTrend: false,
      showCharts: true,
      addingNote: false,
      editRULSettings: false
    });
  }

  closeEditTrend = () => {
    this.setState({
      editTrend: false,
      editTagInfo: false,
      showCharts: true
    });
  }

  onFeatureDetailsClick(feature) {
    this.setState(prevState => ({
      openFeatureDetails: true,
      featureForDetails: feature,
      showCharts: !prevState.showCharts
    }));
  }

  closeFeatureDetailsClick() {
    this.setState(prevState => ({
      openFeatureDetails: false,
      showCharts: !prevState.showCharts
    }));
  }

  toggleOverallAlarm() {
    this.setState(prevState => ({
      openOverallAlarm: !prevState.openOverallAlarm,
      showCharts: !prevState.showCharts,
      addingNote: false
    }));
  }

  toggleAddingNote() {
    this.setState(prevState => ({
      addingNote: !prevState.addingNote,
    }));
  }

  setChartType = (chartType) => {
    if (chartType !== this.state.chartType) {
      this.setState({ chartType }, () => this.setChartSettingsInSession());
    }
  }

  setSpeedFilterValues = (filterMin, filterMax) => {
    this.setState({ filterMin, filterMax });
  }

  handleSpectraChange = (e, data) => {
    if (this.state.number_of_spectrums !== data.value) {
      this.setState({ number_of_spectrums: data.value });
    }
  }

  openEditDemod = () => {
    this.setState({
      editDemod: true,
      showCharts: false,
      addingNote: false
    });
  }

  closeEditDemod = (refetchDemod = false) => {
    this.setState({
      editDemod: false,
      showCharts: true
    });

    if (refetchDemod) {
      this.getChartData({ amp_type: 'demod' });
    }
  }

  openBaselinePage = () => {
    history.push(`/machines/${this.props.machine_id ? this.props.machine_id : this.props.breadcrumb.machine.id}/baseline`);
  }

  toggleShowBaseline = () => {
    this.setState(prevState => ({
      showBaseline: !prevState.showBaseline
    }));
    toggleShowSettingInSession('showBaseline');
    if (this.state.chartType === 'trend') this.refreshChart();
  }

  isComponentRULEnabled = () => {
    const { tag } = this.state;
    return tag.rul_settings && tag.rul_settings.enabled;
  }

  isHidden = (chartType) => {
    const { tag, showCharts, measurement_type } = this.state;
    switch (chartType) {
      case 'trend':
        return !tag || !showCharts;
      case 'spectrum':
      case 'waveform':
      case 'TSW':
        return !tag || tag.structure_type !== 'vector' || !showCharts || measurement_type === 0;
      case 'waterfall_spectrum':
      case 'cepstrum':
        return !tag || tag.type !== 'vibration' || !showCharts || measurement_type === 0;
      default:
        return true;
    }
  }

  render() {
    const {
      addingNote,
      amp_type,
      brushSelected,
      bin_nos,
      calendarExpanded,
      componentNode,
      config,
      chartType,
      days,
      editTrend,
      editDemod,
      editRULSettings,
      envelopeAlarm,
      feature,
      featureForDetails,
      featureToOpen,
      filterMax,
      filterMin,
      lastHoveredChart,
      measurement_type,
      number_of_spectrums,
      openFeature,
      openFeatureDetails,
      openOverallAlarm,
      customDayFrom,
      customDayTo,
      selectedTabItem,
      selectedRange,
      selection,
      showCharts,
      tag,
      waveAndSpecSelection,
      showBaseline,
      loadingWaterfall,
      editTagInfo,
      paginationLoading,
      loadingPreViewConfig
    } = this.state;
    const { hierarchyViewPane } = this.props;
    const showWidth = this.props.previewConfig ? '100vw ' : `calc(100vw - ${hierarchyViewPane.currentWidth + 15}px)`; // accomodate for the gap in Footer
    let hasData = false;
    if (
      config.trends_data &&
      config.trends_data[0] &&
      config.trends_data[0].trend_data &&
      config.trends_data[0].trend_data.length > 0
    ) {
      hasData = true;
    }

    const menuItems = [];
    menuItems.push({
      text: 'addNote',
      onClick: this.toggleAddingNote,
      disabled: (
        !hasData ||
        !showCharts ||
        chartType === 'waterfall_spectrum' ||
        amp_type === 'demod' ||
        chartType === 'TSW' ||
        (config && !config.default)) ||
        (tag && tag.type === 'utilization'),
      active: addingNote
    });
    menuItems.push({
      text: 'refresh',
      onClick: this.refreshChart,
      disabled: !showCharts,
      active: false
    });
    menuItems.push({
      text: 'download',
      onClick: () => helpers.downloadChartData(this.state),
      disabled: chartType === 'waterfall_spectrum' || !showCharts,
      active: false
    });
    menuItems.push({
      text: 'editChart',
      onClick: this.edit,
      disabled: chartType === 'waterfall_spectrum' || openFeatureDetails || (tag && tag.type === 'utilization'),
      active: editTrend || openFeature || editDemod || editTagInfo || editRULSettings
    });

    const tabItems = [
      {
        text: 'General',
        onClick: () => this.toggleShowTabOptions('General'),
        dropdown: [
          {
            text: 'Add Note',
            onClick: () => this.toggleAddingNote(),
            disabled: (
              !hasData ||
              !showCharts ||
              chartType === 'waterfall_spectrum' ||
              amp_type === 'demod' ||
              chartType === 'TSW' ||
              (tag && tag.type === 'utilization') ||
              (config && !config.default)
            ),
            id: 4
          },
          {
            text: 'Calendar',
            onClick: () => this.toggleCalendarExpand(),
            id: 1,
            disabled: !showCharts
          },
          {
            text: 'Refresh',
            onClick: () => this.refreshChart(),
            id: 2,
            disabled: !showCharts
          },
          {
            text: 'Download',
            onClick: () => helpers.downloadChartData(this.state),
            id: 3,
            disabled: !hasData || !tag || chartType === 'waterfall_spectrum' || !showCharts
          }
        ]
      },
      {
        text: 'Alarms',
        hidden: (tag && (_.includes(['utilization', 'shaft_speed', 'magnetic_flux'], tag.type))),
        onClick: () => this.toggleShowTabOptions('Alarms'),
        dropdown: [
          {
            text: 'Envelope Alarm',
            selected: envelopeAlarm,
            onClick: () => this.openEnvelopeAlarm(),
            disabled: (
              !hasData || !tag ||
              (config && !config.default) ||
              tag.structure_type !== 'vector' ||
              !_.includes(['velocity', 'acceleration', 'normal'], amp_type) ||
              (!showCharts && !envelopeAlarm) ||
              measurement_type === 0 ||
              (tag.type === 'current' && !this.state.logScale)
            ),
            hidden: !tag || tag.structure_type !== 'vector',
            title: (
              !hasData || !tag ||
              (config && !config.default) ||
              tag.structure_type !== 'vector' ||
              !_.includes(['velocity', 'normal'], amp_type) ||
              (!showCharts && !envelopeAlarm) ||
              measurement_type !== 1
            ) ? 'Envelope Alarms can be set for Full Bandwidth - Velocity feature' : null,
            id: 7
          },
          {
            text: 'Overall Alarm',
            selected: openOverallAlarm,
            onClick: () => this.toggleOverallAlarm(),
            disabled: (
              !hasData || !tag ||
              (!showCharts && !openOverallAlarm) ||
              (config && !config.default) ||
              (tag.type === 'vibration' && feature !== 'rms') ||
              (tag.type === 'current' && feature !== 'rms') ||
              (tag.type === 'vibration' && amp_type !== 'velocity' && amp_type !== 'acceleration') ||
              (tag.type === 'vibration' && measurement_type !== 1) ||
              (tag.type === 'current' && !this.state.logScale)
            ),
            title: (
              !hasData || !tag ||
              (!showCharts && !openOverallAlarm) ||
              (config && !config.default) ||
              (tag.type === 'vibration' && feature !== 'rms') ||
              (tag.type === 'current' && feature !== 'rms') ||
              (tag.type === 'vibration' && amp_type !== 'velocity' && amp_type !== 'acceleration') ||
              (tag.type === 'vibration' && measurement_type !== 1)
            ) ? 'Overall Alarms can only be set for Full Bandwidth - Velocity and Acceleration RMS feature.' : null,
            id: 4
          },
          {
            text: 'Demod Alarm',
            selected: openOverallAlarm,
            onClick: () => this.toggleOverallAlarm(),
            disabled: (
              !hasData || !tag ||
              (!showCharts && !openOverallAlarm) ||
              (config && !config.default) ||
              (tag.type === 'vibration' && feature !== 'rms') ||
              (tag.type === 'vibration' && amp_type !== 'demod') ||
              (tag.type === 'vibration' && measurement_type !== 1)
            ),
            title: (
              !hasData || !tag ||
              (!showCharts && !openOverallAlarm) ||
              (config && !config.default) ||
              (tag.type === 'vibration' && feature !== 'rms') ||
              (tag.type === 'vibration' && amp_type !== 'demod') ||
              (tag.type === 'vibration' && measurement_type !== 1)
            ) ? 'Demod Alarms can only be set for Full Bandwidth - Demod RMS feature.' : null,
            hidden: !tag || tag.type !== 'vibration',
            id: 5
          }
        ]
      },
      {
        text: 'Edit',
        hidden: (tag && tag.type === 'utilization'),
        onClick: () => this.toggleShowTabOptions('Edit'),
        dropdown: [
          {
            text: 'Chart',
            selected: editTrend,
            onClick: () => this.openEditTrend(),
            disabled: (!showCharts && !editTrend),
            id: 1
          },
          {
            text: 'Spectrum Feature',
            selected: openFeature,
            onClick: () => this.openAddEditFeatures(),
            disabled: !hasData || !tag || (!showCharts && !openFeature) || (config && !config.default) || measurement_type === 0,
            hidden: !tag || tag.structure_type !== 'vector',
            id: 2
          },
          {
            text: 'Demod Spectrum',
            selected: editDemod,
            onClick: () => this.openEditDemod(),
            disabled: !tag || (!showCharts && !editDemod) || (config && !config.default) || measurement_type === 0,
            hidden: !tag || !utils.isDemodSupported(tag.type),
            id: 3
          },
          {
            text: 'Set Baseline',
            onClick: this.openBaselinePage,
            disabled: tag.active === false,
            id: 4
          },
          {
            text: 'Tag Info',
            selected: editTagInfo,
            onClick: () => this.openEditTagInfo(),
            disabled: (!showCharts && !editTagInfo),
            id: 5
          },
          {
            text: 'RUL Settings',
            selected: editRULSettings,
            onClick: this.openRULSettings,
            hidden: !tag || tag.type !== 'vibration' || (chartType !== 'trend' && chartType !== 'TSW') || !this.isComponentRULEnabled(),
            id: 6
          }
        ]
      }
    ];

    const viewItems = [
      {
        text: 'Tr',
        fullText: 'Trend',
        active: chartType === 'trend',
        onClick: () => this.setChartType('trend'),
        hidden: this.isHidden('trend'),
        id: 1
      },
      {
        text: 'Sp',
        fullText: 'Spectrum',
        active: chartType === 'spectrum',
        onClick: () => this.setChartType('spectrum'),
        hidden: this.isHidden('spectrum'),
        id: 2
      },
      {
        text: 'Wa',
        fullText: 'Waveform',
        active: chartType === 'waveform',
        onClick: () => this.setChartType('waveform'),
        hidden: this.isHidden('waveform'),
        id: 3
      },
      {
        text: 'Co',
        fullText: 'Combined (T,S,W)',
        active: chartType === 'TSW',
        onClick: () => this.setChartType('TSW'),
        hidden: this.isHidden('TSW'),
        id: 5
      },
      {
        text: 'Wf',
        fullText: 'Waterfall Spectrum',
        active: chartType === 'waterfall_spectrum',
        onClick: () => this.setChartType('waterfall_spectrum'),
        hidden: this.isHidden('waterfall_spectrum'),
        id: 6
      },
      {
        text: 'Ce',
        fullText: 'Cepstrum',
        active: chartType === 'cepstrum',
        onClick: () => this.setChartType('cepstrum'),
        hidden: this.isHidden('cepstrum'),
        id: 7
      }
    ];
    const disableBinFilter = editTrend || openFeature ||
      openFeatureDetails || editDemod || envelopeAlarm;
    const disableFilterOptions = disableBinFilter || openOverallAlarm;
    return (
      <ConfigContainer background="white">
        <FlexContainer backgroundColor="white" direction="column">
          <ChartTab
            tabItems={tabItems}
            selectedTabItem={selectedTabItem}
            toggleShowTabOptions={this.toggleShowTabOptions}
          />
          <ChartOptions
            disableFilterOptions={disableFilterOptions}
            disableBinFilter={disableBinFilter}
            addingNote={addingNote}
            bin_nos={bin_nos}
            calendarSelect={this.calendarSelect}
            calendarExpand={this.toggleCalendarExpand}
            calendarExpanded={calendarExpanded}
            chartType={chartType}
            menuItems={menuItems}
            viewItems={viewItems}
            number_of_spectrums={number_of_spectrums}
            handleSpectraChange={this.handleSpectraChange}
            defaultChart={config.default}
            customDayFrom={customDayFrom}
            customDayTo={customDayTo}
            days={days}
            tag={tag}
            amp_type={amp_type}
            feature={feature}
            getChartData={this.getChartData}
            measurement_type={measurement_type}
            filterMin={filterMin}
            filterMax={filterMax}
            setSpeedFilterValues={this.setSpeedFilterValues}
            speedUnits={config && config.trends_data && config.trends_data[0] && config.trends_data[0].speed_units}
            refreshChart={this.refreshChart}
            showBaseline={showBaseline}
            loadingWaterfall={loadingWaterfall}
            toggleShowBaseline={this.toggleShowBaseline}
            logScale={this.state.logScale}
          />
            {loadingPreViewConfig &&
            (<LoadingSvg />)
          }
          {showCharts && config && !config.default && config.trends_data && config.trends_data.length > 0 && (
            <ChartMultilineItem
              config={config}
              days={days}
              customDayTo={customDayTo}
              customDayFrom={customDayFrom}
            />
          )}
          {config && config.trends_data && config.trends_data.error && !this.loading && (
            <Error>{config.trends_data.error}</Error>
          )}
          {showCharts && config && tag && config.default && config.trends_data && config.trends_data.length > 0 && (
            <ChartItem
              config={config}
              days={days}
              tag={tag}
              chartType={chartType}
              customDayFrom={customDayFrom}
              customDayTo={customDayTo}
              amp_type={amp_type}
              feature={feature}
              bin_nos={bin_nos}
              selection={selection}
              waveAndSpecSelection={waveAndSpecSelection}
              mouseoverChart={this.mouseoverChart}
              refreshChartAfterOutlier={() => this.refreshChart({ afterOutlier: true })}
              selectedRange={selectedRange}
              getWaveAndSpecForId={this.getWaveAndSpecForId}
              storeBrushSelection={this.storeBrushSelection}
              brushSelected={brushSelected}
              lastHoveredChart={lastHoveredChart}
              setLastHoveredChart={this.setLastHoveredChart}
              onFeatureDetailsClick={this.onFeatureDetailsClick}
              onFeatureEditClick={this.openAddEditFeatures}
              openFeature={openFeature}
              openFeatureDetails={openFeatureDetails}
              closeFeatureDetailsClick={this.closeFeatureDetailsClick}
              featureForDetails={featureForDetails}
              featureToOpen={featureToOpen}
              filterMinVal={tag.type === 'vibration' ? filterMin : null}
              filterMaxVal={tag.type === 'vibration' ? filterMax : null}
              openEditDemod={this.openEditDemod}
              measurement_type={measurement_type}
              componentNode={componentNode}
              showBaseline={showBaseline}
              paginationLoading={paginationLoading}
              editRULSettings={editRULSettings}
              closeRULSettings={this.closeRULSettings}
            />
          )}
          {((!config && !config.trends_data) ||
            (config && config.trends_data && config.trends_data.loading) ||
            (this.props.previewConfig && this.state.chartLoading)) &&
            !openOverallAlarm &&
            !envelopeAlarm &&
            <LoadingSvg />
          }
          {addingNote && (
              <NoteForm
                amp_type={amp_type}
                brushSelected={brushSelected}
                chart_type={chartType}
                config={config}
                data_source="tag"
                feature={feature}
                machine_id={this.props.machine_id ? this.props.machine_id : this.props.breadcrumb.machine.id}
                measurement_id={waveAndSpecSelection ? waveAndSpecSelection.measurement_id : null}
                onCancel={this.toggleAddingNote}
                tag_id={tag && tag.id}
                showWidth={showWidth}
              />
          )}
          {editTrend && (
            <>
              {config && config.machines.length > 1 && (
                <CompareTagsModal
                  close={this.closeEditTrend}
                  isEditMode={!!config}
                  config={config}
                />
              )}
              {config && config.machines.length === 1 && (
                <AddChartModal
                  config={config}
                  close={this.closeEditTrend}
                  defaultChart={config.default}
                  globalDays={days}
                  trends_config={config.trends_config}
                  getChartData={this.getChartData}
                  showWidth={showWidth}
                  refreshConfigMetadata={this.refreshConfigMetadata}
                  selectedTags={this.props.selectedTags}
                />
              )}
            </>
          )}
          {editTagInfo && config.default && (
            <AddTagModal
              config={config}
              close={this.closeEditTrend}
              defaultChart={config.default}
              getChartData={this.getChartData}
              showWidth={showWidth}
              tag={tag}
              editTagInfo
            />
          )}
          {envelopeAlarm && (
            <EnvelopeAlarmModal
              config={config}
              tag={tag}
              feature={feature}
              amp_type={amp_type}
              close={this.closeEnvelopeAlarm}
              showWidth={showWidth}
              measurement_type={measurement_type}
              componentNode={componentNode}
              waveAndSpecSelection={waveAndSpecSelection}
              filterTrendChart={this.getChartData}
            />
          )}
          {openOverallAlarm && (
            <OverallAlarmModal
              config={config}
              tag={tag}
              feature={feature}
              ampType={amp_type}
              close={this.toggleOverallAlarm}
              showWidth={showWidth}
              bin_nos={bin_nos}
            />
          )}
          {openFeatureDetails &&
            config.spectrumFeatures &&
            config.spectrum && (
              <FeatureTrendModal
                spectrum={tag.spectrum}
                close={this.closeFeatureDetailsClick}
                tag={tag}
                ampType={amp_type}
                feature={featureForDetails}
                showWidth={showWidth}
                // {...commonModalProps}
              />
          )}

          {openFeature &&
            config.spectrumFeatures &&
            config.spectrum && (
              <FeatureModal
                close={this.closeAddEditFeature}
                spectrum={config.spectrum}
                tag={tag}
                features={config.spectrumFeatures}
                selectedFeature={featureToOpen}
                config={config}
                showWidth={showWidth}
              />
          )}
          {editDemod && (
            <DemodSpectrumSettingsModal
              close={this.closeEditDemod}
              tags={[tag]}
              showWidth={showWidth}
              freq_units={this.props.currentUser.frequency_units}
            />
          )}
        </FlexContainer>
      </ConfigContainer>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.user,
  rbacPermissions: state.rbacPermissions,
  assetHierarchy: state.assetHierarchyReducer.assetInfo,
  currentAccount: state.currentAccount,
  hierarchyViewPane: state.hierarchyViewPane,
  breadcrumb: state.breadcrumb
});

const mapDispatchToProps = dispatch => ({
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch),
  spectrumFeatureActions: bindActionCreators(spectrumFeatureActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
  getTagTemplateMetadata: bindActionCreators(getTagTemplateMetadata, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChartsView));
