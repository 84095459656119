import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import { mapNames } from 'common/charts/constants';
import * as utils from 'common/utils';
import Selection from 'common/components/Chart/components/Selection';

import TagChart from '../../../../Machines/MachineDetails/MachineCharts/components/TagChart';
import NoDataChart from '../../../../Machines/MachineDetails/MachineCharts/components/NoDataChart';

import * as overallAlarmUtils from '../../../utils/overallAlarmUtils';


class CreateAndDisplayTrendAlarm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: null
    };
  }

  componentDidMount() {
    this.createOrUpdateThresholds();
  }

  componentDidUpdate(prevProps) {
    const { tag, overallAlarmSettings } = this.props;
    if (
      !_.isEqual(prevProps.tag.trends, tag.trends) ||
      !_.isEqual(prevProps.overallAlarmSettings, overallAlarmSettings)
    ) {
      this.createOrUpdateThresholds();
    }
  }

  createOrUpdateThresholds = () => {
    const { tag, overallAlarmSettings } = this.props;
    if (!overallAlarmSettings) return;
    if (tag.trends && !tag.trends.loading) {
      const bins = [];
      const thresholdsList = [];
      _.keys(overallAlarmSettings).forEach((bin) => {
        if (tag.trends.data[bin]) {
          const amps = tag.trends.data[bin].map(d => d.y);
          const thresholds = overallAlarmUtils.calculateThresholds(amps, overallAlarmSettings[bin]);
          thresholdsList.push(thresholds);
          bins.push(bin);
        }
      });
      this.props.updateOverallAlarmThresholds(bins, thresholdsList, tag.id);
    }
  }

  getLabelAndUnits = () => {
    const { feature, tag, yUnit, speedUnit } = this.props;
    return {
      yTitle: mapNames[feature] || utils.CapitalizeEachWord(tag.type),
      yUnit,
      speedUnit
    };
  }

  mouseoverChart = (selection) => {
    this.setState({
      selection: {
        ...selection,
        isDate: true,
        ...this.getLabelAndUnits()
      }
    });
  }

  render() {
    const {
      ampType,
      chartName,
      feature,
      data,
      tag,
      unitSystem,
      warningThreshold,
      criticalThreshold,
      loading,
      showNoDataChart,
      yUnit,
      theme
    } = this.props;
    return (
      <>
        {!_.isEmpty(data) ? (
          <>
          {!!this.state.selection && <Selection data={this.state.selection} />}
          <TagChart
            type="trend"
            title="trend"
            chartName={chartName || `tagId-${tag.id}-overall-alarm-analyst`}
            data={data}
            color={theme.primaryColor}
            loading={loading || (tag.trends && tag.trends.loading)}
            tagId={tag.id}
            expanded
            yTitle={tag.structure_type === 'vector' ? mapNames[feature] : utils.CapitalizeEachWord(tag.type)}
            yUnit={yUnit}
            xIsDate
            warningThreshold={warningThreshold}
            criticalThreshold={criticalThreshold}
            ampType={ampType}
            feature={feature}
            height="300px"
            contextHeight="25px"
            chart_size="Large"
            unit_system={unitSystem}
            tag_type={tag.type}
            selection={this.state.selection}
            mouseoverChart={this.mouseoverChart}
          />
          </>
        ) : (
          <>
          {showNoDataChart && (
            <NoDataChart
              tagType={tag.type}
              height="300px"
              title="No data available"
            />)}
          </>
        )
      }
      </>
    );
  }
}

CreateAndDisplayTrendAlarm.propTypes = {
  ampType: PropTypes.string,
  feature: PropTypes.string,
  chartName: PropTypes.string,
  tag: PropTypes.object.isRequired,
  unitSystem: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  updateOverallAlarmThresholds: PropTypes.func,
  overallAlarmSettings: PropTypes.object,
  warningThreshold: PropTypes.number.isRequired,
  criticalThreshold: PropTypes.number.isRequired
};

CreateAndDisplayTrendAlarm.defaultProps = {
  ampType: 'velocity',
  feature: 'rms',
  chartName: '',
  loading: false,
  updateOverallAlarmThresholds: () => {},
  overallAlarmSettings: {}
};

export default withTheme(CreateAndDisplayTrendAlarm);
