export default {
  GET_FAILURE_MODE_DETAILS: 'GET_FAILURE_MODE_DETAILS',
  GET_FAILURE_MODE_DETAILS_REQUEST: 'GET_FAILURE_MODE_DETAILS_REQUEST',
  GET_FAILURE_MODE_DETAILS_SUCCESS: 'GET_FAILURE_MODE_DETAILS_SUCCESS',
  GET_FAILURE_MODE_DETAILS_FAILURE: 'GET_FAILURE_MODE_DETAILS_FAILURE',

  SAVE_FAILURE_MODE_DETAILS: 'SAVE_FAILURE_MODE_DETAILS',
  SAVE_FAILURE_MODE_DETAILS_REQUEST: 'SAVE_FAILURE_MODE_DETAILS_REQUEST',
  SAVE_FAILURE_MODE_DETAILS_SUCCESS: 'SAVE_FAILURE_MODE_DETAILS_SUCCESS',
  SAVE_FAILURE_MODE_DETAILS_FAILURE: 'SAVE_FAILURE_MODE_DETAILS_FAILURE',

  UPDATE_FAILURE_MODE_DETAILS: 'UPDATE_FAILURE_MODE_DETAILS',
  UPDATE_FAILURE_MODE_DETAILS_REQUEST: 'UPDATE_FAILURE_MODE_DETAILS_REQUEST',
  UPDATE_FAILURE_MODE_DETAILS_SUCCESS: 'UPDATE_FAILURE_MODE_DETAILS_SUCCESS',
  UPDATE_FAILURE_MODE_DETAILS_FAILURE: 'UPDATE_FAILURE_MODE_DETAILS_FAILURE',

  DELETE_FAILURE_MODE: 'DELETE_FAILURE_MODE',
  DELETE_FAILURE_MODE_REQUEST: 'DELETE_FAILURE_MODE_REQUEST',
  DELETE_FAILURE_MODE_SUCCESS: 'DELETE_FAILURE_MODE_SUCCESS',
  DELETE_FAILURE_MODE_FAILURE: 'DELETE_FAILURE_MODE_FAILURE',

  GET_FAILURE_MODE_LIST: 'GET_FAILURE_MODE_LIST',
  GET_FAILURE_MODE_LIST_SUCCESS: 'GET_FAILURE_MODE_LIST_SUCCESS',
  GET_FAILURE_MODE_LIST_REQUEST: 'GET_FAILURE_MODE_LIST_REQUEST',
  GET_FAILURE_MODE_LIST_FAILURE: 'GET_FAILURE_MODE_LIST_FAILURE',

  SELECT_FAILURE_MODE: 'SELECT_FAILURE_MODE',
};
