import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputField_T from '../../atoms/InputField';

const SpeedInputField = styled(InputField_T)`
  width: 120px;
  margin-bottom: 0.5em;
  margin-top: 1em;
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  label {
    margin-bottom: 0;
  }
`;

SpeedInputField.propTypes = {
  marginLeft: PropTypes.string
};

SpeedInputField.defaultProps = {
  marginLeft: undefined
};

export default styled(SpeedInputField)``;
