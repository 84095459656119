import styled from 'styled-components';

import Container from 'common/components/atoms/Container';

const ChartItemContainer = styled(Container)`
  padding: 0.5em 1.5em 0.5em 0.5em;
  margin-bottom: ${props => props.marginBottom};
  position: ${props => props.position};
`;

export default styled(ChartItemContainer)``;
