import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as _ from 'lodash';
import { SortableHandle } from 'react-sortable-hoc';

import RBAC from 'common/rbac/RBAC';
import { mapComponentToResource, operations } from 'common/rbac/constants';

import PlusSvg from 'common/images/PlusSvg';
import MinusSvg from 'common/images/MinusSvg';
import DeleteIcon from 'common/images/BearingModal/DeleteIconSvg';
import NodeItem from 'common/components/atoms/NodeItem';
import DragHandlerIconT from 'common/images/DragHandlerIcon';
import EditIcon from 'common/images/BearingModal/EditIcon';
import colors from 'common/styles/colors';
import ColoredCircle from 'common/components/Chart/atoms/ColoredCircle';

import { FlexContainer } from 'common/components/atoms';
import H4_T from 'common/typography/H4/H4';
import { connect } from 'react-redux';
import SvgBtn from '../../atoms/SvgBtn/SvgBtn';

const H3 = styled(FlexContainer)`
  display: flex;
  position: relative;
  justify-content: space-between;
  font-size: 14px;
  width: ${props => props.width ? props.width : '95%'};
  ${props => props.theme.fonts.lightTitle};
  color: ${props => props.theme.colors.greyXXD};
  ${props => props.placeholder && `
    color: ${props => props.theme.colors.greyXD};
  `}
`;

const H4 = styled(H4_T)`
 font-weight: 300;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-grow: 5;
  align-items: center;
  height: 100%;
  ${props => props.childs ? `
    margin-left: calc(25px * ${props.depth})` : `
    margin-left: calc(25px * ${props.depth} + 9px)
  `};

  ${SvgBtn} {
    position: relative;
    margin-right: 7.5px;
  }
`;

const FamilyLines = styled.svg`
  overflow: visible!important;
  height: inherit;
  width: 50px;
  position: absolute;
  left: ${props => `calc(25px * ${props.depth - 1})`};
`;

const ThroughPath = styled.path.attrs({
  d: props => props.d,
  stroke: '#999B95'
})`
  overflow: visible;
  stroke: #999B95;
  stroke-width: 1.5;
  stroke-dasharray: 3;
  fill: none;
`;

const ColoredCircleContainer = styled(ColoredCircle)`
  margin-top: 6px;
  padding: 1px 6px 5px 0px;
`;

const DragHandlerIcon = () => (
  <div style={{ cursor: 'row-resize' }}>
    <DragHandlerIconT />
  </div>
);

const DragHandle = SortableHandle(() => <DragHandlerIcon />);

const MachineNodeItem = ({
  node,
  name,
  createChild,
  depth,
  selected,
  expanded,
  toggleExpand,
  switchNode,
  deleteNode,
  isInfo,
  family: {
    childs,
  },
  activeDepth,
  editMode,
  editIconClick,
  getMissingFields,
  editable,
  primaryColor
}) => (
  <NodeItem selected={selected} paddingRight="17px">
    <FamilyLines depth={depth}>
      {childs && expanded && <ThroughPath d={`M 30 25 V ${25 + activeDepth * 50}`} />}
      {depth !== 1 && (
        <Fragment>
          <ThroughPath d="M 8 25 H 25" />
        </Fragment>)
      }
    </FamilyLines>
    <ItemContainer
      id={name}
      depth={depth}
      childs={!!childs}
      onClick={switchNode}
    >
      {!!childs && (
        <SvgBtn
          opaque
          greyBorder
          onClick={toggleExpand}
          height="16px"
          width="16px"
        >
          {expanded ? (
            <MinusSvg height={8} width={8} />
          ) : (
            <PlusSvg height={8} width={8} />
          )}
        </SvgBtn>
      )}
      <H3
        className="nodeName"
        placeholder={(!name).toString()}
        name={`${node.nodeType}_${node.id}`}
        width="93.3%"
        style={{ fontWeight: depth === 1 ? 600 : 'normal' }}
      >
        <H4>{depth === 1 ? name || 'New Asset' : name || 'New Component'}</H4>
        <RBAC
          resource={mapComponentToResource.Machines}
          operation={operations.Update}
          yes={(
            <div>
              {(node.nodeType === 'machine' || node.nodeType === 'component') &&
                getMissingFields.length > 0 && (
                  <div>
                    <ColoredCircleContainer color={colors.cyan} />
                  </div>
              )}
            </div>
          )}
        />
      </H3>
    </ItemContainer>
    {isInfo ? (
      <>
        {editMode && depth === 1 && (
          <RBAC
            resource={mapComponentToResource.ComponentDetails}
            operation={operations.Create}
            yes={(
              <SvgBtn onClick={createChild} padding="0 20px 0 0">
                <PlusSvg height={11} width={11} fill={colors.greyXD} />
              </SvgBtn>
            )}
          />
        )}
      </>
    ) : (
      <>
        {depth === 1 && editable && (
          <SvgBtn onClick={createChild} padding={depth !== 1 && '0 20px 0 0'}>
            <span data-tooltip="Create the asset hierarchy by clicking on the + sign" data-position="left center" data-inverted="">
              <PlusSvg height={13} width={13} fill="#999B95" />
            </span>
          </SvgBtn>
        )}
      </>
    )}
    {isInfo && editable && node.nodeType === 'machine' && (
      <RBAC
        resource={mapComponentToResource.Machines}
        operation={operations.Update}
        yes={(
          <SvgBtn onClick={editIconClick}>
            <EditIcon fill={editMode ? primaryColor : colors.greyXD} />
          </SvgBtn>
        )}
      />
    )}
    {((editMode && node.newNode) || !isInfo) && (depth !== 1) && editable && (
      <SvgBtn onClick={deleteNode} padding={editMode && '0 20px 0 0'}>
        <MinusSvg height={16} width={13} stroke={colors.greyXD} />
      </SvgBtn>
    )}
    {editMode && node.nodeType !== 'machine' && !node.newNode && (
      <RBAC
        resource={mapComponentToResource.ComponentDetails}
        operation={operations.Delete}
        yes={(
          <SvgBtn onClick={deleteNode} padding="0 20px 0 0"><DeleteIcon /></SvgBtn>
        )}
      />
    )}
    {editMode && node.nodeType !== 'machine' && (
      <DragHandle />
    )}
  </NodeItem>
);

MachineNodeItem.propTypes = {
  node: PropTypes.object.isRequired,
  name: PropTypes.string,
  expanded: PropTypes.bool,
  createChild: PropTypes.func,
  depth: PropTypes.number,
  toggleExpand: PropTypes.func,
  switchNode: PropTypes.func.isRequired,
  deleteNode: PropTypes.func,
  isInfo: PropTypes.bool,
  editMode: PropTypes.bool
};

MachineNodeItem.defaultProps = {
  createChild: undefined,
  name: undefined,
  depth: 0,
  expanded: true,
  toggleExpand: undefined,
  deleteNode: undefined,
  isInfo: false,
  editMode: false,
  completionPercent: undefined
};

const mapStateToProps = state => ({
  primaryColor: state.companyReducer.partner.theme.primaryColor
});
export default connect(mapStateToProps)(styled(MachineNodeItem)``);
