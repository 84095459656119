import styled from 'styled-components';

const LineChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.height || '400px'};
  ${props => props.top && 'top: -20px;'}
  ${props => (props.height === '250px' ? 'margin-bottom: 5em;' : '')}
  ${props => (/empty-chart-container/.test(props.chartName) ? 'background-color: rgba(239,240,238,0.24);' : '')}
  ${props => (/-ctx/.test(props.chartName) ? 'margin-top: 2em;' : '')}
  ${props => props.noMarginBottom || 'margin-bottom: 3.5em;'}
  ${props => props.miniMode ? 'margin-bottom: 1.6em;' : ''}
  line {
    &.selected {
      stroke: ${props => props.theme.primaryColor};
    }
    &.handle {
      stroke: #7d7d7d;
      stroke-width: 8px;
    }
  }
  .pointSelection {
    ${props => `clip-path: url(#${props.chartName}-clip);`}
    stroke: ${props => props.theme.colors.black};
    stroke-width: 2px;
    fill: white;
    &.line {
      stroke-width: 1px;
      stroke: #AFAFAE
    }
  }
  .selection.line, .selection.circle {
    ${props => `clip-path: url(#${props.chartName}-clip);`}
  }
  .selection.line {
    stroke: ${props => props.theme.colors.lightGray};
  }
  [class*='feature-'].brush {
    ${props => `clip-path: url(#${props.chartName}-clip);`}
  }
  [class*='baseline-'].circle, .baselinebrush {
    ${props => `clip-path: url(#${props.chartName}-clip);`}
  }
  [class*='baseline-'] {
    .selection {
      stroke: transparent;
      y: 1;
      opacity: 0.4;
      ${props => `clip-path: url(#${props.chartName}-clip);`}
    }
    .handle {
      fill: ${props => props.theme.colors.lightGray};
      ${props => `clip-path: url(#${props.chartName}-clip);`}
    }
  }
  .baselinebrush {
    stroke-width: 1;
    &.y {
      stroke: ${props => props.theme.colors.lightGray};
      stroke-dasharray: 5;
    }
    &.x {
      stroke: ${props => props.theme.primaryColor};
    }
  }
  .axis {
    path, line {
      stroke: ${props => props.theme.colors.divBackgroundGray};
    }
  }
  [class*='-ctx'] {
    .selection {
      fill-opacity: .2;
    }
    .handle {
      fill: #afafae;
      height: 100%;
      y: 0;
      ${props => `clip-path: url(#${props.chartName}-clip);`}
    }
    .tick > text {
      color: ${props => props.theme.colors.greyXD};
    }
    .tick > line {
      stroke-width: 0;
    }
  }
  .noClipDataline {
    fill: none;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-width: 1.5px;
    stroke: #676767;
    stroke-opacity: .5;
  }
  .dataline {
    fill: none;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-width: 2px;
    ${props => (props.applyGradient ? 'stroke: url(#line-gradient) !important' : '')};
    ${props => `clip-path: url(#${props.chartName}-clip);`}
    &[class*='-ctx'] {
      stroke-width: 1px;
    }
    &.util {
      stroke-width: 16px;
      stroke-linejoin: initial;
      stroke-linecap: initial;
    }
  }
  .tickline-hidden{
    .tick > line, path {
      visibility: hidden;
    }
  }
  .tick text {
    font-weight: 600;
    font-family: "Petasense Open Sans";
  }
  .brush .selection {
    stroke: #fff;
  }
  .title {
    font-size: 30px;
    font-weight: 600;
    fill: ${props => props.theme.colors.divBackgroundGray};
    line-height: 1;
    text-transform: capitalize;
  }
  .ylabel {
    font-size: 10px;
    fill: ${props => props.theme.colors.greyXD};
  }
  .xlabel {
    font-size: 12px;
    fill: ${props => props.theme.colors.darkGray};
  }
  .cursorGroupContainer {
    ${props => `clip-path: url(#${props.chartName}-clip);`}
    .cursorValue {
      user-select: none;
      font-size: 10px;
      color: lightgrey;
    }
    line.cursorSelection {
      fill: lightgray;
      stroke: lightgray;
    }
  }
  .cursorInfo {
    user-select: none;
    font-size: 12px;
  }
`;

LineChartContainer.displayName = 'LineChartContainer';

export default LineChartContainer;
