import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';

import LoadingSvg from 'common/images/LoadingSvg';
import { OnboardingHeader } from '../../common/OnboardingComponents';
import { userActions } from '../user.actions';
import { history } from '../../common/helpers';
import { Times } from '../../common/images/FaIcons';

class AccountSelectionPage extends React.Component {
  handleSubmit = (id) => {
    const { selectAccount } = this.props;
    selectAccount(id);
  };

  render() {
    const { accountSelectionState } = this.props;
    if (accountSelectionState && accountSelectionState.result) {
      history.push('/');
    } else if (accountSelectionState && accountSelectionState.result === false) {
      toastr.error('An error occured, please login again');
      history.push('/login');
    }
    const { user } = this.props;
    const userObj = user ? user.user : null;
    const accounts = userObj ? userObj.accounts : null;
    if (accounts.length <= 1) {
      history.push('/');
    }
    return (
      <div className="container">
        <div className="col-sm-4 col-sm-offset-4 col-xs-8 col-xs-offset-2">
          <div className="ps-horizontal-center">
            <OnboardingHeader />
            <form className="form scrollable-form">
              {user && user.loading && <em>Loading Accounts...</em>}
              {user && user.error && <span className="text-danger">{user.error}</span>}
              <div>
                {accounts &&
                  accounts.map((account, index) => (
                    <div key={index} className="form">
                      <div className={`ps-card${userObj.account_id === account.id ? ' active' : ''}`} onClick={() => this.handleSubmit(account.id)}>
                        <div className="title ps-vertical-center"> {account.name} </div>
                      </div>
                      <div className={`ps-align-center help-block${accountSelectionState && accountSelectionState.loadingId === account.id ? ' pop' : ''}`}>
                        <LoadingSvg />
                      </div>
                    </div>
                  ))}
              </div>
            </form>
            <Link to="/">
              <Times size="lg" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  accountSelectionState: state.accountSelectionState
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    selectAccount: userActions.selectAccount
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(AccountSelectionPage);
