import styled from 'styled-components';

const H1 = styled.h1`
  font-size: 24px;
  font-weight: 600;
  font-family: 'Petasense Open Sans';
  color: ${props => props.theme.colors.black};
  margin: 0;
  padding: 1em 0;
`;

export default H1;
