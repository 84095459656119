import styled from 'styled-components';

import Button from '../../atoms/Button';
import FlexContainer from '../../atoms/FlexContainer';

const ChartHeaderRowContainer = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start'
})`
  position: relative;
  margin-bottom: 0.2em;
  height: inherit;
  ${Button} {
    padding: 0 0.5em;
  }
  h3:hover {
    span {
      visibility: visible;
    }
  }
`;

export default styled(ChartHeaderRowContainer)``;
