import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import FooterFlex from 'home/AssetHierarchy/components/atoms/FooterFlex';
import Button from 'common/components/atoms/Button';
import InputField from 'common/components/atoms/InputField';
import LoadingSvg from 'common/components/atoms/Loading';
import { Checkbox, FlexContainer } from 'common/components/atoms';
import Label from 'common/typography/Label/Label';
import { isPetasenseAdmin } from 'common/rbac/util';
import AlertMessage_T from './organisms/AlertMessage';
import * as machineMLActions from '../actions/machineML.actions';
import ComponentBaselineItem from './ComponentBaselineItem';

const AlertMessage = styled(AlertMessage_T)`
  margin: 4px;
`;

const CheckboxContainer = styled(FlexContainer).attrs({ alignItems: 'center' })`
  ${Checkbox} {
    margin-right: 8px;
  }
`;
const EmptyMsgContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em;
  margin: 2em;
  border: 2px dotted ${props => props.theme.colors.lightGray};
  border-radius: ${props => props.theme.utils.borderRadius};
  background-color: ${props => props.theme.colors.white};
  span {
    color: ${props => props.theme.colors.lightGray};
    font-weight: bold;
  }
`;
const Container = styled.div`
width: 100%;
border: 1px solid #D8DCD3;
border-top: none;
padding: 20px 26px 60px;
overflow-y: auto;
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #E5E8E1;
}
background: white;
height: calc(100vh - 110px);
`;

const BaselineContainer = styled.div`
  background-color: white;
  margin: 10px 0 35px;
  padding: 15px;
  border: 1px solid #D8DCD3;
  border-radius: 8px;
  position: relative;
`;

const MultiSetContainer = styled(FlexContainer)`
  border-bottom: 1px solid #ddd;
`;

const MultiSetField = styled(InputField)`
  margin: 12px 0 0 10px;
  label {
    color: ${props => (props.value ? 'black' : 'gray')};
    font-size: 14px;
  }
`;

class BaselineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMultiSetActive: false,
      multiSetProperties: {
        components: {},
        componentNames: {},
        baselineInfo: null,
        outlierInfo: null,
        seedHealthScore: null,
        calendarInfo: {
          days: undefined,
          customDayFrom: null,
          customDayTo: null,
        },
        reExtractFeaturesWithRMS: false
      }
    };
  }

  componentDidMount() {
    const partner = this.props.partner;
    const title = partner.title;
    document.title = `${title} | Baseline`;
    const { machineId, hierarchy } = this.props;
    if (!hierarchy.loading) this.props.machineMLActions.getComponentTrendsForMachineId(machineId);
  }

  componentDidUpdate(prevProps) {
    const { machineId, hierarchy } = this.props;
    if (!hierarchy.loading && hierarchy.loading !== prevProps.hierarchy.loading) this.props.machineMLActions.getComponentTrendsForMachineId(machineId);
  }

  resetState = () => {
    this.setState(prevState => ({
      isMultiSetActive: !prevState.isMultiSetActive,
      multiSetProperties: {
        components: {},
        componentNames: {},
        baselineInfo: null,
        seedHealthScore: null,
        calendarInfo: {
          days: undefined,
          customDayFrom: null,
          customDayTo: null,
        }
      }
    }));
  }

  multiSetComponentHandler = (componentId, componentName, tagsId, isValueSelected, handlerLevel) => {
    const { multiSetProperties } = this.state;
    let newTags = tagsId;

    if (multiSetProperties.components[componentId] && handlerLevel === 'tag') {
      if (isValueSelected) {
        newTags = multiSetProperties.components[componentId].filter(item => item !== tagsId[0]);
      } else newTags = [...this.state.multiSetProperties.components[componentId], tagsId[0]];
    }

    this.setState((prevState) => {
      if (multiSetProperties.components[componentId] && (handlerLevel === 'component' || _.isEmpty(newTags))) {
        const newComponents = { ...prevState.multiSetProperties.components };
        const newComponentNames = { ...prevState.multiSetProperties.componentNames };
        delete newComponents[componentId];
        delete newComponentNames[componentId];
        return {
          multiSetProperties: {
            ...prevState.multiSetProperties,
            components: newComponents,
            componentNames: newComponentNames
          }
        };
      } return {
        multiSetProperties: {
          ...prevState.multiSetProperties,
          components: {
            ...prevState.multiSetProperties.components,
            [componentId]: newTags
          },
          componentNames: {
            ...prevState.multiSetProperties.componentNames,
            [componentId]: componentName
          }
        }
      };
    });
  }

  setMultiSetCalendar = (value, customDayFrom = null, customDayTo = null) => {
    const newState = {
      days: value,
      customDayFrom,
      customDayTo
    };
    this.setState(prevState => ({
      multiSetProperties: {
        ...prevState.multiSetProperties,
        calendarInfo: { ...newState }
      }
    }));
  }

  setMultiSetSeedScore = (newSeedScore) => {
    this.setState(prevState => ({
      multiSetProperties: {
        ...prevState.multiSetProperties,
        seedHealthScore: newSeedScore
      }
    }));
  }

  setBaselineValue = (baseline_info, tag_type = null) => {
    this.setState((prevState) => {
      if (tag_type) {
        return {
          multiSetProperties: {
            ...prevState.multiSetProperties,
            baselineInfo: {
              start_time: baseline_info.start_time,
              end_time: baseline_info.end_time
            },
            outlierInfo: {
              ...prevState.multiSetProperties.outlierInfo,
              [tag_type]: {
                outlier_lower_limit: baseline_info.outlier_lower_limit,
                outlier_upper_limit: baseline_info.outlier_upper_limit
              }
            }
          }
        };
      } return {
        multiSetProperties: {
          ...prevState.multiSetProperties,
          baselineInfo: {
            start_time: baseline_info.start_time,
            end_time: baseline_info.end_time
          }
        }
      };
    });
  }

  saveMultiSetBaseline = () => {
    const { machineMLActions } = this.props;
    const { multiSetProperties, reExtractFeaturesWithRMS } = this.state;

    if (multiSetProperties.baselineInfo) {
      const finalBaselineInfo = {
        start_time: new Date(multiSetProperties.baselineInfo.start_time),
        end_time: new Date(multiSetProperties.baselineInfo.end_time)
      };
      Object.keys(multiSetProperties.components).forEach((key) => {
        machineMLActions.postComponentBaseline(
          key,
          multiSetProperties.componentNames[key],
          multiSetProperties.components[key],
          finalBaselineInfo,
          reExtractFeaturesWithRMS
        );
      });
    }
    this.resetState();
  }

  toggleReExtractFeatures = () => (
    this.setState(prevState => ({ reExtractFeaturesWithRMS: !prevState.reExtractFeaturesWithRMS }))
  )

  render() {
    const { hierarchy, user, baselines } = this.props;
    const { components } = this.props.baselines;
    const { getAllTagChartDataForAComponent } = this.props.machineMLActions;
    const { isMultiSetActive, multiSetProperties, reExtractFeaturesWithRMS } = this.state;

    let allBaselinesValid = true;
    components.forEach((component) => {
      const tagsResults = component && component.tags_data;
      const isCompBaselineValid = tagsResults && _.every(
        tagsResults.map(item => _.isEmpty(item.validation) || item.validation.overall_is_valid)
      );
      allBaselinesValid = allBaselinesValid && isCompBaselineValid;
    });

    const componentTrendsLoading = baselines && baselines.components && _.some(baselines.components.map(c => c.loading));

    return (
      <>
      {isMultiSetActive && (
        <FooterFlex justifyContent="space-between" width={`calc(100vw - ${this.props.hierarchyWidth + 15}px)`}>
          <FlexContainer>
            <Label fontSize="14px">{_.size(multiSetProperties.components)} &nbsp;</Label>
            <Label fontSize="14px" fontWeight="300">{_.size(multiSetProperties.components) <= 1 ? 'Component' : 'Components'} selected</Label>
          </FlexContainer>
          <FlexContainer>
          {isMultiSetActive && isPetasenseAdmin(user) && (
            <CheckboxContainer onClick={this.toggleReExtractFeatures}>
              <Checkbox
                value={reExtractFeaturesWithRMS}
              />
              Re-extract features with RMS method
            </CheckboxContainer>
          )}
          <Button
            cancel
            onClick={() => this.resetState()}
            disabled={_.isEmpty(multiSetProperties.components)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.saveMultiSetBaseline()}
            disabled={_.isEmpty(multiSetProperties.components)}
            danger={!allBaselinesValid}
          >
            {allBaselinesValid ? 'Save' : 'Override and Save'}
          </Button>
          </FlexContainer>
        </FooterFlex>
      )}
      <MultiSetContainer direction="row" backgroundColor="white" padding="10px 20px" justifyContent="flex-start">
        <MultiSetField
          onClick={() => this.resetState()}
          title="MultiSet"
          type="checkbox"
          value={isMultiSetActive}
          disabled={hierarchy.loading}
        />
      </MultiSetContainer>
      <Container>
        <AlertMessage
          message="We currently support only Vibration features in AHS computation"
        />
        {(hierarchy.loading || componentTrendsLoading) && <LoadingSvg />}
        {!hierarchy.loading && _.every(components.map(c => !c.loading && !c.error && _.isEmpty(c.tags_data))) && (
          <EmptyMsgContainer>
          <span>No components with sensors associated</span>
          </EmptyMsgContainer>
        )}
        {components.filter(c => !_.isEmpty(c.tags_data)).map((component, idx) => (
          <BaselineContainer>
            <ComponentBaselineItem
              key={`new-${idx}`}
              component={component}
              postBaseline={this.postBaseline}
              deleteBaseline={this.deleteBaseline}
              getAllTagChartDataForAComponent={getAllTagChartDataForAComponent}
              chart_size="Normal"
              isMultiSetActive={isMultiSetActive}
              multiSetProperties={multiSetProperties}
              setBaselineValue={this.setBaselineValue}
              multiSetComponentHandler={this.multiSetComponentHandler}
              setMultiSetCalendar={this.setMultiSetCalendar}
              setMultiSetSeedScore={this.setMultiSetSeedScore}
            />
          </BaselineContainer>
        ))}
      </Container>
      </>
    );
  }
}

BaselineModal.propTypes = {
  machineId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  baselines: state.machineDetails.ML.baselines,
  breadcrumb: state.breadcrumb,
  hierarchy: state.assetHierarchyReducer.assetInfo.hierarchy,
  hierarchyWidth: state.hierarchyViewPane.currentWidth,
  user: state.user.user,
  partner: state.companyReducer.partner
});

const mapDispatchToProps = dispatch => ({
  machineMLActions: bindActionCreators(machineMLActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(BaselineModal);
