import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { history } from 'common/helpers/history';
import Modal from 'common/components/organisms/Modal';
import Button from 'common/components/atoms/Button';
import CompareTagsAssetsList from 'home/Machines/components/MachinesPage';
import LoadingFullScreen from 'common/components/atoms/LoadingFullScreen';
import styled from 'styled-components';
import { FlexContainer } from 'common/components/atoms';
import { CapitalizeEachWord } from 'common/utils';
import LoadingSvg from 'common/images/LoadingSvg';
import { chartColors } from 'common/constants';
import colors from 'common/styles/colors';
import * as machineChartsActions from 'home/Machines/MachineDetails/MachineCharts/actions/machineCharts.actions';
import { flattenHierarchy } from 'home/AssetHierarchy/utils/assetHierarchyUtils';
import * as assetDetailsActions from 'home/AssetHierarchy/actions/assetDetails.actions';
import MultilineChartView from 'home/AssetHierarchy/components/pages/MultilineChartView';
import CompareTagsAssetItem from './CompareTagsAssetItem';
import CompareTagsHierarchy from './CompareTagsHierarchy';
import CompareTagsListItem from './CompareTagsListItem';
import CompareTagsConfigOptions from './CompareTagsConfigOptions';
import { PAGE_STATE } from '../constants/CompareTags.constants';
import ModalFooter from './ModalFooter';
import SelectAssetsModalHeader from './SelectAssetsModalHeader';
import SelectTagsModalHeader from './SelectTagsModalHeader';
import ChartNameModal from './ChartNameModal';

const ListContainer = styled(FlexContainer).attrs({ direction: 'column' })`
  position: relative;
  overflow: auto;
  width: ${props => props.width};
  height: ${props => props.height};
  ${props => (props.width === '25%') && `border-right: 2px solid ${props.theme.colors.greyL};`}
  ${props => props.background && `background: ${props.theme.colors.greyXL}`}
`;

const ActionIcons = styled(FlexContainer).attrs({ alignItems: 'center' })`
  margin-left: auto;
  ${Button} {
    margin-top: 1em;
    margin-right: 1em;
  }
`;

const PreviewModalContainer = styled.div`
  background-color: white;
  position: relative;
  height: calc(100vh - 114px);
  margin: 0 1em;
`;

class CompareTagsModal extends Component {
  constructor(props) {
    super(props);

    const selectedAssets = (props.config && props.config.machines) ||
      (props.machine && [props.machine.id]) || [];

    this.state = {
      pageState: PAGE_STATE.SELECT_ASSETS,
      selectedAssets,
      assetInfo: {},
      hierarchyData: null,
      selectedGroup: null,
      config: null,
      previewConfig: [],
      trends_config: (props.config && props.config.trends_config) || [],
      hierarchyLoading: false,
      loading: false,
      chartName: (props.config && props.config.name) || '',
      chartNameError: undefined,
      deleteModal: false,
      showChartNameModal: false,
      selectedAllConfig: false
    };
  }

  componentDidMount() {
    if (!this.props.configMetadata) {
      this.props.machineChartsActions.getChartsConfigMetadata();
    }
    if (!_.isEmpty(this.state.selectedAssets)) {
      this.setState({ hierarchyLoading: true });
      this.fetchAllTagData(this.state.selectedAssets, () => {
        this.setHierarchyData();
        this.setInitialConfig();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedGroup, trends_config, config } = this.state;
    // Update selected all checkbox
    if (
      _.get(selectedGroup, 'name') !== _.get(prevState.selectedGroup, 'name') ||
      trends_config.length !== prevState.trends_config.length ||
      (selectedGroup && prevState.selectedGroup &&
        !_.isEqual(
          config[selectedGroup.name],
          prevState.config[prevState.selectedGroup.name]
        ))
    ) {
      this.updateSelectedAllConfig();
    }
  }

  getMachineTags = (machine_id, machine_name) => (
    new Promise(resolve => this.props.machineChartsActions.getMachineTags(machine_id, false).then(
      (res) => {
        this.setState(prevState => ({
          assetInfo: {
            ...prevState.assetInfo,
            [machine_id]: {
              ...prevState.assetInfo[machine_id],
              tags: res,
              name: machine_name
            }
          }
        }), resolve);
      },
      (error) => {
        toastr.error(`${machine_name}: ${error.message}`);
        resolve();
      }
    ))
  )

  fetchAllTagData = (assetsList, callBackFn) => {
    const allMachines = flattenHierarchy(this.props.assetHierarchy.hierarchy).filter(node => node.node_type === 'machine');
    const promises = [];
    assetsList.forEach((machine_id) => {
      const machine = allMachines.find(machine => machine.id === machine_id);
      if (!this.state.assetInfo[machine_id]) {
        promises.push(this.getMachineTags(machine.id, machine.node_name));
      }
    });
    Promise.all(promises).then(
      () => callBackFn()
    );
  }

  setInitialConfig = () => {
    if (!this.props.config) return;
    const { config: { trends_config }, configMetadata } = this.props;
    const { config } = this.state;
    const allTags = this.getAllTagsList(this.state.assetInfo);
    if (trends_config) {
      trends_config.forEach((cfg) => {
        const tag = allTags.find(tag => tag.id === cfg.tag_id);
        const groupName = this.getGroupName(tag);

        if (!config || !config[groupName]) {
          this.setState(prevState => ({
            config: {
              ...prevState.config,
              [groupName]: {
                tag_type: tag.type,
                tag_structure_type: configMetadata[tag.type].structure_type,
                amp_type: cfg.amp_type,
                feature: cfg.feature
              }
            }
          }));
        }
      });
    }
  }

  onAssetClick = (machine, isSelected) => {
    this.setState((prevState) => {
      if (isSelected) return {
        selectedAssets: prevState.selectedAssets.filter(item => item !== machine.id)
      };
      return {
        selectedAssets: [...prevState.selectedAssets, machine.id]
      };
    });
  };

  onClickContinue = () => {
    this.setState({
      pageState: PAGE_STATE.SELECT_TAGS,
      hierarchyLoading: true
    });
    this.fetchAllTagData(this.state.selectedAssets, () => {
      this.setHierarchyData();
      this.refreshTrendsConfig();
    });
  };

  onClickBack = () => {
    this.setState({
      pageState: PAGE_STATE.SELECT_ASSETS,
      selectedGroup: null
    });
  };

  onBackFromPreviewModal = () => {
    this.setState({
      pageState: PAGE_STATE.SELECT_TAGS
    });
  }

  closeChartNameModal = () => {
    this.setState({ showChartNameModal: false });
  }

  openChartNameModal = () => {
    this.setState({ showChartNameModal: true });
  }

  setChartName = (chartName) => {
    this.setState({ chartName });
  }

  getAllTagsList = (assetInfo) => {
    const allTags = [];
    Object.keys(assetInfo).forEach(
      (key) => {
        if (assetInfo[key].tags) {
          assetInfo[key].tags.forEach((tag) => {
            if (tag.type === 'utilization') return;
            allTags.push({
              ...tag,
              machine_name: assetInfo[key].name,
              machine_id: parseInt(key, 10)
            });
          });
        }
      }
    );
    return allTags;
  }

  getGroupName = (tag) => {
    const altKeyArr = [tag.location_description, tag.type, tag.direction];
    const altKey = altKeyArr.reduce((res, val) => val ? `${res}_${val}` : res);
    return CapitalizeEachWord(tag.template || altKey);
  }

  setHierarchyData = () => {
    this.setState({
      hierarchyData: this.getHierarchyData(),
      hierarchyLoading: false,
    });
  }

  getHierarchyData = () => {
    const { assetInfo, selectedAssets } = this.state;
    const allTags = this.getAllTagsList(assetInfo).filter(
      tag => selectedAssets.includes(tag.machine_id) &&
      tag.type !== 'shaft_speed' // FIXME: remove once handled in backend
    );

    const tagGroups = _.groupBy(allTags, tag => this.getGroupName(tag));

    const data = {
      common_tags: {
        expanded: true,
        items: {}
      },
      uncommon_tags: {
        expanded: false,
        items: {}
      },
    };

    Object.keys(tagGroups).forEach((key) => {
      const group = tagGroups[key];
      if (group.length > 1) {
        data.common_tags.items[key] = group;
      } else {
        data.uncommon_tags.items[key] = group;
      }
    });

    return data;
  };

  toggleHierarchyExpand = (key) => {
    this.setState(prevState => ({
      hierarchyData: {
        ...prevState.hierarchyData,
        [key]: {
          ...prevState.hierarchyData[key],
          expanded: !prevState.hierarchyData[key].expanded
        }
      }
    }));
  }

  setselectedGroup = (category, groupName) => {
    const { hierarchyData, config } = this.state;
    const { configMetadata } = this.props;
    const group = hierarchyData[category].items[groupName];
    this.setState({
      selectedGroup: {
        items: group,
        name: groupName
      }
    });
    if (!config || !config[groupName]) {
      const configOptions = {};
      if (configMetadata[group[0].type]) {
        configMetadata[group[0].type].chart_config.forEach((cfgOption) => {
          configOptions[cfgOption.name] = cfgOption.values[0];
        });
        this.setState(prevState => ({
          config: {
            ...prevState.config,
            [groupName]: {
              tag_type: group[0].type,
              tag_structure_type: configMetadata[group[0].type].structure_type,
              ...configOptions
            }
          }
        }));
      }
    }
  };

  getSubTitle = (tag) => {
    const subTitle = tag.description;
    let deviceInfo = tag.device_label ||
      (tag.type === 'shaft_speed' ? 'Auto-Detected' : '');
    if (deviceInfo !== '') deviceInfo = ` (${deviceInfo})`;
    return subTitle + deviceInfo;
  };

  updateConfigProperties = (cfgOptionName, cfgOptionValue) => {
    const { selectedGroup } = this.state;
    this.setState(prevState => ({
      config: {
        ...prevState.config,
        [selectedGroup.name]: {
          ...prevState.config[selectedGroup.name],
          [cfgOptionName]: cfgOptionValue
        }
      }
    }));
  };

  updateSelectedAllConfig = () => {
    const { selectedGroup } = this.state;
    if (!selectedGroup) return;
    const changed = _.every(selectedGroup.items, item => this.getTrendConfig(item.id));
    this.setState({
      selectedAllConfig: changed
    });
  }

  toggleAllTagsFromSelectedGroup = (isSelectAll) => {
    const { config, selectedGroup, trends_config } = this.state;

    let new_trend_config;
    if (isSelectAll) {
      const items = selectedGroup.items.map(item => ({
        ...config[selectedGroup.name],
        tag_id: item.id,
        machine_id: item.machine_id,
        machine_name: item.machine_name
      }));
      new_trend_config = [...trends_config, ...items];
      new_trend_config.forEach((item) => {
        item.color = this.getNextAvailableColor(new_trend_config);
      });
    } else {
      new_trend_config = _.filter(trends_config, item => !this.getTrendConfig(item.tag_id));
    }
    this.setState({
      trends_config: new_trend_config
    });
  }

  refreshTrendsConfig = () => {
    const allTagIds = this.getAllTagsList(this.state.assetInfo).filter(
      tag => this.state.selectedAssets.includes(tag.machine_id)
    ).map(
      tag => tag.id
    );
    this.setState(prevState => ({
      trends_config: prevState.trends_config.filter(
        config => allTagIds.includes(config.tag_id)
      )
    }));
  }

  getNextAvailableColor = (trends_config) => {
    const takenColors = trends_config.map(cfg => cfg.color);
    const availableColors = chartColors.filter(color => !takenColors.includes(color));

    if (_.isEmpty(availableColors)) return chartColors[0];

    return availableColors[0];
  }

  getTrendConfig = (tag_id) => {
    const { trends_config, config, selectedGroup } = this.state;
    const options = config[selectedGroup.name];
    const cfg = _.find(trends_config, cfg => cfg.tag_id === tag_id && _.isMatch(cfg, options));
    return cfg;
  }

  toggleTagFromTrendsConfig = (tag_id) => {
    const { trends_config, config, selectedGroup } = this.state;
    const allTags = this.getAllTagsList(this.state.assetInfo);
    let new_trends_config;
    const existingTrendConfig = this.getTrendConfig(tag_id);
    if (existingTrendConfig) {
      new_trends_config = trends_config.filter(cfg => cfg !== existingTrendConfig);
    } else {
      const tag = allTags.find(tag => tag.id === tag_id);
      new_trends_config = [...trends_config, {
        ...config[selectedGroup.name],
        color: this.getNextAvailableColor(this.state.trends_config),
        tag_id,
        machine_id: tag.machine_id,
        machine_name: tag.machine_name
      }];
    }
    this.setState({ trends_config: new_trends_config });
  }

  onSubmitTrendConfig = () => {
    this.setState({ loading: true });
    if (!this.props.config) {
      this.props.machineChartsActions.postChartsConfig(
        null,
        this.state.trends_config,
        this.state.chartName,
        !!this.props.machineId && !!this.props.machineConfigItems
      ).then(
        () => {
          this.setState({
            loading: false,
            chartNameError: undefined
          });
          if (this.props.machineId) {
            this.props.assetDetailsActions.getConfigs(this.props.machineId, this.props.siteId);
          }
          this.props.assetDetailsActions.getHierarchy(false);
          this.props.close();
        },
        (error) => {
          this.setState({
            loading: false,
            chartNameError: error.toString()
          });
        },
      );
    } else {
      this.props.machineChartsActions.putChartsConfig(
        this.props.config.config_id,
        this.state.trends_config,
        this.state.chartName,
        false,
        !!this.props.machineId
      ).then(
        () => {
          this.setState({
            chartNameError: undefined,
            loading: false
          });
          if (this.props.machineId) {
            this.props.assetDetailsActions.getConfigs(this.props.machineId, this.props.siteId);
          }
          if (this.state.chartName !== this.props.config.name) {
            this.props.assetDetailsActions.getHierarchy(false);
          }
          this.props.close();
        },
        (error) => {
          this.setState({
            chartNameError: error.toString(),
            loading: false
          });
        },
      );
    }
  }

  onDeleteChart = () => {
    if (this.props.config) {
      history.push({ pathname: `/machines/${this.props.machineId}/overview` });
    }
    this.props.machineChartsActions.deleteChartsConfig(this.props.config.config_id, false).then(
      () => this.props.assetDetailsActions.getHierarchy(false)
    );
    this.props.close();
  }

  openChartPreviewModal = () => {
    const { trends_config, selectedAssets } = this.state;
    const params = {
      machines: [...selectedAssets],
      trends_config,
      trends_data: [],
      spectrums: [],
      default: false
    };
    this.setState({
      previewConfig: [params],
      pageState: PAGE_STATE.CHART_PREVIEW });
  }

  closeChartPreviewModal = () => {
    this.props.close();
    window.location.reload(false);
  }

  openDeleteModal = () => {
    this.setState({ deleteModal: true });
  }

  closeDeleteModal = () => {
    this.setState({ deleteModal: false });
  }

  nameChangeHandler = (event) => {
    const name = event.target.value;
    this.setState({ chartName: name });
  };

  getChartPreviewHeader = () => (
    this.state.selectedAssets.map(
      (m_id) => {
        const info = this.state.assetInfo[m_id];
        return info ? info.name : '';
      }
    ).join(', ')
  )

  render() {
    const {
      selectedAssets,
      pageState,
      hierarchyData,
      selectedGroup,
      config,
      trends_config,
      hierarchyLoading,
      loading,
      chartName,
      chartNameError,
      previewConfig,
      assetInfo,
      showChartNameModal,
      selectedAllConfig
    } = this.state;
    const {
      close,
      accounts,
      accountId,
      isEditMode
    } = this.props;

    const listProps = {
      onAssetClick: this.onAssetClick,
      selectedAssets
    };

    const account = accounts.find(element => element.id === accountId);

    return (
      <>
        {pageState === PAGE_STATE.SELECT_ASSETS && (
          <Modal
            id="select-assets-modal"
            title={() => (
              <SelectAssetsModalHeader
                machineName={this.props.machine && this.props.machine.node_name}
                isEditMode={isEditMode}
              />
            )}
            padding="0"
            width="52rem"
            close={close}
            outerPadding="1.5rem 2.4rem"
          >
            <CompareTagsAssetsList
              showMoreOptions={false}
              columnSizes={[8, 54, 38]}
              headers={[{
                name: 'checkbox',
                label: '',
                noSorter: true
              }, {
                name: 'name',
                label: 'Name'
              }, {
                name: 'area_name',
                label: 'Area'
              }]}
              ItemComponent={CompareTagsAssetItem}
              allowAssetCreation={false}
              filters={[]}
              maxHeightAfterScroll="420px"
              showFilterBorderBottom={false}
              listProps={listProps}
              showChartsView={false}
              listContainerBorder={`1px solid ${colors.grey}`}
              listContainerBorderRadius="0.5em"
            />
            <ModalFooter
              statusContent={`${selectedAssets.length} Assets selected`}
              onCancel={close}
              onContinue={this.onClickContinue}
              onDelete={this.openDeleteModal}
              pageState={pageState}
              marginTop="1.5rem"
              proceed={selectedAssets.length > 1}
              isEditMode={isEditMode}
            />
          </Modal>
        )}
        {pageState === PAGE_STATE.SELECT_TAGS && (
          <Modal
            id="select-tags-modal"
            padding="0px"
            title={() => (
              <SelectTagsModalHeader
                accountName={account.name}
                subHeader="Compare Tags"
                header="Define Compare Parameters"
              />
            )}
            close={close}
            footer={() => (
              <ModalFooter
                statusContent={`${trends_config.length} selected`}
                onBack={this.onClickBack}
                onContinue={this.openChartPreviewModal}
                onDelete={this.openDeleteModal}
                pageState={pageState}
                saveDisabled={_.isEmpty(trends_config)}
                isEditMode={isEditMode}
              />
            )}
          >
            <FlexContainer>
              <ListContainer
                id="groups-list"
                width="25%"
                height="calc(100vh - 114px)"
              >
                {hierarchyLoading && (
                  <FlexContainer
                    padding="1rem"
                    width="100%"
                    justifyContent="center"
                  >
                    <LoadingSvg />
                  </FlexContainer>
                )}
                {!hierarchyLoading && hierarchyData && (
                  <CompareTagsHierarchy
                    hierarchy={hierarchyData}
                    onHierarchyGroupClick={this.toggleHierarchyExpand}
                    onGroupItemClick={this.setselectedGroup}
                    selectedItemKey={selectedGroup && selectedGroup.name}
                  />
                )}
              </ListContainer>
              <ListContainer
                id="select-tags-list"
                background
                width="75%"
                height="calc(100vh - 114px)"
              >
                {selectedGroup && config && (
                  <CompareTagsConfigOptions
                    config={config[selectedGroup.name]}
                    onConfigOptionClick={this.updateConfigProperties}
                    selectAll={selectedAllConfig}
                    toggleSelectAll={this.toggleAllTagsFromSelectedGroup}
                  />
                )}
                <FlexContainer margintop="2.5rem" direction="column">
                  {selectedGroup && !_.isEmpty(selectedGroup.items) &&
                    selectedGroup.items.map(tag => (
                      <CompareTagsListItem
                        key={tag.id}
                        title={tag.machine_name}
                        subTitle={this.getSubTitle(tag)}
                        onTagItemClick={() => this.toggleTagFromTrendsConfig(tag.id)}
                        selected={trends_config.find(cfg => cfg === this.getTrendConfig(tag.id))}
                      />
                    ))
                  }
                </FlexContainer>
              </ListContainer>
            </FlexContainer>
          </Modal>
        )}
        {pageState === PAGE_STATE.CHART_PREVIEW && (
          <Modal
            title={() => (
              <SelectTagsModalHeader
                accountName={account.name}
                subHeader="Chart Comparison"
                header={this.getChartPreviewHeader()}
              />
            )}
            padding="0px"
            close={this.closeChartPreviewModal}
            footer={() => (
              <ModalFooter
                onContinue={this.openChartNameModal}
                pageState={pageState}
                onCancel={this.closeChartPreviewModal}
                onBack={this.onBackFromPreviewModal}
                justifyContent="flex-end"
              />
            )}
          >
            <PreviewModalContainer>
              <MultilineChartView
                previewConfig={previewConfig}
                allTags={this.getAllTagsList(assetInfo)}
                borderRadius="8px"
                useCompareChartsTags
              />
            </PreviewModalContainer>
          </Modal>
        )}
        {showChartNameModal && (
          <ChartNameModal
            error={chartNameError}
            onSave={this.onSubmitTrendConfig}
            closeModal={this.closeChartNameModal}
            chartName={chartName}
            setChartName={this.setChartName}
          />
        )}
        {this.state.deleteModal && (
          <Modal title="Delete config" close={this.closeDeleteModal} width="50%" padding="0">
            <span>Are you sure you want to delete this chart config?</span>
            <ActionIcons>
              <Button secondary={colors.greyD} onClick={this.closeDeleteModal}>Cancel</Button>
              <Button secondary={colors.red} onClick={this.onDeleteChart}>Delete</Button>
            </ActionIcons>
          </Modal>
        )}
        {loading && <LoadingFullScreen />}
      </>
    );
  }
}

CompareTagsModal.propTypes = {
  isEditMode: PropTypes.bool,
  machine: PropTypes.object,
  config: PropTypes.object,
  close: PropTypes.func.isRequired
};

CompareTagsModal.defaultProps = {
  isEditMode: false,
  config: null,
  machine: null
};

const mapStateToProps = state => ({
  accountId: state.user.user.account_id,
  accounts: [...state.user.user.accounts],
  assetHierarchy: state.assetHierarchyReducer.assetInfo,
  siteId: state.breadcrumb.site.id,
  machineId: state.breadcrumb.machine.id,
  configMetadata: state.machineDetails.charts.config ?
    state.machineDetails.charts.config.metadata :
    null,
  machineConfigItems: state.machineDetails.charts.config && state.machineDetails.charts.config.items
});

const mapDispatchToProps = dispatch => ({
  machineChartsActions: bindActionCreators(machineChartsActions, dispatch),
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CompareTagsModal);
