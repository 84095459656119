export const cutTo2Decimals = (num) => {
  if (num) {
    const matched = num.toString().match(/^-?\d+(?:\.\d{0,2})?/);
    if (matched && matched.length > 0) {
      return matched[0];
    } // not a number
    return num;
  }
  return null;
};
