import { combineReducers } from 'redux';

import { AutomaticAlarmConstants } from './constants';

const initialState = { items: [] };

const machinesAlarmListReducer = (state = initialState, action) => {
  switch (action.type) {
    case AutomaticAlarmConstants.GET_MACHINES_ALARM_SUCCESS: {
      const { items, page } = action.payload;
      return {
        ...action.payload,
        items: page === 1 ? items : state.items.concat(items),
      };
    }
    case AutomaticAlarmConstants.RESET_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export const AutomaticAlarmReducer = combineReducers({
  machinesAlarmList: machinesAlarmListReducer
});
