import { toggleHierarchyView } from 'home/AssetHierarchy/actions/hierarchy.actions';
import { adminDashboardConstants } from './constants/adminDashboard.constants';

export const toggleAdminDashboard = () => (dispatch, getState) => {
  const hierarchyOpen = !!getState().hierarchyViewPane.currentWidth;
  const adminOpen = getState().adminDashboard.adminOpen;

  if ((!adminOpen && hierarchyOpen) || (adminOpen && !hierarchyOpen)) {
    dispatch(toggleHierarchyView());
  }

  dispatch({
    type: adminDashboardConstants.TOGGLE_ADMIN_DASHBOARD
  });
};
