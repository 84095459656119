import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';

import { humanize } from 'common/helpers/humanize';

import InputField_T from 'common/components/atoms/InputField';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Button from 'common/components/atoms/Button';
import Label_T from 'common/typography/Label/Label';
import UnassignSensorAlertPrompt from 'common/components/organisms/AlertPrompt';
import ButtonConfirm from 'common/components/molecules/ButtonConfirm';
import RBAC from 'common/rbac/RBAC';
import { mapComponentToResource, operations } from 'common/rbac/constants';
import DeleteIconSvg from 'common/images/BearingModal/DeleteIconSvg';
import EditIcon from 'common/images/BearingModal/EditIcon';
import TickSvg from 'common/images/BearingModal/TickSvg';
import CrossSvg from 'common/images/CrossSvg';
import PlusSvg from 'common/images/PlusSvg';
import { ResourceInputField, ResourceSection } from 'common/components/atoms/ResourceDetails';

const InputField = styled(InputField_T)`
  width: 30%;
  min-width: 300px;
  label {
    font-weight: 800;
  }
  margin-bottom: 5px;
`;

const BearingDetailsContainer = styled.div`
  order: ${props => props.order};
  margin-bottom: 1.5em;
`;

const BearingLocation = styled(FlexContainer)`
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0px')};
`;

const BearingProp = styled(FlexContainer).attrs({ direction: 'column' })`
  width: ${props => props.width};
  order: ${props => props.order};
  ${Label} {
    color: ${props => props.theme.colors.greyXD};
  }
  &:last-of-type {
    padding-left: 0px;
  }
  padding: 0px 8px;
`;

const BearingContainer = styled.div`
  &:not(:first-of-type) {
    margin-top: 1.5em;
  }
`;

const InlineButton = styled(Button)`
  margin-left: 12px;
  ${props => props.marginTop && `margin-top: ${props.marginTop}`};
  ${props => props.order && `order: ${props.order};`}
`;

const LinksContainer = styled(FlexContainer).attrs({ alignItems: 'center' })`
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
`;

const Label = styled(Label_T).attrs({ fontWeight: '650' })``;

const DescriptionLabel = styled(Label_T)`
  font-weight: 300;
  margin-bottom: 0;
  margin-left: 8px;
  margin-top: 0px;
`;

const Value = styled.div`
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  color: ${props => props.theme.colors.greyXXD};
  border-radius: 4px;
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColumnLabel = styled(Label_T)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SvgContainer = styled.span`
  padding-left: 0.7em;
  ${props => props.marginTop && `margin-top: ${props.marginTop}`};
  &:hover {
    cursor: ${props => (props.valid ? 'pointer' : 'default')};
    #edit-icon-stroke {
      stroke: ${props => props.theme.colors.black};
    }
  }
`;

const BorderContainer = styled.div`
  border: 2px solid #f0f5f0;
  padding: 15px;
`;

class BearingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bearings: props.bearings ? [...props.bearings] : [],
      newLocationOpen: false,
      newLocationName: '',
      newLocationDescription: '',
      newLocationShaftNumber: props.shaftNumber,
      newSensorOpen: {},
      unassociatedSensorOptions: this.props.unassociatedSensors.object.map(
        sensor => ({
          value: sensor.serial_number,
          text: sensor.serial_number
        })
      ),
      selectedSensor: {},
      selectedOrientation: {},
      selectedPhase: {},
      unassignSensor: null,
      unassignSensorLocationId: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.bearings !== prevProps.bearings) {
      this.setState({
        bearings: [...this.props.bearings]
      });
    }
    if (
      this.props.unassociatedSensors.object !==
      prevProps.unassociatedSensors.object
    ) {
      this.setState({
        unassociatedSensorOptions: this.props.unassociatedSensors.object.map(
          sensor => ({
            value: sensor.serial_number,
            text: sensor.serial_number
          })
        )
      });
    }
  }

  toggleEdit = (idx) => {
    const { bearings } = this.state;
    bearings[idx] = { ...bearings[idx], edit: !bearings[idx].edit };
    this.setState({ bearings });
  };

  setLocationName = (name, idx) => {
    const { bearings } = this.state;
    bearings[idx] = { ...bearings[idx], name };
    this.setState({ bearings });
  };

  setLocationDescription = (description, idx) => {
    const { bearings } = this.state;
    bearings[idx] = { ...bearings[idx], description };
    this.setState({ bearings });
  };

  resetNameAndDescription = (idx) => {
    const bearing = this.props.bearings.find(
      item =>
        item.bearing_location_id ===
        this.state.bearings[idx].bearing_location_id
    );
    if (!bearing) return;
    this.setLocationName(bearing.name, idx);
    this.setLocationDescription(bearing.description, idx);
    this.toggleEdit(idx);
  };

  updateLocation = (idx) => {
    const { bearings } = this.state;
    const locationId = bearings[idx].bearing_location_id;
    const name = bearings[idx].name;
    const description = bearings[idx].description;
    if (!name || !name.trim()) return;
    this.props.updateLocation(locationId, { name, description });
    this.toggleEdit(idx);
  };

  clearAndCloseNewLocation = () => {
    this.setState({
      newLocationOpen: false,
      newLocationName: '',
      newLocationDescription: ''
    });
  };

  clearAndCloseNewSensor = (locationId) => {
    this.setState(prevState => ({
      newSensorOpen: {
        ...prevState.newSensorOpen,
        [locationId]: false
      },
      selectedSensor: {
        ...prevState.selectedSensor,
        [locationId]: null
      },
      selectedOrientation: {
        ...prevState.selectedOrientation,
        [locationId]: null
      },
      selectedPhase: {
        ...prevState.selectedPhase,
        [locationId]: null
      }
    }));
  };

  onLocationNameSubmit = () => {
    const {
      newLocationName,
      newLocationDescription,
      newLocationShaftNumber
    } = this.state;
    if (!newLocationName && !newLocationName.trim()) return;
    this.props.onLocationNameSubmit(
      newLocationName.trim(),
      newLocationDescription.trim(),
      newLocationShaftNumber
    );
    this.clearAndCloseNewLocation();
  };

  unassignSensor = (sensor, locationId) => {
    const params = {};
    let model = sensor.model;
    if (['VM1', 'VM2', 'VM3', 'VM4P', 'GSP', 'VM4', 'GS'].includes(model)) params[model] = { type: 'Vibration Mote' };
    else if (sensor.type && sensor.type === 'Vibration Sensor') params[model] = { type: 'Vibration Sensor' };
    else {
      model = 'other';
      params[model] = { type: 'other' };
    }
    params[model] = {
      ...params[model],
      location_id: null,
      serial_number: [sensor.serial_number]
    };
    this.props.unassignSensor(locationId, params, sensor.serial_number, model);
    this.unassignSensorModalClose();
  };

  assignSensor = (locationId) => {
    const params = {};
    const selectedSensor = this.state.selectedSensor[locationId];
    const selectedOrientation = this.state.selectedOrientation[locationId];
    const selectedPhase = this.state.selectedPhase[locationId];
    if (
      !selectedSensor ||
      (this.checkOrientationRequired(locationId) && !selectedOrientation) ||
      (this.checkPhaseRequired(locationId) && !selectedPhase)
    ) return;
    const serialNumber = selectedSensor.serial_number;
    let model = selectedSensor.model;
    if (selectedSensor.type.includes('Vibration Mote')) params[model] = { type: 'Vibration Mote' };
    else if (selectedSensor.type === 'Vibration Sensor') params[model] = { type: 'Vibration Sensor' };
    else {
      model = 'other';
      params[model] = { type: 'other' };
    }
    params[model] = {
      ...params[model],
      location_id: locationId,
      serial_number: [serialNumber]
    };
    if (selectedOrientation) params[model] = { ...params[model], orientation: selectedOrientation };
    if (selectedPhase) params[model] = { ...params[model], phase: selectedPhase };
    this.props.assignSensor(
      locationId,
      params,
      serialNumber,
      model,
      selectedOrientation,
      selectedSensor.type
    );
    this.setState(prevState => ({
      newSensorOpen: {
        ...prevState.newSensorOpen,
        [locationId]: false
      },
      selectedSensor: {
        ...prevState.selectedSensor,
        [locationId]: null
      },
      selectedOrientation: {
        ...prevState.selectedOrientation,
        [locationId]: null
      },
      selectedPhase: {
        ...prevState.selectedPhase,
        [locationId]: null
      }
    }));
  };

  changeSelectedSensor = (e, data, locationId) => {
    const serial_number =
      e.target.value !== null && e.target.value !== undefined
        ? e.target.value
        : data.value;
    const sensor = this.props.unassociatedSensors.object.find(
      sensor => sensor.serial_number === serial_number
    );
    this.setState(prevState => ({
      selectedSensor: {
        ...prevState.selectedSensor,
        [locationId]: sensor
      }
    }));
  };

  changeSelectedOrientation = (e, data, locationId) => {
    const orientation =
      e.target.value !== null && e.target.value !== undefined
        ? e.target.value
        : data.value;
    this.setState(prevState => ({
      selectedOrientation: {
        ...prevState.selectedOrientation,
        [locationId]: orientation
      }
    }));
  };

  changeSelectedPhase = (e, data, locationId) => {
    const phase =
      e.target.value !== null && e.target.value !== undefined
        ? e.target.value
        : data.value;
    this.setState(prevState => ({
      selectedPhase: {
        ...prevState.selectedPhase,
        [locationId]: phase
      }
    }));
  };

  unassignSensorModalOpen = (sensor, locationId) => {
    this.setState({
      unassignSensorLocationId: locationId,
      unassignSensor: sensor
    });
  };

  unassignSensorModalClose = () => {
    this.setState({
      unassignSensorLocationId: null,
      unassignSensor: null
    });
  };

  checkOrientationRequired = (locationId) => {
    const sensorsMetadata = this.props.sensorsMetadata;
    const selectedSensor = this.state.selectedSensor;
    return (
      selectedSensor[locationId].type !== 'Current Sensor' &&
      !!sensorsMetadata[selectedSensor[locationId].model] &&
      !!sensorsMetadata[selectedSensor[locationId].model].orientation &&
      sensorsMetadata[selectedSensor[locationId].model].orientation.length !== 0
    );
  };

  checkPhaseRequired = (locationId) => {
    const sensorsMetadata = this.props.sensorsMetadata;
    const selectedSensor = this.state.selectedSensor;
    return (
      selectedSensor[locationId].type === 'Current Sensor' &&
      !!sensorsMetadata[selectedSensor[locationId].model] &&
      !!sensorsMetadata[selectedSensor[locationId].model].phase &&
      sensorsMetadata[selectedSensor[locationId].model].phase.length !== 0
    );
  };

  render() {
    const {
      newLocationOpen,
      bearings,
      newLocationName,
      newSensorOpen,
      selectedSensor,
      unassignSensor,
      unassignSensorLocationId,
      selectedOrientation,
      selectedPhase
    } = this.state;
    if (!bearings || bearings.length === 0) return null;
    const {
      order,
      openBearingModal,
      unassignBearingDetails,
      columnSizes,
      deleteLocation,
      sensorsMetadata,
      primaryColor
    } = this.props;
    return (
      <>
        <FlexContainer>
          <ResourceSection width="160px" paddingBottom="15px">
            Bearing Locations
          </ResourceSection>
          {!newLocationOpen && (
            <RBAC
              resource={mapComponentToResource.Machines}
              operation={operations.Create}
              yes={(
                <LinksContainer>
                  <PlusSvg
                    onClick={() => this.setState({ newLocationOpen: true })}
                  />
                </LinksContainer>
              )}
            />
          )}
        </FlexContainer>
        {bearings.length !== 0 && (
          <BearingDetailsContainer order={order}>
            {bearings.map((item, idx) => (
              <BorderContainer>
                <BearingContainer key={idx} style={{ position: 'relative' }}>
                  <Label>Bearing {parseInt(idx, 10) + 1}</Label>
                  {!item.location_type_id && (
                    <RBAC
                      resource={mapComponentToResource.BearingDetails}
                      operation={operations.Delete}
                      yes={(
                        <ButtonConfirm
                          onProceed={() =>
                            deleteLocation(item.bearing_location_id, item.key)
                          }
                          tooltip="Delete Bearing Location"
                          buttonContent={<DeleteIconSvg />}
                          promptMessage="Delete Bearing Location"
                          promptSecondaryMessage="This Bearing Location and its related Data will be deleted. This action cannot be undone."
                        />
                      )}
                    />
                  )}
                  <BearingLocation alignItems="center" marginBottom="16px">
                    {!item.edit && (
                      <>
                        <Value>{` ${humanize(item.name)}`}</Value>
                        {item.description && (
                          <DescriptionLabel>
                            {humanize(item.description)}
                          </DescriptionLabel>
                        )}
                        {!(
                          this.props.editBearingDisabled &&
                          item.location_type_id
                        ) && (
                            <RBAC
                              resource={mapComponentToResource.BearingDetails}
                              operation={operations.Update}
                              yes={(
                                <InlineButton
                                  marginBottom="0px"
                                  text
                                  onClick={() => this.toggleEdit(idx)}
                                >
                                  <EditIcon />
                                </InlineButton>
                              )}
                            />
                          )}
                      </>
                    )}
                    {item.edit && (
                      <>
                        <LinksContainer>
                          <ResourceInputField
                            placeholder="Bearing Location Name"
                            value={item.name}
                            onChange={e =>
                              this.setLocationName(e.target.value, idx)
                            }
                          />
                          <ResourceInputField
                            style={{ marginLeft: '1rem' }}
                            placeholder="Description"
                            value={item.description}
                            onChange={e =>
                              this.setLocationDescription(e.target.value, idx)
                            }
                          />
                          <SvgContainer
                            id={`bearing-name-edit-icon-${idx}`}
                            valid={item.name && item.name.trim()}
                            onClick={() => this.updateLocation(idx)}
                          >
                            <TickSvg
                              inactive={!item.name || !item.name.trim()}
                            />
                          </SvgContainer>
                          <SvgContainer
                            id={`new-bearing-name-cross-icon-${idx}`}
                            marginTop="4px"
                            valid
                            onClick={() => this.resetNameAndDescription(idx)}
                          >
                            <CrossSvg width={20} height={20} fill="#888" />
                          </SvgContainer>
                        </LinksContainer>
                      </>
                    )}
                  </BearingLocation>
                  {item.id ? (
                    <>
                      <FlexContainer justifyContent="flex-start">
                        <table
                          style={{ borderCollapse: 'collapse', width: '100%' }}
                        >
                          <tbody>
                            <tr>
                              {Object.entries(item.properties).map(
                                (prop, idx) => (
                                  <td
                                    key={idx}
                                    style={{
                                      border: '1.5px solid #f0f0f0',
                                      padding: '8px',
                                      width: `${columnSizes[prop[1].order - 1]
                                        }%`
                                    }}
                                  >
                                    <BearingProp order={prop[1].order}>
                                      <ColumnLabel>
                                        {prop[1].display_name ||
                                          humanize(prop[0])}
                                      </ColumnLabel>
                                    </BearingProp>
                                  </td>
                                )
                              )}
                            </tr>
                            <tr>
                              {Object.entries(item.properties).map(
                                (prop, idx) => (
                                  <td
                                    key={idx}
                                    style={{
                                      border: '1.5px solid #f0f0f0',
                                      padding: '8px',
                                      width: `${columnSizes[prop[1].order - 1]
                                        }%`
                                    }}
                                  >
                                    <BearingProp order={prop[1].order}>
                                      <Value
                                        fontSize="12px"
                                        padding="4px 8px"
                                        title={
                                          prop[1].display_name ===
                                            'Manufacturer' ||
                                            prop[1].display_name ===
                                            'Bearing Number'
                                            ? prop[1].value
                                            : Number.parseFloat(
                                              prop[1].value
                                            ).toFixed(3)
                                        }
                                      >
                                        {prop[1].display_name ===
                                          'Manufacturer' ||
                                          prop[1].display_name ===
                                          'Bearing Number'
                                          ? prop[1].value
                                          : Number.parseFloat(
                                            prop[1].value
                                          ).toFixed(3)}
                                      </Value>
                                    </BearingProp>
                                  </td>
                                )
                              )}
                            </tr>
                          </tbody>
                        </table>
                        <RBAC
                          resource={mapComponentToResource.BearingDetails}
                          operation={operations.Update}
                          yes={(
                            <InlineButton
                              marginTop="30px"
                              order={Object.keys(item.properties).length}
                              text
                              onClick={() =>
                                openBearingModal(
                                  item.bearing_location_id,
                                  item.id,
                                  item.key
                                )
                              }
                            >
                              <EditIcon />
                            </InlineButton>
                          )}
                        />
                        <RBAC
                          resource={mapComponentToResource.BearingDetails}
                          operation={operations.Update}
                          yes={(
                            <InlineButton
                              marginTop="30px"
                              order={Object.keys(item.properties).length}
                              text
                              onClick={() =>
                                unassignBearingDetails(
                                  item.bearing_location_id,
                                  item.id,
                                  item.key
                                )
                              }
                            >
                              <DeleteIconSvg />
                            </InlineButton>
                          )}
                        />
                      </FlexContainer>
                    </>
                  ) : (
                    <FlexContainer justifyContent="flex-start">
                      {!this.props.newSensorDisabled &&
                        !newSensorOpen[item.bearing_location_id] && (
                          <RBAC
                            resource={mapComponentToResource.Sensors}
                            operation={operations.Update}
                            yes={(
                              <FlexContainer marginright="5%">
                                <Button
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 90
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      newSensorOpen: {
                                        ...newSensorOpen,
                                        [item.bearing_location_id]: true
                                      }
                                    });
                                  }}
                                  text
                                  fontSize="11px"
                                >
                                  Assign Sensor
                                </Button>
                              </FlexContainer>
                            )}
                          />
                        )}
                      <RBAC
                        resource={mapComponentToResource.BearingDetails}
                        operation={operations.Update}
                        yes={(
                          <Button
                            style={{ position: 'absolute', top: 0, right: 0 }}
                            onClick={() =>
                              openBearingModal(
                                item.bearing_location_id,
                                null,
                                item.key
                              )
                            }
                            text
                            fontSize="11px"
                          >
                            Set Bearing
                          </Button>
                        )}
                      />
                    </FlexContainer>
                  )}
                  {item.motes.concat(item.tx_sensors).map((sensor, idx) => (
                    <>
                      <FlexContainer>
                        <FlexContainer
                          direction="column"
                          margintop="3px"
                          marginright="10px"
                        >
                          <Label>Sensor {parseInt(idx, 10) + 1}</Label>
                          <ResourceInputField value={sensor.serial_number} readOnly />
                        </FlexContainer>
                        {/* {sensor.orientation && (
                        <FlexContainer direction="column" margintop="16px" marginright="10px">
                          <Label>
                            Orientation
                          </Label>
                          <InputFieldBold
                            value={sensor.orientation}
                            disabled
                          />
                        </FlexContainer>
                       )} */}
                        <RBAC
                          resource={mapComponentToResource.Sensors}
                          operation={operations.Update}
                          yes={(
                            <FlexContainer margintop="3.1em">
                              <Button
                                onClick={() =>
                                  this.unassignSensorModalOpen(
                                    sensor,
                                    item.bearing_location_id
                                  )
                                }
                                text
                                fontSize="11px"
                                color="red"
                              >
                                Unassign
                              </Button>
                            </FlexContainer>
                          )}
                        />
                      </FlexContainer>
                    </>
                  ))}
                  {newSensorOpen[item.bearing_location_id] && (
                    <FlexContainer margintop="16px">
                      <FlexContainer direction="column" marginright="15px">
                        <Label>
                          Sensor{' '}
                          {parseInt(
                            item.motes.concat(item.tx_sensors).length,
                            10
                          ) + 1}
                        </Label>
                        <ResourceInputField
                          type="select"
                          search
                          options={
                            item.motes.length > 0
                              ? this.state.unassociatedSensorOptions.filter(
                                sensor => sensor.text.substr(0, 2) !== 'VM'
                              )
                              : this.state.unassociatedSensorOptions
                          }
                          name="sensors"
                          key="sensors"
                          onChange={(e, data) =>
                            this.changeSelectedSensor(
                              e,
                              data,
                              item.bearing_location_id
                            )
                          }
                        />
                      </FlexContainer>
                      {selectedSensor[item.bearing_location_id] &&
                        this.checkOrientationRequired(
                          item.bearing_location_id
                        ) && (
                          <FlexContainer direction="column">
                            <Label>Orientation</Label>
                            <ResourceInputField
                              type="select"
                              search
                              options={
                                sensorsMetadata[
                                  selectedSensor[item.bearing_location_id].model
                                ].orientation
                              }
                              name="orientation"
                              key="orientation"
                              onChange={(e, data) =>
                                this.changeSelectedOrientation(
                                  e,
                                  data,
                                  item.bearing_location_id
                                )
                              }
                            />
                          </FlexContainer>
                        )}
                      {selectedSensor[item.bearing_location_id] &&
                        this.checkPhaseRequired(item.bearing_location_id) && (
                          <FlexContainer direction="column">
                            <Label>Phase</Label>
                            <InputField
                              type="select"
                              search
                              options={
                                sensorsMetadata[
                                  selectedSensor[item.bearing_location_id].model
                                ].phase
                              }
                              name="phase"
                              key="phase"
                              onChange={(e, data) =>
                                this.changeSelectedPhase(
                                  e,
                                  data,
                                  item.bearing_location_id
                                )
                              }
                            />
                          </FlexContainer>
                        )}
                      <SvgContainer
                        id="new-seneor-name-add-icon"
                        marginTop="40px"
                        valid={
                          selectedSensor[item.bearing_location_id] &&
                          (!this.checkOrientationRequired(
                            item.bearing_location_id
                          ) ||
                            (selectedOrientation[item.bearing_location_id] &&
                              (!this.checkPhaseRequired(
                                item.bearing_location_id
                              ) ||
                                selectedPhase[item.bearing_location_id])))
                        }
                        onClick={() =>
                          this.assignSensor(item.bearing_location_id)
                        }
                      >
                        <TickSvg
                          inactive={
                            !selectedSensor[item.bearing_location_id] ||
                            (this.checkOrientationRequired(
                              item.bearing_location_id
                            ) &&
                              !selectedOrientation[item.bearing_location_id]) ||
                            (this.checkPhaseRequired(
                              item.bearing_location_id
                            ) &&
                              !selectedPhase[item.bearing_location_id])
                          }
                        />
                      </SvgContainer>
                      <SvgContainer
                        id="new-sensor-name-cross-icon"
                        marginTop="40px"
                        valid
                        onClick={() =>
                          this.clearAndCloseNewSensor(item.bearing_location_id)
                        }
                      >
                        <CrossSvg width={20} height={20} fill="#888" />
                      </SvgContainer>
                    </FlexContainer>
                  )}
                  {!this.props.newSensorDisabled &&
                    !newSensorOpen[item.bearing_location_id] &&
                    item.id && (
                      <RBAC
                        resource={mapComponentToResource.Sensors}
                        operation={operations.Update}
                        yes={(
                          <FlexContainer margintop="15px">
                            <Button
                              style={{ position: 'absolute', top: 0, right: 0 }}
                              onClick={() => {
                                this.setState({
                                  newSensorOpen: {
                                    ...newSensorOpen,
                                    [item.bearing_location_id]: true
                                  }
                                });
                              }}
                              text
                              fontSize="11px"
                            >
                              Add Sensor
                            </Button>
                          </FlexContainer>
                        )}
                      />
                  )}
                </BearingContainer>
              </BorderContainer>
            ))}
          </BearingDetailsContainer>
        )}
        {newLocationOpen && (
          <LinksContainer marginBottom="2.5rem">
            <ResourceInputField
              placeholder="Bearing Location Name"
              onChange={e => this.setState({ newLocationName: e.target.value })}
            />
            <ResourceInputField
              style={{ marginLeft: '1rem' }}
              placeholder="Description"
              onChange={e => this.setState({ newLocationDescription: e.target.value })}
            />
            <SvgContainer
              id="new-bearing-name-edit-icon"
              valid={newLocationName && newLocationName.trim()}
              onClick={this.onLocationNameSubmit}
            >
              <TickSvg inactive={!newLocationName || !newLocationName.trim()} fill={primaryColor} />
            </SvgContainer>
            <SvgContainer
              id="new-bearing-name-cross-icon"
              marginTop="4px"
              valid
              onClick={this.clearAndCloseNewLocation}
            >
              <CrossSvg width={20} height={20} fill="#888" />
            </SvgContainer>
          </LinksContainer>
        )}
        {unassignSensor && (
          <UnassignSensorAlertPrompt
            message={`Are you sure you want to unassign ${unassignSensor.serial_number} ?`}
            onCancel={this.unassignSensorModalClose}
            onProceed={() =>
              this.unassignSensor(unassignSensor, unassignSensorLocationId)
            }
          />
        )}
      </>
    );
  }
}

BearingDetails.propTypes = {
  order: PropTypes.number.isRequired,
  bearings: PropTypes.array.isRequired,
  openBearingModal: PropTypes.func.isRequired,
  unassignBearingDetails: PropTypes.func.isRequired,
  deleteLocation: PropTypes.func,
  updateLocation: PropTypes.func,
  onLocationNameSubmit: PropTypes.func
};

BearingDetails.defaultProps = {
  deleteLocation: () => { },
  updateLocation: () => { },
  onLocationNameSubmit: () => { }
};

const mapStateToProps = state => ({
  primaryColor: state.companyReducer.partner.theme.primaryColor
});

export default connect(mapStateToProps)(withTheme(BearingDetails));
