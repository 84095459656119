import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import FlexContainer from 'common/components/atoms/FlexContainer';
import H2 from 'common/typography/H2/H2';
import InputField from 'common/components/atoms/InputField';

import {
  BASE_GRAPH_OPTIONS,
  THRESHOLD_OPTIONS
} from '../../../constants/envelope.constants';


const EnvelopeThresholdSettings = ({
  baseGraphType,
  envelopeSettingsError,
  settings,
  updateEnvelopeSettings,
  spectrumXUnit
}) => (
  <FlexContainer marginright="2em" direction="column">
    <H2>Settings</H2>
    <InputField
      disabled={settings.type !== THRESHOLD_OPTIONS.PERCENT_ABOVE_BASE_GRAPH}
      error={envelopeSettingsError.percent_above_base_graph}
      label="Percent above the base graph"
      onClickSelectable={() => {
        if (settings.type === THRESHOLD_OPTIONS.PERCENT_ABOVE_BASE_GRAPH) return;
        updateEnvelopeSettings('threshold', {
          ...settings,
          type: THRESHOLD_OPTIONS.PERCENT_ABOVE_BASE_GRAPH,
          value: 20,
        });
      }}
      onChange={(e) => {
        updateEnvelopeSettings('threshold', {
          ...settings,
          value: _.isEmpty(e.target.value) ? '' : parseFloat(e.target.value, 0)
        });
      }}
      selectable="radio"
      selectableValue={settings.type === THRESHOLD_OPTIONS.PERCENT_ABOVE_BASE_GRAPH}
      type="number"
      value={settings.type === THRESHOLD_OPTIONS.PERCENT_ABOVE_BASE_GRAPH ? settings.value : ''}
    />
    {baseGraphType !== BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE && (
      <InputField
        disabled={settings.type !== THRESHOLD_OPTIONS.STD_ABOVE_BASE_GRAPH}
        error={envelopeSettingsError.std_deviation_above_base_graph}
        label="Std. deviations above base graph"
        onClickSelectable={() => {
          if (settings.type === THRESHOLD_OPTIONS.STD_ABOVE_BASE_GRAPH) return;
          updateEnvelopeSettings('threshold', {
            ...settings,
            type: THRESHOLD_OPTIONS.STD_ABOVE_BASE_GRAPH,
            value: 1,
          });
        }}
        onChange={(e) => {
          updateEnvelopeSettings('threshold', {
            ...settings,
            value: _.isEmpty(e.target.value) ? '' : parseFloat(e.target.value, 0)
          });
        }}
        selectable="radio"
        selectableValue={settings.type === THRESHOLD_OPTIONS.STD_ABOVE_BASE_GRAPH}
        type="number"
        value={settings.type === THRESHOLD_OPTIONS.STD_ABOVE_BASE_GRAPH ? settings.value : ''}
      />
    )}
    <InputField
      error={envelopeSettingsError.starting_frequency}
      label="Starting frequency"
      onChange={(e) => {
        updateEnvelopeSettings('threshold', {
          ...settings,
          starting_frequency: _.isEmpty(e.target.value) ? '' : parseFloat(e.target.value, 0)
        });
      }}
      prefix={spectrumXUnit}
      type="number"
      value={settings.starting_frequency}
    />
  </FlexContainer>
);

EnvelopeThresholdSettings.propTypes = {
  baseGraphType: PropTypes.string.isRequired,
  envelopeSettingsError: PropTypes.object.isRequired,
  frequencyUnits: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  updateEnvelopeSettings: PropTypes.func.isRequired
};

export default EnvelopeThresholdSettings;
