import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NTNLogo from 'common/images/NTNLogo.jpg';
import PetasenseSvg from 'common/images/PetasenseSvg';

const NTNImage = ({ height, width }) => (
    <img src={NTNLogo} alt="NTN Logo" style={{ height: `${height}px`, width: `${width}px` }} />
);

const LogoSvg = ({ width, height, partner }) => {
  if (partner === 'Petasense') {
    return <PetasenseSvg width={width} height={height} />;
  } if (partner === 'NTN') {
    return <NTNImage width={width} height={height} />;
  }
  return null;
};


LogoSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

LogoSvg.defaultProps = {
  width: null,
  height: 24
};

const mapStateToProps = state => ({
  partner: state.companyReducer.partner.name
});

export default connect(mapStateToProps, null)(LogoSvg);
