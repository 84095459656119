import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MultiSelectDropdown_T from 'common/components/molecules/MultiSelectDropdown';
import { FlexContainer } from 'common/components/atoms';
import Button_T from 'common/components/atoms/Button';
import Error_T from '../../../home/AssetHierarchy/components/atoms/Error';


const MultiSelectDropdown = styled(MultiSelectDropdown_T)`
  max-width: 500px;
`;

const Button = styled(Button_T)`
  margin-right: 20px;
`;

const Error = styled(Error_T)`
  padding: 0 0 10px 0;
`;

const SpeedRangeMultiSelect = ({
  optionsAvailable,
  selectedOptions,
  toggleOption,
  error,
  onClickCopySettings,
  disabledCopyButton
}) => (
  <FlexContainer direction="column" alignItems="flex-start" padding="0 0 20px 0">
    <MultiSelectDropdown
      className="ed-multi-select-dropdown"
      optionsAvailable={optionsAvailable}
      selectedOptions={selectedOptions}
      disableSelectAll
      toggleOption={toggleOption}
      typeOfOptions="Speed Ranges"
      id="vfd-bins-select"
      label="Copy settings to other speed ranges"
      placeholder="Select VFD Speed Ranges"
    />
    {error && (<Error>{error}</Error>)}
    <FlexContainer>
      <Button
        text
        onMouseDown={onClickCopySettings}
        disabled={disabledCopyButton}
      >
        Copy Settings
      </Button>
    </FlexContainer>
  </FlexContainer>
);

SpeedRangeMultiSelect.propTypes = {
  optionsAvailable: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  toggleOption: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  onClickCopySettings: PropTypes.func.isRequired,
  disabledCopyButton: PropTypes.bool.isRequired
};

export default SpeedRangeMultiSelect;
