import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import Button from 'common/components/atoms/Button';
import FlexContainer from 'common/components/atoms/FlexContainer';
import InputField_T from 'common/components/atoms/InputField';
import Modal from 'common/components/organisms/Modal';
import LoadingFullScreen from 'common/components/atoms/LoadingFullScreen';
import LoadingSvg from 'common/images/LoadingSvg';

import { ASSET_TYPE } from 'common/charts/constants';
import { getScaledEnvelope } from 'common/utils';
import { getEnvelopeSettingsInPrefUnits } from 'home/AssetHierarchy/utils/envelopeUtils';
import EnvelopeAlarmPreviewItem from './EnvelopeAlarmPreviewItem';
import Error from '../../atoms/Error';
import { BASE_GRAPH_OPTIONS, ENVELOPE_STATES, X_SCALE } from '../../../constants/envelope.constants';

import * as assetDetailActions from '../../../actions/assetDetails.actions';
import * as envelopeActions from '../../../actions/envelope.actions';

const fontSettings = `
  font-family: "Petasense Open Sans";
  font-size: 14px;
  font-weight: 300;
`;

const InputField = styled(InputField_T)`
  width: max-content;
  min-width: 150px;
`;

const MsgContainer = styled.div`
  & div {
    padding: 1rem;
  }
`;

class EnvelopeAlarmPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      spectralEnvelopes: this.getSpectralEnvelopes(),
      selectedBin: this.props.selectedBins[0],
      loading: true,
      showSaveLoader: false,
      completedTags: 0,
      tags: props.tags.filter(t => t.type === props.tag.type && t.active)
    };
  }

  componentDidMount() {
    if (!this.isTagSpectrumLoading()) this.getSpectrumsAndEnvelopeForAllTags();
  }

  componentDidUpdate(prevProps) {
    if (!this.isTagSpectrumLoading() &&
      !_.isEqual(prevProps.tag.envelope_spectrums, this.props.tag.envelope_spectrums)) {
      this.getSpectrumsAndEnvelopeForAllTags();
    }
  }

  getSpectralEnvelopes = () => {
    const { selectedBins, tags: prop_tags, tag } = this.props;
    const tags = prop_tags.filter(t => t.type === tag.type && t.active);
    const spectralEnvelopes = _.map(tags, (tag) => {
      const sp = { tag_id: tag.id };
      selectedBins.forEach((bin) => {
        sp[bin] = {
          selected: true,
          spectralEnvelope: null,
          spectrum: null
        };
      });
      return sp;
    });
    return spectralEnvelopes;
  }

  prepareEnvelopeSettings = (envelope_settings) => {
    const { selectedBins, assetType } = this.props;
    const xScale = assetType === ASSET_TYPE.VFD ? X_SCALE.ORDERS : X_SCALE.FREQUENCY;
    selectedBins.forEach((bin) => {
      const bin_no = `bin_${bin}`;
      const timestamps = this.props.getBaseGraphTimestamps(bin);
      envelope_settings[bin_no].base_graph.timestamps = timestamps;
      if ([BASE_GRAPH_OPTIONS.CURRENTLY_VISIBLE, BASE_GRAPH_OPTIONS.BASELINE].includes(envelope_settings[bin_no].base_graph.type)) {
        envelope_settings[bin_no].base_graph.value = null;
      }
      envelope_settings[bin_no].x_scale = xScale;
    });
    return envelope_settings;
  }

  getCreateEnvelopeParams = () => {
    const {
      breadcrumb: { machine },
      envelopeSettings,
      measurement_type,
      tag,
      settingsUnits,
      currentUser
    } = this.props;
    let settings;
    if (this.props.assetType === ASSET_TYPE.FIXED_SPEED) {
      settings = getEnvelopeSettingsInPrefUnits(
        envelopeSettings,
        settingsUnits,
        currentUser.frequency_units,
        this.props.getShaftSpeed(),
        currentUser.frequency_units
      );
    } else {
      settings = envelopeSettings;
    }

    const envelope_settings = this.prepareEnvelopeSettings(_.cloneDeep(settings));

    return {
      machine_id: machine.id,
      tag_type: tag.type,
      envelope_settings: {
        [measurement_type]: {
          ...envelope_settings
        }
      }
    };
  }

  getSpectrumsAndEnvelopeForAllTags = () => {
    const params = this.getCreateEnvelopeParams();
    this.props.envelopeActions.createEnvelopeThresholds(params).then(
      (res) => {
        this.props.envelopeActions.recurTillTaskCompleted(
          res.task_id,
          this.onTaskSuccess,
          this.onTaskPending,
          this.onTaskError
        );
      },
      (error) => {
        toastr.error(error.message);
        this.props.close();
      }
    );
  }

  onTaskSuccess = (res) => {
    this.updateEnvelopeData(res.result);
  }

  onTaskPending = (res) => {
    this.setState({
      completedTags: res.completed_tags
    });
  }

  onTaskError = (error) => {
    toastr.error(error.message);
    this.props.close();
  }

  isTagSpectrumLoading = () => {
    const { selectedBins, tag } = this.props;
    return _.some(selectedBins, bin =>
      tag.envelope_spectrums[`bin_${bin}`].loading);
  }

  isSaveDisabled = () => {
    const { error } = this.state;
    if (error || this.isTagSpectrumLoading()) return true;
    return false;
  }

  getFooter = () => {
    const { error, showSaveLoader } = this.state;
    return (
      <>
        {error && (<Error>{error}</Error>)}
        <Button secondary="black" disabled={showSaveLoader} onClick={this.onCancel}>Cancel</Button>
        <Button onClick={this.onSave} disabled={this.isSaveDisabled()}>
          {showSaveLoader ? (<LoadingSvg fill="white" width="2.5rem" />) : 'Save'}
        </Button>
      </>
    );
  }

  onBinChange = (selectedBin) => {
    this.setState({ selectedBin });
  }

  binFilterOptionsToBeDisplayed = () => {
    const { selectedBins, binFilterOptions } = this.props;
    return binFilterOptions.filter(bin => selectedBins.includes(bin.value));
  }

  getHeader = () => {
    const { assetType } = this.props;
    const { selectedBin } = this.state;
    return (
      <>
        {assetType === ASSET_TYPE.VFD && (
          <FlexContainer justifyContent="flex-end">
            <InputField
              type="select"
              options={this.binFilterOptionsToBeDisplayed()}
              value={selectedBin}
              onChange={(e, d) => this.onBinChange(d.value)}
              fontSettings={fontSettings}
              optionFontSettings={fontSettings}
              marginBottom="0"
            />
          </FlexContainer>
        )}
      </>
    );
  }

  getEnvelopeDataForAllTags = () => {
    const { spectralEnvelopes, tags } = this.state;
    const { envelopeSettings, measurement_type, selectedBins, currentUser, settingsUnits, assetType } = this.props;
    const envelope_settings = this.prepareEnvelopeSettings(_.cloneDeep(envelopeSettings));
    const final_tags = _.filter(tags, (tag) => {
      const sp = _.find(spectralEnvelopes, sp => sp.tag_id === tag.id);
      return _.some(selectedBins, bin => sp[bin].spectralEnvelope && sp[bin].selected);
    });
    const data = _.map(final_tags, (tag) => {
      const sp = _.find(spectralEnvelopes, sp => sp.tag_id === tag.id);
      const settings = {};
      const envelopes = {};
      const xScaleSavePref = assetType === ASSET_TYPE.VFD ? X_SCALE.ORDERS : X_SCALE.FREQUENCY;
      let xUnitSavePref;
      if (xScaleSavePref === X_SCALE.ORDERS) {
        xUnitSavePref = 'Orders';
      } else {
        xUnitSavePref = currentUser.frequency_units;
      }
      const shaft_speed = this.props.getShaftSpeed();
      const converted_envelope_settings = getEnvelopeSettingsInPrefUnits(
        envelope_settings,
        settingsUnits,
        xUnitSavePref,
        shaft_speed,
        currentUser.frequency_units
      );

      if (xScaleSavePref === X_SCALE.ORDERS) {
        selectedBins.forEach((bin) => {
          sp[bin].spectralEnvelope = getScaledEnvelope(
            sp[bin].spectralEnvelope,
            currentUser.frequency_units,
            xUnitSavePref,
            shaft_speed,
            currentUser.frequency_units
          );
        });
      }

      selectedBins.forEach((bin) => {
        const bin_no = `bin_${bin}`;
        if (sp[bin].selected) {
          settings[bin_no] = {
            ...converted_envelope_settings[bin_no]
          };
          envelopes[bin_no] = sp[bin].spectralEnvelope;
        }
      });

      return {
        id: tag.id,
        envelope_settings: {
          ...tag.envelope_settings,
          [measurement_type]: {
            ...settings
          }
        },
        spectral_envelope: {
          ...tag.spectral_envelope,
          [measurement_type]: {
            ...envelopes
          }
        }
      };
    });
    return { tags: data };
  }

  onCancel = () => {
    this.props.close();
  }

  onSave = () => {
    const data = this.getEnvelopeDataForAllTags();
    const { breadcrumb: { machine, site } } = this.props;
    this.setState({ showSaveLoader: true });
    this.props.envelopeActions.bulkUpdateTags(machine.id, data).then(
      (res) => {
        toastr.success(res.message);
        this.props.close();
        this.props.setEnvelopeState(ENVELOPE_STATES.VIEW);
        this.setState({ showSaveLoader: false });
        this.props.assetDetailActions.getTags(machine.id, site.id);
      },
      (error) => {
        toastr.error(error.message);
        this.setState({ showSaveLoader: false });
      }
    );
  };

  updateEnvelopeData = (results) => {
    let { spectralEnvelopes } = this.state;
    const { measurement_type, selectedBins } = this.props;

    spectralEnvelopes = _.map(this.state.spectralEnvelopes, (sp) => {
      const result = _.find(results, res => res.tag_id === sp.tag_id);
      selectedBins.forEach((bin) => {
        const { envelope_data, spectrum_info } = result.envelope_info[measurement_type][`bin_${bin}`];
        sp[bin] = {
          ...sp[bin],
          spectralEnvelope: {
            envelope_data
          },
          selected: !_.isEmpty(spectrum_info),
          spectrum: _.isEmpty(spectrum_info) ? null : spectrum_info
        };
      });
      return sp;
    });
    this.setState({ spectralEnvelopes, loading: false });
  }

  areNoTagsSelected = () => {
    const { selectedBins } = this.props;
    const { spectralEnvelopes } = this.state;
    for (let i = 0; i < spectralEnvelopes.length; i++) {
      const sp = spectralEnvelopes[i];
      if (_.some(selectedBins, bin => sp[bin].spectralEnvelope && sp[bin].selected)) return false;
    }
    return true;
  }

  toggleSelectedTag = (tag_id) => {
    let { spectralEnvelopes } = this.state;
    const { selectedBin } = this.state;
    spectralEnvelopes = _.map(this.state.spectralEnvelopes, (sp) => {
      if (sp.tag_id !== tag_id) return sp;
      sp[selectedBin] = {
        ...sp[selectedBin],
        selected: !sp[selectedBin].selected
      };
      return sp;
    });
    let error = '';
    if (this.areNoTagsSelected()) error = 'select at least one tag';
    this.setState({ spectralEnvelopes, error });
  }

  getLoaderMessage = () => {
    const { completedTags } = this.state;
    const msg = (
      <MsgContainer>
        <div>Computing Envelope Thresholds...</div>
        <div><b>{completedTags}</b> out of <b>{this.state.tags.length}</b> tags completed</div>
      </MsgContainer>
    );
    return msg;
  }

  render() {
    const {
      breadcrumb,
      currentUser,
      envelopeSettings,
      config,
      componentNode,
      assetType,
      spectrumXScale,
      setSpectrumXScale,
      spectrumXUnit,
      setSpectrumXUnit
    } = this.props;
    const { spectralEnvelopes, selectedBin, loading, tags } = this.state;
    return (
      <>
        <Modal
          close={this.props.close}
          footer={this.getFooter}
          header={this.getHeader}
          title={`${breadcrumb.machine.name} > Spectrum Envelope Alarm > Preview`}
        >
          {tags.map((tag, idx) => {
            const envelopes = _.find(spectralEnvelopes, sp => sp.tag_id === tag.id);
            const envelope = envelopes[selectedBin];
            return (
              <EnvelopeAlarmPreviewItem
                assetType={assetType}
                config={config}
                componentNode={componentNode}
                envelopeSettings={envelopeSettings}
                frequencyUnits={currentUser.frequency_units}
                key={`preview-${idx}`}
                loading={loading}
                selected={envelope.spectrum && envelope.selected}
                spectralEnvelope={envelope.spectralEnvelope}
                spectrum={envelope.spectrum}
                tag={tag}
                toggleSelectedTag={this.toggleSelectedTag}
                unitSystem={currentUser.unit_system}
                spectrumXScale={spectrumXScale}
                setSpectrumXScale={setSpectrumXScale}
                spectrumXUnit={spectrumXUnit}
                setSpectrumXUnit={setSpectrumXUnit}
              />
            );
          })}
        </Modal>
        {loading && <LoadingFullScreen msg={this.getLoaderMessage()} />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const site = _.find(state.assetHierarchyReducer.assetInfo.sites, s => s.site_id === state.breadcrumb.site.id);
  const machine = site && _.find(site.machines, m => m.machine_id === state.breadcrumb.machine.id);
  const tags = machine && machine.tags;
  return {
    breadcrumb: state.breadcrumb,
    currentUser: state.user.user,
    tags
  };
};

const mapDispatchToProps = dispatch => ({
  assetDetailActions: bindActionCreators(assetDetailActions, dispatch),
  envelopeActions: bindActionCreators(envelopeActions, dispatch)
});

EnvelopeAlarmPreview.propTypes = {
  close: PropTypes.func.isRequired,
  envelopeSettings: PropTypes.object.isRequired,
  measurement_type: PropTypes.number,
  setEnvelopeState: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  getBaseGraphTimestamps: PropTypes.func.isRequired,
  selectedBins: PropTypes.array.isRequired,
  tag: PropTypes.object.isRequired,
  binFilterOptions: PropTypes.object.isRequired,
  assetType: PropTypes.string.isRequired
};

EnvelopeAlarmPreview.defaultProps = {
  measurement_type: 1
};

export default connect(mapStateToProps, mapDispatchToProps)(EnvelopeAlarmPreview);
