import React from 'react';
import PropTypes from 'prop-types';

const HealthIconSvg = ({ width, height }) => (
<svg width={width} height={height} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g id="AH_v3" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="ah_v3-5-12-1" transform="translate(-370.000000, -250.000000)">
      <g id="activity" transform="translate(344.000000, 134.000000)">
        <g id={0} transform="translate(0.000000, 86.000000)">
          <g id="health-icon" transform="translate(24.000000, 28.000000)">
            <g id="bg">
              <rect id="icon-bg" x={0} y={0} width={20} height={20} />
            </g>
            <g id="icon" transform="translate(3.000000, 3.000000)" stroke="#3C3D3A" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3125">
              <path d="M14,5.83333333 C14,5.18900113 13.4776655,4.66666667 12.8333333,4.66666667 L9.33333333,4.66666667 L9.33333333,1.16666667 C9.33333333,0.522334459 8.81099887,0 8.16666667,0 L5.83333333,0 C5.18900113,0 4.66666667,0.522334459 4.66666667,1.16666667 L4.66666667,4.66666667 L1.16666667,4.66666667 C0.522334459,4.66666667 0,5.18900113 0,5.83333333 L0,8.16666667 C0,8.81099887 0.522334459,9.33333333 1.16666667,9.33333333 L4.66666667,9.33333333 L4.66666667,12.8333333 C4.66666667,13.4776655 5.18900113,14 5.83333333,14 L8.16666667,14 C8.81099887,14 9.33333333,13.4776655 9.33333333,12.8333333 L9.33333333,9.33333333 L12.8333333,9.33333333 C13.4776655,9.33333333 14,8.81099887 14,8.16666667 L14,5.83333333 Z" id="Path" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

);

HealthIconSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

HealthIconSvg.defaultProps = {
  width: 16,
  height: 16
};

export default HealthIconSvg;
