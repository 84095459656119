import moment from 'moment';
import FeedTypes from '../actions/feed.types';

const initialState = {
  items: [],
  loading: false,
  isMeta: false,
  hasMore: true,
  before: moment().format(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FeedTypes.GET_FEEDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FeedTypes.GET_FEEDS_SUCCESS:
      return {
        ...state,
        items: state.items.concat(action.payload.items),
        loading: false,
        before: action.payload.start_timestamp,
        hasMore: action.payload.has_more,
        isMeta: action.payload.isRange,
      };
    case FeedTypes.GET_FEEDS_FAILURE:
      return {
        ...state,
        error: action.error.message,
        loading: false,
      };
    default:
      return state;
  }
};
