import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';

import FlexContainer from 'common/components/atoms/FlexContainer';
import TxPortSvg from 'common/images/TXPortSvg';
import Label_T from 'common/typography/Label/Label';
import PerChannelSvg from 'common/images/PerChannelSvg';
import AggressiveReadingIcon from 'common/images/AggressiveReadingIcon';
import ButtonT from 'common/components/atoms/Button';
import H2_T from 'common/typography/H2/H2';
import H4_T from 'common/typography/H4/H4';
import UnassignSensorAlertPrompt from 'common/components/organisms/AlertPrompt';
import Modal from 'common/components/organisms/Modal';
import { ResourceItem } from 'common/components/atoms/ResourceDetails';
import SimultaneousMeasurementIcon from 'common/images/SimultaneousMeasurementIcon';
import { isPartnerAdmin, isPetasenseAdmin } from 'common/rbac/util';

import { vibrationMeasurementTypeEnum } from '../constants/sensors.constants';
import {
  MODAL_TITLES,
  MODAL_SUBTITLES,
  MODAL_MESSAGES,
  TOOLTIP_MESSAGES,
  BUTTON_LABELS,
  SECTION_TITLES,
  SAMPLING_LABELS,
  DROPDOWN_PLACEHOLDERS,
  MACHINE_LABELS,
  CHANNEL_LABEL,
  PORT_LABEL,
  NOTE_LABEL,
  MODAL_DIMENSIONS,
  FMAX_LOR_CONVERSION_FACTOR,
  SAMPLING_LIMIT
} from '../constants/txPorts.constants';
import AssignSensorModalV2 from './AssignSensorModalV2';

const PortHeader = styled(FlexContainer).attrs({ direction: 'row', alignItems: 'center' })`
  margin-top: 0.8em;
  position: relative;
  &:after {
    display: inline-block;
    content: ' ';
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${props => props.theme.colors.borderGray};
  }
`;

const PortControls = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end'
})`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: white;
  padding-left: 10px;
`;

const SamplingSettingsContainer = styled(FlexContainer).attrs({
  direction: 'column'
})`
  margin-top: 1.5em;
  padding: 1.5em;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
`;

const SamplingRow = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
})`
  margin-bottom: 1.2em;
  &:last-child {
    margin-bottom: 0;
  }

  .ui.selection.dropdown {
    min-width: 200px;
    border-color: #ced4da;
    &:hover, &:focus {
      border-color: ${props => props.theme.primaryColor};
    }
  }
`;

const SamplingLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #495057;
  margin-right: 1em;
`;

const Label = styled(Label_T)`
  font-weight: 800;
  min-width: 60px;
  ${props => props.grey && `color: ${props.theme.colors.greyXD};`}
  ${props => props.marginbottom && `margin-bottom: ${props.marginbottom};`}
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.paddingtop && `padding-top: ${props.paddingtop};`}
  ${props => props.width && `width: ${props.width};`}
`;

const PerChannelContainer = styled(FlexContainer).attrs({ direction: 'row', alignItems: 'center' })`
  border: 1px solid #D8DCD3;
  border-radius: 4px;
  padding: 0.5em 1em;
  margin-top: 0.8em;
`;

const H4 = styled(H4_T)`
  width: max-content;
  padding:10px
  margin: 5px;
  font-weight:300;
  font-size:12px
  ${props => props.hover && `
    &:hover {
      color: ${props.theme.primaryColor};
      font-weight: 300;
      cursor: pointer;
    }
  `}
`;

const SubTitle = styled(H4)`
  font-weight: 600;
  font-size: 12px;
  color: #333;
  padding-top:5px
`;

const Button = styled(ButtonT)`
  ${props => props.paddingTop && `padding-top: ${props.paddingTop};`}
`;

const H2 = styled(H2_T)`
  padding-top: 1em;
  padding-bottom: 0.5em;
  font-size: 20px;
`;

const IconContainer = styled.div`
  margin-left: 10px;
`;

const FooterContainer = styled(FlexContainer).attrs({
  direction: 'row',
  justifyContent: 'flex-end'
})`
  margin-top: 20px;
  gap: 10px;
  border-top: 1px solid #f0f0f0;
  padding-top: 20px;
`;

const ModalText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
  margin-bottom: 1.5rem;
`;

const ModalNote = styled(ModalText)`
  display: flex;
  align-items: flex-start;
  background: #fff3e0;
  border: 1px solid #ffe0b2;
  border-radius: 6px;
  padding: 1em 1.2em;
  margin: 1em 0 1.5em;

  strong {
    color: #e65100;
    margin-right: 0.5em;
  }
`;

const ModalSection = styled.div`
  & + & {
    margin-top: 2em;
  }
`;

const ModalSectionTitle = styled(H4_T)`
  font-weight: 600;
  font-size: 16px;
  color: #212529;
  margin-bottom: 1em;
`;

const TxPorts = (props) => {
  const txs = _.sortBy(props.txs, 'serial_number');
  const [assignSensorModalOpen, setAssignSensorModalOpen] = useState(false);
  const [sensorToUnassign, setSensorToUnassign] = useState(null);
  const [updatingPorts, setUpdatingPorts] = useState({});
  const [selectedPort, setSelectedPort] = useState(null);
  const [selectedTx, setSelectedTx] = useState(null);
  const [samplingSettings, setSamplingSettings] = useState({ fullBandwidth: null, highRes: null });
  const [samplingModalOpen, setSamplingModalOpen] = useState(false);

  const canEditSimultaneous = props.editMode && (isPetasenseAdmin(props.user) || isPartnerAdmin(props.user));

  const disableSimultaneousMeasurement = async (sensor) => {
    if (!props.onUpdateSensorSettings) return;

    try {
      const updateData = {
        [sensor.model]: {
          serial_number: [sensor.serial_number],
          simultaneous_measurement_enabled: false,
          type: sensor.sensor_type
        }
      };

      await props.onUpdateSensorSettings(updateData);
    } catch (error) {
      toastr.error('Failed to disable simultaneous measurement');
    }
  };

  const unassignSensor = async (sensor, txSerialNumber) => {
    if (sensor.simultaneous_measurement_enabled) {
      await disableSimultaneousMeasurement(sensor);
    }

    if (sensor.no_of_channels > sensor.orientations.length) {
      setSensorToUnassign({
        sensorSerialNumber: sensor.serial_number,
        txSerialNumber
      });
    } else {
      props.unassignSensor(sensor.serial_number, txSerialNumber);
    }
  };

  // Toggle simultaneous measurement for a port - now opens modal first
  const toggleSimultaneousMeasurement = (port, tx) => {
    // Store the port and tx for later use when the modal is confirmed
    setSelectedPort(port);
    setSelectedTx(tx);

    // Initialize sampling settings with current values
    const currentSettings = {
      fullBandwidth: null,
      highRes: null
    };

    setSamplingSettings(currentSettings);
    setSamplingModalOpen(true);
  };

  // Apply both simultaneous measurement and sampling settings
  const applySimultaneousAndSamplingSettings = () => {
    if (!selectedPort || !selectedTx) {
      setSamplingModalOpen(false);
      return;
    }

    // Track which port is being updated
    setUpdatingPorts({
      ...updatingPorts,
      [`${selectedTx.serial_number}-${selectedPort.port_id}`]: true
    });

    // Close the modal
    setSamplingModalOpen(false);

    const updatePromises = [];

    // Group sensors by model
    const sensorsByModel = {};
    selectedPort.sensors.forEach((sensor) => {
      if (!sensorsByModel[sensor.model]) {
        sensorsByModel[sensor.model] = [];
      }
      sensorsByModel[sensor.model].push(sensor);
    });

    // First update sampling settings if any are changed
    if (samplingSettings.fullBandwidth !== null || samplingSettings.highRes !== null) {
      Object.entries(sensorsByModel).forEach(([model, sensors]) => {
        sensors.forEach((sensor) => {
          const samplingData = {
            type: sensor.sensor_type,
            spectrum_measurements: {}
          };

          if (samplingSettings.fullBandwidth !== null) {
            samplingData.spectrum_measurements[vibrationMeasurementTypeEnum.fullBandwidth] = {
              sampling: {
                resolution: samplingSettings.fullBandwidth
              }
            };
          }

          if (samplingSettings.highRes !== null) {
            samplingData.spectrum_measurements[vibrationMeasurementTypeEnum.highRes] = {
              sampling: {
                resolution: samplingSettings.highRes
              }
            };
          }

          // If it's a VSX, use sensor_group_serial_number
          if (sensor.sensor_group_serial_number) {
            samplingData.serial_number = [sensor.sensor_group_serial_number];
          } else {
            samplingData.serial_number = [sensor.serial_number];
          }

          updatePromises.push(props.onUpdateSensorSettings({ [model]: samplingData }));
        });
      });
    }

    // Check if all sensors have simultaneous measurement enabled
    const allSensorsSimultaneous = selectedPort.sensors.every(sensor => sensor.simultaneous_measurement_enabled);

    // Then update simultaneous measurement setting
    Object.entries(sensorsByModel).forEach(([model, sensors]) => {
      const simultaneousData = {
        serial_number: sensors.map(s => s.serial_number),
        simultaneous_measurement_enabled: !allSensorsSimultaneous,
        type: sensors[0].sensor_type
      };

      updatePromises.push(props.onUpdateSensorSettings({ [model]: simultaneousData }));
    });

    // Handle all updates
    if (props.onUpdateSensorSettings) {
      Promise.all(updatePromises)
        .then(() => {
          setUpdatingPorts({
            ...updatingPorts,
            [`${selectedTx.serial_number}-${selectedPort.port_id}`]: false
          });
          // Refresh TX details after successful update
          if (props.onRefreshTxDetails) {
            props.onRefreshTxDetails();
          }
        })
        .catch((error) => {
          setUpdatingPorts({
            ...updatingPorts,
            [`${selectedTx.serial_number}-${selectedPort.port_id}`]: false
          });
          toastr.error(error);
        });
    } else {
      setUpdatingPorts({
        ...updatingPorts,
        [`${selectedTx.serial_number}-${selectedPort.port_id}`]: false
      });
    }
  };

  // Filter sampling options based on simultaneous measurement setting
  const getFilteredSamplingOptions = (options, isSimultaneousMeasurementEnabled) => {
    if (!options) return [];

    if (isSimultaneousMeasurementEnabled) {
      // Filter options to only include those with fewer than 20k samples
      return options.filter((option) => {
        const samplingSize = option.value && option.value.lines_of_resolution ?
          option.value.lines_of_resolution * FMAX_LOR_CONVERSION_FACTOR :
          0;
        return samplingSize <= SAMPLING_LIMIT;
      });
    }

    return options;
  };

  // Update local sampling settings in modal without submitting to API
  const updateLocalSamplingSettings = (measurementType, value) => {
    setSamplingSettings({
      ...samplingSettings,
      [measurementType]: value
    });
  };

  // Add this function to check if any changes are made
  const hasChanges = () => {
    // Check if sampling settings have changed
    const hasSamplingChanges = samplingSettings.fullBandwidth !== null || samplingSettings.highRes !== null;
    return hasSamplingChanges;
  };

  const renderSamplingSettings = () => {
    const firstSensor = selectedPort.sensors[0];
    const sensorMetadata = props.metadata;
    if (!sensorMetadata) return null;

    const willBeSimultaneous = !selectedPort.sensors.every(s => s.simultaneous_measurement_enabled);

    const fullBandwidthOptions = getFilteredSamplingOptions(
      sensorMetadata[firstSensor.model].spectrum_measurements.sampling.resolution[
        vibrationMeasurementTypeEnum.fullBandwidth
      ],
      willBeSimultaneous
    );

    const highResOptions = getFilteredSamplingOptions(
      sensorMetadata[firstSensor.model].spectrum_measurements.sampling.resolution[
        vibrationMeasurementTypeEnum.highRes
      ],
      willBeSimultaneous
    );

    return (
      <>
        {fullBandwidthOptions && fullBandwidthOptions.length > 0 && (
          <SamplingRow>
            <SamplingLabel>{SAMPLING_LABELS.FULL_BANDWIDTH}</SamplingLabel>
            <Dropdown
              selection
              placeholder={DROPDOWN_PLACEHOLDERS.SELECT_RESOLUTION}
              options={fullBandwidthOptions}
              value={samplingSettings.fullBandwidth}
              onChange={(e, data) => updateLocalSamplingSettings('fullBandwidth', data.value)}
            />
          </SamplingRow>
        )}
        {highResOptions && highResOptions.length > 0 && (
          <SamplingRow>
            <SamplingLabel>{SAMPLING_LABELS.HIGH_RESOLUTION}</SamplingLabel>
            <Dropdown
              selection
              placeholder={DROPDOWN_PLACEHOLDERS.SELECT_RESOLUTION}
              options={highResOptions}
              value={samplingSettings.highRes}
              onChange={(e, data) => updateLocalSamplingSettings('highRes', data.value)}
            />
          </SamplingRow>
        )}
      </>
    );
  };

  const renderSimultaneousMeasurementIcon = (tx, port) => {
    const sensorSerialNumbers = port.sensors.map(sensor => sensor.serial_number);
    const hasTriaxialSensor = port.sensors.some(sensor =>
      sensor.channels &&
      sensor.channels.length === 3 &&
      sensor.channels.includes(1) &&
      sensor.channels.includes(2) &&
      sensor.channels.includes(3)
    );
    const sensorModels = [...new Set(port.sensors.map(sensor => sensor.model))];
    const allSameSensorModel = sensorModels.length <= 1;
    const hasMultipleSensors = sensorSerialNumbers.length >= 2;
    const canEnableSimultaneous = (hasMultipleSensors || hasTriaxialSensor) && allSameSensorModel;
    const allSensorsSimultaneous = port.sensors.length > 0 && port.sensors.every(sensor => sensor.simultaneous_measurement_enabled);

    let tooltipMessage = '';
    if (!canEditSimultaneous) {
      tooltipMessage = allSensorsSimultaneous ? TOOLTIP_MESSAGES.STATUS_ENABLED : TOOLTIP_MESSAGES.STATUS_DISABLED;
      if (props.editMode && props.userRole === 'user') {
        tooltipMessage = `${tooltipMessage} ${TOOLTIP_MESSAGES.REQUIRES_ADMIN}`;
      }
    } else if (!hasMultipleSensors && !hasTriaxialSensor) {
      tooltipMessage = TOOLTIP_MESSAGES.REQUIRES_MULTIPLE_OR_TRIAXIAL;
    } else if (!allSameSensorModel) {
      tooltipMessage = TOOLTIP_MESSAGES.REQUIRES_SAME_MODEL;
    } else {
      tooltipMessage = allSensorsSimultaneous ? TOOLTIP_MESSAGES.DISABLE : TOOLTIP_MESSAGES.ENABLE;
    }

    return (
      <IconContainer title={tooltipMessage}>
        <SimultaneousMeasurementIcon
          active={allSensorsSimultaneous}
          disabled={!canEditSimultaneous || !canEnableSimultaneous || updatingPorts[`${tx.serial_number}-${port.port_id}`]}
          onClick={canEditSimultaneous ? () => toggleSimultaneousMeasurement(port, tx) : undefined}
        />
      </IconContainer>
    );
  };

  return (
    <>
      <FlexContainer>
        <H2>{SECTION_TITLES.PORTS}</H2>
        {props.editMode && (
          <Button text paddingTop="1em" margin="0 0 0 1em" fontSize="12px" onClick={() => setAssignSensorModalOpen(true)}>
            {BUTTON_LABELS.ASSIGN_SENSOR}
          </Button>
        )}
      </FlexContainer>
      {assignSensorModalOpen && (
        <AssignSensorModalV2
          close={() => setAssignSensorModalOpen(false)}
          txs={txs}
          onAssignSensor={props.onAssignSensor}
        />
      )}
      {samplingModalOpen && (
        <Modal
          close={() => setSamplingModalOpen(false)}
          width={MODAL_DIMENSIONS.WIDTH}
          title={selectedPort && !selectedPort.sensors.every(s => s.simultaneous_measurement_enabled) ?
            MODAL_TITLES.ENABLE : MODAL_TITLES.DISABLE}
          subtitle={MODAL_SUBTITLES.SIMULTANEOUS_MEASUREMENT}
          outerPadding={MODAL_DIMENSIONS.OUTER_PADDING}
          headerPadding={MODAL_DIMENSIONS.HEADER_PADDING}
          padding={MODAL_DIMENSIONS.PADDING}
        >
          <ModalSection>
            <ModalText>
              {selectedPort && !selectedPort.sensors.every(s => s.simultaneous_measurement_enabled)
                && MODAL_MESSAGES.ENABLE_DESCRIPTION}
            </ModalText>
            {selectedPort && !selectedPort.sensors.every(s => s.simultaneous_measurement_enabled) && (
              <ModalNote>
                <strong>{NOTE_LABEL}:</strong>
                {MODAL_MESSAGES.SAMPLING_LIMIT_NOTE}
              </ModalNote>
            )}
          </ModalSection>
          <ModalSection>
            <ModalSectionTitle>{SECTION_TITLES.UPDATE_SAMPLING_SETTINGS}</ModalSectionTitle>
            <SamplingSettingsContainer>
              {renderSamplingSettings()}
            </SamplingSettingsContainer>
          </ModalSection>
          <FooterContainer>
            <ButtonT
              text
              color="grey"
              onClick={() => setSamplingModalOpen(false)}
            >
              {BUTTON_LABELS.CANCEL}
            </ButtonT>
            <ButtonT
              primary
              onClick={applySimultaneousAndSamplingSettings}
              disabled={!hasChanges()}
            >
              {BUTTON_LABELS.APPLY}
            </ButtonT>
          </FooterContainer>
        </Modal>
      )}
      {txs && txs.map((tx, txIndex) => {
        const aggressiveIconHoverText = tx.poe ? 'Device in aggressive measurement mode.' :
          'Device in aggressive measurement mode, this will impact battery life.';
        return (
          <Fragment key={`tx-${txIndex}`}>
            {sensorToUnassign && (
              <UnassignSensorAlertPrompt
                message={`Are you sure you want to unassign ${sensorToUnassign.sensorSerialNumber} from all Transmitters?`}
                onCancel={() => setSensorToUnassign(null)}
                onProceed={() => props.unassignSensor(sensorToUnassign.sensorSerialNumber, sensorToUnassign.txSerialNumber)}
              />
            )}
            {txs.length > 1 && <Label fontsize="16px">{tx.serial_number}</Label>}
            {tx.ports && tx.ports.map((port, portIndex) => (
              <Fragment key={`tx-${txIndex}-port-${portIndex}`}>
                <PortHeader>
                  <TxPortSvg width={60} height={30} portNumber={port.port_position} />
                  <Label grey fontsize="16px" marginbottom="0" paddingtop="0">{PORT_LABEL} {port.port_position}</Label>
                  <PortControls>
                    {renderSimultaneousMeasurementIcon(tx, port)}
                  </PortControls>
                </PortHeader>
                {port.sensors && port.sensors.map((sensor, sensorIndex) => (
                  <Fragment key={`tx-${txIndex}-port-${portIndex}-sensor-${sensorIndex}`}>
                    {sensor.channels && sensor.channels.map((channel, channelIndex) => (
                      <PerChannelContainer key={`tx-${txIndex}-port-${portIndex}-sensor-${sensorIndex}-channel-${channelIndex}`}>
                        <PerChannelSvg channels={[channel]} />
                        <H4 paddingRight="2em" marginRight="1.5em">{CHANNEL_LABEL} {channel}</H4>
                        <ResourceItem
                          noPadding
                          hover={props.viewSensor && sensor.sensor_type}
                          onClick={props.viewSensor && sensor.sensor_type ? () => props.viewSensor(sensor.serial_number, sensor.machine_id) : () => { }}
                        >
                          {sensor.sensor_type ? `${sensor.sensor_type} (${sensor.serial_number}${sensor.no_of_channels
                            && sensor.no_of_channels > 1 && sensor.orientations[channelIndex] ? `_${sensor.orientations[channelIndex]}` : ''})` : '--'}
                          {sensor.aggressive_measurement && (
                            <span
                              style={{ float: 'right', paddingLeft: '5px', margin: '5px' }}
                              title={aggressiveIconHoverText}
                            >
                              <AggressiveReadingIcon />
                            </span>
                          )}
                        </ResourceItem>
                        <SubTitle>
                          {sensor.machine ? MACHINE_LABELS.ON : ''} {sensor.machine ?
                            <Link style={{ color: '#333' }} to={`/machines/${sensor.machine_id}/overview`}>{sensor.machine}</Link>
                            : MACHINE_LABELS.NOT_ASSOCIATED}
                        </SubTitle>
                        {props.editMode && (
                          <Button text color="red" margin="0 0 0 auto" fontSize="12px" onClick={() => unassignSensor(sensor, tx.serial_number)}>
                            {BUTTON_LABELS.UNASSIGN}
                          </Button>
                        )}
                      </PerChannelContainer>
                    ))}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </Fragment>
        );
      })}
    </>
  );
};

TxPorts.propTypes = {
  txs: PropTypes.array,
  editMode: PropTypes.bool,
  userRole: PropTypes.oneOf(['petasense_admin', 'partner_admin', 'user']),
  viewSensor: PropTypes.func,
  unassignSensor: PropTypes.func,
  onAssignSensor: PropTypes.func,
  onUpdateSensorSettings: PropTypes.func,
  metadata: PropTypes.object,
  onRefreshTxDetails: PropTypes.func
};

TxPorts.defaultProps = {
  txs: [],
  editMode: false,
  userRole: 'user',
  viewSensor: null,
  unassignSensor: () => { },
  onAssignSensor: () => { },
  onUpdateSensorSettings: null,
  metadata: null,
  onRefreshTxDetails: () => { }
};

export default TxPorts;
