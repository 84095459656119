import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from 'common/styles/colors';
import { ThLarge } from 'common/images/FaIcons';
import ActivityList from './ActivityList';
import { ListFooter } from '../ListComponents';

const TITLE_HEIGHT = 40;
const Title = styled.div`
  color: ${colors.darkGray};
  font-weight: 600;
  width: 100%;
  padding: 1em 0 0 1em;
  height: ${TITLE_HEIGHT}px;
`;

class ActivityPage extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: 'white', height: '100%', borderRadius: '4px', position: 'relative' }}>
        <div>
          <Title>Recent Activity</Title>
          <ActivityList activities={this.props.data} />
        </div>
        <ListFooter>
          <Link title="View All Activity" className="ps-hover-pointer" to="/activity">
            <ThLarge />
          </Link>
        </ListFooter>
      </div>
    );
  }
}

export default ActivityPage;
