import React, { Fragment } from 'react';
import styled, { withTheme } from 'styled-components';

import Modal from '../../../common/components/organisms/Modal';
import FlexContainer from '../../../common/components/atoms/FlexContainer';
import Button_T from '../../../common/components/atoms/Button';

const ButtonContainer = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 30px;
`;

const Button = styled(Button_T)`
  margin-left: 10px;
  &:hover {
    border-color: ${props => props.borderColor}
  }
`;

const TextContainer = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
  font-size: ${props => props.fontSize};
  color: ${props => props.theme.colors.black};
  line-height: ${props => props.lineHeight};
  font-weight: ${props => props.fontWeight};

`;

const DeleteSensorModel = ({
  closeModal,
  deleteModel,
  modelName,
  ...rest
}) => (
  <Modal title="" close={closeModal} height="30%" width="40%" titleSize="16px">
    <Fragment>
      <div>
        <TextContainer fontSize="14px" lineHeight="27px">Are you sure you want to delete {modelName}?</TextContainer>
      </div>
      <ButtonContainer>
        <Button borderColor={rest.theme.colors.critical} onClick={deleteModel} secondary={rest.theme.colors.critical}>DELETE</Button>
        <Button onClick={closeModal} secondary={rest.theme.colors.black}>
          CANCEL
        </Button>
      </ButtonContainer>
    </Fragment>
  </Modal>
);

export default withTheme(DeleteSensorModel);
