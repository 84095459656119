import _ from 'lodash';
import { axiosInstance, ENDPOINT } from 'common/constants';
import { toastr } from 'react-redux-toastr';
import { handleResponse } from 'common/helpers';
import activityTypes from 'home/Activity/actions/activity.types';
import { getMachineDetails } from 'home/Machines/MachineDetails/machineDetails.actions';
import { machinesActions } from 'home/Machines/machines.actions';
import { getHierarchy } from 'home/AssetHierarchy/actions/assetDetails.actions';
import { uploadAttachment } from './attachments.actions';
import { taskConstants } from '../constants/constants';

export const addTask = (params, isAssetsPage) => {
  const request = () => ({ type: taskConstants.ADD_TASK_REQUEST });
  const success = () => ({ type: taskConstants.ADD_TASK_SUCCESS });
  const failure = () => ({ type: taskConstants.ADD_TASK_FAILURE });

  return (dispatch, getState) => {
    dispatch(request());

    return handleResponse(axiosInstance.post(ENDPOINT.ADD_TASK, { ...params }))
      .then(
        (res) => {
          const files = getState().attachments.files && getState().attachments.files.task;

          dispatch(success(res.result));
          dispatch(getMachineDetails(params.machine_id));
          dispatch(getHierarchy(false));
          if (isAssetsPage) dispatch(machinesActions.getMachines());

          toastr.success('Task created successfully');
          if (!_.isEmpty(files)) {
            dispatch(uploadAttachment('task', res.id, files, 0));
          }
          return res;
        },
        (error) => {
          dispatch(failure(error));
          toastr.error(error.message);
          throw error;
        }
      );
  };
};

export const deleteTask = (taskId) => {
  const request = () => ({ type: activityTypes.DELETE_TASK_REQUEST });
  const success = taskId => ({ type: activityTypes.DELETE_TASK_SUCCESS, taskId });
  const failure = () => ({ type: activityTypes.DELETE_TASK_FAILURE });

  return (dispatch) => {
    dispatch(request());

    return handleResponse(axiosInstance.delete(ENDPOINT.TASK(taskId)))
      .then(
        (res) => {
          dispatch(success(taskId));
          toastr.success('Task deleted successfully');
          return res;
        },
        (error) => {
          dispatch(failure(error));
          toastr.error(error.message);
          throw error;
        }
      );
  };
};

export const updateTask = (taskId, params) => {
  const request = () => ({ type: taskConstants.UPDATE_TASK_REQUEST });
  const success = updatedDetails => ({ type: taskConstants.UPDATE_TASK_SUCCESS, updatedDetails });
  const failure = () => ({ type: taskConstants.UPDATE_TASK_FAILURE });

  return (dispatch) => {
    dispatch(request());

    return handleResponse(axiosInstance.patch(ENDPOINT.TASK(taskId), { ...params }))
      .then(
        (res) => {
          dispatch(success(params));
          dispatch(getTaskDetails(taskId));
          dispatch(getHierarchy(false));
          toastr.success('Task updated successfully');
          return res;
        },
        (error) => {
          dispatch(failure(error));
          toastr.error(error.message);
          throw error;
        }
      );
  };
};

export const getTaskDetails = (id, refreshHistory = true) => {
  const request = () => ({ type: taskConstants.GET_TASK_DETAILS_REQUEST, refreshHistory });
  const success = task => ({ type: taskConstants.GET_TASK_DETAILS_SUCCESS, task });
  const failure = error => ({ type: taskConstants.GET_TASK_DETAILS_FAILURE, error });

  return (dispatch, getState) => {
    // if task exists in activity, add that to details and only show loader the history
    const events = getState().events.list;
    const task = (
      !_.isEmpty(events) && events.find(activity => (activity.id === id && activity.type === 'task'))
    );
    if (task && !refreshHistory) {
      dispatch(success(task));
      return dispatch(getTaskDetails(id));
    }

    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.TASK(id)))
      .then(
        (res) => {
          dispatch(success(res));
          return res;
        },
        (error) => {
          dispatch(failure(error));
          throw error;
        }
      );
  };
};

export const openTaskModal = (taskId = null, details = null) => {
  const setModalOpen = details => ({ type: taskConstants.SET_TASK_MODAL_OPEN, taskId, details });
  return dispatch => dispatch(setModalOpen(details));
};

export const closeTaskModal = () => {
  const setModalClose = () => ({ type: taskConstants.SET_TASK_MODAL_CLOSE });
  return dispatch => dispatch(setModalClose());
};
