import { reportActionConstants } from 'home/Reports/actions/reports.constants';
import _ from 'lodash';

const initialState = {
  allAssets: [],
  allSensors: [],
  plantHealthSummaryImgs: [],
};

export const reportHealthSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportActionConstants.INIT_REPORTS: {
      const resetState = {
        allAssets: [],
        plantHealthSummaryImgs: [],
        allSensors: [],
      };
      return {
        ...resetState
      };
    }
    case reportActionConstants.ADD_PLANT_SUMMARY_IMAGE_TO_REPORT: {
      return {
        ...state,
        plantHealthSummaryImgs: state.plantHealthSummaryImgs.concat([action.dataUri])
      };
    }
    case reportActionConstants.ADD_ALL_ASSETS: {
      return {
        ...state,
        allAssets: [
          ...action.assets
        ]
      };
    }
    case reportActionConstants.REMOVE_ALL_ASSETS: {
      return {
        ...state,
        allAssets: []
      };
    }
    case reportActionConstants.ADD_ALL_SENSORS: {
      return {
        ...state,
        allSensors: action.keepPrevSensors ? _.uniqBy([
          ...state.allSensors, ...action.sensors
        ], 'serial_number') : [...action.sensors]
      };
    }
    case reportActionConstants.REMOVE_ALL_SENSORS: {
      return {
        ...state,
        allSensors: []
      };
    }
    default:
      return state;
  }
};
