import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'common/components/organisms/Modal';
import Button_T from 'common/components/atoms/Button';
import InputField from 'common/components/atoms/InputField';
import FlexContainer from 'common/components/atoms/FlexContainer';

const ButtonContainer = styled(FlexContainer)`
  justify-content: flex-end;
  align-items: center;
`;

const Button = styled(Button_T)`
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-top: 0;
`;

const ChartNameModal = ({
  closeModal,
  onSave,
  chartName,
  setChartName,
  error
}) => {
  const nameChangeHandler = (event) => {
    const name = event.target.value;
    setChartName(name);
  };

  return (
    <Modal id="chart-name" title="Please enter a name for the chart" close={closeModal} width="40%">
      <InputField
        error={error}
        label="Chart Name"
        maxLength={60}
        onChange={nameChangeHandler}
        placeholder="Enter Chart Name"
        type="text"
        value={chartName}
      />
      <ButtonContainer>
        <Button id="footer-generate-continue" disabled={chartName.length === 0} onClick={onSave}>Save</Button>
        <Button text onClick={closeModal} alignVertical>
          Cancel
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

ChartNameModal.propTypes = {
  error: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  chartName: PropTypes.string.isRequired,
  setChartName: PropTypes.func.isRequired
};

ChartNameModal.defaultProps = {
  error: ''
};

export default ChartNameModal;
