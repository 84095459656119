import React from 'react';
import PropTypes from 'prop-types';

const AreaIcon = ({ width, height, fill }) => (
<svg width={width} height={height} viewBox="0 0 16 16" version="1.1">
    <g id="AH_v3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ah_v3-4-0-0" transform="translate(-36.000000, -189.000000)">
            <g id="more_dd" transform="translate(16.000000, 98.000000)">
                <g id="3" transform="translate(20.000000, 91.000000)">
                    <g id="floor-icon">
                        <g id="bound" fill="#FFFFFF">
                            <rect id="icon-bg" x="0" y="0" width={width} height={height} />
                        </g>
                        <g id="icon" stroke={fill} strokeWidth="1.5">
                            <rect id="Rectangle" strokeLinecap="round" strokeLinejoin="round" x="5" y="5" width="1" height="1" rx="0.444444444" />
                            <rect id="Rectangle" strokeLinecap="round" strokeLinejoin="round" x="10" y="5" width="1" height="1" rx="0.444444444" />
                            <rect id="Rectangle" strokeLinecap="round" strokeLinejoin="round" x="5" y="10" width="1" height="1" rx="0.444444444" />
                            <rect id="Rectangle" strokeLinecap="round" strokeLinejoin="round" x="10" y="10" width="1" height="1" rx="0.444444444" />
                            <rect id="Rectangle" x="0.75" y="0.75" width="14.5" height="14.5" rx="2" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

AreaIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

AreaIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: '#999B95'
};

export default AreaIcon;
