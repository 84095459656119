import { combineReducers } from 'redux';

import currentDefinition from './currentDefinition.reducer';
import list from './list.reducer';
import static_data from './static_data.reducer';

export const eventDefinitions = combineReducers({
  currentDefinition,
  list,
  static_data
});
