import { eventAnalyticsConstants } from './constants/eventAnalytics.constants';

export const eventAnalyticsReducer = (state = {}, action) => {
  switch (action.type) {
    case eventAnalyticsConstants.GET_EVENT_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventAnalyticsConstants.GET_EVENT_ANALYTICS_SUCCESS: {
      return {
        ...state,
        ...action.data,
        loading: false
      };
    }
    case eventAnalyticsConstants.GET_EVENT_ANALYTICS_FAILURE: {
      return {
        ...state,
        error: action.error.message,
        loading: false
      };
    }
    default:
      return state;
  }
};
