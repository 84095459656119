import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';

import Avatar_T from '../../../common/components/atoms/Avatar';
import { EllipsisV } from '../../../common/images/FaIcons';
import InputField from '../../../common/components/atoms/InputField';
import FlexContainer from '../../../common/components/atoms/FlexContainer';
import Button_T from '../../../common/components/atoms/Button';
import Text from '../../../common/typography/Text/Text';
import SecondaryText from '../../../common/typography/SecondaryText/SecondaryText';
import InlineModal from '../../../common/components/molecules/InlineModal';
import * as utils from '../../../common/utils';

const avatarRadius = 35;
const CommentContainer = styled(FlexContainer)`
  margin-top: 2em;
  max-width: 700px;
  justify-content: space-between;
`;
const Username = styled(Text)`
  font-size: 13px;
  padding: 0 1em;
`;
const MainSection = styled(FlexContainer).attrs({ direction: 'column' })`
  width: 100%;
  ${InputField} {
    flex-grow: 1;
    margin-right: 1em;
  }
`;

const Button = styled(Button_T)`
  ${props => props.cancel && `
  margin-top: 15px;
  margin-left: 5px;
  color: ${props.theme.colors.black};
  `}
`;

const CommentText = styled(Text)`
  font-size: 13px;
  padding-left: calc(${avatarRadius}px + 1em); // move text to align with width of username (width of avatar and padding after)
  display: block;
`;
const SubMenuContainer = styled.div`
  position: relative;
  margin-left: 1em;
  margin-bottom: auto;
  ${Button} {
    width: 100%;
    padding: 1em;
    color: ${props => props.theme.colors.black};
    font-size: 12px;
    &:hover {
      color: ${props => props.theme.colors.greyXD}
    }
  }
`;
const Avatar = styled(Avatar_T)`
  ${props => props.ownComment && `
    background-color:  ${props.theme.colors.darkGray};
    color: ${props.theme.colors.backgroundGray};
  `}
`;
const SvgContainer = styled(FlexContainer)`
  cursor: pointer;
  padding: 0.5em;
`;
const EditCommentForm = styled(FlexContainer).attrs({ justifyContent: 'space-between' })`
  padding: 1em;
`;

class Comment extends Component {
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toggleCommentMenuOpen = this.toggleCommentMenuOpen.bind(this);
    this.putComment = this.putComment.bind(this);
    this.editComment = this.editComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.state = {
      commentMenuOpen: false,
      editing: false,
      noteMessage: props.comment.comment
    };
  }

  componentDidMount() {
    const el = document.getElementById(this.props.comment.comment_id);
    if (el) {
      el.addEventListener('keypress', this.handleKeyDown, false);
    }
  }

  componentWillUnmount() {
    const el = document.getElementById(this.props.comment.comment_id);
    if (el) {
      el.removeEventListener('keypress', this.handleKeyDown, false);
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 13 && this.state.noteMessage !== '') {
      this.putComment(this.state.noteMessage);
      const el = document.getElementById(this.props.comment.comment_id);
      el.blur();
    }
  }

  putComment() {
    if (this.props.commentEditLoading) return;
    const noteMessage = this.state.noteMessage.trim();
    if (noteMessage) {
      this.props.putComment(this.state.noteMessage)
        .then(() => {
          this.setState({ noteMessage: '', editing: false });
        });
    }
  }

  toggleCommentMenuOpen() {
    this.setState(prevState => ({
      commentMenuOpen: !prevState.commentMenuOpen
    }));
  }

  editComment() {
    this.setState({
      commentMenuOpen: false,
      editing: true
    });
  }

  deleteComment() {
    this.setState({
      deleting: true
    });
    this.toggleCommentMenuOpen();
    this.props.deleteComment();
  }

  render() {
    const { comment } = this.props;
    if (this.state.deleting) return null;
    return (
      <CommentContainer id={comment.timestamp}>
        <MainSection>
          <FlexContainer alignItems="center">
            <Avatar
              initials={utils.getInitials(comment.user_name)}
              size={avatarRadius}
              ownComment={comment.user_name === `${this.props.user.first_name} ${this.props.user.last_name}`}
            />
            <Username>{comment.user_name}</Username>
            <SecondaryText>{ utils.formatDate(comment.timestamp)}</SecondaryText>
          </FlexContainer>
          {!this.state.editing ? (
            <CommentText>{comment.comment}</CommentText>
          ) : (
            <EditCommentForm>
              <InputField
                type="text"
                onChange={e => this.setState({ noteMessage: e.target.value })}
                value={this.state.noteMessage}
              />
              <Button onClick={this.putComment} disabled={this.props.commentEditLoading}>Save</Button>
              <Button cancel text onClick={() => this.setState({ editing: false })} disabled={this.props.commentEditLoading}>Cancel</Button>
            </EditCommentForm>
          )}
        </MainSection>
        {comment.user_name === `${this.props.user.first_name} ${this.props.user.last_name}` && (
          <SubMenuContainer>
            <SvgContainer onClick={this.toggleCommentMenuOpen}>
              <EllipsisV color={this.props.theme.colors.greyD} />
            </SvgContainer>
            {this.state.commentMenuOpen && (
              <InlineModal
                close={this.toggleCommentMenuOpen}
              >
                <Button text onClick={this.editComment}>Edit</Button>
                <Button text onClick={this.deleteComment}>Delete</Button>
              </InlineModal>
            )}
          </SubMenuContainer>
        )}
      </CommentContainer>
    );
  }
}

const mapStateToProps = state => ({
  commentEditLoading: state.events.commentEditLoading
});

export default connect(mapStateToProps, null)(withTheme(Comment));
