export const templatesActionConstants = {
  GET_ALL_TEMPLATES: 'GET_ALL_TEMPLATES',
  SELECT_TEMPLATE_FOR_CREATION: 'SELECT_TEMPLATE_FOR_CREATION',
  REMOVE_TEMPLATE_FOR_CREATION: 'REMOVE_TEMPLATE_FOR_CREATION',
  CLEAR_SELECTED_TEMPLATES: 'CLEAR_SELECTED_TEMPLATES',
  UPDATE_TEMPLATE_NUMS: 'UPDATE_TEMPLATE_NUMS',
  GET_TEMPLATE_INFO_REQUEST: 'GET_TEMPLATE_INFO_REQUEST',
  GET_TEMPLATE_INFO_SUCCESS: 'GET_TEMPLATE_INFO_SUCCESS',
  GET_TEMPLATE_INFO_FAILURE: 'GET_TEMPLATE_INFO_FAILURE',
  UPDATE_TEMPLATE_INFO: 'UPDATE_TEMPLATE_INFO',
  SET_SAVE_AS_GLOBAL: 'SET_SAVE_AS_GLOBAL',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
};
