import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import InlineModal from './InlineModal';
import OutsideAlerter from '../../OutsideAlerter';

const FilterContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`;

const FilterChip = styled.div`
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 4px 12px;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid ${props => props.theme.colors.lightGray || '#e0e0e0'};
  
  &:hover {
    border-color: ${props => props.theme.colors.gray || '#c0c0c0'};
  }
  
  ${props => props.hasSelection && `
    background-color: ${props.theme.primaryColor};
    border-color: ${props.theme.primaryColor};
    color: white;
  `}
  
  ${props => props.disabled && `
    opacity: 0.6;
    cursor: not-allowed;
    &:hover {
      border-color: ${props.theme.colors.lightGray || '#e0e0e0'};
    }
  `}
`;

const FilterLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkGray || 'gray'};
  ${props => props.hasSelection && `
    color: white;
  `}
`;

const CountBadge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: ${props => props.hasSelection ? 'white' : props.theme.primaryColor};
  color: ${props => props.hasSelection ? props.theme.primaryColor : 'white'};
  font-size: 10px;
  font-weight: bold;
  margin-left: 6px;
  padding: 0 4px;
`;

const Filter = ({
  event,
  title,
  value,
  open,
  toggleOpen,
  alignVertical,
  alignHorizontal,
  onChangeSearch,
  searchValue,
  children,
  viewAllItems,
  selectedItems,
  selectGivenItems,
  itemName,
  width,
  minWidth,
  Icon,
  zIndex,
  modalClassName,
  displayItemClassName,
  disabled,
  hoverTitle,
  customDisplayValue,
  autoFocusSearch,
  ...rest
}) => {
  // Determine if there's a selection based on both value and selectedItems
  const hasValueSelection = !_.isEmpty(value);
  const hasItemsSelection = Array.isArray(selectedItems) && selectedItems.length > 0;
  const hasSelection = hasValueSelection || hasItemsSelection;

  const getValueBasedCount = () => {
    if (!hasValueSelection) return 0;
    return value.includes(',') ? value.split(',').length : 1;
  };

  const selectionCount = hasItemsSelection
    ? selectedItems.length
    : getValueBasedCount();

  return (
    <FilterContainer {...rest}>
      <FilterChip
        className={displayItemClassName}
        onClick={toggleOpen}
        disabled={disabled}
        hasSelection={hasSelection}
      >
        {!customDisplayValue && (
          <>
            <FilterLabel hasSelection={hasSelection}>
              {title}
            </FilterLabel>
            {hasSelection && (
              <CountBadge
                hasSelection={hasSelection}
                title={hoverTitle || value}
              >
                {selectionCount}
              </CountBadge>
            )}
          </>
        )}
        {customDisplayValue && (
          <>{customDisplayValue()}</>
        )}
      </FilterChip>
      {open && (
        <OutsideAlerter
          open={open}
          handleClick={toggleOpen}
          event={event}
        >
          <InlineModal
            alignVertical={alignVertical}
            alignHorizontal={alignHorizontal}
            close={toggleOpen}
            searchValue={searchValue}
            onChangeSearch={onChangeSearch}
            viewAllItems={viewAllItems}
            selectedItems={selectedItems}
            selectGivenItems={selectGivenItems}
            itemName={itemName}
            width={width}
            minWidth={minWidth}
            zIndex={zIndex}
            noScroll={rest.noScroll}
            className={modalClassName}
            noOverlay={rest.noOverlay}
            minWidthInline={rest.minWidthInline}
            marginTop={rest.marginTop}
            autoFocusSearch={autoFocusSearch}
          >
            {children}
          </InlineModal>
        </OutsideAlerter>
      )}
    </FilterContainer>
  );
};

Filter.propTypes = {
  event: PropTypes.oneOf(['mousedown', 'click']),
  title: PropTypes.string,
  value: PropTypes.string,
  hoverTitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  alignVertical: PropTypes.oneOf(['top', 'bottom']),
  alignHorizontal: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  Icon: PropTypes.node,
  modalClassName: PropTypes.string,
  displayItemClassName: PropTypes.string,
  autoFocusSearch: PropTypes.bool
};

Filter.defaultProps = {
  event: 'mousedown',
  title: undefined,
  value: undefined,
  alignVertical: 'bottom',
  modalClassName: '',
  alignHorizontal: 'left',
  children: undefined,
  Icon: undefined,
  hoverTitle: '',
  displayItemClassName: 'filter-item',
  autoFocusSearch: false
};

export default styled(Filter)`
  margin-right: 0.5em;
`;
