import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/organisms/Modal';
import NoteDetails from './NoteDetails';


function NoteDetailsModal(props) {
  return (
    <Modal
      title={['Notes', props.isEditMode ? 'Edit Note' : 'Create Note']}
      padding="0"
      outerPadding="0"
      headerPadding="1rem"
      headerRadius="5px"
      width="60rem"
      close={props.closeModal}
      showHeaderBorder
    >
      <NoteDetails {...props} />
    </Modal>
  );
}

NoteDetailsModal.propTypes = {
  isEditMode: PropTypes.bool,
  closeModal: PropTypes.func,
};

NoteDetailsModal.defaultProps = {
  isEditMode: false,
  closeModal: () => {},
};

export default NoteDetailsModal;
