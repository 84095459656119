import React, { Component } from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AccessRestricted from 'common/components/AccessRestricted/AccessRestricted';
import FlexContainer from 'common/components/atoms/FlexContainer';
import RBAC from 'common/rbac/RBAC';
import AccountMenuItems from 'common/components/organisms/AccountMenuItems';
import MoreOptions from 'common/components/organisms/MoreOptions';
import MsgContainer from 'common/components/atoms/MsgContainer';
import RadioButton from 'common/components/molecules/Radiobutton';
import SearchFilters from 'common/components/atoms/SearchFilters';
import Filter from 'common/components/molecules/Filter';
import { isFilterApplied, round } from 'common/utils';
import Button from 'common/components/atoms/Button';
import H3_T from 'common/typography/H3/H3';
import InputField from 'common/components/atoms/InputField';
import { localTimeString } from 'common/helpers';
import { mapDayToString } from 'common/constants';
import OutsideAlerter from 'common/OutsideAlerter';
import Calendar from 'common/components/molecules/ReactCalendar';
import H2 from 'common/typography/H2/H2';
import CrossSvg from 'common/images/CrossSvg';
import FilterItem_T from 'common/components/atoms/FilterItem';
import DateSelector from 'common/components/Chart/atoms/DateSelector';
import CalendarContainer from 'common/components/Chart/atoms/CalendarContainer';
import { operations } from 'common/rbac/constants';
import { widgetActions } from 'home/Widget/actions/widget.actions';
import Widget from 'home/Widget';
import DevicesHealthContainer from '../../components/DevicesHealthContainer';
import StatsContainer from '../../components/StatsContainer';
import AccountAnalyticsWidget from './AccountAnalytics.widget';
import * as sensorActions from '../../../Sensors/actions/sensors.actions';
import * as accountAnalyticsActions from '../accountAnalytics.actions';

const H3 = styled(H3_T)`
  font-weight: 600;
  padding: 10px 0;
`;

const FilterItem = styled(FilterItem_T)`
  width: auto;
  span {
    margin-left: 0.5em;
  }
`;

const ChartsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  grid-template-columns: repeat(3, minmax(370px, 1fr));
`;

const SvgContainer = styled(FlexContainer)`
  cursor: pointer;
  padding: 0.5em;
  padding-left: 2em;
`;

const formatDate = (locale, date) => {
  const day = date.getDay();
  return mapDayToString[day][0];
};

class AccountAnalytics extends Component {
  constructor() {
    super();
    this.initialSearchParams = {
      date: ['7'],
      date_from: new Date(new Date().setDate(new Date().getDate() - 7)),
      date_to: new Date()
    };
    this.state = {
      filtersOpen: {
        accounts: false,
        date: false
      },
      searchParams: {
        ...this.initialSearchParams,
      },
      allAccounts: [],
      account: 'summary',
      dateExpanded: false,
      calenderOpen: null,
      customDayFrom: false,
      customDayTo: false,
    };
    this.dateOptions = [
      {
        text: 'Today',
        value: 'today',
        name: 'Today'
      },
      {
        text: 'Yesterday',
        value: 'yesterday',
        name: 'Yesterday'
      },
      {
        text: 'Last 7 Days',
        value: '7',
        name: 'Last 7 Days'
      },
      {
        text: 'Last 30 Days',
        value: '30',
        name: 'Last 30 Days'
      },
      {
        text: 'This Month',
        value: 'thisMonth',
        name: 'This Month'
      },
      {
        text: 'Last Month',
        value: 'lastMonth',
        name: 'Last Month'
      },
      {
        text: 'Custom',
        value: 'custom',
        name: 'Custom'
      }
    ];
  }

  fetchAccountAnalytics(account, filters = null) {
    const {
      getAccountAnalyticsSummary,
      getAccountAnalytics
    } = this.props.accountAnalyticsActions;

    if (account === 'summary') getAccountAnalyticsSummary(filters);
    else getAccountAnalytics(filters, account);
  }

  componentDidMount() {
    const title = this.props.title;
    document.title = `${title} | Account Analytics`;

    this.mounted = true;
    const { getResourcesBasedOnType } = this.props.sensorActions;

    getResourcesBasedOnType('account').then((res) => {
      this.setState({
        allAccounts: [{ text: 'Account Analytics', value: 'summary' }, ...res],
      });
    });
    this.fetchAccountAnalytics(this.state.account);
    this.props.widgetActions.getWidgetsMetadata();
  }

  toggleFilterOpen = (filter) => {
    this.setState(prevState => ({
      filtersOpen: {
        ...prevState.filtersOpen,
        [filter]: !prevState.filtersOpen[filter]
      }
    }));
  };

  changeAccount = (account) => {
    this.setState({ account }, this.search);
  }

  openCustomCalender = (str) => {
    this.setState({
      calenderOpen: str
    });
  };

  renderFilterOptions = (options, name) => {
    if (_.isEmpty(options)) {
      return (
        <MsgContainer>
          <span>No {name} are available</span>
        </MsgContainer>
      );
    }

    return options.map((option, index) => {
      const selected = _.includes(this.state.searchParams[name], option.value);
      return (
          <FilterItem
            key={index.toString()}
            className="dropdown-item"
            selected={selected}
            onClick={() => {
              let types = [];
              if (!selected) {
                types = [option.value];
              }
              if (option.value === 'custom' || this.state.dateExpanded) {
                this.toggleDateExpanded('dateExpanded');
              }
              this.changeDateFilter(types);
            }}
          >
            <RadioButton value={selected} />
            <span>{option.text}</span>
          </FilterItem>
      );
    });
  };

  toggleDateExpanded = () => {
    this.setState(prevState => ({
      dateExpanded: !prevState.dateExpanded
    }));
  };

  changeDateFilter = (value) => {
    let start_date;
    let end_date;
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth();

    if (value.includes('custom')) {
      start_date = new Date(new Date().setDate(current.getDate() - 7));
      end_date = current;
    } else if (value.includes('today')) {
      start_date = new Date(new Date().setHours(0, 0, 0, 0));
      end_date = current;
    } else if (value.includes('yesterday')) {
      start_date = new Date(
        new Date(current.setHours(0, 0, 0, 0)).setDate(
          current.getDate() - 1
        )
      );
      end_date = new Date(current.setHours(0, 0, 0, 0));
    } else if (value.includes('7')) {
      start_date = new Date(new Date().setDate(current.getDate() - 7));
      end_date = current;
    } else if (value.includes('30')) {
      start_date = new Date(new Date().setDate(current.getDate() - 30));
      end_date = current;
    } else if (value.includes('thisMonth')) {
      start_date = new Date(year, month, 1);
      end_date = new Date(year, month + 1, 0);
    } else if (value.includes('lastMonth')) {
      start_date = new Date(year, month - 1, 1);
      end_date = new Date(year, month, 0);
    }

    const searchParams = {
      ...this.state.searchParams,
      date: value,
      date_from: start_date,
      date_to: end_date
    };
    this.setState({
      searchParams,
      customDayFrom: false,
      customDayTo: false
    });
    return this.search(searchParams);
  };

  onClickDay = (value, label) => {
    let searchParams = {};
    if (label === 'from') {
      searchParams = {
        ...this.state.searchParams,
        date_from: value
      };
      this.setState({
        searchParams,
        customDayFrom: value,
        calenderOpen: null
      });
    } else if (label === 'to') {
      searchParams = {
        ...this.state.searchParams,
        date_to: value
      };
      this.setState({
        searchParams,
        customDayTo: value,
        calenderOpen: null
      });
    }
    this.search(searchParams);
  };

  closeCalender = () => {
    this.setState({ calenderOpen: null });
  };

  clearDate = () => {
    this.changeDateFilter(['7']);
  };

  clearAllFilters = () => {
    const searchParams = {
      ...this.initialSearchParams
    };
    this.setState({ searchParams }, this.search);
    if (this.state.dateExpanded) this.toggleDateExpanded();
  }

  search = (params = null) => {
    const { searchParams: stateParams } = this.state;
    const searchParams = params || stateParams;
    const start_date = searchParams.date_from;
    const end_date = searchParams.date_to;
    const filters = {
      start_date,
      end_date
    };
    this.fetchAccountAnalytics(this.state.account, filters);
  };

  getFilters = () => {
    const account = this.state.account;
    const filters = [];
    if (account === 'summary') filters.push({ name: 'all', op: 'eq', value: true });
    else filters.push({ name: 'account_id', op: 'eq', value: account });
    return filters;
  }

  render() {
    const dateTextValue = this.state.searchParams.date.reduce(
      (acc, id, idx) => {
        const name = this.dateOptions.filter(s => s.value === id);
        if (!_.isEmpty(name)) {
          if (idx === 0) return `${name[0].name}`;
          if (idx === 1) return `${acc}, ${name[0].name}...`;
        }
        return acc;
      },
      ''
    );
    return (
      <div>
        <MoreOptions>
          <AccountMenuItems />
        </MoreOptions>
          <SearchFilters>
            <InputField
              maxLength={50}
              onChange={(e, d) => this.changeAccount(d.value)}
              placeholder="Click to select account"
              type="select"
              search
              value={this.state.account}
              options={this.state.allAccounts}
            />
            {this.state.account !== 'summary' && (
              <>
            <Filter
              title="Measurement Interval:"
              value={dateTextValue}
              open={this.state.filtersOpen.date}
              noScroll
              toggleOpen={() => this.toggleFilterOpen('date')}
            >
              <FlexContainer
                direction="row"
              >
                <FlexContainer
                  direction="column"
                >
                  {this.renderFilterOptions(this.dateOptions, 'date')}
                </FlexContainer>
                {this.state.dateExpanded && (
                <DateSelector>
                  {this.state.calenderOpen === 'from' && (
                    <OutsideAlerter open={this.state.calenderOpen === 'from'} handleClick={this.closeCalender}>
                      <CalendarContainer>
                        <Calendar
                          formatShortWeekday={(locale, date) => formatDate(locale, date)}
                          maxDetail="month"
                          maxDate={this.state.customDayTo || new Date()}
                          showNeighboringMonth={false}
                          onClickDay={value => this.onClickDay(value, 'from')}
                          value={this.state.customDayFrom}
                        />
                      </CalendarContainer>
                    </OutsideAlerter>
                  )}
                  {this.state.calenderOpen === 'to' && (
                    <OutsideAlerter open={this.state.calenderOpen === 'to'} handleClick={this.closeCalender}>
                      <CalendarContainer to="true">
                        <Calendar
                          formatShortWeekday={(locale, date) => formatDate(locale, date)}
                          maxDetail="month"
                          maxDate={new Date()}
                          minDate={this.state.customDayFrom}
                          value={this.state.customDayTo}
                          showNeighboringMonth={false}
                          onClickDay={value => this.onClickDay(value, 'to')}
                        />
                      </CalendarContainer>
                    </OutsideAlerter>
                  )}
                    <FlexContainer direction="row" justifyContent="space-between">
                      <H2>Custom</H2>
                      <SvgContainer
                        onClick={() => this.toggleFilterOpen('date')}
                      >
                        <CrossSvg width={30} height={30} fill="#999B95" />
                      </SvgContainer>
                    </FlexContainer>
                    <InputField label="From" placeholder="Select" readOnly value={this.state.customDayFrom ? this.state.customDayFrom.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalender('from')} />
                    <InputField label="To" placeholder="Select " readOnly value={this.state.customDayTo ? this.state.customDayTo.toString().slice(0, 15) : ''} onFocus={() => this.openCustomCalender('to')} />
                    <Button
                      text
                      onClick={this.clearDate}
                      disabled={!this.state.customDayFrom && !this.state.customDayTo}
                    >
                      CLEAR
                    </Button>
                </DateSelector>)
                }
              </FlexContainer>
            </Filter>
            {isFilterApplied(this.state.searchParams) && (
              <Button text onClick={this.clearAllFilters} fontSize="11px" margin="0 20px">
                Clear Filters
              </Button>
            )}
            </>
            )}

          </SearchFilters>
        <RBAC
          operation={operations.Read}
          yes={(
            <DevicesHealthContainer>
                <ChartsContainer>
                  {this.state.account !== 'summary' && <AccountAnalyticsWidget analytics_type="measurements" />}
                  <AccountAnalyticsWidget analytics_type="channels" />
                  <AccountAnalyticsWidget analytics_type="tags" />
                  {!this.props.widgetsMetadata.loading && this.state.account !== 'summary' && !_.isEmpty(this.props.widgetsMetadata.object) && (
                    <Widget filters={this.getFilters()} showBorder metadata={this.props.widgetsMetadata.object.alarm_review_and_action_report} height="220px" />
                  )}
                  {!this.props.widgetsMetadata.loading && this.state.account !== 'summary' && !_.isEmpty(this.props.widgetsMetadata.object) && (
                    <Widget filters={this.getFilters()} showBorder metadata={this.props.widgetsMetadata.object.not_analysed_open_alarms} height="220px" />
                  )}
                  <StatsContainer>
                      <H3>Number of Users: {this.props.accountAnalytics.num_users ? this.props.accountAnalytics.num_users : '0'}</H3>
                      <H3>Number of Machines: {this.props.accountAnalytics.num_machines ? this.props.accountAnalytics.num_machines : '0'}</H3>
                      <H3>Number of Motes: {this.props.accountAnalytics.num_motes ? this.props.accountAnalytics.num_motes : '0'}</H3>
                      <H3>Number of Tx: {this.props.accountAnalytics.num_tx ? this.props.accountAnalytics.num_tx : '0'}</H3>
                      <H3>Number of Components: {this.props.accountAnalytics.num_components ? this.props.accountAnalytics.num_components : '0'}</H3>
                      <H3>Number of Channels: {this.props.accountAnalytics.num_channels ? this.props.accountAnalytics.num_channels : '0'}</H3>
                      <H3>Number of Tags: {this.props.accountAnalytics.num_tags ? this.props.accountAnalytics.num_tags : '0'}</H3>
                    {this.state.account !== 'summary' && (
                      <>
                      <H3>Start Date: {this.props.accountAnalytics.start_date ? localTimeString(this.props.accountAnalytics.start_date) : '0'}</H3>
                      <H3>Number of Measurements: {this.props.accountAnalytics.num_measurements ? this.props.accountAnalytics.num_measurements : '0'}</H3>
                      <H3>Size of Timeseries Data(in GB): {this.props.accountAnalytics.size_of_timeseries_data_in_GB ? round(this.props.accountAnalytics.size_of_timeseries_data_in_GB) : '0'} </H3>
                      </>
                    )}
                  </StatsContainer>
                </ChartsContainer>
            </DevicesHealthContainer>
              )}
          no={<AccessRestricted />}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  adminDashboard: state.adminDashboard,
  accountAnalytics: state.accountAnalytics,
  accountAnalyticsSummary: state.accountAnalyticsSummary,
  title: state.companyReducer.partner.title,
  widgetsMetadata: state.widgets.metadata
});

const mapDispatchToProps = dispatch => ({
  sensorActions: bindActionCreators(sensorActions, dispatch),
  accountAnalyticsActions: bindActionCreators(accountAnalyticsActions, dispatch),
  widgetActions: bindActionCreators(widgetActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountAnalytics);
