
import { combineReducers } from 'redux';

import FailureModeConstants from '../constants/FailureMode.constants';

const failureModeInitialState = {
  list: [],
  details: {},
  loading: false
};

const failureModes = (state = failureModeInitialState, action) => {
  switch (action.type) {
    case FailureModeConstants.GET_FAILURE_MODE_DETAILS_REQUEST:
      return {
        ...state,
        details: action.data,
        loading: true
      };
    case FailureModeConstants.GET_FAILURE_MODE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.data,
        loading: false
      };
    case FailureModeConstants.UPDATE_FAILURE_MODE_DETAILS_SUCCESS:
      return {
        ...state,
        list: state.list.map(f => f.id === action.id ? { ...f, ...action.data } : f),
        details: {
          ...state.details,
          ...action.data,
        }
      };
    case FailureModeConstants.DELETE_FAILURE_MODE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(failureMode => failureMode.id !== action.id)
      };
    case FailureModeConstants.SAVE_FAILURE_MODE_DETAILS_SUCCESS:
      return {
        ...state,
        list: [{ ...action.data }, ...state.list],
        details: action.data
      };
    case FailureModeConstants.SELECT_FAILURE_MODE:
      return {
        ...state,
        selectedFailureMode: action.id === state.selectedFailureMode ? null : action.id
      };
    case FailureModeConstants.GET_FAILURE_MODE_LIST_REQUEST:
      return {
        ...state
      };

    case FailureModeConstants.GET_FAILURE_MODE_LIST_FAILURE:
      return {
        ...state
      };

    case FailureModeConstants.GET_FAILURE_MODE_LIST_SUCCESS:
      return {
        ...state,
        list: action.data,
        loading: false
      };
    default:
      return state;
  }
};

export const assetFailureMode = combineReducers({
  failureModes
});
