import React from 'react';
import PropTypes from 'prop-types';

const OverallAlarmWarningNew = ({ width, height, fill }) => (
<svg width={width} height={height} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g id="Web---Single" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="1.0.0-Single-Overall_alarm" transform="translate(-453.000000, -589.000000)">
      <g transform="translate(413.000000, 199.000000)" id="readings">
        <g transform="translate(40.000000, 381.000000)">
          <g id={1}>
            <g id="top">
              <g id="overall_alarm/warning-icon" transform="translate(0.000000, 9.000000)">
                <rect id="icon-bg" fill={fill} x={0} y={0} width={20} height={20} />
                <g id="icon" transform="translate(2.000000, 5.000000)" fillRule="nonzero" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.625">
                  <g id="graph">
                    <polyline id="Path-5" stroke="#3C3D3A" points="0 10.1475897 3.48925339 8.33317952 6.35797511 10.1475897 9.02775028 8.33317952 15.3466841 0.846153846" />
                    <path d="M0,5.3125 L15.9465144,5.3125" id="Path-6" stroke="#F0C000" fill="#FF4F39" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
);

OverallAlarmWarningNew.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

OverallAlarmWarningNew.defaultProps = {
  width: 20,
  height: 20,
  fill: '#FFFFFF'
};


export default OverallAlarmWarningNew;
