import { dashboardConstants, accountDashboardConstants } from '../constants/dashboard.constants';

const initialState = {
  dashboards: {
    loading: false,
    data: []
  },
  activeDashboard: null,
  accountDashboard: null,
  accountDashboardLoading: false,
  accountDashboardError: null,
  existingAccountDashboardTypes: [],
  dashboardSettingsLoading: false,
  dashboardSettingsError: null,
  defaultDashboard: null
};

export function dashboards(state = initialState, action) {
  switch (action.type) {
    case dashboardConstants.GET_DASHBOARDS_REQUEST:
      return {
        ...state,
        dashboards: {
          data: [],
          loading: true
        }
      };
    case dashboardConstants.GET_DASHBOARDS_SUCCESS: {
      const accountDashboards = action.dashboards.filter(d => d.account_dashboard);
      const existingTypes = accountDashboards.map(d => d.dashboard_config.type);
      const dashboardConfig = accountDashboards.map(d => d.dashboard_config);
      const defaultDashboard = action.dashboards.find(d => d.is_default) || null;

      return {
        ...state,
        dashboards: {
          data: action.dashboards,
          loading: false,
          dashboard_config: dashboardConfig
        },
        existingAccountDashboardTypes: existingTypes,
        defaultDashboard
      };
    }
    case dashboardConstants.GET_DASHBOARDS_FAILURE:
      return {
        ...state,
        dashboards: {
          error: action.error,
          loading: false
        }
      };
    case dashboardConstants.CREATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboards: {
          loading: false,
          data: [
            ...state.dashboards.data,
            action.dashboard
          ]
        }
      };
    case dashboardConstants.SET_ACTIVE_DASHBOARD:
      return {
        ...state,
        activeDashboard: action.dashboard,
        accountDashboard: (!!action.dashboard.account_dashboard)
      };
    case dashboardConstants.DELETE_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboards: {
          loading: false,
          data: state.dashboards.data.filter(
            dash => dash.id !== action.dashboardId
          )
        }
      };
    case dashboardConstants.UPDATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboards: {
          loading: false,
          data: state.dashboards.data.map((dash) => {
            if (dash.id !== action.dashboard.id) return dash;
            return {
              ...dash,
              ...action.dashboard
            };
          })
        }
      };
    case dashboardConstants.UPDATE_DASHBOARD_SETTINGS_REQUEST:
      return {
        ...state,
        dashboardSettingsLoading: true,
        dashboardSettingsError: null
      };
    case dashboardConstants.UPDATE_DASHBOARD_SETTINGS_SUCCESS: {
      const updatedDashboards = state.dashboards.data.map((dash) => {
        if (dash.id !== action.dashboard.id) {
          if (action.dashboard.is_default) {
            return { ...dash, is_default: false };
          }
          return dash;
        }
        return {
          ...dash,
          ...action.dashboard,
          is_default: action.dashboard.is_default
        };
      });

      return {
        ...state,
        dashboardSettingsLoading: false,
        dashboards: {
          ...state.dashboards,
          data: updatedDashboards
        },
        defaultDashboard: action.dashboard.is_default ? action.dashboard : state.defaultDashboard
      };
    }
    case dashboardConstants.UPDATE_DASHBOARD_SETTINGS_FAILURE:
      return {
        ...state,
        dashboardSettingsLoading: false,
        dashboardSettingsError: action.error
      };
    case accountDashboardConstants.CREATE_ACCOUNT_DASHBOARD_REQUEST:
      return {
        ...state,
        accountDashboardLoading: true,
        accountDashboardError: null
      };
    case accountDashboardConstants.CREATE_ACCOUNT_DASHBOARD_SUCCESS:
      return {
        ...state,
        accountDashboardLoading: false,
        dashboards: {
          ...state.dashboards,
          data: [...state.dashboards.data, action.dashboard]
        }
      };
    case accountDashboardConstants.CREATE_ACCOUNT_DASHBOARD_FAILURE:
      return {
        ...state,
        accountDashboardLoading: false,
        accountDashboardError: action.error
      };
    default:
      return state;
  }
}
