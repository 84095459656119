export default {

  GET_SENSOR_MODEL_LIST_REQUEST: 'GET_SENSOR_MODEL_LIST_REQUEST',
  GET_SENSOR_MODEL_LIST_SUCCESS: 'GET_SENSOR_MODEL_LIST_SUCCESS',
  GET_SENSOR_MODEL_LIST_FAILURE: 'GET_SENSOR_MODEL_LIST_FAILURE',

  GET_SENSOR_MODEL_REQUEST: 'GET_SENSOR_MODEL_REQUEST',
  GET_SENSOR_MODEL_SUCCESS: 'GET_SENSOR_MODEL_SUCCESS',
  GET_SENSOR_MODEL_FAILURE: 'GET_SENSOR_MODEL_FAILURE',

  CREATE_SENSOR_MODEL_REQUEST: 'CREATE_SENSOR_MODEL_REQUEST',
  CREATE_SENSOR_MODEL_FAILURE: 'CREATE_SENSOR_MODEL_FAILURE',

  GET_SENSOR_MODEL_METADATA_REQUEST: 'GET_SENSOR_MODEL_METADATA_REQUEST',
  GET_SENSOR_MODEL_METADATA_SUCCESS: 'GET_SENSOR_MODEL_METADATA_SUCCESS',
  GET_SENSOR_MODEL_METADATA_FAILURE: 'GET_SENSOR_MODEL_METADATA_FAILURE',

  UPDATE_SENSOR_MODEL_REQUEST: 'UPDATE_SENSOR_MODEL_REQUEST',
  UPDATE_SENSOR_MODEL_FAILURE: 'UPDATE_SENSOR_MODEL_FAILURE',

  SET_SENSOR_SEARCH_PARAMS: 'SET_SENSOR_SEARCH_PARAMS',
  CLEAR_SENSOR_SEARCH_PARAMS: 'CLEAR_SENSOR_SEARCH_PARAMS',

  DELETE_SENSOR_MODEL_REQUEST: 'DELETE_SENSOR_MODEL_REQUEST',
  DELETE_SENSOR_MODEL_SUCCESS: 'DELETE_SENSOR_MODEL_SUCCESS',
  DELETE_SENSOR_MODEL_FAILURE: 'DELETE_SENSOR_MODEL_FAILURE',

  GET_SENSOR_INSTANCES_REQUEST: 'GET_SENSOR_INSTANCES_REQUEST',
  GET_SENSOR_INSTANCES_SUCCESS: 'GET_SENSOR_INSTANCES_SUCCESS',
  GET_SENSOR_INSTANCES_FAILURE: 'GET_SENSOR_INSTANCES_FAILURE',

  CLEAR_SELECTED_MODEL: 'CLEAR_SELECTED_MODEL',
  CLEAR_MODELS_LIST: 'CLEAR_MODELS_LIST',
  CLEAR_SENSOR_INSTANCES: 'CLEAR_SENSOR_INSTANCES',

  INCREASE_PAGE: 'INCREASE_PAGE',
  SET_MODELS_SORTER: 'SET_MODELS_SORTER',

  SELECT_MODELS_FOR_CREATION: 'SELECT_MODELS_FOR_CREATION',
  REMOVE_MODEL_FOR_CREATION: 'REMOVE_MODEL_FOR_CREATION',
  CLEAR_SELECTED_MODELS: 'CLEAR_SELECTED_MODELS',
  UPDATE_MODEL_COPIES: 'UPDATE_MODEL_COPIES',
};
