import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import Button from 'common/components/atoms/Button';
import InputField_T from 'common/components/atoms/InputField';
import Label from 'common/typography/Label/Label';
import { ExclamationTriangle } from 'common/images/FaIcons';

import { BASE_GRAPH_OPTIONS } from '../../../constants/envelope.constants';

const InputField = styled(InputField_T)`
  ${props => props.margin && `margin: ${props.margin};`}
  ${ifProp('disabled', css`
    svg, label {
      &:hover {
        cursor: not-allowed;
      }
    }
  `
  )}
`;


const BaselineOption = ({
  baselineSet,
  openBaselineModal,
  settings,
  updateSettings
}) => (
  <>
    <InputField
      disabled={!baselineSet}
      margin="0"
      onClick={() => {
        if (settings.type === BASE_GRAPH_OPTIONS.BASELINE || !baselineSet) return;
        updateSettings('base_graph', {
          type: BASE_GRAPH_OPTIONS.BASELINE,
          value: ''
        });
      }}
      title="Collect data from baseline"
      type="radio"
      value={settings.type === BASE_GRAPH_OPTIONS.BASELINE}
    />
    {!baselineSet && (
      <>
        <ExclamationTriangle size="sm" style={{ color: '#000', marginLeft: '15px', marginTop: '2px', marginRight: '5px' }} />
        <Label margin="0 10px 0 0">Baseline not available! Please set a baseline.</Label>
        <Button text onClick={openBaselineModal}>Set Baseline</Button>
      </>
    )}
  </>
);

BaselineOption.propTypes = {
  baselineSet: PropTypes.bool.isRequired,
  openBaselineModal: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  updateSettings: PropTypes.func.isRequired
};

export default BaselineOption;
