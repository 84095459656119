import React from 'react';
import PropTypes from 'prop-types';


import styled from 'styled-components';
import { Info } from 'common/images/FaIcons';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Button from 'common/components/atoms/Button';
import H2 from 'common/typography/H2/H2';
import H5 from 'common/typography/H5/H5';
import colors from 'common/styles/colors';
import Loading from 'common/components/atoms/Loading';
import Modal from './Modal';

const AlertPrompt = ({
  message,
  secondaryMessage,
  infoMessage,
  onProceed,
  onClose,
  onCancel,
  component,
  onProceedDisabled,
  proceedColor,
  proceedText,
  cancelText,
  width,
  loading
}) => (
  <Modal
    className="alert-prompt"
    width={width}
    padding="0"
    close={onClose || onCancel}
  >
    <FlexContainer direction="column" alignItems="center">
    {infoMessage && (
        <H5>
          <Info size="sm" />
        <i>{infoMessage}</i>
        </H5>
    )}
      <H2>{message}</H2>
      <H5 style={{ margin: '5px' }}>{secondaryMessage}</H5>
      {component}
      <FlexContainer>
        <Button
          className="alert-prompt-confirm"
          style={{ margin: '10px' }}
          bordercolor={proceedColor}
          secondary={proceedColor}
          onClick={onProceed}
          disabled={onProceedDisabled}
        >
          {proceedText}
        </Button>
        <Button
          className="alert-prompt-cancel"
          style={{ margin: '10px' }}
          bordercolor={colors.black}
          secondary={colors.black}
          onClick={onCancel}
        >
          {cancelText}
        </Button>
      </FlexContainer>
    </FlexContainer>
    {loading && (<Loading />)}
  </Modal>
);

AlertPrompt.propTypes = {
  message: PropTypes.string,
  secondaryMessage: PropTypes.string,
  infoMessage: PropTypes.string,
  onProceed: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  component: PropTypes.node,
  onProceedDisabled: PropTypes.bool,
  proceedColor: PropTypes.string,
  proceedText: PropTypes.string,
  cancelText: PropTypes.string,
  width: PropTypes.string,
  loading: PropTypes.bool
};

AlertPrompt.defaultProps = {
  message: '',
  secondaryMessage: '',
  infoMessage: '',
  component: null,
  onProceedDisabled: false,
  onClose: null,
  proceedColor: colors.red,
  proceedText: 'PROCEED',
  cancelText: 'CLOSE',
  width: '50%',
  loading: false
};

export default AlertPrompt;
