import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as _ from 'lodash';

import List from 'common/components/molecules/List';
import PlusSvg from 'common/images/PlusSvg';
import { ScrollContainer } from 'common/components/atoms';
import { humanize } from 'common/helpers/humanize';
import { checkPermissions } from 'common/rbac/util';
import {
  mapComponentToResource,
  operations
} from 'common/rbac/constants';
import { getShaftName } from 'home/Machines/utils';

import Modal from 'common/components/organisms/Modal';
import Button_T from 'common/components/atoms/Button';
import ForcingFreqListItem from '../molecules/ForcingFreqListItem';

const Button = styled(Button_T)`
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  font-size: ${props => props.fontSize ? props.fontSize : '20px'};
  padding: 12px 16px;
  width: ${props => props.width ? props.width : 'auto'};
  border: none !important;
`;

const ForcingFreqListContainer = styled.div`
  margin: ${props => props.margin ? props.margin : '15px 0px 40px 0px'};
  border: 1px solid ${props => props.theme.colors.greyL};
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FooterContainer = styled.div`
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: white;
  z-index: 10;
  border-top: 2px solid #EFF0EE;
  height: 57px;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
`;

const ModalHeading = styled.div`
  font-size: 20px;
  line-height: 27px;
`;

const ModalSubHeading = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
`;

const ForcingFreqModal = (props) => {
  const columnSizes = [15, 26, 13, 17, 25, 4];

  const frequencyUnits = props.currentUser.frequency_units;

  const headers = [
    {
      name: 'marker',
      label: 'Marker',
    }, {
      name: 'name',
      label: 'Name',
    }, {
      name: 'multiplier',
      label: 'Multiplier'
    }, {
      name: 'frequency',
      label: `Frequency (${frequencyUnits})`,
      noSorter: true
    }, {
      name: 'state',
      label: 'State',
      noSorter: true,
    }, {
      name: 'delete',
      label: '',
      noSorter: true,
    }
  ];

  const {
    initialForcingFrequencies,
    close,
    components
  } = props;

  const [enableSave, setEnableSave] = useState(false);
  const [forcingFrequencies, setForcingFrequencies] = useState(_.cloneDeep(initialForcingFrequencies));

  const hasAccess = checkPermissions(
    mapComponentToResource.MachineDetails,
    props.rbacPermissions,
    operations.Update,
    props.currentUser
  );

  useEffect(() => {
    if (!_.isEqual(initialForcingFrequencies, forcingFrequencies)) {
      const save = !_.some(forcingFrequencies, ffItem => _.some(ffItem.forcing_frequencies,
        ff => (!ff.marker || !ff.name || (!ff.is_default && !ff.multiplier && !ff.frequency))));
      setEnableSave(save);
    } else setEnableSave(false);
  }, [forcingFrequencies]);

  const updateForcingFreqAssetItems = (componentId, ffId, key, value) => {
    const newForcingFrequencies = forcingFrequencies.map((ffItem) => {
      if (ffItem.component_id === componentId) {
        const ffs = ffItem.forcing_frequencies;
        ffs.map((ff) => {
          if (ff.id === ffId) {
            if (key === 'multiplier' && ff.frequency) ff.frequency = null;
            if (key === 'frequency' && ff.multiplier) ff.multiplier = null;
            ff[key] = (!value && value !== false) ? null : value;
          }
          return ff;
        });
      }
      return ffItem;
    });

    setForcingFrequencies(newForcingFrequencies);
  };

  const addForcingFreqAssetItem = (componentId) => {
    const newForcingFrequencies = forcingFrequencies.map((ffItem) => {
      if (ffItem.component_id === componentId) {
        const ffs = ffItem.forcing_frequencies;
        const newId = _.max(ffs.map(ff => ff.id)) + 1 || 0;
        const newFf = {
          id: newId,
          enabled: true,
          secondary: false,
          frequency: null,
          marker: null,
          multiplier: null,
          name: null
        };
        ffs.push(newFf);
      }
      return ffItem;
    });
    setForcingFrequencies(newForcingFrequencies);
  };

  const deleteForcingFreqAssetItem = (componentId, ffId) => {
    const newForcingFrequencies = forcingFrequencies.map((ffItem) => {
      if (ffItem.component_id === componentId) {
        ffItem.forcing_frequencies = ffItem.forcing_frequencies.filter(ff => ff.id !== ffId);
      }
      return ffItem;
    });
    setForcingFrequencies(newForcingFrequencies);
  };

  const saveForcingFrequencies = () => {
    setEnableSave(false);
    props.saveForcingFrequencies(forcingFrequencies);
  };

  const shaftNumberOptions = (componentId) => {
    const shaftNumberMenu = [];
    components.forEach((component) => {
      if (component.id === componentId) {
        const numberOfShafts = component.properties.no_of_shafts;
        for (let shaftNumber = 1; shaftNumber <= numberOfShafts; shaftNumber++) {
          shaftNumberMenu.push({
            text: getShaftName(shaftNumber, numberOfShafts),
            value: shaftNumber
          });
        }
      }
    });
    return shaftNumberMenu;
  };

  return (
    <Modal
      width="90%"
      padding="0px 0px"
      headerHeight="88px"
      headerPadding="0px 12px"
      outerPadding="0px 12px 0px"
      title={() => (<ModalHeading>Forcing Frequencies</ModalHeading>)}
      close={close}
    >
      <ScrollContainer height="80vh" enabled style={{ padding: '0px 12px' }}>
        {forcingFrequencies.map((forcingFrequencyItem) => {
          const componentType = forcingFrequencyItem.component_type;
          const secondaryColumn = props.componentsList.drive.map(type => type.value).includes(componentType) ||
            props.componentsList.driven.map(type => type.value).includes(componentType) || componentType === 'multi_stage_gearbox';
          const secondaryColumnSizes = [15, 26, 13, 17, 7, 18, 4];
          const secondaryColumnHeaders = headers.slice();
          secondaryColumnHeaders.splice(4, 0, { name: 'secondary', label: 'Secondary' });
          return (
          <Fragment>
            <HeadingContainer>
              <ModalSubHeading>{forcingFrequencyItem.component_name || humanize(forcingFrequencyItem.component_type)} Forcing Frequencies</ModalSubHeading>
              {hasAccess && (
                <Button
                  secondary
                  onClick={() => { addForcingFreqAssetItem(forcingFrequencyItem.component_id); }}
                >
                  <PlusSvg width="16" height="16" />
                </Button>
              )}
            </HeadingContainer>
            <ForcingFreqListContainer>
              <List
                componentId={forcingFrequencyItem.component_id}
                columnSizes={secondaryColumn ? secondaryColumnSizes : columnSizes}
                headers={secondaryColumn ? secondaryColumnHeaders : headers}
                sorterState={null}
                setSorter={null}
                items={{
                  loading: false,
                  object: forcingFrequencyItem.forcing_frequencies
                }}
                updateItem={updateForcingFreqAssetItems}
                deleteItem={deleteForcingFreqAssetItem}
                loading={false}
                ItemComponent={ForcingFreqListItem}
                emptyMsg="No Forcing Frequencies Found"
                padding="0 1rem"
                // contentPadding="0px 1rem"
                noBottomBorder
                hasAccess={hasAccess}
                frequencyUnits={frequencyUnits}
                componentType={forcingFrequencyItem.component_type}
                shaftOptions={shaftNumberOptions}
                secondaryColumn={secondaryColumn}
              />
            </ForcingFreqListContainer>
          </Fragment>
          );
        })}
      </ScrollContainer>
      <FooterContainer>
        <Button
          success
          fontSize="12px"
          disabled={!enableSave}
          onClick={saveForcingFrequencies}
        >
          APPLY
        </Button>
      </FooterContainer>
    </Modal>
  );
};


const mapStateToProps = state => ({
  rbacPermissions: state.rbacPermissions.result,
  currentUser: state.user.user,
  components: state.machineDetails.info.details.components,
  componentsList: state.machines.machineBuilder.metadata.components
});


ForcingFreqModal.propTypes = {
  close: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ForcingFreqModal);
