export default {
  GET_MACHINE_BHS_TREND_REQUEST: 'GET_MACHINE_BHS_TREND_REQUEST',
  GET_MACHINE_BHS_TREND_SUCCESS: 'GET_MACHINE_BHS_TREND_SUCCESS',
  GET_MACHINE_BHS_TREND_FAILURE: 'GET_MACHINE_BHS_TREND_FAILURE',

  GET_SPECTRUM_DATA_REQUEST: 'GET_SPECTRUM_DATA_REQUEST',
  GET_SPECTRUM_DATA_SUCCESS: 'GET_SPECTRUM_DATA_SUCCESS',
  GET_SPECTRUM_DATA_FAILURE: 'GET_SPECTRUM_DATA_FAILURE',

  GET_BHS_LATEST_SCORE_REQUEST: 'GET_BHS_LATEST_SCORE_REQUEST',
  GET_BHS_LATEST_SCORE_SUCCESS: 'GET_BHS_LATEST_SCORE_SUCCESS',
  GET_BHS_LATEST_SCORE_FAILURE: 'GET_BHS_LATEST_SCORE_FAILURE',

  GET_BHS_SCORE_DETAILS_REQUEST: 'GET_BHS_SCORE_DETAILS_REQUEST',
  GET_BHS_SCORE_DETAILS_SUCCESS: 'GET_BHS_SCORE_DETAILS_SUCCESS',
  GET_BHS_SCORE_DETAILS_FAILURE: 'GET_BHS_SCORE_DETAILS_FAILURE',

  SET_SELECTED_LOCATION_REQUEST: 'SET_SELECTED_LOCATION_REQUEST',
  SET_SELECTED_LOCATION_SUCCESS: 'SET_SELECTED_LOCATION_SUCCESS',
  SET_SELECTED_LOCATION_FAILURE: 'SET_SELECTED_LOCATION_FAILURE',

  CREATE_BHS_MODEL_REQUEST: 'CREATE_BHS_MODEL_REQUEST',
  CREATE_BHS_MODEL_SUCCESS: 'CREATE_BHS_MODEL_SUCCESS',
  CREATE_BHS_MODEL_FAILURE: 'CREATE_BHS_MODEL_FAILURE',

  RESET_BHS_MODEL_REQUEST: 'RESET_BHS_MODEL_REQUEST',
  RESET_BHS_MODEL_SUCCESS: 'RESET_BHS_MODEL_SUCCESS',
  RESET_BHS_MODEL_FAILURE: 'RESET_BHS_MODEL_FAILURE',
  
  RECOMPUTE_BHS_REQUEST: 'RECOMPUTE_BHS_REQUEST',
  RECOMPUTE_BHS_INITIATED: 'RECOMPUTE_BHS_INITIATED',
  RECOMPUTE_BHS_FAILED: 'RECOMPUTE_BHS_FAILED',

  RECOMPUTE_BHS_TASK_STATUS: 'RECOMPUTE_BHS_TASK_STATUS',
  RECOMPUTE_BHS_TASK_DONE: 'RECOMPUTE_BHS_TASK_DONE',

  GET_BHS_MODELS_REQUEST: 'GET_BHS_MODELS_REQUEST',
  GET_BHS_MODELS_SUCCESS: 'GET_BHS_MODELS_SUCCESS',
  GET_BHS_MODELS_FAILURE: 'GET_BHS_MODELS_FAILURE',

  UPDATE_BHS_MODEL_REQUEST: 'UPDATE_BHS_MODEL_REQUEST',
  UPDATE_BHS_MODEL_SUCCESS: 'UPDATE_BHS_MODEL_SUCCESS',
  UPDATE_BHS_MODEL_FAILURE: 'UPDATE_BHS_MODEL_FAILURE',
};
