import styled from 'styled-components';

const OutlierSettingsModal = styled.div`
position: absolute;
width: 250px;
padding: 1em;
box-shadow: 0 0 16px 0 rgba(0,0,0,0.12);
background-color: white;
border-radius: 4px;
top: 40px;
left: -40px;
z-index: 3;
`;

export default styled(OutlierSettingsModal)``;
