/* eslint-disable no-underscore-dangle */
import moment from 'moment-timezone';

Date.prototype.getUnixTime = function () { return this.getTime() / 1000 | 0; };
if (!Date.now) Date.now = () => new Date();
Date.time = () => Date.now().getUnixTime();

export const setPreferredTimezone = (timezone) => {
  if (timezone) {
    moment.tz.setDefault(timezone);
  } else {
    moment.tz.setDefault(moment.tz.guess());
  }
};

export const getPreferredTimezone = (user, account) => {
  let timezone = null;
  if (user.use_account_timezone && account.preferences && account.preferences.timezone) {
    timezone = account.preferences.timezone.name;
  }
  return timezone;
};

export function timeSince(timestamp) {
  if (!timestamp) {
    return '--';
  }
  const timeDiff = moment(timestamp) - moment(new Date());
  let result = moment.duration(timeDiff, 'milliseconds').humanize(true);
  result = result.replace(' ago', '');
  result = result.replace('in ', '');
  return `since ${result}`;
}

export function localTimeString(timestamp) {
  if (!timestamp) {
    return '                --';
  }
  const time = moment(timestamp);
  // return time.format('MM.DD.YYYY hh:mm A'); --> in design
  return time.format('MMM Do, YYYY h:mm A, z');
}
