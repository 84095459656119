export const roleOptions = [
  { text: 'Admin', value: 'admin' },
  { text: 'Analyst', value: 'analyst' },
  { text: 'Manager', value: 'manager' },
  { text: 'Technician', value: 'technician' },
];

export const petasenseAdminRoleAllowedOptions = [
  { text: 'Petasense Admin', value: 'petasense_admin' },
  { text: 'Partner Admin', value: 'partner_admin' }
];

export const partnerAdminRoleAllowedOptions = [
  { text: 'Partner Admin', value: 'partner_admin' }
];
