import theme from 'common/styles/theme';

const VFD_OPTIONS = {
  VFD_ROTATING: 'vfd_rotating',
  NON_VFD_ROTATING: 'non_vfd_rotating',
  OTHER: 'other'
};

const ALARM_METRICS_OPTIONS = {
  MACHINES_WITH_RECENT_OPEN_ALARMS: 'machines_with_recent_open_alarms',
  MACHINES_WITH_ALARM_BUT_NO_TASK: 'machines_with_alarm_but_no_task',
  MACHINES_WITH_TASK_BUT_NO_ALARM: 'machines_with_task_but_no_alarm',
  MACHINES_WITH_ALARM_TASK_BUT_NO_UPDATES: 'machines_with_alarm_task_but_no_updates'
};

export const machinesAnalyticsConstants = {
  GET_MACHINES_ANALYTICS_DATA_REQUEST: 'GET_MACHINES_ANALYTICS_DATA_REQUEST',
  GET_MACHINES_ANALYTICS_DATA_SUCCESS: 'GET_MACHINES_ANALYTICS_DATA_SUCCESS',
  GET_MACHINES_ANALYTICS_DATA_FAILURE: 'GET_MACHINES_ANALYTICS_DATA_FAILURE',
};

export const machineAnalyticsFilters = [
  'vfd',
  'health_score',
  'asset_setup',
  'events'
];

export const filtersIncludedInCharts = [
  'area'
];

export const customLabels = {
  vfd_rotating: 'VFD Rotating',
  non_vfd_rotating: 'Non-VFD Rotating',
  pole_count_speed: 'Pole Count / Rated Speed',
};

export const widgetTypes = {
  DONUT_CHART: 'DONUT_CHART',
  BAR_CHART: 'BAR_CHART'
};

export const alarmMetricsMetadata = {
  title: 'Alarm Metrics',
  filterOptions: [
    ALARM_METRICS_OPTIONS.MACHINES_WITH_RECENT_OPEN_ALARMS,
    ALARM_METRICS_OPTIONS.MACHINES_WITH_ALARM_BUT_NO_TASK,
    ALARM_METRICS_OPTIONS.MACHINES_WITH_TASK_BUT_NO_ALARM,
    ALARM_METRICS_OPTIONS.MACHINES_WITH_ALARM_TASK_BUT_NO_UPDATES
  ]
};

export const machinesAnalyticsMetadata = {
  vfd: {
    sortOrder: [
      VFD_OPTIONS.VFD_ROTATING,
      VFD_OPTIONS.NON_VFD_ROTATING,
      VFD_OPTIONS.OTHER
    ],
    colors: [theme.colors.normal, theme.colors.slight, theme.colors.unknown],
    filterOptions: [
      { text: VFD_OPTIONS.VFD_ROTATING, value: VFD_OPTIONS.VFD_ROTATING },
      { text: VFD_OPTIONS.NON_VFD_ROTATING, value: VFD_OPTIONS.NON_VFD_ROTATING },
      { text: VFD_OPTIONS.OTHER, value: VFD_OPTIONS.OTHER }
    ],
    title: 'Breakdown by VFD',
    id: 'machine-analytics-vfd-info-chart'
  },
  events: {
    colors: [
      '#37B6B8',
      '#05FBA1',
      '#4babfa',
      '#A6FF36',
    ],
    title: 'Breakdown by Open Events',
    id: 'machine-analytics-events-info-chart',
    chartArea: { width: '90%', left: '20%', height: '80%', top: '5%' },
    height: 'auto',
    yLabelFontSize: 10,
  },
  health_score: {
    sortOrder: {
      5: ['< 10', '< 8', '< 6', '< 4', '< 2', 'unknown'],
      3: ['< 10', '< 7', '< 4', 'unknown']
    },
    colors: {
      5: [
        theme.colors.normal,
        theme.colors.slight,
        theme.colors.moderate,
        theme.colors.warning,
        theme.colors.critical,
        theme.colors.unknown
      ],
      3: [
        theme.colors.normal,
        theme.colors.warning,
        theme.colors.critical,
        theme.colors.unknown
      ]
    },
    filterOptions: {
      5: [
        { text: '< 10', value: [8, 10] },
        { text: '< 8', value: [6, 8] },
        { text: '< 6', value: [4, 6] },
        { text: '< 4', value: [2, 4] },
        { text: '< 2', value: [0, 2] },
        { text: 'unknown', value: 'unknown' }
      ],
      3: [
        { text: '< 10', value: [7, 10] },
        { text: '< 7', value: [4, 7] },
        { text: '< 4', value: [0, 4] },
        { text: 'unknown', value: 'unknown' }
      ],
    },
    title: 'Breakdown by Health Score',
    id: 'machine-analytics-hs-info-chart'
  },
  asset_setup: {
    sortOrder: {
      baseline: ['partially_configured', 'not_configured'],
      alarms: ['partially_configured', 'not_configured'],
      speed_info: ['pole_count_speed', 'line_frequency', 'gear_ratio', 'speed_ratio'],
      others: []
    },
    chartArea: { width: '90%', left: '20%', height: '70%' },
    height: 250,
    yLabelFontSize: 12,
    colors: [
      // for baseline
      // theme.colors.normal, // hide configured field
      theme.colors.slight,
      theme.colors.moderate,
      // for alarm thresholds
      // '#EF5ECD', // hide configured field
      '#FF9764',
      '#FFE25F',
      // for speed info
      '#37B6B8',
      '#05FBA1',
      '#4babfa',
      '#A6FF36',
      // for others
      '#ff8282',
      '#FB056A',
      '#b14aff',
      '#ff4a4a',
    ],
    title: 'Breakdown by Asset Setup',
    id: 'machine-analytics-setup-info-chart'
  }
};
