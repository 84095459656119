import { actionTypes } from 'redux-form';
import { erroneousReadingsConstants } from './constants/erroneousReadings.constants';

const erroneousReadingsInitialState = {
  loading: false,
  object: [],
  hasMore: false,
  page: 1
};

export const erroneousReadingsReducer = (state = erroneousReadingsInitialState, action) => {
  switch (action.type) {
    case erroneousReadingsConstants.GET_SUMMARIZED_ERRONEOUS_READINGS_REQUEST: {
      if (action.page === 1) {
        return {
          ...state,
          loading: true,
          object: []
        };
      }
      return {
        ...state,
        loading: false,
        // hack state to avoid calling duplicate API request during pagination and not showing list loading SVG for better UX
        listLoading: true
      };
    }
    case erroneousReadingsConstants.GET_SUMMARIZED_ERRONEOUS_READINGS_SUCCESS: {
      let erroneousReadingsArray = [];
      if (action.page === 1) erroneousReadingsArray = action.erroneousReadings;
      else erroneousReadingsArray = state.object.concat(action.erroneousReadings);
      return {
        hasMore: action.page < action.total_pages,
        object: erroneousReadingsArray,
        page: action.page,
        total_count: action.total_count,
        loading: false,
        listLoading: false
      };
    }
    case erroneousReadingsConstants.GET_SUMMARIZED_ERRONEOUS_READINGS_FAILURE:
      return {
        ...state,
        hasMore: false,
        loading: false,
        listLoading: false,
        error: action.error.message
      };
    case erroneousReadingsConstants.CLEAR_SUMMARIZED_ERRONEOUS_READINGS_LIST:
      return {
        ...state,
        object: []
      };
    case erroneousReadingsConstants.INCREASE_ERRONEOUS_PAGE:
      return {
        ...state,
        page: action.page
      };
    default:
      return state;
  }
};

const initialStateFilters = {
  filters: [],
  start_date: null,
  end_date: null
};

export const erroneousReadingsFilters = (state = initialStateFilters, action) => {
  switch (action.type) {
    case erroneousReadingsConstants.SET_SUMMARIZED_ERRONEOUS_READINGS_FILTERS:
      return {
        filters: action.filters,
        start_date: action.start_date,
        end_date: action.end_date
      };
    default:
      return state;
  }
};

const sensorErroneousReadingsInitialState = {
  loading: false,
  object: [],
  hasMore: false,
  page: 1,
  sensor_id: null
};

export const sensorErroneousReadingsReducer = (state = sensorErroneousReadingsInitialState, action) => {
  switch (action.type) {
    case erroneousReadingsConstants.GET_ERRONEOUS_READINGS_REQUEST: {
      if (action.page === 1) {
        return {
          ...state,
          loading: true,
          object: [],
          sensor_id: null
        };
      }
      return {
        ...state,
        loading: false,
        // hack state to avoid calling duplicate API request during pagination and not showing list loading SVG for better UX
        listLoading: true
      };
    }
    case erroneousReadingsConstants.GET_ERRONEOUS_READINGS_SUCCESS: {
      let sensorErroneousReadingsArray = [];
      if (action.page === 1) sensorErroneousReadingsArray = action.erroneousReadings;
      else sensorErroneousReadingsArray = state.object.concat(action.erroneousReadings);
      return {
        hasMore: action.page < action.total_pages,
        object: sensorErroneousReadingsArray,
        page: action.page,
        total_count: action.total_count,
        loading: false,
        listLoading: false,
        sensor_id: action.sensor_id
      };
    }
    case erroneousReadingsConstants.GET_ERRONEOUS_READINGS_FAILURE:
      return {
        ...state,
        hasMore: false,
        loading: false,
        listLoading: false,
        error: action.error.message
      };
    case erroneousReadingsConstants.CLEAR_ERRONEOUS_READINGS_LIST:
      return {
        ...state,
        object: []
      };
    case erroneousReadingsConstants.INCREASE_SENSOR_ERRONEOUS_PAGE:
      return {
        ...state,
        page: action.page
      };
    default:
      return state;
  }
};

const initialSensorStateFilters = {
  filters: [],
  start_date: null,
  end_date: null
};

export const sensorErroneousReadingsFilters = (state = initialSensorStateFilters, action) => {
  switch (action.type) {
    case erroneousReadingsConstants.SET_ERRONEOUS_READINGS_FILTERS:
      return {
        filters: action.filters,
        start_date: action.start_date,
        end_date: actionTypes.end_date
      };
    default:
      return state;
  }
};
