import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as _ from 'lodash';

import RBAC from 'common/rbac/RBAC';
import { mapComponentToResource, operations } from 'common/rbac/constants';
import TickSvg from 'common/images/BearingModal/TickSvg';
import CrossSvg from 'common/images/CrossSvg';
import { Grid, Segment } from 'semantic-ui-react';
import {
  ResourceItemLabel,
  ResourceInputField,
  ResourceSection,
  ResourceItem
} from 'common/components/atoms/ResourceDetails';
import DeleteSensorModal from '../deleteSensorModal';
import OutsideAlerter from '../../../../common/OutsideAlerter';
import Modal from '../../../../common/components/organisms/Modal';
import { history, humanize } from '../../../../common/helpers';
import * as sensorsActions from '../../actions/sensors.actions';
import { localTimeString } from '../../../../common/helpers/time';
import LoadingSvg from '../../../../common/images/LoadingSvg';
import FlexContainer from '../../../../common/components/atoms/FlexContainer';
import CloseSvg from '../../../../common/images/closeIconSvg';
import H1_T from '../../../../common/typography/H1/H1';
import Label_T from '../../../../common/typography/Label/Label';
import Button_T from '../../../../common/components/atoms/Button';
import {
  EllipsisV,
  ExclamationTriangle
} from '../../../../common/images/FaIcons';
import MeasurementScheduleModal from '../MeasurementScheduleModal';
import {
  MESSAGE,
  reservedPrefixes,
  oldTxMeasurementVersion
} from '../../constants/sensors.constants';

const Container = styled(FlexContainer).attrs({ direction: 'column' })`
  padding: 2em;
  min-height: Calc(100vh - 138px);
`;

const H1 = styled(H1_T)`
  padding: ${props => (props.padding ? `${props.padding};` : '0;')};
  margin-bottom: 3px;
  min-width: max-content;
  width: 100%;
  cursor: pointer;
`;

const Button = styled(Button_T)`
  width: max-content;
  padding-left: 52px;
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;

const ViewButton = styled(Button_T)`
  width: max-content;
  padding-left: 10px;
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;

const SaveButton = styled(Button_T)`
  max-width: 200px;
  ${props => props.fontsize && `font-size: ${props.fontsize};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}
`;

const LoadingContainer = styled.div`
  align-self: center;
  margin: auto;
`;

const ButtonsContainer = styled(FlexContainer)`
  padding-top: 2em;
  button:first-child {
    margin-right: 15px;
  }
`;

const AbsoluteLoading = styled.div`
  background: 'white'
  position: absolute;
  align-self: center;
  margin: auto;
  opacity: 0.4;
  width: 100%;
  height: calc(100vh - 138px);
  overflow: none;
  svg {
    transform: translateX(35vw) translateY(50vh) translateY(-69px);
  }
`;

const TextLabel = styled(Label_T)`
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 8px;
  margin-top: -2px;
`;

const SvgContainer = styled.div`
  position: relative;
  cursor: ${props => (props.cursor ? `${props.cursor};` : 'pointer;')}
  margin: 0 10px;
  padding: ${props => (props.padding ? `${props.padding};` : '0.2em;')}
  ${props => props.marginTop && `margin-top: ${props.marginTop};`}
`;

const BorderContainer = styled.div`
  border: 2px solid #f0f0f0;
  padding: 20px;
`;

const HeaderContainer = styled(FlexContainer).attrs({
  justifyContent: 'space-between',
  alignItems: 'center'
})``;

const CenterText = styled.div`
  font-family: "Petasense Open Sans";
  font-weight: 600;
  text-align: center;
`;

const SubMenuContainer = styled.div`
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

const InlineSpan = styled.span`
  box-shadow: 0 0 8px 0 rgba(60, 61, 58, 0.24);
  background: white;
  border-radius: 4px;
  position: absolute;
  right: 0px;
  span {
    font-family: "Petasense Open Sans";
    font-weight: 600;
    &:hover {
      color: ${props => props.theme.primaryColor};
    }
  }
  padding: 1em;
`;

class OtherSensor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        machine: props.machines || [],
        component: [],
        location: [],
        orientation: [],
        tx: [],
        overall_measurement_type: []
      },
      details: {
        serial_number: props.sensor.serial_number,
        type: props.sensor.type,
        model: props.sensor.model
      },
      error: false,
      editMode: props.editMode,
      saveEnabled: false,
      menuExpanded: false,
      editSerialNumber: false,
      updatedSerialNumber: props.sensor.serial_number,
      errorMessages: {}
    };
  }

  componentDidMount() {
    const { getResourcesBasedOnType } = this.props.sensorsActions;
    if (this.state.options.machine.length === 0) {
      getResourcesBasedOnType('area');
      getResourcesBasedOnType('machine').then(
        res =>
          this.setState(prevState => ({
            options: {
              ...prevState.options,
              machine: res
            }
          })),
        error => this.setState({ error })
      );
    }
    getResourcesBasedOnType('tx').then(
      res =>
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            tx: res
          }
        })),
      error => this.setState({ error })
    );
    const {
      details,
      sensor: { model, serial_number }
    } = this.props;
    let sensor = {};
    if (details[model]) {
      sensor = details[model].find(d => d.serial_number === serial_number);
    }
    if (sensor) {
      this.setState({
        details: _.cloneDeep(sensor),
        associatedMachineId: sensor.machine
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      details,
      sensor: { serial_number }
    } = this.props;
    const {
      details: prevDetails,
      sensor: { serial_number: prevSerialNumber }
    } = prevProps;
    const {
      getLocationOptions,
      getComponentOptions
    } = this.props.sensorsActions;
    let sensor = {};
    let prevSensor = {};

    if (details.other) {
      sensor = details.other.find(d => d.serial_number === serial_number);
    }
    if (prevDetails.other) {
      prevSensor = prevDetails.other.find(
        d => d.serial_number === prevSerialNumber
      );
    }
    if (
      sensor &&
      (!_.isEqual(prevSensor, sensor) ||
        prevState.details.serial_number !== sensor.serial_number ||
        !_.isEqual(
          prevState.options.machine.length,
          this.state.options.machine.length
        ))
    ) {
      if (
        this.props.createdMachineId &&
        !_.isEmpty(this.state.options.machine)
      ) {
        const clonedDetails = _.cloneDeep(sensor);
        clonedDetails.machine = this.props.createdMachineId;
        const obj = this.state.options.machine.find(
          e => e.value === this.props.createdMachineId
        );
        clonedDetails.machine_name = (obj && obj.text) || '';
        this.setState({
          details: clonedDetails,
          associatedMachineId: this.props.createdMachineId,
          updatedSerialNumber: serial_number,
          editSerialNumber: false,
          errorMessages: {}
        });
      } else {
        this.setState({
          details: _.cloneDeep(sensor),
          associatedMachineId: sensor.machine,
          updatedSerialNumber: serial_number,
          editSerialNumber: false,
          errorMessages: {}
        });
      }
    }
    if (
      this.state.details.machine &&
      (_.isEmpty(this.state.options.component) ||
        prevState.details.machine !== this.state.details.machine)
    ) {
      getComponentOptions(Number(this.state.details.machine)).then(
        (res) => {
          this.setState(prevState => ({
            options: {
              ...prevState.options,
              component: res
            }
          }));
        },
        error => this.setState({ error })
      );
    }
    if (
      this.state.details.machine &&
      (_.isEmpty(this.state.options.location) ||
        prevState.details.machine !== this.state.details.machine ||
        prevState.details.component !== this.state.details.component)
    ) {
      getLocationOptions(
        Number(this.state.details.machine),
        Number(this.state.details.component)
      ).then(
        (res) => {
          this.setState(prevState => ({
            options: {
              ...prevState.options,
              location: res
            }
          }));
        },
        error => this.setState({ error })
      );
    }
    if (prevState.editMode !== this.props.editMode) {
      this.setState({
        editMode: this.props.editMode
      });
    }
    if (!sensor) return;
    const stateDetails = _.cloneDeep(this.state.details);
    const propsDetails = _.cloneDeep(sensor);
    delete stateDetails.machine;
    delete stateDetails.machine_name;
    delete stateDetails.location;
    delete stateDetails.location_name;
    delete stateDetails.component;
    delete stateDetails.component_name;
    delete propsDetails.machine;
    delete propsDetails.machine_name;
    delete propsDetails.location;
    delete propsDetails.location_name;
    delete propsDetails.component;
    delete propsDetails.component_name;

    if (_.isEqual(this.state.details, sensor)) {
      if (this.state.saveEnabled !== false) {
        this.setState({
          saveEnabled: false
        });
        if (this.props.updateFooter) {
          this.props.updateFooter(false);
        }
      }
    } else if (_.isEqual(stateDetails, propsDetails)) {
      if (
        this.state.details.machine &&
        this.state.details.location &&
        !_.isEqual(this.state.details.location, sensor.location)
      ) {
        if (this.state.saveEnabled !== true) {
          this.setState({ saveEnabled: true });
          if (this.props.updateFooter) {
            this.props.updateFooter(true);
          }
        }
      } else if (this.state.saveEnabled !== false) {
        this.setState({
          saveEnabled: false
        });
        if (this.props.updateFooter) {
          this.props.updateFooter(false);
        }
      }
    } else if (this.state.saveEnabled !== true) {
      this.setState({ saveEnabled: true });
      if (this.props.updateFooter) {
        this.props.updateFooter(true);
      }
    }
  }

  dissociateSensor = () => {
    this.setState(
      prevState => ({
        details: {
          ...prevState.details,
          location: null,
          location_name: null,
          machine: null,
          machine_name: null,
          component: null,
          component_name: null
        },
        associatedMachineId: null
      }),
      () => this.onSave()
    );
  };

  isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  stringifyOptions = options =>
    options.map(option => ({
      ...option,
      value: _.isObject(option.value)
        ? JSON.stringify(option.value)
        : option.value
    }));

  renderField = (
    type,
    label,
    options_T,
    value_T,
    disabled,
    name,
    search = false
  ) => {
    const { editMode } = this.state;
    const { updating } = this.props;
    const options = this.stringifyOptions(options_T);
    const value = _.isObject(value_T) ? JSON.stringify(value_T) : value_T;
    if (editMode) {
      return (
        <ResourceInputField
          type={type}
          label={label}
          options={options}
          value={value}
          name={name}
          key={name}
          disabled={disabled || updating}
          onChange={(e, value) => this.onChangeField(e, value, name)}
          selectOnBlur={false}
          search={search}
        />
      );
    }
    let textValue = '';
    if (options) {
      const element = options.filter(option =>
        _.isEqual(option.value, value)
      )[0];
      if (element) textValue = element.text;
      else textValue = '--';
    }
    return (
      <Fragment>
        <ResourceItemLabel>{label}</ResourceItemLabel>
        {name === 'machine' ? (
          <ResourceItem
            onClick={() => this.redirectToMachine(value)}
            hover={textValue !== '--'}
          >
            {textValue}
          </ResourceItem>
        ) : (
          <ResourceItem>{textValue}</ResourceItem>
        )}
      </Fragment>
    );
  };

  onChangeField = (e, { value }, name) => {
    if (name === 'machine' || name === 'component' || name === 'location') {
      const key = `${name}_name`;
      const text = this.state.options[name].find(e => e.value === value).text;
      this.setState(prevState => ({
        details: {
          ...prevState.details,
          [key]: text,
          [name]: value
        }
      }));
      return null;
    }
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        [name]: this.isJson(value) ? JSON.parse(value) : value
      }
    }));
  };

  deleteSensor = () => {
    const {
      details: { tx_serial_number, serial_number }
    } = this.state;
    if (tx_serial_number) {
      toastr.error(
        `${serial_number} is assigned to ${tx_serial_number}. First unassign and then delete.`
      );
    } else this.setState({ deleteModalOpen: true });
  };

  onDelete = () => {
    const { deleteSensor } = this.props.sensorsActions;
    const data = {
      serial_numbers: [this.state.details.serial_number]
    };
    deleteSensor(data).then(
      () => this.props.close(),
      error =>
        this.setState({
          error,
          deleteModalOpen: false,
          menuExpanded: false
        })
    );
  };

  onDeleteCancel = () => {
    this.setState({
      deleteModalOpen: false,
      menuExpanded: false
    });
  };

  onSave = () => {
    const { updateSensorDetails } = this.props.sensorsActions;
    const {
      details: {
        serial_number,
        location_name,
        machine,
        machine_name,
        component,
        component_name,
        orientation,
        location,
        overall_measurement_schedule,
        overall_measurement_stops,
        overall_measurement_type,
        overall_measurement_interval
      }
    } = this.state;
    const propsDetails = this.props.details.other.find(
      d => d.serial_number === this.props.sensor.serial_number
    );
    const data = {
      other: {
        serial_number: [serial_number],
        orientation,
        overall_measurements: {
          measurement: {
            type: overall_measurement_type,
            schedule: overall_measurement_schedule,
            stops: overall_measurement_stops
          },
          measurement_interval: overall_measurement_interval
        },
        location_id: location,
        location_name,
        component,
        component_name,
        machine,
        machine_name,
        type: 'other'
      }
    };
    if (_.isEqual(orientation, propsDetails.orientation)) delete data.other.orientation;
    if (_.isEqual(location, propsDetails.location)) delete data.other.location_id;
    return updateSensorDetails(data).then(
      res => res,
      error => toastr.error(error)
    );
  };

  viewSensor = (serial_number) => {
    if (!serial_number) return;
    const { getSensorDetails } = this.props.sensorsActions;
    // const { details: { serial_number: txSerialNumber } } = this.state;

    const { appliedFilters } = this.props;
    delete appliedFilters.area_search_key;
    delete appliedFilters.machine_search_key;

    if (this.props.machine_id) {
      delete appliedFilters.machines;
      const filters = _.every(appliedFilters, value => _.isEmpty(value));
      const present = this.sensorPresentInTheList(serial_number);
      if (!filters && !present) {
        this.setState({
          changeFilterModal: true,
          changeFilterSerialNumber: serial_number
        });
        return;
      }

      getSensorDetails(serial_number, true);
    } else {
      const filters = _.every(appliedFilters, value => _.isEmpty(value));
      const present = this.sensorPresentInTheList(serial_number);
      if (!filters && !present) {
        this.setState({
          changeFilterModal: true,
          changeFilterSerialNumber: serial_number
        });
        return;
      }

      getSensorDetails(serial_number, true);
    }
  };

  clearAppliedFilters = () => {
    const { changeFilterSerialNumber } = this.state;
    if (!changeFilterSerialNumber) return;
    this.props.removeAppliedFilters(changeFilterSerialNumber);
  };

  sensorPresentInTheList = (serial_number) => {
    const { items } = this.props;
    return items.find(el => el.serial_number === serial_number);
  };

  onCancel = () => {
    const {
      details,
      sensor: { serial_number }
    } = this.props;
    let sensor = {};
    if (details.other) {
      sensor = details.other.find(d => d.serial_number === serial_number);
    }
    if (sensor && !_.isEqual(this.state.details, sensor)) {
      this.setState({
        details: _.cloneDeep(sensor)
      });
    }
  };

  saveSchedule = (schedule, stops, mode) => {
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        overall_measurement_schedule: schedule[0],
        overall_measurement_stops: stops[0],
        aggressive_measurement: mode
      },
      openScheduler: false
    }), () => this.onSave());
  };

  redirectToMachine = (machineId) => {
    if (machineId) {
      history.push(`/machines/${machineId}/overview`);
    }
  };

  toggleMenuExpand = () => {
    this.setState(prevState => ({
      menuExpanded: !prevState.menuExpanded
    }));
  };

  validateInput = () => {
    let updatedSerialNumber = this.state.updatedSerialNumber;
    const errorMessages = {};
    if (updatedSerialNumber) {
      updatedSerialNumber = _.toUpper(updatedSerialNumber);
      reservedPrefixes.forEach((keyword) => {
        if (_.startsWith(updatedSerialNumber, keyword)) errorMessages.serialNumber = MESSAGE.serialNumberError;
      });
      this.setState({ errorMessages });
    }
  };

  changeSerialNumber = (value) => {
    const regex = new RegExp('^[0-9a-zA-Z_-]+$');
    if (value && !regex.test(value)) return;
    this.setState(
      {
        updatedSerialNumber: value
      },
      this.validateInput
    );
  };

  saveSerialNumber = () => {
    const { updateSensorSerialNumber } = this.props.sensorsActions;
    updateSensorSerialNumber(this.state.details.serial_number, 'other', {
      new_serial_number: this.state.updatedSerialNumber
    });
  };

  render() {
    const {
      details: {
        serial_number,
        type,
        model,
        last_measurement,
        machine,
        component,
        location,
        tx,
        tx_serial_number,
        overall_measurement_schedule,
        overall_measurement_stops,
        overall_measurement_type,
        overall_measurement_interval,
        transmitter_version
      },
      saveEnabled,
      error,
      editSerialNumber,
      updatedSerialNumber,
      errorMessages
    } = this.state;
    const {
      loading,
      detailsError,
      errorList,
      metadata,
      updating,
      utcOffset,
      primaryColor
    } = this.props;
    if (!loading && (!metadata || !metadata.other)) return "Metadata for the model other doesn't exist";
    if (!model) {
      return (
        <AbsoluteLoading nobackground>
          <LoadingSvg />
        </AbsoluteLoading>
      );
    }
    return (
      <Fragment>
        {updating && (
          <AbsoluteLoading>
            <LoadingSvg />
          </AbsoluteLoading>
        )}
        {this.state.deleteModalOpen && (
          <DeleteSensorModal
            modelName={this.state.details.serial_number}
            closeModal={this.onDeleteCancel}
            deleteModel={this.onDelete}
          />
        )}
        {this.state.openScheduler && (
          <MeasurementScheduleModal
            close={() => this.setState({ openScheduler: false })}
            editMode={this.state.editMode}
            schedule={{
              0: overall_measurement_schedule,
              1: [],
              2: []
            }}
            stops={{
              0: overall_measurement_stops,
              1: [],
              2: []
            }}
            disabledRows={{
              0: false,
              1: true,
              2: true
            }}
            intervals={metadata.other.measurement_settings.intervals}
            saveSchedule={this.saveSchedule}
            utcOffset={utcOffset}
          />
        )}
        <Container>
          {loading && !error && !detailsError && (
            <LoadingContainer>
              <LoadingSvg />
            </LoadingContainer>
          )}
          {!loading && (error || detailsError) && (
            <div>
              {error || detailsError}{' '}
              <b>(serial numbers: {errorList.join(', ')})</b>
            </div>
          )}
          {!loading && !error && (
            <Fragment>
              <HeaderContainer>
                {!editSerialNumber && (
                  <H1 onClick={() => this.setState({ editSerialNumber: true })}>
                    {serial_number}
                  </H1>
                )}
                {editSerialNumber && (
                  <FlexContainer>
                    <ResourceInputField
                      type="text"
                      name="serial_number"
                      value={updatedSerialNumber}
                      placeholder="Enter"
                      maxLength="100"
                      error={errorMessages.serialNumber}
                      onChange={e => this.changeSerialNumber(e.target.value)}
                    />
                    <SvgContainer
                      cursor={
                        errorMessages.serialNumber ||
                        updatedSerialNumber === serial_number ||
                        !updatedSerialNumber
                          ? 'default'
                          : 'pointer'
                      }
                      onClick={
                        errorMessages.serialNumber ||
                        updatedSerialNumber === serial_number ||
                        !updatedSerialNumber
                          ? () => {}
                          : this.saveSerialNumber
                      }
                      marginTop="1.5em"
                      padding="0 0 0 0.7em"
                    >
                      <TickSvg
                        inactive={
                          errorMessages.serialNumber ||
                          updatedSerialNumber === serial_number ||
                          !updatedSerialNumber
                        }
                        fill={primaryColor}
                      />
                    </SvgContainer>
                    <SvgContainer
                      valid
                      onClick={() =>
                        this.setState(prevState => ({
                          updatedSerialNumber: prevState.details.serial_number,
                          editSerialNumber: false
                        }))
                      }
                      marginTop="1.5em"
                      padding="0"
                    >
                      <CrossSvg width={20} height={20} fill="#888" />
                    </SvgContainer>
                  </FlexContainer>
                )}
                <FlexContainer direction="row">
                  <RBAC
                    resource={mapComponentToResource.Sensors}
                    operation={operations.Delete}
                    yes={(
                     <FlexContainer padding="0.2em" direction="column">
                        <SvgContainer onClick={this.toggleMenuExpand}>
                          <EllipsisV size="1x" color="#9ea09a" />
                        </SvgContainer>
                        {this.state.menuExpanded && (
                          <OutsideAlerter
                            styles="align-items: center; flex-basis: 100%; margin: auto;"
                            open
                            handleClick={() => this.toggleMenuExpand()}
                          >
                            <SubMenuContainer>
                              <InlineSpan>
                                <span onClick={() => this.deleteSensor()}>
                                  Delete
                                </span>
                              </InlineSpan>
                            </SubMenuContainer>
                          </OutsideAlerter>
                        )}
                     </FlexContainer>
                    )}
                  />
                  <span
                    style={{
                      cursor: 'pointer',
                      padding: '0.5em 0',
                      margin: '0 3px'
                    }}
                    onClick={this.props.close}
                  >
                    <CloseSvg width={14} height={14} />
                  </span>
                </FlexContainer>
              </HeaderContainer>
              <ResourceSection>Sensor Info</ResourceSection>
              <BorderContainer>
                <Grid columns={3} doubling stackable>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Type</ResourceItemLabel>
                        <ResourceItem>{type}</ResourceItem>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Model</ResourceItemLabel>
                        <ResourceItem>{model}</ResourceItem>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Transmitter</ResourceItemLabel>
                        <ResourceItem
                          onClick={() => this.viewSensor(tx_serial_number)}
                          hover={!!tx}
                        >
                          {tx_serial_number || '--'}
                        </ResourceItem>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '10px'
                          }}
                        >
                          <div>
                            <ResourceItemLabel>Machine</ResourceItemLabel>
                            {this.state.associatedMachineId &&
                              !this.props.createdMachineId &&
                              this.state.editMode && (
                                <Button
                                  text
                                  fontsize="12px"
                                  onClick={this.dissociateSensor}
                                >
                                  Dissociate
                                </Button>
                            )}
                            {this.renderField(
                              'select',
                              '',
                              this.state.options.machine,
                              machine,
                              !!this.state.associatedMachineId ||
                                this.props.createdMachineId,
                              'machine',
                              true
                            )}
                          </div>
                        </div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        {this.renderField(
                          'select',
                          'Component',
                          this.state.options.component,
                          component,
                          false,
                          'component',
                          true
                        )}
                      </Segment>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        {this.renderField(
                          'select',
                          'Location',
                          this.state.options.location.filter(
                            l => l.component_id === component
                          ),
                          location,
                          false,
                          'location'
                        )}
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <ResourceItemLabel>Last Measurement</ResourceItemLabel>
                        <ResourceItem>
                          {localTimeString(last_measurement)}
                        </ResourceItem>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </BorderContainer>
              <ResourceSection>Measurement Settings</ResourceSection>
              <BorderContainer>
                <Grid columns={3} doubling stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment>
                        <Grid.Column>
                          <Segment>
                            <ResourceItemLabel>Type</ResourceItemLabel>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '3px'
                              }}
                            >
                              <ResourceItem>
                                {humanize(overall_measurement_type)}
                              </ResourceItem>
                              {!_.includes(
                                oldTxMeasurementVersion,
                                this.props.currentAccount.preferences
                                  .tx_measurement_version
                              ) &&
                                overall_measurement_type === 'schedule_based' &&
                                transmitter_version !== '0804' && (
                                  <ViewButton
                                    text
                                    onClick={() =>
                                      this.setState({ openScheduler: true })
                                    }
                                    fontsize="12px"
                                  >
                                    {this.state.editMode ? 'Set ' : 'View'}
                                  </ViewButton>
                              )}
                            </div>
                          </Segment>
                        </Grid.Column>
                      </Segment>
                    </Grid.Column>

                    {(this.props.currentAccount.preferences
                      .tx_measurement_version === 'v1' ||
                      transmitter_version === '0804') && (
                      <Grid.Column>
                        <Segment>
                          {this.renderField(
                            'select',
                            'Interval',
                            metadata.other.measurement_settings.intervals,
                            overall_measurement_interval,
                            false,
                            'overall_measurement_interval'
                          )}
                        </Segment>
                      </Grid.Column>
                    )}

                      <Grid.Column>
                        <Segment>
                          <ResourceItemLabel>Reporting Interval</ResourceItemLabel>
                          <FlexContainer>
                            <ExclamationTriangle size="sm" />
                            <TextLabel>{`Reporting interval to be defined in Transmitter (${tx_serial_number ||
                            '--'})`}
                            </TextLabel>
                          </FlexContainer>
                        </Segment>
                      </Grid.Column>
                  </Grid.Row>
                </Grid>
              </BorderContainer>
              {this.state.editMode && (
                <ButtonsContainer>
                  <SaveButton disabled={!saveEnabled} onClick={this.onSave}>
                    Save
                  </SaveButton>
                  <SaveButton
                    secondary="#3C3D3A"
                    disabled={!saveEnabled}
                    onClick={this.onCancel}
                  >
                    Cancel
                  </SaveButton>
                </ButtonsContainer>
              )}
            </Fragment>
          )}
        </Container>
        {this.state.changeFilterModal && (
          <Modal
            width="40%"
            close={() =>
              this.setState({
                changeFilterModal: false,
                changeFilterSerialNumber: null
              })
            }
          >
            <CenterText>
              The sensor details cannot be displayed because there is a filter
              applied on this list. Would you like to remove the filter and
              proceed?
            </CenterText>
            <FlexContainer justifyContent="center">
              <ButtonsContainer>
                <Button width="70px" onClick={this.clearAppliedFilters}>
                  Ok
                </Button>
                <Button
                  secondary="#3C3D3A"
                  onClick={() =>
                    this.setState({
                      changeFilterModal: false,
                      changeFilterSerialNumber: null
                    })
                  }
                >
                  Cancel
                </Button>
              </ButtonsContainer>
            </FlexContainer>
          </Modal>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  items: state.sensors.items.object,
  metadata: state.sensors.metadata.metadata,
  currentAccount: state.currentAccount,
  details: state.sensors.details.details,
  loading: state.sensors.details.loading,
  detailsError: state.sensors.details.error,
  updating: state.sensors.details.updating,
  errorList: state.sensors.details.errorList,
  primaryColor: state.companyReducer.partner.theme.primaryColor
});

const mapDispatchToProps = dispatch => ({
  sensorsActions: bindActionCreators(sensorsActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true
})(OtherSensor);
