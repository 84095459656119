import _ from 'lodash';

let initialState = {
  currentWidth: 0,
  previousWidth: 275
};

const hierarchyViewPaneLocal = localStorage.getItem('hierarchyViewPaneLocal');
if (!_.isNull(hierarchyViewPaneLocal)) {
  initialState = JSON.parse(hierarchyViewPaneLocal);
}

const hierarchyViewPane = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_HIERARCHY_VIEW_PANE_WIDTH':
      return {
        ...state,
        currentWidth: action.payload.width
      };

    case 'TOGGLE_HIERARCHY_VIEW':
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

export default hierarchyViewPane;
