
const initialState = {
  partner: {},
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_COMPANY':
      return {
        ...state,
        partner: {
          ...state.partner,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};


export default companyReducer;
