import { axiosInstance, ENDPOINT } from 'common/constants';
import { handleResponse } from 'common/helpers';

import resourceConstants from '../resource.constant';

export const getAreas = () => {
  const request = () => ({ type: resourceConstants.GET_AREAS_REQUEST });
  const success = areas => ({ type: resourceConstants.GET_AREAS_SUCCESS, areas });
  const failure = error => ({ type: resourceConstants.GET_AREAS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    const params = {
      resource_type: 'area'
    };
    return handleResponse(axiosInstance.get(ENDPOINT.GET_FILTER_RESOURCES, { params }))
      .then(
        (res) => {
          const areas = res.result.map(area => ({ text: area.path, value: area.name, id: area.id }));
          dispatch(success(areas));
          return res;
        },
        (error) => {
          dispatch(failure(error));
          throw error;
        }
      );
  };
};
