import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Label from 'common/typography/Label/Label';
import Button from 'common/components/atoms/Button';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Modal from 'common/components/organisms/ModalV2';
import InputField from 'common/components/atoms/InputField';
import * as sensorActions from '../actions/sensors.actions';

const CancelButton = styled(Button)`
  margin-right: 20px
`;

const DropdownContainer = styled.div`
  width: 60%;
`;

class AssignModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account_id: '',
      allAccounts: []
    };
  }

  componentDidMount() {
    const {
      getResourcesBasedOnType,
    } = this.props.sensorActions;
    getResourcesBasedOnType('account').then((res) => {
      this.setState({
        allAccounts: res
      });
    });
  }

  render() {
    const { text, close, assign } = this.props;
    const { account_id } = this.state;
    return (
    <Modal
      width="600px"
      padding="0 20px"
      close={close}
    >
      <FlexContainer direction="column" marginbottom="40px" margintop="40px">
        <FlexContainer direction="column" alignItems="center" marginbottom="40px">
          <Label fontWeight="300" fontSize="18px" marginBottom="8px">Please select the account to assign for </Label>
          <Label fontSize="18px" marginBottom="0px">{text}</Label>
        </FlexContainer>
        <FlexContainer justifyContent="center" marginbottom="8px">
            <DropdownContainer>
                <InputField
                  label="Accounts:"
                  maxLength={50}
                  onChange={(e, d) => this.setState({ account_id: d.value })}
                  placeholder="Click to select an account"
                  type="select"
                  search
                  value={this.state.account_id}
                  options={this.state.allAccounts}
                />
            </DropdownContainer>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <CancelButton onClick={close} secondary="#000">CANCEL</CancelButton>
          <Button onClick={() => assign(account_id)}>ASSIGN</Button>
        </FlexContainer>
      </FlexContainer>
    </Modal>
    );
  }
}


AssignModel.propTypes = {
  assign: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  text: PropTypes.string
};

AssignModel.defaultProps = {
  text: ''
};

const mapDispatchToProps = dispatch => ({
  sensorActions: bindActionCreators(sensorActions, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(AssignModel);
