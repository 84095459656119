import React from 'react';
import styled from 'styled-components';

import Loading from '../../../../../common/components/atoms/Loading';
import { Chart } from '../../../../../common/components/Chart';
import H3 from '../../../../../common/typography/H3/H3';
import FlexContainer from '../../../../../common/components/atoms/FlexContainer';

const Title = styled(H3)`
  font-weight: 600;
  font-size: 14px;
`;

const NoDataContainer = styled.div`
  position: relative;
  width: 100%;
`;

const AbsoluteLoading = styled(Loading)`
  position: absolute;
  height: 91%;
  width: 97.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e447;
  margin-left: 53px;
`;

const NoDataTextOverlay = styled(FlexContainer).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexGap: props => props.flexGap,
})`
  position: absolute;
  width: 100%;
  height: calc(100% + 10px);
  top: -12.5px;
  left: 0;
`;

const NoDataChart = ({ configId, height, days, tagType, flexGap, title, domain, children, ...rest }) => {
  if (!domain && days) {
    const now = new Date();
    const lastDay = new Date();
    lastDay.setDate(lastDay.getDate() - days);
    domain = [lastDay, now];
  }
  const noDataDays = days !== undefined ? `(last ${days} day${days > 1 ? 's' : ''})` : '';
  return (
    <NoDataContainer>
      {rest.loading && <AbsoluteLoading />}
      <Chart
        type="line"
        chartName={`empty-chart-container-${configId}`}
        data={[]}
        domain={domain}
        height={tagType === 'utilization' ? '100px' : height}
        {...rest}
      />
      {!rest.loading && (
        <NoDataTextOverlay flexGap={flexGap}>
          {children}
          {!children && <Title>{(typeof title === 'string' || typeof title === 'object') ? title : `No data found for this chart config ${noDataDays}`}</Title>}
        </NoDataTextOverlay>
      )}
    </NoDataContainer>
  );
};

export default NoDataChart;
