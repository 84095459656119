import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Menu } from 'semantic-ui-react';
import styled from 'styled-components';

import DynamicTabs from 'common/components/organisms/DynamicTabs';
import PageNotFound from 'common/components/PageNotFound/PageNotFound';
import { history } from 'common/helpers';
import { isPetasenseAdmin, isPetasenseViewer } from 'common/rbac/util';

import Account from 'home/Accounts/components/Account';
import LoadingSvg from 'common/images/LoadingSvg';
import HomePageContainer from '../../HomePage/components/atoms/HomePageContainer';
import * as adminDashboardActions from '../adminDashboard.actions';
import Sensors from '../../Sensors/components/Sensors';
import DevicesHealth from '../../DevicesHealth/DevicesHealth';
import ErroneousReadings from '../ErroneousReadings/components/ErroneousReadings';
import MissedMeasurements from '../MissedMeasurements/components/MissedMeasurements';
import FirmwareDeployment from '../../FirmwareDeployment/components/FirmwareDeployment';
import AccountAnalytics from '../AccountAnalytics/components/AccountAnalytics';
import EventAnalytics from '../EventAnalytics/components/EventAnalytics';
import FirmwareDeploymentDetails from '../../FirmwareDeployment/components/FirmwareDeploymentDetails';
import MeasurementDeviceMonitor from '../MeasurementDeviceMonitor/components/MeasurementDeviceMonitor';


const SwitchContainer = styled.div`
  height: 100%;
  ${props => props.background && `background: ${props.background};`}
`;

const Content = styled.div`
  width: 100%;
`;

class AdminDashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'devices'
    };
    this.tabItems = [
      'accounts',
      'devices',
      'devices-health',
      'erroneous-readings',
      'missed-measurements',
      'account-analytics',
      'event-analytics',
      'measurement-device-monitor'
    ];
    if (isPetasenseAdmin(this.props.user.user) || isPetasenseViewer(this.props.user.user)) {
      this.tabItems.push('firmware-deployments');
    }
  }

  updateActiveItem = () => {
    const { url } = this.props.match;
    const items = this.props.location.pathname.slice(url.length).split('/');
    const item = items[items.length - 1];
    if (item.length > 0) {
      this.setState({ activeItem: item });
    } else {
      history.replace(`${url}/devices`);
    }
  };

  componentDidMount() {
    this.updateActiveItem();
    if (!this.props.adminDashboard.adminOpen) this.props.adminDashboardActions.toggleAdminDashboard();
  }

  componentWillUnmount() {
    this.props.adminDashboardActions.toggleAdminDashboard();
  }

  componentDidUpdate() {
    this.updateActiveItem();
  }

  adminDashboardPanes = () => {
    const { url } = this.props.match;
    const { activeItem } = this.state;
    const menuItems = [
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/accounts`}
            key="accounts"
            active={activeItem === 'accounts'}
          >
            Accounts
          </Menu.Item>
        ),
        key: 'admin-dashboard-accounts',
        path: `${url}/accounts`,
        title: 'Accounts'
      },
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/devices`}
            key="devices"
            active={activeItem === 'devices'}
          >
            Devices
          </Menu.Item>
        ),
        key: 'admin-dashboard-devices',
        path: `${url}/devices`,
        title: 'Devices'
      },
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/devices-health`}
            key="devices-health"
            active={activeItem === 'devices-health'}
          >
            Devices Health
          </Menu.Item>
        ),
        key: 'admin-dashboard-devices-health',
        path: `${url}/devices-health`,
        title: 'Devices Health'
      },
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/erroneous-readings`}
            key="erroneous-readings"
            active={activeItem === 'erroneous-readings'}
          >
            Erroneous Readings
          </Menu.Item>
        ),
        key: 'admin-dashboard-erroneous-readings',
        path: `${url}/erroneous-readings`,
        title: 'Erroneous Readings'
      },
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/missed-measurements`}
            key="missed-measurements"
            active={activeItem === 'missed-measurements'}
          >
            Missed Measurements
          </Menu.Item>
        ),
        key: 'admin-dashboard-missed-measurements',
        path: `${url}/missed-measurements`,
        title: 'Missed Measurements'
      },
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/account-analytics`}
            key="account-analytics"
            active={activeItem === 'account-analytics'}
          >
            Account Analytics
          </Menu.Item>
        ),
        key: 'admin-dashboard-account-analytics',
        path: `${url}/account-analytics`,
        title: 'Account Analytics'
      },
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/event-analytics`}
            key="event-analytics"
            active={activeItem === 'event-analytics'}
          >
            Event Analytics
          </Menu.Item>
        ),
        key: 'admin-dashboard-event-analytics',
        path: `${url}/event-analytics`,
        title: 'Event Analytics'
      },
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/measurement-device-monitor`}
            key="measurement-device-monitor"
            active={activeItem === 'measurement-device-monitor'}
          >
            Measurement and Device Monitor
          </Menu.Item>
        ),
        key: 'measurement-device-monitor',
        path: `${url}/measurement-device-monitor`,
        title: 'Measurement and Device Monitor'
      }
    ];
    if (isPetasenseAdmin(this.props.user.user) || isPetasenseViewer(this.props.user.user)) {
      menuItems.push({
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/firmware-deployments`}
            key="firmware-deployments"
            active={activeItem === 'firmware-deployments'}
          >
            Firmware Deployment
          </Menu.Item>
        ),
        key: 'admin-dashboard-firmware-deployments',
        path: `${url}/firmware-deployments`,
        title: 'Firmware Deployments'
      });
    }
    return menuItems;
  };

  handleTabChange = (e, data) => {
    const { activeIndex } = data;
    this.setState({ activeItem: this.tabItems[activeIndex] });
  };

  render() {
    const {
      accountSelectionState,
    } = this.props;

    const { activeItem } = this.state;
    if (accountSelectionState.loadingId) {
      return (
        <div className="full-screen-center">
          <LoadingSvg />
        </div>
      );
    }
    return (
      <HomePageContainer className="home-page">
        <Content>
          {!accountSelectionState.loadingId && (
            <SwitchContainer id="home-page-switch-container">
              <Switch>
                {this.tabItems.indexOf(activeItem) !== -1 && (
                  <>
                    <DynamicTabs
                      menu={{ secondary: true }}
                      panes={this.adminDashboardPanes()}
                      activeIndex={this.tabItems.indexOf(activeItem)}
                      onTabChange={this.handleTabChange}
                      bufferSpace={45}
                    />
                  </>
                )}
              </Switch>
              <Switch>
                <Route
                  path="/admin-dashboard/accounts"
                  component={Account}
                />
                <Route
                  path="/admin-dashboard/devices"
                  component={Sensors}
                />
                <Route
                  path="/admin-dashboard/devices-health"
                  component={DevicesHealth}
                />
                <Route
                  path="/admin-dashboard/erroneous-readings"
                  component={ErroneousReadings}
                />
                <Route
                  path="/admin-dashboard/missed-measurements"
                  component={MissedMeasurements}
                />
                <Route
                  path="/admin-dashboard/account-analytics"
                  component={AccountAnalytics}
                />
                <Route
                  path="/admin-dashboard/event-analytics"
                  component={EventAnalytics}
                />
                <Route
                  path="/admin-dashboard/measurement-device-monitor"
                  component={MeasurementDeviceMonitor}
                />
                {(isPetasenseAdmin(this.props.user.user) || isPetasenseViewer(this.props.user.user)) && (
                  <>
                    <Route
                      exact
                      path="/admin-dashboard/firmware-deployments"
                      component={FirmwareDeployment}
                    />
                    <Route
                      exact
                      path="/admin-dashboard/firmware-deployments/:deployment_id"
                      component={FirmwareDeploymentDetails}
                    />
                  </>
                )}
                <Route exact path="*" component={PageNotFound} />
              </Switch>
            </SwitchContainer>
          )}
        </Content>
      </HomePageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    accountSelectionState,
    user,
    adminDashboard
  } = state;
  return {
    user,
    accountSelectionState,
    adminDashboard
  };
};

const mapDispatchToProps = dispatch => ({
  adminDashboardActions: bindActionCreators(adminDashboardActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
