import { combineReducers } from 'redux';

import { teamsActionConstants } from './constants';


const initialTeamsList = {
  items: []
};

export const teamsListReducer = (state = initialTeamsList, action) => {
  switch (action.type) {
    case teamsActionConstants.GET_TEAMS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case teamsActionConstants.GET_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data.items
      };

    case teamsActionConstants.GET_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case teamsActionConstants.UPDATE_TEAM_SUCCESS: {
      const { teamId, args } = action;
      const newItems = state.items.map((item) => {
        if (item.id === teamId) {
          return {
            ...item,
            ...args
          };
        }
        return item;
      });
      return {
        ...state,
        items: newItems
      };
    }

    case teamsActionConstants.ADD_USER_SUCCESS:
    case teamsActionConstants.DELETE_USER_SUCCESS:
    case teamsActionConstants.ADD_AREA_SUCCESS:
    case teamsActionConstants.DELETE_AREA_SUCCESS:
    {
      const { data } = action;
      const newItems = state.items.map((item) => {
        if (item.id === data.id) return data;
        return item;
      });
      return {
        ...state,
        items: newItems
      };
    }

    case teamsActionConstants.CREATE_TEAM_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        items: [...state.items, data]
      };
    }

    case teamsActionConstants.DELETE_TEAM_SUCCESS: {
      const { teamId } = action;
      const newItems = state.items.filter(item => item.id !== teamId);
      return {
        ...state,
        items: newItems
      };
    }

    default:
      return state;
  }
};

export const teams = combineReducers({
  list: teamsListReducer,
});
