import React from 'react';
import PropTypes from 'prop-types';

const ResetSvg = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <rect id="path-1" x={0} y={0} width={16} height={16} rx="1.33333333" />
      <filter x="-75.0%" y="-75.0%" width="250.0%" height="250.0%" filterUnits="objectBoundingBox" id="filter-2">
        <feOffset dx={0} dy={0} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={4} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0.235294118   0 0 0 0 0.239215686   0 0 0 0 0.22745098  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g id="Machine/Charts" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="1-6-0-Machine-Charts-Expanded" transform="translate(-1012.000000, -268.000000)">
        <g id="charts" transform="translate(80.000000, 212.000000)">
          <g id={1}>
            <g id="trend" transform="translate(0.000000, 16.000000)">
              <g id="graph" transform="translate(20.000000, 38.000000)">
                <g id="refresh-icon" transform="translate(920.000000, 10.000000)">
                  <g id="bounds">
                    <use fill="black" fillOpacity={1} filter="url(#filter-2)" xlinkHref="#path-1" />
                    <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
                  </g>
                  <g id="icon" transform="translate(3.000000, 3.000000)" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.15384615">
                    <polyline id="Path" points="6.53846154 3.65384615 9.42307692 3.65384615 9.42307692 0.192307692" />
                    <path d="M9.27769231,3.65384615 C8.74334842,1.58061867 6.85338482,0.147052309 4.71286314,0.191353268 C2.57234147,0.235654226 0.743310004,1.74619016 0.295203363,3.83975077 C-0.152903277,5.93331139 0.897576676,8.06017922 2.83242764,8.97677474 C4.76727861,9.89337026 7.07845637,9.35901806 8.41461538,7.68615385" id="Path" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ResetSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

ResetSvg.defaultProps = {
  width: 32,
  height: 32,
  fill: '#999B95'
};


export default ResetSvg;
