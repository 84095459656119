import styled from 'styled-components';

const SortContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: .5em;
  svg {
    color: ${props => props.theme.colors.lightGray};
    height: 0.85em;
  }
  svg:first-of-type {
    margin-top: 0.2em;
  }
  svg:last-of-type {
    margin-top: -0.8em;
  }
  &.asc {
    svg:first-of-type {
      color: ${props => props.theme.colors.black};
    }
  }
  &.desc {
    svg:last-of-type {
      color: ${props => props.theme.colors.black};
    }
  }
`;

export default SortContainer;
