import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as _ from 'lodash';

import moment from 'moment-timezone';
import { toastr } from 'react-redux-toastr';
import { history } from 'common/helpers';
import { chartColors } from 'common/constants';
import LoadingSvg from 'common/components/atoms/Loading';
import * as helpers from 'common/components/Chart/utils/helpers';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Text from 'common/typography/Text/Text';
import ColoredCircle from 'common/components/Chart/atoms/ColoredCircle';
import ChartOptions from 'common/components/Chart/components/ChartOptions';
import { defaultChartSettings } from 'common/charts/constants';
import ChartMultilineItem from './ChartMultilineItem';
import * as assetDetailsActions from '../../actions/assetDetails.actions';
import * as spectrumFeatureActions from '../../actions/spectrumFeatures.actions';
import * as userActions from '../../../../onboarding/user.actions';
import * as hierarchyUtils from '../../utils/assetHierarchyUtils';
import ChartTab from '../../../../common/components/Chart/components/ChartTab';
import SplitableMultilineChart from '../../../../common/components/Chart/organisms/SplitableMultilineChart';
import ChartItemContainer from '../../../../common/components/Chart/components/ChartItemContainer';
import ChartContainer from '../atoms/ChartContainer';
import NoDataChart from '../../../Machines/MachineDetails/MachineCharts/components/NoDataChart';
import CompareTagsModal from '../../../Machines/CompareTags/components/CompareTagsModal';
import AddChartModal from '../organisms/AddChartModal';
import * as compareChartsTags from '../../actions/compareCharts.actions';
import { formatChartData, convertTimestringsToDateObjects } from '../../../../common/utils';

const ConfigContainer = styled.div`
  ${props => props.background && `background: ${props.background};`}
  ${props => props.borderRadius && `border-radius: ${props.borderRadius};`}
  overflow: auto;
  height: 100%;
`;

const ValueText = styled(Text)`
  font-size: ${props => props.fontSize ? props.fontSize : '10px'};
  color: ${props => props.theme.colors.darkGray};
  margin-right: 5px;
  cursor: default;
`;

class MultilineChartView extends Component {
  constructor(props) {
    super(props);
    this.calendarSelect = this.calendarSelect.bind(this);
    this.getWaveAndSpecForId = this.getWaveAndSpecForId.bind(this);
    this.getWaveformAndSpectrum = this.getWaveformAndSpectrum.bind(this);
    this.refreshChart = this.refreshChart.bind(this);

    this.state = {
      amp_type: '',
      config: props.previewConfig ? props.previewConfig : [],
      days: 30,
      bin_nos: [],
      feature: '',
      chartType: 'trend',
      measurement_type: 1,
      selectedTabItem: '',
      showCharts: true,
      editTrend: false,
      tags: [],
      split: false,
      componentNode: null,
      waveAndSpecSelections: null,
      paginationLoading: false,
      useCompareChartsTags: props.useCompareChartsTags || false
    };
  }

  componentDidMount() {
    this.updateChartSettings();
    this.updateCalendar();
    const { currentAccount, allTags } = this.props;
    if (_.isEmpty(currentAccount)) {
      this.props.userActions.getCurrentAccount();
    }
    if (!this.props.previewConfig) {
      const config = this.getConfigFromHierarchy(true);
      if (_.isEmpty(config)) return;
      this.setState({
        config,
        componentNode: hierarchyUtils.getComponentNodeByConfigId(this.props.assetHierarchy.hierarchy, config[0].id),
      });
    }
    const tags = allTags || this.getTagFromHierarchy();
    if (!_.isEmpty(tags)) this.setState({ tags });
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state;
    const { previewConfig } = this.props;
    if (this.props.match.params.config_id !== prevProps.match.params.config_id) {
      this.resetState();
    }
    if (!this.props.previewConfig) {
      const config = this.getConfigFromHierarchy(this.props.match.params.config_id !== prevProps.match.params.config_id);
      if (_.isEmpty(config)) return;
      if (!_.isEqual(this.state.config, config)) {
        this.setState({
          config,
          componentNode: hierarchyUtils.getComponentNodeByConfigId(this.props.assetHierarchy.hierarchy, config[0].id),
        });
      }
    }
    const tags = this.getTagFromHierarchy();
    if ((config[0] && !this.state.trendLoading && _.isEmpty(config[0].trends_data)) ||
        (previewConfig && !this.state.trendLoading && _.isEmpty(config[0].trends_data)) ||
        (!_.isEmpty(prevState.config[0]) && !_.isEqual(prevState.config[0].trends_config, config[0].trends_config)) ||
        (_.isEmpty(prevState.config))) {
      if (!previewConfig) this.resetState();
      const params = this.getParamsForChartsData();
      this.setState({ trendLoading: true });
      if (previewConfig && previewConfig[0]) {
        const updatedConfig = _.cloneDeep(config);
        if (this.state.useCompareChartsTags === true) {
          this.useCompareChartsFunction(previewConfig, config);
        } else {
          this.fetchChartData(
            { trends_config: previewConfig[0].trends_config, ...params },
            false,
            (res) => {
              if (!res) return;
              res.forEach((r) => {
                const r_key = helpers.getTagMatchKey(r);
                const t_data = updatedConfig[0].trends_data.find(item => helpers.getTagMatchKey(item) === r_key);
                if (!t_data) updatedConfig[0].trends_data.push(r);
              });
              this.setState({ config: updatedConfig, trendLoading: false }, () => this.getDataForPreviewConfig(res));
            }
          );
        }
      } else if (!previewConfig && config[0]) {
        this.fetchChartData(
          { ...params, config_id: config[0].config_id },
          false,
          (res) => {
            if (!res) return;
            res.forEach(r => this.getSpectrumData(r, config[0].config_id));
            this.setState({ trendLoading: false });
          }
        );
      }
    }
    if (_.isEmpty(tags) || _.some(tags, tag => _.isEmpty(tag))) return;
    if (!_.isEqual(this.state.tags, tags)) {
      this.setState({ tags }, () => this.groupedConfigByTagInfo());
    }
  }

  getDataForPreviewConfig = (res, handlerType = null) => {
    res.forEach((r) => { this.getSpectrumData(r, null, handlerType); });
  }

  resetState = () => {
    this.setState({
      addingNote: false,
      editTrend: false,
      showCharts: true,
      editDemod: false,
      openFeature: false,
      openFeatureDetails: false
    });
  }

  hasData = t_data => t_data && t_data.length

  setInitialSelection() {
    const { config } = this.state;
    if (!_.isEmpty(config) &&
        config[0].trends_data &&
        config[0].trends_data.length > 0 &&
        _.every(config[0].trends_data, t => t.trend_data.length > 0)) {
      const data = config[0].trends_data.map(t => this.getDataFromTimestamp(t.trend_data) || t.trend_data[t.trend_data - 1]);
      this.setSelection(data);
    }
  }

  setSelection = (data) => {
    this.setState({
      waveAndSpecSelections: data
    });
  }

  getSpectrumData = (res, config_id = null, handlerType = null) => {
    const { config } = this.state;
    const tag_id = res.tag_id;
    const res_key = helpers.getTagMatchKey(res);
    const trendData = res.trend_data;
    const t_config = config[0].trends_config.find(t => helpers.getTagMatchKey(t) === res_key);
    if (!t_config) return;
    if (trendData.length > 0) {
      const data = handlerType === 'calendar' ? null : this.getDataFromTimestamp(trendData);
      const params = {
        tag_id,
        timestamp: data ? data.x : trendData[trendData.length - 1].x
      };
      if (t_config.amp_type) params.amp_type = t_config.amp_type;
      if (t_config.feature) params.feature = t_config.feature;
      this.getMeasurementData(tag_id, params, config_id);
      this.setInitialSelection();
    }
  }

  getDataFromTimestamp = (dataset) => {
    const chartSettings = this.getChartSettingsFromSession();
    const { machine_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    if (!chartSettings.timestamp || !chartSettings.timestamp[machine_id]) return null;
    return helpers.closestXDataPointOnDataset(dataset, new Date(chartSettings.timestamp[machine_id]), true);
  }

  getMeasurementData = (tag_id, params, config_id = null) => {
    const { previewConfig } = this.props;
    const { getTagSpectrum } = this.props.assetDetailsActions;
    if (params.amp_type === 'demod') {
      params.amp_type = 'acceleration';
      params.type = 'demod';
    }
    this.setState({ spectrumLoading: true });
    if (previewConfig) {
      getTagSpectrum(tag_id, params, config_id, true).then((spectrum) => {
        this.setState((prevState) => {
          const spectrumConfig = _.cloneDeep(prevState.config);
          if (!spectrum) return prevState;
          const idx = spectrumConfig[0].spectrums.findIndex(s => s.tag_id && s.tag_id === spectrum.tag_id);
          if (idx > -1) spectrumConfig[0].spectrums[idx] = spectrum;
          else spectrumConfig[0].spectrums.push(spectrum);
          return { config: spectrumConfig, spectrumLoading: false };
        });
      });
    } else getTagSpectrum(tag_id, params, config_id, true).then(() => this.setState({ spectrumLoading: false }));
    this.setTimeStampInSession(params.timestamp);
  }

  fetchChartData = (params, pagination, callBackFn, noOfRequests, uniqueKey) => (
    this.props.assetDetailsActions.getChartsData(params, pagination, noOfRequests, uniqueKey).then(
      res => callBackFn(res)
    )
  )

  useCompareChartsFunction = (previewConfig, config) => {
    const compareTagsParams = _.cloneDeep(config);
    const params = { ...previewConfig[0], measurement_type: this.state.measurement_type, days: this.state.days };
    this.getCompareChartsForAllTags(params, compareTagsParams);
  }

  calendarSelect(selectedRange, newCustomDayFrom = null, newCustomDayTo = null) {
    const { config, measurement_type } = this.state;
    const { previewConfig } = this.props;
    if (!config[0] || !config[0].trends_data) return;
    const newState = { selectedRange };
    const days = parseInt(selectedRange, 10);
    const params = {
      measurement_type,
    };
    if (selectedRange === 'custom' && newCustomDayFrom && newCustomDayTo) {
      params.from_timestamp = newCustomDayFrom;
      params.to_timestamp = newCustomDayTo;
      newState.customDayFrom = newCustomDayFrom;
      newState.customDayTo = newCustomDayTo;
    } else {
      newState.customDayFrom = null;
      newState.customDayTo = null;
    }
    if (!Number.isNaN(days)) {
      params.days = days;
    }
    newState.days = params.days;
    if (selectedRange === 'custom') {
      newState.days = 'custom';
    }
    this.setState({ trendLoading: true });
    if (previewConfig && previewConfig[0]) {
      const updatedConfig = _.cloneDeep(this.props.previewConfig);
      if (this.state.useCompareChartsTags === true) {
        this.useCompareChartsFunction(previewConfig, config);
      } else {
        this.fetchChartData(
          { ...params, trends_config: previewConfig[0].trends_config },
          false,
          (res) => {
            if (!res) return;
            res.forEach((r) => {
              const r_key = helpers.getTagMatchKey(r);
              const t_data = updatedConfig[0].trends_data.find(item => helpers.getTagMatchKey(item) === r_key);
              if (!t_data) updatedConfig[0].trends_data.push(r);
            });
            this.setState({ config: updatedConfig, trendLoading: false }, () => this.getDataForPreviewConfig(res, 'calendar'));
          }
        );
      }
    } else {
      this.fetchChartData(
        { ...params, config_id: config[0].config_id },
        false,
        (res) => {
          if (!res) return;
          res.forEach(r => this.getSpectrumData(r, config[0].config_id, 'calendar'));
          this.setState({ trendLoading: false });
        }
      );
    }
    this.setState({
      ...newState,
      calendarExpanded: false
    }, () => this.setCalendarInSession());
  }

  updateChartSettings = () => {
    const chartSettings = this.getChartSettingsFromSession();
    if (chartSettings && chartSettings.vector) {
      const setToTrend = chartSettings.vector.chartType === 'TSW' || chartSettings.vector.chartType === 'waterfall_spectrum';
      this.setState({
        ...chartSettings.vector,
        ...(setToTrend && { chartType: 'trend' })
      });
    } else {
      this.setState({
        ...defaultChartSettings.vector,
        chartType: 'trend'
      }, () => this.setChartSettingsInSession());
    }
  }

  getChartSettingsFromSession = () => {
    const chartSettings = sessionStorage.getItem('chartSettings');
    return chartSettings ? JSON.parse(chartSettings) : {};
  }

  setChartSettingsInSession = () => {
    const { amp_type, measurement_type, feature, chartType } = this.state;
    const chartSettings = this.getChartSettingsFromSession();
    chartSettings.vector = { ...chartSettings.vector, amp_type, feature, measurement_type, chartType };
    sessionStorage.setItem('chartSettings', JSON.stringify(chartSettings));
  }

  setCalendarInSession = () => {
    const { days, customDayFrom, customDayTo } = this.state;
    const chartSettings = this.getChartSettingsFromSession();
    chartSettings.calendar = { days, customDayFrom, customDayTo };
    sessionStorage.setItem('chartSettings', JSON.stringify(chartSettings));
  }

  setTimeStampInSession = (timestamp) => {
    const { machine_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    const chartSettings = this.getChartSettingsFromSession();
    if (!chartSettings.timestamp) chartSettings.timestamp = {};
    chartSettings.timestamp[machine_id] = timestamp;
    sessionStorage.setItem('chartSettings', JSON.stringify(chartSettings));
  }

  getAmpTypeAndFeature = () => {
    const { config } = this.state;
    const chartSettings = this.getChartSettingsFromSession();
    if (chartSettings && chartSettings.vector) {
      return chartSettings.vector;
    }
    return {
      amp_type: !_.isEmpty(config) ? config[0].trends_config[0].amp_type : defaultChartSettings.vector.amp_type,
      feature: !_.isEmpty(config) ? config[0].trends_config[0].feature : defaultChartSettings.vector.feature
    };
  }

  updateCalendar = () => {
    const chartSettings = this.getChartSettingsFromSession();
    if (chartSettings && chartSettings.calendar) {
      this.setState({
        ...chartSettings.calendar
      });
    }
  }

  getWaveAndSpecForIdDebounced = _.debounce((selections) => {
    selections.forEach((selection, idx) => {
      this.getWaveformAndSpectrum({ timestamp: selection.x }, idx);
    });
  }, 500);

  getWaveAndSpecForId(selections) {
    this.getWaveAndSpecForIdDebounced(selections);
    this.setState({
      waveAndSpecSelections: [...selections],
    });
  }

  getWaveformAndSpectrum(newparams, idx) {
    const { getTagSpectrum } = this.props.assetDetailsActions;
    const { tags, config } = this.state;
    const { previewConfig } = this.props;
    if (_.isEmpty(tags) || !tags[idx]) return;
    const tag = tags[idx];
    const t_config = config[0].trends_config.find(t => t.tag_id === tag.id);
    const params = {
      feature: t_config.feature,
      amp_type: t_config.amp_type,
      ...newparams
    };
    this.setState({ spectrumLoading: true });
    if (previewConfig) {
      getTagSpectrum(tag.id, params, config[0].config_id, true).then((spectrum) => {
        if (!spectrum) return;
        const spectrumConfig = _.cloneDeep(this.state.config);
        const idx = spectrumConfig[0].spectrums.findIndex(s => s.tag_id && s.tag_id === spectrum.tag_id);
        if (idx > -1) spectrumConfig[0].spectrums[idx] = spectrum;
        else spectrumConfig[0].spectrums.push(spectrum);
        this.setState(() => ({ config: spectrumConfig, spectrumLoading: false }));
      });
    } else getTagSpectrum(tag.id, params, config[0].config_id, true).then(() => this.setState({ spectrumLoading: false }));
    if (params.timestamp) {
      this.setTimeStampInSession(params.timestamp);
    }
  }

  refreshChart() {
    const { config, waveAndSpecSelections } = this.state;
    const { previewConfig } = this.props;
    const params = this.getParamsForChartsData();
    const selections = [];
    new Promise(() => {
      if (this.state.useCompareChartsTags === true) {
        this.useCompareChartsFunction(previewConfig, config);
      } else {
        this.fetchChartData(
          previewConfig && previewConfig[0] && previewConfig[0].trends_config ? { ...params, trends_config: previewConfig[0].trends_config } :
            { ...params, config_id: config[0].config_id },
          false,
          (res) => {
            if (!res) return;
            res.forEach((r, i) => {
              const tag_id = r.tag_id;
              const trendData = r.trend_data;
              const r_key = helpers.getTagMatchKey(r);
              const t_config = config[0].trends_config.find(t => helpers.getTagMatchKey(t) === r_key);
              if (trendData.length > 0) {
                const lastPointTimestamp = trendData[trendData.length - 1].x;
                const params = {
                  tag_id
                };
                if (t_config.amp_type) params.amp_type = t_config.amp_type;
                if (t_config.feature) params.feature = t_config.feature;
                if (waveAndSpecSelections && waveAndSpecSelections[i] && waveAndSpecSelections[i].measurement_id) {
                  const { x, measurement_id } = waveAndSpecSelections[i];
                  const idx = trendData.findIndex(d => d.measurement_id === measurement_id);
                  if (idx > -1) {
                    selections.push(trendData[idx]);
                  }
                  params.timestamp = x;
                } else {
                  params.timestamp = lastPointTimestamp;
                }
                if (this.props.previewConfig) this.getMeasurementData(tag_id, params);
                else this.getMeasurementData(tag_id, params, config.config_id);
              }
            });
          });
      }
    }
    ).then(
      () => this.setSelection(selections)
    );
  }

  setLastHoveredChart = (type) => {
    this.setState({ lastHoveredChart: type });
  };

  toggleShowTabOptions = (selectedTabItem) => {
    this.setState(prevState => ({
      selectedTabItem: selectedTabItem === prevState.selectedTabItem ? '' : selectedTabItem
    }));
  }

  toggleCalendarExpand = () => {
    this.setState(prevState => ({
      calendarExpanded: !prevState.calendarExpanded,
      selectedTabItem: ''
    }));
  }

  toggleSplit = () => {
    this.setState(prevState => ({
      split: !prevState.split
    }));
  }

  setChartType = (chartType) => {
    if (chartType !== this.state.chartType) {
      this.setState({ chartType }, () => this.setChartSettingsInSession());
    }
  }

  closeFeatureDetailsClick = () => {
    this.setState(prevState => ({
      openFeatureDetails: false,
      showCharts: !prevState.showCharts
    }));
  }

  getConfigFromHierarchy = (switchAccountIfRequired = false) => {
    const { sites } = this.props.assetHierarchy;
    const { machine_id, site_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    const configId = Number.parseInt(this.props.match.params.config_id, 10);
    const configList = hierarchyUtils.getConfigList(machine_id, site_id, sites);
    if (!configList || configList.loading) return [];
    const config = _.cloneDeep(configList.filter(item => _.isEqual(item.config_id, configId)));
    if (!_.isEmpty(config)) {
      return config;
    }
    if (switchAccountIfRequired) this.props.assetDetailsActions.validateAndSwitchAccountOnConfig(configId);
    return [];
  }

  getTagFromHierarchy = () => {
    const { sites } = this.props.assetHierarchy;
    const { site_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(this.props.breadcrumb);
    const { config } = this.state;
    let tagsList = [];
    if (config[0] && config[0].machines) {
      config[0].machines.forEach((machineId) => {
        let tagList = hierarchyUtils.getTagsList(machineId, site_id, sites);
        if (_.isEmpty(tagList)) tagList = this.props.assetDetailsActions.getTags(machineId, site_id);
        if (tagList && !tagList.loading && !_.isEmpty(tagList)) tagsList = tagsList.concat(tagList);
      });
    }
    if (_.isEmpty(tagsList) || !config) return [];
    const tagIds = config[0].trends_config.map(t => t.tag_id);
    const tags = [];
    tagIds.forEach((tagId) => {
      const tag = tagsList.find(item => item.id === tagId);
      if (tag) tags.push(tag);
    });
    return tags;
  }

  getParamsForChartsData = () => {
    const { measurement_type, days, customDayFrom, customDayTo } = this.state;
    const params = {};
    const chartSettings = this.getAmpTypeAndFeature();
    params.measurement_type = chartSettings.measurement_type || measurement_type;

    if (days !== 'custom' && days !== undefined) {
      params.days = days;
    } else if (days === 'custom') {
      params.from_timestamp = customDayFrom;
      params.to_timestamp = customDayTo;
    }

    return params;
  }

  getCompareChartsForAllTags = (params, compareTagsParams) => {
    params.discard_outliers = !assetDetailsActions.getChartSettingsFromSession().showOutliers;
    params.version = 2;
    this.props.compareChartsTags.createCompareChartsTags(params).then(
      (res) => {
        this.props.compareChartsTags.recurTillChartTaskCompleted(
          res.task_id,
          res => this.onTaskSuccess(res, params, compareTagsParams),
          this.onTaskError
        );
      },
      (error) => {
        toastr.error(error.message);
      }
    );
  }

  onTaskSuccess = (res, params, compareTagsParams) => {
    this.updateParamsForChartsData(res.result, params, compareTagsParams);
  }

  onTaskError = (error) => {
    toastr.error(error.message);
  }

  updateParamsForChartsData = (result, params, compareTagsParams) => {
    const data = result.response.map(d => ({
      ...d,
      trend_data: formatChartData(
        d.trend_data.data,
        convertTimestringsToDateObjects(d.trend_data.time),
        d.trend_data.outliers ? convertTimestringsToDateObjects(d.trend_data.outliers) : null,
        { measurement_id: d.trend_data.measurement_id, speed: d.trend_data.speed }
      ),
      days: params.days
    }));
    // order data to match the order of trends_config
    // TODO: this is not suitable as a unique identifier, there can be duplicates
    let time_range;
    if (result.time_range) {
      time_range = [
        moment(result.time_range.start_timestamp),
        moment(result.time_range.end_timestamp)
      ];
    }

    let configItem = {};
    if (params.trends_config) {
      configItem = { trends_config: params.trends_config };
      if (configItem !== undefined) {
        const orderedData = configItem.trends_config.map(config =>
          _.find(data, (d) => {
            let sameObject = true;
            if (config.tag_id) {
              sameObject = d.tag_id === config.tag_id;
            }
            if (config.tag_type) {
              sameObject = sameObject && d.tag_type === config.tag_type;
            }
            if (config.feature) {
              sameObject = sameObject && d.feature === config.feature;
            }
            if (config.amp_type) {
              sameObject = sameObject && d.amp_type === config.amp_type;
            }
            if (config.color) {
              sameObject = sameObject && d.color === config.color;
            }
            return sameObject;
          })
        );
        // FIXME: undefined is found if two tags with same tag_id is present.
        const tempFix = orderedData.filter(d => d !== undefined);
        tempFix.forEach((r) => {
          const t_data = params.trends_data.find(item => helpers.getTagMatchKey(item) === helpers.getTagMatchKey(r));
          if (!t_data) params.trends_data.push(r);
        });
        compareTagsParams[0] = params;
        this.setState({ config: compareTagsParams, trendLoading: false }, () => this.getDataForPreviewConfig(tempFix));
      }
    } else {
      const tempFix = data.filter(d => d !== undefined);
      return tempFix;
    }
    return data;
  }

  setLastHoveredChart = (type) => {
    this.setState({ lastHoveredChart: type });
  };

  toggleShowTabOptions = (selectedTabItem) => {
    this.setState(prevState => ({
      selectedTabItem: selectedTabItem === prevState.selectedTabItem ? '' : selectedTabItem
    }));
  }

  toggleCalendarExpand = () => {
    this.setState(prevState => ({
      calendarExpanded: !prevState.calendarExpanded,
      selectedTabItem: ''
    }));
  }

  toggleSplit = () => {
    this.setState(prevState => ({
      split: !prevState.split
    }));
  }

  setChartType = (chartType) => {
    if (chartType !== this.state.chartType) {
      this.setState({ chartType }, () => this.setChartSettingsInSession());
    }
  }

  getTagFromFeature = (feature) => {
    const { tags } = this.state;
    return tags.find(tag => tag.id === feature.tag_id);
  }

  getConfigFromFeature = (feature) => {
    const { config } = this.state;
    const tag = this.getTagFromFeature(feature);
    return config[0].trends_config.find(t_config => t_config.tag_id === tag.id) ? config[0] : undefined;
  }

  closeEditTrend = () => {
    this.setState({
      editTrend: false,
      editTagInfo: false,
      showCharts: true
    });
  }

  openEditTagInfo = () => {
    this.setState({
      editTagInfo: true,
      editTrend: false,
      showCharts: false,
      addingNote: false
    });
  }

  openBaselinePage = () => {
    history.push(`/machines/${this.props.breadcrumb.machine.id}/baseline`);
  }

  groupedConfigByTagInfo = () => {
    const { config, tags } = this.state;
    const groupedConfig = {};
    let spectrum_data = {};
    if (config[0] &&
      !_.isEmpty(config[0].trends_config) &&
      config[0].trends_data &&
      !config[0].trends_data.loading &&
      !Object.keys(config[0].trends_data).includes('error')
    ) {
      config[0].trends_config.forEach((t) => {
        const tag = tags.find(tag => tag.id === t.tag_id);
        if (!tag) return;

        const cfg_match_key = helpers.getTagMatchKey(t);
        const t_data = config[0].trends_data.find(t_data => helpers.getTagMatchKey(t_data) === cfg_match_key);
        if (!t_data) return;

        if (config[0].spectrums && config[0].spectrums.length > 0) {
          spectrum_data = config[0].spectrums.find(s => s.tag_id === t.tag_id);
        }
        const { tag_type, tag_structure_type } = t;
        if (!groupedConfig[tag_type]) {
          groupedConfig[tag_type] = {
            tag_type,
            tag_structure_type,
            configList: [],
            tagsList: [],
            trendData: [],
            spectrums: [],
            hasData: false
          };
        }
        groupedConfig[tag_type].configList.push({ ...t });
        groupedConfig[tag_type].tagsList.push(tag);
        groupedConfig[tag_type].trendData.push(t_data);
        if (t_data && t_data.trend_data && t_data.trend_data.length > 0) {
          groupedConfig[tag_type].hasData = true;
        }
        if (!_.isEmpty(spectrum_data)) groupedConfig[tag_type].spectrums.push(spectrum_data);
      });
    }
    return groupedConfig;
  }

  getNoDataTitle = () => {
    const { customDayFrom, customDayTo, days } = this.props;
    const title = days === 'custom' && customDayFrom ?
      `No data available from ${customDayFrom.toString().slice(0, 15)} to ${customDayTo.toString().slice(0, 15)}`
      : 'No data available';
    return title;
  }

  getSpectrumFeatures = (idx = false) => {
    const { config, tags } = this.state;
    let spectrumFeatures;
    let configSpectrumFeatures;
    if (idx !== false) {
      if (config[0] && config[0].spectrumFeatures) configSpectrumFeatures = config[0].spectrumFeatures;
      if (configSpectrumFeatures && tags[idx] && tags[idx].forcingFrequencies) {
        spectrumFeatures = {
          items: configSpectrumFeatures.items.concat(tags[idx].forcingFrequencies.items)
        };
      } else if (configSpectrumFeatures) {
        spectrumFeatures = configSpectrumFeatures;
      } else if (tags[idx] && tags[idx].forcingFrequencies) {
        spectrumFeatures = tags[idx].forcingFrequencies;
      }
    } else {
      const items = [];
      if (tags[0] && tags[0].forcingFrequencies) items.push(...tags[0].forcingFrequencies.items);
      spectrumFeatures = {
        items
      };
    }
    return spectrumFeatures;
  }

  getLegendDesc = (tags, selection) => {
    const { config } = this.state;
    const tag = tags.find(tag => (tag.id === config[0].trends_config[selection.dataIdx].tag_id));
    let machine_name = '';
    if (config[0].machines.length > 1) {
      machine_name = config[0].trends_config[selection.dataIdx].machine_name || '';
    }
    const tagDesc = tag ? tag.description : '';
    return (
      <ValueText fontSize="13px">
        <b>{machine_name}</b>
        {machine_name !== '' && ' - '}
        {tagDesc}
      </ValueText>
    );
  }

  measurementChartisLoading = () => {
    const { chartType } = this.state;
    switch (chartType) {
      case 'spectrum':
        return this.state.spectrumLoading;
      default:
        return false;
    }
  }

  render() {
    const {
      amp_type,
      addingNote,
      bin_nos,
      calendarExpanded,
      chartType,
      customDayFrom,
      customDayTo,
      config,
      days,
      editDemod,
      feature,
      measurement_type,
      openFeature,
      openFeatureDetails,
      selectedTabItem,
      showCharts,
      split,
      tags,
      lastHoveredChart,
      waveAndSpecSelections,
      componentNode,
      editTrend,
      editTagInfo,
      paginationLoading
    } = this.state;
    const { previewConfig, hierarchyViewPane, borderRadius } = this.props;
    const groupedConfig = this.groupedConfigByTagInfo();
    const showWidth = `calc(100vw - ${hierarchyViewPane.currentWidth + 15}px)`;
    let hasData = false;
    if ((!_.isEmpty(config) &&
      config[0].trends_data &&
      config[0].trends_data.length > 0 &&
      _.some(config[0].trends_data, t => t.trend_data.length > 0)
    )) {
      hasData = true;
    }
    const menuItems = [];
    menuItems.push({
      text: 'refresh',
      onClick: this.refreshChart,
      disabled: !showCharts || previewConfig,
      active: false
    });
    menuItems.push({
      text: 'download',
      onClick: () => helpers.downloadChartData({ config: config[0] }),
      disabled: !showCharts,
      active: false
    });
    menuItems.push({
      text: 'editChart',
      onClick: () => this.setState({ editTrend: true, showCharts: false }),
      disabled: openFeatureDetails || (tags && _.some(tags, tag => tag.type === 'utilization')) || previewConfig,
      active: editTrend || editTagInfo
    });

    const tabItems = [
      {
        text: 'General',
        onClick: () => this.toggleShowTabOptions('General'),
        dropdown: [
          {
            text: 'Calendar',
            onClick: () => this.toggleCalendarExpand(),
            id: 1,
            disabled: !showCharts
          },
          {
            text: 'Refresh',
            onClick: () => this.refreshChart(),
            id: 2,
            disabled: !showCharts
          },
          {
            text: 'Download',
            onClick: () => helpers.downloadChartData({ config: config[0] }),
            id: 3,
            disabled: !hasData || !tags || !showCharts
          }
        ]
      },
      {
        text: 'Edit',
        hidden: (tags && _.some(tags, tag => tag.type === 'utilization')),
        onClick: () => this.toggleShowTabOptions('Edit'),
        dropdown: [
          {
            text: 'Chart',
            selected: editTrend,
            onClick: () => this.setState({ editTrend: true, showCharts: false }),
            disabled: (!showCharts && !editTrend),
            id: 1
          },
          {
            text: 'Set Baseline',
            onClick: this.openBaselinePage,
            disabled: _.some(tags, tag => tag.active === false),
            id: 4
          }
        ]
      }
    ];

    const viewItems = [
      {
        text: 'Tr',
        fullText: 'Trend',
        active: chartType === 'trend',
        onClick: () => this.setChartType('trend'),
        hidden: _.isEmpty(tags) || !showCharts,
        id: 1
      },
      {
        text: 'Sp',
        fullText: 'Spectrum',
        active: chartType === 'spectrum',
        onClick: () => this.setChartType('spectrum'),
        hidden: _.isEmpty(tags) || !showCharts || measurement_type === 0,
        id: 2
      }
    ];

    const disableFilterOptions = openFeature || openFeatureDetails || editDemod || editTrend;
    return (
      <ConfigContainer background="white" borderRadius={borderRadius}>
        <FlexContainer backgroundColor="white" direction="column">
          {!previewConfig && (
            <ChartTab
              tabItems={tabItems}
              selectedTabItem={selectedTabItem}
              toggleShowTabOptions={this.toggleShowTabOptions}
            />
          )}
          <ChartOptions
            disableFilterOptions={disableFilterOptions}
            disableBinFilter={disableFilterOptions}
            addingNote={addingNote}
            bin_nos={bin_nos}
            calendarSelect={this.calendarSelect}
            calendarExpand={this.toggleCalendarExpand}
            calendarExpanded={calendarExpanded}
            chartType={chartType}
            menuItems={menuItems}
            viewItems={viewItems}
            defaultChart={false}
            customDayFrom={customDayFrom}
            customDayTo={customDayTo}
            days={days}
            tag={tags[0]}
            amp_type={amp_type}
            feature={feature}
            measurement_type={measurement_type}
            speedUnits={false}
            split={split}
            toggleSplit={this.toggleSplit}
            refreshChart={this.refreshChart}
          />
          {(config && config[0] && config[0].trends_data && config[0].trends_data.loading || this.state.trendLoading) && <LoadingSvg />}
          {showCharts && !_.isEmpty(config) && !_.isEmpty(tags) && config[0].trends_data && config[0].trends_data.length > 0 && (
            <ChartItemContainer>
              <ChartContainer id="Chart-Container" minimizeHeight={this.props.minimizeHeight && false}>
                {hasData && !this.state.split && chartType === 'trend' && (
                  <Fragment>
                    <ChartMultilineItem
                      config={config[0]}
                      paginationLoading={paginationLoading}
                    />
                  </Fragment>
                )}
                {hasData && (this.state.split || chartType === 'spectrum') && (
                <>
                {Object.keys(groupedConfig).map(key => groupedConfig[key].hasData && (
                  <div key={key}>
                    <SplitableMultilineChart
                      type={amp_type === 'demod' ? 'demod_trend' : 'trend'}
                      multilineTitle="trend"
                      multilineMiniMode={chartType !== 'trend'}
                      multilineYlabel="Multiline"
                      yParameters={groupedConfig[key].tagsList.map(t => t.direction)}
                      multilineChartSize={chartType === 'trend' ? 'Large' : 'Normal'}
                      multilineChartHeight={chartType === 'trend' ? '300px' : '100px'}
                      multiline_data={groupedConfig[key].trendData.map(t => t.trend_data)}
                      ampTypes={groupedConfig[key].configList.map(config => config.amp_type)}
                      yUnits={groupedConfig[key].trendData.map(t => t.units)}
                      colors={chartColors}
                      split={chartType === 'trend'}
                      mapDataToScale={false}
                      points={waveAndSpecSelections}
                      mouseClick={this.getWaveAndSpecForId}
                      tags={groupedConfig[key].tagsList}
                      lastHoveredChart={lastHoveredChart}
                      setLastHoveredChart={this.setLastHoveredChart}
                      paginationLoading={paginationLoading}
                      config={config[0]}
                    />
                    <FlexContainer direction="row" flexWrap="wrap" padding="0 4em" justifyContent="center">
                      {waveAndSpecSelections && !_.isEmpty(waveAndSpecSelections) && waveAndSpecSelections.length <= config[0].trends_config.length &&
                        waveAndSpecSelections.map((selection) => {
                          if (!config[0].trends_config[selection.dataIdx]) return null;
                          const description = this.getLegendDesc(tags, selection);
                          return (
                            <FlexContainer direction="row" alignItems="center">
                              <ColoredCircle color={chartColors[selection.dataIdx]} />
                              {description}
                            </FlexContainer>
                          );
                        })}
                    </FlexContainer>
                  </div>
                ))}
                  {!_.isEmpty(config) && !_.isEmpty(config[0].spectrums) && _.every(config[0].spectrums, s => s.spectrum_data) && !this.state.spectrumLoading && chartType === 'spectrum' && (
                  <>
                  {Object.keys(groupedConfig).filter(key => groupedConfig[key].tag_structure_type === 'vector').map(key => (
                    <>
                    {(this.measurementChartisLoading()) && <LoadingSvg />}
                    <SplitableMultilineChart
                      type={amp_type === 'demod' ? 'demod_spectrum' : 'spectrum'}
                      multilineTitle="multiline spectrum"
                      multilineYlabel="Multiline"
                      yParameters={groupedConfig[key].tagsList.map(t => t.direction)}
                      yUnits={groupedConfig[key].spectrums.map(s => s.amp_units)}
                      colors={chartColors}
                      multiline_data={groupedConfig[key].spectrums.map(s => s.spectrum_data)}
                      ampTypes={groupedConfig[key].configList.map(config => config.amp_type)}
                      split={this.state.split}
                      mapDataToScale={false}
                      tags={groupedConfig[key].tagsList}
                      points={waveAndSpecSelections}
                      spectrums={groupedConfig[key].spectrums}
                      lastHoveredChart={lastHoveredChart}
                      setLastHoveredChart={this.setLastHoveredChart}
                      getSpectrumFeatures={this.getSpectrumFeatures}
                      componentNode={componentNode}
                      config={config[0]}
                    />
                    </>
                  )
                  )}
                  </>
                  )}
                </>
                )}
                {(!hasData) && (
                  <NoDataChart
                    tagType="vibration"
                    height="300px"
                    days={this.props.days}
                    title={this.getNoDataTitle()}
                  />
                )}
              </ChartContainer>
            </ChartItemContainer>
          )}
          {editTrend && (
            <>
              {config[0] && !previewConfig && config[0].machines.length > 1 && (
                <CompareTagsModal
                  close={this.closeEditTrend}
                  isEditMode={!!config}
                  config={config[0]}
                />
              )}
              {config[0] && !previewConfig && config[0].machines.length === 1 && (
                <AddChartModal
                  config={config[0]}
                  close={this.closeEditTrend}
                  defaultChart={false}
                  globalDays={days}
                  trends_config={config[0].trends_config}
                  showWidth={showWidth}
                  refreshConfigMetadata={this.refreshConfigMetadata}
                />
              )}
            </>
          )}
        </FlexContainer>
      </ConfigContainer>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.user,
  rbacPermissions: state.rbacPermissions,
  assetHierarchy: state.assetHierarchyReducer.assetInfo,
  currentAccount: state.currentAccount,
  breadcrumb: state.breadcrumb,
  hierarchyViewPane: state.hierarchyViewPane
});

const mapDispatchToProps = dispatch => ({
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch),
  spectrumFeatureActions: bindActionCreators(spectrumFeatureActions, dispatch),
  compareChartsTags: bindActionCreators(compareChartsTags, dispatch),
  userActions: bindActionCreators(userActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MultilineChartView));
