import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from 'common/styles/colors';

const RibbonItem = styled.button`
  display: flex;
  color: ${props => props.color};
  font-family: 'Petasense Open Sans';
  font-weight: 600;
  font-size: ${props => props.fontSize};
  flex-direction: column;
  padding: ${props => props.padding ? props.padding : '10px 1em'};
  width: ${props => props.width || '100%'};
  border: none;
  ${props => props.borderBottom && 'border-bottom: 1px solid #EFF0EE;'}
  outline: none;
  background-color: white;
  ${props =>
    props.selected
      ? `
      color: ${props.theme.primaryColor};
      svg {
        color: ${props.theme.primaryColor};
        #icon {
          stroke: ${props.theme.primaryColor};
        }
      }
    ` : `
      color: ${colors.black};
      svg {
        color: ${colors.black};
        #icon {
          stroke: ${colors.black};
        }
      }
    `}
  ${props =>
    props.disabled
      ? `
    color: ${props.disabledColor};
    svg {
      color: ${props.disabledColor};
      #icon {
        stroke: ${props.disabledColor};
      }
    }
`
      : `
    &:hover {
      color: ${props.theme.primaryColor};
      svg {
        color: ${props.theme.primaryColor};
        #icon {
          stroke: ${props.theme.primaryColor};
        }
      }
      ${props.hoverable && `
        .dropdown {
          display: block;
        }
      `}
    }
    &:action {
      color: ${props.theme.primaryColor};
    }
  `}
`;

RibbonItem.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  padding: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  disabledColor: PropTypes.string,
};

RibbonItem.defaultProps = {
  fontSize: '12px',
  color: colors.black,
  disabledColor: colors.grey,
};

export default RibbonItem;
