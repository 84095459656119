import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import styled, { withTheme } from 'styled-components';
import * as _ from 'lodash';

import colors from 'common/styles/colors';
import FlexContainer from 'common/components/atoms/FlexContainer';
import NavButton from 'common/components/atoms/NavButton';
import LoadingSvg from 'common/components/atoms/Loading';
import AlertPrompt from 'common/components/organisms/AlertPrompt';
import { updateBreadcrumb } from 'home/Menu/actions/breadcrumb.actions';
import SaveTemplateModal from 'home/Machines/MachineAdd/components/organisms/SaveTemplateModal';
import templatesActions from 'home/Templates/actions/templates.actions';
import machineAddActions from 'home/Machines/MachineAdd/actions/machineAdd.actions';
import * as machineDetailsActions from 'home/Machines/MachineDetails/machineDetails.actions';
import { prepareForcingFrequenciesForMultiStageGearbox } from 'home/Machines/MachineBuilder/utils/utils';

import * as machineBuilderActions from '../actions/machineBuilder.actions';
import * as machineInfoActions from '../../MachineDetails/MachineInfo/actions/machineInfo.actions';
import * as resourceActions from '../../../Resources/actions/resources.action';

import LeftColumn from './atoms/LeftColumn/LeftColumn';
import MainColumn from './atoms/MainColumn/MainColumn';
import NodeList from './organisms/NodeList/NodeList';
import NodeDetails from './organisms/NodeDetails';
import {
  MEASUREMENT_INTERVAL,
  MEASUREMENT_TYPE,
  AGGRESSIVE_SETTINGS
} from '../constants/NodeDetails.constants';

const Page = styled.div`
  height: inherit;
`;

const AbsoluteLoading = styled.div`
  background: #e9e9e9;
  position: absolute;
  align-self: center;
  margin: auto;
  opacity: 0.5;
  width: 100%;
  height: calc(100%);
  overflow: none;
`;

const AbsoluteLoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const ColumnLayout = styled(FlexContainer).attrs({})`
  height: 100%;
`;

class MachineBuilder extends React.Component {
  constructor(props) {
    super(props);
    if (props.setTitle) props.setTitle('Define Asset Hierarchy');

    const areaName = this.props.location.state && this.props.location.state.areaName;
    this.state = {
      treeNodes: [{
        children: [],
        id: 1,
        expanded: true,
        details: {
          asset_type: this.props.mcType,
          state_detection_enabled: false,
          bluetooth_p2p_enabled: false,
          area_name: areaName,
          sub_type: this.props.nonRotatingSubtype,
          overall_interval: MEASUREMENT_INTERVAL.overall,
          high_resolution_interval: MEASUREMENT_INTERVAL.high_resolution,
          full_bandwidth_interval: MEASUREMENT_INTERVAL.full_bandwidth,
          schedule: {},
          num_aggressive_readings: AGGRESSIVE_SETTINGS.num_aggressive_readings,
          aggressive_reading_interval: AGGRESSIVE_SETTINGS.aggressive_reading_interval,
          is_aggressive_measurement: false,
          voltmeter_utilization_enabled: false,
          speed_detection_from_flux_disabled: false
        },
        depth: 1,
        nodeType: 'machine',
        nodeName: '',
        nodeDescription: '',
        assetType: '',
        assetSubtype: ''
      }],
      selectedNodeId: 1,
      highestId: 1,
      selectedNodeDetails: {
        nodeName: '',
        nodeType: 'machine',
        depth: 1,
        details: {
          asset_type: this.props.mcType,
          state_detection_enabled: false,
          bluetooth_p2p_enabled: false,
          full_bandwidth_interval: MEASUREMENT_INTERVAL.full_bandwidth,
          overall_interval: MEASUREMENT_INTERVAL.overall,
          high_resolution_interval: MEASUREMENT_INTERVAL.high_resolution,
          schedule: {},
          num_aggressive_readings: AGGRESSIVE_SETTINGS.num_aggressive_readings,
          aggressive_reading_interval: AGGRESSIVE_SETTINGS.aggressive_reading_interval,
          is_aggressive_measurement: false,
          area_name: areaName,
          sub_type: this.props.nonRotatingSubtype,
          voltmeter_utilization_enabled: false,
          speed_detection_from_flux_disabled: false
        },
        numberOfChildren: 0
      },
      componentsArray: [],
      showSaveAsTemplateModal: false,
      enableSave: true,
      enableSave_info: true,
      editMode: false,
      confirmSave: false,
      confirmAlarmSave: false,
      nodeDetails: {
        nodeName: '',
        nodeType: '',
        details: {},
      },
      deleteComponentDetails: null
    };
  }

  componentDidMount() {
    if (this.props.isInfo) {
      this.populateTreeFromInfo(true);
      this.props.chooseMcType(this.props.info.details.asset_type);
      this.props.chooseNonRotatingSubtype(this.props.info.details.sub_type);
      this.props.updateBreadcrumb({
        selected: {
          name: this.props.info.details.name,
          id: this.props.info.details.id,
          type: this.props.buildType === 'template' ? 'config' : 'machine',
        },
      });
    } else {
      this.props.updateBreadcrumb({
        selected: {
          name: `New ${this.props.buildType}`,
          id: '',
          type: 'config',
        },
      });
      if (this.props.mcType === 'non_rotating') {
        this.insertNewNode();
      }
    }
    this.props.resourceActions.getAreas();
  }

  populateMeasurementSettings = (rootNode) => {
    const details = this.props.info.details;
    const measurement_intervals = details && details.measurement_settings && details.measurement_settings.measurement_intervals;
    if (!_.isEmpty(measurement_intervals)) {
      rootNode.details.high_resolution_interval = measurement_intervals[MEASUREMENT_TYPE.high_resolution];
      rootNode.details.full_bandwidth_interval = measurement_intervals[MEASUREMENT_TYPE.full_bandwidth];
      rootNode.details.overall_interval = measurement_intervals[MEASUREMENT_TYPE.overall];
    }
    rootNode.details.schedule = details && details.measurement_settings && details.measurement_settings.schedule || {};

    const aggressive_settings = details && details.measurement_settings && details.measurement_settings.aggressive_settings || {};
    if (!_.isEmpty(aggressive_settings)) {
      rootNode.details.num_aggressive_readings = aggressive_settings.num_readings;
      rootNode.details.aggressive_reading_interval = aggressive_settings.readings_interval;
      rootNode.details.is_aggressive_measurement = aggressive_settings.is_aggressive_measurement;
    }

    const type = details && details.measurement_settings && details.measurement_settings.type;
    rootNode.details.bluetooth_p2p_enabled = type === 'state_based';
  }

  populateTreeFromInfo = (root = false) => {
    // select root node (machine) on first load by default.
    const nodes = [{
      children: [],
      id: 1,
      expanded: true,
      details: {
        state_detection_enabled: false,
        bluetooth_p2p_enabled: false,
        overall_interval: MEASUREMENT_INTERVAL.overall,
        high_resolution_interval: MEASUREMENT_INTERVAL.high_resolution,
        full_bandwidth_interval: MEASUREMENT_INTERVAL.full_bandwidth,
        schedule: {},
        num_aggressive_readings: AGGRESSIVE_SETTINGS.num_aggressive_readings,
        aggressive_reading_interval: AGGRESSIVE_SETTINGS.aggressive_reading_interval,
        is_aggressive_measurement: false,
        voltmeter_utilization_enabled: false,
        speed_detection_from_flux_disabled: false
      },
      depth: 1,
      nodeType: 'machine',
      nodeName: ''
    }];

    const details = this.props.info.details;
    const rootNode = nodes[0];
    rootNode.id = details.id;
    rootNode.nodeName = details.name;
    rootNode.details.area_name = details.area_name;
    rootNode.details.unique_id = details.unique_id;
    rootNode.details.orientation = details.orientation;
    rootNode.details.health_score_method_preference = details.health_score_method_preference;
    rootNode.details.state_detection_enabled = details.state_detection_enabled;
    rootNode.details.voltmeter_utilization_enabled = details.voltmeter_utilization_enabled;
    rootNode.details.speed_detection_from_flux_disabled = details.speed_detection_from_flux_disabled;
    rootNode.details.state_detection_interval = details.state_detection_interval;
    this.populateMeasurementSettings(rootNode);
    rootNode.details.criticality = details.criticality;
    rootNode.details.foundation = details.foundation;
    rootNode.details.device = details.utilization_sensor;
    rootNode.details.asset_type = details.asset_type;
    rootNode.details.sub_type = details.sub_type;
    rootNode.details.state_detection_threshold_in_gauss = details.state_detection_threshold_in_gauss;
    rootNode.details.asset_off_vibration_threshold = details.asset_off_vibration_threshold;
    if (details.alarm_settings) {
      rootNode.details.alarms_type = details.alarm_settings.type;
      rootNode.details.alarms_limits = [details.alarm_settings.warning_threshold, details.alarm_settings.critical_threshold];
      rootNode.details.warning_limit = details.alarm_settings.warning_threshold;
      rootNode.details.critical_limit = details.alarm_settings.critical_threshold;
    }
    if (details.vibration_demod_settings) {
      rootNode.details.lower_bandpass_cutoff = details.vibration_demod_settings.lower_bandpass_cutoff;
      rootNode.details.upper_bandpass_cutoff = details.vibration_demod_settings.upper_bandpass_cutoff;
    }
    if (this.props.buildType === 'template') {
      rootNode.details.asset_type = details.asset_type;
      rootNode.details.sub_type = details.sub_type;
    }
    rootNode.details.description = details.description;
    rootNode.assetType = details.asset_type;
    rootNode.assetSubtype = details.sub_type;
    rootNode.nodeDescription = details.description;
    let highestId = 0;
    const innerNodes = (children, components, depth = 2) => {
      components.forEach((component) => {
        const child = {
          children: [],
          id: component.id,
          expanded: true,
          details: {},
          depth,
          nodeType: '',
          nodeName: component.name
        };
        highestId += 1;
        child.nodeType = 'component';
        child.details.component_type = component.component_type;
        child.details.locations = component.locations || component.properties.locations;
        child.details.forcing_frequencies = component.forcing_frequencies || [];
        if (!_.isEmpty(component.bearings)) {
          child.details.bearings = {};
          component.bearings.forEach((bearing) => {
            if (!bearing.name) bearing.name = bearing.description;
            if (child.details.bearings[bearing.name]) return;
            child.details.bearings[bearing.id] = {
              id: bearing.properties.model_id,
              description: bearing.description,
              bearing_location_id: bearing.id,
              location_type_id: bearing.location_type_id ? bearing.location_type_id : undefined,
              motes: bearing.motes,
              tx_sensors: bearing.tx_sensors,
              shaft_number: bearing.shaft_number,
              name: bearing.name
            };
            const properties = _.cloneDeep(this.props.machineBuilder.metadata.bearing_properties);
            Object.entries(bearing.properties).forEach(([key, value]) => {
              if (key === 'model_id') return;
              properties[key].value = value;
            });
            child.details.bearings[bearing.id].properties = properties;
          });
        }
        Object.entries(component.properties).forEach(([key, value]) => {
          child.details[key] = value;
        });
        if (component.components) {
          innerNodes(child.children, component.components, depth + 1);
        }
        children.push(child);
      });
    };
    innerNodes(rootNode.children, details.components);
    this.setState({
      treeNodes: nodes,
      highestId
    }, () => {
      if (root) this.selectNode(rootNode.id, rootNode.nodeType);
      else this.selectNode(this.state.selectedNodeId, this.state.selectedNodeDetails.nodeType);
    });
  }

  getComponentsList = () => {
    const components = [];
    const getComponent = (components, nodes = this.state.treeNodes) => {
      nodes.forEach((node) => {
        if (node.nodeType === 'component' && node.details.component_type && !node.newNode) components.push({
          id: node.id,
          name: node.nodeName,
          ...node.details
        });
        getComponent(components, node.children);
      });
    };
    getComponent(components);
    return components;
  }

  reviewMachineStructure = () => {
    const errors = [];
    const reviewStructure = (errors, nodes = this.state.treeNodes) => {
      nodes.forEach((node) => {
        if (!node.nodeName) errors.push('All components should contain a name');
        if (node.nodeType !== 'machine' && node.nodeType !== 'generic' && node.nodeType !== 'component') errors.push('All nodes should have a type');
        if (node.nodeType === 'component' && !node.details.component_type) errors.push('All components should have a type');
        if (node.nodeType === 'machine' && node.children.length === 0) errors.push('Machine should have atleast one child component');
        if (node.nodeType === 'machine' && node.details.alarms_type === 'user_defined' && (parseFloat(node.details.warning_limit) > parseFloat(node.details.critical_limit))) errors.push('Warning Alarm Limit is greater than Critical Limit');
        if (node.nodeType === 'machine' && !node.details.asset_type && this.props.buildType === 'template') errors.push('Template should have a Type');
        if (node.nodeType === 'machine' && !node.details.sub_type && this.props.buildType === 'template') errors.push('Template should have a Subtype');
        if (node.nodeType === 'machine' && !node.details.description && this.props.buildType === 'template') errors.push('Template should have a Description');
        if (node.details.alarms_type === 'iso' && !node.details.alarms_limits) errors.push('ISO alarm limits are missing');
        if (node.details.alarms_type === 'user_defined' && (!node.details.critical_limit || !node.details.warning_limit)) errors.push('Warning and Critical Limits are missing');
        if (node.nodeType === 'machine' && node.details.upper_bandpass_cutoff && !node.details.lower_bandpass_cutoff) errors.push('Lower bandpass cutoff is missing');
        if (node.nodeType === 'machine' && node.details.lower_bandpass_cutoff && !node.details.upper_bandpass_cutoff) errors.push('Upper bandpass cutoff is missing');
        if (node.nodeType === 'machine' && node.details.upper_bandpass_cutoff && node.details.lower_bandpass_cutoff && node.details.upper_bandpass_cutoff <= node.details.lower_bandpass_cutoff) errors.push('Lower bandpass cutoff cannot be greater than Upper bandpass cutoff');

        reviewStructure(errors, node.children);
      });
    };
    reviewStructure(errors);
    return errors;
  }

  verifyMachineStructure = (nodes = this.state.treeNodes) => {
    nodes.forEach((node) => {
      if (!node.nodeName) throw Error('All components should contain a name');
      if (node.nodeType !== 'machine' && node.nodeType !== 'generic' && node.nodeType !== 'component') throw Error('All nodes should have a type');
      if (node.nodeType === 'machine' && node.children.length === 0) throw Error('Machine should have atleast one child component');
      if (node.nodeType === 'machine' && !node.details.asset_type && this.props.buildType === 'template') throw Error('Machine should have a Type');
      if (node.nodeType === 'machine' && !node.details.sub_type && this.props.buildType === 'template') throw Error('Machine should have a Subtype');
      this.verifyMachineStructure(node.children);
    });
  };

  verifyTemplate = () => {
    try {
      this.verifyMachineStructure();
    } catch (e) {
      toastr.error(e.message);
      return;
    }
    if (this.props.buildType === 'template') {
      this.createTemplate();
    } else {
      this.setState({ showSaveAsTemplateModal: true });
    }
  };

  createTemplate = () => {
    const res = this.createTemplateResponse();
    // Set account_id if template is going be created at account level
    if (!this.props.isInfo && !this.props.saveAsGlobal) {
      res.account_id = this.props.accountId;
    }

    if (!res.name) {
      toastr.error('Template name cannot be empty');
      return;
    }
    if (this.props.isInfo && this.props.buildType === 'template') {
      this.props.templatesActions.updateTemplateInfo(this.props.info.details.id, res).then(
        (response) => {
          this.setState({ showSaveAsTemplateModal: false });
          this.setNewNodesFalse();
          toastr.success(response.message);
        },
        (error) => {
          this.setState({ showSaveAsTemplateModal: false });
          toastr.error(error.message);
        }
      );
    } else {
      this.props.machineBuilderActions.createTemplate(res).then(
        (response) => {
          this.setState({ showSaveAsTemplateModal: false });
          toastr.success(response.message);
          if (this.props.buildType === 'template') {
            this.props.history.push('/templates');
          }
        },
        (error) => {
          this.setState({ showSaveAsTemplateModal: false });
          toastr.error(error.message);
        }
      );
    }
  };

  createMachine = () => {
    try {
      this.verifyMachineStructure();
    } catch (e) {
      toastr.error(e.message);
      return;
    }
    const res = this.createResponse();
    if (!res.name) {
      toastr.error('Machine name cannot be empty');
      return;
    }
    this.props.machineBuilderActions.createMachine(res).then(
      response => this.props.history.push(`/machines/${response.machine_id}/info`),
      (error) => {
        toastr.error(error.message);
      }
    );
  };

  createTemplateResponse = () => {
    const result = [{}];
    let position = -1;
    const innerNodes = (res, nodes = this.state.treeNodes, level = 0) => {
      nodes.forEach((node, idx) => {
        if (!node.nodeName || !node.nodeType) return;
        res[idx].name = node.nodeName;
        if (node.nodeType === 'component') {
          res[idx].component_type = node.details.component_type;
          res[idx].locations = node.details.locations ?
            node.details.locations.filter(location => !location.location_type_id) : [];
          let forcingFrequencies = node.details.forcing_frequencies;
          if (!forcingFrequencies) {
            if (node.details.component_type === 'multi_stage_gearbox') {
              const numberOfShafts = node.details.no_of_shafts;
              const ff = this.props.forcingFrequencyConstants[node.details.component_type] || [];
              forcingFrequencies = prepareForcingFrequenciesForMultiStageGearbox(ff, numberOfShafts);
              forcingFrequencies = forcingFrequencies.map((ff, index) => ({ ...ff, is_default: true, id: index }));
            } else {
              forcingFrequencies = this.props.forcingFrequencyConstants[node.details.component_type] || [];
              forcingFrequencies = forcingFrequencies.map((ff, index) => ({ ...ff, is_default: true, id: index }));
            }
          }
          res[idx].forcing_frequencies = forcingFrequencies;
        } else {
          res[idx].component_type = node.nodeType;
        }
        res[idx].level = level;
        res[idx].position = position;
        position++;
        if (level !== 0) res[idx].properties = {};
        if (level === 0) res[idx].definition = {};
        if (node.details.bearings) {
          res[idx].bearing_models = {};
          Object.entries(node.details.bearings).forEach(([loc, bearing]) => {
            res[idx].bearing_models[loc] = bearing.id;
            if (!res[idx].locations) res[idx].locations = [];
            if (!bearing.location_type_id) {
              res[idx].locations.push({
                description: bearing.description,
                is_bearing: true,
                name: loc,
                bearing_model_id: bearing.id ? bearing.id : undefined,
              });
            }
          });
        }
        if (level === 0) {
          if (this.props.buildType === 'template') {
            res[idx].description = node.details.description;
            res[idx].asset_type = node.details.asset_type.toLowerCase();
            res[idx].sub_type = node.details.sub_type;
          } else {
            res[idx].description = node.nodeDescription;
            res[idx].asset_type = node.assetType.toLowerCase();
            res[idx].sub_type = node.assetSubtype;
          }
        }
        if (level === 0) {
          const isISO = node.details.alarms_type === 'iso';
          const warning_threshold = isISO ? node.details.alarms_limits[0] : node.details.warning_limit;
          const critical_threshold = isISO ? node.details.alarms_limits[1] : node.details.critical_limit;
          if (node.details.alarms_type && warning_threshold && critical_threshold) {
            res[idx].definition.alarm_settings = {
              ...res[idx].definition.alarm_settings,
              type: node.details.alarms_type,
              warning_threshold,
              critical_threshold
            };
          }
          if (node.details.lower_bandpass_cutoff && node.details.upper_bandpass_cutoff) {
            res[idx].definition.vibration_demod_settings = {
              ...res[idx].definition.vibration_demod_settings,
              lower_bandpass_cutoff: node.details.lower_bandpass_cutoff,
              upper_bandpass_cutoff: node.details.upper_bandpass_cutoff
            };
          }
        }
        Object.entries(node.details).forEach(([key, value]) => {
          if (key === 'component_type' || key === 'bearings' || key === 'locations' || key === 'forcing_frequencies') return;
          if (level === 0) {
            if (!_.includes(['alarms_type', 'alarms_limits', 'warning_limit', 'critical_limit', 'lower_bandpass_cutoff', 'upper_bandpass_cutoff'], key)) {
              res[idx].definition[key] = value;
            }
          } else {
            res[idx].properties[key] = value;
          }
        });
        res[idx].components = node.children.map(() => ({}));
        innerNodes(res[idx].components, node.children, level + 1);
        res[idx].components = res[idx].components.filter(n => !_.isEmpty(n));
        if (res[idx].components.length === 0) {
          delete res[idx].components;
        }
      });
    };
    innerNodes(result);
    const components = _.cloneDeep(result[0].components);
    result[0].definition = {
      ...result[0].definition,
      components,
    };
    delete result[0].components;
    return result[0];
  };

  createResponse = () => {
    const result = [{}];
    let position = -1;
    const innerNodes = (res, nodes = this.state.treeNodes, level = 0) => {
      nodes.forEach((node, idx) => {
        if (!node.nodeName || !node.nodeType) return;
        res[idx].name = node.nodeName;
        if (node.nodeType === 'component') {
          res[idx].component_type = node.details.component_type;
          res[idx].locations = node.details.locations.filter(location => !location.location_type_id);
          let forcingFrequencies = node.details.forcing_frequencies;
          if (!forcingFrequencies) {
            if (node.details.component_type === 'multi_stage_gearbox') {
              const numberOfShafts = node.details.no_of_shafts;
              const ff = this.props.forcingFrequencyConstants[node.details.component_type] || [];
              forcingFrequencies = prepareForcingFrequenciesForMultiStageGearbox(ff, numberOfShafts);
              forcingFrequencies = forcingFrequencies.map((ff, index) => ({ ...ff, is_default: true, id: index }));
            } else {
              forcingFrequencies = this.props.forcingFrequencyConstants[node.details.component_type] || [];
              forcingFrequencies = forcingFrequencies.map((ff, index) => ({ ...ff, is_default: true, id: index }));
            }
          }
          res[idx].forcing_frequencies = forcingFrequencies;
        } else {
          res[idx].component_type = node.nodeType;
        }
        res[idx].level = level;
        res[idx].position = position;
        position++;
        if (level !== 0) res[idx].properties = {};
        if (node.details.bearings) {
          res[idx].bearing_models = {};
          Object.entries(node.details.bearings).forEach(([loc, bearing]) => {
            res[idx].bearing_models[loc] = bearing.id;
            if (!res[idx].locations) res[idx].locations = [];
            if (!bearing.location_type_id) {
              res[idx].locations.push({
                description: bearing.description,
                is_bearing: true,
                name: loc,
                bearing_model_id: bearing.id ? bearing.id : undefined,
                shaft_number: bearing.shaft_number,
              });
            }
          });
        }
        if (level === 0) {
          const isISO = node.details.alarms_type === 'iso';
          const warning_threshold = isISO ? node.details.alarms_limits[0] : node.details.warning_limit;
          const critical_threshold = isISO ? node.details.alarms_limits[1] : node.details.critical_limit;
          if (node.details.alarms_type && warning_threshold && critical_threshold) {
            res[idx].alarm_settings = {
              ...res[idx].alarm_settings,
              type: node.details.alarms_type,
              warning_threshold,
              critical_threshold
            };
          }

          if (node.details.lower_bandpass_cutoff && node.details.upper_bandpass_cutoff) {
            res[idx].vibration_demod_settings = {
              ...res[idx].vibration_demod_settings,
              lower_bandpass_cutoff: node.details.lower_bandpass_cutoff,
              upper_bandpass_cutoff: node.details.upper_bandpass_cutoff
            };
          }
        }
        Object.entries(node.details).forEach(([key, value]) => {
          if (key === 'component_type' || key === 'bearings' || key === 'locations' || key === 'forcing_frequencies') return;
          if (level === 0) {
            if (!_.includes(['alarms_type', 'alarms_limits', 'warning_limit', 'critical_limit', 'lower_bandpass_cutoff', 'upper_bandpass_cutoff'], key)) {
              res[idx][key] = value;
            }
          } else {
            res[idx].properties[key] = value;
          }
        });
        res[idx].components = node.children.map(() => ({}));
        innerNodes(res[idx].components, node.children, level + 1);
        res[idx].components = res[idx].components.filter(n => !_.isEmpty(n));
      });
    };
    innerNodes(result);
    return result[0];
  };

  createComponent = () => {
    const nodes = this.state.treeNodes[0].children;
    const componentsPositionInfo = this.componentsPositionInfo(nodes);
    const machineId = this.props.info.details.id;
    try {
      this.verifyMachineStructure();
    } catch (e) {
      toastr.error(e.message);
      return;
    }
    const innerNodes = (nodes, level = 1) => {
      nodes.forEach((node, idx) => {
        if (!node.newNode) innerNodes(node.children, level + 1);
        else {
          const positionInfo = componentsPositionInfo.find(r => r.id === node.id);
          const res = this.createComponentResponse(node, level, positionInfo.position, positionInfo.parent_id);
          node.newNode = false;
          if (!_.isEmpty(res)) {
            this.props.machineInfoActions.createComponent(machineId, res, idx).then(
              (component) => {
                this.populateTreeFromInfo();
                toastr.success('Component created');
                this.props.machineDetailsActions.getMachineCompletionStatus(this.props.info.details.id);
                this.setState({ enableSave_info: false });
                this.selectNode(component.component.id, node.nodeType);
              },
              () => {
                node.newNode = true;
              }
            );
          }
        }
      });
    };
    innerNodes(nodes);
  }

  createComponentResponse = (node, level, position, parent_id = null) => {
    const result = [{}];
    const innerNodes = (res, nodes, level) => {
      nodes.forEach((node, idx) => {
        if (!node.nodeName || !node.nodeType) return;
        res[idx].name = node.nodeName;
        if (node.nodeType === 'component') {
          res[idx].component_type = node.details.component_type;
          res[idx].locations = node.details.locations.filter(location => !location.location_type_id);
          let forcingFrequencies = [];
          if (node.details.component_type === 'multi_stage_gearbox') {
            const numberOfShafts = node.details.no_of_shafts;
            const ff = this.props.forcingFrequencyConstants[node.details.component_type] || [];
            forcingFrequencies = prepareForcingFrequenciesForMultiStageGearbox(ff, numberOfShafts);
            res[idx].forcing_frequencies = forcingFrequencies.map((ff, index) => ({ ...ff, is_default: true, id: index }));
          } else {
            forcingFrequencies = this.props.forcingFrequencyConstants[node.details.component_type] || [];
            res[idx].forcing_frequencies = forcingFrequencies.map((ff, index) => ({ ...ff, is_default: true, id: index }));
          }
        } else {
          res[idx].component_type = node.nodeType;
        }
        res[idx].level = level;
        res[idx].position = position;
        position++;
        res[idx].parent_id = parent_id;
        res[idx].properties = {};
        if (node.details.bearings) {
          res[idx].bearing_models = {};
          Object.entries(node.details.bearings).forEach(([loc, bearing]) => {
            res[idx].bearing_models[loc] = bearing.id;
            if (!res[idx].locations) res[idx].locations = [];
            if (!bearing.location_type_id) {
              res[idx].locations.push({
                description: bearing.description,
                is_bearing: true,
                name: loc,
                bearing_model_id: bearing.id ? bearing.id : undefined,
                shaft_number: bearing.shaft_number,
              });
            }
          });
        }
        Object.entries(node.details).forEach(([key, value]) => {
          if (key === 'component_type' || key === 'bearings' || key === 'locations') return;
          res[idx].properties[key] = value;
        });
        res[idx].components = node.children.map(() => ({}));
        innerNodes(res[idx].components, node.children, level + 1);
        res[idx].components = res[idx].components.filter(n => !_.isEmpty(n));
      });
    };
    innerNodes(result, [node], level, position, parent_id);
    return result[0];
  };

  insertNewNode = (nodeId = 1, nodeType = 'machine', nodes = this.state.treeNodes, depth = 1) => {
    // Add a new child node to this nodeId Node.
    nodes.forEach((node) => {
      if (node.id === nodeId && node.nodeType === nodeType) {
        const { highestId } = this.state;
        const newChildren = [
          ...node.children,
          {
            id: highestId + 1,
            children: [],
            expanded: true,
            depth: depth + 1,
            details: {},
            nodeType: '',
            nodeName: '',
            ...(this.props.isInfo && { newNode: true })
          }
        ];
        // NOTE: updating inplace via reference
        node.children = newChildren;
        this.setState(prevState => ({
          highestId: prevState.highestId + 1,
          treeNodes: this.state.treeNodes
        }));
        this.selectNode(this.state.selectedNodeId, this.state.selectedNodeDetails.nodeType);
        return;
      }
      this.insertNewNode(nodeId, nodeType, node.children, depth + 1);
    });
  };

  deleteNode = (node) => {
    if (this.state.editMode && !node.newNode && this.props.buildType === 'machine') {
      this.setState({ deleteComponentDetails: node });
      return;
    }
    this.removeNode(node.id, node.nodeType);
    if (this.state.selectedNodeId === 1) {
      const componentsArray = this.createComponentsArray();
      this.setState({
        componentsArray
      });
    }
    if (this.state.editMode && !node.newNode && this.props.buildType === 'template') this.verifyTemplate();
  };

  deleteComponent = () => {
    const node = this.state.deleteComponentDetails;
    if (!node.id || !node.nodeType) return;
    this.removeNode(node.id, node.nodeType);
    if (this.state.selectedNodeId === 1) {
      const componentsArray = this.createComponentsArray();
      this.setState({
        componentsArray
      });
    }
    this.props.machineInfoActions.deleteComponent(node.id).then(
      () => { toastr.success('Component deleted'); },
      () => { this.populateTreeFromInfo(); }
    );
    this.setState({ deleteComponentDetails: null });
  }

  removeNode = (nodeId, nodeType, nodes = this.state.treeNodes, parentNode = null) => {
    // remove the node (and all it's children) with given nodeId
    if (nodeType === 'machine') {
      throw Error('Cannot remove root Machine Node!');
    }
    nodes.forEach((node, idx) => {
      if (node.id === nodeId && node.nodeType === nodeType) {
        // NOTE: updating inplace via reference
        nodes.splice(idx, 1);
        this.setState({
          treeNodes: this.state.treeNodes
        });
        if (this.state.selectedNodeId < nodeId) return;
        this.selectNode(parentNode.id, parentNode.nodeType);
        return;
      }
      if (node.children.length) this.removeNode(nodeId, nodeType, node.children, node);
    });
  };

  toggleNodeExpand = (nodeId, nodeType, nodes = this.state.treeNodes) => {
    nodes.forEach((node) => {
      if (node.id === nodeId && node.nodeType === nodeType) {
        node.expanded = !node.expanded;
        this.setState({
          treeNodes: this.state.treeNodes
        });
        return;
      }
      this.toggleNodeExpand(nodeId, nodeType, node.children);
    });
  };

  selectNode = (nodeId, nodeType, nodes = this.state.treeNodes) => {
    nodes.forEach((node) => {
      if (node.id === nodeId && node.nodeType === nodeType) {
        // copy required details
        this.setState({
          selectedNodeId: nodeId,
          selectedNodeDetails: {
            nodeName: node.nodeName,
            nodeType: node.nodeType,
            depth: node.depth,
            details: _.cloneDeep(node.details),
            numberOfChildren: node.children.length,
            newNode: node.newNode
          }
        });
        if (node.nodeType === 'machine') {
          const componentsArray = this.createComponentsArray();
          this.setState({
            componentsArray
          });
        } else {
          this.setState({
            componentsArray: []
          });
        }
        return;
      }
      this.selectNode(nodeId, nodeType, node.children);
    });
  };

  saveNodeDetails = (nodeName, nodeType, details, nodeId, onlyLocalSave = false, newNode = false, override_tag_limits = false) => {
    // locally updating details in state
    const saveInnerNodes = (nodes = this.state.treeNodes) => {
      nodes.forEach((node) => {
        if (node.id === nodeId) {
          const clonedDetails = _.cloneDeep(details);
          node.nodeName = nodeName;
          node.nodeType = nodeType;
          node.details = clonedDetails;
          this.setState({
            treeNodes: this.state.treeNodes,
            selectedNodeDetails: {
              ...this.state.selectedNodeDetails,
              nodeName,
              nodeType,
              details: clonedDetails
            }
          });
          return;
        }
        saveInnerNodes(node.children);
      });
    };
    saveInnerNodes();
    if (this.props.isInfo && this.props.buildType === 'machine' && !onlyLocalSave && !newNode) {
      const storeDetails = this.props.info.details;
      if (nodeType === 'machine') {
        const res = {};

        const isISO = details.alarms_type === 'iso';
        const warning_threshold = isISO ? details.alarms_limits[0] : details.warning_limit;
        const critical_threshold = isISO ? details.alarms_limits[1] : details.critical_limit;
        const high_resolution_interval = details.high_resolution_interval;
        const overall_interval = details.overall_interval;
        const full_bandwidth_interval = details.full_bandwidth_interval;
        const schedule = details.schedule;
        const aggressive_settings = {
          num_readings: details.num_aggressive_readings,
          readings_interval: details.aggressive_reading_interval,
          is_aggressive_measurement: details.is_aggressive_measurement
        };

        if (!storeDetails.alarm_settings || (storeDetails.alarm_settings && (storeDetails.alarm_settings.type !== details.alarms_type
          || storeDetails.alarm_settings.warning_threshold !== warning_threshold
          || storeDetails.alarm_settings.critical_threshold !== critical_threshold
        ))) {
          if (details.alarms_type && warning_threshold && critical_threshold) {
            res.alarm_settings = {
              ...res.alarm_settings,
              type: details.alarms_type,
              warning_threshold,
              critical_threshold
            };
            res.override_tag_limits = override_tag_limits;
          } else if (storeDetails.alarm_settings) res.alarm_settings = null;
        }

        if (_.isEmpty(storeDetails.measurement_settings) || (!_.isEmpty(storeDetails.measurement_settings) && (storeDetails.bluetooth_p2p_enabled !== details.bluetooth_p2p_enabled
          || storeDetails.measurement_settings.measurement_intervals[MEASUREMENT_TYPE.high_resolution] !== high_resolution_interval
          || storeDetails.measurement_settings.measurement_intervals[MEASUREMENT_TYPE.overall] !== overall_interval
          || storeDetails.measurement_settings.measurement_intervals[MEASUREMENT_TYPE.full_bandwidth] !== full_bandwidth_interval
          || !_.isEqual(storeDetails.schedule, schedule)
          || !_.isEqual(storeDetails.aggressive_settings, aggressive_settings)
        ))) {
          if (high_resolution_interval && full_bandwidth_interval && overall_interval) {
            res.measurement_settings = {
              ...res.measurement_settings,
              type: details.bluetooth_p2p_enabled ? 'state_based' : 'schedule_based',
              measurement_intervals: {
                [MEASUREMENT_TYPE.overall]: overall_interval,
                [MEASUREMENT_TYPE.full_bandwidth]: full_bandwidth_interval,
                [MEASUREMENT_TYPE.high_resolution]: high_resolution_interval
              },
              schedule,
              aggressive_settings
            };
          }
        }

        if (!storeDetails.vibration_demod_settings || (storeDetails.vibration_demod_settings && (storeDetails.vibration_demod_settings.lower_bandpass_cutoff !== details.lower_bandpass_cutoff
          || storeDetails.vibration_demod_settings.upper_bandpass_cutoff !== details.upper_bandpass_cutoff))) {
          if (details.lower_bandpass_cutoff && details.upper_bandpass_cutoff) {
            res.vibration_demod_settings = {
              lower_bandpass_cutoff: details.lower_bandpass_cutoff,
              upper_bandpass_cutoff: details.upper_bandpass_cutoff
            };
          } else if (storeDetails.vibration_demod_settings) res.vibration_demod_settings = null;
        }

        Object.entries(details).forEach(([key, value]) => {
          if (!_.includes(['alarms_type', 'alarms_limits', 'warning_limit', 'critical_limit', 'lower_bandpass_cutoff', 'upper_bandpass_cutoff', 'device'], key) && (storeDetails[key] !== value)) res[key] = value;
        });
        res.name = nodeName;
        this.props.machineInfoActions.updateMachineInfo(nodeId, res).then(
          () => {
            toastr.success('Machine info updated');
            this.props.machineDetailsActions.getMachineCompletionStatus(this.props.info.details.id);
          },
          () => { this.populateTreeFromInfo(); },
        );
      } else if (nodeType === 'component' || nodeType === 'generic') {
        const innerStoreDetails = this.findNode(nodeId, storeDetails.components);
        if (!innerStoreDetails) return;
        if (!_.isEmpty(details.bearings)) {
          const bearingDescriptions = [];
          if (innerStoreDetails) {
            innerStoreDetails.bearings.forEach((bearing) => {
              bearingDescriptions.push(bearing.description);
              if (details.bearings[bearing.description]) {
                if (details.bearings[bearing.description].id !== bearing.properties.model_id) {
                  this.props.machineInfoActions.associateBearing(nodeId, {
                    bearing_model_id: details.bearings[bearing.description].id,
                    bearing_location_id: details.bearings[bearing.description].bearing_location_id
                  }, details.bearings[bearing.description].properties);
                }
              }
            });
          }
          Object.entries(details.bearings).forEach(([key, value]) => {
            if (bearingDescriptions.indexOf(key) === -1 && value.id) {
              this.props.machineInfoActions.associateBearing(nodeId, {
                bearing_model_id: value.id,
                bearing_location_id: value.bearing_location_id
              }, value.properties);
            }
          });
        }
        const res = {};
        res.name = nodeName;
        Object.entries(details).forEach(([key, value]) => {
          if (key === 'component_type' || key === 'bearings') return;
          if (key === 'speed_bins_config' && value) {
            value.speed_bins = _.map(value.speed_bins, config => _.omit(config, ['range_text']));
          }
          if (innerStoreDetails.properties[key] !== value) res[key] = value;
        });
        this.props.machineInfoActions.updateComponentInfo(nodeId, res).then(
          () => {
            toastr.success('Component info updated');
            this.props.machineDetailsActions.getMachineCompletionStatus(this.props.info.details.id);
          },
          () => { this.populateTreeFromInfo(); }
        );
      }
    }
  };

  saveTemplateDetails = (key, value) => {
    this.setState((prevState) => {
      const tmpTreeNodes = _.cloneDeep(prevState.treeNodes);
      tmpTreeNodes[0][key] = value;
      return {
        treeNodes: tmpTreeNodes,
      };
    });
  }

  findNode = (nodeId, components) => {
    let node = null;
    const innerNodes = (components) => {
      components.forEach((component) => {
        if (component.id === nodeId) {
          node = component;
        } else innerNodes(component.components);
      });
    };
    innerNodes(components);
    return node;
  };

  createComponentsArray = () => {
    let position = 1;
    const componentsArray = [];
    const populateArray = (nodes = this.state.treeNodes) => {
      nodes.forEach((node) => {
        if (node.children.length) populateArray(node.children);
        else if (node.nodeType === 'component' && node.details.component_type) {
          componentsArray.push({ component_type: node.details.component_type, position });
          position++;
        }
      });
    };
    populateArray();
    return componentsArray;
  };

  deleteMachine = () => {
    if (!this.props.isInfo) return;
    const machineId = this.props.info.details.id;
    this.props.machineInfoActions.deleteMachine(machineId);
  };

  setEnableSave = (enableSave) => {
    this.setState({ enableSave });
  }

  setEnableSave_info = (enableSave_info, nodeDetails) => {
    const details = _.cloneDeep(nodeDetails);
    this.setState({
      enableSave_info,
      nodeDetails: details,
    });
  }

  updateSchedule = () => {
    const { selectedNodeId } = this.state;
    const storeDetails = this.props.info.details;

    const res = {};
    res.measurement_settings = {
      ...res.measurement_settings,
      type: storeDetails.bluetooth_p2p_enabled ? 'state_based' : 'schedule_based',
      measurement_intervals: {
        [MEASUREMENT_TYPE.overall]: storeDetails.measurement_settings.measurement_intervals[MEASUREMENT_TYPE.overall],
        [MEASUREMENT_TYPE.full_bandwidth]: storeDetails.measurement_settings.measurement_intervals[MEASUREMENT_TYPE.full_bandwidth],
        [MEASUREMENT_TYPE.high_resolution]: storeDetails.measurement_settings.measurement_intervals[MEASUREMENT_TYPE.high_resolution]
      },
      schedule: storeDetails.measurement_settings.schedule
    };
    this.props.machineInfoActions.updateMachineInfo(selectedNodeId, res).then(
      () => {
        toastr.success('Updated measurement schedule');
      },
      (err) => {
        toastr.error(err);
      }
    );
  }

  saveForcingFrequencies = (forcingFrequencies) => {
    const componentIdForcingFrequencyMap = {};
    forcingFrequencies.forEach((ff) => {
      componentIdForcingFrequencyMap[ff.component_id] = ff.forcing_frequencies;
    });

    const clonedTreeNodes = _.cloneDeep(this.state.treeNodes);
    const updateTreeNodes = (nodes = clonedTreeNodes) => {
      nodes.forEach((node) => {
        if (node.nodeType === 'component') {
          const ffs = componentIdForcingFrequencyMap[node.id];
          if (ffs) {
            node.details.forcing_frequencies = ffs;
          }
        }
        updateTreeNodes(node.children);
      });
    };

    updateTreeNodes();
    this.setState({
      treeNodes: clonedTreeNodes
    }, () => {
      if (this.props.isInfo) {
        if (this.props.buildType === 'template') {
          this.createTemplate();
        } else {
          const res = { forcing_frequencies: forcingFrequencies };
          const { selectedNodeId } = this.state;
          this.props.machineInfoActions.updateMachineInfo(selectedNodeId, res).then(
            () => {
              toastr.success('Asset Forcing Frequencies Updated');
            },
            (err) => {
              toastr.error(err);
              this.populateTreeFromInfo();
            }
          );
        }
      }
    });
  }

  editIconClick = () => {
    if (this.state.editMode) this.populateTreeFromInfo();
    this.setState(prevState => ({
      editMode: !prevState.editMode
    }));
  }

  reorderTree = (oldIndex, newIndex, nodeType, nodeId, nodes = this.state.treeNodes) => {
    nodes.forEach((node) => {
      if (node.nodeType === nodeType && node.id === nodeId) {
        const el = nodes.splice(oldIndex, 1);
        nodes.splice(newIndex, 0, el[0]);
        this.setState({
          treeNodes: this.state.treeNodes,
          enableSave_info: true,
          reorderComponents: true
        });
        throw null;
      }
      this.reorderTree(oldIndex, newIndex, nodeType, nodeId, node.children);
    });
  };

  reorderComponents = () => {
    const { treeNodes } = this.state;
    const machineId = this.props.info.details.id;
    const components = this.componentsPositionInfo(treeNodes[0].children, true);
    this.props.machineInfoActions.reorderComponents(machineId, components, treeNodes[0].children).then(
      () => { toastr.success('Components reordered'); },
      () => { this.populateTreeFromInfo(); }
    );
  }

  componentsPositionInfo = (nodes, ignoreNewNodes = false, components = [], parent_id = null) => {
    nodes.forEach((node) => {
      if (ignoreNewNodes && node.newNode) return;
      components.push({ id: node.id, parent_id, position: components.length });
      this.componentsPositionInfo(node.children, ignoreNewNodes, components, node.id);
    });
    return components;
  }

  hasNewNodes = () => {
    const { treeNodes } = this.state;
    let hasNewNodes = false;
    const innerNodes = (nodes) => {
      nodes.forEach((node) => {
        if (node.newNode) {
          hasNewNodes = true;
          return;
        }
        innerNodes(node.children);
      });
    };
    innerNodes(treeNodes);
    return hasNewNodes;
  }

  setNewNodesFalse = () => {
    const nodes = _.cloneDeep(this.state.treeNodes);
    const innerNodes = (nodes) => {
      nodes.forEach((node) => {
        if (node.newNode) {
          node.newNode = false;
        }
        innerNodes(node.children);
      });
    };
    innerNodes(nodes);
    this.setState({ treeNodes: nodes });
  }

  onProceed = () => {
    const { selectedNodeId } = this.state;
    if (this.state.reorderComponents) {
      this.setState({ reorderComponents: false, enableSave_info: false });
    }
    if (this.state.nodeDetails) {
      this.saveNodeDetails(
        this.state.nodeDetails.nodeName,
        this.state.nodeDetails.nodeType,
        this.state.nodeDetails.details,
        selectedNodeId,
        true,
      );
    }
    this.verifyTemplate();
    this.closeSavePrompt();
  }

  onAlarmChangeProceed = (override) => {
    const { selectedNodeId } = this.state;
    this.saveNodeDetails(
      this.state.nodeDetails.nodeName,
      this.state.nodeDetails.nodeType,
      this.state.nodeDetails.details,
      selectedNodeId,
      false,
      false,
      override
    );
    this.closeAlarmPrompt();
  }

  onSpeedBinChangeProceed = () => {
    const { selectedNodeId } = this.state;
    this.saveNodeDetails(
      this.state.nodeDetails.nodeName,
      this.state.nodeDetails.nodeType,
      this.state.nodeDetails.details,
      selectedNodeId,
    );
    this.closeSpeedBinPrompt();
  }

  onClickSave = () => {
    const { selectedNodeDetails, selectedNodeId, nodeDetails } = this.state;
    if (this.props.buildType === 'template') {
      if (this.props.isInfo) {
        this.openSavePrompt();
      } else {
        this.verifyTemplate();
      }
    } else if (this.props.isInfo) {
      if (this.state.reorderComponents) {
        this.reorderComponents();
        this.setState({ reorderComponents: false, enableSave_info: false });
      }

      this.createComponent();
      if (selectedNodeDetails.nodeType === 'machine' && nodeDetails && nodeDetails.details) {
        const {
          details: {
            alarms_type,
            alarms_limits,
            warning_limit,
            critical_limit
          }
        } = this.state.nodeDetails;
        const warning_threshold = alarms_type === 'iso' ? alarms_limits[0] : warning_limit;
        const critical_threshold = alarms_type === 'iso' ? alarms_limits[1] : critical_limit;
        const updated_alarm_settings = {
          type: alarms_type,
          warning_threshold,
          critical_threshold
        };
        if (
          this.props.info.details.alarm_settings &&
          warning_threshold &&
          critical_threshold &&
          alarms_type &&
          !_.isEqual(this.props.info.details.alarm_settings, updated_alarm_settings)
        ) {
          this.openAlarmPrompt();
          return;
        }
      }
      if (selectedNodeDetails.nodeType === 'component' && !selectedNodeDetails.newNode) {
        const { details } = this.state.nodeDetails;
        const { selectedNodeId } = this.state;
        const storeDetails = this.props.info.details;
        const innerStoreDetails = this.findNode(selectedNodeId, storeDetails.components);
        if (details.speed_bins_config) {
          details.speed_bins_config.speed_bins = _.map(
            details.speed_bins_config.speed_bins,
            config => _.omit(config, ['range_text'])
          );
        }
        if (
          innerStoreDetails.properties.vfd !== details.vfd ||
          !_.isEqual(innerStoreDetails.properties.speed_bins_config, details.speed_bins_config)
        ) {
          this.openSpeedBinPrompt();
          return;
        }
      }
      if (this.state.nodeDetails) {
        this.saveNodeDetails(
          this.state.nodeDetails.nodeName,
          this.state.nodeDetails.nodeType,
          this.state.nodeDetails.details,
          selectedNodeId,
        );
      }
    } else {
      this.createMachine();
    }
  }

  openSavePrompt = () => this.setState({ confirmSave: true })

  closeSavePrompt = () => this.setState({ confirmSave: false })

  getComponentTypes = (nodes = this.state.treeNodes) => {
    const res = [];
    nodes.forEach((node) => {
      node.children.forEach((child) => {
        if ((child.details.component_type) && (child.nodeType === 'component')) {
          res.push(child.details.component_type);
        }
      });
      res.push(...this.getComponentTypes(node.children));
    });

    return res;
  };

  openAlarmPrompt = () => this.setState({ confirmAlarmSave: true })

  closeAlarmPrompt = () => this.setState({ confirmAlarmSave: false })

  openSpeedBinPrompt = () => this.setState({ confirmSpeedBinChange: true })

  closeSpeedBinPrompt = () => this.setState({ confirmSpeedBinChange: false })

  getMissingFields = (nodeId, nodeType) => {
    const { completionStatus } = this.props;
    if (completionStatus && completionStatus.data && completionStatus.data.setup_info && completionStatus.data.setup_info[0]) {
      if (nodeType === 'component') {
        const components = completionStatus.data.setup_info[0].components;
        const component = components.find(c => c.id === nodeId);
        if (component) return component.missing_fields;
      }
      if (nodeType === 'machine') {
        const machine = completionStatus.data.setup_info[0].machine;
        if (machine && machine.missing_fields) return machine.missing_fields;
      }
    }
    return [];
  }

  render() {
    const {
      treeNodes,
      selectedNodeDetails,
      selectedNodeId,
      componentsArray,
      showSaveAsTemplateModal,
    } = this.state;
    const {
      resources: { areas },
      buildType,
      info,
      theme
    } = this.props;
    if (this.props.setFooter) this.props.setFooter({
      items: [
        {
          Tag: NavButton,
          props: {
            id: 'cancel',
            key: 'footer-btn1',
            cancel: true,
            onClick: () => {
              if (this.props.buildType === 'machine' && this.props.isInfo) {
                this.props.history.push('overview');
              } else {
                this.props.history.goBack();
              }
            },
          },
          children: 'Cancel',
        },
        {
          Tag: NavButton,
          props: {
            id: 'save',
            disabled: this.props.isInfo ? !this.state.enableSave_info : !this.state.enableSave,
            key: 'footer-btn2',
            onClick: this.onClickSave
          },
          children: (this.props.isInfo || buildType !== 'machine') ? 'Save' : 'Save and Assign Sensors',
        }
      ]
    });
    if (this.hasNewNodes() && !this.state.enableSave_info) this.setState({ enableSave_info: true });
    return (
      <Page>
        {showSaveAsTemplateModal && (
          <SaveTemplateModal
            saveTemplateDetails={this.saveTemplateDetails}
            templateName={treeNodes[0].nodeName}
            proceed={this.createTemplate}
            close={() => { this.setState({ showSaveAsTemplateModal: false }); }}
          />
        )}
        {(this.props.machineBuilder.create.loading || areas.loading) && (
          <>
            <AbsoluteLoading />
            <AbsoluteLoadingContainer>
              <LoadingSvg />
            </AbsoluteLoadingContainer>
          </>
        )}
        {!areas.loading && (
          <ColumnLayout id="asset-info" isInfo={this.props.isInfo}>
            <LeftColumn>
              <NodeList
                treeNodes={treeNodes}
                deleteNode={this.deleteNode}
                spawnNode={this.insertNewNode}
                switchNode={this.selectNode}
                toggleExpand={this.toggleNodeExpand}
                selectedNodeId={selectedNodeId}
                selectedNodeDetails={selectedNodeDetails}
                isInfo={this.props.isInfo}
                editMode={this.state.editMode}
                editIconClick={this.editIconClick}
                buildType={buildType}
                reorderTree={this.reorderTree}
                getMissingFields={this.getMissingFields}
                editable={this.props.mcType === 'rotating'}
              />
            </LeftColumn>
            <MainColumn borderRight="none" borderBottom="none">
              <NodeDetails
                id={selectedNodeId}
                depth={selectedNodeDetails.depth}
                nodeName={selectedNodeDetails.nodeName}
                nodeType={selectedNodeDetails.nodeType}
                details={selectedNodeDetails.details}
                newNode={selectedNodeDetails.newNode}
                info={info}
                saveNodeDetails={this.saveNodeDetails}
                componentsArray={componentsArray}
                numberOfChildren={selectedNodeDetails.numberOfChildren}
                deleteMachine={this.deleteMachine}
                isInfo={this.props.isInfo}
                areas={areas.items}
                buildType={buildType}
                verifyTemplate={this.verifyTemplate}
                createMachine={this.createMachine}
                verifyMachineStructure={this.verifyMachineStructure}
                reviewMachineStructure={this.reviewMachineStructure}
                setEnableSave={this.setEnableSave}
                enableSave_info={this.state.enableSave_info}
                setEnableSave_info={this.setEnableSave_info}
                componentsList={this.getComponentsList}
                saveForcingFrequencies={this.saveForcingFrequencies}
                getComponentTypes={this.getComponentTypes}
                width={this.props.hierarchyViewPane.currentWidth}
                getMissingFields={this.getMissingFields}
                updateSchedule={this.updateSchedule}
              />
            </MainColumn>
          </ColumnLayout>
        )}
        {this.state.deleteComponentDetails && (
          <AlertPrompt
            message={`Are you sure you want to delete ${this.state.deleteComponentDetails.nodeName} for ${this.props.info.details.name} ?`}
            secondaryMessage={<div>This will delete all related tags<br /> This action cannot be undone </div>}
            onProceed={() => this.deleteComponent()}
            onCancel={() => this.setState({ deleteComponentDetails: null })}
          />
        )}
        {this.state.confirmSave && (
          <AlertPrompt
            message="Are you sure you want to save the changes ?"
            proceedColor={theme.primaryColor}
            onProceed={this.onProceed}
            onCancel={this.closeSavePrompt}
          />
        )}
        {this.state.confirmAlarmSave && (
          <AlertPrompt
            message="Do you want these settings to be applied on all vibration tags of the machine ?"
            secondaryMessage="Accepting this will replace the existing vibration tag alarm limits with new limits."
            proceedColor={theme.primaryColor}
            proceedText="YES"
            cancelText="NO"
            onProceed={() => this.onAlarmChangeProceed(true)}
            onCancel={() => this.onAlarmChangeProceed(false)}
            onClose={this.closeAlarmPrompt}
            width="60%"
          />
        )}
        {this.state.confirmSpeedBinChange && (
          <AlertPrompt
            message="Are you sure you want to make changes to speed bins?"
            secondaryMessage="Overall and Envelope Alarms will have to be recomputed"
            proceedColor={theme.primaryColor}
            onProceed={this.onSpeedBinChangeProceed}
            onCancel={this.closeSpeedBinPrompt}
          />
        )}
      </Page>
    );
  }
}

MachineBuilder.propTypes = {
  buildType: PropTypes.string,
};

MachineBuilder.defaultProps = {
  buildType: 'machine',
};

const mapStateToProps = (state, ownProps) => ({
  mcType: state.machines.machineAdd.machineAddOptions.mcType,
  nonRotatingSubtype: state.machines.machineAdd.machineAddOptions.nonRotatingSubtype,
  machineBuilder: state.machines.machineBuilder,
  info: ownProps.buildType === 'machine' ? state.machineDetails.info : state.templates.templateInfo,
  resources: state.resources,
  saveAsGlobal: state.templates.saveAsGlobal,
  accountId: state.user.user.account_id,
  hierarchyViewPane: state.hierarchyViewPane,
  completionStatus: state.machineDetails.completion_status,
  forcingFrequencyConstants: state.machines.machineBuilder.metadata.component_forcing_frequencies
});

const mapDispatchToProps = dispatch => ({
  machineBuilderActions: bindActionCreators(machineBuilderActions, dispatch),
  machineInfoActions: bindActionCreators(machineInfoActions, dispatch),
  resourceActions: bindActionCreators(resourceActions, dispatch),
  updateBreadcrumb: bindActionCreators(updateBreadcrumb, dispatch),
  templatesActions: bindActionCreators(templatesActions, dispatch),
  chooseMcType: bindActionCreators(machineAddActions.chooseMcType, dispatch),
  machineDetailsActions: bindActionCreators(machineDetailsActions, dispatch),
  chooseNonRotatingSubtype: bindActionCreators(machineAddActions.chooseNonRotatingSubtype, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTheme(MachineBuilder)));
