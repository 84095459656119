import React, { useState, Fragment, useEffect } from 'react';

import styled from 'styled-components';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import machineLarge from 'common/images/machineLarge.svg';
import SplashScreen from 'common/components/molecules/SplashScreen';

import * as sensorActions from '../../../Sensors/actions/sensors.actions';
import { getCurrentAccount } from '../../../../onboarding/user.actions';

const Page = styled.div`
  height: 100%;
  padding-top: 4rem;
`;


const AssociateSensors = (props) => {
  useEffect(() => {
    const { getUnassociatedSensorsList, getSensorsMetadata } = props.sensorActions;
    props.setFooter(null);
    props.setTitle([props.machineName, 'Associate Sensors']);
    getUnassociatedSensorsList();
    getSensorsMetadata();
  }, []);

  return (
    <Page>
      <Fragment>
        <SplashScreen
          image={machineLarge}
          highlight="Water Inject Pump - 1B"
          heading={() => <Fragment>The asset<span> {props.machineName} </span>has been successfully created. Let's associate some sensors now</Fragment>}
          click={() => props.setShowSensorModal(true)}
          linkText="Associate Sensors"
        >
          <Fragment>Click the button below to pick the sensors first, then assign them to the individual components.</Fragment>
        </SplashScreen>
      </Fragment>
    </Page>
  );
};

const mapStateToProps = state => ({
  sensors: state.sensors,
  currentAccount: state.currentAccount,
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  sensorActions: bindActionCreators(sensorActions, dispatch),
  getCurrentAccount: bindActionCreators(getCurrentAccount, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AssociateSensors);
