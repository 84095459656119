import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as _ from 'lodash';
import Filter from '../molecules/Filter';
import MoreIconSvg from '../../images/MoreIconSvg';
import ScrollContainer from './ScrollContainer';

const FilterItem = styled.div`
  padding: ${props => props.padding};
  white-space: pre;
  font-size: 14px;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
  border-radius: 4px;
  color: ${props => props.altStyle ? props.theme.colors.greyXD : props.theme.colors.greyXXD};
  &:hover {
    color: ${props => props.altStyle ? props.theme.colors.greyXXD : props.theme.primaryColor};
    ${props => props.altStyle ? 'background: #F7F8F6;' : ''}
  }
`;

const FilterDropdown = (props) => {
  const {
    data,
    right,
    title,
    chevron,
    itemPadding,
    modalClassName,
    alignHorizontal,
    altStyle,
    fill,
    activeFill,
    style,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  if (data.length < 1) {
    return null;
  }
  return (
    <Filter
      alignHorizontal={alignHorizontal}
      style={right ? { margin: '5px 5px 0 auto', ...style } : { ...style }}
      open={open}
      title={title}
      toggleOpen={() => setOpen(!open)}
      Icon={chevron ? null : () => <MoreIconSvg style={{ transform: 'rotate(90deg)' }} fill={open ? fill : activeFill} />}
      modalClassName={modalClassName}
      {...rest}
    >
      <ScrollContainer>
        {data.map(({ type, text, onClick }, idx) => (
          <FilterItem
            onClick={() => {
              onClick();
              setOpen(false);
            }}
            id={`dropdown-item-${_.camelCase(text)}`}
            altStyle={altStyle}
            padding={itemPadding}
            key={`filter-item-${idx}-${type}-${text}`}
          >
            {text}
          </FilterItem>
        ))}
      </ScrollContainer>
    </Filter>
  );
};

FilterDropdown.propTypes = {
  right: PropTypes.bool,
  data: PropTypes.array,
  title: PropTypes.string,
  chevron: PropTypes.bool,
  itemPadding: PropTypes.string,
  modalClassName: PropTypes.string,
  alignHorizontal: PropTypes.string,
  fill: PropTypes.string,
  activeFill: PropTypes.string,
  altStyle: PropTypes.bool
};

FilterDropdown.defaultProps = {
  right: false,
  data: [],
  title: '',
  chevron: false,
  itemPadding: '20px',
  modalClassName: '',
  alignHorizontal: 'right',
  fill: '#91B846',
  activeFill: '#000000',
  altStyle: false
};

export default styled(FilterDropdown)``;
