export const erroneousReadingsConstants = {
  GET_SUMMARIZED_ERRONEOUS_READINGS_REQUEST: 'GET_SUMMARIZED_ERRONEOUS_READINGS_REQUEST',
  GET_SUMMARIZED_ERRONEOUS_READINGS_SUCCESS: 'GET_SUMMARIZED_ERRONEOUS_READINGS_SUCCESS',
  GET_SUMMARIZED_ERRONEOUS_READINGS_FAILURE: 'GET_SUMMARIZED_ERRONEOUS_READINGS_FAILURE',
  SET_SUMMARIZED_ERRONEOUS_READINGS_FILTERS: 'SET_SUMMARIZED_ERRONEOUS_READINGS_FILTERS',
  CLEAR_SUMMARIZED_ERRONEOUS_READINGS_LIST: 'CLEAR_SUMMARIZED_ERRONEOUS_READINGS_LIST',

  GET_ERRONEOUS_READINGS_REQUEST: 'GET_ERRONEOUS_READINGS_REQUEST',
  GET_ERRONEOUS_READINGS_SUCCESS: 'GET_ERRONEOUS_READINGS_SUCCESS',
  GET_ERRONEOUS_READINGS_FAILURE: 'GET_ERRONEOUS_READINGS_FAILURE',
  SET_ERRONEOUS_READINGS_FILTERS: 'SET_ERRONEOUS_READINGS_FILTERS',
  CLEAR_ERRONEOUS_READINGS_LIST: 'CLEAR_ERRONEOUS_READINGS_LIST',

  NUMBER_OF_ERRONEOUS_READINGS_PER_PAGE: 80,
  NUMBER_OF_SENSOR_ERRONEOUS_READINGS_PER_PAGE: 20,
  NUMBER_OF_DAYS_ERRONEOUS: 7,
  NUMBER_OF_DAYS_SENSOR_ERRONEOUS: 7,
  INCREASE_ERRONEOUS_PAGE: 'INCREASE_ERRONEOUS_PAGE',
  INCREASE_SENSOR_ERRONEOUS_PAGE: 'INCREASE_SENSOR_ERRONEOUS_PAGE'
};
