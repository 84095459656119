export const machinesConstants = {
  GET_MACHINES_REQUEST: 'GET_MACHINES_REQUEST',
  GET_MACHINES_SUCCESS: 'GET_MACHINES_SUCCESS',
  GET_MACHINES_FAILURE: 'GET_MACHINES_FAILURE',

  CLEAR_MACHINES: 'CLEAR_MACHINES',

  SET_MACHINE_QUERY: 'SET_MACHINE_QUERY',

  SET_MACHINE_SORTER: 'SET_MACHINE_SORTER',

  SET_MACHINE_SEARCH_PARAMS: 'SET_MACHINE_SEARCH_PARAMS',
  CLEAR_MACHINE_SEARCH_PARAMS: 'CLEAR_MACHINE_SEARCH_PARAMS',

  SET_MACHINE_FILTER_PARAMS: 'SET_MACHINE_FILTER_PARAMS',

  GET_AREAS_REQUEST: 'GET_AREAS_REQUEST',
  GET_AREAS_SUCCESS: 'GET_AREAS_SUCCESS',
  GET_AREAS_FAILURE: 'GET_AREAS_FAILURE',


  NUMBER_OF_MACHINES_PER_PAGE: 50,
  SET_MACHINE_PAGINATER: 'SET_MACHINE_PAGINATER',
  SET_MACHINES_REQUIRING_ATTENTION_VIEW: 'SET_MACHINES_RSET_MACHINES_REQUIRING_ATTENTION_VIEW',

  SET_ATTETNION_MACHINE_REQUEST: 'SET_ATTETNION_MACHINE_REQUEST',
  SET_ATTENTION_MACHINE_SUCCESS: 'SET_ATTENTION_MACHINE_SUCCESS',
  SET_ATTENTION_MACHINE_FAILURE: 'SET_ATTENTION_MACHINE_FAILURE',
};

export const ARA_ITEM_OPTIONS = [
  { text: 'Create Task', value: 'task' },
];
