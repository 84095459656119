import { firmwareDeploymentConstants } from '../constants/firmwareDeployment.constants';

const firmwareDeploymentInitialState = {
  loading: false,
  object: [],
  hasMore: false,
  page: 1,
  error: null,
  total_count: 0
};

export const firmwareDeploymentReducer = (state = firmwareDeploymentInitialState, action) => {
  switch (action.type) {
    case firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_REQUEST: {
      if (action.page === 1) {
        return {
          ...state,
          loading: true,
          object: []
        };
      }
      return {
        ...state,
        loading: false,
        listLoading: true
      };
    }
    case firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_SUCCESS: {
      let firmwareDeploymentsArray = [];
      if (action.all === true || action.page === 1) {
        firmwareDeploymentsArray = action.items;
      } else {
        firmwareDeploymentsArray = [...state.object, ...action.items];
      }
      return {
        ...state,
        loading: false,
        object: firmwareDeploymentsArray,
        hasMore: action.page < action.total_pages,
        page: action.page,
        total_count: firmwareDeploymentsArray.length,
        listLoading: false
      };
    }
    case firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    case firmwareDeploymentConstants.CLEAR_FIRMWARE_DEPLOYMENT_LIST: {
      return {
        ...state,
        object: []
      };
    }
    case firmwareDeploymentConstants.INCREASE_FIRMWARE_DEPLOYMENT_PAGE: {
      return {
        ...state,
        page: action.page
      };
    }
    default:
      return state;
  }
};

const firmwareDeploymentFiltersInitialState = {
  filters: []
};

export const firmwareDeploymentFiltersReducer = (state = firmwareDeploymentFiltersInitialState, action) => {
  switch (action.type) {
    case firmwareDeploymentConstants.SET_FIRMWARE_DEPLOYMENT_FILTERS: {
      return {
        filters: action.filters,
      };
    }
    default:
      return state;
  }
};

const firmwareDeploymentMetadataState = {
  tx: [],
  motes: [],
  firmwares: [],
  error: '',
  loading: false
};

export const firmwareDeploymentMetadataReducer = (state = firmwareDeploymentMetadataState, action) => {
  switch (action.type) {
    case firmwareDeploymentConstants.GET_ALL_OPS_TX_SENSOR_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case firmwareDeploymentConstants.GET_ALL_OPS_TX_SENSOR_SUCCESS: {
      return {
        ...state,
        tx: action.items,
        loading: false
      };
    }
    case firmwareDeploymentConstants.GET_ALL_OPS_TX_SENSOR_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      };
    }
    case firmwareDeploymentConstants.GET_ALL_OPS_MOTE_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case firmwareDeploymentConstants.GET_ALL_OPS_MOTE_SUCCESS: {
      return {
        ...state,
        motes: action.items,
        loading: false
      };
    }
    case firmwareDeploymentConstants.GET_ALL_OPS_MOTE_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      };
    }
    case firmwareDeploymentConstants.GET_FIRMWARES_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case firmwareDeploymentConstants.GET_FIRMWARES_SUCCESS: {
      return {
        ...state,
        firmwares: action.items,
        loading: false
      };
    }
    case firmwareDeploymentConstants.GET_FIRMWARES_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      };
    }
    default:
      return state;
  }
};

const firmwareDeploymentDetailsState = {
  details: {},
  loading: false,
  affectedDevices: [],
  error: '',
};

export const firmwareDeploymentDetailsReducer = (state = firmwareDeploymentDetailsState, action) => {
  switch (action.type) {
    case firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        details: action.details,
        loading: false
      };
    }
    case firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_DETAILS_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      };
    }
    case firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_AFFECTED_DEVICES_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_AFFECTED_DEVICES_SUCCESS: {
      return {
        ...state,
        affectedDevices: action.devices,
        loading: false
      };
    }
    case firmwareDeploymentConstants.GET_FIRMWARE_DEPLOYMENT_AFFECTED_DEVICES_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      };
    }
    case firmwareDeploymentConstants.RETRY_FIRMWARE_DEPLOYMENT_DEVICES_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case firmwareDeploymentConstants.RETRY_FIRMWARE_DEPLOYMENT_DEVICES_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case firmwareDeploymentConstants.RETRY_FIRMWARE_DEPLOYMENT_DEVICES_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      };
    }
    default:
      return state;
  }
};
