import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Popup } from 'semantic-ui-react';
import styled, { withTheme } from 'styled-components';
import { AnimatedButton } from 'common/components/atoms/Button';
import { CircleCheck, CircleXMark } from 'common/images/FaIcons';
import { FlexContainer } from 'common/components/atoms';
import ValidationResults from './ValidationResults';

const ValidationButton = styled.span`
  position: relative;
`;

function BaselineValidator({
  buttonDisabled,
  onSave,
  component,
  validationLoading,
  saveLoading,
  tagIds,
  toggleOutliers,
  outliersShown,
  isMultiSetActive,
  theme,
  showSaveOptions,
  validationKey,
  showPopupformat
}) {
  let tagsResults = component && component.tags_data;
  if (tagIds) {
    tagsResults = tagsResults.filter(res => tagIds.includes(res.tag_id));
  }

  const hasValidationResults = tagsResults && _.some(
    tagsResults.map(item => !_.isEmpty(item.validation && item.validation[validationKey]))
  );

  const isValidBaseline = hasValidationResults && _.every(
    tagsResults.map(item => _.isEmpty(item.validation[validationKey]) || item.validation[validationKey].overall_is_valid)
  );

  return (
    <FlexContainer alignItems="center">
      {validationLoading ? (
        'Validating baseline...'
      ) : (
        <>
          <ValidationButton position="relative">
            {hasValidationResults && showPopupformat && (
              <Popup
                trigger={
                  isValidBaseline ? (
                    <CircleCheck
                      style={{ color: theme.colors.green }}
                      size="lg"
                    />
                  ) : (
                    <CircleXMark
                      style={{ color: theme.colors.critical }}
                      size="lg"
                    />
                  )
                }
                flowing
                hoverable
              >
                <ValidationResults
                  component={component}
                  tagIds={tagIds}
                  toggleOutliers={toggleOutliers}
                  outliersShown={outliersShown}
                  validationKey={validationKey}
                />
              </Popup>
            )}
            {hasValidationResults && !showPopupformat && (
              <FlexContainer alignItems="center">
                {isValidBaseline ? (
                  <CircleCheck
                    style={{ color: theme.colors.green }}
                    size="lg"
                  />
                ) : (
                  <CircleXMark
                    style={{ color: theme.colors.critical }}
                    size="lg"
                  />
                )}
                <ValidationResults
                  component={component}
                  tagIds={tagIds}
                  toggleOutliers={toggleOutliers}
                  outliersShown={outliersShown}
                  validationKey={validationKey}
                  showInline
                />
              </FlexContainer>
            )}
          </ValidationButton>
          {!isMultiSetActive && showSaveOptions && (
            <AnimatedButton
              loading={saveLoading}
              disabled={saveLoading || buttonDisabled}
              onClick={onSave}
              danger={!isValidBaseline}
            >
              {isValidBaseline || !hasValidationResults ? 'Save' : 'Override and Save'}
            </AnimatedButton>
          )}
        </>
      )}
    </FlexContainer>
  );
}

BaselineValidator.propTypes = {
  showSaveOptions: PropTypes.bool,
  validationKey: PropTypes.string,
  showPopupformat: PropTypes.bool
};

BaselineValidator.defaultProps = {
  showSaveOptions: true,
  validationKey: 'overall',
  showPopupformat: true
};

export default withTheme(BaselineValidator);
