import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { localTimeString } from 'common/helpers';
import ListItem from 'common/components/atoms/ListItem';
import ErroneousReadingsChartsModel from './ErroneousReadingsChartsModel';

const ErroneousReadingsListItem = styled(ListItem)`
  cursor: pointer;
  ${props => props.selected && 'background-color: rgba(145,184,70,0.08);'}
  td {
    height: 54px;
  }
`;

class SensorErroneousReadingsItem extends React.Component {
  constructor() {
    super();
    this.state = {
      chartsModelOpen: false
    };
  }

  toggleChartsModel = () => {
    this.setState(prevState => ({
      chartsModelOpen: !prevState.chartsModelOpen
    }));
  };

  render() {
    const {
      item,
      columnSizes,
      search
    } = this.props;
    return (
      <>
      <ErroneousReadingsListItem onClick={this.toggleChartsModel}>
          <td width={`${columnSizes[0]}%`}>
            {localTimeString(item.timestamp) || '--'}
          </td>
          <td width={`${columnSizes[1]}%`}>
            {item.reviewed_by || '--'}
          </td>
      </ErroneousReadingsListItem>

      {this.state.chartsModelOpen && (
        <ErroneousReadingsChartsModel
          close={this.toggleChartsModel}
          item={item}
          search={search}
        />
      )}

      </>
    );
  }
}

SensorErroneousReadingsItem.propTypes = {
  item: PropTypes.object.isRequired,
  columnSizes: PropTypes.array.isRequired,
  search: PropTypes.func.isRequired
};

export default SensorErroneousReadingsItem;
