import { createBrowserHistory } from 'history';
import { getLocationId } from 'common/utils';

export const history = createBrowserHistory();

export function applyBrowserDuplicateBlocker() {
  history.block(
    (location, action) =>
      action !== 'PUSH' ||
      getLocationId(location) !== getLocationId(history.location)
  );
}
