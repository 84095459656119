import _ from 'lodash';

import assetDetailsConstants from '../constants/assetHierarchy.constants';
import * as assetDetailsActions from './assetDetails.actions';
import { axiosInstance, ENDPOINT } from '../../../common/constants';
import { handleResponse } from '../../../common/helpers';
import * as hierarchyUtils from '../utils/assetHierarchyUtils';
import * as breadcrumbActions from '../../Menu/actions/breadcrumb.actions';

export const findNode = (nodeId, nodeType, hierarchy, propertyType, propertyValue) => {
  if (_.isEmpty(hierarchy)) return null;
  if (
    (hierarchy.node_type === nodeType && hierarchy.id === nodeId) ||
    (propertyType && hierarchy[propertyType] === propertyValue)
  ) return hierarchy;

  for (let i = 0; i < hierarchy.children.length; i++) {
    const found = findNode(nodeId, nodeType, hierarchy.children[i], propertyType, propertyValue);
    if (found) {
      found.expanded = true;
      hierarchy.children[i].expanded = true;
      return found;
    }
  }
  return null;
};

export const selectNode = (nodeId, nodeType, propertyType = null, propertyValue = null) => (dispatch, getState) => {
  const assetDetails = getState().assetHierarchyReducer;
  const { site_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(getState().breadcrumb);
  const hierarchy = _.cloneDeep(assetDetails.assetInfo.hierarchy);
  for (let i = 0; i < hierarchy.length; i++) {
    if (hierarchy[i].id === site_id) {
      const node = findNode(nodeId, nodeType, hierarchy[i], propertyType, propertyValue);
      nodeId = nodeId || (node && node.id);
      if (!_.isEmpty(node)) {
        const path = [];
        hierarchyUtils.haspath(hierarchy, nodeId, nodeType, path);
        const params = hierarchyUtils.getHierarchyParams(path, nodeId, nodeType);
        hierarchyUtils.expandPathNodes(hierarchy, path);
        dispatch(breadcrumbActions.updateBreadcrumb(params));
      }
      dispatch(refreshHierarchy(hierarchy));
      return nodeId;
    }
  }
  return null;
};

export const toggleNodeExpand = (nodeId, nodeType) => (dispatch, getState) => {
  const assetDetails = getState().assetHierarchyReducer;
  const { site_id } = hierarchyUtils.getSelectedPathFromBreadcrumb(getState().breadcrumb);
  const hierarchy = _.cloneDeep(assetDetails.assetInfo.hierarchy);
  const toggleNodeHelper = (nodeId, nodeType, nodes) => {
    nodes.forEach((node) => {
      if (node.id === nodeId && node.node_type === nodeType) {
        node.expanded = !node.expanded;
        return;
      }
      toggleNodeHelper(nodeId, nodeType, node.children);
    });
  };
  for (let i = 0; i < hierarchy.length; i++) {
    if (hierarchy[i].id === site_id) {
      toggleNodeHelper(nodeId, nodeType, hierarchy[i].children);
    }
  }
  dispatch(refreshHierarchy(hierarchy));
};

export const refreshHierarchy = hierarchy => dispatch =>
  dispatch({ type: assetDetailsConstants.REFRESH_HIERARCHY, hierarchy });

export const deleteArea = areaId => () => handleResponse(
  axiosInstance.delete(ENDPOINT.DELETE_AREA(areaId))
).then(
  res => res,
  (error) => { throw error; }
);

export const editArea = (areaId, data) => () => handleResponse(
  axiosInstance.patch(ENDPOINT.UPDATE_AREA(areaId), data)
).then(
  res => res,
  (error) => { throw error; }
);

export const createArea = data => () => handleResponse(
  axiosInstance.post(ENDPOINT.CREATE_AREA, data)
).then(
  res => res,
  (error) => { throw error; }
);

export const deleteTags = res => (dispatch) => {
  dispatch({ type: assetDetailsConstants.DELETE_TAGS_REQUEST });
  return handleResponse(axiosInstance.delete(ENDPOINT.TAGS, { data: res })).then(
    (data) => {
      dispatch({ type: assetDetailsConstants.DELETE_TAGS_SUCCESS });
      dispatch(assetDetailsActions.getHierarchy(false));
      return data;
    },
    (error) => {
      dispatch({ type: assetDetailsConstants.DELETE_TAGS_FAILURE, error });
      dispatch(assetDetailsActions.getHierarchy(false));
      throw error;
    }
  );
};

export const toggleHierarchyView = () => (dispatch, getState) => {
  const { hierarchyViewPane: { currentWidth, previousWidth } } = getState();
  dispatch({
    type: 'TOGGLE_HIERARCHY_VIEW',
    payload: {
      currentWidth: previousWidth,
      previousWidth: currentWidth
    }
  });
};


export const toggleHierarchyViewOnMobile = () => (dispatch, getState) => {
  const { mobileHierarchyViewPane: { mobileCurrentWidth, mobilePreviousWidth } } = getState();
  dispatch({
    type: 'TOGGLE_HIERARCHY_VIEW_ON_MOBILE',
    payload: {
      mobileCurrentWidth: mobilePreviousWidth,
      mobilePreviousWidth: mobileCurrentWidth,
    },
  });
};

export const minimizeHierarchyViewPane = () => (dispatch) => {
  dispatch({
    type: 'TOGGLE_HIERARCHY_VIEW_ON_MOBILE',
    payload: {
      mobileCurrentWidth: 0,
      mobilePreviousWidth: window.innerWidth,
    },
  });
};

export const updateHierarchyViewPane = width => (dispatch) => {
  dispatch({ type: 'UPDATE_HIERARCHY_VIEW_PANE_WIDTH', payload: { width } });
};

export const updateMobileHierarchyViewPane = () => (dispatch, getState) => {
  const { mobileHierarchyViewPane: { mobileCurrentWidth } } = getState();
  let nextCurrentWidth;
  let nextPreviousWidth;
  if (mobileCurrentWidth === 0) {
    nextCurrentWidth = 0;
    nextPreviousWidth = window.innerWidth;
  } else {
    nextPreviousWidth = 0;
    nextCurrentWidth = window.innerWidth;
  }
  dispatch({
    type: 'TOGGLE_HIERARCHY_VIEW_ON_MOBILE',
    payload: {
      mobileCurrentWidth: nextCurrentWidth,
      mobilePreviousWidth: nextPreviousWidth,
    },
  });
};
