import React from 'react';
import PropTypes from 'prop-types';
import CrossSvg from 'common/images/CrossSvg';
import { humanize as humanizeText } from 'common/helpers/humanize';
import colors from 'common/styles/colors';
import styled from 'styled-components';

const OptionContainer = styled.div`
  &.ed-multiselect-selected-option {
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
    ${props => props.isClickable && `
      cursor: pointer;
      &:hover {
        color: ${colors.blue};
      }
    `}
  }
`;


export const SelectedOption = ({
  option,
  onClick,
  disabled,
  humanize,
  backgroundColor,
  color,
  onContainerClick
}) => (
  <OptionContainer
    className="ed-multiselect-selected-option"
    onClick={onContainerClick}
    isClickable={!!onContainerClick}
    color={color}
    backgroundColor={backgroundColor}
  >
    <span>{humanize ? humanizeText(option.text) : option.text}</span>
    <div
      className="ed-selected-option-close ps-hover-pointer"
      htmlFor="unselect_option"
      onClick={onClick}
    >
    {!disabled &&
      <CrossSvg />
    }
    </div>
  </OptionContainer>
);

SelectedOption.propTypes = {
  option: PropTypes.object.isRequired, // value and text as keys
  backgroundColor: PropTypes.string,
  color: PropTypes.string
};

SelectedOption.defaultProps = {
  backgroundColor: colors.greyL,
  color: colors.black
};
