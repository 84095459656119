import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import * as _ from 'lodash';

import * as graphUtils from 'common/components/Chart/utils/graphUtils';
import { LineChart } from '../../../../../common/components/Chart/hoc/LineChart';
import {
  addMouseClickEvent,
  addHorizontalBrush,
  drawCircleOnPos,
  addMouseoverEvent,
  drawLineOnPos,
  drawTooltip
} from '../../../../../common/components/Chart/utils/graphUtils';
import { closestExactDataPoint, getAllDataPointsArray } from '../../../../../common/components/Chart/utils/helpers';
import InlineModal from '../../../../../common/components/Chart/components/InlineModal';

import Button from '../../../../../common/components/atoms/Button';
import Text from '../../../../../common/typography/Text/Text';
import Label from '../../../../../common/typography/Label/Label';

import { formatDate } from '../../../../../common/utils';

const initialState = {
  dataPos: {
    y: false,
    x: false
  },
  dataToSave: null,
  dataToDisplay: null
};

class BaselineChart extends Component {
  constructor(props) {
    super(props);
    this.brushed = this.brushed.bind(this);
    this.brushended = this.brushended.bind(this);
    this.brushstarted = this.brushstarted.bind(this);
    this.drawExcludedCircles = this.drawExcludedCircles.bind(this);
    this.drawOutliers = this.drawOutliers.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addMouseoverEventListener = this.addMouseoverEventListener.bind(this);
    this.state = initialState;
  }

  componentWillReceiveProps(nextProps) {
    // check if either baseline end or start date has changed via props change (eg hitting cancel to reset timestamps to original values)
    const newStart = new Date(nextProps.baselineStart).getTime();
    const newEnd = new Date(nextProps.baselineEnd).getTime();
    const oldStart = new Date(this.props.baselineStart).getTime();
    const oldEnd = new Date(this.props.baselineEnd).getTime();
    if (newStart !== oldStart || newEnd !== oldEnd) {
      // if no nextProps baseline delete any existing brushes and add a new one via drawGraph
      if (!nextProps.baselineEnd && this.ctx && this.ctx.brush) {
        d3.select(`g.${this.props.chartName}.brush`).remove();
        this.drawGraph(this.ctx, nextProps);
      } else if (this.ctx && this.ctx.brush) {
        // call brush.move on the brush element with a new extent to reset original positions
        d3.select(`g.${this.props.chartName}.brush`)
          .call(
            this.ctx.brush.move,
            [this.ctx.x(newStart), this.ctx.x(newEnd)]
          );
      }
    }
    this.drawExcludedCircles(nextProps);
    this.drawOutliers(nextProps);
  }

  drawGraph(ctx, props = this.props) {
    this.ctx = ctx;
    const options = {
      brushed: this.brushed,
      brushended: this.brushended,
      brushstarted: this.brushstarted
    };
    // set extent on brush if baselineEnd (and by association baselineStart) exist
    if (props.baselineEnd) {
      options.extent = [
        ctx.x(new Date(this.props.baselineStart).getTime()),
        ctx.x(new Date(this.props.baselineEnd).getTime())
      ];
    }

    d3.select(`.${this.props.chartName}.brush`).remove();
    this.ctx.brush = null;
    this.ctx.brush = addHorizontalBrush(ctx.chart, this.props.chartName, options);
    this.brushstarted();

    this.drawExcludedCircles(this.props);
    this.drawOutliers(this.props);
  }

  addMouseoverEventListener(ctx) {
    if (this.mouseover) return;
    this.mouseover = addMouseoverEvent(ctx.chart, () => {
      const dataPoints = getAllDataPointsArray(ctx);
      ctx.props.mouseover(dataPoints);
    });
  }

  drawSelection(ctx, selection) {
    // drawSelection is called when HoC's drawGraph() is called which results a null d3.event and d3.mouse() function crashes the app.
    if (!d3.event) return;

    const dataPoint = closestExactDataPoint(ctx);
    if (!dataPoint) return;

    const outsideSelectionDate = new Date(ctx.props.baselineEnd).getTime() < dataPoint.x || new Date(ctx.props.baselineStart).getTime() > dataPoint.x;
    d3.selectAll(`.${this.props.chartName}.selection`).remove();

    drawLineOnPos(ctx.chart, ctx.x(dataPoint.x), 0, ctx.x(dataPoint.x), ctx.chart.getBoundingClientRect().height, `${this.props.chartName} selection`)
      .attr('pointer-events', 'none');
    drawCircleOnPos(ctx.chart, ctx.x(dataPoint.x), ctx.y(dataPoint.y), 3, `${this.props.chartName} selection`)
      .attr('pointer-events', 'none');

    if (outsideSelectionDate) {
      this.tooltip = undefined;
      d3.select(`.${this.props.chartName}-tooltip`).remove();
    } else if (!this.tooltip) {
      d3.select(`.${this.props.chartName}-tooltip`).remove();
      this.tooltip = drawTooltip(
        this.ctx.chart,
        this.ctx.x(dataPoint.x),
        this.ctx.y(dataPoint.y),
        selection.title,
        this.props.chartName
      );
    } else {
      this.tooltip
        .attr('transform', `translate(${this.ctx.x(dataPoint.x) + 10}, 10)`)
        .select('text')
        .text(selection.title);
    }
  }

  zoomHandlerBaseline = (ctx) => {
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'brush') return; // ignore zoom-by-brush

    const newStart = new Date(this.props.baselineStart).getTime();
    const newEnd = new Date(this.props.baselineEnd).getTime();
    if (ctx && ctx.brush && newStart && newEnd) {
      // call brush.move on the brush element with a new extent to reset original positions
      d3.select(`g.${this.props.chartName}.brush`)
        .call(
          ctx.brush.move,
          [ctx.x(newStart), ctx.x(newEnd)]
        );
      this.drawExcludedCircles(this.props);
      this.drawOutliers(this.props);
    }
  };

  brushstarted() {
    d3.select(this.ctx.chart)
      .selectAll('.handle')
      .attr('y', (this.ctx.chart.getBoundingClientRect().height / 2) - 20)
      .attr('height', 40);
  }

  brushed() {
    // in addition to normal brushed action (moving selection overlay etc) we create and move separate lines for styling purposes
    // if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') return; // ignore brush-by-zoom

    this.brushstarted();
    const selection = d3.event.selection || this.ctx.x.range();
    const data = [
      {
        x1: selection[0], y1: 0, x2: selection[1], y2: 0, class: 'x'
      },
      {
        x1: selection[0], y1: 0, x2: selection[0], y2: this.ctx.chart.getBoundingClientRect().height, class: 'y'
      },
      {
        x1: selection[0], y1: this.ctx.chart.getBoundingClientRect().height, x2: selection[1], y2: this.ctx.chart.getBoundingClientRect().height, class: 'x'
      },
      {
        x1: selection[1], y1: 0, x2: selection[1], y2: this.ctx.chart.getBoundingClientRect().height, class: 'y'
      }
    ];

    const lines = d3.select(`.${this.props.chartName}.brush`)
      .selectAll('.baselinebrush')
      .data(data);

    lines.exit().remove();

    lines.enter()
      .append('line')
      .merge(lines)
      .attr('class', d => `baselinebrush ${d.class} ${this.props.chartName} line`)
      .attr('x1', d => d.x1)
      .attr('y1', d => d.y1)
      .attr('x2', d => d.x2)
      .attr('y2', d => d.y2)
      .attr('pointer-events', 'none');
  }

  brushended() {
    // guard for incorrect burshended events on clicks that would not yet create a selection
    if (!d3.brushSelection(d3.select(`g.${this.props.chartName}.brush`).node())) return;

    this.brushstarted();

    // remove click listeners (pointer-events) from brush elements
    const brushEl = d3.select(`.${this.props.chartName}.brush`);

    brushEl.select('.overlay')
      .attr('pointer-events', 'none');

    brushEl.select('.selection')
      .attr('pointer-events', 'none');

    // find selection coordinates of brush event
    const s = d3.event.selection || this.ctx.x.range();

    // guard against double updates when we programmatically update the brush and brushended gets called
    // only update baseline like this when manual d3 event found, mouseup is manual movement of baseline brush, bursh event in moving the context chart brush
    if (d3.event.sourceEvent) {
      if (d3.event.sourceEvent.type === 'mouseup' || d3.event.sourceEvent.type === 'brush') {
        // set baseline range with values from brush
        // invert converts coordinate values to values in the scale
        const baseline = {
          start_time: this.ctx.x.invert(s[0]),
          end_time: this.ctx.x.invert(s[1])
        };
        this.props.updateBaseline(baseline);
      }
    }

    // add click listener to chart
    if (!this.mouseClick) {
      this.mouseClick = addMouseClickEvent(this.ctx.chart, () => {
        const dataPoint = closestExactDataPoint(this.ctx);

        // check that datapoint clicked is inside selection range
        if (new Date(this.props.baselineEnd).getTime() > dataPoint.x && new Date(this.props.baselineStart).getTime() < dataPoint.x) {
          const dataPos = {
            x: this.ctx.x(dataPoint.x),
            y: this.ctx.y(dataPoint.y)
          };
          if (dataPos.x > this.ctx.chart.getBoundingClientRect().width - 400) dataPos.alignLeft = true; // tooltip box should be to the left of the dot
          // store information about the point clicked into state
          // dataPos is location info,
          // dataToSave is data going to parent
          // dataToDisplay is data shown on the tooltip box
          this.setState({
            dataPos,
            dataToDisplay: {
              y: dataPoint.y,
              x: formatDate(dataPoint.x),
              measurement_id: dataPoint.measurement_id
            }
          });
          d3.selectAll(`.${this.props.chartName}.clickedPosition`).remove();
          drawCircleOnPos(this.ctx.chart, this.ctx.x(dataPoint.x), this.ctx.y(dataPoint.y), 3, `${this.props.chartName} clickedPosition`);
        }
      });
    }
  }

  drawOutliers(props) {
    const { outliersArray, chartName } = props;
    d3.selectAll(`.${chartName}.outliers`).remove();
    if (this.ctx && outliersArray) {
      const height = this.ctx.chart.getBoundingClientRect().height;
      outliersArray.forEach((d) => {
        if (this.ctx.x(d.x) > 0 && this.ctx.x(d.x) < this.ctx.chart.getBoundingClientRect().width
              && this.ctx.y(d.y) > 0 && this.ctx.y(d.y) < parseInt(height, 10)) {
          graphUtils.drawCircleOnPos(this.ctx.chart, this.ctx.x(d.x), this.ctx.y(d.y), 3, `${chartName} outliers`).attr('fill', 'red');
        }
      });
    }
  }

  drawExcludedCircles(props) {
    d3.selectAll(`.${props.chartName}.circle`).remove();
    if (this.ctx && (props.outlierUpperLimit || props.outlierLowerLimit)) {
      props.data.forEach((dataPoint, idx) => {
        // dont draw measurements from non-active datasets, also break if !dataPoint aka no measurements to discard
        if (!props.activeData[idx] || !dataPoint) return;
        dataPoint.forEach((d) => {
          if (props.baselineEnd && (new Date(props.baselineEnd).getTime() < d.x || new Date(props.baselineStart).getTime() > d.x)) return;
          if ((!parseFloat(props.outlierLowerLimit) || d.y > parseFloat(props.outlierLowerLimit)) && (!parseFloat(props.outlierUpperLimit) || d.y < parseFloat(props.outlierUpperLimit))) return;
          const tmpCircle = drawCircleOnPos(this.ctx.chart, this.ctx.x(d.x), this.ctx.y(d.y), 4, `${this.ctx.props.chartName}`);
          tmpCircle.attr('stroke', '#333')
            .attr('fill', '#fff');
        });
      });
    }
  }

  closeModal() {
    this.setState(initialState);
    d3.selectAll(`.${this.props.chartName}.clickedPosition`).remove();
  }

  render() {
    if (!this.state.dataPos.x || !this.props.showDiscardModal) return null;
    return (
      <InlineModal {...this.state.dataPos} height="160" width="400" close={this.closeModal}>
        <Label>Measurement time</Label>
        <Text>{this.state.dataToDisplay.x}</Text>
        <Label>Amplitude</Label>
        <Text>{this.state.dataToDisplay.y.toFixed(2)} {}</Text>
      </InlineModal>
    );
  }
}

BaselineChart.displayName = 'BaselineChart';

BaselineChart.propTypes = {
  showDiscardModal: PropTypes.bool
};

BaselineChart.defaultProps = {
  showDiscardModal: true
};

export default LineChart(BaselineChart);
