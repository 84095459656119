import React, { Component } from 'react';
import * as d3 from 'd3';
import * as _ from 'lodash';
import { connect } from 'react-redux';

import colors from 'common/styles/colors';
import { LineChart } from '../../../../../common/components/Chart/hoc/LineChart';
import { KEYBOARD_CODES } from '../../../../../common/constants';
import * as graphUtils from '../../../../../common/components/Chart/utils/graphUtils';
import * as helpers from '../../../../../common/components/Chart/utils/helpers';
import { toFixedXIfNumber, isElementPartiallyVisible } from '../../../../../common/utils';

const X_UNITS_MIN_PIXEL_DIFFERENCE = 30;
class WavefromChart extends Component {
  constructor(props) {
    super(props);
    this.drawCursor = this.drawCursor.bind(this);
    this.drawCursors = this.drawCursors.bind(this);
    this.drawCursorInfotext = this.drawCursorInfotext.bind(this);
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyStrokes);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyStrokes);
  }

  handleKeyStrokes = (e) => {
    if (!this.ctx || !this.ctx.chart || !this.ctx.x || this.props.lastHoveredChart !== this.props.type) return;
    if (e.keyCode !== KEYBOARD_CODES.LEFT_ARROW && e.keyCode !== KEYBOARD_CODES.RIGHT_ARROW) return;
    const isVisible = isElementPartiallyVisible(this.ctx.chart);
    if (!isVisible) return;
    const { dataPoint } = _.cloneDeep(this.state);
    if (_.isEmpty(dataPoint)) return;
    const { points } = this.state;
    const { data } = this.props;
    const { indexOnDataset } = dataPoint;
    const { indexOnDataset: referenceIndex } = points[0];
    let newIndex = -1;
    if (e.keyCode === KEYBOARD_CODES.LEFT_ARROW) {
      if (Math.abs(indexOnDataset - referenceIndex) < 10) return;
      if (indexOnDataset < referenceIndex) newIndex = indexOnDataset + 1;
      else newIndex = indexOnDataset - 1;

      if (newIndex < 0 || newIndex > data[0].length - 1) return;
      dataPoint.x = data[0][newIndex].x;
      dataPoint.indexOnDataset = newIndex;
    } else if (e.keyCode === KEYBOARD_CODES.RIGHT_ARROW) {
      if (indexOnDataset < referenceIndex) newIndex = indexOnDataset - 1;
      else newIndex = indexOnDataset + 1;

      if (newIndex < 0 || newIndex > data[0].length - 1) return;
      dataPoint.x = data[0][newIndex].x;
      dataPoint.indexOnDataset = newIndex;
    }

    const diffXvalue = Math.abs(dataPoint.x - this.state.points[0].x);
    const diffXcoord = Math.abs(this.ctx.x(dataPoint.x - this.state.points[0].x));

    if (diffXcoord < X_UNITS_MIN_PIXEL_DIFFERENCE) return;
    dataPoint.text = Math.round(diffXvalue);

    this.setState({ dataPoint });
    this.drawCursors(this.ctx, dataPoint);
    this.drawCursorInfotext(this.ctx, diffXvalue);
  };

  drawGraph(ctx) {
    this.ctx = ctx;
    this.cursorContainer = d3.select(ctx.chart)
      .append('g')
      .attr('width', ctx.chart.getBoundingClientRect().width)
      .attr('height', ctx.chart.getBoundingClientRect().height)
      .attr('class', `${this.props.chartName} cursorGroupContainer`);

    this.redrawCursors(ctx);
  }

  /* eslint-disable */
  drawSelection(ctx, selection){
    d3.selectAll(`.${ctx.props.chartName}.selection`).remove();
    let selections = [];
    if (!_.isArray(selection)) selections = [_.cloneDeep(selection)]
    else selections = selection
    const height = ctx.chart.getBoundingClientRect().height
    selections.forEach((dataPoint) => {
      graphUtils.drawLineOnPos(ctx.chart, ctx.x(dataPoint.x), 0, ctx.x(dataPoint.x), height, `${ctx.props.chartName} selection`)
        .attr('pointer-events', 'none');
    });

    selections.forEach((dataPoint) => {
      graphUtils.drawCircleOnPos(ctx.chart, ctx.x(dataPoint.x), ctx.y(dataPoint.y), 3, `${ctx.props.chartName} selection`)
        .attr('pointer-events', 'none')
        .attr('fill', ctx.props.colors ? ctx.props.colors[dataPoint.dataIdx] : colors.green);
    });
  }

  drawPoints(ctx, points) {
    if (points.length === 1) {
      this.drawCursor(ctx, { ...points[0], text: 'R' });

      this.setState({
        points,
        drawingCursor: true
      });
    } else if (points.length === 2) {
      this.setState({
        points,
        drawingCursor: false
      });
    } else if (points.length === 0) {
      this.cursors = [];
      d3.selectAll(`.${this.props.chartName}.cursorGroup`).remove();
      d3.selectAll(`.${this.props.chartName}.cursorInfo`).remove();
      this.setState({
        points,
        drawingCursor: false
      });
    }
  }

  drawCursorInfotext(ctx, info) {
    const frequencyUnit = this.props.currentUser.frequency_units;
    let delta = toFixedXIfNumber(1000 / info, 2);
    if (frequencyUnit === 'CPM') {
      delta = toFixedXIfNumber(delta * 60, 0);
    }
    d3.selectAll(`.${this.props.chartName}.cursorInfo`).remove();
    d3.select(ctx.chart)
      .append('text')
      .attr('class', `${this.props.chartName} cursorInfo`)
      .attr('dx', 0)
      .attr('dy', -5)
      .text(`Delta: ${delta} ${frequencyUnit}, ${toFixedXIfNumber(info, 2)} ${ctx.props.xUnit}`);
  }

  addMouseoverEventListener(ctx) {
    if (this.mouseover) return;
    // this is really slow on linechart
    this.mouseover = graphUtils.addMouseoverEvent(ctx.chart, () => {
      const dataPoint = helpers.closestExactDataPoint(ctx);
      if (this.state.drawingCursor) {
        this.setState({ dataPoint });
        this.drawCursors(ctx, dataPoint);
      } else {
        ctx.props.mouseover(dataPoint, this.props.chartName);
      }
    });
  }

  drawCursor(ctx, point) {
    // draw single cursor line into the container
    const points = ctx.props.data.map((dataSet) => {
      return helpers.closestXDataPointOnDataset(dataSet, point.x);
    });
    const colors = ctx.props.colors ? ctx.props.colors : chartColors
    const cursorG = this.cursorContainer
      .append('g')
      .attr('class', `${this.props.chartName} cursorGroup`)
      .attr('transform', `translate(${ctx.x(point.x)}, 0)`);

    graphUtils.drawLineOnPos(cursorG.node(), 0, 0, 0, ctx.chart.getBoundingClientRect().height, `${this.props.chartName} cursorSelection`)
      .attr('stroke-width', 3)
      .attr('stroke-dasharray', (5, 5));

    cursorG.append('text')
      .attr('class', `${this.props.chartName} cursorValue`)
      .attr('dx', 3)
      .attr('dy', 12)
      .text(point.text || toFixedXIfNumber(point.x, 0));

    points.map((point,idx) => graphUtils.drawCircleOnPos(cursorG.node(), 0, ctx.y(point.y), 4,`${this.props.chartName} cursorSelection`)
    .attr('fill', ctx.props.colors ? ctx.props.colors[idx] : colors.green));
  }

  drawCursors(ctx, point) {
    // calculate distance of hover position (point) to the first clicked position (this.props.points[0])

    const diffXvalue = Math.abs(point.x - this.state.points[0].x);
    const diffXcoord = Math.abs(ctx.x(point.x - this.state.points[0].x));

    // guard against too many lines
    if (diffXcoord < 20 || diffXvalue < 1) {
      d3.selectAll(`.${this.props.chartName}.cursorGroup`).remove();
      this.drawCursor(ctx, { ...this.state.points[0], text: 'R' });
      return;
    }

    this.drawCursorInfotext(ctx, diffXvalue);

    const rightCursors = (ctx.props.data[0][ctx.props.data[0].length - 1].x - this.state.points[0].x) / diffXvalue;
    const leftCursors = this.state.points[0].x / diffXvalue;

    d3.selectAll(`.${this.props.chartName}.cursorGroup`).remove();

    this.cursors = [{ ...this.state.points[0], text: 'R' }];

    for (let i = 1; i <= rightCursors; i++) {
      const xValue = this.state.points[0].x + diffXvalue * i;
      const closestPoint = helpers.closestXDataPointOnDataset(ctx.props.data[0], xValue);
      closestPoint.text = Math.round(diffXvalue * i)
      this.cursors.push(closestPoint);
    }

    for (let i = 1; i <= leftCursors; i++) {
      const xValue = this.state.points[0].x - diffXvalue * i;
      const closestPoint = helpers.closestXDataPointOnDataset(ctx.props.data[0], xValue);
      closestPoint.text = Math.round(diffXvalue * i) * (-1);
      this.cursors.push(closestPoint);
    }

    this.cursors.forEach((cursor) => {
      if (ctx.x(cursor.x) > ctx.chart.getBoundingClientRect().width || ctx.x(cursor.x) < 0) return;
      this.drawCursor(ctx, cursor);
    });
  }

  redraw(ctx) {
    if (!this.cursors) return;

    d3.selectAll(`.${this.props.chartName}.cursorGroup`).remove();

    // TODO: dont redraw each line fully (this function is called on brush)
    // calculate if next line needs to be visible + if other line needs to be removed
    this.cursors.forEach((cursor) => {
      if (ctx.x(cursor.x) > ctx.chart.getBoundingClientRect().width || ctx.x(cursor.x) < 0) return;
      this.drawCursor(ctx, cursor);
    });
  }

  redrawCursors(ctx){
    if (this.cursors) {
      d3.selectAll(`.${this.props.chartName}.cursorGroup`).remove();
      this.cursors.forEach((cursor) => {
        if (ctx.x(cursor.x) > ctx.chart.getBoundingClientRect().width || ctx.x(cursor.x) < 0) return;
        this.drawCursor(ctx, cursor);
      });
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.user,
});

WavefromChart.displayName = 'WavefromChart';
export default connect(
  mapStateToProps
)(LineChart(WavefromChart));
