import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import HealthIconSvg from 'common/images/HealthIconSvg';
import { Task, Wrench } from 'common/images/FaIcons';
import NoteSvgNew from 'common/images/NoteSvgNew';
import { history } from '../../../common/helpers/history';
import Text_T from '../../../common/typography/Text/Text';
import AlarmFlagSvg from '../../../common/images/AlarmFlagSvg';
import OverallAlarmWarningNew from '../../../common/images/OverallAlarmWarningNew';
import OverallAlarmCriticalNew from '../../../common/images/OverallAlarmCriticalNew';
import MoteUnreachableNew from '../../../common/images/MoteUnreachableNew';
import MachineSvg from '../../../common/images/MachineSvg';
import NewBatteryIcon from '../../../common/images/NewBatteryIcon';
import { humanize } from '../../../common/helpers/humanize';

import colors from '../../../common/styles/colors';

export const Text = styled(Text_T)`
  font-size: 13px;
  ${props => props.hover && `
    &:hover {
      color: ${props.theme.primaryColor};
      cursor: pointer;
    }
  `}
`;

const onSensorClick = (e, serial_number) => {
  e.stopPropagation();
  history.push('/devices', { serial_number });
};

const onMachineClick = (e, machine_id) => {
  e.stopPropagation();
  history.push(`/machines/${machine_id}/overview`);
};

const DescriptionContainer = styled.div`
${props => props.marginTop && 'margin-top: 0.5em;'}
  > ${Text} {
    margin-bottom: 0.5em;
  }
`;

const getSensorType = SSN => (SSN.split('-')[0] === 'TX' ? 'transmitter' : 'mote');
const getSpeedRangeText = (threshold_value) => {
  if (!threshold_value) return '';
  return `for speed range - ${threshold_value}`;
};


const activityFormat = {
  vibration_warning: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> machine has a <Text bold>vibration warning alarm</Text> on the <Text bold>{humanize(activity.location)}</Text> at <Text bold>{activity.component_name}</Text> in <Text bold>{activity.direction}</Text> direction</Text>,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibrations have exceeded warning alarm on the {humanize(activity.location)} at {activity.component_name} in {activity.direction} direction. </Text>
          <Text>The latest RMS of {activity.rms} {activity.units} exceeds the threshold of {activity.threshold_value} {activity.units} by {activity.percent_increase}% {getSpeedRangeText(activity.threshold_type)} . </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmWarningNew />
  },
  vibration_critical: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> machine has a <Text bold>vibration critical alarm</Text> on the <Text bold>{humanize(activity.location)}</Text> at <Text bold>{activity.component_name}</Text> in <Text bold>{activity.direction}</Text> direction</Text>,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibrations have exceeded critical alarm on the {humanize(activity.location)} at {activity.component_name} in {activity.direction} direction. </Text>
          <Text>The latest RMS of {activity.rms} {activity.units} exceeds the threshold of {activity.threshold_value} {activity.units} by {activity.percent_increase}% {getSpeedRangeText(activity.threshold_type)} . </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmCriticalNew />
  },
  vibration_demod_warning: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> machine has a <Text bold> demod vibration warning alarm</Text> on the <Text bold>{humanize(activity.location)}</Text> at <Text bold>{activity.component_name}</Text> in <Text bold>{activity.direction}</Text> direction</Text>,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibrations have exceeded {activity.alarm_level_prefix} {activity.alarm_level} alarm on the {humanize(activity.location)} at {activity.component_name} in {activity.direction} direction.</Text>
          <Text>The latest {activity.alarm_level_prefix} RMS of {activity.rms} {activity.units} exceeds the threshold of {activity.threshold_value} {activity.units} by {activity.percent_increase}% {getSpeedRangeText(activity.threshold_type)} . </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmWarningNew />
  },
  vibration_demod_critical: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> machine has a <Text bold>demod vibration critical alarm</Text> on the <Text bold>{humanize(activity.location)}</Text> at <Text bold>{activity.component_name}</Text> in <Text bold>{activity.direction}</Text> direction</Text>,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibrations have exceeded {activity.alarm_level_prefix} {activity.alarm_level} alarm on the {humanize(activity.location)} at {activity.component_name} in {activity.direction} direction.</Text>
          <Text>The latest {activity.alarm_level_prefix} RMS of {activity.rms} {activity.units} exceeds the threshold of {activity.threshold_value} {activity.units} by {activity.percent_increase}% {getSpeedRangeText(activity.threshold_type)} . </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmCriticalNew />
  },
  vibration_acceleration_warning: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> machine has a <Text bold>vibration acceleration warning alarm</Text> on the <Text bold>{humanize(activity.location)}</Text> at <Text bold>{activity.component_name}</Text> in <Text bold>{activity.direction}</Text> direction</Text>,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibrations have exceeded {activity.alarm_level_prefix} {activity.alarm_level} alarm on the {humanize(activity.location)} at {activity.component_name} in {activity.direction} direction.</Text>
          <Text>The latest {activity.alarm_level_prefix} RMS of {activity.rms} {activity.units} exceeds the threshold of {activity.threshold_value} {activity.units} by {activity.percent_increase}% {getSpeedRangeText(activity.threshold_type)} . </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmWarningNew />
  },
  vibration_acceleration_critical: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> machine has a <Text bold>vibration acceleration critical alarm</Text> on the <Text bold>{humanize(activity.location)}</Text> at <Text bold>{activity.component_name}</Text> in <Text bold>{activity.direction}</Text> direction</Text>,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibrations have exceeded {activity.alarm_level_prefix} {activity.alarm_level} alarm on the {humanize(activity.location)} at {activity.component_name} in {activity.direction} direction.</Text>
          <Text>The latest {activity.alarm_level_prefix} RMS of {activity.rms} {activity.units} exceeds the threshold of {activity.threshold_value} {activity.units} by {activity.percent_increase}% {getSpeedRangeText(activity.threshold_type)} . </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmCriticalNew />
  },
  vibration_envelope_alarm: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> machine has a <Text bold>vibration envelope alarm</Text> on the <Text bold>{humanize(activity.location)}</Text> at <Text bold>{activity.component_name}</Text> in <Text bold>{activity.direction}</Text> direction</Text>,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Vibration spectrum exceeded envelope limit {getSpeedRangeText(activity.threshold_type)}. </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
        <DescriptionContainer marginTop>
          {activity.triggered_threshold_types && activity.triggered_threshold_types.length > 1 && (
            <Text>
              Also triggered on speed ranges - {activity.triggered_threshold_types.filter(t => t !== activity.threshold_type).join(', ')}
            </Text>
          )}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmCriticalNew />
  },
  current_envelope_alarm: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> machine has a <Text bold>current envelope alarm</Text> on the <Text bold>{humanize(activity.location)}</Text> at <Text bold>{activity.component_name}</Text></Text>,
    description: activity => (
      <>
        <DescriptionContainer>
          <Text>Current spectrum exceeded envelope limit. </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
      </>
    ),
    icon: () => <OverallAlarmCriticalNew />
  },
  machine_condition_warning: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> is in <Text bold>warning</Text> condition</Text>,
    description: activity => (
      <DescriptionContainer>
        <Text>This machine condition has changed to warning at {activity.created_at}. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <MachineSvg fill={colors.warning} />
  },
  machine_condition_critical: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> is in <Text bold>critical</Text> condition</Text>,
    description: activity => (
      <DescriptionContainer>
        <Text>This machine condition has changed to critical at {activity.created_at}. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <MachineSvg fill={colors.critical} />
  },
  health_score_warning: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> has a <Text bold>health score warning</Text> alarm</Text>,
    description: activity => (
      <DescriptionContainer>
        <Text>The health score {activity.change_direction} to </Text>
        <Text>{activity.parameter_value} which is {activity.comparision_string}. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <AlarmFlagSvg fill={colors.alarmWarning} />
  },
  health_score_critical: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> has a <Text bold>health score critical</Text> alarm</Text>,
    description: activity => (
      <DescriptionContainer>
        <Text>The health score {activity.change_direction} to </Text>
        <Text>{activity.parameter_value} which is {activity.comparision_string}. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <AlarmFlagSvg fill={colors.alarmCritical} />
  },
  battery_level_warning: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> {getSensorType(activity.sensor_serial_number)} <Text bold hover onClick={e => onSensorClick(e, activity.sensor_serial_number)}>{activity.sensor_serial_number} </Text> has a <Text bold>battery level warning</Text> alarm</Text>,
    description: activity => (
      <DescriptionContainer>
        <Text>The mote on {humanize(activity.location)} has less than {activity.battery_level} battery remaining. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <NewBatteryIcon fill="#F0C000" />
  },
  battery_level_critical: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> {getSensorType(activity.sensor_serial_number)} <Text bold hover onClick={e => onSensorClick(e, activity.sensor_serial_number)}>{activity.sensor_serial_number} </Text> has a <Text bold>battery level critical</Text> alarm</Text>,
    description: activity => (
      <DescriptionContainer>
        <Text>The mote on {humanize(activity.location)} has less than {activity.battery_level} battery remaining. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <NewBatteryIcon fill="#FF4F39" />
  },
  tx_battery_level_warning: {
    title: activity => <Text><Text bold hover onClick={e => onSensorClick(e, activity.sensor_serial_number)}>{activity.sensor_serial_number}</Text> has a <Text bold>tx battery level warning</Text> alarm</Text>,
    description: activity => (
      <DescriptionContainer>
        <Text>This transmitter has less than {activity.battery_level} battery remaining. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <NewBatteryIcon fill="#F0C000" />
  },
  tx_battery_level_critical: {
    title: activity => <Text><Text bold hover onClick={e => onSensorClick(e, activity.sensor_serial_number)}>{activity.sensor_serial_number}</Text> has a <Text bold>battery level critical</Text> alarm</Text>,
    description: activity => (
      <DescriptionContainer>
        <Text>This transmitter has less than {activity.battery_level} battery remaining. </Text>
        {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => <NewBatteryIcon fill="#FF4F39" />
  },
  mote_unreachable: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> {getSensorType(activity.sensor_serial_number)} <Text bold hover onClick={e => onSensorClick(e, activity.sensor_serial_number)}>{activity.sensor_serial_number} </Text> is unreachable</Text>,
    description: activity => (
      <DescriptionContainer>
        {!activity.closed_at && (
          <>
            <Text>This mote on {humanize(activity.location)} is unreachable since {activity.duration}.</Text>
            <Text>It sent last reading at {activity.last_reporting_time}. </Text>
          </>
        )}

        {activity.closed_at && <Text>This mote on {humanize(activity.location)} was unreachable for {activity.duration} between {activity.last_reporting_time} and {activity.closed_at}.</Text>}
      </DescriptionContainer>
    ),
    icon: () => <MoteUnreachableNew fill="red" />
  },
  tx_unreachable: {
    title: activity => <Text><Text bold hover onClick={e => onSensorClick(e, activity.sensor_serial_number)}>{activity.sensor_serial_number}</Text> is unreachable</Text>,
    description: activity => (
      <DescriptionContainer>
        {!activity.closed_at && (
          <>
            <Text>This transmitter is unreachable since {activity.duration}. </Text>
            <Text>It last reported at {activity.last_reporting_time}. </Text>
          </>
        )}
        {activity.closed_at && <Text>This transmitter was unreachable for {activity.duration} between {activity.last_reporting_time} and {activity.closed_at}.</Text>}
      </DescriptionContainer>
    ),
    icon: () => <AlarmFlagSvg fill={colors.alarmCritical} />
  },
  custom: {
    title: activity => <>
    <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> has <Text bold>{humanize(activity.event_type)} alarm</Text>
    {activity.location && <Text> on the <Text bold>{humanize(activity.location)}</Text></Text>}
    {activity.direction && <Text> in <Text bold>{activity.direction}</Text> direction </Text>}
    </Text>
    </>,
    description: activity => (
      <>
      {activity.parameter_type !== 'health_score' ? (
        <DescriptionContainer>
          {activity.location && <Text>The {activity.parameter_type} on {humanize(activity.location)} {activity.change_direction} to </Text>}
          {activity.location && <Text>{activity.parameter_value} which is {activity.comparision_string}. </Text>}
          {!activity.location && <Text>The {activity.parameter_type} {activity.change_direction} to {activity.parameter_value}</Text>}
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
      ) : (
        <DescriptionContainer>
          <Text>The health score {activity.change_direction} to </Text>
          <Text>{activity.parameter_value} which is {activity.comparision_string}. </Text>
          {activity.closed_at && <Text>This event was closed on {activity.closed_at}. </Text>}
        </DescriptionContainer>
      )}
      </>
    ),
    icon: activity => <AlarmFlagSvg fill={activity.event_type && activity.event_type.includes('critical') ? colors.alarmCritical : colors.alarmWarning} />
  },
  diagnosis: {
    title: activity => <>
    <Text><Text bold>{activity.rule_name}</Text> triggered on <Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text>
    {activity.location && <Text> at <Text bold>{humanize(activity.location)}</Text></Text>}
    </Text>
    </>,
    description: activity => (
      <DescriptionContainer>
        <Text>{activity.recommendation}</Text>
      </DescriptionContainer>
    ),
    icon: () => <HealthIconSvg />
  },
  new_operation_modes: {
    title: activity => <Text>New operating mode detected on <Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text></Text>,
    description: () => (
      <DescriptionContainer>
        <Text>Re-configure VFD speed ranges.</Text>
      </DescriptionContainer>
    ),
    icon: () => ''
  },
  baseline_missing_for_operation_modes: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> has baselines missing for few operating modes at <Text bold>{activity.component_name}</Text></Text>,
    description: activity => (
      <DescriptionContainer>
        <Text>Re-baseline to include these operation modes.</Text>
        {!_.isEmpty(activity.speed_info) && <Text> Feedback raised for speed ranges - {activity.speed_info.filter(info => info.speed_bin).map(info => info.speed_bin).join(', ')}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => ''
  },
  baseline_higher_than_measurement_value: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> vibrations changed from earlier indicated normal behavior at <Text bold>{activity.component_name}</Text></Text>,
    description: activity => (
      <DescriptionContainer>
        <Text>Re-baseline asset with recent data.</Text>
        {!_.isEmpty(activity.speed_info) && <Text> Feedback raised for speed ranges - {activity.speed_info.filter(info => info.speed_bin).map(info => info.speed_bin).join(', ')}. </Text>}
      </DescriptionContainer>
    ),
    icon: () => ''
  },
  task: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> has a task <Text bold>{activity.name}</Text> by <Text bold>{activity.user_name}</Text></Text>,
    icon: () => <Task />
  },
  repair: {
    title: activity => <Text><Text bold hover onClick={e => onMachineClick(e, activity.machine_id)}>{activity.machine_name}</Text> has a repair <Text bold>{activity.name}</Text> by <Text bold>{activity.user_name}</Text></Text>,
    icon: () => <Wrench />
  },
  note: {
    icon: () => <NoteSvgNew />
  }
};

export default activityFormat;
