import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';


import Label from 'common/typography/Label/Label';
import Button from 'common/components/atoms/Button';
import FlexContainer from 'common/components/atoms/FlexContainer';
import Modal from 'common/components/organisms/ModalV2';
import InputField from 'common/components/atoms/InputField';
import AlertPrompt from 'common/components/organisms/AlertPrompt';

import * as firmwareDeploymentActions from '../actions/firmwareDeployment.actions';
import {
  DEVICE_TYPES,
  FIRMWARE_TYPES,
  ASSOCIATION_TYPES,
  firmwareDeploymentConstants
} from '../constants/firmwareDeployment.constants';

const DeploymentModalContainer = styled(FlexContainer)`
  line-height: 0.2em;
`;

class AddDeploymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device_type: 'tx',
      firmware_type: '',
      association_type: '',
      name: '',
      fw_id: '',
      associations: [],
      verifyDeviceDeploymentModalOpen: false,
      verifyDeviceDeploymentDetails: {}
    };
  }

  renderAccountOptions = () => this.props.user.accounts.map(account => ({ value: account.id, text: account.name }))

  renderFirmwareOptions = () => {
    const { metadata } = this.props;
    const firmwares = metadata.firmwares.map(
      fw => ({ value: fw.id, text: fw.version, model_version: fw.model_version })
    ) || [];

    return firmwares.filter((fw) => {
      if (this.state.device_type === firmwareDeploymentConstants.DEVICE_TYPE_MOTE) {
        return firmwareDeploymentConstants.MOTE_MODEL_VERSION === fw.model_version;
      }
      return firmwareDeploymentConstants.TX_MODEL_VERSION === fw.model_version;
    });
  };


  renderDeviceOptions = () => {
    const {
      fw_id,
      device_type
    } = this.state;
    const firmware = fw_id && this.props.metadata.firmwares.find(it => it.id === fw_id);

    if (!firmware) return [];

    return _.filter(
      device_type === 'tx' ? this.props.metadata.tx : this.props.metadata.motes,
      device => (_.isEmpty(firmware.account_ids) || _.includes(firmware.account_ids, device.account_id)) && device.model === firmware.model_version
    ).map(
      device => ({ value: device.id, text: device.serial_number })
    );
  }

  onClickAdd = () => {
    const {
      device_type,
      association_type,
      firmware_type,
      associations
    } = this.state;

    const { verifyDeviceDeployment } = this.props.firmwareDeploymentActions;
    if (association_type === 'devices') {
      const params = {
        device_ids: associations,
        device_type,
        firmware_type
      };
      verifyDeviceDeployment(params).then(
        (res) => {
          if (res.invalid === 1) {
            this.setState({
              verifyDeviceDeploymentModalOpen: true,
              verifyDeviceDeploymentDetails: res
            });
          } else {
            this.createDeployment();
          }
        }, () => {}
      );
      return;
    }
    this.createDeployment();
  }

  createDeployment = () => {
    const {
      device_type,
      association_type,
      fw_id,
      name,
      associations
    } = this.state;
    const { addFirmwareDeployment } = this.props.firmwareDeploymentActions;

    let paramsList = [];
    switch (association_type) {
      case 'devices':
        paramsList = [{
          device_type,
          fw_id,
          name,
          association_type,
          device_ids: associations
        }];
        break;
      case 'firmwares':
        paramsList = [{
          device_type,
          fw_id,
          name,
          association_type,
          firmware_ids: associations
        }];
        break;
      case 'accounts':
        paramsList = associations.map(account_id => ({
          association_type,
          device_ids: [],
          device_type,
          firmware_ids: [],
          fw_id,
          name,
          account_id
        }));
        break;
      default:
    }
    paramsList.map(params => addFirmwareDeployment(params));

    this.props.close();
  }

  closeVerifyDeviceDeploymentModal = () => this.setState({ verifyDeviceDeploymentModalOpen: false })

  render() {
    const {
      close,
    } = this.props;
    const {
      device_type,
      firmware_type,
      association_type,
      fw_id,
      name,
      associations,
      verifyDeviceDeploymentDetails,
      verifyDeviceDeploymentModalOpen
    } = this.state;
    return (
    <>
      <Modal
        width="30%"
        close={close}
      >
        <DeploymentModalContainer direction="column" marginbottom="40px" margintop="40px">
          <FlexContainer direction="column" alignItems="left" marginbottom="40px">
            <Label fontWeight="300" fontSize="20px" marginBottom="8px">Add Deployment</Label>
          </FlexContainer>
            <InputField
              label="Deployment Name"
              maxLength={50}
              onChange={e => this.setState({ name: e.target.value })}
              placeholder="Enter deployment name"
              type="text"
              search
              value={name}
            />
            <InputField
              label="Device Type"
              maxLength={50}
              onChange={(e, d) => this.setState({
                device_type: d.value,
                firmware_type: '',
                association_type: '',
                fw_id: ''
              })}
              placeholder="Click to select device type"
              type="select"
              search
              value={device_type}
              options={DEVICE_TYPES}
            />
            <InputField
              label="Firmware Type"
              maxLength={50}
              onChange={(e, d) => this.setState({ firmware_type: d.value })}
              placeholder="Click to select Firmware type"
              type="select"
              search
              value={firmware_type}
              options={FIRMWARE_TYPES}
            />
            {firmware_type.length > 0 && (
              <InputField
                label="Target Firmware Version"
                maxLength={50}
                onChange={(e, d) => this.setState({ fw_id: d.value })}
                placeholder="Click to select target firmware version"
                type="select"
                search
                value={fw_id}
                options={this.renderFirmwareOptions()}
              />
            )}
            {fw_id && (
              <InputField
                label="Association Type"
                maxLength={50}
                onChange={(e, d) => {
                  this.setState({ association_type: d.value });
                }}
                placeholder="Click to select association type"
                type="select"
                search
                value={association_type}
                options={ASSOCIATION_TYPES}
              />
            )}

            { association_type === 'devices' && (
                <InputField
                  label="Associated Devices"
                  onChange={(e, d) => this.setState({ associations: d.value })}
                  placeholder="Associated Devices"
                  multiple
                  type="select"
                  search
                  value={associations}
                  options={this.renderDeviceOptions()}
                />
            )}
            { association_type === 'firmwares' && (
                <InputField
                  label="Associated Firmwares"
                  onChange={(e, d) => this.setState({ associations: d.value })}
                  placeholder="Associated Firmwares"
                  multiple
                  type="select"
                  search
                  value={associations}
                  options={this.renderFirmwareOptions()}
                />
            )}
            { association_type === 'accounts' && (
                <InputField
                  label="Associated Accounts"
                  onChange={(e, d) => this.setState({ associations: d.value })}
                  placeholder="Associated Accounts"
                  multiple
                  type="select"
                  search
                  value={associations}
                  options={this.renderAccountOptions()}
                />
            )}
          <FlexContainer justifyContent="right">
            <Button onClick={close} cancel margin="0 20px 0 0">Cancel</Button>
            <Button
              onClick={this.onClickAdd}
              disabled={!(name && device_type && fw_id && association_type && associations)}
            >
              Add
            </Button>
          </FlexContainer>
        </DeploymentModalContainer>
      </Modal>
      {verifyDeviceDeploymentModalOpen && (
        <AlertPrompt
          infoMessage="Following devices are associated with other device level deployments. They will be removed from previous associations"
          message=" Do you wish to continue ?"
          component={`${verifyDeviceDeploymentDetails.device_deployment_mapping.map(d => `${d.device_serial_number} : ${d.deployment_ids}`).join(', ')}`}
          onProceed={this.createDeployment}
          onCancel={this.closeVerifyDeviceDeploymentModal}
        />
      )}
    </>
    );
  }
}


AddDeploymentModal.propTypes = {
  close: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.user,
  metadata: state.firmwareDeployments.metadata
});

const mapDispatchToProps = dispatch => ({
  firmwareDeploymentActions: bindActionCreators(firmwareDeploymentActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDeploymentModal);
