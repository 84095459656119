import PropTypes from 'prop-types';
import React from 'react';

import FlexContainer from 'common/components/atoms/FlexContainer';
import H2 from 'common/typography/H2/H2';
import InputField from 'common/components/atoms/InputField';

import {
  ALGO_OPTIONS,
} from '../../../constants/overallAlarm.constants';


const OverallAlarmThresholdSettings = ({
  disabled,
  thresholdSettings,
  updateOverallAlarmSettings,
  yUnit
}) => (
  <FlexContainer marginright="2em" direction="column">
    <H2>Settings</H2>
    <InputField
      type="select"
      label="Type"
      options={ALGO_OPTIONS}
      value={thresholdSettings.type}
      onChange={(e, data) => {
        updateOverallAlarmSettings('threshold', {
          ...thresholdSettings,
          type: data.value
        });
      }}
      disabled={disabled}
    />
    <InputField
      type="number"
      label="Warning alarm factor"
      value={thresholdSettings.warning_factor}
      onChange={(e) => {
        updateOverallAlarmSettings('threshold', {
          ...thresholdSettings,
          warning_factor: parseFloat(e.target.value) || null
        });
      }}
      disabled={disabled}
      prefix={thresholdSettings.type === 'offset' && yUnit}
    />
    <InputField
      type="number"
      label="Critical alarm factor"
      value={thresholdSettings.critical_factor}
      onChange={(e) => {
        updateOverallAlarmSettings('threshold', {
          ...thresholdSettings,
          critical_factor: parseFloat(e.target.value) || null
        });
      }}
      disabled={disabled}
      prefix={thresholdSettings.type === 'offset' && yUnit}
    />
  </FlexContainer>
);

OverallAlarmThresholdSettings.propTypes = {
  updateOverallAlarmSettings: PropTypes.func.isRequired,
  thresholdSettings: PropTypes.object,
  disabled: PropTypes.bool,
  yUnit: PropTypes.string.isRequired
};

OverallAlarmThresholdSettings.defaultProps = {
  thresholdSettings: {},
  disabled: false,
};

export default OverallAlarmThresholdSettings;
