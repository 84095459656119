export const AccountsTypes = {
  GET_ACCOUNTSLIST_DATA_REQUEST: 'GET_ACCOUNTSLIST_DATA_REQUEST',
  GET_ACCOUNTSLIST_DATA_SUCCESS: 'GET_ACCOUNTSLIST_DATA_SUCCESS',
  GET_ACCOUNTSLIST_DATA_FAILURE: 'GET_ACCOUNTSLIST_DATA_FAILURE',

  NUMBER_OF_ACCOUNTS_PER_PAGE: 80,
  INCREASE_ACCOUNTSLIST_PAGE: 'INCREASE_ACCOUNTSLIST_PAGE',

  SET_ACCOUNTS_SORTER: 'SET_ACCOUNTS_SORTER',
};

export const INDUSTRY_TYPES = [
  { value: 'Power Generation', text: 'Power Generation' },
  { value: 'Pharmaceuticals', text: 'Pharmaceuticals' },
  { value: 'Oil and Gas', text: 'Oil and Gas' },
  { value: 'Buildings and Facilties', text: 'Buildings and Facilties' },
  { value: 'Food and Beverage', text: 'Food and Beverage' },
  { value: 'Metals', text: 'Metals' },
  { value: 'Others', text: 'Others' },
];
