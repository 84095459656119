import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as _ from 'lodash';
import RBAC from 'common/rbac/RBAC';
import {
  CRITICALITY_MAP,
  MACHINE_STATE,
  ColorAccToCondition
} from 'common/constants';
import {
  FlexContainer, Button
} from 'common/components/atoms';
import { timeSince } from 'common/helpers/time';
import { mapComponentToResource, operations } from 'common/rbac/constants';
import * as taskActions from 'home/HomePage/actions/tasks.actions';
import * as repairActions from 'home/HomePage/actions/repairs.actions';
import * as noteActions from 'home/HomePage/actions/notes.actions';
import theme from 'common/styles/theme';
import SummaryDropdown from './organisms/SummaryDropdown';
import * as analystSummaryActions from '../Machines/MachineDetails/Health/actions/analystSummary.actions';
import * as machineDetailsActions from '../Machines/MachineDetails/machineDetails.actions';
import * as failureModesActions from '../MachineDiagnostics/actions/failureModes.actions';
import ColoredCircle from '../Machines/MachineDetails/Analyst/ColoredCircle';
import * as assetDetailsActions from '../AssetHierarchy/actions/assetDetails.actions';

const DetailsHeader = styled(FlexContainer)`
  position: fixed;
  display: inline-flex;
  top: 0.7em;
  right: ${props => props.right};
  z-index: 3;

  @media (max-width: 1046px) {
    right: 10em;

    & > :nth-child(2),
    & > :nth-child(3) {
      display: none;
    }

  @media (min-width: 1046px) {
    right: 22em;
  }
`;


const HeaderTitle = styled.span`
  font-size: 13px;
  margin: 0.4em 0em;
  color: ${props => props.theme.colors.darkGray};
`;

const HeaderValue = styled(FlexContainer)`
  font-size: 13px;
  font-weight: 300;
  margin: 0.4em 1em 0.4em 0.4em;
  color: ${props => props.theme.colors.darkGray};
`;

const initialState = {
  analystWindow: {
    show: false,
    minimised: false,
    editing: false
  },
  pageType: '',
  isSmallScreen: window.innerWidth <= 700,
  analystSummary: {
    condition: '',
    suspected_defect: '',
    description: '',
    saved: false
  },
};

class AnalystSummaryView extends React.Component {
  constructor(props) {
    super(props);

    this.state = _.cloneDeep(initialState);
    this.minimiseAnalystWindow = this.minimiseAnalystWindow.bind(this);
    this.showAnalystWindow = this.showAnalystWindow.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);
    this.updateSummary = this.updateSummary.bind(this);
    this.saveSummary = this.saveSummary.bind(this);
    this.excludeRoutes = [
      'site-profile',
      'event-definitions',
      'sensor-models',
      'saas-agreement',
      'users',
      'profile'
    ];
  }

  componentDidMount() {
    const { machineDetails, machine_id } = this.props;
    if (!machineDetails.object) {
      this.props.machineDetailsActions.getMachineDetails(machine_id);
    }
    this.props.failureModesActions.getFailureModeList();
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidUpdate(prevProps) {
    if (this.props.analystWindowRedux.edit && !this.state.analystWindow.show) {
      this.showAnalystWindow(this.props.analystWindowRedux.edit);
    }
    if (prevProps.machine_id !== this.props.machine_id) this.props.machineDetailsActions.getMachineDetails(this.props.machine_id);
  }

  handleWindowSizeChange = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 700 });
  };

  updateSummary(e) {
    const { analystSummary } = this.state;
    if (e.target.value.length <= 1000) analystSummary.description = e.target.value;
    this.setState({ analystSummary });
  }

  resetSummaryState = () => {
    this.setState(_.cloneDeep(initialState));
  }

  saveSummary() {
    const { machine_id } = this.props;
    if (!machine_id) return;
    const analystSummaryT = { ...this.state.analystSummary };
    if (analystSummaryT.suspected_defect !== 'N/A') {
      const defect_option = this.props.failureModes.list
        .filter(option => option.name === analystSummaryT.suspected_defect);
      const id = defect_option[0].id;
      analystSummaryT.suspected_defect = id;
    } else {
      analystSummaryT.suspected_defect = null;
    }
    analystSummaryT.condition = analystSummaryT.condition.toLowerCase();
    if (!analystSummaryT.id) {
      const analystSummary = _.pick(analystSummaryT, ['condition', 'description', 'suspected_defect']);
      this.props.analystSummaryActions.postAnalystSummary(machine_id, { ...analystSummary })
        .then(() => {
          this.props.analystSummaryActions.getLatestAnalystSummary(machine_id);
          this.props.machineDetailsActions.getMachineDetails(machine_id);
          this.resetSummaryState();
          this.props.assetDetailsActions.getHierarchy(false);
        });
    } else {
      const analystSummary = _.pick(analystSummaryT, ['description', 'suspected_defect']);
      this.props.analystSummaryActions.putAnalystSummary(machine_id, analystSummaryT.id, { ...analystSummary })
        .then(() => {
          this.props.analystSummaryActions.getLatestAnalystSummary(machine_id);
          this.props.machineDetailsActions.getMachineDetails(machine_id);
          this.resetSummaryState();
        });
    }
    this.showAnalystWindow(false);
  }

  onSelectHandler(field) {
    return (condition) => {
      this.setState(prevState => ({
        analystSummary: {
          ...prevState.analystSummary,
          [field]: condition
        }
      }));
    };
  }

  minimiseAnalystWindow() {
    const editing = Number.isInteger(this.state.analystSummary.id);
    this.setState(prevState => ({
      analystWindow: {
        minimised: !prevState.analystWindow.minimised,
        show: true,
        editing
      }
    }));
    this.props.analystSummaryActions.setAnalystSummaryWindow(true);
  }

  getLocalSummary = () => this.state.analystSummary;

  showAnalystWindow(value, isNew = false) {
    let analystSummary = {};
    if (!isNew) {
      analystSummary = { ...this.props.analystSummary };
      analystSummary.condition = this.getConditionString(analystSummary.condition);
      analystSummary.suspected_defect = this.getDefectString(analystSummary.suspected_defect);
    }
    this.setState({
      analystWindow: {
        show: value,
        editing: !isNew
      },
      analystSummary
    });
    if (!isNew) this.props.analystSummaryActions.setAnalystSummaryWindow(value);
  }

  getDefectString = (suspected_defect) => {
    if (typeof suspected_defect !== 'number') return suspected_defect;
    return this.props.failureModes.list.filter(option => option.id === suspected_defect)[0].name;
  }

  getConditionString = (condition) => {
    if (typeof condition !== 'number') return condition;
    switch (condition) {
      case 1:
        return 'Good';
      case 2:
        return 'Warning';
      case 3:
        return 'Critical';
      default:
        return null;
    }
  }

  removeMachineDetailsHeader = () => {
    const { location } = this.props;
    const paths = location.pathname.split('/');
    if (location.pathname === '/') paths[1] = 'dashboard';
    if (this.excludeRoutes.includes(paths[1])) return true;
    return false;
  }

  openModal = (type) => {
    switch (type) {
      case 'task':
        this.props.taskActions.openTaskModal();
        break;
      case 'repair':
        this.props.repairActions.openRepairModal();
        break;
      case 'note':
        this.props.noteActions.openNoteModal();
        break;
      default:
        break;
    }
  }

  render() {
    const { machineDetails } = this.props;
    const { isSmallScreen } = this.state;

    let conditionContent = null;
    if (machineDetails.object && !this.removeMachineDetailsHeader()) {
      const coloredCircleStyle = isSmallScreen ? { paddingTop: '8px' } : {};
      if (isSmallScreen) {
        conditionContent = (
          <HeaderValue alignItems="center" className="header-value">
            <Button
              title={timeSince(machineDetails.object.condition_since)}
              secondary={theme.colors.black}
              className="toolbar-item"
              style={{ border: 'none', paddingTop: '8px' }}
            >
              <ColoredCircle type={machineDetails.object.condition} />
            </Button>
          </HeaderValue>
        );
      } else {
        conditionContent = (
          <HeaderValue alignItems="center">
            <HeaderTitle>Condition:</HeaderTitle>
            <ColoredCircle type={machineDetails.object.condition} style={{ marginLeft: '5px' }} />
            <div style={{ color: ColorAccToCondition(machineDetails.object.condition, 'text') }}>
              {timeSince(machineDetails.object.condition_since)}
            </div>
          </HeaderValue>
        );
      }
      return (
        <div className="machine-details">
          <div>
            <RBAC
              resource={mapComponentToResource.Notes}
              operation={operations.Create}
              yes={<SummaryDropdown openModal={this.props.openModal ? this.props.openModal : this.openModal} />}
            />
          </div>
          <DetailsHeader direction="row" alignItems="center" right={this.props.right ? this.props.right : '25em'}>
            <FlexContainer>
             {conditionContent}
            </FlexContainer>
            <FlexContainer>
             <HeaderTitle>Current state:</HeaderTitle>
             <HeaderValue>
              {MACHINE_STATE[machineDetails.object.current_state] || 'NA'}
             </HeaderValue>
            </FlexContainer>
            <FlexContainer>
              <HeaderTitle>Criticality:</HeaderTitle>
              <HeaderValue>
               {CRITICALITY_MAP[machineDetails.object.criticality] || 'NA'}
              </HeaderValue>
            </FlexContainer>
          </DetailsHeader>
        </div>
      );
    }
    return <div />;
  }
}

const mapStateToProps = state => ({
  machineDetails: state.machineDetails.details,
  currentUser: state.user.user,
  analystSummary: state.machineDetails.AS.summary,
  analystWindowRedux: state.machineDetails.AS.window,
  assetConditionLevels: state.currentAccount.preferences.machine_condition_levels,
  failureModes: state.assetFailureMode.failureModes
});

const mapDispatchToProps = dispatch => ({
  machineDetailsActions: bindActionCreators(machineDetailsActions, dispatch),
  analystSummaryActions: bindActionCreators(analystSummaryActions, dispatch),
  assetDetailsActions: bindActionCreators(assetDetailsActions, dispatch),
  failureModesActions: bindActionCreators(failureModesActions, dispatch),
  taskActions: bindActionCreators(taskActions, dispatch),
  repairActions: bindActionCreators(repairActions, dispatch),
  noteActions: bindActionCreators(noteActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(AnalystSummaryView);
