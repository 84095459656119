/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import * as d3 from 'd3';

import * as helpers from 'common/components/Chart/utils/helpers';

import { chartColors } from 'common/constants';
import { Chart } from 'common/components/Chart';
import * as utils from 'common/utils';
import { humanize } from 'common/helpers';
import { calendarOptions, mapNames } from 'common/charts/constants';
import MultilineSelection from '../components/MultilineSelection';


class SplitableMultilineChart extends React.Component {
  constructor(props) {
    super(props);
    const idx = calendarOptions.findIndex(el => el.value === props.days);
    const selectedRange = idx < 0 ? 'custom' : calendarOptions[idx].text;
    this.state = {
      data: [],
      selectedRange,
      selections: {},
      transform: {
        transformX: 0,
        transformY: 0,
        kx: 1,
        ky: 1
      },
      axisLocked: { x: false, y: true },
      brushRange: [],
      points: []
    };
  }

  componentDidMount() {
    this.calculateChartDataModifications();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.multiline_data, this.props.multiline_data)) {
      this.calculateChartDataModifications();
    }
  }

  setInitialSelection = () => {
    if (this.props.noHover) return;

    const selections = this.state.data.map((data, idx) => {
      if (_.isEmpty(data)) return null;
      return { ...data[data.length - 1], dataIdx: idx };
    }).filter(item => item);

    if (selections) {
      this.setState({
        selections
      });
    }
  }

  calculateChartDataModifications = () => {
    const { multiline_data, mapDataToScale, yUnits, type, tags } = this.props;
    // base range for which the chart will be rendered and data scaled to
    const endRange = [0, 10];
    // object for holding ranges of individual unit types,
    // e.g. { in/s: [min, max] } will hold min and max values from all datasets that are in/s
    const unitRanges = {};
    // boolean to determine existance of data
    let hasData = false;
    const domain = [];
    const multiline_data_snapshot = _.cloneDeep(multiline_data);
    if (multiline_data_snapshot && multiline_data_snapshot.length > 0) {
      const unit_system = this.props.currentUser.unit_system;
      multiline_data_snapshot.forEach((item, idx) => {
        if (item.length > 0) {
          const dataDomain = d3.extent(item, d => d.x);
          if ((dataDomain[0] !== undefined && dataDomain[0] < domain[0]) || !domain[0]) domain[
            0] = dataDomain[0];
          if ((dataDomain[1] !== undefined && dataDomain[1] > domain[1]) || !domain[1]) domain[
            1] = dataDomain[1];
          hasData = true;
          const yUnit = yUnits[idx] || 'mm/s';
          const tag_type = tags && tags[idx] && tags[idx].type ? tags[idx].type : 'vibration';
          const itemRange = utils.chartRange(item, unit_system, yUnit, '', tag_type, type);
          if (unitRanges[yUnit] === undefined) {
            unitRanges[yUnit] = itemRange;
          } else {
            if (itemRange[1] > unitRanges[yUnit][1]) unitRanges[yUnit][1] = itemRange[1];
            if (itemRange[0] < unitRanges[yUnit][0]) unitRanges[yUnit][0] = itemRange[0];
          }
        }
      });
    }

    // map over all datapoints and give a scaled value for y
    // insert new key realY which will give the initial value of this datapoint (used on hover selection)
    let data = [];
    if (hasData) {
      if (mapDataToScale) {
        data = _.map(multiline_data_snapshot, (d, idx) => {
          if (_.isEqual(d, [])) return [];
          const dataWithScale = _.map(d, dataEntry => ({
            ...dataEntry,
            y: helpers.scaleDataEntry(dataEntry.y, unitRanges[yUnits[idx] || 'mm/s'], endRange),
            realY: dataEntry.y
          }));
          return dataWithScale;
        });
      } else {
        data = multiline_data_snapshot;
      }
    }
    this.setState(
      {
        data,
        domain,
        range: mapDataToScale || _.isEmpty(yUnits) ? endRange : unitRanges[yUnits[0]]
      },
      () => this.setInitialSelection()
    );
  }

  brushCb = (brushRange) => {
    this.setState({
      brushRange
    });
  }

  setSelections = (selections, idx = null) => {
    const { multiline_data } = this.props;
    const { data } = this.state;
    if (idx === null) {
      this.setState({
        selections: _.cloneDeep(selections)
      });
    } else {
      const s = multiline_data.map((t, i) => {
        if (!_.isEmpty(t)) {
          if (idx === i) return {
            ...selections,
            dataIdx: i
          };
          return {
            ...helpers.closestXDataPointOnDataset(data[i], selections.x),
            dataIdx: i
          };
        }
        return {};
      });
      this.setState({
        selections: s
      });
    }
    if (this.props.setLastHoveredChart) {
      this.props.setLastHoveredChart(this.props.type);
    }
  }

  storeTransform = (transform) => {
    this.setState(prevState => ({
      transform: {
        ...prevState.transform,
        ...transform
      }
    }));
  }

  toggleZoomLocks = (axisLocked) => {
    this.setState(prevState => ({
      axisLocked: {
        ...prevState.axisLocked,
        ...axisLocked
      }
    }));
  };

  resetChartsZoom = () => {
    const { axisLocked } = this.state;
    if (!axisLocked.x && !axisLocked.y) {
      this.setState({
        transform: {
          transformX: 0,
          transformY: 0,
          kx: 1,
          ky: 1
        }
      });
    } else if (!axisLocked.x) {
      this.setState(prevState => ({
        transform: {
          ...prevState.transform,
          transformX: 0,
          kx: 1,
        }
      }));
    } else if (!axisLocked.y) {
      this.setState(prevState => ({
        transform: {
          ...prevState.transform,
          transformY: 0,
          ky: 1,
        }
      }));
    }
  };

  setPointsToCorrectUnits = (points) => {
    this.setState({ points });
  }

  setShaftSpeedState = (shaftSpeed) => {
    this.setState({ shaftSpeed });
  }

  mouseClick = () => {
    const { type } = this.props;
    if (type === 'trend' || type === 'demod_trend') {
      if (this.props.mouseClick) this.props.mouseClick(this.state.selections);
    } else if (this.state.points === undefined || this.state.points.length === 0) {
      // determine if first or second click on the graph
      // if (this.state.points === undefined || this.state.points.length === 0) {
      this.setState({
        points: [_.cloneDeep(this.state.selections[0])]
      });
    } else if (this.state.points.length === 1) {
      this.setState({
        points: [...this.state.points, _.cloneDeep(this.state.selections[0])]
      });
    } else if (this.state.points.length === 2) {
      this.setState({
        points: []
      });
    }
  }

  toggleSpecFrequencyUnits = (units, spectrumData, idx = null) => {
    if (idx) {
      this.setState((prevState) => {
        const newData = _.cloneDeep(prevState.data);
        newData[idx] = spectrumData[0];
        return {
          spectrumXUnit: units,
          data: newData
        };
      });
    } else {
      this.setState({
        spectrumXUnit: units,
        data: spectrumData
      });
    }
  }

  getXUnit = () => {
    const { type, spectrums } = this.props;
    const { spectrumXUnit } = this.state;
    switch (type) {
      case 'spectrum':
      case 'demod_spectrum':
        return spectrumXUnit || spectrums[0].freq_units;
      case 'waveform':
        return 'ms';
      default:
        return '';
    }
  }

  getYLabel = (idx) => {
    const { tags, yUnits } = this.props;
    if (tags[idx] && tags[idx].type === 'vibration') return `Amplitude (${yUnits[idx]},0-Pk)`;
    return `Amplitude (${yUnits[idx]})`;
  }

  getTrendYLabel = (idx, tag_type) => {
    const { tags, yUnits } = this.props;
    if (tags && tags[idx] && tags[idx].structure_type === 'vector') return `${mapNames[tags[idx].feature] || utils.CapitalizeOnlyFirstLetter(tags[idx].feature)} (${yUnits[idx]})`;
    return tag_type ? `${humanize(tag_type)} (${yUnits[idx]})` : `${yUnits[idx]}`;
  }

  render() {
    const {
      multiline_data,
      split,
      titles,
      colors,
      yUnits,
      type,
      tags,
      spectrums,
      yParameters,
      yParametersReference,
      currentUser,
      config
    } = this.props;
    const { data, range, domain, axisLocked, transform, brushRange } = this.state;
    const tag_type = !_.isEmpty(tags) ? tags[0].type : null;
    const ampTypes = this.props.ampTypes || [];

    return (
      <Fragment>
        {!_.isEmpty(this.state.selections) && (
          <MultilineSelection
            selections={this.state.selections}
            spectrumFreqUnit={currentUser.frequency_units}
            shaftSpeed={this.state.shaftSpeed}
            type={type}
            xUnit={this.getXUnit()}
            yUnits={yUnits}
            colors={colors}
            yParameters={yParameters}
            config={config}
            yParametersReference={yParametersReference}
          />
        )}
        {(type === 'trend' || type === 'demod_trend') && (
          <>
          {split ? (
          <>
          {multiline_data.filter(t => !_.isEmpty(t)).map((t, idx) => (
            <div style={{ marginBottom: '15px' }} key={idx}>
              <Chart
                type={type}
                ylabel={this.getTrendYLabel(idx, tag_type)}
                height="120px"
                chart_size="Normal"
                transformFromParent={transform}
                mouseover={s => this.setSelections(s, idx)}
                mouseClick={this.mouseClick}
                points={!_.isEmpty(this.props.points) ? this.props.points[idx] : {}}
                chartName={`chart-splited-${type}-${tag_type}-${idx}`}
                title={!_.isEmpty(titles) ? titles[idx] : tags[idx].direction}
                domain={domain}
                axisLocked={axisLocked}
                yUnit={yUnits[idx]}
                ampType={ampTypes[idx]}
                selection={this.state.selections[idx]}
                colors={[colors[idx] || chartColors[idx]]}
                range={range}
                enableZoom
                resetChartsZoom={this.resetChartsZoom}
                toggleZoomLocks={this.toggleZoomLocks}
                showZoomLocks={idx === 0 ? 'y' : (idx === multiline_data.length - 1 ? 'x' : null)}
                data={data[idx] ? [data[idx]] : [[]]}
                outliersArray={data[idx] ? data[idx].filter(d => d.is_outlier) : []}
                storeTransform={this.storeTransform}
                brushCb={this.brushCb}
                lastHoveredChart={this.props.lastHoveredChart}
                setLastHoveredChart={this.props.setLastHoveredChart}
                expanded={idx === multiline_data.length - 1}
                paginationLoading={this.props.paginationLoading}
              />
            </div>
          ))}
          <Chart
            type="context"
            autoFill
            height="25px"
            chartName={`chart-splited-ctx-${type}-${tag_type}`}
            data={data}
            range={range}
            brushRange={brushRange}
            chartType="multiline"
            brushCb={this.brushCb}
            storeTransform={this.storeTransform}
            colors={_.map(multiline_data, (c, i) => colors[i] || chartColors[i])}
            ampType={this.props.ampType}
            applyGradient={this.props.applyGradient}
            axisLocked={axisLocked}
            resetChartZoom={this.resetChartZoom}
            multiBrush={this.props.multiBrush}
          />
          </>
          ) : (
          <Chart
            type="multiline"
            chartName={`chart-multi-line-${type}-${tag_type}`}
            title={this.props.multilineTitle}
            data={data}
            range={range}
            yUnit={yUnits[0]}
            ampType={ampTypes[0]}
            activeData={_.map(multiline_data, () => true)}
            colors={_.map(multiline_data, (c, i) => colors[i] || chartColors[i])}
            contextChart
            ylabel={this.props.multilineYlabel || 'Multiline'}
            mouseover={this.setSelections}
            mouseClick={this.mouseClick}
            points={!_.isEmpty(this.props.points) ? this.props.points : []}
            outliersArray={!_.isEmpty(data) ? _.flatten(data.map(dt => dt.filter(d => d.is_outlier))) : []}
            selection={this.state.selections}
            selectedRange={this.state.selectedRange}
            chart_size={this.props.multilineChartSize}
            miniMode={this.props.multilineMiniMode}
            height={this.props.multilineChartHeight}
            lastHoveredChart={this.props.lastHoveredChart}
            setLastHoveredChart={this.props.setLastHoveredChart}
            expanded
            filterMinVal={this.props.filterMinVal}
            filterMaxVal={this.props.filterMaxVal}
            filterKey="speed"
            paginationLoading={this.props.paginationLoading}
          />
          )}
        </>
        )}
        {type === 'spectrum' && (
          <>
          {split ? (
          <>
          {multiline_data.map((t, idx) => (
            <div style={{ marginBottom: '25px' }}>
              <Chart
                type={type}
                ylabel={this.getYLabel(idx)}
                height="120px"
                chart_size="Normal"
                transformFromParent={transform}
                mouseover={s => this.setSelections(s, idx)}
                mouseClick={this.mouseClick}
                points={this.state.points}
                split={split}
                chartName={`chart-splited-${type}-${tag_type}-${idx}`}
                title={tags[idx].direction}
                componentNode={this.props.componentNode}
                axisLocked={axisLocked}
                yUnit={yUnits[idx]}
                ampType={ampTypes[idx]}
                selection={this.state.selections[idx]}
                colors={[colors[idx] || chartColors[idx]]}
                range={range}
                xAxis="linear"
                enableZoom
                resetChartsZoom={this.resetChartsZoom}
                toggleZoomLocks={this.toggleZoomLocks}
                showZoomLocks={idx === 0 ? 'y' : (idx === multiline_data.length - 1 ? 'x' : null)}
                data={data[idx] ? [data[idx]] : [[]]}
                originalData={multiline_data[idx] ? [multiline_data[idx]] : [[]]}
                storeTransform={this.storeTransform}
                brushCb={this.brushCb}
                lastHoveredChart={this.props.lastHoveredChart}
                setLastHoveredChart={this.props.setLastHoveredChart}
                setPointsToCorrectUnits={this.setPointsToCorrectUnits}
                setShaftSpeedState={this.setShaftSpeedState}
                initialXunit={spectrums[idx].freq_units}
                xUnit={this.getXUnit()}
                shaftSpeed={this.state.shaftSpeed || spectrums[idx].shaft_speed}
                toggleSpectrumFrequencyUnits={(units, spectrumData) => this.toggleSpecFrequencyUnits(units, spectrumData, idx)}
                features={this.props.getSpectrumFeatures(idx)}
                noXlabel
                onFeatureDetailsClick={this.props.onFeatureDetailsClick}
                onFeatureEditClick={this.props.onFeatureEditClick}
                {...this.props.spectrumProps}
              />
            </div>
          ))}
          <Chart
            type="context"
            autoFill
            height="25px"
            chartName={`chart-splited-ctx-${type}-${tag_type}`}
            data={data}
            range={range}
            brushRange={brushRange}
            yUnit={yUnits[0]}
            chartType="spectrum"
            brushCb={this.brushCb}
            storeTransform={this.storeTransform}
            colors={_.map(multiline_data, (c, i) => colors[i] || chartColors[i])}
            ampType={this.props.ampType}
            applyGradient={this.props.applyGradient}
            axisLocked={axisLocked}
            resetChartZoom={this.resetChartZoom}
            multiBrush={this.props.multiBrush}
            xAxis="linear"
            tag_type={tag_type}
          />
          </>
          ) : (
          <Chart
            type={type}
            chartName={`chart-multi-line-${type}-${tag_type}`}
            title={this.props.multilineTitle}
            xAxis="linear"
            data={data}
            range={range}
            split={split}
            activeData={_.map(multiline_data, () => true)}
            originalData={multiline_data}
            colors={_.map(multiline_data, (c, i) => colors[i] || chartColors[i])}
            contextChart
            yUnit={yUnits[0]}
            ylabel={this.props.multilineYlabel || 'Multiline'}
            mouseover={this.setSelections}
            mouseClick={this.mouseClick}
            points={this.state.points}
            selection={this.state.selections}
            selectedRange={this.state.selectedRange}
            chart_size={this.props.multilineChartSize}
            miniMode={this.props.multilineMiniMode}
            height={this.props.multilineChartHeight}
            lastHoveredChart={this.props.lastHoveredChart}
            measurementTimestamp={this.props.points && this.props.points[0] && this.props.points[0].x}
            componentNode={this.props.componentNode}
            setLastHoveredChart={this.props.setLastHoveredChart}
            setPointsToCorrectUnits={this.setPointsToCorrectUnits}
            setShaftSpeedState={this.setShaftSpeedState}
            initialXunit={spectrums[0].freq_units}
            xUnit={this.getXUnit()}
            toggleSpectrumFrequencyUnits={this.toggleSpecFrequencyUnits}
            shaftSpeed={this.state.shaftSpeed || spectrums[0].shaft_speed}
            features={this.props.getSpectrumFeatures()}
            onFeatureDetailsClick={this.props.onFeatureDetailsClick}
            onFeatureEditClick={this.props.onFeatureEditClick}
            ampType={this.props.ampType}
            tag_type={tag_type}
            {...this.props.spectrumProps}
          />
          )}
        </>
        )}
        {type === 'waveform' && (
          <>
          {split ? (
          <>
          {multiline_data.map((t, idx) => (
            <div style={{ marginBottom: '25px' }}>
              <Chart
                type={type}
                ylabel={`Amplitude (${yUnits[idx]})`}
                height="120px"
                chart_size="Normal"
                transformFromParent={transform}
                mouseover={s => this.setSelections(s, idx)}
                mouseClick={this.mouseClick}
                points={this.state.points}
                chartName={`chart-splited-${type}-${idx}`}
                title={tags[idx].direction}
                domain={domain}
                axisLocked={axisLocked}
                yUnit={yUnits[idx]}
                ampType={ampTypes[idx]}
                selection={this.state.selections[idx]}
                colors={[colors[idx] || chartColors[idx]]}
                range={range}
                xAxis="linear"
                enableZoom
                resetChartsZoom={this.resetChartsZoom}
                toggleZoomLocks={this.toggleZoomLocks}
                showZoomLocks={idx === 0 ? 'y' : (idx === multiline_data.length - 1 ? 'x' : null)}
                data={data[idx] ? [data[idx]] : [[]]}
                storeTransform={this.storeTransform}
                brushCb={this.brushCb}
                lastHoveredChart={this.props.lastHoveredChart}
                setLastHoveredChart={this.props.setLastHoveredChart}
                xUnit={this.getXUnit()}
                noXlabel={idx !== multiline_data.length - 1}
              />
            </div>
          ))}
          <Chart
            type="context"
            autoFill
            height="25px"
            chartName={`chart-splited-ctx-${type}`}
            data={data}
            range={range}
            brushRange={brushRange}
            chartType="waveform"
            brushCb={this.brushCb}
            storeTransform={this.storeTransform}
            colors={_.map(multiline_data, (c, i) => colors[i] || chartColors[i])}
            ampType={this.props.ampType}
            applyGradient={this.props.applyGradient}
            axisLocked={axisLocked}
            resetChartZoom={this.resetChartZoom}
            multiBrush={this.props.multiBrush}
            xAxis="linear"
          />
          </>
          ) : (
          <Chart
            type={type}
            chartName={`chart-multi-line-${type}`}
            title={this.props.multilineTitle}
            xAxis="linear"
            data={data}
            range={range}
            activeData={_.map(multiline_data, () => true)}
            colors={_.map(multiline_data, (c, i) => colors[i] || chartColors[i])}
            contextChart
            yUnit={yUnits[0]}
            ampType={ampTypes[0]}
            ylabel={this.props.multilineYlabel || 'Multiline'}
            mouseover={this.setSelections}
            mouseClick={this.mouseClick}
            points={this.state.points}
            selection={this.state.selections}
            selectedRange={this.state.selectedRange}
            chart_size={this.props.multilineChartSize}
            miniMode={this.props.multilineMiniMode}
            height={this.props.multilineChartHeight}
            lastHoveredChart={this.props.lastHoveredChart}
            setLastHoveredChart={this.props.setLastHoveredChart}
            xUnit={this.getXUnit()}
          />
          )}
        </>
        )}
        {type === 'demod_spectrum' && (
          <>
          {split ? (
          <>
          {multiline_data.map((t, idx) => (
            <div style={{ marginBottom: '25px' }}>
              <Chart
                type={type}
                ylabel={this.getYLabel(idx)}
                height="120px"
                chart_size="Normal"
                transformFromParent={transform}
                mouseover={s => this.setSelections(s, idx)}
                mouseClick={this.mouseClick}
                points={this.state.points}
                chartName={`chart-splited-${type}-${idx}`}
                componentNode={this.props.componentNode}
                originalData={multiline_data[idx] ? [multiline_data[idx]] : [[]]}
                setShaftSpeedState={this.setShaftSpeedState}
                title={tags[idx].direction}
                domain={domain}
                axisLocked={axisLocked}
                yUnit={yUnits[idx]}
                selection={this.state.selections[idx]}
                colors={[colors[idx] || chartColors[idx]]}
                range={range}
                xAxis="linear"
                enableZoom
                resetChartsZoom={this.resetChartsZoom}
                toggleZoomLocks={this.toggleZoomLocks}
                showZoomLocks={idx === 0 ? 'y' : (idx === multiline_data.length - 1 ? 'x' : null)}
                data={data[idx] ? [data[idx]] : [[]]}
                storeTransform={this.storeTransform}
                brushCb={this.brushCb}
                lastHoveredChart={this.props.lastHoveredChart}
                setLastHoveredChart={this.props.setLastHoveredChart}
                setPointsToCorrectUnits={this.setPointsToCorrectUnits}
                initialXunit={spectrums[idx].freq_units}
                xUnit={this.getXUnit()}
                shaftSpeed={this.state.shaftSpeed || spectrums[idx].shaft_speed}
                toggleSpectrumFrequencyUnits={this.toggleSpecFrequencyUnits}
                features={this.props.getSpectrumFeatures(idx)}
                noXlabel
              />
            </div>
          ))}
          <Chart
            type="context"
            autoFill
            height="25px"
            chartName={`chart-splited-ctx-${type}`}
            data={data}
            range={range}
            brushRange={brushRange}
            chartType="demod_spectrum"
            brushCb={this.brushCb}
            storeTransform={this.storeTransform}
            colors={_.map(multiline_data, (c, i) => colors[i] || chartColors[i])}
            ampType={this.props.ampType}
            applyGradient={this.props.applyGradient}
            axisLocked={axisLocked}
            resetChartZoom={this.resetChartZoom}
            multiBrush={this.props.multiBrush}
            xAxis="linear"
            tag_type={tag_type}
          />
          </>
          ) : (
          <Chart
            type={type}
            chartName={`chart-multi-line-${type}`}
            title={this.props.multilineTitle}
            xAxis="linear"
            data={data}
            range={range}
            activeData={_.map(multiline_data, () => true)}
            originalData={multiline_data}
            colors={_.map(multiline_data, (c, i) => colors[i] || chartColors[i])}
            contextChart
            componentNode={this.props.componentNode}
            yUnit={yUnits[0]}
            ylabel={this.props.multilineYlabel || 'Multiline'}
            mouseover={this.setSelections}
            mouseClick={this.mouseClick}
            points={this.state.points}
            selection={this.state.selections}
            selectedRange={this.state.selectedRange}
            chart_size={this.props.multilineChartSize}
            miniMode={this.props.multilineMiniMode}
            height={this.props.multilineChartHeight}
            lastHoveredChart={this.props.lastHoveredChart}
            setLastHoveredChart={this.props.setLastHoveredChart}
            setPointsToCorrectUnits={this.setPointsToCorrectUnits}
            setShaftSpeedState={this.setShaftSpeedState}
            initialXunit={spectrums[0].freq_units}
            xUnit={this.getXUnit()}
            toggleSpectrumFrequencyUnits={this.toggleSpecFrequencyUnits}
            shaftSpeed={this.state.shaftSpeed || spectrums[0].shaft_speed}
            features={this.props.getSpectrumFeatures()}
            ampType={this.props.ampType}
            tag_type={tag_type}
          />
          )}
        </>
        )}
      </Fragment>
    );
  }
}

SplitableMultilineChart.propTypes = {
  type: PropTypes.string,
  multilineYlabel: PropTypes.string,
  multiline_data: PropTypes.array.isRequired,
  split: PropTypes.bool,
  mapDataToScale: PropTypes.bool,
  multilineChartSize: PropTypes.string,
  multilineChartHeight: PropTypes.string,
  multilineTitle: PropTypes.string
};

SplitableMultilineChart.defaultProps = {
  type: 'trend',
  multilineYlabel: 'Multiline',
  split: false,
  mapDataToScale: true,
  multilineChartSize: 'Large',
  multilineChartHeight: '300px',
  multilineTitle: ''
};

const mapStateToProps = state => ({
  currentUser: state.user.user,
  rbacPermissions: state.rbacPermissions,
  componentId: state.breadcrumb.component.id
});


export default connect(mapStateToProps, null)(SplitableMultilineChart);
