import * as _ from 'lodash';

import { bearingConstants } from './bearing.constants';
import * as bearingService from './bearing.service';
import { alertActions } from '../../../../../../alert/alert.actions';


export const setBearingSearchFilter = query =>
  (dispatch) => {
    dispatch({ type: bearingConstants.SET_BEARING_QUERY, query });
    dispatch(initBearingPaginater());
  };

export const initBearingPaginater = () =>
  (dispatch) => {
    dispatch({ type: bearingConstants.CLEAR_BEARING });
    dispatch(setBearingPaginater(1));
  };

export const increaseBearingPaginater = () =>
  (dispatch, getState) => {
    const bearingState = getState().bearings;
    const { paginater } = bearingState;

    dispatch(setBearingPaginater(paginater.page + 1));
  };

export const setBearingSorter = sorter =>
  (dispatch) => {
    dispatch({ type: bearingConstants.SET_BEARING_SORTER, sorter });
    dispatch({ type: bearingConstants.CLEAR_BEARING });
    dispatch({ type: bearingConstants.SET_BEARING_PAGINATER, page: 1 });

    dispatch(getBearings());
  };

export const setBearingPaginater = page =>
  (dispatch) => {
    dispatch({ type: bearingConstants.SET_BEARING_PAGINATER, page });
    dispatch(getBearings());
  };

export const selectRow = id =>
  dispatch => dispatch({ type: bearingConstants.SELECT_BEARING_ROW, id });

export const addBearing = data =>
  dispatch => bearingService.addBearing(data).then(
    res => res,
    (error) => {
      throw error;
    }
  );

export const deleteBearing = id =>
  dispatch => bearingService.deleteBearing(id).then(
    res => res,
    (error) => {
      throw error;
    }
  );

export const updateBearing = (data, id) => {
  const failure = error => ({ type: bearingConstants.UPDATE_BEARING_FAILURE, error });
  return dispatch => bearingService.updateBearing(data, id).then(
    result => result,
    (error) => {
      dispatch(failure(error));
      throw error;
    }
  );
};

export const associateBearing = (data, componentId) => {
  const request = () => ({ type: bearingConstants.ASSOCIATE_BEARING_REQUEST });
  const success = bearings => ({ type: bearingConstants.ASSOCIATE_BEARING_SUCCESS, bearings });
  const failure = error => ({ type: bearingConstants.ASSOCIATE_BEARING_FAILURE, error });
  const bearingColumns = ['manufacturer', 'bearing_no', 'bpfi', 'bpfo', 'bsf', 'ftf'];

  return (dispatch, getState) => {
    dispatch(request(request()));
    return bearingService.associateBearing(data, componentId).then(
      (result) => {
        const res = result.bearing;
        let bearingItems = getState().machineDetails.info.bearingItems;
        bearingItems = bearingItems.map((row) => {
          if (row.component_id === componentId) {
            row.bearings.items = row.bearings.items.map((bearing) => {
              if (bearing.bearing_location_id === data.bearing_location_id) {
                bearing.id = res.id;
                bearingColumns.map((column) => {
                  bearing.properties[column].value = res[column];
                });
              }
              return bearing;
            });
          }
          return row;
        });
        dispatch(success(bearingItems));
      },
      (error) => {
        dispatch(failure(error));
        throw error;
      }
    );
  };
};

export const unassignBearing = (data, id) =>
  () => bearingService.unassignBearing(data, id).then(
    res => res,
    (error) => {
      throw error;
    }
  );

export const updateBearingState = data =>
  dispatch => dispatch({ type: bearingConstants.UPDATE_BEARING_STATE, data });

export const setSelectedFalse = () =>
  dispatch => dispatch({ type: bearingConstants.SELECTED_FALSE });

export const updateRow = data =>
  dispatch => dispatch({ type: bearingConstants.UPDATE_ROW, data });

export const newBearingEntry = state =>
  dispatch => dispatch({ type: bearingConstants.NEW_BEARING_ENTRY, state });

export const removeRowFromTable = id =>
  dispatch => dispatch({ type: bearingConstants.REMOVE_ROW_FROM_TABLE, id });

export const addRowToTable = data =>
  dispatch => dispatch({ type: bearingConstants.ADD_ROW_TO_TABLE, data });

export const getAllManufacturers = () =>
  dispatch => bearingService.getAllManufacturers().then(
    (res) => {
      dispatch({ type: bearingConstants.GET_ALL_MANUFACTURERS_SUCCESS, result: res.result });
      return res.result;
    },
    error => dispatch({ type: bearingConstants.GET_ALL_MANUFACTURERS_FAILURE, error })
  );

export const getBearings = (bearingModelId) => {
  const request = () => ({ type: bearingConstants.GET_BEARING_REQUEST });
  const success = (bearings, page, total_pages) => ({
    type: bearingConstants.GET_BEARING_SUCCESS, bearings, page, total_pages
  });
  const failure = error => ({ type: bearingConstants.GET_BEARING_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    const bearingState = getState().bearings;
    const sorterState = bearingState.sorter;
    const { query } = bearingState;
    const { paginater } = bearingState;

    let order_by = null;
    if (sorterState.sorter) {
      order_by = { field: sorterState.sorter.name, direction: sorterState.sorter.order };
    } else {
      dispatch({ type: bearingConstants.SET_BEARING_SORTER, sorter: { name: 'manufacturer', order: 'asc' } });
    }

    const params = {
      order_by,
      per_page: bearingConstants.NUMBER_OF_BEARING_PER_PAGE,
      page: paginater.page,
      search_key: query,
    };
    if (bearingModelId && params.page === 1) {
      params.bearing_model_id = bearingModelId;
    }
    return bearingService.getBearings(params).then(
      (res) => {
        dispatch(success(res.items, res.page, res.total_pages));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };
};
