import React from 'react';
import styled, { withTheme } from 'styled-components';
import { FlexContainer } from '../components/atoms';

const ChartContainer = styled(FlexContainer).attrs({
  alignItems: 'center',
  direction: 'column'
})`
  height: 100%;
  .error {
    ${props => props.theme.fonts.selectOption}
  }
`;

const TITLE_HEIGHT = 40;
const Title = styled.div`
  color: ${props => props.theme.colors.darkGray};
  font-weight: 600;
  width: 100%;
  padding: 1em 0 0 1em;
  height: ${TITLE_HEIGHT}px;
`;

function NoDataWidget({ title, message }) {
  return (
    <ChartContainer>
      {title && <Title className="title">{title}</Title>}
      <FlexContainer height="100%" width="100%" justifyContent="center" alignItems="center">
        <span className="error">{message}</span>
      </FlexContainer>
    </ChartContainer>
  );
}

export default withTheme(NoDataWidget);
