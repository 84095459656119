import React from 'react';
import PropTypes from 'prop-types';

const TagIcon = ({ width, height, fill }) => (
<svg width={width} height={height} viewBox="0 0 16 16" version="1.1">
    <g id="PS---Web-app-UI-Kit-3-0-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3-0-1/p05/icons" transform="translate(-430.000000, -1377.000000)">
            <g id="activity" transform="translate(80.000000, 972.000000)">
                <g id="icons" transform="translate(350.000000, 0.000000)">
                    <g id="vib-icon" transform="translate(0.000000, 405.000000)">
                        <rect id="icon-bg" fill="#FFFFFF" fillRule="nonzero" x="0" y="0" width={width} height={height} />
                        <g id="icon" transform="translate(1.600000, 4.000000)" stroke={fill} strokeWidth="1.5">
                            <g id="graph">
                                <polyline id="Path-5" points="0 8.11807176 2.79140271 6.66654362 5.08638009 8.11807176 7.22220022 6.66654362 12.2773473 0.676923077" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

TagIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

TagIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: '#999B95'
};

export default TagIcon;
