import types from '../actions/machineOverview.types';

const initialState = {
  data: {},
  loading: [],
  error: {}
};

const graphicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COMPONENT_SVG_REQUEST:
      return {
        ...state,
        loading: state.loading.concat([action.component_type])
      };
    case types.GET_COMPONENT_SVG_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.component_type]: action.svg
        },
        loading: state.loading.filter(e => e !== action.component_type)
      };
    case types.GET_COMPONENT_SVG_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          [action.component_type]: action.error
        },
        loading: state.loading.filter(e => e !== action.component_type)
      };
    default:
      return state;
  }
};

export default graphicsReducer;
