export * from './history';
export * from './axiosResponse';
export { timeSince, localTimeString } from './time';
export { cutTo2Decimals } from './decimal';
export { download, downloadAsCSV } from './download';
export { humanize } from './humanize';

let gIsMouseDown = 0;
document.body.onmousedown = () => {
  gIsMouseDown = 1;
};
document.body.onmouseup = () => {
  gIsMouseDown = 0;
};

export const isMouseDown = () => gIsMouseDown;
