import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ConditionalFragment = ({ showFrag, tag, Component, children, ...rest }) => {
  const e = React.createElement;
  if (showFrag) return (<Fragment key={rest.key}>{ children }</Fragment>);
  if (Component) return (<Component {...rest}>{children}</Component>);
  return e(tag, rest, children);
};

ConditionalFragment.propTypes = {
  showFrag: PropTypes.bool.isRequired,
  tag: PropTypes.string,
};

ConditionalFragment.defaultProps = {
  tag: 'div'
};

export default ConditionalFragment;
