import definitionTypes from './definition.types';

const initialState = {
  operator_map: [],
  threshold_map: [],
  roles: [],
  users: [],
  overall_alarm_template_names: [],
  trigger_count_range: [],
  parameters: {}
};

export default function list(state = initialState, action) {
  switch (action.type) {
    case definitionTypes.GET_USERS_REQUEST: {
      return {
        ...state,
        usersLoading: true,
        users: []
      };
    }

    case definitionTypes.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.users,
        usersLoading: false
      };
    }

    case definitionTypes.GET_USERS_FAILURE: {
      return {
        ...state,
        users: false,
        error: action.error.message
      };
    }

    case definitionTypes.GET_DEFINITIONS_METADATA_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case definitionTypes.GET_DEFINITIONS_METADATA_SUCCESS: {
      return {
        users: state.users,
        operator_map: action.data.trigger_operators,
        threshold_map: action.data.trigger_thresholds,
        roles: action.data.subscriber_roles,
        parameters: action.data.trigger_parameter_types,
        overall_alarm_template_names: action.data.overall_alarm_template_names,
        parameterUnits: action.data.parameter_data_units,
        trigger_count_range: action.data.trigger_count_range
      };
    }

    case definitionTypes.GET_DEFINITIONS_METADATA_FAILURE: {
      return {
        ...initialState,
        error: action.error.message
      };
    }

    default:
      return state;
  }
}
