import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Popup, Menu } from 'semantic-ui-react';
import { Route, Switch, Link } from 'react-router-dom';
import styled from 'styled-components';
import * as _ from 'lodash';

import ColoredCircle from 'common/components/Chart/atoms/ColoredCircle';
import colors from 'common/styles/colors';
import RBAC from 'common/rbac/RBAC';
import { mapComponentToResource, operations } from 'common/rbac/constants';

import lazyRetry from 'common/helpers/componentLoader';
import LoadingSvg from 'common/components/atoms/Loading';
import { FlexContainer } from 'common/components/atoms';
import DynamicTabs from 'common/components/organisms/DynamicTabs';
import ActivityTabHeader from 'home/HomePage/components/ActivityTabHeader';
import { history } from '../../../common/helpers';
import * as machineDetailsActions from './machineDetails.actions';
import * as analystSummaryActions from './Health/actions/analystSummary.actions';
import { getMachineBuilderMetadata } from '../MachineBuilder/actions/machineBuilder.actions';
import MachineInfoV2 from './MachineInfo/MachineInfoV2';
import BaselinePageV2 from './Health/components/BaselinePageV2';

const MachineHealth = lazy(() => lazyRetry(() => import('home/Machines/MachineDetails/Health')));
const MachineOverview = lazy(() => lazyRetry(() => import('./MachineOverview/MachineOverview')));
const Attachments = lazy(() => lazyRetry(() => import('../../HomePage/organisms/Attachments')));
const Sensors = lazy(() => lazyRetry(() => import('../../Sensors/components/Sensors')));
const MachineActivity = lazy(() => lazyRetry(() => import('./MachineActivity/MachineActivity')));
const MachineCharts = lazy(() => lazyRetry(() => import('./MachineCharts/MachineCharts')));
const MachineInstrumentationAlerts = lazy(() => lazyRetry(() => import('./MachineInstrumentationAlerts/MachineInstrumentationAlerts')));

const BetaSpan = styled.span`
  font-family: 'Petasense Open Sans';
  font-size: 10px;
  font-weight: 600;
  position: relative;
  left: ${props => props.leftMargin ? '-10px' : '0px'};
  top: -10px;
`;

const IndicatorSpan = styled.span`
  position: relative;
  right: ${props => props.rightPos ? props.rightPos : '20px'};
  top: -10px;
`;

class MachineDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: 'overview',
      title: '',
    };
    this.tabItems = [
      'overview',
      'health',
      'baseline',
      'charts',
      'activity',
      'instrumentation-alerts',
      'devices',
      'attachments',
      'info'
    ];
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.updateActiveItem();
    }
    const { machine_id } = this.props.match.params;
    if (machine_id !== prevProps.match.params.machine_id) this.props.machineDetailsActions.getMachineDetails(machine_id);
    if (machine_id !== prevProps.match.params.machine_id) this.props.machineDetailsActions.getMachineCompletionStatus(machine_id);
  }

  componentWillReceiveProps(nextProps) {
    const { title } = this.state;
    const { machineDetails } = nextProps;
    const name = _.get(machineDetails, 'object.name');
    if (name) {
      const newTitle = name.split('_').join(' ');
      if (newTitle !== title) {
        this.setState({ title: newTitle });
      }
    }
  }

  updateActiveItem = () => {
    const { url } = this.props.match;
    const items = this.props.location.pathname.slice(url.length).split('/');
    const item = items[1];
    if (item && item.length > 0) {
      this.setState({ activeItem: item });
    } else {
      history.replace(`${url}/overview`);
    }
  };

  handleTabChange = (e, data) => {
    const { activeIndex } = data;
    this.setState({ activeItem: this.tabItems[activeIndex] });
  };

  onChangeTitle = (title) => {
    const { machine_id } = this.props.match.params;
    this.setState({ title: title.machineTitle });
    this.props.machineDetailsActions.updateMachineDetails(machine_id, {
      key: 'name',
      value: title.machineTitle
    });
  };

  isAspectIncomplete = (aspect_name) => {
    const { completionStatus } = this.props;
    if (!completionStatus.loading && completionStatus.data && completionStatus.data.missing_steps) {
      return completionStatus.data.missing_steps.includes(aspect_name);
    }
    return false;
  };

  componentDidMount() {
    this.updateActiveItem();
    const { machine_id } = this.props.match.params;
    this.props.machineDetailsActions.getMachineDetails(machine_id);
    this.props.machineDetailsActions.getMachineCompletionStatus(machine_id);
    this.props.getMachineBuilderMetadata();
  }

  machineDetailsPanes = () => {
    const { machine_id } = this.props.match.params;
    const { url } = this.props.match;
    const { activeItem } = this.state;
    const menuItems = [
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/overview`}
            key="overview"
            active={activeItem === 'overview'}
          >
            Overview
          </Menu.Item>
        ),
        key: 'machine-menu-overview',
        path: `${url}/overview`,
        title: 'Overview'
      },
      {
        menuItem: (
          <Popup
            content="Baselines are not set"
            disabled={!this.isAspectIncomplete('HEALTH')}
            trigger={(
              <Menu.Item
                as={Link}
                to={`${url}/health`}
                key="health"
                active={activeItem === 'health'}
              >
                Health
                <RBAC
                  resource={mapComponentToResource.Machines}
                  operation={operations.Update}
                  yes={(
                    <FlexContainer>
                      {this.isAspectIncomplete('HEALTH') && (
                        <IndicatorSpan><ColoredCircle color={colors.cyan} /></IndicatorSpan>
                      )}
                      <BetaSpan leftMargin={this.isAspectIncomplete('HEALTH')}>beta</BetaSpan>
                    </FlexContainer>
                  )}
                  no={(
                    <BetaSpan>beta</BetaSpan>
                  )}
                />
              </Menu.Item>
            )}
          />
        ),
        key: 'machine-menu-health',
        path: `${url}/health`,
        title: 'Health'
      },
      {
        menuItem: (
          <Menu.Item
            className="baseline"
            as={Link}
            to={`${url}/baseline`}
            key="baseline"
            active={activeItem === 'baseline'}
          >
          Baseline
          <RBAC
            resource={mapComponentToResource.Machines}
            operation={operations.Update}
          />
          </Menu.Item>
        ),
        key: 'machine-menu-baseline',
        path: `${url}/baseline`,
        title: 'Baseline'
      },
      {
        menuItem: (
          <Popup
            content="Alarms are not set"
            disabled={!this.isAspectIncomplete('CHARTS')}
            trigger={(
            <Menu.Item
              as={Link}
              to={`${url}/charts`}
              key="charts"
              active={activeItem === 'charts'}
            >
              Charts
              <RBAC
                resource={mapComponentToResource.Machines}
                operation={operations.Update}
                yes={(
                  <FlexContainer>
                    {this.isAspectIncomplete('CHARTS') && (
                    <IndicatorSpan><ColoredCircle color={colors.cyan} /></IndicatorSpan>
                    )}
                  </FlexContainer>
                )}
              />
            </Menu.Item>
            )}
          />
        ),
        key: 'machine-menu-charts',
        path: `${url}/charts`,
        title: 'Charts'
      },
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/activity`}
            key="activity"
            active={activeItem === 'activity'}
          >
           <ActivityTabHeader machine_id={machine_id} />
          </Menu.Item>
        ),
        key: 'machine-menu-activity',
        path: `${url}/activity`,
        title: 'Activity'
      },
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/devices`}
            key="devices"
            active={activeItem === 'devices'}
          >
            Devices
          </Menu.Item>
        ),
        key: 'machine-menu-sensors',
        path: `${url}/devices`,
        title: 'Devices'
      },
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/attachments`}
            key="attachments"
            active={activeItem === 'attachments'}
          >
            Attachments
          </Menu.Item>
        ),
        key: 'machine-menu-pictures',
        path: `${url}/attachments`,
        title: 'Attachments'
      },
      {
        menuItem: (
          <Popup
            content="Machine info is incomplete"
            disabled={!this.isAspectIncomplete('INFO')}
            trigger={(
              <Menu.Item
                className="info"
                as={Link}
                to={`${url}/info`}
                key="info"
                active={activeItem === 'info'}
              >
              Info
              <RBAC
                resource={mapComponentToResource.Machines}
                operation={operations.Update}
                yes={(
                  <FlexContainer>
                    {this.isAspectIncomplete('INFO') && (
                      <IndicatorSpan rightPos="15px">
                        <ColoredCircle color={colors.cyan} />
                      </IndicatorSpan>
                    )}
                  </FlexContainer>
                )}
              />
              </Menu.Item>
            )}
          />
        ),
        key: 'machine-menu-info',
        path: `${url}/info`,
        title: 'Info'
      },
      {
        menuItem: (
          <Menu.Item
            as={Link}
            to={`${url}/instrumentation-alerts`}
            key="instrumentation-alerts"
            active={activeItem === 'instrumentation-alerts'}
          >
           Instrumentation Alerts
          </Menu.Item>
        ),
        key: 'machine-menu-instrumentation-alerts',
        path: `${url}/instrumentation-alerts`,
        title: 'Instrumentation Alerts'
      },
    ];
    return menuItems;
  };

  renderMachineDetails = () => {
    const { machineDetails } = this.props;
    const { activeItem } = this.state;
    if (!machineDetails || machineDetails.loading) {
      return (
        <div className="full-screen-center">
          <LoadingSvg />
        </div>
      );
    }
    if (machineDetails.error) {
      return <div>Error loading machine details</div>;
    }
    if (machineDetails.object) {
      return (
        <div className="machine-details">
          <div>
            <DynamicTabs
              menu={{ secondary: true }}
              panes={this.machineDetailsPanes()}
              activeIndex={this.tabItems.indexOf(activeItem)}
              onTabChange={this.handleTabChange}
              bufferSpace={45}
            />
            <Suspense fallback={(
                <LoadingSvg height="100%" />
              )}
            >
              <Switch>
                <Route
                  path="/machines/:machine_id/overview"
                  component={MachineOverview}
                />
                <Route
                  path="/machines/:machine_id/health"
                  component={MachineHealth}
                />
                <Route
                  path="/machines/:machine_id/baseline"
                  render={() => (
                    <div>
                      <BaselinePageV2 machineId={this.props.match.params.machine_id} />
                    </div>
                  )}
                />
                <Route
                  path="/machines/:machine_id/charts"
                  component={MachineCharts}
                />
                <Route
                  path="/machines/:machine_id/activity"
                  component={MachineActivity}
                />
                <Route path="/machines/:machine_id/devices" component={Sensors} />
                <Route
                  path="/machines/:machine_id/attachments"
                  render={() => (
                    <Attachments originId={this.props.match.params.machine_id} originType="machine" allowUpload showExistingAtts showSelectedFiles={false} uploadButtonText="UPLOAD FILES" />
                  )}
                />
                <Route path="/machines/:machine_id/info" component={MachineInfoV2} />
                <Route
                  path="/machines/:machine_id/instrumentation-alerts"
                  component={MachineInstrumentationAlerts}
                />
              </Switch>
            </Suspense>
          </div>
        </div>
      );
    }
    return <div />;
  };

  render() {
    return <div>{this.renderMachineDetails()}</div>;
  }
}

const mapStateToProps = state => ({
  machineDetails: state.machineDetails.details,
  currentUser: state.user.user,
  analystSummary: state.machineDetails.AS.summary,
  completionStatus: state.machineDetails.completion_status
});

const mapDispatchToProps = dispatch => ({
  getMachineBuilderMetadata: bindActionCreators(getMachineBuilderMetadata, dispatch),
  machineDetailsActions: bindActionCreators(machineDetailsActions, dispatch),
  analystSummaryActions: bindActionCreators(analystSummaryActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MachineDetails);
