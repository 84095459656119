import React from 'react';
import PropTypes from 'prop-types';

const WiFiSvg = ({
  width,
  height,
  fill,
  wifiLevel
}) => (
  <svg width={width} height={height} viewBox="0 0 15 15" id="svg2" version="1.1">
    <defs id="defs4" />
    <g id="layer1" transform="translate(0,-1037.3622)">
      <g id="g4231" transform="translate(-112.00893,473.56579)">
        <path
          id="path4233"
          d="m 113.61607,569.68363 c 0,0 2.22903,-2.42143 5.89286,-2.42143 3.66383,0 5.89286,2.42143 5.89286,2.42143"
          fill="none"
          stroke={wifiLevel > 3 ? fill.selection : fill.rest}
        />
        <path
          d="m 115.416,571.4833 c 0,0 1.65575,-1.70018 4.09293,-1.70019 2.43718,-10e-6 4.09307,1.70004 4.09307,1.70004"
          id="path4235"
          fill="none"
          stroke={wifiLevel > 2 ? fill.selection : fill.rest}
        />
        <path
          id="path4237"
          d="m 117.116,573.18315 c 0,0 0.99271,-0.99993 2.39293,-0.99995 1.40022,-1e-5 2.39307,0.99995 2.39307,0.99995"
          fill="none"
          stroke={wifiLevel > 1 ? fill.selection : fill.rest}
        />
        <circle
          r="0.73393893"
          cy="575.09668"
          cx="119.509"
          id="circle4239"
          fill={wifiLevel > 0 ? fill.selection : 'none'}
          stroke={wifiLevel > 0 ? 'none' : fill.rest}
        />
      </g>
    </g>
  </svg>
);

WiFiSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.shape({
    selection: PropTypes.string,
    rest: PropTypes.string
  }),
  wifiLevel: PropTypes.number
};

WiFiSvg.defaultProps = {
  width: 20,
  height: 20,
  fill: {
    selection: '#333',
    rest: '#d5d5d5'
  },
  wifiLevel: 0
};

export default WiFiSvg;
