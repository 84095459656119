import * as _ from 'lodash';

import { round, getAmpUnitConversionFactor } from 'common/utils';
import * as envelopeUtils from './envelopeUtils';
import { commonDefaultOverallAlarmSettings, ALARMS_ROUND_TO_NUMBER } from '../constants/overallAlarm.constants';

export const initialOverallAlarmSettings = (tag_type, unit_system, ampType = null) => {
  const alarmSettings = _.cloneDeep(commonDefaultOverallAlarmSettings);

  if (tag_type === 'vibration' && ampType === 'velocity') {
    const amp_fact = getAmpUnitConversionFactor(1, unit_system);
    alarmSettings.lower_outlier_limit = round(amp_fact * 0.8);
  }
  return alarmSettings;
};

export const calculateThresholds = (data, settings) => {
  if (_.isEmpty(data) || _.isEmpty(settings)) {
    return null;
  }
  const { threshold: { type, warning_factor, critical_factor } } = settings;
  const avg = _.sum(data) / data.length;
  switch (type) {
    case 'multiplier':
      return {
        warning: round(avg * warning_factor, ALARMS_ROUND_TO_NUMBER),
        critical: round(avg * critical_factor, ALARMS_ROUND_TO_NUMBER),
      };
    case 'standard_deviation': {
      const std = envelopeUtils.calculateStandardDeviation(data);
      return {
        warning: round(avg + std * warning_factor, ALARMS_ROUND_TO_NUMBER),
        critical: round(avg + std * critical_factor, ALARMS_ROUND_TO_NUMBER),
      };
    }
    case 'offset':
      return {
        warning: round(avg + warning_factor, ALARMS_ROUND_TO_NUMBER),
        critical: round(avg + critical_factor, ALARMS_ROUND_TO_NUMBER),
      };
    default:
      return null;
  }
};
