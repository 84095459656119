import styled from 'styled-components';

import MoreIconSvg from '../../images/MoreIconSvg';


const SvgContainer = styled.div`
  ${MoreIconSvg} {
    transform: rotate(90deg);
  }
  cursor: pointer;
  display: flex;
  ${props => props.marginRight && `margin-right: ${props.marginRight}`};
  ${props => props.marginLeft && `margin-left: ${props.marginLeft}`};
  ${props => props.paddingTop && `padding-top: ${props.paddingTop};`}
`;

SvgContainer.displayName = 'SvgContainer';

export default styled(SvgContainer)``;
