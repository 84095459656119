import _, { template } from 'lodash';
import { axiosInstance, ENDPOINT } from 'common/constants';
import { toastr } from 'react-redux-toastr';
import { handleResponse } from 'common/helpers';
import { templateConstants } from '../../HomePage/constants/constants';

export const createTemplate = (params) => {
  const request = () => ({ type: templateConstants.ADD_TEMPLATE_REQUEST });
  const success = () => ({ type: templateConstants.ADD_TEMPLATE_SUCCESS });
  const failure = () => ({ type: templateConstants.ADD_TEMPLATE_FAILURE });

  return (dispatch) => {
    dispatch(request());

    return handleResponse(axiosInstance.post(ENDPOINT.CREATE_TEMPLATE, { ...params }))
      .then(
        (res) => {
          dispatch(success(res.result));
          toastr.success('Template created successfully');
          return res;
        },
        (error) => {
          dispatch(failure(error));
          toastr.error(error.message);
          throw error;
        }
      );
  };
};
export const formatError = error => error.split(':').pop();

export const updateTaskTemplate = (params) => {
  const id = params.id;
  const request = () => ({ type: templateConstants.UPDATE_TASK_TEMPLATE_DETAILS_REQUEST });
  const success = data => ({ type: templateConstants.UPDATE_TASK_TEMPLATE_DETAILS_SUCCESS, data, id });
  const failure = error => ({ type: templateConstants.UPDATE_TASK_TEMPLATE_DETAILS_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    const { details } = getState().taskTemplates.templates;
    const changedDetails = {};
    Object.entries(params).forEach(([key, value]) => {
      if (!_.isEqual(details[key], value)) changedDetails[key] = value;
    });
    return handleResponse(axiosInstance.patch(ENDPOINT.UPDATE_TASK_TEMPLATE(id), { ...changedDetails })).then(
      (res) => {
        dispatch(success(changedDetails));
        return res;
      },
      (error) => {
        dispatch(failure(error));
        throw error;
      }
    );
  };
};


export const getTemplatesList = () => {
  const request = () => ({ type: templateConstants.GET_TEMPLATE_LIST_REQUEST });
  const success = data => ({ type: templateConstants.GET_TEMPLATE_LIST_SUCCESS, data });
  const failure = error => ({ type: templateConstants.GET_TEMPLATE_LIST_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.get(ENDPOINT.TEMPLATE_LIST))
      .then(
        (res) => {
          dispatch(success(res.items));
          return (res);
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };
};

export const deleteTaskTemplate = (id) => {
  const request = () => ({ type: templateConstants.DELETE_TASK_TEMPLATE_REQUEST });
  const success = data => ({ type: templateConstants.DELETE_TASK_TEMPLATE_SUCCESS, data, id });
  const failure = error => ({ type: templateConstants.DELETE_TASK_TEMPLATE_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    return handleResponse(axiosInstance.delete(ENDPOINT.DELETE_TASK_TEMPLATE(id))).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        toastr.error(formatError(error.message));
        dispatch(failure(error.message));
      }
    );
  };
};

export const selectTaskTemplate = template => (dispatch) => {
  dispatch({ type: templateConstants.SELECT_TASK_TEMPLATE, template });
};
export const getTaskTemplateDetails = template => (dispatch) => {
  const success = data => ({ type: templateConstants.GET_TEMPLATE_DETAILS_SUCCESS, data });
  const id = template.id;
  dispatch({ type: templateConstants.SELECT_TASK_TEMPLATE, id });
  dispatch(success(template));
};

export const addNewTaskTemplateToList = () => (dispatch) => {
  dispatch({ type: templateConstants.ADD_NEW_TASK_TEMPLATE_TO_LIST });
  dispatch({ type: templateConstants.SELECT_TASK_TEMPLATE, id: -1 });
};

export const addTaskTemplateToList = data => (dispatch) => {
  dispatch({ type: templateConstants.ADD_TASK_TEMPLATE_TO_LIST, data });
};

export const removeTaskTemplateFromList = id => dispatch =>
  dispatch({ type: templateConstants.REMOVE_TASK_TEMPLATE_FROM_LIST, id });

export const initTaskTemplatePaginater = () => (dispatch) => {
  dispatch({ type: templateConstants.CLEAR_TASK_TEMPLATES });
  dispatch({ type: templateConstants.CLEAR_TASK_TEMPLATE_SEARCH_PARAMS });
  dispatch(setTaskTemplatePaginater(1));
};

export const setTaskTemplatePaginater = page => (dispatch) => {
  dispatch({ type: templateConstants.SET_TASK_TEMPLATE_PAGINATER, page });
  dispatch(getTaskTemplates());
};

export const increaseTaskTemplatePaginater = () => (dispatch, getState) => {
  const taskTemplates = getState().taskTemplates;
  const { paginater } = taskTemplates;
  dispatch(setTaskTemplatePaginater(paginater.page + 1));
};

export const setTaskTemplateSearchParams = searchParams => (dispatch) => {
  dispatch({
    type: templateConstants.SET_TASK_TEMPLATE_SEARCH_PARAMS,
    searchParams
  });
  dispatch({
    type: templateConstants.SET_TASK_TEMPLATE_PAGINATER,
    page: 1
  });
  dispatch({
    type: templateConstants.CLEAR_TASK_TEMPLATES
  });
  dispatch(getTaskTemplates());
};

export const setTaskTemplateSorter = sorter => (dispatch) => {
  dispatch({ type: templateConstants.SET_TASK_TEMPLATE_SORTER, sorter });
  dispatch({ type: templateConstants.CLEAR_TASK_TEMPLATES });
  dispatch({ type: templateConstants.SET_TASK_TEMPLATE_PAGINATER, page: 1 });

  dispatch(getTaskTemplates());
};

const getTaskTemplates = () => {
  const request = () => ({
    type: templateConstants.GET_TEMPLATE_LIST_REQUEST
  });
  const success = (data, page, total_count) => ({
    type: templateConstants.GET_TEMPLATE_LIST_SUCCESS,
    data,
    page,
    total_count
  });
  const failure = error => ({
    type: templateConstants.GET_TEMPLATE_LIST_FAILURE,
    error
  });

  return (dispatch, getState) => {
    dispatch(request());
    const taskTemplates = getState().taskTemplates;
    const { sorter, paginater, searchParams } = taskTemplates;

    const order_by = { field: 'name', direction: 'asc' };
    if (sorter && sorter.sorter && sorter.sorter.order) {
      order_by.field = sorter.sorter.name;
      order_by.direction = sorter.sorter.order;
    }

    const params = {
      order_by,
      search_key: searchParams.searchKey,
      resource_version: 3
    };

    if (searchParams) {
      params.filters = [];
      Object.keys(searchParams).forEach((filterKey) => {
        if (filterKey === 'searchKey') return;
        if (searchParams[filterKey].length > 0) {
          const filter = {
            name: filterKey,
            op: 'in',
            value: searchParams[filterKey]
          };
          params.filters.push(filter);
        }
      });
    }
    return handleResponse(axiosInstance.get(ENDPOINT.TEMPLATE_LIST, { params })).then(
      (res) => {
        dispatch(success(res.items));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};
