import React, { useEffect, useState } from 'react';
import FooterFlex from 'home/AssetHierarchy/components/atoms/FooterFlex';
import { Checkbox, FlexContainer, InputField } from 'common/components/atoms';
import Label from 'common/typography/Label/Label';
import _ from 'lodash';
import styled from 'styled-components';
import Button from 'common/components/atoms/Button';
import { isPetasenseAdmin } from 'common/rbac/util';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingSvg from 'common/components/atoms/Loading';
import AlertMessage_T from './organisms/AlertMessage';
import ComponentBaselineItem from './ComponentBaselineItem';
import * as machineMLActions from '../actions/machineML.actions';

const AlertMessage = styled(AlertMessage_T)`
  margin: 4px;
`;

const CheckboxContainer = styled(FlexContainer).attrs({ alignItems: 'center' })`
  ${Checkbox} {
    margin-right: 8px;
  }
`;

const MultiSetContainer = styled(FlexContainer)`
  border-bottom: 1px solid #ddd;
`;

const MultiSetField = styled(InputField)`
  margin: 12px 0 0 10px;
  label {
    color: ${props => (props.value ? 'black' : 'gray')};
    font-size: 14px;
  }
`;

const EmptyMsgContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em;
  margin: 2em;
  border: 2px dotted ${props => props.theme.colors.lightGray};
  border-radius: ${props => props.theme.utils.borderRadius};
  background-color: ${props => props.theme.colors.white};
  span {
    color: ${props => props.theme.colors.lightGray};
    font-weight: bold;
  }
`;

const Container = styled.div`
  width: 100%;
  border: 1px solid #D8DCD3;
  border-top: none;
  padding: 20px 26px 60px;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #E5E8E1;
  }
  background: white;
  height: calc(100vh - 110px);
`;

const BaselineContainer = styled.div`
  background-color: white;
  margin: 10px 0 35px;
  padding: 15px;
  border: 1px solid #D8DCD3;
  border-radius: 8px;
  position: relative;
`;


function BaselinePageV2(props) {
  const [isMultiSetActive, setIsMultiSetActive] = useState(false);
  const [reExtractFeaturesWithRMS, setReExtractFeatures] = useState(false);
  const [multiSetProperties, setMultiSetProperties] = useState({
    components: {},
    componentNames: {},
    baselineInfo: null,
    outlierInfo: null,
    seedHealthScore: null,
    calendarInfo: {
      days: undefined,
      customDayFrom: null,
      customDayTo: null,
    },
    reExtractFeaturesWithRMS: false
  });

  useEffect(() => {
    const partner = props.partner;
    const title = partner.title;
    document.title = `${title} | Baseline`;
    const { machineId, hierarchy } = props;
    if (!hierarchy.loading) props.machineMLActions.getComponentTrendsForMachineId(machineId);

    // get components features info
    props.machineMLActions.getAHSFeaturesInfo(parseInt(machineId, 10));
  }, []);

  useEffect(() => {
    const { machineId, hierarchy } = props;

    // when hierarchy finishes loading
    if (!hierarchy.loading) {
      props.machineMLActions.getComponentTrendsForMachineId(machineId);
    }
  }, [props.hierarchy.loading]);

  const resetState = () => {
    setIsMultiSetActive(prevState => !prevState);
    setMultiSetProperties({
      components: {},
      componentNames: {},
      baselineInfo: null,
      seedHealthScore: null,
      calendarInfo: {
        days: undefined,
        customDayFrom: null,
        customDayTo: null,
      }
    });
  };

  const saveMultiSetBaseline = () => {
    const { machineMLActions } = props;

    if (multiSetProperties.baselineInfo) {
      const finalBaselineInfo = {
        start_time: new Date(multiSetProperties.baselineInfo.start_time),
        end_time: new Date(multiSetProperties.baselineInfo.end_time)
      };
      Object.keys(multiSetProperties.components).forEach((key) => {
        machineMLActions.postComponentBaseline(
          key,
          multiSetProperties.componentNames[key],
          multiSetProperties.components[key],
          finalBaselineInfo,
          reExtractFeaturesWithRMS
        );
      });
    }
    resetState();
  };

  const setBaselineValue = (baseline_info, tag_type = null) => {
    setMultiSetProperties((prevState) => {
      if (tag_type) {
        return ({
          ...prevState,
          baselineInfo: {
            start_time: baseline_info.start_time,
            end_time: baseline_info.end_time
          },
          outlierInfo: {
            ...prevState.outlierInfo,
            [tag_type]: {
              outlier_lower_limit: baseline_info.outlier_lower_limit,
              outlier_upper_limit: baseline_info.outlier_upper_limit
            }
          }
        });
      } return ({
        ...prevState,
        baselineInfo: {
          start_time: baseline_info.start_time,
          end_time: baseline_info.end_time
        }
      });
    });
  };

  const multiSetComponentHandler = (componentId, componentName, tagsId, isValueSelected, handlerLevel) => {
    let newTags = tagsId;

    if (multiSetProperties.components[componentId] && handlerLevel === 'tag') {
      if (isValueSelected) {
        newTags = multiSetProperties.components[componentId].filter(item => item !== tagsId[0]);
      } else newTags = [...multiSetProperties.components[componentId], tagsId[0]];
    }

    setMultiSetProperties((prevState) => {
      if (prevState.components[componentId] && (handlerLevel === 'component' || _.isEmpty(newTags))) {
        const newComponents = { ...prevState.components };
        const newComponentNames = { ...prevState.componentNames };
        delete newComponents[componentId];
        delete newComponentNames[componentId];
        return ({
          ...prevState,
          components: newComponents,
          componentNames: newComponentNames
        });
      } return ({
        ...prevState,
        components: {
          ...prevState.components,
          [componentId]: newTags
        },
        componentNames: {
          ...prevState.componentNames,
          [componentId]: componentName
        }
      });
    });
  };

  const toggleReExtractFeatures = () => (
    setReExtractFeatures(prevState => !prevState)
  );

  const setMultiSetCalendar = (value, customDayFrom = null, customDayTo = null) => {
    const newState = {
      days: value,
      customDayFrom,
      customDayTo
    };
    setMultiSetProperties(prevState => ({
      ...prevState,
      calendarInfo: { ...newState }
    }));
  };

  const setMultiSetSeedScore = (newSeedScore) => {
    setMultiSetProperties(prevState => ({
      ...prevState,
      seedHealthScore: newSeedScore
    }));
  };

  let allBaselinesValid = true;
  props.baselines.components.forEach((component) => {
    const tagsResults = component && component.tags_data;
    const isCompBaselineValid = tagsResults && _.every(
      tagsResults.map(item => _.isEmpty(item.validation) || item.validation.overall_is_valid)
    );
    allBaselinesValid = allBaselinesValid && isCompBaselineValid;
  });

  const componentTrendsLoading = props.baselines && props.baselines.components && _.some(props.baselines.components.map(c => c.loading));

  return (
    <>
      {isMultiSetActive && (
        <FooterFlex justifyContent="space-between" width={`calc(100vw - ${props.hierarchyWidth + 15}px)`}>
          <FlexContainer>
            <Label fontSize="14px">{_.size(multiSetProperties.components)} &nbsp;</Label>
            <Label fontSize="14px" fontWeight="300">{_.size(multiSetProperties.components) <= 1 ? 'Component' : 'Components'} selected</Label>
          </FlexContainer>
          <FlexContainer>
          {isPetasenseAdmin(props.user) && (
            <CheckboxContainer onClick={toggleReExtractFeatures}>
              <Checkbox
                value={reExtractFeaturesWithRMS}
              />
              Re-extract features with RMS method
            </CheckboxContainer>
          )}
          <Button
            cancel
            onClick={resetState}
            disabled={_.isEmpty(multiSetProperties.components)}
          >
            Cancel
          </Button>
          <Button
            onClick={saveMultiSetBaseline}
            disabled={_.isEmpty(multiSetProperties.components)}
            danger={!allBaselinesValid}
          >
            {allBaselinesValid ? 'Save' : 'Override and Save'}
          </Button>
          </FlexContainer>
        </FooterFlex>
      )}
      <MultiSetContainer direction="row" backgroundColor="white" padding="10px 20px" justifyContent="flex-start">
        <MultiSetField
          onClick={resetState}
          title="MultiSet"
          type="checkbox"
          value={isMultiSetActive}
          disabled={props.hierarchy.loading}
        />
      </MultiSetContainer>
      <Container>
        <AlertMessage
          message="We currently support only Vibration features in AHS computation"
        />
        {(props.hierarchy.loading || componentTrendsLoading) && <LoadingSvg />}
        {!props.hierarchy.loading && _.every(props.baselines.components.map(c => !c.loading && !c.error && _.isEmpty(c.tags_data))) && (
          <EmptyMsgContainer>
            <span>No components with sensors associated</span>
          </EmptyMsgContainer>
        )}
        {props.baselines.components.filter(c => !_.isEmpty(c.tags_data)).map((component, idx) => (
          <BaselineContainer>
            <ComponentBaselineItem
              key={`new-${idx}`}
              component={component}
              getAllTagChartDataForAComponent={props.machineMLActions.getAllTagChartDataForAComponent}
              chart_size="Normal"
              isMultiSetActive={isMultiSetActive}
              multiSetProperties={multiSetProperties}
              setBaselineValue={setBaselineValue}
              multiSetComponentHandler={multiSetComponentHandler}
              setMultiSetCalendar={setMultiSetCalendar}
              setMultiSetSeedScore={setMultiSetSeedScore}
              ahsFeaturesInfo={props.ahsFeaturesInfo.find(info => info.component_id === component.id)}
            />
          </BaselineContainer>
        ))}
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  baselines: state.machineDetails.ML.baselines,
  breadcrumb: state.breadcrumb,
  hierarchy: state.assetHierarchyReducer.assetInfo.hierarchy,
  hierarchyWidth: state.hierarchyViewPane.currentWidth,
  user: state.user.user,
  partner: state.companyReducer.partner,
  ahsFeaturesInfo: state.machineDetails.ML.health.selected_ahs_features && state.machineDetails.ML.health.selected_ahs_features.list
});

const mapDispatchToProps = dispatch => ({
  machineMLActions: bindActionCreators(machineMLActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(BaselinePageV2);
