import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSortAlphaUp } from '@fortawesome/free-solid-svg-icons/faSortAlphaUp';
import { faSortAlphaDown } from '@fortawesome/free-solid-svg-icons/faSortAlphaDown';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faBatteryQuarter } from '@fortawesome/free-solid-svg-icons/faBatteryQuarter';
import { faBatteryHalf } from '@fortawesome/free-solid-svg-icons/faBatteryHalf';
import { faBatteryThreeQuarters } from '@fortawesome/free-solid-svg-icons/faBatteryThreeQuarters';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons/faWindowClose';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faToggleOn } from '@fortawesome/free-solid-svg-icons/faToggleOn';
import { faToggleOff } from '@fortawesome/free-solid-svg-icons/faToggleOff';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons/faStopwatch';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import { faFileWord } from '@fortawesome/free-solid-svg-icons/faFileWord';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons/faArrowsAltV';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSliders } from '@fortawesome/free-solid-svg-icons/faSliders';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faHashtag } from '@fortawesome/free-solid-svg-icons/faHashtag';
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons/faUserGroup';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons/faCircleXmark';
import { faListAlt } from '@fortawesome/free-solid-svg-icons/faListAlt';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faTemperatureHalf } from '@fortawesome/free-solid-svg-icons/faTemperatureHalf';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';

export const SortUp = props => <FontAwesomeIcon icon={faSortUp} {...props} />;

export const SortAlphaUp = props => <FontAwesomeIcon icon={faSortAlphaUp} {...props} />;

export const SortDown = props => <FontAwesomeIcon icon={faSortDown} {...props} />;

export const SortAlphaDown = props => <FontAwesomeIcon icon={faSortAlphaDown} {...props} />;

export const Search = props => <FontAwesomeIcon icon={faSearch} {...props} />;

export const BatteryQuarter = props => <FontAwesomeIcon icon={faBatteryQuarter} {...props} />;

export const BatteryHalf = props => <FontAwesomeIcon icon={faBatteryHalf} {...props} />;

export const BatteryThreeQuarters = props => (
  <FontAwesomeIcon icon={faBatteryThreeQuarters} {...props} />
);

export const EllipsisV = props => <FontAwesomeIcon icon={faEllipsisV} {...props} />;

export const EllipsisH = props => <FontAwesomeIcon icon={faEllipsisH} {...props} />;

export const Times = props => <FontAwesomeIcon icon={faTimes} {...props} />;

export const ArrowLeft = props => <FontAwesomeIcon icon={faArrowLeft} {...props} />;

export const ArrowRight = props => <FontAwesomeIcon icon={faArrowRight} {...props} />;

export const ArrowDown = props => <FontAwesomeIcon icon={faArrowDown} {...props} />;

export const ArrowUp = props => <FontAwesomeIcon icon={faArrowUp} {...props} />;

export const ChevronDown = props => <FontAwesomeIcon icon={faChevronDown} {...props} />;

export const ChevronUp = props => <FontAwesomeIcon icon={faChevronUp} {...props} />;

export const ChevronLeft = props => <FontAwesomeIcon icon={faChevronLeft} {...props} />;

export const ChevronRight = props => <FontAwesomeIcon icon={faChevronRight} {...props} />;

export const WindowClose = props => <FontAwesomeIcon icon={faWindowClose} {...props} />;

export const Bars = props => <FontAwesomeIcon icon={faBars} {...props} />;

export const Calendar = props => <FontAwesomeIcon icon={faCalendarAlt} {...props} />;

export const Check = props => <FontAwesomeIcon icon={faCheck} {...props} />;

export const Plus = props => <FontAwesomeIcon icon={faPlus} {...props} />;

export const Minus = props => <FontAwesomeIcon icon={faMinus} {...props} />;

export const Expand = props => <FontAwesomeIcon icon={faExpand} {...props} />;

export const Compress = props => <FontAwesomeIcon icon={faCompress} {...props} />;

export const SyncAlt = props => <FontAwesomeIcon icon={faSyncAlt} {...props} />;

export const Download = props => <FontAwesomeIcon icon={faDownload} {...props} />;

export const Info = props => <FontAwesomeIcon icon={faInfoCircle} {...props} />;

export const ExclamationTriangle = props => <FontAwesomeIcon icon={faExclamationTriangle} {...props} />;

export const ToggleOn = props => <FontAwesomeIcon icon={faToggleOn} {...props} />;

export const ToggleOff = props => <FontAwesomeIcon icon={faToggleOff} {...props} />;

export const Eye = props => <FontAwesomeIcon icon={faEye} {...props} />;

export const EyeSlash = props => <FontAwesomeIcon icon={faEyeSlash} {...props} />;

export const ExclamationCircle = props => <FontAwesomeIcon icon={faExclamationCircle} {...props} />;

export const Asterisk = props => <FontAwesomeIcon icon={faAsterisk} {...props} />;

export const StopWatch = props => <FontAwesomeIcon icon={faStopwatch} {...props} />;

export const Clock = props => <FontAwesomeIcon icon={faClock} {...props} />;

export const User = props => <FontAwesomeIcon icon={faUser} {...props} />;

export const Home = props => <FontAwesomeIcon icon={faHome} {...props} />;

export const Bullhorn = props => <FontAwesomeIcon icon={faBullhorn} {...props} />;

export const Task = props => <FontAwesomeIcon icon={faTasks} {...props} />;

export const Wrench = props => <FontAwesomeIcon icon={faWrench} {...props} />;

export const Paperclip = props => <FontAwesomeIcon icon={faPaperclip} {...props} />;

export const WordFile = props => <FontAwesomeIcon icon={faFileWord} {...props} />;

export const PdfFile = props => <FontAwesomeIcon icon={faFilePdf} {...props} />;

export const ArrowsV = props => <FontAwesomeIcon icon={faArrowsAltV} {...props} />;

export const FilterIcon = props => <FontAwesomeIcon icon={faFilter} {...props} />;

export const SlidersIcon = props => <FontAwesomeIcon icon={faSliders} {...props} />;

export const CrossCircle = props => <FontAwesomeIcon icon={faTimesCircle} {...props} />;

export const BarChart = props => <FontAwesomeIcon icon={faChartBar} {...props} />;

export const LineChart = props => <FontAwesomeIcon icon={faChartLine} {...props} />;

export const PieChart = props => <FontAwesomeIcon icon={faChartPie} {...props} />;

export const ListIcon = props => <FontAwesomeIcon icon={faList} {...props} />;

export const Hashtag = props => <FontAwesomeIcon icon={faHashtag} {...props} />;

export const ThLarge = props => <FontAwesomeIcon icon={faThLarge} {...props} />;

export const ColumnIcon = props => <FontAwesomeIcon icon={faColumns} {...props} />;

export const TeamsIcon = props => <FontAwesomeIcon icon={faUserGroup} {...props} />;

export const CircleCheck = props => <FontAwesomeIcon icon={faCircleCheck} {...props} />;

export const CircleXMark = props => <FontAwesomeIcon icon={faCircleXmark} {...props} />;

export const ListAlt = props => <FontAwesomeIcon icon={faListAlt} {...props} />;

export const NotificationIcon = props => <FontAwesomeIcon icon={faEnvelope} {...props} />;

export const SortIcon = props => <FontAwesomeIcon icon={faSort} {...props} />;

export const GearIcon = props => <FontAwesomeIcon icon={faGear} {...props} />;

export const TemperatureHalf = props => <FontAwesomeIcon icon={faTemperatureHalf} {...props} />;

export const Copy = props => <FontAwesomeIcon icon={faCopy} {...props} />;
