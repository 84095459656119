export const firmwareDeploymentConstants = {
  GET_FIRMWARE_DEPLOYMENT_REQUEST: 'GET_FIRMWARE_DEPLOYMENT_REQUEST',
  GET_FIRMWARE_DEPLOYMENT_SUCCESS: 'GET_FIRMWARE_DEPLOYMENT_SUCCESS',
  GET_FIRMWARE_DEPLOYMENT_FAILURE: 'GET_FIRMWARE_DEPLOYMENT_FAILURE',
  SET_FIRMWARE_DEPLOYMENT_FILTERS: 'SET_FIRMWARE_DEPLOYMENT_FILTERS',
  CLEAR_FIRMWARE_DEPLOYMENT_LIST: 'CLEAR_FIRMWARE_DEPLOYMENT_LIST',

  GET_FIRMWARES_REQUEST: 'GET_FIRMWARES_REQUEST',
  GET_FIRMWARES_SUCCESS: 'GET_FIRMWARES_SUCCESS',
  GET_FIRMWARES_FAILURE: 'GET_FIRMWARES_FAILURE',

  GET_FIRMWARE_DEPLOYMENT_DETAILS_REQUEST: 'GET_FIRMWARE_DEPLOYMENT_DETAILS_REQUEST',
  GET_FIRMWARE_DEPLOYMENT_DETAILS_SUCCESS: 'GET_FIRMWARE_DEPLOYMENT_DETAILS_SUCCESS',
  GET_FIRMWARE_DEPLOYMENT_DETAILS_FAILURE: 'GET_FIRMWARE_DEPLOYMENT_DETAILS_FAILURE',

  GET_FIRMWARE_DEPLOYMENT_AFFECTED_DEVICES_REQUEST: 'GET_FIRMWARE_DEPLOYMENT_AFFECTED_DEVICES_REQUEST',
  GET_FIRMWARE_DEPLOYMENT_AFFECTED_DEVICES_SUCCESS: 'GET_FIRMWARE_DEPLOYMENT_AFFECTED_DEVICES_SUCCESS',
  GET_FIRMWARE_DEPLOYMENT_AFFECTED_DEVICES_FAILURE: 'GET_FIRMWARE_DEPLOYMENT_AFFECTED_DEVICES_FAILURE',

  RETRY_FIRMWARE_DEPLOYMENT_DEVICES_REQUEST: 'RETRY_FIRMWARE_DEPLOYMENT_DEVICES_REQUEST',
  RETRY_FIRMWARE_DEPLOYMENT_DEVICES_SUCCESS: 'RETRY_FIRMWARE_DEPLOYMENT_DEVICES_SUCCESS',
  RETRY_FIRMWARE_DEPLOYMENT_DEVICES_FAILURE: 'RETRY_FIRMWARE_DEPLOYMENT_DEVICES_FAILURE',

  GET_ALL_OPS_TX_SENSOR_REQUEST: 'GET_ALL_OPS_TX_SENSOR_REQUEST',
  GET_ALL_OPS_TX_SENSOR_SUCCESS: 'GET_ALL_OPS_TX_SENSOR_SUCCESS',
  GET_ALL_OPS_TX_SENSOR_FAILURE: 'GET_ALL_OPS_TX_SENSOR_FAILURE',

  GET_ALL_OPS_MOTE_REQUEST: 'GET_ALL_OPS_MOTE_REQUEST',
  GET_ALL_OPS_MOTE_SUCCESS: 'GET_ALL_OPS_MOTE_SUCCESS',
  GET_ALL_OPS_MOTE_FAILURE: 'GET_ALL_OPS_MOTE_FAILURE',

  NUMBER_OF_FIRMWARE_DEPLOYMENTS_PER_PAGE: 50,

  INCREASE_FIRMWARE_DEPLOYMENT_PAGE: 'INCREASE_FIRMWARE_DEPLOYMENT_PAGE',

  DEVICE_TYPE_MOTE: 'mote',
  DEVICE_TYPE_TRANSMITTER: 'tx',
  SENSOR_MODEL_RX: 'RX',
  SENSOR_MODEL_TX: 'TX',
  MOTE_MODEL_VERSION: '0901',
  TX_MODEL_VERSION: '0805',
};

export const DEVICE_TYPES = [
  { value: 'tx', text: 'Transmitter' },
  { value: 'mote', text: 'Mote' }
];

export const FIRMWARE_TYPES = [
  { value: 'mcu', text: 'MCU' },
  { value: 'bluetooth', text: 'Bluetooth' },
  { value: 'bootloader', text: 'Bootloader' }
];

export const ASSOCIATION_TYPES = [
  { value: 'devices', text: 'Devices' },
  { value: 'accounts', text: 'Accounts' },
  { value: 'firmwares', text: 'Firmwares' },
];
