import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import Link from '../components/atoms/Link';

import InputField from '../components/atoms/InputField';

const CheckboxField = styled(InputField).attrs({ type: 'checkbox' })`
  label {
    color: ${props => props.theme.colors.black};
    font-weight: 300;
  }
`;

const CountryCodeContainer = styled(InputField)`
  width: 5%;
  min-width: 100px;
  padding-right: 10px;
  .ui.search {
    font-size: 16px;
  }
`;

export const checkEmpty = value => ((typeof value !== 'undefined' && value != null) ? undefined : 'Required field');

export const checkEmail = value =>
  (value && !/^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined);

export const checkPassword = (value) => {
  const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@!%*?&#]{8,}$/;
  if (regExp.test(value)) {
    return undefined;
  }
  return 'Should be at least 8 characters with at least 1 digit, 1 lowercase and 1 uppercase character. Allowed special characters ($@!%*?#&)';
};

export const checkPhone = (value) => {
  if (!value) return undefined;
  const regExp = /^[0-9]+$/;
  const regExpLength = /^[0-9]{3,14}$/;
  if (regExp.test(value)) {
    if (regExpLength.test(value)) return undefined;
    return 'Length of phone number should be in range 3 to 14';
  }
  return 'Invalid phone number, only numbers are allowed';
};

export const checkTermsAccept = value => (value === true ? undefined : 'You must accept Terms and Conditions');

export const renderField = ({
  input,
  label,
  type,
  disabled,
  meta: { touched, error, warning }
}) => (
    <div className={`form-group${touched && error ? ' has-error' : ''}`}>
      <input className="form-control" {...input} placeholder={label} type={type} disabled={disabled} />
      {<span className={`help-block${touched && error ? ' pop' : ''}`}>{error}</span> ||
        <span className={`help-block info${touched && warning ? ' pop' : ''}`}>{warning}</span>}
    </div>
);

export const checkBoxInput = ({
  input,
  disabled,
  meta: { touched, error },
  ...rest
}) => (
    <CheckboxField type="checkbox" marginBottom="0" error={touched ? error : ''} {...rest} {...input} disabled={disabled} />
);

export const renderInputField = ({
  input,
  label,
  type,
  disabled,
  meta: { touched, error }
}) => (
    <InputField
      autoComplete="on"
      error={touched ? error : ''}
      {...input}
      placeholder={label}
      type={type}
      disabled={disabled}
    />
);

export const renderTextArea = ({
  input,
  label,
  disabled,
  fixed,
  meta: { touched, error, warning }
}) => (
    <div className={`form-group${touched && error ? ' has-error' : ''}`}>
      <textarea className={`form-control${fixed ? ' fixed' : ''}`} {...input} placeholder={label} disabled={disabled} />
      {<span className={`help-block${touched && error ? ' pop' : ''}`}>{error}</span> ||
        <span className={`help-block info${touched && warning ? ' pop' : ''}`}>{warning}</span>}
    </div>
);

/* Left this in as reference for now if needed */
export const renderNormalCheckbox = ({
  input,
  label,
}) => (
    <div className="abc-checkbox">
      <input {...input} id="normalCheckbox" type="checkbox" />
      <label className="normal-checkbox-label" htmlFor="normalCheckbox">{label}</label>
    </div>
);

/* todo replace usage with InputField */
export const renderTermsCheckbox = ({
  input,
  label,
  meta: { touched, error, warning }
}) => (
    <div className="abc-checkbox">
      <input type="checkbox" id="termsAccepted" {...input} />
      <label htmlFor="termsAccepted">{label}</label>
      <Link to="/terms" target="_blank" className="btn btn-link">Terms & Conditions</Link>
      {<span className={`help-block${touched && error ? ' pop' : ''}`}>{error}</span> ||
        <span className={`help-block info${touched && warning ? ' pop' : ''}`}>{warning}</span>}
    </div>
);

export class renderDropdown extends React.Component {
  onChange = (e, data) => {
    const { input } = this.props;
    input.onChange(data.value);
  }

  render() {
    const {
      options, className, placeholder, input
    } = this.props;
    return (
      <Dropdown {...input} value={input.value} className={className} placeholder={placeholder} selection options={options} onChange={this.onChange} />
    );
  }
}

export const renderCountryCodeDropdown = ({
  input,
  options,
  placeholder,
  meta: { touched, error },
  ...rest
}) => (
    <CountryCodeContainer
      error={touched ? error : ''}
      {...input}
      {...rest}
      value={input.value}
      placeholder={placeholder}
      selection
      options={options}
      onChange={(e, data) => input.onChange(data.value)}
      onBlur={() => {}}
    />
);
