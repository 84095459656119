export default {
  GET_ACCOUNT_USERS_REQUEST: 'GET_ACCOUNT_USERS_REQUEST',
  GET_ACCOUNT_USERS_SUCCESS: 'GET_ACCOUNT_USERS_SUCCESS',
  GET_ACCOUNT_USERS_FAILURE: 'GET_ACCOUNT_USERS_FAILURE',

  INVITE_USER_FAILURE: 'INVITE_USER_FAILURE',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  SET_CURRENT_USER_SUCCESS: 'SET_CURRENT_USER_SUCCESS'
};
