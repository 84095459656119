export default {
  GET_RUL_TREND_SUCCESS: 'GET_RUL_TREND_SUCCESS',
  GET_RUL_TREND_REQUEST: 'GET_RUL_TREND_REQUEST',
  GET_RUL_TREND_FAILURE: 'GET_RUL_TREND_FAILURE',
  GET_RUL_TREND: 'GET_RUL_TREND',
  UPDATE_RUL_SETTINGS_REQUEST: 'UPDATE_RUL_SETTINGS_REQUEST',
  UPDATE_RUL_SETTINGS_SUCCESS: 'UPDATE_RUL_SETTINGS_SUCCESS',
  UPDATE_RUL_SETTINGS_FAILURE: 'UPDATE_RUL_SETTINGS_FAILURE',
  GET_RUL_SETTINGS_SUCCESS: 'GET_RUL_SETTINGS_SUCCESS',
  GET_RUL_SETTINGS_REQUEST: 'GET_RUL_SETTINGS_REQUEST',
  GET_RUL_SETTINGS_FAILURE: 'GET_RUL_SETTINGS_FAILURE',
  GET_RUL_SETTINGS: 'GET_RUL_SETTINGS'
};
