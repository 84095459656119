import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { toastr } from 'react-redux-toastr';
import { Popup } from 'semantic-ui-react';

import H2 from 'common/typography/H2/H2';
import { FlexContainer, Button, InputField } from 'common/components/atoms';
import { humanize } from 'common/helpers/humanize';
import { ChevronLeft, ChevronRight } from 'common/images/FaIcons';
import ChartsView from 'home/AssetHierarchy/components/pages/ChartsView';
import Loading from 'common/components/atoms/Loading';
import { getConfigs, getTags } from 'home/AssetHierarchy/actions/assetDetails.actions';
import * as taskActions from 'home/HomePage/actions/tasks.actions';
import * as repairActions from 'home/HomePage/actions/repairs.actions';
import * as noteActions from 'home/HomePage/actions/notes.actions';
import SummaryDropdown from 'home/HomePage/organisms/SummaryDropdown';
import TaskDetailsModal from 'home/HomePage/components/TaskDetailsModal';
import RepairDetailsModal from 'home/HomePage/components/RepairDetailsModal';
import NoteDetailsModal from 'home/HomePage/components/NoteDetailsModal';
import { getActivity } from 'home/Activity/actions/activity.actions';
import * as constants from 'home/Activity/constants/constants';
import ReasonItem from 'home/Machines/components/ReasonItem';
import Footer from 'home/HomePage/components/atoms/Footer';
import NoteSvgNew from 'common/images/NoteSvgNew';
import { getMachineDetails, postMarkasAnalyzed } from 'home/Machines/MachineDetails/machineDetails.actions';
import BreadCrumbHeader from 'home/Menu/BreadCrumbHeader';
import AnalystSummaryView from 'home/HomePage/AnalystSummaryView';
import { localTimeString } from '../../../common/helpers';
import groupedActivityFormat from './GroupedActivityFormat';


const Description = styled(H2)`
  margin: 0;
  ${props => props.marginLeft && `margin-left: ${props.marginLeft}`};
  font-weight: 600;
  color: #333;
  height: 30px;
  font-size: 14px;
`;

const AnalysisHeader = styled(H2)`
  font-weight: 600;
  padding: 0.5em 0;
`;

const CountLabel = styled(H2)`
  font-weight: 600;
  margin-top: 1em;
  margin-right: 1em;
  color: #333;
  height: 30px;
  font-size: 14px;
`;

const TabContainer = styled(FlexContainer).attrs({
  alignItems: 'center',
  backgroundColor: 'white'
})`
padding: 0 20px;
`;

const LoadingContainer = Loading.extend`
  position: static;
`;

const AnalyzeViewContainer = styled.div`
  height: 100vh;
  overflow: auto;
  background-color: white;
`;

const EventsListDropdown = styled(SummaryDropdown)`
  margin-top: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  margin-left: 2em;
`;

const TasksContainer = styled.div`
  margin-top: 1em;
`;

const MarkasReviewedBtn = styled(Button)`
  margin-right: 1em;
`;

const ChartContainer = styled.div`
  background-color: white;
`;

export const LastMeasurementTime = styled(FlexContainer)`
  width: 100%;
  font-size: 11px;
  padding-bottom: 8px;
  color: #999B95;
  font-weight: bold;
  padding: 1em 0;
  align-items: center;
`;

const EmptyMsgContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em;
  margin: 2em;
  border: 2px dotted ${props => props.theme.colors.lightGray};
  border-radius: ${props => props.theme.utils.borderRadius};
  background-color: ${props => props.theme.colors.white};
  span {
    color: ${props => props.theme.colors.lightGray};
    font-weight: bold;
  }
`;

const MarkAsAnalysedButton = (text, disabled) => (
  <MarkasReviewedBtn
    style={{ margin: '1em', marginRight: '4em' }}
    text
    disabled={disabled}
  >
    {text}
  </MarkasReviewedBtn>
);

function AnalyzeView(props) {
  const [configList, setConfigList] = useState(null);
  const [tagsList, setTagList] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoader] = useState(false);
  const [taskModal, setTaskModal] = useState({ });
  const [repairModal, setRepairModal] = useState({});
  const [noteModal, setNotesModal] = useState({});
  const [selectedEventsList, setSelectedEventsList] = useState([]);
  const [selectedItems, setSelectedItems] = useState(null);
  const [taskList, setTaskList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [machineName, setMachineName] = useState('');
  const [machineDetails, setMachineDetails] = useState(null);
  const [chartKey, setChartKey] = useState(0);
  const [ismarkasAnalysed, setMarkasAnalysed] = useState(false);
  const [noteText, setNoteText] = useState('');

  const machine_id = props.match.params.machine_id;

  useEffect(() => {
    if (!selectedItems) {
      getMachineActivities();
    }
    if (!machineDetails) {
      setLoader(true);
      props.getMachineDetails(machine_id).then((res) => {
        setLoader(false);
        if (res) {
          setMachineDetails(res);
          setMachineName(res.name);
        }
      });
    }
    if (!configList) {
      setLoader(true);
      props.getConfigs(machine_id).then((res) => {
        setLoader(false);
        if (res) {
          const configs = res;
          setConfigList(configs);
        }
      });
    }
    if (!tagsList) {
      setLoader(true);
      props.getTags(machine_id).then((res) => {
        setLoader(false);
        if (res) {
          const tags = res;
          setTagList(tags);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedItems) {
      setChartSearchParams(selectedItems[selectedIndex]);
      setSelectedEvent(selectedItems[selectedIndex]);
    }
  }, [selectedEvent]);

  const getActivityParams = () => {
    const params = constants.initialSearchParams;
    params.days = null;
    params.order_by = { field: 'timestamp', direction: 'desc' };
    params.state = ['open'];
    searchParams.type = ['event', 'task'];
    return params;
  };

  const onEventSelection = (value) => {
    const selectedEvent = selectedItems.find(element => (element.activity_id === value));
    const index = selectedItems.indexOf(selectedEvent);
    setUpdateConfigs(index);
    setChartSearchParams(selectedEvent);
    setSelectedEvent(selectedEvent);
  };

  const getMachineActivities = () => {
    setLoader(true);
    props.getActivity(getActivityParams(), machine_id, false).then((res) => {
      setLoader(false);
      if (res) {
        const filteredItems = res.filter(item => item.tag_id);
        const taskList = res.filter(item => item.type === 'task');
        if (taskList) {
          setTasksList(taskList);
        }
        if (filteredItems && filteredItems.length !== 0) {
          setSelectedItems(filteredItems);
          const selectedEvents = [];
          filteredItems.forEach((element) => {
            selectedEvents.push({ text: humanize((element.description || '').replace(`${element.machine_name || ''} machine has a`, '')), value: element.activity_id });
          });
          setSelectedEventsList(selectedEvents);
          setChartSearchParams(filteredItems[0]);
          setSelectedEvent(filteredItems[0]);
        }
      }
    });
  };

  const setTasksList = (taskList) => {
    const list = [];
    if (taskList) {
      taskList.forEach((task) => {
        list.push({ id: task.activity_id, name: task.name });
      });
    }
    setTaskList(list);
  };

  const onClickNext = () => setUpdateConfigs(selectedIndex + 1);

  const onClickPrevious = () => setUpdateConfigs(selectedIndex - 1);

  const setUpdateConfigs = (index) => {
    setSelectedIndex(index);
    setChartSearchParams(selectedItems[index]);
    setSelectedEvent(selectedItems[index]);
    setChartKey(prevKey => prevKey + 1);
  };

  const getSelectedConfig = () => {
    const filteredConfigs = !_.isEmpty(configList) &&
    configList.filter(config => config.default === true && config.trends_config.some(trend => trend.tag_id === selectedEvent.tag_id));

    let selectedConfig = null;
    if (!_.isEmpty(filteredConfigs)) selectedConfig = filteredConfigs[0];
    return selectedConfig;
  };

  const openModal = (type) => {
    const modal = {};
    modal.open = true;
    modal.taskId = null;
    switch (type) {
      case 'task':
        modal.openTaskDetails = {};
        setTaskModal(modal);
        break;
      case 'repair':
        modal.openRepairDetails = {};
        setRepairModal(modal);
        break;
      case 'note':
        modal.openNoteDetails = {};
        setNotesModal(modal);
        break;
      default:
        break;
    }
  };

  const closeModal = () => {
    setTaskModal({});
    setRepairModal({});
    setNotesModal({});
  };

  const markasAnalysed = () => {
    setLoader(false);
    props.postMarkasAnalyzed(machine_id, noteText).then((res) => {
      if (res) {
        toastr.success(res.message);
        setMarkasAnalysed(true);
      }
    })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const setChartSearchParams = (activity) => {
    const paramsObj = {
      activity_id: activity.activity_id,
      tag_id: activity.tag_id,
      timestamp: activity.timestamp,
      chartType: 'trend',
    };
    if (activity.threshold_type) paramsObj.threshold_type = activity.threshold_type;
    if (activity.measurement_type) paramsObj.measurement_type = activity.measurement_type;
    if (activity.event_type && (activity.event_type === 'vibration_acceleration_warning' || activity.event_type === 'vibration_acceleration_critical')) {
      paramsObj.amp_type = 'acceleration';
    } else if (activity.event_type && (activity.event_type === 'vibration_demod_warning' || activity.event_type === 'vibration_demod_critical')) {
      paramsObj.amp_type = 'demod';
    } else if (activity.event_type && (activity.event_type === 'vibration_warning' || activity.event_type === 'vibration_critical')) {
      paramsObj.amp_type = 'velocity';
    } else {
      paramsObj.amp_type = 'velocity';
    }
    if (activity.event_type && !(activity.event_type.includes('vibration'))) {
      delete paramsObj.amp_type;
    }
    setSearchParams(paramsObj);
  };

  const selectedTag = tagsList ? tagsList.find(item => item.id === selectedEvent.tag_id) : null;
  const selectedConfig = getSelectedConfig();

  return (
    <AnalyzeViewContainer>
      {machineDetails && (
        <BreadCrumbHeader
          location={{ pathname: '/analyse' }}
          machineDetails={machineDetails}
          handleLogoClick={() => {}}
          discardAnalystSummary
          breadCrumb={[machineDetails.area_name, machineDetails.name]}
          accountsName={
            props.user.accounts
              ? props.user.accounts.filter(
                item => item.id === props.user.account_id
              )[0].name
              : ''
          }
        />
      )}
      {machineDetails && (
        <AnalystSummaryView
          machine_id={machineDetails.id}
          location={{ pathname: '/analyse' }}
          openModal={openModal}
        />
      )}
      {machineDetails && (
        <TabContainer
          className="AnalysisViewContainer"
          direction="coloum"
          alignItems="center"
          width="100%"
          showBorderBottom
        >
          <FlexContainer direction="column">
            {selectedEvent && (
              <Description>
                {humanize(
                  (selectedEvent.description || '').replace(
                    `${machineName || ''} machine has a`,
                    ''
                  )
                )}
              </Description>
            )}
            {machineDetails.last_vibration_analysis && (
              <LastMeasurementTime>
                {humanize(
                  `last_vibration_analysis : ${localTimeString(
                    machineDetails.last_vibration_analysis
                  )}`
                )}
                <Popup
                  trigger={(
                    <span style={{ marginLeft: '10px' }}>
                      <NoteSvgNew />
                    </span>
                  )}
                  hoverable
                >
                  <div style={{ width: '50rem', padding: '0.5em' }}>
                    <span>
                      <AnalysisHeader>Analysis Note</AnalysisHeader>
                      {machineDetails.analysis_note}
                    </span>
                  </div>
                </Popup>
              </LastMeasurementTime>
            )}
            {taskList.length !== 0 && (
              <TasksContainer>
                <ReasonItem
                  reason={{ tasks: taskList }}
                  metadata={{ tasks: taskList }}
                  machineId={machine_id}
                  style={{ marginTop: '10px' }}
                />
              </TasksContainer>
            )}
          </FlexContainer>
          {selectedItems && (
            <FlexContainer
              position="relative"
              style={{
                marginTop: taskList.length === 0 ? '0px' : '-10px',
                marginLeft: 'auto',
              }}
            >
              {!ismarkasAnalysed ? (
                <FlexContainer alignItems="center">
                  <Popup
                    trigger={MarkAsAnalysedButton('Mark as Analysed', false)}
                    flowing
                    on="click"
                  >
                    <InputField
                      type="textarea"
                      placeholder="Type your note here..."
                      height="100px"
                      width="400px"
                      value={noteText}
                      onChange={e => setNoteText(e.target.value)}
                    />
                    <Footer
                      paddingTop="0"
                      onSave={markasAnalysed}
                      showCancel={false}
                      saveDisabled={false}
                    />
                  </Popup>
                </FlexContainer>
              ) : (
                MarkAsAnalysedButton('Analysed', true)
              )}
              <EventsListDropdown
                openModal={onEventSelection}
                dropdownOptions={selectedEventsList}
                isAnalyzeView
              />
              <Button
                onClick={() => onClickPrevious()}
                secondary
                fontSize="14.5px"
                title="Previous"
                disabled={selectedIndex === 0}
                style={{
                  marginTop: '1em',
                  marginRight: '1em',
                  marginBottom: '1em',
                }}
              >
                {' '}
                <ChevronLeft />
              </Button>
              <CountLabel>{`${selectedIndex + 1} / ${
                selectedItems.length
              }`}
              </CountLabel>
              <Button
                secondary
                onClick={() => onClickNext()}
                fontSize="14.5px"
                title="Next"
                disabled={selectedIndex === selectedItems.length - 1}
                style={{
                  marginTop: '1em',
                  marginRight: '1em',
                  marginBottom: '1em',
                }}
              >
                <ChevronRight />
              </Button>
            </FlexContainer>
          )}
        </TabContainer>
      )}
      {!loading && !selectedItems && (
        <EmptyMsgContainer>
          <span>No open events to analyse</span>
        </EmptyMsgContainer>
      )}
      {!loading && selectedTag && selectedConfig && (
        <ChartContainer key={chartKey}>
          <ChartsView
            previewTag={selectedTag}
            previewConfig={selectedConfig}
            machine_id={machine_id}
            site_id={props.account_id}
            selectedTags={tagsList}
            searchParams={searchParams}
            style={{
              flex: 1,
              width: '100%',
              height: '100%',
              overflowy: 'auto',
            }}
          />
        </ChartContainer>
      )}
      {loading && <LoadingContainer />}
      {taskModal.open && (
        <TaskDetailsModal
          isEditMode={false}
          closeModal={closeModal}
          machine_id={machine_id}
          machine_name={machineName}
          eventsDetails={selectedItems.map(
            event => ({
              ...event,
              description: (groupedActivityFormat[event.event_type] || groupedActivityFormat.custom).title(event)
            })
          )}
          allowedTriggers={{
            alarms: {
              selected: false,
              resources_info: []
            }
          }}
        />
      )}
      {repairModal.open && (
        <RepairDetailsModal
          isEditMode={false}
          closeModal={closeModal}
          machine_id={machine_id}
          machine_name={machineName}
        />
      )}
      {noteModal.open && (
        <NoteDetailsModal
          isEditMode={false}
          closeModal={closeModal}
          machine_id={machine_id}
          machine_name={machineName}
        />
      )}
    </AnalyzeViewContainer>
  );
}

const mapStateToProps = state => ({
  machines: state.machines,
  account_id: state.user.account_id,
  sites: state.sites,
  user: state.user.user,
});

const mapDispatchToProps = dispatch => ({
  getConfigs: bindActionCreators(getConfigs, dispatch),
  getTags: bindActionCreators(getTags, dispatch),
  taskActions: bindActionCreators(taskActions, dispatch),
  repairActions: bindActionCreators(repairActions, dispatch),
  noteActions: bindActionCreators(noteActions, dispatch),
  getActivity: bindActionCreators(getActivity, dispatch),
  getMachineDetails: bindActionCreators(getMachineDetails, dispatch),
  postMarkasAnalyzed: bindActionCreators(postMarkasAnalyzed, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnalyzeView));
