import { combineReducers } from 'redux';
import { eventDefinitions } from './EventDefinitions/reducers';
import { sensorModels } from './SensorModels';
import { thirdPartyTags } from './ThirdPartyTags';
import { teams } from './Teams/reducers';
import thirdPartyIntegrations from './ThirdPartyIntegrations/reducers/thirdPartyIntegrations.reducer';
import accountUsers from './AccountUsers/reducers/accountUsers.reducer';

export const settings = combineReducers({
  eventDefinitions,
  accountUsers,
  sensorModels,
  thirdPartyTags,
  thirdPartyIntegrations,
  teams
});
