import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import _ from 'lodash';

import * as bearingActions from '../bearing.actions';
import Input_T from '../../../../../../../common/components/atoms/Input';
import FlexContainer from '../../../../../../../common/components/atoms/FlexContainer';
import ListItem from '../../../../../../../common/components/atoms/ListItem';
import TickSvg from '../../../../../../../common/images/BearingModal/TickSvg';
import OutsideAlerter from '../../../../../../../common/OutsideAlerter';


const Dropdown = styled(SemanticDropdown)`
  &.ui.dropdown {
    .menu.transition.visible {
      .scrolling.menu.transition {
        max-height: 200px;
        border-top: 0;
      }
      margin-left: -22em;
      div[role='option'] {
        font-weight: 600;
        &.active.item {
          background-color: rgba(145,184,70,0.08);
        }
      }
    }
    >.dropdown.icon {
        display: none;
    }
  }
`;

const SvgContainer = styled.div`
  width: max-content;
  &:hover {
    cursor: ${props => props.valid ? 'pointer' : 'default'};
  }
`;

const Input = styled(Input_T)`
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 14px;
  &:focus {
    outline: none;
  }
`;

const threeDeciamalRegex = /^\d*(\.\d{1,3})?$/;

class BearingNewItem extends Component {
  constructor() {
    super();
    this.state = {
      manufacturer: '',
      bearing_number: '',
      bpfi: '',
      bpfo: '',
      bsf: '',
      ftf: '',
      dropdownActive: false,
      selectedManufacturer: false,
      manufacturers: []
    };
  }

  componentDidMount() {
    const { getAllManufacturers } = this.props;
    getAllManufacturers().then(res =>
      this.setState({ manufacturers: res })
    );
    const { item } = this.props;
    if (item && !item.newitem) {
      this.setState({
        manufacturer: item.manufacturer,
        bearing_number: item.bearing_no,
        bpfi: item.bpfi,
        bpfo: item.bpfo,
        bsf: item.bsf,
        ftf: item.ftf,
        id: item.id
      });
    }
  }

  inputFocus = (e) => {
    if (e.target.placeholder === 'Manufacturer') {
      this.setState({ dropdownActive: true });
    } else {
      this.setState({ dropdownActive: false });
    }
  };

  handleOutsideClick = () => {
    this.setState({
      dropdownActive: false
    });
  };

  handleInputChange = (e) => {
    const value = e.target.value;
    switch (e.target.placeholder) {
      case 'Manufacturer':
        this.setState({
          manufacturer: value,
          manufacturers: this.props.manufacturers.filter(item => item.value.toLowerCase().includes(value))
        });
        break;
      case 'Bearing Number':
        this.setState({ bearing_number: value });
        break;
      case 'BPFI':
        if (threeDeciamalRegex.test(value)) {
          this.setState({ bpfi: parseFloat(value) });
        }
        break;
      case 'BPFO':
        if (threeDeciamalRegex.test(value)) {
          this.setState({ bpfo: parseFloat(value) });
        }
        break;
      case 'BSF':
        if (threeDeciamalRegex.test(value)) {
          this.setState({ bsf: parseFloat(value) });
        }
        break;
      case 'FTF':
        if (threeDeciamalRegex.test(value)) {
          this.setState({ ftf: parseFloat(value) });
        }
        break;
      default:
        break;
    }
    return null;
  };

  addBearing = (valid) => {
    if (valid) {
      const data = {
        manufacturer: this.state.manufacturer,
        bearing_no: this.state.bearing_number,
        bpfo: this.state.bpfo,
        bpfi: this.state.bpfi,
        bsf: this.state.bsf,
        ftf: this.state.ftf,
        id: this.state.id,
        source: 'Custom'
      };
      const {
        item,
        addBearing,
        updateBearing,
        addRowToTable,
        updateRow
      } = this.props;
      if (item && !item.newitem) {
        updateBearing(data, item.id).then((res) => {
          updateRow(data);
          this.props.itemSaved();
        });
      } else {
        addBearing(data).then((res) => {
          addRowToTable(res.bearing);
          this.props.itemSaved();
        });
      }
    }
  };

  handleItemClick = (id, name) => {
    this.setState({
      selectedManufacturer: this.state.selectedManufacturer === id ? false : id,
      manufacturer: name,
      dropdownActive: false
    });
  };

  render() {
    const {
      columnSizes,
      primaryColor
    } = this.props;
    const {
      manufacturer,
      bearing_number,
      bpfi,
      bpfo,
      bsf,
      ftf,
      dropdownActive,
      selectedManufacturer,
      manufacturers
    } = this.state;
    let valid;
    if (manufacturer && bearing_number && bpfo && bpfi && ftf && bsf) {
      valid = true;
    } else {
      valid = false;
    }
    return (
      <ListItem editItem>
        <td width={`${columnSizes[0]}%`}>
          <FlexContainer justifyContent="flex-start" alignItems="center">
            <Input
              placeholder="Manufacturer"
              onChange={this.handleInputChange}
              value={manufacturer}
              onClick={this.inputFocus}
              onFocus={this.inputFocus}
            />
            <OutsideAlerter
              open={dropdownActive}
              handleClick={this.handleOutsideClick}
            >
              <Dropdown
                floating
                open={dropdownActive}
              >
                <SemanticDropdown.Menu>
                  <SemanticDropdown.Menu scrolling>
                    {!_.isEmpty(manufacturers) ? manufacturers.map((item, idx) => (
                      <SemanticDropdown.Item
                        key={idx}
                        onClick={() => this.handleItemClick(item.key, item.value)}
                        active={selectedManufacturer === item.key}
                      >
                          {item.value}
                      </SemanticDropdown.Item>
                    )) : null}
                  </SemanticDropdown.Menu>
                </SemanticDropdown.Menu>
              </Dropdown>
            </OutsideAlerter>
          </FlexContainer>
        </td>
        <td width={`${columnSizes[1]}%`}>
          <Input
            placeholder="Bearing Number"
            onChange={this.handleInputChange}
            value={bearing_number}
            onFocus={this.inputFocus}
          />
        </td>
        <td width={`${columnSizes[2]}%`}>
          <Input
            type="number"
            placeholder="BPFI"
            onChange={this.handleInputChange}
            value={bpfi}
            onFocus={this.inputFocus}
          />
        </td>
        <td width={`${columnSizes[3]}%`}>
          <Input
            type="number"
            placeholder="BPFO"
            onChange={this.handleInputChange}
            value={bpfo}
            onFocus={this.inputFocus}
          />
        </td>
        <td width={`${columnSizes[4]}%`}>
          <Input
            type="number"
            placeholder="BSF"
            onChange={this.handleInputChange}
            value={bsf}
            onFocus={this.inputFocus}
          />
        </td>
        <td width={`${columnSizes[5]}%`}>
          <Input
            type="number"
            placeholder="FTF"
            onChange={this.handleInputChange}
            value={ftf}
            onFocus={this.inputFocus}
          />
        </td>
        <td align="right" width={`${columnSizes[6]}%`}>
          <SvgContainer valid={valid} onClick={() => this.addBearing(valid)}>
            <TickSvg inactive={!valid} fill={primaryColor} />
          </SvgContainer>
        </td>
      </ListItem>
    );
  }
}

BearingNewItem.propTypes = {
  columnSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const mapStateToProps = state => ({
  manufacturers: state.bearings.manufacturers,
  primaryColor: state.companyReducer.partner.theme.primaryColor
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getAllManufacturers: bearingActions.getAllManufacturers,
    addBearing: bearingActions.addBearing,
    addRowToTable: bearingActions.addRowToTable,
    updateBearing: bearingActions.updateBearing,
    updateRow: bearingActions.updateRow,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BearingNewItem);
