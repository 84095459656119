export default {
  GET_TAG_TEMPLATE_METADATA: 'GET_TAG_TEMPLATE_METADATA',
  GET_TAG_TEMPLATE_METADATA_REQUEST: 'GET_TAG_TEMPLATE_METADATA_REQUEST',
  GET_TAG_TEMPLATE_METADATA_SUCCESS: 'GET_TAG_TEMPLATE_METADATA_SUCCESS',
  GET_TAG_TEMPLATE_METADATA_FAILURE: 'GET_TAG_TEMPLATE_METADATA_FAILURE',

  GET_TAG_TEMPLATE_LIST: 'GET_TAG_TEMPLATE_LIST',
  GET_TAG_TEMPLATE_LIST_REQUEST: 'GET_TAG_TEMPLATE_LIST_REQUEST',
  GET_TAG_TEMPLATE_LIST_SUCCESS: 'GET_TAG_TEMPLATE_LIST_SUCCESS',
  GET_TAG_TEMPLATE_LIST_FAILURE: 'GET_TAG_TEMPLATE_LIST_FAILURE',

  GET_TAG_TEMPLATE_DETAILS: 'GET_TAG_TEMPLATE_DETAILS',
  GET_TAG_TEMPLATE_DETAILS_REQUEST: 'GET_TAG_TEMPLATE_DETAILS_REQUEST',
  GET_TAG_TEMPLATE_DETAILS_SUCCESS: 'GET_TAG_TEMPLATE_DETAILS_SUCCESS',
  GET_TAG_TEMPLATE_DETAILS_FAILURE: 'GET_TAG_TEMPLATE_DETAILS_FAILURE',

  SAVE_TAG_TEMPLATE_DETAILS: 'SAVE_TAG_TEMPLATE_DETAILS',
  SAVE_TAG_TEMPLATE_DETAILS_REQUEST: 'SAVE_TAG_TEMPLATE_DETAILS_REQUEST',
  SAVE_TAG_TEMPLATE_DETAILS_SUCCESS: 'SAVE_TAG_TEMPLATE_DETAILS_SUCCESS',
  SAVE_TAG_TEMPLATE_DETAILS_FAILURE: 'SAVE_TAG_TEMPLATE_DETAILS_FAILURE',

  UPDATE_TAG_TEMPLATE_DETAILS: 'UPDATE_TAG_TEMPLATE_DETAILS',
  UPDATE_TAG_TEMPLATE_DETAILS_REQUEST: 'UPDATE_TAG_TEMPLATE_DETAILS_REQUEST',
  UPDATE_TAG_TEMPLATE_DETAILS_SUCCESS: 'UPDATE_TAG_TEMPLATE_DETAILS_SUCCESS',
  UPDATE_TAG_TEMPLATE_DETAILS_FAILURE: 'UPDATE_TAG_TEMPLATE_DETAILS_FAILURE',

  DELETE_TAG_TEMPLATE: 'DELETE_TAG_TEMPLATE',
  DELETE_TAG_TEMPLATE_REQUEST: 'DELETE_TAG_TEMPLATE_REQUEST',
  DELETE_TAG_TEMPLATE_SUCCESS: 'DELETE_TAG_TEMPLATE_SUCCESS',
  DELETE_TAG_TEMPLATE_FAILURE: 'DELETE_TAG_TEMPLATE_FAILURE',

  REMOVE_TAG_TEMPLATE_FROM_LIST: 'REMOVE_TAG_TEMPLATE_FROM_LIST',

  SELECT_TAG_TEMPLATE: 'SELECT_TAG_TEMPLATE',
  ADD_NEW_TAG_TEMPLATE_TO_LIST: 'ADD_NEW_TAG_TEMPLATE_TO_LIST',
  ADD_TAG_TEMPLATE_TO_LIST: 'ADD_TAG_TEMPLATE_TO_LIST',
  SET_TAG_TEMPLATE_SEARCH_PARAMS: 'SET_TAG_TEMPLATE_SEARCH_PARAMS',
  CLEAR_TAG_TEMPLATE_SEARCH_PARAMS: 'CLEAR_TAG_TEMPLATE_SEARCH_PARAMS',
  CLEAR_TAG_TEMPLATES: 'CLEAR_TAG_TEMPLATES',
  SET_TAG_TEMPLATE_PAGINATER: 'SET_TAG_TEMPLATE_PAGINATER',
  SET_TAG_TEMPLATE_SORTER: 'SET_TAG_TEMPLATE_SORTER',
  NUMBER_OF_TAG_TEMPLATES_PER_PAGE: 20
};
