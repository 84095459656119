import { machineDetailsConstants } from '../machineDetails.constants';

function spectrum(state = {}, action) {
  if (action.type.startsWith('MACHINE_DETAILS_TAG_CHART/SPECTRUM_FEATURES')) {
    return {
      ...state,
      features: features(state.features, action),
    };
  }

  switch (action.type) {
    case machineDetailsConstants.tagChart.SPECTRUM_REQUEST:
      return {
        loading: true,
      };
    case machineDetailsConstants.tagChart.SPECTRUM_SUCCESS:
      return {
        data: action.spectrum,
      };
    case machineDetailsConstants.tagChart.SPECTRUM_FAILURE:
      return {
        error: action.error.message,
      };
    case machineDetailsConstants.tagChart.SPECTRUM_SHOW_FEATURE_MODAL:
      return { ...state, featureOpen: true };
    case machineDetailsConstants.tagChart.SPECTRUM_HIDE_FEATURE_MODAL:
      return { ...state, featureOpen: false };
    default:
      return state;
  }
}

// Spectrum features
function features(features, action) {
  const { items } = features || {};

  switch (action.type) {
    case machineDetailsConstants.tagChart.spectrumFeature.GET_FEATURES_REQUEST:
      return {
        ...features,
        loading: true,
      };
    case machineDetailsConstants.tagChart.spectrumFeature.GET_FEATURES_SUCCESS:
      return {
        ...features,
        items: action.features.features.reverse(),
      };
    case machineDetailsConstants.tagChart.spectrumFeature.GET_FEATURES_FAILURE:
      return {
        ...features,
        error: action.error.message,
      };
    case machineDetailsConstants.tagChart.spectrumFeature.DELETE_FEATURE_REQUEST:
      return {
        ...features,
        loading: true,
      };
    case machineDetailsConstants.tagChart.spectrumFeature.DELETE_FEATURE_FAILURE:
      return {
        ...features,
        error: action.error.message
      };
    case machineDetailsConstants.tagChart.spectrumFeature.DELETE_FEATURE_SUCCESS: {
      return {
        items: items.filter(feature => feature.id !== action.featureId),
      };
    }
    case machineDetailsConstants.tagChart.spectrumFeature.CREATE_FEATURE_REQUEST:
      return {
        ...features,
        loading: true,
      };
    case machineDetailsConstants.tagChart.spectrumFeature.CREATE_FEATURE_SUCCESS:
      return {
        ...features,
        items: [
          ...items,
          action.feature,
        ],
      };
    case machineDetailsConstants.tagChart.spectrumFeature.CREATE_FEATURE_FAILURE:
      return {
        ...features,
        error: action.error.message,
      };
    case machineDetailsConstants.tagChart.spectrumFeature.UPDATE_FEATURE_REQUEST:
      return {
        ...features,
        loading: true,
      };
    case machineDetailsConstants.tagChart.spectrumFeature.UPDATE_FEATURE_SUCCESS: {
      const updatedIndex = items.findIndex(feature => feature.id === action.feature.id);
      if (updatedIndex < 0) {
        return features;
      }

      items[updatedIndex] = {
        ...items[updatedIndex],
        ...action.feature,
      };
      return {
        ...features,
        items,
      };
    }
    case machineDetailsConstants.tagChart.spectrumFeature.UPDATE_FEATURE_FAILURE:
      return {
        ...features,
        error: action.error.message,
      };
    default:
      return features;
  }
}

export default spectrum;
