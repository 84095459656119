import { Component } from 'react';
import * as d3 from 'd3';
import { withTheme } from 'styled-components';
import * as _ from 'lodash';
import { LineChart } from '../../../../../common/components/Chart/hoc/LineChart';

import * as graphUtils from '../../../../../common/components/Chart/utils/graphUtils';

class OverallAlarmChart extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.warningThreshold !== prevState.warning_threshold || nextProps.criticalThreshold !== prevState.critical_threshold) {
      return {
        critical_threshold: nextProps.criticalThreshold,
        warning_threshold: nextProps.warningThreshold
      };
    }

    // do not update state otherwise
    return null;
  }

  constructor(props) {
    super(props);

    this.drawAlarmSections = this.drawAlarmSections.bind(this);
    this.updateAlarmSections = this.updateAlarmSections.bind(this);
    this.dragged = this.dragged.bind(this);
    this.updateDecreseYstate = this.updateDecreseYstate.bind(this);

    const yRange = props.thresholdRange[1] - props.criticalThreshold < 1 ? 1.1 * props.criticalThreshold : props.thresholdRange[1];

    this.state = {
      warning_threshold: props.warningThreshold,
      critical_threshold: props.criticalThreshold,
      range: [props.thresholdRange[0], yRange],
      increaseYaxis: 0.15 * yRange,
      maxDataValue: props.thresholdRange[1],
      decreaseDisabled: 0.85 * yRange < props.thresholdRange[1] || 0.85 * yRange < 1.1 * props.criticalThreshold
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState, this.state) || !_.isEqual(prevProps.alarmLocked, this.props.alarmLocked)) {
      this.updateAlarmSections();
    }
  }

  drawGraph(ctx) {
    if (!ctx) return;
    this.ctx = ctx;
    this.ctx.y.domain(this.state.range);
    this.ctx.drawAxis();
    this.drawAlarmSections();
    this.ctx.setState({ decreaseDisabled: this.state.decreaseDisabled });
  }

  redraw() {
    this.updateAlarmSections();
  }

  increaseYAxis = () => {
    const { range, increaseYaxis, decreaseDisabled } = this.state;
    range[1] += increaseYaxis;
    this.ctx.y.domain(range);
    this.setState({ range });
    this.props.updateThresholdRange(range);
    this.ctx.drawAxis();
    this.ctx.redrawDataLines();
    this.drawAlarmSections();
    if (decreaseDisabled) {
      this.setState({ decreaseDisabled: false });
      this.ctx.setState({ decreaseDisabled: false });
    }
  };

  decreaseYAxis = () => {
    const {
      range,
      increaseYaxis,
      maxDataValue,
      decreaseDisabled,
      critical_threshold
    } = this.state;
    if (decreaseDisabled) return;
    range[1] -= increaseYaxis;
    if (range[1] < maxDataValue) {
      range[1] = maxDataValue;
      this.setState({ decreaseDisabled: true });
      this.ctx.setState({ decreaseDisabled: true });
    }
    if (range[1] < critical_threshold * 1.1) {
      range[1] = critical_threshold * 1.1;
      this.setState({ decreaseDisabled: true });
      this.ctx.setState({ decreaseDisabled: true });
    }
    this.ctx.y.domain(range);
    this.setState({ range });
    this.ctx.drawAxis();
    this.props.updateThresholdRange(range);
    this.drawAlarmSections();
    this.ctx.redrawDataLines();
  };

  drawAlarmSections() {
    if (this.props.feature === 'crest_factor') {
      d3.selectAll(`.${this.props.chartName}.warning`).remove();
      d3.selectAll(`.${this.props.chartName}.critical`).remove();
      return;
    }

    if (this.props.alarmLocked) {
      d3.selectAll(`.${this.props.chartName}.warning`).remove();
      d3.selectAll(`.${this.props.chartName}.critical`).remove();
      this.warningRect = undefined;
      this.criticalRect = undefined;
      if (this.state.warning_threshold) {
        this.warningLine = graphUtils.drawLineOnPos(
          this.ctx.chart,
          0,
          this.ctx.y(this.state.warning_threshold),
          this.ctx.chart.getBoundingClientRect().width,
          this.ctx.y(this.state.warning_threshold),
          `${this.props.chartName} warning`,
        );
        this.warningLine.attr('stroke-width', '1px')
          .attr('stroke', this.props.theme.colors.alarmWarning);
      }

      if (this.state.critical_threshold) {
        this.criticalLine = graphUtils.drawLineOnPos(
          this.ctx.chart,
          0,
          this.ctx.y(this.state.critical_threshold),
          this.ctx.chart.getBoundingClientRect().width,
          this.ctx.y(this.state.critical_threshold),
          `${this.props.chartName} critical`,
        );
        this.criticalLine.attr('stroke-width', '1px')
          .attr('stroke', this.props.theme.colors.alarmCritical);
      }
      return;
    }

    d3.selectAll(`.${this.props.chartName}.warning`).remove();
    this.warningRect = undefined;
    if (this.state.warning_threshold !== null) {
      this.warningRect = graphUtils.drawRectOnPos(
        this.ctx.chart,
        `${this.props.chartName} warning`,
        this.ctx.y(this.state.warning_threshold) - this.ctx.y(this.state.critical_threshold),
        this.ctx.chart.getBoundingClientRect().width,
        0,
        this.ctx.y(this.state.critical_threshold)
      );
      this.warningRect.attr('fill', this.props.theme.colors.alarmWarning)
        .attr('fill-opacity', '0.3');

      this.warningLine = graphUtils.drawLineOnPos(
        this.ctx.chart,
        this.ctx.chart.getBoundingClientRect().width / 2 - 15,
        this.ctx.y(this.state.warning_threshold),
        this.ctx.chart.getBoundingClientRect().width / 2 + 15,
        this.ctx.y(this.state.warning_threshold),
        `${this.props.chartName} warning`,
      );
      this.warningLine.attr('stroke-width', '4px')
        .attr('stroke-linecap', 'round')
        .attr('stroke', this.props.theme.colors.alarmWarning);

      graphUtils.addDragHandler(this.warningLine, {
        dragged: () => this.dragged('warning')
      });
      this.warningLine
        .attr('cursor', 'ns-resize');
    }

    // critical section

    d3.selectAll(`.${this.props.chartName}.critical`).remove();
    this.criticalRect = undefined;
    if (this.state.critical_threshold !== null) {
      this.criticalRect = graphUtils.drawRectOnPos(
        this.ctx.chart,
        `${this.props.chartName} critical`,
        this.ctx.y(this.state.critical_threshold),
        this.ctx.chart.getBoundingClientRect().width
      );
      this.criticalRect.attr('fill', this.props.theme.colors.alarmCritical)
        .attr('fill-opacity', '0.3');


      this.criticalLine = graphUtils.drawLineOnPos(
        this.ctx.chart,
        this.ctx.chart.getBoundingClientRect().width / 2 - 15,
        this.ctx.y(this.state.critical_threshold),
        this.ctx.chart.getBoundingClientRect().width / 2 + 15,
        this.ctx.y(this.state.critical_threshold),
        `${this.props.chartName} critical`,
      );
      this.criticalLine.attr('stroke-width', '4px')
        .attr('stroke-linecap', 'round')
        .attr('stroke', this.props.theme.colors.alarmCritical);

      graphUtils.addDragHandler(this.criticalLine, {
        dragged: () => this.dragged('critical'),
        dragended: () => {
          this.updateDecreseYstate();
        }
      });
      this.criticalLine
        .attr('cursor', 'ns-resize');
    }
  }

  updateDecreseYstate() {
    const { range, critical_threshold, maxDataValue } = this.state;
    if (range[1] > 1.1 * critical_threshold && range[1] > maxDataValue) {
      this.setState({
        decreaseDisabled: false
      });
      this.ctx.setState({
        decreaseDisabled: false
      });
    }
  }

  updateAlarmSections() {
    if (!this.warningRect || !this.criticalRect || this.props.alarmLocked || (this.warningRect && this.state.warning_threshold === null) || (this.criticalRect && this.state.critical_threshold === null)) {
      this.drawAlarmSections();
      return;
    }

    if (this.warningRect && this.state.warning_threshold >= this.props.thresholdRange[0] && this.state.warning_threshold <= this.props.thresholdRange[1]) {
      this.warningRect
        .attr('width', this.ctx.chart.getBoundingClientRect().width)
        .attr('height', this.ctx.y(this.state.warning_threshold) - this.ctx.y(this.state.critical_threshold))
        .attr('transform', `translate(0, ${this.ctx.y(this.state.critical_threshold)})`);
      this.warningLine
        .attr('x1', this.ctx.chart.getBoundingClientRect().width / 2 - 15)
        .attr('y1', this.ctx.y(this.state.warning_threshold))
        .attr('x2', this.ctx.chart.getBoundingClientRect().width / 2 + 15)
        .attr('y2', this.ctx.y(this.state.warning_threshold));
    }

    if (this.criticalRect && this.state.critical_threshold >= this.props.thresholdRange[0] && this.state.critical_threshold <= this.props.thresholdRange[1]) {
      this.criticalRect
        .attr('width', this.ctx.chart.getBoundingClientRect().width)
        .attr('height', this.ctx.y(this.state.critical_threshold));
      this.criticalLine
        .attr('x1', this.ctx.chart.getBoundingClientRect().width / 2 - 15)
        .attr('y1', this.ctx.y(this.state.critical_threshold))
        .attr('x2', this.ctx.chart.getBoundingClientRect().width / 2 + 15)
        .attr('y2', this.ctx.y(this.state.critical_threshold));
    }
  }

  dragged(section) {
    const { range } = this.state;
    const criticalPos = this.criticalLine.attr('y1');
    const warningPos = this.warningLine.attr('y1');
    const bottomLimit = this.ctx.y(range[0]);
    const topLimit = this.ctx.y(range[1]);
    let newpos = d3.event.y;

    if (newpos >= bottomLimit) newpos = bottomLimit;
    else if (newpos <= topLimit) newpos = topLimit;

    if (section === 'warning') {
      const height = newpos - this.ctx.y(this.state.critical_threshold);
      this.warningLine
        .attr('y1', newpos)
        .attr('y2', newpos);

      this.warningRect
        .attr('height', height >= 0 ? height : 0);

      if (newpos < criticalPos) {
        this.criticalLine
          .attr('y1', newpos)
          .attr('y2', newpos);
      }
    }

    if (section === 'critical') {
      const height = this.ctx.y(this.state.warning_threshold) - newpos;
      this.criticalLine
        .attr('y1', newpos)
        .attr('y2', newpos);

      this.warningRect
        .attr('height', height >= 0 ? height : 0)
        .attr('transform', `translate(0, ${newpos})`);
      this.criticalRect
        .attr('height', newpos);

      if (newpos > warningPos) {
        this.warningLine
          .attr('y1', newpos)
          .attr('y2', newpos);
      }
    }

    // update values to modal state
    const criticalVal = this.ctx.y.invert(this.criticalLine.attr('y1'));
    const warningVal = this.ctx.y.invert(this.warningLine.attr('y1'));

    this.props.updateThreshold({
      critical_threshold: parseFloat(criticalVal.toPrecision(2)),
      warning_threshold: parseFloat(warningVal.toPrecision(2))
    }, this.state.range);
  }

  render() {
    return null;
  }
}

OverallAlarmChart.displayName = 'OverallAlarmChart';

export default withTheme(LineChart(OverallAlarmChart));
